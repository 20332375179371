import React from "react";
import moment from "moment";

// import DivisionCourse from "../../assets/images/op36/divisions.png";
import ProfileAvatar from "../../assets/images/mock/StockProfilePic.png";
import Op36Logo from "../../assets/images/logos/OP36_clean_icon_dark.png";

import "./CurriculumReportPrint.scss";
import StateSwitcher from "../common/StateSwitcher";

class CurriculumReportPrint extends React.Component {
  state = {
    sortState: "highToLow",
  };

  render() {
    const { user, title } = this.props;
    const { sortState } = this.state;
    return (
      <div className='print-container m-5' id='CurriculumReportPrint'>
        <div className='row py-4 d-flex align-items-center print-top-header'>
          <div className='header-container d-flex align-items-center'>
            <div className='small-round-image-wrapper'>
              <img
                className='round-image p-1'
                src={Op36Logo}
                alt={"Operation 36 Logo"}
              />
            </div>
            <h2> {title}</h2>
          </div>
          <p className='print-date-text'>{moment().format("MM/DD/YYYY")}</p>
        </div>
        <div className='no-print'>
          <StateSwitcher
            options={[
              {
                type: "highToLow",
                text: 'Level ' + String.fromCharCode(0x25bc),
              },
              {
                type: "lowToHigh",
                text: 'Level ' + String.fromCharCode(0x25b2),
              },
            ]}
            initialTypeSelected={"highToLow"}
            selectedType={sortState}
            stateSwitcher={this._sortStateToggle}
            position={"right"}
          />
        </div>
        {this._renderNewTable()}
        {/* <img
          src={DivisionCourse}
          alt='Division Course'
          className='print-division-course'
        /> */}
        <div className='row py-2 mt-5 d-flex align-items-center flex-row border-top border-bottom print-bottom-footer'>
          <div className='d-flex align-items-center flex-row'>
            <div className='small-round-image-wrapper'>
              <img
                className='round-image'
                src={
                  user && user.communities
                    ? user.communities[0].photo.file_url
                    : ProfileAvatar
                }
                alt={"Community Logo"}
              />
            </div>
            <p className='ml-2 mb-0 print-community-name'>
              {user && user.communities ? user.communities[0].name : ""}
            </p>
          </div>
          <div className='d-flex align-items-center flex-row print-op36-footer-wrapper'>
            <div className='small-round-image-wrapper'>
              <img
                className='round-image p-1'
                src={Op36Logo}
                alt={"Operation 36 Logo"}
              />
            </div>
            <div className='print-footer-text-wrapper'>
              <p className='mb-0 print-op36-text'>Powered By Operation 36</p>
              <p className='mb-0 print-op36-copyright'>
                Copyright © {this._renderYear()} - Operation 36 Golf LLC
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderYear = () => {
    let today = new Date();
    return today.getFullYear();
  };

  _renderNewTable = () => {
    const { data } = this.props;
    let dataCopy = data.filter((d) => d.current_rank);
    if (dataCopy.length < 1) {
      return null;
    }
    dataCopy.sort(this._handleSort);

    return dataCopy.map((info) => {
      if (info.current_rank) {
        let age = moment().diff(moment(info.student.birthdate), "years");
        return (
          <table key={info.student.id}>
            <thead>
              <tr>
                <th className='text-center' />
                {info.report.map((sub) => {
                  return (
                    <th className='text-center' key={sub.subject.id}>
                      {sub.subject.name}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  className='text-center player-data'
                  style={{
                    background: info.current_rank.color_hex,
                    minWidth: 190,
                  }}
                >
                  <img
                    src={
                      info.student.avatar
                        ? info.student.avatar.file_url
                        : ProfileAvatar
                    }
                    className='round-image'
                    alt='avatar'
                  />
                  <div className='name-container'>
                    <h5>{`${info.student.first_name} ${info.student.last_name}`}</h5>
                  </div>
                  <p>{`Age: ${age}`}</p>
                  <p>{`Level: ${info.current_rank.order}`}</p>
                  <p>Level Percentage</p>
                  <p>{`${info.percent_rank_complete.toFixed(1)}%`}</p>
                </td>
                {info.report.map((sub, index) => {
                  if (sub.has_completed_objective) {
                    return (
                      <td className='text-center' key={index}>
                        <p
                          style={{
                            background: info.current_rank.color_hex,
                            color: "#fff",
                            borderRadius: "8px",
                            marginBottom: 0,
                          }}
                        >
                          &#10003;
                        </p>
                      </td>
                    );
                  } else {
                    return (
                      <td className='text-center' key={index}>
                        ---
                      </td>
                    );
                  }
                })}
              </tr>
            </tbody>
          </table>
        );
      }
      return null;
    });
  };

  _sortStateToggle = (type) => {
    this.setState({ sortState: type });
  };

  _handleSort = (a, b) => {
    const { sortState } = this.state;
    if (sortState === "lowToHigh") {
      if (a.current_rank.order < b.current_rank.order) {
        return -1
      }
      if (a.current_rank.order > b.current_rank.order) {
        return 1
      }
      return 0
    } else {
      if (a.current_rank.order > b.current_rank.order) {
        return -1
      }
      if (a.current_rank.order < b.current_rank.order) {
        return 1
      }
      return 0
    }
  };
}

export default CurriculumReportPrint;
