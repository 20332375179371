import React from "react";
import { createFilter } from 'react-search-input'
import PropTypes from 'prop-types'

// CHILDREN
import PageHeader from '../../common/PageHeader/PageHeader'
import CreateResourceModal from "../Modals/CreateResourceModal";
import ResourceSectionCard from "./ResourceSectionCard";
import Placeholder from "../../common/Placeholder";
import Loader from "../../common/Op36Loader-web";
import ResourceRow from "../ResourceRow";
import SearchInput from '../../common/SearchInput'

// ASSETS
import "./ResourceSectionsPage.scss";

// REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as commonActions from "../../../actions/commonActions";
import * as userActions from "../../../actions/userActions";
import * as resourceActions from "../../../actions/resourceActions";
import * as resourceSectionActions from "../../../actions/resourceSectionActions";
import * as resourceCategoryActions from "../../../actions/resourceCategoryActions";
import ReorganizeSectionsModal from "./Modals/ReorganizeSectionsModal";

const KEYS_TO_FILTERS = ["title"];

class ResourceSectionsPage extends React.Component {
  state = {
    showResourceModal: false,
    id: -1,
    title: "",
    description: "",
    video_url: "",
    button_url: "",
    uploadPhotoName: "",
    uploadPhotoFile: "",
    uploadPhotoSrc: "",
    resource_section_id: -1,
    resource_section_name: "",
    resource_section_description: "",
    resource_section_uploadPhotoName: "",
    resource_section_uploadPhotoFile: "",
    resource_section_uploadPhotoSrc: "",
    resource_category_id: -1,
    resource_category_name: "",
    resource_category_description: "",
    resourceLoader: false,
    resourceSectionLoader: false,
    resourceCategoryLoader: false,
    recentResourceLoader: false,
    formRendered: "resource",
    searchTerm: "",
    showReorganizeModal: false,
  };

  componentDidMount() {
    const { common_actions } = this.props;
    common_actions.setCurrentTab("Resources");
    common_actions.showSideBar(true);
    common_actions.showNavBar(true);

    this.fetchResourceSections();
    this.fetchRecentResources();
  }

  searchUpdated = (e) => {
    const term = e.target.value
    const { resource_actions } = this.props;
    this.setState({ searchTerm: term }, () => {
      this.setState({ resourceLoader: true }, () => {
        if (this.state.searchTerm !== "") {
          resource_actions.searchResources(this.state.searchTerm).then(() => {
            this.setState({
              resourceLoader: false,
            });
          });
        } else {
          this.setState({
            resourceLoader: false,
          });
        }
      });
    });
  };

  fetchRecentResources = () => {
    const { resource_actions } = this.props;

    this.setState(
      {
        recentResourceLoader: true,
      },
      () => {
        resource_actions.getRecentResources().then(() => {
          this.setState({
            recentResourceLoader: false,
          });
        });
      }
    );
  };

  fetchResourceSections = () => {
    const { resource_section_actions } = this.props;
    this.setState(
      {
        resourceSectionLoader: true,
      },
      () => {
        resource_section_actions.getResourceSections().then(() => {
          this.setState({
            resourceSectionLoader: false,
          });
          // console.log("resource section props", this.props);
        });
      }
    );
  };

  fetchResourceCategories = (sectionId) => {
    const { resource_category_actions } = this.props;
    this.setState(
      {
        resourceCategoryLoader: true,
      },
      () => {
        resource_category_actions.getResourceCategories(sectionId).then(() => {
          this.setState({
            resourceCategoryLoader: false,
          });
        });
      }
    );
  };

  resetResourceForm = () => {
    this.setState({
      showResourceModal: false,
      id: -1,
      name: "",
      description: "",
      video_url: "",
      button_url: "",
      uploadPhotoName: "",
      uploadPhotoFile: "",
      uploadPhotoSrc: "",
      resource_section_id: -1,
      reource_category_id: -1,
    });
  };

  resetResourceSectionForm = () => {
    this.setState({
      resource_section_name: "",
      resource_section_description: "",
      resource_section_uploadPhotoName: "",
      resource_section_uploadPhotoFile: "",
      resource_section_uploadPhotoSrc: "",
    });
  };

  resetResourceCategoryForm = () => {
    this.setState({
      resource_category_name: "",
      resource_category_description: "",
    });
  };

  resetResourceCategoryForm = () => {
    this.setState({
      resource_category_name: "",
      resource_category_description: "",
    });
  };

  createResource = () => {
    const { resource_actions } = this.props;

    this.setState(
      {
        resourceLoader: true,
      },
      () => {
        resource_actions.adminCreateResource(this.state).then(() => {
          this.setState({
            resourceLoader: false,
          });
          this.resetResourceForm();
        });
      }
    );
  };

  createResourceSection = () => {
    console.log("creating resource section");
    const { resource_section_actions } = this.props;
    const {
      resource_section_name,
      resource_section_description,
      resource_section_uploadPhotoFile,
    } = this.state;
    this.setState(
      {
        resourceSectionLoader: true,
      },
      () => {
        let resourceSection = {
          name: resource_section_name,
          description: resource_section_description,
          resource_section_photo:
            resource_section_uploadPhotoFile &&
            resource_section_uploadPhotoFile,
        };

        resource_section_actions
          .createResourceSection(resourceSection)
          .then(() => {
            this.setState({
              resourceSectionLoader: false,
            });
            this.resetResourceSectionForm();
            this.toggleForm("resource");
          });
      }
    );
  };

  createResourceCategory = () => {
    const { resource_category_actions } = this.props;
    const {
      resource_category_name,
      resource_category_description,
      resource_section_id,
    } = this.state;
    this.setState(
      {
        resourceCategoryLoader: true,
      },
      () => {
        let resourceCategory = {
          name: resource_category_name,
          description: resource_category_description,
          resource_section_id: resource_section_id,
        };

        resource_category_actions
          .createResourceCategory(resourceCategory)
          .then(() => {
            this.setState({
              resourceCategoryLoader: false,
            });
            this.resetResourceCategoryForm();
            this.toggleForm("resource");
          });
      }
    );
  };

  toggleForm = (formRendered) => {
    this.setState({
      formRendered,
    });
  };

  toggleResourceModal = () => {
    this.setState({
      showResourceModal: true,
    });
  };

  closeResourceModal = () => {
    this.setState(
      {
        showResourceModal: false,
      },
      () => {
        this.resetResourceForm();
      }
    );
  };

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSectionChange = (event) => {
    this.setState(
      {
        resource_section_id: event.target.value,
      },
      () => {
        const { resource_section_id } = this.state;
        this.fetchResourceCategories(resource_section_id);
      }
    );
  };

  handleStatefulEditorChange = (htmlString) => {
    this.setState({
      description: htmlString,
    });
  };

  grabEditorData = (description) => {
    this.setState({
      description,
    });
  };

  grabPhotoData = (photoData) => {
    const { uploadPhotoFile, uploadPhotoName, uploadPhotoSrc } = photoData;
    this.setState({
      uploadPhotoName,
      uploadPhotoFile,
      uploadPhotoSrc,
    });
  };

  grabSectionPhotoData = (photoData) => {
    const { uploadPhotoFile, uploadPhotoName, uploadPhotoSrc } = photoData;
    this.setState({
      resource_section_uploadPhotoName: uploadPhotoName,
      resource_section_uploadPhotoFile: uploadPhotoFile,
      resource_section_uploadPhotoSrc: uploadPhotoSrc,
    });
  };

  renderResourceSections = () => {
    const { resource_sections, resources } = this.props;
    const { resourceSectionLoader, searchTerm } = this.state;

    if (resourceSectionLoader) {
      return <Loader message='loading resources' />;
    }

    if (searchTerm !== "" && resources && resources.length > 0) {
      const filteredResources = resources.filter(
        createFilter(searchTerm, KEYS_TO_FILTERS)
      );
      return filteredResources.map((section) => (
        <ResourceSectionCard
          section={section}
          key={section.id}
        />
      ));
    }

    if (resource_sections && resource_sections.length > 0) {
      return resource_sections.map((section) => (
        <ResourceSectionCard
          section={section}
          key={section.id}
        />
      ));
    } else {
      return (
        <div className='col-md-6 mx-auto'>
          <Placeholder
            title={"No Resources Available"}
            icon={"fa-folder"}
            subtext1={"There are currently no resources available"}
          />
        </div>
      );
    }
  };

  renderRecentResources = () => {
    const { recent_resources } = this.props;

    if (recent_resources && recent_resources.length > 0) {
      return recent_resources.map((resource) => (
        <ResourceRow
          resource={resource}
          isRecent
          key={resource.id}
        />
      ));
    }
    return (
      <Placeholder
        title={"No Recent Activity"}
        icon={"fa-paperclip"}
        subtext1={"There has been no recent activity in the resource center"}
      />
    );
  };

  renderResources = () => {
    const { resourceLoader } = this.state;
    const { resources } = this.props;

    if (resourceLoader) {
      return <Loader message='loading resources' />;
    }

    if (resources && resources.length > 0) {
      return resources.map((resource) => (
        <ResourceRow resource={resource} key={resource.id} />
      ));
    } else {
      return (
        <Placeholder
          title={"No Resources Found"}
          icon={"fa-search"}
          subtext1={
            "We couldn't find any resources that matched the search criteria"
          }
          subtext2={"Please Try Again"}
        />
      );
    }
  };

  headerActionButtonAdmin = () => (
    <button
      className='btn btn-primary text-white pull-right mr-2'
      onClick={this.toggleResourceModal}
    >
      Add Resource
    </button>
  )

  render = () => {
    const { current_user, resource_sections, resource_categories } = this.props;
    const {
      showResourceModal,
      showReorganizeModal,
      resourceSectionLoader,
      resourceCategoryLoader,
      resourceLoader,
      searchTerm,
    } = this.state;
    return (
      <div id='ResourceSectionsPage' className='animated fadeIn'>
        <PageHeader
          title='Resource Center'
          actionComponent={current_user.type === 'Admin' && this.headerActionButtonAdmin()}
        />
        <div className='resource-search-bar mt-2'>
          <SearchInput placeholder='Search Resource Center' onChange={this.searchUpdated}/>
        </div>
        {searchTerm.length > 0 ? (
          <div className='col-md-6 mx-auto resource-search-container'>
            <h3 className='mb-4'>Search Results</h3>
            {this.renderResources()}
          </div>
        ) : (
          <div className='animated fadeIn'>
            <div className='row'>
              <div className='col-md-9'>
                {current_user &&
                  current_user.type === "Coach" &&
                  current_user.communities.length > 0 &&
                  current_user.communities[0].resource_folder_url && (
                    <div className='resource-folder-box'>
                      <div className='folder-box'>
                        <i className='fa fa-folder' aria-hidden='true' />
                        {current_user.communities[0].photo &&
                          current_user.communities[0].photo.file_url && (
                            <div
                              className='community-logo'
                              style={{
                                backgroundImage: `url(${current_user.communities[0].photo.file_url})`,
                              }}
                            />
                          )}
                      </div>
                      <div style={{ flex: "1 1" }}>
                        <p className='box-title'>
                          YOUR CUSTOM, READY-MADE RESOURCES
                        </p>
                        <p>
                          Here are the custom resources - onboarding slides,
                          flyers, etc. - that your Program Advisor designed just
                          for you during onboarding!
                        </p>
                      </div>
                      <div>
                        <a
                          className='btn'
                          href={current_user.communities[0].resource_folder_url}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          View resources
                        </a>
                      </div>
                    </div>
                  )}
                <div className='row'>{this.renderResourceSections()}</div>
              </div>
              <div className='col-md-3 recent-resource-activity-container'>
                {current_user.type === "Admin" && (
                  <button
                    className='btn-rectangular btn-rectangular-main-positive mb-4'
                    onClick={() => this.setState({ showReorganizeModal: true })}
                  >
                    Organize Sections
                  </button>
                )}
                <h3 className='mb-4'>Recent Activity</h3>
                {this.renderRecentResources()}
              </div>
            </div>
          </div>
        )}

        {showResourceModal && (
          <CreateResourceModal
            showModal={showResourceModal}
            closeModal={this.closeResourceModal}
            formState={this.state}
            handleInputChange={this.handleInputChange}
            handleSectionChange={this.handleSectionChange}
            handleStatefulEditorChange={this.handleStatefulEditorChange}
            grabPhotoData={this.grabPhotoData}
            grabSectionPhotoData={this.grabSectionPhotoData}
            createResource={this.createResource}
            createResourceSection={this.createResourceSection}
            createResourceCategory={this.createResourceCategory}
            resourceSections={resource_sections}
            resourceSectionLoader={resourceSectionLoader}
            resourceCategories={resource_categories}
            resourceCategoryLoader={resourceCategoryLoader}
            resourceLoader={resourceLoader}
            toggleForm={this.toggleForm}
            sendEditorData={this.grabEditorData}
          />
        )}

        {showReorganizeModal && (
          <ReorganizeSectionsModal
            showModal={showReorganizeModal}
            closeModal={this._closeReorganizeModal}
            sections={resource_sections}
            updateSections={this._updateSections}
          />
        )}
      </div>
    );
  };

  _closeReorganizeModal = () => {
    this.setState({ showReorganizeModal: false });
  };

  _updateSections = (sections) => {
    this.props.resource_section_actions.reorderResourceSections(sections);
    // console.log("reorganize sections action called here", sections);
  };
}
ResourceSectionsPage.propTypes = {
  current_user: PropTypes.shape({
    type: PropTypes.string,
  }),
}

function mapStateToProps(state, ownProps) {
  return {
    ui: state.ui,
    current_user: state.user.current_user,
    resources: state.resources,
    resource_sections: state.resourceSections,
    resource_categories: state.resourceCategories,
    recent_resources: state.recentResources,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    common_actions: bindActionCreators(commonActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
    resource_actions: bindActionCreators(resourceActions, dispatch),
    resource_section_actions: bindActionCreators(
      resourceSectionActions,
      dispatch
    ),
    resource_category_actions: bindActionCreators(
      resourceCategoryActions,
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourceSectionsPage);
