import PropTypes from 'prop-types'
import React from 'react'
import { getCommunityTimeInNetwork } from '../../utility/datesHelper'

import './CommunityCard.scss'
import whiteHex from '../../assets/images/common/icons/white-hex.png'
import defaultPic from '../../assets/images/mock/classRowBanner.png'
import defaultAvatar from '../../assets/images/mock/StockProfilePic.png'

const _getColor = (meters) => {
  let fifteen = 24140;
  let thirty = 48280;
  // let fifty = 80467;
  if (meters <= fifteen) {
    return "#71ce1b";
  } else if (meters <= thirty) {
    return "#fdda12";
  } else {
    return "#ec8391";
  }
};

const CommunityCard = ({ community, onClick }) => {
  let color = _getColor(community.distance.value);

  const locType = community.level === "Academy" ? 'coaching' : 'league'
  const locTypeClass = `community-card-location-type community-card-location-${locType}`;
  const [time, unit] = getCommunityTimeInNetwork(community.network_date || community.created_at)

  return (
    <div id={`CommunityCard`} onClick={() => onClick(community.id)}>
      {community.is_certified && !community.is_top50 && (
        <div className='certified-header'>
          <div
            className='white-hex'
            style={{ backgroundImage: `url(${whiteHex})` }}
          />
          Certified Location!
        </div>
      )}
      {community.is_top50 && (
        <div className='certified-header top50'>
          <div
            className='white-hex'
            style={{ backgroundImage: `url(${whiteHex})` }}
          />
          Top 50 Location!
        </div>
      )}
      <div
        className={`community-card-header ${
          community.is_certified || community.is_top50 ? "is-certified" : ""
        }`}
      >
        <div className='d-flex align-items-center'>
          <div
            className='distance-light'
            style={{ background: color, boxShadow: `0 0 4px ${color}` }}
          />
          <p>
            {community.distance.text} | {community.duration.text}
          </p>
        </div>
        <div className='community-card-types'>
          <p className='is-public'>
            {community.community_type === "Private" ? (
              <i className='fa fa-sm fa-lock' aria-hidden='true' />
            ) : (
              <i className='fa fa-sm fa-unlock' aria-hidden='true' />
            )}
            {community.community_type === "Private" ? "Private" : "Public"}
          </p>
          <p
            className={locTypeClass}
          >
            {community.level === "Academy" ? "Academy" : "Dev. League"}
          </p>
        </div>
      </div>
      <div className='community-card-container'>
        <div className='community-photo-area'>
          <div
            className='community-card-photo'
            style={{
              backgroundImage: `url(${
                community.photo ? community.photo.file_url : defaultPic
              })`,
            }}
          />
          <p className='network-time'>
            {time} {unit}
          </p>
        </div>
        <div className='community-info-area'>
          <p className='name'>{community.name}</p>
          <p className='address'>{community.address.name}</p>
        </div>
        <i className='fa fa-chevron-right' />
      </div>
      <div className='community-card-footer'>
        {community.lead_coach ? (
          <div className='lead-coach-info'>
            <div
              className='lead-coach-icon'
              style={{
                backgroundImage: `url(${
                  community.lead_coach.avatar
                    ? community.lead_coach.avatar.file_url
                    : defaultAvatar
                })`,
              }}
            />
            <p>{`${community.lead_coach.first_name} ${community.lead_coach.last_name}`}</p>
            {community.other_coach_count > 0 ? (
              <p className='more-coaches'>{`+${community.other_coach_count} More`}</p>
            ) : (
              ""
            )}
          </div>
        ) : (
          <p>0 coaches</p>
        )}
      </div>
    </div>
  );
}

CommunityCard.propTypes = {
  community: PropTypes.shape({
    network_date: PropTypes.string,
    created_at: PropTypes.string,
  }),
}

export default CommunityCard;
