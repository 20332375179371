import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { Link, NavLink } from 'react-router-dom';
import * as commonActions from '../../actions/commonActions';

import '../../assets/styles/components/common/TabBar.scss';

import ActivitySocialMenu from './ActivitySocialMenu';

import leaderboardUnselected from '../../assets/images/common/icons/leaderboard_unselected.png'
import objectivesUnselected from '../../assets/images/common/icons/objectives_unselected.png'
import profileUnselected from '../../assets/images/common/icons/profile_unselected.png'
import activityUnselected from '../../assets/images/common/icons/activity_unselected.png'

import leaderboardSelected from '../../assets/images/common/icons/leaderboard_selected.png'
import objectivesSelected from '../../assets/images/common/icons/objectives_selected.png'
import profileSelected from '../../assets/images/common/icons/profile_selected.png'
import activitySelected from '../../assets/images/common/icons/activity_selected.png'

import social from '../../assets/images/common/icons/social.png'

class TabBar extends React.Component{
  constructor(props, context){
    super(props, context);
    this.state = {};
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu(){
    if(this.props.ui.showActivityMenu){
      this.props.actions.showActivitySocialMenu(false);
    }else{
      this.props.actions.showActivitySocialMenu(true);
    }
  }

  render(){
    return(
      <div id="navBarComponent" className={"container" + ((this.props.ui.showActivityMenu) ? " menu-shown" : "")}>
        <ActivitySocialMenu selectItem={this.toggleMenu} />
        <div className="row">
          <div className="iconColumn" id="firstIcon">
            <NavLink to={'/ranks'} className={({ isActive }) => isActive ? 'active' : undefined}>
              <img src={this.props.ui.currentTab === 'Objectives' ? objectivesSelected : objectivesUnselected} className="icon" role="presentation" />
            </NavLink>
          </div>
          <div className="iconColumn" id="secondIcon">
            <NavLink to={'/activityFeed'} className={({ isActive }) => isActive ? 'active' : undefined}>
              <img src={this.props.ui.currentTab === 'Activity' ? activitySelected : activityUnselected} className="icon" role="presentation" />
            </NavLink>
          </div>
          <div className="iconColumn" id="thirdIcon" onClick={this.toggleMenu}>
            <img src={social} className="icon" role="presentation" />
          </div>
          <div className="iconColumn" id="fourthIcon">
            <NavLink to={'/leaderboard'} className={({ isActive }) => isActive ? 'active' : undefined}>
              <img src={this.props.ui.currentTab === 'Leaderboard' ? leaderboardSelected : leaderboardUnselected} className="icon" role="presentation" />
            </NavLink>
          </div>
          <div className="iconColumn" id="fifthIcon">
            <NavLink to={'/profile'} className={({ isActive }) => isActive ? 'active' : undefined}>
              <img src={this.props.ui.currentTab === 'Profile' ? profileSelected : profileUnselected} className="icon" role="presentation" />
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps){
  return {
    value: ownProps.value,
    ui: state.ui
  };
}

function mapDispatchToProps(dispatch){
  return{
    actions: bindActionCreators(commonActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TabBar);

