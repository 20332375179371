import React from "react";
import TextInput from "../common/form/TextInput";

import "./ResetPasswordEmailForm.scss";

export default class ResetPasswordEmailForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = { email: "" };

    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  onChangeEmail(event) {
    this.setState({ email: event.target.value });
  }

  submitForm(event) {
    event.preventDefault();
    this.props.handleSubmit(this.state.email);
  }

  render() {
    const { email } = this.state;
    return (
      <div>
        {!this.props.emailSent ? (
          <form id='reset-password-form' className='form-container'>
            <div className='col-md-6 mx-auto mt-5 mb-5'>
              <label>Email Or Username</label>
              <TextInput
                name='email'
                type='text'
                label=''
                onChange={this.onChangeEmail}
                placeholder='john.doe@op36.com'
                value={email}
              />
            </div>
            <div className='d-flex justify-content-center align-items-center'>
              <button
                id='submit-btn'
                onClick={this.submitForm}
                type='submit'
                className='btn btn-primary'
                disabled={email === ""}
              >
                {email !== ""
                  ? "Reset Password"
                  : "Enter Your Email or Username"}
              </button>
            </div>
          </form>
        ) : (
          <form id='reset-password-form' className='form-container'>
            <div className='col-md-6 mx-auto mt-5 mb-5'>
              <label>
                Email has been sent! Please check your inbox for link to reset
                password.
              </label>
            </div>

            <div className='d-flex justify-content-center align-items-center'>
              <button
                id='submit-btn'
                onClick={this.props.closeModal}
                type='submit'
                className='btn btn-primary'
              >
                Back To Sign In
              </button>
            </div>
          </form>
        )}
      </div>
    );
  }
}
