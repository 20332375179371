import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ModalImage from 'react-modal-image'
import Embed from 'react-tiny-oembed'

import Input from '../../../common/form/Input'
// import RichTextEditor from '../../../common/form/RichTextEditor/RichTextEditor'
import CategorySelect from './CategorySelect'
import ProgramSearchTags from './ProgramSearchTags'
import { ReactComponent as CameraIcon } from '../../../../assets/images/common/icons/programBuilder/camera-icon.svg'
import { ReactComponent as PlayVideoIcon } from '../../../../assets/images/common/icons/programBuilder/play-video-icon.svg'
import HeaderImageModal from './HeaderImageModal'
import PersonalizedVideoModal from './PersonalizedVideoModal'
import { setField } from '../../../../reducers/programBuilderReducer'
import { isFeatureEnabled } from '../../../../utility/featureEnablement'

import './Marketing.scss'

const DESCRIPTION_MAX_LENGTH = 2500

const Marketing = () => {
  const dispatch = useDispatch()

  const community = useSelector(state => state.user.current_user.communities[0])
  const errors = useSelector(state => state.programBuilder.errors)
  const description = useSelector(state => state.programBuilder.description)
  const headerImageUrl = useSelector(state => state.programBuilder.headerImageUrl)
  const personalizedVideoUrl = useSelector(state => state.programBuilder.personalizedVideoUrl)

  const [showHeaderImageModal, setShowHeaderImageModal] = useState(false)
  const [showPersonalizedVideoModal, setShowPersonalizedVideoModal] = useState(false)

  const handleInputChange = e => {
    const field = e.target.name
    const value = e.target.value

    dispatch(setField({ field, value }))
  }

  // const handleInputChange = value => {
  //   dispatch(setField({ field: 'description', value }))
  // }

  const removeHeaderImage = () => {
    dispatch(setField({ field: 'headerImage', value: 'remove' }))
    dispatch(setField({ field: 'headerImageUrl', value: '' }))
  }

  const removePersonalizedVideo = () => {
    dispatch(setField({ field: 'personalizedVideoUrl', value: '' }))
  }

  return (
    <section id='Marketing'>
      <p className='title'>Market your Program</p>
      <p>
        Get more sign ups by using these tools to promote your program. From the design of your registration page to how participants find and
        search for your program, we have you covered!
      </p>
      <div className='row'>
        <div className='col-12 col-md-7 mb-4'>
          <div className='d-flex justify-content-between align-items-start'>
            <label htmlFor='headerImage' className='mb-2'>
              Header Image
            </label>

            {headerImageUrl && (
              <span className='remove-media' aria-hidden='true' onClick={removeHeaderImage}>Remove</span>
            )}
          </div>

          {headerImageUrl ? (
            <div className='media-card-container'>
              <ModalImage
                small={headerImageUrl}
                large={headerImageUrl}
                className='header-image-preview'
                alt='Header Image'
                hideDownload
                hideZoom
              />
            </div>
          ) : (
            <div
              className='d-flex flex-column justify-content-center align-items-center media-card-container'
              aria-hidden='true'
              onClick={() => setShowHeaderImageModal(true)}
            >
              <CameraIcon className='mb-3' />

              Add header image (optional)
            </div>
          )}
        </div>

        <div className='col-12 col-md-5 mb-4'>
          <div className='d-flex justify-content-between align-items-start'>
            <label htmlFor='personalizedVideo' className='mb-2'>
              Personalized Video
            </label>

            {personalizedVideoUrl && (
              <span className='remove-media' aria-hidden='true' onClick={removePersonalizedVideo}>Remove</span>
            )}
          </div>

          {personalizedVideoUrl ? (
            <div className='media-card-container'>
              <div className='link-video-preview'>
                <Embed
                  url={personalizedVideoUrl}
                  FallbackElement=' '
                  LoadingFallbackElement=' '
                />
              </div>
            </div>
          ) : (
            <div
              className='d-flex flex-column justify-content-center align-items-center media-card-container'
              aria-hidden='true'
              onClick={() => setShowPersonalizedVideoModal(true)}
            >
              <PlayVideoIcon className='mb-2'/>

              Add a video (optional)
            </div>
          )}
        </div>

        <div className='col-12 mb-4'>
          <label htmlFor='description' className='mb-1'>
            Description:
          </label>
          <div className='d-flex justify-content-between align-items-start'>
            <p className='description-details mb-2'>
              Want some ideas? Follow this&nbsp;
              <a
                href='https://docs.google.com/document/d/1-tLDNN23akDFzqaiDJZdli7_3N_EAtr8d8VZ22dP4xQ/edit#heading=h.i4ky487qda69'
                className='description-examples-link'
                target='_blank'
              >
                link
              </a>
              &nbsp;for program description templates.
            </p>
            <p className='character-counter m-0'>
              {`${description.length}/${DESCRIPTION_MAX_LENGTH} characters`}
            </p>
          </div>

          <Input
            name='description'
            type='textarea'
            onChange={handleInputChange}
            placeholder='Write a description'
            value={description}
            error={errors.description}
            maxLength={DESCRIPTION_MAX_LENGTH}
            styles={{ inputContainer: { height: '220px' } }}
          />
          {/* <RichTextEditor
            value={description}
            onChange={handleInputChange}
            placeholder='Write a description'
            error={errors.description}
          /> */}
        </div>

        <div className='col-12 mb-4'>
          <label htmlFor='tags' className='mb-1'>
            Community Landing Page Categories
          </label>
          <p className='tags-details mb-2'>
            Organize this program into a customized category on your community landing page, so students can easily find the right program.
          </p>

          <CategorySelect />
        </div>

        <div className='col-12 mb-4'>
          <label htmlFor='tags' className='mb-1'>
            Program Search Tags
          </label>
          <p className='tags-details mb-2'>
            Tags allow your students to find programs specific to their needs on your landing page. Participants can also search for these programs
            in our Global Marketplace if your program is public.
          </p>

          <ProgramSearchTags />
        </div>
      </div>

      <HeaderImageModal
        showModal={showHeaderImageModal}
        closeModal={() => { setShowHeaderImageModal(false) }}
      />

      <PersonalizedVideoModal
        showModal={showPersonalizedVideoModal}
        closeModal={() => { setShowPersonalizedVideoModal(false) }}
      />
    </section>
  )
}

export default Marketing
