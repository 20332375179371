import React from "react";
import PropTypes from 'prop-types'

import "./SmallMilestoneIcon.scss";

const findBorderColor = div => {
  switch (div) {
    case 1:
    case 6:
      return '#FF6633'
    case 2:
    case 7:
      return "#FDDB10";
    case 3:
    case 8:
      return "#D5DE27";
    case 4:
    case 9:
      return "#2BAAE1";
    case 5:
    case 10:
    case 11:
      return "#B51E8C";
    default:
      return "#414143";
  }
};

// http://brenna.github.io/csshexagon/

// takes optional props of color and bgColor --> if not provided, will default to their current division

const SmallMilestoneIcon = props => {
  let borderColor = props.color || findBorderColor(props.division);
  let bgColor = props.bgColor || props.division < 6 ? "#fff" : "#1d2933";

  const getComponentId = () => {
    if (props.fromSticky) {
      return 'StickySmallMilestoneIcon'
    } else if (props.fromAvatar) {
      return 'AvatarSmallMilestoneIcon'
    } else {
      return 'SmallMilestoneIcon'
    }
  }

  return (
    <div
      role='button'
      tabIndex={0}
      onClick={props.onClick ? props.onClick : (() => {})}
      onKeyDown={null}
      id={getComponentId()}
    >
      <div
        style={{
          backgroundColor: bgColor,
          borderTop: `solid ${props.fromAvatar ? '2.82845px' : '5.6569px' } ${borderColor}`,
          borderRight: `solid ${props.fromAvatar ? '2.82845px' : '5.6569px'} ${borderColor}`,
        }}
        className='hexagon-before'
      />
      <div
        style={{
          borderLeft: `solid ${props.fromAvatar ? '2px' : '4px' } ${borderColor}`,
          borderRight: `solid ${props.fromAvatar ? '2px' : '4px' } ${borderColor}`,
          backgroundColor: bgColor
        }}
        className='hexagon text-center'
      >
        <p style={{ color: borderColor }}>{props.division <= 10 ? props.division : 10}</p>
      </div>
      <div
        style={{
          backgroundColor: bgColor,
          borderBottom: `solid ${props.fromAvatar ? '2.82845px' : '5.6569px' } ${borderColor}`,
          borderLeft: `solid ${props.fromAvatar ? '2.82845px' : '5.6569px'} ${borderColor}`,
        }}
        className='hexagon-after'
      />
    </div>
  );
};

SmallMilestoneIcon.propTypes = {
  division: PropTypes.number,
  fromAvatar: PropTypes.bool,
  fromSticky: PropTypes.bool,
}

export default SmallMilestoneIcon;
