import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet } from 'react-router-dom'

import { reset } from '../../reducers/communityLandingPageReducer'

const CommunityLandingPageWrapper = () => {
  const dispatch = useDispatch()

  useEffect(() => () => {
    dispatch(reset())
  }, [dispatch])

  return <Outlet />
}

export default CommunityLandingPageWrapper
