import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import Modal from 'react-modal'

import { deserialize } from '../../../../utility/serialization'
import { programPresentationData, programPresentationTabs } from '../../../../reducers/programBuilderReducer'
import { setProgram } from '../../../../reducers/programPresentationReducer'
import ProgramPresentationPage from '../../ProgramLanding/ProgramPresentationPage/ProgramPresentationPage'
import Loader from '../../../common/Op36Loader-web'

import styles from './PreviewModal.module.scss'

const PreviewModal = ({ showModal, closeModal, onSaveProgram, setShowSaveModal, loader }) => {
  const dispatch = useDispatch()

  const community = useSelector(state => state.user.current_user.communities[0])
  const programData = useSelector(programPresentationData)
  const programTabs = useSelector(programPresentationTabs)
  const previewMode = useSelector(state => state.programPresentation.course.previewMode)
  const {
    id,
    isDraft,
    enableRegistrationDates,
    registrationStartsAt,
    registrationEndsAt,
    enableProgramCapacity,
    spotsAvailable,
    enrollments_count,
  } = useSelector(state => state.programBuilder)

  const computeRegistrationClosedReason = useMemo(() => {
    if (enableRegistrationDates && registrationStartsAt && registrationEndsAt &&
      !moment().tz(community.timezone).isBetween(registrationStartsAt, registrationEndsAt)
    ) {
      return 'registration_closed'
    } else if (enableProgramCapacity && spotsAvailable && (spotsAvailable - enrollments_count <= 0)) {
      return 'limit_reached'
    }
  }, [enableRegistrationDates, registrationStartsAt, registrationEndsAt, enableProgramCapacity, spotsAvailable, enrollments_count, community])

  useEffect(() => {
    dispatch(setProgram({
      program: {
        ...programData,
        community: deserialize(community),
        registrationClosedReason: computeRegistrationClosedReason,
      },
      detailsTabs: programTabs,
    }))
  }, [programData, community, computeRegistrationClosedReason, programTabs, dispatch])

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={closeModal}
      contentLabel='Preview Program'
      className={styles['preview-program-modal-content']}
      autoFocus
    >
      <section className='d-flex flex-column h-100'>
        {!previewMode
          ? <Loader message='Loading preview' />
          : <>
            <div className={styles['preview-header']}>
              <span className={`d-flex justify-content-center align-items-center w-100 h-100 ${styles['header-title']}`}>THIS IS A PREVIEW</span>
            </div>

            <ProgramPresentationPage />

            <div className={`row ${styles['preview-footer']}`}>
              <div className={`${styles['action-button']} col-12 col-sm-6 d-flex justify-content-center align-items-center`}>
                <button className={`btn btn-primary ml-sm-auto ${styles['back-button']}`} onClick={closeModal}>
                  Back to editing
                </button>
              </div>

              <div className={`${styles['action-button']} col-12 col-sm-6 d-flex justify-content-center align-items-center`}>
                <button
                  className={`btn btn-primary mr-sm-auto ${styles['save-button']}`}
                  onClick={(id && !isDraft) ? () => onSaveProgram(false) : () => { setShowSaveModal(true); closeModal() }}
                  disabled={loader}
                >
                  {(id && !isDraft) ? 'Save' : 'Save or Publish'}
                </button>
              </div>
            </div>
          </>
        }
      </section>
    </Modal>
  )
}

PreviewModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSaveProgram: PropTypes.func.isRequired,
  setShowSaveModal: PropTypes.func.isRequired,
  loader: PropTypes.bool.isRequired,
}

export default PreviewModal
