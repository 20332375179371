import React from "react";

import "./ChallengeDestroy.scss";

import ChallengeDetailCard from "../subcomponents/ChallengeDetailCard";

class ChallengeDestroy extends React.Component {
  state = {
    deniedModal: false,
    secretPassword: "",
    destroyClicked: false
  };
  render() {
    const { selectedChallenge, returnToIndex, goToShow } = this.props;
    return (
      <div id='ChallengeDestroy'>
        {this.state.deniedModal && <div className='deniedModal'>✕</div>}
        <div className='return-button-container'>
          <button className='return-challenge-button' onClick={returnToIndex}>
            Back to Challenges
          </button>
        </div>
        <ChallengeDetailCard
          selectedChallenge={selectedChallenge}
          goToEdit={false}
          goToShow={goToShow}
          handleDestroy={this._destroyClick}
          secretPassword={this.state.secretPassword}
          handleInputChange={this._handleInputChange}
          destroyClicked={this.state.destroyClicked}
        />
      </div>
    );
  }

  _handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  _destroyClick = word => {
    const { handleDestroyChallenge, selectedChallenge } = this.props;
    if (!word) {
      return false;
    }
    if (word !== "derekisawesome") {
      this.setState({ deniedModal: true }, () => {
        setTimeout(this.props.returnToIndex, 2000);
      });
    } else {
      this.setState({ destroyClicked: true }, () => {
        handleDestroyChallenge(selectedChallenge.id);
      });
    }
  };
}

export default ChallengeDestroy;
