/* eslint-disable react/prop-types */
/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as commonActions from '../../../actions/commonActions'
import * as userActions from '../../../actions/userActions'
import * as drillActions from '../../../actions/drillActions'
import * as subjectActions from '../../../actions/subjectActions'
import * as tagActions from '../../../actions/tagActions'
import * as practiceTypesActions from '../../../actions/practiceTypeActions'
import * as lessonPlanActions from '../../../actions/lessonPlanActions'

import LessonPlanBuilderProgressBar from './LessonPlanBuilderProgressBar'
import LessonBasicDetails from './builderViews/LessonBasicDetails'
import LessonCreateSchedule from './builderViews/LessonCreateSchedule'
import LessonReviewPublish from './builderViews/LessonReviewPublish'
import LessonPlanActionModal from '../modals/LessonPlanActionModal'
import TwoButtonModal from '../modals/TwoButtonModal'
import CurriculumDetailModal from '../modals/CurriculumDetailModal'
// import DrillDetailModal from "../modals/DrillDetailModal";
import DrillDetailModal from '../../drills/modals/DrillDetailModal'

import blankIcon from '../../../assets/images/common/icons/lesson_plans/blank-template.png'
import homeIcon from '../../../assets/images/common/icons/lesson_plans/house.png'
import paperclipIcon from '../../../assets/images/common/icons/lesson_plans/paper-clip.png'

import './LessonPlanBuilderPage.scss'
import Loader from '../../common/Op36Loader-web'
import { withRouting } from '../../common/hooks'

class LessonPlanBuilderPage extends React.Component {
  state = {
    page: 1, // controlls the view of the builder. 1) Add Basic Details, 2) Create Schedule, 3) Review and Publish
    pageProgress: 1, // keeps track of the furthest the user got through the flow so they can jump around using the top arrow thing
    discardModal: false,
    defaultDuration: 60, // starting default duration for sessions => set on page 1
    lessonPlanName: '',
    tagInput: '', // current state of tag input
    textTags: [], // list of tags pushed from into by hitting enter
    notes: '', // notes on the lesson plan itself
    video_url: '', // only admins can add this - video describing the template they're building
    uploadPhotoName: '', // image stuff
    uploadPhotoFile: '', // image stuff
    uploadPhotoFileSrc: '', // image stuff
    failedValidation: [], // array of strings to pinpoint what to do if validation fails. Gets reset on every step
    sessions: [
      // here's what sessions will look like. One is added if you don't have any when the page is rendered.
      // {
      //   name: 'Session 1',
      //   duration: 60 or whatever you have entered as 'default duration',
      //   notes: '',
      //   blocks: [{ name: 'Block 1', duration: 10, description: '', tasks: [] }]
      // }
    ],
    myDrillsLoader: true, // loader for incoming drills
    myActivitiesLoader: true,
    publicDrillsLoader: true,
    publicActivitiesLoader: true,
    practiceTypesLoader: true,
    subjectLoader: true, // loader for incoming subjects (curriculum obejectives)
    curriculumModal: false, // deftail modal for clicking on curriculum
    selectedSubject: {}, // holds details of what user clicked
    // drillModal: false, // deftail modal for clicking on activity/drill
    // selectedDrill: {}, // holds details of what user clicked
    selectedDrillId: -1,
    isEdit: false, // set if url params are passed = will handle publishing differently
    // this is set if you're editing and will say 'draft' if you're editing a draft or 'published' if you're editing a real lesson plan
    editType: 'none',
    lessonPlanLoader: true, // if this is an edit, need to retrieve current info from api - this will be loader for that
    tagLoader: true, // loader for incoming tags to show as potential filters
    saveDraftConfirm: false, // modal confirming that you want to save your draft
    failedDraftValidation: false, // checks if lesson plan has a name before saving draft
    savingDraft: false, // show something happening (loader) while draft is being saved
    draftSaved: false, // modal once draft is saved and moves you back to home page
    publishModal: false, // gives you options before actually saving
    savedModal: false, // will show saving status and confirm saved
    // publishing: false, // true when in process of saving
    published: false, // lesson plan saved
  }

  componentDidMount() {
    this.props.common_actions.showNavBar(false)
    this.props.common_actions.showSideBar(false)
    // this.props.drill_actions
    //   .getLessonPlanDrills()
    //   .then(() => this.setState({ drillLoader: false }));

    this.props.drill_actions.getMyDrills(null, 1, 20).then(() => {
      // this grabs the current user's drills
      this.setState({ myDrillsLoader: false })
    })

    this.props.drill_actions.getMyActivities(null, 1, 20).then(() => {
      // this grabs the current user's activities
      this.setState({ myActivitiesLoader: false })
    })

    this.props.drill_actions
      .getPublicDrills({ type: 'Drill' }, 1, 20)
      .then(() => {
        this.setState({ publicDrillsLoader: false })
      })

    this.props.drill_actions
      .getPublicActivities({ type: 'Coaching Activity' }, 1, 20)
      .then(() => {
        this.setState({ publicActivitiesLoader: false })
      })

    this.props.subject_actions
      .getAllSubjects()
      .then(() => this.setState({ subjectLoader: false }))

    this.props.tag_actions
      .getTags()
      .then(() => this.setState({ tagLoader: false }))

    this.props.practice_types_actions.getPracticeTypes().then(() => {
      this.setState({ practiceTypesLoader: false })
    })

    if (Object.keys(this.props.params).length !== 0) {
      this.setState({ lessonPlanLoader: true }, () => {
        this.props.lessonPlan_actions
          .grabOneLessonPlan(parseInt(this.props.params.lessonPlanId, 10), true)
          .then(() => {
            this._readLessonPlan(this.props.params)
          })
      })
    } else {
      this.setState({ lessonPlanLoader: false })
    }
  }

  _readLessonPlan = (params) => {
    const { lesson_plan } = this.props
    if (!lesson_plan) {
      return this.props.navigate('/lesson-plan')
    }
    const admin = this.props.user.type === 'Admin'
    if (
      // users cannot edit lesson plans that don't belong to them
      !admin &&
      lesson_plan.user_id !== this.props.user.id &&
      params.isEdit === 'edit'
    ) {
      return this.props.navigate('/lesson-plan')
    }
    if (lesson_plan.is_draft) {
      const sessions = JSON.parse(lesson_plan.draft_sessions)
      this.setState(
        {
          isEdit: params.isEdit === 'edit',
          editType: 'draft',
          lessonPlanName:
            params.isEdit === 'edit'
              ? lesson_plan.name
              : lesson_plan.is_template
                ? lesson_plan.name
                : 'Copy | ' + lesson_plan.name,
          textTags: lesson_plan.tags,
          defaultDuration: lesson_plan.duration,
          notes: lesson_plan.notes,
          sessions: sessions,
          uploadPhotoFileSrc:
            lesson_plan.photo && params.isEdit === 'edit'
              ? lesson_plan.photo.file_url
              : lesson_plan.template_photo_url && params.isEdit === 'edit'
                ? lesson_plan.template_photo_url
                : '',
          video_url: admin ? lesson_plan.video_url || '' : '',
        },
        () => {
          this.setState({ lessonPlanLoader: false })
        }
      )
    } else {
      const sessions = JSON.parse(lesson_plan.draft_sessions)
      this.setState(
        {
          isEdit: params.isEdit === 'edit',
          editType: 'published',
          lessonPlanName:
            params.isEdit === 'edit'
              ? lesson_plan.name
              : lesson_plan.is_template
                ? lesson_plan.name
                : 'Copy | ' + lesson_plan.name,
          textTags: lesson_plan.tags,
          defaultDuration: lesson_plan.duration,
          notes: lesson_plan.notes,
          sessions: sessions,
          uploadPhotoFileSrc:
            lesson_plan.photo && params.isEdit === 'edit'
              ? lesson_plan.photo.file_url
              : lesson_plan.photo && lesson_plan.is_template && !admin
                ? lesson_plan.photo.file_url
                : lesson_plan.template_photo_url && params.isEdit === 'edit'
                  ? lesson_plan.template_photo_url
                  : '',
          video_url: admin ? lesson_plan.video_url || '' : '',
        },
        () => {
          this.setState({ lessonPlanLoader: false })
        }
      )
    }
  }

  render() {
    if (window.$zopim && window.$zopim.livechat) {
      window.$zopim.livechat.hideAll()
    }
    if (this.state.lessonPlanLoader) {
      if (this.props.params.isEdit === 'edit') {
        return <Loader message={'grabbing lesson plan'} />
      } else {
        return <Loader message={'creating template'} />
      }
    }

    return (
      <div id='LessonPlanBuilderPage'>
        <div className='lesson-builder-topnav cond-hid'>
          <div className='row'>
            <div className='col-sm-4'>
              <h3>Lesson Plan Builder</h3>
            </div>
            <LessonPlanBuilderProgressBar
              page={this.state.page}
              handleClick={this._handleProgressBarClick}
              pageProgress={this.state.pageProgress}
            />
          </div>
        </div>
        <div className='builder-content-area cond-hid'>
          {this._renderContent(this.state.page)}
        </div>
        <div className='lesson-builder-footer-container cond-hid'>
          <button
            className='lesson-plan-builder-action-btn lp-btn-secondary'
            onClick={this._handleDiscardClick}
          >
            Discard Changes
          </button>
          <div className='good-button-box d-flex align-items-center'>
            <button
              className='lesson-plan-builder-action-btn lp-btn-secondary'
              onClick={this._handleSaveDraftClick}
            >
              Save Draft
            </button>
            <button
              className='lesson-plan-builder-action-btn lp-btn-primary'
              onClick={this._handleNextClick}
            >
              {this.state.page < 3 ? 'Next' : this._checkPublishWording()}
            </button>
          </div>
        </div>
        <div className='w-100 h-100 cond-show p-5'>
          <p className='text-center'>
            Your screen is too small to use this feature. Please log in on a
            computer and make sure your window is maximized.
          </p>
          <button
            className='btn-rectangular btn-rectangular-main'
            onClick={this._handleDiscard}
          >
            Back to Lesson Plans
          </button>
        </div>
        {this.state.discardModal && (
          <TwoButtonModal
            title={'Discard draft?'}
            subtitle={
              'If you discard this draft, all your progress so far will be lost! This cannot be undone.'
            }
            close={this._closeDiscardModal}
            confirmText={'Discard draft'}
            confirmAction={this._handleDiscard}
          />
        )}
        {this.state.curriculumModal && (
          <CurriculumDetailModal
            subject={this.state.selectedSubject}
            close={this._closeCurriculumModal}
          />
        )}

        {this.state.selectedDrillId > 0 && (
          // <DrillDetailModal
          //   drill={this.state.selectedDrill}
          //   close={this._closeDrillModal}
          // />
          <DrillDetailModal
            drillId={this.state.selectedDrillId}
            currentUser={this.props.user}
            close={this._closeDrillModal}
            drillsPage={false}
            handlePin={() => false}
            handleFavorite={() => false}
            currentPage={'lessonPlan'}
          />
        )}
        {this.state.saveDraftConfirm &&
          (this.state.failedDraftValidation ? (
            <div className='failed-draft-validation-container'>
              <div className='grey-out' onClick={this._closeDraftConfirm} onKeyDown={this._closeDraftConfirm} role='button' tabIndex={0}/>
              <div className='failed-draft-validation'>
                <p className='failed-draft-validation-title'>Oops!</p>
                <p className='failed-draft-validation-text'>
                  This lesson plan needs a name before it can be saved as a
                  draft
                </p>
                <button
                  className='ok-close-modal-button'
                  onClick={this._closeDraftConfirm}
                >
                  Ok
                </button>
              </div>
            </div>
          ) : (
            <TwoButtonModal
              title={'Save draft?'}
              subtitle={
                this.state.isEdit
                  ? this._getDraftText()
                  : 'You can revisit this lesson plan to publish later.'
              }
              close={this._closeDraftConfirm}
              confirmText={'Save draft'}
              confirmAction={this._handleDraftSave}
            />
          ))}
        {this.state.savingDraft && (
          <div className='lp-save-modal-container'>
            <div className='hard-grey-out' />
            {this.state.draftSaved ? (
              <div className='lp-save-modal'>
                <p className='save-draft-title'>Draft Saved!</p>
                <p className='save-draft-text'>
                  When you are ready to come back, you can find this draft in
                  the &apos;Drafts&apos; tab of your Lesson Plan homepage
                </p>
                <button
                  className='return-to-homepage-btn'
                  onClick={this._returnHome}
                >
                  Return to homepage
                </button>
              </div>
            ) : (
              <div className='lp-save-modal'>
                <i className='fa fa-spinner fa-spin' aria-hidden='true' />
                <p>Saving now</p>
              </div>
            )}
          </div>
        )}
        {this.state.publishModal && (
          <LessonPlanActionModal
            title={`Ready to ${
              this.state.editType === 'published' ? 'Update' : 'Publish'
            }!`}
            subtitle={'What would you like to do next?'}
            close={this._closePublishModal}
            links={[
              {
                onClick: () => this._publishAndHome(true), // by passing in 'true' here it will override where you're sent once the LP saves
                name: 'Attach to class',
                icon: paperclipIcon,
              },
              {
                onClick: this._createAnother,
                name: 'Create another Lesson Plan',
                icon: blankIcon,
              },
              {
                onClick: () => this._publishAndHome(), // passing in nothing doesn't override router push, and you'll go back to lesson plan page
                name: 'Return to homepage',
                icon: homeIcon,
              },
            ]}
          />
        )}
        {this.state.savedModal && (
          <div className='lp-save-modal-container'>
            <div className='hard-grey-out' />
            <div className='lp-save-modal'>
              {this.state.published ? (
                <i className='fa fa-check-circle' aria-hidden='true' />
              ) : (
                <i className='fa fa-spinner fa-spin' aria-hidden='true' />
              )}
              {this.state.published ? 'Saved!' : 'Saving Now'}
            </div>
          </div>
        )}
        {((this.state.page === 2 && this.state.failedValidation.length > 0) ||
          (this.state.failedValidation.length > 0 &&
            !this.state.failedValidation.includes('name'))) && (
          <div className='sessions-failed-validation-modal-container'>
            <div
              className='grey-out-validation'
              onClick={this._closeValidationModal}
              tabIndex={0}
              role='button'
              onKeyDown={this._closeValidationModal}
            />
            <div className='sessions-failed-validation-modal'>
              <p className='validation-modal-title'>Oops!</p>
              {this._renderValidationText(this.state.failedValidation)}
              {this.state.page === 2 ? (
                <button
                  onClick={this._closeValidationModal}
                  className='failed-validation-btn'
                >
                  Ok
                </button>
              ) : (
                <div className='validation-modal-button-group'>
                  <button
                    onClick={this._closeValidationModal}
                    className='validation-modal-cancel-btn'
                  >
                    Cancel
                  </button>
                  <button
                    className='validation-modal-nav-btn'
                    onClick={() =>
                      this.setState({ page: 2, failedValidation: [] })
                    }
                  >
                    Go to sessions
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    )
  }

  _checkPublishWording = () => {
    const { isEdit, editType } = this.state
    if (!isEdit || editType === 'draft') {
      return 'Publish'
    }
    return 'Update'
  }

  _createAnother = () => {
    this.setState({ publishModal: false, savedModal: true }, () => {
      const data = {
        user_id: this.props.user.id,
        name: this.state.lessonPlanName,
        duration: this.state.defaultDuration,
        notes: this.state.notes,
        tags: JSON.stringify(this.state.textTags),
        is_draft: false,
        draft_sessions: JSON.stringify(this.state.sessions),
        video_url: this.state.video_url,
      }
      if (this.state.uploadPhotoFile) {
        data.photo = this.state.uploadPhotoFile
      }
      if (this.state.isEdit) {
        if (!this.state.uploadPhotoFileSrc) {
          data.photo = 'remove'
        }
        this.props.lessonPlan_actions
          .updateDraftToDraft(
            parseInt(this.props.params.lessonPlanId, 10),
            data
          )
          .then(() => {
            this.setState({ publishing: false, published: true }, () => {
              setTimeout(this._restartBuilder, 2000)
            })
          })
      } else {
        if (
          this.state.editType === 'published' &&
          this.props.lesson_plan &&
          this.props.lesson_plan.is_template &&
          this.props.user.type !== 'Admin' &&
          this.state.uploadPhotoFileSrc &&
          !this.state.uploadPhotoFile
        ) {
          // "this should be a coach copying a template without switching the photo"
          data.template_photo_id = this.props.lesson_plan.id
        }
        data.is_pinned = false
        this.props.lessonPlan_actions.saveNewLessonPlanDraft(data).then(() => {
          this.setState({ publishing: false, published: true }, () => {
            setTimeout(this._restartBuilder, 2000)
          })
        })
      }
    })
  }

  _publishAndHome = (programOverride) => {
    this.setState({ publishModal: false, savedModal: true }, () => {
      const data = {
        user_id: this.props.user.id,
        name: this.state.lessonPlanName,
        duration: this.state.defaultDuration,
        notes: this.state.notes,
        tags: JSON.stringify(this.state.textTags),
        is_draft: false,
        draft_sessions: JSON.stringify(this.state.sessions),
        video_url: this.state.video_url,
      }
      if (this.state.uploadPhotoFile) {
        data.photo = this.state.uploadPhotoFile
      }
      if (this.state.isEdit) {
        if (!this.state.uploadPhotoFileSrc) {
          data.photo = 'remove'
        }
        this.props.lessonPlan_actions
          .updateDraftToDraft(
            parseInt(this.props.params.lessonPlanId, 10),
            data
          )
          .then(() => {
            this.setState({ publishing: false, published: true }, () => {
              setTimeout(
                () =>
                  this.props.navigate(
                    programOverride ? '/programs' : '/lesson-plan'
                  ),
                2000
              )
            })
          })
      } else {
        if (
          this.state.editType === 'published' &&
          this.props.lesson_plan &&
          this.props.lesson_plan.is_template &&
          this.props.user.type !== 'Admin' &&
          this.state.uploadPhotoFileSrc &&
          !this.state.uploadPhotoFile
        ) {
          //"this should be a coach copying a template without switching the photo"
          data.template_photo_id = this.props.lesson_plan.id
        }
        data.is_pinned = false
        this.props.lessonPlan_actions.saveNewLessonPlanDraft(data).then(() => {
          this.setState({ publishing: false, published: true }, () => {
            setTimeout(
              () =>
                this.props.navigate(
                  programOverride ? '/programs' : '/lesson-plan'
                ),
              2000
            )
          })
        })
      }
    })
  }

  _handleDraftSave = () => {
    if (!this.state.lessonPlanName) {
      this.setState({ failedDraftValidation: true })
    } else {
      this.setState({ saveDraftConfirm: false, savingDraft: true }, () => {
        const data = {
          user_id: this.props.user.id,
          name: this.state.lessonPlanName,
          duration: this.state.defaultDuration,
          notes: this.state.notes,
          tags: JSON.stringify(this.state.textTags),
          is_draft: true,
          is_pinned: false,
          draft_sessions: JSON.stringify(this.state.sessions),
          video_url: this.state.video_url,
        }
        if (this.state.uploadPhotoFile) {
          data.photo = this.state.uploadPhotoFile
        }

        if (this.state.isEdit) {
          if (!this.state.uploadPhotoFileSrc) {
            data.photo = 'remove'
          }
          // if (this.state.editType === "published") {
          // =======================================================
          // =======================================================
          // =======================================================
          // ADD SOMETHING TO CHECK IF YOU'RE SAVING A PUBLISHED LP TO DRAFT => **don't worry about this unless
          // lesson plan table is changed to NOT house sessions in json column**
          // =======================================================
          // =======================================================
          // =======================================================
          // } else if (this.state.editType === "draft") {
          this.props.lessonPlan_actions
            .updateDraftToDraft(
              parseInt(this.props.params.lessonPlanId, 10),
              data
            )
            .then(() => {
              this.setState({ draftSaved: true })
            })
          // }
        } else {
          if (
            this.state.editType === 'published' &&
            this.props.lesson_plan &&
            this.props.lesson_plan.is_template &&
            this.props.user.type !== 'Admin' &&
            this.state.uploadPhotoFileSrc &&
            !this.state.uploadPhotoFile
          ) {
            //"this should be a coach copying a template without switching the photo"
            data.template_photo_id = this.props.lesson_plan.id
          }
          this.props.lessonPlan_actions
            .saveNewLessonPlanDraft(data)
            .then(() => {
              this.setState({ draftSaved: true })
            })
        }
      })
    }
  }

  _restartBuilder = () => {
    if (Object.keys(this.props.params).length !== 0) {
      this.props.navigate('/lesson-plan/builder')
    }
    this.setState({
      page: 1,
      pageProgress: 1,
      discardModal: false,
      defaultDuration: 60,
      lessonPlanName: '',
      tagInput: '',
      textTags: [],
      notes: '',
      video_url: '',
      uploadPhotoName: '',
      uploadPhotoFile: '',
      uploadPhotoFileSrc: '',
      failedValidation: [],
      sessions: [],
      drillLoader: false,
      subjectLoader: false,
      curriculumModal: false,
      selectedSubject: {},
      drillModal: false,
      selectedDrill: {},
      isEdit: false,
      editType: 'none',
      lessonPlanLoader: false,
      tagLoader: false,
      saveDraftConfirm: false,
      failedDraftValidation: false,
      savingDraft: false,
      draftSaved: false,
      publishModal: false,
      savedModal: false,
      published: false,
    })
  }

  _getDraftText = () => {
    const { editType } = this.state
    switch (editType) {
      case 'draft':
        return "This draft will be updated. You won't be able to revert this change."
      case 'published':
        return 'This Lesson Plan will be reverted to a draft and will no longer be published.'
      default:
        return 'You can revisit this lesson plan to publish later.'
    }
  }

  _returnHome = () => {
    this.props.navigate('/lesson-plan')
  }

  _handleSaveDraftClick = () => {
    this.setState({ saveDraftConfirm: true })
  }

  _closeDraftConfirm = () => {
    this.setState({ saveDraftConfirm: false, failedDraftValidation: false })
  }

  _addTaskToBlock = (result, sessionIndex) => {
    if (!result.destination) {
      return false
    }
    const blockArray = result.destination.droppableId.split('-')
    const blockIndex = blockArray[blockArray.length - 1]
    const taskType = result.draggableId.split('_')[0]
    const taskId = result.draggableId.split('_')[1]
    const { sessions } = this.state
    const sessionsCopy = [...sessions]

    sessionsCopy[sessionIndex].blocks[blockIndex].tasks.push({
      type: taskType,
      id: taskId,
    })

    this.setState({ sessions: sessionsCopy })
  }

  _removeTaskFromBlock = (block, taskIndex, session) => {
    const { sessions } = this.state
    const sessionsCopy = [...sessions]
    const sessionTasks = [...sessions[session].blocks[block].tasks]
    const filteredTasks = sessionTasks.filter(
      (task, index) => taskIndex !== index
    )
    sessionsCopy[session].blocks[block].tasks = filteredTasks
    this.setState({ sessions: sessionsCopy })
  }

  _onChangePhoto = (event) => {
    if (event.target.value) {
      this.setState({
        uploadPhotoName: event.target.value,
        uploadPhotoFile: event.target.files[0],
      })
      const reader = new window.FileReader()
      const page = this
      reader.onload = function (e) {
        page.setState({
          uploadPhotoFileSrc: e.target.result,
        })
      }
      reader.readAsDataURL(event.target.files[0])
    }
  }

  _clearPhoto = () => {
    this.setState({
      uploadPhotoName: '',
      uploadPhotoFile: '',
      uploadPhotoFileSrc: '',
    })
  }

  _handleProgressBarClick = (target) => {
    const { page } = this.state
    if (target > page) {
      let failedValidation = []
      for (let i = page; i < target; i++) {
        failedValidation = this._checkValidation(i)
        if (failedValidation.length !== 0) {
          break
        }
      }
      if (failedValidation.length === 0) {
        this.setState({ page: target, failedValidation: [] })
      } else {
        this.setState({ failedValidation: failedValidation })
      }
    } else {
      this.setState({ page: target })
    }
  }

  // moves on to the next section until you're at the end
  _handleNextClick = () => {
    const { page, pageProgress } = this.state
    if (page < 3) {
      const failedValidation = this._checkValidation(page)
      if (failedValidation.length === 0) {
        if (page + 1 > pageProgress) {
          this.setState({
            pageProgress: page + 1,
            page: page + 1,
            failedValidation: [],
          })
        } else {
          this.setState({ page: page + 1, failedValidation: [] })
        }
      } else {
        this.setState({ failedValidation: failedValidation })
      }
    } else {
      this.setState({ publishModal: true })
    }
  }

  _closePublishModal = () => {
    this.setState({ publishModal: false })
  }

  _checkValidation = (page) => {
    const failArray = []
    if (page === 1) {
      // only validation check here is for the lesson plan name
      if (!this.state.lessonPlanName) {
        failArray.push('name')
      }
    }
    if (page === 2) {
      // checking for 4 things (in order... order is determined by how text is rendered). 1) Does the lesson plan have any sessions?
      // 2) Do each of those sessions have names? 3) Are there any sessions with zero blocks? 4) Duration of session and blocks match up
      const { sessions } = this.state
      if (sessions.length < 1) {
        failArray.push('sessions')
      }
      sessions.forEach((session, i) => {
        if (session.blocks.length < 1) {
          failArray.push(`blocks_${session.name}`)
        }
        if (!session.name) {
          failArray.push(`sessionName_${i}`)
        }

        const sessionDuration = parseInt(session.duration, 10)
        let blocksDuration = 0
        session.blocks.map((block) => {
          const duration = parseInt(block.duration, 10)
          if (duration > 0) {
            blocksDuration += duration
          }
        })
        if (sessionDuration !== blocksDuration) {
          failArray.push(
            `duration_${session.name}_?duration-diff_${
              sessionDuration - blocksDuration
            }`
          )
        }
        return blocksDuration > sessionDuration
      })
    }
    return failArray
  }

  _renderValidationText = (val) => {
    if (val.includes('sessions')) {
      return (
        <p className='failed-validation-text'>
          Looks like your lesson plan,{' '}
          <span className='empty-sessions'>{this.state.lessonPlanName}</span>,
          doesn&apos;t have any sessions. You&apos;re unable to move forward with an empty
          lesson plan.
        </p>
      )
    }

    const namelessSessionCount = val.filter(
      (b) => b.indexOf('sessionName_') === 0
    ).length
    if (namelessSessionCount > 0) {
      return (
        <p className='failed-validation-text'>
          You have{' '}
          <span className='empty-sessions'>{namelessSessionCount}</span> session
          {namelessSessionCount > 1 ? 's' : ''} without a name. Each session
          must have a name to move forward.
        </p>
      )
    }

    const emptySessions = val
      .filter((b) => b.indexOf('blocks_') === 0)
      .map((b) => b.substring(7))

    if (emptySessions.length > 0) {
      return (
        <p className='failed-validation-text'>
          You have some empty sessions. Each session needs at least one block in
          order to move forward. The following sessions are empty:{' '}
          <span className='empty-sessions'>{emptySessions.join(', ')}</span>
        </p>
      )
    }

    const durationMismatch = val
      .filter((b) => b.indexOf('duration_') === 0)
      .map((b) => {
        // use something weird here so there's no change that it will appear in the session name and throw off the string being parsed
        const separator = b.indexOf('_?duration-diff_')
        const name = b.substring(9, separator)
        const diff = parseInt(b.substring(separator + 16), 10)
        return { name, diff }
      })
    if (durationMismatch.length > 0) {
      return (
        <div>
          <p className='failed-validation-text'>
            Some of your sessions are shorter or longer than their set duration.
            Please fix these sessions in order to proceed to Step 3:
          </p>
          {durationMismatch.map((m, i) => (
            <div
              key={i}
              className={`session-duration-mismatch-bar ${
                i === 0 ? 'first-mismatch' : ''
              } ${i === durationMismatch.length - 1 ? 'last-mismatch' : ''}`}
            >
              <p className='session-name-mismatch'>{m.name}</p>
              <p className='actual-mismatch'>
                {m.diff > 0
                  ? `${m.diff} min under duration`
                  : `${Math.abs(m.diff)} min over duration`}
              </p>
            </div>
          ))}
        </div>
      )
    }
  }

  _closeValidationModal = () => {
    this.setState({ failedValidation: [] })
  }

  _handleDiscardClick = () => {
    this.setState({ discardModal: true })
  }

  _closeDiscardModal = () => {
    this.setState({ discardModal: false })
  }

  _handleDiscard = () => {
    this.props.navigate('/lesson-plan')
  }

  _handleInputChange = (event) => {
    const name = event.target.name
    const val = event.target.value
    this.setState({
      [name]: val && name === 'defaultDuration' ? parseInt(val, 10) : val,
    })
  }

  _handleTagInput = (event) => {
    const { tagInput, textTags } = this.state
    if (event.key !== 'Enter' || !tagInput) {
      return false
    } else {
      this.setState({ textTags: [...textTags, tagInput] }, () => {
        this.setState({ tagInput: '' })
      })
    }
  }

  _handleRemoveTag = (i) => {
    const { textTags } = this.state
    this.setState({
      textTags: textTags.filter((tag, index) => index !== i),
    })
  }

  _addSession = () => {
    const { sessions, defaultDuration } = this.state
    const newSession = {
      name: `Session ${sessions.length + 1}`,
      duration: defaultDuration,
      notes: '',
      blocks: [
        {
          name: 'Block 1',
          duration: 10,
          description: '',
          tasks: [],
        },
      ],
    }
    this.setState({ sessions: [...sessions, newSession] })
  }

  _duplicateSession = (sessionIndex) => {
    const { sessions } = this.state
    const sessionsCopy = [...sessions]
    const sessionToDuplicate = JSON.parse(
      JSON.stringify(sessionsCopy[sessionIndex])
    )
    sessionToDuplicate.name = 'Copy: ' + sessionToDuplicate.name
    if (sessionIndex === sessionsCopy.length - 1) {
      sessionsCopy.push(sessionToDuplicate)
    } else {
      sessionsCopy.splice(sessionIndex + 1, 0, sessionToDuplicate)
    }
    this.setState({ sessions: sessionsCopy })
  }

  _deleteSession = (sessionIndex) => {
    const { sessions } = this.state
    const sessionsCopy = [...sessions]
    sessionsCopy.splice(sessionIndex, 1)
    this.setState({ sessions: sessionsCopy })
  }

  _addSessionBlock = (sessionIndex) => {
    const { sessions } = this.state
    const newBlock = {
      name: `Block ${sessions[sessionIndex].blocks.length + 1}`,
      duration: 10,
      description: '',
      tasks: [],
    }
    const sessionsCopy = [...sessions]
    sessionsCopy[sessionIndex].blocks.push(newBlock)
    this.setState({ sessions: sessionsCopy })
  }

  _handleSessionInput = (name, val, sessionIndex) => {
    const { sessions } = this.state
    const sessionsCopy = [...sessions]

    sessionsCopy[sessionIndex][name] = val
    this.setState({ sessions: sessionsCopy })
  }

  _handleMoveTask = (dir, taskIndex, blockIndex, sessionIndex) => {
    const { sessions } = this.state
    const sessionsCopy = [...sessions]

    if (
      taskIndex + dir < 0 ||
      taskIndex + dir >=
        sessionsCopy[sessionIndex].blocks[blockIndex].tasks.length
    ) {
      return false
    }

    const task = sessionsCopy[sessionIndex].blocks[blockIndex].tasks.splice(
      taskIndex,
      1
    )[0]

    sessionsCopy[sessionIndex].blocks[blockIndex].tasks.splice(
      taskIndex + dir,
      0,
      task
    )

    this.setState({ sessions: sessionsCopy })
  }

  _handleMoveBlock = (dir, blockIndex, sessionIndex) => {
    const { sessions } = this.state
    const sessionsCopy = [...sessions]

    if (
      blockIndex + dir < 0 ||
      blockIndex + dir >= sessionsCopy[sessionIndex].blocks.length
    ) {
      return false
    }

    const block = sessionsCopy[sessionIndex].blocks.splice(blockIndex, 1)[0]

    sessionsCopy[sessionIndex].blocks.splice(blockIndex + dir, 0, block)

    this.setState({ sessions: sessionsCopy })
  }

  _handleDeleteBlock = (sessionIndex, blockIndex) => {
    const { sessions } = this.state
    const sessionsCopy = [...sessions]

    sessionsCopy[sessionIndex].blocks.splice(blockIndex, 1)
    this.setState({ sessions: sessionsCopy })
  }

  _handleBlockInput = (name, val, sessionIndex, blockIndex) => {
    const { sessions } = this.state
    const sessionsCopy = [...sessions]

    sessionsCopy[sessionIndex].blocks[blockIndex][name] = val

    this.setState({ sessions: sessionsCopy })
  }

  _triggerCurriculumDetailModal = (id) => {
    const { subjects } = this.props
    const selectedSubject = subjects.find((sub) => sub.id === id)

    this.setState({ selectedSubject }, () => {
      this.setState({ curriculumModal: true })
    })
  }

  _closeCurriculumModal = () => {
    this.setState({ curriculumModal: false, selectedSubject: {} })
  }

  _triggerDrillDetailModal = (id) => {
    // const { drills } = this.props;
    // const selectedDrill = drills.find(drill => {
    //   return drill.id === id;
    // });

    // this.setState({ selectedDrill }, () => {
    //   this.setState({ drillModal: true });
    // });
    this.setState({ selectedDrillId: id })
  }

  _closeDrillModal = () => {
    this.setState({ selectedDrillId: -1 })
  }

  _rearrangeSessions = (moveFrom, moveTo) => {
    const { sessions } = this.state
    const sessionsCopy = [...sessions]
    const sessionToMove = sessionsCopy.splice(moveFrom, 1)[0]

    sessionsCopy.splice(moveTo, 0, sessionToMove)

    this.setState({ sessions: sessionsCopy })
  }

  //renders the middle section of the page (between the header and footer) based upon which section of the builder you're in
  _renderContent = (page) => {
    const admin = this.props.user && this.props.user.type === 'Admin'
    switch (page) {
      case 1:
        return (
          <LessonBasicDetails
            defaultDuration={this.state.defaultDuration}
            lessonPlanName={this.state.lessonPlanName}
            tagInput={this.state.tagInput}
            textTags={this.state.textTags}
            notes={this.state.notes}
            video_url={this.state.video_url}
            onChange={this._handleInputChange}
            textTagChange={this._handleTagInput}
            removeTag={this._handleRemoveTag}
            onChangePhoto={this._onChangePhoto}
            photo={{
              uploadPhotoName: this.state.uploadPhotoName,
              uploadPhotoFile: this.state.uploadPhotoFile,
              uploadPhotoFileSrc: this.state.uploadPhotoFileSrc,
            }}
            clearPhoto={this._clearPhoto}
            failedValidation={this.state.failedValidation}
            pageProgress={this.state.pageProgress}
            is_admin={admin}
          />
        )
      case 2:
        return (
          <LessonCreateSchedule
            sessions={this.state.sessions}
            addSession={this._addSession}
            duplicateSession={this._duplicateSession}
            rearrangeSessions={this._rearrangeSessions}
            deleteSession={this._deleteSession}
            addBlock={this._addSessionBlock}
            handleSessionInput={this._handleSessionInput}
            handleBlockInput={this._handleBlockInput}
            handleDeleteBlock={this._handleDeleteBlock}
            handleMoveBlock={this._handleMoveBlock}
            drills={this.props.drills}
            subjects={this.props.subjects}
            initialMyDrillsLoader={this.state.myDrillsLoader}
            initialMyActivitiesLoader={this.state.myActivitiesLoader}
            initialPublicDrillsLoader={this.state.publicDrillsLoader}
            initialPublicActivitiesLoader={this.state.publicActivitiesLoader}
            subjectLoader={this.state.subjectLoader}
            // handleDragEnd={this._handleDragEnd}
            handleAddTask={this._addTaskToBlock}
            handleMoveTask={this._handleMoveTask}
            removeActiveTask={this._removeTaskFromBlock}
            triggerCurriculumDetailModal={this._triggerCurriculumDetailModal}
            triggerDrillDetailModal={this._triggerDrillDetailModal}
            allTags={this.props.all_tags}
            tagLoader={this.state.tagLoader}
          />
        )
      case 3:
        return (
          <LessonReviewPublish
            isBuilder={true}
            sessions={this.state.sessions}
            triggerCurriculumDetailModal={this._triggerCurriculumDetailModal}
            triggerDrillDetailModal={this._triggerDrillDetailModal}
            lessonPlanName={this.state.lessonPlanName}
            lessonPlanNotes={this.state.notes}
            lessonPlanTags={this.state.textTags}
            lessonPlanPhoto={this.state.uploadPhotoFileSrc}
            video_url={this.state.video_url}
            drills={this.props.drills.possible_tasks}
            subjects={this.props.subjects}
            backToPage={this._handleProgressBarClick}
          />
        )
      default:
        return ''
    }
  }
}

function mapStateToProps(state, _ownProps) {
  return {
    ui: state.ui,
    user: state.user.current_user,
    drills: state.drills,
    subjects: state.subjects,
    all_tags: state.tags,
    practice_types: state.practiceTypes,
    // lesson_plans: state.lessonPlans.really_all_plans
    lesson_plan: state.lessonPlans.selected_plan,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    common_actions: bindActionCreators(commonActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
    drill_actions: bindActionCreators(drillActions, dispatch),
    subject_actions: bindActionCreators(subjectActions, dispatch),
    tag_actions: bindActionCreators(tagActions, dispatch),
    practice_types_actions: bindActionCreators(practiceTypesActions, dispatch),
    lessonPlan_actions: bindActionCreators(lessonPlanActions, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouting(LessonPlanBuilderPage))
