import React from "react";
import "./Notification.scss";

const navigateFromType = (notification, navigateFromNotification) => {
  // notification.family_id
  switch (notification.type) {
    case "NotificationApply":
      navigateFromNotification(
        notification.type,
        notification.enrollment.course.id
      );
      break
    case "NotificationFamily":
      navigateFromNotification(notification.type, notification.family_id)
      break
    case "NotificationLead":
      navigateFromNotification(notification.type, "not using this value")
      break
    case "NotificationComment":
      navigateFromNotification(notification.type, notification.activity_id)
      break
    case "NotificationLike":
      navigateFromNotification(notification.type, notification.activity_id)
      break
    default:
      return null
  }
};

const Notification = ({ notification, navigateFromNotification }) => (
  <div
    id='NotificationWrapper'
    onClick={() => navigateFromType(notification, navigateFromNotification)}
  >
    <i className='fa fa-group notification-icon' />
    <p className='notification-message'>{notification.message}</p>
  </div>
);

export default Notification;
