import Modal from '../../common/Modal'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import SubmitButton from '../../common/buttons/SubmitButton'

import styles from './SentReminderModal.module.scss'

const SentReminderModal = ({ showModal, closeModal }) => (
  <Modal
    isOpen={showModal}
    onClose={closeModal}
    contentLabel='Exit Event Modal'
    className={styles.sentReminderModal}
    autoFocus
  >
    <div className={classNames('d-flex flex-column align-items-center', styles.modalContainer)}>
      <div className={classNames('d-flex justify-content-center align-items-center', styles.iconWrapper)}>
        <i className='fa fa-envelope emailIcon'/>
      </div>
      <div className={styles.modalContent}>
        <span className={styles.title}>RSVP Reminder Sent!</span>
      </div>
      <div className='w-100 pull-bottom'>
        <SubmitButton
          onClick={closeModal}
          buttonMessage='Close'
          buttonColor='navy-button'
        />
      </div>
    </div>
  </Modal>
)

SentReminderModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default SentReminderModal
