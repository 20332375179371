import React from "react";

import "./CommunityPlaceholder.scss";

// import pic from "../../assets/images/common/icons/objectives/objective_icon_fitness_nutrition.png";
import greyPin from "../../assets/images/pins/greyed_pin.png";

const CommunityPlaceholder = props => {
  return (
    <div id='CommunityPlaceholder'>
      <img src={greyPin} alt='pin' />
      {/* <i className="fa fa-globe" aria-hidden="true" /> */}
      <p>
        We'll display the Operation 36 locations closest to you here. Enter your
        address or city in the search bar above to start!
      </p>
    </div>
  );
};

export default CommunityPlaceholder;
