import React from 'react';

import './SessionPopover.scss';

class SessionPopover extends React.Component {
  componentWillMount() {
    document.addEventListener('click', this._handleClick, false);
  }
  componentWillUnmount() {
    document.removeEventListener('click', this._handleClick, false);
  }

  render() {
    return (
      <div
        id='SessionPopover'
        ref={node => (this.node = node)}
        onClick={this._anotherClick}
      >
        <p className='duplicate-session' onClick={this._duplicate}>
          Duplicate session
        </p>
        <p className='delete-session' onClick={this._delete}>
          Delete session
        </p>
      </div>
    );
  }

  _duplicate = e => {
    e.stopPropagation();
    const { index } = this.props;
    this.props.duplicate(index);
  };

  _delete = e => {
    e.stopPropagation();
    const { index } = this.props;
    this.props.delete(index);
  };

  _handleClick = e => {
    // just tracks if the user clicks outside the component to close the popover
    if (!this.node.contains(e.target)) {
      this.props.close();
    }
  };

  _anotherClick = e => {
    // stops from click event on whitespace of component selecting this session to display on the page
    e.stopPropagation();
  };
}

export default SessionPopover;
