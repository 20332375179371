import classNames from 'classnames'
import PropTypes from 'prop-types'
import Modal from 'react-modal'

import SubmitButton from '../../../../common/buttons/SubmitButton'
import { ReactComponent as WarningIcon } from '../../../../../assets/images/common/icons/warning-icon.svg'
import { ReactComponent as ModalXIcon } from '../../../../../assets/images/common/icons/modal-X-icon.svg'
import './DropEnrollmentModal.scss'
import Input from '../../../../common/form/Input'

const DropEnrollmentModal = ({ showModal, closeModal, coachMessage, handleCoachMessageChange, handleRemoveEnrollment, enrollment }) => {
  const isEnrollmentApproved = enrollment.status === 'active'

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={closeModal}
      contentLabel='Drop Enrollment Modal'
      className={classNames('drop-enrollment-modal-content')}
      autoFocus
    >
      <div id='DropEnrollmentModal'>
        <div className='animated fadeIn'>
          <div className='d-flex justify-content-end text-align-right'>
            <ModalXIcon id='modalXIcon' onClick={closeModal} />
          </div>
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <WarningIcon id='warningIcon' />
            <p className='title'>{isEnrollmentApproved ? 'Drop' : 'Decline'} Enrollment</p>
            <p className='sub-title'>
              Are you sure you want to {isEnrollmentApproved ? 'drop' : 'decline'} this enrollment? This action will trigger
              an email for the family, telling them the enrollment was {isEnrollmentApproved ? 'dropped' : 'declined'}.
            </p>
            <p className='sub-title mt-2'>
              Do you want to send an additional message telling the family why their enrollment was {isEnrollmentApproved ? 'dropped' : 'declined'}?
            </p>
            <Input
              name='description'
              type='textarea'
              onChange={handleCoachMessageChange}
              placeholder='Write a custom message for the family! (optional)'
              value={coachMessage}
              classNames={{ inputContainer: 'coachMessageInputContainer', inputContainerWrapper: 'mt-3 coachMessageWrapper' }}
            />
          </div>
          <div>
            <div className='row py-3'>
              <div className='col-12'>
                <SubmitButton
                  onClick={handleRemoveEnrollment}
                  buttonMessage={`${isEnrollmentApproved ? 'Drop' : 'Decline'} Enrollment`}
                  buttonColor='orange-button'
                />
              </div>
              <div className='col-12'>
                <SubmitButton
                  onClick={closeModal}
                  buttonMessage='Cancel'
                  buttonColor='navy-button'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

DropEnrollmentModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  coachMessage: PropTypes.string,
  handleCoachMessageChange: PropTypes.func.isRequired,
  handleRemoveEnrollment: PropTypes.func.isRequired,
  enrollment: PropTypes.object.isRequired,
}

export default DropEnrollmentModal
