import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './SimplePhotoUploader.scss'
import FieldError from './FieldError'

// if you need a 2nd photo uploader on the same page, pass prop -> second={true} to the second one.

class SimplePhotoUploader extends Component {
  constructor(props) {
    super(props)

    const setImage = () => {
      const { photo, selectedPhoto } = props

      if (photo) {
        return photo.file_url
      } else if (selectedPhoto) {
        return selectedPhoto
      }
      return ''
    }

    this.state = {
      uploadPhotoName: '',
      uploadPhotoFile: '',
      uploadPhotoSrc: setImage(),
    }
  }

  clearImage = () => {
    const { sendPhotoData } = this.props

    this.setState(
      {
        uploadPhotoName: '',
        uploadPhotoFile: '',
        uploadPhotoSrc: '',
      },
      () => {
        sendPhotoData(this.state)
      }
    )
  }
  uploadClicked = () => {
    const { second } = this.props
    document.getElementById(second ? 'input-photo-upload-second' : 'input-photo-upload')
      .click()
  }

  onChangePhotoInput = (event) => {
    const { sendPhotoData } = this.props
    this.setState({
      uploadPhotoName: event.target.value,
      uploadPhotoFile: event.target.files[0],
    })

    const reader = new window.FileReader()
    const page = this
    reader.onload = function(e) {
      page.setState(
        {
          uploadPhotoSrc: e.target.result,
        },
        () => {
          sendPhotoData(page.state)
        }
      )
    }
    reader.readAsDataURL(event.target.files[0])
  }

  render() {
    const { uploadPhotoSrc } = this.state
    const { placeholder, error, className } = this.props
    return (
      <div id='SimplePhotoUploader'>
        <div className={classnames('photo-uploader-input', className, { 'error-input': !!error })}>
          {uploadPhotoSrc ? (
            <div className='uploaded-image-container'>
              <button
                type='button'
                className='uploaded-image-wrapper animation-shrink'
                onClick={this.uploadClicked}
              >
                <img src={uploadPhotoSrc} alt='uploaded' />
              </button>
              <button type='button' className='w-100 clear-image-wrapper' onClick={this.clearImage}>
                <i className='fa fa-times' aria-hidden='true' />
                <p>clear image</p>
              </button>
            </div>
          ) : (
            <button
              type='button'
              className='empty-uploader animation-shrink'
              onClick={this.uploadClicked}
            >
              <i className='fa fa-camera' aria-hidden='true' />
              <p>{placeholder ? placeholder : 'upload an image'}</p>
            </button>
          )}

          <input
            name={''}
            id={`input-photo-upload${this.props.second ? '-second' : ''}`}
            type={'file'}
            accept={'image/png, image/jpeg'}
            className={'hide form-control-file photoInput'}
            onChange={this.onChangePhotoInput}
            placeholder={'Add a Different Photo'}
            value={this.state.uploadPhotoName || ''}
          />
        </div>
        {error && <FieldError message={error} />}
      </div>
    )
  }
}

SimplePhotoUploader.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  photo: PropTypes.shape({
    file_url: PropTypes.string,
  }),
  selectedPhoto: PropTypes.oneOfType([
    PropTypes.any,
  ]),
  sendPhotoData: PropTypes.func,
  second: PropTypes.bool,
  error: PropTypes.string,
}

export default SimplePhotoUploader
