import React from 'react'

const LOGO_ORANGE = '#ff6633'
const LIGHT_NAVY = '#5F6F7C'
const BLACK = '#000000'
const WHITE = '#FFFFFF'

/**
  SVG component for horizontal logo that you can convert to different colors.
*/
const horizontalLogo = (props) => {

  // colorScheme: 'light', 'dark', 'black', or 'white'
  // --> 'light' is orange and white; 'dark' is orange and light navy
  // classes: any class names you want to apply
  const { colorScheme, classes } = props

  // Default fills for the letterforms and the number logo (36)
  let letterFill
  let numFill

  switch (colorScheme) {
    case 'dark':
      letterFill = LIGHT_NAVY
      numFill = LOGO_ORANGE
      break
    case 'light':
      letterFill = WHITE
      numFill = LOGO_ORANGE
      break
    case 'white':
      letterFill = WHITE
      numFill = WHITE
      break
    case 'black':
      letterFill = BLACK
      numFill = BLACK
      break
    default:
      letterFill = LIGHT_NAVY
      numFill = LOGO_ORANGE
      break
  }

  return (
    <svg className={classes} width='386' height='57' viewBox='0 0 386 57' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M27.1 28.1C27.1 30.1 26.8 31.9 26.1 33.5C25.5 35.2 24.5 36.6 23.4 37.8C22.2 39 20.8 40 19.1 40.6C17.4 41.3 15.6 41.6 13.6 41.6C11.6
        41.6 9.7 41.3 8.1 40.6C6.4 39.9 5 39 3.8 37.8C2.6 36.6 1.7 35.1 1 33.5C0.3 31.8 0 30 0 28.1C0 26.2 0.3 24.3 1 22.7C1.7 21 2.6 19.6 3.8 18.4C5
        17.2 6.4 16.2 8.1 15.6C9.8 14.9 11.6 14.6 13.6 14.6C15 14.6 16.2 14.8 17.4 15.1C18.6 15.4 19.7 15.9 20.7 16.4C21.7 16.9 22.6 17.7 23.4
        18.5C24.2 19.3 24.9 20.2 25.4 21.2C26 22.2 26.4 23.3 26.7 24.5C27 25.6 27.1 26.8 27.1 28.1ZM22 28.1C22 26.6 21.8 25.3 21.4 24.2C21 23 20.4
        22.1 19.7 21.2C19 20.4 18.1 19.8 17.1 19.3C16.1 18.9 14.9 18.7 13.6 18.7C12.3 18.7 11.1 18.9 10.1 19.3C9.1 19.7 8.2 20.4 7.4 21.2C6.7 22 6.1
        23 5.7 24.2C5.3 25.4 5.1 26.7 5.1 28.1C5.1 29.6 5.3 30.9 5.7 32C6.1 33.2 6.7 34.1 7.4 34.9C8.1 35.7 9 36.3 10.1 36.8C11.1 37.2 12.3 37.4 13.6
        37.4C14.9 37.4 16.1 37.2 17.1 36.8C18.1 36.4 19 35.8 19.7 34.9C20.4 34.1 21 33.1 21.4 32C21.8 30.9 22 29.6 22 28.1Z'
        fill={letterFill}
      />
      <path
        d='M40.1 14.8C41.9 14.8 43.4 15 44.7 15.4C46 15.8 47.1 16.4 47.9 17.2C48.7 18 49.4 18.8 49.8 19.9C50.2 20.9 50.4 22.1 50.4 23.3C50.4 24.6 50.2
        25.8 49.8 26.8C49.4 27.9 48.7 28.8 47.9 29.6C47 30.4 46 31 44.7 31.4C43.4 31.8 41.9 32 40.2 32H36.5V41.4H31.5V14.8H40.1ZM40.1 28.2C41 28.2
        41.8 28.1 42.5 27.9C43.2 27.7 43.7 27.3 44.2 26.9C44.6 26.5 45 26 45.2 25.4C45.4 24.8 45.5 24.1 45.5 23.4C45.5 22.7 45.4 22 45.2 21.5C45 20.9
        44.6 20.4 44.2 20C43.8 19.6 43.2 19.3 42.5 19.1C41.8 18.9 41 18.8 40.1 18.8H36.4V28.4H40.1V28.2Z'
        fill={letterFill}
      />
      <path d='M59.3 18.7V26.1H68.6V29.9H59.3V37.4H71.1V41.4H54.3V14.8H71.1V18.7H59.3Z' fill={letterFill}/>
      <path
        d='M96.3 41.4H91.8C91 41.4 90.3 41.1 90 40.4L84.4 31.9C84.2 31.6 84 31.4 83.7 31.2C83.4 31 83.1 31 82.6 31H80.5V41.4H75.5V14.8H83.6C85.4
        14.8 87 15 88.3 15.4C89.6 15.8 90.7 16.3 91.5 17C92.3 17.7 92.9 18.5 93.3 19.4C93.7 20.3 93.9 21.4 93.9 22.5C93.9 23.4 93.8 24.2 93.5 25C93.2
        25.8 92.9 26.5 92.4 27.2C91.9 27.9 91.3 28.4 90.6 28.9C89.9 29.4 89 29.8 88.1 30.1C88.4 30.3 88.7 30.5 89 30.8C89.3 31.1 89.5 31.4 89.7
        31.7L96.3 41.4ZM83.5 27.5C84.4 27.5 85.2 27.4 85.9 27.1C86.6 26.9 87.2 26.5 87.6 26.1C88 25.7 88.4 25.2 88.6 24.6C88.8 24 88.9 23.4 88.9
        22.7C88.9 21.3 88.5 20.3 87.6 19.6C86.7 18.9 85.3 18.5 83.5 18.5H80.3V27.3H83.5V27.5Z'
        fill={letterFill}
      />
      <path
        d='M122 41.4H118.2C117.8 41.4 117.4 41.3 117.1 41.1C116.8 40.9 116.6 40.6 116.5 40.3L114.5 34.9H103.5L101.5 40.3C101.4 40.6 101.2 40.8 100.9
        41.1C100.6 41.3 100.3 41.5 99.8 41.5H95.9L106.4 14.8H111.5L122 41.4ZM113.3 31.4L110.1 22.5C109.9 22.1 109.8 21.6 109.6 21.1C109.4 20.6 109.3
        20 109.1 19.3C108.9 19.9 108.8 20.5 108.6 21.1C108.4 21.6 108.3 22.1 108.1 22.5L104.9 31.3H113.3V31.4Z'
        fill={letterFill}
      />
      <path d='M140.8 18.9H132.8V41.5H127.8V18.9H119.8V14.8H140.8V18.9Z' fill={letterFill}/>
      <path d='M149.3 41.4H144.3V14.8H149.3V41.4Z' fill={letterFill}/>
      <path
        d='M180.7 28.1C180.7 30.1 180.4 31.9 179.7 33.5C179.1 35.2 178.1 36.6 177 37.8C175.8 39 174.4 40 172.7 40.6C171 41.3 169.2 41.6 167.2
        41.6C165.2 41.6 163.3 41.3 161.7 40.6C160 39.9 158.6 39 157.4 37.8C156.2 36.6 155.3 35.1 154.6 33.5C153.9 31.8 153.6 30 153.6 28.1C153.6
        26.2 153.9 24.3 154.6 22.7C155.3 21 156.2 19.6 157.4 18.4C158.6 17.2 160 16.2 161.7 15.6C163.4 14.9 165.2 14.6 167.2 14.6C168.6 14.6 169.8
        14.8 171 15.1C172.2 15.4 173.3 15.9 174.3 16.4C175.3 16.9 176.2 17.7 177 18.5C177.8 19.3 178.5 20.2 179 21.2C179.6 22.2 180 23.3 180.3
        24.5C180.5 25.6 180.7 26.8 180.7 28.1ZM175.6 28.1C175.6 26.6 175.4 25.3 175 24.2C174.6 23 174 22.1 173.3 21.2C172.6 20.4 171.7 19.8 170.7
        19.3C169.7 18.9 168.5 18.7 167.2 18.7C165.9 18.7 164.7 18.9 163.7 19.3C162.7 19.7 161.8 20.4 161 21.2C160.3 22 159.7 23 159.3 24.2C158.9 25.4
        158.7 26.7 158.7 28.1C158.7 29.6 158.9 30.9 159.3 32C159.7 33.2 160.3 34.1 161 34.9C161.7 35.7 162.6 36.3 163.7 36.8C164.7 37.2 165.9 37.4
        167.2 37.4C168.5 37.4 169.7 37.2 170.7 36.8C171.7 36.4 172.6 35.8 173.3 34.9C174 34.1 174.6 33.1 175 32C175.4 30.9 175.6 29.6 175.6 28.1Z'
        fill={letterFill}
      />
      <path
        d='M207.5 14.8V41.5H205C204.6 41.5 204.3 41.4 204 41.3C203.7 41.2 203.5 41 203.2 40.6L189.3 22.8C189.4 23.6 189.4 24.4 189.4
        25V41.4H185V14.8H187.6C187.8 14.8 188 14.8 188.1 14.8C188.3 14.8 188.4 14.9 188.5 14.9C188.6 15 188.7 15 188.8 15.1C188.9 15.2 189 15.3 189.2
        15.5L203.2 33.3C203.2 32.9 203.1 32.4 203.1 32C203.1 31.6 203.1 31.2 203.1 30.8V14.8H207.5Z'
        fill={letterFill}
      />
      <path
        d='M315.2 28.1V38.9C313.8 39.9 312.4 40.6 310.9 41.1C309.4 41.6 307.7 41.8 306 41.8C303.8 41.8 301.9 41.5 300.1 40.8C298.3 40.1 296.8 39.2
        295.6 38C294.4 36.8 293.4 35.4 292.7 33.7C292 32 291.7 30.2 291.7 28.2C291.7 26.2 292 24.4 292.7 22.7C293.4 21 294.3 19.6 295.5 18.4C296.7
        17.2 298.2 16.3 299.9 15.6C301.6 14.9 303.5 14.6 305.7 14.6C306.8 14.6 307.8 14.7 308.7 14.9C309.6 15.1 310.5 15.3 311.3 15.6C312.1 15.9 312.8
        16.3 313.5 16.7C314.2 17.1 314.8 17.6 315.3 18.1L313.9 20.3C313.7 20.7 313.4 20.9 313 21C312.6 21.1 312.3 21 311.8 20.7C311.4 20.5 311 20.2
        310.6 20C310.2 19.8 309.7 19.6 309.2 19.4C308.7 19.2 308.1 19.1 307.5 19C306.9 18.9 306.2 18.8 305.4 18.8C304.1 18.8 302.9 19 301.8 19.5C300.7
        19.9 299.8 20.6 299.1 21.4C298.4 22.2 297.8 23.2 297.4 24.4C297 25.6 296.8 26.8 296.8 28.3C296.8 29.8 297 31.2 297.5 32.4C297.9 33.6 298.6
        34.6 299.3 35.4C300.1 36.2 301 36.9 302.2 37.3C303.3 37.7 304.6 38 305.9 38C306.9 38 307.8 37.9 308.5 37.7C309.3 37.5 310 37.2 310.8
        36.9V32.1H307.4C307.1 32.1 306.8 32 306.7 31.8C306.5 31.6 306.4 31.4 306.4 31.1V28.3H315.2V28.1Z'
        fill={letterFill}
      />
      <path
        d='M345.4 28.1C345.4 30.1 345.1 31.9 344.4 33.5C343.8 35.2 342.8 36.6 341.7 37.8C340.5 39 339.1 40 337.4 40.6C335.7 41.2 333.9 41.6 331.9
        41.6C329.9 41.6 328 41.3 326.4 40.6C324.7 39.9 323.3 39 322.1 37.8C320.9 36.6 320 35.1 319.3 33.5C318.6 31.8 318.3 30 318.3 28.1C318.3 26.2
        318.6 24.3 319.3 22.7C320 21 320.9 19.6 322.1 18.4C323.3 17.2 324.7 16.2 326.4 15.6C328.1 14.9 329.9 14.6 331.9 14.6C333.3 14.6 334.5 14.8
        335.7 15.1C336.9 15.4 338 15.9 339 16.4C340 17 340.9 17.7 341.7 18.5C342.5 19.3 343.2 20.2 343.7 21.2C344.3 22.2 344.7 23.3 345 24.5C345.3
        25.6 345.4 26.8 345.4 28.1ZM340.4 28.1C340.4 26.6 340.2 25.3 339.8 24.2C339.4 23 338.8 22.1 338.1 21.2C337.4 20.4 336.5 19.8 335.5
        19.3C334.5 18.9 333.3 18.7 332 18.7C330.7 18.7 329.5 18.9 328.5 19.3C327.5 19.7 326.6 20.4 325.8 21.2C325.1 22 324.5 23 324.1 24.2C323.7
        25.4 323.5 26.7 323.5 28.1C323.5 29.6 323.7 30.9 324.1 32C324.5 33.2 325.1 34.1 325.8 34.9C326.5 35.7 327.4 36.3 328.5 36.8C329.5 37.2
        330.7 37.4 332 37.4C333.3 37.4 334.5 37.2 335.5 36.8C336.5 36.4 337.4 35.8 338.1 34.9C338.8 34.1 339.4 33.1 339.8 32C340.2 30.9 340.4 29.6
        340.4 28.1Z'
        fill={letterFill}
      />
      <path d='M365.4 37.3V41.4H349.8V14.8H354.8V37.4H365.4V37.3Z' fill={letterFill}/>
      <path d='M373.9 18.7V26.6H383.9V30.6H373.9V41.5H368.9V14.8H385.7V18.7H373.9Z' fill={letterFill}/>
      <path
        d='M274.9 34C274.1 32.3 273.1 30.8 271.8 29.6C270.5 28.4 269 27.6 267.4 27C265.7 26.4 264 26.2 262.3 26.2C261.2 26.2 260.2 26.3 259.2
        26.5C258.2 26.7 257.4 27 256.5 27.4C257 26.8 257.4 26.2 257.9 25.6L271.7 8.7H263.8C262.7 8.7 261.8 8.9 260.9 9.3C260.1 9.7 259.4 10.2 259
        10.8L248.2 25.5C247.7 26.2 247.2 26.8 246.8 27.5C245.7 24.7 243.4 22.6 239.8 21.4C240.9 21 241.9 20.6 242.7 20C243.6 19.4 244.3 18.8 244.9
        17.9C245.5 17.1 245.9 16.1 246.2 15.1C246.5 14 246.6 12.8 246.6 11.3C246.6 9.7 246.3 8.2 245.6 6.8C245 5.4 244.1 4.2 242.9 3.1C241.7 2.1
        240.3 1.2 238.6 0.6C237 0.3 235.1 0 232.9 0C230.9 0 229 0.3 227.3 0.9C225.6 1.5 224 2.3 222.7 3.4C221.4 4.5 220.2 5.9 219.3 7.5C218.4 9.2
        217.8 11.1 217.5 13.3L222 14.1C223.2 14.3 224.1 14.2 224.7 13.9C225.3 13.5 225.8 12.9 226.2 11.8C226.4 11.1 226.7 10.5 227.1 10C227.5 9.4
        228 9 228.5 8.5C229 8.1 229.7 7.8 230.4 7.5C231.1 7.3 231.8 7.1 232.7 7.1C234.6 7.1 236 7.6 237 8.6C238 9.6 238.6 11.1 238.6 13.1C238.6 14
        238.5 14.9 238.2 15.7C238 16.5 237.5 17.2 236.8 17.7C236.1 18.3 235.2 18.7 234 19C232.8 19.3 231.3 19.5 229.4 19.5V25.5C231.6 25.5 233.4 25.6
        234.7 26C236 26.3 237 26.8 237.7 27.3C238.4 27.9 238.8 28.6 239 29.4C239.2 30.2 239.3 31.2 239.3 32.2C239.3 33 239.2 33.8 238.9 34.6C238.6
        35.4 238.2 36.1 237.6 36.7C237 37.3 236.3 37.8 235.4 38.2C234.5 38.6 233.5 38.8 232.3 38.8C231.4 38.8 230.5 38.7 229.8 38.4C229.1 38.1 228.4
        37.8 227.8 37.3C227.2 36.8 226.7 36.3 226.2 35.6C225.7 34.9 225.3 34.2 224.9 33.4C224.5 32.7 223.9 32.2 223.1 32C222.2 31.8 221.3 31.9 220.3
        32.4L216.5 34C217.2 36 218 37.7 218.9 39.2C219.8 40.7 220.9 41.9 222.2 42.9C223.5 43.9 224.9 44.6 226.6 45.1C228.3 45.6 230.2 45.8 232.4
        45.8C234.5 45.8 236.4 45.5 238.3 44.9C240.2 44.3 241.8 43.4 243.2 42.2C243.2 42.2 243.2 42.1 243.3 42.1C243.5 43.6 243.8 45 244.3 46.3C245.1
        48.3 246.2 50.1 247.6 51.5C249 53 250.8 54.1 252.8 54.9C254.8 55.7 257.1 56.1 259.7 56.1C262.2 56.1 264.5 55.7 266.6 54.8C268.7 53.9 270.4
        52.8 271.9 51.4C273.4 50 274.5 48.3 275.3 46.3C276.1 44.4 276.5 42.3 276.5 40.1C276.1 37.8 275.7 35.8 274.9 34ZM247.6 34.5C246.6 35.1 245.3
        35.7 244.6 35.9C243.8 36.1 242.9 36.7 242.9 36.7V28.6C242.9 28.6 243.3 28.9 243.4 29.3C243.5 29.7 243.6 30.5 244.7 30.9C245.8 31.4 247.2 31.3
        248.2 31.4C249.3 31.5 250.8 31.7 251.2 32.5C251.6 33.3 251.9 34.9 251.9 34.9C251.9 34.9 251.6 34.5 250.7 34C249.7 33.6 248.7 33.9 247.6
        34.5ZM266.7 44.1C266.3 45.1 265.7 45.9 265 46.7C264.3 47.4 263.4 48 262.5 48.4C261.5 48.8 260.4 49 259.3 49C258 49 256.9 48.8 255.9 48.4C254.9
        48 254.1 47.5 253.5 46.8C252.9 46.1 252.4 45.2 252 44.2C251.7 43.2 251.5 42.1 251.5 40.9C251.5 39.7 251.7 38.6 252 37.6C252.4 36.6 252.9 35.8
        253.6 35C254.3 34.3 255.1 33.7 256 33.3C256.9 32.9 258 32.7 259.1 32.7C260.3 32.7 261.4 32.9 262.4 33.2C263.4 33.6 264.2 34.1 265 34.8C265.7
        35.5 266.3 36.3 266.7 37.4C267.1 38.4 267.3 39.5 267.3 40.8C267.3 42 267.1 43.1 266.7 44.1Z'
        fill={numFill}
      />
      <path
        d='M284.1 17.8C284.1 16.9 283.5 16.3 282.4 16.3H280.4V20.9H281.2V19.3H282.1L283.3 20.9H284.2L282.9 19.2C283.6 19.1 284.1 18.5 284.1
        17.8ZM281.1 17H282.3C282.9 17 283.3 17.3 283.3 17.8C283.3 18.4 282.8 18.6 282.3 18.6H281.1V17Z'
        fill={letterFill}
      />
      <path
        d='M282.1 14.2C279.6 14.2 277.6 16.2 277.6 18.7C277.6 21.2 279.6 23.2 282.1 23.2C284.6 23.2 286.6 21.2 286.6 18.7C286.5 16.2 284.5 14.2 282.1
        14.2ZM285.7 18.6C285.7 20.6 284.1 22.2 282.1 22.2C280.1 22.2 278.5 20.6 278.5 18.6C278.5 16.6 280.1 15 282.1 15C284.1 15 285.7 16.6 285.7
        18.6Z'
        fill={letterFill}
      />
    </svg>
  )
}

export default horizontalLogo
