import * as types from "../actions/actionTypes";
import update from "immutability-helper";

// state = [] : Start out with no accounts
export default function coaches(state = [], action) {
  switch (action.type) {
    case types.ADD_COACHES:
      return action.coaches;

    case types.CREATE_COACH:
      return [...state, Object.assign({}, action.coach)];

    case types.UPDATE_COACH:
      let currentCoachIndex = state.findIndex(
        coach => coach.id === action.coach.id
      );
      // let currentCoach = state[currentCoachIndex];
      let newCoach = action.coach;
      return update(state, {
        [currentCoachIndex]: { $set: newCoach }
      });

    default:
      return state;
  }
}
