import Modal from 'react-modal'
import PropTypes from 'prop-types'

import SubmitButton from '../common/buttons/SubmitButton'
import './InfoModal.scss'

const modalStyles = {
  content: {
    height: 'min-content',
    margin: 'auto',
    maxWidth: '550px',
    marginTop: '12%',
    borderRadius: '8px',
    overflow: 'hidden',
  },
}

const InfoModal = ({
  showModal,
  closeModal,
  title,
  children
}) => {
  return (
    <Modal
      isOpen={showModal || false}
      onRequestClose={closeModal}
      contentLabel='Info Modal'
      style={modalStyles}
      autoFocus
    >
      <div id='InfoModal'>
        <div className='animated fadeIn'>
          <div>
            <div className='d-flex justify-content-end text-align-right'>
              <button
                onClick={closeModal}
                className='info-modal-close-button'
              >
                <i className='fa fa-times info-modal-close-icon' />
              </button>
            </div>
            <div className='d-flex flex-column justify-content-center text-align-center info-modal-container'>
              {title && (
                <div className='info-modal-title'>
                  <p>{title}</p>
                </div>
              )}
              {children}
            </div>
            <div className='row py-3'>
              <div className='col-12'>
                <SubmitButton
                  onClick={closeModal}
                  buttonMessage='Close'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

InfoModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.node
}

export default InfoModal
