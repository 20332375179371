import classNames from 'classnames'

import logo from '../../assets/images/logos/OP36_clean_icon_orange.png'
import SubmitButton from '../common/buttons/SubmitButton'

import classes from './MaintenancePage.module.scss'

const MaintenancePage = () => (
  <div className={classNames(classes.programRegistrationPrompt, 'd-flex justify-content-center align-items-center')}>
    <main className={classNames(classes.promptContent, 'd-flex flex-column justify-content-center align-items-center')}>
      <img src={logo} height={120} width={120} alt='logo' className='mb-5' />

      <h1 className={classes.titleText}>Website Is Being Aerified</h1>

      <h3 className={classes.subtitleText}>
        The web app is currently being worked on to bring you a better
        experience. Sorry for the inconvenience. Check back in soon or click
        the button below to refresh and check the status!
      </h3>

      <SubmitButton
        className={classes.actionLink}
        onClick={() => window.location.reload()}
        buttonMessage='Reload'
      />
    </main>
  </div>
)

export default MaintenancePage
