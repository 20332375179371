import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Placeholder from "./Placeholder";

import * as commonActions from "../../actions/commonActions";
import * as authActions from "../../actions/authActions";
import { withRouting } from "./hooks";

class FourOhFour extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  componentWillMount() {
    this.props.common_actions.showSideBar(false);
    this.props.common_actions.showNavBar(false);
  }

  componentWillUnmount() {
    this.props.common_actions.showSideBar(true);
    this.props.common_actions.showNavBar(true);
  }

  render() {
    return (
      <div
        id="404page"
        className="container d-flex align-items-center justify-content-center"
      >
        <div className="card mt-5 p-4">
          <Placeholder
            title={"Oops You Are Out Of Bounds"}
            icon={"fa-lock"}
            subtext1={
              "You have reached a page you have no access to or doesn't exist."
            }
            subtext2={
              "Please click the button to navigate back to the dashboard."
            }
            buttonText={"Return Home"}
            buttonIcon={"fa-home"}
            handleClick={() => this.props.navigate('/')}
          />
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    common_actions: bindActionCreators(commonActions, dispatch),
    auth_actions: bindActionCreators(authActions, dispatch)
  };
}

export default connect(
  null,
  mapDispatchToProps
)(withRouting(FourOhFour))
