// import * as types from '../actions/actionTypes';
// import update from 'immutability-helper';

// state = [] : Start out with no accounts
export default function activity(state = null, action){
  switch(action.type){
    // case types.GET_ACTIVITY: 
    //   return action.activity;

    default:
      return state;
  }
}