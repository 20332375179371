import React, { Component } from "react";
import PropTypes from "prop-types";

class SvgIcon extends Component {
  static propTypes = {
    /**
     * Elements passed into the SVG Icon.
     */
    children: PropTypes.node,
    /**
     * Override the inline-styles of the root element.
     */
    style: PropTypes.object,
    /**
     * Allows you to redefine what the coordinates
     * without units mean inside an svg element. For example,
     * if the SVG element is 500 (width) by 200 (height), and you
     * pass viewBox="0 0 50 20", this means that the coordinates inside
     * the svg will go from the top left corner (0,0) to bottom right (50,20)
     * and each unit will be worth 10px.
     */
    viewBox: PropTypes.string,
  };

  static defaultProps = {
    viewBox: "0 0 24 24",
  };

  render() {
    const { children, style, viewBox, ...other } = this.props;

    const mergedStyles = Object.assign(
      {
        display: "inline-block",
        userSelect: "none",
      },
      style
    );

    return (
      <svg {...other} style={mergedStyles} viewBox={viewBox}>
        {children}
      </svg>
    );
  }
}

export default SvgIcon;
