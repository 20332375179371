import React from "react";
import Modal from "react-modal";

// CHILDREN
import ForgotUsernameEmailForm from "../../ForgotUsernameEmailForm";
import Loader from "../../../common/Op36Loader-web";

const ForgotUsernameModal = ({
  showForgotUsernameModal,
  closeForgotUsernameModal,
  handleSubmitForgotUsernameEmail,
  loading,
  emailSent,
}) => (
  <Modal
    isOpen={showForgotUsernameModal}
    onRequestClose={closeForgotUsernameModal}
    contentLabel='Example Modal'
  >
    <div className='modal-header row'>
      <div className='col-11'>
        <h5 className='modal-title display-4'>Forgot Username</h5>
      </div>
      <div className='col-1'>
        <button
          type='button'
          className='close pull-right font-200'
          data-dismiss='modal'
          aria-label='Close'
          onClick={closeForgotUsernameModal}
        >
          <span aria-hidden='true'>✕</span>
        </button>
      </div>
    </div>
    <div className='modal-content'>
      <div className='modal-body' id='input-border'>
        <div className='row mb-2 p-2'>
          <div className='col'>
            {loading ? (
              <Loader message='sending email' />
            ) : (
              <div className='form-group'>
                <ForgotUsernameEmailForm
                  handleSubmit={handleSubmitForgotUsernameEmail}
                  emailSent={emailSent}
                  closeModal={closeForgotUsernameModal}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  </Modal>
);

export default ForgotUsernameModal;
