import * as types from '../actions/actionTypes';
// import update from 'immutability-helper';

// state = [] : Start out with no accounts
export default function ranks(state = [], action){
  switch(action.type){

    case types.ADD_RANKS:
      return action.ranks;

    default:
      return state;
  }
}

// APPENDS ACTION.ACTIVITY ONTO THE STATE
// [...state, Object.assign({}, action.activity) ];
