import * as types from './actionTypes'
import * as common from './commonActions'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { serialize, deserialize } from '../utility/serialization'
import { createAsyncThunk } from '@reduxjs/toolkit'
import Axios from '../axios'
import { handleAPIError } from '../utility/actionHelper'
import { objectToFormData } from '../utility/formHelper'

export function successRequestingCommunity(response) {
  if (response.status === 200) {
    return {
      type: types.GET_COMMUNITY,
      community: response.data.community,
    }
  } else {
    return { type: types.UI_ERROR, error: response.data }
  }
}

export function successRequestingCommunityStudentsDates(response) {
  if (response.status === 200) {
    return {
      type: types.GET_COMMUNITY_STUDENTS_DATES,
      student_dates: response.data.member_dates,
    }
  } else {
    return { type: types.UI_ERROR, error: response.data }
  }
}

export function successRequestingCommunities(response) {
  if (response.status === 200) {
    return {
      type: types.ADD_COMMUNITIES,
      communities: response.data.communities,
    }
  } else {
    return { type: types.UI_ERROR, error: response.data }
  }
}

export function successRequestingSearchCommunities(response) {
  if (response.status === 200) {
    return {
      type: types.ADD_COMMUNITIES,
      communities: response.data.communities,
    }
  } else {
    return { type: types.UI_ERROR, error: response.data }
  }
}

export function successRequestingCreateCommunity(response) {
  if (response.status === 201) {
    return { type: types.POST_COMMUNITY, community: response.data.community }
  } else {
    return { type: types.UI_ERROR, error: response.data }
  }
}

export function successRequestingUpdateCommunity(response) {
  if (response.status === 200) {
    return { type: types.UPDATE_COMMUNITY, community: deserialize(response.data.community) }
  } else {
    return { type: types.UI_ERROR, error: response.data }
  }
}

export function successRequestingAllStudents(response) {
  if (response.status === 200) {
    return {
      type: types.GET_COMMUNITY_STUDENTS,
      students: response.data.students,
      pages: response.data.meta.pagination
        ? response.data.meta.pagination.total_pages
        : null,
    }
  } else {
    return { type: types.UI_ERROR, error: response.data }
  }
}

export function successRequestingStats(response) {
  if (response.status === 200) {
    return { type: types.GET_COMMUNITY_STATS, stats: response.data.stats }
  } else {
    return { type: types.UI_ERROR, error: response.data }
  }
}

export function successRequestingPipelineStats(response) {
  if (response.status === 200) {
    return {
      type: types.GET_COMMUNITY_PIPELINE_STATS,
      pipeline_stats: response.data.pipeline_stats,
    }
  } else {
    return { type: types.UI_ERROR, error: response.data }
  }
}

export function successRequestingInvitations(response) {
  if (response.status === 200) {
    return {
      type: types.GET_COMMUNITY_INVITATIONS,
      invitations: response.data.invitations,
    }
  } else {
    return { type: types.UI_ERROR, error: response.data }
  }
}

export function successRequestingCompleteOnboarding(response) {
  if (response.status === 200) {
    return {
      type: types.GET_COMMUNITY,
      community: response.data.community,
    }
  } else {
    return { type: types.UI_ERROR, error: response.data }
  }
}

export function successRequestingIncompleteOnboarding(response) {
  if (response.status === 200) {
    return {
      type: types.GET_COMMUNITY,
      community: response.data.community,
    }
  } else {
    return { type: types.UI_ERROR, error: response.data }
  }
}

export function successRequestingCompleteUpsell(response) {
  if (response.status === 200) {
    return {
      type: types.COMPLETE_COMMUNITY_UPSELL,
      community: response.data.community,
    }
  } else {
    return { type: types.UI_ERROR, error: response.data }
  }
}

export function successRequestingIncompleteUpsell(response) {
  if (response.status === 200) {
    return {
      type: types.INCOMPLETE_COMMUNITY_UPSELL,
      community: response.data.community,
    }
  } else {
    return { type: types.UI_ERROR, error: response.data }
  }
}

export function successHandlingCommunityMapFilter(response) {
  if (response.status === 200) {
    return {
      type: types.HANDLE_MAP_FILTER,
      community: response.data.community,
    }
  } else {
    return { type: types.UI_ERROR, error: response.data }
  }
}

export function successRequestingCommunityKeyMetrics(response) {
  if (response.status === 200) {
    // console.log("got response", response.data);
    return {
      type: types.GET_COMMUNITY_KEY_METRICS,
      key_metrics: response.data.key_metrics,
    }
  } else {
    return { type: types.UI_ERROR, error: response.data }
  }
}

export function successRequestingCommunitiesPaginated(response) {
  if (response.status === 200) {
    return {
      type: types.GET_COMMUNITIES_PAGINATED,
      communities: deserialize(response.data.communities),
      pages: response.data.meta.pagination
        ? response.data.meta.pagination.total_pages
        : null,
    }
  } else {
    return { type: types.UI_ERROR, error: deserialize(response.data) }
  }
}

// THUNKS
export function getCommunity(community_id) {
  return function (dispatch) {
    return requestCommunity(community_id).then((response) =>
      dispatch(successRequestingCommunity(response))
    )
  }
}

export const getCommunityLandingPageData = createAsyncThunk(
  'community/getLandingPageView',
  async (communityId, { rejectWithValue }) => {
    try {
      const { data } = await requestCommunityLandingPage(communityId)

      return deserialize(data)
    } catch (e) {
      return handleAPIError(e, rejectWithValue)
    }
  }
)

export const getCommunityLandingPageSettings = createAsyncThunk(
  'community/getLandingPageSettings',
  async (communityId, { rejectWithValue }) => {
    try {
      const { data } = await requestCommunityCoachSettings(communityId)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export function getCommunityStudentsDates(community_id) {
  return function (dispatch) {
    return requestCommunityStudentsDates(community_id).then((response) =>
      dispatch(successRequestingCommunityStudentsDates(response))
    )
  }
}

export function getCommunities(filter, rep_id) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestCommunities(filter, rep_id)
      .then((response) => dispatch(successRequestingCommunities(response)))
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function searchCommunities(searchTerm) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestSearchCommunities(searchTerm)
      .then((response) =>
        dispatch(successRequestingSearchCommunities(response))
      )
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function getCommunitiesPaginated(page, perPage, searchTerm) {
  return function (dispatch) {
    return requestCommunitiesPaginated(page, perPage, searchTerm)
      .then((response) => dispatch(successRequestingCommunitiesPaginated(response)))
  }
}


export function createCommunity(community) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestAdminCreateCommunity(community)
      .then((response) => dispatch(successRequestingCreateCommunity(response)))
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function updateCommunity(community) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestAdminUpdateCommunity(community)
      .then((response) => dispatch(successRequestingUpdateCommunity(response)))
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export const updateCommunityLandingPageSettings = createAsyncThunk(
  'community/updateCommunityLandingPageSettings',
  async ({ id, communityData }, { rejectWithValue }) => {
    try {
      const { data } = await requestUpdateCommunity(id, communityData)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export function getAllStudents(community_id, page, per_page, search) {
  return function (dispatch) {
    dispatch(showLoading())
    return requestGetAllStudents(community_id, page, per_page, search)
      .then((response) => dispatch(successRequestingAllStudents(response)))
      .then(() => dispatch(hideLoading()))
      .catch(() => dispatch(hideLoading()))
  }
}

export function getStats(community_id) {
  return function (dispatch) {
    dispatch(showLoading())
    return requestGetStats(community_id)
      .then((response) => dispatch(successRequestingStats(response)))
      .then(() => dispatch(hideLoading()))
      .catch(() => dispatch(hideLoading()))
  }
}

export function getPipelineStats(community_id, playType) {
  return function (dispatch) {
    return requestPipelineStats(community_id, playType).then((response) =>
      dispatch(successRequestingPipelineStats(response))
    )
  }
}

export function getCommunityInvitations(community_id) {
  return function (dispatch) {
    return requestGetInvitations(community_id).then((response) =>
      dispatch(successRequestingInvitations(response))
    )
  }
}

export function getAdminStats() {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestGetAdminStats()
      .then((response) => dispatch(successRequestingStats(response)))
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function completeOnboardingStep(community_id, onboarding_step_order) {
  return function (dispatch) {
    return requestCompleteOnboarding(community_id, onboarding_step_order).then(
      (response) => dispatch(successRequestingCompleteOnboarding(response))
    )
  }
}

export function incompleteOnboardingStep(community_id, onboarding_step_order) {
  return function (dispatch) {
    return requestIncompleteOnboarding(
      community_id,
      onboarding_step_order
    ).then((response) =>
      dispatch(successRequestingIncompleteOnboarding(response))
    )
  }
}

export function completeUpsellPurchase(community_id, productId) {
  return function (dispatch) {
    return requestCompleteUpsell(community_id, productId).then((response) =>
      dispatch(successRequestingCompleteUpsell(response))
    )
  }
}

export function incompleteUpsellPurchase(community_id, productId) {
  return function (dispatch) {
    return requestIncompleteUpsell(community_id, productId).then((response) =>
      dispatch(successRequestingIncompleteUpsell(response))
    )
  }
}

export function handleCommunityMapFilter(community_id, filter) {
  return function (dispatch) {
    return requestHandleMapFilter(community_id, filter).then((response) =>
      dispatch(successHandlingCommunityMapFilter(response))
    )
  }
}

export function getCommunityKeyMetrics(community_id) {
  return function (dispatch) {
    return requestCommunityKeyMetrics(community_id).then((response) =>
      dispatch(successRequestingCommunityKeyMetrics(response))
    )
  }
}

export function clearCommunityKeyMetrics() {
  return function (dispatch) {
    dispatch({ type: types.CLEAR_COMMUNITY_KEY_METRICS })
  }
}

// API CALLS
const requestCommunity = (communityId) => Axios.get(`/api/v1/communities/${communityId}`)

const requestCommunityCoachSettings = (communityId) => Axios.get(`/api/v1/communities/${communityId}/coach_settings`)

const requestCommunityLandingPage = (communityId) => Axios.get(`/api/v1/communities/${communityId}/landing_page`)

function requestCommunityStudentsDates(community_id) {
  const requestUrl = 'api/v1/communities/' + community_id + '/member_dates'
  return Axios.get(requestUrl)
}

function requestCommunities(filter, rep_id) {
  let requestUrl = '/api/v1/communities'

  if (filter) {
    requestUrl += `?representative_id=${rep_id}`
    switch (filter) {
      case 'Active Accounts':
        requestUrl += '&support_filter=active_accounts'
        break
      case 'Inactive Accounts':
        requestUrl += '&support_filter=inactive_accounts'
        break
      case 'New Accounts':
        requestUrl += '&support_filter=new_accounts'
        break
      case 'Never Contacted':
        requestUrl += '&support_filter=never_contacted'
        break
      case 'Critical Accounts':
        requestUrl += '&support_filter=critical_accounts'
        break
      case 'High Priority':
        requestUrl += '&support_filter=high_priority'
        break
      case 'Medium Priority':
        requestUrl += '&support_filter=medium_priority'
        break
      case 'Low Priority':
        requestUrl += '&support_filter=low_priority'
        break
      case 'Safe Accounts':
        requestUrl += '&support_filter=safe_accounts'
        break
      default:
        requestUrl += ''
    }
  }

  return Axios.get(requestUrl)
}

function requestSearchCommunities(searchTerm) {
  const requestUrl = `/api/v1/communities?search=${encodeURIComponent(searchTerm)}`
  return Axios.get(requestUrl)
}

function requestCommunitiesPaginated(page, perPage, searchTerm) {
  const requestUrl = '/api/v1/communities'
  const params = { page, perPage, searchTerm }

  return Axios.get(requestUrl, { params: serialize(params) })
}

const requestAdminCreateCommunity = (community) => {
  const requestUrl = '/api/v1/admin/communities'

  const data = new FormData()
  const nullableValues = ['email', 'phone_number', 'booking_url', 'hubspot_onboarding_id', 'hubspot_company_id']
  const complexAttributes = ['address', 'location']

  for (const key in community) {
    if (!complexAttributes.includes(key)) {
      data.append(key, community[key])
    }
  }

  data.append('address[name]', community.address)
  data.append('address[lat]', community.location.lat)
  data.append('address[lng]', community.location.lng)
  data.append('address[street]', community.location.street)
  data.append('address[zip_code]', community.location.zip_code)
  data.append('address[city]', community.location.city)
  data.append('address[state]', community.location.state)
  data.append('address[country]', community.location.country)

  nullableValues.forEach(key => {
    if (!community[key]) {
      data.delete(key)
    }
  })

  return Axios.post(requestUrl, data)
}

const requestAdminUpdateCommunity = (community) => {
  const requestUrl = '/api/v1/admin/communities/' + community.id

  const data = new FormData()
  const complexAttributes = ['address', 'location', 'completedOnboarding']

  for (const key in community) {
    if (!complexAttributes.includes(key)) {
      data.append(key, community[key])
    }
  }

  if (community.completed_onboarding) {
    data.append('completed_onboarding', JSON.stringify(community.completed_onboarding))
  }

  if (community.address && community.location) {
    data.append('address[name]', community.address)
    data.append('address[lat]', community.location.lat)
    data.append('address[lng]', community.location.lng)
    data.append('address[street]', community.location.street)
    data.append('address[zip_code]', community.location.zip_code)
    data.append('address[city]', community.location.city)
    data.append('address[state]', community.location.state || '')
    data.append('address[country]', community.location.country)
  }

  return Axios.put(requestUrl, data)
}

const requestUpdateCommunity = (id, communityData) => {
  const requestUrl = `/api/v1/communities/${id}`

  const filteredData = {
    description: communityData.description,
    bookingUrl: communityData.bookingUrl,
    courseCategoriesAttributes: communityData.categories.map((c, index) => ({
      id: c.id,
      order: index,
    })),
  }

  if (communityData.headerImageDelete) {
    filteredData.headerImageAttributes = { _destroy: true }
  }

  if (communityData.headerImageData) {
    filteredData.headerImageAttributes = {
      photo: communityData.headerImageData,
      name: communityData.headerImageName,
    }
  }

  const data = objectToFormData(serialize({ community: filteredData }))

  return Axios.patch(requestUrl, data)
}

function requestGetAllStudents(community_id, page, per_page, search) {
  let requestUrl = '/api/v1/communities/' + community_id + '/students'

  if (page && per_page) {
    requestUrl += `?page=${page}&per_page=${per_page}`
  }

  if (search) {
    requestUrl += `?search=${search}`
  }

  return Axios.get(requestUrl)
}

function requestGetAdminStats() {
  const requestUrl = '/api/v1/communities/admin_stats'
  return Axios.get(requestUrl)
}

function requestGetStats(community_id) {
  const requestUrl = '/api/v1/communities/' + community_id + '/stats'
  return Axios.get(requestUrl)
}

function requestPipelineStats(community_id, playType) {
  let requestUrl = '/api/v1/communities/' + community_id + '/pipeline_stats'
  if (playType) {
    requestUrl += '?play_type=' + playType
  }
  return Axios.get(requestUrl)
}

function requestGetInvitations(community_id) {
  const requestUrl = '/api/v1/communities/' + community_id + '/invitations'
  return Axios.get(requestUrl)
}

function requestCompleteOnboarding(community_id, onboarding_step_id) {
  const requestUrl = '/api/v1/complete_community_onboarding'

  const data = new FormData()

  data.append('community_id', community_id)
  data.append('community_onboarding_step_id', onboarding_step_id)

  return Axios.put(requestUrl, data)
}

function requestIncompleteOnboarding(community_id, onboarding_step_id) {
  const requestUrl = '/api/v1/incomplete_community_onboarding'

  const data = new FormData()

  data.append('community_id', community_id)
  data.append('community_onboarding_step_id', onboarding_step_id)

  return Axios.put(requestUrl, data)
}

function requestCompleteUpsell(community_id, productId) {
  const requestUrl = '/api/v1/complete_community_upsell'
  const data = {
    community_id: community_id,
    product_id: productId,
  }
  return Axios.put(requestUrl, data)
}

function requestIncompleteUpsell(community_id, productId) {
  const requestUrl = '/api/v1/incomplete_community_upsell'
  const data = {
    community_id: community_id,
    product_id: productId,
  }
  return Axios.put(requestUrl, data)
}

function requestHandleMapFilter(community_id, filter) {
  const requestUrl = '/api/v1/handle_community_map_filter'
  const data = {
    community_id: community_id,
    filter: filter,
  }
  return Axios.put(requestUrl, data)
}

function requestCommunityKeyMetrics(community_id) {
  const requestUrl = `/api/v1/communities/${community_id}/community_key_metrics`
  return Axios.get(requestUrl)
}
