import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef } from 'react'

import { getCommunityLandingPageCategories } from '../../actions/programCategoryActions'
import { getCommunityLandingPagePrograms } from '../../actions/programActions'
import { defaultPerPage, selectInitialFilters, setScrollPosition } from '../../reducers/communityLandingPageReducer'
import Loader from '../common/Op36Loader-web'
import ProgramList from './ProgramList'
import CategoryDetails from './CategoryDetails'
import Filters from './Filters'
import NoProgramsPlaceholder from './NoProgramsPlaceholder'

import classes from './ProgramsScreen.module.scss'

const ProgramsScreen = ({ className }) => {
  const dispatch = useDispatch()
  const initialFilters = useSelector(selectInitialFilters)
  const {
    courses,
    isLoadingCourses,
    isLoadingCategories,
    isLoadingDetailedCategory,
    community,
    selectedCategory,
    categories,
    scrollPosition,
  } = useSelector(state => state.communityLandingPage)
  const programsScreenRef = useRef(null)

  const isLoadingAllCategories = isLoadingCourses || !courses
  const isLoadingOneCategory = isLoadingDetailedCategory

  useEffect(() => {
    const currentRef = programsScreenRef
    currentRef?.current?.scrollTo(0, scrollPosition)

    return () => {
      if (currentRef.current) {
        const { scrollTop } = currentRef.current
        dispatch(setScrollPosition(scrollTop))
      }
    }
  }, [dispatch, scrollPosition])

  useEffect(() => {
    if (categories.ids.length === 0) {
      dispatch(getCommunityLandingPageCategories(community.id))
    }
  }, [categories.ids.length, community.id, dispatch])

  useEffect(() => {
    if (!courses && !isLoadingCategories) {
      dispatch(getCommunityLandingPagePrograms({ communityId: community.id, filters: initialFilters, page: 1, perPage: defaultPerPage }))
    }
  }, [community.id, courses, dispatch, initialFilters, isLoadingCategories])

  return (
    <div
      id='programsScreen'
      ref={programsScreenRef}
      className={classnames('flex-column p-3', className, classes.programsScreen)}
    >
      <div className='d-flex justify-content-between'>
        <Filters />
        {!!community.bookingUrl && (
          <a
            href={community.bookingUrl}
            className={classnames('flex-shrink-0 d-none d-lg-block', classes.bookingLink)}
            target='_blank'
          >
            Book a Private Lesson<i className={classnames('ml-1 fa fa-angle-right ml-3', classes.angleRight)} />
          </a>
        )}
      </div>
      {isLoadingAllCategories || isLoadingOneCategory ? (
        <Loader message='loading programs' />
      ) : courses.length === 0 ? (
        <NoProgramsPlaceholder />
      ) : (selectedCategory ? (
        <CategoryDetails />
      ) : (
        <ProgramList ref={programsScreenRef} />
      ))}
    </div>
  )
}

ProgramsScreen.propTypes = {
  className: PropTypes.string,
}

export default ProgramsScreen
