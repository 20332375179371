import { useEffect, useState, forwardRef, useCallback } from 'react'
import _ from 'lodash'
import classnames from 'classnames'
import { Popover, PopoverBody } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'

import SearchInput from '../../common/SearchInput'
import { pluralize } from '../../../utility/helperFunctions'
import Select from '../../common/form/Select'
import SubmitButton from '../../common/buttons/SubmitButton'
import CancelButton from '../../common/buttons/CancelButton'
import { selectAllCategoryOptions, perPage, selectInitialFilters } from '../../../reducers/groupProgramsReducer'
import { getCoachPrograms } from '../../../actions/programActions'

import { ReactComponent as DropDownIcon } from '../../../assets/images/common/icons/select-drop-down-icon-light-navy.svg'
import { ReactComponent as FilterIcon } from '../../../assets/images/common/icons/filter-icon.svg'
import { ReactComponent as OutsideLinkIcon } from '../../../assets/images/common/icons/outside-link-icon.svg'
import styles from './CoachProgramsFilterBar.module.scss'

// eslint-disable-next-line react/prop-types
const DatePickerInput = forwardRef(({ value, onClick }, ref) => (
  <button className={styles.datePickerInput} onClick={onClick} ref={ref}>
    {value || 'Select date'}
    <DropDownIcon/>
  </button>
))

DatePickerInput.displayName = 'DatePickerInput'

const CoachProgramsFilterBar = ({
  filters,
  filteredCategory,
  filtersNumber,
  search,
  tab }) => {

  const dispatch = useDispatch()
  const [filtersMenuOpen, setFiltersMenuOpen] = useState(false)
  const [newFilters, setNewFilters] = useState(null)
  const [searchInput, setSearchInput] = useState(search || '')
  const initialFilters = useSelector(selectInitialFilters)
  const categoryOptions = useSelector(selectAllCategoryOptions)
  const communityId = useSelector(state => state.user.current_user.communities[0].id)

  const paymentOptions = [{ value: ['card', 'cash'], label: 'All' }, { value: 'card', label: 'Online Payment' },
    { value: 'cash', label: 'Offline Payment' }]
  const visibilityOptions = [{ value: ['public', 'unlisted', 'private'], label: 'All' },
    { value: 'public', label: 'Public' }, { value: 'unlisted', label: 'Unlisted' }, { value: 'private', label: 'Private' }]

  const handleOpenFiltersMenuClick = () => {
    setFiltersMenuOpen(value => !value)
  }

  const categoryOptionColorDot = (color = 'transparent') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10,
    },
  })

  useEffect(() => {
    if (filtersMenuOpen) {
      setNewFilters({
        ...filters,
        categoryName: filteredCategory.name,
        categoryColor: filteredCategory.color,
        paymentMethodType: selectFilteredPayment(filters.paymentMethod),
        visibilityType: selectFilteredVisibility(filters.visibility),
      })
    }
  }, [filters, filtersMenuOpen, filteredCategory])

  const applyFilters = () => {
    reloadPrograms({ filters: newFilters, search: search, tab: tab })
    setFiltersMenuOpen(false)
  }

  const categorySelectColourStyles = {
    option: (styles, { data }) => ({
      ...styles,
      ...categoryOptionColorDot(data.color),
    }),
    singleValue: (styles, { data }) => (data.color ? { ...styles, ...categoryOptionColorDot(data.color) } : styles),
  }

  const selectFilteredPayment = (payment) => {
    if (_.isEqual(payment, ['card', 'cash'])){
      return 'All'
    } else {
      return payment === 'card' ? 'Online Payment' : 'Offline Payment'
    }
  }

  const selectFilteredVisibility = (visibility) => {
    if (_.isEqual(visibility, ['public', 'unlisted', 'private'])){
      return 'All'
    } else {
      return _.capitalize(visibility)
    }
  }

  const reloadPrograms = ({ filters, search, tab }) => {
    if (filters) {
      const requestFilters = {
        categoryId: filters.categoryId,
        paymentMethod: filters.paymentMethod,
        visibility: filters.visibility,
        startDate: filters.startDate,
        endDate: filters.endDate,
      }

      dispatch(getCoachPrograms({
        filters: { ...requestFilters, [tab]: 'true', search: search },
        page: 1,
        perPage: perPage,
        withEnrollments: 'true',
      }))
    } else {
      dispatch(getCoachPrograms({
        filters: { ...initialFilters, [tab]: 'true', search: search },
        page: 1,
        perPage: perPage,
        withEnrollments: 'true',
      }))
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedReload = useCallback(
    _.debounce((value, filters) => {
      reloadPrograms({ filters: filters, search: value, tab: tab })
    }, 400),
    []
  )

  const handleSearchInput = (value) => {
    setSearchInput(value)
    debouncedReload(value, filters)
  }

  return (
    <>
      <div className={styles.coachProgramsFilterBar}>
        <div className={styles.programsSearchBar}>
          <SearchInput placeholder='Search Programs' onChange={(e) => handleSearchInput(e.target.value)} value={searchInput}/>
        </div>
        <div className='d-flex flex-grow-1 align-items-center'>
          <button
            id='programsPageFiltersButton'
            className={classnames('flex-grow-1 flex-lg-grow-0 d-flex align-items-center', styles.filtersButton)}
          >
            <FilterIcon className='mx-2' />
            {filtersNumber > 0 ? `${filtersNumber} ${pluralize('filter', filtersNumber)} applied` : 'Filters'}
          </button>
          {filtersNumber > 0 && (
            <button
              className={classnames('ml-3 animation-shrink d-flex align-items-center', styles.resetFiltersButton)}
              onClick={() => reloadPrograms({ tab: tab, search: search })}
            >
              Clear <i className='ml-2 fa fa-close' />
            </button>
          )}
        </div>
        <Link to={`/landing/${communityId}`} target='_blank' className='d-flex align-items-center view-community-link'>
          <OutsideLinkIcon id='outsideLinkIcon'/>
          <span className='view-community-label'>View Landing Page</span>
        </Link>
      </div>

      <Popover
        target='programsPageFiltersButton'
        isOpen={filtersMenuOpen}
        placement='bottom-start'
        trigger='legacy'
        toggle={handleOpenFiltersMenuClick}
        popperClassName={styles.filtersMenu}
      >
        <PopoverBody className={'p-3'}>
          <p className={styles.filterTitle}>Program Filters</p>

          <div className='d-flex'>
            <div>
              <label className={styles.filterName} htmlFor='startDatePicker'>Start Date</label>
              <DatePicker
                id='startDatePicker'
                selected={newFilters?.startDate}
                customInput={<DatePickerInput/>}
                onChange={(date) => setNewFilters(prevState => ({ ...prevState, startDate: date }))}
                dateFormat='MMM d, yyyy'
              />
            </div>
            <div>
              <label className={styles.filterName} htmlFor='endDatePicker'>End Date</label>
              <DatePicker
                id='endDatePicker'
                selected={newFilters?.endDate}
                customInput={<DatePickerInput/>}
                onChange={(date) => setNewFilters(prevState => ({ ...prevState, endDate: date }))}
                dateFormat='MMM d, yyyy'
              />
            </div>
          </div>

          <p className={styles.filterName}>Program Category</p>
          <Select
            isSearchable={false}
            options={categoryOptions}
            styles={categorySelectColourStyles}
            value={{ value: newFilters?.categoryId, label: newFilters?.categoryName, color: newFilters?.categoryColor }}
            classNames={{
              menuList: () => styles.selectMenuList,
            }}
            onChange={option => {
              setNewFilters(prevState => ({ ...prevState, categoryId: option.value, categoryName: option.label, categoryColor: option.color }))
            }}
          />

          <p className={styles.filterName}>Payment Types</p>
          <Select
            isSearchable={false}
            options={paymentOptions}
            value={{ value: newFilters?.paymentMethod, label: newFilters?.paymentMethodType }}
            classNames={{
              menuList: () => styles.selectMenuList,
            }}
            onChange={option => setNewFilters(prevState => ({ ...prevState, paymentMethod: option.value, paymentMethodType: option.label }))} />

          <p className={styles.filterName}>Privacy</p>
          <Select
            isSearchable={false}
            options={visibilityOptions}
            value={{ value: newFilters?.visibility, label: newFilters?.visibilityType }}
            classNames={{
              menuList: () => styles.selectMenuList,
            }}
            onChange={option => setNewFilters(prevState => ({ ...prevState, visibility: option.value, visibilityType: option.label }))}
            menuPlacement='top' />

          <div className={styles.buttons}>
            <CancelButton buttonMessage='Cancel' className='mt-3' onClick={() => setFiltersMenuOpen(false)} />
            <SubmitButton buttonMessage='Apply' onClick={applyFilters}/>
          </div>

        </PopoverBody>
      </Popover>
    </>
  )
}

CoachProgramsFilterBar.propTypes = {
  filters: PropTypes.object,
  filteredCategory: PropTypes.object,
  filtersNumber: PropTypes.number,
  search: PropTypes.string,
  tab: PropTypes.string,
}

export default CoachProgramsFilterBar
