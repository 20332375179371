// These questions follow the free Op36 coach account registration questions, but they are not required.

import aboutIcon from "../../../../assets/images/registration-coach/about-facility-icon.png";
import locIcon from "../../../../assets/images/registration-coach/facility-location-icon.png";
import hoursIcon from "../../../../assets/images/registration-coach/coaching-hours-icon.png";
import programsIcon from "../../../../assets/images/registration-coach/current-programs-icon.png";
import blockersIcon from "../../../../assets/images/registration-coach/program-roadblocks-icon.png";
import partnersIcon from "../../../../assets/images/registration-coach/partner-affiliations-icon.png";

const onboardingQuestions = [
  {
    id: 'facility_about',
    icon: aboutIcon,
    section_name: 'Tell us about your facility.',
    section_desc: '',
    questions: [
      {
        id: 'community_name',
        title: 'The name of my golf facility is...',
        type: 'input',
        placeholder: 'Facility name',
        errorMsg: 'Please enter your facility\'s name'
      },
      {
        id: 'community_type',
        title: 'My facility type is...',
        type: 'dropdown',
        placeholder: 'Select facility type',
        choices: [
          'Private',
          'Semi-private',
          'Public',
          'Resort',
          'Indoor',
          'Driving range'
        ],
        other_choice: true
      },
      {
        id: 'season_length',
        title: 'My facility’s season length is...',
        type: 'dropdown',
        placeholder: 'Select season length',
        choices: [
          '1 - 2 months',
          '3 - 4 months',
          '5 - 6 months',
          '7 - 8 months',
          '9 - 10 months',
          '11 - 12 months'
        ],
        other_choice: false
      }
    ]
  },

  // Special case - use PlacesAutocomplete for this type of dropdown
  {
    id: 'location',
    icon: locIcon,
    section_name: 'Where are you located?',
    section_desc: '',
    questions: [
      {
        id: 'location',
        title: '[facility name] is located in...',
        type: 'dropdown',
        placeholder: 'Select country',
        choices: [
          'United States of America'
        ],
        other_choice: false
      }
    ]
  },

  {
    id: 'hours',
    icon: hoursIcon,
    section_name: 'How much are you coaching?',
    section_desc: 'This will help us find the programs that best fit your schedule.',
    questions: [
      {
        id: 'hours',
        title: 'I can dedicate _____ a week to coaching.',
        type: 'multiple_choice',
        choices: [
          '0 hours',
          '1 - 5 hours',
          '6 - 10 hours',
          '11 - 15 hours',
          '16 - 20 hours',
          '21 - 25 hours',
          '26 - 30 hours',
          '31+ hours'
        ],
        other_choice: false,
        multi_select: false
      },
    ]
  },
  {
    id: 'programs',
    icon: programsIcon,
    section_name: 'Which programs do you currently run?',
    section_desc: 'The Op 36 Model can help enhance any program you run.',
    questions: [
      {
        id: 'programs',
        title: 'Our team currently runs the following:',
        type: 'multiple_choice',
        choices: [
          'PGA Jr. League',
          'The First Tee',
          'LPGA*USGA Girls Golf',
          'LPGA Golf 101',
          'Get Golf Ready',
          'Junior camps',
          'Junior clinics',
          'Adult clinics',
          'Private lessons',
          'Leagues'
        ],
        other_choice: true,
        multi_select: true
      },
    ]
  },

  {
    id: 'pain_points',
    icon: blockersIcon,
    section_name: 'What are your program roadblocks?',
    section_desc: 'Select the challenges that you wish you could overcome.',
    questions: [
      {
        id: 'pain_points',
        title: 'I struggle with...',
        type: 'multiple_choice',
        choices: [
          'Organizing staff to deliver classes',
          'Giving golfers a structured curriculum',
          'Giving golfers a model to learn to play',
          'Easily tracking a golfer\'s development',
          'Filling classes and marketing efficiently'
        ],
        other_choice: true,
        multi_select: true
      },
    ]
  },

  {
    id: 'affiliations',
    icon: partnersIcon,
    section_name: 'Finally, which of our partners are you affiliated with?',
    section_desc: 'We have multiple partners we work with to make it even easier to start.',
    questions: [
      {
        id: 'affiliations',
        title: 'My affiliations and certifications:',
        type: 'multiple_choice',
        choices: [
          'LPGA Professional',
          'PGA Professional',
          'First Tee Location',
          'Birdie Basics Member',
          'Proponent Group Member',
          'Golf Business Network Member',
          'TPI Certified'
        ],
        other_choice: true,
        multi_select: true
      },
    ]
  },
];

export default onboardingQuestions;
