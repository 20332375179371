import * as types from '../actions/actionTypes';

const initialState = {
  '1': [],
  '2': [],
  '3': [],
  '4': [],
  '5': [],
  '6': [],
}

const objectiveReducer = (objectives = initialState, action) => {
  switch (action.type) {
    case types.ADD_RANK_OBJECTIVES:
      return {
        ...objectives,
        [action.rankOrder]: action.objectives,
      }
    case types.UPDATE_OBJECTIVE:
      const newItem = action.objective;

      return {
        ...objectives,
        [newItem.level]: objectives[newItem.level].map(oldItem => {
          if (oldItem.id !== newItem.id) {
            return oldItem
          }
          return { ...oldItem, ...newItem }
        }),
      }
    default:
      return objectives;
  }
}

export default objectiveReducer
