import * as types from "./actionTypes";
import Axios from '../axios'

export function successRequestingCommunityStages(response) {
  if (response.status === 200) {
    return {
      type: types.GET_COMMUNITY_STAGES,
      community_stages: response.data.community_stages
    };
  } else {
    return { type: types.UI_ERROR, error: response.data };
  }
}

export function successCreatingCommunityStage(response) {
  if (response.status === 200) {
    return {
      type: types.CREATE_COMMUNITY_STAGE,
      community_stage: response.data.community_stage
    };
  } else {
    return { type: types.UI_ERROR, error: response.data };
  }
}

export function successDeletingCommunityStage(response) {
  if (response.status === 200) {
    return {
      type: types.DELETE_COMMUNITY_STAGE,
      community_stage: response.data.community_stage
    };
  } else {
    return { type: types.UI_ERROR, error: response.data };
  }
}

// THUNKS
export function getCommunityStages() {
  return function(dispatch) {
    return requestCommunityStages().then(response =>
      dispatch(successRequestingCommunityStages(response))
    );
  };
}

export function createCommunityStage(community_stage) {
  return function(dispatch) {
    return requestCreateCommunityStage(community_stage).then(response =>
      dispatch(successCreatingCommunityStage(response))
    );
  };
}

export function deleteCommunityStage(community_stage) {
  return function(dispatch) {
    return requestDeleteCommunityStage(community_stage).then(response =>
      dispatch(successDeletingCommunityStage(response))
    );
  };
}

// REQUESTS
function requestCommunityStages() {
  let requestUrl = "/api/v1/community_stages";
  return Axios.get(requestUrl);
}

function requestCreateCommunityStage(community_stage) {
  let requestUrl = "/api/v1/community_stages";

  var data = new FormData();

  for (var key in community_stage) {
    if (community_stage.hasOwnProperty(key) && community_stage[key] !== "") {
      data.append(key, community_stage[key]);
    }
  }

  return Axios.post(requestUrl, data);
}

function requestDeleteCommunityStage(community_stage) {
  let requestUrl = "/api/v1/community_stages/" + community_stage.id;

  return Axios.delete(requestUrl);
}
