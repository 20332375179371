import CSSTransition from 'react-transition-group/CSSTransition';
import React from 'react';
export const FADE_DURATION = 100;

export const Fade = ({ children, ...props }) => (
  <CSSTransition
    timeout={500}
    {...props}
    classNames="fade">
    {children}
  </CSSTransition>
);
