import * as constants from "./actionTypes";
import * as common from "./commonActions";

import Axios from '../axios'

export function successRequestingGetCoaches(response) {
  if (response.status === 200) {
    return { type: constants.ADD_COACHES, coaches: response.data.coaches };
  } else {
    console.log("ERROR ON SIGNIN");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

// export function successRequestingSyncMailChimp(response){
//   if(response.status === 200){
//     return { type: constants.SYNC, coaches: response.data.sync};
//   }else{
//     console.log("ERROR ON SYNCING MAILCHIMP")
//     return {type: constants.UI_ERROR, error: response.data}
//   }
// }

export function successRequestingCreateCoach(response) {
  if (response.status === 201) {
    return { type: constants.CREATE_COACH, coach: response.data.user };
  } else {
    console.log("ERROR ON SIGNIN");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successRequestingUpdateCoach(response) {
  if (response.status === 200) {
    return { type: constants.UPDATE_COACH, coach: response.data.user };
  } else {
    console.log("ERROR ON SIGNIN");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successAddingStudentEnrollment(response) {
  if (response.status === 200 || response.status === 201) {
    return {
      type: constants.GET_COURSE,
      course: response.data.course,
      enrollment: response.data.enrollment,
    };
  } else {
    console.log("ERROR ON ADDING FAMILY ENROLLMENT");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successRemovingStudentEnrollment(response) {
  if (response.status === 200 || response.status === 201) {
    return {
      type: constants.REMOVE_STUDENTS,
      enrollment: response.data.enrollment,
      course: response.data.course,
    };
  } else {
    console.log("ERROR ON ADDING FAMILY ENROLLMENT");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

// THUNKS HERE
export function getCoaches(searchTerm) {
  return function (dispatch) {
    return requestGetCoaches(searchTerm).then((response) =>
      dispatch(successRequestingGetCoaches(response))
    );
  };
}

// export function SyncMailChimp(){
//   return function(dispatch){
//     dispatch(common.loadingChanged(true));
//     return requestSyncMailChimp().then(
//       response => dispatch(successRequestingSyncMailChimp(response))
//     ).then(
//       turnOffSpinner => dispatch(common.loadingChanged(false))
//     )
//   }
// }

export function createCoach(coach) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true));
    return requestCreateCoach(coach)
      .then((response) => dispatch(successRequestingCreateCoach(response)))
      .then((turnOffSpinner) => dispatch(common.loadingChanged(false)));
  };
}

export function updateCoach(coach) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true));
    return requestUpdateCoach(coach)
      .then((response) => dispatch(successRequestingUpdateCoach(response)))
      .then((turnOffSpinner) => dispatch(common.loadingChanged(false)));
  };
}

export function adminUpdateCoach(coach) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true));
    return requestAdminUpdateCoach(coach)
      .then((response) => dispatch(successRequestingUpdateCoach(response)))
      .then((turnOffSpinner) => dispatch(common.loadingChanged(false)));
  };
}

export function addStudentEnrollment(user_id, course) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true));

    return requestAddStudentEnrollment(user_id, course)
      .then((response) => dispatch(successAddingStudentEnrollment(response)))
      .then((turnOffSpinner) => dispatch(common.loadingChanged(false)));
  };
}

export function removeStudentEnrollment(enrollment_id, course) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true));

    return requestRemoveStudentEnrollment(enrollment_id, course)
      .then((response) => dispatch(successRemovingStudentEnrollment(response)))
      .then((turnOffSpinner) => dispatch(common.loadingChanged(false)));
  };
}

// API CALLS
function requestGetCoaches(searchTerm) {
  let requestUrl = "/api/v1/coaches";

  if (searchTerm) requestUrl += `?search=${searchTerm}`;
  return Axios.get(requestUrl);
}

// function requestSyncMailChimp(){
//   let requestUrl = '/api/v1/sync'
//   return Axios.get(requestUrl)
// }

function requestCreateCoach(coach) {
  let requestUrl = "/api/v1/users";
  return Axios.post(requestUrl, {
    type: coach.type,
    first_name: coach.first_name,
    last_name: coach.last_name,
    email: coach.email,
    password: coach.password,
    community_id: coach.community_id,
    coach_permission: coach.permission,
    gender: coach.gender,
    birthdate: coach.birthdate,
  });
}

function requestUpdateCoach(coach) {
  let requestUrl = "/api/v1/users/" + coach.id + "/";
  return Axios.put(requestUrl, {
    first_name: coach.first_name,
    last_name: coach.last_name,
    email: coach.email,
    password: coach.password,
    current_password: coach.current_password,
    ...(coach.gender && { gender: coach.gender }),
    //community_id: coach.community_id
  });
}

function requestAdminUpdateCoach(coach) {
  let requestUrl = "/api/v1/users/" + coach.id + "/admin_update_coach";
  return Axios.post(requestUrl, {
    first_name: coach.first_name,
    last_name: coach.last_name,
    email: coach.email,
    password: coach.password,
    current_password: coach.current_password,
    coach_permission: coach.permission,
    gender: coach.gender,
    birthdate: coach.birthdate,
    //community_id: coach.community_id
  });
}

function requestAddStudentEnrollment(user_id, course) {
  let requestUrl = "/api/v1/enrollments";
  //var rank_id = course.ranks.sort(function(a, b) { return a.id - b.id })[0].id
  return Axios.post(requestUrl, {
    user_id: user_id,
    course_id: course.id,
    //current_rank_id: rank_id,
    status: "active",
  });
}

function requestRemoveStudentEnrollment(enrollment_id, course) {
  // let requestUrl = '/api/v1/enrollments/' + enrollment_id;
  // return Axios.delete(requestUrl);
  let requestUrl = "/api/v1/enrollments/" + enrollment_id;
  return Axios.delete(requestUrl);
  // return Axios.put(requestUrl, {
  //   course_id: null
  // });
}
