import React, { Component } from "react";

// import "./SinglePhotoUploader.scss";
import StockProfilePic from "../../../../assets/images/mock/StockProfilePic.png";

// Derived from SimplePhotoUploader in components/common
// and SinglePhotoUploader in components/proShopDisplay

// if you need a 2nd photo uploader on the same page, pass prop -> second={true} to the second one.

class PhotoUploader extends Component {
  constructor(props) {
    super(props);

    const setImage = () => {
      const { photo, selectedPhoto } = props;

      if (photo) {
        return photo.file_url;
      } else if (selectedPhoto) {
        return selectedPhoto;
      }
      return "";
    };

    this.state = {
      name: "",
      file: null,
      src: setImage()
    };
  }

  clearImage = () => {
    const { sendPhotoData } = this.props;

    this.setState(
      {
        name: "",
        file: null,
        src: ""
      },
      () => {
        // sendPhotoData(this.state);
        sendPhotoData(this.state.file);
      }
    );
  };
  uploadClicked = () => {
    const { second } = this.props;
    document
      .getElementById(
        second ? "input-photo-upload-second" : "input-photo-upload"
      )
      .click();
  };

  onChangePhotoInput = event => {
    const { sendPhotoData } = this.props;

    // Make sure file upload wasn't canceled - only update
    // file info with valid values
    if (event.target.value && event.target.files[0]) {
      this.setState({
        name: event.target.value,
        file: event.target.files[0]
      });
    }

    let reader = new window.FileReader();
    let page = this;
    reader.onload = function(e) {
      page.setState(
        {
          src: e.target.result
        },
        () => {
          // sendPhotoData(page.state);
          sendPhotoData(page.state.file);
        }
      );
    };

    // Only pass new file into readAsDataURL if file upload
    // wasn't canceled
    if (event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
    }
    // Delete this (it causes duplicate upload if upload canceled):
    // } else {
    //   reader.readAsDataURL(this.state.file);
    // }
  };
  render() {
    // const { src } = this.state;

    // Extract filename from filepath - get the part of the path
    // after the last forward slash
    // let fileName = 'File name';
    // console.log('upload photo src: ' + src);
    // if (this.state.name.length > 0) {
    //   const lastIdx = this.state.name.lastIndexOf('\\');
    //   fileName = this.state.name.substring(lastIdx + 1);
    // }

    return (
      <div id='CoachRegistrationPhotoUploader'>
        {/* src ? (
          <div className='uploaded-image-container'>
            <div
              className='uploaded-image-wrapper animation-shrink'
              onClick={this.uploadClicked}
            >
              <img
                className='uploaded-image-thumbnail'
                src={src}
                alt='Uploaded Image' />
            </div>
            <div className="uploaded-file-info-wrapper">
              <p className='uploaded-file-name'>Attached file: <br />{fileName}</p>
              <div className='clear-image-wrapper' onClick={this.clearImage}>
                <i className='fa fa-times' aria-hidden='true' />
                <p>clear image</p>
              </div>
            </div>
          </div>
        ) : (
          <button className='empty-uploader animation-shrink'
          onClick={this.uploadClicked}>Upload an image</button>
        ) */}
        <div
          className='account-created-step-img-uploader'
          onClick={this.uploadClicked}>
          <img
            className='account-created-step-img-uploader-img'
            src={this.state.src ? this.state.src : StockProfilePic}
            alt='Add an optional profile illustration'/>

          <input
            name={""}
            id={`input-photo-upload${this.props.second ? "-second" : ""}`}
            type={"file"}
            accept={"image/*"}
            className={"hide form-control-file photoInput"}
            onChange={this.onChangePhotoInput}
            placeholder={"Add a Different Photo"}
          />
        </div>
        {this.state.src
          ? (
            <button
              className='no-bg-text-btn account-created-step-img-uploader-button' onClick={this.clearImage}>
              Remove profile picture
            </button>
          )
          : (
            <button
              className='no-bg-text-btn account-created-step-img-uploader-button' onClick={this.uploadClicked}>
              + Add profile picture
            </button>
          )
        }
      </div>
    );
  }
}

export default PhotoUploader;
