import React from "react";

import "./PlaceholderPreviewCard.scss";

class PlaceholderPreviewCard extends React.Component {
  render() {
    const { active, message, removeActive, blockIndex, taskIndex } = this.props;
    return (
      <div id="PlaceholderPreviewCard">
        <p className="placeholder-card-message">{message}</p>
        {active && (
          <p
            className="placeholder-card-action"
            onClick={e => removeActive(e, blockIndex, taskIndex)}
          >
            Click here to remove
          </p>
        )}
      </div>
    );
  }
}

export default PlaceholderPreviewCard;
