import { array } from "prop-types";
import React from "react";
import BaseModal from "../../common/BaseModal";

import "./DrillFailedValidationModal.scss";

class DrillFailedValidationModal extends React.Component {
  render() {
    const { fails, close, is_drill, navigate, navigateText } = this.props;
    return (
      <BaseModal close={close}>
        <div id='DrillFailedValidationModal'>
          <h4>Oops</h4>
          <p className='subtitle'>
            Please correct the following before moving forward:
          </p>
          <div
            style={{
              width: "100%",
              height: 1,
              background: "rgba(95, 111, 124, 0.5)",
              marginBottom: 5,
            }}
          />
          <div className='fail-container'>
            {this._renderFails(fails, is_drill)}
          </div>
          {navigateText ? (
            <div className='two-button-container'>
              <button className='cancel-button' onClick={close}>
                Cancel
              </button>
              <button className='navigate-button' onClick={navigate}>
                {navigateText}
              </button>
            </div>
          ) : (
            <button className='full-length-btn' onClick={close}>
              Ok
            </button>
          )}
        </div>
      </BaseModal>
    );
  }

  _renderFails = (fails, is_drill) => {
    let name = is_drill ? "drill" : "activity";
    let arr = [];
    fails.forEach((fail, i) => {
      switch (fail) {
        case "title":
          arr.push(
            <p key={i} className='fail-text'>
              Your {name} needs a <span className='orange-text'>name</span>
            </p>
          );
          break;
        case "description":
          arr.push(
            <p key={i} className='fail-text'>
              Your {name} needs a{" "}
              <span className='orange-text'>description</span>
            </p>
          );
          break;
        case "equipment":
          arr.push(
            <p key={i} className='fail-text'>
              Add at least one piece of{" "}
              <span className='orange-text'>equipment</span> needed for this{" "}
              {name}
            </p>
          );
          break;
        case "steps":
          arr.push(
            <p key={i} className='fail-text'>
              Add at least one <span className='orange-text'>step</span> needed
              to get this {name} set up
            </p>
          );
          break;
        case "blank_equipment":
          arr.push(
            <p key={i} className='fail-text'>
              Looks like one of your{" "}
              <span className='orange-text'>equipment</span> fields is blank.
              Delete it if you don't want it
            </p>
          );
          break;
        case "blank_steps":
          arr.push(
            <p key={i} className='fail-text'>
              Looks like one of your{" "}
              <span className='orange-text'>steps to setup</span> fields is
              blank. Delete it if you don't want it
            </p>
          );
          break;
        case "content":
          arr.push(
            <p key={i} className='fail-text'>
              Your {name} needs some content in the{" "}
              <span className='orange-text'>'About this drill'</span> section
            </p>
          );
          break;
        case "privacy":
          arr.push(
            <p key={i} className='fail-text'>
              Set who can see your new {name} under the{" "}
              <span className='orange-text'>visibility</span> tab
            </p>
          );
          break;
        case "draft_title":
          arr.push(
            <p key={i} className='fail-text'>
              Your {name} at least needs a{" "}
              <span className='orange-text'>name</span> before you can save it
              as a draft
            </p>
          );
          break;
        case "numeric_scoring_measurement":
          arr.push(
            <p key={i} className='fail-text'>
              Numerical scoring is enabled, but you haven't answered{" "}
              <span className='orange-text'>What are you measuring?</span>
            </p>
          );
          break;
        case "star_high_low_label":
          arr.push(
            <p key={i} className='fail-text'>
              Star feedback is enabled, but you haven't filled out the{" "}
              <span className='orange-text'>Lowest/Highest value labels</span>
            </p>
          );
          break;
        case "star_question_to_ask":
          arr.push(
            <p key={i} className='fail-text'>
              Star feedback is enabled, but you haven't filled in the{" "}
              <span className='orange-text'>Question to ask</span>
            </p>
          );
          break;
        case "drill_tier":
          arr.push(
            <p key={i} className='fail-text'>
              Oh my goodness gracious! I can't tell if this thing is{" "}
              <span className='orange-text'>Premium</span> or{" "}
              <span className='orange-text'>Basic</span>
            </p>
          );
          break;
        default:
          arr.push(
            <p key={i} className='fail-text'>
              Something went wrong
            </p>
          );
          break;
      }
    });
    return arr;
  };
}

export default DrillFailedValidationModal;
