import ReactToggle from 'react-toggle'
import 'react-toggle/style.css';

const Toggle = (props) => {

  return (
    <ReactToggle
      {...props}
    />
  )
}

export default Toggle
