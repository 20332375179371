import * as constants from "./actionTypes";
import Axios from '../axios'

function successRequestingRankObjectives(response, rankOrder) {
  if (response.status === 200) {
    return {
      type: constants.ADD_RANK_OBJECTIVES,
      objectives: response.data.objectives,
      rankOrder,
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successRequestingCommunitySocialObjectives(response) {
  if (response.status === 200) {
    return {
      type: constants.ADD_SOCIAL_OBJECTIVES,
      social_objectives: response.data.social_objectives,
    };
  } else {
    return {
      type: constants.UI_ERROR,
      error: response.data,
    };
  }
}

function successRequestingUpdateObjective(objective) {
  return {
    type: constants.UPDATE_OBJECTIVE,
    objective: objective,
  };
}

function failureRequestingUpdateObjective(response) {
  return {
    type: constants.UI_ERROR,
    error: response.data,
  };
}

function successRequestingCompleteObjective(response) {
  if (response.status === 201) {
    return {
      type: constants.COMPLETE_OBJECTIVE,
      user_curriculum: response.data.user_curriculum,
    };
  } else {
    return {
      type: constants.UI_ERROR,
      error: response.data,
    };
  }
}

function successRequestingRemoveObjective(response) {
  if (response.status === 201) {
    return {
      type: constants.REMOVE_OBJECTIVE,
      user_curriculum: response.data.user_curriculum,
    };
  } else {
    return {
      type: constants.UI_ERROR,
      error: response.data,
    };
  }
}

// THUNKS HERE
export function getAllRankObjectives(rankId) {
  return function (dispatch) {
    return requestAllRankObjectives(rankId).then((response) =>
      dispatch(successRequestingRankObjectives(response, rankId))
    );
  };
}

export function getRankObjectives(rankId, userId) {
  return function (dispatch) {
    return requestRankObjectives(rankId, userId).then((response) =>
      dispatch(successRequestingRankObjectives(response, rankId))
    );
  };
}

export function getCommunitySocialObjectives(communityId) {
  return function (dispatch) {
    return requestCommunitySocialObjectives(communityId).then((response) =>
      dispatch(successRequestingCommunitySocialObjectives(response))
    );
  };
}

export function updateObjective(objective) {
  return function (dispatch) {
    return requestUpdateObjective(objective).then((response) => {
      if (response.status === 200) {
        dispatch(successRequestingUpdateObjective(response.data.objective));
      } else {
        dispatch(failureRequestingUpdateObjective(response));
      }
    });
  };
}

export function completeObjective(data) {
  return function (dispatch) {
    return requestCompleteObjective(data).then((response) =>
      dispatch(successRequestingCompleteObjective(response))
    );
  };
}

export function removeObjective(data) {
  return function (dispatch) {
    return requestRemoveObjective(data).then((response) =>
      dispatch(successRequestingRemoveObjective(response))
    );
  };
}

// API CALLS
function requestAllRankObjectives(rankId) {
  if (parseInt(rankId) === -1) {
    rankId = 1;
  }
  var requestUrl = "/api/v1/ranks/" + rankId + "/objectives";

  return Axios.get(requestUrl);
}

function requestRankObjectives(rankId, userId) {
  var requestUrl = "/api/v1/ranks/" + rankId + "/objectives";

  if (userId) {
    requestUrl += "?user_id=" + userId;
  }

  return Axios.get(requestUrl);
}

function requestCommunitySocialObjectives(communityId) {
  let requestUrl = "/api/v1/communities/" + communityId + "/social_objectives";
  return Axios.get(requestUrl);
}

function requestUpdateObjective(updatedObjective) {
  let requestUrl = "/api/v1/objectives/" + updatedObjective.id;

  var data = new FormData();
  data.append("name", updatedObjective.name);
  data.append("description", updatedObjective.description);
  data.append("points", updatedObjective.points);

  if (updatedObjective.newVideoSelected) {
    data.append("video_url", updatedObjective.video.attachment_url);
  }

  if (
    updatedObjective.newImageSelected &&
    updatedObjective.uploadPhotoFile !== ""
  ) {
    data.append("photo", updatedObjective.uploadPhotoFile);
  }

  if (updatedObjective.newLinks) {
    // console.log('updatedObjective.newLinks: ', updatedObjective.newLinks);
    // console.log('updatedObjective.links: ', updatedObjective.links);
    data.append("links", JSON.stringify(updatedObjective.links));
  }

  return Axios.put(requestUrl, data);
}

// New Way of Completing and Removing Objectives
// Goes along with the UserCurriculum restructure

function requestCompleteObjective(data) {
  // TODO: replace with
  // const requestUrl = `/api/v1/users/${data.userId}/update_objective_progress?objective_id=${data.objectiveId}&action_type=unapprove_objective`
  const requestUrl = `/api/v1/users/${data.userId}/complete_objective?objective_id=${data.objectiveId}`
  return Axios.put(requestUrl);
}

function requestRemoveObjective(data) {
  // TODO: replace with
  // const requestUrl = `/api/v1/users/${data.userId}/update_objective_progress?objective_id=${data.objectiveId}&action_type=unapprove_objective`
  const requestUrl = `/api/v1/users/${data.userId}/remove_objective?objective_id=${data.objectiveId}`
  return Axios.put(requestUrl);
}
