import React, { Component } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

// ASSETS
import "./Toast.scss";

/*

USE REACT TOASTIFY DOCS FOR MORE DETAILS REGARDING PROP NAMES

----------

https://www.npmjs.com/package/react-toastify

*/

class Toast extends Component {
  toastId = null;

  triggerToast = () => {
    const { type, position, autoClose } = this.props;

    if (!toast.isActive(this.toastId)) {
      this.toastId = toast[type](this.renderToastContent(), {
        position: toast.POSITION[position.toUpperCase()],
        autoClose
      });
    }
  };

  renderToastContent = () => {
    const { message, action, actionText } = this.props;
    return (
      <div id='ToastContainer'>
        <p>{message}</p>
        <button onClick={() => action()} className='animation-shrink'>
          {actionText}
        </button>
      </div>
    );
  };

  render() {
    const { children, show } = this.props;
    return (
      <div
        id='Toast'
        onClick={show ? this.triggerToast() : () => this.triggerToast()}
      >
        {children}
      </div>
    );
  }
}

Toast.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  autoClose: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
  action: PropTypes.func,
  actionText: PropTypes.string,
  show: PropTypes.bool
};

export default Toast;
