import * as types from "../actions/actionTypes";

export default function invitations(state = [], action) {
  switch (action.type) {
    case types.GET_COMMUNITY_INVITATIONS:
      return action.invitations;

    case types.ADD_INVITATION:
      return [action.invitation, ...state]

    case types.DELETE_INVITATION:
      if (!state || state.length === 0) {
        return null;
      }
      let selected_index_delete = state
        .map(function(e) {
          return e.id;
        })
        .indexOf(action.invitation.id);
      let stateCopy = [...state];
      stateCopy.splice(selected_index_delete, 1);

      return stateCopy;
    default:
      return state;
  }
}
