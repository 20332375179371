import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import PropTypes from 'prop-types'
import SessionsList from './SessionsList'
import SubmitButton from '../../common/buttons/SubmitButton'
import Loader from "../../common/Op36Loader"
import ScheduleSessionModal from './ScheduleSessionModal'

import './ClassScheduleView.scss'

const ClassScheduleView = ({
  course,
  openEditClassModal,
  loader
}) => {
  const navigate = useNavigate()

  const current_user = useSelector(state => state.user.current_user)

  const [showPastSessions, setShowPastSessions] = useState(false)
  const [showScheduleSessionModal, setShowScheduleSessionModal] = useState(false)
  const [selectedScheduleSession, setSelectedScheduleSession] = useState({})
  const [scheduleAction, setScheduleAction] = useState('')

  const handleScheduleSessionClick = (session) => {
    setSelectedScheduleSession(session)
    setScheduleAction('view')
    setShowScheduleSessionModal(true)
  }

  const handleScheduleEditClick = (e, session) => {
    e.stopPropagation()
    setSelectedScheduleSession(session)
    setScheduleAction('update')
    setShowScheduleSessionModal(true)
  }

  if(loader) {
    return (
      <Loader message='Loading class schedule'/>
    )
  }

  return (
    <div id='ClassScheduleView'>
      { course.schedule?.scheduleSessions?.length > 0 &&
        <div className='schedule-header'>
          <div className='col-12 col-md-6 mx-auto header-content'>
            <span className='title'>Class Schedule</span>
            <div className='d-flex'>
              <div className='header-button' onClick={() => setShowPastSessions(!showPastSessions)}>
                <i className={showPastSessions ? 'fa fa-eye-slash' : 'fa fa-eye'}/>
                {showPastSessions ? ' Hide' : ' Show'} past sessions
              </div>
              <div
                className='header-button'
                aria-hidden='true'
                onClick={() => navigate(`/programs/${course.id}/edit?step=2`)}
              >
                <i className='fa fa-pencil-square-o'/> Edit schedule
              </div>
            </div>
          </div>
        </div>
      }
      <div className='col-12 col-md-6 mx-auto my-3'>
        <SessionsList
          editable
          schedule={course.schedule}
          showPastSessions={showPastSessions}
          scheduleAction={'update'}
          //handleScheduleSessionClick={handleScheduleSessionClick}
          //handleScheduleEditClick={handleScheduleEditClick}
        />
        {!course.schedule?.scheduleSessions?.length > 0 && (
          <SubmitButton
            buttonMessage='Add schedule'
            onClick={() => navigate(`/programs/${course.id}/edit?step=2`)}
          />
        )}
      </div>
      <ScheduleSessionModal
        editable
        selectedScheduleSession={selectedScheduleSession}
        showScheduleSessionModal={showScheduleSessionModal}
        scheduleAction={scheduleAction}
        setScheduleAction={setScheduleAction}
        closeModal={() => setShowScheduleSessionModal(false)}
      />
    </div>
  )
}

ClassScheduleView.propTypes = {
  course: PropTypes.shape({
    id: PropTypes.number,
    schedule: PropTypes.shape({
      scheduleSessions: PropTypes.array,
    }),
  }),
}

export default ClassScheduleView
