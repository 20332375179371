import React, { Component } from "react";
import { Collapse } from "reactstrap";

// ASSETS
import "./EducationSection.scss";
import EducationLesson from "./EducationLesson";

class EducationSection extends Component {
  state = {
    collapse: false
  };
  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  calculateCompletedLessons = () => {
    const { section, userEducation } = this.props;
    let completedLessonCount = 0;

    section.education_lessons.map(lesson => {
      let completedIndex = userEducation.completed_lessons
        .map(completedLesson => parseInt(Object.keys(completedLesson), 10))
        .indexOf(lesson.id);
      if (completedIndex > -1) {
        completedLessonCount += 1;
      }
    });

    return completedLessonCount;
  };

  renderEducationlessons = () => {
    const { section, toggleVideoModal, isCoach, userEducation } = this.props;

    if (section.education_lessons && section.education_lessons.length > 0) {
      return section.education_lessons.map((lesson, index) => {
        let completeLessonIndex = isCoach
          ? userEducation.completed_lessons
              .map(completedLesson =>
                parseInt(Object.keys(completedLesson), 10)
              )
              .indexOf(lesson.id)
          : -1;
        return (
          <EducationLesson
            lesson={lesson}
            index={index}
            toggleVideoModal={toggleVideoModal}
            isCoach={isCoach}
            isCompleted={completeLessonIndex > -1}
            key={lesson.id}
          />
        );
      });
    }
    return <p>No lessons to show</p>;
  };

  render() {
    const { section, index, isCoach } = this.props;
    return (
      <div id='EducationSection'>
        <div className='section-container card'>
          <button onClick={this.toggle}>
            <div className='section-info-wrapper col-10 pl-0'>
              <p>Section: {index + 1}</p>
              <h5>{section.name}</h5>
            </div>
            {isCoach && (
              <p
                className={`col-2 pr-0 section-completed-count-wrapper ${this.calculateCompletedLessons() ===
                  section.education_lessons.length &&
                  "completed-section animated bounceIn"}`}
              >
                {this.calculateCompletedLessons()} /{" "}
                {section.education_lessons.length}
              </p>
            )}
          </button>
          <Collapse isOpen={this.state.collapse || this.props.expanded}>
            {this.renderEducationlessons()}
          </Collapse>
        </div>
      </div>
    );
  }
}
export default EducationSection;
