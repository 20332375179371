import React from "react";

import "./LargeMilestoneIcon.scss";

import white from "../../assets/images/logos/OP36_clean_icon.png";
import dark from "../../assets/images/logos/OP36_clean_icon_dark.png";
import lock from "../../assets/images/common/icons/rankLockedIcon.png";

const findBorderColor = div => {
  switch (div) {
    case 1:
    case 6:
      return '#FF6633'
    case 2:
    case 7:
      return "#FDDB10";
    case 3:
    case 8:
      return "#D5DE27";
    case 4:
    case 9:
      return "#2BAAE1";
    case 5:
    case 10:
      return "#B51E8C";
    default:
      return "#414143";
  }
};

const LargeMilestoneIcon = props => {
  let borderColor = props.color || findBorderColor(props.division);
  let bgColor = props.bgColor || props.division < 6 ? "#fff" : "#1d2933";
  let logo = props.division < 6 ? dark : white;
  return (
    <div
      id='LargeMilestoneIcon'
      className={props.isLocked ? "" : "milestone-animated"}
    >
      <div
        style={{
          backgroundColor: bgColor,
          borderTop: `solid 12.7279px ${borderColor}`,
          borderRight: `solid 12.7279px ${borderColor}`
        }}
        className='hexagon-before'
      >
        <div
          className='inner-hex-before'
          style={{
            borderTop: `solid 2.8284px ${borderColor}`,
            borderRight: `solid 2.8284px ${borderColor}`
          }}
        />
      </div>
      <div
        style={{
          borderLeft: `solid 9px ${borderColor}`,
          borderRight: `solid 9px ${borderColor}`,
          backgroundColor: bgColor
        }}
        className='hexagon'
      >
        <div
          className='inner-hex text-center'
          style={{
            borderLeft: `solid 2px ${borderColor}`,
            borderRight: `solid 2px ${borderColor}`
          }}
        >
          <div
            className='left-division-box'
            style={{ color: borderColor, backgroundColor: bgColor }}
          >
            {props.division}
          </div>
          <div className='icon-container'>
            <img
              className='36-logo'
              src={props.isLocked ? lock : logo}
              alt='36'
            />
          </div>
          <div
            className='right-division-box'
            style={{ color: borderColor, backgroundColor: bgColor }}
          >
            {props.division}
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundColor: bgColor,
          borderBottom: `solid 12.7279px ${borderColor}`,
          borderLeft: `solid 12.7279px ${borderColor}`
        }}
        className='hexagon-after'
      >
        <div
          className='inner-hex-after'
          style={{
            borderBottom: `solid 2.8284px ${borderColor}`,
            borderLeft: `solid 2.8284px ${borderColor}`
          }}
        />
      </div>
    </div>
  );
};

export default LargeMilestoneIcon;
