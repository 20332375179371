import React from 'react'

import './DrillTagsAndVisibility.scss'

import PropTypes from 'prop-types'
import Loader from '../../../common/Op36Loader-web'

import lock_icon from '../../../../assets/images/common/icons/drills/lock-icon.png'
import unlock_icon from '../../../../assets/images/common/icons/drills/unlock-icon.png'

import money_user_icon from '../../../../assets/images/common/icons/drills/money-user-icon.png'
import group_user_icon from '../../../../assets/images/common/icons/drills/group-user-icon.png'

class DrillTagsAndVisibility extends React.Component {
  state = {
    type: 'stroke',
    tagInput: '',
  }
  render() {
    const { type } = this.state
    const {
      is_drill,
      privacy,
      setPrivacy,
      tags,
      customTags,
      isAdmin,
      drill_tier,
      setDrillTier,
    } = this.props
    return (
      <div id='DrillTagsAndVisibility'>
        <div className='content-container'>
          <h4 className='mt-2'>
            Visibility<span className='orange-text'>*</span>
          </h4>
          <div className='visibility-container'>
            <div
              className={`visibility-card ${privacy === 0 ? 'selected1' : ''}`}
              aria-hidden='true'
              onClick={() => setPrivacy(0)}
            >
              <div
                className='visibility-icon lock'
                style={{ backgroundImage: `url(${lock_icon})` }}
              />

              <h4>Private</h4>
              <p style={{ textAlign: 'center' }}>
                Only members of my community can view and use this{' '}
                {is_drill ? 'drill' : 'activity'}.
              </p>
            </div>
            <div
              className={`visibility-card ${privacy === 1 ? 'selected1' : ''}`}
              aria-hidden='true'
              onClick={() => setPrivacy(1)}
            >
              <div
                className='visibility-icon unlock'
                style={{ backgroundImage: `url(${unlock_icon})` }}
              />
              <h4>Public</h4>
              <p style={{ textAlign: 'center' }}>
                Any member of the Operation 36 Network can view and use this{' '}
                {is_drill ? 'drill' : 'activity'}
              </p>
            </div>
          </div>
          {isAdmin && is_drill && (
            <h4 className='mt-4'>
              Drill Tier<span className='orange-text'>*</span>
            </h4>
          )}
          {isAdmin && is_drill && (
            <div className='visibility-container'>
              <div
                className={`visibility-card ${
                  drill_tier === 'premium' ? 'selected1' : ''
                }`}
                aria-hidden='true'
                onClick={() => setDrillTier('premium')}
              >
                <div
                  className='visibility-icon lock'
                  style={{ backgroundImage: `url(${money_user_icon})` }}
                />

                <h4>Premium</h4>
                <p style={{ textAlign: 'center' }}>
                  Only members with a Premium subscription can view or use this{' '}
                  {is_drill ? 'drill' : 'activity'}.
                </p>
              </div>
              <div
                className={`visibility-card ${
                  drill_tier === 'basic' ? 'selected1' : ''
                }`}
                aria-hidden='true'
                onClick={() => setDrillTier('basic')}
              >
                <div
                  className='visibility-icon unlock'
                  style={{ backgroundImage: `url(${group_user_icon})` }}
                />
                <h4>Basic</h4>
                <p style={{ textAlign: 'center' }}>
                  This is available to all tiers of members, even if they don&apos;t
                  have Premium
                </p>
              </div>
            </div>
          )}
          <h4 className='mt-3'>Tags</h4>
          <div className='selected-tag-container'>
            {this._showTags(tags)}
            {tags.length > 0 && customTags.length > 0 && (
              <div
                style={{ height: '1px', background: '#ff6633', width: '100%' }}
              />
            )}
            {this._showCustomTags(customTags)}
          </div>
          <p>
            Select from the most commonly used tags in the categories below, or
            click Custom to enter your own tags.
          </p>
          <div className='type-picker-container mb-2'>
            <div className='type-container'>
              <p aria-hidden='true' onClick={() => this._switchType('stroke')}>Stoke types</p>
              <div
                className={`bottom-bar ${type === 'stroke' ? 'active' : ''}`}
              />
            </div>
            <div className='type-container'>
              <p aria-hidden='true' onClick={() => this._switchType('location')}>Location</p>
              <div
                className={`bottom-bar ${type === 'location' ? 'active' : ''}`}
              />
            </div>
            <div className='type-container'>
              <p aria-hidden='true' onClick={() => this._switchType('groups')}>Groups</p>
              <div
                className={`bottom-bar ${type === 'groups' ? 'active' : ''}`}
              />
            </div>
            <div className='type-container'>
              <p aria-hidden='true' onClick={() => this._switchType('custom')}>Custom</p>
              <div
                className={`bottom-bar ${type === 'custom' ? 'active' : ''}`}
              />
            </div>
          </div>
          <div className='tag-picker-container'>{this._renderAllTags()}</div>
        </div>
      </div>
    )
  }

  _showTags = (tags) => {
    if (tags.length > 0) {
      const { all_tags } = this.props
      return tags.map((t) => {
        const tag = all_tags.find((t1) => t1.id === t)
        return (
          <p
            key={t}
            className='shown-tag'
            aria-hidden='true'
            onClick={() => this.props.removeTag(t)}
          >
            {tag.name} <i className='fa fa-times' aria-hidden='true' />
          </p>
        )
      })
    } else {
      return null
    }
  }

  _showCustomTags = (tags) => {
    if (tags.length > 0) {
      return tags.map((t, i) => (
        <p
          key={i}
          className='shown-tag'
          aria-hidden='true'
          onClick={() => this.props.removeCustomTag(i)}
        >
          {t} <i className='fa fa-times' aria-hidden='true' />
        </p>
      ))
    } else {
      return null
    }
  }

  _renderAllTags = () => {
    if (this.props.all_tags_loader) {
      return <Loader message={'grabbing tags'} />
    }
    const { type } = this.state
    const { all_tags } = this.props

    switch (type) {
      case 'stroke':
        return this._buildTags(all_tags.filter((t) => t.type === 'TagStroke'))
      case 'location':
        return this._buildTags(
          all_tags.filter((t) => t.type === 'TagLocation')
        )
      case 'groups':
        return this._buildTags(all_tags.filter((t) => t.type === 'TagGroup'))
      case 'custom':
        return this._renderCustomTagFlow()
      default:
        return false
    }
  }

  _renderCustomTagFlow = () => (
    <div className='custom-tag-flow-container'>
      <p>
          To add a custom tag to your list, type it in the field below and hit
          Enter.
      </p>
      <div className='tag-input-container'>
        <i className='fa fa-tags' aria-hidden='true' />
        <input
          type='text'
          value={this.state.tagInput}
          className='transparent-input'
          onChange={this._handleTagInput}
          onKeyUp={this._handleSendingTag}
          placeholder='Enter a tag here'
        />
      </div>
    </div>
  )

  _handleTagInput = (e) => {
    this.setState({ tagInput: e.target.value })
  }

  _handleSendingTag = (e) => {
    const { tagInput } = this.state
    if (e.key !== 'Enter' || !tagInput) {
      return false
    } else {
      this.props.addCustomTag(tagInput)
      this.setState({ tagInput: '' })
    }
  }

  _buildTags = (tags) => {
    const selectedTags = this.props.tags
    const { addTag, removeTag } = this.props
    return tags.map((t, i) => (
      <p
        className={`tag-pill ${selectedTags.includes(t.id) ? 'dull' : ''}`}
        key={i}
        aria-hidden='true'
        onClick={
          selectedTags.includes(t.id)
            ? () => removeTag(t.id)
            : () => addTag(t.id)
        }
      >
        {t.name}
      </p>
    ))
  }

  _switchType = (newType) => {
    const { type } = this.state
    if (newType === type) {
      return false
    }
    this.setState({ type: newType })
  }
}

DrillTagsAndVisibility.propTypes = {
  is_drill: PropTypes.bool,
  isAdmin: PropTypes.bool,
  privacy: PropTypes.number,
  drill_tier: PropTypes.string,
  all_tags_loader: PropTypes.bool,
  setPrivacy: PropTypes.func,
  setDrillTier: PropTypes.func,
  practice_types: PropTypes.array,
  all_tags: PropTypes.array,
  tags: PropTypes.array,
  customTags: PropTypes.array,
  addTag: PropTypes.func,
  addCustomTag: PropTypes.func,
  removeTag: PropTypes.func,
  removeCustomTag: PropTypes.func,
}

export default DrillTagsAndVisibility
