import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Draggable, Droppable } from "react-beautiful-dnd";

import * as drillActions from "../../../actions/drillActions";

import "./BlockContentPicker.scss";

import Pagination from "../../common/Pagination";
import DrillPreviewCard from "./DrillPreviewCard";
import CurriculumPreviewCard from "./CurriculumPreviewCard";
// import TagFilterModal from "../modals/TagFilterModal";

import filterIcon from "../../../assets/images/common/icons/filter-icon.png";
import FilterModal from "../../drills/modals/FilterModal";

class BlockContentPicker extends React.Component {
  state = {
    db: "community",
    taskType: "activities",
    searchTerm: "",
    myDrillsSearchTerm: "",
    myActivitiesSearchTerm: "",
    publicDrillsSearchTerm: "",
    publicActivitiesSearchTerm: "",
    myDrillsSearchTimeout: null,
    myActivitiesSearchTimeout: null,
    publicDrillsSearchTimeout: null,
    publicActivitiesSearchTimeout: null,
    filterModal: false,
    filters: [],
    publicDrillsPagination: 1,
    publicActivitiesPagination: 1,
    myDrillsPagination: 1,
    myActivitiesPagination: 1,
    myDrillsLoader: false,
    myActivitiesLoader: false,
    publicDrillsLoader: false,
    publicActivitiesLoader: false,
    publicDrillsFilters: {
      type: "Drill",
      visibility: "Any",
      skills: [],
      tags: []
    },
    publicActivitiesFilters: {
      type: "Coaching Activity",
      visibility: "Any",
      skills: [],
      tags: []
    },
    myDrillsFilters: {
      type: "Drill",
      visibility: "Any",
      skills: [],
      tags: []
    },
    myActivitiesFilters: {
      type: "Coaching Activity",
      visibility: "Any",
      skills: [],
      tags: []
    }
  };

  render() {
    const {
      db,
      taskType,
      searchTerm,
      myDrillsSearchTerm,
      myActivitiesSearchTerm,
      publicDrillsSearchTerm,
      publicActivitiesSearchTerm
    } = this.state;
    return (
      <div id='BlockContentPicker'>
        <div className='block-content-picker-top-container'>
          <div
            className='block-content-database-picker'
            onClick={this._toggleDB}
          >
            <div
              className={`moving-pill ${db === "personal" ? "right" : ""}`}
            />
            <p
              className='database-picker-title'
              style={db === "personal" ? {} : { color: "#fff" }}
            >
              Community Database
            </p>
            <p
              className='database-picker-title'
              style={db === "personal" ? { color: "#fff" } : {}}
            >
              My Database
            </p>
          </div>
          <div className='block-content-task-switcher'>
            <div className='task-picker-box'>
              <p onClick={() => this._handleNewTask("activities")}>
                Activities
              </p>
              <div
                className={`picker-underline ${
                  taskType === "activities" ? "active" : ""
                }`}
              />
            </div>
            <div className='task-picker-box'>
              <p onClick={() => this._handleNewTask("drills")}>Drills</p>
              <div
                className={`picker-underline ${
                  taskType === "drills" ? "active" : ""
                }`}
              />
            </div>
            <div className='task-picker-box'>
              <p onClick={() => this._handleNewTask("curriculum")}>
                Curriculum
              </p>
              <div
                className={`picker-underline ${
                  taskType === "curriculum" ? "active" : ""
                }`}
              />
            </div>
          </div>
          <div className='block-task-search-container'>
            <div
              className='block-task-search-inner-container'
              style={taskType !== "curriculum" ? { display: "none" } : {}}
            >
              <i className='fa fa-search' aria-hidden='true' />
              <input
                name='searchTerm'
                value={searchTerm}
                type='text'
                onChange={this._handleSearchInput}
                placeholder={`Find a${
                  taskType === "activities" ? "n" : ""
                } ${this._renderSingular(taskType)}`}
              />
            </div>
            <div
              className='block-task-search-inner-container'
              style={
                taskType === "drills" && db === "personal"
                  ? {}
                  : { display: "none" }
              }
            >
              <i className='fa fa-search' aria-hidden='true' />
              <input
                name='searchTerm'
                value={myDrillsSearchTerm}
                type='text'
                onChange={e => this._handleSearchState(e, "myDrills")}
                placeholder={`Find a${
                  taskType === "activities" ? "n" : ""
                } ${this._renderSingular(taskType)}`}
              />
            </div>
            <div
              className='block-task-search-inner-container'
              style={
                taskType === "activities" && db === "personal"
                  ? {}
                  : { display: "none" }
              }
            >
              <i className='fa fa-search' aria-hidden='true' />
              <input
                name='searchTerm'
                value={myActivitiesSearchTerm}
                type='text'
                onChange={e => this._handleSearchState(e, "myActivities")}
                placeholder={`Find a${
                  taskType === "activities" ? "n" : ""
                } ${this._renderSingular(taskType)}`}
              />
            </div>
            <div
              className='block-task-search-inner-container'
              style={
                taskType === "drills" && db === "community"
                  ? {}
                  : { display: "none" }
              }
            >
              <i className='fa fa-search' aria-hidden='true' />
              <input
                name='searchTerm'
                value={publicDrillsSearchTerm}
                type='text'
                onChange={e => this._handleSearchState(e, "publicDrills")}
                placeholder={`Find a${
                  taskType === "activities" ? "n" : ""
                } ${this._renderSingular(taskType)}`}
              />
            </div>
            <div
              className='block-task-search-inner-container'
              style={
                taskType === "activities" && db === "community"
                  ? {}
                  : { display: "none" }
              }
            >
              <i className='fa fa-search' aria-hidden='true' />
              <input
                name='searchTerm'
                value={publicActivitiesSearchTerm}
                type='text'
                onChange={e => this._handleSearchState(e, "publicActivities")}
                placeholder={`Find a${
                  taskType === "activities" ? "n" : ""
                } ${this._renderSingular(taskType)}`}
              />
            </div>
            {taskType !== "curriculum" && (
              <div
                className='block-task-filter-icon'
                style={{ backgroundImage: `url(${filterIcon})` }}
                onClick={this._triggerFilterModal}
              >
                {/* {this.state.filters.length > 0 ? (
                  <p className='filter-count'>{this.state.filters.length}</p>
                ) : null} */}
                {this._checkFilter(db, taskType)}
              </div>
            )}
          </div>
        </div>
        <div className='block-content-container'>
          {this._renderPagination(db, taskType)}
          {this._renderTasks()}
        </div>
        {this.state.filterModal && (
          // <TagFilterModal
          //   filters={this.state.filters}
          //   addFilter={this._addFilter}
          //   removeFilter={this._removeFilter}
          //   clearAll={this._clearAllFilters}
          //   close={this._closeFilterModal}
          //   allTags={this.props.allTags}
          //   tagLoader={this.props.tagLoader}
          // />
          <FilterModal
            filters={this._grabCorrectFilter()}
            applyHandler={this._filterTasks}
            close={this._closeFilterModal}
            drillSkills={this.props.practice_types}
            tags={this.props.allTags}
            showType={false}
            showVisibility={false}
            showSkills={taskType === "drills"}
          />
        )}
      </div>
    );
  }

  _handleSearchState = (event, view) => {
    let term = event.target.value;
    this.setState({ [view + "SearchTerm"]: term }, () => {
      this._handleSearch(view, term);
    });
  };

  _handleSearch = (view, term) => {
    this.setState({ [view + "Loader"]: true }, () => {
      if (this.state[view + "SearchTimeout"]) {
        clearTimeout(this.state[view + "SearchTimeout"]);
      }
      if (!term) {
        this.setState({
          [view + "SearchTimeout"]: setTimeout(
            () => this._updateSearchList(view, this.state[view + "Pagination"]),
            1500
          )
        });
      } else {
        this.setState({
          [view + "SearchTimeout"]: setTimeout(
            () => this._updateSearchList(view, null, term),
            1500
          )
        });
      }
    });
  };

  _updateSearchList = (view, pagination, searchTerm) => {
    let filter = this.state[view + "Filters"];
    if (view === "myDrills") {
      return this.props.drill_actions
        .getMyDrills(filter, pagination, pagination && 20, searchTerm)
        .then(() => {
          this.setState({ [view + "Loader"]: false });
        });
    }
    if (view === "myActivities") {
      return this.props.drill_actions
        .getMyActivities(filter, pagination, pagination && 20, searchTerm)
        .then(() => {
          this.setState({ [view + "Loader"]: false });
        });
    }
    if (view === "publicDrills") {
      return this.props.drill_actions
        .getPublicDrills(filter, pagination, pagination && 20, searchTerm)
        .then(() => {
          this.setState({ [view + "Loader"]: false });
        });
    }
    if (view === "publicActivities") {
      return this.props.drill_actions
        .getPublicActivities(filter, pagination, pagination && 20, searchTerm)
        .then(() => {
          this.setState({ [view + "Loader"]: false });
        });
    }
  };

  _filterTasks = filters => {
    const { db, taskType } = this.state;
    if (db === "personal") {
      if (taskType === "drills") {
        this.setState({ myDrillsFilters: filters }, () => {
          this._closeFilterModal();
          this._fetchMyDrills(1, this.state.myDrillsSearchTerm);
        });
      } else if (taskType === "activities") {
        this.setState({ myActivitiesFilters: filters }, () => {
          this._closeFilterModal();
          this._fetchMyActivities(1, this.state.myActivitiesSearchTerm);
        });
      }
    } else {
      if (taskType === "drills") {
        this.setState({ publicDrillsFilters: filters }, () => {
          this._closeFilterModal();
          this._fetchPublicDrills(1, this.state.publicDrillsSearchTerm);
        });
      } else if (taskType === "activities") {
        this.setState({ publicActivitiesFilters: filters }, () => {
          this._closeFilterModal();
          this._fetchPublicActivities(1, this.state.publicActivitiesSearchTerm);
        });
      }
    }
  };

  _checkFilter = (db, taskType) => {
    if (db === "personal") {
      if (taskType === "drills") {
        return this._checkFilterCount(this.state.myDrillsFilters);
      } else if (taskType === "activities") {
        return this._checkFilterCount(this.state.myActivitiesFilters);
      }
    } else {
      if (taskType === "drills") {
        return this._checkFilterCount(this.state.publicDrillsFilters);
      } else if (taskType === "activities") {
        return this._checkFilterCount(this.state.publicActivitiesFilters);
      }
    }
  };

  _checkFilterCount = filter => {
    let count = 0;
    if (filter.skills.length > 0) {
      count += filter.skills.length;
    }
    if (filter.tags.length > 0) {
      count += filter.tags.length;
    }
    if (filter.visibility !== "Any") {
      count += 1;
    }
    if (count > 0) {
      return <p className='filter-count'>{count}</p>;
    }
  };

  _grabCorrectFilter = () => {
    const { db, taskType } = this.state;
    if (db === "personal") {
      if (taskType === "activities") {
        return this.state.myActivitiesFilters;
      }
      if (taskType === "drills") {
        return this.state.myDrillsFilters;
      }
    } else {
      if (taskType === "activities") {
        return this.state.publicActivitiesFilters;
      }
      if (taskType === "drills") {
        return this.state.publicDrillsFilters;
      }
    }
  };

  _renderPagination = (db, taskType) => {
    const {
      publicDrillsPagination,
      publicActivitiesPagination,
      myDrillsPagination,
      myActivitiesPagination
    } = this.state;
    // don't show anything if taskType is 'curriculum' (the only other option besides 'drill' or 'activities')
    if (db === "personal") {
      if (taskType === "drills") {
        return (
          this.props.pages &&
          this.props.pages.myDrills > 1 && (
            <Pagination
              pageCount={this.props.pages.myDrills}
              fetchData={this._fetchMyDrills}
              activePage={myDrillsPagination}
            />
          )
        );
      } else if (taskType === "activities") {
        return (
          this.props.pages &&
          this.props.pages.myActivities > 1 && (
            <Pagination
              pageCount={this.props.pages.myActivities}
              fetchData={this._fetchMyActivities}
              activePage={myActivitiesPagination}
            />
          )
        );
      }
    } else {
      // this is for the 'community database' selection
      if (taskType === "drills") {
        return (
          this.props.pages &&
          this.props.pages.publicDrills > 1 && (
            <Pagination
              pageCount={this.props.pages.publicDrills}
              fetchData={this._fetchPublicDrills}
              activePage={publicDrillsPagination}
            />
          )
        );
      } else if (taskType === "activities") {
        return (
          this.props.pages &&
          this.props.pages.publicActivities > 1 && (
            <Pagination
              pageCount={this.props.pages.publicActivities}
              fetchData={this._fetchPublicActivities}
              activePage={publicActivitiesPagination}
            />
          )
        );
      }
    }
  };

  _fetchMyDrills = (step, searchTerm) => {
    const { myDrillsFilters } = this.state;
    this.setState({ myDrillsLoader: true, myDrillsPagination: step }, () => {
      this.props.drill_actions
        .getMyDrills(
          myDrillsFilters,
          this.state.myDrillsPagination,
          20,
          searchTerm
        )
        .then(() => {
          this.setState({ myDrillsLoader: false });
        });
    });
  };

  _fetchMyActivities = (step, searchTerm) => {
    const { myActivitiesFilters } = this.state;
    this.setState(
      { myActivitiesLoader: true, myActivitiesPagination: step },
      () => {
        this.props.drill_actions
          .getMyActivities(
            myActivitiesFilters,
            this.state.myActivitiesPagination,
            20,
            searchTerm
          )
          .then(() => {
            this.setState({ myActivitiesLoader: false });
          });
      }
    );
  };

  _fetchPublicDrills = (step, searchTerm) => {
    const { publicDrillsFilters } = this.state;
    this.setState(
      { publicDrillsLoader: true, publicDrillsPagination: step },
      () => {
        this.props.drill_actions
          .getPublicDrills(
            publicDrillsFilters,
            this.state.publicDrillsPagination,
            20,
            searchTerm
          )
          .then(() => {
            this.setState({ publicDrillsLoader: false });
          });
      }
    );
  };

  _fetchPublicActivities = (step, searchTerm) => {
    const { publicActivitiesFilters } = this.state;
    this.setState(
      { publicActivitiesLoader: true, publicActivitiesPagination: step },
      () => {
        this.props.drill_actions
          .getPublicActivities(
            publicActivitiesFilters,
            this.state.publicActivitiesPagination,
            20,
            searchTerm
          )
          .then(() => {
            this.setState({ publicActivitiesLoader: false });
          });
      }
    );
  };

  // _removeFilter = id => {
  //   let filters = this.state.filters.filter(f => id !== f);
  //   this.setState({ filters });
  // };

  // _addFilter = id => {
  //   let filters = [...this.state.filters];
  //   filters.push(id);
  //   this.setState({ filters });
  // };

  // _clearAllFilters = () => {
  //   this.setState({ filters: [] });
  // };

  _closeFilterModal = () => {
    this.setState({ filterModal: false });
  };

  _triggerFilterModal = () => {
    this.setState({ filterModal: true });
  };

  // _applyFilters = drills => {
  //   const { filters } = this.state;
  //   if (filters.length < 1) {
  //     return drills;
  //   } else {
  //     return drills.filter(drill => {
  //       let include = false;
  //       for (let i = 0; i < drill.tags.length; i++) {
  //         if (filters.includes(drill.tags[i].id)) {
  //           include = true;
  //           break;
  //         }
  //       }
  //       return include;
  //     });
  //   }
  // };

  _renderTasks = () => {
    const { taskType, db, searchTerm } = this.state;
    const {
      drills,
      subjects,
      subjectLoader,
      triggerCurriculumDetailModal,
      triggerDrillDetailModal
    } = this.props;
    let renderDrills;
    switch (taskType) {
      case "drills":
        renderDrills =
          db === "personal" ? drills.my_drills : drills.public_drills;
        return this._checkLoaders(db, taskType) ? (
          <div className='loader-container'>
            <i className='fa fa-spinner fa-spin' aria-hidden='true' />
          </div>
        ) : (
          <Droppable droppableId='remove-task-drop-zone' isDropDisabled={true}>
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <div className='block-task-card-container'>
                  {renderDrills.length > 0 ? (
                    renderDrills.map((drill, index) => {
                      return (
                        <Draggable
                          key={drill.id}
                          draggableId={`drill_${drill.id}`}
                          index={index}
                          style={{ opacity: 1 }}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <DrillPreviewCard
                                drill={drill}
                                picker={true}
                                triggerDetailModal={triggerDrillDetailModal}
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })
                  ) : (
                    <div className='placeholder-task-card'>
                      <i className='fa fa-flag-o' aria-hidden='true' />
                      <p>
                        Looks like no drills match your search/filter criteria.
                        Don't let it get you down, get in there and try again!
                      </p>
                    </div>
                  )}
                </div>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        );
      case "activities":
        renderDrills =
          db === "personal" ? drills.my_activities : drills.public_activities;
        return this._checkLoaders(db, taskType) ? (
          <div className='loader-container'>
            <i className='fa fa-spinner fa-spin' aria-hidden='true' />
          </div>
        ) : (
          <Droppable droppableId='remove-task-drop-zone' isDropDisabled={true}>
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <div className='block-task-card-container'>
                  {renderDrills.length > 0 ? (
                    renderDrills.map((drill, index) => {
                      return (
                        <Draggable
                          key={drill.id}
                          draggableId={`drill_${drill.id}`}
                          index={index}
                          style={{ opacity: 1 }}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <DrillPreviewCard
                                drill={drill}
                                picker={true}
                                triggerDetailModal={triggerDrillDetailModal}
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })
                  ) : (
                    <div className='placeholder-task-card'>
                      <i className='fa fa-flag-o' aria-hidden='true' />
                      <p>
                        Looks like no drills match your search/filter criteria.
                        Don't let it get you down, get in there and try again!
                      </p>
                    </div>
                  )}
                </div>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        );
      case "curriculum":
        let renderSubjects;
        if (!searchTerm) {
          renderSubjects = [...subjects].slice(0, 12);
        } else {
          renderSubjects = subjects.slice(0, 12).filter(subject => {
            return subject.name
              .toLowerCase()
              .includes(searchTerm.toLowerCase());
          });
        }
        return subjectLoader ? (
          <div className='loader-container'>
            <i className='fa fa-spinner fa-spin' aria-hidden='true' />
          </div>
        ) : (
          <Droppable droppableId='remove-task-drop-zone' isDropDisabled={true}>
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <div className='block-task-card-container'>
                  {renderSubjects.length > 0 ? (
                    renderSubjects.map((subject, index) => {
                      // if (index < 12) {
                      // this keeps the old social actions from appearing here
                      return (
                        <Draggable
                          key={subject.id}
                          draggableId={`subject_${subject.id}`}
                          index={index}
                          style={{ opacity: 1 }}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <CurriculumPreviewCard
                                subject={subject}
                                triggerDetailModal={
                                  triggerCurriculumDetailModal
                                }
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                      // }
                    })
                  ) : (
                    <div className='placeholder-task-card'>
                      <i className='fa fa-flag-o' aria-hidden='true' />
                      <p>
                        Looks like no curriculum objectives match your search
                        criteria. Don't let it get you down, get in there and
                        try again!
                      </p>
                    </div>
                  )}
                </div>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        );
      default:
        return null;
    }
  };

  _checkLoaders = (db, taskType) => {
    const {
      initialMyActivitiesLoader,
      initialMyDrillsLoader,
      initialPublicActivitiesLoader,
      initialPublicDrillsLoader
    } = this.props;
    const {
      myDrillsLoader,
      myActivitiesLoader,
      publicDrillsLoader,
      publicActivitiesLoader
    } = this.state;

    if (db === "personal") {
      if (taskType === "drills") {
        return initialMyDrillsLoader || myDrillsLoader;
      }
      if (taskType === "activities") {
        return initialMyActivitiesLoader || myActivitiesLoader;
      }
    } else {
      if (taskType === "drills") {
        return initialPublicDrillsLoader || publicDrillsLoader;
      }
      if (taskType === "activities") {
        return initialPublicActivitiesLoader || publicActivitiesLoader;
      }
    }
  };

  _renderSingular = task => {
    switch (task) {
      case "activities":
        return "activity";
      case "drills":
        return "drill";
      case "curriculum":
        return "curriculum objective";
      default:
        return "task";
    }
  };

  _handleSearchInput = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  _handleNewTask = type => {
    const { taskType } = this.state;
    if (type !== taskType) {
      this.setState({ taskType: type });
    }
  };

  _toggleDB = () => {
    const { db } = this.state;
    if (db === "personal") {
      this.setState({ db: "community" });
    } else {
      this.setState({ db: "personal" });
    }
  };
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user.current_user,
    drills: state.drills,
    pages: state.pages,
    practice_types: state.practiceTypes
  };
}

function mapDispatchToProps(dispatch) {
  return {
    drill_actions: bindActionCreators(drillActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BlockContentPicker);
