import React, { Component } from "react";
import Loader from "react-loaders";
import Modal from "react-modal";
import { CSSTransition } from "react-transition-group";
import Axios from "../../../../axios";

// REDUX (for login)
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as authActions from "../../../../actions/authActions";
import * as commonActions from "../../../../actions/commonActions";

import StartingStep from "./StartingStep";
import QuestionStep from "./QuestionStep";
import ResultStep from "./ResultStep";
import AccountCreatedStep from "./AccountCreatedStep";
import OnboardingStep from "./OnboardingStep";

import Logo from "../../../common/logoSVGs/HorizontalLogo";
import introImg from "../../../../assets/images/registration-coach/starting-step-dashboard.png";
import commMap from "../../../../assets/images/registration-coach/community-map.png";
import errorImg from "../../../../assets/images/registration-coach/oh-snap.png";
import REG_QUESTIONS from "./registrationQuestions.js";
import ONBOARDING_QUESTIONS from "./onboardingQuestions.js";

import "./CoachRegistrationFlow.scss";
import { withRouting } from "../../../common/hooks";
import { initializePendo } from '../../../../utility/pendo'

// Request URLs for creating free coach, updating pic, updating their sales lead
// info, and grabbing token for login
const CREATE_COACH_URL = "api/v1/users/create_free_coach";
const ADD_PIC_URL = "/api/v1/users/";
const UPDATE_LEAD_URL = "/api/v1/sales_leads/";

// Twilio error codes (invalid input, maximum )
// Please see https://www.twilio.com/docs/api/errors#6-anchor
const INVALID_INPUT_ERR = [60200, 60204, 60205, 60300, 60306];
const MAX_CHECK_ERR = 60202; // Max attempts = 5, then must wait for expiration
const MAX_SEND_ERR = 60203; // Max attempts = 5, then must wait for expiration
// NOTE: Verification takes 10 min to expire

// Text for the different types of results
const RESULTS = {
  not_golf_pro: {
    title: "Sorry, this registration form is for golf pros only!",
    desc: "If you are looking for a program near you to join, we recommend checking out the Facility Map. From there, you can send a message to a coach and request to join their golf community.",
  },
  something_wrong: {
    title: "Oh snap! Something went wrong on our end...",
    desc: "We're working on fixing this. Please refresh the page or try again later.",
  },
};

// Strings for the different types of steps
const STARTING_STEP = "starting_step";
const QUESTION_STEP = "question_step";
const ONBOARDING_STEP = "onboarding_step";
const NOT_GOLF_PRO = "not_golf_pro";
const ACCOUNT_CREATED = "account_created";

// Styling class for onboarding
const POST_REG_STYLE = "CoachOnboarding";

// Error image for error modals
const ERR_IMG = <img src={errorImg} alt='Something went wrong' />;

// Transitions duration and types
const PAGE_FADE = 200;
const CHANGE_PAGE = "pageTransition";
const CHANGE_QUESTION = "questionTransition";

/**
  Flow a coach must go through to create a free account. Includes optional
  onboarding questions.

  Tried using <label> and <input> elements for the multiple
  choice questions, but they caused onClick to be fired multiple
  times per click.

  We are going to keep onboarding at the same route because we don't want them
  to be able to get to onboarding without creating an account.
*/
class CoachRegistrationFlow extends Component {
  // Commented some fields in case we switch designs again and need them later
  state = {
    // For registration
    consent: false,
    privacy: false,
    sign_up_reasons: [],
    job_title: [],
    first_last_name: ["", ""], // [first, last]
    email: [""],
    phone: [""],
    verification_code: [""], // Via phone #
    password: ["", ""], // [password, confirmPassword]

    // For onboarding
    photo: null, // Profile pic - will be File object if uploaded
    community_name: "",
    community_type: "",
    season_length: "",
    location: "",
    hours: "",
    programs: "",
    pain_points: "",
    affiliations: "",
    otherValues: {}, // Stores 'Other' values in onboarding MC questions

    // For navigating between steps and substeps (questions)
    currentStep: "starting_step",
    stepIndex: 0, // Index in current step (in registration and onboarding)
    regDone: false, // Registration done?
    loading: false,
    loadingMessage: "",

    // In case we need the user's account and sales info thoughout
    // onboarding flow to send data to DB
    user: {},
    salesLeadInfo: {},
    coachId: -1, // User id
    leadId: -1, // Id in sales lead DB (!= coachId)

    // For Twilio SMS verification
    phoneErrorModal: false, // If error with phone # or sending SMS
    phoneErrorMsg: "", // Reason for error (invalid #, server error, etc.)
    codeErrorModal: false, // If code entered by user doesn't match SMS code
    codeErrorMsg: "", // Reason for error (incorrect code, etc.)

    // In case signin from onboarding fails
    loginErrorModal: false,

    // Transition animations for pages and questions
    pageTransition: false,
    questionTransition: false,
  };

  componentDidMount() {
    if (window.$zopim && window.$zopim.livechat) {
      window.$zopim.livechat.hideAll();
    }

    // Init arrays for registrationQuestions
    for (let i = 0; i < REG_QUESTIONS.length; i++) {
      let choices = [];
      const q = REG_QUESTIONS[i];
      const questionId = q.id;

      // Initialize the arrays in state for MC questions all with 'false' values
      if (q.type === "multiple_choice") {
        const numChoices = q.other_choice
          ? q.ans_choices.length + 1
          : q.ans_choices.length;
        for (let j = 0; j < numChoices; j++) {
          choices[j] = false;
        }
        this.setState({ [questionId]: choices });
      }
    }

    // Init arrays for onboardingQuestions
    let otherValuesStart = [];
    for (let h = 0; h < ONBOARDING_QUESTIONS.length; h++) {
      for (let i = 0; i < ONBOARDING_QUESTIONS[h].questions.length; i++) {
        let choices = [];
        const q = ONBOARDING_QUESTIONS[h].questions[i];
        const questionId = q.id;

        // Initialize the arrays in state for MC questions all with 'false' values
        if (q.type === "multiple_choice") {
          const numChoices = q.other_choice
            ? q.choices.length + 1
            : q.choices.length;
          for (let j = 0; j < numChoices; j++) {
            choices[j] = false;
          }
          this.setState({ [questionId]: choices });
        }

        // Also set up the otherValues array
        if (q.other_choice) {
          otherValuesStart[questionId] = "";
        } else {
          otherValuesStart[questionId] = null;
        }
        this.setState({ otherValues: otherValuesStart });
      }
    }

    // TODO FOR TESTING - REMOVE WHEN DONE
    // this.setState({
    //   regDone: true,
    //   currentStep: ONBOARDING_STEP,
    //   // currentStep: ACCOUNT_CREATED,
    //   stepIndex: 0,
    //   // currentStep: QUESTION_STEP,
    //   // stepIndex: 5
    // });
  }

  componentWillMount() {
    this.props.common_actions.showSideBar(false);
    this.props.common_actions.showNavBar(false);
  }

  componentWillUnmount() {
    clearInterval(this.pageTimer);
    this.props.common_actions.showSideBar(true);
    this.props.common_actions.showNavBar(true);
  }

  render() {
    const {
      regDone,
      loading,
      phoneErrorModal,
      phoneErrorMsg,
      codeErrorModal,
      codeErrorMsg,
      email,
      loadingMessage,
      loginErrorModal,
      currentStep,
      pageTransition,
    } = this.state;

    // console.log('props', this.props);

    if (window.$zopim && window.$zopim.livechat) {
      window.$zopim.livechat.hideAll();
    }
    // console.log("main state", this.state);

    let mainClass = "CoachRegistrationFlow";
    let cardClass = "CoachRegistrationFlowCard";
    if (regDone) {
      mainClass = mainClass.concat(" ", POST_REG_STYLE);

      if (currentStep === ACCOUNT_CREATED) {
        cardClass = cardClass.concat(" ", "CoachAccountCreated");
      }
    }

    return (
      <div className={mainClass}>
        {loading && (
          <div className='CoachRegistrationFlowLoader'>
            <Loader type='ball-pulse-sync' color='white' />
            <h5>{loadingMessage}</h5>
          </div>
        )}
        <Modal
          isOpen={phoneErrorModal}
          contentLabel='Error with sending SMS'
          onRequestClose={this._closeModal}
          className='customModal'
        >
          <div className='CoachRegistrationFlowModal'>
            {ERR_IMG}
            <p>{phoneErrorMsg}</p>
            <button
              className='btn-rectangular btn-rectangular-main-positive'
              onClick={this._closeModal}
            >
              Try again
            </button>
          </div>
        </Modal>
        <Modal
          isOpen={codeErrorModal}
          contentLabel='Error with code'
          onRequestClose={this._closeModal}
          className='customModal'
        >
          <div className='CoachRegistrationFlowModal'>
            {ERR_IMG}
            <p>{codeErrorMsg}</p>
            <button
              className='btn-rectangular btn-rectangular-main-positive'
              onClick={this._closeModal}
            >
              Try again
            </button>
          </div>
        </Modal>
        <Modal
          isOpen={loginErrorModal}
          contentLabel='Problem logging in'
          onRequestClose={this._closeModal}
          className='customModal'
        >
          <div className='CoachRegistrationFlowModal'>
            {ERR_IMG}
            <p>
              Sorry, we had a problem signing you in from here. Please click the
              button below to go to the Sign In page and use your email and
              password to sign in.
            </p>
            <p>
              Your email: <strong>{email[0]}</strong>
            </p>
            <a href='/signin'>
              <button className='btn-rectangular btn-rectangular-main-positive'>
                Go to Sign In page
              </button>
            </a>
          </div>
        </Modal>
        <div className='CoachRegistrationFlowHeader'>
          <Logo colorScheme='white' />
          <p>The Developmental Golf Program for Adults and Juniors</p>
        </div>
        <div className={cardClass}>
          <CSSTransition
            key={currentStep}
            in={pageTransition}
            timeout={PAGE_FADE}
            classNames='regStep'
          >
            {this._renderStep()}
          </CSSTransition>
        </div>
      </div>
    );
  }

  /**
    Changes pageTransition so there's a fade transition between currentStep
    pages.
  */
  _transition = (transitionType, newValue) => {
    this.setState({ [transitionType]: false }, () => {
      this.pageTimer = setTimeout(() => {
        if (transitionType === CHANGE_PAGE) {
          this.setState({ currentStep: newValue }, () => {
            this.setState({ [transitionType]: true });

            // Reset index after transition to ACCOUNT_CREATED so you don't
            // see a flicker of the first QuestionStep page
            if (this.state.currentStep === ACCOUNT_CREATED) {
              this.setState({ stepIndex: 0 });
            }
          });
        } else if (transitionType === CHANGE_QUESTION) {
          this.setState({ stepIndex: newValue }, () => {
            this.setState({ [transitionType]: true });
          });
        } else {
          this.setState({ [transitionType]: true });
        }
      }, PAGE_FADE);
    });
  };

  /**
    Need this to set error modal to false
    (Can't use setState or pass in a key to this in render()).
  */
  _closeModal = () => {
    // Don't feel like writing a closing function for each modal type
    this.setState({
      phoneErrorModal: false,
      codeErrorModal: false,
      loginErrorModal: false,
    });
  };

  /**
    Un/toggle the appropriate checkbox for the legal agreement
    items in StartingStep and save the state.
  */
  _onLegalCheckboxClick = (event, item) => {
    event.stopPropagation();
    this.setState({ [item]: !this.state[item] });
  };

  /**
    Change state whenever an input field is edited, a checkbox or radio button is un/selected, an image is uploaded/removed, etc. in a QuestionStep.
  */
  _onAnswerChange = (questionId, values) => {
    this.setState({ [questionId]: values }, () => {
      if (
        questionId === "sign_up_reasons" ||
        questionId === "job_title" ||
        questionId === "hours"
      ) {
        // There's no Next button for this page, so advance to next page when
        // an answer is clicked
        this._nextButtonHandler();
      }
    });
  };

  /**
    Updates the appropriate value in the otherValues object when an 'Other' input
    value is changed.
  */
  _onOtherInputChange = (event, questionId) => {
    const newVal = event.target.value;
    let otherValuesCopy = { ...this.state.otherValues };
    otherValuesCopy[questionId] = newVal;
    this.setState({ otherValues: otherValuesCopy });
  };

  /**
    Brings the user back to the previous step, whether that's a QuestionStep or
    the StartingStep.
  */
  _backButtonHandler = () => {
    if (this.state.stepIndex !== 0) {
      // this.setState({ stepIndex: this.state.stepIndex - 1 });
      this._transition(CHANGE_QUESTION, this.state.stepIndex - 1);
    } else {
      this._transition(CHANGE_PAGE, STARTING_STEP);
    }
  };

  /**
    Brings the user to the next step. Calls the submit handler if on the last
    question.
  */
  _nextButtonHandler = async () => {
    const { currentStep, stepIndex } = this.state;

    // Check which step they're in
    switch (currentStep) {
      case STARTING_STEP:
        this._transition(CHANGE_PAGE, QUESTION_STEP);
        break;
      case QUESTION_STEP:
        const notGolfProIndex = this.state.job_title.length - 1;
        if (this.state.job_title[notGolfProIndex]) {
          // If not golf pro
          // If not a golf pro, take them to 'not golf pro' result screen
          this._transition(CHANGE_PAGE, NOT_GOLF_PRO);
        } else if (stepIndex < REG_QUESTIONS.length - 1) {
          if (REG_QUESTIONS[stepIndex].id === "phone") {
            // Check if phone is valid for SMS
            this._startPhoneVerification(this.state.phone[0]);
          } else if (REG_QUESTIONS[stepIndex].id === "verification_code") {
            // Check if code matches
            this._checkVerificationCode(
              this.state.phone[0],
              this.state.verification_code
            );
          } else {
            // If not on the last question, go to next page
            // this.setState({ stepIndex: stepIndex + 1 });
            this._transition(CHANGE_QUESTION, this.state.stepIndex + 1);
          }
        } else {
          // Else, submit answers and create account
          this._createAccount();
        }
        break;
      case ACCOUNT_CREATED:
        if (this.state.photo) {
          this._requestAddProfilePic();
        }
        this._transition(CHANGE_PAGE, ONBOARDING_STEP);
        break;
      case ONBOARDING_STEP:
        // POST data to DB
        this._updateSalesLead();

        // If not on the last question, go to the next question
        // Else, take to dashboard
        if (stepIndex < ONBOARDING_QUESTIONS.length - 1) {
          // this.setState({ stepIndex: stepIndex + 1 });
          this._transition(CHANGE_QUESTION, this.state.stepIndex + 1);
        } else {
          // console.log("Opening dashboard");

          // If different user signed in between post-registration signin and
          // onboarding completion (or they signed out), need to sign in with
          // new user so we open the correct dashboard
          if (!this.props.user || this.props.user.id !== this.state.coachId) {
            this._requestSignIn(true);
          } else {
            this.props.navigate('/dashboard')
          }

          // console.log("this.state.user", this.state.user);
          // console.log("this.state.photo", this.state.photo);
          // console.log("this.state.salesLeadInfo", this.state.salesLeadInfo);
        }
        break;
      default:
        return;
    }

    // Scroll smoothly to the top if needed
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  /**
    Makes sure the data is sent to the DB in the correct format to create the
    account, then takes them to the 'account_created' step.
  */
  _createAccount = () => {
    // Only create one account if the 'Create account' button is mashed
    if (this.state.loading) {
      return;
    }

    const { first_last_name, email, phone, password } = this.state;
    // Format data
    // In case they somehow got through without filling in all of the necessary
    // fields, add placeholders
    let user = {
      first_name: first_last_name[0] || "John",
      last_name: first_last_name[1] || "Smith",
      email: email[0] || "sample@email.com",
      gender: 0, // Prefer not to say
      password: password[0] || "testing123",
      password_confirmation: password[0] || "testing123",
      // birthdate: 'January 01 2000', // birthday not required
      phone: phone[0] || "+1 (555) 555-555",
      is_golfer: true,
      community_id: this._grabFreeCommunity(), // Free coaches' community
      type: "Coach",
      community_name: "Coaches' Community", // Free coaches' community
    };

    // Grab appropriate values for sign_up_reasons and job_title data
    const signupReason = this.state.sign_up_reasons.indexOf(true);
    const golfProType = this.state.job_title.indexOf(true);
    let salesLeadInfo = {
      job_title: REG_QUESTIONS[1].ans_choices[golfProType],
    };
    let lastIdx = REG_QUESTIONS[0].ans_choices.length - 1;
    if (signupReason === lastIdx) {
      // Grab all answer choices
      salesLeadInfo.sign_up_reasons = REG_QUESTIONS[0].ans_choices.slice(
        0,
        lastIdx
      );
    } else {
      // Grab only selected choice
      salesLeadInfo.sign_up_reasons = [
        REG_QUESTIONS[0].ans_choices[signupReason],
      ];
    }

    // Add sign_up_reasons and job_title to user now that create_free_coach
    // now accepts them as params
    user.job_title = salesLeadInfo.job_title;
    user.sign_up_reasons = salesLeadInfo.sign_up_reasons;
    if (this.props.params.sales_guy) {
      let repId = this._grabSalesRepId(this.props.params.sales_guy);

      if (repId) {
        user.sales_rep_id = repId;
      }
    }

    // Send data to DB and attempt to create account
    this.setState({ user, salesLeadInfo }, () => {
      // console.log("creating account and sales lead here...");
      this._requestCreateFreeCoach();
    });
  };

  _grabFreeCommunity = () => {
    return Axios.defaults.baseURL === "https://operation36.herokuapp.com"
      ? 1138
      : Axios.defaults.baseURL === "https://operation36-dev.herokuapp.com"
      ? 25
      : 2;
  };

  _grabSalesRepId = (name) => {
    name = name.toLowerCase();
    if (Axios.defaults.baseURL === "https://operation36.herokuapp.com") {
      switch (name) {
        case "matt":
          return 37160;
        case "charlie":
          return 37159;
        default:
          return false;
      }
    }
    if (Axios.defaults.baseURL === "https://operation36-dev.herokuapp.com") {
      switch (name) {
        case "tommy":
          return 281;
        case "michael":
          return 272;
        default:
          return false;
      }
    }
    // will always return false if using local rails
    return false;
  };

  /**
    After each onboarding screen, this updates the sales lead with the user's
    responses.
  */
  _updateSalesLead = () => {
    // Grab questions from current index in onboarding flow
    const questions = ONBOARDING_QUESTIONS[this.state.stepIndex].questions;
    let salesLeadCopy = { ...this.state.salesLeadInfo };

    // Add new fields to the sales lead object
    for (let i = 0; i < questions.length; i++) {
      const q = questions[i];

      if (q.type === "multiple_choice") {
        // Grab array of values that were marked 'true', including any custom
        // 'Other' value (which is the last index)
        const selectedVal = this.state[q.id];
        let ans = [];
        for (let j = 0; j < selectedVal.length; j++) {
          if (
            j === selectedVal.length - 1 &&
            q.other_choice &&
            selectedVal[j]
          ) {
            ans.push(this.state.otherValues[q.id]);
          } else if (selectedVal[j]) {
            ans.push(q.choices[j]);
          }
        }

        // Return array if multi_select; string if not
        if (q.multi_select) {
          salesLeadCopy[q.id] = ans;
        } else {
          salesLeadCopy[q.id] = ans[0];
        }
      } else if (q.type === "dropdown") {
        // Check if 'Other' value was selected
        if (this.state[q.id] !== "Other") {
          salesLeadCopy[q.id] = this.state[q.id];
        } else {
          salesLeadCopy[q.id] = this.state.otherValues[q.id];
        }
      } else {
        // Is 'input'
        salesLeadCopy[q.id] = this.state[q.id];
      }
    }

    // Send the updated sales lead object to DB
    this.setState({ salesLeadInfo: salesLeadCopy }, () => {
      this._requestUpdateSalesLead();
      // console.log(
      //   "Sending data...",
      //   this.state.user,
      //   this.state.salesLeadInfo,
      //   this.state.photo
      // );
    });
  };

  /**
    Returns the component to render for the current step.
    The 'not_golf_pro', 'account_created', and 'onboarding_done' are different
    types of ResultSteps.
  */
  _renderStep = () => {
    const { currentStep, stepIndex, questionTransition } = this.state;

    switch (currentStep) {
      case STARTING_STEP:
        return (
          <StartingStep
            img={introImg}
            hasConsented={this.state.consent}
            acceptedPrivacy={this.state.privacy}
            toggleHandle={this._onLegalCheckboxClick}
            startButtonHandle={this._nextButtonHandler}
          />
        );
      case QUESTION_STEP:
        let question = REG_QUESTIONS[stepIndex];
        // Replace the [number] in the verification_code question with
        // the number the user inputted
        if (question.id === "verification_code") {
          question.desc = question.desc.replace(
            "[number]",
            this.state.phone[0]
          );
        }
        return (
          <QuestionStep
            question={question}
            percentage={(stepIndex + 1) / REG_QUESTIONS.length}
            answers={this.state[question.id]}
            changeHandle={this._onAnswerChange}
            backButtonHandle={this._backButtonHandler}
            nextButtonHandle={this._nextButtonHandler}
            resendCodeHandle={this._resendCodeHandler}
            questionTransition={questionTransition}
            animDuration={PAGE_FADE}
          />
        );
      case NOT_GOLF_PRO:
        return (
          <ResultStep
            img={commMap}
            imgAlt='Community map'
            title={RESULTS.not_golf_pro.title}
            desc={RESULTS.not_golf_pro.desc}
          >
            <a
              className='btn-rectangular btn-rectangular-main-positive result-step-button result-step-link'
              href='https://operation36golf.com/community-map'
            >
              Go to the Facility Map
            </a>
            <a
              className='btn-rectangular btn-rectangular-main-negative result-step-link'
              href='https://operation36.golf/'
            >
              Return to operation36.golf
            </a>
          </ResultStep>
        );
      case ACCOUNT_CREATED:
        return (
          <AccountCreatedStep
            logo={<Logo colorScheme='light' />}
            name={this.state.first_last_name[0]}
            changeHandle={this._onAnswerChange}
            nextButtonHandle={this._nextButtonHandler}
            questionId='photo'
          />
        );
      case ONBOARDING_STEP:
        const step = ONBOARDING_QUESTIONS[stepIndex];

        // Replace the [facility name] in the 'location' step with the
        // facility name the user inputted
        if (step.id === "location") {
          step.questions[0].title = step.questions[0].title.replace(
            "[facility name]",
            this.state.community_name
          );
        }

        // Create user answers object
        // Grab list of "Other" values if needed for custom input fields
        let answers = {};
        let otherValues = {};
        for (let i = 0; i < step.questions.length; i++) {
          const questionId = step.questions[i].id;
          answers[questionId] = this.state[questionId];
          otherValues[questionId] = this.state.otherValues[questionId];
        }

        return (
          <OnboardingStep
            step={step}
            percentage={(stepIndex + 1) / ONBOARDING_QUESTIONS.length}
            answers={answers}
            otherValues={otherValues}
            changeHandle={this._onAnswerChange}
            nextButtonHandle={this._nextButtonHandler}
            otherValueChangeHandle={this._onOtherInputChange}
            questionTransition={questionTransition}
            animDuration={PAGE_FADE}
          />
        );
      default:
        // Return error result screen here
        return (
          <ResultStep
            img={errorImg}
            imgAlt='Something went wrong'
            title={RESULTS.something_wrong.title}
            desc={RESULTS.something_wrong.desc}
          >
            <button
              className='btn-rectangular btn-rectangular-main-positive result-step-button'
              onClick={() => window.location.reload()}
            >
              Refresh
            </button>
          </ResultStep>
        );
    }
  };

  /**
    Forwards the number to _startPhoneVerification (since the 'verification_code')
    doesn't have access to the 'phone' field).
  */
  _resendCodeHandler = () => {
    this._startPhoneVerification(this.state.phone[0]);
  };

  _userLoggedIn = () => {
    // Register for push notifications
    const { user } = this.props;
    this.setState({ loading: false });
    if (window.FirebasePlugin) {
      window.FirebasePlugin.grantPermission();
      window.FirebasePlugin.subscribe("op36user");
    }
    if (window.pendo) {
      initializePendo(window.pendo, user)
    }
  };

  //////////////////////////////////////////////////////////////////////////////
  // API CALLS
  //////////////////////////////////////////////////////////////////////////////

  /**
    Sends an SMS verification code.
    API takes a phone # and returns a service id.
  */
  _startPhoneVerification = async (phoneNum) => {
    // In case they mash the 'Send code' button
    if (this.state.loading) {
      return;
    }

    // Format phone number for Twilio
    const re = /[\+0-9]/g;
    const num = phoneNum.match(re).join("");

    this.setState({
      loading: true,
      loadingMessage: "Sending code...",
    });

    Axios.post("/api/v1/users/verify_phone", { phone: num })
      .then((res) => {
        // console.log("verify_phone, res", res);

        // Check if 'Send code' on 'phone' step was clicked or 'Resend code'
        // on 'verification_code' step was clicked.
        // Clicking on the former moves to the next page, but the latter does not.
        const { stepIndex } = this.state;
        if (REG_QUESTIONS[stepIndex].id === "phone") {
          this.setState({ loading: false }, () => {
            this._transition(CHANGE_QUESTION, this.state.stepIndex + 1);
            return true;
          });
        } else {
          this.setState({ loading: false }, () => {
            return true;
          });
        }
      })
      .catch((err) => {
        // console.log("Phone verification error :(");
        const errCode = err.response.data.error.code;

        // Assume error on server side
        let errMsg =
          "Sorry, it looks like there a problem sending the code on our end. Please try again later.";
        // Check if actually error on client side
        if (INVALID_INPUT_ERR.includes(errCode)) {
          errMsg = `Sorry, we're unable to send a code to ${phoneNum}. Please make sure this is a valid cell phone number.`;
        } else if (errCode === MAX_SEND_ERR) {
          errMsg =
            "Whoops, you have reached the max number of send attempts. Please try again in 10 minutes.";
        }

        this.setState(
          {
            loading: false,
            phoneErrorModal: true,
            phoneErrorMsg: errMsg,
          },
          () => {
            return false;
          }
        );
      });
  };

  /**
    Checks if the SMS verification code is correct or not.
    API takes code and service id; it returns true or false.
  */
  _checkVerificationCode = async (phoneNum, verification_code) => {
    // In case they mash the 'Verify code' button
    if (this.state.loading) {
      return;
    }

    // Format phone number for Twilio
    const re = /[\+0-9]/g;
    const num = phoneNum.match(re).join("");

    this.setState({
      loading: true,
      loadingMessage: "Checking code...",
    });

    Axios.post("/api/v1/users/check_phone_verification", {
      phone: num,
      verification_code,
    })
      .then((res) => {
        // console.log("check_phone, res", res);
        const { stepIndex } = this.state;
        this.setState({ loading: false }, () => {
          if (res.data.verified) {
            // this.setState({ stepIndex: stepIndex + 1 });
            this._transition(CHANGE_QUESTION, stepIndex + 1);
          } else {
            this.setState({
              codeErrorModal: true,
              codeErrorMsg: `Whoops, the code you entered doesn't match the code sent to ${phoneNum}. Please make sure you entered the correct code.`,
            });
          }
          return res.data.verified;
        });
      })
      .catch((err) => {
        // console.log("Phone verification error :(");
        const errCode = err.response.data.error.code;

        // Assume error on server side
        let errMsg =
          'Sorry, there was a problem verifying your code on our end. If your code expired, click "Resend code" and enter the new code sent to your phone. Otherwise, please try again in a moment.';
        // Check if actually error on client side
        if (errCode === MAX_CHECK_ERR) {
          errMsg =
            'Whoops, you have reached the max number of check attempts. Please wait 10 minutes for your current code to expire, then click "Resend code" to send a new code.';
        }

        this.setState(
          {
            loading: false,
            codeErrorModal: true,
            codeErrorMsg: errMsg,
          },
          () => {
            return false;
          }
        );
      });
  };

  /**
    Makes POST request to create free coach account. IF successful, it starts
    the onboarding flow. Else, it shows an error page.
  */
  _requestCreateFreeCoach = async () => {
    // const { user, email, password } = this.state;
    const { user } = this.state;
    // const { auth_actions } = this.props;

    this.setState({
      loading: true,
      loadingMessage: "Creating your account...",
    });

    Axios.post(CREATE_COACH_URL, user)
      .then((res) => {
        // If account is successfully created, take them to the success screen
        // console.log("Account created!", res.data);
        this.setState(
          {
            loading: false,
            regDone: true,
            // currentStep: ACCOUNT_CREATED,
            // stepIndex: 0,
            userId: res.data.user.id,
            leadId: res.data.sales_lead.id,
          },
          () => {
            this._transition(CHANGE_PAGE, ACCOUNT_CREATED);
            // Also sign the user in - if they quit before onboarding and reopen
            // the app, they will already be logged in
            this._requestSignIn(false);
            // auth_actions.signOutUser();
            // auth_actions.signInUser(email[0], password[0])
            //   .then((res) => {
            //     console.log('Login success', res);
            //     this._userLoggedIn();
            //   })
            //   .catch((err) => {
            //     console.log('Login error', err.response);
            //   });
          }
        );
      })
      .catch((err) => {
        // Else, take them to an error screen
        console.log("Error creating account :(");
        this.setState({
          loading: false,
          // currentStep: "error",
        });
        this._transition(CHANGE_PAGE, "error");
      });
  };

  /**
    Updates the new coach user with a profile pic.
  */
  _requestAddProfilePic = async () => {
    const { userId, photo } = this.state;
    let data = new FormData();
    data.append("photo", photo);
    Axios.put(ADD_PIC_URL + userId, data);
    // .then((res) => {
    //   console.log("Profile picture updated!", res.data);
    // })
    // .catch((err) => {
    //   console.log("Error updating profile picture :(");
    // });
  };

  /**
    Makes PUT request to update sales lead info for the coach.
    Don't need loading here (user doesn't need to wait for data to be sent
    before proceeding).
  */
  _requestUpdateSalesLead = async () => {
    const { salesLeadInfo, leadId } = this.state;

    Axios.put(
      UPDATE_LEAD_URL + leadId,
      Object.assign({ ac_sync: true }, salesLeadInfo)
    );
    // .then((res) => {
    //   console.log("Profile updated!", res.data);
    // })
    // .catch((err) => {
    //   console.log("Error updating profile :(", err.response);
    // });
  };

  /**
    Signs the user into their dashboard.
  */
  _requestSignIn = async (onboardingDone) => {
    const { email, password } = this.state;
    const { auth_actions } = this.props;

    if (onboardingDone) {
      this.setState({
        loading: true,
        loadingMessage: "Logging into dashboard...",
      });
    }

    auth_actions.signOutUser();
    auth_actions
      .signInUser(email[0], password[0])
      .then((res) => {
        // console.log('Login success', res);
        this._userLoggedIn();
        if (onboardingDone) {
          this.props.navigate('/dashboard')
        }
      })
      .catch((err) => {
        // console.log('Login error :(');
        if (onboardingDone) {
          this.setState({
            loading: false,
            loginErrorModal: true,
          });
        }
      });
  };
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user.current_user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    auth_actions: bindActionCreators(authActions, dispatch),
    common_actions: bindActionCreators(commonActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouting(CoachRegistrationFlow))
