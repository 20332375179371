import PropTypes from 'prop-types'
import Placeholder from '../../common/Placeholder'

const EventSubmitted = ({ isEdit, onClick }) => (
  <div className='row'>
    <div className='col-6 mx-auto vh-100 mt-5 animated fadeInUp'>
      <Placeholder
        title={`Please be patient while we finish ${
          isEdit ? 'updating' : 'creating'
        } golfer rounds`}
        icon={'fa-cloud-upload'}
        subtext1={`The golfers' rounds for this event are currently being ${
          isEdit ? 'updated' : 'created'
        }.`}
        subtext2={
          'Refresh on the event page if the leaderboard has not yet populated.'
        }
        buttonText={'Go to Events'}
        buttonIcon={'fa-arrow-left'}
        handleClick={() => onClick()}
      />
    </div>
  </div>
)

EventSubmitted.propTypes = {
  isEdit: PropTypes.bool,
  onClick: PropTypes.func,
}

export default EventSubmitted
