import React from "react";
import { Progress } from "reactstrap";
import SmallMilestoneIcon from "../common/SmallMilestoneIcon";
// import LargeMilestoneIcon from "../common/LargeMilestoneIcon";

import "./DivisionPopover.scss";

const getColor = (div, record, target) => {
  if (record && record <= target) {
    switch (div) {
      case 1:
      case 6:
        return '#FF6633'
      case 2:
      case 7:
        return "#FDDB10";
      case 3:
      case 8:
        return "#D5DE27";
      case 4:
      case 9:
        return "#2BAAE1";
      case 5:
      case 10:
        return "#B51E8C";
      default:
        return "#414143";
    }
  } else {
    return "#f4f4f5";
  }
};

const getYardage = div => {
  switch (div) {
    case 1:
      return "225 yards";
    case 2:
      return "450 yards";
    case 3:
      return "900 yards";
    case 4:
      return "1350 yards";
    case 5:
      return "1800 yards";
    case 6:
      return "1801 - 2300 yards";
    case 7:
      return "2301 - 2600 yards";
    case 8:
      return "2601 - 2900 yards";
    case 9:
      return "2901 - 3200 yards";
    case 10:
    case 11:
      return "3201 + yards";
    default:
      return "";
  }
};

const calculateCut = record => {
  if (!record) return 0;
  if (record > 50) return record - 50;
  if (record > 45) return record - 45;
  if (record > 39) return record - 39;
  if (record > 36) return record - 36;
  return 0;
};

const getNext = record => {
  if (!record) return 0;
  if (record > 50) return 50;
  if (record > 45) return 45;
  if (record > 39) return 39;
  if (record > 36) return 36;
  return 0;
};

const calculatePercentage = record => {
  if (!record) return 0;
  if (record > 50) return 20;
  if (record > 45) return 40;
  if (record > 39) return 60;
  if (record > 36) return 80;
  return 100;
};

const renderText = (
  record,
  div,
  numToCut,
  nextMilestone,
  isStudent,
  isSelf,
  isCoach,
  name
) => {
  if (record) {
    if (record > 36) {
      return `${
        isSelf ? "You" : name.charAt(0).toUpperCase() + name.slice(1)
      } shot ${record} in Level ${div}. ${
        isSelf ? "You" : name.charAt(0).toUpperCase() + name.slice(1)
      } only need${isSelf ? "" : "s"} to shave ${numToCut} more stroke${
        numToCut > 1 ? "s" : ""
      } to reach ${nextMilestone}! ${
        isCoach ? "Help them get there!" : "Keep going!"
      }`;
    } else {
      return `Great job ${name.charAt(0).toUpperCase() + name.slice(1)}! ${
        isSelf ? "You've" : "They have"
      } passed Level ${div}. `
    }
  } else {
    return `No rounds logged at this level yet. Get ${
      isCoach ? "them" : ""
    } on the course and give it a shot!`;
  }
};

const DivisionPopover = props => {
  let div =
    props.division.current_division <= 10
      ? props.division.current_division
      : 10;
  let record = props.division.divisions[div - 1].nine_hole.record_score;
  let numToCut = calculateCut(record);
  let nextMilestone = getNext(record);
  return (
    <div id='DivisionPopover' className='text-center'>
      <h5 className='mb-0 popover-title'>Level {div}</h5>
      <p>{`(${getYardage(div)})`}</p>
      <hr />
      <p className='mb-1'>{record ? "Best Score:" : ""}</p>
      {record ? <h3>{record}</h3> : null}
      <div className='progress-area'>
        <div className='current-division-progress'>
          <SmallMilestoneIcon division={div} />
        </div>
        {div < 10 ? (
          <div className='next-division-progress'>
            <SmallMilestoneIcon
              division={div + 1}
              bgColor='#fff'
              color='#c4c4c4'
            />
          </div>
        ) : (
          <div className='next-division-progress'>
            <SmallMilestoneIcon division={div} />
          </div>
        )}
      </div>
      <div className='progress-tracker-area'>
        <Progress
          value={calculatePercentage(record)}
          className='progress-container'
          barClassName={`progress-bar-${div}`}
        />
        <div className='circle-container'>
          <div
            className='fifty'
            style={{
              border: `2px solid ${getColor(div, record, 50)}`,
              color: record && record <= 50 ? "" : "#eee"
            }}
          >
            50
          </div>
          <div
            className='fortyfive'
            style={{
              border: `2px solid ${getColor(div, record, 45)}`,
              color: record && record <= 45 ? "" : "#eee"
            }}
          >
            45
          </div>
          <div
            className='thirtynine'
            style={{
              border: `2px solid ${getColor(div, record, 39)}`,
              color: record && record <= 39 ? "" : "#eee"
            }}
          >
            39
          </div>
          <div
            className='thirtysix'
            style={{
              border: `2px solid ${getColor(div, record, 36)}`,
              color: record && record <= 36 ? "" : "#eee"
            }}
          >
            36
          </div>
        </div>
      </div>
      <p className='bottom-text'>
        {renderText(
          record,
          div,
          numToCut,
          nextMilestone,
          props.isStudent,
          props.isSelf,
          props.isCoach,
          props.firstName
        )}
      </p>
    </div>
  );
};

export default DivisionPopover;
