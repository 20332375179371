import React from "react";
import { useNavigate } from "react-router-dom";
import "./FinishedStep.scss";

// class FinishedStep extends Component {
//   render() {
//     const { community, navigate } = this.props;
//     return (
//       <div id="FinishedStep" className="animated zoomIn">
//         <i className="fa fa-thumbs-up icon" />
//         <h2 className="title">Congratulations!</h2>
//         <p className="message">
//           You have officially joined the {community.name}
//         </p>
//         <p className="message">
//           Get started by clicking the button below to see your dashboard
//         </p>
//         <button className="btn btn-primary" onClick={() => navigate('/')}>
//           Go to your dashboard
//         </button>
//       </div>
//     );
//   }
// }
// 
// export default withRouting(FinishedStep)

const FinishedStep = ({ community }) => {
  const navigate = useNavigate()

  return (
    <div id="FinishedStep" className="animated zoomIn">
      <i className="fa fa-thumbs-up icon" />
      <h2 className="title">Congratulations!</h2>
      <p className="message">
        You have officially joined the {community.name}
      </p>
      <p className="message">
        Get started by clicking the button below to see your dashboard
      </p>
      <button className="btn btn-primary" onClick={() => navigate('/')}>
        Go to your dashboard
      </button>
    </div>
  )
}

export default FinishedStep;
