import React, { Component } from "react";
import Modal from "react-modal";
import ReportsCard from "./ReportsCard";
import ReportsThumbCard from "./ReportsThumbCard";
import PageHeader from '../common/PageHeader/PageHeader'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import HowToUseAppGuide from "./HowToUseAppGuide/HowToUseAppGuide";
import AdminExportsContainer from "../exports/AdminExportsContainer";

import communityRounds from "../../assets/images/report-previews/comm-rounds-report.png";
import communityStats from "../../assets/images/report-previews/comm-stats-report.png";
import communityTraining from "../../assets/images/report-previews/comm-training-report.png";
import facilityImpact from "../../assets/images/report-previews/facility-impact-report.png";
import playerJourney from "../../assets/images/report-previews/player-journey-report.png";
import golferActivity from "../../assets/images/report-previews/golfer-activity-summary.png";
import golferCurriculum from "../../assets/images/report-previews/golfer-curriculum-summary.png";
import golferEngagement from "../../assets/images/report-previews/golfer-engagement-summary.png";
import juniorDev from "../../assets/images/report-previews/junior-dev-model.png";
import onCourse from "../../assets/images/report-previews/on-course-journey.png";

import * as commonActions from "../../actions/commonActions";
import * as userActions from "../../actions/userActions";


class ChooseReports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      guideModalOpen: false,
      downloadPDFLoader: false,
    };
    this.reports = this.props.current_user.type === "Admin" ? [
      {
        name: "Coach Engagement Report",
        summary:
          "Measures the engagement of Coaches and helps give insight on who we need to contact",
        reportType: "coach_engagement",
      },
      {
        name: "Support Activity Report",
        summary:
          "Shows a list of support actions done in a given start and end date",
        reportType: "support_activity",
      },
      {
        name: "Priority Report",
        summary: "Shows a list of communities in order of priority",
        reportType: "community_priority",
      },
      // {
      //   name: "Admin Community Report",
      //   summary: `This provides a summary of all of the communities in Op36 Program and their associated coaches`,
      //   reportType: "admin-community"
      // }
    ] : [
      {
        name: "Player Journey Report",
        summary:
          "Tell the story of your student's progression in the Operation 36 Program with a report to be proud of!",
        reportType: "player_journey",
        image: playerJourney,
      },
      {
        name: "Facility Impact Report",
        summary:
          "This report provides you with a snapshot of the impact the Operation 36 program has had on your community's income.",
        reportType: "impact",
        image: facilityImpact,
      },
      {
        name: "Junior Development Model Report (Beta)",
        summary:
          "See where your students are compared to their chosen pathway.",
        reportType: "progress",
        image: juniorDev,
      },
      {
        name: "Community Stats Report (All-time)",
        summary: `This report will give you a good overview of total family & golfer activities in your community.`,
        reportType: "community",
        image: communityStats,
      },
      {
        name: "Community Rounds Report",
        summary: `View all the rounds logged by your golfers and sort through their data by date logged.`,
        reportType: "community_rounds",
        image: communityRounds,
      },
      {
        name: "Community Training Report",
        summary: `View all the practice sessions logged by your golfers and sort through their data by date logged.`,
        reportType: "community_practice",
        image: communityTraining,
      },
      {
        name: "Operation 36 Golfer Curriculum Summary (Printable)",
        summary: `This report provides a summary of your golfers' current ranks and goals they have accomplished.`,
        reportType: "curriculum",
        image: golferCurriculum,
      },
      {
        name: "Golfer Engagement Summary",
        summary: `This report provides an overview of your golfers' Play, Train, & Social engagement.`,
        reportType: "engagement",
        image: golferEngagement,
      },
      {
        name: "Golfer Activity Summary (All-time)",
        summary: `This report provides an overview of total actions your golfers have completed in your community.`,
        reportType: "stats",
        image: golferActivity,
      },
      {
        name: "Operation 36 On-Course Journey (Printable)",
        summary: `This report provides a printable summary of your golfers' on-course journey.  This is a great summary you can provide parents and golfers to make them realistic with their expectations.`,
        reportType: "rounds",
        image: onCourse,
      },
    ];
  }

  _toggleGuideModal = () => {
    this.setState((prevState) => ({
      guideModalOpen: !prevState.guideModalOpen,
    }));
  };

  viewReport = (type) => {
    this.props.viewReport(type);
    this.props.next();
  };

  renderReports() {
    if (this.props.current_user.type === "Admin") {
      return (
        <>
          <PageHeader title='Reports' />
          <div className='container my-5 animated fadeIn'>
            <div className='card p-4'>
              {this.reports.map((report, index) => (
                <ReportsCard
                  key={index}
                  reportName={report.name}
                  summary={report.summary}
                  viewReport={this.viewReport}
                  reportType={report.reportType} />
              ))}
            </div>
            <AdminExportsContainer />
          </div>
        </>
      )
    }

    const { guideModalOpen, downloadPDFLoader } = this.state;
    const commId = this.props.current_user.communities[0].id;

    const headerActionLink = (
      <button
        className='no-bg-text-btn'
        onClick={this._toggleGuideModal}
        style={{ fontSize: '16px', marginLeft: '36px' }}
      >
        <i className='fa fa-file-pdf-o' aria-hidden='true' /> Want accurate
        reports? Increase app usage!
      </button>
    )

    return (
      <div>
        <PageHeader title='Reports' actionComponent={headerActionLink}/>
        <Modal
          id='HowToUseAppGuide'
          isOpen={guideModalOpen}
          onRequestClose={this._toggleGuideModal}
          contentLabel='How to Use the Mobile App Guide Modal'
        >
          <div className='modal-header row m-0 p-0'>
            <div className='col-11 m-0 p-0'>
              <h5 className='modal-title display-4'>
                Getting students into the mobile app:
              </h5>
            </div>
            <div className='col-1 m-0 p-0'>
              <button
                type='button'
                className='close pull-right font-200'
                data-dismiss='modal'
                aria-label='Close'
                onClick={this._toggleGuideModal}
              >
                <span aria-hidden='true'>✕</span>
              </button>
            </div>
          </div>
          <HowToUseAppGuide commId={commId} />
        </Modal>
        <div className='coach-report-container d-flex flex-grow-1 flex-wrap'>
          {this.reports.map((report, index) => {
            return (
              <ReportsThumbCard
                key={index}
                reportName={report.name}
                summary={report.summary}
                viewReport={this.viewReport}
                reportType={report.reportType}
                image={report.image}
              />
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    return this.renderReports();
  }
}

function mapStateToProps(state, ownProps) {
  return {
    current_user: state.user.current_user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    common_actions: bindActionCreators(commonActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChooseReports);
