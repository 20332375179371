import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './SubmitButton.module.scss'

const SubmitButtonIcon = ({ type }) => {
  switch (type) {
    case 'loadingSpinner':
      return <i className='fa fa-spinner fa-spin' />
    case 'addButton':
      return <i className='fa fa-plus' />
    default:
      return null
  }
}

SubmitButtonIcon.propTypes = {
  type: PropTypes.string.isRequired,
}

const SubmitButton = ({ onClick, disabled, buttonMessage, loading, buttonColor, noTopMargin, className, type, iconType, ...props }) => (
  <button
    type={type || 'submit'}
    className={classnames(
      'btn btn-primary d-flex align-items-center w-100',
      noTopMargin ? 'mt-0' : 'mt-3',
      styles['submit-button'],
      buttonColor ? styles[buttonColor] : styles['orange-button'],
      className
    )}
    onClick={onClick}
    disabled={disabled}
    {...props}
  >
    {(iconType || loading) && (
      <span className='flex-shrink-0 pr-2'>
        <SubmitButtonIcon type={loading ? 'loadingSpinner' : iconType} />
      </span>
    )}
    <div className={classnames('flex-grow-1 d-flex justify-content-center', { 'pr-2': iconType || loading })}>
      {buttonMessage}
    </div>
  </button>
)

SubmitButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  buttonMessage: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  buttonColor: PropTypes.string,
  noTopMargin: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.string,
  iconType: PropTypes.string,
}

export default SubmitButton
