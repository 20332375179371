import React, { Component } from "react";
import EducationContent from "../Detail/Content/EducationContent";
import ReactPlayer from "react-player";

import "./EducationPreviewModal.scss";

class EducationPreviewModal extends Component {
  render() {
    const { education, user, assignFree, payProcess, loader } = this.props;
    const isFree = education.price == 0;
    return (
      <div id='EducationPreviewModal'>
        <h2 className='preview-title'>{education.name}</h2>
        <p className='description'>{education.description}</p>
        <h3>What you'll learn</h3>
        <EducationContent
          education={education}
          toggleVideoModal={() => false}
          isCoach={false}
          userEducation={{ completed_lessons: { none: -1 } }}
          expanded
        />
        <div className='preview-sidebar'>
          <div className='preview-video-container'>
            <ReactPlayer
              className='react-player'
              url={education.video_url}
              width='100%'
              height='100%'
              controls
            />
          </div>
          <div style={{ padding: 24 }}>
            <p className='price'>{this._formatPrice(education.price)}</p>
            {loader ? (
              <button
                className='btn-rectangular btn-rectangular-main-positive'
                onClick={() => false}
              >
                <i className='fa fa-spinner fa-spin' aria-hidden='true' />
              </button>
            ) : (
              <button
                className='btn-rectangular btn-rectangular-main-positive'
                onClick={
                  user.type === "Admin"
                    ? () =>
                        alert(
                          "whoa whoa whoa. You're an admin. You know you don't have to take this course, right? If you're trying to edit it, click the little pencil thingy at the top of the card."
                        )
                    : isFree
                    ? () => assignFree(education, user.id)
                    : () => payProcess(education, user.id)
                }
              >
                {isFree ? "Get course" : "Buy course"}
              </button>
            )}
            {isFree ? null : (
              <div className='sub-description'>
                <p className='sub-description-text'>
                  Clicking the 'Buy this course' button, you will be taken to a
                  secure checkout page. After the transaction is complete, you
                  will be redirect back to this page to view the course content.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  _formatPrice = price => {
    if (price == 0) return "FREE";
    return "$" + parseFloat(price).toFixed(2);
  };
}

export default EducationPreviewModal;
