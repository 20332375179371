import QuickActionItem from './QuickActionItem'
import styles from './CoachDashboardHome.module.scss'
import { ReactComponent as ProgramsIcon } from '../../../assets/images/common/icons/quickActions/Programs.svg'
import { ReactComponent as EventsIcon } from '../../../assets/images/common/icons/quickActions/Events.svg'
import { ReactComponent as EmailIcon } from '../../../assets/images/common/icons/quickActions/Email.svg'
import { ReactComponent as LandingPageIcon } from '../../../assets/images/common/icons/quickActions/LandingPage.svg'
import { ReactComponent as CoachIcon } from '../../../assets/images/common/icons/quickActions/Coach.svg'
import PropTypes from 'prop-types'

const CoachDashboardQuickActions = ({ communityId }) => (
  <div className={styles.quickActionsContainer}>
    <QuickActionItem
      title='Manage Programs'
      icon={<ProgramsIcon className={styles.icon}/>}
      link='/programs'
    />
    <QuickActionItem
      title='9 Hole Events'
      icon={<EventsIcon className={styles.icon}/>}
      link='/events'
    />
    <QuickActionItem
      title='Landing Page'
      icon={<LandingPageIcon className={styles.icon}/>}
      externalLink={`${window.location.origin}/landing/${communityId}`}
    />
    <QuickActionItem
      title='Send an Email'
      icon={<EmailIcon className={styles.icon}/>}
      link='/announcements'
    />
    <QuickActionItem
      title='Coach Community'
      icon={<CoachIcon className={styles.icon}/>}
      externalLink='https://operation-36-network-coaches.mn.co/landing?from=https%3A%2F%2Foperation-36-network-coaches.mn.co%2Ffeed'
    />
  </div>
)

CoachDashboardQuickActions.propTypes = {
  communityId: PropTypes.number,
}

export default CoachDashboardQuickActions
