import React, { Component } from "react";
import Modal from "react-modal";
import CoachClassCard from "../../classes/CoachClassCard/CoachClassCard";
import Loader from "../../common/Op36Loader-web";
import Placeholder from "../../common/Placeholder";

class ProgramsModal extends Component {
  state = {
    loader: true,
    showCompletedPrograms: false
  };

  togglePrograms = () => {
    this.setState({ showCompletedPrograms: !this.state.showCompletedPrograms });
  };

  renderPrograms = () => {
    const { courses, loader, golfer, current_user } = this.props;
    const { showCompletedPrograms } = this.state;

    if (loader) return <Loader message='loading student classes' />;

    if (courses && courses.length > 0) {
      let filteredCourses = courses.filter(course => {
        if (showCompletedPrograms) {
          return course.is_completed;
        }
        return !course.is_completed;
      });
      return filteredCourses.map((course, index) => (
        <div className='col-xl-4 col-lg-6 col-md-6' key={course.id}>
          <CoachClassCard
            index={index}
            course={course}
            courseActions={this.props.course_actions}
            golfer={golfer}
            current_user={current_user}
            user={this.props.user}
            isStudentProfile
          />
        </div>
      ));
    }
    return (
      <div className='d-flex justify-content-center w-100'>
        <Placeholder
          title={"No classes to show"}
          icon={"fa-flag"}
          subtext1={`${golfer.first_name} ${golfer.last_name} is currently not enrolled in any classes`}
        />
      </div>
    );
  };
  render() {
    const { golfer, showModal, toggleModal } = this.props;
    const { showCompletedPrograms } = this.state;
    return (
      <Modal
        isOpen={showModal}
        onRequestClose={toggleModal}
        contentLabel='ProgramsModal'
        // style={modalStyles}
      >
        <div className='modal-header row'>
          <div className='col-11'>
            <h5 className='modal-title display-4'>
              {golfer.first_name} {golfer.last_name}'s Classes
            </h5>
          </div>
          <div className='col-1'>
            <button
              type='button'
              className='close pull-right font-200'
              data-dismiss='modal'
              aria-label='Close'
              onClick={toggleModal}
            >
              <span aria-hidden='true'>✕</span>
            </button>
          </div>
        </div>

        <div className='modal-content'>
          <div className='col-md-8 mx-auto' id='input-border'>
            <div className='d-flex justify-content-end'>
              <button
                className='btn btn-link-preview'
                onClick={this.togglePrograms}
              >
                {!showCompletedPrograms
                  ? "View class history"
                  : "Show active classes"}
              </button>
            </div>
            <div className='row mt-5'>{this.renderPrograms()}</div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ProgramsModal;
