import React from 'react';
import YouTube from 'react-youtube';

const YouTubeVideo = (props) => {
  let regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/; // eslint-disable-line no-useless-escape
  let match = props.url.match(regExp);
  let videoId = "";
  if (match && match[2].length === 11) videoId = match[2];
  let opts = {
    playerVars: {
      rel: 0
    }
  }

  return (
    <div className="embeddedVideo">
      <div className='media-player' tabIndex="0" >
        <div className="media-player-element">
          <YouTube
            videoId={videoId}
            onReady={e => {e.target.pauseVideo()}}
            opts={opts}
          />
        </div>
      </div>
    </div>
  )
}

export default YouTubeVideo;