import classNames from 'classnames'
import { NavLink, useSearchParams } from 'react-router-dom'

import classes from './AccountNavigation.module.scss'
import { useSelector } from 'react-redux'
import { useContext } from 'react'
import { AccountContext } from '../AccountContext'
import { isAdminInFamily, isUserCoachInCommunity } from '../../../utility/userHelper'

const AccountNavigation = () => {
  const { user, isProfile } = useContext(AccountContext)
  const [searchParams] = useSearchParams()
  const currentUser = useSelector(state => state.user.current_user)

  const isCurrentUser = user.id === currentUser.id
  const isCoach = user.type === 'Coach'
  const isCurrentCoachEditingAnotherUser =
    currentUser.type === 'Coach' &&
    isProfile &&
    !currentUser.family_admin &&
    !isCurrentUser

  const isAllowedToSeeTheirOwnSettings = currentUser.type === 'Coach' || currentUser.family_admin
  const isAllowedToSeeOtherUserSettings =
    isAdminInFamily(currentUser, user) ||
    isUserCoachInCommunity(currentUser, user)
  const isAllowedToAccessSettings = isCurrentUser ? isAllowedToSeeTheirOwnSettings : isAllowedToSeeOtherUserSettings

  const currentTab = searchParams.get('editTab')

  return (
    <nav>
      <ul className={classNames(classes.accountNavigation, 'py-2')}>
        <li>
          <NavLink
            to={{ search: '?editTab=personal' }}
            className={classNames({ [classes.active]: currentTab === 'personal' || currentTab === null })}
          >
          Personal
          </NavLink>
        </li>
        <li>
          <NavLink
            to={{ search: '?editTab=profile' }}
            className={classNames({ [classes.active]: currentTab === 'profile' })}
          >
          Profile
          </NavLink>
        </li>
        {isCoach && (
          <li>
            <NavLink
              to={{ search: '?editTab=booking' }}
              className={classNames({ [classes.active]: currentTab === 'booking' })}
            >
            Booking
            </NavLink>
          </li>
        )}
        {isCurrentUser && isCoach && (
          <li>
            <NavLink
              to={{ search: '?editTab=notifications' }}
              className={classNames({ [classes.active]: currentTab === 'notifications' })}
            >
            Notifications
            </NavLink>
          </li>
        )}
        <li>
          <NavLink
            to={{ search: '?editTab=change-password' }}
            className={classNames({ [classes.active]: currentTab === 'change-password' })}
          >
            {isCurrentCoachEditingAnotherUser
              ? 'Reset Password'
              : 'Change Password'}
          </NavLink>
        </li>
        {isAllowedToAccessSettings && (
          <li>
            <NavLink
              to={{ search: '?editTab=account-settings' }}
              className={classNames({ [classes.active]: currentTab === 'account-settings' })}
            >
            Account Settings
            </NavLink>
          </li>
        )}
      </ul>
    </nav>
  )
}

export default AccountNavigation
