import React from 'react';

import './TwoButtonModal.scss';

const TwoButtonModal = ({
  title,
  subtitle,
  close,
  confirmText,
  confirmAction
}) => {
  return (
    <div id='TwoButtonModal'>
      <div className='grey-out' onClick={close} />
      <div className='modal-content-2button'>
        <div className='close-button-container'>
          <i className='fa fa-times' aria-hidden='true' onClick={close} />
        </div>
        <div className='modal-content-box'>
          <p className='modal-title'>{title}</p>
          <p className='modal-subtitle'>{subtitle}</p>
          <div className='modal-button-box'>
            <button className='modal-cancel-button' onClick={close}>
              Cancel
            </button>
            <button className='modal-confirm-button' onClick={confirmAction}>
              {confirmText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoButtonModal;
