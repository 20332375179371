import _ from 'lodash'
import { useSelector } from 'react-redux'
import PackageSelection from './PackageSelection'
import PaymentMethodSelection from './PaymentMethodSelection'
import FamilyMemberSelection from './FamilyMemberSelection'
import { Col, Container, Row } from 'reactstrap'

const ProgramDetailsStep = () => {
  const course = useSelector(state => state.programPresentation.course)
  const enrollmentData = useSelector(state => state.programRegistration.enrollmentData)

  const withPackages = !_.isEmpty(enrollmentData.coursePackages)

  return (
    <Container fluid id='ProgramDetailsStep'>
      <Row>
        <Col sm={6}>
          <FamilyMemberSelection />
        </Col>
        {withPackages && (
          <Col sm={6} className='pt-5 pt-sm-0'>
            <PaymentMethodSelection />
          </Col>
        )}
      </Row>
      {withPackages && (
        <Row>
          <Col className='pt-5'>
            <PackageSelection
              coursePackages={enrollmentData.coursePackages}
              currency={course.community.currency}
            />
          </Col>
        </Row>
      )}
    </Container>
  )
}

export default ProgramDetailsStep
