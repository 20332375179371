import { useSearchParams } from 'react-router-dom'

import PersonalForm from './AccountForm/PersonalForm'
import ProfileForm from './AccountForm/ProfileForm'
import BookingForm from './AccountForm/BookingForm'
import SettingsForm from './AccountForm/SettingsForm'
import PasswordChangeForm from './AccountForm/PasswordChangeForm'
import NotificationsForm from './AccountForm/NotificationsForm'


const AccountFormDispatcher = () => {
  const [searchParams] = useSearchParams()
  const currentTab = searchParams.get('editTab')

  const renderTabs = () => {
    switch (currentTab) {
      default:
      case 'personal':
        return <PersonalForm />

      case 'profile':
        return <ProfileForm />

      case 'booking':
        return <BookingForm />

      case 'account-settings':
        return <SettingsForm />

      case 'change-password':
        return <PasswordChangeForm />

      case 'notifications':
        return <NotificationsForm />
    }
  }

  return renderTabs()
}

export default AccountFormDispatcher
