import PropTypes from 'prop-types'
import styles from './EventInProgressAlert.module.scss'
import colors from '../../assets/styles/globals.scss'

const EventInProgressAlert = ({ onClick, scoresPassedDue }) => (
  <div className={styles.messageCard} style={{ backgroundColor: scoresPassedDue ? colors.redDarker : colors.primaryOrange }}>
    <div className='d-flex justify-content-center align-items-center'>
      <i className='fa fa-exclamation-triangle' />
      <span className='ml-3'>{scoresPassedDue ? 'Past Due - 9 Hole Event' : 'Logging Scores In-Progress'} </span>
    </div>
    <div aria-hidden='true' className={styles.continueEditingButton} onClick={onClick}>
      <span>{scoresPassedDue ? 'Log Scores Now' : 'Continue Logging Scores'}</span>
    </div>
  </div>
)

EventInProgressAlert.propTypes = {
  onClick: PropTypes.func.isRequired,
  scoresPassedDue: PropTypes.bool,
}

export default EventInProgressAlert
