import PropTypes from 'prop-types'
import React, { Component } from "react";
import Modal from "react-modal";
import moment from "moment";

// CHILDREN
import Avatar from "../../../components/common/avatar/avatar.js";
import Loader from "../../../components/common/Op36Loader-web";
import Placeholder from "../../../components/common/Placeholder";
import Comment from "../Comment";
import Claps from "../../common/Claps";

import stock_profile from "../../../assets/images/mock/StockProfilePic.png";

// ASSETS
import "./ActivityModal.scss";

const customStyles = {
  content: {
    zIndex: 9999,
    position: "absolute",
    padding: "0px",
    width: "80%",
    height: "90%",
    overflow: "hidden",
    marginTop: "50px",
  },
};

class ActivityModal extends Component {
  state = {
    hoverTriggered: false,
  };

  triggerOnMouseEnter = () => {
    this.setState({ hoverTriggered: true });
  };

  triggerOnMouseLeave = () => {
    this.setState({ hoverTriggered: false });
  };

  displayWebPhotoUrl = () => {
    const {
      activity,
      displayObjectivePhoto,
      displayRankPhoto,
      displayGoalPhoto,
    } = this.props;
    const { hoverTriggered } = this.state;

    if (activity.type === "ActivityObjective") {
      return (
        <div className='col-12 p-0 activity-image'>
          {displayObjectivePhoto()}
        </div>
      );
    } else if (activity.type === "ActivityRank") {
      return (
        <div className='col-12 p-0 activity-image'>{displayRankPhoto()}</div>
      );
    } else if (activity.type === "ActivityGoal") {
      return (
        <div className='col-12 activity-image p-0'>{displayGoalPhoto()}</div>
      );
    } else if (
      activity.type === "ActivityEvent" &&
      activity.event &&
      activity.event.photo
    ) {
      return (
        <div className='activity-image-wrapper'>
          <img
            src={activity.event.photo.large_file_url}
            alt={`${activity.user.first_name} ${activity.user.last_name}`}
          />
        </div>
      );
    } else if (
      activity.type === "ActivityPlay" &&
      activity.event &&
      activity.event.photo
    ) {
      return (
        <div className='activity-image-wrapper'>
          <img
            src={activity.event.photo.large_file_url}
            alt={`${activity.user.first_name} ${activity.user.last_name}`}
          />
        </div>
      );
    } else {
      if (activity.photo && activity.photo.large_file_url) {
        return (
          <div
            className='activity-image-wrapper'
            onMouseEnter={this.triggerOnMouseEnter}
            onMouseLeave={this.triggerOnMouseLeave}
          >
            <img
              src={activity.photo.large_file_url}
              alt={`${activity.user.first_name} ${activity.user.last_name}`}
            />
            {activity.type === "ActivityPlay" && activity.share_score && (
              <div
                className={`activity-score-overlay-container ${
                  hoverTriggered ? "animated fadeIn" : "animated fadeOut"
                }`}
              >
                <div className='activity-score-overlay-wrapper'>
                  <div className='row'>
                    <div className='col-4 activity-data activity-score'>
                      <h5>{activity.score}</h5>
                      <p>Score</p>
                    </div>
                    <div className='col-4 activity-data activity-greens'>
                      <h5
                        style={{
                          color: this.renderPillColor(
                            activity.greens,
                            "greens",
                            activity.holes
                          ),
                        }}
                      >
                        {activity.greens ? activity.greens : "N/A"}
                      </h5>
                      <p>Greens</p>
                    </div>
                    <div className='col-4 activity-data activity-putts'>
                      <h5
                        style={{
                          color: this.renderPillColor(
                            activity.putts,
                            "putts",
                            activity.holes
                          ),
                        }}
                      >
                        {activity.putts ? activity.putts : "N/A"}
                      </h5>
                      <p>Putts</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      } else {
        return (
          <div className='activity-image-wrapper'>
            <div className='no-media-wrapper'>
              <i className='fa fa-picture-o' />
              <h2>No media to show</h2>
            </div>
          </div>
        );
      }
    }
  };

  renderPillColor(number, type, holeCount) {
    if (type === "greens") {
      if (holeCount === 9) {
        if (number >= 0 && number <= 2) {
          return "#d9534f";
        } else if (number >= 3 && number <= 5) {
          return "#FFFF00";
        } else if (number >= 6 && number <= 9) {
          return "#33d9b2";
        } else {
          return "#2b3843";
        }
      } else {
        if (number >= 0 && number <= 5) {
          return "#d9534f";
        } else if (number >= 6 && number <= 11) {
          return "#FFFF00";
        } else if (number >= 12 && number <= 18) {
          return "#33d9b2";
        } else {
          return "#2b3843";
        }
      }
    } else if (type === "putts") {
      if (holeCount === 9) {
        if (number >= 23) {
          return "#d9534f";
        } else if (number >= 16 && number <= 22) {
          return "#FFFF00";
        } else if (number >= 1 && number <= 15) {
          return "#33d9b2";
        } else {
          return "#2b3843";
        }
      } else {
        if (number >= 45) {
          return "#d9534f";
        } else if (number >= 31 && number <= 44) {
          return "#FFFF00";
        } else if (number >= 1 && number <= 30) {
          return "#33d9b2";
        } else {
          return "#2b3843";
        }
      }
    }
  }

  renderComments = (comments, commentLoader) => {
    if (commentLoader) {
      return <Loader message='loading comments' />;
    }

    if (comments && comments.length > 0) {
      return comments.map((comment) => (
        <Comment
          key={comment.id}
          user={comment.user}
          body={comment.text}
          web
          time={comment.created_at}
        />
      ));
    }
    return (
      <Placeholder
        title={"No comments to show"}
        icon={"fa-comment"}
        subtext1='No members have posted any comments on this activity yet'
      />
    );
  };
  render() {
    const {
      showCommentModal,
      closeCommentModal,
      activity,
      toProfile,
      displayActivityHeaderType,
      commentLoader,
      comments,
      likes,
      user,
      onSubmit,
      onInputChange,
      value,
    } = this.props;
    return (
      <Modal
        isOpen={showCommentModal}
        onRequestClose={closeCommentModal}
        contentLabel='Comment Modal'
        style={customStyles}
      >
        <div id='ActivityModal' className='container-fluid'>
          <div className='row hidden-md-down'>
            <div className='col-lg-8 col-md-12 p-0 activity-modal-left-container'>
              {this.displayWebPhotoUrl()}
            </div>
            <div className='d-flex flex-column col-lg-4 col-md-12 p-0 activity-modal-right-container'>
              <div className='pt-3 mr-3'>
                <button
                  type='button'
                  className='close pull-right font-200'
                  data-dismiss='modal'
                  aria-label='Close'
                  onClick={closeCommentModal}
                >
                  <span aria-hidden='true'>✕</span>
                </button>
              </div>
              <div className='activity-modal-user-wrapper row mx-3'>
                <div className='col-2' onClick={toProfile}>
                  <Avatar
                    className='img-responsive'
                    imageUrl={
                      activity.user.avatar
                        ? activity.user.avatar.file_url
                        : null
                    }
                    hasCoachLabel={activity.user.type === "Coach"}
                    hasBorder={activity.user.type === "Coach"}
                    altName={
                      activity.user.first_name + " " + activity.user.last_name
                    }
                  />
                </div>
                <div className='col-10 activity-info-container'>
                  <h4 className='userName ml-2'>
                    {activity.user.first_name} {activity.user.last_name}
                  </h4>
                  {displayActivityHeaderType()}
                  <p className='activity-date ml-2'>
                    {moment(activity.activity_date).fromNow()}
                  </p>
                </div>
              </div>
              {activity.caption && (
                <p className='activity-caption mx-3 ml-2'>{activity.caption}</p>
              )}
              <div className='claps-comments-wrapper mx-3'>
                <Claps activity={activity} likes={likes} currentUser={user} />
                <p>
                  {activity.comment_count} Comment
                  {activity.comment_count === 1 ? '' : 's'}
                </p>
              </div>
              <div className='comments-section mx-3 scrollable-content'>
                {this.renderComments(comments, commentLoader)}
              </div>
              <div className='post-comment-wrapper'>
                <div className='comment-user-wrapper'>
                  <div className='smaller-round-image-wrapper'>
                    <img
                      src={user.avatar ? user.avatar.file_url : stock_profile}
                      alt={`${user.first_name} ${user.last_name}`}
                      className='round-image'
                    />
                  </div>
                </div>
                <form
                  className='comment-action-container'
                  onSubmit={onSubmit}
                >
                  <div className='comment-input-wrapper'>
                    <input
                      type='text'
                      name='Comment Form'
                      onChange={onInputChange}
                      value={value}
                      className='form-control'
                      placeholder={'Write a comment...'}
                    />
                  </div>
                  <div className='comment-post-wrapper'>
                    <button className='btn btn-link' type='submit'>
                      <i className='fa fa-paper-plane' />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className='hidden-lg-up activity-mobile-view'>
            <div className='pt-3 mr-3'>
              <button
                type='button'
                className='close pull-right font-200'
                data-dismiss='modal'
                aria-label='Close'
                onClick={closeCommentModal}
              >
                <span aria-hidden='true'>✕</span>
              </button>
            </div>
            <div className='activity-modal-user-wrapper'>
              <div className='avatar-wrapper' onClick={toProfile}>
                <Avatar
                  className='img-responsive'
                  imageUrl={
                    activity.user.avatar ? activity.user.avatar.file_url : null
                  }
                  hasCoachLabel={activity.user.type === "Coach"}
                  hasBorder={activity.user.type === "Coach"}
                  altName={
                    activity.user.first_name + " " + activity.user.last_name
                  }
                />
              </div>
              <div className='activity-info-container'>
                <h4 className='userName'>
                  {activity.user.first_name} {activity.user.last_name}
                </h4>
                {displayActivityHeaderType()}
                <p className='activity-date'>
                  {moment(activity.activity_date).fromNow()}
                </p>
              </div>
            </div>
            {activity.caption && (
              <p className='activity-caption'>{activity.caption}</p>
            )}
            {this.displayWebPhotoUrl()}
            <div className='activity-social-section'>
              <div className='claps-comments-wrapper'>
                <Claps activity={activity} likes={likes} currentUser={user} />
                <p>{activity.comment_count} Comments</p>
              </div>
              <div className='comments-section scrollable-content'>
                {this.renderComments(comments, commentLoader)}
              </div>
              <div className='post-comment-wrapper'>
                <div className='comment-user-wrapper'>
                  <div className='smaller-round-image-wrapper'>
                    <img
                      src={user.avatar ? user.avatar.file_url : stock_profile}
                      alt={`${user.first_name} ${user.last_name}`}
                      className='round-image'
                    />
                  </div>
                </div>
                <form className='comment-action-container' onSubmit={onSubmit}>
                  <div className='comment-input-wrapper'>
                    <input
                      type='text'
                      name='Comment Form'
                      onChange={onInputChange}
                      value={value}
                      className='form-control'
                      placeholder={"Write A comment..."}
                    />
                  </div>
                  <div className='comment-post-wrapper'>
                    <button className='btn btn-link' type='submit'>
                      <i className='fa fa-paper-plane' />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

ActivityModal.propTypes = {
  activity: PropTypes.shape({
    comment_count: PropTypes.number,
  }),
  user: PropTypes.shape({
    avatar: PropTypes.shape({
      file_url: PropTypes.string,
    }),
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }),
}

export default ActivityModal;
