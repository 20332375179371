import React from "react";
import PropTypes from 'prop-types'

import ReactPlayer from "react-player";
import ResourcesList from '../subcomponents/ResourcesList'

import "./CurriculumDetailModal.scss";

class CurriculumDetailModal extends React.Component {
  state = {
    selectedObjective: 0,
  };
  render() {
    const { selectedObjective } = this.state;
    const { subject, close, hasSideNav } = this.props;
    return (
      <div id='CurriculumDetailModal'>
        <div className='grey-out' onClick={close} />
        <div
          className={`curriculum-detail-modal-content ${
            hasSideNav ? "hasSideNav" : ""
          }`}
        >
          <div className='modal-preview-bar'>
            <div
              className='top-photo'
              style={{ backgroundImage: `url(${subject.photo.file_url})` }}
            >
              <div
                className='subject-icon'
                style={{ backgroundImage: `url(${subject.icon.file_url})` }}
              />
            </div>
            <div className='title-bar'>
              <p className='subject-title'>{subject.name}</p>
              <p className='subject-subtitle'>{`Operation 36 Curriculum ${subject.name} - all 6 Levels`}</p>
            </div>
            <div className='objective-list-view'>
              {subject.objectives.map((objective, index) => {
                return (
                  <div
                    key={index}
                    className={`objective-preview-bar ${
                      index === selectedObjective && "selected-objective"
                    }`}
                    onClick={() => this._selectObjective(index)}
                  >
                    <div
                      className='subject-icon-detail'
                      style={{
                        backgroundImage: `url(${subject.icon.file_url})`,
                        backgroundColor: objective.rank.color_hex,
                      }}
                    />
                    <div className='objective-preview-text'>
                      <p className='objective-preview-title'>
                        {this._titleCase(objective.name.toLowerCase())}
                      </p>
                      <p className='objective-preview-subtitle'>{`Level ${
                        index + 1
                      }: ${objective.rank.name}`}</p>
                    </div>
                    <i className='fa fa-angle-right' aria-hidden='true' />
                  </div>
                );
              })}
            </div>
          </div>
          <div className='object-view-area'>
            <div className='exit-container'>
              <i className='fa fa-times' aria-hidden='true' onClick={close} />
            </div>
            <div className='objective-video-container'>
              <ReactPlayer
                className='objective-modal-react-player'
                url={subject.objectives[selectedObjective].video.attachment_url}
                width='100%'
                height='240px'
                controls
              />
            </div>
            <div className='objective-view-title-area-container'>
              <div
                className='objective-icon-detail'
                style={{
                  backgroundImage: `url(${subject.icon.file_url})`,
                  backgroundColor:
                    subject.objectives[selectedObjective].rank.color_hex,
                }}
              />
              <div className='objective-view-title-area'>
                <p className='objective-detail-title'>
                  {this._titleCase(
                    subject.objectives[selectedObjective].name.toLowerCase()
                  )}
                </p>
                <p className='objective-detail-subtitle'>{`Level ${
                  selectedObjective + 1
                }: ${subject.objectives[selectedObjective].rank.name}`}</p>
              </div>
            </div>
            <p>{subject.objectives[selectedObjective].description}</p>
            <div className='objective-resources-container'>
              <div className='objective-resources-box'>
                <p className='resource-section-title'>Resources</p>
                {subject.objectives[selectedObjective]?.links?.length > 0 ?
                  <ResourcesList links={subject.objectives[selectedObjective].links}/>
                :
                  <div className='no-resource-placeholder'>
                    <p> Looks like this objective doesn't have any attached resources yet </p>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _selectObjective = (index) => {
    this.setState({ selectedObjective: index });
  };

  _titleCase = (str) => {
    let sentence = str.split(" ");
    let newStr = "";
    sentence.forEach((word) => {
      let newWord = word[0].toUpperCase() + word.slice(1);
      newStr += newWord + " ";
    });
    return newStr;
  };
}

CurriculumDetailModal.propTypes = {
  subject: PropTypes.shape({
    name: PropTypes.string,
  }),
}

export default CurriculumDetailModal;
