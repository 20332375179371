import _ from 'lodash'
import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'
import { getCommunityLandingPageData } from '../actions/communitiesActions'
import { getCommunityLandingPageCategories } from '../actions/programCategoryActions'
import { getCategoryPrograms, getCommunityLandingPagePrograms } from '../actions/programActions'
import { createCommunityView } from '../actions/viewActions'

const categoriesAdapter = createEntityAdapter()

export const defaultPerPage = 20

const initialState = {
  community: null,
  isLoadingViewCreate: true,
  isRequestInformationModalOpen: false,
  errors: null,
  categories: categoriesAdapter.getInitialState(),
  courses: null,
  otherRegistrationPeriodCoursesCount: null,
  isLoadingCategories: true,
  isLoadingCourses: true,
  isLoadingDetailedCategory: false,
  selectedCategory: null,
  filters: null,
  page: 0,
  hasMore: false,
  scrollPosition: 0,
  categoryViewScrollPosition: 0,
}

const communityLandingPageReducer = createSlice({
  name: 'communityLandingPage',
  initialState,
  reducers: {
    reset: () => initialState,
    setRequestInformationModalOpen: (state, action) => {
      state.isRequestInformationModalOpen = action.payload
    },
    setScrollPosition: (state, action) => {
      state.scrollPosition = action.payload
    },
    setCategoryViewScrollPosition: (state, action) => {
      state.categoryViewScrollPosition = action.payload
    },
    resetSelectedCategory: state => {
      state.selectedCategory = null
    },
  },
  extraReducers: builder => {
    builder
      // Get community details
      .addCase(getCommunityLandingPageData.fulfilled, (state, action) => {
        state.community = action.payload.community
      })
      .addCase(getCommunityLandingPageData.rejected, state => {
        state.errors = {
          title: 'Community not found',
          message: 'Looks like this community doesn\'t exist.',
        }
      })
      // Post community views
      .addCase(createCommunityView.fulfilled, state => {
        state.isLoadingViewCreate = false
      })
      .addCase(createCommunityView.rejected, state => {
        state.errors = {
          title: 'Something went wrong.',
          message: 'Please try again or search for another community.',
        }
      })
      // Get community categories for all available courses
      .addCase(getCommunityLandingPageCategories.fulfilled, (state, action) => {
        const categories = action.payload.courseCategories

        categoriesAdapter.setMany(state.categories, categories)
        state.filters = {
          future: false,
          categoryId: categories.map(c => c.id),
        }
        state.isLoadingCategories = false
      })
      // Get community programs grouped by categories
      .addCase(getCommunityLandingPagePrograms.pending, (state, action) => {
        if (action.meta.arg.page === 1) {
          state.isLoadingCourses = true
        }
      })
      .addCase(getCommunityLandingPagePrograms.fulfilled, (state, action) => {
        const filters = action.payload.filters
        if (state.courses && _.isEqual(filters, state.filters)) {
          state.courses = state.courses.concat(action.payload.courses)
        } else {
          state.courses = action.payload.courses
        }
        state.filters = filters

        state.otherRegistrationPeriodCoursesCount = action.payload.meta.otherRegistrationPeriodCoursesCount

        const paginationInfo = action.payload.meta.pagination
        state.page = paginationInfo.page
        state.hasMore = !paginationInfo.isLastPage

        state.isLoadingCourses = false
      })
      // Get single course category details
      .addCase(getCategoryPrograms.pending, (state, action) => {
        if (action.meta.arg.page === 1) {
          state.isLoadingDetailedCategory = true
        }
      })
      .addCase(getCategoryPrograms.fulfilled, (state, action) => {
        const courses = action.payload.courses
        const categoryId = action.payload.categoryId
        const pagination = action.payload.meta.pagination

        let allCourses = state.selectedCategory?.courses
        if (allCourses) {
          allCourses = allCourses.concat(courses)
        } else {
          allCourses = courses
        }

        state.selectedCategory = {
          ...state.categories.entities[categoryId],
          courses: allCourses,
          page: pagination.page,
          hasMore: !pagination.isLastPage,
        }
        state.isLoadingDetailedCategory = false
      })
  },
})

export const {
  reset,
  setRequestInformationModalOpen,
  setScrollPosition,
  setCategoryViewScrollPosition,
  resetSelectedCategory,
} = communityLandingPageReducer.actions

const { selectById } = categoriesAdapter.getSelectors()

export const {
  selectAll: selectAllCategories,
  selectIds: selectAllCategoriesIds,
} = categoriesAdapter.getSelectors(state => state.communityLandingPage.categories)

export const selectFilteredCategoryName = createSelector(
  state => state.communityLandingPage.categories,
  state => state.communityLandingPage.filters?.categoryId,
  selectAllCategoriesIds,
  (state, id, allIds) => {
    if (_.isEqual(id, allIds)) {
      return 'All'
    } else {
      return selectById(state, id)?.name
    }
  }
)

export const selectInitialFilters = createSelector(
  selectAllCategoriesIds,
  categoriesIds => ({
    future: false,
    categoryId: categoriesIds,
  })
)

export const selectHasFilters = createSelector(
  state => state.communityLandingPage.filters,
  selectInitialFilters,
  (filters, initialFilters) => !_.isEqual(filters, initialFilters)
)

export const selectFiltersNumber = createSelector(
  state => state.communityLandingPage.filters,
  selectInitialFilters,
  (filters, initialFilters) => {
    if (!filters) {
      return 0
    }

    return Object.keys(filters).reduce((count, filter) =>
      _.isEqual(filters[filter], initialFilters[filter]) ? count : count + 1, 0)
  }
)

export const selectAllOptions = createSelector(
  selectAllCategoriesIds,
  selectAllCategories,
  (categoriesIds, categories) => [
    { value: categoriesIds, label: 'All' },
    ...categories.map(c => ({ value: c.id, label: c.name })),
  ]
)

export default communityLandingPageReducer.reducer
