import React from "react";
import Modal from "react-modal";

// CHILDREN
import ResetPasswordEmailForm from "../../ResetPasswordEmailForm";
import Loader from "../../../common/Op36Loader-web";

const ForgotPasswordModal = ({
  showForgotPasswordModal,
  closeForgotPasswordModal,
  handleSubmitResetPasswordEmail,
  loading,
  emailSent,
}) => (
  <Modal
    isOpen={showForgotPasswordModal}
    onRequestClose={closeForgotPasswordModal}
    contentLabel='Example Modal'
  >
    <div className='modal-header row'>
      <div className='col-11'>
        <h5 className='modal-title display-4'>Forgot Password</h5>
      </div>
      <div className='col-1'>
        <button
          type='button'
          className='close pull-right font-200'
          data-dismiss='modal'
          aria-label='Close'
          onClick={closeForgotPasswordModal}
        >
          <span aria-hidden='true'>✕</span>
        </button>
      </div>
    </div>
    <div className='modal-content'>
      <div className='modal-body' id='input-border'>
        <div className='row mb-2 p-2'>
          <div className='col'>
            {loading ? (
              <Loader message='sending email' />
            ) : (
              <div className='form-group'>
                <ResetPasswordEmailForm
                  handleSubmit={handleSubmitResetPasswordEmail}
                  emailSent={emailSent}
                  closeModal={closeForgotPasswordModal}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  </Modal>
);

export default ForgotPasswordModal;
