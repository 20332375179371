export const getLevel = (yardage) => {
  if (yardage === 225) {
    return 1
  } else if (yardage === 450) {
    return 2
  } else if (yardage === 900) {
    return 3
  } else if (yardage === 1350) {
    return 4
  } else if (yardage === 1800) {
    return 5
  } else if (yardage > 1800 && yardage <= 2300) {
    return 6
  } else if (yardage > 2300 && yardage <= 2600) {
    return 7
  } else if (yardage > 2600 && yardage <= 2900) {
    return 8
  } else if (yardage > 2900 && yardage <= 3200) {
    return 9
  } else if (yardage > 3200) {
    return 10
  } else {
    return null
  }
}

export const yardageByLevel = {
  1: 225,
  2: 450,
  3: 900,
  4: 1350,
  5: 1800,
  6: 'fullTee',
  7: 'fullTee',
  8: 'fullTee',
  9: 'fullTee',
  10: 'fullTee',
}

export const fullTeeYardageByLevel = {
  6: 1801,
  7: 2301,
  8: 2601,
  9: 2901,
  10: 3201,
}
