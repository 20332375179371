import React, { Component } from "react";

import "./SinglePhotoUploader.scss";

// Derived from SimplePhotoUploader in components/common
class SinglePhotoUploader extends Component {
  constructor(props) {
    super(props);

    const setImage = () => {
      const { photo, selectedPhoto } = props;

      if (photo) {
        return photo.file_url;
      } else if (selectedPhoto) {
        return selectedPhoto;
      }
      return "";
    };

    this.state = {
      uploadPhotoName: "",
      uploadPhotoFile: "",
      uploadPhotoSrc: setImage()
    };
  }

  clearImage = () => {
    this.setState(
      {
        uploadPhotoName: "",
        uploadPhotoFile: "",
        uploadPhotoSrc: ""
      });
  };

  uploadClicked = () => {
    document.getElementById("input-photo-upload").click();
    this.clearImage();
  };

  onChangePhotoInput = event => {
    const { sendPhotoData } = this.props;

    // Make sure file upload wasn't canceled - only update
    // file info with valid values
    if (event.target.value && event.target.files[0]) {
      this.setState({
        uploadPhotoName: event.target.value,
        uploadPhotoFile: event.target.files[0]
      });
    }

    let reader = new window.FileReader();
    let page = this;
    reader.onload = function(e) {
      page.setState(
        {
          uploadPhotoSrc: e.target.result
        },
        () => {
          sendPhotoData(page.state);
        }
      );
    };

    // Only pass new file into readAsDataURL if file upload
    // wasn't canceled
    if (event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  render() {
    return (
      <div id='SinglePhotoUploader'>
        <button
          className='btn-rectangular btn-rectangular-secondary-positive'
          disabled={this.props.disableUploadBtn}
          onClick={this.uploadClicked}>+ Upload an image</button>

        {/*
          The onClick attribute is in case you upload a photo, delete it right
          after uploading, and then try to reupload it right after deleting
          */}
        <input
          name={""}
          id={'input-photo-upload'}
          type={"file"}
          accept={"image/*"}
          className={"hide form-control-file photoInput"}
          onChange={this.onChangePhotoInput}
          onClick={(event) => {event.target.value = ""}}
          placeholder={"Add a Different Photo"}
        />
      </div>
    );
  }
}

export default SinglePhotoUploader;
