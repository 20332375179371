import { useSelector } from 'react-redux'
import EventRow from '../../../../events/SeriesFlow/EventRow'

import styles from './SeriesSchedule.module.scss'

const SeriesSchedule = () => {
  const course = useSelector(state => state.programPresentation.course)

  return (
    course.series.map((series, index) =>
      <div className={styles['series-schedule']} key={index}>
        <p className={styles['title']}>{series.title}</p>
        <p>{series.subtitle}</p>
        <div className={styles['events']}>
          {series.events.map((event, index) => (
            <EventRow key={index} event={{ ...event }} timezone={course.community.timezone} index={index} />
          ))}
        </div>
      </div>
    )
  )
}

export default SeriesSchedule
