import * as types from "../actions/actionTypes";

// state = [] : Start out with no accounts
export default function upsellProductUsage(state = [], action) {
  switch (action.type) {
    case types.GET_UPSELL_PRODUCT_USAGE:
      return action.data;

    default:
      return state;
  }
}
