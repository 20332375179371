import React from "react";

import "./ReportsPopover.scss";

class ReportsPopover extends React.Component {
  render() {
    return (
      <div
        className={`container mt-2 text-center ${
          this.props.fromSticky ? "fix" : ""
        }`}
        id='ReportsPopover'
      >
        <h5>Reports</h5>
        <hr />
        <p onClick={this._handlePlayerProgressClick}>
          Junior Development Model (Beta)
        </p>
        <p onClick={this._handleJourneyClick}>On-Course Journey</p>
      </div>
    );
  }

  _handlePlayerProgressClick = () => {
    this.props.togglePlayerProgressModal();
    this.props.toggleDropdown();
  };

  _handleJourneyClick = () => {
    this.props.toggleJourneyModal();
    this.props.toggleDropdown();
  };
}

export default ReportsPopover;
