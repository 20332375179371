import React from "react";
import "./PlayerJourneyReportProgressBar.scss";
// import DIVISIONS from "../../../constants/divisions.json";

const PlayerJourneyReportProgressBar = (props) => {
  // let divisions = [...DIVISIONS];

  const { report } = props;
  // const divisionColor = divisions.filter(
  //   (division) => division.order === report.current_division
  // )[0].color;
  const rankBackgroundColor =
    "objective-rank-" + report.my_curriculum_progress.current_rank;
  const rankColor =
    "objective-rank-" + report.my_curriculum_progress.current_rank + "-text";
  const rankCompleted =
    report.my_curriculum_progress.rank_objectives_completed || 0;
  const rankToGo = report.my_curriculum_progress.objectives_to_go || 0;
  const completed = (rankCompleted / 12) * 100;

  const fillerStyles = {
    width: `${completed}%`,
  };

  return (
    <div className='progress-bar-container'>
      <div className='progress-bar-left'>
        <div
          style={fillerStyles}
          className={`progress-bar-filled ${rankBackgroundColor}`}
        ></div>
      </div>
      <div className='progress-bar-labels'>
        <span className={`completed ${rankColor}`}>
          <span className='progress-bar-qty'>{rankCompleted}</span>
          {' '}
          completed!
        </span>
        <span className='left'>
          <span className='progress-bar-qty'>{rankToGo}</span>
          {' '}
          more to go!
        </span>
      </div>
    </div>
  );
};

export default PlayerJourneyReportProgressBar;
