import classNames from 'classnames'
import Toggle from '../../common/form/Toggle'
import PropTypes from 'prop-types'
import classes from './NotificationToggle.module.scss'

const NotificationToggle = ({ id, name, title, description, defaultChecked, disabled }) => (
  <div className={classNames(classes.notificationWrapper, 'd-flex row flex-nowrap')}>
    <Toggle
      size='small'
      id={id}
      name={name}
      defaultChecked={defaultChecked}
      disabled={disabled}
    />
    <label htmlFor={id} className='d-flex flex-column'>
      <h5 className='font-weight-bold'>{title}</h5>
      <p>{description}</p>
    </label>
  </div>
)

NotificationToggle.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default NotificationToggle
