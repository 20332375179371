import { useDispatch, useSelector } from 'react-redux'
import MCElement from '../../../common/MCElement'
import { ReactComponent as InfoCircleIconDark } from '../../../../assets/images/common/icons/info-circle-icon-dark.svg'
import Tooltip from '../../../common/Tooltip'
import { setSelectedPaymentMethod } from '../../../../reducers/programRegistrationReducer'

import './PaymentSelection.scss'
import colors from '../../../../assets/styles/globals.scss'

const PaymentMethodSelection = () => {
  const dispatch = useDispatch()
  const selectedPaymentMethod = useSelector(state => state.programRegistration.selectedPaymentMethod)
  const enrollmentData = useSelector(state => state.programRegistration.enrollmentData)

  const handleSelectPaymentMethod = (paymentMethod) => {
    if (selectedPaymentMethod !== paymentMethod) {
      dispatch(setSelectedPaymentMethod(paymentMethod))
    }
  }

  return (
    <div id='PaymentMethodSelection'>
      <h5 className='step-label'>Select a payment method:</h5>
      {enrollmentData?.coursePaymentMethod.card && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
          onClick={() => handleSelectPaymentMethod('card')}
          className='payment-option-card'
        >
          <MCElement
            type='radio'
            onKeyDown={(e) => {
              e.key === 'Enter' && handleSelectPaymentMethod('card')
            }}
            primaryColor={selectedPaymentMethod === 'card' ? colors.secondaryColor : colors.lightNavy}
            isSelected={selectedPaymentMethod === 'card'}
          />
          <span className='payment-method-info'>Online (card)</span>
        </div>
      )}
      {enrollmentData?.coursePaymentMethod.cash && (
        <>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div
            onClick={() => handleSelectPaymentMethod('cash')}
            className='payment-option-card mb-0'
          >
            <MCElement
              type='radio'
              onKeyDown={(e) => {
                e.key === 'Enter' && handleSelectPaymentMethod('cash')
              }}
              primaryColor={selectedPaymentMethod === 'cash' ? colors.secondaryColor : colors.lightNavy}
              isSelected={selectedPaymentMethod === 'cash'}
            />
            <span className='payment-method-info'>Offline (cash, checks, etc.)</span>
            <InfoCircleIconDark
              id='infoCircleIcon'
              data-tip='Your coach may send more info to you about payment after you request enrollment.'
              data-for='cashPaymentInfo'
              aria-hidden='true'
              onClick={(e) => {
                e.stopPropagation()
              }} // Prevent selecting cash when clicking on the tooltip (for mobile)
            />
          </div>
          <Tooltip id='cashPaymentInfo' />
        </>
      )}
    </div>
  )
}

export default PaymentMethodSelection
