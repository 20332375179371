import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import _ from 'lodash'

import * as constants from '../../misc/Constants'
import { getAllSeries, deleteSeries } from '../../actions/seriesActions'
import colors from '../../assets/styles/globals.scss'

import SearchInput from '../common/SearchInput'
import SeriesList from './SeriesList'
import EventInProgressCard from './EventInProgressCard'
import ConfirmationModal2 from '../common/ConfirmationModal2'

import classNames from 'classnames'
import styles from './Events.module.scss'

const Series = ({ typeCompleted, loadScores, searchTerm, setSearchTerm, activePage, setActivePage }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const series = useSelector(state => typeCompleted ? state.series.completedSeries : state.series.upcomingSeries)
  const pagination = useSelector(state => typeCompleted ? state.series.completedSeriesPagination : state.series.upcomingSeriesPagination)

  const localScores = JSON.parse(localStorage.getItem('draftScores'))
  const eventScores = localScores && localScores[-1] && localScores[-1]['scores']

  const [loader, setLoader] = useState(false)
  const [selectedSeriesId, setSelectedSeriesID] = useState(null)
  const [showDeleteSeriesModal, setShowDeleteSeriesModal] = useState(false)

  const fetchSeries = useCallback((filters) => {
    setLoader(true)
    dispatch(getAllSeries({
      filters: { ...filters, withEvents: true },
      page: activePage, perPage: constants.PER_PAGE_DEFAULT,
    })).unwrap().then(() => {
      setLoader(false)
    })
  }, [dispatch, activePage])

  const debouncedSearchSeries = useMemo(() => (
    _.debounce(fetchSeries, 400)
  ), [fetchSeries])

  const onChange = (e) => {
    const value = e.target.value
    setSearchTerm(value)
    setLoader(true)
    setActivePage(1)
    debouncedSearchSeries({ search: value, completed: typeCompleted, withDrafts: !typeCompleted })
  }

  useEffect(() => {
    if (series && pagination.page === activePage || series?.length === 0) {
      return
    }
    fetchSeries({ completed: typeCompleted, search: searchTerm, withDrafts: !typeCompleted })
  }, [fetchSeries, activePage, series, pagination, typeCompleted, searchTerm])

  const removeSeries = () => {
    setSelectedSeriesID(selectedSeriesId)
    setShowDeleteSeriesModal(true)
  }

  const handleSeriesMenuClick = (seriesId) => {
    setSelectedSeriesID(seriesId)
  }

  const navigateToEdit = (step) => {
    navigate(`/series/${selectedSeriesId}/edit?step=${step}`)
  }

  const navigateToEmails = () => {
    const selectedSeries = series.find(s => s.id === selectedSeriesId)
    navigate('/announcements/new-campaign', { state: { smartToggle: 'email', students: selectedSeries.students } })
  }

  const handleDeleteSeries = () => {
    setLoader(true)

    dispatch(deleteSeries({ seriesId: selectedSeriesId, typeCompleted: typeCompleted })).unwrap()
      .then((res) => {
        toast.success(`Successfully deleted ${res.series.title}`, { position: toast.POSITION.BOTTOM_LEFT })
      })
      .catch(() => {
        toast.error('Something went wrong.', { position: toast.POSITION.TOP_RIGHT })
      })
      .finally(() => {
        setShowDeleteSeriesModal(false)
        setLoader(false)
      })
  }

  return (
    <div className={styles['events-page']}>
      <div className={classNames(styles['events-search-bar'], 'mt-2')}>
        <SearchInput value={searchTerm} placeholder='Search Events' onChange={onChange}/>
      </div>
      {eventScores && <div className='px-4 pb-3'><EventInProgressCard onClick={loadScores}/></div>}
      <SeriesList
        typeCompleted={typeCompleted}
        series={series}
        loader={loader}
        searchTerm={searchTerm}
        removeSeries={removeSeries}
        activePage={activePage}
        setActivePage={setActivePage}
        handleSeriesMenuClick={handleSeriesMenuClick}
        navigateToEdit={navigateToEdit}
        navigateToEmails={navigateToEmails}
        localScores={localScores}
      />
      <ConfirmationModal2
        showModal={showDeleteSeriesModal}
        closeModal={() => setShowDeleteSeriesModal(false)}
        onDeny={() => setShowDeleteSeriesModal(false)}
        denyMessage='Cancel'
        onProceed={() => handleDeleteSeries()}
        proceedMessage='Delete Series'
        icon={<i className='fa fa-exclamation-triangle' style={{ fontSize: '72px', color: colors.secondaryColor }} />}
        title='Are you sure?'
        message='This will remove the series and all the upcoming events.'
      />
    </div>
  )
}

Series.propTypes = {
  typeCompleted: PropTypes.bool,
  searchTerm: PropTypes.string,
  activePage: PropTypes.number,
  loadScores: PropTypes.func.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  setActivePage: PropTypes.func.isRequired,
}
export default Series
