import React, { Component } from 'react';
import { CSSTransition } from "react-transition-group";

// For facility location autocomplete dropdown
import PlacesAutocomplete from 'react-places-autocomplete';

import MCElement from '../../../common/MCElement';

// Uses some fields from QuestionStep
import './QuestionStep.scss';
import './OnboardingStep.scss';

const PROG_BAR_LENGTH = 236;

/**
  Represents a question screen in coach onboarding following free registration.
  Like enhanced version of QuestionStep.
*/
class OnboardingStep extends Component {
  state = {
    errors: {},
    valid: {}
  }

  componentDidMount() {
    this._initState();
  }

  // Need to reset local state when question changes
  componentDidUpdate(prevProps) {
    if (this.props.step.id !== prevProps.step.id) {
      this._initState();
    }
  }

  render() {
    const {
      id,
      icon,
      section_name,
      section_desc,
      questions
    } = this.props.step;

    const { percentage, answers, questionTransition, animDuration } = this.props;

    return (
      <div className='QuestionStep OnboardingStep'>
        <CSSTransition
          key={id + '-header'}
          in={questionTransition}
          timeout={animDuration}
          classNames='regStep'
        >
          <div style={{ width: '100%' }}>
            <img src={icon} alt={section_name} />
            <h2>{section_name}</h2>
            <h3>{section_desc}</h3>
          </div>
        </CSSTransition>
        <div
          className='question-step-header-bar'
          style={{ width: PROG_BAR_LENGTH }}>
          <div
            className='question-step-header-bar-fill'
            style={{ width: percentage * PROG_BAR_LENGTH + 'px' }}
            />
        </div>
        <CSSTransition
          key={id + '-body'}
          in={questionTransition}
          timeout={animDuration}
          classNames='regStep'
        >
          <div style={{ width: '100%' }}>
            {questions.map(q => this._renderQuestion(q, answers[q.id]))}
            {this._renderButton(id, questions[0])}
          </div>
        </CSSTransition>
      </div>
    );
  }

  /**
    Resets the arrays in local state when the question changes.
  */
  _initState = () => {
    let valid = {};
    let errors = {};
    for (let i = 0; i < this.props.step.questions.length; i++) {
      const questionId = this.props.step.questions[i].id;
      // If this question is 'affiliations', then valid can be initialized to
      // true because the question is optional
      valid[questionId] = questionId === 'affiliations';
      // valid[questionId] = false;
      errors[questionId] = null;
    }

    this.setState({ valid, errors });
  };

  /**
    Renders each question title and field.
  */
  _renderQuestion = (question, answer) => {
    const { errors } = this.state;
    const { id, title } = question;
    const { otherValues } = this.props;
    const errorClass = errors[id] && errors[id] ? ' question-step-input-field-error' : '';

    switch (question.type) {
      case 'input':
        const { errorMsg } = question;

        return (
          <div key={id} className='onboarding-question-container'>
            <label
              className='question-step-question'
              htmlFor={id}
              id={id + '_choice'}
              name={id + '_choice'}>
              {title}
            </label>
            <input
              className={'question-step-input-field' + errorClass}
              type={question.type}
              placeholder={question.placeholder}
              value={answer || ''}
              onChange={(event) => this._handleInputChange(event.target.value, id, errorMsg, 'input')} />
            <p className='question-step-input-error-msg'>{errors[id] && errorMsg}</p>
          </div>
        );
      case 'dropdown':
        // Special case for location autocomplete dropdown
        if (id === 'location') {
          return this._getLocationDropdown(id, answer, title);
        }

        // Get answer choices
        let dropdownVal = (
          question.choices.map(c => (
            <option value={c} key={c}>{c}</option>
          ))
        );

        // If 'Other' is a choice, add it as a dropdown value
        if (question.other_choice) {
          dropdownVal.push(<option value='Other' key='Other'>Other</option>);
        }

        // Add 'placeholder' to front of array
        dropdownVal.unshift(
          <option value={'unselected'} key='unselected' disabled>
            {question.placeholder}
          </option>
        );

        // Render select element
        const selectColor = answer === '' ? ' dropdown-unselected' : '';
        const showOtherField = answer === 'Other' ? true : false;
        return (
          <div key={id} className='onboarding-question-container'>
            <label
              htmlFor={id + '_choice'}
              className='question-step-question'>
              {title}
            </label>
            <select
              className={'question-step-input-field question-step-dropdown' + selectColor}
              onChange={(event) => this._handleInputChange(event.target.value, id, '', 'dropdown')}
              defaultValue={'unselected'}
              id={id}
              name={id}>
              {dropdownVal}
            </select>
            {showOtherField &&
              <div>
                <input
                  className='question-step-dropdown-other-input'
                  type='text'
                  placeholder='Enter answer here'
                  value={otherValues[id] || ''}
                  onChange={(e) => this._checkOtherInputChange(e, id, true)} />
                <p className='question-step-input-error-msg'>{errors[id]}</p>
              </div>
            }
          </div>
        );
      case 'multiple_choice':
        const { choices, multi_select, other_choice } = question;
        return (
          <div key={id} className='onboarding-question-container'>
            <label
              className='question-step-question'
              htmlFor={id}>
              {title}
            </label>
            {multi_select &&
              <h4 className='multi-select-desc'>(Select all that apply)</h4>
            }
            {this._renderMultipleChoice(choices, multi_select, answer, other_choice, otherValues[id], id)}
            <p className='question-step-input-error-msg'>{errors[id] && errors[id]}</p>
          </div>
        );
      default:
        return null;
    }
  };

  /**
    Change the appropriate array index in the values array and
    send it back to CoachRegistrationFlow to save in state.
  */
  _handleInputChange = (value, questionId, errorMsg, type) => {
    // Pass up new value
    this.props.changeHandle(questionId, value);

    // Check validity here based on question type (dropdown or input)
    if (type === 'dropdown' && questionId !== 'location') {
      if (value !== 'Other') {
        // Change input to valid since it's no longer unselected
        let valid = { ...this.state.valid };
        let errors = { ...this.state.errors };
        valid[questionId] = true;
        errors[questionId] = null;
        this.setState({ valid, errors });
      } else {
        // Check if "Other" option is filled in
        const message = 'Please fill in the "Other" option';
        this._validateInput(this.props.otherValues[questionId], questionId, message);
      }
    } else {
      // Is input field or dropdown with input field (i.e. PlacesAutocomplete)
      // Check if it's filled in
      this._validateInput(value, questionId, errorMsg);
    }
  };

  /**
    Renders multiple choice answer choices.
  */
  _renderMultipleChoice = (choices, isMultiSelect, selectedVal, hasOther, otherValue, questionId) => {
    const elemType = isMultiSelect ? 'checkbox' : 'radio';

    // Render items that are not 'Other'
    let items = choices.map((c, idx) => {
      // Check selectedVal depending on if it's multiselect (array) or not (string)
      return (
        <div
          key={c + idx}
          className='question-step-multiple-choice'
          onClick={(event) => this._handleMultipleChoiceClick(event, isMultiSelect, idx, selectedVal, questionId, hasOther)}>
          <MCElement type={elemType} isSelected={selectedVal[idx]} />
          {c}
        </div>
      );
    });

    // If there is an 'Other' option, add that too
    if (hasOther) {
      const isSelected = selectedVal[choices.length];
      items = items.concat(
        <div
          key='Other'
          className='question-step-multiple-choice'
          onClick={(event) => this._handleMultipleChoiceClick(event, isMultiSelect, choices.length, selectedVal, questionId, hasOther)}>
          <MCElement type={elemType} isSelected={isSelected} />
          Other:
          <input
            className='question-step-multiple-choice-other-input'
            type='text'
            placeholder='Enter answer here'
            value={otherValue || ''}
            onChange={(e) => this._checkOtherInputChange(e, questionId, isSelected)} />
        </div>
      );
    }

    return items;
  };

  /**
    Change the values array and send it back to CoachRegistrationFlow
    to save in state.
  */
  _handleMultipleChoiceClick = (event, isMultiSelect, idx, selectedVal, questionId, hasOther) => {

    event.stopPropagation();

    // If click was on <input> inside of multiple choice option, don't select
    // or deselect the element
    if (event.target.tagName === 'INPUT') {
      return;
    }

    let userInput = [...selectedVal];

    // Check if multiple items are allowed to be selected
    if (isMultiSelect) {
      userInput[idx] = !userInput[idx]; // De/select (toggle to opposite state)
    } else {
      // Change value to selected value
      userInput = userInput.map((c, i) => userInput[i] = false);
      userInput[idx] = true;
    }

    // Update value in main state
    this.props.changeHandle(questionId, userInput);

    // Validation - check if there is at least one value that is selected (true)
    let valid = { ...this.state.valid };
    let errors = { ...this.state.errors };

    // Check if any values are selected
    if (userInput.some(c => c)) {
      // Also if 'Other' is selected but the field is empty, show error
      if (userInput[userInput.length - 1] && hasOther && !this.props.otherValues[questionId]) {
        valid[questionId] = false;
        errors[questionId] = 'Please fill in the "Other" option';
      } else {
        valid[questionId] = true;
        errors[questionId] = null;
      }
    } else {
      // If no values are selected, answers are invalid
      // (Except for the affiliations question - answers are optional there)
      if (questionId !== 'affiliations') {
        valid[questionId] = false;
        errors[questionId] = null;
      } else {
        valid[questionId] = true;
        errors[questionId] = null;
      }
    }
    this.setState({ valid, errors });
  };

  /**
    Check if input in Other checkbox field is valid as it is changed.
  */
  _checkOtherInputChange = (event, questionId, isSelected) => {
    // Send value to main state
    this.props.otherValueChangeHandle(event, questionId);

    // Validation (if Other is selected)
    if (isSelected) {
      const message = 'Please fill in the "Other" option';
      this._validateInput(event.target.value, questionId, message);
    }
  };

  /**
    Checks if input is empty or not and adds error message if it is.
  */
  _validateInput = (input, questionId, msg) => {
    const regex = /[^\s]/;
    let valid = { ...this.state.valid };
    let errors = { ...this.state.errors };

    if (regex.test(input)) {
      valid[questionId] = true;
      errors[questionId] = null;
    } else {
      valid[questionId] = false;
      errors[questionId] = msg;
    }

    this.setState({ valid, errors });
  };

  /**
    Renders a button with the appropriate text if a button is needed.
  */
  _renderButton = (id, question) => {
    // If it is a radio button question, don't need to render button
    if (question.type === 'multiple_choice' && !question.multi_select) {
      return;
    }

    // Change the button label if it's the last question, which has an id of
    // 'affiliations'
    const btnText = id !== 'affiliations' ? 'Next' : 'Go to my dashboard!';

    // If all values are valid, enable button
    let buttonDisabled = false;
    for (let k in this.state.valid) {
      if (!this.state.valid[k]) {
        buttonDisabled = true;
      }
    }

    return (
      <button
        className='btn-rectangular btn-rectangular-main-positive question-step-button'
        disabled={buttonDisabled}
        onClick={() => this.props.nextButtonHandle()}>
        {btnText}
      </button>
    );
  };

  /**
    Grabs the PlacesAutocomplete location dropdown.
    (Pasted from https://github.com/hibiken/react-places-autocomplete#readme)
  */
  _getLocationDropdown = (id, value, title) => {
    const { errors } = this.state;
    const errorClass = errors[id] && errors[id] ? ' question-step-input-field-error' : '';
    const errMsg = 'Please enter your facility\'s location';
    const classes = 'question-step-input-field'.concat(errorClass);

    // Only grab cities
    const searchOptions = {
      types: ['(cities)']
    };

    // TODO clean up the onChange and onSelect eventually
    return (
      <div key={id} className='onboarding-question-container'>
        <label
          htmlFor={id + '_choice'}
          className='question-step-question'>
          {title}
        </label>
        <PlacesAutocomplete
          key={id}
          value={value || ''}
          onChange={(val) => {
            // Use handleInputChange to check if empty, but it's not truly
            // valid until selected from list (even if it matches a suggestion
            // exactly)
            this._handleInputChange(val, id, errMsg, 'dropdown');
            let valid = { ...this.state.valid };
            valid[id] = false;
            this.setState({ valid });
          }}
          onSelect={(address) => {
            // It is always valid if it's selected from the list
            this._handleInputChange(address, id, errMsg, 'dropdown');
            let valid = { ...this.state.valid };
            valid[id] = true;
            this.setState({ valid });
          }}
          searchOptions={searchOptions}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <input
                {...getInputProps({
                  placeholder: 'City, Region, Country',
                  className: classes
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion, idx) => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  return (
                    <div
                      key={idx}
                      {...getSuggestionItemProps(suggestion, {
                        className
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
        <p className='question-step-input-error-msg' style={{ marginTop: '10px'}}>
          {errors[id]}
        </p>
      </div>
    );
  };
}

export default OnboardingStep;
