import React, { Component } from 'react';

import '../../assets/styles/common/placeholder.scss';

class Placeholder extends Component {
  render() {
    return (
      <div className='placeholder-container'>
        <h4 className='placeholder-title'>{this.props.title}</h4>
        <div className='placeholder-icon-wrapper'>
          <i className={`fa ${this.props.icon} placeholder-icon`} style={{ fontSize: `${this.props.size}px`}} aria-hidden='true'></i>
        </div>
        <div className='placeholder-subtext-wrapper'>
          { this.props.subtext1 ? <p className='placeholder-subtext'>{this.props.subtext1}</p> : null }
          { this.props.subtext2 ? <p className='placeholder-subtext'>{this.props.subtext2}</p> : null }
          { this.props.subtext3 ? <p className='placeholder-subtext'>{this.props.subtext3}</p> : null }
        </div>
        { this.props.buttonText ?
          <button className="btn btn-primary text-white mb-3 placeholder-button" onClick={this.props.handleClick}>
            <i className={`fa ${this.props.buttonIcon}`} aria-hidden="true"></i> {this.props.buttonText}
          </button> : null }
      </div>
    )
  }
}

export default Placeholder;
