import PropTypes from 'prop-types'
import './ModalFooter.scss'

const ModalFooter = ({ firstButton, secondButton }) => (
  <div className='modal-footer-container'>
    <div className='footer-buttons-container pull-right'>
      {firstButton &&
        <button
          className='btn btn-primary text-white pull-right ml-3'
          onClick={() => firstButton.onClick()}
          disabled={firstButton.disabled}
        >
          {firstButton.text}
        </button>
      }
      {secondButton &&
        <button
          className='btn btn-tertiary text-white pull-right ml-3'
          onClick={() => secondButton.onClick()}
          disabled={secondButton.disabled}
        >
          {secondButton.text}
        </button>
      }
    </div>
  </div>
)

ModalFooter.propTypes = {
  firstButton: PropTypes.shape({
    text: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
  }),
  secondButton: PropTypes.shape({
    text: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
  }),
}

export default ModalFooter
