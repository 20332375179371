import React from "react";
import "./ErrorMessage.scss";

const ErrorMessage = ({ error }) => (
  <div id="ErrorMessage">
    <p>{error}</p>
  </div>
);

export default ErrorMessage;
