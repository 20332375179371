import React from "react";

import "./LessonPlanCardPopover.scss";

class LessonPlanCardPopover extends React.Component {
  componentWillMount() {
    document.addEventListener("click", this._handleClick, false);
  }
  componentWillUnmount() {
    document.removeEventListener("click", this._handleClick, false);
  }
  render() {
    const { is_draft, duplicate, id, isMine } = this.props;
    return (
      <div
        id='LessonPlanCardPopover'
        ref={node => (this.node = node)}
        onClick={this._anotherClick}
      >
        <p onClick={duplicate}>{`Duplicate ${is_draft ? "draft" : "plan"}`}</p>
        <p
          className={isMine ? "" : "inactive"}
          onClick={e => this._handleEditClick(e)}
        >{`Edit ${is_draft ? "draft" : "plan"}`}</p>
        <p
          className={`delete-lp ${isMine ? "" : "inactive"}`}
          onClick={() => this._handleDeleteClick(id)}
        >{`Delete ${is_draft ? "draft" : "plan"}`}</p>
      </div>
    );
  }

  _handleEditClick = e => {
    const { edit, isMine } = this.props;
    if (isMine) {
      edit(e);
    }
  };

  _handleDeleteClick = id => {
    const { deleteLP, isMine } = this.props;
    if (isMine) {
      deleteLP(id);
    }
  };

  _handleClick = e => {
    // just tracks if the user clicks outside the component to close the popover
    if (!this.node.contains(e.target)) {
      this.props.close();
    }
  };

  _anotherClick = e => {
    // stops from click event on whitespace of component selecting this session to display on the page
    e.stopPropagation();
  };
}

export default LessonPlanCardPopover;
