import React from "react";

// REDUX BITS
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as commonActions from "../../actions/commonActions";
import * as familyActions from "../../actions/familyActions";

// DEPENENCIES
import _ from "lodash";

// ASSETS
import "../../assets/styles/components/families/FamilyPage.scss";

// CHILDREN
// import StudentForm from './StudentForm';
import StudentCard from "../common/StudentCard";
import Loader from "../common/Op36Loader-web";
import AlertDialog from "../common//AlertDialog";
import PageHeader from '../common/PageHeader/PageHeader'
import FamilyMemberModal from "./family_modals/FamilyMemberModal";
import { withRouting } from "../common/hooks";
// import COPPAConsentNotice from '../consent/COPPAConsentNotice';

class FamilyPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showAddMemberModal: false,
      // showCOPPAModal: false,
      family_id: "",
      errorTitle: "Error",
      errorMessage: "Something went wrong, please try again later.",
      familyLoader: true
    };

    this.renderFamilyMembers = this.renderFamilyMembers.bind(this);
    this.showErrors = this.showErrors.bind(this);
  }

  componentWillMount() {
    const { common_actions } = this.props;
    common_actions.setCurrentTab("Family");
    common_actions.showSideBar(true);
    common_actions.showNavBar(true);
    this.props.family_actions.getFamily(this.props.user.family.id).then(() => {
      this.setState({
        familyLoader: false
      });
    });

    if (this.props.params.showAdd) {
      this.setState({ showAddMemberModal: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.family) {
      this.setState({ family_id: nextProps.family.id });
    }
  }

  // handleAddGolfer = () => this.toggleCOPPAModal();

  toggleAddMemberModal = () =>
    this.setState(prevState => ({
      showAddMemberModal: !prevState.showAddMemberModal
    }));

  // toggleCOPPAModal = () => this.setState(prevState => ({ showCOPPAModal: !prevState.showCOPPAModal }));

  navigateTo = user => {
    this.props.navigate(
      `/profile/${user.id}`,
      { state: { userId: user.id } }
    )
  };

  renderFamilyMembers() {
    const { family } = this.props;
    const { familyLoader } = this.state;
    
    if (familyLoader) {
      return (
        <div className="" style={{ marginTop: "50px", width: "100%" }}>
          <Loader message="loading family" />
        </div>
      );
    }

    if (_.isEmpty(family)) {
      return null
    }

    let sortedUsers = [...family.users].sort((u1, u2) => {
      if (u1.family_admin && !u2.family_admin) return -1
      if (!u1.family_admin && u2.family_admin) return 1
      
      if (u1.last_name < u2.last_name) return -1
      if (u1.last_name > u2.last_name) return 1
  
      if (u1.first_name < u2.first_name) return -1
      if (u1.first_name > u2.first_name) return 1
  
      return 0
    })
    
    return sortedUsers.map(user => (
      <StudentCard student={user} key={user.id} />
    ))
  }

  checkUserAvatar(user) {
    if (user.avatar) {
      return (
        <img
          src={user.avatar.file_url}
          alt="Name"
          className="img-responsive classDetailPage-avatar center-block"
        />
      );
    } else {
      return null;
    }
  }

  showErrors() {
    if (this.state.errors) {
      console.log("ERRORS");
      return (
        <div id="formErrors">
          <p>
            <span className="icon icon-info" />
            {this.state.errors}
          </p>
        </div>
      );
    } else {
      return "";
    }
  }

  headerActionButton = () => (
    <button
      className='btn btn-primary text-white pull-right mr-2'
      onClick={this.toggleAddMemberModal}
    >
      Add Member
    </button>
  )

  render() {
    return (
      <div id="FamilyPage">
        <PageHeader
          title='Family Members'
          actionComponent={this.headerActionButton()}
        />
        <AlertDialog
          hideOnOverlayClicked={true}
          ref="alertDialog"
          showOverlay={true}
          title={this.state.errorTitle}
        >
          {this.state.errorMessage}
        </AlertDialog>
        <div className='row mt-15px'>{this.renderFamilyMembers()}</div>
        <FamilyMemberModal
          toggleModal={this.toggleAddMemberModal}
          showModal={this.state.showAddMemberModal}
          modalType="add"
        />
        {/* <Modal
          isOpen={this.state.showCOPPAModal}
          onRequestClose={this.toggleCOPPAModal}
          contentLabel="COPPA Modal"
        >
          <COPPAConsentNotice toggleAddMemberModal={this.toggleAddMemberModal} />
        </Modal> */}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    ui: state.ui,
    user: state.user.current_user,
    family: state.family
  };
}

function mapDispatchToProps(dispatch) {
  return {
    common_actions: bindActionCreators(commonActions, dispatch),
    family_actions: bindActionCreators(familyActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouting(FamilyPage))
