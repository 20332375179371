import ProgressBarSteps from '../../../util/progress-bar/ProgressBarSteps'
import { useSelector } from 'react-redux'

const RegistrationStepsProgressBar = () => {
  const { steps, completedSteps } = useSelector(state => state.programRegistration)

  return (
    <ProgressBarSteps
      currentStep={completedSteps.length}
      stepsNumber={steps.length}
      stepNames={steps}
    />
  )
}

export default RegistrationStepsProgressBar
