import React, { Component } from "react";

// CHILDREN
import Placeholder from "../../../common/Placeholder";
import EducationSection from "./EducationSection";

class EducationContent extends Component {
  state = {};

  renderEducationSections = () => {
    const {
      education,
      toggleVideoModal,
      isCoach,
      userEducation,
      expanded
    } = this.props;

    if (
      education.education_sections &&
      education.education_sections.length > 0
    ) {
      return education.education_sections.map((section, index) => (
        <EducationSection
          section={section}
          index={index}
          toggleVideoModal={toggleVideoModal}
          isCoach={isCoach}
          userEducation={userEducation}
          key={section.id}
          expanded={expanded}
        />
      ));
    }
    return (
      <Placeholder
        title={"No content to show"}
        icon={"fa-graduation-cap"}
        subtext1={"Currently there is no content available for this training"}
      />
    );
  };

  render() {
    return <div className='mt-3'>{this.renderEducationSections()}</div>;
  }
}

export default EducationContent;
