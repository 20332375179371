import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import Modal from '../../common/Modal'
import SubmitButton from '../../common/buttons/SubmitButton'
import ButtonLink from '../../common/buttons/ButtonLink'

import classes from './CommunityLandingPageTab.module.scss'
import { ReactComponent as Icon } from '../../../assets/images/common/icons/golfer-half-icon-round.svg'

const ConfirmationModal = (props) => {
  const communityId = useSelector(state => state.communitySettings.landingPage.community.id)

  return (
    <Modal fixedTop {...props}>
      <div className='d-flex flex-column align-items-center px-3'>
        <Icon className='mb-3'/>
        <h2 className={classes['confirmation-modal-title']}>Saved Successfully</h2>
        <p className='text-center font-weight-bold'>Your Community Landing Page and Program Reporting have been updated successfully.</p>
        <ButtonLink navigateTo={`/landing/${communityId}`} target='_blank'>
          View Landing Page
        </ButtonLink>
        <SubmitButton type='button' buttonColor='navy-button' buttonMessage='Done' onClick={props.onClose} />
      </div>
    </Modal>
  )
}

ConfirmationModal.propTypes = {
  onClose: PropTypes.func,
}

export default ConfirmationModal
