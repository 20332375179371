import React, { Component } from "react";
import _ from "lodash";
import SimplePhotoUploader from "../../common/SimplePhotoUploader";
import DatePicker from '../../common/DatePicker'
import CurrencyInput from "../../common/CurrencyInput";

class AdminEducationForm extends Component {
  render() {
    const {
      formState,
      closeModal,
      loader,
      next,
      handleInputChange,
      grabPhotoData,
      isEditModal,
      handleDateChange,
    } = this.props;
    return (
      <div className='row'>
        <div className='col-md-6'>
          <label htmlFor='featured-image'>Featured Image</label>
          <SimplePhotoUploader
            sendPhotoData={grabPhotoData}
            selectedPhoto={formState.uploadPhotoSrc}
          />
        </div>
        <div className='col-md-6'>
          <div className='form-group'>
            <label htmlFor='name'>Education Name</label>
            <input
              type='text'
              name='name'
              className='form-control'
              id='name'
              placeholder='Education Name'
              onChange={handleInputChange}
              value={formState.name}
            />
          </div>
          <div className='form-group'>
            <label htmlFor='video_url'>Preview Video URL</label>
            <input
              type='text'
              name='video_url'
              className='form-control'
              id='video_url'
              placeholder='Preview Video URL'
              onChange={handleInputChange}
              value={formState.video_url}
            />
          </div>
        </div>
        <div className='col-md-6'>
          <div className='form-group'>
            <label htmlFor='visibility'>Visibility (who can see this)</label>
            <select
              name='visibility'
              className='form-control'
              value={formState.visibility}
              onChange={handleInputChange}
            >
              <option value={0}>All Coaches</option>
              <option value={1}>Dev League and Academy Coaches</option>
              <option value={2}>Only Academy Coaches</option>
            </select>
          </div>
        </div>
        <div className='col-md-6'>
          <div className='form-group'>
            <label htmlFor='price'>Price</label>
            <CurrencyInput
              className='form-control'
              max={999999}
              onValueChange={(num) => this._formatCurrencyEvent(num)}
              value={formState.price}
              style={{ textAlign: "right" }}
            />
          </div>
        </div>
        <div className='col-md-6'>
          <div className='form-group'>
            <label>Start Date (optional)</label>
            <DatePicker
              selected={formState.start_date}
              onChange={(d) => handleDateChange("start_date", d)}
            />
          </div>
        </div>
        <div className='col-md-6'>
          <div className='form-group'>
            <label>End Date (optional)</label>
            <DatePicker
              selected={formState.end_date}
              onChange={(d) => handleDateChange("end_date", d)}
            />
          </div>
        </div>
        <div className='col-md-12'>
          <div className='form-group'>
            <label htmlFor='description'>Description</label>
            <textarea
              type='text'
              name='description'
              className='form-control'
              id='description'
              placeholder='Description'
              onChange={handleInputChange}
              value={formState.description}
            />
          </div>
        </div>

        <div className='col-md-12 d-flex justify-content-center align-items-center mt-4'>
          <button
            className='btn btn-dark m-2'
            onClick={closeModal}
            disabled={loader}
          >
            Cancel
          </button>
          <button
            className='btn btn-primary m-2'
            onClick={next}
            disabled={loader}
          >
            {isEditModal ? "Edit" : "Add"} Sections
          </button>
        </div>
      </div>
    );
  }

  _formatCurrencyEvent = (num) => {
    let event = {
      target: {
        name: "price",
        value: num,
      },
    };
    this.props.handleInputChange(event);
  };
}
export default AdminEducationForm;
