import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Col, Collapse, Container, Row } from 'reactstrap'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { withMaterials } from '../../../../reducers/programRegistrationReducer'

import './SummaryDropDown.scss'
import DropdownIcon from '../../../../assets/images/common/icons/select-drop-down-icon-white.svg'

const SummaryDropDown = ({ children, isEmbedded }) => {
  const isWithMaterials = useSelector(withMaterials)
  const isStatic = isEmbedded && !isWithMaterials
  const [isOpen, setIsOpen] = useState(isStatic)

  return (
    <div id='SummaryDropDown' className={classNames('w-100', !isStatic && 'position-relative')}>
      <div
        role='button'
        tabIndex={0}
        onClick={() => { setIsOpen(!isOpen) }}
        onKeyDown={(e) => { e.key === 'Enter' && setIsOpen(!isOpen) }}
        className={classNames('align-items-center justify-content-end px-3 summary-dropdown-header', isStatic ? 'd-none d-md-flex' : 'd-flex')}
      >
        <span>{isOpen ? 'Hide' : 'View'} Details</span>
        <img
          src={DropdownIcon}
          alt='dropdown'
          className='ml-2 ml-md-5 summary-dropdown-icon'
          style={isOpen ? { transform: 'scaleY(-1)' } : null}
        />
      </div>
      <Collapse isOpen={isOpen} className={classNames('w-100 summary-dropdown-section', !isStatic && 'position-absolute')} toggler='#toggler'>
        <Container fluid className='py-3 pb-md-4 pt-md-0'>
          <Row className='pl-md-5'>
            <Col md={6}>
              {children[0]}
            </Col>
            <Col md={6} className='pt-2 pt-md-0'>
              {children[1]}
            </Col>
          </Row>
        </Container>
      </Collapse>
    </div>
  )
}

SummaryDropDown.propTypes = {
  isEmbedded: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.node),
}

export default SummaryDropDown
