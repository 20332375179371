import React from "react";

// import ProShopHeader from "../proShopHeader/ProShopHeader";
import CommunityLeaderboardCard from "./communityLeaderboardCard/CommunityLeaderboardCard";

import StockProfilePic from "../../../assets/images/mock/StockProfilePic.png";

import playIcon from "../../../assets/images/activity-icons/play-open-opaque.png";
import trainIcon from "../../../assets/images/activity-icons/train-open-opaque.png";
import socialIcon from "../../../assets/images/activity-icons/social-open-opaque.png";

import "./CommunityLeaderboard.scss";

const communityLeaderboard = props => {
  // Get today's month
  const today = new Date();
  const month = new Intl.DateTimeFormat(undefined, {
    month: "long"
  }).format(today);

  // Style outer wrapper so it occupies remaining part of vh
  // const wrapperStyle = {
  //   height: "100%",
  //   display: "flex",
  //   flexDirection: "column"
  // };

  // Get top 10 to slice into 1-2 parts: top3 and remainder
  // (remainder will be empty if there are fewer than 4 students)
  const top = props.commLeaders.slice(0, 10);
  const top3 = top.slice(0, 3); // Could be empty if 0 students
  const remainder = top.length > 3 ? top.slice(3) : [];

  // Arrays to hold top3 elements and remainder elements
  let top3Elem = [];
  let remainderElem = [];

  // Call-to-action for the right side if there is empty space
  const callToAction = (
    <div className='comm-leaderboard-no-data comm-leaderboard-call-to-action'>
      <p className='comm-leaderboard-call-to-action-text'>
        Log Play, Train, and Social actions to appear on the leaderboard!
      </p>
      <div>
        <img
          src={playIcon}
          alt='play icon'
          className='comm-leaderboard-call-to-action-icon'
        />
        <img
          src={trainIcon}
          alt='train icon'
          className='comm-leaderboard-call-to-action-icon'
        />
        <img
          src={socialIcon}
          alt='social icon'
          className='comm-leaderboard-call-to-action-icon'
        />
      </div>
    </div>
  );

  // Add top3 students, if any (left half of display)
  for (let i = 0; i < 3; i++) {
    // If student exists in spot, map data to elem; else, put "No data"
    if (top3[i]) {
      // Use default profile pic if student hasn't set one yet
      let studentPic = StockProfilePic;
      if (top3[i].student.avatar && top3[i].student.avatar.file_url) {
        studentPic = top3[i].student.avatar.file_url;
      }
      const name =
        top3[i].student.first_name + " " + top3[i].student.last_name[0] + ".";

      top3Elem[i] = (
        <div
          key={i}
          className='comm-leaderboard-top-3-card'
          id={"comm-leaderboard-top-3-card-" + (i + 1)}
        >
          <img
            src={studentPic}
            alt={"profile picture of " + name}
            className='comm-leaderboard-top-3-card-pic'
          />
          <p className='comm-leaderboard-top-3-card-name'>{name}</p>
          <p className='comm-leaderboard-top-3-card-points'>
            {top3[i].monthly_points.toLocaleString()}pts
          </p>
          <div className='comm-leaderboard-top-3-card-ordinal'>
            <p>{i + 1}</p>
          </div>
        </div>
      );
    } else {
      top3Elem[i] = (
        <div
          key={i}
          className='comm-leaderboard-top-3-card'
          id={"comm-leaderboard-top-3-card-" + (i + 1)}
        >
          <p className='comm-leaderboard-no-data'>Up for grabs!</p>
          <div className='comm-leaderboard-top-3-card-ordinal'>
            <p>{i + 1}</p>
          </div>
        </div>
      );
    }
  }

  // Reorder top3Elem so #1 is in the middle, #2 is on the left, and #3 is on the right
  const temp = top3Elem[0];
  top3Elem[0] = top3Elem[1];
  top3Elem[1] = temp;

  // Add remaining students, if any (right half of display)
  if (remainder.length > 0) {
    remainderElem = remainder.map((s, id) => (
      <CommunityLeaderboardCard
        key={id + 3}
        ordinal={id + 4}
        pic={s.student.avatar}
        name={s.student.first_name + " " + s.student.last_name[0] + "."}
        points={s.monthly_points.toLocaleString()}
      />
    ));
  }

  return (
    <div className='comm-leaderboard-display-area'>
      <div className='comm-leaderboard-top-3'>
        <h1 className='comm-leaderboard-heading'>{month}'s Leading Golfers</h1>
        <p className='comm-leaderboard-desc'>
          Every action our golfers take to improve their game moves our
          community up the Op 36 Global Leaderboard. These 10 golfers are our
          top contributors for this month.
        </p>
        <div className='comm-leaderboard-top-3-card-wrapper'>{top3Elem}</div>
      </div>
      <div className='comm-leaderboard-4-to-10'>
        {remainderElem}
        {top.length > 7 ? null : callToAction}
      </div>
    </div>
  );

  // return (
  //   <div style={wrapperStyle}>
  //     <ProShopHeader pageName={props.pageName} comm={props.comm} />
  //     <div className="comm-leaderboard-display-area">
  //       <div className="comm-leaderboard-top-3">
  //         <h1 className="comm-leaderboard-heading">{month} Community Leaderboard</h1>
  //         <p className="comm-leaderboard-desc">Every action our golfers take to improve their game moves our community up the Op 36 Global Leaderboard. These 10 golfers are our top contributors for this month.</p>
  //         <div className="comm-leaderboard-top-3-card-wrapper">
  //           {top3Elem}
  //         </div>
  //       </div>
  //       <div className="comm-leaderboard-4-to-10">
  //         {remainderElem}
  //         {top.length > 7 ? null : callToAction}
  //       </div>
  //     </div>
  //   </div>
  // );
};

export default communityLeaderboard;
