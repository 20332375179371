import * as types from "../actions/actionTypes";
import update from "immutability-helper";

// state = [] : Start out with no accounts
// eslint-disable-next-line complexity
export default function activities(state = [], action) {
  switch (action.type) {
    case types.POST_COURSE_ACTIVITY:
      return [...state, Object.assign({}, action.activity)];

    case types.ADD_COURSE_ACTIVITIES:
      if (parseInt(action.pagination.page, 10) === 1) {
        return action.activities;
      } else {
        return state.concat(action.activities);
      }

    case types.POST_ACTIVITY_LIKE:
      if (!state || state.length === 0) {
        return state
      }
      const selected_index_post_like = state
        .map(function (e) {
          return e.id;
        })
        .indexOf(action.like.activity_id);
      if (selected_index_post_like === -1) {
        return state;
      }
      return update(state, {
        [selected_index_post_like]: {
          like_count: { $set: state[selected_index_post_like].like_count + 1 },
          is_liked: { $set: true },
        },
      });

    case types.DELETE_ACTIVITY_LIKE:
      if (!state || state.length === 0) {
        return state
      }
      const selected_index_delete_like = state
        .map(function (e) {
          return e.id;
        })
        .indexOf(action.activity_id);
      if (selected_index_delete_like === -1) {
        return state;
      }
      return update(state, {
        [selected_index_delete_like]: {
          like_count: {
            $set: state[selected_index_delete_like].like_count - 1,
          },
          is_liked: { $set: false },
        },
      });

    case types.DELETE_ACTIVITY:
      if (!state || state.length === 0) {
        return state
      }
      let selected_index_delete = state
        .map(function (e) {
          return e.id;
        })
        .indexOf(action.activity.id);
      let stateCopy = [...state];
      stateCopy.splice(selected_index_delete, 1);

      return stateCopy;

    case types.POST_ACTIVITY_COMMENT:
      if (!state || state.length === 0) {
        return state
      }
      var activity_index = state
        .map(function (e) {
          return e.id;
        })
        .indexOf(action.comment.activity_id);
      if (!activity_index || activity_index === -1) {
        return state;
      }
      return update(state, {
        [activity_index]: {
          comment_count: { $set: state[activity_index].comment_count + 1 },
        },
      });

    case types.GET_ACTIVITY:
      return [action.activity];

    case types.UPDATE_ACTIVITY:
      return state.map(a => {
        if (a.id === action.activity.id) {
          return Object.assign({}, action.activity)
        }
        return a
      })

    case types.UPDATE_TRAIN_ACTIVITY:
      return Object.assign({}, state, {
        activity: action.activity,
      });

    default:
      return state;
  }
}

// APPENDS ACTION.ACTIVITY ONTO THE STATE
// [...state, Object.assign({}, action.activity) ];
