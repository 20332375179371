import * as types from "../actions/actionTypes";

// state = [] : Start out with no accounts
export default function communityFlags(state = [], action) {
  switch (action.type) {
    case types.GET_COMMUNITY_FLAGS:
      return action.community_flags;

    case types.CREATE_COMMUNITY_FLAG:
      return [...state, Object.assign({}, action.community_flag)];

    default:
      return state;
  }
}
