import React from "react";
import PropTypes from 'prop-types'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import update from "immutability-helper";
import _ from "lodash";
import "../../assets/styles/components/families/FamilyPage.scss";

import ObjectiveModal from "./ObjectiveModal";
import PageHeader from "../common/page-header/PageHeader";

import * as commonActions from "../../actions/commonActions";
import * as rankActions from "../../actions/rankActions";
import * as goalActions from "../../actions/goalActions";
import * as objectiveActions from "../../actions/objectiveActions";
import * as userActions from "../../actions/userActions";

class ObjectivesPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showEditObjectiveModal: false,
      currentRankId: "-1",
      currentObjective: null
      // newImageSelected: false,
      // newVideoSelected: false,
    };
    this.addLink = this.addLink.bind(this);
    this.removeLink = this.removeLink.bind(this);
    this.onChangeLink = this.onChangeLink.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.onChangePoints = this.onChangePoints.bind(this);
    this.onChangeVideo = this.onChangeVideo.bind(this);
    this.onChangePhoto = this.onChangePhoto.bind(this);
    this.onChangeRank = this.onChangeRank.bind(this);
    this.handleSubmitObjective = this.handleSubmitObjective.bind(this);
  }

  componentWillMount() {
    const { common_actions } = this.props;
    common_actions.setCurrentTab("Objectives-admin");
    common_actions.showSideBar(true);
    common_actions.showNavBar(true);
    let rankId = this.props.ranks[0]?.id

    if (!this.props.ranks || this.props.ranks.length === 0) {
      this.props.rank_actions.getAllRanks().then(response => {
        rankId = this.props.ranks[0].id
        this.setState({ currentRankId: rankId })
        this.props.objective_actions.getRankObjectives(rankId)
      });
    } else if (!this.props.objectives[rankId] || this.props.objectives[rankId].length === 0) {
      this.setState({ currentRankId: rankId })
      this.props.objective_actions.getRankObjectives(rankId)
    } else {
      this.setState({
        currentRankId: rankId,
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.state.currentRankId === "-1" &&
      nextProps.ranks &&
      nextProps.ranks.length
    ) {
      this.setState({ currentRankId: nextProps.ranks[0].id });
    }
  }

  getCurrentRankObj = () => {
    if (this.props.ranks && this.props.ranks.length > 0) {
      return this.props.ranks[
        this.props.ranks
          .map(function(e) {
            return e.id;
          })
          .indexOf(parseInt(this.state.currentRankId, 10))
      ];
    }
  };

  openEditObjectiveModal = objective => {
    this.setState({
      showEditObjectiveModal: true,
      currentObjective: objective
    });
  };

  closeEditObjectiveModal = () => {
    this.setState({
      showEditObjectiveModal: false,
      currentObjective: null
    });
  };

  addLink() {
    let newState = update(this.state, {
      currentObjective: {
        links: { $push: [{ name: "", attachment_url: "" }] },
        newLinks: { $set: true }
      }
    });

    this.setState(newState);
  }

  removeLink(index) {
    let newState = update(this.state, {
      currentObjective: {
        links: { $splice: [[index, 1]] },
        newLinks: { $set: true }
      }
    });

    this.setState(newState);
  }

  onChangeLink(index, key, value) {
    let newState = update(this.state, {
      currentObjective: {
        links: {
          [index]: {
            [key]: { $set: value }
          }
        },
        newLinks: { $set: true }
      }
    });

    this.setState(newState);
  }

  onChangeName(event) {
    let updatedObjective = Object.assign({}, this.state.currentObjective);
    updatedObjective.name = event.target.value;
    this.setState({ currentObjective: updatedObjective });
  }

  onChangeDescription(event) {
    let updatedObjective = Object.assign({}, this.state.currentObjective);
    updatedObjective.description = event.target.value;
    this.setState({ currentObjective: updatedObjective });
  }

  onChangePoints(event) {
    let updatedObjective = Object.assign({}, this.state.currentObjective);
    updatedObjective.points = parseInt(event.target.value, 10);
    this.setState({ currentObjective: updatedObjective });
  }

  onChangeVideo(event) {
    let newState = update(this.state, {
      currentObjective: {
        video: {
          attachment_url: { $set: event.target.value }
        },
        newVideoSelected: { $set: true }
      }
    });

    this.setState(newState);
  }

  onChangePhoto(event) {
    this.setState({
      uploadPhotoName: event.target.value,
      uploadPhotoFile: event.target.files[0],
      newImageSelected: true
    });
    var reader = new window.FileReader();
    var page = this;
    reader.onload = function(e) {
      page.setState({
        uploadPhotoSrc: e.target.result
      });
    };
    reader.readAsDataURL(event.target.files[0]);
  }

  onChangeRank(event) {
    if (event.target.value === "social") {
      this.setState({ currentRankId: "social" });
      this.props.objective_actions.getCommunitySocialObjectives(1); //default to community id 1
    } else {
      this.setState({ currentRankId: event.target.value });
      this.props.objective_actions.getRankObjectives(event.target.value);
    }
  }

  renderObjectives = () => {
    const { currentRankId } = this.state
    if (this.state.currentRankId === "social") {
      if (
        this.props.social_objectives &&
        this.props.social_objectives.length > 0
      ) {
        return this.props.social_objectives.map((objective, index) =>
          this.renderObjectiveRow(objective)
        );
      }
    } else if (this.props.objectives[currentRankId] && this.props.objectives[currentRankId].length > 0) {
      return this.props.objectives[currentRankId].map((objective) =>
        this.renderObjectiveRow(objective)
      );
    }
  };

  checkObjectivePhoto = objective => {
    if (objective.subject.icon) {
      return (
        <img
          src={objective.subject.icon.file_url}
          alt={objective.name}
          style={{
            backgroundColor:
              this.state.currentRankId === "social"
                ? "#2A3742"
                : this.getCurrentRankObj().color_hex
          }}
          className='img-responsive classDetailPage-avatar center-block'
        />
      );
    } else {
      return null;
    }
  };

  renderObjectiveRow = objective => {
    return (
      <tr key={"table-row-" + objective.id} className='border-bottom'>
        <td>{this.checkObjectivePhoto(objective)}</td>
        <td>{objective.name}</td>
        <td>{objective.description}</td>
        <td>{objective.subject.name}</td>
        <td>{objective.points}</td>
        {this.props.user.type === "Admin" ? (
          <td>
            <button
              className='btn btn-dark btn-sm m-0 pull-right'
              onClick={this.openEditObjectiveModal.bind(this, objective)}
            >
              Edit
            </button>
          </td>
        ) : null}
      </tr>
    );
  };

  renderObjectivesTable = () => {
    return (
      <table className='table table-responsive w-100'>
        <thead className='primary-background'>
          <tr className='text-white font-200'>
            <th />
            <th className='font-200'>Name</th>
            <th className='font-200'>Description</th>
            <th className='font-200'>Subject</th>
            <th className='font-200'>Points</th>
            {this.props.user.type === "Admin" ? (
              <th className='font-200'>Edit</th>
            ) : null}
          </tr>
        </thead>
        <tbody>{this.renderObjectives()}</tbody>
      </table>
    );
  };

  handleSubmitObjective() {
    if (
      this.state.currentObjective.name &&
      this.state.currentObjective.description &&
      this.state.currentObjective.points
    ) {
      this.props.objective_actions.updateObjective(this.state.currentObjective);
      this.setState({
        showEditObjectiveModal: false,
        currentObjective: null
      });
    }
  }

  displayRankOptions = () => {
    if (this.props.ranks && this.props.ranks.length > 0) {
      return this.props.ranks.map((rank, index) => (
        <option value={rank.order} key={'rank-option-' + rank.order}>
          {rank.name}
        </option>
      ));
    }
  };

  displayCourseOptions() {
    if (this.props.courses && this.props.courses.length > 0) {
      return this.props.courses.map((course, index) => (
        <option value={course.id} key={"course-option-" + course.id}>
          {course.name}
        </option>
      ));
    }
  }

  displayTabs() {
    var courseSelect = (
      <select
        className='form-control select-box'
        style={{ width: "inherit", marginBottom: 0 }}
        id='classSelection'
        value={this.state.currentCourseId}
        onChange={this.onChangeCourse}
      >
        <option disabled value='-1'>
          Select Program
        </option>
        {this.displayCourseOptions()}
      </select>
    );
    return (
      <div className='row'>
        <div className='col'>
          <div className='form-group pull-right'>
            <label
              style={{
                float: "left",
                padding: "0.5rem 0.75rem",
                marginBottom: 0
              }}
              htmlFor='rankSelection'
            >
              Show me:
            </label>
            {this.props.user.type === "Coach" ? courseSelect : null}
            <select
              className='form-control select-box'
              id='rankSelection'
              value={this.state.currentRankId}
              onChange={this.onChangeRank}
              style={{ width: "inherit", marginBottom: 0 }}
            >
              {this.displayRankOptions()}
              <option value={"social"} key={"rank-option-social"}>
                Social
              </option>
            </select>
          </div>
        </div>
      </div>
    );
  }

  renderObjectiveModal = () => {
    if (this.state.currentObjective) {
      return (
        <ObjectiveModal
          objective={this.state.currentObjective}
          show={this.state.showEditObjectiveModal}
          emailSent={this.state.emailSent}
          onClose={this.closeEditObjectiveModal}
          close={this.closeEditObjectiveModal}
          addLink={this.addLink}
          removeLink={this.removeLink}
          onChangeLink={this.onChangeLink}
          onChangeName={this.onChangeName}
          onChangeDescription={this.onChangeDescription}
          onChangePoints={this.onChangePoints}
          onChangeVideo={this.onChangeVideo}
          onChangePhoto={this.onChangePhoto}
          handleSubmit={this.handleSubmitObjective}
        />
      );
    }
  };

  render() {
    return (
      <div id='ObjectivesPage'>
        <PageHeader title='Objectives' headerIcon='fa fa-flag' />
        {this.displayTabs()}
        {this.renderObjectivesTable()}
        {this.renderObjectiveModal()}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    ui: state.ui,
    user: state.user.current_user,
    ranks: state.ranks,
    goals: state.user.user_goals,
    objectives: state.objectives,
    social_objectives: state.socialObjectives
  };
}

ObjectivesPage.propTypes = {
  objectives: PropTypes.shape({
    objectives: PropTypes.arrayOf(PropTypes.shape({
      length: PropTypes.number,
    })),
  }),
  ranks: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })),
  objective_actions: PropTypes.shape({
    getRankObjectives: PropTypes.func,
  }),
}

function mapDispatchToProps(dispatch) {
  return {
    common_actions: bindActionCreators(commonActions, dispatch),
    rank_actions: bindActionCreators(rankActions, dispatch),
    goal_actions: bindActionCreators(goalActions, dispatch),
    objective_actions: bindActionCreators(objectiveActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ObjectivesPage);
