import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  completeStep,
  decrementCurrentStep,
  incrementCurrentStep,
  incrementCurrentStepBy,
  selectAcceptedMaterialsIds,
  validProgramDetailsStep,
  validProgramMaterialsStep,
} from '../../../reducers/programRegistrationReducer'
import { initializeSession, requestEnrollment } from '../../../actions/programRegistrationActions'
import { getSignUpPageProgram } from '../../../actions/programActions'

import { Col, Container, Row } from 'reactstrap'
import ConfirmationModal2 from '../../common/ConfirmationModal2'
import SubmitButton from '../../common/buttons/SubmitButton'

import './ProgramRegistrationFooter.scss'

const ProgramRegistrationFooter = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const course = useSelector(state => state.programPresentation.course)
  const {
    status,
    currentStep,
    steps,
    completedSteps,
    selectedFamilyMember,
    selectedPackage,
    selectedPaymentMethod,
  } = useSelector(state => state.programRegistration)
  const acceptedMaterialsIds = useSelector(selectAcceptedMaterialsIds)
  const validFirstStep = useSelector(validProgramDetailsStep)
  const validSecondStep = useSelector(validProgramMaterialsStep)

  const [showCancelConfirmationModal, setShowCancelConfirmationModal] = useState(false)

  const isLoading = status === 'processing registration'
  const isValidStep = useMemo(() => {
    switch (currentStep) {
      case 0:
        return validFirstStep
      case 1:
        if (steps[1] === 'Program Materials') {
          return validSecondStep
        } else {
          return true
        }
      default:
        return true
    }
  }, [currentStep, steps, validFirstStep, validSecondStep])

  const handleGoBack = () => {
    if (currentStep === 0) {
      setShowCancelConfirmationModal(true)
    } else {
      dispatch(decrementCurrentStep())
    }
  }

  const goToCheckout = () => {
    selectedPaymentMethod === 'cash'
      ? dispatch(requestEnrollment({
        courseId: course.id,
        userId: selectedFamilyMember?.id,
        packageId: selectedPackage.id,
        packagePrice: selectedPackage.price,
        materialsIds: acceptedMaterialsIds,
      })).unwrap()
        .then(data => {
          navigate(`/landing/${course.community.id}/class/${course.id}/registration/${data.enrollment.registration.id}`)
        })
      : dispatch(initializeSession({
        courseId: course.id,
        userId: selectedFamilyMember?.id,
        packageId: selectedPackage.id,
        packagePrice: selectedPackage.price,
        materialsIds: acceptedMaterialsIds,
      })).unwrap()
        .then(data => {
          window.location.replace(data.sessionUrl)
        })
  }

  const handleGoNext = () => {
    if (!completedSteps.includes(currentStep)) {
      dispatch(completeStep(currentStep))
    }
    if (steps[currentStep + 1] === 'Checkout') {
      dispatch(incrementCurrentStepBy(2))
      dispatch(completeStep(currentStep + 1))
      goToCheckout()
    } else {
      dispatch(incrementCurrentStep())
    }
  }

  const navigateToClassPage = () => {
    dispatch(getSignUpPageProgram(course.id))
    navigate(`/landing/${course.community.id}/class/${course.id}`)
  }

  return (
    <Container id='ProgramRegistrationFooter' fluid>
      <Row className='h-100 flex-column-reverse flex-sm-row justify-content-center align-items-center'>
        <Col sm={6} className='go-back'>
          <SubmitButton
            noTopMargin
            onClick={handleGoBack}
            disabled={isLoading}
            buttonMessage='Go back'
            buttonColor='navy-button'
          />
        </Col>
        <Col sm={6} className='submit-register-button'>
          <SubmitButton
            noTopMargin
            disabled={!isValidStep || isLoading}
            onClick={handleGoNext}
            buttonMessage='Next Step'
          />
        </Col>

        <ConfirmationModal2
          showModal={showCancelConfirmationModal}
          closeModal={() => setShowCancelConfirmationModal(false)}
          onDeny={() => setShowCancelConfirmationModal(false)}
          denyMessage='No'
          onProceed={navigateToClassPage}
          proceedMessage='Yes'
          icon={<i className='fa fa-question-circle' style={{ fontSize: '72px' }} />}
          title='Cancel registration?'
          message='All progress will be lost. Are you sure you want to cancel the registration?'
        />
      </Row>
    </Container>
  )
}

ProgramRegistrationFooter.propTypes = {
  fetchCourse: PropTypes.func,
}

export default ProgramRegistrationFooter
