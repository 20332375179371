import * as constants from "./actionTypes";
import { serialize } from "../utility/serialization";

import Axios from '../axios'

export function successTogglingCommunityFeature(response) {
  return {
    type: constants.TOGGLE_COMMUNITY_FEATURE,
    features: response.data.features,
  }
}

// THUNKS HERE

export function toggleCommunityFeature(communityId, feature) {
  return function(dispatch) {
    return (feature.enabled
      ? requestDisableFeature(communityId, feature.key)
      : requestEnableFeature(communityId, feature.key))
        .then((response) => dispatch(successTogglingCommunityFeature(response)));
  }
}

// API CALLS

function requestEnableFeature(communityId, featureKey) {
  let requestUrl = '/api/v1/features/enable';
  let featureData = {
    communityId: communityId,
    featureKey: featureKey
  }

  return Axios.post(requestUrl, serialize(featureData));
}

function requestDisableFeature(communityId, featureKey) {
  let requestUrl = '/api/v1/features/disable';
  let featureData = {
    communityId: communityId,
    featureKey: featureKey
  }

  return Axios.post(requestUrl, serialize(featureData));
}