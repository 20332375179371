import React from "react";
import { Link } from "react-router-dom";
import "./FamilyProgramCard.scss";

const familyEnrollmentsCount = (course, family) => {
  let familyCount = 0;
  let enrollments = [];
  course.enrollments.map(enrollment => {
    enrollments.push(enrollment.user_id);
  });

  family.users.map(user => {
    let userIndex = enrollments.indexOf(user.id);

    if (userIndex > -1) {
      familyCount++;
    }
  });

  return familyCount;
};

const FamilyProgramCard = ({ course, family }) => (

  <Link
    id="FamilyProgramCard"
    to={`/landing/${course.community_id}/class/${course.id}`}
    className="row p-2 card animation-shrink"
  >
    <div className="program-info-wrapper">
      <h4 className="program-name">{course.name}</h4>
      <strong>
        <h6 className="program-meeting-times">
          {course.group && course.group.name}
        </h6>
      </strong>
      <h6 className="program-meeting-times">{course.meeting_times}</h6>
      {course.enrollments &&
        course.enrollments.length > 0 &&
        familyEnrollmentsCount(course, family) > 0 && (
          <span className="program-pill">
            {familyEnrollmentsCount(course, family)} golfers enrolled
          </span>
        )}
    </div>
  </Link>
);

export default FamilyProgramCard;
