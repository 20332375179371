// This is based off of LessonPlanCardPopover.js
import React, { Component } from "react";

import "./DrillActivityCardPopover.scss";

class DrillActivityCardPopover extends Component {
  componentWillMount() {
    document.addEventListener("click", this._handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this._handleClick, false);
  }

  render() {
    // const { is_draft, duplicate, id, isMine } = this.props;

    const { type, edit, handleDelete, drill_id } = this.props;

    return (
      <div
        className='drill-activity-card-hover-overlay-menu'
        ref={node => (this.node = node)}
        onClick={this._anotherClick}
      >
        <p onClick={() => edit(drill_id)}>Edit {type}</p>
        <p
          onClick={() => handleDelete({ type: type, id: drill_id })}
          className='delete-drill-activity-btn'
        >
          Delete {type}
        </p>
      </div>
    );
  }

  // _handleEditClick = e => {
  //   const { edit, isMine } = this.props;
  //   if (isMine) {
  //     edit(e);
  //   }
  // };
  //
  // _handleDeleteClick = id => {
  //   const { deleteLP, isMine } = this.props;
  //   if (isMine) {
  //     deleteLP(id);
  //   }
  // };

  _handleClick = e => {
    // just tracks if the user clicks outside the component to close the popover
    if (!this.node.contains(e.target)) {
      this.props.close();
    }
  };

  _anotherClick = e => {
    // stops from click event on whitespace of component selecting this session to display on the page
    e.stopPropagation();
  };
}

export default DrillActivityCardPopover;
