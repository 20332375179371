import PropTypes from 'prop-types'
import classnames from 'classnames'
import { displayWithCurrency } from '../../../../utility/currency'

import styles from './PackageCard.module.scss'

const PackageCard = ({ pkg, currency, noBorder, className }) => (
  <article
    className={classnames(
      'd-flex flex-column',
      styles['package-card'],
      { [className]: !!className, [styles['no-border']]: noBorder }
    )}
  >
    <h5 className={classnames('mb-0', styles.name)}>{pkg.name}</h5>
    <h5 className={classnames('mt-2 mb-0', styles.price)}>{displayWithCurrency(pkg.price, currency)}</h5>
    <p className={classnames('mt-2 mb-0', styles.description)}>{pkg.description}</p>
  </article>
)

PackageCard.propTypes = {
  pkg: PropTypes.shape({
    name: PropTypes.string,
    price: PropTypes.number,
    description: PropTypes.string,
  }),
  currency: PropTypes.object,
  noBorder: PropTypes.bool,
  className: PropTypes.string,
}

export default PackageCard
