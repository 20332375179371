import { useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import classNames from 'classnames'

import classes from './PasswordChangeForm.module.scss'
import Input from '../../common/form/Input'
import PasswordInputWithIcon from '../../common/form/PasswordInputWithIcon'
import { triggerResetPassword } from '../../../actions/authActions'
import { flatServerFormErrors } from '../../../utility/helperFunctions'
import { AccountContext } from '../AccountContext'


const PasswordChangeForm = () => {
  const dispatch = useDispatch()
  const { user, isProfile, submitDisabled, updateAction } = useContext(AccountContext) // Note: user could be golfer OR current_user
  const currentUser = useSelector(state => state.user.current_user)

  const [isUpdating, setIsUpdating] = useState(false)
  const [resetEmailSent, setResetEmailSent] = useState(false)
  const [hasChanges, setHasChanges] = useState(false)
  const [errors, setErrors] = useState({})

  const isCurrentUser = user.id === currentUser.id
  const isCoach = currentUser.type === 'Coach'
  const isFamilyAdmin = currentUser.type === 'Student' && currentUser.family_admin

  // Input enabled for: currentUser or familyAdmin
  const disallowCoachToEdit = !isCurrentUser && (isCoach || !isFamilyAdmin)
  const isCurrentCoachEditingAnotherUser =
    currentUser.type === 'Coach' &&
    isProfile &&
    !isFamilyAdmin &&
    !isCurrentUser

  const validateForm = formData => {
    setErrors({})
    const formErrors = {}

    const currentPassword = formData.get('currentPassword')
    const newPassword = formData.get('newPassword')
    const confirmPassword = formData.get('confirmPassword')

    if (!currentPassword) {
      formErrors['current_password'] = 'This field cannot be empty.'
    }

    if (!newPassword) {
      formErrors['new_password'] = 'This field cannot be empty.'
    }

    if (newPassword !== confirmPassword) {
      formErrors['confirm_password'] = 'Confirm Password does not match with New Password.'
    }

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors)
      return false
    }

    return true
  }

  const handleSubmitForm = async e => {
    e.preventDefault()
    const formElement = e.target
    const formData = new FormData(formElement)

    if (validateForm(formData)) {
      const partialUser = {
        id: user.id,
        email: user?.email,
        username: user?.username,
        current_password: formData.get('currentPassword'),
        password: formData.get('newPassword'),
        password_confirmation: formData.get('confirmPassword'),
      }

      try {
        setIsUpdating(true)
        const newState = await dispatch(updateAction(partialUser))

        if (newState.type === 'UI_ERROR') {
          setErrors(flatServerFormErrors(newState?.error))
        } else {
          toast.success('User has been successfully updated!')
          formElement.reset()
        }
      } catch {
        toast.error('Could not update user. Please check again later.')
      } finally {
        setIsUpdating(false)
        setHasChanges(false)
      }
    }
  }

  const handleSendResetPasswordEmail = async () => {
    const userIdentifier = user.email || user.username

    try {
      await dispatch(triggerResetPassword(userIdentifier))
      setResetEmailSent(true)
      toast.success('Reset Password email sent')
    } catch (e) {
      toast.error('Could not send Reset Password email.')
      console.error(e)
    }
  }

  const handleDetectChanges = (e) => {
    const formData = new FormData(e.currentTarget)
    const currentPassword = formData.get('currentPassword')
    const newPassword = formData.get('newPassword')
    const confirmPassword = formData.get('confirmPassword')

    const hasChanges_ =
      currentPassword || newPassword || confirmPassword

    if (hasChanges_ !== hasChanges) {
      setHasChanges(hasChanges_)
    }
  }


  return (
    <form onSubmit={handleSubmitForm} onInput={handleDetectChanges} className={classNames(classes.container, 'animated fadeIn')}>
      <div className='row mb-3'>
        <h4 className={classes.title}>Change Password</h4>
      </div>

      {isCurrentCoachEditingAnotherUser && (
        <div className='row mb-3'>
          <button
            type='button'
            onClick={handleSendResetPasswordEmail}
            className='btn btn-sm btn-dark'
            disabled={resetEmailSent}
          >
            Send Password Reset Email
          </button>
        </div>
      )}

      {!isCurrentCoachEditingAnotherUser && (
        <div>
          <div className='row mb-3'>
            <label htmlFor='currentPassword'>Current Password</label>
            <PasswordInputWithIcon
              id='currentPassword'
              name='currentPassword'
              placeholder='Enter Current Password'
              classNames={{ inputContainer: classes.accountInputContainer }}
              disabled={disallowCoachToEdit}
              error={errors?.current_password}
            />
          </div>
          <div className='row mb-3'>
            <label htmlFor='newPassword'>New Password</label>
            <PasswordInputWithIcon
              type='password'
              id='newPassword'
              name='newPassword'
              placeholder='Enter New Password'
              classNames={{ inputContainer: classes.accountInputContainer }}
              disabled={disallowCoachToEdit}
              error={errors?.new_password}
            />
          </div>
          <div className={classNames(classes.extraMargin, 'row')}>
            <label htmlFor='confirmPassword'>Confirm Password</label>
            <Input
              type='password'
              id='confirmPassword'
              name='confirmPassword'
              placeholder='Enter Confirm Password'
              classNames={{ inputContainer: classes.accountInputContainer }}
              disabled={disallowCoachToEdit}
              error={errors?.confirm_password}
            />
          </div>
          <div className='row'>
            <button
              type='submit'
              className='btn btn-primary'
              disabled={isUpdating || submitDisabled || !hasChanges}
            >
              Sav{isUpdating ? 'ing' : 'e'} Changes
            </button>
          </div>
        </div>
      )}
    </form>
  )
}

export default PasswordChangeForm
