import React from 'react';

import classNames from 'classnames'
import './MCElement.scss';

/**
  Renders a checkbox or radio button in a selected or unselected form.
*/
const mcElement = ({ id, type, isSelected, primaryColor, secondaryColor, clicked, onKeyDown, disabled }) => {
  // const { type, isSelected, primaryColor, secondaryColor } = props;

  const fillShape = type === 'checkbox'
    ? (
        <i
          className='fa fa-check'
          aria-hidden='true'
          style={secondaryColor && { color: secondaryColor }} />
    ) : (
      <div
        className='radio-button-fill'
        style={primaryColor && { backgroundColor: primaryColor }} />
    );

  const selectedClass = isSelected ? 'selected'.concat('-', type) : '';

  // Colors of span container
  // Only override colors if they have been defined in props
  const borderColor = primaryColor && primaryColor;
  let bgColor = secondaryColor && secondaryColor;
  if (type === 'checkbox' && isSelected && primaryColor) {
    bgColor = primaryColor;
  }

  // Span is the circle/box; fillShape is the radio button fill/checkmark icon
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <span
      id={id}
      className={classNames('mcElement', type, selectedClass, { 'disabled': disabled })}
      tabIndex={disabled ? -1 : 0} // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
      onClick={clicked}
      onKeyDown={onKeyDown}
      style={{
        backgroundColor: bgColor ? bgColor : 'default',
        borderColor: borderColor ? borderColor : 'default'
      }}>
      {isSelected && !disabled && fillShape}
    </span>
  );
};

export default mcElement;
