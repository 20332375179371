import PropTypes from 'prop-types'

// ASSETS
import './CloseButton.scss'

const CloseButton = ({ onClick }) => {
  return (
    <div id='CloseButton'>
      <button onClick={onClick}>
        <i className='fa fa-times' />
      </button>
    </div>
  )
}
CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default CloseButton
