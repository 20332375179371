import React, { Component } from "react";
import PropTypes from 'prop-types'
import { Button, ButtonGroup, Collapse } from "reactstrap";

// REDUX BITS
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as objectiveActions from "../../../actions/objectiveActions";

import GoalRow from "./GoalRow";
import Placeholder from "../../common/Placeholder";
import Op36Goal from "../../../assets/images/logos/op36-no-goal.png";
import "./Goals.scss";

class GoalList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      objectives: [],
      loader: false,
      selectedGoalType: "current",
    };
  }

  componentDidMount() {
    this.props.ranks.map((rank) => {
      if (rank.name === this.props.currentRank?.name) {
        this.props.getObjectivesFromRank(rank.id, this.props.user.id);
      }
    });
  }

  goalTypeSwitcher = (type) => {
    this.setState({
      selectedGoalType: type,
    });
  };

  goalSwitcher = () => {
    return (
      <ButtonGroup className='goal-switch-button-wrapper'>
        <Button
          className={`goal-switch-button ${
            this.state.selectedGoalType === "current"
              ? "active-goal-button"
              : ""
          }`}
          onClick={() => this.goalTypeSwitcher("current")}
        >
          Open
        </Button>
        <Button
          className={`goal-switch-button ${
            this.state.selectedGoalType === "complete"
              ? "active-goal-button"
              : ""
          }`}
          onClick={() => this.goalTypeSwitcher("complete")}
        >
          Completed
        </Button>
      </ButtonGroup>
    );
  };

  calculateCurrentObjectives = () => {
    let current = [];
    this.props.objectives.map((objective) => {
      if (!objective.completed_date) {
        current.push(objective);
      }
    });

    return current.length;
  };

  calculateCompleteObjectives = () => {
    let completed = [];
    this.props.objectives.map((objective) => {
      if (objective.completed_date) {
        completed.push(objective);
      }
    });

    return completed.length;
  };

  displayPrivateGoals = (type) => {
    return (
      <GoalRow
        user={this.props.user}
        goals={this.props.goals}
        isMobile={this.props.isMobile}
        customGoalPage={this.props.customGoalPage}
        isPrivateGoals={true}
        selectedGoalType={this.state.selectedGoalType}
        goalTypeSwitcher={this.goalTypeSwitcher}
        type={type}
      />
    );
  };

  displayGoals = (type) => { // eslint-disable-line arrow-body-style
    return this.props.ranks.map((rank) => {
      if (type === "complete" && rank.progress_percentage > 0) {
        return (
          <GoalRow
            user={this.props.user}
            current_user={this.props.current_user}
            rank={rank}
            key={rank.id}
            type={type}
            currentRank={this.props.currentRank}
            rowLoader={this.props.rowLoader}
            gotInitialGoals={this.props.gotInitialGoals}
            toggleRowLoader={this.props.toggleRowLoader}
            toggleIncompleteModal={this.props.toggleIncompleteModal}
            toggleDropdown={this.props.toggleDropdown}
            collapse={this.props.collapse}
            getObjectivesFromRankRow={this.props.getObjectivesFromRankRow}
            getCurrentObjective={this.props.getCurrentObjective}
            objectiveActivities={this.props.objectiveActivities}
            isPrivateGoals={false}
          />
        );
      } else if (type === "current") {
        return (
          <GoalRow
            user={this.props.user}
            current_user={this.props.current_user}
            rank={rank}
            key={rank.id}
            type={type}
            currentRank={this.props.currentRank}
            rowLoader={this.props.rowLoader}
            rankUp={this.props.rankUp}
            gotInitialGoals={this.props.gotInitialGoals}
            toggleRowLoader={this.props.toggleRowLoader}
            toggleCompleteModal={this.props.toggleCompleteModal}
            toggleDropdown={this.props.toggleDropdown}
            collapse={this.props.collapse}
            getObjectivesFromRankRow={this.props.getObjectivesFromRankRow}
            getCurrentObjective={this.props.getCurrentObjective}
            objectiveActivities={this.props.objectiveActivities}
            isPrivateGoals={false}
          />
        );
      }
    });
  };

  render() {
    return (
      <div className='container private-goal-mobile'>
        {this.props.isPrivateGoals ? (
          this.props.gotGoals &&
          this.props.goals &&
          this.props.goals.length > 0 ? (
            this.state.selectedGoalType === "current" ? (
              <div className='mb-5'>{this.displayPrivateGoals("current")}</div>
            ) : (
              <div className='mb-5'>{this.displayPrivateGoals("complete")}</div>
            )
          ) : (
            <Placeholder
              title={"No Private Goals Yet..."}
              icon={"fa-trophy"}
              subtext1={"There have currently been no private goals created"}
              subtext2={
                this.props.current_user.type === "Coach"
                  ? "Click the Add Private Goal button to open the actions modal"
                  : "Ask or wait for your Coach to give you a private goal"
              }
              buttonText={
                this.props.current_user.type === "Coach"
                  ? "Add Private Goal"
                  : null
              }
              buttonIcon={"fa-plus"}
              handleClick={() => this.props.toggleModal("goals")}
            />
          )
        ) : (
          <div className='custom-goal-container'>
            <div className='custom-goal-header curriculum-header'>
              <button
                className='mb-3 back-curriculms-btn'
                onClick={this.props.decrementPage}
              >
                <i
                  className='fa fa-arrow-left back-curriculms-icon'
                  aria-hidden='true'
                />
              </button>
              <div className='custom-goal-header-info-wrapper'>
                <div className='custom-goal-header-wrapper'>
                  <div className='custom-goal-header-title-wrapper'>
                    <h4 className='custom-goal-header-title op36-curriculum-title'>
                      Operation 36 Curriculum
                    </h4>
                  </div>
                  <div className='row current-rank-row'>
                    <p className='current-rank'>{this.props.currentRank.name}</p>
                  </div>
                  <div className='row custom-goal-text-row'>
                    <div className='col-md-6 custom-goal-text-wrapper pr-0'>
                      <p className='custom-goal-text'>Open:</p>{" "}
                      <span className='custom-goal-text-aside'>
                        {this.props.loader
                          ? "loading"
                          : this.calculateCurrentObjectives()}
                      </span>
                    </div>
                    <div className='col-md-6 custom-goal-text-wrapper pr-0'>
                      <p className='custom-goal-text'>Completed:</p>{" "}
                      <span className='custom-goal-text-aside an'>
                        {this.props.loader
                          ? "loading"
                          : this.calculateCompleteObjectives()}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='custom-goal-header-info'>
                  <div className='progress'>
                    <div
                      className='progress-bar'
                      role='progressbar'
                      style={{
                        width: `${
                          !this.props.percentage ? "0" : this.props.percentage
                        }%`,
                        backgroundColor: '#ff6633',
                      }}
                      aria-valuenow={
                        !this.props.percentage ? 0 : this.props.percentage
                      }
                      aria-valuemin='0'
                      aria-valuemax='100'
                    >
                      {!this.props.percentage
                        ? ""
                        : `${this.props.percentage}%`}
                    </div>
                  </div>
                  <div className='private-goal-toggle custom-goal-switcher'>
                    {this.goalSwitcher()}
                  </div>
                </div>
              </div>
            </div>
            {this.state.selectedGoalType === "current" ? (
              <div className='mt-5'>{this.displayGoals("current")}</div>
            ) : (
              <div className='mt-5'>{this.displayGoals("complete")}</div>
            )}
          </div>
        )}
      </div>
    );
  }
}

GoalList.propTypes = {
  currentRank: PropTypes.shape({
    name: PropTypes.string,
  }),
  ranks: PropTypes.array,
}

function mapStateToProps(state, ownProps) {
  return {
    objectives: state.objectives[ownProps.currentRank?.order],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(objectiveActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GoalList);
