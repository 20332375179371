import React from "react";

const NumericScoringCard = (props) => {
  return (
    <div className='d-flex align-items-center'>
      <div
        style={{
          backgroundColor: "#34ACE0",
          height: 25,
          width: 92,
          borderRadius: 4,
          marginRight: 9,
          color: "#fff",
        }}
        className='d-flex align-items-center justify-content-center'
      >
        <p className='mb-0'>123</p>
      </div>
      <div>
        <p className='mb-0' style={{ lineHeight: 1.2 }}>
          Numerical Scoring
        </p>
        <p className='mb-0' style={{ lineHeight: 1.2 }}>
          (E.g. Putts in a row)
        </p>
      </div>
    </div>
  );
};

export default NumericScoringCard;
