import { deserialize, serialize } from '../utility/serialization'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { handleAPIError } from '../utility/actionHelper'

import Axios from '../axios'

// THUNKS HERE

export const getCommunitySettingsPackages = createAsyncThunk(
  'packages/getCommunitySettingsPackages',
  async ({ archived, page, perPage, mode }, { rejectWithValue }) => {
    try {
      const { data } = await requestGetPackages(archived, page, perPage, mode)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const getCommunityPackages = createAsyncThunk(
  'packages/getCommunityPackages',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await requestGetPackages()
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const createPackage = createAsyncThunk(
  'packages/create',
  async (_package, { rejectWithValue }) => {
    try {
      const { data } = await requestCreatePackage(_package)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const updatePackage = createAsyncThunk(
  'packages/update',
  async ({ _package, changeArchived = false }, { rejectWithValue }) => {
    try {
      const { data } = await requestUpdatePackage(_package, changeArchived)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)


export const deletePackage = createAsyncThunk(
  'packages/delete',
  async (_package, { rejectWithValue }) => {
    try {
      const { data } = await requestDeletePackage(_package)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

// API CALLS

function requestGetPackages(archived = false, page = null, perPage = null, mode = null) {
  const requestUrl = '/api/v1/packages'
  const params = { archived, mode, page, perPage }

  return Axios.get(requestUrl, { params: serialize(params) })
}

function requestCreatePackage(_package) {
  const requestUrl = '/api/v1/packages'
  const packageData = {
    name: _package.name,
    price: _package.price,
    description: _package.description,
  }

  return Axios.post(requestUrl, packageData)
}

function requestUpdatePackage(_package, changeArchived) {
  const requestUrl = `/api/v1/packages/${_package.id}`
  const packageData = {
    name: _package.name,
    price: _package.price,
    description: _package.description,
    archived: changeArchived ? !_package.archived : _package.archived,
  }

  return Axios.put(requestUrl, packageData)
}

function requestDeletePackage(_package) {
  const requestUrl = `/api/v1/packages/${_package.id}`

  return Axios.delete(requestUrl)
}
