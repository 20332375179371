import React from 'react';
import { Link } from 'react-router-dom';

import TextInput from '../common/form/TextInput';
import TextAreaInput from '../common/form/TextAreaInput';
import MyStatefulEditor from '../common/form/MyStatefulEditor';

import _ from 'lodash';
import Select from 'react-select';
// import 'react-select/dist/react-select.css';
import '../../assets/styles/components/activity/actions/AnnoucementFormMobile.scss'
import Toggle from '../common/Toggle'

const selectInputFile = () => {
  document.getElementById('input-photo-upload').click()
}


const displayImageInput = (selectInputFile, inputFileSrc) => {
  if (inputFileSrc) {
    return (
      <Link className="imageSelect" onClick={selectInputFile}> <div className="center-cropped" style={{backgroundImage: 'url(' + inputFileSrc + ')'}}/> </Link>
    );
  } else {
    return (
      <Link className="imageSelect" onClick={selectInputFile}> <i className="fa fa-camera" aria-hidden="true"></i> </Link>
    );
  }

}

export default class AnnouncementForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    const isSafari = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;

    this.state = {
      courses: [],
      uploadPhotoName: '',
      uploadPhotoFile: '',
      uploadPhotoSrc: '',
      description: '',
      sendEmail: false,
      subject: '',
      message: '',
      content: '',
      isSafari: isSafari,
      isWeb: this.props.web ? this.props.web : false
    };

    this.onSelectAll = this.onSelectAll.bind(this);
    this.onEmailToggle = this.onEmailToggle.bind(this);
    this.onSelectOption = this.onSelectOption.bind(this);
    this.onChangeSubject = this.onChangeSubject.bind(this);
    this.onChangeMessage = this.onChangeMessage.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.renderEditor = this.renderEditor.bind(this);
    this.handlePhotoInputChange = this.handlePhotoInputChange.bind(this);
  }

  componentWillMount() {
    if (this.props.sendEmail) this.setState({sendEmail: true});
  }

  onSelectOption(optionsSelected) {
    this.setState({courses: optionsSelected});
  }

  onSelectAll() {
    this.setState({courses: this.props.courseOptions});
  }

  onEmailToggle(event) {
    this.setState({sendEmail: !this.state.sendEmail});
  }

  onChangeSubject(event) {
    this.setState({subject: event.target.value});
  }

  onChangeMessage(event) {
    this.setState({message: event.target.value});
  }

  handleStatefulEditorChange = htmlString => {
    this.setState({
      message: htmlString,
    });
  };

  handlePhotoInputChange(changeEvent){
    this.setState({
      uploadPhotoName: changeEvent.target.value,
      uploadPhotoFile: changeEvent.target.files[0]
    });
    var reader = new window.FileReader();
    var page = this
    reader.onload = function (e) {
      page.setState({
        uploadPhotoSrc: e.target.result
      });
    }
    reader.readAsDataURL(changeEvent.target.files[0]);
  }

  submitForm(event) {
    event.preventDefault();
    this.props.handleSubmit(this.state);
  }

  renderEditor(){
    if(this.state.isSafari){
      return (<TextAreaInput
              name="Message"
              type='text'
              rows="3"
              label=""
              onChange={this.onChangeMessage}
              placeholder="Enter your message"
              value={this.state.message}/>)
    }
    else{
      return (<MyStatefulEditor
                  name='content'
                  content={this.state.message}
                  onChange={this.handleStatefulEditorChange} />)
    }
  }

  render() {
    return (
      <form id="announcement-form" className="form-container announcement-form-mobile">
        { !this.state.isWeb ?
          <div className="col-12">
            <div id="basicFormUploadImageThumb" className="text-center mx-auto">
              {displayImageInput(selectInputFile, this.state.uploadPhotoSrc)}
              <div className="col hide">
                <TextInput
                  name={''}
                  id={'input-photo-upload'}
                  type={'file'}
                  accept={"image/*"}
                  className={'form-control-file photoInput'}
                  onChange={this.handlePhotoInputChange}
                  placeholder={'Add a Different Photo'}
                  value={this.state.uploadPhotoName}
                  error={''} />
              </div>
            </div>
          </div> : null }
        <label className='first-label'>To</label>
        <span id='select-all-courses'
          onClick={this.onSelectAll}
          >Select All</span>
        <Select
          multi
          name="form-field-name"
          value={this.state.courses}
          options={this.props.courseOptions}
          placeholder="Select Courses"
          onChange={this.onSelectOption}
          className="course-select-input"/>

        <label>Subject</label>
        <TextInput
          name="Subject"
          type='text'
          label=""
          onChange={this.onChangeSubject}
          placeholder="Subject"
          value={this.state.subject}/>

        <label>Message</label>
        {!this.state.isWeb
          ?
          <TextAreaInput
              name="Message"
              type='text'
              rows="3"
              label=""
              onChange={this.onChangeMessage}
              placeholder="Message"
              value={this.state.message}/>
          :
          this.renderEditor()
        }

        <label id='email-toggle-label' htmlFor='email-toggle'>Also send as email</label>
        <Toggle
          id='email-toggle'
          className='orange-bg'
          defaultChecked={this.state.sendEmail}
          icons={false}
          onChange={this.onEmailToggle} />
        <button
          id="submit-btn"
          type="submit"
          className="btn btn-block"
          onClick={this.submitForm}
          disabled={this.props.loader}
        >{this.props.loader ? 'Sending Email...' : 'Send Email'}</button>
      </form>
    );
  }
};
