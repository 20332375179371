import React from "react";
import "./SimpleResourceCard.scss";

const navigateTo = url => {
  let win = window.open(url, "_blank");
  win.focus();
};

const SimpleResourceCard = ({ resource }) => (
  <div id="SimpleResourceCard" onClick={() => navigateTo(resource.button_url)}>
    <div className="resource-image-wrapper">
      {resource.photo ? (
        <img src={resource.photo.file_url} alt={resource.title} />
      ) : (
        <i className="fa fa-clipboard" />
      )}
    </div>
    <p>{resource.title}</p>
  </div>
);

export default SimpleResourceCard;
