import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import styles from './ButtonLink.module.scss'

const ButtonLink = ({ navigateTo, isExternal, disabled, children, onClick, linkColor, noTopMargin, className, ...props }) => {
  const classes = classNames(
    'w-100',
    noTopMargin ? 'mt-0' : 'mt-3',
    styles['button-link'],
    linkColor ? styles[linkColor] : styles['orange'],
    className,
    { 'disabled': disabled }
  )

  return (
    <>
      {isExternal ? (
        <a href={navigateTo} onClick={onClick} className={classes} {...props}>
          {children}
        </a>
      ) : (
        <Link to={navigateTo} onClick={onClick} className={classes} {...props}>
          {children}
        </Link>
      )}
    </>
  )
}

export const buttonLinkType = {
  navigateTo: PropTypes.string.isRequired,
  isExternal: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  linkColor: PropTypes.string,
  noTopMargin: PropTypes.bool,
  className: PropTypes.string,
}

ButtonLink.propTypes = {
  ...buttonLinkType,
  children: PropTypes.node,
}

export default ButtonLink
