import React from 'react';
import TextInput from '../common/form/TextInput';
import './LinksTable.scss';

export default class LinksTable extends React.Component {
  onChangeLink = (e, link, key) => {
    e.preventDefault();
    var index = this.props.links.indexOf(link);
    this.props.onChangeLink(index, key, e.target.value);
  }

  removeLink = (e, link) => {
    e.preventDefault();
    var index = this.props.links.indexOf(link);
    this.props.removeLink(index);
  }

  addLink = event => {
    event.preventDefault();
    this.props.addLink();
  }

  render() {
    return (
      <div className="links-table">
        {
          this.props.links && this.props.links.length ?
          <div className="links-labels-row">
            <label className="col-4">Button Text</label>
            <label className="col-6">Button Link</label>
          </div> :
          null
        }
        {
          this.props.links ?
          this.props.links.map((link, i) => (
            <div key={i} className="row link-row">

              <div className="label-input-container col-4">
                <TextInput
                  name='label'
                  type='text'
                  label='Label'
                  placeholder='Button Text'
                  onChange={(event) => this.onChangeLink(event, link, 'name')}
                  value={link.name} />
              </div>

              <div className="url-input-container col-6">
                <TextInput
                  name='url'
                  type='text'
                  label='URL Address'
                  placeholder='http://www.google.com'
                  onChange={(event) => this.onChangeLink(event, link, 'attachment_url')}
                  value={link.attachment_url} />
              </div>

              <div className="col-2">
                <button
                  className="btn btn-dark pull-right remove-link-btn"
                  onClick={(event) => this.removeLink(event, link)}
                >✕</button>
              </div>
            </div>
          )) :
          null
        }
        <button className="btn btn-dark add-link-btn" onClick={this.addLink}>Add Link</button>
      </div>
    );
  }
}