import axios from 'axios'

export async function getTimezoneId(latLng) {
  // https://developers.google.com/maps/documentation/timezone/overview
  let apiUrl = 'https://maps.googleapis.com/maps/api/timezone/json?timestamp=1698155975' // Timestamp is not important in our case
  apiUrl += `&location=${latLng.lat}%2C${latLng.lng}`
  apiUrl += `&key=${process.env.REACT_APP_GOOGLE_API_KEY}`

  return axios
    .get(apiUrl)
    .then((res) => res.data.timeZoneId)
}
