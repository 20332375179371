import React from 'react';

import './LessonPlanActionModal.scss';

const LessonPlanActionModal = ({ title, subtitle, close, links }) => {
  const width = links.length * 150 + 55;
  return (
    <div id='LessonPlanActionModal'>
      <div className='grey-out' onClick={close} />
      <div className='lesson-plan-action-modal-content' style={{ width }}>
        <div className='d-flex justify-content-end'>
          <i className='fa fa-times' aria-hidden='true' onClick={close} />
        </div>
        <p className='lesson-plan-action-modal-title'>{title}</p>
        <p className='lesson-plan-action-modal-subtitle'>{subtitle}</p>
        <div className='lesson-plan-action-modal-links-container'>
          {links.map((link, index) => {
            return (
              <div
                className='lesson-plan-action-modal-link-button'
                key={index}
                onClick={link.onClick}
              >
                {link.fa_icon ? (
                  <i className={`fa ${link.fa_icon}`} aria-hidden='true' />
                ) : (
                  <div
                    className='lesson-plan-action-modal-link-icon'
                    style={{ backgroundImage: `url(${link.icon})` }}
                  />
                )}
                <p className='lesson-plan-action-modal-link-label'>
                  {link.name}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LessonPlanActionModal;

/*
  the links props should be an array of objects structured like this:

  links=[
    {
      onClick: <<function you want to exicute>>
      icon: <<icon>>,                                             ===> optional if you want to use font awesome instead
      name: <<label for the link>>,
      fa_icon: <<if you want to use font awesome icons>>          ===> optional (will override other icon if included)
    }, {
      ...
    }
  ]
*/
