import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getFamily, getFamilyEnrollments, getFamilyStats } from '../../../actions/familyActions'
import { getFamilyActivities } from '../../../actions/activityActions'
import { setCurrentTab } from '../../../actions/commonActions'

import ActivityLoader from 'react-loaders'
import StockProfilePic from '../../../assets/images/mock/StockProfilePic.png'
import classes from './StudentDashboardPage.module.scss'
import StudentCard from '../../common/StudentCard'
import Loader from '../../common/Op36Loader-web'
import classNames from 'classnames'
import ReactList from 'react-list'
import Activity from '../../activity/Activity'
import moment from 'moment'
import { abbrNum } from '../../../utility/helperFunctions'
import Placeholder from '../../common/Placeholder'
import FamilyProgramCard from '../FamilyProgramCard'
import { getCommunityCourses } from '../../../actions/courseActions'
import EnrollmentCard from '../EnrollmentCard'
import PageHeader from '../../common/PageHeader/PageHeader'
import ReactPlayer from 'react-player'
import { getUserActivities } from '../../../actions/userActions'

const ACTIVITIES_PER_PAGE = 6

// eslint-disable-next-line max-lines-per-function
const StudentDashboardPage = () => {
  const dispatch = useDispatch()

  const user = useSelector(state => state.user.current_user)
  const community = user.communities[0]
  const family = useSelector(state => state.family)
  const activities = useSelector(state => state.activities)
  const family_stats = useSelector(state => state.family.family_stats)
  const enrollments = useSelector(state => state.familyEnrollments)
  const programs = useSelector(state => state.courses.courses)

  const [width, setWidth] = useState(window.innerWidth)
  const [activitiesPage, setActivitiesPage] = useState(1)
  const [familyLoader, setFamilyLoader] = useState(true)
  const [statsLoader, setStatsLoader] = useState(true)
  const [activitiesLoader, setActivitiesLoader] = useState(true)
  const [enrollmentsLoader, setEnrollmentsLoader] = useState(true)
  const [programsLoader, setProgramsLoader] = useState(true)

  useEffect(() => {
    const handleResize = () => { setWidth(window.innerWidth) }
    window.addEventListener('resize', handleResize)

    dispatch(setCurrentTab('Dashboard'))
    dispatch(getFamily(user.family_id)).then(() => {
      setFamilyLoader(false)
      dispatch(getFamilyStats(user.family_id)).then(() => setStatsLoader(false))
      dispatch(getFamilyEnrollments(user.family_id)).then(() => setEnrollmentsLoader(false))
      dispatch(getCommunityCourses(community.id)).then(() => setProgramsLoader(false))
    })

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [dispatch, user.family_id, community.id])

  useEffect(() => {
    if (user.family_admin) {
      dispatch(getFamilyActivities(user.family_id, activitiesPage, ACTIVITIES_PER_PAGE)).then(() => setActivitiesLoader(false))
    } else {
      dispatch(getUserActivities(user.id)).then(() => setActivitiesLoader(false))
    }
  }, [dispatch, user.family_id, user.family_admin, user.id, activitiesPage])


  const renderItem = (index, key) => {
    if (activities && activities.length > 0) {
      if (index > ACTIVITIES_PER_PAGE * activitiesPage - 2) {
        setActivitiesPage((prevState) => prevState + 1)
      }

      if (activities) {
        const rowActivity = activities[index]

        if (rowActivity) {
          return (
            <Activity
              key={key}
              displayComments={false}
              activity={rowActivity}
              triggerReload={undefined}
              isStudentJourney
            />
          )
        }
      }
    }
  }

  const displayActivities = () => (
    <div className='container-fluid'>
      <div className='row my-3 '>
        <div className='col-md-12 p-0'>
          <div className={classNames(classes.dashboardWelcomeContainer, 'card hidden-sm-down animated fadeIn')}>
            <div className={classNames(classes.dashboardWelcomeInfoWrapper, 'd-flex flex-row align-items-center justify-content-center pt-3')}>
              <div className={classNames(classes.dashboardWelcomeAvatar, 'round-image-wrapper mr-3 dashboard-welcome-avatar')}>
                <img
                  className={classNames(classes.roundImage)}
                  src={user.avatar ? user.avatar.file_url : StockProfilePic}
                  alt={`${user.first_name} ${user.last_name}`}
                />
              </div>
              <div>
                <h5 className={classes.dashboardTimeName}>{`Welcome ${user.first_name}`}</h5>

                <div className={classes.dashboardTimeWrapper}>
                  <h5 className={classes.dashboardTimeText}>Today is</h5>
                  <h5 className={classNames(classes.dashboardTime, 'animated fadeIn')}>
                    {moment().format('MM/DD/YYYY hh:mm A')}
                  </h5>
                </div>
              </div>
            </div>
          </div>
          {activities.length > 0 ? (
            <>
              <div className='activity-feed-divider hidden-sm-down' />
              <div className='activity-feed-section dashboard-fourth-step'>
                <ReactList
                  itemRenderer={renderItem}
                  length={activities.length}
                  type='simple'
                />
              </div>
            </>
          ) : (
            <div className={classNames(classes.familyActivityCard, 'card my-3')}>
              <h5 className='p-2 ml-3 my-2 card-header-text'>Activity Feed </h5>
              <div className='card-block p-2'>
                <div className='row py-2'>
                  <div className='col-12'>
                    <h2 className='text-center secondary-text activity-text'>
                      No Activity Yet
                    </h2>
                  </div>
                </div>
                <div className='player-wrapper animated fadeIn'>
                  <ReactPlayer
                    className='react-player'
                    url='https://vimeo.com/310150761/904bc3a28a'
                    width='100%'
                    height='100%'
                    controls
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )

  const displayEnrollments = () => {
    if (enrollments && enrollments.length > 0) {
      return enrollments.map((enrollment, index) => {
        if (enrollment.course) {
          return (
            <EnrollmentCard
              enrollment={enrollment}
              key={index}
            />
          )
        }
        return null
      })
    } else {
      return (
        <Placeholder
          title={'No Current Enrollments'}
          icon={'fa-group'}
          size={'100'}
          subtext1={'Currently no golfers are enrolled.'}
          subtext2={'Take a look at active programs and sign up.'}
        />
      )
    }
  }

  const displayPrograms = () => {
    if (programs && programs.length > 0) {
      return programs.map(program => (
        <FamilyProgramCard
          key={program.id}
          course={program}
          family={family}
        />
      ))
    }

    return (
      <Placeholder
        title={'No programs listed'}
        icon={'fa-clipboard'}
        size={'100'}
        subtext1={'Contact the coach of the community'}
        subtext2={'to see when a program will be listed.'}
      />
    )
  }

  return (
    <div className={classNames(classes.studentDashboardPage, 'animated fadeIn')}>
      {width < 1200 && <PageHeader/>}
      <div className='container-fluid'>
        {(familyLoader || activitiesLoader || statsLoader)
          ? <Loader message='Building Dashboard' />
          : <>
            <div className={classes.dashboardHeader}>
              <div className={classes.familyContainer}>
                {[...family.users].sort((a, b) => (
                  (b.family_admin - a.family_admin) || (a.last_name.localeCompare(b.last_name)) || (a.first_name.localeCompare(b.last_name))
                )).map(user => (
                  <StudentCard student={user} key={user.id} />
                ))}
              </div>
            </div>
            <div className='row mt-4'>
              <div className='col-md-4'>
                {displayActivities()}
              </div>

              <div className='col-md-8 family-stats-container'>
                <div className='row my-3'>
                  <div className='col-md-3'>
                    <div className='card'>
                      <h5 className='p-2 ml-3 my-2 card-header-text'>
                        Family Points{' '}
                      </h5>
                      <p className='family-points'>{abbrNum(family.points, 1)}</p>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='card'>
                      <h5 className='p-2 ml-3 my-2 card-header-text'>
                        Total Golfers{' '}
                      </h5>
                      <p className='family-golfers'>{family.users.length}</p>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='card'>
                      <h5 className='p-2 ml-3 my-2 card-header-text'>
                        Total Activities{' '}
                      </h5>
                      <p className='family-activities'>
                        {abbrNum(family_stats.total_activities, 1)}
                      </p>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='card'>
                      <h5 className='p-2 ml-3 my-2 card-header-text'>
                        Activities Today{' '}
                      </h5>
                      <p className='family-activities-today'>
                        {family_stats.activities_today}
                      </p>
                    </div>
                  </div>
                </div>
                <div className='row my-3'>
                  <div className='col-md-6'>
                    <div className='card'>
                      <h5 className='p-2 ml-3 my-2 card-header-text'>
                        Active Community Classes{' '}
                      </h5>
                      <div className='p-3 family-dashboard-card'>
                        {programsLoader ? (
                          <div className='row d-flex justify-content-center align-items-center p-5 animated fadeIn'>
                            <ActivityLoader type='ball-pulse' color={'lightgrey'} />
                          </div>
                        ) : (
                          displayPrograms()
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='card'>
                      <h5 className='p-2 ml-3 my-2 card-header-text'>
                        Active Enrollments{' '}
                      </h5>
                      <div className='p-3 family-dashboard-card'>
                        {enrollmentsLoader ? (
                          <div className='row d-flex justify-content-center align-items-center p-5 animated fadeIn'>
                            <ActivityLoader type='ball-pulse' color={'lightgrey'} />
                          </div>
                        ) : (
                          displayEnrollments()
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      </div>
    </div>
  )
}

export default StudentDashboardPage
