import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as commonActions from "../../actions/commonActions";
import * as courseActions from "../../actions/courseActions";
import * as familyActions from "../../actions/familyActions";

import Modal from "react-modal";
import _ from "lodash";

import ParentClassCard from "./ParentClassCard";
import PageHeader from '../common/PageHeader/PageHeader'

import "../../assets/styles/components/classes/parentClasses.scss";
import { withRouting } from "../common/hooks";

class ClassesPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showModal: false,
      activeCourse: "",
      activeMembers: [],
    };

    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.displayModal = this.displayModal.bind(this);

    this.displayEnrolledFamilyMembers = this.displayEnrolledFamilyMembers.bind(
      this
    );
    this.displayNonEnrolledFamilyMember = this.displayNonEnrolledFamilyMember.bind(
      this
    );
    this.displayPendingFamilyMembers = this.displayPendingFamilyMembers.bind(
      this
    );

    this.displayFamilyMember = this.displayFamilyMember.bind(this);

    this.handleSubmitUserJoinCourse = this.handleSubmitUserJoinCourse.bind(
      this
    );
    this.isUserEnrolled = this.isUserEnrolled.bind(this);
    this.handleMemberChange = this.handleMemberChange.bind(this);
    this.isMemberChecked = this.isMemberChecked.bind(this);
    this.isUserPending = this.isUserPending.bind(this);
  }

  componentDidMount() {
    const { common_actions } = this.props;
    common_actions.setCurrentTab("Students");
    common_actions.showSideBar(true);
    common_actions.showNavBar(true);
    this.props.course_actions.getCommunityCourses(
      this.props.user.current_user.communities[0].id
    );
    this.props.family_actions.getFamily(this.props.user.current_user.family_id);
    this.props.family_actions.getFamilyEnrollments(
      this.props.user.current_user.family_id
    );
  }

  displayCourseCoachNames(course) {
    if (course.coaches && course.coaches.length > 0) {
      return (
        <h3 className='text-white h6'>
          <strong>
            {/*WITH COACH {course.coaches[0].first_name.toUpperCase()} */}
          </strong>
        </h3>
      );
    } else {
      return (
        <h3 className='text-white h6'>
          <strong> </strong>
        </h3>
      );
    }
  }


  displayCourse(course, index) {
    //api will not return classes that are archived, draft, or completed
    return (
      <div className='col-xl-4 col-lg-6 col-md-6 mb-3' key={course.id}>
        <ParentClassCard course={course} openModal={this.openModal} />
      </div>
    );
  }

  closeModal() {
    this.setState({ showModal: false, activeCourse: "", activeMembers: [] });
  }

  openModal(course) {
    this.setState({
      showModal: true,
      activeCourse: course
    });
  }

  handleMemberChange(user_id) {
    // IF THE MEMBER IS CHECKED UNCHECKED ELSE CHECK IT
    if (this.isMemberChecked(this.state.activeMembers, user_id)) {
      var newArrayIsChecked = _.pull(this.state.activeMembers, user_id);
      this.setState({ activeMembers: newArrayIsChecked });
    } else {
      var newArrayNotChecked = this.state.activeMembers.slice();
      newArrayNotChecked.push(user_id);
      this.setState({ activeMembers: newArrayNotChecked });
    }
  }

  displayEnrolledFamilyMembers(user, course_id, index) {
    return (
      <div key={index} className='join-program-listitem'>
        <div className='checkbox enrolledFamilyMember'>
          {user.first_name} {user.last_name}
        </div>
      </div>
    );
  }

  isMemberChecked(members, user_id) {
    if (_.indexOf(members, user_id) > -1) {
      return true;
    } else {
      return false;
    }
  }
  displayNonEnrolledFamilyMember(user, course_id, index) {
    return (
      <div key={index} className='join-program-listitem'>
        <div className='checkbox nonEnrolledFamilyMember'>
          <label>
            <input
              name=''
              type='checkbox'
              checked={this.isMemberChecked(this.state.activeMembers, user.id)}
              onChange={this.handleMemberChange.bind(this, user.id)}
            />
            {user.first_name} {user.last_name}
          </label>
        </div>
      </div>
    );
  }

  displayPendingFamilyMembers(user, course_id, index) {
    return (
      <div key={index} className='join-program-listitem'>
        <div className='checkbox pendingFamilyMember'>
          {user.first_name} {user.last_name}{" "}
          <small>
            <i>Pending</i>
          </small>
        </div>
      </div>
    );
  }

  // DISPALY FAMILY MEMBER IF ENROLLED OR NOT
  displayFamilyMember(user, course_id, index) {
    if (this.isUserEnrolled(user, course_id)) {
      return this.displayEnrolledFamilyMembers(user, course_id, index);
    } else if (this.isUserPending(user, course_id)) {
      return this.displayPendingFamilyMembers(user, course_id, index);
    } else {
      return this.displayNonEnrolledFamilyMember(user, course_id, index);
    }
  }
  displayFamilyMembers(course_id) {
    if (this.props.family) {
      return this.props.family.map((user, index) =>
        this.displayFamilyMember(user, course_id, index)
      );
    }
  }

  handleSubmitUserJoinCourse(course_id) {
    // LOOP THROUGH LOCAL STATE
    this.state.activeMembers.map((user_id, index) =>
      this.props.family_actions.addFamilyMemberEnrollment(user_id, course_id)
    );

    this.setState({ showModal: false, activeMembers: [] });
    alert("The coach has been notified of the enrollment request!");
    this.props.navigate('/dashboard')
  }

  isUserEnrolled(user, course_id) {
    if (this.props.enrollments) {
      for (var i = 0; i < this.props.enrollments.length; i++) {
        if (
          user.id === this.props.enrollments[i].user_id &&
          course_id === this.props.enrollments[i].course_id
        ) {
          return true;
        }
      }

      return false;
    }
  }

  isUserPending(user, course_id) {
    // 0 is Applied
    // 1 is Active
    // 2 is Suspended
    if (this.props.enrollments) {
      let enrollments = _.filter(this.props.enrollments, {
        user_id: user.id,
        course_id: course_id,
        status: "applied"
      });
      if (enrollments.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  displayModal() {
    return (
      <Modal
        isOpen={this.state.showModal}
        onRequestClose={this.closeModal}
        contentLabel='JoinClassModal'
      >
        <div className='modal-header row'>
          <div className='col-11'>
            <h5 className='modal-title display-4'>Join Class</h5>
            <span className='text-center font-200'>
              {this.state.activeCourse.name}
              {this.state.activeCourse.meeting_times
                ? ` - ${this.state.activeCourse.meeting_times}`
                : null}
            </span>
          </div>

          <div className='col-1'>
            <button
              type='button'
              className='close pull-right font-200'
              data-dismiss='modal'
              aria-label='Close'
              onClick={this.closeModal}
            >
              <span aria-hidden='true'>✕</span>
            </button>
          </div>
        </div>

        <div className='modal-content'>
          <div className='modal-body' id='input-border'>
            <div className='row mb-2 border-bottom p-2'>
              <div className='col'>
                <div className='row'>
                  <div className='col'>
                    <p className='lead'>
                      Add your family members to this class
                    </p>
                  </div>
                </div>

                <div className='join-program-list'>
                  {this.displayFamilyMembers(this.state.activeCourse.id)}
                </div>
                <div className='d-flex justify-content-center align-content-center'>
                  <button
                    id='submit-btn'
                    className='btn btn-primary'
                    disabled={this.state.activeMembers.length === 0}
                    onClick={this.handleSubmitUserJoinCourse.bind(
                      this,
                      this.state.activeCourse.id
                    )}
                  >
                    Request to Join Class
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  render() {
    return (
      <div id='ParentClassesPage'>
        <PageHeader title='Classes'/>
        <div className='row mb-3'>
          {this.props.courses
            ? this.props.courses.map((course, index) =>
                this.displayCourse(course, index)
              )
            : null}
        </div>

        {this.displayModal()}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    ui: state.ui,
    user: state.user,
    courses: state.courses.courses,
    family: state.family.users,
    enrollments: state.familyEnrollments
  };
}

function mapDispatchToProps(dispatch) {
  return {
    common_actions: bindActionCreators(commonActions, dispatch),
    family_actions: bindActionCreators(familyActions, dispatch),
    course_actions: bindActionCreators(courseActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouting(ClassesPage))
