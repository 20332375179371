import React, { Component } from "react";
import Axios from "../../axios";
import BaseModal from "../common/BaseModal";

import "./AdminExportsContainer.scss";

const GOLF_ED_REPORTS = [
  {
    name: "Strokes Lost",
    slug: "strokes_lost",
  },
  {
    name: "9-Hole Events",
    slug: "events",
  },
  {
    name: "Formal Golfers Created",
    slug: "golfers_created",
  },
  {
    name: "Rounds Logged",
    slug: "rounds",
  },
  {
    name: 'Formal Level Passers',
    slug: "division_passers",
  },
];

export default class AdminExportsContainer extends Component {
  state = {
    email: "",
    responseModal: null,
  };

  render() {
    const { email, responseModal } = this.state;
    return (
      <div id='AdminExportsContainer' className='card p-4'>
        <h2>Exportable Reports</h2>

        <p className='sub-text'>
          These reports are generated in the background and will be emailed to
          you. Fill in your preferred email below, and select a report. If
          sending to more than one email, separate them with a comma.
        </p>
        <label htmlFor='email'>Email</label>
        <input
          type='text'
          id='email'
          value={email}
          onChange={this._handleInputChange}
          name='email'
          placeholder='Enter email(s)'
        />
        <hr />
        {/* =========== this should probably be refactored if more reports are added === */}
        <h4>Golf*Ed Report</h4>
        <p>
          Multiple leaderboards for strokes lost, 9-hole events, golfers
          created, rounds logged, and level passers.
        </p>
        {GOLF_ED_REPORTS.map((report, idx) => {
          return (
            <div
              className='d-flex align-items-center justify-content-between mb-2 pl-3'
              key={idx}
            >
              <div className='mr-2'>
                <h5>{report.name}</h5>
              </div>
              <div style={{ flex: "1 1", borderBottom: "2px dotted #ddd" }} />
              <div className='button-box ml-2'>
                <button
                  className='btn btn-sm btn-block px-4'
                  onClick={() => this._handleExportClick(report.slug)}
                >
                  <i className='fa fa-download mr-2' aria-hidden='true' />
                  Export
                </button>
              </div>
            </div>
          )
        })}
        {/* ============================================================================ */}
        {!!responseModal && (
          <BaseModal
            close={() => false}
            backable={false}
            hideClose={true}
            width='550px'
          >
            <h4 className='text-center'>{responseModal}</h4>
            <p className='text-center'>
              {responseModal === "success"
                ? `Export job successfully started. Check emails: ${email}`
                : "Something happened"}
            </p>
          </BaseModal>
        )}
      </div>
    );
  }

  _handleExportClick = async (report_slug) => {
    // this will need to be more flexible if more reports are added
    const { email } = this.state;
    if (!email) {
      // show an error here -
      return false;
    }
    const response = await Axios.get(
      `/api/v1/exports/golf_ed/?report_slug=${report_slug}&email=${email}`
    );
    if (response.status === 200) {
      this.setState({ responseModal: "success" }, () => {
        setTimeout(this._closeResponseModal, 3500);
      });
    } else {
      this.setState({ responseModal: "fail" }, () => {
        setTimeout(this._closeResponseModal, 3500);
      });
    }
  };

  _closeResponseModal = () => {
    this.setState({ responseModal: null });
  };

  _handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
}
