import * as constants from './actionTypes';
import * as common from './commonActions';

import Axios from '../axios';

export function successRequestingGetTags(response){
  if(response.status === 200){
    return { type: constants.GET_TAGS, tags: response.data.tags};
  }else{
    console.log("ERROR ON SIGNIN")
    return {type: constants.UI_ERROR, error: response.data}
  }
}

export function successRequestingDrillFilterTags(response){
  if(response.status === 200){
    return { type: constants.GET_DRILL_FILTER_TAGS, tags: response.data.tags};
  }else{
    console.log("ERROR ON SIGNIN")
    return {type: constants.UI_ERROR, error: response.data}
  }
}

// THUNKS HERE
export function getTags(){
  return function(dispatch){
    dispatch(common.loadingChanged(true));
    return requestGetTags().then(
      response => dispatch(successRequestingGetTags(response))
    ).then(
      turnOffSpinner => dispatch(common.loadingChanged(false))
    )
  }
}

export function getDrillFilterTags(){
  return function(dispatch){
    dispatch(common.loadingChanged(true));
    return requestGetDrillFilterTags().then(
      response => dispatch(successRequestingDrillFilterTags(response))
    ).then(
      turnOffSpinner => dispatch(common.loadingChanged(false))
    )
  }
}

// API CALLS
function requestGetTags() {
  let requestUrl = '/api/v1/tags'
  return Axios.get(requestUrl)
}

// API CALLS
function requestGetDrillFilterTags() {
  let requestUrl = '/api/v1/tags/drill_filters'
  return Axios.get(requestUrl)
}
