import React from "react";
import PropTypes from 'prop-types'

class IssueTrophy extends React.Component {
  // constructor(props, context){
  //   super(props, context);
  // }

  displayRankObjectives() {
    if (this.props.incompleteObjectives.length > 0) {
      return this.props.incompleteObjectives.map((objective, index) => {
        return this.displayRankObjective(objective);
      });
    }
  }

  displayRankObjective(objective) {
    if (objective.is_completed === false) {
      return (
        <option value={objective.id} key={objective.id}>
          {objective.subject.name} : {objective.name}
        </option>
      );
    }
  }

  handleRankObjectiveSelectChanged(changeEvent) {
    this.setState({
      issueTrophyFormObjectiveId: changeEvent.target.value
    });
  }

  render() {
    return (
      <div className="modal-content">
        <div className="modal-body" id="input-border">
          <div className="row">
            <div className="col">
              <div className="form-group">
                {this.props.objectives.length &&
                !this.props.isLoading &&
                !this.props.isRankCompleted ? (
                  <div>
                    <label htmlFor="objectives">
                      Select Rank {this.props.userCurriculum.rank_id} Objective
                    </label>
                    <select
                      className="form-control"
                      id="objectives"
                      onChange={this.props.handleRankObjectiveSelectChanged}
                      value={this.props.issueTrophyFormObjectiveId}
                    >
                      {this.displayRankObjectives()}
                    </select>
                    <div className="d-flex justify-content-center align-items-center">
                      <button
                        type="button"
                        className="btn btn-primary w-50 mx-auto mt-5"
                        onClick={this.props.submitIssueTrophyForm}
                      >
                        Mark Goal Complete
                      </button>
                    </div>
                  </div>
                ) : this.props.isRankCompleted && !this.props.isLoading ? (
                    <div>
                      <label htmlFor='level-up-button'>Level Up</label>
                      <div className='d-flex justify-content-center align-items-center'>
                        <button
                          id='level-up-button'
                          type='button'
                          className='btn btn-primary w-50 mx-auto mt-5'
                          onClick={this.props.progressRankForUser}
                        >
                          Level Up
                        </button>
                      </div>
                    </div>
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

IssueTrophy.propTypes = {
  progressRankForUser: PropTypes.func,
}

export default IssueTrophy;
