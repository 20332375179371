import * as constants from "./actionTypes";
import * as common from "./commonActions";

import Axios from '../axios'

export function successCreatingGoal(response) {
  if (response.status === 201) {
    return { type: constants.CREATE_GOAL, goal: response.data.goal };
  } else {
    console.log("ERROR CREATING GOAL");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successGettingGoal(response) {
  if (response.status === 201) {
    return { type: constants.GET_GOAL, goal: response.data.goal };
  } else {
    console.log("ERROR CREATING GOAL");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successGettingGoalStudents(response) {
  if (response.status === 201) {
    return {
      type: constants.GET_GOAL_STUDENTS,
      goal_students: response.data.goal_students
    };
  } else {
    console.log("ERROR GETTING GOAL STUDENTS");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successGettingInactiveGoalStudents(response) {
  if (response.status === 201) {
    return {
      type: constants.GET_GOAL_INACTIVE_STUDENTS,
      inactive_goal_students: response.data.inactive_goal_students
    };
  } else {
    console.log("ERROR GETTING GOAL STUDENTS");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successUpdatingGoal(response) {
  if (response.status === 200 || response.status === 201) {
    return { type: constants.UPDATE_GOAL, goal: response.data.goal };
  } else {
    console.log("ERROR UPDATING GOAL");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function succcessDeletingGoal(response) {
  if (response.status === 200) {
    return { type: constants.DELETE_GOAL, goal: response.data.session };
  } else {
    console.log("ERROR DELETING GOAL");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successCreatingGoalTask(response) {
  if (response.status === 201) {
    return { type: constants.UPDATE_GOAL, goal: response.data.goal };
  } else {
    console.log("ERROR CREATING GOAL TASK");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successUpdatingGoalTask(response) {
  if (response.status === 201) {
    return {
      type: constants.UPDATE_GOAL_TASK,
      goal_task: response.data.goal_task
    };
  } else {
    console.log("ERROR UPDATING GOAL TASK");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

// export function successDeletingGoalTask(response) {
//   if (response.status === 201) {
//     return { type: constants.UPDATE_SESSION, goal: response.data.goal };
//   } else {
//     console.log("ERROR DELETING GOAL TASK");
//     return { type: constants.UI_ERROR, error: response.data };
//   }
// }

export function successCreatingGoalReview(response) {
  if (response.status === 201) {
    return { type: constants.UPDATE_GOAL, goal: response.data.goal };
  } else {
    console.log("ERROR CREATING GOAL TASK");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successUpdatingGoalReview(response) {
  if (response.status === 201) {
    return {
      type: constants.UPDATE_GOAL_REVIEW,
      goal_task: response.data.goal_task
    };
  } else {
    console.log("ERROR UPDATING GOAL TASK");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successDeletingGoalReview(response) {
  if (response.status === 201) {
    return {
      type: constants.DELETE_GOAL_REVIEW,
      goal_review: response.data.goal_review
    };
  } else {
    console.log("ERROR DELETING GOAL TASK");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

// THUNKS HERE

export function getGoal(goalId, communityId) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true));
    return requestGoal(goalId).then(response =>
      dispatch(successGettingGoal(response))
    );
  };
}

export function getGoalStudents(goalId, communityId) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true));
    return requestGoalStudents(goalId, communityId).then(response =>
      dispatch(successGettingGoalStudents(response))
    );
  };
}

export function getInactiveGoalStudents(goalId, communityId) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true));
    return requestInactiveGoalStudents(goalId, communityId).then(response =>
      dispatch(successGettingInactiveGoalStudents(response))
    );
  };
}

export function getGoalTasks(goalId) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true));
    return requestGoalTasks(goalId);
  };
}

export function getGoalReviews(goalId) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true));
    return requestGoalReviews(goalId);
  };
}

export function createGoal(goal, tasks) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true));
    return requestCreateGoal(goal, tasks)
      .then(response => dispatch(successCreatingGoal(response)))
      .then(turnOffSpinner => dispatch(common.loadingChanged(false)));
  };
}

export function createGoalsFromTemplate(goal, students) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true));
    return requestCreateGoalsFromTemplate(goal, students);
  };
}

export function updateGoal(goal, tasks, deletedTasks, deletedPictures) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true));
    return requestUpdateGoal(goal, tasks, deletedTasks, deletedPictures)
      .then(response => dispatch(successUpdatingGoal(response)))
      .then(turnOffSpinner => dispatch(common.loadingChanged(false)));
  };
}

export function deleteGoal(goal) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true));
    return requestDeleteGoal(goal)
      .then(response => dispatch(succcessDeletingGoal(response)))
      .then(turnOffSpinner => dispatch(common.loadingChanged(false)));
  };
}

export function addGoalTask(goalTask) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true));
    return requestCreateGoalTask(goalTask)
      .then(response => dispatch(successCreatingGoalTask(response)))
      .then(turnOffSpinner => dispatch(common.loadingChanged(false)));
  };
}

export function updateGoalTask(goalTask) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true));
    return requestUpdateGoalTask(goalTask)
      .then(response => dispatch(successUpdatingGoalTask(response)))
      .then(turnOffSpinner => dispatch(common.loadingChanged(false)));
  };
}

// export function deleteGoalTask(goalTask) { // TODO: This does not look needed, double check
//   return function(dispatch) {
//     dispatch(common.loadingChanged(true));
//     return requestDeleteGoalTask(goalTask)
//       .then(response => dispatch(successDeletingGoalTask(response)))
//       .then(turnOffSpinner => dispatch(common.loadingChanged(false)));
//   };
// }

export function addGoalReview(goalReview) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true));
    return requestCreateGoalReview(goalReview)
      .then(response => dispatch(successCreatingGoalReview(response)))
      .then(turnOffSpinner => dispatch(common.loadingChanged(false)));
  };
}

export function updateGoalReview(goalReview) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true));
    return requestUpdateGoalReview(goalReview)
      .then(response => dispatch(successUpdatingGoalReview(response)))
      .then(turnOffSpinner => dispatch(common.loadingChanged(false)));
  };
}

export function deleteGoalReview(goalReview) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true));
    return requestDeleteGoalReview(goalReview)
      .then(response => dispatch(successDeletingGoalReview(response)))
      .then(turnOffSpinner => dispatch(common.loadingChanged(false)));
  };
}

// API CALLS
function requestGoal(goalId) {
  let requestUrl = "/api/v1/goals/" + goalId;
  return Axios.get(requestUrl);
}

function requestGoalStudents(goalId, communityId) {
  let requestUrl = "/api/v1/goals/" + goalId + "/students";

  requestUrl += "?community_id=" + communityId;

  return Axios.get(requestUrl);
}

function requestInactiveGoalStudents(goalId, communityId) {
  let requestUrl = "/api/v1/goals/" + goalId + "/inactive_students";

  requestUrl += "?community_id=" + communityId;

  return Axios.get(requestUrl);
}

function requestGoalTasks(goalId) {
  let requestUrl = "/api/v1/goals/" + goalId + "/goal_tasks";
  return Axios.get(requestUrl);
}

function requestGoalReviews(goalId) {
  let requestUrl = "/api/v1/goals/" + goalId + "/goal_reviews";
  return Axios.get(requestUrl);
}

function requestCreateGoalsFromTemplate(goal, students) {
  let requestUrl = "/api/v1/goals/create_from_template";
  return Axios.post(requestUrl, {
    template_id: goal.template_id,
    students: JSON.stringify(students)
  });
}

function requestCreateGoal(goal, tasks) {
  let requestUrl = "/api/v1/goals";

  var data = new FormData();
  if (goal.userId) data.append("user_id", goal.userId);
  if (goal.creatorId) data.append("creator_id", goal.creatorId);
  if (goal.is_template) data.append("is_template", true);
  data.append("name", goal.goalName);
  data.append("description", goal.goalDescription);
  data.append("goal_tasks", JSON.stringify(tasks));

  if (goal.goalVideo !== "") {
    data.append("video_url", goal.goalVideo);
  }

  if (goal.pictures.length > 0) {
    for (let i = 0; i < goal.pictures.length; i++) {
      data.append(`goal_photo_${i}`, goal.pictures[i]);
    }
  }

  return Axios.post(requestUrl, data);
}

function requestUpdateGoal(goal, tasks, deletedTasks, deletedPictures) {
  let requestUrl = "/api/v1/goals/" + goal.currentGoal.id;

  var data = new FormData();
  data.append("id", goal.currentGoal.id);
  data.append("user_id", goal.userId);
  data.append("name", goal.goalName);
  data.append("description", goal.goalDescription);
  data.append("goal_tasks", JSON.stringify(tasks));

  if (deletedTasks.length > 0) {
    data.append("deleted_tasks", JSON.stringify(deletedTasks));
  }

  if (deletedPictures.length > 0) {
    data.append("deleted_pictures", JSON.stringify(deletedPictures));
  }

  if (goal.goalVideo !== "") {
    data.append("video_url", goal.goalVideo);
  }

  if (goal.goalVideo === "") {
    data.append("video_url", "");
  }

  if (goal.pictures.length > 0) {
    for (let i = 0; i < goal.pictures.length; i++) {
      if (goal.pictures[i].hasOwnProperty("id")) {
        data.append(`goal_photo_${i}`, JSON.stringify(goal.pictures[i]));
      } else {
        data.append(`goal_photo_${i}`, goal.pictures[i]);
      }
    }
  }

  return Axios.put(requestUrl, data);
}

function requestDeleteGoal(goal) {
  let requestUrl = "/api/v1/goals/" + goal.id;
  return Axios.delete(requestUrl);
}

// function requestDeleteGoalTask(goalTask) {
//   let requestUrl = "/api/v1/goal_tasks/" + goalTask.id;
//   return Axios.delete(requestUrl);
// }

function requestCreateGoalTask(goalTask) {
  let goalId = goalTask.currentGoal.id;
  let requestUrl = "/api/v1/goals/" + goalId + "/addTask";
  return Axios.post(requestUrl, {
    goal_id: goalId,
    creator_id: goalTask.creatorId,
    note: goalTask.note,
    title: goalTask.taskTitle,
    description: goalTask.description,
    order: goalTask.order,
    duration: goalTask.duration,
    drill_id: goalTask.drillId,
    objective_id: goalTask.objectiveId,
    rank_id: goalTask.rankId
  });
}

function requestUpdateGoalTask(goalTask) {
  let goalId = goalTask.taskId;
  let requestUrl = "/api/v1/goal_tasks/" + goalId;
  return Axios.put(requestUrl, {
    note: goalTask.note,
    title: goalTask.taskTitle,
    description: goalTask.description,
    duration: goalTask.duration,
    drill_id: goalTask.drillId,
    objective_id: goalTask.objectiveId,
    rank_id: goalTask.rankId
  });
}

function requestDeleteGoalReview(goalReview) {
  let requestUrl = "/api/v1/goal_reviews/" + goalReview.id;
  return Axios.delete(requestUrl);
}

function requestCreateGoalReview(goalReview) {
  let goalId = goalReview.currentGoal.id;
  let requestUrl = "/api/v1/goals/" + goalId + "/addReview";

  var data = new FormData();
  data.append("goal_id", goalId);
  data.append("user_id", goalReview.userId);
  data.append("creator_id", goalReview.creatorId);
  if (goalReview.task.id) {
    data.append("task_id", goalReview.task.id);
  }
  data.append("note", goalReview.reviewNote);
  data.append("progress_percentage", goalReview.goalPercentage);
  data.append("is_completed", goalReview.goalComplete);

  if (goalReview.pictures.length > 0) {
    for (let i = 0; i < goalReview.pictures.length; i++) {
      data.append(`goal_review_photo_${i}`, goalReview.pictures[i]);
    }
  }

  return Axios.post(requestUrl, data);
}

function requestUpdateGoalReview(goalReview) {
  let goalId = goalReview.taskId;
  let requestUrl = "/api/v1/goal_tasks/" + goalId;
  return Axios.put(requestUrl, {
    note: goalReview.note,
    title: goalReview.taskTitle,
    description: goalReview.description,
    duration: goalReview.duration,
    drill_id: goalReview.drillId,
    objective_id: goalReview.objectiveId,
    rank_id: goalReview.rankId
  });
}
