import * as constants from './actionTypes'

import Axios from '../axios'
import { serialize } from '../utility/serialization'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { handleAPIError } from '../utility/actionHelper'

function successRequestingGetViews(response) {
  if (response.status === 200) {
    return { type: constants.GET_VIEWS, views: response.data.views }
  } else {
    return { type: constants.UI_ERROR, error: response.data }
  }
}

// THUNKS

export const createCommunityView = createAsyncThunk(
  'views/createCommunityView',
  async (communityId, { rejectWithValue }) => {
    try {
      await requestPostViews(communityId)
    } catch (e) {
      return handleAPIError(e, rejectWithValue)
    }
  }
)

export const getViews = () => dispatch => requestGetViews()
  .then(response => dispatch(successRequestingGetViews(response)))

// API CALLS

export const requestGetViews = () => {
  const requestUrl = '/api/v1/views'
  return Axios.get(requestUrl)
}

export const requestPostViews = (communityId) => {
  const requestUrl = '/api/v1/views'
  return Axios.post(requestUrl, serialize({ communityId }))
}
