import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import moment from 'moment'

import { ReactComponent as MenuIcon } from '../../assets/images/common/icons/more-icon-vertical.svg'
import { ReactComponent as EditIcon } from '../../assets/images/common/icons/programBuilder/edit-icon.svg'
import { ReactComponent as GolferIcon } from '../../assets/images/common/icons/golfer-half-icon.svg'

import styles from './SeriesRow.module.scss'
import classNames from 'classnames'
import colors from '../../assets/styles/globals.scss'
import { pluralize } from '../../utility/helperFunctions'

const SeriesRow = ({ series, expandSeries, isFirstChild, handleSeriesMenuClick }) => {
  const timezone = useSelector(state => state.user.current_user.communities[0].timezone)
  const seriesStartDateInTimezone = moment(series.startDate).tz(timezone)
  const seriesEndDateInTimezone = moment(series.endDate).tz(timezone)
  const seriesNextDateInTimezone = moment(series.nextEventDate).tz(timezone)

  return (
    <div
      className={classNames(styles['series-card'], 'w-100', isFirstChild && styles['first-child'])}
      onKeyDown={null}
      role='button'
      tabIndex={0}
    >
      <div
        className={classNames(series.status !== 'completed' ? 'col-5' : 'col-8', 'py-2 col-5 d-flex align-items-center', styles['series-info'])}
        onClick={() => expandSeries(series.id)}
        aria-hidden='true'
      >
        {seriesStartDateInTimezone.isValid() && seriesEndDateInTimezone.isValid() ?
          <>
            <div className={styles['date-col']}>
              <span className={styles['month-name']}>{ seriesStartDateInTimezone.format('MMM') }</span>
              <span className={styles['day-number']}>{ seriesStartDateInTimezone.format('DD') }</span>
              <span className={styles['day-name']}>{ seriesStartDateInTimezone.format('ddd') }</span>
            </div>
            <div className={styles['horizontal-line']}/>
            <div className={styles['date-col']}>
              <span className={styles['month-name']}>{ seriesEndDateInTimezone.format('MMM') }</span>
              <span className={styles['day-number']}>{ seriesEndDateInTimezone.format('DD') }</span>
              <span className={styles['day-name']}>{ seriesEndDateInTimezone.format('ddd') }</span>
            </div>
          </>
          :
          <div>------------------</div>
        }
        <div className={styles['vertical-line']}></div>
        <div className={classNames('d-flex flex-column justify-content-between h-75', styles['title-col'])}>
          <div className='d-flex'>
            { series.status === 'draft' &&
              <div className={classNames('mr-3', styles['draft-label'])}>
                <EditIcon fill='#fff' className={styles['edit-icon']}/>
                <span>Draft</span>
              </div>
            }
            <span>{series.title}</span>
          </div>
          <div className={styles['events-count-label']}>
            <div className={styles['label-text']}> {series.events.length} {pluralize('event', series.events.length).toUpperCase()}</div>
          </div>
        </div>
      </div>
      {series.status !== 'completed' &&
        <div
          className={classNames('col-3 d-flex flex-column align-items-center justify-content-center', styles['next-event-col'])}
          onClick={() => expandSeries(series.id)}
          aria-hidden='true'
        >
          <span>Next Event</span>
          <span>{ seriesNextDateInTimezone.isValid() ? seriesNextDateInTimezone.format('MMMM Do') : '-' }</span>
        </div>
      }

      <div
        className={classNames('col-3 d-flex align-items-center justify-content-center', styles['expand-view-col'])}
        onClick={() => expandSeries(series.id)}
        aria-hidden='true'
      >
        <GolferIcon className='mr-3' fill={colors.primaryNavy}/>
        <div>
          <span className={styles['golfers-count']}>{series.students.length}</span>
          <span className='ml-1'>{pluralize('golfer', series.students.length)}</span>
        </div>
      </div>

      <div
        className={classNames('col-2', styles['actions-col'])}
        onClick={() => handleSeriesMenuClick(series.id)}
        onKeyDown={null}
        role='button'
        tabIndex={0}
      >
        <button className={styles['series-button']} id='seriesMenuButton'>
          <div className='d-flex align-items-center'>
            <MenuIcon fill={colors.primaryNavy}/>
          </div>
        </button>
      </div>
    </div>
  )
}

SeriesRow.propTypes = {
  series: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    events: PropTypes.array,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    nextEventDate: PropTypes.string,
    students: PropTypes.array,
    status: PropTypes.string,
  }),
  isFirstChild: PropTypes.bool,
  expandSeries: PropTypes.func.isRequired,
  handleSeriesMenuClick: PropTypes.func.isRequired,
}

export default SeriesRow
