import { createSelector, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { getCommunityLandingPageSettings, updateCommunityLandingPageSettings } from '../../actions/communitiesActions'
import { createCourseCategory, deleteCourseCategory, updateCourseCategory } from '../../actions/programCategoryActions'
import { isMissingImageUrl } from '../../utility/helperFunctions'

const initialState = {
  attributeErrors: null,
  isLoadingPage: null,
  isLoadingUpdate: null,
  isLoadingEditCourseCategory: null,
  community: null,
  settingsFields: null,
  categoriesOrdered: null,
  courseCategoryFields: null,
  courseCategoryErrors: {},
}

const landingPageSettingsReducer = createSlice({
  name: 'communitySettings/landingPage',
  initialState,
  reducers: {
    setField: (state, action) => {
      const { field, value } = action.payload

      state.settingsFields[field] = value
    },
    setHeaderImage: (state, action) => {
      const { headerImageUrl, headerImageData, headerImageName } = action.payload

      state.settingsFields.headerImageUrl = headerImageUrl
      state.settingsFields.headerImageData = headerImageData
      state.settingsFields.headerImageName = headerImageName

      if (state.community.headerImage && !headerImageUrl) {
        state.settingsFields.headerImageDelete = true
      }
    },
    setCourseCategory: (state, action) => {
      const category = action.payload
      const newCategoryFields = {
        name: category.name,
        color: category.color,
        order: category.order,
        description: category.description,
        headerImageUrl: category.headerImage?.largeFileUrl,
        headerImageData: null,
        headerImageName: category.headerImage?.name,
      }

      if (category.id) {
        newCategoryFields.id = category.id
      }

      state.courseCategoryFields = newCategoryFields
    },
    setCourseCategoryField: (state, action) => {
      const { field, value } = action.payload

      state.courseCategoryFields[field] = value
    },
    setCourseCategoryHeaderImage: (state, action) => {
      const { headerImageUrl, headerImageData, headerImageName } = action.payload

      if (state.courseCategoryFields.headerImageUrl && !headerImageUrl) {
        state.courseCategoryFields.headerImageDelete = true
      }

      state.courseCategoryFields.headerImageUrl = headerImageUrl
      state.courseCategoryFields.headerImageData = headerImageData
      state.courseCategoryFields.headerImageName = headerImageName
    },
    setCourseCategoryError: (state, action) => {
      const { type, message } = action.payload

      state.courseCategoryErrors[type] = message
    },
    resetCourseCategoryErrors: state => {
      state.courseCategoryErrors = {}
    },
    setCategoriesOrdered: (state, action) => {
      state.categoriesOrdered = action.payload
    },
  },
  extraReducers: builder => {
    builder
      // Get the community landing page settings
      .addCase(getCommunityLandingPageSettings.pending, state => {
        state.isLoadingPage = true
      })
      .addCase(getCommunityLandingPageSettings.fulfilled, (state, action) => {
        state.isLoadingPage = false
        const community = action.payload.community

        state.community = community
        state.settingsFields = {
          description: community.description,
          headerImageUrl: community.headerImage?.largeFileUrl,
          headerImageData: null,
          headerImageName: community.headerImage?.name,
          bookingUrl: community.bookingUrl,
          categories: community.courseCategories,
        }
      })
      // Update the community landing page settings
      .addCase(updateCommunityLandingPageSettings.pending, (state) => {
        state.isLoadingUpdate = true
      })
      .addCase(updateCommunityLandingPageSettings.fulfilled, (state, action) => {
        state.isLoadingUpdate = false

        const community = action.payload.community

        state.community = community
        state.settingsFields = {
          description: community.description,
          headerImageUrl: community.headerImage?.largeFileUrl,
          headerImageData: null,
          headerImageName: community.headerImage?.name,
          bookingUrl: community.bookingUrl,
          categories: community.courseCategories,
        }
        state.attributeErrors = null
      })
      .addCase(updateCommunityLandingPageSettings.rejected, (state, action) => {
        state.isLoadingUpdate = false
        // eslint-disable-next-line no-unused-vars
        const { general: _, ...attributeErrors } = action.payload
        state.attributeErrors = attributeErrors
      })
      // Create a Course Category
      .addCase(createCourseCategory.pending, state => {
        state.isLoadingEditCourseCategory = true
      })
      .addCase(createCourseCategory.fulfilled, (state, action) => {
        state.isLoadingEditCourseCategory = false
        if (state.community) {
          state.community.courseCategories.push(action.payload.courseCategory)
          state.settingsFields.categories.push(action.payload.courseCategory)
        }
      })
      .addCase(createCourseCategory.rejected, (state, action) => {
        state.isLoadingEditCourseCategory = false
        // eslint-disable-next-line no-unused-vars
        const { general: _, ...attributeErrors } = action.payload
        state.courseCategoryErrors = attributeErrors
      })
      // Update a Course Category
      .addCase(updateCourseCategory.pending, state => {
        state.isLoadingEditCourseCategory = true
      })
      .addCase(updateCourseCategory.fulfilled, (state, action) => {
        state.isLoadingEditCourseCategory = false

        const courseCategory = action.payload.courseCategory
        const index = state.community.courseCategories.findIndex(category => category.id === courseCategory.id)
        if (index !== -1) {
          state.community.courseCategories[index] = courseCategory
          state.settingsFields.categories[index] = courseCategory

          if (courseCategory.headerImage && isMissingImageUrl(courseCategory.headerImage.fileUrl)) {
            state.community.courseCategories[index].headerImage.largeFileUrl = null
            state.settingsFields.categories[index].headerImage.largeFileUrl = null
          }
        }
      })
      .addCase(updateCourseCategory.rejected, (state, action) => {
        state.isLoadingEditCourseCategory = false
        // eslint-disable-next-line no-unused-vars
        const { general: _, ...attributeErrors } = action.payload
        state.courseCategoryErrors = attributeErrors
      })
      // Delete a Course Category
      .addCase(deleteCourseCategory.pending, state => {
        state.isLoadingEditCourseCategory = true
      })
      .addCase(deleteCourseCategory.fulfilled, (state, action) => {
        state.isLoadingEditCourseCategory = false

        const courseCategory = action.payload.courseCategory
        const index = state.community.courseCategories.findIndex(category => category.id === courseCategory.id)
        if (index !== -1) {
          state.community.courseCategories.splice(index, 1)
          state.settingsFields.categories.splice(index, 1)
        }
      })
  },
})

export default landingPageSettingsReducer.reducer

export const {
  setField,
  setHeaderImage,
  setCourseCategory,
  setCourseCategoryField,
  setCourseCategoryHeaderImage,
  setCourseCategoryError,
  resetCourseCategoryErrors,
  setCategoriesOrdered,
} = landingPageSettingsReducer.actions

export const selectHasCategories = createSelector(
  state => state.communitySettings.landingPage.community?.courseCategories,
  categories => !_.isEmpty(categories)
)

const initialStateEditableFields = createSelector(
  state => state.communitySettings.landingPage.community,
  community => {
    if (!community) {
      return community
    }

    return {
      description: community.description,
      bookingUrl: community.bookingUrl,
      categories: community.courseCategories.map(category => ({ id: category.id, order: category.order })),
    }
  }
)

export const selectIsInitialStateModified = createSelector(
  [
    initialStateEditableFields,
    state => state.communitySettings.landingPage.community.headerImage,
    state => state.communitySettings.landingPage.settingsFields,
  ],
  (initialStateCommunityAttributes, initialStateHeaderImage, currentState) => {
    if (_.isEmpty(initialStateCommunityAttributes)) {
      return false
    }

    if (currentState.headerImageData || !currentState.headerImageUrl && initialStateHeaderImage) {
      return true
    }

    const settingsFieldsCommunityAttributes = {
      description: currentState.description,
      bookingUrl: currentState.bookingUrl,
      categories: currentState.categories.map(category => ({ id: category.id, order: category.order })),
    }

    return !_.isEqual(initialStateCommunityAttributes, settingsFieldsCommunityAttributes)
  }
)
