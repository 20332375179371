import { useState } from 'react'
import { Link } from 'react-router-dom'
import { AutoSizer, Column, Table } from 'react-virtualized'
import 'react-virtualized/styles.css'
import PropTypes from 'prop-types'

import AvatarPlaceholder from '../../../../assets/images/mock/StockProfilePic.png'
import TooltipText from '../../../common/TooltipText'
import colors from '../../../../assets/styles/globals.scss'
import RegistrationActions from './RegistrationActions'
import moment from 'moment'

import './GolfersTable.scss'
import { displayWithCurrency } from '../../../../utility/currency'
import ColumnPackageSelect from './ColumnPackageSelect'
import ColumnPaymentStatusSelect from './ColumnPaymentStatusSelect'
import Tooltip from '../../../common/Tooltip'

const GolfersTable = ({ course }) => {
  const [packagesSelectOpen, setPackagesSelectOpen] = useState(false)

  const totalFixedWidth = 1089
  const data = course.enrollments.map((e) => ({ enrollment: e }))

  const headerRenderer = ({ label, className }) => (
    <div className={className}>
      <span>{label}</span>
    </div>
  )

  const headerRowRenderer = ({ className, columns, style }) => (
    <div className={`${className} table-header`} role='row' style={style}>
      {columns}
    </div>
  )

  return (
    <div id='GolfersTable'>
      <Tooltip offset={{ top: -5 }} />

      <div className='table-wrapper'>
        <AutoSizer>
          {({ width, height }) => {
            const remainingWidth = Math.max(width, 1089) - totalFixedWidth
            return (
              <Table
                className='table-body'
                rowClassName='tr'
                height={height}
                headerHeight={50}
                width={Math.max(width, 1089)}
                rowHeight={70}
                rowCount={data.length}
                rowGetter={({ index }) => data[index]}
                headerRowRenderer={headerRowRenderer}
              >
                <Column
                  width={189 + remainingWidth * 0.2}
                  key='golfers'
                  label='Golfer'
                  headerClassName='td'
                  headerRenderer={() =>
                    headerRenderer({
                      label: 'Golfer',
                    })
                  }
                  dataKey='enrollment'
                  cellRenderer={
                    ({ cellData }) => (
                      <div className='d-flex align-items-center cell'>
                        <Link to={`/profile/${cellData.user.id}`} className='golfer-avatar-wrapper'>
                          <img
                            alt='user avatar'
                            className='w-100 h-100'
                            src={cellData.user.avatar ? cellData.user.avatar.file_url : AvatarPlaceholder}
                          />
                        </Link>
                        <div className='golfer-info'>
                          <TooltipText text={`${cellData.user.first_name} ${cellData.user.last_name}`} className='golfer-name' />
                          <TooltipText text={cellData.user.username || cellData.user.email} className='golfer-username-or-email' />
                        </div>
                      </div>
                    )
                  }
                />
                <Column
                  width={100 + remainingWidth * 0.2}
                  key='family'
                  label='Family'
                  headerClassName='td'
                  headerRenderer={() =>
                    headerRenderer({
                      label: 'Family',
                    })
                  }
                  dataKey='enrollment'
                  cellRenderer={
                    ({ cellData }) => (
                      <div className='family-column cell'>
                        <TooltipText text={cellData.user.family?.name.replace(new RegExp(' Family$'), '')} twoLinesEllipsis />
                      </div>
                    )
                  }
                />
                <Column
                  width={200 + remainingWidth * 0.28}
                  key='package'
                  label='Package'
                  headerClassName='td'
                  headerRenderer={() =>
                    headerRenderer({
                      label: 'Package',
                      className: 'package-column-header',
                    })
                  }
                  dataKey='enrollment'
                  cellRenderer={
                    ({ cellData }) => (
                      <div className='package-column cell'>
                        <ColumnPackageSelect
                          course={course}
                          enrollment={cellData}
                          menuOpen={packagesSelectOpen}
                          setMenuOpen={setPackagesSelectOpen}
                        />
                      </div>
                    )
                  }
                />
                <Column
                  width={89 + remainingWidth * 0.07}
                  key='tuition'
                  label='Tuition'
                  headerClassName='td'
                  headerRenderer={() =>
                    headerRenderer({
                      label: 'Tuition',
                      className: 'tuition-column-header',
                    })
                  }
                  dataKey='enrollment'
                  cellRenderer={
                    ({ cellData }) => (
                      <div className='tuition-column cell' style={{ color: cellData.package ? colors.darkNavy : colors.placeholderGray }}>
                        {cellData.package ? displayWithCurrency(cellData.transaction_amount, cellData.currency, true) : '--'}
                      </div>
                    )
                  }
                />
                <Column
                  width={145 + remainingWidth * 0.1}
                  key='paymentStatus'
                  label='Payment status'
                  headerClassName='td'
                  headerRenderer={() =>
                    headerRenderer({
                      label: 'Payment status',
                      className: 'payment-status-column-header',
                    })
                  }
                  dataKey='enrollment'
                  cellRenderer={
                    ({ cellData }) => (
                      <div className='payment-status-column cell'>
                        <ColumnPaymentStatusSelect
                          enrollment={cellData}
                        />
                      </div>
                    )
                  }
                />
                <Column
                  width={70 + remainingWidth * 0.06}
                  key='points'
                  label='Points'
                  headerClassName='td'
                  headerRenderer={() =>
                    headerRenderer({
                      label: 'Points',
                      className: 'points-column-header',
                    })
                  }
                  dataKey='enrollment'
                  cellRenderer={
                    ({ cellData }) => (
                      <div className='points-column cell'>{cellData.user.points}</div>
                    )
                  }
                />
                <Column
                  width={110 + remainingWidth * 0.09}
                  key='dateJoined'
                  label='Date joined'
                  headerClassName='td'
                  headerRenderer={() =>
                    headerRenderer({
                      label: 'Date joined',
                      className: 'date-joined-column-header',
                    })
                  }
                  dataKey='enrollment'
                  cellRenderer={
                    ({ cellData }) => (
                      <div
                        className='date-joined-column cell'
                        style={{ color: cellData.status === 'applied' ? colors.placeholderGray : colors.darkNavy }}
                      >
                        {cellData.status === 'applied' ? '--' : moment(cellData.created_at).format('MM/DD/YYYY')}
                      </div>
                    )
                  }
                />
                <Column
                  width={186}
                  key='actions'
                  label='Actions'
                  headerClassName='td'
                  headerRenderer={() =>
                    headerRenderer({
                      label: 'Actions',
                      className: 'actions-column-header',
                    })
                  }
                  dataKey='enrollment'
                  cellRenderer={
                    ({ cellData }) => (
                      <div className='actions-column cell'>
                        <RegistrationActions course={course} enrollment={cellData} />
                      </div>
                    )
                  }
                />
              </Table>
            )
          }}
        </AutoSizer>
      </div>
    </div>
  )
}

GolfersTable.propTypes = {
  course: PropTypes.shape({
    id: PropTypes.number,
    enrollments: PropTypes.array,
    available_packages: PropTypes.array,
    hasMore: PropTypes.bool,
  }),
}

export default GolfersTable
