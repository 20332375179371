import React from "react";
import Modal from "react-modal";
import PlayerProgress from "../reports/PlayerProgress";

const PlayerProgressModal = props => {
  return (
    <Modal
      isOpen={props.showPlayerProgressModal}
      onRequestClose={props.closePlayerProgressModal}
      contentLabel='Junior Development Model'
    >
      <div className='modal-content animated fadeIn'>
        <div>
          <button
            onClick={props.closePlayerProgressModal}
            className='confirmation-modal-close-btn btn-link'
          >
            <i className='fa fa-times confirmation-modal-close-icon' />
          </button>
          <h1>Junior Development Model (Beta)</h1>
          <div
            className='container mt-5'
            style={{ border: "1px solid #ccc", borderRadius: "10px" }}
          >
            <PlayerProgress
              user={props.user}
              title={props.title}
              studentSelected={props.studentSelected}
              division={props.division}
              fromProfile={props.fromProfile}
              handlePlayTypeChange={props.handlePlayTypeChange}
              playType={props.playType}
              loader={props.loader}
              handleGoalChange={props.handleGoalChange}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PlayerProgressModal;
