import { useSelector, useDispatch } from 'react-redux'
import { addMaterial, deleteMaterial } from '../../../../reducers/programBuilderReducer'

import FieldError from '../../../common/FieldError'
import MaterialCard from '../../../materials/MaterialCard'

const MaterialSelect = () => {
  const dispatch = useDispatch()

  const errors = useSelector(state => state.programBuilder.errors)
  const communityMaterials = useSelector(state => state.programBuilder.communityMaterials)
  const materials = useSelector(state => state.programBuilder.materials)

  const handleMaterialsChange = (material, isSelected) => {
    dispatch(isSelected ? deleteMaterial(material) : addMaterial(material))
  }

  return (
    <div id='MaterialSelect' className='mt-3'>
      {communityMaterials.map((material) => (
        <MaterialCard
          key={material.id}
          className='mb-3 font-size-14'
          material={material}
          isSelected={materials?.some(m => m.id === material.id)}
          handleCourseMaterialsChange={handleMaterialsChange}
          classEditor
        />
      ))}
      {errors.materialsIds && <FieldError message={errors.materialsIds} /> }
    </div>
  )
}

export default MaterialSelect
