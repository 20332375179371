import * as types from './actionTypes';
import * as common from './commonActions';

import Axios from '../axios';

export function successRequestingPlayTypes(response){
  if(response.status === 200){
    return { type: types.ADD_PLAY_TYPES, play_types: response.data.play_types};
  }else{
    console.log("ERROR ON PLAY TYPES")
    return {type: types.UI_ERROR, error: response.data}
  }
}

export function getPlayTypes(){
  return function(dispatch){
    dispatch(common.loadingChanged(true));
    return requestPlayTypes().then(
      response => dispatch(successRequestingPlayTypes(response))
    ).then(
      response => dispatch(common.loadingChanged(false))
    )
  }
}

function requestPlayTypes(){
  let requestUrl = '/api/v1/play_types'
  return Axios.get(requestUrl)
}
