import React from "react";

import moment from "moment";
import ReactDOM from "react-dom";
import { Line } from "react-chartjs-2";
// import ReactToPrint from "react-to-print";

import "./PlayerProgress.scss";

import defaultAvatar from "../common/avatar/images/default_profile.png";
import Loader from "../common/Op36Loader-web";
import Placeholder from "../common/Placeholder";
import StateSwitcher from "../common/StateSwitcher";

const colors = {
  d1: "#f68a21",
  d2: "#fdda12",
  d3: "#d5de27",
  d4: "#2aaae0",
  d5: "#b51d8c",
  d6: "#404042",
  d7: "#404042",
  d8: "#404042",
  d9: "#404042",
  d10: "#404042"
};

class PlayerProgress extends React.Component {
  state = {
    lineData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    holeCount: "nine",
    ageOverride: "",
    ageLoader: false,
    infoModalOpen: false
  };

  componentDidMount() {
    if (!this.props.loader) {
      this._placeIcon();
    }
    if (this.props.division && this.props.division.goal_track) {
      this._moveLine(this.props.division.goal_track);
    }
  }

  componentDidUpdate() {
    if (!this.props.loader && !this.state.ageLoader) {
      this._placeIcon();
    }
    // if (this.props.division && this.props.division.goal_track) {
    //   this._moveLine(this.props.division.goal_track);
    // }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.division &&
      nextProps.division.goal_track &&
      !this.props.division
    ) {
      this._moveLine(nextProps.division.goal_track);
    }
  }

  render() {
    return (
      <div className='container' id='PlayerProgress'>
        <div
          className={this.state.infoModalOpen ? "grey-out" : ''}
          onClick={this._toggleInfoModal}
        />
        {this.state.infoModalOpen && (
          <div className='popup' onClick={this.closeInfoModal}>
            <h4>Operation 36 Junior Golf Development Tracks</h4>
            <div className='info-modal'>
              <div className='exploratory-track-container track-container'>
                <p className='info-modal-section-title'>
                  Exploratory Track{" "}
                  <span className='orange-pill'>
                    Projected 30 + Handicap at Age 19
                  </span>
                </p>
                <p className='info-modal-section-subtitle'>
                  This track is designed to introduce the golfer and:
                </p>
                <ul>
                  <li>Allow them to explore their interest in golf</li>
                  <li>Play their first 9 holes using Operation 36 Format</li>
                </ul>
              </div>
              <div className='social-track-container track-container'>
                <p className='info-modal-section-title'>
                  Social Track{" "}
                  <span className='orange-pill'>
                    Projected 20-29 Handicap at Age 19
                  </span>
                </p>
                <p className='info-modal-section-subtitle'>
                  This track identifies golfers who are on pace or prepared to:
                </p>
                <ul>
                  <li>Play in Scramble Team Events</li>
                  <li>Continue to play in Operation 36 Events</li>
                </ul>
              </div>
              <div className='recreational-track-container track-container'>
                <p className='info-modal-section-title'>
                  Recreational Track{" "}
                  <span className='orange-pill'>
                    Projected 10-20 Handicap at Age 19
                  </span>
                </p>
                <p className='info-modal-section-subtitle'>
                  This track identifies golfers who are on pace or prepared to:
                </p>
                <ul>
                  <li>Play 9 holes Individually in Op 36 & normal formats</li>
                  <li>Play in Scramble Team Events</li>
                  <li>Participate in Drive, Chip, and Putt Events</li>
                </ul>
              </div>
              <div className='competitor-track-container track-container'>
                <p className='info-modal-section-title'>
                  Competitor Track{" "}
                  <span className='orange-pill'>
                    Projected 1-10 Handicap at Age 19
                  </span>
                </p>
                <p className='info-modal-section-subtitle'>
                  This track identifies golfers who are on pace or prepared to:
                </p>
                <ul>
                  <li>Play for Middle School and High School Teams</li>
                  <li>Play in Local Junior Golf Tournaments</li>
                  <li>Continue in Op 36 Events & PGA Junior League Teams</li>
                </ul>
              </div>
              <div className='college-track-container track-container'>
                <p className='info-modal-section-title'>
                  College Track{" "}
                  <span className='orange-pill'>
                    Projected 0 Handicap or better at Age 19
                  </span>
                </p>
                <p className='info-modal-section-subtitle'>
                  This track identifies golfers who are on pace or prepared to:
                </p>
                <ul>
                  <li>Be recruited to play at a D1-D3 College Golf Program</li>
                  <li>Play in Regional and National Junior Golf Tournaments</li>
                </ul>
              </div>
            </div>
          </div>
        )}
        {this.props.fromProfile ? null : (
          <div className='row mb-5 d-flex align-items-center'>
            <div className='col-md-4'>
              <h2>{this.props.title}</h2>
            </div>
            <div className='col-md-4 d-flex align-items-center'>
              <select
                onChange={this.props.handleStudentSelected}
                value={this.props.studentSelected}
                name='program'
                className='form-control'
                disabled={this.props.studentLoader}
              >
                <option value={-1} disabled>
                  {this.props.studentLoader
                    ? "Loading Students..."
                    : "Select Student (required)"}
                </option>
                {this.props.renderStudents()}
              </select>
            </div>
            <div className='col-md-4'>
              <button
                className='btn btn-sm btn-block'
                onClick={() => this.props.goBack()}
              >
                <i
                  className='fa fa-arrow-left text-white mr-1'
                  aria-hidden='true'
                />{" "}
                View Another Report
              </button>
            </div>
          </div>
        )}
        {this.props.studentSelected === -1 || this.props.loader ? (
          <div className='col-6 mx-auto'>
            {this.props.studentSelected === -1 ? (
              <Placeholder
                title={"Select A Student"}
                icon={"fa-address-card"}
              />
            ) : (
              <Loader message='loading player progress' />
            )}
          </div>
        ) : (
          <div>
            {this._renderInfoCard()}
            <div className='row chart-area mt-3 hidden-md-down'>
              <div className='col-12 card'>
                {this._renderGraph()}
                <div className='row'>{this._populateChart()}</div>
              </div>
            </div>
            <div className='hidden-lg-up col-6 mx-auto'>
              <Placeholder
                title={"Open on desktop to view report"}
                icon={"fa-desktop"}
              />
            </div>
          </div>
        )}
      </div>
    );
  }

  _toggleInfoModal = () => {
    this.setState({ infoModalOpen: !this.state.infoModalOpen });
  };

  _renderInfoCard = () => { // eslint-disable-line arrow-body-style
    return (
      <div className='row progress-header'>
        <div className='col-12 card'>
          <div className='row'>
            <div className='col-md-6 d-flex align-items-center'>
              <img
                src={
                  this.props.user.avatar
                    ? this.props.user.avatar.file_url
                    : defaultAvatar
                }
                alt='icon'
              />

              <div className='info-container'>
                <h2>{`${this.props.user.first_name} ${this.props.user.last_name}`}</h2>
                <p>
                  Age:{" "}
                  <span>
                    {moment().diff(moment(this.props.user.birthdate), "years")}
                  </span>{" "}
                  | Op 36 Level:{' '}
                  <span>{this.props.division.current_division}</span>
                </p>
                <div className='d-flex align-items-center'>
                  <p className='track-label'>Student Goal:</p>
                  <select
                    className='goal-label'
                    name='selectedPath'
                    // value={this.state.selectedPath}
                    value={this.props.division.goal_track || 1}
                    onChange={this._handlePathChange}
                  >
                    <option value={1} disabled>
                      Select A Development Path
                    </option>

                    <option value={"college"}>
                      College Track (Projected 0 Handicap)
                    </option>
                    <option value={"competitor"}>
                      Competitor Track (Projected 1-10 Handicap)
                    </option>
                    <option value={"recreational"}>
                      Recreational Track (Projected 10-20 Handicap)
                    </option>
                    <option value={"social"}>
                      Social Track (Projected 20-29 Handicap)
                    </option>
                    <option value={"exploratory"}>
                      Exploratory Track (Projected 30 + Handicap)
                    </option>
                  </select>
                </div>
                <div className='d-flex align-items-center'>
                  <p className='track-label'>Current Track:</p>
                  <p className='current-track-text'>
                    {this.state.ageLoader ? "" : this._getCurrentTrack()}
                  </p>
                </div>
              </div>
            </div>
            <div className='col-md-6 text-right info-card-right'>
              <h4>
                Junior Golf Development Tracks
                <i className='fa fa-trademark' aria-hidden='true' />
                <button
                  className='btn btn-link mr-2 question-button'
                  onClick={this._toggleInfoModal}
                >
                  <i className='fa fa-question' aria-hidden='true' />
                </button>
              </h4>
              {/* removed abiility to select 9 or 18 holes */}
              {/* ======================================== */}
              {/* <StateSwitcher
                options={[
                  {
                    type: "nine",
                    text: "9 Hole Play"
                  },
                  {
                    type: "eighteen",
                    text: "18 Hole Play"
                  }
                ]}
                initialTypeSelected={"nine"}
                selectedType={this.state.holeCount}
                stateSwitcher={this._holeCountToggle}
                position={"right"}
              /> */}
              {/* ======================================== */}
              <StateSwitcher
                options={[
                  { type: "all", text: "All" },
                  { type: "formal", text: "Formal" },
                  { type: "casual", text: "Casual" }
                ]}
                initialTypeSelected={this.props.playType}
                selectedType={this.props.playType}
                stateSwitcher={this._handlePlayTypeChange}
                position={"right"}
              />
              <input
                className='mt-1 age-override'
                type='number'
                placeholder='Override Age'
                value={this.state.ageOverride}
                onChange={this._overrideAge}
                onWheel={(e) => e.target.blur()}
              />
              {this._renderPaceIndicator(this.props.division.goal_track)}
            </div>
          </div>
        </div>
      </div>
    );
  };

  closeInfoModal = (e) => {
    // close the Modal only if was clicked on parent (out of the Modal)
    if (e.target === e.currentTarget){
      this._toggleInfoModal()
    }
  }

  _getCurrentTrack = () => {
    const age =
      this.state.ageOverride ||
      moment().diff(moment(this.props.user.birthdate), "years");
    const division = this.props.division.current_division;
    let resp = "";
    let col = "College Track";
    let comp = "Competitor Track";
    let rec = "Recreational Track";
    let soc = "Social Track";
    let exp = "Exploratory Track";

    switch (parseInt(age, 10)) {
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
        resp = col;
        break;
      case 8:
        if (division >= 2) {
          resp = col;
          break;
        } else {
          resp = comp;
          break;
        }
      case 9:
        if (division >= 3) {
          resp = col;
          break;
        } else {
          resp = comp;
          break;
        }
      case 10:
        if (division >= 3) {
          resp = col;
          break;
        } else if (division >= 2) {
          resp = comp;
          break;
        } else {
          resp = rec;
          break;
        }
      case 11:
        if (division >= 4) {
          resp = col;
          break;
        } else if (division >= 3) {
          resp = comp;
          break;
        } else if (division >= 2) {
          resp = rec;
          break;
        } else {
          resp = soc;
          break;
        }
      case 12:
        if (division >= 5) {
          resp = col;
          break;
        } else if (division >= 3) {
          resp = comp;
          break;
        } else if (division >= 2) {
          resp = rec;
          break;
        } else {
          resp = soc;
          break;
        }
      case 13:
        if (division >= 6) {
          resp = col;
          break;
        } else if (division >= 4) {
          resp = comp;
          break;
        } else if (division >= 3) {
          resp = rec;
          break;
        } else if (division >= 2) {
          resp = soc;
          break;
        } else {
          resp = exp;
          break;
        }
      case 14:
        if (division >= 8) {
          resp = col;
          break;
        } else if (division >= 5) {
          resp = comp;
          break;
        } else if (division >= 4) {
          resp = rec;
          break;
        } else if (division >= 3) {
          resp = soc;
          break;
        } else resp = exp;
        break;
      case 15:
        if (division >= 10) {
          resp = col;
          break;
        } else if (division >= 6) {
          resp = comp;
          break;
        } else if (division >= 5) {
          resp = rec;
          break;
        } else if (division >= 3) {
          resp = soc;
          break;
        } else {
          resp = exp;
          break;
        }
      case 16:
        if (division >= 10) {
          resp = col;
          break;
        } else if (division >= 8) {
          resp = comp;
          break;
        } else if (division >= 6) {
          resp = rec;
          break;
        } else if (division >= 4) {
          resp = soc;
          break;
        } else {
          resp = exp;
          break;
        }
      case 17:
        if (division >= 10) {
          resp = col;
          break;
        } else if (division >= 9) {
          resp = comp;
          break;
        } else if (division >= 7) {
          resp = rec;
          break;
        } else if (division >= 5) {
          resp = soc;
          break;
        } else {
          resp = exp;
          break;
        }
      default:
        resp = "";
    }

    return resp;
  };

  _handlePlayTypeChange = type => {
    if (type !== this.props.playType) {
      this.props.handlePlayTypeChange(type);
    }
  };

  _overrideAge = event => {
    // flash a loader so the chart re-renders and the previous icon disappears
    this.setState({ ageOverride: event.target.value, ageLoader: true }, () => {
      this.setState({ ageLoader: false });
    });
  };

  _holeCountToggle = type => {
    this.setState({ holeCount: type });
  };

  _handlePathChange = event => {
    let path = event.target.value;
    this.props.handleGoalChange(this.props.user.id, path);
    this._moveLine(path);
  };

  _moveLine = path => {
    if (path === "exploratory") {
      this.setState({
        lineData: [0, 0.4, 0.7, 1.9, 4.3, 6.7, 9.1, 11.5, 14.5, 18.0, 22.2, 27]
      });
    } else if (path === "social") {
      this.setState({
        lineData: [
          0,
          0.8,
          1.6,
          4.2,
          9.6,
          14.9,
          20.2,
          25.5,
          32.1,
          40.1,
          49.4,
          60
        ]
      });
    } else if (path === "recreational") {
      this.setState({
        lineData: [
          0,
          1.2,
          2.4,
          6.5,
          14.6,
          22.7,
          30.9,
          39.0,
          49.1,
          61.3,
          75.6,
          91.8
        ]
      });
    } else if (path === "competitor") {
      this.setState({
        lineData: [
          0,
          1.5,
          3.1,
          8.1,
          18.3,
          28.5,
          38.7,
          48.8,
          61.6,
          76.8,
          94.6,
          115
        ]
      });
    } else if (path === "college") {
      this.setState({
        lineData: [
          0,
          9.2,
          18.3,
          28.5,
          38.7,
          48.8,
          61.6,
          76.8,
          94.6,
          113,
          115,
          115
        ]
      });
    }
  };

  _renderPaceIndicator = path => {
    const age =
      this.state.ageOverride ||
      moment().diff(moment(this.props.user.birthdate), "years");
    const division = this.props.division.current_division;

    let text = "";
    let background = "";

    if (!age || age < 3 || age > 17) {
      return <p style={{ background: "#42ade0" }}>Override Age</p>;
    }

    if (path === "college") {
      switch (parseInt(age, 10)) {
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
          text = division >= 1 ? "On Pace" : "Behind Pace";
          background = division >= 1 ? "#65debc" : "#ec8391";
          break;
        case 8:
          text = division >= 2 ? "On Pace" : "Behind Pace";
          background = division >= 2 ? "#65debc" : "#ec8391";
          break;
        case 9:
        case 10:
          text = division >= 3 ? "On Pace" : "Behind Pace";
          background = division >= 3 ? "#65debc" : "#ec8391";
          break;
        case 11:
          text = division >= 4 ? "On Pace" : "Behind Pace";
          background = division >= 4 ? "#65debc" : "#ec8391";
          break;
        case 12:
          text = division >= 5 ? "On Pace" : "Behind Pace";
          background = division >= 5 ? "#65debc" : "#ec8391";
          break;
        case 13:
          text = division >= 6 ? "On Pace" : "Behind Pace";
          background = division >= 6 ? "#65debc" : "#ec8391";
          break;
        case 14:
          text = division >= 8 ? "On Pace" : "Behind Pace";
          background = division >= 8 ? "#65debc" : "#ec8391";
          break;
        default:
          text = division >= 10 ? "On Pace" : "Behind Pace";
          background = division >= 10 ? "#65debc" : "#ec8391";
          break;
      }
    } else if (path === "competitor") {
      switch (parseInt(age, 10)) {
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
          text = division >= 1 ? "On Pace" : "Behind Pace";
          background = division >= 1 ? "#65debc" : "#ec8391";
          break;
        case 10:
        case 11:
          text = division >= 2 ? "On Pace" : "Behind Pace";
          background = division >= 2 ? "#65debc" : "#ec8391";
          break;
        case 12:
          text = division >= 3 ? "On Pace" : "Behind Pace";
          background = division >= 3 ? "#65debc" : "#ec8391";
          break;
        case 13:
          text = division >= 4 ? "On Pace" : "Behind Pace";
          background = division >= 4 ? "#65debc" : "#ec8391";
          break;
        case 14:
          text = division >= 5 ? "On Pace" : "Behind Pace";
          background = division >= 5 ? "#65debc" : "#ec8391";
          break;
        case 15:
          text = division >= 6 ? "On Pace" : "Behind Pace";
          background = division >= 6 ? "#65debc" : "#ec8391";
          break;
        case 16:
          text = division >= 8 ? "On Pace" : "Behind Pace";
          background = division >= 8 ? "#65debc" : "#ec8391";
          break;
        default:
          text = division >= 9 ? "On Pace" : "Behind Pace";
          background = division >= 9 ? "#65debc" : "#ec8391";
          break;
      }
    } else if (path === "recreational") {
      switch (parseInt(age, 10)) {
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
          text = division >= 1 ? "On Pace" : "Behind Pace";
          background = division >= 1 ? "#65debc" : "#ec8391";
          break;
        case 11:
        case 12:
          text = division >= 2 ? "On Pace" : "Behind Pace";
          background = division >= 2 ? "#65debc" : "#ec8391";
          break;
        case 13:
          text = division >= 3 ? "On Pace" : "Behind Pace";
          background = division >= 3 ? "#65debc" : "#ec8391";
          break;
        case 14:
          text = division >= 4 ? "On Pace" : "Behind Pace";
          background = division >= 4 ? "#65debc" : "#ec8391";
          break;
        case 15:
          text = division >= 5 ? "On Pace" : "Behind Pace";
          background = division >= 5 ? "#65debc" : "#ec8391";
          break;
        case 16:
          text = division >= 6 ? "On Pace" : "Behind Pace";
          background = division >= 6 ? "#65debc" : "#ec8391";
          break;
        default:
          text = division >= 7 ? "On Pace" : "Behind Pace";
          background = division >= 7 ? "#65debc" : "#ec8391";
          break;
      }
    } else if (path === "social") {
      switch (parseInt(age, 10)) {
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
        case 11:
          text = division >= 1 ? "On Pace" : "Behind Pace";
          background = division >= 1 ? "#65debc" : "#ec8391";
          break;
        case 12:
        case 13:
          text = division >= 2 ? "On Pace" : "Behind Pace";
          background = division >= 2 ? "#65debc" : "#ec8391";
          break;
        case 14:
        case 15:
          text = division >= 3 ? "On Pace" : "Behind Pace";
          background = division >= 3 ? "#65debc" : "#ec8391";
          break;
        case 16:
          text = division >= 4 ? "On Pace" : "Behind Pace";
          background = division >= 4 ? "#65debc" : "#ec8391";
          break;
        default:
          text = division >= 5 ? "On Pace" : "Behind Pace";
          background = division >= 5 ? "#65debc" : "#ec8391";
          break;
      }
    } else if (path === "exploratory") {
      if (parseInt(age, 10) < 16) {
        text = division >= 1 ? "On Pace" : "Behind Pace";
        background = division >= 1 ? "#65debc" : "#ec8391";
      } else {
        text = division >= 2 ? "On Pace" : "Behind Pace";
        background = division >= 2 ? "#65debc" : "#ec8391";
      }
    } else {
      background = "#42ade0";
      text = "Select a Path";
    }
    return <p style={{ background: background }}>{text}</p>;
  };

  _placeIcon = () => {
    let icon = this._renderIcon();
    let age =
      this.state.ageOverride ||
      moment().diff(moment(this.props.user.birthdate), "years");
    let division = this.props.division.current_division;
    let xAxisData = 0;

    if (age) {
      if (age < 3 || age > 17) {
        return false;
      } else if (age < 7) {
        xAxisData = 0;
      } else {
        xAxisData = age - 6;
      }

      let id = `${xAxisData}-${division}`;

      let dataPoint = document.getElementById(id);

      ReactDOM.render(icon, dataPoint);
    } else {
      return false;
    }
  };

  _renderIcon = () => {
    return (
      <div className='icon-wrapper'>
        <img
          style={{
            height: "100%",
            width: "100%",
            borderRadius: "50%"
            // overflowX: "show",
            // overflowY: "show"
          }}
          src={
            this.props.user.avatar
              ? this.props.user.avatar.file_url
              : defaultAvatar
          }
          alt='icon'
        />
      </div>
    );
  };

  _renderMilestones = division => {
    const divisions = this.props.division.divisions;

    let is50 = false;
    let is45 = false;
    let is39 = false;
    let is36 = false;

    if (divisions) {
      let record =
        this.state.holeCount === "nine"
          ? divisions[division - 1].nine_hole.record_score
          : divisions[division - 1].eighteen_hole.record_score;

      if (record && this.state.holeCount === "nine") {
        if (record <= 36) {
          is36 = true;
        }
        if (record <= 39) {
          is39 = true;
        }
        if (record <= 45) {
          is45 = true;
        }
        if (record <= 50) {
          is50 = true;
        }
      }
      if (record && this.state.holeCount === "eighteen") {
        if (record <= 72) {
          is36 = true;
        }
        if (record <= 78) {
          is39 = true;
        }
        if (record <= 90) {
          is45 = true;
        }
        if (record <= 100) {
          is50 = true;
        }
      }
    }
    return (
      <div className='row h-100'>
        <div className='col-3 milestone-box'>
          <div
            className='milestone-circle'
            style={{
              background: is50 ? colors[`d${division}`] : "",
              border: is50
                ? "2px solid " +
                  colors[`d${division > 5 ? division - 5 : division}`]
                : "",
              textShadow: is50 ? "0 0 1px #111" : ""
            }}
          >
            <p style={{ color: is50 ? "#fff" : "" }}>
              {this.state.holeCount === "nine" ? 50 : 100}
            </p>
          </div>
        </div>
        <div className='col-3 milestone-box'>
          <div
            className='milestone-circle'
            style={{
              background: is45 ? colors[`d${division}`] : "",
              border: is45
                ? "2px solid " +
                  colors[`d${division > 5 ? division - 5 : division}`]
                : "",
              textShadow: is45 ? "0 0 1px #111" : ""
            }}
          >
            <p style={{ color: is45 ? "#fff" : "" }}>
              {this.state.holeCount === "nine" ? 45 : 90}
            </p>
          </div>
        </div>
        <div className='col-3 milestone-box'>
          <div
            className='milestone-circle'
            style={{
              background: is39 ? colors[`d${division}`] : "",
              border: is39
                ? "2px solid " +
                  colors[`d${division > 5 ? division - 5 : division}`]
                : "",
              textShadow: is39 ? "0 0 1px #111" : ""
            }}
          >
            <p style={{ color: is39 ? "#fff" : "" }}>
              {this.state.holeCount === "nine" ? 39 : 78}
            </p>
          </div>
        </div>
        <div className='col-3 milestone-box'>
          <div
            className='milestone-circle'
            style={{
              background: is36 ? colors[`d${division}`] : "",
              border: is36
                ? "2px solid " +
                  colors[`d${division > 5 ? division - 5 : division}`]
                : "",
              textShadow: is36 ? "0 0 1px #111" : ""
            }}
          >
            <p style={{ color: is36 ? "#fff" : "" }}>
              {this.state.holeCount === "nine" ? 36 : 72}
            </p>
          </div>
        </div>
      </div>
    );
  };

  _renderGraph = () => {
    const data = {
      labels: ["", "", "", "", "", "", "", "", "", "", "", ""],
      datasets: [
        {
          // doesn't display - used to set the visual parameters of the graph.
          label: "standard",
          fill: false,
          lineTension: 0.5,
          backgroundColor: "rgba(0,0,0,0)",
          borderColor: "rgba(0,0,0,0)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(75,192,192,0)",
          pointBackgroundColor: "rgba(0,0,0,0)",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,0)",
          pointHoverBorderColor: "rgba(220,220,220,0)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110]
        },
        {
          label: "line1",
          fill: false,
          lineTension: 0.2,
          backgroundColor: "white",
          borderColor: "white",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(75,192,192,0)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,0)",
          pointHoverBorderColor: "rgba(220,220,220,0)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: this.state.lineData
        }
      ]
    };

    return (
      <div className='graph'>
        <Line
          data={data}
          options={{
            plugins: {
              legend: {
                display: false
              },
              tooltip: {
                callbacks: {
                  label: function() {
                    return "Development Path";
                  }
                }
              },
            },
            hover: { intersect: false },
            maintainAspectRatio: false,
            scales: {
              x: { grid: { display: false } },
              y: {
                ticks: { display: false },
                grid: { display: false }
              }
            }
          }}
        />
      </div>
    );
  };

  _renderLabel = division => {
    const { holeCount } = this.state;
    const isNine = holeCount === "nine";
    switch (division) {
      case 1:
        return isNine ? "225 Yards" : "450 Yards";
      case 2:
        return isNine ? "450 Yards" : "900 Yards";
      case 3:
        return isNine ? "900 Yards" : "1800 Yards";
      case 4:
        return isNine ? "1350 Yards" : "2700 Yards";
      case 5:
        return isNine ? "1800 Yards" : "3600 Yards";
      case 6:
        return isNine ? "1801 - 2300 Yards" : "3601 - 4600 Yards";
      case 7:
        return isNine ? "2301 - 2600 Yards" : "4601 - 5200 Yards";
      case 8:
        return isNine ? "2601 - 2900 Yards" : "5201 - 5800 Yards";
      case 9:
        return isNine ? "2901 - 3200 Yards" : "5801 - 6400 Yards";
      case 10:
        return isNine ? "3201 + Yards" : "6401 + Yards";
      default:
        return "";
    }
  };

  _populateChart = () => { //eslint-disable-line max-lines-per-function
    let col = [];
    for (let i = 0; i < 11; i++) {
      if (i < 10) {
        col.push(
          <div className='col-12' key={i}>
            <div className='row top-row'>
              <div className='col-3 y-axis'>
                <div className='row'>
                  <div className='col-md-6'>
                    <p className='division-title text-truncate'>{`Level ${10 -
                      i}`}</p>
                    <p className='division-title-label text-truncate'>
                      {this._renderLabel(10 - i)}
                    </p>
                  </div>
                  <div className='col-md-6 hidden-md-down'>
                    {this._renderMilestones(10 - i)}
                  </div>
                </div>
              </div>
              <div className='col-9'>
                <div className='row data-row'>
                  <div
                    className='col-1 text-center data-area right-line'
                    id={`0-${10 - i}`}
                    style={{ background: "#61dab3" }}
                  />
                  <div
                    className='col-1 text-center data-area right-line'
                    id={`1-${10 - i}`}
                    style={{ background: "#43ace0" }}
                  />
                  <div
                    className='col-1 text-center data-area right-line'
                    id={`2-${10 - i}`}
                    style={{ background: "#43ace0" }}
                  />
                  <div
                    className='col-1 text-center data-area right-line'
                    id={`3-${10 - i}`}
                    style={{ background: "#43ace0" }}
                  />
                  <div
                    className='col-1 text-center data-area right-line'
                    id={`4-${10 - i}`}
                    style={{ background: "#43ace0" }}
                  />
                  <div
                    className='col-1 text-center data-area right-line'
                    id={`5-${10 - i}`}
                    style={{ background: "#6677de" }}
                  />
                  <div
                    className='col-1 text-center data-area right-line'
                    id={`6-${10 - i}`}
                    style={{ background: "#6677de" }}
                  />
                  <div
                    className='col-1 text-center data-area right-line'
                    id={`7-${10 - i}`}
                    style={{ background: "#6677de" }}
                  />
                  <div
                    className='col-1 text-center data-area right-line'
                    id={`8-${10 - i}`}
                    style={{ background: "#6677de" }}
                  />
                  <div
                    className='col-1 text-center data-area right-line'
                    id={`9-${10 - i}`}
                    style={{ background: "#2b3843" }}
                  />
                  <div
                    className='col-1 text-center data-area right-line'
                    id={`10-${10 - i}`}
                    style={{ background: "#2b3843" }}
                  />
                  <div
                    className='col-1 text-center data-area'
                    id={`11-${10 - i}`}
                    style={{ background: "#2b3843" }}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        col.push(
          <div className='col-12' key={10}>
            <div className='row'>
              <div className='col-3 y-axis'>
                <p className='division-title text-truncate'>
                  No Progress Tracking
                </p>
                <p className='division-title-label text-truncate'>
                  Unstructured Play | Fundamental Movement | Love the Course!
                </p>
              </div>
              <div className='col-9'>
                <div className='row data-row no-prog-tracking-row'>
                  <div
                    className='col-1 text-center data-area right-line'
                    id='0-0'
                    style={{ background: "#61dab3" }}
                  />
                  <div
                    className='col-1 text-center data-area right-line'
                    id='1-0'
                    style={{ background: "#43ace0" }}
                  />
                  <div
                    className='col-1 text-center data-area right-line'
                    id='2-0'
                    style={{ background: "#43ace0" }}
                  />
                  <div
                    className='col-1 text-center data-area right-line'
                    id='3-0'
                    style={{ background: "#43ace0" }}
                  />
                  <div
                    className='col-1 text-center data-area right-line'
                    id='4-0'
                    style={{ background: "#43ace0" }}
                  />
                  <div
                    className='col-1 text-center data-area right-line'
                    id='5-0'
                    style={{ background: "#6677de" }}
                  />
                  <div
                    className='col-1 text-center data-area right-line'
                    id='6-0'
                    style={{ background: "#6677de" }}
                  />
                  <div
                    className='col-1 text-center data-area right-line'
                    id='7-0'
                    style={{ background: "#6677de" }}
                  />
                  <div
                    className='col-1 text-center data-area right-line'
                    id='8-0'
                    style={{ background: "#6677de" }}
                  />
                  <div
                    className='col-1 text-center data-area right-line'
                    id='9-0'
                    style={{ background: "#2b3843" }}
                  />
                  <div
                    className='col-1 text-center data-area right-line'
                    id='10-0'
                    style={{ background: "#2b3843" }}
                  />
                  <div
                    className='col-1 text-center data-area'
                    id='11-0'
                    style={{ background: "#2b3843" }}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
    col.push(
      <div className='col-12' key={11}>
        <div className='row'>
          <div className='col-3 y-axis' />
          <div className='col-9'>
            <div className='row x-axis'>
              <div className='col-1'>
                <p className='text-center text-truncate'>3 - 6</p>
              </div>
              <div className='col-1'>
                <p className='text-center'>7</p>
              </div>
              <div className='col-1'>
                <p className='text-center'>8</p>
              </div>
              <div className='col-1'>
                <p className='text-center'>9</p>
              </div>
              <div className='col-1'>
                <p className='text-center text-truncate'>10</p>
              </div>
              <div className='col-1'>
                <p className='text-center text-truncate'>11</p>
              </div>
              <div className='col-1'>
                <p className='text-center text-truncate'>12</p>
              </div>
              <div className='col-1'>
                <p className='text-center text-truncate'>13</p>
              </div>
              <div className='col-1'>
                <p className='text-center text-truncate'>14</p>
              </div>
              <div className='col-1'>
                <p className='text-center text-truncate'>15</p>
              </div>
              <div className='col-1'>
                <p className='text-center text-truncate'>16</p>
              </div>
              <div className='col-1'>
                <p className='text-center text-truncate'>17</p>
              </div>
            </div>

            <div className='col-2 ml-auto mr-auto'>
              <h5>AGES</h5>
            </div>
          </div>
        </div>
      </div>
    );

    if (this.state.ageLoader) {
      return (
        <div className='col-6 mx-auto'>
          <Loader message='updating age' />
        </div>
      );
    } else {
      return col;
    }

    // return col;
  };
}

export default PlayerProgress;
