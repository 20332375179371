import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Loader from "../common/Op36Loader";
import SearchInput from '../common/SearchInput'

import * as commonActions from "../../actions/commonActions";
import * as communitiesActions from "../../actions/communitiesActions";
import * as userActions from "../../actions/userActions";

import StudentCard from "../common/StudentCard";

import "../../assets/styles/components/students/StudentsPage.scss";
import Placeholder from "../common/Placeholder";
import Pagination from "../common/Pagination";

// const KEYS_TO_FILTERS = ["first_name", "last_name", "username"];

class StudentsPage extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      in: false,
      studentsLoader: false,
      searchTerm: "",
      perPage: 20,
      // studentDivision: []
    };
  }
  componentDidMount() {
    const { common_actions } = this.props;
    const { perPage } = this.state;
    common_actions.showSideBar(true);
    common_actions.showNavBar(true);
    this.setState({
      studentsLoader: true,
    });
    this.props.communities_actions
      .getAllStudents(this.props.user.communities[0].id, 1, perPage)
      .then(() => {
        this.setState({
          studentsLoader: false,
        });
      });
  }

  render() {
    const { pages } = this.props;
    const { searchTerm } = this.state;
    return (
      <div id='StudentsPage'>
        <div className='animated fadeIn'>
          <div className='students-search-bar mt-2'>
            <SearchInput placeholder='Search Students' onChange={this._searchUpdated}/>
          </div>
          <div className='row'>
            {searchTerm === "" && pages > 1 && (
              <Pagination pageCount={pages} fetchData={this._fetchStudents} />
            )}
          </div>
          <div className='row'>{this._renderStudents()}</div>
        </div>
      </div>
    );
  }

  _searchUpdated = (e) => {
    const term = e.target.value
    if (!term) {
      this.setState({ searchTerm: "" }, () => {
        this._fetchStudents(1);
      });
    } else {
      this.setState({ searchTerm: term }, () => {
        const { searchTerm } = this.state;
        this._fetchStudents(null, searchTerm);
      });
    }
  };

  _fetchStudents = (page, searchTerm) => {
    if (searchTerm && searchTerm.length < 3) {
      return
    }
    const { perPage } = this.state;
    const { communities_actions, user } = this.props;

    this.setState(
      {
        studentsLoader: true,
      },
      () => {
        communities_actions
          .getAllStudents(user.communities[0].id, page, perPage, searchTerm)
          .then(() => {
            this.setState({
              studentsLoader: false,
            });
          });
      }
    );
  };

  _renderStudents() {
    const { community_students } = this.props;
    const { studentsLoader } = this.state;
    if (studentsLoader) {
      return (
        <div className='' style={{ marginTop: "50px", width: "100%" }}>
          <Loader message='loading students' />
        </div>
      );
    } else if (community_students && community_students.length > 0) {
      return community_students.map((student, index) => {
        return (
          <StudentCard
            student={student}
            key={student.id}
          />
        );
      });
      // <StudentTable
      //   students={this.props.community_students}
      // />
    }
    return (
      <div className='mx-auto'>
        <Placeholder
          title={"No Students In Your Community"}
          icon={"fa-group"}
          subtext1={
            "Get your families to start adding golfers to get started adding actions for them"
          }
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    ui: state.ui,
    user: state.user.current_user,
    community_students: state.communityStudents,
    pages: state.pages,
    user_division: state.userDivision,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    common_actions: bindActionCreators(commonActions, dispatch),
    communities_actions: bindActionCreators(communitiesActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentsPage);
