import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import classNames from 'classnames'

import classes from './SettingsForm.module.scss'
import { deleteUser } from '../../../actions/userActions'
import { signOutUser } from '../../../actions/authActions'
import SubmitButton from '../../common/buttons/SubmitButton'
import DeleteAccountModal from './DeleteAccountModal'
import { AccountContext } from '../AccountContext'
import { useContext } from 'react'

const SettingsForm = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user, submitDisabled } = useContext(AccountContext) // Note: user could be golfer OR current_user
  const currentUser = useSelector(state => state.user.current_user)

  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')

  const handleDeleteAccount = () => {
    dispatch(deleteUser(user.id, password))
      .then(() => {
        if (currentUser.id === user.id) {
          dispatch(signOutUser())
          navigate('/signin')
        } else {
          navigate('/family')
        }
      })
      .catch((error) => {
        if (error.response && error.response.data?.errors?.current_password) {
          setPasswordError('The password is incorrect. Make sure you are typing the password of the student you want to delete.')
        } else {
          setPasswordError('Something went wrong. Please try again.')
        }
      })
  }

  const renderResetPasswordSection = () => {
    if (user.type === 'Coach') {
      return (
        <div>
          <p>If you would like to delete your account and data, please send us a Data Deletion Request using the button below.</p>
          <p>In your message, <span className='font-weight-bold'>please include the email or username you use</span> to log into the Op 36 app.
            If you are a family admin and want to delete other family members&apos; accounts,
          <span className='font-weight-bold'> please include their emails or usernames as well</span>.
          </p>
          <a className='btn btn-primary text-white' href='mailto:support@op36golf.com'>Contact us</a>
        </div>
      )
    }

    if (currentUser.family_admin) {
      return (
        <div className='d-flex flex-column'>
          <p className={classes.deleteMessage}>Are you sure you want to delete the account and its data?</p>

          <SubmitButton
            onClick={(e) => { e.preventDefault(); setShowDeleteAccountModal(true) }}
            buttonMessage='Delete Account'
            buttonColor='orange-button'
            className={classNames(classes.deleteButton, 'mt-auto')}
            disabled={submitDisabled}
          />

          <DeleteAccountModal
            showModal={showDeleteAccountModal}
            closeModal={() => { setPassword(''); setPasswordError(''); setShowDeleteAccountModal(false) }}
            password={password}
            passwordError={passwordError}
            handlePasswordChange={(e) => { setPassword(e.target.value) }}
            handleDeleteAccount={handleDeleteAccount}
          />
        </div>
      )
    }

    return <p className={classes.deleteMessage}>Only Family Admins can Delete Account. Please ask your parent to do it for you.</p>
  }

  return (
    <div className={classNames(classes.accountSettings, 'animated fadeIn d-flex flex-column')}>
      <div className='row'>
        <h4 className={classNames(classes.title, 'col-md-12 p-0')}>Account Settings</h4>
        <p className={classNames(classes.deleteSubtitle, 'p-0 pb-2')}>Delete Account and Data</p>
      </div>
      <div className='row p-0'>
        {renderResetPasswordSection()}
      </div>
    </div>
  )
}

export default SettingsForm
