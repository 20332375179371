import React from 'react'
import PropTypes from 'prop-types'
import profile_avatar from '../../../assets/images/mock/StockProfilePic.png'
import lp_icon from '../../../assets/images/common/icons/lesson_plans/lesson-plan-icon.png'

import './CoachClassCard.scss'
import { withRouting } from '../../common/hooks'

class CoachClassCard extends React.Component {
  constructor(props, context) {
    super(props, context)
    let newStudents = 0
    let activeStudents = 0

    for (let i = 0; i < this.props.course.enrollments.length; i++) {
      const enrollment = this.props.course.enrollments[i]
      switch (enrollment['status']) {
        case 'active':
          activeStudents++
          break
        case 'completed':
          activeStudents++
          break
        case 'applied':
          newStudents++
          break
        default:
          break
      }
    }

    this.state = {
      newStudents,
      activeStudents,
      showConfirmModal: false,
    }
  }


  showDraftStatus(course) {
    if (course.is_draft) {
      return (
        <div className='draft-status'>
          <p>Draft</p>
          <i className={'fa fa-sm fa-pencil-square-o'} aria-hidden='true' />
        </div>
      )
    }
  }

  showPrivacyStatus(course) {
    const type = course.visibility === 'private' ? 'lock' : 'unlock'
    const privacy = course.visibility.charAt(0).toUpperCase() + course.visibility.slice(1)
    return (
      <div className='privacy-status'>
        <p>{privacy}</p>
        <i className={`fa fa-sm fa-${type}`} aria-hidden='true' />
      </div>
    )
  }

  displayEnrollments = activeEnrollments => activeEnrollments.slice(0, 8).map(enrollment => (
    <div className='enrollment-avatar-wrapper' key={enrollment.id}>
      <img
        src={
          enrollment.user.avatar
            ? enrollment.user.avatar.file_url
            : profile_avatar
        }
        alt={`${enrollment.first_name} ${enrollment.last_name}`}
      />
    </div>
  ))

  renderEnrollments = () => {
    const {
      course: { enrollments },
    } = this.props

    let extraStudents = 0

    if (enrollments && enrollments.length > 0) {
      const activeEnrollments = enrollments.filter(
        enrollment => enrollment.status === 'active'
      )
      if (activeEnrollments.length > 8) {
        extraStudents = activeEnrollments.length - 8
      }
      return (
        <div className='class-card-enrollments-container'>
          {this.displayEnrollments(activeEnrollments)}
          <p>{extraStudents > 0 ? `+${extraStudents} more` : ''}</p>
        </div>
      )
    }
    return (
      <div className='class-card-enrollments-container'>
        <p>No enrollments listed</p>
      </div>
    )
  }

  renderPhoto = () => {
    const { course } = this.props

    if (course && course.lesson_plan) {
      if (course.lesson_plan.photo) {
        return (
          <div className='class-card-photo-wrapper'>
            {/* <img src={course.lesson_plan.photo.file_url} alt={course.name} /> */}
            <div
              className='icon-image'
              style={{
                backgroundImage: `url(${course.lesson_plan.photo.file_url})`,
              }}
            />
          </div>
        )
      } else if (course.lesson_plan.template_photo_url) {
        return (
          <div className='class-card-photo-wrapper'>
            {/* <img
              src={course.lesson_plan.template_photo_url}
              alt={course.name}
            /> */}
            <div
              className='icon-image'
              style={{
                backgroundImage: `url(${course.lesson_plan.template_photo_url})`,
              }}
            />
          </div>
        )
      } else {
        return (
          <div className='class-card-photo-wrapper class-card-no-photo'>
            <div
              className='lp-icon'
              style={{ backgroundImage: `url(${lp_icon})` }}
            />
          </div>
        )
      }
    }
    // if (course && course.photo_url) {
    //   return (
    //     <div className='class-card-photo-wrapper'>
    //       <img src={course.photo_url} alt={course.name} />
    //     </div>
    //   );
    // } else if (course && course.photo) {
    //   return (
    //     <div className='class-card-photo-wrapper'>
    //       <img src={course.photo.file_url} alt={course.name} />
    //     </div>
    //   );
    // } else {
    //   return (
    //     <div className='class-card-photo-wrapper class-card-no-photo'>
    //       <i className='fa fa-flag' />
    //     </div>
    //   );
    // }
  }

  render() {
    const { course, navigate, isStudentProfile, current_user } = this.props
    const { newStudents } = this.state

    return (
      <div
        key={course.id}
        id='CoachClassCard'
        className={`${
          isStudentProfile && current_user.type === 'Student'
            ? ''
            : 'animation-shrink'
        } animated fadeIn`}
      >
        {newStudents > 0 && !isStudentProfile && (
          <div className='class-card-notification'>
            <p>{newStudents}</p>
          </div>
        )}

        <div
          aria-hidden='true'
          className={`${
            this.props.index + 1 > 3 ? 'card mt-15px' : 'card'
          } ${isStudentProfile &&
            current_user.type === 'Student' &&
            'disabled-card'}`}
          onClick={
            isStudentProfile && current_user.type === 'Student'
              ? e => e.preventDefault()
              : () =>
                navigate(
                  `/programs/${course.id}`,
                  {
                    state: {
                      courseId: course.id,
                      // router: router // TODO: Please remove this somehow
                    },
                  }
                )
          }
        >
          <div
            className='class-card-header'
            style={course.header_image ? { backgroundImage: `url(${course.header_image.large_file_url})` } : null}
          >
            <div className='image-gradient' />
          </div>
          {this.showDraftStatus(course)}
          {this.showPrivacyStatus(course)}
          {/* {course.lesson_plan && (
            <div
              className='lp-indicator'
              style={{ backgroundImage: `url(${lp_icon})` }}
            />
          )} */}
          <div className='class-card-info-wrapper'>
            {this.renderPhoto()}
            <div>
              <h6>{course.name}</h6>
              <p>
                {course.meeting_times && course.meeting_times !== 'null'
                  ? course.meeting_times
                  : 'No meeting times listed'}
              </p>
            </div>
          </div>
          {!isStudentProfile && this.renderEnrollments()}
        </div>
      </div>
    )
  }
}

CoachClassCard.propTypes = {
  course: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    meeting_times: PropTypes.string,
    enrollments: PropTypes.arrayOf(PropTypes.shape({
      status: PropTypes.string,
    })),
    lesson_plan: PropTypes.shape({
      photo: PropTypes.shape({
        file_url: PropTypes.string,
      }),
      template_photo_url: PropTypes.string,
    }),
    header_image: PropTypes.shape({
      large_file_url: PropTypes.string,
    }),
  }),
  current_user: PropTypes.shape({
    type: PropTypes.string,
  }),
  navigate: PropTypes.func,
  isStudentProfile: PropTypes.bool,
  index: PropTypes.number,
}

export default withRouting(CoachClassCard)
