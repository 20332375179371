import React, { Component } from "react";
import ReactTable from "react-table";
import createTable from "react-table-hoc-fixed-columns";
// import "react-table/react-table.css";

import CSVExportModal from "./CSVExportModal";
import RoundsReportPrint from "./RoundsReportPrint";
import Placeholder from "../common/Placeholder";

const ReactTableFixedColumns = createTable(ReactTable);

const HEADERS = [
  { label: 'Levels', key: 'divisions' },
  { label: "Yardage", key: "yardage" },
  { label: 'Level Attempts', key: 'divisionattempts' },
  { label: "Journey Status", key: "journeystatus" },
  { label: "Baseline Score", key: "baselinescore" },
  { label: "Record Score", key: "recordscore" },
  { label: "Strokes Lost", key: "strokeslost" }
];

class StudentRoundsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      dataLoader: false,
      data: []
    };
  }

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  createData = () => {
    const { division } = this.props;
    let tempData = [];
    this.setState({ dataLoader: true });
    if (division.divisions.length > 0) {
      let div = division.divisions;
      div.map(info => {
        let completed = false;
        let message = "---";

        if (info.nine_hole.record_score) {
          if (info.nine_hole.record_score <= 36) {
            completed = true;
          } else {
            if (info.nine_hole.division_attempts <= 20) {
              message = "Ahead of Pace";
            } else {
              message = "On Pace";
            }
          }
        }

        const divisions = `Level ${info.division_id}`
        let yardage = this.renderYardage({ value: info.division_id });
        let divisionattempts = info.nine_hole.division_attempts
          ? info.nine_hole.division_attempts
          : "---";
        let journeystatus = completed ? "✅" : message;
        let baselinescore = info.nine_hole.first_score
          ? info.nine_hole.first_score
          : "---";
        let recordscore = info.nine_hole.record_score
          ? info.nine_hole.record_score
          : "---";
        let strokeslost =
          info.nine_hole.first_score && info.nine_hole.record_score
            ? baselinescore - recordscore
            : "---";

        tempData.push({
          divisions,
          yardage,
          divisionattempts,
          journeystatus,
          baselinescore,
          recordscore,
          strokeslost
        });
      });
    }

    this.setState({
      showModal: !this.state.showModal,
      data: tempData,
    });
  };

  renderYardage = props => {
    switch (props.value) {
      case 1:
        return "225";
      case 2:
        return "450";
      case 3:
        return "900";
      case 4:
        return "1350";
      case 5:
        return "1800";
      case 6:
        return "1801 - 2300";
      case 7:
        return "2301 - 2600";
      case 8:
        return "2601 - 2900";
      case 9:
        return "2901 - 3200";
      case 10:
        return "3201 +";
      default:
        return "";
    }
  };

  renderDivisionCell = props => (
    <div className='number'>
      <div style={{ color: '#2b3843' }}>{'Level ' + props.value}</div>
    </div>
  )

  renderTableCell = props => (
    <div className='number'>
      <div style={{ color: '#2b3843' }}>{this.renderYardage(props)}</div>
    </div>
  )
  renderDivisionAttemptsCell = props => (
    <div className='number' style={{ backgroundColor: '#2b3843' }}>
      <div style={{ color: 'white' }}>
        {props.value ? props.value : '---'}
      </div>
    </div>
  )
  renderStatusMessage = (attempts, record) => {
    let completed = false;

    if (record) {
      if (record <= 36) {
        completed = true;
      }
    }

    if (completed) {
      return <i className='fa fa-check' />;
    }
    if (attempts >= 1 && attempts <= 20) {
      return "Ahead of Pace";
    } else if (attempts > 20) {
      return "On Pace";
    } else {
      return null;
    }
  };
  renderStatusColor = (attempts, record) => {
    let completed = false;

    if (record) {
      if (record <= 36) {
        completed = true;
      }
    }

    if (completed) {
      return '#ff6633'
    }
    if (attempts >= 1 && attempts <= 20) {
      return "#33d9b2";
    } else if (attempts > 20) {
      return "#FFFF00";
    } else {
      return "white";
    }
  };
  renderStatusCell = props => (
    <div className='number' style={{ backgroundColor: '#2b3843' }}>
      <div
        style={{
          color: props.value.division_attempts
            ? this.renderStatusColor(
                props.value.division_attempts,
                props.value.record_score
              )
            : 'white',
        }}
      >
        {props.value.division_attempts
          ? this.renderStatusMessage(
            props.value.division_attempts,
            props.value.record_score
          )
          : '---'}
      </div>
    </div>
  )
  renderDivisionColor = props => {
    switch (props.value) {
      case 1:
        return '#ff6633'
      case 2:
        return "#FDDB10";
      case 3:
        return "#D5DE27";
      case 4:
        return "#2BAAE1";
      case 5:
        return "#B51E8C";
      default:
        return "#414143";
    }
  };
  renderColorBorder = props => {
    switch (props.value) {
      case 6:
        return '4px solid #FF6633'
      case 7:
        return "4px solid #FDDB10";
      case 8:
        return "4px solid #D5DE27";
      case 9:
        return "4px solid #2BAAE1";
      case 10:
        return "4px solid #B51E8C";
      default:
        return "";
    }
  };
  renderDivisionColorCell = props => (
    <div className='number'>
      <div
        style={{
          height: 25,
          width: 25,
          backgroundColor: this.renderDivisionColor(props),
          borderRadius: '50%',
          border: this.renderColorBorder(props),
        }}
      />
    </div>
  )
  renderDivisionBaselineCell = props => (
    <div className='number' style={{ backgroundColor: '#2b3843' }}>
      <div style={{ color: 'white' }}>
        {props.value ? props.value : '---'}
      </div>
    </div>
  )
  renderDivisionRecordCell = props => (
    <div className='number' style={{ backgroundColor: '#2b3843' }}>
      <div style={{ color: 'white' }}>
        {props.value ? props.value : '---'}
      </div>
    </div>
  )

  renderStrokesLostCell = props => {
    let firstScore = props.value.first_score ? props.value.first_score : 0;
    let recordScore = props.value.record_score ? props.value.record_score : 0;
    let strokesLost = firstScore - recordScore;

    return (
      <div className='number' style={{ backgroundColor: '#2b3843' }}>
        <div style={{ color: "white" }}>
          {props.value.first_score ? strokesLost : "---"}
        </div>
      </div>
    );
  };
  renderTable = () => {
    const { loader, division } = this.props;
    const columns = [
      {
        Header: "",
        accessor: "division_id",
        Cell: props => this.renderDivisionColorCell(props),
        sortable: false
      },
      {
        Header: 'Levels',
        accessor: "division_id",
        Cell: props => this.renderDivisionCell(props),
        sortable: false
      },
      {
        Header: "Yardage",
        accessor: "division_id",
        Cell: props => this.renderTableCell(props),
        sortable: false
      },
      {
        Header: 'Level Attempts',
        accessor: "nine_hole.division_attempts",
        Cell: props => this.renderDivisionAttemptsCell(props),
        style: { padding: 0 },
        sortable: false
      },
      {
        Header: "Journey Status",
        accessor: "nine_hole",
        Cell: props => this.renderStatusCell(props),
        style: { padding: 0 },
        sortable: false
      },
      {
        Header: "Baseline Score",
        accessor: "nine_hole.first_score",
        Cell: props => this.renderDivisionBaselineCell(props),
        style: { padding: 0 },
        sortable: false
      },
      {
        Header: "Record Score",
        accessor: "nine_hole.record_score",
        Cell: props => this.renderDivisionRecordCell(props),
        style: { padding: 0 },
        sortable: false
      },
      {
        Header: "Strokes Lost",
        accessor: "nine_hole",
        Cell: props => this.renderStrokesLostCell(props),
        style: { padding: 0 },
        sortable: false
      }
    ];

    return (
      <div className='container my-5 animated fadeIn'>
        <div className='row mb-5 d-flex align-items-center'>
          <div className='col-md-4'>
            <h2>{this.props.title}</h2>
          </div>
          <div className='col-md-4 d-flex align-items-center'>
            <select
              onChange={this.props.handleStudentSelected}
              value={this.props.studentSelected}
              name='program'
              className='form-control'
              disabled={this.props.studentLoader}
            >
              <option value={-1} disabled>
                {this.props.studentLoader
                  ? "Loading Students..."
                  : "Select Student (required)"}
              </option>
              {this.props.renderStudents()}
            </select>
          </div>
          <div className='col-md-4'>
            <button
              className='btn btn-sm btn-block'
              onClick={() => this.props.goBack()}
            >
              <i
                className='fa fa-arrow-left text-white mr-1'
                aria-hidden='true'
              />{" "}
              View Another Report
            </button>
          </div>
        </div>
        <div className='row'>
          {this.props.studentSelected !== -1 ? (
            <div className='col-12'>
              <ReactTableFixedColumns
                data={!loader ? division.divisions : []}
                columns={columns}
                showPaginationBottom={false}
                showPageSizeOptions={false}
                defaultPageSize={10}
                loading={this.props.loader}
                loadingText='Loading Report'
                noDataText={
                  this.props.studentSelected === -1
                    ? "Choose a student to see rounds comparison"
                    : "No data found"
                }
              />
            </div>
          ) : (
            <div className='col-6 mx-auto'>
              <Placeholder
                title='select a student to view report'
                icon='fa-address-book'
              />
            </div>
          )}
        </div>
        {this.props.studentSelected !== -1 ? (
          <div className='row mt-3'>
            <div className='col-12'>
              <button
                className='btn btn-primary mr-3 mt-3'
                onClick={this.props.showReportModal}
              >
                <i className='fa fa-print text-white mr-1' aria-hidden='true' />{" "}
                Print Overview
              </button>
              <button
                onClick={this.createData}
                className='mt-3 btn btn-success'
              >
                Export as CSV
              </button>
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderTable()}
        <CSVExportModal
          headers={HEADERS}
          data={this.state.data}
          showModal={this.state.showModal}
          toggleModal={this.toggleModal}
          reportType={"On-Course Journey"}
          filename={"on-course-journey.csv"}
        />
      </div>
    );
  }
}

export default StudentRoundsTable;
