import { useLayoutEffect, useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import * as commonActions from '../../../actions/commonActions'
import * as userActions from '../../../actions/userActions'
import * as communitiesActions from '../../../actions/communitiesActions'
import { getSeries, createSeries, updateSeries } from '../../../actions/seriesActions'
import { resetState, setField, seriesFormData } from '../../../reducers/seriesReducer'
import * as constants from '../../../misc/Constants'

import SeriesDetails from './Steps/SeriesDetails'
import EventSchedule from './Steps/EventSchedule'
import ManageSeriesRoster from './Steps/ManageSeriesRoster'

import { ReactComponent as DetailsIcon } from '../../../assets/images/common/icons/programBuilder/details-icon.svg'
import { ReactComponent as ScheduleIcon } from '../../../assets/images/common/icons/programBuilder/schedule-icon.svg'
import { ReactComponent as MarketingIcon } from '../../../assets/images/common/icons/programBuilder/marketing-icon.svg'

import FlowHeader from '../../common/FlowHeader/FlowHeader'
import FlowNavigation from '../../common/FlowNavigation/FlowNavigation'
import FlowFooter from '../../common/FlowFooter/FlowFooter'
import Loader from '../../common/Op36Loader-web'
import SaveModal from '../../common/SaveModal/SaveModal'

import styles from './EventSeries.module.scss'

const customErrorMessages = {
  title: 'Please enter the title.',
  reminderDescription: 'Please provide a description.',
}

// eslint-disable-next-line complexity
const EventSeries = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const [searchParams] = useSearchParams()

  const user = useSelector(state => state.user.current_user)
  const { id, step, status, errors } = useSelector(state => state.series)
  const seriesFields = useSelector(state => state.series)
  const formData = useSelector(seriesFormData)

  const [loader, setLoader] = useState(false)
  const [showSaveModal, setShowSaveModal] = useState(false)
  const [seriesLoading, setSeriesLoading] = useState(!!params.id)
  const dataLoaded = seriesLoading
  const isPublished = (id && status !== 'draft')

  const fetchCourses = useCallback(() => {
    dispatch(userActions.getEventCourses({ communityId: user.communities[0].id, seriesId: id }))
  }, [dispatch, user.communities, id])

  const fetchStudents = useCallback((searchTerm = null) => {
    dispatch(communitiesActions.getAllStudents(user.communities[0].id, null, null, searchTerm))
  }, [dispatch, user.communities])

  useLayoutEffect(() => {
    dispatch(commonActions.showSideBar(false))
    dispatch(commonActions.showNavBar(false))
  }, [dispatch])

  useEffect(() => {
    fetchCourses()
    fetchStudents()
  }, [fetchCourses, fetchStudents])

  useEffect(() => {
    const paramsStep = searchParams.get('step')
    if (paramsStep && ['1', '2', '3', '4'].includes(paramsStep)) {
      dispatch(setField({ field: 'step', value: parseInt(paramsStep) }))
    }

    if (params.id) {
      dispatch(getSeries(params.id)).unwrap().then(() => setSeriesLoading(false))
    }
  }, [user.id, dispatch, params.id, searchParams, user.communities])

  const invalidFields = {
    title: seriesFields.title === '',
    reminderDescription: (seriesFields.earlyReminderDays || seriesFields.lateReminderDays) && seriesFields.reminderDescription === '',
  }

  const validateSeries = (isDraft) => {
    const errors = {};

    ['title', 'reminderDescription'].forEach((type) => {
      if (invalidFields[type]) {
        errors[type] = customErrorMessages[type]
        errors.step1 = true
      }
    })

    if (!isDraft && !seriesFields.events?.length ) {
      errors.step2 = true
    }

    if (!isDraft && !seriesFields.students?.length && !seriesFields.courses?.length) {
      errors.step3 = true
    }

    dispatch(setField({ field: 'errors', value: errors }))

    return Object.keys(errors).length === 0
  }

  const handleCreateSeries = (seriesData) => {
    setLoader(true)

    dispatch(createSeries(seriesData)).unwrap()
      .then(() => {
        dispatch(resetState())
        navigate('/events')
      })
      .catch(() => {
        toast.error('Something went wrong. Please check the steps where errors occurred and fix them.', { position: toast.POSITION.TOP_RIGHT })
      })
      .finally(() => {
        setLoader(false)
      })
  }

  const handleUpdateSeries = (seriesData) => {
    setLoader(true)

    dispatch(updateSeries(seriesData)).unwrap()
      .then(() => {
        dispatch(resetState())
        navigate('/events')
      })
      .catch(() => {
        toast.error('Something went wrong. Please check the steps where errors occurred and fix them.', { position: toast.POSITION.TOP_RIGHT })
      })
      .finally(() => {
        setLoader(false)
      })
  }

  const handleSaveSeries = (saveAsDraft) => {
    if (validateSeries(saveAsDraft)) {
      const seriesData = { ...formData, status: saveAsDraft ? 'draft' : 'active' }
      if (id) {
        seriesData.id = id
        handleUpdateSeries(seriesData)
      } else {
        handleCreateSeries(seriesData)
      }
    } else {
      toast.error(
        'Oops, it seems you are missing some required fields. Please check the steps where errors occurred and fix them.',
        { position: toast.POSITION.TOP_RIGHT }
      )
    }
  }

  const handleSave = () => {
    isPublished ? handleSaveSeries(false) : setShowSaveModal(true)
  }

  const setStep = (value) => {
    dispatch(setField({ field: 'step', value: value }))
  }

  const steps = [
    { index: 1, title: 'Series Details', icon: DetailsIcon },
    { index: 2, title: 'Event Schedule', icon: ScheduleIcon },
    { index: 3, title: 'Manage Roster', icon: MarketingIcon },
  ]

  return (
    <div className={styles['event-series']}>
      {dataLoaded
        ? <Loader message='Loading series' />
        : <>
          <div className='sticky-top'>
            <FlowHeader
              isPublished={isPublished}
              onSave={handleSaveSeries}
              setShowSaveModal={setShowSaveModal}
              loader={loader}
              title={'9 Hole Events'}
            />
            <FlowNavigation
              currentStep={step}
              setStep={setStep}
              steps={steps}
              errors={errors}
            />
          </div>
          {
            step === 1 && <SeriesDetails/> ||
            step === 2 && <EventSchedule/> ||
            step === 3 && <ManageSeriesRoster/>
          }
        </>
      }
      <FlowFooter
        isPublished={isPublished}
        handleSaveClick={handleSave}
        loader={loader}
        dataLoaded={dataLoaded}
        step={step}
        setStep={setStep}
        steps={steps}
      />

      <SaveModal
        showModal={showSaveModal}
        closeModal={() => setShowSaveModal(false)}
        publish={() => { handleSaveSeries(false); setShowSaveModal(false) }}
        draft={() => { handleSaveSeries(true); setShowSaveModal(false) }}
        title={'Publish Event Series'}
        message={constants.SERIES_SAVE_MESSAGE}
      />
    </div>
  )
}

export default EventSeries
