import * as constants from "./actionTypes";
// import { objectToFormData } from "../utility/formHelper";
import Axios from "../axios";

function successGettingConsents(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_USER_CONSENTS,
      consents: response.data.consents,
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successPostingConsents(response) {
  if (response.status === 200) {
    return {
      type: constants.POST_USER_CONSENTS,
      consents: response.data.consents,
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successPostingFamilyConsents(response) {
  if (response.status === 200) {
    return {
      // we're not going to do anything with this, so data doesn't matter, but want to catch errors
      type: constants.POST_FAMILY_CONSENTS,
      consents: response,
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function getUserConsents(user_id) {
  return function (dispatch) {
    return requestUserConsents(user_id).then((response) =>
      dispatch(successGettingConsents(response))
    );
  };
}

export function postConsents(consentArray, user_id) {
  return function (dispatch) {
    return requestPostConsents(consentArray, user_id).then((response) =>
      dispatch(successPostingConsents(response))
    );
  };
}

export function postFamilyConsents() {
  return function (dispatch) {
    return requestPostFamilyConsents().then((response) =>
      dispatch(successPostingFamilyConsents(response))
    );
  };
}

function requestUserConsents(user_id) {
  let requestUrl = `api/v1/users/${user_id}/check_consents`;
  return Axios.get(requestUrl);
}

function requestPostConsents(consentsArray, user_id) {
  let requestUrl = "api/v1/consents";
  return Axios.post(requestUrl, {
    consents: Object.assign({}, consentsArray),
    user_id: user_id,
  });
}

function requestPostFamilyConsents() {
  let requestUrl = "api/v1/consents/family_consents";
  return Axios.post(requestUrl);
}
