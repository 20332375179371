import * as constants from "./actionTypes";

import Axios from '../axios'

export function successRequestingCurrencies(response) {
  return {
    type: constants.GET_CURRENCIES,
    currencies: response.data.currencies,
  }
}

// THUNKS HERE

export function getCurrencies() {
  return function (dispatch) {
    return requestGetCurrencies().then((response) =>
      dispatch(successRequestingCurrencies(response))
    );
  };
}

// API CALLS

function requestGetCurrencies() {
  let requestUrl = "/api/v1/invoice_currencies";
  return Axios.get(requestUrl);
}
