import React from "react";

import "./ChallengeNew.scss";

import ChallengeForm from "../subcomponents/ChallengeForm";

class ChallengeNew extends React.Component {
  state = {
    //send these
    name: "",
    points: "",
    startDate: null,
    endDate: null,
    video_url: "",
    description: "",
    uploadPhotoFile: {},
    uploadPhotoName: "",
    uploadPhotoSrc: "",
    uploadIconFile: {},
    uploadIconSrc: "",
    uploadIconName: "",
    links: [],
    // thru here
    failedValidation: [],
    submitClicked: false
  };
  render() {
    const {
      name,
      points,
      startDate,
      endDate,
      video_url,
      description,
      uploadPhotoSrc,
      uploadIconSrc,
      failedValidation,
      submitClicked,
      links
    } = this.state;
    return (
      <div id='ChallengeNew'>
        <div className='return-button-container'>
          <button
            className='return-challenge-button'
            onClick={this.props.returnToIndex}
          >
            Back to Challenges
          </button>
        </div>
        <h3 className='text-center'>Add a new challenge!</h3>
        <div className='new-challenge-form-container'>
          <ChallengeForm
            name={name}
            points={points}
            startDate={startDate}
            endDate={endDate}
            video_url={video_url}
            description={description}
            handleInputChange={this._handleInputChange}
            handleStartChange={this._handleStartChange}
            handleEndChange={this._handleEndChange}
            grabPhotoData={this._grabPhotoData}
            grabIconData={this._grabIconData}
            selectedPhoto={uploadPhotoSrc}
            selectedIcon={uploadIconSrc}
            failedValidation={failedValidation}
            submitClicked={submitClicked}
            handleSubmitClick={this._handleSubmitClick}
            isEdit={false}
            links={links}
            addNewLink={this._addNewLink}
            removeLink={this._removeLink}
            handleDisplayName={this._handleDisplayName}
            handleLinkUrl={this._handleLinkUrl}
          />
        </div>
      </div>
    );
  }

  _handleDisplayName = (e, i) => {
    let val = e.target.value;
    const links = [...this.state.links];
    links[i].display_name = val;
    this.setState({ links });
  };

  _handleLinkUrl = (e, i) => {
    let val = e.target.value;
    const links = [...this.state.links];
    links[i].link_url = val;
    this.setState({ links });
  };

  _removeLink = i => {
    const links = [...this.state.links];
    links.splice(i, 1);
    this.setState({ links });
  };

  _addNewLink = () => {
    const links = [...this.state.links];
    links.push({ display_name: "", link_url: "" });
    this.setState({ links });
  };

  _handleSubmitClick = () => {
    const { handleCreateChallenge } = this.props;
    let validation = this._checkValidation();
    if (validation.length > 0) {
      this.setState({ failedValidation: validation });
    } else {
      this.setState({ submitClicked: true }, () => {
        handleCreateChallenge(this._buildChallenge());
      });
    }
  };

  _buildChallenge = () => {
    const {
      name,
      points,
      startDate,
      endDate,
      video_url,
      description,
      uploadPhotoFile,
      uploadIconFile,
      links
    } = this.state;
    let data = {};

    data["name"] = name;
    data["points"] = points;
    data["description"] = description;
    data["photo"] = uploadPhotoFile;
    data["icon"] = uploadIconFile;
    data["video_url"] = video_url;
    data["start_date"] = startDate;
    data["end_date"] = endDate;

    if (links.length > 0) {
      data["links"] = JSON.stringify(links);
    } else {
      data["links"] = "";
    }

    return data;
  };

  _checkValidation = () => {
    const {
      name,
      points,
      description,
      // uploadPhotoFile,
      uploadPhotoName,
      uploadIconName
    } = this.state;

    let failedArray = [];
    if (!uploadPhotoName) {
      failedArray.push("photo");
    }
    if (!name) {
      failedArray.push("name");
    }
    if (!points) {
      failedArray.push("points");
    }
    if (!uploadIconName) {
      failedArray.push("icon");
    }
    if (!description) {
      failedArray.push("description");
    }
    return failedArray;
  };

  _handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  _handleStartChange = date => {
    this.setState({ startDate: date });
  };

  _handleEndChange = date => {
    this.setState({ endDate: date });
  };

  _grabPhotoData = photoData => {
    const { uploadPhotoFile, uploadPhotoName, uploadPhotoSrc } = photoData;
    this.setState({
      uploadPhotoName,
      uploadPhotoFile,
      uploadPhotoSrc
    });
  };

  _grabIconData = iconData => {
    const { uploadPhotoFile, uploadPhotoName, uploadPhotoSrc } = iconData;
    this.setState({
      uploadIconName: uploadPhotoName,
      uploadIconFile: uploadPhotoFile,
      uploadIconSrc: uploadPhotoSrc
    });
  };
}

export default ChallengeNew;
