import classnames from 'classnames'
import { isEqual } from 'lodash'
import { Popover, PopoverBody } from 'reactstrap'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  defaultPerPage,
  selectAllOptions,
  selectFilteredCategoryName,
  selectFiltersNumber,
  selectHasFilters,
  selectInitialFilters,
} from '../../reducers/communityLandingPageReducer'
import { getCommunityLandingPagePrograms } from '../../actions/programActions'
import { pluralize } from '../../utility/helperFunctions'
import Select from '../common/form/Select'
import SubmitButton from '../common/buttons/SubmitButton'

import classes from './Filters.module.scss'
import { ReactComponent as FilterIcon } from '../../assets/images/common/icons/filter-icon.svg'

const Filters = () => {
  const dispatch = useDispatch()

  const [isFiltersMenuOpen, setFiltersMenuOpen] = useState(false)
  const { community, filters } = useSelector(state => state.communityLandingPage)
  const [newFilters, setNewFilters] = useState(null)
  const filteredCategoryName = useSelector(selectFilteredCategoryName)
  const hasFilters = useSelector(selectHasFilters)
  const initialFilters = useSelector(selectInitialFilters)
  const options = useSelector(selectAllOptions)
  const filtersNumber = useSelector(selectFiltersNumber)

  const handleOpenFiltersMenuClick = () => {
    setFiltersMenuOpen(value => !value)
  }

  const handleResetFiltersClick = () => {
    dispatch(getCommunityLandingPagePrograms({ communityId: community.id, filters: initialFilters, page: 1, perPage: defaultPerPage }))
  }

  const handleApplyButtonClick = () => {
    // eslint-disable-next-line no-unused-vars
    const { categoryName, ...requestFilters } = newFilters

    if (!isEqual(filters, requestFilters)) {
      dispatch(getCommunityLandingPagePrograms({ communityId: community.id, filters: requestFilters, page: 1, perPage: defaultPerPage }))
    }

    setFiltersMenuOpen(false)
  }

  useEffect(() => {
    if (isFiltersMenuOpen) {
      setNewFilters({
        ...filters,
        categoryName: filteredCategoryName,
      })
    }
  }, [filters, isFiltersMenuOpen, filteredCategoryName])

  return (
    <>
      <div className='d-flex w-100'>
        <button
          id='landingPageFiltersButton'
          className={classnames('flex-grow-1 flex-lg-grow-0 d-flex align-items-center', classes.filtersButton)}
          onClick={handleOpenFiltersMenuClick}
        >
          <FilterIcon className='mx-2' />
          {filtersNumber > 0 ? `${filtersNumber} ${pluralize('filter', filtersNumber)} applied` : 'Filters'}
        </button>
        {hasFilters && (
          <button
            className={classnames('ml-3 animation-shrink d-flex align-items-center', classes.resetFiltersButton)}
            onClick={handleResetFiltersClick}
          >
            Clear <i className='ml-2 fa fa-close' />
          </button>
        )}
      </div>
      <Popover
        target='landingPageFiltersButton'
        isOpen={isFiltersMenuOpen}
        placement='bottom-start'
        trigger='legacy'
        toggle={handleOpenFiltersMenuClick}
        popperClassName={classes.filtersMenu}
      >
        <PopoverBody className={'p-3'}>
          <h5 className={classnames('mb-3', classes.filterName)}>Program Category</h5>
          <Select
            isSearchable={false}
            options={options}
            value={{ value: newFilters?.communityId, label: newFilters?.categoryName }}
            classNames={{
              menuList: () => classes.selectMenuList,
            }}
            onChange={option => {
              setNewFilters(prevState => ({ ...prevState, categoryId: option.value, categoryName: option.label }))
            }}
          />
          <button
            className={classnames('mt-4 d-flex justify-content-center align-items-center', classes.switchButton)}
            onClick={() => setNewFilters(prevState => ({ ...prevState, future: !prevState.future }))}
          >
            <div
              className={classnames(classes.switchOption, !newFilters?.future && classes.switchSelected)}
            >
              Active
            </div>
            <div
              className={classnames(classes.switchOption, newFilters?.future && classes.switchSelected)}
            >
              Future
            </div>
          </button>
          <SubmitButton
            className='mt-5'
            buttonMessage='Apply'
            onClick={handleApplyButtonClick}
          />
        </PopoverBody>
      </Popover>
    </>
  )
}

export default Filters
