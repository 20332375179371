import React from 'react';
import TextInput from '../common/form/TextInput';
import TextAreaInput from '../common/form/TextAreaInput';
import LinksTable from './LinksTable';
// import ObjectiveImage from './ObjectiveImage';
import './ObjectiveForm.scss';

export default class ObjectiveForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      uploadPhotoName: this.props.objective.photo ? this.props.objective.photo.name : '',
      uploadPhotoFile: this.props.objective.photo ? this.props.objective.photo.source : '',
      uploadPhotoSrc: this.props.objective.photo ? this.props.objective.photo.file_url : '',
    };
  }

  submitForm = event => {
    event.preventDefault();
    this.props.handleSubmit();
  }

  render() {
    return (
      <form id="objective-form" className="form-container" onSubmit={this.submitForm}>
        {/* <label htmlFor="objective-image">Background Image</label> */}
        {/* <ObjectiveImage
          photo={this.state.uploadPhotoSrc}
          photoName={this.state.uploadPhotoName}
          onChangePhoto={this.props.onChangePhoto}
        /> */}

        <div className="row name-and-points-row">
          <div className="name-input-container col-10">
          <label htmlFor="name">Title</label>
            <TextInput
              name='name'
              type='text'
              onChange={this.props.onChangeName}
              placeholder='Name'
              value={this.props.objective.name} />
          </div>
          <div className="points-input-container col-2">
            <label htmlFor="points">Points</label>
            <TextInput
              name='points'
              type='text'
              onChange={this.props.onChangePoints}
              placeholder='Points'
              value={this.props.objective.points.toString()} />
          </div>
        </div>

        <label htmlFor="description">Description</label>
        <TextAreaInput
          name='description'
          type='text'
          label=''
          rows='4'
          cols='1'
          onChange={this.props.onChangeDescription}
          placeholder='Description'
          value={this.props.objective.description} />

        <label htmlFor="video">Video URL</label>
        <TextInput
          name='video'
          type='text'
          label=''
          onChange={this.props.onChangeVideo}
          placeholder='Video URL'
          value={this.props.objective.video ? this.props.objective.video.attachment_url : ""} />

        <LinksTable
          links={this.props.objective.links}
          addLink={this.props.addLink}
          removeLink={this.props.removeLink}
          onChangeLink={this.props.onChangeLink} />

        <button id="save-objective-btn" type="submit" className="btn btn-block">Save Objective</button>
      </form>
    );
  }
};