import PropTypes from 'prop-types'
import Select, { components } from 'react-select'
import CurrencyFlag from 'react-currency-flags'

import { ReactComponent as SelectDropDownIcon } from '../../assets/images/common/icons/select-drop-down-icon.svg'
import './CurrencySelect.scss'

const selectStyles = {
  container: (provided) => ({
    ...provided, height: '38px'
  }),
  control: (provided) => ({
    ...provided, borderRadius: '0', cursor: 'pointer'
  }),
  valueContainer: (provided) => ({
    ...provided, height: '38px', padding: '0', input: { position: 'absolute', height: '0', border: 'none' }
  })
}

const Option = (props) => {
  const { data, innerProps } = props

  return (
    <div
      {...innerProps}
      key={data.id}
      className='option'
    >
      <div className='d-flex align-items-center currency-card currency-option' key={data.id}>
        <CurrencyFlag currency={data.code}></CurrencyFlag>
        <p>{`${data.name} (${data.symbol})`}</p>
      </div>
    </div>
  )
}

const SingleValue = (props) => {
  const { data, innerProps } = props;

  return (
    <div {...innerProps} key={data.id} className='d-flex align-items-center currency-card'>
      <CurrencyFlag currency={data.code}></CurrencyFlag>
      <p>{`${data.name} (${data.symbol})`}</p>
    </div>
  )
}

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <SelectDropDownIcon style={{ marginRight: '-5px' }} />
      </components.DropdownIndicator>
    )
  );
};

const CurrencySelect = ({ currencies, currency, onCurrencyChange, classNames }) => (
  <div id='CurrencySelect'>
    <Select
      name='currency-selector'
      className={`currency-selector ${classNames}`}
      value={currencies.find((curr) => curr.code === currency.code)}
      options={currencies}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator,
        SingleValue,
        Option,
      }}
      onChange={onCurrencyChange}
      isSearchable={false}
      styles={selectStyles}
    />
  </div>
)

CurrencySelect.propTypes = {
  currencies: PropTypes.array,
  currency: PropTypes.shape({
    code: PropTypes.string,
  }),
  onCurrencyChange: PropTypes.func,
  classNames: PropTypes.string,
}

export default CurrencySelect
