import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types'
import _ from 'lodash'

// REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as commonActions from "../../actions/commonActions";
import * as authActions from "../../actions/authActions";
import * as communitiesActions from "../../actions/communitiesActions";

// CHILDREN
import TextInput from "../common/form/TextInput";
import ValidationModal from "../common/ValidationModal";
import Loader from "../common/Op36Loader-web";
import AnimatedScrollView from "../common/AnimatedScrollView";
import ForgotPasswordModal from "./Registration/Modals/ForgotPasswordModal";
import ForgotUsernameModal from "./Registration/Modals/ForgotUsernameModal";

// ASSETS
import "./SignIn.scss";
import "../../assets/styles/components/auth/SignIn.scss";
import "../../assets/styles/globals.scss";
import logo from "../../assets/images/logos/OP36_clean_icon_dark.png";
import { withRouting } from "../common/hooks";
import { initializePendo } from '../../utility/pendo'
import { NAVIGATION } from '../../misc/Constants'

class SignIn extends Component {
  state = {
    userName: "",
    password: "",
    emailSent: false,
    showForgotPasswordModal: false,
    showForgotUsernameModal: false,
    errorMessage: "Something went wrong, please try again",
    errorTitle: "Error",
    loading: false,
    showValidationModal: false,
    validationSuccess: false,
    validationMessage: ""
  };

  componentWillMount() {
    const { common_actions, auth_actions } = this.props;

    common_actions.showSideBar(false);
    common_actions.showNavBar(false);
    auth_actions.signOutUser();

    if (window.$zopim && window.$zopim.livechat)
      window.$zopim.livechat.hideAll();
  }

  componentDidMount() {
    const { params: routeParams, communities_actions } = this.props;

    if (routeParams.communityId) {
      communities_actions.getCommunity(routeParams.communityId)
    }
  }

  componentWillUnmount() {
    const { common_actions } = this.props;

    common_actions.showSideBar(true);
    common_actions.showNavBar(true);
    common_actions.setSideBarState(NAVIGATION.COLLAPSED)
  }

  render() {
    const {
      loading,
      userName,
      password,
      showValidationModal,
      showForgotPasswordModal,
      showForgotUsernameModal,
      validationMessage,
      validationSuccess,
      emailSent,
    } = this.state;

    return (
      <div id='SignInWeb'>
        <div className='signInPage' />
        <div id='SignInContainer' className='container animated fadeIn'>
          <div className='row p-0 signin-wrapper' style={{ height: !_.isEmpty(this.props.community) && '715px' }}>
            <div className='col-md-12 mx-auto p-0 signin-card'>
              <div className='row signin-inner-wrapper'>
                <div className='col-xl-5 col-lg-5 col-md-12 signin-left-container p-5'>
                  {loading ? (
                    <div className='text-center signin-loader-container'>
                      <Loader message='Signing In' />
                    </div>
                  ) : (
                    <div>
                      <form
                        className='form-container signin-form-container'
                        onSubmit={this._onSignInSubmit}
                      >
                        <div className='row op36-logo-wrapper'>
                          <img
                            className='op36-signin-logo'
                            alt={"Logo"}
                            src={logo}
                          />
                        </div>
                        <h4>Operation 36</h4>
                        <p>
                          Sign In to your account {!_.isEmpty(this.props.community) &&
                          <> at <span style={{ fontWeight: '700' }}>{this.props.community.name}</span> </>}
                        </p>
                        <div className='row my-2'>
                          <div className='col'>
                            <label>Email or Username</label>
                            <TextInput
                              name='Username'
                              type='text'
                              label=''
                              value={userName}
                              onChange={this._onChangeUserName}
                              className='my-2 input-border'
                              placeholder=''
                              autoComplete='username'
                            />
                          </div>
                        </div>

                        <div className='row my-2'>
                          <div className='col'>
                            <label>Password</label>
                            <TextInput
                              name='Password'
                              type='password'
                              label=''
                              value={password}
                              onChange={this._onChangePassword}
                              className='my-2 input-border'
                              placeholder=''
                              autoComplete='current-password'
                            />
                          </div>
                        </div>
                        <div className='d-flex justify-content-center align-content-center w-100'>
                          <button
                            type='submit'
                            className='btn btn-primary btn-orange mx-auto'
                          >
                            Sign In
                          </button>
                        </div>

                        {/*<Link to={'/register'}>
                                    Not a member? Register
                                  </Link>*/}
                      </form>

                      {!_.isEmpty(this.props.community) && (
                        <div className='d-flex flex-column text-align-center justify-content-center no-account-container'>
                          <div className='divider' />
                          <p className='no-account-question-label'>
                            Don't have an Op 36 account at <span className='font-weight-bold'>{this.props.community.name}</span>?
                          </p>
                          <div className='w-50 mx-auto'>
                            <Link to={`/register/${this.props.community.id}`} className='sign-up-here-link btn btn-primary mx-auto mt-2'>
                              Sign up here
                            </Link>
                          </div>
                        </div>
                      )}

                      <div className='divider' />
                      <div className='forgot-credential-container'>
                        <div className='forgot-text-wrapper'>
                          <div // TODO: This used to be a Link
                            onClick={this._openForgotPasswordModal}
                            className='forgot-text-link animation-shrink'
                          >
                            Forgot password?
                          </div>
                        </div>
                        <div className='text-separator' />
                        <div className='forgot-text-wrapper'>
                          <div // TODO: This used to be a Link
                            onClick={this._openForgotUsernameModal}
                            className='forgot-text-link animation-shrink'
                          >
                            Forgot username?
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-xl-7 col-lg-7 hidden-md-down signin-right-container'>
                  <AnimatedScrollView
                    views={[
                      {
                        title: "Welcome to Operation 36",
                        subtitle:
                          "Don't have a login and want to learn more? Click the button below that applies to you.",
                        primaryAction: {
                          text: "I'm a Golfer",
                          url: "https://operation36.golf",
                        },
                        secondaryAction: {
                          text: "I'm a Coach",
                          url: "https://operation36.golf/coaches",
                        },
                      },
                      {
                        title: "New iOS App Is Available",
                        subtitle:
                          "Download the new mobile app for iOS and get involved in your community",
                        primaryAction: {
                          text: "Download Now",
                          url: "https://itunes.apple.com/us/app/op-36-golf/id1186959877?mt=8",
                        },
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {showValidationModal && (
          <ValidationModal
            showValidationModal={showValidationModal}
            closeValidationModal={this._toggleValidationModal}
            validationSuccess={validationSuccess}
            validationMessage={validationMessage}
          />
        )}
        {showForgotPasswordModal && (
          <ForgotPasswordModal
            showForgotPasswordModal={showForgotPasswordModal}
            closeForgotPasswordModal={this._closeForgotPasswordModal}
            handleSubmitResetPasswordEmail={
              this._handleSubmitResetPasswordEmail
            }
            emailSent={emailSent}
            loading={loading}
          />
        )}
        {showForgotUsernameModal && (
          <ForgotUsernameModal
            showForgotUsernameModal={showForgotUsernameModal}
            closeForgotUsernameModal={this._closeForgotUsernameModal}
            handleSubmitForgotUsernameEmail={
              this._handleSubmitForgotUsernameEmail
            }
            emailSent={emailSent}
            loading={loading}
          />
        )}
      </div>
    );
  }

  _openForgotPasswordModal = () => {
    this.setState({ showForgotPasswordModal: true });
  };

  _closeForgotPasswordModal = () => {
    this.setState({ showForgotPasswordModal: false, emailSent: false });
  };

  _openForgotUsernameModal = () => {
    this.setState({ showForgotUsernameModal: true });
  };

  _closeForgotUsernameModal = () => {
    this.setState({ showForgotUsernameModal: false, emailSent: false });
  };

  _toggleValidationModal = (success, message) => {
    this.setState({
      showValidationModal: !this.state.showValidationModal,
      validationSuccess: success,
      validationMessage: message,
    });
  };

  // ============
  // FORM FUNCTIONS
  // ============
  _onChangeUserName = (event) => {
    this.setState({ userName: event.target.value });
  };

  _onChangePassword = (event) => {
    this.setState({ password: event.target.value });
  };

  _onSignInSubmit = (event) => {
    const { userName, password } = this.state;
    const { auth_actions } = this.props;
    event.preventDefault();

    if (userName !== "" && password !== "") {
      this.setState({ loading: true });
      auth_actions
        .signInUser(userName, password)
        .then((response) => this._userLoggedIn())
        .catch((err) => {
          this.setState({ loading: false });
          if (err.response && err.response.status === 422) {
            this._toggleValidationModal(false, "Invalid Credentials");
          }
        });
    } else {
      this.setState({ loading: false });
      this._toggleValidationModal(
        false,
        "Please login with a valid email and password"
      );
    }
  };

  _handleSubmitResetPasswordEmail = (email) => {
    const { auth_actions } = this.props;
    if (email !== "") {
      this.setState({ loading: true });
      auth_actions
        .triggerResetPassword(email)
        .then((response) => {
          this.setState({ emailSent: true, loading: false });
        })
        .catch((err) => {
          this.setState({ loading: true });
          alert(
            "Unable to send email. Either it was not recognized, or it is imporperly formatted. Please try again."
          );
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  };

  _handleSubmitForgotUsernameEmail = (email) => {
    const { auth_actions } = this.props;
    if (email !== "") {
      this.setState({ loading: true });
      auth_actions
        .triggerForgotUsername(email)
        .then((response) => {
          this.setState({ emailSent: true, loading: false });
        })
        .catch((err) => {
          this.setState({ loading: true });
          alert("Something went wrong, please try again.");
        });
    }
  };

  _userLoggedIn = () => {
    // Register for push notifications
    const { user } = this.props;
    this.setState({ loading: false });

    if (window.FirebasePlugin) {
      window.FirebasePlugin.grantPermission();
      window.FirebasePlugin.subscribe("op36user");
    }
    if (window.pendo) {
      initializePendo(window.pendo, user)
    }

    const redirectUrl = user.type === 'Admin' ? '/communities' : (localStorage.getItem('redirectUrl') || '/')
    localStorage.removeItem('redirectUrl')
    this.props.navigate(redirectUrl, { replace: true });
  };

  _executeOnOverlayClicked = () => {
    this.showOverlay = false;
  };
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user.current_user,
    community: state.community
  };
}

function mapDispatchToProps(dispatch) {
  return {
    common_actions: bindActionCreators(commonActions, dispatch),
    auth_actions: bindActionCreators(authActions, dispatch),
    communities_actions: bindActionCreators(communitiesActions, dispatch)
  };
}

SignIn.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    email: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    created_at: PropTypes.string,
    intercom_hash: PropTypes.string,
    user_type: PropTypes.string,
    lead_coach: PropTypes.bool,
    communities: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      license_type: PropTypes.string,
      representative_name: PropTypes.string,
      student_count: PropTypes.number,
      created_at: PropTypes.string,
    })),
  }),
  community: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  common_actions: PropTypes.shape({
    setSideBarState: PropTypes.func,
  }),

}

export default connect(mapStateToProps, mapDispatchToProps)(withRouting(SignIn));
