import React from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as commonActions from "../../actions/commonActions";
import * as educationActions from "../../actions/educationActions";

import Loader from "../common/Op36Loader-web";
import EducationsCard from "./EducationsCard";
import BaseModal from "../common/BaseModal";

import moment from "moment";

import Axios from "../../axios";

import fav_icon from "../../assets/images/education/education-icon-01.png";

import "./NewEducationPage.scss";
import EducationPreviewModal from "./Modals/EducationPreviewModal";
import AdminEducationModal from "./Modals/AdminEducationModal";

import Placeholder from "../common/Placeholder";
import { withRouting } from "../common/hooks";

class EducationPage extends React.Component {
  state = {
    view: "all",
    sidebarOpen: true,
    loader: true,
    selectedEducation: false,
    showAdminModal: false,
    educationLoader: false,
    name: "",
    description: "",
    price: 0,
    visibility: 0,
    start_date: null,
    end_date: null,
    video_url: "",
    uploadPhotoName: "",
    uploadPhotoFile: "",
    uploadPhotoSrc: "",
    education_sections: [],
    sectionIndex: -1,
    assigningEducation: false,
  };

  componentDidMount() {
    const { common_actions, education_actions } = this.props;

    common_actions.setCurrentTab("Education");
    common_actions.showSideBar(true);
    common_actions.showNavBar(true);

    education_actions.getAllEducations().then(() => {
      this.setState({ loader: false });
    });
  }

  render() {
    const { current_user } = this.props;
    const isAdmin = current_user && current_user.type === "Admin";
    const {
      view,
      sidebarOpen,
      loader,
      selectedEducation,
      showAdminModal,
      educationLoader,
    } = this.state;
    return (
      <div id='EducationsPage'>
        <div className='lesson-dashboard-topnav'>
          <div className='nav-title'>
            <h3 className='topnav-title'>Coach Academy</h3>
          </div>
          <div className='topnav-tabs-buttons-wrapper'>
            <div className='topnav-tabs-wrapper'>
              <div
                className='finish-container lesson-status-select'
                onClick={() => this._changeView("all")}
              >
                <p className={view === "all" ? "active-pg" : ""}>
                  Available Courses
                </p>
                <div
                  className={`finish-hover-bar ${
                    this.state.view === "all" ? "make-active-hover" : ""
                  }`}
                />
              </div>
              {isAdmin ? null : (
                <div
                  className='drafts-container lesson-status-select'
                  onClick={() => this._changeView("completed")}
                >
                  <p className={view === "completed" ? "active-pg" : ""}>
                    Completed Courses
                  </p>
                  <div
                    className={`drafts-hover-bar ${
                      this.state.view === "completed" ? "make-active-hover" : ""
                    }`}
                  />
                </div>
              )}
            </div>
            <div className='nav-search-area'>
              {isAdmin && (
                <div
                  className='add-button-1 d-flex align-items-center justify-content-center mb-2'
                  onClick={this._toggleAdminModal}
                >
                  <i className='fa fa-plus' />{" "}
                  <p style={{ margin: 0, marginLeft: "6px" }}>Create</p>
                </div>
              )}
            </div>
          </div>
        </div>
        {loader ? (
          <Loader message='grabbing education courses' />
        ) : (
          <div className='education-content-container'>
            {isAdmin ? null : (
              <div
                className='in-progress-sidebar'
                style={sidebarOpen ? { flex: "0 0 350px" } : {}}
              >
                <div className='pinned-toggler' onClick={this._toggleSidebar}>
                  <i
                    className={`fa fa-chevron-left ${
                      sidebarOpen ? "" : "flip"
                    }`}
                  />
                  {sidebarOpen ? <h3>In Progress Courses</h3> : null}
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {sidebarOpen && this._renderCards("in-progress")}
                </div>
              </div>
            )}
            <div className='drills-all-main-area'>
              {/* <div className='promo-banner'>
                <p>
                  ⛳ First week promotion: 10% off 2020 Online Certification!
                  Sale ends April 30.{" "}
                  <span className='count-down'>
                    {this._getDaysLeft()} days left!
                  </span>{" "}
                  ⛳
                </p>
                <p className='price-preview'>
                  ($225 <span className='strike-through'>$250</span>)
                </p>
              </div> */}
              <div className='drills-activities-cards-main-area'>
                <div className='d-flex flex-wrap'>
                  {this._renderCards(view)}
                </div>
              </div>
            </div>
          </div>
        )}
        {selectedEducation && (
          <BaseModal width={"80vw"} close={this._closePreview}>
            {this._renderPreview(selectedEducation)}
          </BaseModal>
        )}
        {current_user.type === "Admin" && showAdminModal && (
          <AdminEducationModal
            showModal={showAdminModal}
            closeModal={this._closeAdminModal}
            formState={this.state}
            grabPhotoData={this._grabPhotoData}
            handleInputChange={this._handleInputChange}
            sendSectionData={this._grabSectionData}
            addSection={this._addSection}
            deleteSection={this._deleteSection}
            createEducation={this._createEducation}
            loader={educationLoader}
            handleDateChange={this._handleDateChange}
          />
        )}
      </div>
    );
  }

  _getDaysLeft = () => {
    let now = moment();
    let end = moment("2020-04-30").endOf("day");
    return end.diff(now, "days");
  };

  // ADMIN MODAL STUFF
  // =======================================================================

  _toggleAdminModal = () => {
    this.setState({ showAdminModal: true });
  };

  _closeAdminModal = () => {
    this.setState({ showAdminModal: false });
  };

  _grabPhotoData = (photoData) => {
    const { uploadPhotoFile, uploadPhotoName, uploadPhotoSrc } = photoData;
    this.setState({
      uploadPhotoName,
      uploadPhotoFile,
      uploadPhotoSrc,
    });
  };

  _handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  _grabSectionData = (data) => {
    let education_sections = [...this.state.education_sections];
    let indexOfSection = education_sections
      .map((section) => section.order)
      .indexOf(data.order);

    if (indexOfSection > -1) {
      education_sections.splice(indexOfSection, 1, data);
    } else {
      education_sections.push(data);
    }

    this.setState({ education_sections });
  };

  _addSection = () => {
    let sectionIndex = this.state.sectionIndex;
    let education_sections = [...this.state.education_sections];
    sectionIndex++;

    education_sections.push({ order: sectionIndex });
    this.setState({
      sectionIndex,
      education_sections,
    });
  };

  _deleteSection = (section) => {
    let education_sections = [...this.state.education_sections];
    let indexOfSection = education_sections
      .map((section) => section.order)
      .indexOf(section.order);

    education_sections.splice(indexOfSection, 1);

    this.setState({ education_sections });
  };

  _createEducation = () => {
    const { education_actions } = this.props;

    this.setState({ educationLoader: true });
    education_actions
      .addEducation(this.state)
      .then(() =>
        this.setState({ educationLoader: false, showAdminModal: false })
      );
  };

  _handleDateChange = (val, date) => {
    this.setState({ [val]: date });
  };

  // =======================================================================

  _assignFreeEducation = (edu, user_id) => {
    this.setState({ assigningEducation: true }, () => {
      Axios.post(`api/v1/educations/${edu.id}/assign_free`, {
        user_id: user_id,
      })
        .then((res) => {
          this._navigateTo(edu, res.data.user_education);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  _getPaymentIntent = (edu, user_id) => {
    const location = window.location.origin

    let key = process.env.REACT_APP_STRIPE_PK_EDUCATION

    this.setState({ assigningEducation: true }, () => {
      Axios.get(
        `api/v1/educations/${edu.id}/get_payment_intent?user_id=${user_id}&base=${location}&new_web=true`
      ).then((res) => {
        let session_id = res.data.session.id;
        let stripe = window.Stripe(key);
        stripe.redirectToCheckout({
          sessionId: session_id,
        });
      });
    });
  };

  _renderCards = (view) => {
    const { current_user, educations } = this.props;
    const isAdmin = current_user && current_user.type === "Admin";
    let filteredEducations = educations.filter((e) => {
      return this._applyFilter(view)(e);
    });
    if (filteredEducations.length === 0) {
      return this._renderPlaceHolder(view);
    }
    return filteredEducations.map((e) => {
      return (
        <EducationsCard
          key={e.id}
          isMainArea={view !== "in-progress"}
          education={e}
          isAdmin={isAdmin}
          navigateTo={this._navigateTo}
          showPreview={this._showPreview}
        />
      );
    });
  };

  _applyFilter = (view) => {
    switch (view) {
      case "all":
        return (edu) => !edu.is_open;
      case "completed":
        return (edu) => edu.user_education && edu.user_education.is_completed;
      case "in-progress":
        return (edu) => edu.user_education && !edu.user_education.is_completed;
      default:
        return false;
    }
  };

  _renderPlaceHolder = (view) => {
    switch (view) {
      case "all":
        return (
          <div className='col-6 mx-auto'>
            <Placeholder
              title={"Great job"}
              icon='fa-thumbs-o-up'
              size={70}
              subtext1={"You've completed all available courses!"}
            />
          </div>
        );
      case "completed":
        return (
          <div className='col-6 mx-auto'>
            <Placeholder
              title={""}
              icon='fa-puzzle-piece'
              size={70}
              subtext1={
                "You haven't completed any courses yet? Take a look at all the available courses!"
              }
            />
          </div>
        );
      case "in-progress":
        return (
          <div className='empty-sidebar-msg'>
            <img src={fav_icon} alt={"drills"} />
            <p>
              {"No courses in progress"}
              <br />
            </p>
          </div>
        );
      default:
        return null;
    }
  };

  _changeView = (view) => {
    this.setState({ view });
  };
  _toggleSidebar = () => {
    this.setState({ sidebarOpen: !this.state.sidebarOpen });
  };

  _renderPreview = (education) => {
    return (
      <EducationPreviewModal
        education={education}
        user={this.props.current_user}
        loader={this.state.assigningEducation}
        assignFree={this._assignFreeEducation}
        payProcess={this._getPaymentIntent}
      />
    );
  };

  _showPreview = (education) => {
    this.setState({ selectedEducation: education });
  };

  _closePreview = () => {
    this.setState({ selectedEducation: false });
  };

  _navigateTo = (education, userEducation) => {
    // send them here if they own the course and click it
    const { navigate } = this.props;

    navigate(
      `/education/${education.id}`,
      { state: { educationId: education.id, userEducationId: userEducation.id } }
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    ui: state.ui,
    current_user: state.user.current_user,
    educations: state.educations,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    common_actions: bindActionCreators(commonActions, dispatch),
    education_actions: bindActionCreators(educationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouting(EducationPage))
