import React from "react";

import "./DeleteConfirmationModal.scss";

const DeleteConfirmationModal = ({ id, close, kill, success, clicked }) => {
  return (
    <div id="DeleteConfirmationModal">
      <i
        className={`top-icon fa fa-${success ? "bomb" : "times-circle"}`}
        aria-hidden="true"
        style={{ color: success ? "black" : "red" }}
      />
      <p>
        {success
          ? "Boom! Done..."
          : "Are you sure? You won't be able to get this guy back..."}
      </p>

      {success ? (
        <button className="close-modal" onClick={close}>
          Close
        </button>
      ) : (
        <div className="button-area">
          <button className="never-mind" onClick={close}>
            Never mind
          </button>
          <button className="kill" onClick={() => kill(id)} disabled={clicked}>
            {clicked ? (
              <i className="fa fa-spinner fa-spin" aria-hidden="true" />
            ) : (
              "Kill it"
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export default DeleteConfirmationModal;
