import React from "react";
import Modal from "react-modal";

import Loader from "./../../../common/Op36Loader-web";

import SinglePhotoUploader from "../singlePhotoUploader/SinglePhotoUploader";

import "./ImageBankModal.scss";

const MAX_UPLOADS = 10;

const imageBankModal = props => {
  // This is what the user sees if the bank is empty
  let uploadedImages = [];
  let emptyMessage = (
    <p className='image-bank-modal-empty-message'>
      No saved images. Click the "Upload an image" button to upload an image.
    </p>
  );
  let emptyBankDisplay = props.uploads.length <= 0 ? { display: "flex" } : {};

  // If the bank has at least one image, display bank contents
  if (props.uploads.length > 0) {
    uploadedImages = props.uploads.map(img => {
      const imgSelected = props.selected.includes(img.id);
      const selectedClass = imgSelected
        ? " image-bank-modal-photo-selected"
        : "";

      // key should be img.id and not index because when a new photo is added,
      // index causes the last uploaded photo to briefly flicker in its place
      return (
        <div
          key={img.id}
          className={"image-bank-modal-photo" + selectedClass}
          onClick={
            props.deletingLoader === img.id
              ? false
              : () => props.selectPhoto(imgSelected, img)
          }
        >
          {props.deletingLoader === img.id && (
            <i
              className='fa fa-spinner fa-spin delete-spinner'
              aria-hidden='true'
            />
          )}
          <div className='image-bank-modal-photo-selected-overlay'>
            {imgSelected ? (
              <div className='image-bank-modal-photo-selected-check'>
                <i className='fa fa-check' aria-hidden='true' />
              </div>
            ) : (
              <div className='image-bank-checkmark-circle-outline' />
            )}
            <div
              className='image-bank-delete-button'
              onClick={
                props.deletingLoader === img.id
                  ? false
                  : e => props.deletePhoto(img.id, e)
              }>
              <i
                className='fa fa-trash'
                aria-hidden='true'
              />
            </div>
          </div>
          <img
            className='image-bank-modal-photo-thumbnail'
            src={img.large_file_url}
            alt={"Uploaded"}
          />
        </div>
      );
    });
  }

  return (
    <Modal
      isOpen
      contentLabel='Image Bank Modal'
      onRequestClose={props.closeModal}
      className='customModal'
    >
      <div className='image-bank-modal'>
        <div className='image-bank-modal-header'>
          <button className='x-modal-close-btn' onClick={props.closeModal}>
            <i className='fa fa-times' aria-hidden='true'></i>
          </button>
          <h1>Choose up to 3 images</h1>
          <h2>
            They can be reordered via dragging after confirming your selection.
          </h2>

          {/*
            <button
              className='btn-rectangular btn-rectangular-secondary-positive'
              disabled={props.uploads.length >= MAX_UPLOADS}>+ Upload images</button>
          */}

          <hr />
          <div className='image-bank-upload-button-wrapper'>
            <SinglePhotoUploader
              sendPhotoData={props.sendPhotoData}
              disableUploadBtn={props.uploads.length >= MAX_UPLOADS}
            />
            <p>You can store up to 10 images here for quick access.</p>
          </div>
        </div>
        <p className='image-bank-modal-num-selected'>
          Images selected: {props.selected.length} / 3
        </p>
        <div className='image-bank-modal-images' style={emptyBankDisplay}>
          {/*<p>Images selected: {props.selected.length} / 3</p>*/}
          {props.uploading && (
            <div className='image-bank-modal-photo'>
              <Loader />
            </div>
          )}
          {uploadedImages}
          {uploadedImages.length > 0 || props.uploading ? null : emptyMessage}
        </div>
        <div className='image-bank-modal-footer'>
          <button
            className='btn-rectangular btn-rectangular-main-negative'
            onClick={props.closeModal}
          >
            Cancel
          </button>
          <button
            className='btn-rectangular btn-rectangular-main-positive'
            onClick={props.saveSelection}
          >
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default imageBankModal;
