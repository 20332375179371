import React from "react";

import "./PlaceholderLPCard.scss";

const PlaceholderLPCard = ({ type }) => {
  return (
    <div id="PlaceholderLPCard">
      <p>You don't have any {type} Lesson Plans.</p>
    </div>
  );
};

export default PlaceholderLPCard;
