import { combineReducers } from '@reduxjs/toolkit'
import pricingReducer from './pricingSettingsReducer'
import landingPageReducer from './landingPageSettingsReducer'

const communitySettingsReducer = combineReducers({
  pricing: pricingReducer,
  landingPage: landingPageReducer,
})

export default communitySettingsReducer
