import classNames from 'classnames'
import ReactTooltip from 'react-tooltip'
import _ from 'lodash'
import colors from '../../assets/styles/globals.scss'

import './Tooltip.scss'

const Tooltip = ({ className, ...otherProps }) => {

  return (
    <div className='Tooltip'>
      <ReactTooltip
        type='light'
        events='click'
        effect='solid'
        place='top'
        border={true}
        borderColor={colors.placeholderGray}
        arrowColor={colors.placeholderGray}
        className={classNames('custom-tooltip', className)}
        {...otherProps}
      />
    </div>
  )
}

export default Tooltip
