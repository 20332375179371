import { configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import rootReducer from '../reducers'
import { loadState, saveState } from './localStorage'
import throttle from 'lodash/throttle'
import { NAVIGATION } from '../misc/Constants'

// LOAD THE LOCAL STATE FROM THE LOCALSTORAGE
const persistedState = loadState()
const initialState = {
  ui: {
    isLoading: false,
    showSideBar: true,
    showNavBar: true,
    sideBarState: NAVIGATION.COLLAPSED,
  },
}

// SELECT AN APP STATE, IF A USER ALREADY HAS STATE VS A NEW USER
function selectAppState(initialState, persistedState) {
  // return initialState //Include this line if you do not want to use persisted state (used for testing)
  if (persistedState === undefined) {
    return initialState
  } else {
    return persistedState
  }
}

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => {
    if (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_ENABLE_REDUX_LOGGER === 'true') {
      return getDefaultMiddleware({
        serializableCheck: false,
      }).concat(logger)
    }
    return getDefaultMiddleware({
      serializableCheck: false,
    })
  },
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState: selectAppState(initialState, persistedState),
})

// SAVE STATE TO LOCAL STORE EVERY TIME CHANGES
// ENABLE ALL THIS ON PRODUCTION
store.subscribe(throttle(() => {
  saveState({
    ui: store.getState().ui,
    user: store.getState().user,
  })
}, 1000))

export default store
