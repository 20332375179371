import React from "react";
import { abbrNum } from "../../../utility/helperFunctions";

// ASSETS
import "./CommunityStatCard.scss";

const CommunityStatCard = ({ number, name, icon }) => (
  <div id='CommunityStatCard'>
    <div className='stat-wrapper'>
      <div className='icon-wrapper'>
        <i className={`fa fa-${icon}`} />
      </div>
      <p className='stat-number'>{abbrNum(number, 1)}</p>
      <p className='stat-name'>{name}</p>
    </div>
  </div>
);
export default CommunityStatCard;
