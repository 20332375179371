import { objectToFormData } from '../utility/formHelper'
import { deserialize, serialize } from '../utility/serialization'
import Axios from '../axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { handleAPIError } from '../utility/actionHelper'

// THUNKS

export const createCourseCategory = createAsyncThunk(
  'programCategory/create',
  async (courseCategoryData, { rejectWithValue }) => {
    try {
      const { data } = await requestCreateCourseCategory(courseCategoryData)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const updateCourseCategory = createAsyncThunk(
  'programCategory/update',
  async ({ id, courseCategoryData }, { rejectWithValue }) => {
    try {
      const { data } = await requestUpdateCourseCategory(id, courseCategoryData)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const deleteCourseCategory = createAsyncThunk(
  'programCategory/delete',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await requestDeleteCourseCategory(id)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const getCommunityCategories = createAsyncThunk(
  'programCategory/getCommunityCategories',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await requestGetCourseCategories()
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const getCommunityLandingPageCategories = createAsyncThunk(
  'programCategory/getCommunityLandingPageCategories',
  async (communityId, { rejectWithValue }) => {
    try {
      const { data } = await requestGetCommunityLandingPageCategories(communityId)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

// API CALLS

const requestCreateCourseCategory = (courseCategoryData) => {
  const requestUrl = '/api/v1/course_categories'

  const filteredData = {
    name: courseCategoryData.name,
    color: courseCategoryData.color,
    description: courseCategoryData.description,
    order: courseCategoryData.order,
    communityId: courseCategoryData.communityId,
    headerImageAttributes: courseCategoryData.headerImageData ? ({
      photo: courseCategoryData.headerImageData,
      name: courseCategoryData.headerImageName,
    }) : null,
  }

  const data = objectToFormData(serialize({ courseCategory: filteredData }))

  return Axios.post(requestUrl, data)
}

const requestUpdateCourseCategory = (id, courseCategoryData) => {
  const requestUrl = `/api/v1/course_categories/${id}`

  const filteredData = {
    name: courseCategoryData.name,
    color: courseCategoryData.color,
    description: courseCategoryData.description,
    order: courseCategoryData.order,
    communityId: courseCategoryData.communityId,
  }

  if (courseCategoryData.headerImageDelete) {
    filteredData.headerImageAttributes = { _destroy: true }
  }

  if (courseCategoryData.headerImageData) {
    filteredData.headerImageAttributes = {
      photo: courseCategoryData.headerImageData,
      name: courseCategoryData.headerImageName,
    }
  }

  const data = objectToFormData(serialize({ courseCategory: filteredData }))

  return Axios.put(requestUrl, data)
}

const requestDeleteCourseCategory = (id) => {
  const requestUrl = `/api/v1/course_categories/${id}`

  return Axios.delete(requestUrl)
}

const requestGetCourseCategories = () => Axios.get('/api/v1/course_categories/')

const requestGetCommunityLandingPageCategories = (communityId) => {
  const requestUrl = '/api/v1/course_categories/community_landing_page'
  const params = serialize({ communityId })

  return Axios.get(requestUrl, { params })
}
