import { useSelector } from 'react-redux'
import Pagination from '../common/Pagination'
import PropTypes from 'prop-types'
import moment from 'moment'

import { ReactComponent as EllipsisIcon } from '../../assets/images/common/icons/ellipsis-icon.svg'
import { UncontrolledPopover } from 'reactstrap'
import '../../assets/styles/components/communities/CommunitiesTable.scss'
import { Link, useNavigate } from 'react-router-dom'
import { ReactComponent as StripeIcon } from '../../assets/images/common/icons/stripe-icon.svg'
import Loader from '../common/Op36Loader-web'
import { licenseLabel } from '../../utility/communityLicense'

const CommunitiesTable = ( { fetchCommunities, toggleCommunity, toggleConfirmationModal, loader, page } ) => {
  const pages = useSelector(state => state.pages)
  const communities = useSelector(state => state.communities.communities)
  const navigate = useNavigate()

  const renderCommunitiesTable = () => (
    <table className='table w-100 animated fadeIn'>
      <thead className='primary-background'>
        <tr>
          <th className='font-200'>Id</th>
          <th className='font-200'>Name</th>
          <th className='font-200'>License Type</th>
          <th className='font-200'>State</th>
          <th className='font-200'>Created Date</th>
          <th className='font-200'>Account Manager</th>
          <th className='font-200'>Landing Page</th>
          <th className='font-200'>Active Classes</th>
          <th className='font-200'>Enrollments</th>
          <th className='font-200'>Coach Name</th>
          <th className='font-200'>Stripe</th>
          <th></th>
        </tr>
      </thead>
      <tbody>{renderCommunities()}</tbody>
    </table>
  )

  const renderCommunities = () => {
    if (communities.length === 0) {
      return null
    } else {
      return communities.map((community) =>
        renderCommunityRow(community)
      )
    }
  }

  const showCommunityImage = (community) => {
    if (community.photo) {
      return (
        <img
          src={community.photo.smallFileUrl}
          alt={community.name}
          className='img-responsive classDetailPage-avatar center-block'
        />
      )
    } else {
      return null
    }
  }

  const renderCommunityRow = (community) => (
    <tr
      key={community.id}
      className={`border-bottom ${!community.isActive && 'community-row'}`}
    >
      <td>{community.id}</td>
      <td>{showCommunityImage(community)} {community.name}</td>
      <td>{community.licenseType ? licenseLabel(community.licenseType) : 'No license'}</td>
      <td>{community.address.state ? community.address.state : 'No state'}</td>
      <td>{moment(community.createdAt).format('M/D/Y')}</td>
      <td>{community.representativeName ? community.representativeName : 'No manager'}</td>
      <td>
        <Link to={`/landing/${community.id}`} target='_blank'>
          Click Here
        </Link>
      </td>
      <td>{community.activeClasses}</td>
      <td>{community.enrollmentsCount}</td>
      <td>{community.leadCoach ? community.leadCoach.firstName + ' ' + community.leadCoach.lastName : 'No coach'}</td>
      <td>{community.selectedStripeConnectedAccount ? <StripeIcon id='stripeIcon' /> : 'No account'}</td>
      <td className='table-row-actions' style={{ width: '5%' }}>
        <EllipsisIcon id={`community_${community.id}`} className='table-ellipsis'/>
        <UncontrolledPopover
          placement='bottom'
          target={`community_${community.id}`}
          trigger='legacy'
          popperClassName='popover-style'
        >
          <div className='communities-actions d-flex flex-column align-items-start justify-content-start'>
            <button className='community-action' onClick={() => navigate(`/communities/${community.id}`)}>
              Review
            </button>
            <button className='community-action' onClick={() => toggleConfirmationModal(community)}>
              Login
            </button>
            <a
              href={`https://app.hubspot.com/contacts/44706418/record/2-27564788/${community.hubspotOnboardingId}`}
              target='_blank'
              className={`community-action ${community.hubspotOnboardingId ? '' : 'disabled'}`}
            >
              {community.hubspotOnboardingId ? 'HubSpot' : 'No HubSpot'}
            </a>
            <button className='community-action' onClick={() => toggleCommunity(community)}>
              {community.isActive ? 'Deactivate' : 'Activate'}
            </button>
          </div>
        </UncontrolledPopover>
      </td>
    </tr>
  )

  return (
    <div>
      {pages > 1 && (
        <Pagination
          pageCount={pages}
          fetchData={fetchCommunities}
          activePage={page}
        />
      )}

      { loader
        ? ( <Loader message={'Loading Communities'} /> )
        : communities && communities.length > 0
          ? renderCommunitiesTable()
          : (
            <div className='no-packages d-flex justify-content-center'>
              <p className='mb-0'>No available communities</p>
            </div>
          )
      }
    </div>

  )
}

CommunitiesTable.propTypes = {
  fetchCommunities: PropTypes.func,
  loader: PropTypes.bool,
  page: PropTypes.number,
  toggleCommunity: PropTypes.func,
  toggleConfirmationModal: PropTypes.func,
}

export default CommunitiesTable
