import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'
import { toast, ToastContainer } from 'react-toastify'
import PropTypes from 'prop-types'

import { setRequestInformationModalOpen } from '../../reducers/communityLandingPageReducer'
import { getCommunityTimeInNetwork } from '../../utility/datesHelper'
import CollapsibleBar from './collapsible-bar/CollapsibleBar'

import certifiedBadge from '../../assets/images/pins/certified_badge.png'
import top50Badge from '../../assets/images/pins/top_50_generic.png'
import masterTop50Badge from '../../assets/images/pins/master_top_50.png'
import { ReactComponent as QuestionMark } from '../../assets/images/common/icons/question-mark-circle.svg'
import { ReactComponent as CallIcon } from '../../assets/images/common/icons/call-icon.svg'
import { ReactComponent as ShareIcon } from '../../assets/images/common/icons/share-icon.svg'
import { ReactComponent as CalendarIcon } from '../../assets/images/common/icons/communityLandingPage/calendar-icon.svg'
import { ReactComponent as GolferIcon } from '../../assets/images/common/icons/communityLandingPage/golfer-icon.svg'
import { ReactComponent as StudentIcon } from '../../assets/images/common/icons/communityLandingPage/student-icon.svg'
import defaultAvatar from '../../assets/images/mock/default-profile-icon.png'
import classes from './CommunityDetails.module.scss'
import CoachCollapsibleInfo from './collapsible-bar/CoachCollapsibleInfo'


const collapsibleBarClassnames = {
  collapsibleBar: classes.collapsibleBar,
  bar: classes.bar,
  collapsibleBarExpanded: classes.collapsibleBarExpanded,
}

const CommunityDetails = ({ className }) => {
  const dispatch = useDispatch()

  const community = useSelector(state => state.communityLandingPage.community)
  const coaches = community.coaches

  const [time, unit] = useMemo(() => getCommunityTimeInNetwork(community.networkDate || community.createdAt), [community])
  const landingPageUrl = `${process.env.REACT_APP_BASE_URL}/landing/${community.id}`

  const handleShareButtonClick = () => {
    navigator.clipboard.writeText(landingPageUrl)
      .then(() => {
        toast.info('Link was copied to clipboard', {
          toastId: 'LinkCopiedToast',
          position: 'bottom-right',
          autoClose: 2500,
        })
      })
  }

  return (
    <div className={classnames('flex-column p-4', className, classes.communityDetails)}>
      <p className={classnames('mb-4', classes.description)}>{community.description}</p>
      <button
        className={classnames('animation-shrink d-flex align-items-center', classes.requestInfoButton)}
        onClick={() => dispatch(setRequestInformationModalOpen(true))}
      >
        <QuestionMark className={classnames('flex-shrink-0', classes.questionMark)} />
        <span className='ml-3'>Have Questions? Request Program Information</span>
      </button>
      <div className={classnames('d-flex px-3', community.phoneNumber ? 'justify-content-between' : 'justify-content-center')}>
        {!!community.phoneNumber && (
          <a className={classnames('animation-shrink', classes.actionButton)} href={`tel:${community.phoneNumber}`}>
            <CallIcon className={classes.actionIcon} />
            Call us
          </a>
        )}
        <button className={classnames('animation-shrink', classes.actionButton)} onClick={handleShareButtonClick}>
          <ShareIcon className={classes.actionIcon} />
          Share page
        </button>
      </div>
      <ul className='p-0'>
        {coaches.length > 0 && (
          <CollapsibleBar
            components={{
              bar: <h2 className={classes.barTitle}>Coaches</h2>,
              collapse: (
                <ul className='p-0'>
                  {coaches.map(coach => (
                    <CollapsibleBar
                      key={coach.id}
                      classNames={{
                        collapsibleBar: classes.coachCollapsibleBar,
                        bar: classes.coachBar,
                      }}
                      components={{
                        bar: (
                          <div className='d-flex align-items-center'>
                            <div className='medium-round-image-wrapper flex-shrink-0 mr-2'>
                              <img
                                className='round-image'
                                src={coach.avatar ? coach.avatar.smallFileUrl : defaultAvatar}
                                alt={`Coach ${coach.firstName} ${coach.lastName}`}
                              />
                            </div>
                            <span className={classes.coachName}>{coach.firstName} {coach.lastName}</span>
                            {community.leadCoachId === coach.id && (
                              <span className={classes.leadCoach}>Lead Coach</span>
                            )}
                          </div>
                        ),
                        collapse: <CoachCollapsibleInfo coach={coach} />,
                      }}
                    />
                  ))}
                </ul>
              ),
            }}
            classNames={collapsibleBarClassnames}
          />
        )}
        <CollapsibleBar
          components={{
            bar: <h2 className={classes.barTitle}>Achievements</h2>,
            collapse: (
              <div className={classes.achievements}>
                <div className={classnames('mt-3', classes.statistics)}>
                  <CalendarIcon />
                  <span className={classes.statisticsDescription}>
                    <span className={classes.number}>{time} </span>{unit} in Network
                  </span>
                </div>
                <div className={classes.statistics}>
                  <StudentIcon />
                  <span className={classes.statisticsDescription}>
                    <span className={classes.number}>{community.studentCount} </span>Total Students
                  </span>
                </div>
                <div className={classes.statistics}>
                  <GolferIcon />
                  <span className={classes.statisticsDescription}>
                    <span className={classes.number}>{community.golfersCreated} </span>Golfers Created
                  </span>
                </div>
                <div className={classes.accolades}>
                  {community.isCertified && (
                    <img src={certifiedBadge} className={classes.accolade} alt='certified badge' />
                  )}
                  {community.isTop50 && (
                    <img src={top50Badge} className={classes.accolade} alt='top 50 badge' />
                  )}
                  {community.isMasterTop50 && (
                    <img src={masterTop50Badge} className={classes.accolade} alt='master top 50 badge' />
                  )}
                </div>
              </div>
            ),
          }}
          classNames={collapsibleBarClassnames}
        />
      </ul>
      <ToastContainer />
    </div>
  )
}

CommunityDetails.propTypes = {
  className: PropTypes.string,
}

export default CommunityDetails
