// Derived from ChooseTemplateModal, but this allows you to
// choose template (all on same page - no tabs) or scratch

import React from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'

import calendarIcon from '../../../assets/images/common/icons/lesson_plans/lesson-calendar.svg'
import defaultIcon from '../../../assets/images/common/icons/lesson_plans/lesson-plan-icon.png'

import './AddLessonPlanModal.scss'
import Loader from '../../common/Op36Loader-web'

class AddLessonPlanModal extends React.Component {
  state = {
    category: 0,
    selectedTemplateId: -1,
  }
  render() {
    const { selectedTemplateId } = this.state
    const { close, selectBlank } = this.props
    return (
      <Modal
        isOpen
        contentLabel='Lesson Plan Action Modal'
        shouldCloseOnOverlayClick
        className='customModal'
      >
        <div id='AddLessonPlanModal'>
          <div
            className={`choose-template-modal ${
              selectedTemplateId > -1 ? 'show-preview' : ''
            }`}
          >
            <div className='modal-header'>
              <h5 className='modal-title display-4'>
                Create New Lesson Plan
              </h5>
              <div className='col-1'>
                <button
                  type='button'
                  className='close pull-right font-200'
                  data-dismiss='modal'
                  aria-label='Close'
                  onClick={close}
                >
                  <span aria-hidden='true'>✕</span>
                </button>
              </div>
            </div>
            <div className='create-lesson-plan-options'>
              {selectedTemplateId < 0 ? (
                <div className='create-lesson-plan-options-all'>
                  <button
                    className='start-from-scratch-button'
                    onClick={selectBlank}>
                    <i className='fa fa-plus blank-icon' />
                    Start from scratch
                  </button>
                  <div className='all-template-boxes'>
                    {/* 1 = Op 36 Templates; 0 = My Templates */}
                    <div className='template-box'>
                      <h2 className='template-cat-title'>Operation 36 Templates</h2>
                      {this._renderTemplates(1)}
                    </div>
                    <div className='template-box'>
                      <h2 className='template-cat-title'>My Templates</h2>
                      {this._renderTemplates(0)}
                    </div>
                  </div>
                </div>
              ) : (
                this._renderPreview(selectedTemplateId)
              )}
            </div>
          </div>
        </div>
      </Modal>
    )
  }

  _renderPreview = id => {
    const { op36Templates, selectTemplate } = this.props
    const temp = op36Templates.find(t => t.id === id)
    if (temp) {
      return (
        <div>
          <div
            className='view-another-template-button-wrapper'
            style={!temp.video_url ? { margin: '0 auto' } : {}}>
            <button
              className='no-bg-text-btn view-another-template-button'
              onClick={() => this._unselectTemplate()}>
              <i
                className='fa fa-arrow-left'
                aria-hidden='true'/>{' '}
              View another template
            </button>
          </div>
          <div className='lp-template-preview-content'>
            {temp.video_url && (
              <div className='responsive-player-wrapper'>
                <ReactPlayer
                  className='lp-template-react-player'
                  url={temp.video_url}
                  width='100%'
                  height='100%'
                  controls
                />
              </div>
            )}
            <div className='template-about-text'>
              <div className='template-about-heading'>
                <div
                  className='template-image'
                  style={
                    temp.photo
                      ? { backgroundImage: `url(${temp.photo.file_url})` }
                      : temp.template_photo_url
                        ? { backgroundImage: `url(${temp.template_photo_url})` }
                        : {}
                  }
                >
                  {temp.photo || temp.template_photo_url ? null : (
                    <div
                      className='placeholder-icon'
                      style={{ backgroundImage: `url(${defaultIcon})` }}
                    />
                  )}
                </div>
                <div className='template-about-heading-text'>
                  <h2 className='template-name'>{temp.name}</h2>
                  <div className='template-session-box'>
                    <div
                      className='calendar-icon'
                      style={{ backgroundImage: `url(${calendarIcon})` }}
                    />
                    {this._grabSessionCount(temp.draft_sessions)}
                  </div>
                </div>
              </div>
              <div className='template-note-box'>
                {temp.notes ? (
                  <p>{temp.notes}</p>
                ) : (
                  <p className='notes-placeholder'>(No description)</p>
                )}
              </div>
              <div className='template-tag-container'>
                <span className='tag-title'>Tags:</span>
                {temp.tags.length > 0 ? (
                  temp.tags.map((t, i) => <p key={i}>{t}</p>)
                ) : (
                  <span className='tag-placeholder'>(No tags)</span>
                )}
              </div>
              <button
                className='btn-rectangular btn-rectangular-main-positive select-lp-template-button'
                onClick={() => selectTemplate(temp.id)}
              >
                Use this template
              </button>
            </div>
          </div>
        </div>
      )
    } else {
      return <p>something went wrong</p>
    }
  }

  _unselectTemplate = () => {
    this.setState({ selectedTemplateId: -1 })
  }

  _previewTemplate = id => {
    this.setState({ selectedTemplateId: id })
  }

  _grabSessionCount = json_sessions => {
    const sessions = JSON.parse(json_sessions)
    if (sessions) {
      return (
        <p className='session-count-text'>
          <span>{sessions.length}</span>{' '}
          {`session${sessions.length !== 1 ? 's' : ''}`}
        </p>
      )
    } else {
      return <p className='session-count-text'>No sessions</p>
    }
  }

  _renderTemplates = category => {
    const {
      templates,
      selectTemplate,
      op36Templates,
      is_admin,
      loader,
    } = this.props
    if (category === 0 || is_admin) {
      return templates
        .filter(t => !t.is_draft)
        .map(t => (
          <div
            key={t.id}
            className='template-view animated fadeIn'
            onClick={() => selectTemplate(t.id)}
            onKeyDown={(e) => e.key === 'Enter' && selectTemplate(t.id)}
            role='button'
            tabIndex={0}
          >
            <div
              className='template-image'
              style={
                t.photo
                  ? { backgroundImage: `url(${t.photo.file_url})` }
                  : t.template_photo_url
                    ? { backgroundImage: `url(${t.template_photo_url})` }
                    : {}
              }
            >
              {t.photo || t.template_photo_url ? null : (
                <div
                  className='placeholder-icon'
                  style={{ backgroundImage: `url(${defaultIcon})` }}
                />
              )}
            </div>
            <div className='template-view-content'>
              <p className='template-title'>{t.name}</p>
              <div className='template-session-box'>
                <div
                  className='schedule-icon'
                  style={{ backgroundImage: `url(${calendarIcon})` }}
                />
                {this._grabSessionCount(t.draft_sessions)}
              </div>
            </div>
          </div>
        ))
    }
    if (category === 1) {
      if (loader) {
        return <Loader message={'Grabbing Op36 Templates'} />
      }
      return op36Templates.map(t => (
        <div
          key={t.id}
          className='template-view animated fadeIn'
          onClick={() => this._previewTemplate(t.id)}
          onKeyDown={(e) => e.key === 'Enter' && this._previewTemplate(t.id)}
          role='button'
          tabIndex={0}
        >
          <div
            className='template-image'
            style={
              t.photo ? { backgroundImage: `url(${t.photo.file_url})` } : {}
            }
          >
            {t.photo ? null : (
              <div
                className='placeholder-icon'
                style={{ backgroundImage: `url(${defaultIcon})` }}
              />
            )}
          </div>
          <div className='template-view-content'>
            <p className='template-title'>{t.name}</p>
            <div className='template-session-box'>
              <div
                className='schedule-icon'
                style={{ backgroundImage: `url(${calendarIcon})` }}
              />
              {this._grabSessionCount(t.draft_sessions)}
            </div>
          </div>
        </div>
      ))
    }
  }
}

AddLessonPlanModal.propTypes = {
  close: PropTypes.func,
  is_admin: PropTypes.bool,
  loader: PropTypes.bool,
  op36Templates: PropTypes.array,
  selectTemplate: PropTypes.func,
  selectBlank: PropTypes.func,
  templates: PropTypes.array,
}

export default AddLessonPlanModal
