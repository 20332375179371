import { ReactComponent as CommentsDollarIcon } from '../../../assets/images/common/icons/comments-dollar-duotone.svg'
import { ReactComponent as CloneIcon } from '../../../assets/images/common/icons/clone-regular.svg'
import { ReactComponent as MailIcon } from '../../../assets/images/common/icons/mail-icon.svg'
import styles from './CoachDashboardProgramsOverview.module.scss'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

const ProgramsOverviewAction = ({ programs, spots, enrollments }) => {
  const navigate = useNavigate()
  return (
    <div className={styles.programsOverviewAction}>
      <div className={styles.textContainer}>
        <CommentsDollarIcon className={styles.actionIcon}/>
        <div className={styles.actionText}>
          {programs === 0 &&
            <p>
              You currently have no active programs. Your first step to creating golfers and growing your revenue is making your program live!
            </p>
          }
          {programs > 0 && enrollments === 0 &&
            <>
              <p>You have {programs} programs live{spots ? ` and ${spots} spots available` : '' }.</p>
              <p>
                With your average package being $300 you have a $3000 revenue opportunity.
                Be sure to continue to market your program via email, social media and flyers at your facility.
              </p>
            </>
          }
          {programs > 0 && enrollments > 0 &&
            <>
              <p>Congratulations!</p>
              <p>You have {programs} program(s) live and {enrollments} active enrollment(s).</p>
              <p>Start communicating with your participants via the email tool in the web app,
                 or by using the feed announcement tool in the mobile app.
              </p>
              <p>Easily manage your enrollments in the web.</p>
            </>
          }

        </div>
      </div>

      <div className={styles.buttonContainer}>
        {programs === 0 &&
          <button
            className='btn btn-primary text-white'
            onClick={() => navigate('/programs/new')}
          >
            Build a Program
          </button>
        }
        {programs > 0 && enrollments === 0 &&
          <>
            <button
              onClick={() => navigator.clipboard.writeText(`${process.env.REACT_APP_BASE_URL}/landing/${1}/`)}
              className={styles.actionButton}
            >
              <CloneIcon className={styles.buttonIcon} />
              Copy Link to Landing Page
            </button>
            <button
              className='btn btn-primary text-white'
              onClick={() => navigate('/resource-sections')}
            >
              View Resources
            </button>
          </>
        }

        {programs > 0 && enrollments > 0 &&
          <>
            <button
              onClick={() => navigate('/announcements')}
              className={styles.actionButton}
            >
              <MailIcon className={styles.buttonIcon} />
              Send an Email
            </button>
            <button
              className='btn btn-primary text-white'
              onClick={() => navigate('/programs')}
            >
                Manage Programs
            </button>
          </>
        }
      </div>
    </div>
  )
}

ProgramsOverviewAction.propTypes = {
  programs: PropTypes.number,
  spots: PropTypes.number,
  enrollments: PropTypes.number,
}

export default ProgramsOverviewAction
