import Modal from 'react-modal'
import PropTypes from 'prop-types'

import { ReactComponent as ModalXIcon } from '../../../../assets/images/common/icons/modal-X-icon.svg'
import { ReactComponent as BuilderGolferIcon } from '../../../../assets/images/common/icons/golfer-half-icon-round.svg'
import SubmitButton from '../../../common/buttons/SubmitButton'

import './SaveModal.scss'

const SaveModal = ({ showModal, closeModal, publishProgram, draftProgram }) =>
  <Modal
    isOpen={showModal}
    onRequestClose={closeModal}
    contentLabel='Program Builder Save Modal'
    className='save-program-modal-content'
    autoFocus
  >
    <div id='SaveModal' className='animated fadeIn'>
      <div className='d-flex justify-content-end text-align-right'>
        <ModalXIcon id='modalXIcon' onClick={closeModal} />
      </div>
      <div className='d-flex flex-column justify-content-center align-items-center'>
        <BuilderGolferIcon id='builderGolferIcon' />
        <p className='title'>Save Program</p>
        <p className='text-center message'>
          Publish your Program to the Community or Save it as a Draft and come back to it later.
        </p>
      </div>
      <div className='row py-3'>
        <div className='col-12'>
          <SubmitButton
            onClick={publishProgram}
            buttonMessage='Publish Program'
            buttonColor='orange-button'
          />
        </div>
        <div className='col-12'>
          <SubmitButton
            onClick={draftProgram}
            buttonMessage='Save as Draft'
            buttonColor='navy-button'
          />
        </div>
      </div>
    </div>
  </Modal>

SaveModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  publishProgram: PropTypes.func.isRequired,
  draftProgram: PropTypes.func.isRequired,
}

export default SaveModal
