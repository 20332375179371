import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import "./ResourceRow.scss";

const ResourceRow = ({ resource, isRecent }) => {
  const navigate = useNavigate()

  const goToResource = useCallback(
    () => {
      const { id } = resource

      navigate(
        `/resources/${id}`,
        { state: { resourceId: id } }
      )
    },
    [ navigate, resource ]
  )

  return (
    <div
      id='ResourceRow'
      className='card animation-shrink'
      onClick={goToResource}
    >
      <div className='resource-row-info-wrapper'>
        <div className='resource-image-wrapper'>
          {resource.photo ? (
            <img src={resource.photo.file_url} alt={resource.name} />
          ) : (
            <i className='fa fa-paperclip' />
          )}
        </div>
        <p>{resource.title}</p>
      </div>
      {isRecent && (
        <p className='resource-row-updated-text'>
          updated on {moment(resource.updated_at).format("MM-DD-YYYY")}
        </p>
      )}
    </div>
  );
};

export default ResourceRow;
