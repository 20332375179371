import React, { Component } from 'react';
import { Button } from 'reactstrap';

import GoalList from './GoalList';
import CurriculumGoalView from './CurriculumGoalView';
import CustomGoalView from './CustomGoalView';
import GoalCard from './GoalCard';

import Loader from '../../common/Op36Loader-web';

class GoalFlow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      currentRank: null,
      currentObjective: {},
      percentage: 0,
      completedCount: 0,
      goal: null,
      goalLoader: false
    };
  }

  componentDidMount() {
    this.props.getCurrentRank();
  }

  renderGoalView() {
    if (this.props.page === 1) {
      return (
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-4 hidden-sm-down'>
              <div>
                <Button
                  className='dropdown-rank-toggle private-goal-toggle'
                  disabled={false}
                >
                  <div className='dropdown-rank-desc'>All Curriculums</div>
                </Button>
              </div>
              {this.props.ranksLoader ? (
                <div
                  className={`loading-content ranks-loading curriculums-loading ${
                    this.props.ranksLoader ? '' : ''
                  }`}
                >
                  <Loader message='loading golfer curriculums' />
                </div>
              ) : (
                <GoalCard
                  completedCount={this.props.completedCount}
                  incompleteObjectives={this.props.incompleteObjectives}
                  incrementPage={this.props.incrementPage}
                  percentage={this.props.percentage}
                  currentRank={this.props.currentRank}
                />
              )}
            </div>
            <div className='col-md-8'>
              <GoalList
                user={this.props.user}
                goals={this.props.goals}
                current_user={this.props.current_user}
                ranks={this.props.ranks}
                gotGoals={this.props.gotGoals}
                toggleModal={this.props.toggleModal}
                isPrivateGoals={true}
                customGoalPage={this.props.getCustomGoalPage}
                incrementPage={this.props.incrementPage}
                decrementPage={this.props.decrementPage}
                getCurrentObjective={this.getCurrentObjective}
                objectiveActivities={this.props.objectiveActivities}
              />
            </div>
          </div>
        </div>
      );
    } else if (this.props.page === 2) {
      return (
        <GoalList
          user={this.props.user}
          current_user={this.props.current_user}
          ranks={this.props.ranks}
          loader={this.props.loader}
          getObjectivesFromRank={this.props.getObjectivesFromRank}
          getObjectivesFromRankRow={this.props.getObjectivesFromRankRow}
          rowLoader={this.props.rowLoader}
          gotInitialGoals={this.props.gotInitialGoals}
          rankUp={this.props.rankUp}
          objectives={this.props.objectives}
          toggleRowLoader={this.props.toggleRowLoader}
          toggleCompleteModal={this.props.toggleCompleteModal}
          toggleIncompleteModal={this.props.toggleIncompleteModal}
          toggleDropdown={this.props.toggleDropdown}
          collapse={this.props.collapse}
          currentRank={this.props.currentRank}
          percentage={this.props.percentage}
          isPrivateGoals={false}
          incrementPage={this.props.incrementPage}
          decrementPage={this.props.decrementPage}
          getCurrentObjective={this.props.getCurrentObjective}
          objectiveActivities={this.props.objectiveActivities}
        />
      );
    } else if (this.props.page === 3) {
      return (
        <CurriculumGoalView
          user={this.props.user}
          current_user={this.props.current_user}
          ranks={this.props.ranks}
          incrementPage={this.props.incrementPage}
          decrementPage={this.props.decrementPage}
          objective={this.props.currentObjective}
        />
      );
    } else if (this.props.page === 4) {
      return (
        <CustomGoalView
          user={this.props.user}
          current_user={this.props.current_user}
          updateGoal={this.props.updateGoal}
          reviewTask={this.props.reviewTask}
          goals={this.props.goals}
          goal={this.props.goal}
          loader={this.props.reviewLoader}
          getCustomGoalPage={this.props.getCustomGoalPage}
          startPage={this.props.startPage}
          deleteItem={this.props.deleteItem}
          uploadingPhoto={this.props.uploadingPhoto}
        />
      );
    }
  }

  render() {
    return this.renderGoalView();
  }
}

export default GoalFlow;
