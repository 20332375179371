import React from "react";

import "./ChallengeIndex.scss";

import ChallengeCard from "../subcomponents/ChallengeCard";

class ChallengeIndex extends React.Component {
  render() {
    const {
      challenges,
      handleMakeSelected,
      goToNew,
      leapfrogToDestroy
    } = this.props;
    return (
      <div id='ChallengeIndex'>
        <div className='new-challenge-button-container'>
          <button className='new-challenge-button' onClick={goToNew}>
            New Challenge
          </button>
        </div>
        <div className='challenge-index-content-area'>
          {challenges.map(challenge => {
            return (
              <ChallengeCard
                key={challenge.id}
                challenge={challenge}
                onClick={handleMakeSelected}
                onDelete={leapfrogToDestroy}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export default ChallengeIndex;
