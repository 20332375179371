import React, { Component } from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'
import PropTypes from 'prop-types'

// REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as commonActions from '../../../actions/commonActions'
import * as usersActions from '../../../actions/usersActions'
import * as userActions from '../../../actions/userActions'
import * as authActions from '../../../actions/authActions'

// CHILDREN
import UserRow from '../UserRow'
import AdminUserEditModal from '../Modals/AdminUserEditModal'
import PageHeader from '../../common/PageHeader/PageHeader'
import Placeholder from '../../common/Placeholder'
import Loader from '../../common/Op36Loader-web'
import SearchInput from '../../common/SearchInput'

// ASSETS
import './AdminUsersPage.scss'

class AdminUsersPage extends Component {
  state = {
    users: [],
    usersLoader: false,
    searchTerm: '',
    showEditModal: false,
    updateUserLoader: false,
    deleteUserLoader: false,
    resetPasswordLoader: false,
  }

  componentDidMount() {
    const { common_actions } = this.props
    common_actions.setCurrentTab('Users')
    common_actions.showSideBar(true)
    common_actions.showNavBar(true)
  }

  fetchUser = user => {
    const { user_actions } = this.props
    this.setState(
      {
        userLoader: true,
      },
      () => {
        user_actions.getUser(user.id).then(() => {
          this.setState({
            userLoader: false,
          })
        })
      }
    )
  }

  resetPassword = user => {
    const email = user.family_admin ? user.email : user.username
    const message = user.family_admin
      ? `Reset password email sent to ${user.first_name} ${user.last_name}`
      : `Reset password email sent to the family admin of ${user.first_name} ${
        user.last_name
      }`
    this.setState({ resetPasswordLoader: true })
    this.props.auth_actions.triggerResetPassword(email).then(() => {
      this.setState({ resetPasswordLoader: false })
      toast.success(message, {
        position: toast.POSITION.BOTTOM_LEFT,
      })
    })
  }

  updateUser = user => {
    const { user_actions } = this.props
    this.setState({ userLoader: true, updateUserLoader: true })

    let copyUser = { ... user }

    if (user.type === 'Representative') {
      // eslint-disable-next-line no-unused-vars
      const { family_id, birthdate, ...rest } = copyUser
      copyUser = rest
    }

    switch (user.type) {
      case 'Coach':
        copyUser.type = 'Coach'
        break
      case 'Family Admin':
        copyUser.type = 'Student'
        break
      case 'Golfer':
        copyUser.type = 'Student'
        break
      case 'Student':
        copyUser.type = 'Student'
        break
      case 'Representative':
        copyUser.type = 'Representative'
        break
      default:
        return null
    }

    user_actions.updateUser(copyUser).then(() => {
      this.setState({
        searchTerm: '',
        updateUserLoader: false,
        userLoader: false,
      })
      if (_.isEmpty(this.props.error)) {
        this.closeEditModal()
        toast.success(`Success! Updated ${user.first_name} ${user.last_name}`, {
          position: toast.POSITION.BOTTOM_LEFT,
        })
      } else {
        toast.error(
          `Something went wrong updating ${user.first_name} ${user.last_name}`,
          {
            position: toast.POSITION.BOTTOM_LEFT,
          }
        )
      }
    })
  }

  deleteUser = user => {
    const { user_actions } = this.props
    this.setState({ deleteUserLoader: true })

    user_actions.adminDeleteUser(user.id).then(() => {
      this.setState({
        searchTerm: '',
        deleteUserLoader: false,
        users: [...this.props.users],
      })
      if (_.isEmpty(this.props.error)) {
        this.closeEditModal()
        toast.success(`Success! Deleted ${user.first_name} ${user.last_name}`, {
          position: toast.POSITION.BOTTOM_LEFT,
        })
      } else {
        toast.error(
          `Something went wrong deleting ${user.first_name} ${user.last_name}`,
          {
            position: toast.POSITION.BOTTOM_LEFT,
          }
        )
      }
    })
  }

  toggleEditModal = user => {
    this.setState(
      {
        showEditModal: true,
      },
      () => {
        this.fetchUser(user)
      }
    )
  }

  closeEditModal = () => {
    this.setState({
      showEditModal: false,
    })
  }

  resetState = () => {
    this.setState({
      users: [],
      usersLoader: false,
    })
  }

  searchUsers = () => {
    const { searchTerm } = this.state

    if (searchTerm.length < 5) {
      return
    }

    this.setState({ usersLoader: true })
    this.props.users_actions.searchUsers(searchTerm).then(() => {
      this.setState({
        users: [...this.props.users],
        usersLoader: false,
      })
    })
  }

  debouncedFetchUsers = _.debounce(this.searchUsers, 700)

  searchUpdated = e => {
    const term = e.target.value

    if (!term) {
      this.resetState()
      return
    }

    this.setState({ searchTerm: term }, () => {
      this.debouncedFetchUsers()
    })
  }

  renderUsers = () => {
    const users = this.state.users

    if (users.length === 0) {
      return (
        <div className='col-md-6 mx-auto'>
          <Placeholder
            title={'No results found'}
            icon={'fa-search'}
            subtext1={'Please Try again'}
          />
        </div>
      )
    }

    return users.map(user => (
      <UserRow user={user} toggleModal={this.toggleEditModal} key={user.id} />
    ))
  }

  renderUserTable = () => {
    const { users } = this.state
    if (users && users.length > 0) {
      return (
        <div className='container-fluid mx-auto'>
          <div className='row w-100 users-table-header'>
            <div className='col-1'>
              <p>Id</p>
            </div>
            <div className='col-3'>
              <p>Name</p>
            </div>
            <div className='col-1'>
              <p>Type</p>
            </div>
            <div className='col-3'>
              <p>Email / Username</p>
            </div>
            <div className='col-3'>
              <p>Community</p>
            </div>
          </div>
          <div>{this.renderUsers()}</div>
        </div>
      )
    } else {
      return (
        <div className='col-md-6 mx-auto'>
          <Placeholder
            title={'Search for User'}
            icon={'fa-search'}
            subtext1={'Start typing to search for a user'}
          />
        </div>
      )
    }
  }

  render() {
    const {
      usersLoader,
      showEditModal,
      userLoader,
      updateUserLoader,
      resetPasswordLoader,
      deleteUserLoader,
    } = this.state
    const { user, error } = this.props

    return (
      <div id='AdminUsersPage'>
        <div className='animated fadeIn'>
          <PageHeader title='Users' />
          <div className='users-search-bar mt-2'>
            <SearchInput placeholder='Search Users' onChange={this.searchUpdated}/>
          </div>
          <div className='row'>
            {usersLoader ? (
              <Loader message={'Loading Users'} />
            ) : (
              this.renderUserTable()
            )}
          </div>
        </div>
        {showEditModal && (
          <AdminUserEditModal
            user={user}
            error={error}
            updateUser={this.updateUser}
            deleteUser={this.deleteUser}
            resetPassword={this.resetPassword}
            showModal={showEditModal}
            closeModal={this.closeEditModal}
            loader={userLoader}
            updateLoader={updateUserLoader}
            deleteLoader={deleteUserLoader}
            resetPasswordLoader={resetPasswordLoader}
          />
        )}
      </div>
    )
  }
}

AdminUsersPage.propTypes = {
  user: PropTypes.object,
  users: PropTypes.array,
  error: PropTypes.object,
  auth_actions: PropTypes.shape({
    triggerResetPassword: PropTypes.func,
  }),
  common_actions: PropTypes.shape({
    showSideBar: PropTypes.func,
    showNavBar: PropTypes.func,
    setCurrentTab: PropTypes.func,
  }),
  user_actions: PropTypes.shape({
    adminDeleteUser: PropTypes.func,
    updateUser: PropTypes.func,
    getUser: PropTypes.func,
  }),
  users_actions: PropTypes.shape({
    searchUsers: PropTypes.func,
  }),
}

function mapStateToProps(state) {
  return {
    ui: state.ui,
    user: state.user.user,
    current_user: state.user.current_user,
    users: state.users,
    error: state.ui.error || {},
  }
}

function mapDispatchToProps(dispatch) {
  return {
    common_actions: bindActionCreators(commonActions, dispatch),
    users_actions: bindActionCreators(usersActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
    auth_actions: bindActionCreators(authActions, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminUsersPage)
