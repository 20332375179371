import * as types from '../actions/actionTypes'

export default function events(state = { events: null, pagination: {} }, action) {
  switch (action.type) {
    case types.GET_EVENTS:
      return {
        events: action.events,
        pagination: action.pagination,
      }

    default:
      return state
  }
}
