import React, { Component } from "react";
import PropTypes from 'prop-types'
import { Button, ButtonGroup, Collapse } from "reactstrap";

// REDUX BITS
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as objectiveActions from "../../../actions/objectiveActions";

import Placeholder from "../../common/Placeholder";
import Loader from "../../common/Op36Loader-web";
import "./Goals.scss";

const AUTOCOMPLETE_OBJECTIVES = ['performance', 'work-ethic']

class GoalRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapse: false,
      rowLoader: true,
      objectives: [],
      incompleteObjectives: [],
      gotInitialGoals: false,
      completeGoalsExist: -1,
      noCurrentGoals: false,
    };
  }

  componentDidMount() {
    if (this.props.goals) {
      this.checkGoals();
    }

    if (this.props.rank) {
      if (this.props.user.user_curriculum.rank_id === this.props.rank.id) {
        this.setState({
          collapse: true,
        });
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.goals) {
      if (nextProps.goals.length !== this.props.goals.length) {
        this.resetGoalState();
      }
    }
  }

  resetGoalState = () => {
    this.setState({
      noCurrentGoals: false,
    });
  };

  toggleDropdown = (rank) => {
    if (rank) {
      if (
        this.props.objectives.length === 0 &&
        this.props.rank.progress_percentage !== 100
      ) {
        this.props.toggleRowLoader(true);
        this.setState({ collapse: !this.state.collapse }, () => {
          this.props.getObjectivesFromRankRow(
            this.props.rank.id,
            this.props.user.id
          );
        });
      } else {
        this.setState({ collapse: !this.state.collapse });
      }

      if (
        this.props.rank.progress_percentage === 100 &&
        this.props.type === "complete"
      ) {
        this.props.toggleRowLoader(true);
        this.setState({ collapse: !this.state.collapse }, () => {
          this.props.getObjectivesFromRankRow(
            this.props.rank.id,
            this.props.user.id
          );
        });
      }

      if (
        this.props.rank.progress_percentage === 100 &&
        this.props.type === "current"
      ) {
        this.props.toggleRowLoader(false);
        this.setState({ collapse: !this.state.collapse });
      }
    } else {
      this.props.toggleRowLoader(false);
      this.setState({ collapse: !this.state.collapse });
    }

    if (
      this.props.type === "current" &&
      this.props.rank.progress_percentage < 100
    ) {
      this.props.toggleRowLoader(true);
      this.setState({ collapse: !this.state.collapse }, () => {
        this.props.getObjectivesFromRankRow(
          this.props.rank.id,
          this.props.user.id
        );
      });
    }
  };

  displayObjectiveCard = (objective) => {
    if (this.props.isPrivateGoals) {
      return (
        <div
          className='goal-objective-wrapper'
          onClick={() => this.props.customGoalPage(objective)}
        >
          <div className='goal-objective-description-container'>
            <div
              className='goal-objective-private-image-container'
              style={{ backgroundColor: '#ff6633' }}
            >
              {/* <img src='' alt={objective.name} className='goal-objective-image' /> */}
              <i
                className='fa fa-check-circle goal-objective-icon'
                aria-hidden='true'
              />
            </div>
            <div>
              <div className='goal-objective-name-wrapper'>
                <h5 className='goal-objective-name'>{objective.name}</h5>
                <div className='progress hidden-sm-down'>
                  <div
                    className='progress-bar'
                    role='progressbar'
                    style={{
                      width: `${
                        !objective.progress_percentage
                          ? "0"
                          : objective.progress_percentage
                      }%`,
                      backgroundColor: '#ff6633',
                    }}
                    aria-valuenow={
                      !objective.progress_percentage
                        ? 0
                        : objective.progress_percentage
                    }
                    aria-valuemin='0'
                    aria-valuemax='100'
                  >
                    {!objective.progress_percentage
                      ? ""
                      : `${objective.progress_percentage}%`}
                  </div>
                </div>
              </div>
              <p className='goal-objective-desc hidden-sm-down'>
                {objective.description}
              </p>
            </div>
          </div>
          <div className='goal-objective-nav-wrapper'>
            <div className='goal-objective-btn-wrapper'>
              {/* {objective.is_completed ?
                null :
                <div>
                  <button className="btn btn-primary btn-block goal-objective-btn">Review</button>
                  <button className="btn btn-block btn-success goal-objective-btn">Complete</button>
                </div>
              } */}
            </div>
            <div className='goal-objective-view-wrapper'>
              <button
                className={`goal-objective-view ${
                  objective.is_completed ? "goal-objective-completed" : ""
                }`}
                onClick={() => this.props.customGoalPage(objective)}
              >
                <i className='fa fa-chevron-right mb-3' aria-hidden='true' />
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className='goal-objective-wrapper'>
          <div className='goal-objective-description-container'>
            <div
              className='goal-objective-image-container'
              style={{ backgroundColor: `${this.props.rank.color_hex}` }}
            >
              <img
                src={objective.subject.icon.file_url}
                alt={objective.name}
                className='goal-objective-image'
              />
            </div>
            <div>
              <div className='goal-objective-name-wrapper'>
                <h5 className='goal-objective-name'>{objective.name}</h5>
                <div className='goal-objective-subject-wrapper'>
                  <h5 className='goal-objective-subject'>
                    {objective.subject.name}
                  </h5>
                </div>
              </div>
              <p className='goal-objective-desc'>{objective.description}</p>
            </div>
          </div>
          <div className='goal-objective-btn-wrapper'>
            {this.props.current_user?.type === 'Coach' &&
              this.props.type === 'current' &&
              !AUTOCOMPLETE_OBJECTIVES.includes(objective.subject.key) && (
                <button
                  className='btn btn-success btn-block goal-objective-btn'
                  onClick={() =>
                    this.props.toggleCompleteModal(objective, this.props.user)
                  }
                >
                  Complete
                </button>
              )}

            {this.props.current_user &&
              this.props.current_user.type === "Coach" &&
              this.props.type === 'complete' &&
              !AUTOCOMPLETE_OBJECTIVES.includes(objective.subject.key) && (
                <button
                  className='btn btn-dark btn-block goal-objective-btn'
                  onClick={() =>
                    this.props.toggleIncompleteModal(objective, this.props.user)
                  }
                >
                  Remove
                </button>
              )}
            <button
              className='goal-objective-next-btn'
              onClick={() => this.props.getCurrentObjective(objective)}
            >
              <i className='fa fa-chevron-right mb-3' aria-hidden='true' />
            </button>
          </div>
        </div>
      );
    }
  };

  checkGoals = () => {
    let goals = [...this.props.goals];
    let completedGoals = [];

    for (let i = 0; i < goals.length; i++) {
      if (goals[i].is_completed) {
        this.setState({
          completeGoalsExist: 1,
        });
        completedGoals.push(i);
      }
    }

    if (completedGoals.length === goals.length) {
      this.setState({
        noCurrentGoals: true,
      });
    }
  };

  displayObjectives = (rank, type) => {
    if (this.props.isPrivateGoals) {
      if (this.state.noCurrentGoals && this.props.type === "current") {
        return (
          <Placeholder
            title={"No Open Private Goals"}
            icon={"fa-check-circle"}
            subtext1={"There are currently no private goals open"}
          />
        );
      } else if (
        this.state.completeGoalsExist === -1 &&
        this.props.type === "complete"
      ) {
        return (
          <Placeholder
            title={"No Completed Private Goals"}
            icon={"fa-check-circle"}
            subtext1={"There are currently no private goals completed"}
          />
        );
      }
      if (this.props.goals) {
        return this.props.goals.map((goal) => {
          if (
            this.props.type === "complete" &&
            goal.progress_percentage === 100 &&
            goal.is_completed
          ) {
            return (
              <div key={goal.id} className='goal-objective-container'>
                {this.displayObjectiveCard(goal)}
              </div>
            );
          } else if (this.props.type === "current" && !goal.is_completed) {
            return (
              <div key={goal.id} className='goal-objective-container'>
                {this.displayObjectiveCard(goal)}
              </div>
            );
          }
        });
      }
    } else {
      if (
        this.props.rank.progress_percentage === 100 &&
        this.props.type === "current"
      ) {
        return (
          <div
            className=''
            style={{
              height: "100px",
              marginTop: "0px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4 style={{ textAlign: "center", textTransform: "uppercase" }}>
              All Goals Completed
            </h4>
          </div>
        );
      }
      if (
        !this.props.gotInitialGoals &&
        this.props.user.user_curriculum.rank_id === this.props.rank.id
      ) {
        this.props.getObjectivesFromRankRow(
          this.props.user.user_curriculum.rank_id,
          this.props.user.id
        );
      }
      if (this.props.rowLoader) {
        return (
          <div className='' style={{ marginTop: "-190px" }}>
            {/* <Loader message="loading golfer goals" /> */}
          </div>
        );
      } else if (
        !this.props.rowLoader &&
        this.props.rank.progress_percentage === 100 &&
        this.props.type === "complete"
      ) {
        return this.props.objectives.map((objective) => {
          return (
            <div key={objective.id} className='goal-objective-container'>
              {this.displayObjectiveCard(objective)}
            </div>
          );
        });
      } else if (!this.props.rowLoader && this.props.type === "current") {
        return this.props.objectives.map((objective) => {
          if (!objective.completed_date) {
            return (
              <div key={objective.id} className='goal-objective-container'>
                {this.displayObjectiveCard(objective)}
              </div>
            );
          } else {
            return null;
          }
        });
      } else if (!this.props.rowLoader && this.props.type === "complete") {
        return this.props.objectives.map((objective) => {
          if (objective.completed_date) {
            return (
              <div key={objective.id} className='goal-objective-container'>
                {this.displayObjectiveCard(objective)}
              </div>
            );
          }
        });
      }
    }
  };

  renderIcon = () => {
    if (this.props.rank.progress_percentage === 100) {
      return <i className='fa fa-check-circle' aria-hidden='true' />;
    }
    if (this.props.user.user_curriculum.rank_id === this.props.rank.id) {
      return (
        <div className='goal-objective-active-icon'>
          <p className='goal-objective-active-text'>Active</p>
        </div>
      );
    }
    if (
      this.props.user.user_curriculum.rank_id < this.props.rank.id
    ) {
      return <i className='fa fa-lock' aria-hidden='true' />;
    }
  };

  renderToggleIcon = () => {
    if (this.props.user.user_curriculum.rank_id === this.props.rank.id) {
      return null;
    }

    if (this.state.collapse) {
      return (
        <i
          className='fa fa-chevron-up'
          aria-hidden='true'
          style={{ marginLeft: "20px" }}
        />
      );
    } else if (!this.state.collapse && !this.state.completedGoals) {
      return (
        <i
          className='fa fa-chevron-down'
          aria-hidden='true'
          style={{ marginLeft: "20px" }}
        />
      );
    }
  };

  goalSwitcher = () => {
    return (
      <ButtonGroup
        className={`goal-switch-button-wrapper ${
          this.props.isMobile ? "mobile-goal-switcher" : ""
        }`}
      >
        <Button
          className={`goal-switch-button ${
            this.props.selectedGoalType === "current"
              ? "active-goal-button"
              : ""
          }`}
          onClick={() => this.props.goalTypeSwitcher("current")}
        >
          Open
        </Button>
        <Button
          className={`goal-switch-button ${
            this.props.selectedGoalType === "complete"
              ? "active-goal-button"
              : ""
          }`}
          onClick={() => this.props.goalTypeSwitcher("complete")}
        >
          Completed
        </Button>
      </ButtonGroup>
    );
  };

  render() {
    return (
      <div>
        {this.props.isPrivateGoals ? (
          <div>
            {this.props.isMobile ? (
              <div className='private-goal-toggle'>{this.goalSwitcher()}</div>
            ) : (
              <Button className='dropdown-rank-toggle private-goal-toggle'>
                <div className='dropdown-rank-desc'>
                  {this.props.user.first_name}
                  's Private Goals
                </div>
                <div className='private-goal-switcher'>
                  {this.goalSwitcher()}
                </div>
              </Button>
            )}
            <Collapse isOpen={true}>
              {this.displayObjectives(this.props.goal)}
            </Collapse>
          </div>
        ) : (
          <div>
            <Button
              className='dropdown-rank-toggle'
              onClick={() => this.toggleDropdown(this.props.rank)}
              disabled={this.props.user.user_curriculum.rank_id < this.props.rank.id}
            >
              <div className='dropdown-rank-desc'>
                <div
                  style={{
                    height: "30px",
                    width: "30px",
                    borderRadius: "50%",
                    backgroundColor: this.props.rank.color_hex,
                    marginRight: "10px",
                  }}
                />
                {this.props.rank.name}
              </div>
              <div className='goal-rank-icon-wrapper'>
                <div className='progress goal-rank-progress'>
                  <div
                    className='progress-bar goal-rank-progress-bar'
                    role='progressbar'
                    style={{
                      width: `${this.props.rank.progress_percentage}%`,
                      backgroundColor: this.props.rank.color_hex,
                    }}
                    aria-valuenow={this.props.rank.progress_percentage}
                    aria-valuemin='0'
                    aria-valuemax='100'
                  >
                    {this.props.rank.progress_percentage}%
                  </div>
                </div>
                {this.renderIcon()}
                {this.renderToggleIcon()}
              </div>
            </Button>
            <Collapse isOpen={this.state.collapse}>
              {this.displayObjectives(this.props.rank)}
            </Collapse>
          </div>
        )}
      </div>
    );
  }
}

GoalRow.propTypes = {
  user: PropTypes.shape({
    user_curriculum: PropTypes.shape({
      rank_id: PropTypes.number,
    }),
  }),
  current_user: PropTypes.shape({
    type: PropTypes.string,
  }),
  rank: PropTypes.shape({
    id: PropTypes.number,
  }),
  type: PropTypes.string,
}

function mapStateToProps(state, ownProps) {
  return {
    objectives: state.objectives[ownProps.rank?.order],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(objectiveActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GoalRow);
