import React, { Component } from "react";
import Modal from "react-modal";
import moment from "moment";

import "../../assets/styles/components/annoucements/CampaignPage.scss";

class SimplePreviewModal extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  createMarkup = () => {
    return { __html: this.props.announcement.message };
  };

  render() {
    return (
      <Modal
        isOpen={this.props.showModal}
        onRequestClose={() => this.props.closeModal()}
        contentLabel="Announcement Modal"
      >
        <div className="modal-header row m-0 p-0">
          <div className="col-11 m-0 p-0">
            <h5 className="modal-title display-4">
              Reviewing{" "}
              {this.props.announcement.subject !== ""
                ? "Email"
                : "Announcement"}{" "}
              |{" "}
              {moment(this.props.announcement.created_at).format("MM-DD-YYYY")}
            </h5>
          </div>
          <div className="col-1 m-0 p-0">
            <button
              type="button"
              className="close pull-right font-200"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => this.props.closeModal()}
            >
              <span aria-hidden="true">✕</span>
            </button>
          </div>
        </div>
        <div className="container">
          <div className="row mt-4">
            <div className="col-md-12">
              {this.props.announcement.subject !== "" ? (
                <div>
                  <h3>Subject</h3>
                  <p className="border p-3">
                    {this.props.announcement.subject}
                  </p>
                  <h3>Message</h3>
                  <div
                    className="border p-3"
                    dangerouslySetInnerHTML={this.createMarkup()}
                  />
                </div>
              ) : (
                <div>
                  <h3>Message</h3>
                  <p className="border p-3">
                    {this.props.announcement.message}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default SimplePreviewModal;
