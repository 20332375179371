import React from "react";
import { Collapse, Button } from "reactstrap";

import RankObjectivesCard from "./RankObjectivesCard";

class Rank extends React.Component {
  render() {
    const { rank, isOpen, handleOpen } = this.props;
    return (
      <div>
        <Button
          className='mb-1'
          onClick={() => (isOpen ? handleOpen(0) : handleOpen(rank.id))}
          style={{ width: "100%", background: "#2a3743" }}
        >
          <div
            className='d-flex align-items-center'
            style={{ fontSize: "1.5rem" }}
          >
            <div
              style={{
                height: "30px",
                width: "30px",
                borderRadius: "50%",
                backgroundColor: rank.color_hex,
                marginRight: "10px"
              }}
            />
            {rank.name}
          </div>
        </Button>
        <Collapse isOpen={isOpen}>
          {rank.objectives.map(obj => {
            return (
              <div key={obj.id}>
                <RankObjectivesCard
                  color={rank.color_hex}
                  icon={obj.subject.icon.file_url}
                  subject={obj.subject.name}
                  name={obj.name}
                  description={obj.description}
                  rankId={rank.id}
                  id={obj.id}
                />
              </div>
            );
          })}
        </Collapse>
      </div>
    );
  }
}

export default Rank
