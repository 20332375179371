import React, { Component } from "react";
import Modal from "react-modal";
import Loader from "../../../common/Op36Loader-web";
import StudentPreviewCard from "../../../common/StudentPreviewCard";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as goalActions from "../../../../actions/goalActions";

class AddStudentsModal extends Component {
  state = {
    selectedStudents: []
  };

  filterStudents = id => {
    const selectedStudents = [...this.state.selectedStudents];

    let index = selectedStudents.indexOf(id);

    if (index > -1) {
      selectedStudents.splice(index, 1);
    } else if (index === -1) {
      selectedStudents.push(id);
    }

    this.setState({ selectedStudents });
  };

  assignStudents = () => {
    const selectedStudents = [...this.state.selectedStudents];
    const { closeModal, goal_actions, goal, getActiveStudents } = this.props;

    goal_actions.createGoalsFromTemplate(goal, selectedStudents).then(() => {
      getActiveStudents(goal);
    });
    this.setState({ selectedStudents: [] }, () => closeModal());
  };

  renderStudents = (students, loading) => {
    if (loading || !students) return <Loader message={"Loading Students"} />;
    if (students.length === 0) return <p>No Students</p>;
    const selectedStudents = [...this.state.selectedStudents];
    return students.map(student => (
      <StudentPreviewCard
        student={student}
        key={student.id}
        filterStudents={this.filterStudents}
        selectedStudents={selectedStudents}
      />
    ));
  };

  render() {
    const { showModal, closeModal, students, loading } = this.props;
    const { selectedStudents } = this.state;
    return (
      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        contentLabel="Goal Modal"
      >
        <div className="modal-header row">
          <div className="col-10">
            <h5 className="modal-title display-4 uppercase detail-title">
              Add Students
            </h5>
          </div>
          <div className="col-2">
            <button
              type="button"
              className="close pull-right font-200 modal-close-btn"
              data-dismiss="modal"
              aria-label="Close"
              onClick={closeModal}
            >
              <span aria-hidden="true">✕</span>
            </button>
          </div>
        </div>
        <div className="row w-50 mx-auto mt-3">
          {this.renderStudents(students, loading)}
        </div>
        {!loading && (
          <div className="row bg-white pb-3 mt-5 animated fadeIn">
            <div className="col-6 mx-auto">
              <div className="row">
                <div className="col-md-6">
                  <button className="btn w-100 btn-dark" onClick={closeModal}>
                    Cancel
                  </button>
                </div>
                <div className="col-md-6">
                  <button
                    className="btn btn-block btn-primary"
                    disabled={selectedStudents.length === 0}
                    onClick={this.assignStudents}
                  >
                    Assign Students
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    goal_actions: bindActionCreators(goalActions, dispatch)
  };
}

export default connect(
  null,
  mapDispatchToProps
)(AddStudentsModal);
