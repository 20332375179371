import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styles from '../../../assets/styles/components/common/SideBarItem.module.scss'
import SideBarIcon from './SideBarIcon'
import { useDispatch } from 'react-redux'
import { setSideBarState } from '../../../actions/commonActions'
import { NAVIGATION } from '../../../misc/Constants'
import classNames from 'classnames'

const SideBarIconLink = ({ icon, links = [], singleLink, index, selected }) => {
  const dispatch = useDispatch()

  return (
    singleLink && links[0].external ?
      <a href={links[0].external}
        target='_blank'
        data-for={`sidebar-tooltip-${index}`}
        data-tip
        className={classNames(styles.sideBarItem, { [styles.selectedSideBarItem]: selected })}
      >
        <SideBarIcon icon={icon} className={styles.icon}/>
      </a>
      :
      <Link to={singleLink ? links[0].to : '#'}
        data-for={`sidebar-tooltip-${index}`}
        data-tip
        className={classNames(styles.sideBarItem, { [styles.selectedSideBarItem]: selected })}
        onClick={() => !singleLink && dispatch(setSideBarState(NAVIGATION.EXPANDED))}
      >
        <SideBarIcon icon={icon} className={styles.icon}/>
      </Link>
  )
}

SideBarIconLink.propTypes = {
  icon: PropTypes.string,
  links: PropTypes.array,
  singleLink: PropTypes.bool,
  index: PropTypes.number,
  changeNavigation: PropTypes.func,
  selected: PropTypes.object,
}

export default SideBarIconLink
