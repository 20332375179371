import React from "react";
import Avatar from "../common/avatar/avatar.js";
import moment from "moment";
import "../../assets/styles/components/activity/Comment.scss";

const Comment = ({ user, user_image, body, web, time }) => {
  return (
    <div id={web ? "comment-web-wrapper" : ""} className='row comment'>
      <div
        className={
          web
            ? "col-3 commentUserPicture pr-1 commentUserPictureWeb"
            : "col-3 commentUserPicture pr-1"
        }
      >
        <Avatar
          className='img-responsive center-block pull-right'
          imageUrl={user.avatar ? user.avatar.file_url : null}
          hasCoachLabel={user.type === "Coach"}
          hasBorder={user.type === "Coach"}
          altName={user.first_name + " " + user.last_name}
          web={web}
        />
      </div>
      <div className='col-9 commentContent'>
        <h6 className='commentUserName'>
          {user.first_name} {user.last_name}
        </h6>

        <p className='commentBody'>{body}</p>
        {web ? (
          <span className='comment-time'>{moment(time).fromNow()}</span>
        ) : null}
      </div>
    </div>
  );
};

export default Comment;
