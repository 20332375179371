import * as types from '../actions/actionTypes'

export default function currencies(state = [], action) {
  switch (action.type) {
    case types.GET_CURRENCIES:
      return action.currencies;
    default:
      return state;
  }
}
