import * as constants from "./actionTypes";
import Axios from '../axios'

export function successRequestingGetRepresentativeActionTypes(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_REPRESENTATIVE_ACTION_TYPES,
      representative_action_types: response.data.representative_action_types
    };
  } else {
    console.log("ERROR GETTING REPRESENTATIVES");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

// THUNKS HERE
export function getRepresentativeActionTypes() {
  return function(dispatch) {
    return requestGetRepresentativeActionTypes().then(response =>
      dispatch(successRequestingGetRepresentativeActionTypes(response))
    );
  };
}

// API CALLS
function requestGetRepresentativeActionTypes() {
  let requestUrl = "/api/v1/representative_action_types";

  return Axios.get(requestUrl);
}
