import { useCallback } from 'react'
import ReactDatePicker from 'react-datepicker'
import moment from 'moment'
import PropTypes from 'prop-types'

const DatePicker = ({ id, selected, onChange, placeholder, ...rest }) => {
  const innerSelected = selected ? selected.toDate() : selected

  const innerOnChange = useCallback(
    (value) => {
      const momentValue = value ? moment(value) : value

      onChange && onChange(momentValue)
    },
    [ onChange ]
  )

  return (
    <ReactDatePicker
      id={id}
      selected={innerSelected}
      onChange={innerOnChange}
      placeholderText={placeholder}
      {...rest}
    />
  )
}

export default DatePicker

DatePicker.propTypes = {
  id: PropTypes.string,
  selected: PropTypes.instanceOf(moment),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
}
