import * as types from "../actions/actionTypes";
// import update from 'immutability-helper';
// state = [] : Start out with no accounts
export default function proShop(state = [], action) {
  switch (action.type) {
    case types.GET_PROSHOP_DIVISION_LEADERBOARD:
      return Object.assign({}, state, {
        division_leaderboard: action.division_leaderboard
      });

    case types.GET_PROSHOP_COACH_DIVISION_LEADERBOARD:
      return Object.assign({}, state, {
        coach_division_leaderboard: action.coach_division_leaderboard
      });

    case types.GET_PROSHOP_COMMUNITY_LEADERBOARD:
      return Object.assign({}, state, {
        community_leaderboard: action.community_leaderboard
      });

    case types.GET_PROSHOP_PHOTO_WALL:
      return Object.assign({}, state, {
        photo_wall: action.photo_wall
      });

    case types.GET_PROSHOP_COMMUNITY_STATS:
      return Object.assign({}, state, {
        community_stats: action.community_stats
      });

    case types.ADD_COACH_AD:
      return Object.assign({}, state, {
        community_ads: action.community_ads
      });

    case types.POST_PINNED_ADS:
      return Object.assign({}, state, {
        community_ads: action.community_ads
      });

    case types.DELETE_COACH_AD:
      return Object.assign({}, state, {
        community_ads: action.community_ads
      });

    case types.GET_ALL_PRO_SHOP:
      return action.stats;

    default:
      return state;
  }
}
