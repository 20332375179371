// This was originally JSON, but there were issues parsing it for some reason

// These questions are required to create a free Op36 coach account.
const registrationQuestions = [
  {
    id: "sign_up_reasons",
    question: "Which best describes your interest?",
    type: "multiple_choice",
    ans_choices: [
      "Learn more about starting an Operation 36 Program",
      "Get access to the free Coach Academy video training",
      "BOTH!"
    ],
    other_choice: false,
    multi_select: false,
  },
  {
    id: "job_title",
    question: "Which best describes you?",
    type: "multiple_choice",
    ans_choices: [
      "PGA/PGM Student/Apprentice",
      "Assistant Golf Professional",
      "Head Golf Professional",
      "Director of Golf",
      "Golf Teaching Professional",
      "Golf Facility Manager",
      "Golf Business Owner",
      "Other golf-related position",
      "I don’t work in golf"
    ],
    other_choice: false,
    multi_select: false,
  },

  {
    id: "first_last_name",
    question: "What’s your name?",
    desc: "",
    type: "input",
    input_fields: [
      { type: "text", placeholder: "First name" },
      { type: "text", placeholder: "Last name" }
    ],
    errorMsgs: [
      "Please enter your first name",
      "Please enter your last name"
    ]
  },
  {
    id: "email",
    question: "What’s your email?",
    desc: "You will use this to log into your account.",
    type: "input",
    input_fields: [
      { type: "email", placeholder: "Email" }
    ],
    errorMsgs: [
      "Please enter a valid email address",
      "Email address already in use"
    ]
  },
  {
    id: "phone",
    question: "What’s your cell phone number?",
    desc: "Golf programs are designed to be run by humans, not robots. We will send you a verification code via SMS. :)",
    type: "input",
    input_fields: [
      { type: "tel", placeholder: "e.g. +1 (555) 555-5555" }
    ],
    errorMsgs: [
      "Phone number must start with a \"+\" and be up to 15 digits long"
    ]
  },
  {
    id: "verification_code",
    question: "Verify your identity:",
    desc: "Enter the 6-digit code texted to [number] below.",
    type: "input",
    input_fields: [
      { type: "auth_code", placeholder: "" }
    ],
    errorMsgs: [
      "Please enter the 6-digit code"
    ]
  },
  {
    id: "password",
    question: "Final step! Create a password:",
    desc: "It must be at least 6 characters long.",
    type: "input",
    input_fields: [
      { type: "password", placeholder: "Password" },
      { type: "password", placeholder: "Confirm password" }
    ],
    errorMsgs: [
      "Please enter a password",
      "Password must be at least 6 characters",
      "Passwords do not match"
    ]
  }
]

export default registrationQuestions;
