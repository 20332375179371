import React from "react";
import moment from "moment";

import ChallengeForm from "../subcomponents/ChallengeForm";

class ChallengeEdit extends React.Component {
  state = {
    name: "",
    points: 0,
    startDate: null,
    endDate: null,
    video_url: "",
    description: "",
    uploadPhotoSrc: "",
    uploadIconSrc: "",
    failedValidation: [],
    links: [],
    submitClicked: false
  };

  componentDidMount() {
    const { selectedChallenge } = this.props;

    this.setState({
      name: selectedChallenge.name,
      points: selectedChallenge.points,
      startDate: selectedChallenge.start_date
        ? moment(selectedChallenge.start_date)
        : '',
      endDate: selectedChallenge.end_date
        ? moment(selectedChallenge.end_date)
        : '',
      video_url: selectedChallenge.video_url || "",
      description: selectedChallenge.description,
      uploadPhotoSrc: selectedChallenge.photo
        ? selectedChallenge.photo.file_url
        : "",
      uploadIconSrc: selectedChallenge.icon
        ? selectedChallenge.icon.file_url
        : "",
      links: selectedChallenge.links ? selectedChallenge.links : []
    });
  }
  render() {
    const {
      name,
      points,
      startDate,
      endDate,
      video_url,
      description,
      uploadPhotoSrc,
      uploadIconSrc,
      failedValidation,
      submitClicked,
      links
    } = this.state;
    return (
      <div id='ChallengeEdit'>
        <div className='return-button-container'>
          <button
            className='return-challenge-button'
            onClick={this.props.returnToIndex}
          >
            Back to Challenges
          </button>
        </div>
        <h3 className='text-center'>Edit Challenge!</h3>
        <ChallengeForm
          name={name}
          points={points}
          startDate={startDate}
          endDate={endDate}
          video_url={video_url}
          description={description}
          handleInputChange={this._handleInputChange}
          handleStartChange={this._handleStartChange}
          handleEndChange={this._handleEndChange}
          grabPhotoData={this._grabPhotoData}
          grabIconData={this._grabIconData}
          selectedPhoto={uploadPhotoSrc}
          selectedIcon={uploadIconSrc}
          failedValidation={failedValidation}
          submitClicked={submitClicked}
          handleSubmitClick={this._handleSubmitClick}
          isEdit={true}
          links={links}
          addNewLink={this._addNewLink}
          removeLink={this._removeLink}
          handleDisplayName={this._handleDisplayName}
          handleLinkUrl={this._handleLinkUrl}
        />
      </div>
    );
  }

  _handleDisplayName = (e, i) => {
    let val = e.target.value;
    const links = [...this.state.links];
    links[i].display_name = val;
    this.setState({ links });
  };

  _handleLinkUrl = (e, i) => {
    let val = e.target.value;
    const links = [...this.state.links];
    links[i].link_url = val;
    this.setState({ links });
  };

  _removeLink = i => {
    const links = [...this.state.links];
    links.splice(i, 1);
    this.setState({ links });
  };

  _addNewLink = () => {
    const links = [...this.state.links];
    links.push({ display_name: "", link_url: "" });
    this.setState({ links });
  };

  _handleSubmitClick = () => {
    const { handleUpdateChallenge, selectedChallenge } = this.props;
    let validation = this._checkValidation();
    if (validation.length > 0) {
      this.setState({ failedValidation: validation });
    } else {
      this.setState({ submitClicked: true }, () => {
        handleUpdateChallenge(selectedChallenge.id, this._buildChallenge());
      });
    }
  };

  _buildChallenge = () => {
    const {
      name,
      points,
      startDate,
      endDate,
      video_url,
      description,
      uploadPhotoFile,
      uploadIconFile,
      links
    } = this.state;

    const { selectedChallenge } = this.props;

    let data = {};

    if (name !== selectedChallenge.name) {
      data["name"] = name;
    }
    if (points !== selectedChallenge.points) {
      data["points"] = points;
    }
    if (description !== selectedChallenge.description) {
      data["description"] = description;
    }
    // if (video_url !== selectedChallenge.video_url) {
    //   data["video_url"] = video_url;
    // }
    if (uploadPhotoFile) {
      data["photo"] = uploadPhotoFile;
    }
    if (uploadIconFile) {
      data["icon"] = uploadIconFile;
    }

    if (links.length > 0) {
      data["links"] = JSON.stringify(links);
    } else {
      data["links"] = "";
    }
    data["video_url"] = video_url;
    data["start_date"] = startDate;
    data["end_date"] = endDate;

    return data;
  };

  _checkValidation = () => {
    const { name, points, description, uploadPhotoName } = this.state;

    let failedArray = [];
    if (!name) {
      failedArray.push("name");
    }
    if (!points) {
      failedArray.push("points");
    }
    if (!description) {
      failedArray.push("description");
    }
    // if (!uploadPhotoName) {
    //   failedArray.push("photo");
    // }
    return failedArray;
  };

  _handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  _handleStartChange = date => {
    this.setState({ startDate: date });
  };

  _handleEndChange = date => {
    this.setState({ endDate: date });
  };

  _grabPhotoData = photoData => {
    const { uploadPhotoFile, uploadPhotoName, uploadPhotoSrc } = photoData;
    this.setState({
      uploadPhotoName,
      uploadPhotoFile,
      uploadPhotoSrc
    });
  };

  _grabIconData = iconData => {
    const { uploadPhotoFile, uploadPhotoName, uploadPhotoSrc } = iconData;
    this.setState({
      uploadIconName: uploadPhotoName,
      uploadIconFile: uploadPhotoFile,
      uploadIconSrc: uploadPhotoSrc
    });
  };
}

export default ChallengeEdit;
