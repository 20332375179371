// Create an array of train icons to easily import into other files
import icon1 from "../assets/images/train-icons/putting-icon-navy.png";
import icon2 from "../assets/images/train-icons/chipping-icon-navy.png";
import icon3 from "../assets/images/train-icons/pitching-icon.png";
import icon4 from "../assets/images/train-icons/wedges-icon-navy.png";
import icon5 from "../assets/images/train-icons/irons-icon-navy.png";
import icon6 from "../assets/images/train-icons/woods-icon-navy.png";
import icon7 from "../assets/images/train-icons/on-course-play-sim-icon-navy.png";
import icon8 from "../assets/images/train-icons/bunkers-icon-navy.png";

export default [
  icon1,
  icon2,
  icon3,
  icon4,
  icon5,
  icon6,
  icon7,
  icon8
];
