import React from "react";
import Modal from "react-modal";
import { CSSTransition } from "react-transition-group";
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as commonActions from "../../actions/commonActions";
import * as userActions from "../../actions/userActions";
import * as proShopActions from "../../actions/proShopActions";
import * as communitiesActions from "../../actions/communitiesActions";

import Loader from "./../common/Op36Loader-web";

// Display config page and slides
import ConfigPage from "./configPage/ConfigPage";
import ImageBankModal from "./configPage/imageBankModal/ImageBankModal";
import DivisionRecordsBoard from "./divisionRecordsBoard/DivisionRecordsBoard";
import CommunityLeaderboard from "./communityLeaderboard/CommunityLeaderboard";
import PhotoWall from "./photoWall/PhotoWall";
import CoachAd from "./coachAd/CoachAd";
import PageHeader from '../common/PageHeader/PageHeader'
//-------------------------
/* TODO - this is part of Div Records v2 - re-add when ready
import DivisionRecordsBoardUpdated from "./divisionRecordsBoard/DivisionRecordsBoardUpdated";
*/
//-------------------------

// Extra zones (sidebar and footer) that can be toggled on/off
import SidebarZone from "./extraZones/sidebarZone/SidebarZone";
import FooterZone from "./extraZones/footerZone/FooterZone";

// Thumbnails for config pg
import divRecordsStudentsImg from "../../assets/images/pro-shop-display/div-records-board-students.png";
//-------------------------
/* TODO - this is part of Div Records v2 - re-add when ready
import divRecordsOverallImg from "../../assets/images/pro-shop-display/div-records-board-overall.png";
//-------------------------
*/
import commLeaderboardImg from "../../assets/images/pro-shop-display/comm-leaderboard.png";
import photoWallImg from "../../assets/images/pro-shop-display/photo-wall.png";
//-------------------------
import coachAdImg from "../../assets/images/pro-shop-display/coach-ad.png";

import "./ProShopDisplay.scss";
import "../../assets/styles/common/transitions.scss"; // For crossfade anim

const DEFAULT_CALL_TO_ACTION = "Join our program today!";
const CALL_TO_ACTION_MAX_CHARS = 75;
const FADE_DELAY = 1000;
// Excludes fade duration - i.e. the screen is shown for at least 1
// sec at full opacity
const MIN_DURATION = 10;
const MAX_SELECTED_ADS = 3;

class ProShopDisplay extends React.Component {
  state = {
    initialLoader: true,
    uploadingLoader: false,
    deletingLoader: -1, // changes to the id of the deleting photo and reset to -1 once it's done
    displayRunning: false,
    duration: 15,
    callToAction: DEFAULT_CALL_TO_ACTION,
    page: "static",
    switchingViews: false,
    showBar: false,
    needTwoModal: false, // requires user to have at least 2 displays selected before starting show
    imageBankModal: false,
    coachAdFile: "",
    tempSelectedPhotos: [], // to save selection of photos before confirmation in case of cancelation
    needCoachAd: false, // requires user to upload coach ad if it's selected
    displayed_pages: [
      {
        name: 'Level Records (Students)',
        description:
          'Displays the student with the best score in each of the 10 Levels. This leaderboard is split into two screens, each containing 5 Levels.',
        title: "division_leaderboard_students",
        displayed: true,
        thumbnail: divRecordsStudentsImg,
      },
      //-------------------------
      /* TODO - this is part of Div Records v2 - re-add when ready
      {
        name: "Level Records (Overall)",
        description:
          "Displays the student and the coach with the best score in each of the 10 Levels.",
        title: "division_leaderboard_overall",
        displayed: true,
        thumbnail: divRecordsOverallImg
      },
      */
      //-------------------------
      {
        name: "Community Leaderboard",
        description:
          "Displays a monthly ranking of the top 10 students in the community. Rankings are determined by how many points they have in the app.",
        title: "community_leaderboard",
        displayed: true,
        thumbnail: commLeaderboardImg,
      },
      {
        name: "Photo Wall",
        description:
          "Displays an array of photos from the Play, Train, and Social posts made by students in the community within the last 30 days.",
        title: "photo_wall",
        displayed: true,
        thumbnail: photoWallImg,
      },
      {
        // make sure this is always the last one - file uploader is below
        name: "Custom Advertisement",
        description:
          "Displays up to " +
          MAX_SELECTED_ADS +
          " images of your choice for marketing purposes. We recommend a size of 1596px x 954px.",
        title: "coach_ad",
        displayed: false,
        thumbnail: coachAdImg,
      },
    ],
    activePageTitles: [], // this will control what's actually shown in the display; an array of all the titles that were selected when the user started the show
  };

  componentDidMount() {
    const {
      common_actions,
      pro_shop_actions,
      // communities_actions,
      user,
    } = this.props;
    this.props.common_actions.showNavBar(true);
    this.props.common_actions.showSideBar(true);
    common_actions.setCurrentTab("ProShopDisplay");
    pro_shop_actions.getProShop(user.communities[0].id).then(() => {
      // Check if there are any invalid pinned_ads (ads that don't exist in
      // coach_ads) and remove them so the app doesn't break
      const allAdIds = this.props.community.coach_ads.map((i) => i.id);
      const correctPins = this.props.community.pinned_ads.filter((item) => {
        return allAdIds.includes(item);
      });

      // To reduce runtime, check if pinned_ads is equal to correctPins
      let arraysEqual = true;
      if (this.props.community.pinned_ads.length === correctPins.length) {
        correctPins.forEach((val, idx) => {
          if (val[idx] !== this.props.community.pinned_ads[idx]) {
            arraysEqual = false;
          }
        });
      } else {
        arraysEqual = false;
      }

      if (arraysEqual) {
        this.setState({
          initialLoader: false,
          tempSelectedPhotos: this._setPinnedAds(
            this.props.community.pinned_ads,
            this.props.community.coach_ads
          ),
        });
      } else {
        this.props.pro_shop_actions
          .pinAds(
            this.props.user.communities[0].id,
            this._setPinnedAds(correctPins, this.props.community.coach_ads)
          )
          .then(() => {
            this.setState({
              initialLoader: false,
              tempSelectedPhotos: this._setPinnedAds(
                this.props.community.pinned_ads,
                this.props.community.coach_ads
              ),
            });
          });
      }

      // this.props.pro_shop_actions.pinAds(
      //   this.props.user.communities[0].id,
      //   this._setPinnedAds(
      //     correctPins,
      //     this.props.community.coach_ads
      //   )
      // ).then(() => {
      //   this.setState({
      //     initialLoader: false,
      //     tempSelectedPhotos: this._setPinnedAds(
      //       this.props.community.pinned_ads,
      //       this.props.community.coach_ads
      //     )
      //   });
      // });

      // TODO remove these calls after we set up getProShop() to
      // return this data too
      //-------------------------
      /* TODO - this is part of Div Records v2 - re-add when ready
      pro_shop_actions.getCoachDivisionLeaderboard(user.communities[0].id);
      */
      //-------------------------
    });
  }

  // Fix some errors in case user backs out of display
  componentWillUnmount() {
    clearInterval(this.timer);
    clearInterval(this.countdown);
    clearInterval(this.animTimer);
    document.removeEventListener("mousemove", this._resetCountdown);
  }

  render() {
    const {
      initialLoader,
      displayRunning,
      showBar,
      needTwoModal,
      imageBankModal,
    } = this.state;

    // Show "Stop slideshow button" only when display is running and
    // mouse is moved
    let barTransition = showBar ? "controls-show" : "controls-hide";

    // Hide cursor if display is running and "Stop slideshow" button
    // is not visible or when on config page ("static")
    let cursorFade =
      (displayRunning && showBar) || this.state.page === "static"
        ? { cursor: "auto" }
        : { cursor: "none" };

    let displayClass = displayRunning ? "display-active" : "display-inactive";

    // IDK why this doesn't work in componentDidMount() or
    // _startDisplay(), but it works here!
    if (window.$zopim && window.$zopim.livechat) {
      window.$zopim.livechat.hideAll();
    }

    // Adjust grid zones based on page (remove if on config page)
    let gridID = "pro-shop-grid";
    let sidebar = <SidebarZone comm={this.props.user.communities[0]} />;
    let footer = <FooterZone callToAction={this.state.callToAction} />;
    if (this.state.page === "static") {
      gridID = null;
      sidebar = null;
      footer = null;
    }

    return (
      <div id='ProShopDisplay' className={displayClass} style={cursorFade}>
        {initialLoader ? (
          <Loader message={"Loading Display"} />
        ) : (
          <div style={{ maxWidth: "100%" }}>
            {!displayRunning && <PageHeader title='Academy Display' />}
            <div id={gridID}>
              {sidebar}
              <CSSTransition
                key={this.state.page}
                in={this.state.switchingViews}
                timeout={FADE_DELAY}
                classNames='crossfade'
              >
                {this._renderPageContent()}
              </CSSTransition>
              {footer}
            </div>
            {displayRunning ? (
              <div className={"slideshow-controls-bar " + barTransition}>
                <button
                  className='btn-rectangular btn-rectangular-main-positive'
                  id='stop-slideshow-btn'
                  onClick={this._stopDisplay}
                >
                  Stop slideshow
                </button>
              </div>
            ) : null}
            {needTwoModal && (
              <Modal
                isOpen
                contentLabel='Need 2 screens modal'
                onRequestClose={this._closeNeedTwoModal}
                className='customModal'
              >
                <div className='needTwoModal'>
                  <p>
                    Please select at least two screens to start the display.
                  </p>
                  <button
                    className='btn-rectangular btn-rectangular-main-positive'
                    onClick={this._closeNeedTwoModal}
                  >
                    Got it!
                  </button>
                </div>
              </Modal>
            )}
            {imageBankModal ? (
              <ImageBankModal
                closeModal={this._closeImageBankModal}
                uploading={this.state.uploadingLoader}
                sendPhotoData={this._grabPhotoData}
                selectPhoto={this._selectPhotoHandler}
                saveSelection={this._confirmSelectedPhotos}
                uploads={this.props.community.coach_ads}
                selected={this.state.tempSelectedPhotos}
                deletePhoto={this._deletePhotoHandler}
                deletingLoader={this.state.deletingLoader}
              />
            ) : null}
          </div>
        )}
      </div>
    );
  }

  //////////////////////////////////////////////////
  ////////// Starting/stopping display //////////
  //////////////////////////////////////////////////
  _startDisplay = () => {
    // Check that there is at least 1 Coach Ad file attached if
    // Coach Ad is selected
    const coachAdIdx = this.state.displayed_pages.length - 1;
    // if (
    //   this.state.displayed_pages[coachAdIdx].displayed &&
    //   !!this.state.coachAdSrc === false
    // ) {
    //   this.setState({ needCoachAd: true });
    //   return;
    // }
    if (
      this.state.displayed_pages[coachAdIdx].displayed &&
      this.props.community.pinned_ads.length < 1
    ) {
      this.setState({ needCoachAd: true });
      return;
    }

    // Check that at least two screens are selected
    if (this._checkTwo(this.state.displayed_pages)) {
      this.props.common_actions.showNavBar(false);
      this.props.common_actions.showSideBar(false);
      this._trackMouseMove();
      const titles = this._grabActiveTitles(this.state.displayed_pages);
      this.setState({ activePageTitles: titles }, () => {
        this.setState(
          {
            page: this.state.activePageTitles[0],
            // page: "division_leaderboard",
            displayRunning: true,
            switchingViews: true,
          },
          () => {
            // TODO uncomment this later
            // Get time in milliseconds
            this.timer = setInterval(
              this._iteratePage,
              this.state.duration * 1000 + FADE_DELAY
            );
          }
        );
      });
    } else {
      this.setState({ needTwoModal: true });
    }
  };

  _stopDisplay = () => {
    this.props.common_actions.showNavBar(true);
    this.props.common_actions.showSideBar(true);
    clearInterval(this.timer);
    clearInterval(this.countdown);
    clearInterval(this.animTimer);
    document.removeEventListener("mousemove", this._resetCountdown);
    this.setState({
      page: "static",
      displayRunning: false,
      switchingViews: false,
    });
  };

  _checkTwo = (pages) => {
    let displayCount = 0;
    pages.forEach((p) => {
      if (p.displayed) {
        displayCount++;
      }
    });
    return displayCount > 1;
  };

  _grabActiveTitles = (pages) => {
    let titles = [];
    for (let i = 0; i < pages.length; i++) {
      if (!pages[i].displayed) {
        continue;
      }
      if (pages[i].title === "division_leaderboard_students") {
        titles.push("division_leaderboard_students_1");
        titles.push("division_leaderboard_students_2");
      } else if (pages[i].title === "coach_ad") {
        // if (pages[i].title === "coach_ad") {
        const numImgs = this.props.community.pinned_ads.length;
        for (let j = 1; j <= numImgs; j++) {
          titles.push("coach_ad_" + j);
        }
      } else {
        titles.push(pages[i].title);
      }
    }
    return titles;
  };

  _closeNeedTwoModal = () => {
    this.setState({ needTwoModal: false });
  };

  //////////////////////////////////////////////////
  ////////// Showing/hiding controls bar //////////
  //////////////////////////////////////////////////
  _trackMouseMove = () => {
    // Call this in case mouse isn't moved when "Start slideshow"
    // button is clicked
    this._resetCountdown();

    // Then call for each mouse movement
    document.addEventListener("mousemove", this._resetCountdown);
  };

  _resetCountdown = () => {
    if (this.state.showBar) {
      clearInterval(this.countdown);
      this.countdown = setInterval(this._hideControlsBar, FADE_DELAY);
    } else {
      this.setState({ showBar: true });
      clearInterval(this.countdown);
      this.countdown = setInterval(this._hideControlsBar, FADE_DELAY);
    }
  };

  _hideControlsBar = () => {
    this.setState({ showBar: false });
  };

  //////////////////////////////////////////////////
  ////////// Config page funtionality //////////
  //////////////////////////////////////////////////

  _setPinnedAds = (pins, ads) => {
    // check to make sure pinned ads exist before trying to render them
    return pins.filter((p) => {
      return ads.find((a) => {
        return a.id === p;
      });
    });
  };

  // Update the coachAdSrc (taken from EventFlow.js)
  _grabPhotoData = (photoData) => {
    // const coachAdSrc = photoData.uploadPhotoSrc;
    const coachAdFile = photoData.uploadPhotoFile;
    const uploadingLoader = true;
    this.setState({ coachAdFile, uploadingLoader }, () => {
      this.props.pro_shop_actions
        .addNewPhoto(this.props.user.communities[0].id, {
          photo: this.state.coachAdFile,
        })
        .then(() => this.setState({ uploadingLoader: false }));
    });
    // this.setState({ needCoachAd: false });
  };

  _handleDurationChange = (event) => {
    // Set it to the min value, 1 sec, if they click out of the field
    // when it's empty or below 1
    // Else, set it to number they entered
    if (
      event.type === "blur" &&
      (!event.target.value ||
        event.target.value === "0" ||
        parseInt(event.target.value, 10) < MIN_DURATION ||
        parseFloat(event.target.value, 10) < MIN_DURATION)
    ) {
      this.setState({ duration: MIN_DURATION });
    } else {
      this.setState({ duration: event.target.value });
    }
  };

  _handleCallToActionChange = (event) => {
    // Set it to the default "Join our program today!" if empty
    // Else, set it to the first 75 chars of the string they entered
    if (
      event.type === "blur" &&
      (!event.target.value || event.target.value === "")
    ) {
      this.setState({ callToAction: DEFAULT_CALL_TO_ACTION });
    } else {
      this.setState({
        callToAction: event.target.value.substr(0, CALL_TO_ACTION_MAX_CHARS),
      });
    }
  };

  _handleConfigCheckbox = (title) => {
    const { displayed_pages } = this.state;
    let displayed_pages_copy = [...displayed_pages];

    let page = displayed_pages_copy.find((p) => p.title === title);

    page.displayed = !page.displayed;
    this.setState({ displayed_pages: displayed_pages_copy });
  };

  _handleAllDisplayClick = (is_checked) => {
    const { displayed_pages } = this.state;
    this.setState({
      displayed_pages: displayed_pages.map((p) => {
        p.displayed = is_checked;
        return p;
      }),
    });
  };

  // TODO we could probably combine _openImageBankModal()
  // _closeImageBankModal() into one method (set to opposite)
  _openImageBankModal = () => {
    this.setState({
      imageBankModal: true,
      tempSelectedPhotos: this.props.community.pinned_ads,
    });
  };

  _closeImageBankModal = () => {
    this.setState({
      imageBankModal: false,
      tempSelectedPhotos: this.props.community.pinned_ads,
    });
  };

  _selectPhotoHandler = (isSelected, photoData) => {
    // Check if select (isSelected false) or deselect (isSelected true)
    if (
      !isSelected &&
      this.state.tempSelectedPhotos.length < MAX_SELECTED_ADS
    ) {
      // Image is not already selected and there are fewer
      // than 3 images attached => select this image by adding
      // id to this.props.community.pinned_ads
      let selectedArray = [...this.state.tempSelectedPhotos];
      selectedArray.push(photoData.id);
      this.setState({ tempSelectedPhotos: selectedArray });
    } else if (isSelected) {
      // Image is selected => deselect image by removing
      // id from this.props.community.pinned_ads
      let selectedArray = this.state.tempSelectedPhotos.filter(
        (id) => id !== photoData.id
      );
      this.setState({ tempSelectedPhotos: selectedArray });
    }
    // Do nothing if user tries to select image when there are
    // already 3 images attached
  };

  _confirmSelectedPhotos = () => {
    // this.props.pro_shop_actions.pinAds(
    //   this.props.user.communities[0].id,
    //   this.state.tempSelectedPhotos
    // );
    //
    // this.setState({
    //   tempSelectedPhotos: this.props.community.pinned_ads
    // });

    this.props.pro_shop_actions
      .pinAds(this.props.user.communities[0].id, this.state.tempSelectedPhotos)
      .then(() => {
        this.setState({
          tempSelectedPhotos: this.props.community.pinned_ads,
        });
      });

    const needCoachAd = this.state.tempSelectedPhotos.length > 0 ? false : true;
    this.setState({ needCoachAd: needCoachAd });

    this._closeImageBankModal();
  };

  _deletePhotoHandler = (imageID, e) => {
    // Remove photo from image bank
    e.stopPropagation();
    if (this.state.deletingLoader > 0) {
      return false;
    }

    this.setState({ deletingLoader: imageID }, () => {
      this.props.pro_shop_actions.deletePhoto(imageID).then(() => {
        this.setState({ deletingLoader: -1 }, () => {
          // Update tempSelectedPhotos AFTER deletion is done so app doesn't break
          this.setState({
            tempSelectedPhotos: this.props.community.pinned_ads,
          });
        });
      });
    });
  };

  _dragPhotoReorderHandler = (array) => {
    this.setState({ tempSelectedPhotos: array });
  };

  _iteratePage = () => {
    const { page } = this.state;
    const pages = this.state.activePageTitles;

    let index = pages.indexOf(page);

    // Get next page
    let nextPage = pages[0];
    if (index !== pages.length - 1) {
      nextPage = pages[index + 1];
    }

    this.setState({ switchingViews: false }, () => {
      // Delay setting switchingViews to true so the current page
      // has time to fade out first
      this.animTimer = setTimeout(() => {
        this.setState({ page: nextPage }, () => {
          this.setState({ switchingViews: true });
          this._backgroundRefresh(page);
        });
      }, FADE_DELAY);
    });
  };

  //////////////////////////////////////////////////
  ////////// Page iteration //////////
  //////////////////////////////////////////////////
  _renderPageContent = () => {
    const { page } = this.state;
    const { user } = this.props;

    // Grab selected image data to pass into CoachAd and config
    // NOTE: Must grab imgData in order of selectedImg array so if the
    // images are reordered by drag-and-drop, the new order is shown
    const uploadedImg = this.props.community.coach_ads;
    const selectedImg = this.props.community.pinned_ads;

    let imgData = [];
    selectedImg.forEach((img_id) => {
      let fullImage = uploadedImg.find((img) => {
        return img.id === img_id;
      });
      if (fullImage) {
        imgData.push(fullImage);
      }
    });

    switch (page) {
      case "division_leaderboard_students_1":
        return (
          <DivisionRecordsBoard
            pageName='Level Records'
            comm={user.communities[0]}
            divRecords={this.props.division_leaderboard}
            showStandardDivs
          />
        );
      case "division_leaderboard_students_2":
        return (
          <DivisionRecordsBoard
            pageName='Level Records'
            comm={user.communities[0]}
            divRecords={this.props.division_leaderboard}
          />
        );
      //-------------------------
      /* TODO - this is part of Div Records v2 - re-add when ready
      case "division_leaderboard_overall":
        return (
          <DivisionRecordsBoardUpdated divRecordsStudents={this.props.division_leaderboard}
          divRecordsCoaches={this.props.coach_division_leaderboard} />
        );
      */
      //-------------------------
      case "community_leaderboard":
        return (
          <CommunityLeaderboard
            pageName='Community Leaderboard'
            comm={user.communities[0]}
            commLeaders={this.props.community_leaderboard}
          />
        );
      case "photo_wall":
        return <PhotoWall photoWall={this.props.photo_wall} />;
      // TODO: Refactor this to reduce redundancy
      case "coach_ad_1":
        return <CoachAd adSrc={imgData[0].large_file_url} />;
      case "coach_ad_2":
        return <CoachAd adSrc={imgData[1].large_file_url} />;
      case "coach_ad_3":
        return <CoachAd adSrc={imgData[2].large_file_url} />;
      default:
        return (
          <ConfigPage
            displayRunning={this.state.displayRunning}
            start={this._startDisplay}
            duration={this.state.duration}
            callToAction={this.state.callToAction}
            handleDurationChange={this._handleDurationChange}
            handleCallToActionChange={this._handleCallToActionChange}
            handleCheck={this._handleConfigCheckbox}
            handleAllDisplayClick={this._handleAllDisplayClick}
            pages={this.state.displayed_pages}
            needCoachAd={this.state.needCoachAd}
            openImageBank={this._openImageBankModal}
            imageSelection={imgData}
            dragReorderHandler={this._dragPhotoReorderHandler}
          />
        );
    }
  };

  _backgroundRefresh = (page) => {
    const { pro_shop_actions, user } = this.props;
    switch (page) {
      case "division_leaderboard_students_1":
        pro_shop_actions.getDivisionLeaderboard(user.communities[0].id);
        break;
      case "division_leaderboard_students_2":
        pro_shop_actions.getDivisionLeaderboard(user.communities[0].id);
        break;
      //-------------------------
      /* TODO - this is part of Div Records v2 - re-add when ready
      case "division_leaderboard_overall":
        pro_shop_actions.getDivisionLeaderboard(user.communities[0].id);
        pro_shop_actions.getCoachDivisionLeaderboard(user.communities[0].id);
        break;
      */
      //-------------------------
      case "community_leaderboard":
        pro_shop_actions.getCommunityLeaderboard(user.communities[0].id);
        break;
      case "photo_wall":
        pro_shop_actions.getPhotoWall(user.communities[0].id);
        break;
      //-------------------------
      /* TODO figure out what to do with props.community_stats
      case "community_stats":
        pro_shop_actions.getCommunityStats(user.communities[0].id);
        break;
      */
      //-------------------------
      default:
        return false;
    }
  };
}

//////////////////////////////////////////////////
////////// Redux funsies //////////
//////////////////////////////////////////////////
function mapStateToProps(state, ownProps) {
  return {
    ui: state.ui,
    user: state.user.current_user,
    division_leaderboard: state.proShop.division_leaderboard,
    //-------------------------
    /* TODO - this is part of Div Records v2 - re-add when ready
    coach_division_leaderboard: state.proShop.coach_division_leaderboard,
    */
    //-------------------------
    community_leaderboard: state.proShop.community_leaderboard,
    photo_wall: state.proShop.photo_wall,
    //-------------------------
    /* TODO figure out what to do with props.community_stats
    community_stats: state.proShop.community_stats,
    */
    //-------------------------
    community: state.proShop.community_ads,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    common_actions: bindActionCreators(commonActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
    pro_shop_actions: bindActionCreators(proShopActions, dispatch),
    communities_actions: bindActionCreators(communitiesActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProShopDisplay);
