import * as types from '../actions/actionTypes';

const subjectsReducer = (subjects = [], action) => {
  switch (action.type) {
    case types.ADD_SUBJECTS:
      return action.subjects;
    default:
      return subjects;
  }
}

export default subjectsReducer
