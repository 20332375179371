import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import PropTypes from 'prop-types'

import * as commonActions from '../../../actions/commonActions'
import * as userActions from '../../../actions/userActions'
import * as salesLeadActions from '../../../actions/salesLeadActions'
import * as representativesActions from '../../../actions/representativesActions'

import defaultProfile from '../../../assets/images/default_profile.png'

import './SalesLeadsPage.scss'

import Loader from '../../common/Op36Loader-web'
import SalesLeadDetailModal from './SalesLeadDetailModal'
import Pagination from '../../common/Pagination'
import PageHeader from '../../common/PageHeader/PageHeader'

class SalesLeadsPage extends React.Component {
  state = {
    loader: true,
    representativesLoader: true,
    selectedLead: null,
    searchLoader: false,
    search: '',
    salesRepFilter: [],
    statusFilter: [],
  }

  componentDidMount() {
    const {
      common_actions,
      sales_lead_actions,
      representatives_actions,
    } = this.props
    common_actions.setCurrentTab('sales-leads')
    sales_lead_actions.getSalesLeads(null, null, null, 1).then((_) => {
      this.setState({ loader: false })
    })
    representatives_actions
      .getRepresentatives()
      .then(() => this.setState({ representativesLoader: false }))
  }

  render() {
    const {
      loader,
      searchLoader,
      representativesLoader,
      selectedLead,
      search,
    } = this.state
    const { sales_leads, pages, sales_reps } = this.props

    if (loader) {
      return (
        <div>
          <Loader message='grabbing sales leads' />
        </div>
      )
    }
    return (
      <div id='SalesLeadsPage'>
        <PageHeader title={'Sales Leads'}/>
        <div className='top-bar'>
          <div className='search-container'>
            <form>
              <input
                type='text'
                name='search'
                className='search-input'
                value={search}
                onChange={this._handleSearchChange}
                placeholder='search sales leads'
              />
              <input
                type='submit'
                disabled={searchLoader}
                className='sub-btn'
                value={'search'}
                onClick={(e) => this._handleSearch(e, search)}
              />
            </form>
          </div>
          <div className='filter-box-container'>
            <div className='filter-box content'>
              <p className='title'>Sales Rep Filters:</p>
              {representativesLoader ? (
                <div className='d-flex align-items-center justify-content-center'>
                  <i className='fa fa-spinner fa-spin' aria-hidden='true' />
                </div>
              ) : (
                sales_reps
                  .filter((r) => !r.is_account_manager) //removes support team members
                  .map((r, i) => (
                    <div
                      className='filter-row'
                      key={i}
                      aria-hidden='true'
                      onClick={() =>
                        this._handleCheckbox('salesRepFilter', r.id)
                      }
                    >
                      <div className='checkbox'>
                        {this.state.salesRepFilter.includes(r.id) ? (
                          <i className='fa fa-times' aria-hidden='true' />
                        ) : null}
                      </div>
                      <p>{`${r.first_name} ${r.last_name}`}</p>
                    </div>
                  ))
              )}
            </div>
            <div className='filter-box content'>
              <p className='title'>Status Filters:</p>
              {this._renderStatusFilters()}
            </div>
            <div className='filter-box empty'>
              <p className='clear-btn' aria-hidden='true' onClick={this._resetPage}>
                clear search
              </p>
            </div>
          </div>
        </div>
        <div className='card'>
          {searchLoader ? (
            <div>
              <Loader message='searching sales leads' />
            </div>
          ) : (
            <div>
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Joined</th>
                    <th>Community Name</th>
                    <th>Status</th>
                    <th>Sales Rep</th>
                  </tr>
                </thead>
                <tbody>
                  {sales_leads.map((lead, i) => (
                    <tr
                      key={i}
                      onClick={() => this.setState({ selectedLead: lead })}
                    >
                      <td>
                        <div
                          className='avatar'
                          style={{
                            backgroundImage: `url(${
                              lead.coach.avatar
                                ? lead.coach.avatar.file_url
                                : defaultProfile
                            })`,
                          }}
                        />
                      </td>
                      <td>{`${lead.coach.first_name} ${lead.coach.last_name}`}</td>
                      <td>{lead.coach.email}</td>
                      <td>{lead.coach.phone}</td>
                      <td>{this._calcLeadAge(lead.coach.created_at)}</td>
                      <td>{lead.community_name}</td>
                      <td>
                        <p
                          className='status-pill'
                          style={{
                            background: this._grabStatusColor(lead.status),
                          }}
                        >
                          {lead.status}
                        </p>
                      </td>
                      <td>{lead.sales_rep ? lead.sales_rep.name : '---'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {sales_leads.length === 0 && (
                <div className='d-flex flex-column align-items-center empty-results-box'>
                  <i className='fa fa-thumbs-o-down' aria-hidden='true' />
                  <p className='text-align-center'>
                    Your search didn&apos;t return any results. Check your filters and try again.
                  </p>
                </div>
              )}
            </div>
          )}
          {pages > 1 && (
            <Pagination pageCount={pages} fetchData={this._handlePagination} />
          )}
        </div>
        {selectedLead && (
          <SalesLeadDetailModal
            lead={selectedLead}
            close={this._closeDetailModal}
            representativesLoader={representativesLoader}
            representatives={sales_reps.filter((r) => !r.is_account_manager)}
          />
        )}
      </div>
    )
  }

  _resetPage = () => {
    this.setState({ searchLoader: true }, () => {
      this.setState(
        { search: '', salesRepFilter: [], statusFilter: [] },
        () => {
          this.props.sales_lead_actions
            .getSalesLeads(null, null, null, 1)
            .then(() => {
              this.setState({ searchLoader: false })
            })
        }
      )
    })
  }

  _renderStatusFilters = () => {
    const vals = [
      'new',
      'attempted contact',
      'contacted/working',
      'demo booked',
      'demo performed',
      'not interested',
      'archived',
      'upgraded',
    ]
    return vals.map((status, i) => (
      <div
        className='filter-row'
        key={i}
        aria-hidden='true'
        onClick={() => this._handleCheckbox('statusFilter', status)}
      >
        <div className='checkbox'>
          {this.state.statusFilter.includes(status) ? (
            <i className='fa fa-times' aria-hidden='true' />
          ) : null}
        </div>
        <p>{status}</p>
      </div>
    ))
  }

  _handleCheckbox = (type, val) => {
    this.setState((prevState) => {
      if (prevState[type].includes(val)) {
        return { [type]: prevState[type].filter((v) => v !== val) }
      } else {
        return { [type]: [...prevState[type], val] }
      }
    })
  }

  _handlePagination = (page) => {
    const { sales_lead_actions } = this.props
    const { search, salesRepFilter, statusFilter } = this.state
    const salesReps = salesRepFilter.length > 0 ? salesRepFilter : ''
    const statuses = statusFilter.length > 0 ? statusFilter : ''
    this.setState({ searchLoader: true }, () => {
      sales_lead_actions
        .getSalesLeads(search.trim(), salesReps, statuses, page)
        .then(() => this.setState({ searchLoader: false }))
    })
  }

  _handleSearchChange = (e) => {
    this.setState({ search: e.target.value })
  }

  _handleSearch = (e, term) => {
    e.preventDefault()
    const { salesRepFilter, statusFilter } = this.state
    term = term.trim()
    if (!term && salesRepFilter.length === 0 && statusFilter.length === 0) {
      return false
    }
    const salesReps = salesRepFilter.length > 0 ? salesRepFilter : ''
    const statuses = statusFilter.length > 0 ? statusFilter : ''
    this.setState({ searchLoader: true }, () => {
      this.props.sales_lead_actions
        .getSalesLeads(term, salesReps, statuses, 1)
        .then(() => this.setState({ searchLoader: false }))
    })
  }

  _grabStatusColor = (status) => {
    switch (status) {
      case 'new':
        return '#ff6633' // orange
      case 'attempted contact':
        return '#fdda12' // gold
      case 'contacted/working':
        return '#33d9b2' // green
      case 'demo booked':
        return '#6677de' // purple..... ish
      case 'not interested':
        return '#ec8391' // light red
      case 'archived':
        return '#dc3446' // darker red
      case 'demo performed':
        return '#2b3843' // dark slate grey
      case 'upgraded':
        return 'black'
      default:
        return '#fff'
    }
  }

  _closeDetailModal = () => {
    this.setState({ selectedLead: null })
  }

  _calcLeadAge = (date) => {
    if (!date) {
      return 'Never'
    }
    const now = moment()
    let time = now.diff(moment(date), 'days')
    let unit = `day${time !== 1 ? 's' : ''}`
    if (time < 1) {
      time = now.diff(moment(date), 'hours')
      unit = `hour${time !== 1 ? 's' : ''}`
      if (time < 1) {
        time = now.diff(moment(date), 'minutes')
        unit = `minute${time !== 1 ? 's' : ''}`
        if (time < 1) {
          return 'just now'
        }
      }
    }
    return `${time} ${unit} ago`
  }
}

SalesLeadsPage.propTypes = {
  sales_leads: PropTypes.array,
  pages: PropTypes.array,
  sales_reps: PropTypes.array,
  common_actions: PropTypes.shape({
    setCurrentTab: PropTypes.func,
  }),
  sales_lead_actions: PropTypes.shape({
    getSalesLeads: PropTypes.func,
  }),
  representatives_actions: PropTypes.shape({
    getRepresentatives: PropTypes.func,
  }),
}

function mapStateToProps(state, _) {
  return {
    ui: state.ui,
    current_user: state.user.current_user,
    pages: state.pages,
    sales_leads: state.salesLeads.sales_leads,
    sales_reps: state.representatives,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    common_actions: bindActionCreators(commonActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
    sales_lead_actions: bindActionCreators(salesLeadActions, dispatch),
    representatives_actions: bindActionCreators(
      representativesActions,
      dispatch
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesLeadsPage)
