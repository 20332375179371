import PropTypes from 'prop-types'
import './PageFooter.scss'

const PageFooter = ({ firstButton, secondButton, reversed }) => (
  <div className='page-footer-container fixed-bottom'>
    <div className='h-100 d-flex align-items-center'>
      <div className='col-12 footer-buttons-container'>
        <div className={`d-flex flex-row${reversed && '-reverse'} pull-right `}>
          {secondButton &&
            <button
              className='btn text-white pull-right ml-3'
              onClick={() => secondButton.onClick()}
              disabled={secondButton.disabled}
              style={secondButton.style}
            >
              <i className={secondButton.icon}></i>
              <span className='ml-2'>{secondButton.text}</span>
            </button>
          }
          {firstButton &&
            <button
              className='btn text-white pull-right ml-3'
              onClick={() => firstButton.onClick()}
              disabled={firstButton.disabled}
              style={firstButton.style}
            >
              <i className={firstButton.icon}></i>
              <span className='ml-2'>{firstButton.text}</span>
            </button>
          }
        </div>
      </div>
    </div>
  </div>
)

PageFooter.propTypes = {
  firstButton: PropTypes.shape({
    text: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    style: PropTypes.object,
    icon: PropTypes.string,
  }),
  secondButton: PropTypes.shape({
    text: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    style: PropTypes.object,
    icon: PropTypes.string,
  }),
  reversed: PropTypes.bool,
}

export default PageFooter
