import * as constants from "./actionTypes";

import Axios from '../axios'

function successRequestingAllTags(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_ALL_ADMIN_TAGS,
      admin_tags: response.data.admin_tags,
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successRequestingUserAdminTags(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_USER_ADMIN_TAGS,
      admin_tags: response.data.admin_tags,
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successRequestingCommunityAdminTags(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_COMMUNITY_ADMIN_TAGS,
      admin_tags: response.data.admin_tags,
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successRemovingAdminTag(response, admin_tag_id) {
  if (response.status === 200) {
    return {
      type: constants.REMOVE_USER_ADMIN_TAG,
      admin_tag_id: admin_tag_id,
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successRemovingCommunityAdminTag(response, admin_tag_id) {
  if (response.status === 200) {
    return {
      type: constants.REMOVE_COMMUNITY_ADMIN_TAG,
      admin_tag_id: admin_tag_id,
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successAddingAdminTag(response, admin_tag_id) {
  if (response.status === 200) {
    return {
      type: constants.ADD_USER_ADMIN_TAG,
      admin_tag_id: admin_tag_id,
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successAddingCommunityAdminTag(response, admin_tag_id) {
  if (response.status === 200) {
    return {
      type: constants.ADD_COMMUNITY_ADMIN_TAG,
      admin_tag_id: admin_tag_id,
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successCreatingNewTag(response) {
  if (response.status === 200) {
    return {
      type: constants.CREATE_NEW_ADMIN_TAG,
      admin_tag: response.data.admin_tag,
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successEditingTag(response) {
  if (response.status === 200) {
    return {
      type: constants.UPDATE_ADMIN_TAG,
      admin_tag: response.data.admin_tag,
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successDestroyingTag(response) {
  if (response.status === 200) {
    return {
      type: constants.REMOVE_ADMIN_TAG,
      admin_tag: response.data.admin_tag,
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

//  =======================================================================

export function getAllTags() {
  return function (dispatch) {
    return requestAllTags().then((response) =>
      dispatch(successRequestingAllTags(response))
    );
  };
}

export function getUserAdminTags(user_id) {
  return function (dispatch) {
    return requestUserAdminTags(user_id).then((response) => {
      dispatch(successRequestingUserAdminTags(response));
    });
  };
}

export function getCommunityAdminTags(community_id) {
  return function (dispatch) {
    return requestCommunityAdminTags(community_id).then((response) =>
      dispatch(successRequestingCommunityAdminTags(response))
    );
  };
}

export function removeAdminTag(user_id, admin_tag_id) {
  return function (dispatch) {
    return requestRemoveAdminTag(user_id, admin_tag_id).then((response) => {
      dispatch(successRemovingAdminTag(response, admin_tag_id));
    });
  };
}

export function removeCommunityAdminTag(community_id, admin_tag_id) {
  return function (dispatch) {
    return requestRemoveCommunityAdminTag(community_id, admin_tag_id).then(
      (response) =>
        dispatch(successRemovingCommunityAdminTag(response, admin_tag_id))
    );
  };
}

export function addAdminTag(user_id, admin_tag_id) {
  return function (dispatch) {
    return requestAddAdminTag(user_id, admin_tag_id).then((response) => {
      dispatch(successAddingAdminTag(response, admin_tag_id));
    });
  };
}

export function addCommunityAdminTag(community_id, admin_tag_id) {
  return function (dispatch) {
    return requestAddCommunityAdminTag(community_id, admin_tag_id).then(
      (response) =>
        dispatch(successAddingCommunityAdminTag(response, admin_tag_id))
    );
  };
}

export function createNewTag(tag_params) {
  return function (dispatch) {
    return requestCreateNewTag(tag_params).then((response) =>
      dispatch(successCreatingNewTag(response))
    );
  };
}

export function editTag(tag_id, tag_params) {
  return function (dispatch) {
    return requestEditTag(tag_id, tag_params).then((response) =>
      dispatch(successEditingTag(response))
    );
  };
}

export function destroyTag(tag_id) {
  return function (dispatch) {
    return requestDestroyTag(tag_id).then((response) =>
      dispatch(successDestroyingTag(response))
    );
  };
}

//  =======================================================================

function requestAllTags() {
  let requestUrl = "/api/v1/admin_tags";
  return Axios.get(requestUrl);
}

function requestUserAdminTags(user_id) {
  let requestUrl = `/api/v1/users/${user_id}/get_admin_tags`;
  return Axios.get(requestUrl);
}

function requestRemoveAdminTag(user_id, admin_tag_id) {
  let requestUrl = `/api/v1/users/${user_id}/remove_admin_tag`;
  return Axios.post(requestUrl, {
    admin_tag_id,
  });
}

function requestAddAdminTag(user_id, admin_tag_id) {
  let requestUrl = `/api/v1/users/${user_id}/add_admin_tag`;
  return Axios.post(requestUrl, {
    admin_tag_id,
  });
}

function requestCreateNewTag(tag_params) {
  let requestUrl = "/api/v1/admin_tags";
  return Axios.post(requestUrl, tag_params);
}

function requestEditTag(tag_id, tag_params) {
  let requestUrl = `/api/v1/admin_tags/${tag_id}`;
  return Axios.put(requestUrl, tag_params);
}

function requestDestroyTag(tag_id) {
  let requestUrl = `/api/v1/admin_tags/${tag_id}`;
  return Axios.delete(requestUrl);
}

function requestCommunityAdminTags(community_id) {
  let requestUrl = `/api/v1/communities/${community_id}/get_admin_tags`;
  return Axios.get(requestUrl);
}

function requestAddCommunityAdminTag(community_id, admin_tag_id) {
  let requestUrl = `/api/v1/communities/${community_id}/add_admin_tag`;
  return Axios.post(requestUrl, { admin_tag_id });
}

function requestRemoveCommunityAdminTag(community_id, admin_tag_id) {
  let requestUrl = `/api/v1/communities/${community_id}/remove_admin_tag`;
  return Axios.post(requestUrl, {
    admin_tag_id,
  });
}
