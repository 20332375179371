import React from 'react';

import StockProfilePic from "../../../../assets/images/mock/StockProfilePic.png";

import "./CommunityLeaderboardCard.scss";

const communityLeaderboardCard = (props) => {
  // Use StockProfilePic if student has no profile pic
  let studentPic = StockProfilePic;
  if (props.pic && props.pic.file_url) {
    studentPic = props.pic.file_url;
  }

  return (
    <div className="comm-leaderboard-card" id={"comm-leaderboard-" + props.ordinal}>
      <div className="comm-leaderboard-card-ordinal">
        <p>{props.ordinal}</p>
      </div>
      <img src={studentPic} alt={"profile picture of " + props.name} className="comm-leaderboard-card-pic" />
      <p className="comm-leaderboard-card-name">{props.name}</p>
      <p className="comm-leaderboard-card-points">{props.points.toLocaleString()}pts</p>
    </div>
  );
};

export default communityLeaderboardCard;
