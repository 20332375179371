import React, { useEffect } from 'react'
import classNames from 'classnames'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { showNavBar, showSideBar } from '../../actions/commonActions'
import { updateCurrentUser } from '../../actions/userActions'

import { AccountContext } from './AccountContext'

// COMPONENTS
import PageHeader from '../common/PageHeader/PageHeader'
import AccountSidebar from './AccountForm/AccountSidebar'
import AccountFormDispatcher from './AccountFormDispatcher'

// STYLES
import classes from './AccountPage.module.scss'
import { useSearchParams } from 'react-router-dom'


const AccountPage = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()
  const currentUser = useSelector(state => state.user.current_user)

  useEffect(() => {
    dispatch(showSideBar(true))
    dispatch(showNavBar(true))
  }, [dispatch])

  useEffect(() => {
    if (!searchParams.has('editTab')) {
      searchParams.set('editTab', 'personal')
      setSearchParams(searchParams)
    }
  }, [searchParams, setSearchParams])

  return (
    <div id='AccountPage'>
      <div>
        <PageHeader title='Account' />
        <div className='col-xl-9 col-lg-10 col-md-12 mx-auto my-5 card animated fadeIn'>
          <AccountContext.Provider value={{ user: currentUser, updateAction: updateCurrentUser, submitDisabled: false }}>
            <div className={classNames(classes.container, 'd-flex')}>
              <div className='col-md-4'>
                <AccountSidebar />
              </div>
              <div className='min-h-100 border-left' />
              <div className='col-md-8'>
                <AccountFormDispatcher />
              </div>
            </div>

          </AccountContext.Provider>
        </div>
      </div>
    </div>
  )
}

export default AccountPage
