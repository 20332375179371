import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { ReactComponent as RightArrow } from '../../assets/images/common/icons/right-arrow.svg'
import { ReactComponent as GolferIcon } from '../../assets/images/common/icons/golfer-half-icon.svg'

import styles from './SeriesEventRow.module.scss'
import colors from '../../assets/styles/globals.scss'
import classNames from 'classnames'
import EventStatus from './EventStatus'

const SeriesEventRow = ({ event, inProgress }) => {
  const navigate = useNavigate()

  const timezone = useSelector(state => state.user.current_user.communities[0].timezone)
  const eventStartDateInTimezone = moment(event.eventDate).tz(timezone)

  const navigateTo = useCallback(() => {
    const { id } = event
    navigate(`/events/${id}`)
  }, [navigate, event])

  return (
    <div
      className={classNames(styles['series-event-card'], 'w-100')}
      onClick={navigateTo}
      onKeyDown={null}
      role='button'
      tabIndex={0}
    >
      <div className={classNames('py-2 col-9 d-flex align-items-center', styles['event-info'])}>
        <div className={styles['date-col']}>
          <span className={styles['month-name']}>{ eventStartDateInTimezone.format('MMM') }</span>
          <span className={styles['day-number']}>{ eventStartDateInTimezone.format('DD') }</span>
          <span className={styles['day-name']}>{ eventStartDateInTimezone.format('ddd') }</span>
        </div>
        <div className={styles['vertical-line']}></div>
        <div className='w-100 d-flex justify-content-between align-items-center'>
          <div className={classNames('d-flex flex-column justify-content-between h-75', styles['title-col'])}>
            <div className={styles['event-title']}>
              Event #{event.index + 1}{event.name && ` - ${event.name}`}
            </div>
            <div className='w-100 d-flex align-items-center event-data'>
              <GolferIcon fill={colors.primaryNavy} width={'17px'} height={'17px'} className='mr-1'/><span className='mr-3'>{event.format}</span>
              <i className='fa fa-clock-o mr-1'/><span className='mr-3'>{moment(eventStartDateInTimezone).format('hh:mm a')}</span>
              {event.location && <><i className='fa fa-map-marker mr-1'/><span className='mr-3'>{event.location}</span></>}
            </div>
          </div>
        </div>
      </div>
      <div className='col-2 d-flex align-items-center '>
        <EventStatus event={event} inProgress={inProgress}/>
      </div>

      <div className={classNames('col-2', styles['actions-col'])}>
        <div><RightArrow/></div>
      </div>
    </div>
  )
}

SeriesEventRow.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    location: PropTypes.string,
    format: PropTypes.string,
    eventDate: PropTypes.string,
    index: PropTypes.number,
    status: PropTypes.string,
  }),
  inProgress: PropTypes.bool,
}

export default SeriesEventRow
