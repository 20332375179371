import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as courseActions from '../../../actions/courseActions'
import Loader from '../../common/Op36Loader-web'
import SimpleResourceCard from './SimpleResourceCard'
import ReactPlayer from 'react-player'
import PropTypes from 'prop-types'

import lp_icon from '../../../assets/images/common/icons/lesson_plans/lesson-plan-icon.png'

import './ProgramDetailPage.scss'

class ProgramDetailPage extends Component {
  state = {
    loader: true,
  }
  componentDidMount = () => {
    const { selectedProgram, selectedTemplate } = this.props
    const program = selectedTemplate ? selectedTemplate : selectedProgram
    this.props.course_actions.getCourse(program.id).then(() => {
      this.setState({
        loader: false,
      })
    })
  }

  renderResources = trainingResources => {
    const {
      course: { resources },
    } = this.props
    const filteredResources = resources.filter(
      resource => resource.resource_type !== 'Training'
    )

    if (trainingResources) {
      return trainingResources.map(resource => (
        <SimpleResourceCard resource={resource} key={resource.id} />
      ))
    }
    return filteredResources.map(resource => (
      <SimpleResourceCard resource={resource} key={resource.id} />
    ))
  }

  renderSessions = () => {
    const {
      course: { lesson_plan },
    } = this.props
    if (lesson_plan && lesson_plan.draft_sessions) {
      const lpSessions = JSON.parse(lesson_plan.draft_sessions)
      return lpSessions.map((session, i) => <p key={i}>- {session.name}</p>)
    }
    return <p>(No Lesson Plan)</p>
  }
  render() {
    const {
      course,
      submitProgram,
      draftProgram,
      builderLoader,
      user,
      current_user,
    } = this.props
    const { loader } = this.state
    return (
      <div id='ProgramDetailPage' className='col-md-8 mx-auto animated fadeIn'>
        {loader ? (
          <Loader message='loading program' />
        ) : (
          <div className='row mt-5'>
            <div className='col-md-3'>
              {this._renderIcon(course.lesson_plan)}
              {((course.sessions && course.sessions.length > 0) ||
                course.lesson_plan) && (
                <div className='resource-container'>
                  {course.lesson_plan ? <h5>{course.lesson_plan.name}</h5> : ''}
                  {course.lesson_plan && <hr />}
                  <h6>Sessions</h6>
                  {this.renderSessions()}
                </div>
              )}
            </div>
            <div className='col-md-9 program-info-container'>
              <div className='program-title-header'>
                <div className='program-title-wrapper'>
                  <h5>{course.name}</h5>
                  <div className='program-organization-wrapper'>
                    <i className='fa fa-group'/>
                    <p>
                      {current_user.type === 'Admin'
                        ? 'Admin Template'
                        : user.communities[0].name}
                    </p>
                  </div>
                </div>
                <div className='program-button-wrapper'>
                  {builderLoader && (
                    <i className='fa fa-spinner fa-spin loading-spinner' />
                  )}
                  <button
                    className='btn btn-dark'
                    onClick={() => draftProgram()}
                    disabled={builderLoader}
                  >
                    Draft this program
                  </button>
                  <button
                    className='btn btn-primary'
                    onClick={() => submitProgram()}
                    disabled={builderLoader}
                  >
                    Build this program
                  </button>
                </div>
              </div>

              <p className='program-description'>{course.description}</p>
              {course.marketing_video_url && (
                <div className='player-wrapper'>
                  <ReactPlayer
                    className='react-player'
                    url={course.marketing_video_url}
                    width='100%'
                    height='100%'
                    controls
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    )
  }

  _renderIcon = lp => {
    if (!lp) {
      return (
        <div className='program-icon-wrapper'>
          <i className='fa fa-flag' />
        </div>
      )
    } else {
      if (lp.photo) {
        return (
          <div
            className='program-icon-wrapper'
            style={{ backgroundImage: `url(${lp.photo.file_url})` }}
          ></div>
        )
      } else if (lp.template_photo_url) {
        return (
          <div
            className='program-icon-wrapper'
            style={{ backgroundImage: `url(${lp.template_photo_url})` }}
          />
        )
      } else {
        return (
          <div className='program-icon-wrapper lp-icon-wrapper'>
            <div
              className='lp-icon'
              style={{ backgroundImage: `url(${lp_icon})` }}
            />
          </div>
        )
      }
    }
  }
}

function mapStateToProps(state) {
  return {
    current_user: state.user.current_user,
    course: state.courses.course,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    course_actions: bindActionCreators(courseActions, dispatch),
  }
}

ProgramDetailPage.propTypes = {
  course_actions: PropTypes.shape({
    getCourse: PropTypes.func,
  }),
  course: PropTypes.shape({
    description: PropTypes.string,
    marketing_video_url: PropTypes.string,
    sessions: PropTypes.array,
    lesson_plan: PropTypes.shape({
      name: PropTypes.string,
      draft_sessions: PropTypes.array,
    }),
    name: PropTypes.string,
    resources: PropTypes.array,
  }),
  submitProgram: PropTypes.func,
  draftProgram: PropTypes.func,
  builderLoader: PropTypes.bool,
  user: PropTypes.shape({
    communities: PropTypes.array,
  }),
  current_user: PropTypes.shape({
    type: PropTypes.string,
  }),
  selectedProgram: PropTypes.object,
  selectedTemplate: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgramDetailPage)
