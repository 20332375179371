import React from "react";
import Modal from "react-modal";
import { CSVLink } from "react-csv";

const modalStyles = {
  content: {
    height: "280px",
    width: "30%",
    marginTop: "12%",
    overflow: "hidden",
  },
};

const CSVExportModal = ({
  data,
  headers,
  showModal,
  toggleModal,
  reportType,
  filename,
}) => {
  return (
    <Modal
      isOpen={showModal}
      onRequestClose={toggleModal}
      contentLabel='CSV Export'
      style={modalStyles}
    >
      <div>
        <button
          onClick={toggleModal}
          className='confirmation-modal-close-btn btn-link mb-3 mr-2'
        >
          <i className='fa fa-times confirmation-modal-close-icon' />
        </button>
        <div className='row my-3'>
          <div className='col-md-12 confirmation-modal-container'>
            <div className='confirmation-title-container'>
              <i
                className={`fa fa-download confirmation-modal-icon`}
                aria-hidden='true'
                style={{ color: "#34404b" }}
              />
            </div>
            <p className='text-center confirmation-modal-message'>
              {`Are you sure you want to export the ${reportType}?`}
            </p>
            <div className='confirmation-button-row'>
              <button
                className='confirmation-denied-button'
                onClick={toggleModal}
              >
                No
              </button>
              <CSVLink
                onClick={toggleModal}
                className='btn btn-primary confirmation-proceed-button'
                data={data}
                headers={headers}
                filename={filename}
              >
                Export
              </CSVLink>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CSVExportModal;
