import PropTypes from 'prop-types'
import classnames from 'classnames'
import moment from 'moment'
import { Link } from 'react-router-dom'

import { formatDates } from '../../utility/datesHelper'

import classes from './ProgramCard.module.scss'
import { ReactComponent as GolferHalfIcon } from '../../assets/images/common/icons/golfer-half-icon.svg'
import { ReactComponent as CalendarIcon } from '../../assets/images/common/icons/calendar-icon.svg'

const ProgramCard = ({ program, community, color, hasFutureRegistration }) => (
  <Link
    to={`class/${program.id}`}
    key={program.id}
    style={{ borderLeftColor: color }}
    className={classnames('mt-3 d-flex', classes.programCard)}
  >
    <div className='flex-grow-1 d-flex flex-column'>
      <h3 className={classes.name}>{program.name}</h3>
      <div className='flex-grow-1 d-flex flex-column justify-content-between justify-content-lg-start flex-lg-row'>
        <span className={classnames('d-lg-flex align-items-lg-center', classes.subtitle)}>{program.meetingTimes}</span>
        <div className={classnames('d-flex align-items-center', classes.subtitle)}>
          <CalendarIcon className={classes.subtitleIcon} />
          <span className='ml-2'>{formatDates(program.startDate, program.endDate, community.timezone)}</span>
        </div>
        {(program.spotsLeft !== null || hasFutureRegistration) && (
          <div className={classnames('d-flex align-items-center', classes.subtitle)}>
            {hasFutureRegistration ? (
              <span className='font-weight-bold'>Registration Opens: {moment(program.registrationStartsAt)
                .tz(community.timezone)
                .format('lll')}</span>
            ) : (program.spotsLeft !== null && (
              <>
                <GolferHalfIcon className={classes.subtitleIcon} />
                <span className={classnames('ml-2 mr-1', classes.spotsLeft)}>{program.spotsLeft}</span>
                Spots Open
              </>
            ))}
          </div>
        )}
      </div>
    </div>
    <div className={classnames(classes.action, 'flex-shrink-0 d-flex justify-content-end align-items-center')}>
      <i className={classnames('fa fa-angle-right', classes.angle)} />
      <span
        className={classnames(classes.actionText, { [classes.grayActionText]: hasFutureRegistration })}
      >
        {hasFutureRegistration ? 'View Details' : 'Learn More'}
      </span>
    </div>
  </Link>
)

ProgramCard.propTypes = {
  program: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    meetingTimes: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    spotsLeft: PropTypes.number,
    registrationStartsAt: PropTypes.string,
  }),
  community: PropTypes.shape({
    timezone: PropTypes.string,
  }),
  color: PropTypes.string,
  hasFutureRegistration: PropTypes.bool,
}

export default ProgramCard
