import * as constants from "./actionTypes";

import Axios from '../axios'

export function successRequestingInvitation(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_INVITATION,
      invitation: response.data.invitation
    };
  } else {
    console.log("ERROR DELETING INVITATION");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successRequestingDeleteInvitation(response) {
  if (response.status === 201) {
    return {
      type: constants.DELETE_INVITATION,
      invitation: response.data.invitation
    };
  } else {
    console.log("ERROR DELETING INVITATION");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

// THUNKS HERE
export function getInvitation(invitation_id) {
  return function(dispatch) {
    return requestInvitation(invitation_id).then(response =>
      dispatch(successRequestingInvitation(response))
    );
  };
}

export function deleteInvitation(invitation) {
  return function(dispatch) {
    return requestDeleteInvitation(invitation).then(response =>
      dispatch(successRequestingDeleteInvitation(response))
    );
  };
}

// API CALLS
function requestInvitation(invitation_id) {
  let requestUrl = "/api/v1/invitations/" + invitation_id;
  return Axios.get(requestUrl);
}

function requestDeleteInvitation(invitation) {
  let requestUrl = "/api/v1/invitations/" + invitation.id;
  return Axios.delete(requestUrl);
}
