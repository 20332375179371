import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { components } from 'react-select'

import { ReactComponent as OpenEyeIcon } from '../../../../assets/images/common/icons/programBuilder/open-eye-icon.svg'
import { ReactComponent as ClosedEyeIcon } from '../../../../assets/images/common/icons/programBuilder/closed-eye-icon.svg'
import { ReactComponent as LockIcon } from '../../../../assets/images/common/icons/lock-icon.svg'
import Select from '../../../common/form/Select'

import { setField } from '../../../../reducers/programBuilderReducer'

import './VisibilitySelect.scss'

const options = [
  { id: 0, value: 'public', name: 'Public', description: 'Can be seen by anyone on landing page marketplace' },
  {
    id: 1, value: 'unlisted', name: 'Unlisted', description: 'This Program will not show up on the registration page, \
      but will still have a private link that you can send to participants privately to register',
  },
  { id: 2, value: 'private', name: 'Private', description: 'Only the coach can see the program and students - there is no sign up page' },
]

const VisibilitySelect = () => {
  const dispatch = useDispatch()

  const programVisibility = useSelector(state => state.programBuilder.visibility)
  const [visibility, setVisibility] = useState(options.find(p => p.value === programVisibility))

  const handleVisibilityChange = (newVisibility) => {
    setVisibility(newVisibility)
    dispatch(setField({ field: 'visibility', value: newVisibility.value }))
  }

  /* eslint-disable react/prop-types */
  const Option = ({ data, ...props }) => (
    <components.Option {...props}>
      <div className='d-flex align-items-center visibility-option p-2'>
        {data.value === 'public' && <OpenEyeIcon aria-hidden='true' style={{ flexShrink: '0' }} />}
        {data.value === 'unlisted' && <ClosedEyeIcon aria-hidden='true' style={{ flexShrink: '0' }} />}
        {data.value === 'private' && <LockIcon aria-hidden='true' style={{ flexShrink: '0' }} />}

        <div className='d-flex flex-column justify-content-center ml-3'>
          <p className='name'>{data.name}</p>
          <p className='description'>{data.description}</p>
        </div>
      </div>
    </components.Option>
  )

  /* eslint-disable react/prop-types */
  const SingleValue = ({ data, ...props }) => (
    <components.SingleValue {...props}>
      <div className='d-flex align-items-center visibility-value pl-2'>
        {data.value === 'public' && <OpenEyeIcon aria-hidden='true' style={{ flexShrink: '0' }} />}
        {data.value === 'unlisted' && <ClosedEyeIcon aria-hidden='true' style={{ flexShrink: '0' }} />}
        {data.value === 'private' && <LockIcon aria-hidden='true' style={{ flexShrink: '0' }} />}

        <p className='name ml-3'>{data.name}</p>
      </div>
    </components.SingleValue>
  )

  return (
    <div id='VisibilitySelect'>
      <Select
        name='visibility-select'
        placeholder='Who can see your class?'
        value={visibility}
        options={options}
        components={{
          SingleValue,
          Option,
        }}
        isSearchable={false}
        onChange={handleVisibilityChange}
        styles={{ option: (provided) => ({ ...provided, paddingTop: '6px', paddingBottom: '6px' }) }}
      />
    </div>
  )
}

export default VisibilitySelect
