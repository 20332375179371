import styles from '../../../assets/styles/components/common/ExpandableSideBar.module.scss'
import { ReactComponent as Op36Icon } from '../../../assets/images/common/icons/sidebarMenu/Op36.svg'
import { ReactComponent as BurgerMenu } from '../../../assets/images/common/icons/sidebarMenu/BurgerMenu.svg'
import logoExpanded from '../../../assets/images/logos/horizontal-logo.svg'
import { NAVIGATION } from '../../../misc/Constants'
import { toggleNavigation } from '../../../utility/helperFunctions'
import { useRef, useEffect, useState } from 'react'
import SideBarProfile from './SideBarProfile'
import classNames from 'classnames'
import SideBarNavigationItems from './SideBarNavigationItems'
import { useSelector, useDispatch } from 'react-redux'
import { setSideBarState } from '../../../actions/commonActions'

const ExpandableSideBar = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user.current_user)
  const navigation = useSelector (state => state.ui.sideBarState)
  const showSideBar = useSelector(state => state.ui.showSideBar)
  const sideBarRef = useRef(null)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const changeNavigation = toggleNavigation(navigation)
  const expanded = navigation === NAVIGATION.EXPANDED

  useEffect(() => {
    function handleClickOutside(event) {
      if (sideBarRef.current && !sideBarRef.current.contains(event.target)) {
        expanded && dispatch(setSideBarState(changeNavigation))
      }
    }
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [changeNavigation, expanded, sideBarRef, dispatch])

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  if (!showSideBar || !user) {
    return null
  }

  return (
    (windowWidth >= 1200 || expanded) ?
      <div id={styles.expandableSideBar} className={classNames({ [styles.sideBarExpanded]: expanded })} ref={sideBarRef}>
        <button
          onClick={() => dispatch(setSideBarState(changeNavigation))}
          className={classNames(styles.logoWrapper, { [styles.logoWrapperExpanded]: expanded })}
        >
          {expanded ? <img alt={'Logo'} src={logoExpanded}/> : <Op36Icon className={styles.op36Logo}/>}
        </button>

        <SideBarNavigationItems />

        <div className={classNames(styles.arrowWrapper, { [styles.arrowWrapperExpanded]: expanded })}>
          <button className={classNames(styles.arrowButton, { [styles.arrowButtonExpanded]: expanded })}
            onClick={() => dispatch(setSideBarState(changeNavigation))}/>
        </div>

        <SideBarProfile />
      </div>
      :
      <div
        className={styles.burgerMenu}
        onKeyDown={(e) => e.key === 'Enter' && dispatch(setSideBarState(changeNavigation))}
        role='button'
        tabIndex='0'
        onClick={() => dispatch(setSideBarState(changeNavigation))}
      >
        <BurgerMenu/>
      </div>
  )
}
export default ExpandableSideBar

