import * as constants from "./actionTypes";
import * as common from "./commonActions";
import { objectToFormData } from "../utility/formHelper";
import _ from "lodash";

import Axios from '../axios'

export function successGrabbingLessonPlanDrills(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_LESSON_PLAN_DRILLS,
      drills: response.data.drills,
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successRequestingGetDrills(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_DRILLS,
      drills: response.data.drills,
      pages: response.data.meta.pagination
        ? response.data.meta.pagination.total_pages
        : null,
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successRequestingMyDrills(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_MY_DRILLS,
      drills: response.data.drills,
      // activities: response.data.activities,
      pages: response.data.meta.pagination
        ? response.data.meta.pagination.total_pages
        : null,
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successRequestingMyActivities(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_MY_ACTIVITIES,
      // drills: response.data.drills,
      activities: response.data.activities,
      pages: response.data.meta.pagination
        ? response.data.meta.pagination.total_pages
        : null,
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successRequestingPublicDrills(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_PUBLIC_DRILLS,
      drills: response.data.drills,
      pages: response.data.meta.pagination
        ? response.data.meta.pagination.total_pages
        : null,
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successRequestingPublicActivities(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_PUBLIC_ACTIVITIES,
      activities: response.data.drills,
      pages: response.data.meta.pagination
        ? response.data.meta.pagination.total_pages
        : null,
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successGrabbingPinnedDrills(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_MY_PINNED_DRILLS,
      drills: response.data.drills,
      activities: response.data.activities,
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successGrabbingFavoriteDrills(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_MY_FAVORITE_DRILLS,
      favorites: response.data.favorites,
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successGrabbingDrill(response) {
  if (response.status === 200) {
    return { type: constants.GRAB_ONE_DRILL, drill: response.data.drill };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successRequestingAddDrills(response) {
  if (response.status === 201) {
    return { type: constants.ADD_DRILL, drill: response.data.drill };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successRequestingUpdateDrill(response) {
  if (response.status === 201) {
    return { type: constants.UPDATE_DRILL, drill: response.data.drill };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successPinningDrill(response) {
  if (response.status === 201) {
    return { type: constants.PIN_DRILL, drill: response.data.drill };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successArchivingDrill(response) {
  if (response.status === 201) {
    return { type: constants.ARCHIVE_DRILL, drill: response.data.drill };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successHandlingFavorite(response) {
  if (response.status === 201) {
    return {
      type: constants.FAVORITE_DRILL,
      drill: response.data.drill,
      status: response.data.action,
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successGrabbingTopContributors(response) {
  if (response.status === 200) {
    return {
      type: constants.TOP_DRILL_MAKERS,
      users: response.data.users,
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function clearSelectedDrill() {
  return {
    type: constants.CLEAR_SELECTED_DRILL
  }
}

// THUNKS HERE

export function getLessonPlanDrills() {
  return function (dispatch) {
    return requestLessonPlanDrills().then((response) =>
      dispatch(successGrabbingLessonPlanDrills(response))
    );
  };
}

export function getDrills(tags, page, per_page, search) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true));
    return requestGetDrills(tags, page, per_page, search)
      .then((response) => dispatch(successRequestingGetDrills(response)))
      .then((turnOffSpinner) => dispatch(common.loadingChanged(false)));
  };
}

export function getMyDrills(tags, page, per_page, search) {
  return function (dispatch) {
    return requestMyDrills(tags, page, per_page, search).then((response) =>
      dispatch(successRequestingMyDrills(response))
    );
  };
}

export function getMyActivities(tags, page, per_page, search) {
  return function (dispatch) {
    return requestMyActivities(tags, page, per_page, search).then((response) =>
      dispatch(successRequestingMyActivities(response))
    );
  };
}

export function getPublicDrills(tags, page, per_page, search) {
  return function (dispatch) {
    return requestGetDrills(tags, page, per_page, search).then((response) =>
      dispatch(successRequestingPublicDrills(response))
    );
  };
}
export function getPublicActivities(tags, page, per_page, search) {
  return function (dispatch) {
    return requestGetDrills(tags, page, per_page, search).then((response) =>
      dispatch(successRequestingPublicActivities(response))
    );
  };
}

export function getPinnedDrills(user_id) {
  return function (dispatch) {
    return requestPinnedDrills(user_id).then((response) =>
      dispatch(successGrabbingPinnedDrills(response))
    );
  };
}

export function grabFavoriteDrills(user_id) {
  return function (dispatch) {
    return requestMyFavoriteDrills(user_id).then((response) =>
      dispatch(successGrabbingFavoriteDrills(response))
    );
  };
}

export function grabOneDrill(id) {
  return function (dispatch) {
    return requestOneDrill(id).then((response) =>
      dispatch(successGrabbingDrill(response))
    );
  };
}

export function addDrill(drill, video) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true));
    return requestAddDrill(drill, video)
      .then((response) => dispatch(successRequestingAddDrills(response)))
      .then((turnOffSpinner) => dispatch(common.loadingChanged(false)));
  };
}

export function updateDrill(drill, video) {
  return function (dispatch) {
    return requestUpdateDrill(drill, video).then((response) =>
      dispatch(successRequestingUpdateDrill(response))
    );
  };
}

export function pinDrill(drill) {
  return function (dispatch) {
    return requestPinDrill(drill).then((response) =>
      dispatch(successPinningDrill(response))
    );
  };
}

export function archiveDrill(drill_id) {
  return function (dispatch) {
    return requestArchiveDrill(drill_id).then((response) =>
      dispatch(successArchivingDrill(response))
    );
  };
}

export function favoriteDrill(drill_id) {
  return function (dispatch) {
    return handleFavoriteDrill(drill_id).then((response) =>
      dispatch(successHandlingFavorite(response))
    );
  };
}

export function grabTopContributors() {
  return function (dispatch) {
    return requestTopContributors().then((response) =>
      dispatch(successGrabbingTopContributors(response))
    );
  };
}

// API CALLS

function requestLessonPlanDrills() {
  let requestUrl = "/api/v1/drills?lesson_plan=true";
  return Axios.get(requestUrl);
}

function requestGetDrills(filters, page, per_page, search) {
  // filter will come through like this:
  //  {
  // skills: [:practice_type_ids]
  // tags: [:tag_ids]
  // type: 'Any', 'Drill', 'Coaching Activity' (one of these strings)
  // visibility: 'Any','Public', 'Private' (one of these strings)
  //  }

  let requestUrl = "/api/v1/drills";
  let params = { page, per_page, search };

  let skills =
    filters &&
    filters.skills &&
    filters.skills.length > 0 &&
    filters.skills.join(",");
  let tags =
    filters &&
    filters.tags &&
    filters.tags.length > 0 &&
    filters.tags.join(",");
  let type = filters && filters.type && filters.type !== "Any" && filters.type;
  let visibility =
    filters &&
    filters.visibility &&
    filters.visibility !== "Any" &&
    filters.visibility;
  if (skills) {
    params["practice_type_ids"] = skills;
  }
  if (tags) {
    params["tag_ids"] = tags;
  }
  if (type) {
    params["drill_type"] = type;
  }
  if (visibility) {
    params["visibility"] = visibility;
  }

  return Axios.get(requestUrl, {
    params: params,
  });
}

function requestMyDrills(filters, page, per_page, search) {
  let requestUrl = "/api/v1/drills/my_drills";

  let params = { page, per_page, search };

  let skills =
    filters &&
    filters.skills &&
    filters.skills.length > 0 &&
    filters.skills.join(",");
  let tags =
    filters &&
    filters.tags &&
    filters.tags.length > 0 &&
    filters.tags.join(",");
  let type = filters && filters.type && filters.type !== "Any" && filters.type;
  let visibility =
    filters &&
    filters.visibility &&
    filters.visibility !== "Any" &&
    filters.visibility;
  if (skills) {
    params["practice_type_ids"] = skills;
  }
  if (tags) {
    params["tag_ids"] = tags;
  }
  if (type) {
    params["drill_type"] = type;
  }
  if (visibility) {
    params["visibility"] = visibility;
  }

  return Axios.get(requestUrl, {
    params: params,
  });
}

function requestMyActivities(filters, page, per_page, search) {
  let requestUrl = "/api/v1/drills/my_activities";

  let params = { page, per_page, search };

  let skills =
    filters &&
    filters.skills &&
    filters.skills.length > 0 &&
    filters.skills.join(",");
  let tags =
    filters &&
    filters.tags &&
    filters.tags.length > 0 &&
    filters.tags.join(",");
  let type = filters && filters.type && filters.type !== "Any" && filters.type;
  let visibility =
    filters &&
    filters.visibility &&
    filters.visibility !== "Any" &&
    filters.visibility;
  if (skills) {
    params["practice_type_ids"] = skills;
  }
  if (tags) {
    params["tag_ids"] = tags;
  }
  if (type) {
    params["drill_type"] = type;
  }
  if (visibility) {
    params["visibility"] = visibility;
  }

  return Axios.get(requestUrl, {
    params: params,
  });
}

function requestPinnedDrills(user_id) {
  let requestUrl = "/api/v1/drills/pinned";
  if (user_id) {
    requestUrl += `?user_id=${user_id}`;
  }
  return Axios.get(requestUrl);
}

function requestMyFavoriteDrills(user_id) {
  let requestUrl = "/api/v1/drills/my_favorite_drills";
  if (user_id) {
    requestUrl += `?user_id=${user_id}`;
  }

  return Axios.get(requestUrl);
}

function requestOneDrill(id) {
  let requestUrl = "/api/v1/drills/" + id;
  return Axios.get(requestUrl);
}

function requestAddDrill(drill, video) {
  let requestUrl = "/api/v1/drills";
  const data = new FormData();
  for (const key in drill) {
    if (drill.hasOwnProperty(key) && drill[key] !== "") {
      data.append(key, drill[key]);
    }
  }
  if (video) {
    data.append("video", video);
  }

  if (drill.is_coach_activity) {
    data.append("type", "DrillCoachActivity");
  } else {
    data.append("type", "DrillExercise");
  }

  if (drill.photos && drill.photos.length > 0) {
    for (let i = 0; i < drill.photos.length; i++) {
      data.append(`drill_photo_${i}`, drill.photos[i].file);
    }
  }

  return Axios.post(requestUrl, data);
}

function requestUpdateDrill(drill, video) {
  let requestUrl = "/api/v1/drills/" + drill.id;
  let drillClone = _.cloneDeep(drill);
  delete drillClone.photos;
  const data = objectToFormData(drillClone);

  if (drill.photos && drill.photos.length > 0) {
    for (let i = 0; i < drill.photos.length; i++) {
      data.append(`drill_photo_${i}`, drill.photos[i].file);
    }
  }

  if (video) {
    data.append("video", video);
  }

  return Axios.put(requestUrl, data);
}

function requestPinDrill(drill) {
  let requestUrl = "/api/v1/drills/" + drill.id;
  const data = new FormData();
  for (const key in drill) {
    if (drill.hasOwnProperty(key) && drill[key] !== "") {
      data.append(key, drill[key]);
    }
  }
  return Axios.put(requestUrl, data);
}

function requestArchiveDrill(drill_id) {
  let requestUrl = "/api/v1/drills/" + drill_id;
  const data = { id: drill_id, is_archived: true };
  return Axios.put(requestUrl, data);
}

function handleFavoriteDrill(drill_id) {
  let requestUrl = `/api/v1/drills/${drill_id}/favorite`;
  return Axios.post(requestUrl);
}

function requestTopContributors() {
  let requestUrl = "/api/v1/drills/top_contributors";
  return Axios.get(requestUrl);
}
