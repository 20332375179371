import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import './DrillsBuilder.scss'

import * as commonActions from '../../../actions/commonActions'
import * as userActions from '../../../actions/userActions'
import * as drillActions from '../../../actions/drillActions'
import * as tagActions from '../../../actions/tagActions'
import * as practiceTypesActions from '../../../actions/practiceTypeActions'
import * as skillLevelActions from '../../../actions/skillLevelActions'

import WorkflowProgressBar from '../../common/WorkflowProgressBar'
import TwoButtonModal from '../../lessonPlans/modals/TwoButtonModal'
import DrillFailedValidationModal from '../modals/DrillFailedValidationModal'

import DrillBasicDetails from './builderPages/DrillBasicDetail'
import DrillTagsAndVisibility from './builderPages/DrillTagsAndVisibility'
import DrillReviewAndPublish from './builderPages/DrillReviewAndPublish'
import BaseModal from '../../common/BaseModal'
import EditSkillsModal from '../modals/EditSkillsModal'
import LessonPlanActionModal from '../../lessonPlans/modals/LessonPlanActionModal'

import blankIcon from '../../../assets/images/common/icons/lesson_plans/blank-template.png'
import homeIcon from '../../../assets/images/common/icons/lesson_plans/house.png'
import Loader from '../../common/Op36Loader-web'
import DrillLegalModal from '../modals/DrillLegalModal'
import PropTypes from 'prop-types'
import { withRouting } from '../../common/hooks'

const maxFileSize = 52428800 // 50 MB

class DrillsBuilder extends React.Component {
  state = {
    is_edit: false, // let components know if you're editing an existing drill or building a new one
    type: '', // controls if you're building a drill or activity
    page: 1, // current page that's showing
    pageProgress: 1, // how far you got so you can jump back
    failedValidation: [], // keeps track of failed validation from any page
    discardModal: false, // confirmation modal for quitting w/o saving
    skillModal: false,
    publishModal: false,
    saveDraftModal: false,
    legalModal: false,
    // start Drill columns
    title: '',
    description: '',
    equipment: [''],
    steps: [''],
    content: '',
    privacy: -1,
    tags: [],
    customTags: [],
    selectedSkills: [],
    score_type: 'high',
    score_label: '',
    score_name: 'numeric',
    star_label_one: '',
    star_label_five: '',
    score_detail: '',
    drill_tier: '',
    // end drill columns
    tagLoader: true,
    practiceTypesLoader: true,
    builderLoader: true,
    videoPreview: null,
    videoLoader: false,
    videoTooLarge: false,
    videoError: '',
    video: null,
    photos: [],
    photoModal: -1,
    originalVideoUrl: null, // only used for edit flow = shows the currently attached video url (vimeo url)
    originalS3VideoUrl: null, // only used for edit flow = shows the currently attached video url (s3 url)
    somethingHappened: false, // catches erroring out while searching for a specific drill to edit
    removedS3Photos: [], // hold the ids of photos that were previously saved that will be removed
    saving: false, // use this to apply a loader while the drill is saving
    consent: null, // will be an object {consented: <moment date/time obj>, version: 'string'}
    scoringEnabled: false,
    selectedSkillLevels: [],
    skillLevelLoader: true,
  }
  componentDidMount() {
    if (this.props.params && this.props.params.drillId) {
      this._setBuilder('edit')
    } else {
      this._setBuilder('new')
    }
  }
  render() {
    if (window.$zopim && window.$zopim.livechat) {
      window.$zopim.livechat.hideAll()
    }

    const is_drill = this.state.type === 'drill'
    return (
      <div id='DrillsBuilder'>
        <div className='lesson-builder-topnav'>
          <div className='row'>
            <div className='col-sm-4'>
              <h3>Drills & Activities Builder</h3>
            </div>
            <WorkflowProgressBar
              titles={[
                'Add Basic Details',
                'Set Tags and Visibility',
                'Review and Publish',
              ]}
              page={this.state.page}
              handleClick={this._handleProgressBarClick}
              pageProgress={this.state.pageProgress}
            />
          </div>
        </div>
        <div className='builder-content-area'>
          {this._renderContent(this.state.page, is_drill)}
        </div>
        <div className='lesson-builder-footer-container'>
          <button
            className='lesson-plan-builder-action-btn lp-btn-secondary'
            onClick={this._handleDiscardClick}
            disabled={this.state.videoLoader}
          >
            Discard Changes
          </button>
          <button
            className='lesson-plan-builder-action-btn lp-btn-primary next-btn-drill'
            onClick={this._handleNextClick}
            disabled={this.state.videoLoader && this.state.page === 3}
          >
            {this.state.page < 3 ? 'Next' : this._checkPublishWording()}
          </button>
          {/* </div> */}
        </div>
        {this.state.discardModal && (
          <TwoButtonModal
            title={'Discard drill?'}
            subtitle={
              'If you discard this drill, all your progress so far will be lost! This cannot be undone.'
            }
            close={this._closeDiscardModal}
            confirmText={'Discard'}
            confirmAction={this._handleDiscard}
          />
        )}
        {this.state.failedValidation.length > 0 && (
          <DrillFailedValidationModal
            fails={this.state.failedValidation}
            close={this._closeValidationModal}
            is_drill={is_drill}
            navigateText={false}
            navigate={false}
          />
        )}
        {this.state.skillModal && (
          <BaseModal close={this._closeSkillModal} width={'70vw'}>
            <EditSkillsModal
              current={this.state.selectedSkills}
              allSkills={this.props.practice_types}
              loader={this.state.practiceTypesLoader}
              applyChanges={this._changeSkills}
            />
          </BaseModal>
        )}
        {this.state.legalModal && (
          <DrillLegalModal
            close={this._closeLegalModal}
            onAgree={this._handleConsentAgree}
            type={is_drill ? 'drill' : 'activity'}
          />
        )}
        {this.state.publishModal && (
          <LessonPlanActionModal
            title={`Ready to ${this.state.is_edit ? 'Update' : 'Publish'}!`}
            subtitle={'What would you like to do next?'}
            close={this._closePublishModal}
            links={[
              {
                onClick: this.state.is_edit
                  ? () => this._editDrill('refresh')
                  : () => this._publishDrill('refresh'),
                name: `Create another ${is_drill ? 'drill' : 'activity'}`,
                icon: blankIcon,
              },
              {
                onClick: this.state.is_edit
                  ? () => this._editDrill('home')
                  : () => this._publishDrill('home'),
                name: 'Return to homepage',
                icon: homeIcon,
              },
            ]}
          />
        )}
        {this.state.photoModal > -1 && (
          <BaseModal close={this._closePhotoModal} width={'min-content'}>
            <div className='d-flex align-items-center justify-content-center'>
              <img
                style={{ borderRadius: '8px', maxHeight: '400px' }}
                src={this.state.photos[this.state.photoModal].src}
                alt={
                  this.state.photos[this.state.photoModal].file
                    ? this.state.photos[this.state.photoModal].file.name
                    : 'drill_photo_' + this.state.photoModal
                }
              />
            </div>
          </BaseModal>
        )}
        {this.state.saving && (
          <BaseModal close={() => false} width={'500px'} hideClose={true}>
            <div className='d-flex flex-column align-items-center justify-content-center'>
              <i
                className='fa fa-spinner fa-spin'
                aria-hidden='true'
                style={{ color: '#ff6633', fontSize: '72px' }}
              />
              <p style={{ fontSize: '18px' }}>
                {this.state.is_edit ? 'Updating' : 'Saving'}{' '}
                {is_drill ? 'Drill' : 'Activity'}
              </p>
            </div>
          </BaseModal>
        )}
      </div>
    )
  }

  //////////////////////////////////////////
  /// RENDER MAIN CONTENT BASED ON PAGE ///
  ////////////////////////////////////////

  _renderContent = (page, is_drill) => {
    const {
      title,
      description,
      equipment,
      steps,
      content,
      privacy,
      tags,
      customTags,
      selectedSkills,
      builderLoader,
      somethingHappened,
      videoPreview,
      videoLoader,
      videoTooLarge,
      videoError,
      video,
      photos,
      originalVideoUrl,
      originalS3VideoUrl,
      score_type,
      score_label,
      score_name,
      star_label_one,
      star_label_five,
      score_detail,
      drill_tier,
      scoringEnabled,
      selectedSkillLevels,
      skillLevelLoader,
    } = this.state
    if (somethingHappened) {
      return (
        <p style={{ textAlign: 'center', paddingTop: 100 }}>
          Well... looks like something happened loading this drill/activity.
          Click &apos;Discard Changes&apos; to get out of here.
        </p>
      )
    }
    if (builderLoader) {
      return <Loader message='searching drills and activities' />
    }
    switch (page) {
      case 1:
        return (
          <DrillBasicDetails
            is_drill={is_drill}
            title={title}
            description={description}
            equipment={equipment}
            steps={steps}
            content={content}
            handleInputChange={this._handleInputChange}
            handleArrayInput={this._handleArrayInput}
            addListItem={this._handleNewListItem}
            removeListItem={this._handleDeleteListItem}
            handleHTML={this._handleHTML}
            onVideoAdded={this._handleVideo}
            videoPreview={videoPreview}
            videoLoader={videoLoader}
            removeVideo={this._deleteFromS3}
            videoTooLarge={videoTooLarge}
            videoError={videoError}
            video={video}
            dismissVideoError={this._clearVideoError}
            dismissVideoTooLarge={this._clearVideoTooLarge}
            onPhotoAdded={this._handlePhoto}
            photos={photos}
            removePhoto={this._removePhoto}
            blowUpPhoto={this._triggerPhotoModal}
            originalVideoUrl={originalVideoUrl}
            removeOriginalVideo={this._removeOriginalVideo}
            awsBackupUrl={originalS3VideoUrl}
            score_type={score_type}
            score_label={score_label}
            score_name={score_name}
            star_label_one={star_label_one}
            star_label_five={star_label_five}
            score_detail={score_detail}
            isAdmin={this.props.user.type === 'Admin'}
            setScoreInfo={this._setScoreInfo}
            scoringEnabled={scoringEnabled}
            handleScoringToggle={this._handleScoringToggle}
            selectedSkillLevels={selectedSkillLevels}
            skillLevelLoader={skillLevelLoader}
            allSkillLevels={this.props.skill_levels}
            handleSkillLevelClick={this._handleSkillLevelClick}
            is_edit={this.state.is_edit}
          />
        )
      case 2:
        return (
          <DrillTagsAndVisibility
            isAdmin={this.props.user.type === 'Admin'}
            is_drill={is_drill}
            privacy={privacy}
            setPrivacy={this._handlePrivacyChange}
            tags={tags}
            all_tags={this.props.all_tags}
            all_tags_loader={this.state.tagLoader}
            customTags={customTags}
            addTag={this._addTag}
            addCustomTag={this._addCustomTag}
            removeTag={this._removeTag}
            removeCustomTag={this._removeCustomTag}
            drill_tier={drill_tier}
            setDrillTier={this._setDrillTier}
          />
        )
      case 3:
        return (
          <DrillReviewAndPublish
            drill={{
              privacy,
              tags,
              custom_tags: customTags,
              title,
              content,
              description,
              equipment,
              steps,
              is_coach_activity: !is_drill,
              skills: selectedSkills,
              photos: photos.map((p) => ({ file_url: p.src })),
              video: video,
              video_url: originalVideoUrl,
            }}
            navigate={this._handleProgressBarClick}
            triggerSkillModal={this._showSkillModal}
            allSkills={this.props.practice_types}
            allTags={this.props.all_tags}
            //==========================================
            videoPreview={videoPreview}
            videoLoader={videoLoader}
            videoTooLarge={videoTooLarge}
            videoError={videoError}
            awsBackupUrl={originalS3VideoUrl}
          />
        )
      default:
        return <p>something went wrong</p>
    }
  }

  /////////////////////
  /// SAVING LOGIC ///
  ///////////////////

  _publishDrill = (next) => {
    const { scoringEnabled, drill_tier, selectedSkillLevels } = this.state
    // handles a brand new drill
    this.setState({ publishModal: false, saving: true }, () => {
      const drill = {
        user_id: this.props.user.id,
        title: this.state.title,
        description: this.state.description,
        content: this.state.content,
        custom_tags: JSON.stringify(this.state.customTags),
        is_coach_activity: this.state.type === 'activity',
        privacy: this.state.privacy,
        is_draft: false,
        is_pinned: false,
        tag_ids: JSON.stringify(this.state.tags),
        steps: JSON.stringify(this.state.steps),
        equipment: JSON.stringify(this.state.equipment),
        skill_ids: JSON.stringify(this.state.selectedSkills),
        consent: JSON.stringify(this.state.consent),
        is_admin:
          this.state.type === 'drill' &&
          this.props.user.type === 'Admin' &&
          scoringEnabled,
        ...(scoringEnabled && this._pullScoringData()),
        ...(this._isMakingAdminDrillExercise() && {
          requires_premium: drill_tier === 'premium',
        }),
        ...(this._isMakingAdminDrillExercise() && {
          drill_skill_levels_attributes: { ...selectedSkillLevels },
        }),
      }

      const { video, photos } = this.state

      if (photos.length > 0) {
        drill.photos = photos
      }

      this.props.drill_actions.addDrill(drill, video).then(() => {
        if (next === 'home') {
          return this.props.navigate('/drills')
        }
        if (next === 'refresh') {
          return this._startNewDrill()
        }
      })
    })
  }

  _editDrill = (next) => {
    const { scoringEnabled, drill_tier, selectedSkillLevels } = this.state
    // handles publishing (updating) and existing drill
    this.setState({ publishModal: false, saving: true }, () => {
      const drill = {
        id: parseInt(this.props.params.drillId, 10),
        title: this.state.title,
        description: this.state.description,
        content: this.state.content,
        custom_tags: JSON.stringify(this.state.customTags),
        privacy: this.state.privacy,
        is_draft: false,
        tag_ids: JSON.stringify(this.state.tags),
        steps: JSON.stringify(this.state.steps),
        equipment: JSON.stringify(this.state.equipment),
        skill_ids: JSON.stringify(this.state.selectedSkills),
        consent: JSON.stringify(this.state.consent),
        ...(this._isMakingAdminDrillExercise() && {
          requires_premium: drill_tier === 'premium',
        }),
        ...(this._isMakingAdminDrillExercise() && {
          drill_skill_levels_attributes: { ...selectedSkillLevels },
        }),
        // score stuff not included on strong parameters on api for drill update.
        // just adding here in case we want to be able to edit scoring later
        ...(scoringEnabled && this._pullScoringData()),
        ...(this._shouldRemoveScoring() && {
          remove_scoring: 'something true',
        }),
      }

      const { video, photos, removedS3Photos } = this.state

      const localPhotos = photos.filter((p) => p.isLocal)

      if (localPhotos.length > 0) {
        drill.photos = localPhotos
      }

      // Take into consideration marking remove_video = true only if the user didn't upload another video
      if (!video) {
        const oldVimeo = this.props.selected_drill && this.props.selected_drill.video_url
        const newVimeo = this.state.originalVideoUrl
        const oldS3 = this.props.selected_drill &&
                      this.props.selected_drill.video &&
                      this.props.selected_drill.video.attachment_url
        const newS3 = this.state.originalS3VideoUrl

        // Check if the drill *had* either a Vimeo or an S3 video and it was removed
        if ((oldVimeo && !newVimeo) || (oldS3 && !newS3)) {
          drill.remove_video = true
        }
      }

      if (removedS3Photos.length > 0) {
        drill.photos_to_remove = JSON.stringify(removedS3Photos)
      }

      this.props.drill_actions.updateDrill(drill, video).then(() => {
        if (next === 'home') {
          return this.props.navigate('/drills')
        }
        if (next === 'refresh') {
          return this._startNewDrill()
        }
      })
    })
  }

  /////////////////////////
  /// END SAVING LOGIC ///
  ///////////////////////

  ////////////////////////
  /// ONCHANGE EVENTS ///
  //////////////////////

  _handleScoringToggle = () => {
    this.setState((prevState) => ({ scoringEnabled: !prevState.scoringEnabled }))
  }

  _setScoreInfo = (field, value) => {
    this.setState({ [field]: value })
  }

  _handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  _handleArrayInput = (name, index, value) => {
    const arrCopy = this.state[name]
    arrCopy[index] = value
    this.setState({ [name]: arrCopy })
  }

  _handleNewListItem = (name) => {
    const arrCopy = this.state[name]
    arrCopy.push('')
    this.setState({ [name]: arrCopy })
  }

  _handleDeleteListItem = (name, index) => {
    const arrCopy = this.state[name]
    arrCopy.splice(index, 1)
    this.setState({ [name]: arrCopy })
  }

  _handleHTML = (htmlString) => {
    this.setState({ content: htmlString })
  }

  _handlePrivacyChange = (num) => {
    const { privacy } = this.state
    if (num === privacy) {
      return false
    }
    this.setState({ privacy: num })
  }

  _setDrillTier = (val) => {
    const { drill_tier } = this.state
    if (val === drill_tier) {
      return false
    }
    this.setState({ drill_tier: val })
  }

  _addTag = (id) => {
    const { tags } = this.state
    this.setState({ tags: [...tags, id] })
  }

  _removeTag = (id) => {
    const { tags } = this.state
    this.setState({ tags: tags.filter((t) => t !== id) })
  }

  _addCustomTag = (text) => {
    const { customTags } = this.state
    this.setState({ customTags: [...customTags, text] })
  }

  _removeCustomTag = (index) => {
    const { customTags } = this.state
    const copy = [...customTags]
    copy.splice(index, 1)
    this.setState({ customTags: copy })
  }

  _changeSkills = (newSkills) => {
    this.setState({ selectedSkills: newSkills }, () => {
      this._closeSkillModal()
    })
  }

  _handleVideo = (e) => {
    const file = e.target.files[0]
    if (file.size > maxFileSize) {
      return this.setState({ videoTooLarge: true })
    }

    this.setState({ video: file, videoPreview: file })
  }

  _handlePhoto = (event) => {
    let file
    if (event.target.value && event.target.files[0]) {
      file = event.target.files[0]
    }
    const reader = new window.FileReader()
    reader.onload = (e) => {
      const { photos } = this.state
      const photosCopy = [...photos]
      photosCopy.push({
        file,
        src: e.target.result,
        isLocal: true,
      })
      this.setState({ photos: photosCopy })
    }
    if (file) {
      reader.readAsDataURL(file)
    }
  }

  _deleteFromS3 = () => {
    this.setState({ video: null, videoPreview: null })
  }

  _removePhoto = (e, index, isLocal, id) => {
    e.stopPropagation()
    const { photos } = this.state
    this.setState({ photos: photos.filter((p, i) => i !== index) }, () => {
      if (!isLocal) {
        this.setState({ removedS3Photos: [...this.state.removedS3Photos, id] })
      }
    })
  }

  _removeOriginalVideo = () => {
    this.setState({ originalVideoUrl: null, originalS3VideoUrl: null })
  }

  _handleConsentAgree = (consent) => {
    this.setState({ consent }, () => {
      this._moveToPublish()
    })
  }

  ////////////////////////////
  /// END ONCHANGE EVENTS ///
  //////////////////////////

  ///////////////////////
  /// CLICK HANDLERS ///
  /////////////////////

  _handleSkillLevelClick = (e, skillLevelId) => {
    if (e.target.checked) {
      const skillLevelStateCopy = [...this.state.selectedSkillLevels]
      const markedForDestruction = skillLevelStateCopy.find(
        (sl) => sl.skill_level_id === skillLevelId && sl._destroy
      )
      if (markedForDestruction) {
        delete markedForDestruction._destroy
        this.setState({ selectedSkillLevels: skillLevelStateCopy })
      } else {
        skillLevelStateCopy.push({
          id: '',
          skill_level_id: skillLevelId,
        })
        this.setState({ selectedSkillLevels: skillLevelStateCopy })
      }
    } else {
      const skillLevelStateCopy = this.state.selectedSkillLevels
        .map((sl) => {
          if (sl.skill_level_id === skillLevelId) {
            if (sl.id) {
              return Object.assign(sl, { _destroy: '1' })
            } else {
              return null
            }
          } else {
            return sl
          }
        })
        .filter((sl) => !!sl)
      this.setState({ selectedSkillLevels: skillLevelStateCopy })
    }
  }

  _handleProgressBarClick = (target) => {
    const { page } = this.state
    if (target > page) {
      let failedValidation = []
      for (let i = page; i < target; i++) {
        failedValidation = this._checkValidation(i)
        if (failedValidation.length !== 0) {
          break
        }
      }
      if (failedValidation.length === 0) {
        this.setState({ page: target, failedValidation: [] })
      } else {
        this.setState({ failedValidation: failedValidation })
      }
    } else {
      this.setState({ page: target })
    }
  }

  _handleNextClick = () => {
    const { page, pageProgress } = this.state
    if (page < 3) {
      const failedValidation = this._checkValidation(page)
      if (failedValidation.length === 0) {
        if (page + 1 > pageProgress) {
          this.setState({
            pageProgress: page + 1,
            page: page + 1,
            failedValidation: [],
          })
        } else {
          this.setState({ page: page + 1, failedValidation: [] })
        }
      } else {
        this.setState({ failedValidation: failedValidation })
      }
    } else {
      this.setState({ legalModal: true })
    }
  }

  _handleDiscard = () => {
    if (this.state.video) {
      this._deleteFromS3()
    }
    this.props.navigate('/drills')
  }

  // SHOW / HIDE MODALS

  _handleDiscardClick = () => {
    this.setState({ discardModal: true })
  }

  _closeDiscardModal = () => {
    this.setState({ discardModal: false })
  }

  _closeValidationModal = () => {
    this.setState({ failedValidation: [] })
  }

  _showSkillModal = () => {
    this.setState({ skillModal: true })
  }

  _closeSkillModal = () => {
    this.setState({ skillModal: false })
  }

  _closePublishModal = () => {
    this.setState({ publishModal: false })
  }

  _clearVideoTooLarge = () => {
    this.setState({ videoTooLarge: false })
  }

  _clearVideoError = () => {
    this.setState({ videoError: '', video: null, videoPreview: null })
  }

  _triggerPhotoModal = (i) => {
    this.setState({ photoModal: i })
  }

  _closePhotoModal = () => {
    this.setState({ photoModal: -1 })
  }

  _closeLegalModal = () => {
    this.setState({ legalModal: false })
  }

  _moveToPublish = () => {
    this.setState({ legalModal: false }, () => {
      this.setState({ publishModal: true })
    })
  }

  ///////////////////////////
  /// END CLICK HANDLERS ///
  /////////////////////////

  /////////////////////////
  /// HELPER FUNCTIONS ///
  ///////////////////////

  _checkPublishWording = () =>
    //check if you're editting a current drill, if so, return 'Update'
    // else
    this.state.is_edit ? 'Update' : 'Publish'

  // eslint-disable-next-line complexity
  _checkValidation = (page) => {
    const {
      title,
      description,
      equipment,
      steps,
      content,
      privacy,
      scoringEnabled,
      score_name,
      score_label,
      star_label_one,
      star_label_five,
      drill_tier,
    } = this.state
    const failArray = []
    if (page === 1) {
      //check for page 1 stuff
      if (!title) {
        failArray.push('title')
      }
      if (!description) {
        failArray.push('description')
      }
      if (equipment.length < 1) {
        failArray.push('equipment')
      } else if (equipment.includes('')) {
        failArray.push('blank_equipment')
      }
      if (steps.length < 1) {
        failArray.push('steps')
      } else if (steps.includes('')) {
        failArray.push('blank_steps')
      }
      if (!content || content === '<p><br></p>') {
        failArray.push('content')
      }
      if (scoringEnabled) {
        if (score_name === 'numeric' && !score_label) {
          failArray.push('numeric_scoring_measurement')
        }
        if (score_name === 'star' && (!star_label_one || !star_label_five)) {
          failArray.push('star_high_low_label')
        }
        if (score_name === 'star' && !score_label) {
          failArray.push('star_question_to_ask')
        }
      }
    }
    if (page === 2) {
      //check for page 2 stuff
      if (privacy < 0) {
        failArray.push('privacy')
      }
      if (
        this.state.type === 'drill' &&
        this.props.user.type === 'Admin' &&
        !drill_tier
      ) {
        failArray.push('drill_tier')
      }
    }
    return failArray
  }

  _startNewDrill = () => {
    this.setState({
      is_edit: false, // let components know if you're editing an existing drill or building a new one
      page: 1, // current page that's showing
      pageProgress: 1, // how far you got so you can jump back
      failedValidation: [], // keeps track of failed validation from any page
      discardModal: false, // confirmation modal for quitting w/o saving
      skillModal: false,
      publishModal: false,
      saveDraftModal: false,
      // start Drill columns
      title: '',
      description: '',
      equipment: [''],
      steps: [''],
      content: '',
      privacy: -1,
      tags: [],
      customTags: [],
      selectedSkills: [],
      score_type: 'high',
      score_label: '',
      score_name: 'numeric',
      star_label_one: '',
      star_label_five: '',
      score_detail: '',
      videoPreview: null,
      videoLoader: false,
      videoTooLarge: false,
      videoError: '',
      video: null,
      photos: [],
      photoModal: -1,
      saving: false, // use this to apply a loader while the drill is saving
      consent: null,
      scoringEnabled: false,
      selectedSkillLevels: [],
    })
  }

  _setBuilder = (status) => {
    if (status === 'new') {
      // this component has to recieve information from the drills page (gets passed in the props.location.state) saying if you're building a drill
      // or activity, and, if it's a drill, which skills the user has selected (will be an array of ids {grab all skills from API})
      if (!this.props.location.state || !this.props.location.state.type) {
        // sends you elsewhere if cannot figure out if you're building a drill or activity
        this.props.navigate('/drills')
      } else {
        if (
          this.props.location.state.type === 'drill' &&
          (!this.props.location.state.selectedSkills ||
            this.props.location.state.selectedSkills.length < 1)
        ) {
          // send back if you're building a drill that doesn't have any skills - this shouldn't happen
          return this.props.navigate('/drills')
        }
        this.setState({
          type: this.props.location.state.type,
          selectedSkills: this.props.location.state.selectedSkills,
          builderLoader: false,
        })
        this.props.common_actions.showNavBar(false)
        this.props.common_actions.showSideBar(false)

        this.props.drill_actions.clearSelectedDrill()

        this.props.tag_actions
          .getTags()
          .then(() => this.setState({ tagLoader: false }))

        this.props.practice_types_actions
          .getPracticeTypes()
          .then(() => this.setState({ practiceTypesLoader: false }))

        this.props.skill_levels_actions.getSkillLevels().then(() => {
          this.setState({ skillLevelLoader: false })
        })
      }
    } else if (status === 'edit') {
      // this process is called if you reach the editor to edit an pre-existing drill. The id should be in the url.

      this.props.drill_actions
        .grabOneDrill(parseInt(this.props.params.drillId, 10))
        .then(() => {
          const drill = JSON.parse(JSON.stringify(this.props.selected_drill))

          if (this.props.user.type !== 'Admin' && this.props.user.id !== drill.user.id) {
            this.props.navigate('/not-today')
          }

          this.setState(
            {
              is_edit: true,
              type: drill.is_coach_activity ? 'activity' : 'drill',
              title: drill.title,
              description: drill.description,
              equipment: drill.equipment,
              steps: drill.steps,
              content: drill.content,
              privacy: drill.privacy === 'public_shared' ? 1 : 0,
              tags: drill.tags.map((t) => t.id),
              customTags: drill.custom_tags,
              selectedSkills: drill.practice_types.map((s) => s.id),
              originalVideoUrl: drill.video_url,
              originalS3VideoUrl: drill && drill.video && drill.video.attachment_url,
              photos: drill.photos.map((p) => ({ src: p.file_url, isLocal: false, id: p.id })),
              selectedSkillLevels: drill.drill_skill_levels,
            },
            () => {
              if (this._isMakingAdminDrillExercise()) {
                // need to set scoring stuff if this is an admin working with a DrillExercise
                this._setScoringData(drill)
                this.props.skill_levels_actions.getSkillLevels().then(() => {
                  this.setState({ skillLevelLoader: false })
                })
              } else {
                this.setState({ builderLoader: false })
              }
            }
          )
        })
        .catch((_) => {
          this.setState({ somethingHappened: true })
        })

      this.props.common_actions.showNavBar(false)
      this.props.common_actions.showSideBar(false)

      this.props.tag_actions
        .getTags()
        .then(() => this.setState({ tagLoader: false }))

      this.props.practice_types_actions
        .getPracticeTypes()
        .then(() => this.setState({ practiceTypesLoader: false }))
    }
  }

  _setScoringData = (apiDrill) => {
    // the scoring data with apiDrill.scoring is in the same format as the state of this component,
    // so it doesn't have to be adapeted
    if (apiDrill.scoring) {
      const scoringStateObject = Object.assign(
        { scoringEnabled: true },
        apiDrill.scoring
      )
      this.setState(scoringStateObject, () => {
        this.setState({
          builderLoader: false,
          drill_tier: apiDrill.requires_premium ? 'premium' : 'basic',
        })
      })
    } else {
      this.setState({
        builderLoader: false,
        drill_tier: apiDrill.requires_premium ? 'premium' : 'basic',
      })
    }
  }

  _pullScoringData = () => {
    const shouldIncludeScoring =
      this.props.user.type === 'Admin' && this.state.type === 'drill'
    if (!shouldIncludeScoring) {
      return {}
    }

    const {
      score_label,
      score_name,
      score_type,
      star_label_five,
      star_label_one,
      score_detail,
    } = this.state
    if (score_name === 'numeric') {
      return {
        score_name,
        score_label,
        score_type,
        score_detail,
      }
    }
    if (score_name === 'star') {
      return {
        score_name,
        score_label,
        star_label_one,
        star_label_five,
      }
    }
  }

  _isMakingAdminDrillExercise = () => this.state.type === 'drill' && this.props.user.type === 'Admin'

  _shouldRemoveScoring = () => {
    const { scoringEnabled } = this.state
    const { selected_drill } = this.props
    // the current drill will have values within selected_drill.scoring if scoring is currently enabled
    // so if we shut off scoring, we need to send message to delete scoring if it exists api side
    if (!selected_drill || scoringEnabled) { return false }
    return !scoringEnabled && selected_drill.scoring
  }

  /////////////////////////////
  /// END HELPER FUNCTIONS ///
  ///////////////////////////
}

DrillsBuilder.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
  }),
  selected_drill: PropTypes.shape({
    video: PropTypes.shape({
      attachment_url: PropTypes.string,
    }),
    video_url: PropTypes.string,
    scoring: PropTypes.number,
  }),
  practice_types: PropTypes.array,
  all_tags: PropTypes.array,
  skill_levels: PropTypes.array,
  navigate: PropTypes.func,
  location: PropTypes.shape({
    state: PropTypes.shape({
      type: PropTypes.string,
      selectedSkills: PropTypes.array,
    }),
  }),
  params: PropTypes.shape({
    drillId: PropTypes.string,
  }),
  common_actions: PropTypes.shape({
    showNavBar: PropTypes.func,
    showSideBar: PropTypes.func,
  }),
  drill_actions: PropTypes.shape({
    addDrill: PropTypes.func,
    updateDrill: PropTypes.func,
    clearSelectedDrill: PropTypes.func,
    grabOneDrill: PropTypes.func,
  }),
  practice_types_actions: PropTypes.shape({
    getPracticeTypes: PropTypes.func,
  }),
  skill_levels_actions: PropTypes.shape({
    getSkillLevels: PropTypes.func,
  }),
  tag_actions: PropTypes.shape({
    getTags: PropTypes.func,
  }),
}

function mapStateToProps(state, _) {
  return {
    ui: state.ui,
    user: state.user.current_user,
    selected_drill: state.drills.selected_drill,
    all_tags: state.tags,
    practice_types: state.practiceTypes,
    skill_levels: state.skillLevels,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    common_actions: bindActionCreators(commonActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
    drill_actions: bindActionCreators(drillActions, dispatch),
    tag_actions: bindActionCreators(tagActions, dispatch),
    practice_types_actions: bindActionCreators(practiceTypesActions, dispatch),
    skill_levels_actions: bindActionCreators(skillLevelActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouting(DrillsBuilder))
