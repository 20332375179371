import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import CoachProgramsFilterBar from './CoachProgramsFilterBar'
import CoachProgramsList from './CoachProgramsList'
import { selectFilteredCategory, selectFiltersNumber } from '../../../reducers/groupProgramsReducer'
import NoPrograms from './EmptyProgramsList'
import Loader from '../../common/Op36Loader-web'

const CoachProgramsCompletedTab = ({ fetchPrograms }) => {
  const completed = useSelector(state => state.groupPrograms.completed)
  const filtersNumber = useSelector(state => selectFiltersNumber(state, completed.filters))
  const filteredCategory = useSelector(state => selectFilteredCategory(state, completed.filters?.categoryId))
  const timezone = useSelector(state => state.user.current_user.communities[0].timezone)

  return (
    <>
      <CoachProgramsFilterBar
        filters={completed.filters}
        filteredCategory={filteredCategory}
        filtersNumber={filtersNumber}
        search={completed.search}
        tab={'completed'}
      />
      {
        completed.programs.length > 0 ?
          <CoachProgramsList
            programs={completed.programs}
            timezone={timezone}
            hasMore={completed.hasMore}
            fetchPrograms={() => fetchPrograms(completed, 'completed')}
          />
          :
          completed.hasMore === false ?
            (filtersNumber > 0 || completed.search) ? (
              <NoPrograms
                title='No Programs Found'
                subtitle='There are no programs that match the search you made, clear all filters and try again.'/>
            ) : (
              <NoPrograms
                title='No Completed Programs Available'
                subtitle='Create a Program and make it live'/>
            ) : (
              <Loader message='loading programs' />
            )
      }
    </>
  )
}

CoachProgramsCompletedTab.propTypes = {
  fetchPrograms: PropTypes.func,
}

export default CoachProgramsCompletedTab
