import React, { Component } from "react";
import { Collapse } from "reactstrap";
import _ from "lodash";

// ASSETS
import "./SimpleEducationSection.scss";

// CHILDREN
import SimpleEducationLesson from "./SimpleEducationLesson";

class SimpleEducationSection extends Component {
  state = {
    collapse: false
  };

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  calculateCompletedLessons = () => {
    const { section, userEducation } = this.props;
    let completedLessonCount = 0;

    section.education_lessons.map(lesson => {
      let completedIndex = userEducation.completed_lessons
        .map(completedLesson => parseInt(Object.keys(completedLesson), 10))
        .indexOf(lesson.id);
      if (completedIndex > -1) {
        completedLessonCount += 1;
      }
    });

    return completedLessonCount;
  };

  renderSimpleEducationlessons = () => {
    const {
      section,
      selectLesson,
      selectedLesson,
      isCoach,
      userEducation
    } = this.props;

    if (section.education_lessons && section.education_lessons.length > 0) {
      return section.education_lessons.map((lesson, index) => {
        let completeLessonIndex = !_.isEmpty(userEducation)
          ? userEducation.completed_lessons
              .map(completedLesson =>
                parseInt(Object.keys(completedLesson), 10)
              )
              .indexOf(lesson.id)
          : -1;

        return (
          <SimpleEducationLesson
            lesson={lesson}
            index={index}
            selectLesson={selectLesson}
            selectedLesson={selectedLesson}
            isCoach={isCoach}
            isCompleted={completeLessonIndex > -1}
            key={lesson.id}
          />
        );
      });
    }
    return <p>No lessons to show</p>;
  };

  render() {
    const { section, index, selectedLesson, isCoach } = this.props;
    return (
      <div id="SimpleEducationSection">
        <div className="section-container card">
          <button className="section-button" onClick={this.toggle}>
            <div className="section-info-wrapper col-10 pl-0">
              <p>Section: {index + 1}</p>
              <h5>{section.name}</h5>
            </div>
            {isCoach && (
              <p
                className={`col-2 pr-0 section-completed-count-wrapper ${this.calculateCompletedLessons() ===
                  section.education_lessons.length &&
                  "completed-section animated bounceIn"}`}
              >
                {this.calculateCompletedLessons()} /{" "}
                {section.education_lessons.length}
              </p>
            )}
          </button>
          <Collapse
            isOpen={
              selectedLesson.education_section_id === section.id
                ? true
                : this.state.collapse
            }
          >
            {this.renderSimpleEducationlessons()}
          </Collapse>
        </div>
      </div>
    );
  }
}
export default SimpleEducationSection;
