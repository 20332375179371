/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React, { Component } from "react";
import Toggle from '../../common/Toggle'
import ConfirmationModal2 from '../../common/ConfirmationModal2'
import moment from "moment";
import DatePicker from '../../common/DatePicker'
import PropTypes from 'prop-types'
import UserAvatarForm from './UserAvatarForm'
// import { resetPassword } from "../../../actions/authActions";

// CHILDREN
import TextInput from "../../common/form/TextInput";

// ASSETS
import "./AdminUserForm.scss";

class AdminUserForm extends Component {
  constructor(props) { // eslint-disable-line complexity
    super(props);

    const user = props.user;

    this.state = {
      id: user ? user.id : -1,
      first_name: user ? user.first_name : "",
      last_name: user ? user.last_name : "",
      username: user && user.username ? user.username : "",
      userusername: user && user.username ? user.username : "",
      email: user && user.email ? user.email : "",
      useremail: user && user.email ? user.email : "",
      gender: user ? user.gender : "unselected",
      birthdate: user ? user.birthdate : null,
      family_id: user ? user.family_id : -1,
      is_golfer: user ? user.is_golfer : true,
      family_admin: user ? user.family_admin : false,
      type: user ? user.type : "",
      do_not_track: user ? user.do_not_track : false,
      pipedrive_person_id: user ? user.pipedrive_person_id || '' : '',
      showDeleteConfirmModal: false,
      bio: user?.bio || '',
      booking_url: user?.booking_url || '',
      uploadPhotoFile: '',
      avatarEditState: false,
      uploadPhotoPreview: '',
    };
  }

  componentDidMount() {
    this.chooseType();
  }

  validateForm = () => {
    const {
      first_name,
      last_name,
      username,
      email,
      family_id,
      type,
      birthdate,
    } = this.state;

    if (first_name === "" && last_name === "" && family_id === "") {
      return true;
    }

    if (type === "Golfer" && username === "") {
      return true
    }

    if (type !== "Golfer" && email === "") {
      return true
    }

    if (!birthdate && type !== 'Representative') { return true }

    const currentDate = new Date()
    if (birthdate > currentDate) { return true }

    return false;
  };

  handleChange = (event) => {
    const inputName = event.target.name
    const inputValue = event.target.value

    if (inputName === 'pipedrive_person_id' && inputValue < 0) {
      return
    }

    this.setState({
      [inputName]: inputValue,
    })
  }

  handleGolferChange = (event) => {
    this.setState({
      is_golfer: event.target.checked,
    });
  };

  handleDoNotTrackChange = (event) => {
    this.setState({
      do_not_track: event.target.checked,
    });
  };

  handleAdminChange = (event) => {
    this.setState({
      family_admin: event.target.checked,
    });
  };

  handleTypeChange = (event) => {
    let family_admin = true
    const eventValue = event.target.value

    switch (event.target.value) {
      case "Coach":
        family_admin = true;
        break;
      case "Family Admin":
        family_admin = true;
        break;
      case "Golfer":
        family_admin = false;
        break;
      case 'Representative':
        family_admin = false
        break

      default:
        return null;
    }

    this.setState({
      type: eventValue,
      family_admin,
      email: family_admin ? this.state.useremail : '',
      username: family_admin ? '' : this.state.userusername
    });
  };

  chooseType = () => {
    const { type, family_admin } = this.state;
    let userType = "";

    if (type === "Student" && family_admin) {
      userType = "Family Admin";
    } else if (type === "Student" && !family_admin) {
      userType = "Golfer";
    } else {
      userType = type
    }

    this.setState({
      type: userType,
    });
  };

  renderMessage = () => {
    const { type } = this.state;

    if (type === "Coach") {
      return "This will send a reset password link to this coach";
    } else if (type === "Golfer") {
      return "This will send a reset password link to the family admin account of this golfer";
    }
    return "This will send a reset password link to this user since they are a family admin";
  };

  _handleBirthdateChange = (date) => {
    this.setState({ birthdate: date });
  };

  handleShowDeleteConfirmModal = () => {
    this.setState({ showDeleteConfirmModal: true })
  }

  handleDeleteUser = () => {
    this.setState({ showDeleteConfirmModal: false })
    this.props.deleteUser(this.props.user)
  }

  toggleAvatarEditState = (e) => {
    e.preventDefault()
    this.setState((prevState) => ({
      avatarEditState: !prevState.avatarEditState,
    }))
  }

  handleAvatarChange = (e) => {
    const uploadPhotoFile = e.target.files[0]
    const uploadPhotoPreview = URL.createObjectURL(uploadPhotoFile)
    this.setState({ uploadPhotoFile, uploadPhotoPreview })
  }

  render() {
    const { first_name, last_name, username, email, family_id, type, pipedrive_person_id, showDeleteConfirmModal, bio, booking_url } = this.state
    const {
      closeModal,
      updateUser,
      user,
      updateLoader,
      resetPasswordLoader,
      deleteLoader,
      resetPassword,
      error,
    } = this.props;

    const actionLoading = updateLoader || resetPasswordLoader || deleteLoader

    let { birthdate } = this.state;
    if (birthdate) {
      birthdate = moment(birthdate);
    }
    return (
      <div id='AdminUserForm'>
        <h4>User Details</h4>
        <div className='row'>
          <div className='col-md-6'>
            <label>First Name</label>
            <input
              name='first_name'
              type='text'
              className='mb-3 form-control'
              label='First Name'
              onChange={this.handleChange}
              placeholder='First Name'
              value={first_name}
            />
          </div>
          <div className='col-md-6'>
            <label>Last Name</label>
            <input
              name='last_name'
              type='text'
              className='mb-3 form-control'
              label=''
              onChange={this.handleChange}
              placeholder='Last Name'
              value={last_name}
            />
          </div>
        </div>
        {type !== "Golfer" &&
          <div className='row'>
            <div className='col-md-12'>
              <label>Email</label>
              <TextInput
                name={"email"}
                type={"email"}
                label={""}
                value={email}
                onChange={this.handleChange}
                className='mb-3 form-control'
                placeholder={""}
                error={error.email}
              />
            </div>
          </div>
        }

        {type === "Golfer" &&
          <div className='row'>
            <div className='col-md-12'>
              <label>Username</label>
              <TextInput
                name={"username"}
                type={"username"}
                label={""}
                value={username}
                onChange={this.handleChange}
                className='mb-3 form-control'
                placeholder={""}
                error={error.username}
              />
            </div>
          </div>
        }

        <div className='row'>
          <div className='col-md-6'>
            <label>Type</label>
            <select
              onChange={this.handleTypeChange}
              name='type'
              value={type}
              className='mb-3 form-control'
            >
              <option value={"Coach"}>Coach</option>
              <option value={"Family Admin"}>Family Admin</option>
              <option value={"Golfer"}>Golfer</option>
              <option value={'Representative'}>Representative</option>
            </select>
          </div>
          {type !== 'Representative' &&
            <div className='col-md-6'>
              <label htmlFor='phone'>Family ID*</label>
              <input
                name='family_id'
                type='number'
                className='mb-3 form-control'
                label=''
                onChange={this.handleChange}
                onWheel={(e) => e.target.blur()}
                placeholder='Family Id'
                value={family_id}
              />
            </div>
          }
        </div>
        <div className='row'>
          {type !== 'Representative' &&
            <div className='col-6 d-flex flex-column mb-3'>
              <label htmlFor='birthdateInput'>Birthdate <span className='required-text'>*</span></label>
              <DatePicker
                id='birthdateInput'
                selected={birthdate}
                onChange={this._handleBirthdateChange}
              />
            </div>
          }
          {type === 'Coach' && (
            <div className='col-6 d-flex flex-column mb-3'>
              <label>Pipedrive person ID</label>
              <TextInput
                name='pipedrive_person_id'
                id='pipedrive_person_id'
                type='number'
                value={pipedrive_person_id}
                onChange={this.handleChange}
                onWheel={(e) => e.target.blur()}
                placeholder='Enter person id'
              />
            </div>
          )}
        </div>
        {type === 'Representative' && (
          <>
            <div className='row'>
              <div className='col-6 d-flex flex-column mb-3'>
                <label htmlFor='calendarLink'>Calendar link</label>
                <TextInput
                  name='booking_url'
                  id='calendarLink'
                  type='text'
                  value={booking_url}
                  onChange={this.handleChange}
                  onWheel={(e) => e.target.blur()}
                  placeholder='Enter calendar link' />
              </div>
              <div className='col-6 d-flex flex-column mb-3'>
                <label htmlFor='repBio'>Bio</label>
                <TextInput
                  name='bio'
                  id='repBio'
                  type='text'
                  value={bio}
                  onChange={this.handleChange}
                  onWheel={(e) => e.target.blur()}
                  placeholder='Enter Bio' />
              </div>
            </div>
            <div className='row justify-content-center'>
              <UserAvatarForm
                user={user}
                preview={this.state.uploadPhotoPreview}
                handleAvatarChange={this.handleAvatarChange}
                avatarEditState={this.state.avatarEditState}
                toggleAvatarEditState={this.toggleAvatarEditState}
                isProfile={false}
              />
            </div>
          </>
        )}
        <div className='row'>
          <div className='col-md-6 d-flex flex-column'>
            <label>Are they an active golfer?</label>
            <div className='pl-2'>
              <Toggle
                defaultChecked={user ? user.is_golfer : false}
                onChange={this.handleGolferChange}
              />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6 d-flex flex-column'>
            <label>Do Not Track!</label>
            <div className='pl-2'>
              <Toggle
                defaultChecked={user ? user.do_not_track : false}
                onChange={this.handleDoNotTrackChange}
              />
            </div>
          </div>
        </div>
        <h4 className='mt-4'>Reset Password</h4>
        <div className='reset-password-container'>
          <button
            className='btn btn-dark'
            onClick={() => resetPassword(user)}
            disabled={
              updateLoader || this.validateForm() || resetPasswordLoader
            }
          >
            {resetPasswordLoader && (
              <i className='fa fa-spinner fa-spin light-loading-spinner' />
            )}
            Send{resetPasswordLoader && "ing"} Reset Password
          </button>
          <p>*{this.renderMessage()}</p>
        </div>
        <div className='row mt-5'>
          <div className='col-md-4'>
            <button
              className='btn btn-dark m-2'
              onClick={closeModal}
              disabled={actionLoading}
            >
              Cancel
            </button>
          </div>
          <div className='col-md-4 d-flex justify-content-center align-items-center'>
            <button
              className='btn btn-primary m-2'
              onClick={() => updateUser(this.state)}
              disabled={
                actionLoading || this.validateForm()
              }
            >
              {updateLoader && (
                <i className='fa fa-spinner fa-spin light-loading-spinner' />
              )}
              Updat{updateLoader ? "ing" : "e"} User
            </button>
          </div>
          <div className='col-md-4'>
            <button
              className='float-right btn btn-primary btn-red m-2'
              onClick={this.handleShowDeleteConfirmModal}
              disabled={actionLoading}
            >
              Delet{deleteLoader ? 'ing' : 'e'} User
            </button>
          </div>
        </div>
        {showDeleteConfirmModal && <ConfirmationModal2
          title='Are you sure you want to delete this user?'
          message='The user will be deleted. If they were the only member in their family, the family will also be deleted.'
          showModal={showDeleteConfirmModal}
          denyMessage='Cancel'
          proceedMessage='Delete User'
          type='delete'
          onDeny={() => this.setState({ showDeleteConfirmModal: false })}
          closeModal={() => this.setState({ showDeleteConfirmModal: false })}
          onProceed={this.handleDeleteUser}
        />}
      </div>
    );
  }
}

AdminUserForm.propTypes = {
  closeModal: PropTypes.func,
  formState: PropTypes.object,
  updateUser: PropTypes.func,
  deleteUser: PropTypes.func,
  resetPassword: PropTypes.func,
  loader: PropTypes.bool,
  updateLoader: PropTypes.bool,
  deleteLoader: PropTypes.bool,
  resetPasswordLoader: PropTypes.bool,
  user: PropTypes.object,
  error: PropTypes.object,
}

export default AdminUserForm;
