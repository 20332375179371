import * as types from "../actions/actionTypes";

export default function likes(state = [], action) {
  switch (action.type) {
    case types.ADD_ACTIVITY_LIKES:
      return action.likes;

    default:
      return state;
  }
}
