import React, { Component } from "react";
import ReactTable from "react-table";
import createTable from "react-table-hoc-fixed-columns";
import DatePicker from '../common/DatePicker'
import moment from "moment";
import "react-table/react-table.css";

import CSVExportModal from "./CSVExportModal";
import profile_avatar from "../../assets/images/mock/StockProfilePic.png";

const ReactTableFixedColumns = createTable(ReactTable);

const HEADERS = [
  { label: "First Name", key: "firstname" },
  { label: "Last Name", key: "lastname" },
  { label: "Date", key: "date" },
  { label: 'Level', key: 'division' },
  { label: "Yardage", key: "yardage" },
  { label: "Type", key: "type" },
  { label: "Holes", key: "holes" },
  { label: "Score", key: "score" },
  { label: "Greens", key: "greens" },
  { label: "Putts", key: "putts" }
];

class CommunityRoundsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      dataLoader: false,
      data: []
    };
  }
  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  createData = () => {
    const { data } = this.props;
    let rounds = data[0].rounds;
    let tempData = [];
    this.setState({ dataLoader: true });
    if (rounds.length > 0) {
      rounds.map(round => {
        let firstname = round.user.first_name;
        let lastname = round.user.last_name;
        let date = round.activity_date;
        let yardage = round.yardage;
        let type = round.play_type.name;
        let holes = round.holes;
        let score = round.score;
        let greens = round.greens;
        let putts = round.putts;

        tempData.push({
          firstname,
          lastname,
          date: moment(date).format("MM-DD-YYYY"),
          division: this.renderDivision(yardage),
          yardage,
          type,
          holes,
          score,
          greens,
          putts
        });
      });
    }

    this.setState({
      showModal: !this.state.showModal,
      data: tempData,
    });
  };
  renderUserAvatarCell = props => {
    return (
      <div
        className='number'
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer"
        }}
        key={props.value ? props.value.id : null}
        onClick={() =>
          this.props.toProfile(props.value ? props.value.id : null)
        }
      >
        <div className='small-round-image-wrapper'>
          {props.value ? (
            <img
              className='round-image'
              src={
                props.value.avatar
                  ? props.value.avatar.file_url
                  : profile_avatar
              }
              alt={
                props.value
                  ? `${props.value.first_name} ${props.value.last_name}`
                  : ""
              }
            />
          ) : null}
        </div>
      </div>
    );
  };
  renderDivision = yardage => {
    switch (yardage) {
      case 225:
        return 'Level 1'
      case 450:
        return 'Level 2'
      case 900:
        return 'Level 3'
      case 1350:
        return 'Level 4'
      case 1800:
        return 'Level 5'
      default:
        if (yardage >= 1500 && yardage !== 1800) {
          return "Full Tee";
        } else if (yardage < 225) {
          return 'Level 1'
        } else {
          return "";
        }
    }
  };
  renderDivisionCell = props => {
    return (
      <div
        className='number'
        style={{
          backgroundColor: "",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div style={{ color: "#2b3843" }}>
          {this.renderDivision(props.value)}
        </div>
      </div>
    );
  };
  renderTableCell = props => {
    return (
      <div
        className='number'
        style={{
          backgroundColor: "",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div style={{ color: "#2b3843" }}>{props.value}</div>
      </div>
    );
  };

  renderActivityDateCell = props => {
    return (
      <div
        className='number'
        style={{
          backgroundColor: "",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div style={{ color: "#2b3843" }}>
          {moment(props.value).format("MM-DD-YYYY")}
        </div>
      </div>
    );
  };
  renderTable = () => {
    const {
      title,
      startDate,
      endDate,
      handleStartDateChanged,
      handleEndDateChanged
    } = this.props;
    const columns = [
      {
        Header: "",
        accessor: "user",
        Cell: props => this.renderUserAvatarCell(props),
        sortable: false,
        fixed: true
      },
      {
        Header: "First Name",
        accessor: "user.first_name",
        fixed: true
      },
      {
        Header: "Last Name",
        accessor: "user.last_name",
        fixed: true
      },
      {
        Header: "Date",
        accessor: "activity_date",
        Cell: props => this.renderActivityDateCell(props)
      },
      {
        Header: 'Level',
        accessor: "yardage",
        Cell: props => this.renderDivisionCell(props)
      },
      {
        Header: "Yardage",
        accessor: "yardage",
        Cell: props => this.renderTableCell(props)
      },
      {
        Header: "Type",
        accessor: "play_type.name",
        Cell: props => this.renderTableCell(props)
      },
      {
        Header: "Holes",
        accessor: "holes",
        Cell: props => this.renderTableCell(props),
        style: { padding: 0 }
      },
      {
        Header: "Score",
        accessor: "score",
        Cell: props => this.renderTableCell(props),
        style: { padding: 0 }
      },
      {
        Header: "Greens",
        accessor: "greens",
        Cell: props => this.renderTableCell(props),
        style: { padding: 0 }
      },
      {
        Header: "Putts",
        accessor: "putts",
        Cell: props => this.renderTableCell(props),
        style: { padding: 0 }
      }
    ];

    return (
      <div className='container my-5 animated fadeIn'>
        <div className='row mb-5 d-flex align-items-center'>
          <div className='col-md-4'>
            <h2>{title}</h2>
          </div>
          <div className='col-md-4 d-flex align-items-center'>
            <div>
              <p className='m-0'>Start Date</p>
              <DatePicker
                name={"start-date"}
                id={"start-date"}
                startDate={startDate}
                endDate={endDate}
                type={"date"}
                className={"col-12 form-control"}
                onChange={handleStartDateChanged}
                placeholder={"Start Date"}
                selected={startDate}
                error={""}
              />
            </div>

            <div className='ml-2'>
              <p className='m-0'>End Date</p>
              <DatePicker
                name={"end-date"}
                id={"end-date"}
                startDate={startDate}
                endDate={endDate}
                type={"date"}
                className={"col-12 form-control"}
                onChange={handleEndDateChanged}
                placeholder={"End Date"}
                selected={endDate}
                error={""}
              />
            </div>
          </div>
          <div className='col-md-4'>
            <button
              className='btn btn-sm btn-block'
              onClick={() => this.props.goBack()}
            >
              <i
                className='fa fa-arrow-left text-white mr-1'
                aria-hidden='true'
              />{" "}
              View Another Report
            </button>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <ReactTableFixedColumns
              data={
                this.props.data[0] && !this.props.loader
                  ? this.props.data[0].rounds
                  : []
              }
              columns={columns}
              defaultPageSize={10}
              loading={this.props.loader}
              loadingText='Loading Report'
              noDataText={
                this.props.data[0]
                  ? "Choose a start date and end date with activities"
                  : "No data found"
              }
            />
          </div>
        </div>
        <button onClick={this.createData} className='mt-3 btn btn-success'>
          Export as CSV
        </button>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderTable()}
        <CSVExportModal
          headers={HEADERS}
          data={this.state.data}
          showModal={this.state.showModal}
          toggleModal={this.toggleModal}
          reportType={"Community Rounds Report"}
          filename={"community-rounds-report.csv"}
        />
      </div>
    );
  }
}

export default CommunityRoundsTable;
