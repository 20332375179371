import React from "react";
import DatePicker from "../../common/DatePicker"
import SimplePhotoUploader from "../../common/SimplePhotoUploader";
import PropTypes from 'prop-types'

import "./ChallengeForm.scss";

const ChallengeForm = ({
  name,
  points,
  startDate,
  endDate,
  video_url,
  description,
  handleInputChange,
  handleStartChange,
  handleEndChange,
  grabPhotoData,
  grabIconData,
  selectedPhoto,
  selectedIcon,
  failedValidation,
  submitClicked,
  handleSubmitClick,
  links,
  addNewLink,
  removeLink,
  handleDisplayName,
  handleLinkUrl,
  isEdit,
}) => { // eslint-disable-line arrow-body-style
  return (
    <div id='ChallengeForm'>
      <div className='challenge-inner-form'>
        <div className='challenge-form-top-container'>
          <div
            className={`challenge-photo-area ${failedValidation.includes(
              "photo"
            ) ? "failed-validation" : ''}`}
          >
            <p className='ml-2'>
              Header Photo<span className='required-field'>*</span>
              {isEdit && (
                <span className='subtext'>
                  (don't change if you're happy with the current picture)
                </span>
              )}
            </p>
            <SimplePhotoUploader
              sendPhotoData={grabPhotoData}
              selectedPhoto={selectedPhoto}
            />
          </div>
          <div className='challenge-form-name-area'>
            <p>
              Name<span className='required-field'>*</span>
            </p>
            <input
              type='text'
              value={name}
              name='name'
              onChange={handleInputChange}
              className={
                failedValidation.includes("name") ? "failed-validation" : ''
              }
            />
            <p>
              Points<span className='required-field'>*</span>
            </p>
            <input
              type='number'
              value={points}
              name='points'
              onChange={handleInputChange}
              onWheel={(e) => e.target.blur()}
              className={
                failedValidation.includes("points") ? "failed-validation" : ''
              }
            />
            <div className='date-box-container'>
              <div className='date-box'>
                <p>Start Date</p>
                <DatePicker selected={startDate} onChange={handleStartChange} />
              </div>
              <div className='date-box'>
                <p>End Date</p>
                <DatePicker selected={endDate} onChange={handleEndChange} />
              </div>
            </div>
          </div>
        </div>
        <div className='challenge-form-bottom-container'>
          <div
            className={`challenge-form-icon-loader-container ${failedValidation.includes(
              "icon"
            ) ? "failed-validation" : ''}`}
          >
            <p className='ml-2'>
              Icon Image<span className='required-field'>*</span>
            </p>
            <SimplePhotoUploader
              sendPhotoData={grabIconData}
              selectedPhoto={selectedIcon}
              second={true}
            />
          </div>
          <div className='challenge-form-detail-area'>
            <p className='mt-2'>Video URL</p>
            <input
              type='text'
              value={video_url}
              name='video_url'
              onChange={handleInputChange}
            />
            <p className='mt-2'>
              Description<span className='required-field'>*</span>
            </p>
            <textarea
              value={description}
              name='description'
              onChange={handleInputChange}
              className={
                failedValidation.includes("description") ? "failed-validation" : ''
              }
            />
          </div>
        </div>
        {failedValidation.length > 0 && (
          <div className='failed-validation-box'>
            <p>{`Oops! Nothing personal, but it looks like you forgot ${failedValidation.join(
              ", "
            )}. Fill these out and try again.`}</p>
          </div>
        )}
        <div className='links-container'>
          {links.length > 0
            ? links.map((link, i) => {
                return (
                  <div key={i} className='single-link-card'>
                    <div
                      className='remove-link-card'
                      onClick={() => removeLink(i)}
                    >
                      <i className='fa fa-trash' aria-hidden='true' />
                    </div>
                    <p>Display Name</p>
                    <input
                      type='text'
                      value={link.display_name}
                      name='link-display-name'
                      onChange={e => handleDisplayName(e, i)}
                    />
                    <p>Link URL</p>
                    <input
                      type='text'
                      value={link.link_url}
                      name='link-display-name'
                      onChange={e => handleLinkUrl(e, i)}
                    />
                  </div>
                );
              })
            : null}
          <p className='add-link-button text-center' onClick={addNewLink}>
            + Add link
          </p>
        </div>
        <div className='challenge-form-submit-container'>
          <button
            className='challenge-submit-button'
            onClick={handleSubmitClick}
            disabled={submitClicked}
          >
            {submitClicked ? (
              <i className='fa fa-spinner fa-spin' aria-hidden='true' />
            ) : null}
            {`${isEdit ? "Edit" : "Add"}${
              submitClicked ? "ing" : ""
            } Challenge`}
          </button>
        </div>
      </div>
    </div>
  );
};

ChallengeForm.propTypes = {
  isEdit: PropTypes.bool,
}

export default ChallengeForm;
