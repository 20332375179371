import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './CancelButton.module.scss'

const CancelButton = ({ type, onClick, buttonMessage, className }) => (
  <button
    type={type || 'button'}
    className={classNames('btn w-100', styles['cancel-button'], className)}
    onClick={onClick}
  >
    {buttonMessage}
  </button>
)
CancelButton.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  noTopMargin: PropTypes.bool,
  buttonMessage: PropTypes.string.isRequired,
}

export default CancelButton
