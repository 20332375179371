import PropTypes from 'prop-types'
import _ from 'lodash'

import './ProgressBarSteps.scss'
import { ReactComponent as CheckCircleWhite } from '../../../assets/images/common/icons/check-circle-white.svg'

const ProgressBarSteps = ({ stepsNumber, stepNames, currentStep, currentStepCompleted = false, className }) => {
  const width = currentStep / (stepsNumber - 1) * 100

  return (
    <div id='ProgressBarSteps' className='w-100'>
      <div className='position-relative'>
        <div className='progress w-100'>
          <div
            className={`progress-bar h-100 ${className}`}
            style={{ width: `${width}%` }}
            role='progressbar' aria-valuenow={currentStep}
            aria-valuemin='0' aria-valuemax={stepsNumber - 1}
          />
        </div>
        <div className='progress-steps d-flex justify-content-between'>
          {_.times(stepsNumber, (i) => (
            <div key={i} className='progress-step'>
              {(i < currentStep || i === currentStep && currentStepCompleted) && (
                <CheckCircleWhite style={{ height: '100%', width: '100%' }}/>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className='d-flex justify-content-between mt-3'>
        {stepNames.map(stepName => (
          <div key={stepName}>{stepName}</div>
        ))}
      </div>
    </div>
  )
}

export default ProgressBarSteps

ProgressBarSteps.propTypes = {
  stepsNumber: PropTypes.number.isRequired,
  stepNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentStep: PropTypes.number.isRequired,
  currentStepCompleted: PropTypes.bool,
  className: PropTypes.string,
}
