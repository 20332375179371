import React from 'react';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as userActions from '../../actions/userActions';
import * as commonActions from '../../actions/commonActions';

import '../../assets/styles/components/activity/ActivityFeedPage.scss';
import Activity from '../activity/Activity';

class ProfileActivities extends React.Component{
  constructor(props, context){
    super(props, context);

    this.showPostActivityMenu = this.showPostActivityMenu.bind(this);
  }

  showPostActivityMenu(e) {
    e.preventDefault();
    this.props.common_actions.showActivitySocialMenu(true);
  }

  displayActivities(){
    if(this.props.allActivities && this.props.allActivities.length > 0){
      return(
        this.props.allActivities.map(activity =>
          <Activity
            key={activity.id}
            displayComments={false}
            activity={activity}
          />
        )
      );
    } else {
      var postActivity = (this.props.current_user.id === this.props.user.id) ? (<p className="text-center"><a href="#" onClick={this.showPostActivityMenu} className="btn btn-sm btn-primary"> Post An Activity</a></p>) : (<span></span>);
      var noActivitiesText = (this.props.current_user.id === this.props.user.id) ? ("You have no activities") : (this.props.user.first_name + " has no activities");

      return (
        <div>
          <div className="sky">
            <div className="empty-putt py-2">
              <h4 className="text-center">{noActivitiesText}</h4>
              {postActivity}
            </div>
          </div>
        </div>
      );
    }
  }

  render(){
    return(
      <div id="profileActivities" ref='profileActivities' style={{overflow: 'auto'}} onScroll={this.handleScroll}>
        {this.displayActivities()}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps){
  return {
    ui: state.ui,
    user: state.user.user,
    current_user: state.user.current_user,
  };
}

function mapDispatchToProps(dispatch){
  return{
    actions: bindActionCreators(userActions, dispatch),
    common_actions: bindActionCreators(commonActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileActivities);
