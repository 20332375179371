import PropTypes from 'prop-types'
import classnames from 'classnames'
import { stockAccolades } from '../../../accolades/accolades'

import classes from '../CommunityDetails.module.scss'
import { ReactComponent as FacebookLogo } from '../../../assets/images/common/icons/social-media/facebook.svg'
import { ReactComponent as TwitterLogo } from '../../../assets/images/common/icons/social-media/twitter.svg'
import { ReactComponent as InstaLogo } from '../../../assets/images/common/icons/social-media/instagram.svg'
import ButtonLink from '../../common/buttons/ButtonLink'

const CoachCollapsibleInfo = ({ coach }) => {
  const accolades = coach.accolades.filter(accolade => accolade.name in stockAccolades).sort()
  const hasFacebook = !!coach.socialMedia.facebook
  const hasTwitter = !!coach.socialMedia.twitter
  const hasInsta = !!coach.socialMedia.insta
  const hasSocialMedia = hasFacebook || hasTwitter || hasInsta

  return (
    <div className={classnames('d-flex flex-column py-3 px-4', classes.coachDetails)}>
      {!!coach.headline && (
        <p>{coach.headline}</p>
      )}
      <h5 className={classes.detailsTitle}>{coach.firstName} {coach.lastName} Bio</h5>
      <p className={classes.coachBio}>{coach.bio ? coach.bio : '- coming soon -'}</p>
      {hasSocialMedia && (
        <ul className={classnames('d-flex p-0 mb-3', classes.socialMediaList)}>
          {hasFacebook && (
            <li className={classes.socialMedia}><a
              rel='noreferrer'
              target='_blank'
              href={coach.socialMedia.facebook}
            >
              <FacebookLogo />
            </a></li>
          )}
          {hasTwitter && (
            <li className={classes.socialMedia}><a
              rel='noreferrer'
              target='_blank'
              href={coach.socialMedia.twitter}
            >
              <TwitterLogo />
            </a></li>
          )}
          {hasInsta && (
            <li className={classes.socialMedia}><a
              rel='noreferrer'
              target='_blank'
              href={coach.socialMedia.insta}
            >
              <InstaLogo />
            </a></li>
          )}
        </ul>
      )}
      {!!coach.bookingUrl && coach.bookingUrl !== 'null' && (
        <ButtonLink
          isExternal
          navigateTo={coach.bookingUrl}
          target='_blank'
          noTopMargin
          className='mb-3'
        >
          Book a Lesson
        </ButtonLink>
      )}
      {accolades.length > 0 && (
        <>
          <h5 className={classes.detailsTitle}>Accolades</h5>
          <div className='d-flex flex-wrap'>
            {accolades.map((accolade, idx) => (
              <img
                key={idx}
                src={stockAccolades[accolade.name].badge}
                alt={accolade.name}
                className={classes.coachAccolade}
              />
            ))}
          </div>
        </>
      )}
    </div>
  )
}

CoachCollapsibleInfo.propTypes = {
  coach: PropTypes.shape({
    accolades: PropTypes.array,
    headline: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    bio: PropTypes.string,
    socialMedia: PropTypes.shape({
      facebook: PropTypes.string,
      twitter: PropTypes.string,
      insta: PropTypes.string,
    }),
    bookingUrl: PropTypes.string,
  }),
}

export default CoachCollapsibleInfo
