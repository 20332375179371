import React from "react";
import PropTypes from 'prop-types'
// import {connect} from 'react-redux';
// import {bindActionCreators} from 'redux';
// import * as userActions from '../../../actions/userActions';
// import * as rankActions from '../../../actions/rankActions';
// import * as practiceTypeActions from '../../../actions/practiceTypeActions';

import Placeholder from "../../common/Placeholder";
import "../../../assets/styles/components/profile/ProfileStats.scss";
import "../ProfilePage.scss";
import CountUp from "react-countup";
import Loader from "../../common/Op36Loader-web";

class ProfileStats extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      divisions: [225, 450, 900, 1350, 1800],
      division: "",
      data: [],
      labels: [],
      stokesLost: 0
    };
  }

  // componentDidMount(){
  //   this.fetchProfileStatsWithProps(this.props);
  //   console.log(this.props);
  // }
  //
  // componentWillReceiveProps(nextProps) {
  //   if(this.props.user && this.props.user.id !== nextProps.user.id) {
  //     this.fetchProfileStatsWithProps(nextProps);
  //   }
  // }
  //
  // fetchProfileStatsWithProps(props) {
  //   props.actions.getUserStats(props.user.id);
  //   props.actions.getUserActivities(props.user.id);
  //   props.actions.getUserPlayActivities(props.user.id);
  //   props.actions.getUserTrainActivities(props.user.id);
  //   props.actions.getUserObjectiveActivities(props.user.id);
  // }

  abbrNum(number, decPlaces) {
    decPlaces = Math.pow(10, decPlaces);

    var abbrev = ["k", "m", "b", "t"];

    for (var i = abbrev.length - 1; i >= 0; i--) {
      var size = Math.pow(10, (i + 1) * 3);

      if (size <= number) {
        number = Math.round((number * decPlaces) / size) / decPlaces;

        if (number == 1000 && i < abbrev.length - 1) {
          number = 1;
          i++;
        }

        number += abbrev[i];
        break;
      }
    }

    return number;
  }

  trackMultipleActivites(activities, secondaryActivities) {
    if (activities.length > 0 || secondaryActivities.length > 0) {
      let today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth() + 1;
      let yyyy = today.getFullYear();

      if (dd < 10) {
        dd = "0" + dd;
      }

      if (mm < 10) {
        mm = "0" + mm;
      }

      today = mm + "-" + dd + "-" + yyyy;

      let firstTimeDiff = -1;
      let secondTimeDiff = -1;

      if (activities.length > 0) {
        let firstDate1 = new Date(activities[0].created_at);
        let firstDate2 = new Date();
        firstTimeDiff = Math.abs(firstDate2.getTime() - firstDate1.getTime());
      }

      if (secondaryActivities.length > 0) {
        let secondDate1 = new Date(secondaryActivities[0].created_at);
        let secondDate2 = new Date();
        secondTimeDiff = Math.abs(
          secondDate2.getTime() - secondDate1.getTime()
        );
      }

      let firstDiffDays = Math.ceil(firstTimeDiff / (1000 * 3600 * 24));
      let secondDiffDays = Math.ceil(secondTimeDiff / (1000 * 3600 * 24));

      if (activities.length > 0 && secondaryActivities.length > 0) {
        let minDiffDays = Math.min(firstDiffDays, secondDiffDays);

        return minDiffDays;
      } else if (activities.length > 0 && secondaryActivities.length === 0) {
        return firstDiffDays;
      } else if (activities.length === 0 && secondaryActivities.length > 0) {
        return secondDiffDays;
      }
    } else if (activities.length === 0 && secondaryActivities.length === 0) {
      return 0;
    }
  }

  trackLastActivity(activities) {
    if (activities.length > 0) {
      let today = new Date();
      let dd = today.getDate();

      let mm = today.getMonth() + 1;
      let yyyy = today.getFullYear();

      if (dd < 10) {
        dd = "0" + dd;
      }

      if (mm < 10) {
        mm = "0" + mm;
      }

      today = mm + "-" + dd + "-" + yyyy;

      let date1 = new Date(activities[0].created_at);
      let date2 = new Date();
      let timeDiff = Math.abs(date2.getTime() - date1.getTime());

      let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      if (activities.length > 0 && diffDays > 0 && diffDays <= 30) {
        return diffDays;
      } else if (activities.length > 0 && diffDays >= 31 && diffDays <= 60) {
        return diffDays;
      } else if (activities.length > 0 && diffDays > 60) {
        return diffDays;
      } else if (activities.length > 0 && diffDays === 0) {
        return 0;
      }
    } else if (activities.length === 0) {
      return 0;
    }
  }

  renderActionLightForActivities(activities, secondaryActivities) {
    if (activities.length > 0 || secondaryActivities.length > 0) {
      let today = new Date();
      let dd = today.getDate();

      let mm = today.getMonth() + 1;
      let yyyy = today.getFullYear();

      if (dd < 10) {
        dd = "0" + dd;
      }

      if (mm < 10) {
        mm = "0" + mm;
      }

      today = mm + "-" + dd + "-" + yyyy;

      let firstTimeDiff = -1;
      let secondTimeDiff = -1;

      if (activities.length > 0) {
        let firstDate1 = new Date(activities[0].created_at);
        let firstDate2 = new Date();
        firstTimeDiff = Math.abs(firstDate2.getTime() - firstDate1.getTime());
      }

      if (secondaryActivities.length > 0) {
        let secondDate1 = new Date(secondaryActivities[0].created_at);
        let secondDate2 = new Date();
        secondTimeDiff = Math.abs(
          secondDate2.getTime() - secondDate1.getTime()
        );
      }

      let firstDiffDays = Math.ceil(firstTimeDiff / (1000 * 3600 * 24));
      let secondDiffDays = Math.ceil(secondTimeDiff / (1000 * 3600 * 24));

      if (activities.length > 0 && secondaryActivities.length > 0) {
        let minDiffDays = Math.min(firstDiffDays, secondDiffDays);

        if (minDiffDays >= 0 && minDiffDays <= 30) {
          return "grn-light";
        } else if (minDiffDays >= 31 && minDiffDays <= 60) {
          return "ylw-light";
        } else if (minDiffDays > 60) {
          return "red-light";
        }
      } else if (activities.length > 0 && secondaryActivities.length === 0) {
        if (firstDiffDays >= 0 && firstDiffDays <= 30) {
          return "grn-light";
        } else if (firstDiffDays >= 31 && firstDiffDays <= 60) {
          return "ylw-light";
        } else if (firstDiffDays > 60) {
          return "red-light";
        }
      } else if (activities.length === 0 && secondaryActivities.length > 0) {
        if (secondDiffDays >= 0 && secondDiffDays <= 30) {
          return "grn-light";
        } else if (secondDiffDays >= 31 && secondDiffDays <= 60) {
          return "ylw-light";
        } else if (secondDiffDays > 60) {
          return "red-light";
        }
      }
    } else if (activities.length === 0 && secondaryActivities.length === 0) {
      return "blk-light";
    }
  }

  renderActionLight(activities) {
    if (activities.length > 0) {
      let today = new Date();
      let dd = today.getDate();

      let mm = today.getMonth() + 1;
      let yyyy = today.getFullYear();

      if (dd < 10) {
        dd = "0" + dd;
      }

      if (mm < 10) {
        mm = "0" + mm;
      }

      today = mm + "-" + dd + "-" + yyyy;

      let date1 = new Date(activities[0].created_at);
      let date2 = new Date();
      let timeDiff = Math.abs(date2.getTime() - date1.getTime());
      let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

      if (diffDays >= 0 && diffDays <= 30) {
        return "grn-light";
      } else if (diffDays >= 31 && diffDays <= 60) {
        return "ylw-light";
      } else if (diffDays > 60) {
        return "red-light";
      }
    } else if (activities.length === 0) {
      return "blk-light";
    }
  }

  /**********
  CALCULATIONS
  **********/

  displayStats() { // eslint-disable-line max-lines-per-function
    // if (this.props.loading || !this.props.stats || !this.props.playActivites || !this.props.trainActivities || !this.props.objectiveActivities) {
    //   return (
    //     <div className="card mb-2 loading-card">
    //       <h5 className="p-2 text-white text-center primary-background">Overview</h5>
    //       <div className="card-block p-2">
    //         <div className="row">
    //           <div className="col-12">
    //             <div className="loading-content stats-loading-content ">
    //               <Loader message='loading golfer stats' />
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   )
    // }

    if (this.props.statsLoader) {
      return (
        <div className="card mb-2 loading-card">
          <h5 className="p-2 ml-3 mt-2 card-header-text">Overview</h5>
          <div className="card-block p-2">
            <div className="row py-2">
              <div className="col-12">
                <div className="loading-content stats-loading-content ">
                  <Loader message="loading golfer stats" />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (
      this.props.stats &&
      this.props.stats.points &&
      !this.props.loading &&
      this.props.playActivities &&
      this.props.trainActivities &&
      this.props.objectiveActivities
    ) {
      return (
        <div>
          <div className="card mb-2 p-0">
            <h5 className="p-2 ml-3 mt-2 card-header-text">Overview</h5>
            <div className="p-1">
              <div className="statGroup">
                <div className="row m-0">
                  <div className="col-4 stats-container text-center">
                    <p className="mt-3 text-center md-stat-title">
                      <span className="hidden-md-down">Total</span> Points
                    </p>
                    <div className="">
                      <div className="count-container-no-icon">
                        {/*<img className="stats-icon" alt="objective-icon" src={minusParenthesisIcon} />*/}
                        <div className="md-percentage-count">
                          {this.abbrNum(this.props.stats.points, 1)}
                        </div>
                      </div>
                      <div className="lg-empty-circle" />
                    </div>
                  </div>
                  <div className="col-4 stats-container text-center">
                    <p className="mt-3 text-center md-stat-title">
                      <span className="hidden-md-down">Strokes</span> Lost
                    </p>
                    <div className="">
                      <div className="count-container-no-icon">
                        {/*<img className="stats-icon" alt="objective-icon" src={minusParenthesisIcon} />*/}
                        <CountUp
                          className="md-percentage-count"
                          start={0}
                          end={this.props.stats.total_strokes_lost}
                          duration={2.75}
                          useEasing={true}
                          useGrouping={true}
                        />
                      </div>
                      <div className="lg-empty-circle" />
                    </div>
                  </div>
                  <div className="col-4 stats-container text-center">
                    <p className="mt-3 text-center stat-title">
                      <span className="hidden-md-down">Last</span> Action{" "}
                      <small>(days)</small>
                    </p>
                    <div className="">
                      {this.props.allActivities ? (
                        <div className="count-container-no-icon">
                          {/*<img className="stats-icon" alt="objective-icon" src={plusIcon} />*/}
                          <CountUp
                            className="md-percentage-count"
                            start={0}
                            end={this.abbrNum(
                              this.trackLastActivity(this.props.allActivities),
                              1
                            )}
                            duration={2.75}
                            useEasing={true}
                            useGrouping={true}
                          />
                        </div>
                      ) : (
                        <div className="count-container-no-icon">
                          <p className="md-percentage-count">n/a</p>
                        </div>
                      )}
                      <div className="lg-empty-circle" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card mb-2 p-0 pb-4">
            <h5 className="p-2 ml-3 mt-2 card-header-text">Engagement</h5>
            <div className="p-1">
              <div className="statGroup">
                <div className="row m-0">
                  <div className="col-4 stats-container text-center">
                    <p className="mt-3 text-center stat-title">
                      <span className="hidden-md-down">Last</span> Play{" "}
                      <small>(days)</small>
                    </p>
                    <div className="">
                      {this.props.playActivities.length > 0 ? (
                        <div className="count-container-no-icon">
                          {/*<img className="stats-icon" alt="objective-icon" src={plusIcon} />*/}
                          <CountUp
                            className="md-percentage-count"
                            start={0}
                            end={this.abbrNum(
                              this.trackLastActivity(this.props.playActivities),
                              1
                            )}
                            duration={2.75}
                            useEasing={true}
                            useGrouping={true}
                          />
                          <div
                            className={`engagement-light ${this.renderActionLight(
                              this.props.playActivities
                            )}`}
                          />
                        </div>
                      ) : (
                        <div className="count-container-no-icon">
                          <p className="md-percentage-count">n/a</p>
                        </div>
                      )}
                      <div className="lg-empty-circle" />
                    </div>
                  </div>
                  <div className="col-4 stats-container text-center">
                    <p className="mt-3 text-center stat-title">
                      <span className="hidden-md-down">Last</span> Practice{" "}
                      <small>(days)</small>
                    </p>
                    <div className="">
                      {this.props.trainActivities.length > 0 ? (
                        <div className="count-container-no-icon">
                          {/*<img className="stats-icon" alt="objective-icon" src={plusIcon} />*/}
                          <CountUp
                            className="md-percentage-count"
                            start={0}
                            end={this.abbrNum(
                              this.trackLastActivity(
                                this.props.trainActivities
                              ),
                              1
                            )}
                            duration={2.75}
                            useEasing={true}
                            useGrouping={true}
                          />
                          <div
                            className={`engagement-light ${this.renderActionLight(
                              this.props.trainActivities
                            )}`}
                          />
                        </div>
                      ) : (
                        <div className="count-container-no-icon">
                          <p className="md-percentage-count">n/a</p>
                        </div>
                      )}
                      <div className="lg-empty-circle" />
                    </div>
                  </div>
                  <div className="col-4 stats-container text-center">
                    <p className="mt-3 text-center stat-title">
                      <span className="hidden-md-down">Last</span> Goal Earned{" "}
                      <small>(days)</small>
                    </p>
                    <div className="">
                      {(this.props.objectiveActivities &&
                        this.props.objectiveActivities.length > 0) ||
                      (this.props.goalActivities &&
                        this.props.goalActivities.length > 0) ? (
                        <div className="count-container-no-icon">
                          {/*<img className="stats-icon" alt="objective-icon" src={plusIcon} />*/}
                          <CountUp
                            className="md-percentage-count"
                            start={0}
                            end={this.abbrNum(
                              this.trackMultipleActivites(
                                this.props.objectiveActivities,
                                this.props.goalActivities
                              ),
                              1
                            )}
                            duration={2.75}
                            useEasing={true}
                            useGrouping={true}
                          />
                          <div
                            className={`engagement-light ${this.renderActionLightForActivities(
                              this.props.objectiveActivities,
                              this.props.goalActivities
                            )}`}
                          />
                        </div>
                      ) : (
                        <div className="count-container-no-icon">
                          <p className="md-percentage-count">n/a</p>
                        </div>
                      )}
                      <div className="lg-empty-circle" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (
      this.props.user.points === 0 ||
      this.props.user.points === undefined
    ) {
      return (
        <div className="card p-0">
          <h5 className="p-2 ml-3 mt-2 card-header-text">Overview</h5>
          <div className="row mt-3 mr-0">
            <div className="col-md-12 pr-1 pr-md-0">
              <div className="card-block p-2">
                <Placeholder
                  title={"No Stats Yet..."}
                  icon={"fa-bar-chart"}
                  subtext1={
                    "Download the Op36 Golf App to begin logging your first actions"
                  }
                  subtext2={"Not sure how to get started on your journey?"}
                  subtext3={"Reach out to your coach to get started"}
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="card mb-2 loading-card">
          <h5 className="p-2 ml-3 mt-2 card-header-text">Overview</h5>
          <div className="card-block p-2">
            <div className="row py-2">
              <div className="col-12">
                <div className="loading-content stats-loading-content ">
                  <Loader message="loading golfer stats" />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <div id="profileStats" className="hasTabBar p-0">
        {this.displayStats()}
      </div>
    );
  }
}

ProfileStats.propTypes = {
  stats: PropTypes.shape({
    total_strokes_lost: PropTypes.number,
  }),
}

// function mapStateToProps(state, ownProps){
//   return {
//     ui: state.ui,
//     stats: state.user.user_stats,
//     ranks: state.ranks,
//     practice_types: state.practiceTypes,
//     actvities: state.user.user_activities,
//     play_activities: state.user.user_play_activities,
//     train_activities: state.user.user_train_activities,
//     objective_activities: state.user.user_objective_activities
//   };
// }
//
// function mapDispatchToProps(dispatch){
//   return{
//     actions: bindActionCreators(userActions, dispatch),
//     rank_actions: bindActionCreators(rankActions, dispatch),
//     practice_type_actions: bindActionCreators(practiceTypeActions, dispatch)
//   };
// }
//
// export default connect(mapStateToProps, mapDispatchToProps)(ProfileStats);

export default ProfileStats;
