import React from 'react'
import './ProgramRow.scss'
import PropTypes from 'prop-types'

import lp_icon from '../../../assets/images/common/icons/lesson_plans/lesson-plan-icon.png'

const ProgramRow = ({ program, viewProgram, user }) => {
  const renderIcon = () => {
    if (program.lesson_plan && program.lesson_plan.photo) {
      return (
        <div
          className='program-photo-wrapper'
          style={{
            backgroundImage: `url(${program.lesson_plan.photo.file_url})`,
          }}
        ></div>
      )
    } else if (program.lesson_plan && program.lesson_plan.template_photo_url) {
      return (
        <div
          className='program-photo-wrapper'
          style={{
            backgroundImage: `url(${program.lesson_plan.template_photo_url})`,
          }}
        ></div>
      )
    } else if (program.lesson_plan) {
      return (
        <div className='program-photo-wrapper lp-icon-wrapper'>
          <div
            className='lp-icon'
            style={{ backgroundImage: `url(${lp_icon})` }}
          />
        </div>
      )
    } else {
      return (
        <div className='program-photo-wrapper program-no-photo'>
          <i className='fa fa-flag' />
        </div>
      )
    }
  }

  return (
    <div
      id='ProgramRow'
      onClick={() => viewProgram(program)}
      role='button'
      tabIndex={0}
      onKeyDown={() => viewProgram(program)}
    >
      {renderIcon()}
      <div className='program-info-wrapper'>
        <h6>{program.name}</h6>
        <div className='program-organization-wrapper'>
          <i
            className='fa fa-group'
          />
          {user.type === 'Admin' ? (
            <p>Admin Template</p>
          ) : (
            <p>
              {user.communities[0].name}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

ProgramRow.propTypes = {
  program: PropTypes.shape({
    name: PropTypes.string,
    lesson_plan: PropTypes.shape({
      template_photo_url: PropTypes.string,
      photo: PropTypes.shape({
        file_url: PropTypes.string,
      }),
    }),
  }),
  viewProgram: PropTypes.func,
  user: PropTypes.shape({
    type: PropTypes.string,
    communities: PropTypes.array,
  }),
}

export default ProgramRow
