import * as types from "../actions/actionTypes";

// state = [] : Start out with no accounts
export default function users(state = [], action) {
  switch (action.type) {
    case types.ADD_USERS:
      return action.users;

    case types.GET_SEARCHED_USERS:
      return action.searched_users;

    case types.DELETE_USER:
      return state.filter(u => u.id !== action.user_id)

    default:
      return state;
  }
}
