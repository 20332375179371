import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as commonActions from "../../actions/commonActions";
import * as rankActions from "../../actions/rankActions";
import * as objectiveActions from "../../actions/objectiveActions";
import _ from "lodash";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types'
//import { Media, Player } from 'react-media-player'

import "../../assets/styles/components/ranks/ObjectiveDetailsPage.scss";
import objective_placeholder from "../../assets/images/mock/objectivePlaceholder.png";

// import Header from '../common/Header';
// import TabBar from '../common/TabBar';
import ObjectiveVideo from "./videoPlayers/ObjectiveVideo";
import PageHeader from '../common/PageHeader/PageHeader'
import { withRouting } from "../common/hooks";

// import Rank from './Rank';

const styles = {
  objectiveDetailsStyle: {
    backgroundColor: "#f8f8f8",
    marginTop: "-150px"
  }
};

class ObjectiveDetailsPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.fetchRanks = this.fetchRanks.bind(this);
  }

  fetchRanks() {
    let rankId =
      parseInt(this.props.params.rankId, 10) ||
      parseInt(this.props.params.rankId, 10);
    if (!this.props.ranks || this.props.ranks.length === 0) {
      this.props.rank_actions.getAllRanks().then(response => {
        this.props.objective_actions.getAllRankObjectives(rankId);
      });
    } else if (!this.props.objectives || this.props.objectives.length === 0) {
      this.setState({ rankId: rankId });
      this.props.objective_actions.getAllRankObjectives(rankId);
    } else {
      this.props.objective_actions.getAllRankObjectives(rankId);
      this.setState({
        rankId: rankId
      });
    }
  }

  componentDidMount() {
    this.fetchRanks();
  }

  componentWillReceiveProps() {
    //this.fetchRanks();
  }

  displayObjectivePoints() {
    if (
      this.props.objective &&
      this.props.objective.points &&
      parseInt(this.props.objective.points, 10) > 0
    ) {
      return (
        <div className='objectivePoints'>
          <i className='fa fa-trophy' aria-hidden='true' />
          <h5>
            {this.props.objective.points}
            <span>pt</span>
          </h5>
        </div>
      );
    }
  }

  displaySocialButton() {
    if (this.props.is_social) {
      return (
        <div className='socialButtonContainer'>
          <Link
            className='btn-sm btn pull-right btn-primary mt-2'
            to={"/social/action/objective/" + this.props.objective.id}
          >
            {" "}
            Log Action{" "}
          </Link>
        </div>
      );
    }
  }

  displayObjectiveTitle() {
    if (this.props.objective && this.props.objective.name) {
      return (
        <div className='objectiveTitle'>
          <div className='row'>
            <div className='col-12'>
              <h5>OBJECTIVE OVERVIEW</h5>
            </div>
          </div>
        </div>
      );
    }
  }

  displayObjectiveDescription() {
    if (this.props.objective && this.props.objective.description) {
      return (
        <div className='objectiveDescription'>
          <div className='row'>
            <div className='col-12'>
              <h5>{this.props.objective.description}</h5>
            </div>
          </div>
        </div>
      );
    }
  }

  displayObjectiveCompletionSection() {
    if (this.props.objective && this.props.objective.completionText) {
      return (
        <div className='howToSection'>
          <div className='row'>
            <div className='col-8 col-md-6 col-lg-4'>
              <h4>HOW TO COMPLETE</h4>
            </div>
            <div className='col-4 col-md-6 col-lg-8'>
              <div className='fullWidthLine' />
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <p>{this.props.objective.description}</p>
            </div>
          </div>
        </div>
      );
    }
  }

  displayObjectiveVideo() {
    if (this.props.objective && this.props.objective.video) {
      return (
        <div className='videoSection'>
          <div className='row'>
            <div className='col-3 col-md-2 col-lg-1'>
              <h4>VIDEO</h4>
            </div>
            <div className='col-9 col-md-10 col-lg-11'>
              <div className='fullWidthLine' />
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              {this.props.objective.video.attachment_url.search("vimeo") > 0
                ? this.renderVimeoVideo(
                    this.props.objective.video.attachment_url
                  )
                : this.renderYoutubeVideo(
                    this.props.objective.video.attachment_url
                  )}
            </div>
          </div>
        </div>
      );
    }
  }

  openNewWindow(url) {
    window.open(url, "_system");
  }

  renderLinks(links) {
    let btnStyle = {
      cursor: "pointer",
      backgroundColor: '#ff6633',
      color: "white"
    };
    return links.map((link, index) => {
      return (
        <a
          key={index}
          style={btnStyle}
          className='btn btn-block'
          onClick={this.openNewWindow.bind(this, link.attachment_url)}
        >
          {link.name}
        </a>
      );
    });
  }

  displayObjectiveAttachments() {
    if (
      this.props.objective &&
      this.props.objective.links &&
      this.props.objective.links.length > 0
    ) {
      return (
        <div className='linksSection'>
          <div className='row'>
            <div className='col-3 col-md-2 col-lg-1'>
              <h4>LINKS</h4>
            </div>
            <div className='col-9 col-md-10 col-lg-11'>
              <div className='fullWidthLine' />
            </div>
          </div>

          <div className='row link'>
            <div className='col-12'>
              {this.renderLinks(this.props.objective.links)}
            </div>
          </div>
        </div>
      );
    }
  }

  getIconURLForSubject(subject) {
    if (subject.icon) {
      return subject.icon.file_url;
    }
  }

  getImageURLForObjective(objective) {
    if (objective.photo) {
      return objective.photo.file_url;
    } else if (objective.subject.photo) {
      return objective.subject.photo.file_url;
    } else {
      return objective_placeholder;
    }
  }

  toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  render() {
    return (
      <div id='ObjectiveDetailsPage'>
        <PageHeader
          title={this.toTitleCase(
            this.props.objective ? this.props.objective.name : ''
          )}
          backButton
          onClickBack={() => this.props.navigate('/ranks')}
        />
        <div className='componentBody'>
          <div
            id='objectiveDetailsWrapper'
            className='container-fluid'
            style={styles.objectiveDetailsStyle}
          >
            <div className='row'>
              <div className='col-12'>
                <div className='objectivePoints'>
                  <div className='row'>
                    <div className='col-12'>
                      <div className='objectivePercentComplete'>
                        <div
                          className='objectiveRankBadge'
                          style={
                            this.props.rank && {
                              backgroundColor: this.props.rank.color_hex,
                              marginTop: "-25px"
                            }
                          }
                        >
                          <img
                            src={this.getIconURLForSubject(
                              this.props.objective
                                ? this.props.objective.subject
                                : ""
                            )}
                            role='presentation'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {this.displaySocialButton()}
                {this.displayObjectivePoints()}
                {this.displayObjectiveTitle()}
                {this.displayObjectiveDescription()}
                {this.displayObjectiveCompletionSection()}
                {this.props.objective && this.props.objective.video ? (
                  <ObjectiveVideo
                    vidURL={this.props.objective.video.attachment_url}
                  />
                ) : null}

                {this.displayObjectiveAttachments()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ObjectiveDetailsPage.propTypes = {
  navigate: PropTypes.func,
  objective: PropTypes.shape({
    name: PropTypes.string,
  }),
}

function mapStateToProps(state, ownProps) {
  if (ownProps.params.social) {
    //This is checkig for param's exisitence not value FYI in case something breaks
    return {
      ui: state.ui,
      rank: state.ranks.find(function(obj) {
        return obj.id === parseInt(ownProps.params.rankId, 10);
      }),
      objective: state.socialObjectives.find(function(obj) {
        return obj.id === parseInt(ownProps.params.objectiveId, 10);
      }),
      is_social: Boolean(ownProps.params.social)
    };
  } else {
    let rankId =
      parseInt(ownProps.params.rankId, 10) ||
      parseInt(this.props.params.rankId, 10);
    let objectiveId =
      parseInt(ownProps.params.objectiveId, 10) ||
      parseInt(this.props.params.objectiveId, 10);

    return {
      ui: state.ui,
      ranks: state.ranks,
      rank: state.ranks.find(function(obj) {
        return obj.id === rankId;
      }),
      objectives: state.objectives[rankId],
      objective: state.objectives[rankId].find((obj) => obj.id === objectiveId),
    };
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(commonActions, dispatch),
    rank_actions: bindActionCreators(rankActions, dispatch),
    objective_actions: bindActionCreators(objectiveActions, dispatch)
  };
}

export default withRouting(
  connect(mapStateToProps, mapDispatchToProps)(ObjectiveDetailsPage)
)
