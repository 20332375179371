import React from 'react'
import SvgIcon from './SvgIcon'

export const CheckMark = (props) => (
  <SvgIcon
    width='13'
    height='13'
    viewBox='0 0 13 13'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M3.344 12.704C3.344 12.5653 3.31733 12.496 3.264 12.496L2.896 12.672C2.896 12.5973 2.85333 12.544 2.768 12.512L2.64 12.496C2.55467 12.496
      2.448 12.5333 2.32 12.608C2.29867 12.5547 2.272 12.5013 2.24 12.448C2.208 12.3947 2.18133 12.3467 2.16 12.304C2.02133 12.0373 1.88267 11.744
      1.744 11.424C1.616 11.0933 1.49333 10.7787 1.376 10.48C1.26933 10.1813 1.184 9.94667 1.12 9.776C1.07733 9.63733 1.02933 9.42933 0.976
      9.152C0.922667 8.87467 0.869333 8.52267 0.816 8.096C0.933333 8.17067 1.024 8.208 1.088 8.208C1.16267 8.208 1.232 8.096 1.296 7.872C1.328 7.91467
      1.38667 7.936 1.472 7.936C1.536 7.936 1.584 7.91467 1.616 7.872L1.872 7.488L2.16 7.584H2.176C2.19733 7.584 2.21867 7.57333 2.24 7.552C2.26133
      7.53067 2.29333 7.50933 2.336 7.488C2.42133 7.43467 2.48533 7.408 2.528 7.408L2.576 7.424C2.84267 7.552 3.01333 7.78667 3.088 8.128C3.28 8.93867
      3.472 9.344 3.664 9.344C3.856 9.344 4.08 9.14133 4.336 8.736C4.464 8.53333 4.592 8.29867 4.72 8.032C4.85867 7.76533 4.99733 7.46667 5.136
      7.136C5.15733 7.264 5.17867 7.328 5.2 7.328C5.25333 7.328 5.344 7.19467 5.472 6.928C5.61067 6.66133 5.82933 6.29333 6.128 5.824C6.29867 5.536
      6.512 5.21067 6.768 4.848C7.03467 4.48533 7.31733 4.112 7.616 3.728C7.91467 3.344 8.20267 2.98133 8.48 2.64C8.768 2.29867 9.024 2.00533 9.248
      1.76C9.472 1.51467 9.63733 1.35467 9.744 1.28C10.1493 1.00267 10.4693 0.735999 10.704 0.48C10.6933 0.554666 10.6773 0.623999 10.656
      0.688C10.6453 0.741333 10.64 0.778666 10.64 0.799999C10.64 0.842666 10.6613 0.863999 10.704 0.863999L11.152 0.639999V0.704C11.152 0.789333
      11.1733 0.832 11.216 0.832C11.248 0.832 11.312 0.784 11.408 0.688C11.504 0.592 11.5573 0.522666 11.568 0.48L11.536 0.704L12.08 0.384L11.952
      0.672C12.1227 0.554666 12.2453 0.495999 12.32 0.495999C12.3627 0.495999 12.3947 0.522666 12.416 0.575999C12.4373 0.618666 12.448 0.661333
      12.448 0.704C12.448 0.767999 12.4213 0.842666 12.368 0.927999C12.3147 1.01333 12.2453 1.11467 12.16 1.232C12.096 1.31733 11.9893 1.44533 11.84
      1.616C11.7013 1.776 11.488 2.016 11.2 2.336C10.912 2.64533 10.528 3.07733 10.048 3.632C9.92 3.77067 9.72267 4.016 9.456 4.368C9.18933 4.70933
      8.88533 5.10933 8.544 5.568C8.21333 6.016 7.88267 6.46933 7.552 6.928C7.22133 7.38667 6.928 7.80267 6.672 8.176C6.416 8.53867 6.23467 8.81067
      6.128 8.992L5.136 10.672C4.92267 11.0347 4.74667 11.3333 4.608 11.568C4.46933 11.792 4.36267 11.9467 4.288 12.032C4.128 12.224 3.952 12.3947
      3.76 12.544L3.616 12.464L3.488 12.544L3.344 12.704Z'
      fill='#1D2933'
    />
  </SvgIcon>
)

export const Trophy36 = (props) => (
  <SvgIcon
    // style={{ height: "72px", width: "72px" }}
    width='72'
    height='72'
    viewBox='0 0 72 72'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M43.9223 0.376679C44.9628 -0.362744 46.4193 0.027526 46.9507 1.18814L50.3948 8.71033C50.7552 9.49747 51.5743 9.97033 52.4361 9.88888L60.6726
      9.11046C61.9434 8.99036 63.0097 10.0566 62.8895 11.3274L62.1111 19.5639C62.0297 20.4257 62.5025 21.2448 63.2897 21.6052L70.8119 25.0493C71.9725
      25.5807 72.3627 27.0372 71.6233 28.0777L66.831 34.8215C66.3295 35.5271 66.3295 36.4729 66.831 37.1785L71.6233 43.9223C72.3627 44.9628 71.9725
      46.4193 70.8119 46.9507L63.2897 50.3948C62.5025 50.7552 62.0297 51.5743 62.1111 52.4361L62.8895 60.6726C63.0096 61.9434 61.9434 63.0096 60.6726
      62.8895L52.4361 62.1111C51.5743 62.0297 50.7552 62.5025 50.3948 63.2897L46.9507 70.8119C46.4193 71.9725 44.9628 72.3627 43.9223 71.6233L37.1785
      66.831C36.4729 66.3295 35.5271 66.3295 34.8215 66.831L28.0777 71.6233C27.0372 72.3627 25.5807 71.9725 25.0493 70.8119L21.6052 63.2897C21.2448
      62.5025 20.4257 62.0297 19.5639 62.1111L11.3274 62.8895C10.0566 63.0096 8.99035 61.9434 9.11046 60.6726L9.88888 52.4361C9.97033 51.5743 9.49746
      50.7552 8.71033 50.3948L1.18814 46.9507C0.0275218 46.4193 -0.362742 44.9628 0.376681 43.9223L5.16903 37.1785C5.67051 36.4729 5.67051 35.5271
      5.16903 34.8215L0.376681 28.0777C-0.362742 27.0372 0.0275216 25.5807 1.18814 25.0493L8.71033 21.6052C9.49747 21.2448 9.97033 20.4257 9.88888
      19.5639L9.11046 11.3274C8.99035 10.0566 10.0566 8.99035 11.3274 9.11046L19.5639 9.88888C20.4257 9.97033 21.2448 9.49747 21.6052 8.71033L25.0493
      1.18814C25.5807 0.0275278 27.0372 -0.362742 28.0777 0.376681L34.8215 5.16904C35.5271 5.67052 36.4729 5.67052 37.1785 5.16904L43.9223 0.376679Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18.1955 36.5823C20.673 38.4275 24.15 39.6851 28.476 39.8278C30.1125 41.3594 32.1736 43.7504 32.1736 45.6034C32.1736 48.4843 31.2172 49.4446
      29.3043 49.4448H29.304C27.3911 49.4449 24.5215 49.4451 24.5215 56.1668H35.9994L47.4773 56.1665C47.4773 49.4448 44.6078 49.4446 42.6949
      49.4445H42.6945C40.7817 49.4443 39.8252 48.484 39.8252 45.6031C39.8252 43.7502 41.886 41.3594 43.5225 39.8279C47.8492 39.6854 51.3267 38.4277
      53.8046 36.5823C56.4011 34.6485 57.9996 31.969 57.9998 29.278L58 24.4762C57.9999 23.4155 57.1434 22.5557 56.087 22.5557H47.7775C47.6348 19.7185
      47.197 17.4722 46.5212 16.7935C45.7655 16.0347 43.6522 15.8335 40.7826 15.8335L36 15.8335H31.2174C28.3478 15.8335 26.234 16.0342 25.4777
      16.7938C24.8019 17.4725 24.3641 19.7186 24.2214 22.5557H15.913C14.8565 22.5557 14.0001 23.4155 14 24.4762L14.0002 29.278C14.0004 31.969 15.5989
      34.6485 18.1955 36.5823ZM47.7957 26.397C47.675 29.6514 47.216 33.0945 46.356 35.6616C48.553 35.2309 50.2728 34.4299 51.525 33.4973C53.4415 32.07
      54.1736 30.4281 54.1737 29.2778L54.1738 26.397H47.7957ZM24.2031 26.397C24.3238 29.6513 24.7828 33.0942 25.6426 35.6614C23.4463 35.2306 21.727
      34.4297 20.475 33.4973C18.5585 32.07 17.8264 30.4281 17.8263 29.2778L17.8262 26.397H24.2031Z'
      fill='#ff6633'
    />
    <path
      d='M43.1905 28.4799C43.0018 28.0336 42.7189 27.6812 42.4123 27.3993C42.0822 27.1175 41.7049 26.906 41.3041 26.7416C40.8796 26.6007 40.4552
      26.5302 40.0307 26.5302C39.7478 26.5302 39.4884 26.5537 39.2526 26.6007C39.0168 26.6477 38.781 26.7181 38.5923 26.8121C38.7102 26.6711 38.8281
      26.5067 38.946 26.3658L42.3887 22.1846H40.408C40.125 22.1846 39.8892 22.2315 39.677 22.349C39.4648 22.443 39.2997 22.5839 39.2054
      22.7248L36.4937 26.3893C36.3758 26.5537 36.2579 26.7181 36.14 26.8826C35.857 26.1779 35.2675 25.6611 34.3715 25.3792C34.6544 25.2852 34.8902
      25.1678 35.1025 25.0268C35.3147 24.8859 35.5033 24.7215 35.6448 24.5101C35.7863 24.3221 35.9042 24.0638 35.9749 23.8054C36.0457 23.547 36.0928
      23.2181 36.0928 22.8658C36.0928 22.4664 36.0221 22.0906 35.857 21.7383C35.692 21.3859 35.4797 21.0805 35.1732 20.8221C34.8667 20.5638 34.513
      20.3523 34.0885 20.2114C33.6876 20.0705 33.1925 20 32.6737 20C32.1549 20 31.7069 20.0705 31.2589 20.2114C30.8109 20.3523 30.4336 20.5638 30.0799
      20.8221C29.7498 21.104 29.4668 21.4564 29.2546 21.8557C29.0188 22.2785 28.8773 22.7483 28.8065 23.2886L29.9148 23.5C30.1978 23.547 30.4336
      23.5235 30.5986 23.4295C30.7637 23.3356 30.8816 23.1711 30.9759 22.9128C31.0231 22.7483 31.0938 22.6074 31.1881 22.4664C31.2825 22.3255 31.4004
      22.2081 31.5418 22.0906C31.6833 21.9966 31.8248 21.9027 32.0135 21.8557C32.1785 21.7852 32.3672 21.7617 32.5794 21.7617C33.051 21.7617 33.4047
      21.8792 33.6641 22.1376C33.9234 22.396 34.0413 22.7718 34.0413 23.2651C34.0413 23.5 34.0178 23.7114 33.947 23.8993C33.8763 24.0872 33.7584
      24.2517 33.5933 24.3926C33.4283 24.5336 33.1925 24.651 32.8859 24.7215C32.5794 24.7919 32.2021 24.8389 31.7305 24.8389V26.3423C32.2964 26.3423
      32.7444 26.3893 33.0746 26.4597C33.4047 26.5302 33.6405 26.6477 33.8291 26.7886C33.9942 26.9295 34.1121 27.1175 34.1592 27.3289C34.2064 27.5403
      34.23 27.7752 34.23 28.0336C34.23 28.2215 34.2064 28.4329 34.1121 28.6208C34.0413 28.8087 33.9234 28.9966 33.782 29.1611C33.6405 29.3255 33.4518
      29.443 33.216 29.5369C33.0038 29.6309 32.7444 29.6779 32.4379 29.6779C32.2021 29.6779 31.9899 29.6544 31.8012 29.5839C31.6126 29.5134 31.4475
      29.4195 31.306 29.302C31.1646 29.1846 31.0231 29.0436 30.9052 28.8792C30.7873 28.7148 30.6694 28.5268 30.5751 28.3389C30.4807 28.151 30.3157
      28.057 30.1035 28.0101C29.9148 27.9631 29.679 27.9866 29.4432 28.0805L28.5 28.4799C28.6651 28.9732 28.8773 29.4195 29.1131 29.7718C29.3489
      30.1477 29.6083 30.453 29.9384 30.6879C30.2685 30.9228 30.6222 31.1107 31.0467 31.2282C31.4711 31.3456 31.9427 31.4161 32.4851 31.4161C33.0038
      31.4161 33.499 31.3456 33.9706 31.1812C34.4422 31.0403 34.8667 30.8054 35.2204 30.5235L35.2439 30.5C35.2911 30.8758 35.3618 31.2282 35.4797
      31.557C35.6684 32.0738 35.9513 32.4966 36.305 32.849C36.6588 33.2013 37.1068 33.4832 37.602 33.6946C38.1207 33.906 38.6866 34 39.3233 34C39.96
      34 40.5259 33.8826 41.0447 33.6711C41.5634 33.4597 41.9879 33.1779 42.3652 32.802C42.7424 32.4497 43.0254 32.0268 43.214 31.5336C43.4027 31.0403
      43.5206 30.547 43.497 29.9832C43.4734 29.443 43.3791 28.9262 43.1905 28.4799ZM36.3522 28.6208C36.0928 28.7617 35.7863 28.9262 35.5976
      28.9732C35.409 29.0201 35.1496 29.1611 35.1496 29.1611V27.1409C35.1496 27.1409 35.2439 27.2114 35.2675 27.3289C35.2911 27.4463 35.3147 27.6107
      35.5741 27.7282C35.8334 27.8456 36.1871 27.8221 36.4465 27.8456C36.7059 27.8691 37.1068 27.9396 37.2011 28.1275C37.2954 28.3154 37.3662 28.7148
      37.3662 28.7148C37.3662 28.7148 37.2954 28.6208 37.0596 28.4799C36.871 28.3859 36.6116 28.4564 36.3522 28.6208ZM41.1154 30.9933C41.0211 31.2282
      40.8796 31.4396 40.691 31.6275C40.5259 31.8389 40.3137 31.9799 40.0543 32.0738C39.7949 32.1678 39.5355 32.2383 39.2526 32.2383C38.9225 32.2383
      38.6395 32.1913 38.4037 32.0973C38.1679 32.0034 37.9557 31.8624 37.7906 31.698C37.6255 31.5101 37.5076 31.2987 37.4133 31.0638C37.3426 30.8054
      37.2954 30.547 37.2954 30.2416C37.2954 29.9362 37.3426 29.6779 37.4369 29.4195C37.5312 29.1611 37.6727 28.9497 37.8378 28.7852C38.0028 28.5973
      38.215 28.4564 38.4508 28.3624C38.6866 28.2685 38.946 28.2215 39.229 28.198C39.5355 28.198 39.7949 28.245 40.0543 28.3389C40.3137 28.4329
      40.5259 28.5503 40.691 28.7383C40.8796 28.9027 40.9975 29.1141 41.1154 29.3725C41.2097 29.6309 41.2569 29.9128 41.2569 30.2181C41.2569 30.5
      41.2097 30.7584 41.1154 30.9933Z'
      fill='white'
    />
  </SvgIcon>
)
