import React, { Component } from "react";
import { toast } from "react-toastify";
import _ from "lodash";

// ASSETS
import "./EducationDetailPage.scss";

// REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as commonActions from "../../../actions/commonActions";
import * as educationActions from "../../../actions/educationActions";

// CHILDREN
import Loader from "../../common/Op36Loader-web";
import EducationOverview from "./EducationOverview";
import EducationContent from "./Content/EducationContent";
import EducationVideoModal from "./Modals/EducationVideoModal";
import AdminEducationModal from "../Modals/AdminEducationModal";
import EducationProgressBar from "../EducationProgressBar";
import moment from "moment";
import { withRouting } from "../../common/hooks";

class EducationDetailPage extends Component {
  state = {
    loader: true,
    editLoader: false,
    detailView: "overview",
    showVideoModal: false,
    showAdminEditModal: false,
    selectedLesson: {},
    id: -1,
    name: "",
    description: "",
    price: 0,
    start_date: null,
    end_date: null,
    visibility: 0,
    video_url: "",
    uploadPhotoName: "",
    uploadPhotoFile: "",
    uploadPhotoSrc: "",
    education_sections: [],
    sectionIndex: -1,
    selectedEducation: {},
    deleted_sections: [],
    deleted_lessons: [],
  };
  componentDidMount() {
    const {
      common_actions,
      education_actions,
      location,
      params,
      current_user,
    } = this.props;

    const educationId = location.state.educationId || params.educationId;
    const userEducationId = location.state.userEducationId || params.userEducationId;

    common_actions.setCurrentTab("Education");
    common_actions.showSideBar(true);
    common_actions.showNavBar(true);

    education_actions
      .getEducation(educationId)
      .then(() => {
        if (current_user.type === "Coach") {
          education_actions.getUserEducation(userEducationId);
        }

        this.setState({ loader: false });
      })
      .catch(() => {
        this.setState({ loader: false });
      });
  }

  updateEducation = () => {
    const { education_actions, education } = this.props;
    this.setState({ editLoader: true }, () => {
      education_actions.updateEducation(this.state).then(() => {
        education_actions.getEducation(education.id).then(() => {
          this.setState(
            { editLoader: false, showAdminEditModal: false },
            () => {
              toast.success("Success. Updated Education!", {
                position: toast.POSITION.BOTTOM_LEFT,
              });
            }
          );
        });
      });
    });
  };

  setEducationState = () => {
    const {
      education: {
        id,
        name,
        description,
        video_url,
        photo,
        education_sections,
        price,
        start_date,
        end_date,
        visibility,
      },
    } = this.props;
    const { education } = this.props;

    this.setState({
      id,
      name,
      description,
      price: price * 100,
      start_date: start_date ? moment(start_date) : null,
      end_date: end_date ? moment(end_date) : null,
      visibility: this.convertVisibilityToInt(visibility),
      video_url,
      uploadPhotoSrc: photo && photo.file_url,
      education_sections,
      selectedEducation: education,
    });
  };

  convertVisibilityToInt = (vis) => {
    switch (vis) {
      case "all_coaches":
        return 0;
      case "paid_coaches":
        return 1;
      case "academy_coaches":
        return 2;
      default:
        return 0;
    }
  };

  chooseFirstUncompletedLesson = () => {
    const { education, user_education } = this.props;
    let firstUncompletedLesson;

    for (let i = 0; i < education.education_sections.length; i++) {
      let section = education.education_sections[i];

      if (!firstUncompletedLesson) {
        for (let z = 0; z < section.education_lessons.length; z++) {
          let lesson = section.education_lessons[z];
          let completedIndex = user_education.completed_lessons
            .map((completedLesson) =>
              parseInt(Object.keys(completedLesson), 10)
            )
            .indexOf(lesson.id);
          if (completedIndex === -1) {
            firstUncompletedLesson = lesson;
            break;
          }
        }
      } else {
        break;
      }
    }

    return firstUncompletedLesson;
  };

  toggleVideoModal = (selectedLesson) => {
    this.setState({
      showVideoModal: true,
      selectedLesson,
    });
  };

  toggleAdminEditModal = () => {
    this.setEducationState();
    this.setState({
      showAdminEditModal: true,
    });
  };

  closeVideoModal = () => {
    this.setState({
      showVideoModal: false,
    });
  };

  closeCompleteModal = (education) => {
    this.setState({
      showVideoModal: false,
    });
  };

  closeAdminEditModal = () => {
    this.setState({
      showAdminEditModal: false,
    });
  };

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleDateChange = (val, date) => {
    this.setState({ [val]: date });
  };

  grabPhotoData = (photoData) => {
    const { uploadPhotoFile, uploadPhotoName, uploadPhotoSrc } = photoData;
    this.setState({
      uploadPhotoName,
      uploadPhotoFile,
      uploadPhotoSrc,
    });
  };

  grabSectionData = (data) => {
    let education_sections = [...this.state.education_sections];
    let indexOfSection = education_sections
      .map((section) => section.order)
      .indexOf(data.order);

    if (indexOfSection > -1) {
      education_sections.splice(indexOfSection, 1, data);
    } else {
      education_sections.push(data);
    }
    this.setState({ education_sections });
  };

  addSection = () => {
    let sectionIndex = this.state.sectionIndex;
    let education_sections = [...this.state.education_sections];

    if (education_sections.length > 0) {
      let lastSectionIndex =
        education_sections[education_sections.length - 1].order;
      lastSectionIndex++;
      sectionIndex = lastSectionIndex;
    } else {
      sectionIndex++;
    }

    education_sections.push({ order: sectionIndex });
    this.setState({
      sectionIndex,
      education_sections,
    });
  };

  deleteSection = (section) => {
    let education_sections = [...this.state.education_sections];
    let indexOfSection = education_sections
      .map((section) => section.order)
      .indexOf(section.order);

    let deletedSection = education_sections.splice(indexOfSection, 1);
    education_sections.splice(indexOfSection, 1);

    this.setState({ education_sections });

    if (deletedSection[0].id && deletedSection[0].id > -1)
      this.deleteSectionFromAPI(deletedSection[0]);
  };

  deleteSectionFromAPI = (section) => {
    let deleted_sections = [...this.state.deleted_sections];

    deleted_sections.push(section.id);

    this.setState({
      deleted_sections,
    });
  };

  deleteLessonFromAPI = (lesson) => {
    let deleted_lessons = [...this.state.deleted_lessons];

    deleted_lessons.push(lesson.id);

    this.setState({
      deleted_lessons,
    });
  };

  toggleDetailView = (detailView) => {
    this.setState({ detailView });
  };

  renderDetailView = () => {
    const { education, current_user, user_education } = this.props;
    const { detailView } = this.state;

    if (detailView === "overview") {
      return <EducationOverview education={education} />;
    } else if (detailView === "content") {
      return (
        <EducationContent
          education={education}
          toggleVideoModal={this.toggleVideoModal}
          isCoach={current_user.type === "Coach"}
          userEducation={user_education}
        />
      );
    }
  };

  render() {
    const { education, current_user, user_education } = this.props;
    const {
      loader,
      editLoader,
      detailView,
      showVideoModal,
      selectedLesson,
      showAdminEditModal,
    } = this.state;
    if (loader) {
      return <Loader message='loading education details' />;
    }
    return (
      <div id='EducationDetailPage' className='animated fadeIn'>
        <div className='col-md-10 mx-auto card mt-5'>
          <div className='row pt-5 pb-5'>
            <div className='col-md-3 education-detail-sidebar'>
              <div className='education-detail-image-wrapper'>
                {education.photo && (
                  <img src={education.photo.file_url} alt={education.name} />
                )}
              </div>
              <div className='education-detail-sidebar-button-wrapper'>
                <button
                  className={`btn btn-link ${
                    detailView === "overview" && "sidebar-active"
                  }`}
                  onClick={() => this.toggleDetailView("overview")}
                >
                  Overview
                </button>
                <button
                  className={`btn btn-link ${
                    detailView === "content" && "sidebar-active"
                  }`}
                  onClick={() => this.toggleDetailView("content")}
                >
                  Course Content
                </button>
              </div>
            </div>
            <div className='col-md-9 education-detail-info-container'>
              <div className='education-detail-header'>
                <div className='education-detail-top-header'>
                  <div className='education-detail-title'>
                    <h5>{education.name}</h5>
                  </div>
                  <div className='education-button-wrapper'>
                    {current_user.type === "Admin" && (
                      <button
                        className='btn btn-dark mr-2'
                        onClick={this.toggleAdminEditModal}
                      >
                        Edit Education
                      </button>
                    )}
                    <button
                      className='btn btn-primary'
                      style={{ transform: "scale(1)" }}
                      onClick={() =>
                        this.toggleVideoModal(
                          current_user.type === "Coach" &&
                            user_education &&
                            user_education.completed_lessons.length > 0 &&
                            !user_education.is_completed
                            ? this.chooseFirstUncompletedLesson()
                            : education.education_sections[0]
                                .education_lessons[0]
                        )
                      }
                    >
                      View Lessons
                    </button>
                  </div>
                </div>

                {current_user.type === "Coach" &&
                  user_education &&
                  user_education.completed_lessons && (
                    <EducationProgressBar
                      education={education}
                      userEducation={user_education}
                    />
                  )}
              </div>
              {this.renderDetailView()}
            </div>
          </div>
        </div>
        {showVideoModal && (
          <EducationVideoModal
            showModal={showVideoModal}
            closeModal={this.closeVideoModal}
            closeCompleteModal={this.closeCompleteModal}
            education={education}
            selectedLesson={selectedLesson}
            isCoach={current_user.type === "Coach"}
          />
        )}

        {current_user.type === "Admin" && showAdminEditModal && (
          <AdminEducationModal
            current_user={current_user}
            showModal={showAdminEditModal}
            closeModal={this.closeAdminEditModal}
            formState={this.state}
            grabPhotoData={this.grabPhotoData}
            handleInputChange={this.handleInputChange}
            handleDateChange={this.handleDateChange}
            sendSectionData={this.grabSectionData}
            addSection={this.addSection}
            deleteSection={this.deleteSection}
            deleteLesson={this.deleteLessonFromAPI}
            updateEducation={this.updateEducation}
            isEditModal
            loader={editLoader}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    ui: state.ui,
    current_user: state.user.current_user,
    education: state.education,
    user_education: state.userEducation,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    common_actions: bindActionCreators(commonActions, dispatch),
    education_actions: bindActionCreators(educationActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouting(EducationDetailPage))
