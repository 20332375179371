import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import _ from "lodash";

// CHILDREN
import DraggableResourceCategory from "./DraggableResourceCategory";

// ASSETS
import "./ResourceSectionForm.scss";

class ReorganizeResourcesForm extends Component {
  state = {
    resources: this.props.resources || [],
  };
  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const resources = this.reorder(
      this.state.resources,
      result.source.index,
      result.destination.index
    );

    let resourcesCopy = JSON.parse(JSON.stringify(resources));

    resourcesCopy.map((r, index) => {
      r.order = index;
    });

    this.setState(
      {
        resources: resourcesCopy,
      },
      () => {
        this.props.updateResources(resourcesCopy);
      }
    );
  };

  grabResourceData = (resource) => {
    let resources = JSON.parse(JSON.stringify(this.state.resources));
    let resourceIndex = resources.map((r) => r.id).indexOf(resource.id);

    resources[resourceIndex].title = resource.title;

    this.setState({ resources });
  };

  renderResources = () => {
    const { resources } = this.state;
    const { updateResources } = this.props;

    if (resources && resources.length > 0) {
      return resources.map((resource, index) => (
        <Draggable key={resource.id} draggableId={`resource_${resource.id}`} index={index}>
          {(provided, snapshot) => (
            <div>
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <DraggableResourceCategory
                  category={resource}
                  categories={resources}
                  updateCategories={updateResources}
                  sendCategoryData={this.grabResourceData}
                />
              </div>
              {/* {provided.placeholder} */}
            </div>
          )}
        </Draggable>
      ));
    }
    return null;
  };

  render() {
    const { resources } = this.state;
    return (
      <div id='ResourceSectionForm' className='row animated fadeIn'>
        <div className='col-md-12'>
          <p>
            So..... don't bother trying to rename the resources here. It won't
            save. Do that by clicking into the resource, then using the 'Edit
            Resource' button.
          </p>
        </div>

        {resources && resources.length > 0 && (
          <div className='resource-draggable-container mt-0'>
            <h3>Arrange Resources</h3>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId='droppable'>
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {resources
                      ? this.renderResources(provided, snapshot)
                      : null}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        )}
      </div>
    );
  }
}
export default ReorganizeResourcesForm;
