import React, { Component } from "react";
import Modal from "react-modal";

import "../../assets/styles/common/confirmationModal.scss";

const modalStyles = {
  content: {
    height: "min-content",
    margin: "auto",
    width: "380px",
    marginTop: "12%",
    overflow: "hidden",
  },
};

class ConfirmationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      actionLoader,
      showConfirmationModal,
      loader,
      levelUpLoader,
      closeConfirmationModal,
      confirmationMessage,
      confirmationDenied,
      confirmationProceed,
    } = this.props;
    return (
      <Modal
        isOpen={showConfirmationModal}
        onRequestClose={loader || levelUpLoader ? null : closeConfirmationModal}
        contentLabel='Validation Modal'
        style={modalStyles}
      >
        <div className='modal-content animated fadeIn'>
          {loader || levelUpLoader ? (
            <div
              id='GoalImage'
              className='text-center confirmation-loader-container'
            >
              <i
                className='fa fa-circle-o-notch fa-spin fa-lg fa-3x confirmation-loader-icon'
                aria-hidden='true'
              />
              <p className='mt-2 confirmation-loader-text'>
                {loader
                  ? "completing objective - please wait for success"
                  : "ranking up golfer - please wait for success"}
              </p>
            </div>
          ) : (
            <div>
              <button
                onClick={closeConfirmationModal}
                className='confirmation-modal-close-btn btn-link'
              >
                <i className='fa fa-times confirmation-modal-close-icon' />
              </button>
              <div className='row my-3'>
                <div className='col-md-12 confirmation-modal-container'>
                  <div className='confirmation-title-container'>
                    <i
                      className={`fa fa-question-circle confirmation-modal-icon`}
                      aria-hidden='true'
                      style={{ color: "#34404b" }}
                    />
                  </div>
                  <p className='text-center confirmation-modal-message'>
                    {confirmationMessage}
                  </p>
                  <div className='confirmation-button-row'>
                    <button
                      className='confirmation-denied-button'
                      onClick={confirmationDenied}
                    >
                      No
                    </button>
                    <button
                      className='btn btn-primary confirmation-proceed-button'
                      onClick={confirmationProceed}
                      disabled={actionLoader}
                    >
                      {actionLoader && (
                        <i className='fa fa-spinner fa-spin confirmation-spinner' />
                      )}
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

export default ConfirmationModal;
