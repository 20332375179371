import { useSelector } from 'react-redux'
import styles from './CoachDashboardHome.module.scss'
import CommunityFeed from './CommunityFeed'
import CoachDashboardQuickActions from './CoachDashboardQuickActions'
import CoachDashboardProgramsOverview from './CoachDashboardProgramsOverview'
import CoachDashboardCalendar from './CoachDashboardCalendar'
import CoachDashboardSchedule from './CoachDashboardSchedule'
import CoachDashboardSupport from './CoachDashboardSupport'

const CoachDashboardHome = () => {
  const communityId = useSelector(state => state.user.current_user.communities[0].id)

  return (
    <div className={styles.coachDashboardHome}>
      <CommunityFeed communityId={communityId}/>
      <div className={styles.dashboardContent}>
        <CoachDashboardQuickActions communityId={communityId}/>
        <div className={styles.dashboardWidgetWrapper}>
          <div>
            <CoachDashboardCalendar/>
            <CoachDashboardProgramsOverview communityId={communityId}/>
          </div>
          <div>
            <CoachDashboardSchedule communityId={communityId}/>
            <CoachDashboardSupport/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CoachDashboardHome
