import React from "react";
import { Link } from "react-router-dom";

import "./Breadcrumbs.scss";

export const BreadcrumbItem = ({ name, route, routeState, disabled }) => {
  return (
    <Link
      className={`breadcrumb-item ${disabled ? 'disabled-breadcrumb' : ''}`}
      to={route}
      {...(routeState ? { state: routeState } : {})}
    >
      {name}
    </Link>
  )
};

export const Breadcrumbs = props => {
  return <div id="BreadcrumbsContainer" className='breadcrumb'>{props.children}</div>;
};
