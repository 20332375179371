import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const SideBarSingleLink = ({ link, title, collapseSideBar }) => (
  link.external ?
    <a
      href={link.external}
      target={link.target}
      onClick={() => collapseSideBar()}
    >
      {title}
    </a>
    :
    <Link
      to={link.to}
      target={link.target}
      onClick={() => collapseSideBar()}
    >
      {title}
    </Link>
)

SideBarSingleLink.propTypes = {
  link: PropTypes.object,
  title: PropTypes.string,
  collapseSideBar: PropTypes.func,
}

export default SideBarSingleLink
