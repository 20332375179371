import { useSelector } from 'react-redux'
import StudentDashboardPage from './studentDashboard/StudentDashboardPage'
import OldDashboardPage from './OldDashboardPage'

const DashboardPage = () => {
  const user = useSelector(state => state.user.current_user)

  return (
    user.type === 'Student' ? (
      <StudentDashboardPage />
    ) : (
      <OldDashboardPage />
    )
  )
}

export default DashboardPage
