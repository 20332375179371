import React from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../../actions/userActions";
import * as commonActions from "../../actions/commonActions";

import Loader from "../common/Op36Loader-web";

import ReactPlayer from "react-player";

import Axios from "../../axios";

import "./UpgradePrompt.scss";
import { withRouting } from "./hooks";

const pages = [
  /*
  {
    test: this is a substring that's compared to the current route,
    title: top of page,
    note: under video,
    video_url: pretty self explanatory,
    tab: uses this to set the current tab so the sidebar shows the correct active tab
  }
  */
  {
    test: "default",
    title: "Requires Upgrade",
    note:
      "This feature is a part of the Operation 36 Academy. Schedule a call below to see all the cool features we have to offer.",
    video_url: "https://youtu.be/LV6UA4lLx_Q",
  },
  {
    test: "lesson-plan",
    title: "Lesson Plans (Requires Upgrade)",
    note:
      "The Lesson Plan feature is for those running coaching programs in the Academy Plan. If you ever wanted to see a demo on how Lesson Plans can organize your staff to know what to coach before the season starts, just schedule a call below.",
    video_url: "https://vimeo.com/427099828",
    tab: "lesson-plans",
  },
  {
    test: "drills",
    title: "Drills and Activities (Requires Upgrade)",
    note:
      "The Drills and Activities feature is for those running coaching programs in the Academy Plan. If you ever wanted to see a demo on how Drills and Activities can give your team over 100 coaching activities/drills to easily run classes in all areas of the game, just schedule a call below. I am happy to walk you through it anytime.",
    video_url: "https://vimeo.com/440798966",
    tab: "Drills",
  },
  {
    test: "families",
    title: "Families (Requires Upgrade)",
    note:
      "The Families page is all about keeping track of your community. Each student will be a part of a family. Here you can see some details about every family that is part of your community.",
    video_url: "https://vimeo.com/441087177",
    tab: "Families",
  },
  {
    test: "students",
    title: "Students (Requires Upgrade)",
    note:
      "The Students page allows you to quickly and easily find any student within your community. Just click the student to navigate to their profile. From there, you can track their progress, log play/train activities, or manage their curriculum objectives.",
    video_url: "https://vimeo.com/442138642/c2b4404b09",
    tab: "Students",
  },
  {
    test: "class",
    title: "Classes & Groups (Requires Upgrade)",
    note:
      "The Classes feature is for those running coaching programs in the Academy Plan. If you ever wanted to see a demo on how Classes can organize your students for communication and engagement, just schedule a call below. I am happy to walk you through it anytime.",
    video_url: "https://vimeo.com/427099949",
    tab: "Classes",
  },
  {
    test: "events",
    title: "9-Hole Events (Requires Upgrade)",
    note:
      "9-Hole Events are the backbone of the Operation 36 program. This is the spot to log your events. Simply add all your students, input their scores, and see an easily digestible leaderboard. You can also look back at all the previous events for your community. If you want to explore the 9-Hole Events feature further, I'm happy to schedule a call to walk you through it.",
    video_url: "https://vimeo.com/441025099/eb08ab4308",
    tab: "Events",
  },
  {
    test: "leads",
    title: "Community Leads (Requires Upgrade)",
    note:
      "The Leads feature is a very powerful tool to help you fill your programs. Prospects will fill out a lead form on the community map, and that lead will be displayed to you here. It's up to you to make contact with the prospect and potentially invite them to be a part of your community. To learn more about how the Leads tool can help your facility, just schedule a call below. I'm happy to walk you through it.",
    video_url: "https://vimeo.com/441105743",
    tab: "Leads",
  },
  {
    test: "resource",
    title: "Resource Center (Requires Upgrade)",
    note:
      "The Resource Center feature is for those running coaching programs in the Academy Plan. If you ever wanted to see a demo on how the Resource Center can give your team all the flyers, program templates, marketing emails, and education to quickly launch a coaching program, just schedule a call below. I am happy to walk you through it anytime.",
    video_url: "https://vimeo.com/427140819",
    tab: "Resources",
  },
  {
    test: "reports",
    title: "Reports (Requires Upgrade)",
    note:
      "The Reports feature is for those running coaching programs in the Academy Plan. It allows you to see where golfers are in their development, calculate totals on golfers activities to showcase to your leadership + many more!  Just schedule a call below. I am happy to walk you through it anytime.",
    video_url: "https://vimeo.com/441446635",
    tab: "Reports",
  },
  {
    test: "goals",
    title: "Private Goals (Requires Upgrade)",
    note:
      "The Private Goals feature is for those running coaching programs in the Academy Plan. If you ever wanted to see a demo on how Goals can give your golfers custom educational objectives to accomplish, just schedule a call below. I am happy to walk you through it anytime.",
    video_url: "https://vimeo.com/442413416",
    tab: "Goals",
  },
  {
    test: "ranks",
    title: "Op 36 Curriculum (Requires Upgrade)",
    note:
      "The Operation 36 Curriculum feature is for those running coaching programs in the Academy Plan. If you ever wanted to see a demo on how Goals can give your golfers custom educational objectives to accomplish, just schedule a call below. I am happy to walk you through it anytime.",
    video_url: "https://vimeo.com/442172413",
    tab: "Curriculums",
  },
  {
    test: "announcements",
    title: "Send an Email (Requires Upgrade)",
    note:
      "The Email feature is for those running coaching programs in the Academy Plan. It allows you to quickly send a blast email to families/golfers in classes.  Just schedule a call below. I am happy to walk you through it anytime.",
    video_url: "https://vimeo.com/442122774",
    tab: "Announcements",
  },
  {
    test: "pro-shop",
    title: "Academy Display (Requires Upgrade)",
    note:
      "The Academy Display feature is for those running coaching programs in the Academy Plan. This is a great tool if you are looking for a modern solution to market programs, create community, and enhance the buzz around your programs. Have it running in your pro-shop in your coaching program section. Just schedule a call below. I am happy to walk you through it anytime.",
    video_url: "https://vimeo.com/385755353/6c98732e9a",
    tab: "ProShopDisplay",
  },
];

class UpgradePrompt extends React.Component {
  state = {
    loader: true,
    salesRep: null,
    page: pages[0]
  };

  componentDidMount() {
    const { location: { pathname } } = this.props;

    this._setPage(pathname);

    let sales_rep_id =
      this.props.user.user_settings.coach_permission === 0
        ? this.props.user.sales_lead.sales_rep_id
        : this.props.user.communities[0].sales_rep_id || 38653;
    // grab the sales rep info
    Axios.get("/api/v1/users/" + sales_rep_id)
      .then((res) => this.setState({ salesRep: res.data.user }))
      .catch((err) =>
        this.setState({
          salesRep: { first_name: "our sales team", last_name: "" },
        })
      )
      .finally(() => this.setState({ loader: false }));
  }

  componentDidUpdate(prevProps) {
    const { location: { pathname } } = this.props;

    if (pathname !== prevProps.location?.pathname) {
      this._setPage(pathname);
    }
  }

  render() {
    const { loader, salesRep, page } = this.state;

    if (loader) {
      return (
        <div className='text-center'>
          <Loader message='loading' />
        </div>
      );
    }
    const { user } = this.props;
    return (
      <div
        id='UpgradePrompt'
        className='col-md-8 mx-auto pt-5 d-flex flex-column align-items-center justify-content-center'
      >
        <h3 className='text-center'>{page.title}</h3>
        <div
          className='border border-dark rounded mt-2 mb-4'
          style={{
            width: "90%",
            height: 360,
            overflow: "hidden",
            boxShadow: "8px 10px 10px -10px rgba(0, 0, 0, 0.3)",
            backgroundColor: 'black',
          }}
        >
          <ReactPlayer
            className='upgrade-react-player'
            url={page.video_url}
            width='100%'
            height='360px'
            controls
          />
        </div>
        <p style={{ textAlign: "center" }}>
          Hey {user.first_name}, {page.note}
        </p>
        <a
          className='btn-rectangular btn-rectangular-main-positive book-demo-btn'
          style={{ width: "max-content" }}
          href={this._grabSalesLink(salesRep.last_name)}
          target='_blank'
          rel='noopener noreferrer'
        >
          <i className='fa fa-calendar mr-2' aria-hidden='true' />{" "}
          {`Schedule a call with ${salesRep.first_name}`}
        </a>
      </div>
    );
  }

  _grabSalesLink = (last_name) => {
    let name = last_name.toLowerCase();
    switch (name) {
      case "gross":
        return "https://calendly.com/gross36/consultation";
      case "bartell":
        return "https://calendly.com/bartell36/consultation";
      default:
        return "https://calendly.com/c/FFGVNFUQM7YFOZRE";
    }
  };

  _setPage = (pathname) => {
    const { page } = this.state;
    if (pathname.includes(page.test)) {
      return false;
    }
    let newPage = pages[0]
    for (let i = 0; i < pages.length; i++) {
      if (pathname.includes(pages[i].test)) {
        newPage = pages[i];
        break;
      }
    }
    if (newPage) {
      const { common_actions } = this.props;
      common_actions.setCurrentTab(newPage.tab);
      this.setState({ page: newPage });
    }
  };
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user.current_user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    common_actions: bindActionCreators(commonActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouting(UpgradePrompt))
