import PropTypes from 'prop-types'
import SideBarSingleLink from './SideBarSingleLink'
import SideBarSectionLink from './SideBarSectionLink'
import { useDispatch } from 'react-redux'
import { setSideBarState } from '../../../actions/commonActions'
import { NAVIGATION } from '../../../misc/Constants'

const SideBarLink = ({ link, singleLink, title, collapsed, selected }) => {
  const dispatch = useDispatch()
  const collapseSideBar = () => {
    dispatch(setSideBarState(NAVIGATION.COLLAPSED))
  }
  return (
    singleLink
      ? <SideBarSingleLink link={link} title={title} collapseSideBar={collapseSideBar}/>
      : <SideBarSectionLink link={link} collapsed={collapsed} collapseSideBar={collapseSideBar} selected={selected}/>
  )
}

SideBarLink.propTypes = {
  link: PropTypes.object,
  singleLink: PropTypes.bool,
  title: PropTypes.string,
  collapsed: PropTypes.bool,
  selected: PropTypes.bool,
}

export default SideBarLink
