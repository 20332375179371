import React from "react";
import Modal from "react-modal";
import GoalForm from "./GoalForm";

const GoalModal = ({ showModal, closeModal, fetchGoals }) => (
  <Modal
    isOpen={showModal}
    onRequestClose={closeModal}
    contentLabel="Goal Modal"
  >
    <div className="modal-header row">
      <div className="col-10">
        <h5 className="modal-title display-4 uppercase detail-title">
          Add Goal Template
        </h5>
      </div>
      <div className="col-2">
        <button
          type="button"
          className="close pull-right font-200 modal-close-btn"
          data-dismiss="modal"
          aria-label="Close"
          onClick={closeModal}
        >
          <span aria-hidden="true">✕</span>
        </button>
      </div>
    </div>
    <GoalForm closeModal={closeModal} fetchGoals={fetchGoals} />
  </Modal>
);

export default GoalModal;
