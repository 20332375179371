import React, { Component } from "react";
import BaseModal from "../../common/BaseModal";
import consent from '../../../constants/drill_consent'

import "./DrillLegalModal.scss";

class DrillLegalModal extends Component {
  state = {
    read: false,
    consented: false,
    validationText: ""
  };
  render() {
    const { close, type } = this.props;
    const { consented, validationText } = this.state;
    return (
      <BaseModal width={"70vw"} close={close}>
        <div id='DrillLegalModal'>
          <h2 className='text-center'>Terms of Service</h2>
          <p>{`Before publishing this ${type}, please read these terms of service and indicate your agreement.`}</p>
          <div className='reader-box' onScroll={this._trackScroll}>
            <div
              className='consent'
              dangerouslySetInnerHTML={{ __html: consent.content }}
            />
          </div>
          <div className='checkbox-container'>
            <div
              className={`checkbox ${consented ? "filled" : ""}`}
              onClick={this._handleCheckBox}
            >
              {consented && <i className='fa fa-check' aria-hidden='true' />}
            </div>
            <p>I have read, understood, and agree to the terms above.</p>
          </div>
          {<p className='text-center text-danger'>{validationText}</p>}
          <button
            className='btn-rectangular btn-rectangular-main-positive'
            disabled={!consented}
            onClick={this._sendConsent}
          >
            Agree
          </button>
        </div>
      </BaseModal>
    );
  }

  _sendConsent = () => {
    let now = Date.now();
    let version = consent.version;
    let content = consent.content;
    this.props.onAgree({ consented: now, version, content });
  };

  _handleCheckBox = () => {
    if (!this.state.read) {
      this.setState({
        validationText: "-Please read the agreement before you consent to it-"
      });
    } else {
      this.setState({ validationText: "", consented: true });
    }
  };

  _trackScroll = e => {
    if (this.state.read) {
      return;
    }
    let isBottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 130;

    if (isBottom) {
      this.setState({ read: true });
    }
  };
}

export default DrillLegalModal;
