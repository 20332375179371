import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as userActions from "../../../actions/userActions";
import * as commonActions from "../../../actions/commonActions";
// import * as drillActions from "../../../actions/drillActions";
// import * as subjectActions from "../../../actions/subjectActions";
import * as lessonPlanActions from "../../../actions/lessonPlanActions";

// import blankIcon from "../../../assets/images/common/icons/lesson_plans/blank-template.png";

import LessonReviewPublish from "../builder/builderViews/LessonReviewPublish";
import CurriculumDetailModal from "../modals/CurriculumDetailModal";
// import DrillDetailModal from "../modals/DrillDetailModal";
import DrillDetailModal from "../../drills/modals/DrillDetailModal";
import Loader from "../../common/Op36Loader-web";

import "./LessonPlanViewerPage.scss";
import TwoButtonModal from "../modals/TwoButtonModal";
import { withRouting } from "../../common/hooks";

class LessonPlanViewerPage extends React.Component {
  state = {
    loader: true,
    selectedSubject: {},
    // selectedDrill: {},
    selectedDrillId: -1,
    deletePopover: false,
    deleteConfirmation: false
  };

  componentDidMount() {
    this.props.common_actions.showNavBar(false);
    this.props.common_actions.showSideBar(false);
    Promise.all([
      // this.props.drill_actions.getDrills(),
      // this.props.subject_actions.getAllSubjects(),
      this.props.lessonPlan_actions.grabOneLessonPlan(
        this.props.params.lessonPlanId,
        true
      )
    ]).then(() => this.setState({ loader: false }));
  }

  render() {
    const { loader, deletePopover } = this.state;
    const { lesson_plan } = this.props;
    return (
      <div id='LessonPlanViewerPage'>
        {loader ? (
          <Loader message='loading lesson plan' />
        ) : (
          <div className='lesson-plan-viewer-container'>
            <div className='lp-viewer-top-header'>
              <i
                className='fa fa-arrow-left'
                aria-hidden='true'
                onClick={this._goBack}
              />
              <p className='lp-name'>{lesson_plan.name}</p>
              <i
                className='fa fa-clone'
                aria-hidden='true'
                onClick={() =>
                  this.props.navigate(`/lesson-plan/builder/${lesson_plan.id}/new`)
                }
              />
              {(lesson_plan.user_id === this.props.user.id ||
                this.props.user.type === "Admin") && (
                <i
                  className='fa fa-pencil-square-o'
                  aria-hidden='true'
                  onClick={() =>
                    this.props.navigate(
                      `/lesson-plan/builder/${lesson_plan.id}/edit`
                    )
                  }
                />
              )}
              {(lesson_plan.user_id === this.props.user.id ||
                this.props.user.type === "Admin") && (
                <i
                  className='fa fa-ellipsis-v'
                  aria-hidden='true'
                  onClick={this._openDeletePopover}
                />
              )}
              {deletePopover && (
                <div
                  ref={el => (this.deleteLP = el)}
                  className='view-delete-popover'
                >
                  <p onClick={this._triggerDeleteConfirmationModal}>
                    Delete plan
                  </p>
                </div>
              )}
            </div>
            <LessonReviewPublish
              isBuilder={false}
              sessions={JSON.parse(lesson_plan.draft_sessions)}
              triggerCurriculumDetailModal={this._triggerCurriculumDetailModal}
              triggerDrillDetailModal={this._triggerDrillDetailModal}
              lessonPlanName={lesson_plan.name}
              lessonPlanNotes={lesson_plan.notes}
              lessonPlanTags={lesson_plan.tags}
              lessonPlanPhoto={
                lesson_plan.photo
                  ? lesson_plan.photo.file_url
                  : lesson_plan.template_photo_url
                  ? lesson_plan.template_photo_url
                  : ""
              }
              video_url={lesson_plan.video_url}
              // drills={this.props.drills}
              // subjects={this.props.subjects}
              drills={lesson_plan.tasks.filter(t => !!t.title)}
              subjects={lesson_plan.tasks.filter(t => !!t.name)}
            />
            {this.state.curriculumModal && (
              <CurriculumDetailModal
                subject={this.state.selectedSubject}
                close={this._closeCurriculumModal}
              />
            )}

            {this.state.selectedDrillId > 0 && (
              <DrillDetailModal
                drillId={this.state.selectedDrillId}
                currentUser={this.props.user}
                close={this._closeDrillModal}
                drillsPage={false}
                handlePin={() => false}
                handleFavorite={() => false}
                currentPage={"lessonPlanViewer"}
              />
            )}
            {this.state.deleteConfirmation && (
              <TwoButtonModal
                title={"Delete Lesson Plan?"}
                subtitle={"This will be gone forever and cannot be retrieved"}
                close={this._closeDeleteConfirmationModal}
                confirmText={"Delete"}
                confirmAction={this._handleDeleteLessonPlan}
              />
            )}
          </div>
        )}
      </div>
    );
  }

  _handleDeleteLessonPlan = () => {
    this.props.lessonPlan_actions
      .deleteLessonPlan(this.props.lesson_plan.id)
      .then(() => {
        this.props.navigate('/lesson-plan')
      });
  };

  _triggerDeleteConfirmationModal = () => {
    this.setState({ deleteConfirmation: true });
  };

  _closeDeleteConfirmationModal = () => {
    this.setState({ deleteConfirmation: false });
  };

  _openDeletePopover = () => {
    document.addEventListener("click", this._handleClick, false);
    this.setState({ deletePopover: true });
  };

  _handleClick = e => {
    // tracks document clicks when delete popover is open and closes popover if click is outside element
    if (this.deleteLP && !this.deleteLP.contains(e.target)) {
      this._closeDeletePopover();
    }
  };

  _closeDeletePopover = () => {
    document.removeEventListener("click", this._handleClick, false);
    this.setState({ deletePopover: false });
  };

  _goBack = () => {
    this.props.navigate('/lesson-plan')
  };

  _triggerCurriculumDetailModal = id => {
    // const { subjects } = this.props;
    const subjects = this.props.lesson_plan.tasks;
    let selectedSubject = subjects.find(sub => {
      return sub.id === id;
    });

    this.setState({ selectedSubject }, () => {
      this.setState({ curriculumModal: true });
    });
  };

  _closeCurriculumModal = () => {
    this.setState({ curriculumModal: false, selectedSubject: {} });
  };

  _triggerDrillDetailModal = id => {
    // const { drills } = this.props;
    // const drills = this.props.lesson_plan.tasks;
    // let selectedDrill = drills.find(drill => {
    //   return drill.id === id;
    // });

    // this.setState({ selectedDrill }, () => {
    //   this.setState({ drillModal: true });
    // });
    this.setState({ selectedDrillId: id });
  };

  _closeDrillModal = () => {
    // this.setState({ drillModal: false, selectedDrill: {} });
    this.setState({ selectedDrillId: -1 });
  };
}

function mapStateToProps(state, ownProps) {
  return {
    ui: state.ui,
    user: state.user.current_user,
    lesson_plan: state.lessonPlans.selected_plan
    // drills: state.drills,
    // subjects: state.subjects
  };
}

function mapDispatchToProps(dispatch) {
  return {
    common_actions: bindActionCreators(commonActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
    lessonPlan_actions: bindActionCreators(lessonPlanActions, dispatch)
    // drill_actions: bindActionCreators(drillActions, dispatch),
    // subject_actions: bindActionCreators(subjectActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouting(LessonPlanViewerPage))
