const consent = {
  version: "1.00",
  content: `
  <h4 class='text-center bold'>
    OPERATION 36 GOLF PROFESSIONALS USER-GENERATED CONTENT AGREEMENT
  </h4>
  <p>
    This is an OPERATION 36 GOLF PROFESSIONALS USER-GENERATED CONTENT AGREEMENT (“UGC Agreement”) by and between you and Operation 36 Golf, LLC (“OP-36” or also sometimes referred to as “we,” “us,” or “our”). You have been granted gratis access to the OP-36 Services pursuant and subject to the terms and conditions of the OP-36 Golf Professionals End User License Agreement, Operation 36 Terms of Use and Operation 36 Privacy Policy. Your access to the OP-36 Services shall also be governed by the terms and conditions of this UGC Agreement with respect to “Your User-Generated Content” as defined below.
  </p>
  <p>
    For and in consideration of the premises and mutual covenants set forth herein and other good and valuable consideration, the receipt and adequacy of which are hereby acknowledged, you agree as follows:
  </p>
  <ol>
    <li class='bold'>
      User-Generated Content
    </li>
    <ol type="A">
      <li>
        As part of our offering of the OP-36 Services to you, we may provide you with the opportunity to upload and contribute certain content such as customized training programs and instructional guidance in various formats including text, images, videos, audio clips, written posts, articles and comments (“Your User- Generated Content”).
      </li>
      <li>
        You may elect to restrict Your User-Generated Content to participants in your ongoing OP-36 programs (“Your Restricted User-Generated Content”), or you may elect to share Your User-Generated Content with all OP-36 professionals and program participants utilizing the OP-36 Services (“Your Shared User-Generated Content”).
      </li>
    </ol>
    <br/>
    <li class='bold'>
      Ownership and License of Your User Generated Content
    </li>
    <ol type="A">
      <li>
        You shall retain ownership of Your User Generated Content (both Your Restricted User-Generated Content and Your Shared User-Generated Content).
      </li>
      <li>
        For Your User Generated Content (both Your Restricted User-Generated Content and Your Shared User-Generated Content), you grant us an unrestricted, royalty- free, perpetual, worldwide, irrevocable non-exclusive license to use, reproduce, display, perform, modify, transmit and distribute such materials in any manner, including, without limitation, for purposes of sharing Your User-Generated Content with other OP-36 professionals and program participants utilizing the OP-36 Services.
      </li>
      <li>
        You grant us the right to use your real name, social media username, likeness, descriptions of you, location, voice, or other identifying information in connection with the above licenses to use Your User-Generated Content.
      </li>
    </ol>
    <br/>
    <li class='bold'>
      Warranties and Indemnities
    </li>
    <ol type="A">
      <li>
        You represent and warrant to us that that (a) you are the sole owner and author of Your User-Generated Content or you have written permission from the owner to make Your User-Generated Content available to the OP-36 Services , and (b) Your User-Generated Content does not infringe upon any third party rights (including but not limited to any copyright, patent, trademark, trade secret, other intellectual property rights, moral rights, privacy rights or publicity rights); (c) Your User-Generated Content does not contain any defamatory, libelous or obscene material; (d) Your User-Generated Content does not violate any applicable laws, regulations, or contain any false statements or misrepresentations; and (e) you have the written consent, release, and/or permission of each and every identifiable individual person, or the owner/author of any objects or work, in Your User-Generated Content to use the name or likeness of each and every such identifiable individual person, object or work, to enable inclusion and use of Your User-Generated Content consistent with the OP-36 Golf Professionals End User License Agreement, Operation 36 Terms of Use, Operation 36 Privacy Policy and the terms and conditions of this UGC Agreement.
      </li>
      <li>
        Your User-Generated Content is deemed non-confidential and we have no obligation to maintain the confidentiality of any information contained in Your User-Generated Content, except pursuant to the Operation 36 Privacy Policy, which you agree to observe in the creation of Your User-Generated Content.
      </li>
      <li>
        We reserve the right not to use or, if used, to remove any of Your User-Generated Content without notice.
      </li>
      <li>
        You shall indemnify, defend and hold us, and our directors, officers, employees, agents and affiliates, harmless from and against any and all third-party claims, liabilities, damages, losses, costs, expenses, fees (including reasonable attorneys' fees) arising out of or connected with any of the guarantees, representations, covenants or agreements made by you in this UGC Agreement, and you agree to reimburse us on demand for any payment made by us at any time after the date hereof for any liability or claim to which the foregoing indemnity applies.
      </li>
    </ol>
    <br/>
    <li class='bold'>
      Existing Terms and Provisions
    </li>
  </ol>
  <p>
    Besides being subject to the provisions of the OP-36 Golf Professionals End User License Agreement, this UGC Agreement is also made subject to the terms and provisions of the Operation 36 Terms of Use and OP 36 Privacy Policy.
  </p>
`
};

export default consent;
