import React from "react";
import Modal from "react-modal";
import PropTypes from 'prop-types'

// CHILDREN
import AdminUserForm from "../Forms/AdminUserForm";
import Loader from "../../common/Op36Loader-web";

const AdminUserEditModal = ({
  showModal,
  closeModal,
  formState,
  updateUser,
  deleteUser,
  resetPassword,
  loader,
  updateLoader,
  deleteLoader,
  resetPasswordLoader,
  user,
  error
}) => (
  <Modal
    id='UserEditModal'
    isOpen={showModal}
    onRequestClose={closeModal}
    contentLabel='Edit User Modal'
  >
    <div className='modal-header row m-0 p-0'>
      <div className='col-11 m-0 p-0'>
        <h5 className='modal-title display-4'>
          {loader
            ? "Loading User..."
            : `${user ? `Edit ${user.first_name} ${user.last_name}` : "User"}`}
        </h5>
      </div>
      <div className='col-1 m-0 p-0'>
        <button
          type='button'
          className='close pull-right font-200'
          data-dismiss='modal'
          aria-label='Close'
          onClick={
            loader || updateLoader || resetPasswordLoader || deleteLoader
              ? e => e.preventDefault()
              : closeModal
          }
        >
          <span aria-hidden='true'>✕</span>
        </button>
      </div>
    </div>
    <div className='modal-content'>
      <div className='modal-body' id='input-border'>
        <div className='row mb-2 p-2'>
          <div className='col-md-6 mx-auto'>
            {loader && !updateLoader ? (
              <Loader message='loading user' />
            ) : (
              <AdminUserForm
                formState={formState}
                updateUser={updateUser}
                deleteUser={deleteUser}
                resetPassword={resetPassword}
                closeModal={closeModal}
                user={user}
                error={error}
                loader={loader}
                updateLoader={updateLoader}
                deleteLoader={deleteLoader}
                resetPasswordLoader={resetPasswordLoader}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  </Modal>
);

AdminUserEditModal.propTypes = {
  showModal: PropTypes.bool,
  closeModal: PropTypes.func,
  formState: PropTypes.object,
  updateUser: PropTypes.func,
  deleteUser: PropTypes.func,
  resetPassword: PropTypes.func,
  loader: PropTypes.bool,
  updateLoader: PropTypes.bool,
  deleteLoader: PropTypes.bool,
  resetPasswordLoader: PropTypes.bool,
  user: PropTypes.object,
  error: PropTypes.object,
}

export default AdminUserEditModal;
