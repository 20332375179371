import React from "react";

import train_icons from "../../../constants/train-icons.js";

class EditSkillsModal extends React.Component {
  state = {
    newSkills: []
  };

  componentDidMount() {
    const { current } = this.props;
    this.setState({ newSkills: current });
  }

  render() {
    const { loader, allSkills, applyChanges } = this.props;
    const { newSkills } = this.state;
    return (
      <div className='new-modal'>
        <h1>Edit skills</h1>
        <div className='subtitle-bar'>
          <p>
            Select skill(s) trained <span>*</span>
          </p>
          <p className='small-grey-text'>
            {newSkills.length} skill
            {newSkills.length === 1 ? "" : "s"} selected
          </p>
        </div>
        {loader ? (
          <div className='centered-container'>
            <i className='fa fa-spinner fa-spin' aria-hidden='true' />
          </div>
        ) : (
          <div className='even-container'>
            {allSkills.map((p, idx) => {
              const selected = newSkills.includes(p.id);
              const selectedClass = selected ? " skill-icon-selected" : "";
              const selectedImg = selected
                ? p.photo && p.photo.file_url
                : train_icons[idx];
              return (
                <div className='icon-container' key={p.id}>
                  <img
                    src={selectedImg}
                    alt={p.name + " icon"}
                    className={"skill-icon" + selectedClass}
                    onClick={
                      selected
                        ? () => this._removeSkill(p.id)
                        : () => this._addSkill(p.id)
                    }
                  />
                  <p>{p.name}</p>
                </div>
              );
            })}
          </div>
        )}
        <button
          className='btn-rectangular btn-rectangular-main-positive'
          disabled={this.state.newSkills.length < 1}
          onClick={() => applyChanges(newSkills)}
        >
          Apply
        </button>
      </div>
    );
  }

  _addSkill = id => {
    const { newSkills } = this.state;
    this.setState({ newSkills: [...newSkills, id] });
  };

  _removeSkill = id => {
    const { newSkills } = this.state;
    this.setState({ newSkills: newSkills.filter(s => s !== id) });
  };
}

export default EditSkillsModal;
