import React from "react";

import "./ConfigPageItem.scss";

const configPageItem = ({
  screenName,
  screenDesc,
  screenTitle,
  checked,
  handleCheck,
  thumbnail
}) => {

  const checkedClass = checked ? ' config-item-checkbox-checked' : '';

  return (
    <div className='config-item'>
      <div
        className={'config-item-checkbox' + checkedClass}
        onClick={() => handleCheck(screenTitle)}>
        {checked ? (
          <i className='fa fa-check' aria-hidden='true' />
        ) : null}
      </div>
      <img src={thumbnail} alt={screenTitle + " thumbnail"} className='config-item-preview-img' />
      <div>
        <h3 className='config-item-label'>{screenName}</h3>
        <p className='config-item-desc'>{screenDesc}</p>
      </div>
    </div>
  );
};

export default configPageItem;
