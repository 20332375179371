import React, { Component } from "react";
import PropTypes from 'prop-types'

// ASSETS
import "./Pagination.scss";

const PAGE_BREAK = 9 // the maximum number of pages that can be navigated at a certain point
const CURRENT_PAGE = Math.floor(PAGE_BREAK / 2) + 1 // the current selection should always be in the middle of the pages displyed

class Pagination extends Component {
  state = {
    activeStep: 1
  };

  componentDidMount() {
    if (this.props.activePage) {
      this.setState({ activeStep: this.props.activePage });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.activePage) {
      if (
        this.props.fetchData !== prevProps.fetchData ||
        prevProps.pageCount !== this.props.pageCount
      ) {
        this.setState({ activeStep: this.props.activePage });
      }
    }
  }

  render() {
    const { activeStep } = this.state;
    const { pageCount } = this.props;

    return (
      <div id='Pagination' className='animated fadeIn'>
        {activeStep > 1 && (
          <div className='paginated-arrow-step animation-shrink animated fadeIn'>
            <button onClick={() => this._toggleStepNavigation("previous")}>
              <i className='fa fa-angle-left' />
            </button>
          </div>
        )}
        {this._renderPaginatedSteps()}
        {activeStep !== pageCount && (
          <div className='paginated-arrow-step animation-shrink animated fadeIn'>
            <button onClick={() => this._toggleStepNavigation("next")}>
              <i className='fa fa-angle-right' />
            </button>
          </div>
        )}
      </div>
    );
  }

  _toggleStepNavigation = type => {
    const { fetchData } = this.props;

    if (type === "previous") {
      this.setState(
        {
          activeStep: this.state.activeStep - 1
        },
        () => {
          fetchData(this.state.activeStep);
        }
      );
    } else {
      this.setState(
        {
          activeStep: this.state.activeStep + 1
        },
        () => {
          fetchData(this.state.activeStep);
        }
      );
    }
  };

  _toggleActiveStep = activeStep => {
    const { fetchData } = this.props;
    this.setState(
      {
        activeStep
      },
      () => {
        fetchData(activeStep);
      }
    );
  };

  _renderPaginatedSteps = () => {
    const { pageCount } = this.props
    const { activeStep } = this.state
    const steps = []

    const [start, end] = this._getStepIndexes(pageCount, activeStep)

    for (let i = start; i < end; i++) {
      steps.push(this._step(i))
    }

    return steps
  };

  /**
   * Computes boundaries for the pagination steps
   * @param pageCount - total number of pages
   * @param activeStep - current step
   */
  _getStepIndexes = (pageCount, activeStep) => {
    // always start at the first page if the number of total pages is less than the number of pages that we need to display
    const startCond = pageCount > PAGE_BREAK && activeStep >= CURRENT_PAGE
    // keep the current selection if the number of remaining pages from the total pages equals the second half from the displayed pages
    const startToMiddleCond = pageCount - activeStep < CURRENT_PAGE ? pageCount - PAGE_BREAK : activeStep - CURRENT_PAGE
    const start = startCond ? startToMiddleCond : 0

    // always end at the number of total pages if the number of pages displayed is bigger than the total
    const endCond = pageCount > PAGE_BREAK && pageCount - activeStep >= CURRENT_PAGE
    // keep the current selection as before
    const endToMiddleCond = activeStep > CURRENT_PAGE ? activeStep + CURRENT_PAGE - 1 : PAGE_BREAK
    const end = endCond ? endToMiddleCond : pageCount

    return [start, end]
  }

  _step = index => {
    const { activeStep } = this.state;
    return (
      <div
        className={`paginated-step animation-shrink ${
          activeStep === index + 1 ? "active-step" : ""
        }`}
        key={index}
      >
        <button
          onClick={() => this._toggleActiveStep(index + 1)}
          disabled={activeStep === index + 1}
        >
          {index + 1}
        </button>
      </div>
    );
  };
}

Pagination.propTypes = {
  pageCount: PropTypes.number,
}

export default Pagination;
