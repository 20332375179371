
import { displayWithCurrency } from '../../../../utility/currency'
import colors from '../../../../assets/styles/globals.scss'
import TooltipText from '../../../common/TooltipText'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

const Option = ({ data, innerProps }) => {
  const currency = useSelector(state => state.user.current_user.communities[0].currency)

  if (data.id === null) {
    return (
      <div {...innerProps} key={data.id} className='d-flex align-items-center package-option'>
        <p style={{ marginBottom: '0', color: colors.placeholderGray }}>{data.name}</p>
      </div>
    )
  }
  return (
    <div {...innerProps} key={data.id} className='d-flex align-items-center package-option'>
      <TooltipText text={data.name} noEllipsis/>
      <p className='price-label'>({ displayWithCurrency(data.price, currency, true) })</p>
    </div>
  )
}

Option.propTypes = {
  data: PropTypes.object,
  innerProps: PropTypes.object,
  currency: PropTypes.string,
}

export default Option
