export function objectToFormData(obj, form = new FormData(), namespace = '') {
  for (const [property, value] of Object.entries(obj)) {
    const formKey = namespace ? `${namespace}[${property}]` : property

    if (value === null) {
      continue
    }

    if (typeof value === 'object' && !(value instanceof File || value instanceof Date)) {
      objectToFormData(value, form, formKey)
    } else {
      form.append(formKey, value)
    }
  }

  return form
}
