import React from 'react'
import PropTypes from 'prop-types'

import EducationProgressBar from './EducationProgressBar'

import './EducationsCard.scss'

class EducationsCard extends React.Component {
  state = {
    hovering: false,
  }
  render() {
    const { education, isAdmin, navigateTo, isMainArea } = this.props
    const { hovering } = this.state
    return (
      <div
        id='EducationsCard'
        onMouseEnter={this._startHover}
        onMouseOver={this.state.hovering ? (() => {}) : this._startHover}
        onFocus={this.state.hovering ? (() => {}) : this._startHover}
        onMouseLeave={this._endHover}
        aria-hidden='true'
        onClick={this._handleCardClick}
        style={isMainArea ? { flex: '0 0 280px' } : {}}
      >
        {hovering && (
          <div className='grey-out'>
            {isAdmin ? (
              <i
                className={'fa fa-pencil-square-o'}
                aria-hidden='true'
                onClick={() => navigateTo(education, {})}
              />
            ) : null}
            <button className='btn btn-outline-light'>View Course</button>
          </div>
        )}
        <div
          className='card-header-photo'
          style={
            education.photo
              ? { backgroundImage: `url(${education.photo.file_url})` }
              : education.template_photo_url
                ? { backgroundImage: `url(${education.template_photo_url})` }
                : { backgroundColor: '#ff6633' }
          }
        >
          {!education.photo && !education.template_photo_url && (
            <i className='fa fa-graduation-cap' aria-hidden='true' />
          )}
        </div>
        <div className='lp-card-title-bar'>
          <p className='lp-card-title'>{education.name}</p>
        </div>
        {education.user_education && (
          <EducationProgressBar
            education={education}
            userEducation={education.user_education}
            isCard
          />
        )}
        <div className='lp-card-notes-section'>
          <p className='lp-card-notes'>
            {education.description
              ? this._renderDescription(education.description)
              : '(no notes)'}
          </p>
        </div>
        <div className='lp-card-footer'>
          <div className='lp-card-session-box'>
            {this._grabSectionCount(education.education_sections)}
          </div>
          <div className='lp-card-tag-box'>
            {education.is_open ? (
              <i className='fa fa-check-circle-o' aria-hidden='true' />
            ) : (
              <p className='lp-card-tag-count'>
                {this._formatPrice(education.price)}
              </p>
            )}
          </div>
        </div>
      </div>
    )
  }

  _renderDescription = desc => {
    const wordArr = desc.split(' ')
    if (wordArr.length <= 25) {
      return desc
    }
    const first25 = wordArr.slice(0, 25)
    first25.push('...')
    return first25.join(' ')
  }

  _handleCardClick = () => {
    const { navigateTo, showPreview, education } = this.props
    if (education.is_open && education.user_education) {
      return navigateTo(education, education.user_education)
    } else {
      return showPreview(education)
    }
  }

  _formatPrice = price => {
    if (price === 0) { return 'FREE' }
    return '$' + parseFloat(price).toFixed(2)
  }

  _grabSectionCount = sections => {
    const sectionCount = sections ? sections.length : 0
    const lessonCount = sectionCount > 0 ? this._countLessons(sections) : 0

    return (
      <p className='session-count-text'>
        <span>{sectionCount}</span>{' '}
        {`section${sectionCount !== 1 ? 's' : ''} | `}
        <span>{lessonCount}</span> {`lesson${lessonCount !== 1 ? 's' : ''}`}
      </p>
    )
  }

  _countLessons = sections => {
    let count = 0
    sections.forEach(sec => {
      if (sec.education_lessons && sec.education_lessons.length > 0) {
        count += sec.education_lessons.length
      }
    })
    return count
  }

  _startHover = () => {
    this.setState({ hovering: true })
  }

  _endHover = () => {
    this.setState({ hovering: false, popoverOpen: false })
  }
}

EducationsCard.propTypes = {
  education: PropTypes.shape({
    education_sections: PropTypes.array,
    is_open: PropTypes.bool,
    name: PropTypes.string,
    description: PropTypes.string,
    price: PropTypes.number,
    photo: PropTypes.shape({
      file_url: PropTypes.string,
      placeholder: PropTypes.string,
    }),
    template_photo_url: PropTypes.string,
    user_education: PropTypes.object,
  }),
  isAdmin: PropTypes.bool,
  isMainArea: PropTypes.bool,
  navigateTo: PropTypes.func,
  showPreview: PropTypes.func,
}


export default EducationsCard
