import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as adminTagActions from "../../actions/adminTagActions";

import "./AdminTagIndex.scss";

class AdminTagIndex extends React.Component {
  state = {
    tagLoader: true,
    editingTag: null,
    newTag: null,
    rowLoader: null,
    confirmDelete: null,
  };

  componentDidMount() {
    const { adminTags } = this.props;
    if (adminTags.length > 0) {
      this.setState({ tagLoader: false });
    } else {
      this.props.admin_tag_actions.getAllTags().then(() => {
        this.setState({ tagLoader: false });
      });
    }
  }

  render() {
    const { tagLoader, editingTag, newTag, rowLoader } = this.state;
    const { adminTags } = this.props;
    if (tagLoader) {
      return (
        <div className='d-flex align-items-center justify-content-center p-5'>
          <i className='fa fa-spinner fa-spin' aria-hidden='true' />
        </div>
      );
    }
    return (
      <div id='AdminTagIndex'>
        <p
          style={{
            color: "lightblue",
            cursor: "pointer",
            fontSize: "0.85rem",
            marginBottom: 6,
          }}
          onClick={this._startNewTag}
        >
          -Add Tag-
        </p>
        <table className='w-100'>
          <thead>
            <tr style={{ fontWeight: "bold" }}>
              <td>Name</td>
              <td>Category</td>
              <td style={{ textAlign: "center" }}>Edit</td>
              <td style={{ textAlign: "center" }}>Delete</td>
            </tr>
          </thead>
          <tbody>
            {newTag &&
              (rowLoader === "new" ? (
                <tr>
                  <td colSpan='4' style={{ textAlign: "center" }}>
                    <i className='fa fa-spinner fa-spin' aria-hidden='true' />
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>
                    <input
                      value={newTag.name}
                      onChange={this._handleNewTagName}
                    />
                  </td>
                  <td>
                    <input
                      value={newTag.category}
                      onChange={this._handleNewTagCategory}
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <div>
                      <i
                        className='fa fa-check-circle mr-1'
                        aria-hidden='true'
                        onClick={this._createNewTag}
                      />
                      <i
                        className='fa fa-ban'
                        aria-hidden='true'
                        onClick={this._cancelNew}
                      />
                    </div>
                  </td>
                  <td style={{ textAlign: "center" }}>-</td>
                </tr>
              ))}
            {adminTags.map((tag) => {
              const isEditable =
                this.state.editingTag && this.state.editingTag.id === tag.id;
              const isMarkedToDelete = this.state.confirmDelete === tag.id;

              if (rowLoader === tag.id) {
                return (
                  <tr key={tag.id}>
                    <td colSpan='4' style={{ textAlign: "center" }}>
                      <i className='fa fa-spinner fa-spin' aria-hidden='true' />
                    </td>
                  </tr>
                );
              }
              return (
                <tr
                  key={tag.id}
                  style={isMarkedToDelete ? { background: "#f4f4f5" } : {}}
                >
                  <td>
                    {isEditable ? (
                      <input
                        value={editingTag.name}
                        onChange={this._handleEditTagName}
                      />
                    ) : (
                      tag.name
                    )}
                  </td>
                  <td>
                    {isEditable ? (
                      <input
                        value={editingTag.category}
                        onChange={this._handleEditTagCategory}
                      />
                    ) : (
                      tag.category
                    )}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {isEditable ? (
                      <div>
                        <i
                          className='fa fa-check-circle mr-1'
                          aria-hidden='true'
                          onClick={this._editTag}
                        />
                        <i
                          className='fa fa-ban'
                          aria-hidden='true'
                          onClick={this._cancelEdit}
                        />
                      </div>
                    ) : (
                      <i
                        className='fa fa-pencil-square'
                        aria-hidden='true'
                        onClick={() =>
                          this._makeEditable(tag.id, tag.name, tag.category)
                        }
                      />
                    )}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {isMarkedToDelete ? (
                      <div>
                        <p
                          style={{
                            marginBottom: 0,
                            fontSize: "0.65rem",
                            color: "red",
                            textAlign: "center",
                          }}
                        >
                          You sure?
                        </p>
                        <i
                          className='fa fa-check-circle mr-1'
                          aria-hidden='true'
                          onClick={this._confirmDelete}
                        />
                        <i
                          className='fa fa-ban'
                          aria-hidden='true'
                          onClick={this._cancelDelete}
                        />
                      </div>
                    ) : (
                      <i
                        className='fa fa-times-circle'
                        aria-hidden='true'
                        onClick={() => this._triggerDeleteConfirm(tag.id)}
                      />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  _createNewTag = () => {
    const { newTag } = this.state;
    const name = newTag.name.trim();
    const category = newTag.category.trim();
    if (!name || !category) {
      return false;
    }
    this.setState({ rowLoader: "new" }, () => {
      this.props.admin_tag_actions.createNewTag({ name, category }).then(() => {
        this.setState({ rowLoader: null, newTag: null });
      });
    });
  };

  _editTag = () => {
    const { editingTag } = this.state;
    const name = editingTag.name.trim();
    const category = editingTag.category.trim();
    if (!name || !category) {
      return false;
    }
    this.setState({ rowLoader: editingTag.id }, () => {
      this.props.admin_tag_actions
        .editTag(editingTag.id, { name, category })
        .then(() => {
          this.setState({ rowLoader: null, editingTag: null });
        });
    });
  };

  _confirmDelete = () => {
    const { confirmDelete, editingTag } = this.state;
    this.setState({ rowLoader: confirmDelete }, () => {
      this.props.admin_tag_actions.destroyTag(confirmDelete).then(() => {
        let shouldCloseEdit = editingTag && confirmDelete === editingTag.id;
        this.setState({
          rowLoader: null,
          confirmDelete: null,
          ...(shouldCloseEdit && { editTag: null }),
        });
      });
    });
  };

  _triggerDeleteConfirm = (tagId) => {
    const { editingTag } = this.state;
    let shouldCloseEdit = editingTag && editingTag.id !== tagId;
    this.setState({
      confirmDelete: tagId,
      ...(shouldCloseEdit && { editingTag: null }),
    });
  };

  _cancelDelete = () => {
    this.setState({ confirmDelete: null });
  };

  _makeEditable = (tagId, tagName, tagCategory) => {
    this.setState({
      editingTag: { id: tagId, name: tagName, category: tagCategory },
      newTag: null,
      confirmDelete: null,
    });
  };

  _startNewTag = () => {
    const { newTag } = this.state;
    if (newTag) return false;
    this.setState({
      newTag: { name: "", category: "" },
      editingTag: null,
      confirmDelete: null,
    });
  };

  _cancelEdit = () => {
    this.setState({ editingTag: null });
  };

  _cancelNew = () => {
    this.setState({ newTag: null });
  };

  _handleEditTagName = (e) => {
    const val = e.target.value;
    const editingTagCopy = { ...this.state.editingTag };
    editingTagCopy.name = val;
    this.setState({ editingTag: editingTagCopy });
  };

  _handleNewTagName = (e) => {
    const val = e.target.value;
    const newTagCopy = { ...this.state.newTag };
    newTagCopy.name = val;
    this.setState({ newTag: newTagCopy });
  };

  _handleEditTagCategory = (e) => {
    const val = e.target.value;
    const editingTagCopy = { ...this.state.editingTag };
    editingTagCopy.category = val;
    this.setState({ editingTag: editingTagCopy });
  };

  _handleNewTagCategory = (e) => {
    const val = e.target.value;
    const newTagCopy = { ...this.state.newTag };
    newTagCopy.category = val;
    this.setState({ newTag: newTagCopy });
  };
}

function mapStateToProps(state, ownProps) {
  return {
    adminTags: state.adminTags.admin_tags,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    admin_tag_actions: bindActionCreators(adminTagActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminTagIndex);
