import { useDispatch, useSelector } from 'react-redux'

import { checkLicensePermission } from '../../../../utility/communityLicense'
import { addPackage, deletePackage } from '../../../../reducers/programBuilderReducer'

import './Pricing.scss'
import PackageSelect from './PackageSelect'
import PaymentMethodSelect from './PaymentMethodSelect'
import MaterialSelect from './MaterialSelect'

const Pricing = () => {
  const dispatch = useDispatch()

  const current_user = useSelector(state => state.user.current_user)
  const licenseType = current_user.communities[0].license_type
  const communityPackages = useSelector(state => state.programBuilder.communityPackages)
  const communityMaterials = useSelector(state => state.programBuilder.communityMaterials)
  const packages = useSelector(state => state.programBuilder.packages)
  const errors = useSelector(state => state.programBuilder.errors)

  const handleAddPackage = (pkg) => {
    if (pkg.id > 0) {
      dispatch(addPackage(pkg))
    }
  }

  const handleDeletePackage = (pkg) => {
    dispatch(deletePackage(pkg))
  }

  return (
    <section id='Pricing'>
      <p className='title'>Pricing & Packages</p>
      <p>
        Select your desired payment method and package, if you want to opt for online payment you must also select a package.
      </p>
      <div className='row'>
        <div className='col-12 mb-3'>
          <label htmlFor='package' className='mb-3'>
            Pricing Packages for Sign-up {checkLicensePermission(licenseType, ['revenue_share']) && (
              <span className='required-text'>*</span>
            )}
          </label>

          <PackageSelect
            communityPackages={communityPackages}
            packages={packages}
            errors={errors}
            onAddPackage={handleAddPackage}
            onDeletePackage={handleDeletePackage}
          />
        </div>
        {checkLicensePermission(licenseType, ['standard', 'standard_legacy']) && packages.length > 0 && (
          <div className='col-12'>
            <label htmlFor='paymentMethod' className='mb-3'>
              Payment method <span className='required-text'>*</span>
            </label>

            <PaymentMethodSelect />
          </div>
        )}
        {communityMaterials.length > 0 && (
          <div className='col-12 mt-3 mb-3'>
            <p className='title'>Program Materials</p>
            <p>
              The section allows you to include required program materials. It will ask participants during registration if they need it. If they do,
              participants will be prompted after registration with directions on how to order these. We will ship the orders directly to your
              participants.
            </p>
            <MaterialSelect />
          </div>
        )}
      </div>
    </section>
  )
}

export default Pricing
