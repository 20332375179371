import PropTypes from 'prop-types'
import moment from 'moment'

import { useSelector, useDispatch } from 'react-redux'
import { setField } from '../../../reducers/nineHoleEventReducer'

import Input from '../../common/form/Input'
import Toggle from '../../common/Toggle'
import SimplePhotoUploader from '../../common/SimplePhotoUploader'
import { DATE_FORMAT, getMomentInCommunityTimezone, getMomentInCommunityTimezoneReversed } from '../../../utility/datesHelper'

import './PostScores.scss'
import { useEffect } from 'react'

const PostScores = ({ status }) => {
  const dispatch = useDispatch()

  const { event_date, location, description, uploadPhotoSrc, name, isEmail, errors } = useSelector(state => state.nineHoleEvent)
  const community = useSelector(state => state.user.current_user.communities[0])
  const timezone = community.timezone

  const handleInputChange = (e) => {
    const payload = { field: e.target.name, value: e.target.value }
    dispatch(setField(payload))
  }

  const handleDateChange = (date) => {
    if (!date) {
      return
    }

    const timezoneDate = getMomentInCommunityTimezone(date, timezone, 'YYYY-MM-DD').toISOString()
    const payload = { field: 'event_date', value: moment(timezoneDate) }
    dispatch(setField(payload))
  }

  const handleEmailChange = (e) => {
    const payload = { field: 'isEmail', value: e.target.checked }
    dispatch(setField(payload))
  }

  const sendPhotoData = (photo) => {
    const { uploadPhotoFile, uploadPhotoName, uploadPhotoSrc } = photo
    let payload = { field: 'uploadPhotoFile', value: uploadPhotoFile }
    dispatch(setField(payload))
    payload = { field: 'uploadPhotoName', value: uploadPhotoName }
    dispatch(setField(payload))
    payload = { field: 'uploadPhotoSrc', value: uploadPhotoSrc }
    dispatch(setField(payload))
  }

  useEffect(() => {
    dispatch(setField({ field: 'location', value: community.name }))
  }, [dispatch, community])

  const eventDateInTimezone = event_date ? getMomentInCommunityTimezoneReversed(moment(event_date), timezone, DATE_FORMAT) : ''

  return (
    <div id='PostScores'>
      <div className='row mx-0'>
        <div className='col-md-5 mx-auto form-container'>
          <div className='form-group'>
            <label htmlFor='featured-image'>Add Event Photo</label>
            <SimplePhotoUploader
              className='form-control photo-select'
              sendPhotoData={sendPhotoData}
              photo={uploadPhotoSrc}
              placeholder='Add header photo (optional)'
            />
          </div>
          <div className='form-group'>
            <label htmlFor='name'>
                Event Title <span className='required-text'>*</span>
            </label>
            <Input
              type='text'
              name='name'
              id='name'
              placeholder='Example: Fall Junior Series'
              onChange={handleInputChange}
              value={name}
              error={errors.name}
            />
          </div>
          <div className='form-group'>
            <label htmlFor='date'>
                Event Date <span className='required-text'>*</span>
            </label>
            <Input
              type='date'
              name='date'
              onChange={handleDateChange}
              placeholder='MM/DD/YYYY'
              value={eventDateInTimezone}
              error={errors.event_date}
              symbol={<i className='fa fa-calendar'/>}
              inputProps={{ readOnly: true }}
            />
          </div>
          <div className='form-group'>
            <label htmlFor='location'>
                Location <span className='required-text'>*</span>
            </label>
            <Input
              type='text'
              name='location'
              id='location'
              placeholder='Your Golf Facility'
              onChange={handleInputChange}
              value={location}
              error={errors.location}
            />
          </div>
          <div className='form-group'>
            <label htmlFor='description'>Description</label>
            <Input
              type='textarea'
              name='description'
              id='description'
              classNames={{ inputContainer: 'description-input' }}
              placeholder='Write a Description'
              onChange={handleInputChange}
              value={description}
            />
          </div>
          {status !== 'completed' && (
            <div className='form-group d-flex flex-column'>
              <label className='ml-0' htmlFor='email'>
                  Send Leaderboard via Email?
              </label>
              <Toggle checked={isEmail} onChange={handleEmailChange} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

PostScores.propTypes = {
  status: PropTypes.string,
}

export default PostScores
