import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet, useSearchParams } from 'react-router-dom'
import Loader from '../common/Op36Loader-web'
import * as authActions from '../../actions/authActions'

const useEmbeddableSearchParams = () => {
  const [searchParams] = useSearchParams()

  return { embed: searchParams.get('embed'), token: searchParams.get('token') }
}

const EmbeddableWrapper = () => {
  const dispatch = useDispatch()
  const { embed, token } = useEmbeddableSearchParams()
  const [loading, setLoading] = useState(!!token)

  useEffect(() => {
    const fetchData = async () => {
      if (embed) {
        localStorage.setItem('embed', true)
      }

      if (token) {
        await dispatch(authActions.tokenAuth(token))
      }
      setLoading(false)
    }

    fetchData()
  }, [dispatch, embed, token])

  return (
    <>
      { loading ? (
        <Loader message='Authenticating...'/>
      ) : (
        <Outlet />
      )}
    </>
  )
}

export default EmbeddableWrapper
