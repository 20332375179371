import React, { Component } from "react";
import moment from "moment";
import Error from "../../../common/ErrorMessage";
import { displayYearOptions } from "../../../../utility/helperFunctions";
import _ from "lodash";
import "./CreateGolferForm.scss";

class CreateGolferForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      first_name: props.golfer ? props.golfer.first_name : "",
      last_name: props.golfer ? props.golfer.last_name : "",
      community_id: props.community && props.community.id,
      email: props.golfer ? props.golfer.email : "",
      username: props.golfer ? props.golfer.username : "",
      gender: props.golfer ? props.golfer.gender : "",
      birth_day: props.golfer ? this.setDate("day") : "",
      birth_month: props.golfer ? this.setDate("month") : "",
      birth_year: props.golfer ? this.setDate("year") : "",
      birthdate: props.golfer ? props.golfer.birthdate : "",
      type: "Student",
      password: "",
      confirm_password: "",
      family_admin: props.golfer ? props.golfer.family_admin : false,
      family_id: props.family && props.family.id,
      id: props.golfer ? props.golfer.id : -1,
      formError: {},
      emptyError: "",
      nextStep: false,
      familyAdminBirthdateError: false,
      requiresProxyConsent: false,
      proxyConsentGiven: false,
    };
  }

  setDate = (type) => {
    const { golfer } = this.props;
    if (!_.isEmpty(golfer)) {
      let date = moment(golfer.birthdate, "YYYY/MM/DD");
      switch (type) {
        case "day":
          return date.format("D");
        case "month":
          return date.format("M");
        case "year":
          return date.format("YYYY");
        default:
          return null;
      }
    }
    return null;
  };

  checkUpdateValidation = () => {
    const { golfer } = this.props;
    const {
      first_name,
      last_name,
      username,
      email,
      birth_day,
      birth_month,
      birth_year,
    } = this.state;

    if (!_.isEmpty(golfer) && golfer.family_admin) {
      if (
        first_name !== "" &&
        last_name !== "" &&
        email !== "" &&
        birth_day !== "" &&
        birth_month !== "" &&
        birth_year !== ""
      ) {
        return false;
      }
      return true;
    } else if (!_.isEmpty(golfer) && !golfer.family_admin) {
      if (
        first_name !== "" &&
        last_name !== "" &&
        username !== "" &&
        birth_day !== "" &&
        birth_month !== "" &&
        birth_year !== ""
      ) {
        return false;
      }
      return true;
    } else {
      return true;
    }
  };

  checkValidation = () => {
    const {
      first_name,
      last_name,
      username,
      birth_day,
      birth_month,
      birth_year,
      password,
      confirm_password,
      gender,
    } = this.state;

    if (
      first_name !== "" &&
      last_name !== "" &&
      username !== "" &&
      birth_day !== "" &&
      birth_month !== "" &&
      birth_year !== "" &&
      gender !== "" &&
      password === confirm_password
    ) {
      return false;
    }
    return true;
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleCheck = (event) => {
    this.setState({
      [event.target.name]: event.target.checked,
    });
  };

  onChangeBirthday = (event) => {
    switch (event.target.name) {
      case "day":
        this.setState(
          { birth_day: event.target.value },
          this._checkBirthdateValidation
        );
        break;
      case "month":
        this.setState(
          { birth_month: event.target.value },
          this._checkBirthdateValidation
        );
        break;
      case "year":
        this.setState(
          { birth_year: event.target.value },
          this._checkBirthdateValidation
        );
        break;
      default:
        return;
    }
  };

  _checkBirthdateValidation = () => {
    const { birth_day, birth_month, birth_year, family_admin } = this.state;
    if (!birth_day || !birth_month || !birth_year) return;
    let birthdate = moment()
      .date(birth_day)
      .month(birth_month - 1)
      .year(birth_year)
      .toDate();
    let isUnder13 = moment().diff(birthdate, "years", false) < 13;
    if (isUnder13 && family_admin) {
      this.setState({ familyAdminBirthdateError: true });
    } else if (family_admin && !isUnder13) {
      this.setState({ familyAdminBirthdateError: false });
    } else if (isUnder13 && !family_admin) {
      this.setState({ requiresProxyConsent: true, proxyConsentGiven: false });
    } else if (!isUnder13 && !family_admin) {
      this.setState({ requiresProxyConsent: false, proxyConsentGiven: false });
    }
  };

  checkInput = (event, type) => {
    const { password, confirm_password } =
      this.state;
    if (
      !event.target.value ||
      event.target.value === "-1" ||
      event.target.value === "unselected"
    ) {
      this.setState({
        formError: { [type]: this.customErrorMessage(type) },
      });
    } else {
      this.setState({
        formError: { [type]: null },
      });
    }

    if (
      password !== "" &&
      confirm_password !== "" &&
      password !== confirm_password
    ) {
      this.setState({
        formError: {
          [type]: "Your passwords do not match",
        },
      });
    }
  };

  customErrorMessage = (type) => {
    switch (type) {
      case "first_name":
        return "Please enter a first name";
      case "last_name":
        return "Please enter a last name";
      case "email":
        return "Please enter an email";
      case "birthday":
        return "Please fill out all birthday fields";
      case "gender":
        return "Please choose a gender";
      case "password":
        return "Please enter a password";
      case "confirm_password":
        return "Please confirm your password";
      case "username":
        return "Please enter a username";
      default:
        return "";
    }
  };

  updateGolfer = (event) => {
    const { updateGolfer } = this.props;
    const { birth_day, birth_month, birth_year } = this.state;
    event.preventDefault()

    if (this.checkValidation()) {
      this.setState({
        emptyError:
          "Please fill out all of the fields correctly before continuing",
      });
    } else {
      let birthdate = moment()
        .date(birth_day)
        .month(birth_month - 1)
        .year(birth_year)
        .toDate();

      this.setState(
        {
          birthdate,
        },
        () => {
          updateGolfer(this.state);
        }
      );
    }
  };

  createGolfer = (event) => {
    const { createGolfer } = this.props;
    const { birth_day, birth_month, birth_year } = this.state;
    event.preventDefault()

    if (this.checkValidation()) {

      this.setState({
        emptyError:
          "Please fill out all of the fields correctly before continuing",
      });
    } else {
      let birthdate = moment()
        .date(birth_day)
        .month(birth_month - 1)
        .year(birth_year)
        .toDate();

      this.setState(
        {
          birthdate,
        },
        () => {
          createGolfer(this.state);
        }
      );
    }
  };

  birthdateIssues = () => {
    const {
      familyAdminBirthdateError,
      requiresProxyConsent,
      proxyConsentGiven,
    } = this.state;
    if (familyAdminBirthdateError) {
      return true;
    }
    if (requiresProxyConsent && !proxyConsentGiven) {
      return true;
    }
    return false;
  };

  render() {
    const {
      first_name,
      last_name,
      username,
      email,
      gender,
      birth_day,
      birth_month,
      birth_year,
      password,
      confirm_password,
      formError,
      emptyError,
      familyAdminBirthdateError,
      requiresProxyConsent,
      proxyConsentGiven,
    } = this.state;
    const { toggleGolferForm, shouldUpdateGolfer, golfer, loader, error } =
      this.props;
    return (
      <div id='FamilyRegisterForm' className='col-xl-6 col-lg-12 mx-auto'>
        <form>
          <div className='form-row'>
            <label htmlFor='first_name'>First Name</label>
            <input
              type={"text"}
              label={""}
              name={"first_name"}
              value={first_name}
              onChange={this.handleChange}
              placeholder={"First Name"}
              disabled={loader}
              onBlur={(e) => this.checkInput(e, "first_name")}
            />
            {formError && formError.first_name && (
              <Error error={formError.first_name} />
            )}
          </div>
          <div className='form-row'>
            <label htmlFor='last_name'>Last Name</label>
            <input
              type={"text"}
              label={""}
              name={"last_name"}
              value={last_name}
              onChange={this.handleChange}
              placeholder={"Last Name"}
              disabled={loader}
              onBlur={(e) => this.checkInput(e, "last_name")}
            />
            {formError && formError.last_name && (
              <Error error={formError.last_name} />
            )}
          </div>
          {shouldUpdateGolfer && golfer.family_admin ? (
            <div className='form-row'>
              <label htmlFor='email'>Email</label>
              <input
                name={"email"}
                type={"text"}
                label={""}
                value={email}
                onChange={this.handleChange}
                placeholder={"email@email.com"}
                disabled={loader}
                onBlur={(e) => this.checkInput(e, "email")}
                autoComplete="email"
              />
              {formError && formError.email && <Error error={formError.email} />}
              {error && error.email && <Error error={error.email[1]} />}
            </div>
          ) : (
            <div className='form-row'>
              <label htmlFor='username'>Username</label>
              <input
                name={"username"}
                type={"text"}
                label={""}
                value={username}
                onChange={this.handleChange}
                placeholder={"username"}
                disabled={loader}
                onBlur={(e) => this.checkInput(e, "username")}
                autoComplete="username"
              />
              {formError && formError.username && (
                <Error error={formError.username} />
              )}
              {error && error.username && <Error error={error.username[0]} />}
            </div>
          )}

          <div className='form-row'>
            <label>Birthdate</label>
            <div className='row'>
              <div className='col-4'>
                <small>
                  <label>Month</label>
                </small>
                <select
                  onChange={this.onChangeBirthday}
                  name='month'
                  value={birth_month}
                  className='form-control'
                  disabled={loader}
                  onBlur={(e) => this.checkInput(e, "birthday")}
                >
                  <option value='' disabled>
                    Select a month
                  </option>
                  {_.range(1, 13).map(function (x) {
                    return <option key={x}>{x}</option>;
                  })}
                </select>
              </div>

              <div className='col-4'>
                <small>
                  <label>Day</label>
                </small>
                <select
                  onChange={this.onChangeBirthday}
                  name='day'
                  value={birth_day}
                  className='form-control'
                  disabled={loader}
                  onBlur={(e) => this.checkInput(e, "birthday")}
                >
                  <option value='' disabled>
                    Select a day
                  </option>
                  {_.range(1, 32).map((x) => {
                    return <option key={x}>{x}</option>;
                  })}
                </select>
              </div>

              <div className='col-4'>
                <small>
                  <label>Year</label>
                </small>
                <select
                  onChange={this.onChangeBirthday}
                  name='year'
                  value={birth_year}
                  className='form-control'
                  disabled={loader}
                  onBlur={(e) => this.checkInput(e, "birthday")}
                >
                  <option value='' disabled>
                    Select a Year
                  </option>
                  {displayYearOptions()}
                </select>
              </div>
            </div>
            {formError && formError.birthday && (
              <Error error={formError.birthday} />
            )}
          </div>
          <div className='form-row'>
            <label htmlFor='gender'>Gender*</label>
            <select
              onChange={this.handleChange}
              name='gender'
              value={gender}
              className={`form-control ${
                gender === "" && "default-option"
              }`}
              disabled={loader}
              onBlur={(e) => this.checkInput(e, "gender")}
            >
              <option value="" disabled>
                Select a gender
              </option>
              <option value={1}>Male</option>
              <option value={2}>Female</option>
              <option value={3}>Other</option>
              {/* <option value={0}>Prefer not to say</option> */}
            </select>
            {formError && formError.gender && <Error error={formError.gender} />}
          </div>
          {!shouldUpdateGolfer && (
            <div className='form-row'>
              <label htmlFor='password'>Password</label>
              <input
                name={"password"}
                type={"password"}
                label={""}
                value={password}
                onChange={this.handleChange}
                placeholder={"password"}
                disabled={loader}
                onBlur={(e) => this.checkInput(e, "password")}
                autoComplete="new-password"
              />
              {formError && formError.password && (
                <Error error={formError.password} />
              )}
            </div>
          )}
          {!shouldUpdateGolfer && (
            <div className='form-row'>
              <label htmlFor='confirm_password'>Confirm Password</label>
              <input
                name={"confirm_password"}
                type={"password"}
                label={""}
                value={confirm_password}
                onChange={this.handleChange}
                placeholder={"confirm password"}
                disabled={loader}
                onBlur={(e) => this.checkInput(e, "confirm_password")}
                autoComplete="new-password"
              />
              {formError && formError.confirm_password && (
                <Error error={formError.confirm_password} />
              )}
            </div>
          )}
          {emptyError && emptyError && <Error error={emptyError} />}

          {!shouldUpdateGolfer && (
            <p className='required-text'>
              *All fields are required to create an account on the Operation 36
              platform
            </p>
          )}

          {familyAdminBirthdateError && (
            <Error error={"Family admin cannot be less than 13 years old."} />
          )}
          {requiresProxyConsent && (
            <div className='white-box'>
              <input
                type='checkbox'
                checked={proxyConsentGiven}
                onChange={this._handleProxyClick}
              />
              <label className="ml-2">Proxy Consent for this user</label>
            </div>
          )}

          <div className={`row form-btn-wrapper ${shouldUpdateGolfer && "pt-5"}`}>
            <button
              type='cancel'
              className='btn btn-warning'
              onClick={toggleGolferForm}
            >
              Cancel
            </button>
            {!shouldUpdateGolfer ? (
              <button
                type='submit'
                disabled={loader || this.birthdateIssues()}
                className='btn btn-primary'
                onClick={(e) => this.createGolfer(e)}
              >
                {loader && (
                  <i className='fa fa-spinner fa-spin confirmation-spinner' />
                )}
                Creat{loader ? "ing" : "e"} Golfer
              </button>
            ) : (
              <button
                type='submit'
                disabled={loader || this.birthdateIssues()}
                className='btn btn-primary'
                onClick={(e) => this.updateGolfer(e)}
              >
                {loader && (
                  <i className='fa fa-spinner fa-spin confirmation-spinner' />
                )}
                Updat{loader ? "ing" : "e"} Golfer
              </button>
            )}
          </div>
        </form>
      </div>
    );
  }

  _handleProxyClick = (e) => {
    this.setState({ proxyConsentGiven: e.target.checked });
  };
}

export default CreateGolferForm;
