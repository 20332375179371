import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useMemo } from 'react'

import {
  setCourseCategoryError,
  setCourseCategoryField,
  setCourseCategoryHeaderImage,
} from '../../../reducers/community-settings/landingPageSettingsReducer'
import { createCourseCategory, deleteCourseCategory, updateCourseCategory } from '../../../actions/programCategoryActions'
import Modal from '../../common/Modal'
import Input from '../../common/form/Input'
import SubmitButton from '../../common/buttons/SubmitButton'
import SimplePhotoUploader from '../../common/SimplePhotoUploader'
import CancelButton from '../../common/buttons/CancelButton'

import classes from './CommunityLandingPageTab.module.scss'
import classnames from 'classnames'

const customErrorMessage = type => {
  switch (type) {
    case 'name':
      return 'Title cannot be empty.'
    case 'color':
      return 'Color cannot be empty.'
    default:
      return ''
  }
}

const CourseCategoryEditModal = (props) => {
  const dispatch = useDispatch()

  const { courseCategoryFields, courseCategoryErrors, isLoadingEditCourseCategory } = useSelector(state => state.communitySettings.landingPage)

  const handleInputChange = e => {
    const field = e.target.name
    const value = e.target.value

    dispatch(setCourseCategoryField({ field, value }))
  }

  const handlePhotoChange = (photoData) => {
    const { uploadPhotoFile, uploadPhotoSrc } = photoData
    dispatch(setCourseCategoryHeaderImage({
      headerImageUrl: uploadPhotoSrc,
      headerImageData: uploadPhotoFile,
      headerImageName: uploadPhotoFile?.name,
    }))
  }

  const handleDeleteCategory = () => {
    dispatch(deleteCourseCategory(courseCategoryFields.id))
      .unwrap()
      .then(() => props.onClose())
  }

  const validateInput = (value, type) => {
    if (!value.trim()) {
      dispatch(setCourseCategoryError({ type, message: [customErrorMessage(type)] }))
      return false
    } else {
      dispatch(setCourseCategoryError({ type, message: null }))
      return true
    }
  }

  const isValidForm = useMemo(() => {
    for (const key in courseCategoryErrors) {
      if (courseCategoryErrors[key]) {
        return false
      }
    }
    return true
  }, [courseCategoryErrors])

  const validateAllInputs = () => {
    let isValid = true

    const fieldsToValidate = ['name', 'color']

    fieldsToValidate.forEach((type) => {
      isValid = validateInput(courseCategoryFields[type], type) && isValid
    })

    return isValid
  }


  const handleSubmit = (e) => {
    e.preventDefault()

    if (!validateAllInputs()) {
      return
    }


    const courseCategoryData = {
      ...courseCategoryFields,
      communityId: props.communityId,
      order: courseCategoryFields.order !== null ? courseCategoryFields.order : props.courseCategoriesNumber,
    }

    const apiRequestCallback = courseCategoryData.id ? updateCourseCategory : createCourseCategory

    dispatch(apiRequestCallback(courseCategoryData.id ? { id: courseCategoryData.id, courseCategoryData: courseCategoryData } : courseCategoryData))
      .unwrap()
      .then((response) => {
        props.onClose()
        if (props.onSubmitCallback) {
          props.onSubmitCallback(response.courseCategory)
        }
      })
  }

  return (
    <Modal className={classnames(classes['course-category-modal'], { [classes['with-delete-button']]: !!courseCategoryFields?.id })} {...props}>
      <form className='px-3' onSubmit={handleSubmit}>
        <h2 className={classes['title']}>Program Category</h2>
        <div className='pb-2'>
          <label htmlFor='title'>
            Title <span className={classes['required']}>*</span>
          </label>
          <Input
            id='title'
            type='text'
            name='name'
            placeholder='Enter a title for your Program Category'
            value={courseCategoryFields?.name}
            onChange={handleInputChange}
            onBlur={e => validateInput(e.target.value, e.target.name)}
            error={courseCategoryErrors?.name && courseCategoryErrors.name[0]}
          />
        </div>
        <div className='pb-2'>
          <label htmlFor='color-picker'>
            Color <span className={classes['required']}>*</span>
          </label>
          <Input
            type='color'
            id='color'
            name='color'
            placeholder='Choose a color to represent your Program Category'
            value={courseCategoryFields?.color}
            onChange={handleInputChange}
            onBlur={e => validateInput(e.target.value, e.target.name)}
            error={courseCategoryErrors?.color && courseCategoryErrors.color[0]}
          />
        </div>
        <div className='pb-2'>
          <label htmlFor='description'>
            Description
          </label>
          <Input
            type='textarea'
            id='description'
            name='description'
            value={courseCategoryFields?.description}
            onChange={handleInputChange}
            placeholder='This will show up on your marketing Landing Page'
            styles={{ inputContainer: { height: '100px' } }}
          />
        </div>
        <div className='pb-3'>
          <label htmlFor='input-photo-upload'>Header Image</label>
          <SimplePhotoUploader
            second
            className='form-control photo-select'
            sendPhotoData={handlePhotoChange}
            selectedPhoto={courseCategoryFields?.headerImageUrl}
            placeholder='Upload a photo'
            error={courseCategoryErrors?.headerImagePhoto && courseCategoryErrors.headerImagePhoto[0]}
          />
        </div>
        {!!courseCategoryFields?.id && (
          <div className='pb-3'>
            <button
              type='button'
              className={classnames('d-flex align-items-center', classes['delete-button'])}
              onClick={handleDeleteCategory}
            >
              <i className='fa fa-trash mr-1' /> Delete Program Category
            </button>
          </div>
        )}
        <div className='d-flex'>
          <CancelButton className='mt-3 mx-2' onClick={props.onClose} buttonMessage='Cancel' />
          <SubmitButton
            className='mx-2'
            buttonMessage={courseCategoryFields?.id ? 'Save' : 'Create'}
            loading={isLoadingEditCourseCategory}
            disabled={!isValidForm || isLoadingEditCourseCategory}
          />
        </div>
      </form>
    </Modal>
  )
}

CourseCategoryEditModal.propTypes = {
  onClose: PropTypes.func,
  onSubmitCallback: PropTypes.func,
  communityId: PropTypes.number,
  courseCategoriesNumber: PropTypes.number,
}

export default CourseCategoryEditModal
