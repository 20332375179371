import React from "react"
// react-modal creates a modal that appears on top of everything,
// including the sidebar and main topnav - it worked in ProShopDisplay
import Modal from "react-modal"

import "./BaseModal.scss"

const BaseModal = props => {
  const close = props.close // pass function to close the modal
  const backable = props.back // option 'back' prop to have back button appear
  // also can pass the width of the modal itself if you want to set it
  const hideClose = props.hideClose // set this props to 'true' if you don't want to be able to close. Still need to pass in something to 'props.close' which will run if user clicks the overlay.

  return (
    <Modal
      isOpen
      contentLabel='Base Modal'
      onRequestClose={close}
      className='customModal'
    >
      <div id='BaseModal'>
        <div className='clickable-overlay' onClick={close} />
        <div
          className='modal-content-container'
          style={props.width ? { width: props.width } : {}}
        >
          {hideClose ? null : backable ? (
            <div className='backable-close-bar'>
              <button className='back-arrow-btn' onClick={backable}>
                <i className='fa fa-arrow-left' aria-hidden='true'></i>
              </button>
              <button className='x-modal-close-btn' onClick={close}>
                <i className='fa fa-times' aria-hidden='true'></i>
              </button>
            </div>
          ) : (
            <div className='top-close-bar'>
              <button className='x-modal-close-btn' onClick={close}>
                <i className='fa fa-times' aria-hidden='true'></i>
              </button>
            </div>
          )}
          {props.children}
        </div>
      </div>
    </Modal>
  )
}

export default BaseModal
