/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable max-lines-per-function */
import React, { Component } from 'react'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'
import PropTypes from 'prop-types'

// REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as commonActions from '../../actions/commonActions'
import * as drillActions from '../../actions/drillActions'
import * as tagActions from '../../actions/tagActions'
import * as practiceTypesActions from '../../actions/practiceTypeActions'

// CHILDREN
// import SearchInput from "react-search-input";
import Loader from 'react-loaders'
import BaseModal from '../common/BaseModal'
import DrillActivityCard from './cards/DrillActivityCard'
// import DrillActivityDetailModal from './modals/DrillActivityDetailModal';
import FilterModal from './modals/FilterModal'
import DrillDetailModal from './modals/DrillDetailModal'
import OP36Loader from '../common/Op36Loader-web'
import Pagination from '../common/Pagination'
import TwoButtonModal from '../lessonPlans/modals/TwoButtonModal'
import PageHeader from '../common/PageHeader/PageHeader'
import SearchInput from '../common/SearchInput'

// ASSETS
import '../../assets/styles/components/classes/ClassesPage.scss'
import './DrillsPage.scss'
import StockProfilePic from '../../assets/images/mock/StockProfilePic.png'
import activity_icon from '../../assets/images/common/icons/drills/easel.png'
import white_activity_icon from '../../assets/images/common/icons/drills/easel_white.png'
import drill_icon from '../../assets/images/common/icons/drills/weight.png'
import white_drill_icon from '../../assets/images/common/icons/drills/weight_white.png'
import train_icons from '../../constants/train-icons.js'
import fav_icon from '../../assets/images/common/icons/drills/favorite-icon.png'
import filter_icon from '../../assets/images/common/icons/topnav/filter-icon.png'
import { withRouting } from '../common/hooks'

class DrillsPage extends Component {
  state = {
    loader: true, // loader for public drills and activities
    myDrillsLoader: true, // loader for 'my drills'
    myActivitiesLoader: true, // loader for 'my activities'
    pinnedLoader: true, // loader for pinned sections (both myDrills and myActivities)
    favoritesLoader: true, // loader for the public favorites section
    leaderboardLoader: true, // loader for top contributors
    publicPagination: 1, // === \\
    drillPagination: 1, // ======= >>  these track where each page is at with pagination
    activityPagination: 1, // == //
    view: 'public',
    searchTerm: {
      public: '',
      myDrills: '',
      myActivities: '',
    },
    searchLoader: false,
    pinnedOpen: true,
    addButtonClicked: false,
    filterButtonClicked: false,
    newTypeSelected: 0, // set to 'drill' or 'activity' based upon selection in modal - used to start builder
    practiceTypesLoader: true,
    tagsLoader: true,
    selectedPracticeTypes: [], // skills selected when building a drill - sent to builder
    publicFilters: {
      type: 'Any',
      visibility: 'Any',
      skills: [],
      tags: [],
    },
    myDrillsFilters: {
      type: 'Any',
      visibility: 'Any',
      skills: [],
      tags: [],
    },
    myActivitiesFilters: {
      type: 'Any',
      visibility: 'Any',
      skills: [],
      tags: [],
    },
    selectedDrillId: -1, // controls drill modal popping up - change this value to the drill id to open
    deleteModal: { type: 'none', id: -1 }, // controls modal for deleting drills and/or activities
    publicSearchTimeout: null,
    myDrillsSearchTimeout: null,
    // throttle for search to avoid sending multiple unnecesary api calls while user is still typing.
    // This was causing unexpected search results sometimes.
    myActivitiesSearchTimeout: null,
    tabIndex: 0,
    viewsIndex: ['public', 'myDrills', 'myActivities'],
  }

  componentDidMount() {
    this.props.common_actions.setCurrentTab('Drills')
    this.props.common_actions.showNavBar(true)
    this.props.common_actions.showSideBar(true)

    this.props.drill_actions.getDrills(null, 1, 20).then(() => {
      // this grabs the public drills and activities
      this.setState({ loader: false })
    })

    this.props.drill_actions.getMyDrills(null, 1, 20).then(() => {
      // this grabs the current user's drills
      this.setState({ myDrillsLoader: false })
    })

    this.props.drill_actions.getMyActivities(null, 1, 20).then(() => {
      // this grabs the current user's activities
      this.setState({ myActivitiesLoader: false })
    })

    this.props.drill_actions.getPinnedDrills().then(() => {
      // this grabs both pinned drills and pinned activities. Reducer splits them up.
      this.setState({ pinnedLoader: false })
    })

    this.props.drill_actions.grabFavoriteDrills().then(() => {
      this.setState({ favoritesLoader: false })
    })

    this.props.drill_actions.grabTopContributors().then(() => {
      this.setState({ leaderboardLoader: false })
    })

    this.props.practice_types_actions.getPracticeTypes().then(() => {
      this.setState({ practiceTypesLoader: false })
    })

    this.props.tag_actions
      .getTags()
      .then(() => this.setState({ tagsLoader: false }))
  }
  render() {
    const {
      view,
      addButtonClicked,
      filterButtonClicked,
      newTypeSelected,
      selectedPracticeTypes,
      practiceTypesLoader,
      selectedDrillId,
      deleteModal,
    } = this.state

    const DrillsHeaderActionButton = (
      <button
        className='btn btn-primary text-white pull-right mr-2'
        onClick={this._handleAddButtonClick}
      >
        Create
      </button>
    )

    return (
      <div id='DrillsPage'>
        <PageHeader
          title='Drills & Activities'
          actionComponent={DrillsHeaderActionButton}
        />
        <Tabs className='tabs' selectedIndex={this.state.tabIndex} onSelect={(index) => this._changeView(this.state.viewsIndex[index], index)}>
          <TabList className='tabList'>
            <Tab className='tab react-tabs__tab' selectedClassName='activeTab'>Public Drills & Activities</Tab>
            <Tab className='tab react-tabs__tab' selectedClassName='activeTab'>My Drills</Tab>
            <Tab className='tab react-tabs__tab' selectedClassName='activeTab'>My Coaching Activities</Tab>
          </TabList>
          {this._renderSearchBar(this.state.view)}
          <TabPanel>
            {this._renderDrillsContainer('public')}
          </TabPanel>
          <TabPanel>
            {this._renderDrillsContainer('myDrills')}
          </TabPanel>
          <TabPanel>
            {this._renderDrillsContainer('myActivities')}
          </TabPanel>
        </Tabs>
        {filterButtonClicked && (
          <FilterModal
            filters={this.state[view + 'Filters']}
            applyHandler={this._applyFilters}
            close={this._closeFilterModal}
            drillSkills={this.props.practice_types}
            tags={this.state.tagsLoader ? null : this.props.tags}
            showType={this.state.view === 'public'}
            showVisibility={this.state.view !== 'public'}
            showSkills={this.state.view !== 'myActivities'}
          />
        )}

        {addButtonClicked && (
          <BaseModal
            close={this._closeAddModal}
            back={newTypeSelected === 'drill' ? this._resetSelectedType : false}
            width={'70vw'}
          >
            {newTypeSelected === 'drill' ? (
              <div className='new-modal'>
                <h1>Set skills</h1>
                <div className='subtitle-bar'>
                  <p>
                    Select skill(s) trained <span>*</span>
                  </p>
                  <p className='small-grey-text'>
                    {selectedPracticeTypes.length} skill
                    {selectedPracticeTypes.length === 1 ? '' : 's'} selected
                  </p>
                </div>
                {practiceTypesLoader ? (
                  <div className='centered-container'>
                    <i className='fa fa-spinner fa-spin' aria-hidden='true' />
                  </div>
                ) : (
                  <div className='even-container'>
                    {this.props.practice_types.map((p, idx) => {
                      const selected = selectedPracticeTypes.includes(p.id)
                      const selectedClass = selected
                        ? ' skill-icon-selected'
                        : ''
                      const selectedImg = selected
                        ? p.photo && p.photo.file_url
                        : train_icons[idx]
                      return (
                        <div className='icon-container' key={p.id}>
                          <img
                            src={selectedImg}
                            alt={p.name + ' icon'}
                            className={'skill-icon' + selectedClass}
                            onClick={
                              selected
                                ? () => this._removeSkill(p.id)
                                : () => this._addSkill(p.id)
                            }
                          />
                          <p>{p.name}</p>
                        </div>
                      )
                    })}
                  </div>
                )}
                <button
                  className='btn-rectangular btn-rectangular-main-positive'
                  disabled={selectedPracticeTypes.length < 1}
                  onClick={this._startBuilder}
                >
                  Start Building
                </button>
              </div>
            ) : (
              <div className='new-modal'>
                <h1>Choose type</h1>
                <div className='picker-container'>
                  <div
                    className='picker-card card-btn'
                    id='picker-card-activity'
                    aria-hidden='true'
                    onClick={() => this._newPicker('activity')}
                    style={
                      newTypeSelected === 'activity'
                        ? { background: '#dfe3e6', border: '1px solid #ff6633' }
                        : {}
                    }
                  >
                    <div className='picker-card-icon'>
                      <img src={activity_icon} alt='Coaching Activity icon' />
                    </div>
                    <h2>Coaching Activity</h2>
                    <ul>
                      <li>
                        Typically a <strong>group</strong> activity/game that
                        can be used in your group coaching programs
                      </li>
                      <li>
                        Can be used in <strong>Lesson Plans</strong>
                      </li>
                    </ul>
                  </div>
                  <div
                    className='picker-card card-btn'
                    id='picker-card-drill'
                    aria-hidden='true'
                    onClick={() => this._newPicker('drill')}
                  >
                    <div className='picker-card-icon'>
                      <img src={drill_icon} alt='Coaching Activity icon' />
                    </div>
                    <h2>Drill</h2>
                    <ul>
                      <li>
                        Activity that an <strong>individual</strong> can do on
                        their own outside of your group training
                      </li>
                      <li>
                        Can be used in <strong>Lesson Plans</strong>
                      </li>
                      {/* <li>
                        Adds to students’ <strong>time trained</strong> stats
                      </li>
                      <li>
                        {" "}
                        Can include <strong>scoring</strong> and a{" "}
                        <strong>leaderboard</strong>
                      </li> */}
                      <li>
                        <strong>In the future</strong> you will be able to
                        assign these to students
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </BaseModal>
        )}
        {selectedDrillId > 0 && (
          <DrillDetailModal
            drillId={selectedDrillId}
            currentUser={this.props.user}
            close={this._closeDrillDetailModal}
            drillsPage={true}
            handlePin={this._handlePin}
            handleEdit={this._handleEdit}
            handleDelete={this._deleteFromModal}
            handleFavorite={this._handleFavorite}
            currentPage={view}
          />
        )}
        {deleteModal.id > 0 && (
          <TwoButtonModal
            title={`Delete this ${deleteModal.type}?`}
            subtitle={`If you delete this ${deleteModal.type}, it will be removed from any Lesson Plan.`}
            close={this._closeDeleteModal}
            confirmText={'Delete'}
            confirmAction={this._handleDelete}
          />
        )}
      </div>
    )
  }

  _handleDelete = () => {
    const id = this.state.deleteModal.id
    this.props.drill_actions.archiveDrill(id).then(() => {
      this._closeDeleteModal()
    })
  }

  _renderDrillsContainer = view =>
    <div
      className={
        'drills-activities-cards-container' +
        (view === 'public' ? '-public' : '')
      }
    >
      <div className={`drills-activities-cards-pinned-sidebar ${this.state.pinnedOpen ? '' : 'drills-activities-cards-pinned-sidebar-closed'}`}>
        <div className='pinned-toggler' onClick={this._togglePinned} role='button' tabIndex={0}>
          <i className={`fa fa-chevron-left ${this.state.pinnedOpen ? '' : 'flip'}`} />
          {this.state.pinnedOpen ? <h3>Favorites</h3> : null}
        </div>
        {this.state.pinnedOpen ? this._pickSidebar(view) : null}
      </div>
      <div className='drills-all-main-area'>
        {view === 'public' ? (
          <div className='drills-activities-cards-leaderboard'>
            <h3>Top Contributors this Month</h3>
            <div className='drills-activities-cards-leaders'>
              {this._renderLeaderboard(
                this.state.leaderboardLoader,
                this.props.drills.top_contributors
              )}
            </div>
          </div>
        ) : null}
        <div className='drills-activities-cards-main-area'>
          {/* {this._renderSearchBars(view)} */}
          {this.state.searchTerm[view] === '' &&
            this.props.pages &&
            this.props.pages[view] > 1 && (
            <Pagination
              pageCount={this.props.pages[view]}
              fetchData={this._sendFetchingFunction(view)}
              activePage={this._sendActivePage(view)}
            />
          )}
          <div className='d-flex flex-wrap'>{this._renderCards(view)}</div>
        </div>
      </div>
    </div>

  _renderSearchBar = view => {
    switch (view) {
      case 'public':
        return (
          <div className='drills-search-bar mt-2'>
            <SearchInput placeholder='Search Public Drills & Activities' onChange={e => this._searchTerm(e, view)}/>
            <img
              src={filter_icon}
              alt='filter button'
              className='filter-button'
              onClick={this._handleFilterButtonClick}
            />
            {this._checkFilterCount(this.state.publicFilters)}
          </div>
        )
      case 'myDrills':
        return (
          <div className='drills-search-bar mt-2'>
            <SearchInput placeholder='Search My Drills' onChange={e => this._searchTerm(e, view)}/>
            <img
              src={filter_icon}
              alt='filter button'
              className='filter-button'
              onClick={this._handleFilterButtonClick}
            />
            {this._checkFilterCount(this.state.myDrillsFilters)}
          </div>
        )
      case 'myActivities':
        return (
          <div className='drills-search-bar mt-2'>
            <SearchInput placeholder='Search My Activities' onChange={e => this._searchTerm(e, view)}/>
            <img
              src={filter_icon}
              alt='filter button'
              className='filter-button'
              onClick={this._handleFilterButtonClick}
            />
            {this._checkFilterCount(this.state.myActivitiesFilters)}
          </div>
        )
      default:
        return null
    }
  }

  _checkFilterCount = filter => {
    let count = 0
    if (filter.skills.length > 0) {
      count += filter.skills.length
    }
    if (filter.tags.length > 0) {
      count += filter.tags.length
    }
    if (filter.visibility !== 'Any') {
      count += 1
    }
    if (filter.type !== 'Any') {
      count += 1
    }
    if (count > 0) {
      return (
        <div className='filter-count'>
          <p>{count}</p>
        </div>
      )
    }
  }

  _searchTerm = (e, view) => {
    const search = Object.assign({}, this.state.searchTerm)
    const term = e.target.value
    search[view] = term
    this.setState({ searchTerm: search }, () => {
      this._handleSearch(view, term)
    })
  }

  _handleSearch = (view, term) => {
    const whichLoader = this._grabLoaderByView(view)
    this.setState({ [whichLoader]: true }, () => {
      if (this.state[view + 'SearchTimeout']) {
        clearTimeout(this.state[view + 'SearchTimeout'])
      }
      if (!term) {
        const paginatelicious = this._grabPaginationByView(view)
        this.setState({
          [view + 'SearchTimeout']: setTimeout(
            () => this._updateMainSection(view, this.state[paginatelicious]),
            1500
          ),
        })
      } else {
        this.setState({
          [view + 'SearchTimeout']: setTimeout(
            () => this._updateMainSection(view, null, term),
            1500
          ),
        })
      }
    })
  }

  _grabLoaderByView = view => {
    switch (view) {
      case 'public':
        return 'loader'
      case 'myDrills':
        return 'myDrillsLoader'
      case 'myActivities':
        return 'myActivitiesLoader'
      default:
        return 'something_completely_random'
    }
  }

  _grabPaginationByView = view => {
    switch (view) {
      case 'public':
        return 'publicPagination'
      case 'myDrills':
        return 'drillPagination'
      case 'myActivities':
        return 'activityPagination'
      default:
        return 'something_completely_random'
    }
  }

  _updateMainSection = (view, pagination, searchTerm) => {
    const filter = this.state[view + 'Filters']
    if (view === 'public') {
      return this.props.drill_actions
        .getDrills(filter, pagination, pagination && 20, searchTerm)
        .then(() => {
          this.setState({ loader: false })
        })
    }
    if (view === 'myDrills') {
      return this.props.drill_actions
        .getMyDrills(filter, pagination, pagination && 20, searchTerm)
        .then(() => {
          this.setState({ myDrillsLoader: false })
        })
    }
    if (view === 'myActivities') {
      return this.props.drill_actions
        .getMyActivities(filter, pagination, pagination && 20, searchTerm)
        .then(() => {
          this.setState({ myActivitiesLoader: false })
        })
    }
  }

  // to pagination component
  _sendActivePage = view => {
    const {
      publicPagination,
      drillPagination,
      activityPagination,
    } = this.state
    switch (view) {
      case 'public':
        return publicPagination
      case 'myDrills':
        return drillPagination
      case 'myActivities':
        return activityPagination
      default:
        return 1
    }
  }

  _sendFetchingFunction = view => {
    switch (view) {
      case 'public':
        return this._fetchPublic
      case 'myDrills':
        return this._fetchDrills
      case 'myActivities':
        return this._fetchActivities
      default:
        return () => false
    }
  }

  _fetchPublic = step => {
    const { publicFilters } = this.state
    this.setState({ loader: true, publicPagination: step }, () => {
      this.props.drill_actions
        .getDrills(publicFilters, this.state.publicPagination, 20)
        .then(() => {
          this.setState({ loader: false })
        })
    })
  }

  _fetchDrills = step => {
    const { myDrillsFilters } = this.state
    this.setState({ myDrillsLoader: true, drillPagination: step }, () => {
      this.props.drill_actions
        .getMyDrills(myDrillsFilters, this.state.drillPagination, 20)
        .then(() => {
          this.setState({ myDrillsLoader: false })
        })
    })
  }

  _fetchActivities = step => {
    const { myActivitiesFilters } = this.state
    this.setState(
      { myActivitiesLoader: true, activityPagination: step },
      () => {
        this.props.drill_actions
          .getMyActivities(
            myActivitiesFilters,
            this.state.activityPagination,
            20
          )
          .then(() => {
            this.setState({ myActivitiesLoader: false })
          })
      }
    )
  }

  // end pagination stuff

  _pickSidebar = view => {
    switch (view) {
      case 'public':
        return this._renderCards('favorites')
      case 'myDrills':
        return this._renderCards('pinned_drills')
      case 'myActivities':
        return this._renderCards('pinned_activities')
      default:
        return false
    }
  }

  _addSkill = id => {
    const { selectedPracticeTypes } = this.state
    this.setState({ selectedPracticeTypes: [...selectedPracticeTypes, id] })
  }

  _removeSkill = id => {
    const { selectedPracticeTypes } = this.state
    this.setState({
      selectedPracticeTypes: selectedPracticeTypes.filter(p => p !== id),
    })
  }

  _resetSelectedType = () => {
    this.setState({ newTypeSelected: 0, selectedPracticeTypes: [] })
  }

  _startBuilder = () => {
    const { newTypeSelected, selectedPracticeTypes } = this.state

    this.props.navigate(
      '/drills/builder',
      { state: { type: newTypeSelected, selectedSkills: selectedPracticeTypes } }
    )
  }

  _newPicker = type => {
    this.setState({ newTypeSelected: type }, () => {
      if (type === 'activity') {
        this._startBuilder()
      }
    })
  }

  _togglePinned = () => {
    this.setState({ pinnedOpen: !this.state.pinnedOpen })
  }

  _changeView = (view, tabIndex) => {
    this.setState({ view, tabIndex })
  }

  _renderLeaderboard = (loader, leaders) => {
    if (loader) {
      return (
        <div className='w-100 d-flex justify-content-center'>
          <Loader type='line-scale' active color='#ff6633' />
        </div>
      )
    }
    if (leaders.length < 1) {
      return (
        <p style={{ textAlign: 'center', opacity: 0.5, width: '100%' }}>
          No recent contributors. Be the first!
        </p>
      )
    }

    return leaders.map((p, idx) => {
      const name = p.first_name + ' ' + p.last_name
      const num = p.monthly_drill_count
      return (
        <div key={idx} className='drills-activities-cards-leaderboard-spot'>
          <div className='contributor-leaderboard-placement'>
            <p>{idx + 1}</p>
          </div>
          <img
            src={p.avatar ? p.avatar.file_url : StockProfilePic}
            alt={'Avatar of ' + name}
          />
          <p>
            <b>{name}</b>
            <br />
            {num} {num === 1 ? 'Addition' : 'Additions'}
          </p>
        </div>
      )
    })
  }

  // eslint-disable-next-line complexity
  _renderCards = view => {
    const {
      loader,
      myDrillsLoader,
      myActivitiesLoader,
      pinnedLoader,
      favoritesLoader,
    } = this.state
    const { drills } = this.props
    // TODO add error handling here like LessonPlanPage
    switch (view) {
      case 'public':
        if (loader) {
          return <OP36Loader message={'loading drills and activities'} />
        }
        return drills.public_drills && drills.public_drills.length > 0 ? (
          drills.public_drills.map(d => (
            <DrillActivityCard
              key={d.id}
              onMain={true}
              item={{
                id: d.id,
                name: d.title,
                is_pinned: d.is_pinned,
                is_favorited: d.is_favorited,
                type: d.is_coach_activity ? 'activity' : 'drill',
                author: {
                  first_name: d.user.first_name,
                  last_name: d.user.last_name,
                  avatar: d.user.avatar,
                },
                photo: {
                  placeholder: d.photos.length < 1,
                  file_url:
                      d.photos.length > 0
                        ? d.photos[0].file_url
                        : d.is_coach_activity
                          ? white_activity_icon
                          : white_drill_icon,
                },
                tags: d.tags.concat(
                  d.custom_tags.map(t => ({ name: t }))
                ),
              }}
              publicDatabaseView
              handleStarClick={this._handleFavorite}
              onClick={this._triggerDetailModal}
            />
          ))
        ) : (
          <div className='empty-main-area-msg'>
            <div>
              <img key='drill' src={drill_icon} alt='drill icon' />
              <img key='activity' src={activity_icon} alt='activity icon' />
            </div>
            <p>{'Add new drills or activities by using the "Create" button.'}</p>
          </div>
        )
      case 'myDrills':
        if (myDrillsLoader) {
          return <OP36Loader message={'grabbing my drills'} />
        }
        return drills.my_drills && drills.my_drills.length > 0 ? (
          drills.my_drills.map(d => (
            <DrillActivityCard
              key={d.id}
              onMain={true}
              item={{
                id: d.id,
                name: d.title,
                is_pinned: d.is_pinned,
                is_favorited: d.is_pinned,
                type: d.is_coach_activity ? 'activity' : 'drill',
                author: {
                  first_name: d.user.first_name,
                  last_name: d.user.last_name,
                  avatar: d.user.avatar,
                },
                photo: {
                  placeholder: d.photos.length < 1,
                  file_url:
                      d.photos.length > 0
                        ? d.photos[0].file_url
                        : d.is_coach_activity
                          ? white_activity_icon
                          : white_drill_icon,
                },
                tags: d.tags.concat(
                  d.custom_tags.map(t => ({ name: t }))
                ),
              }}
              handleDelete={this._triggerDeleteModal}
              handleEdit={this._handleEdit}
              handleStarClick={this._handlePin}
              onClick={this._triggerDetailModal}
            />
          ))
        ) : (
          <div className='empty-main-area-msg'>
            <div>
              <img src={drill_icon} alt='drill icon' />
            </div>
            <p>{'Add new drills by using the "Create" button.'}</p>
          </div>
        )

      case 'myActivities':
        if (myActivitiesLoader) {
          return <OP36Loader message={'grabbing my activities'} />
        }
        return drills.my_activities && drills.my_activities.length > 0 ? (
          drills.my_activities.map(d => (
            <DrillActivityCard
              key={d.id}
              onMain={true}
              item={{
                id: d.id,
                name: d.title,
                is_pinned: d.is_pinned,
                is_favorited: d.is_pinned,
                type: d.is_coach_activity ? 'activity' : 'drill',
                author: {
                  first_name: d.user.first_name,
                  last_name: d.user.last_name,
                  avatar: d.user.avatar,
                },
                photo: {
                  placeholder: d.photos.length < 1,
                  file_url:
                      d.photos.length > 0
                        ? d.photos[0].file_url
                        : d.is_coach_activity
                          ? white_activity_icon
                          : white_drill_icon,
                },
                tags: d.tags.concat(
                  d.custom_tags.map(t => ({ name: t }))
                ),
              }}
              handleDelete={this._triggerDeleteModal}
              handleEdit={this._handleEdit}
              handleStarClick={this._handlePin}
              onClick={this._triggerDetailModal}
            />
          ))
        ) : (
          <div className='empty-main-area-msg'>
            <div>
              <img key='activity' src={activity_icon} alt='activity icon' />
            </div>
            <p>{'Add new activities by using the "Create" button.'}</p>
          </div>
        )
      case 'pinned_drills':
        if (pinnedLoader) {
          return (
            <div className='w-100 d-flex justify-content-center'>
              <Loader type='line-scale' active color='#fff' />
            </div>
          )
        }
        return drills.pinned_drills && drills.pinned_drills.length > 0 ? (
          drills.pinned_drills.map(d => (
            <DrillActivityCard
              key={d.id}
              onMain={false}
              item={{
                id: d.id,
                name: d.title,
                is_pinned: d.is_pinned,
                is_favorited: d.is_pinned,
                type: d.is_coach_activity ? 'activity' : 'drill',
                author: {
                  first_name: d.user.first_name,
                  last_name: d.user.last_name,
                  avatar: d.user.avatar,
                },
                photo: {
                  placeholder: d.photos.length < 1,
                  file_url:
                      d.photos.length > 0
                        ? d.photos[0].file_url
                        : d.is_coach_activity
                          ? white_activity_icon
                          : white_drill_icon,
                },
                tags: d.tags.concat(
                  d.custom_tags.map(t => ({ name: t }))
                ),
              }}
              handleDelete={this._triggerDeleteModal}
              handleEdit={this._handleEdit}
              handleStarClick={this._handlePin}
              onClick={this._triggerDetailModal}
            />
          ))
        ) : (
          <div className='empty-sidebar-msg'>
            <img src={fav_icon} alt={'drills'} />
            <p>
              {'No favorited drills.'}
              <br />
              {'Favorite drills for easier access later.'}
            </p>
          </div>
        )
      case 'pinned_activities':
        if (pinnedLoader) {
          return (
            <div className='w-100 d-flex justify-content-center'>
              <Loader type='line-scale' active color='#fff' />
            </div>
          )
        }
        return drills.pinned_activities &&
          drills.pinned_activities.length > 0 ? (
            drills.pinned_activities.map(d => (
              <DrillActivityCard
                key={d.id}
                onMain={false}
                item={{
                  id: d.id,
                  name: d.title,
                  is_pinned: d.is_pinned,
                  is_favorited: d.is_pinned,
                  type: d.is_coach_activity ? 'activity' : 'drill',
                  author: {
                    first_name: d.user.first_name,
                    last_name: d.user.last_name,
                    avatar: d.user.avatar,
                  },
                  photo: {
                    placeholder: d.photos.length < 1,
                    file_url:
                      d.photos.length > 0
                        ? d.photos[0].file_url
                        : d.is_coach_activity
                          ? white_activity_icon
                          : white_drill_icon,
                  },
                  tags: d.tags.concat(
                    d.custom_tags.map(t => ({ name: t }))
                  ),
                }}
                handleDelete={this._triggerDeleteModal}
                handleEdit={this._handleEdit}
                handleStarClick={this._handlePin}
                onClick={this._triggerDetailModal}
              />
            ))
          ) : (
            <div className='empty-sidebar-msg'>
              <img src={fav_icon} alt={'activities'} />
              <p>
                {'No favorited activities.'}
                <br />
                {'Favorite activities for easier access later.'}
              </p>
            </div>
          )
      case 'favorites':
        if (favoritesLoader) {
          return <OP36Loader message={'grabbing favorites'} />
        }
        return drills.favorites && drills.favorites.length > 0 ? (
          drills.favorites.map(d => (
            <DrillActivityCard
              key={d.id}
              onMain={false}
              item={{
                id: d.id,
                name: d.title,
                is_pinned: d.is_pinned,
                is_favorited: d.is_favorited,
                type: d.is_coach_activity ? 'activity' : 'drill',
                author: {
                  first_name: d.user.first_name,
                  last_name: d.user.last_name,
                  avatar: d.user.avatar,
                },
                photo: {
                  placeholder: d.photos.length < 1,
                  file_url:
                      d.photos.length > 0
                        ? d.photos[0].file_url
                        : d.is_coach_activity
                          ? white_activity_icon
                          : white_drill_icon,
                },
                tags: d.tags.concat(
                  d.custom_tags.map(t => ({ name: t }))
                ),
              }}
              publicDatabaseView
              handleStarClick={this._handleFavorite}
              onClick={this._triggerDetailModal}
            />
          ))
        ) : (
          <div className='empty-sidebar-msg'>
            <img src={fav_icon} alt={'activities'} />
            <p>
              {'No favorited activities.'}
              <br />
              {'Favorite activities for easier access later.'}
            </p>
          </div>
        )

      default:
        return <p>Something went wrong.</p>
    }
  }

  _triggerDeleteModal = item => {
    this.setState({ deleteModal: item })
  }

  _closeDeleteModal = () => {
    this.setState({ deleteModal: { type: 'none', id: -1 } })
  }

  _deleteFromModal = id => {
    this.props.drill_actions.archiveDrill(id).then(() => {
      this._closeDrillDetailModal()
    })
  }

  _handleFavorite = (event, drill_id) => {
    event.stopPropagation()
    this.props.drill_actions.favoriteDrill(drill_id)
  }

  _handlePin = (event, drill_id, pinned) => {
    event.stopPropagation()
    this.props.drill_actions.pinDrill({ id: drill_id, is_pinned: !pinned })
  }

  _handleEdit = id => {
    this.props.navigate(`/drills/builder/${id}`)
  }

  _handleAddButtonClick = _ => {
    this.setState({ addButtonClicked: true })
  }

  _closeAddModal = () => {
    this.setState({
      addButtonClicked: false,
      newTypeSelected: 0,
      selectedPracticeTypes: [],
    })
  }

  _handleFilterButtonClick = _ => {
    this.setState({ filterButtonClicked: true })
  }

  _closeFilterModal = () => {
    // TODO add to this later
    this.setState({ filterButtonClicked: false })
  }

  _applyFilters = newFilters => {
    const { view } = this.state
    const search = this.state.searchTerm[view]
    this.setState({ [view + 'Filters']: newFilters }, () => {
      const loader = this._grabLoaderByView(view)
      this.setState({ [loader]: true }, () => {
        this._closeFilterModal()

        const pagination = this._grabPaginationByView(view)

        if (this._checkFilterForContent(newFilters)) {
          if (search) {
            this._updateMainSection(view, null, search)
          } else {
            this.setState({ [pagination]: 1 }, () => {
              this._updateMainSection(view, 1, search)
            })
          }
        } else {
          if (search) {
            this._updateMainSection(view, null, search)
          } else {
            this._updateMainSection(view, this.state[pagination], search)
          }
        }
      })
    })
  }

  _checkFilterForContent = filter => {
    //an empty filters will have empty arrays in skills and tags, and it will say "Any" on type and visibility
    if (filter.skills.length > 0) {
      return true
    }
    if (filter.tags.length > 0) {
      return true
    }
    if (filter.visibility !== 'Any') {
      return true
    }
    if (filter.type !== 'Any') {
      return true
    }
    return false
  }

  _triggerDetailModal = id => {
    this.setState({ selectedDrillId: id })
  }

  _closeDrillDetailModal = () => {
    this.setState({ selectedDrillId: -1 })
  }
}

DrillsPage.propTypes = {
  drills: PropTypes.shape({
    top_contributors: PropTypes.array,
    public_drills: PropTypes.array,
    my_drills: PropTypes.array,
    pinned_drills: PropTypes.array,
    my_activities: PropTypes.array,
    pinned_activities: PropTypes.array,
    favorites: PropTypes.array,
  }),
  user: PropTypes.shape({
    type: PropTypes.string,
  }),
  pages: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  practice_types: PropTypes.array,
  tags: PropTypes.array,
  navigate: PropTypes.func,
  common_actions: PropTypes.shape({
    setCurrentTab: PropTypes.func,
    showNavBar: PropTypes.func,
    showSideBar: PropTypes.func,
  }),
  drill_actions: PropTypes.shape({
    getDrills: PropTypes.func,
    getMyDrills: PropTypes.func,
    getMyActivities: PropTypes.func,
    getPinnedDrills: PropTypes.func,
    grabFavoriteDrills: PropTypes.func,
    grabTopContributors: PropTypes.func,
    archiveDrill: PropTypes.func,
    favoriteDrill: PropTypes.func,
    pinDrill: PropTypes.func,
  }),
  practice_types_actions: PropTypes.shape({
    getPracticeTypes: PropTypes.func,
  }),
  tag_actions: PropTypes.shape({
    getTags: PropTypes.func,
  }),
}

function mapStateToProps(state, _) {
  return {
    ui: state.ui,
    user: state.user.current_user,
    drills: state.drills,
    tags: state.tags,
    pages: state.pages,
    practice_types: state.practiceTypes,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    common_actions: bindActionCreators(commonActions, dispatch),
    drill_actions: bindActionCreators(drillActions, dispatch),
    tag_actions: bindActionCreators(tagActions, dispatch),
    practice_types_actions: bindActionCreators(practiceTypesActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouting(DrillsPage))
