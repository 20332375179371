import React, { Component } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Breadcrumbs, BreadcrumbItem } from "../../common/Breadcrumbs";
import ReactPlayer from "react-player";
import { toast } from "react-toastify";
import PropTypes from 'prop-types'

// CHILDREN
import PageHeader from '../../common/PageHeader/PageHeader'
import ResourceRow from "../ResourceRow";
import CreateResourceModal from "../Modals/CreateResourceModal";

// ASSETS
import "./ResourceDetailPage.scss";

// REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as commonActions from "../../../actions/commonActions";
import * as userActions from "../../../actions/userActions";
import * as resourceActions from "../../../actions/resourceActions";
import * as resourceSectionActions from "../../../actions/resourceSectionActions";
import * as resourceCategoryActions from "../../../actions/resourceCategoryActions";
import { withRouting } from "../../common/hooks";

class ResourceDetailPage extends Component {
  state = {
    resourceLoader: false,
    showResourceModal: false,
    resourceSectionLoader: false,
    resourceCategoryLoader: false,
    id: -1,
    title: "",
    description: "",
    video_url: "",
    button_url: "",
    uploadPhotoName: "",
    uploadPhotoFile: "",
    uploadPhotoSrc: "",
    resource_section_id: -1,
    resource_section_name: "",
    resource_section_description: "",
    resource_section_uploadPhotoName: "",
    resource_section_uploadPhotoFile: "",
    resource_section_uploadPhotoSrc: "",
    resource_category_id: -1,
    resource_category_name: "",
    resource_category_description: "",
    formRendered: "resource",
  };
  componentDidMount() {
    const { common_actions } = this.props;
    common_actions.setCurrentTab("Resources");
    common_actions.showSideBar(true);
    common_actions.showNavBar(true);
    this.fetchResource();
  }

  componentDidUpdate(prevProps) {
    const oldResourceId = prevProps.location?.state?.resourceId
    const newResourceId = this.props.location?.state?.resourceId

    if (oldResourceId === newResourceId) {
      return
    }

    this.fetchResource()
  }

  fetchResourceSections = () => {
    const { resource_section_actions } = this.props;
    this.setState(
      {
        resourceSectionLoader: true,
      },
      () => {
        resource_section_actions.getResourceSections().then(() => {
          this.setState({
            resourceSectionLoader: false,
          });
        });
      }
    );
  };

  fetchResourceCategories = (sectionId) => {
    const { resource_category_actions } = this.props;
    this.setState(
      {
        resourceCategoryLoader: true,
      },
      () => {
        resource_category_actions.getResourceCategories(sectionId).then(() => {
          this.setState({
            resourceCategoryLoader: false,
          });
        });
      }
    );
  };

  resetResourceForm = () => {
    this.setState({
      showResourceModal: false,
      id: -1,
      name: "",
      description: "",
      video_url: "",
      button_url: "",
      uploadPhotoName: "",
      uploadPhotoFile: "",
      uploadPhotoSrc: "",
      resource_section_id: -1,
      reource_category_id: -1,
    });
  };

  resetResourceSectionForm = () => {
    this.setState({
      resource_section_name: "",
      resource_section_description: "",
      resource_section_uploadPhotoName: "",
      resource_section_uploadPhotoFile: "",
      resource_section_uploadPhotoSrc: "",
    });
  };

  resetResourceCategoryForm = () => {
    this.setState({
      resource_category_name: "",
      resource_category_description: "",
    });
  };

  resetResourceCategoryForm = () => {
    this.setState({
      resource_category_name: "",
      resource_category_description: "",
    });
  };

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSectionChange = (event) => {
    this.setState(
      {
        resource_section_id: event.target.value,
      },
      () => {
        const { resource_section_id } = this.state;
        this.fetchResourceCategories(resource_section_id);
      }
    );
  };

  handleStatefulEditorChange = (htmlString) => {
    this.setState({
      description: htmlString,
    });
  };

  grabEditorData = (description) => {
    this.setState({
      description,
    });
  };

  grabPhotoData = (photoData) => {
    const { uploadPhotoFile, uploadPhotoName, uploadPhotoSrc } = photoData;
    this.setState({
      uploadPhotoName,
      uploadPhotoFile,
      uploadPhotoSrc,
    });
  };

  grabSectionPhotoData = (photoData) => {
    const { uploadPhotoFile, uploadPhotoName, uploadPhotoSrc } = photoData;
    this.setState({
      resource_section_uploadPhotoName: uploadPhotoName,
      resource_section_uploadPhotoFile: uploadPhotoFile,
      resource_section_uploadPhotoSrc: uploadPhotoSrc,
    });
  };

  fetchResource = () => {
    const { resource_actions, location, params } = this.props;
    let id = location.state ? location.state.resourceId : params.resourceId;

    this.setState(
      {
        resourceLoader: true,
      },
      () => {
        resource_actions.getResource(id).then(() => {
          this.setState({
            resourceLoader: false,
          });
        });
      }
    );
  };

  updateResource = () => {
    const { resource_actions } = this.props;
    this.setState({ resourceLoader: true }, () => {
      resource_actions.updateResource(this.state).then(() => {
        this.setState({ resourceLoader: false }, () => {
          this.fetchResource();
        });
        this.closeResourceModal();
        toast.success("Success. Updated Resource!", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
    });
  };

  createResourceSection = () => {
    const { resource_section_actions } = this.props;
    const {
      resource_section_name,
      resource_section_description,
      resource_section_uploadPhotoFile,
    } = this.state;
    this.setState(
      {
        resourceSectionLoader: true,
      },
      () => {
        let resourceSection = {
          name: resource_section_name,
          description: resource_section_description,
          resource_section_photo:
            resource_section_uploadPhotoFile &&
            resource_section_uploadPhotoFile,
        };

        resource_section_actions
          .createResourceSection(resourceSection)
          .then(() => {
            this.setState({
              resourceSectionLoader: false,
            });
            this.resetResourceSectionForm();
            this.toggleForm("resource");
          });
      }
    );
  };

  createResourceCategory = () => {
    const { resource_category_actions } = this.props;
    const {
      resource_category_name,
      resource_category_description,
      resource_section_id,
    } = this.state;
    this.setState(
      {
        resourceCategoryLoader: true,
      },
      () => {
        let resourceCategory = {
          name: resource_category_name,
          description: resource_category_description,
          resource_section_id: resource_section_id,
        };

        resource_category_actions
          .createResourceCategory(resourceCategory)
          .then(() => {
            this.setState({
              resourceCategoryLoader: false,
            });
            this.resetResourceCategoryForm();
            this.toggleForm("resource");
          });
      }
    );
  };

  toggleForm = (formRendered) => {
    this.setState({
      formRendered,
    });
  };

  toggleEditModal = () => {
    const { resource } = this.props;
    this.setState(
      {
        showResourceModal: true,
        id: resource.id,
        title: resource.title,
        description: resource.description,
        video_url: resource.video_url,
        button_url: resource.button_url,
        uploadPhotoSrc: resource.photo ? resource.photo.file_url : "",
        resource_section_id: resource.resource_section
          ? resource.resource_section.id
          : -1,
        resource_category_id: resource.resource_category_id
          ? resource.resource_category_id
          : -1,
      },
      () =>
        this.fetchResourceCategories(
          resource.resource_section ? resource.resource_section.id : -1
        )
    );
  };

  closeResourceModal = () => {
    this.setState(
      {
        showResourceModal: false,
      },
      () => {
        this.resetResourceForm();
      }
    );
  };

  renderRelatedResources = () => {
    const related_resources = this.props.resource.related_resources

    if (related_resources && related_resources.length > 0) {
      return related_resources.map((resource) => (
        <ResourceRow
          resource={resource}
          key={resource.id}
        />
      ));
    }
    return (
      <p className='resources-not-available'>No related resources available</p>
    );
  };

  renderResourceSections = () => {
    const resource_sections = this.props.resource.resource_sections

    if (resource_sections && resource_sections.length > 0) {
      return resource_sections.map((section) => (
        <Link
          key={section.id}
          to={`/resource-sections/${section.id}`}
          state={{ resourceSectionId: section.id }}
        >
          {section.name}
        </Link>
      ));
    }
    return <p className='resources-not-available'>No sections available</p>;
  };

  headerActionButton = () => (
    <button
      className='btn btn-primary text-white pull-right mr-2'
      onClick={this.toggleEditModal}
    >
      {this.props.current_user.type === 'Admin' && 'Edit Resource'}
    </button>
  )

  render() {
    const { current_user, resource, resource_categories, resource_sections} = this.props;
    const {
      resourceLoader,
      showResourceModal,
      resourceSectionLoader,
      resourceCategoryLoader,
    } = this.state;
    return (
      <div id='ResourceDetailPage'>
        <PageHeader
          title='Resource Center'
          actionComponent={this.headerActionButton()}
        />
        <div className='resource-detail-container'>
          <Breadcrumbs>
            <BreadcrumbItem name='Resource Center' route='/resource-sections' />
            {resource.resource_section && (
              <BreadcrumbItem
                name={resource.resource_section.name}
                route={`/resource-sections/${resource.resource_section.id}`}
                routeState={{ resourceSectionId: resource.resource_section.id }}
              />
            )}
            {resource && (
              <BreadcrumbItem
                name={resource.title}
                route={`/resource/${resource.id}`}
                disabled
              />
            )}
          </Breadcrumbs>
          <div className='col-xl-10 col-lg-12 col-md-12 mx-auto card'>
            <div className='row pt-5 pb-5'>
              <div className='col-md-3 resource-detail-sidebar'>
                <div className='resource-detail-image-wrapper'>
                  {resource.photo ? (
                    <img src={resource.photo.file_url} alt={resource.name} />
                  ) : (
                    <i className='fa fa-paperclip' />
                  )}
                </div>
                <div className='related-resources-wrapper'>
                  <h5>Related Resources</h5>
                  {this.renderRelatedResources()}
                </div>
                <div className='resource-sections-wrapper'>
                  <h5>All Sections</h5>
                  {this.renderResourceSections()}
                </div>
              </div>
              <div className='col-md-9 resource-detail-section'>
                <div className='row resource-detail-header'>
                  <div className='col-md-9'>
                    <h4>{resource.title}</h4>
                    <p>
                      Last updated:{" "}
                      {moment(resource.updated_at).format("MM-DD-YYYY")}
                    </p>
                  </div>
                  <div className='col-md-3 d-flex flex-column align-items-end'>
                    {resource.button_url && (
                      <a
                        className='btn btn-primary'
                        href={resource.button_url}
                        target='_blank'
                      >
                        Download
                      </a>
                    )}
                  </div>
                </div>
                {resource.video_url && (
                  <div className='player-wrapper'>
                    <ReactPlayer
                      className='react-player'
                      url={resource.video_url}
                      width='100%'
                      height='100%'
                      controls
                    />
                  </div>
                )}

                <div
                  className='resource-description'
                  dangerouslySetInnerHTML={{
                    __html: resource.description ? resource.description : "",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {showResourceModal && (
          <CreateResourceModal
            showModal={showResourceModal}
            closeModal={this.closeResourceModal}
            formState={this.state}
            handleInputChange={this.handleInputChange}
            handleSectionChange={this.handleSectionChange}
            handleStatefulEditorChange={this.handleStatefulEditorChange}
            grabPhotoData={this.grabPhotoData}
            grabSectionPhotoData={this.grabSectionPhotoData}
            updateResource={this.updateResource}
            createResourceSection={this.createResourceSection}
            createResourceCategory={this.createResourceCategory}
            resourceSections={resource_sections}
            resourceSectionLoader={resourceSectionLoader}
            resourceCategories={resource_categories}
            resourceCategoryLoader={resourceCategoryLoader}
            resourceLoader={resourceLoader}
            toggleForm={this.toggleForm}
            sendEditorData={this.grabEditorData}
            isEditModal
          />
        )}
      </div>
    );
  }
}

ResourceDetailPage.propTypes = {
  current_user: PropTypes.shape({
    type: PropTypes.string,
  }),
}

function mapStateToProps(state, ownProps) {
  return {
    ui: state.ui,
    current_user: state.user.current_user,
    resource: state.resource,
    resource_sections: state.resourceSections,
    resource_categories: state.resourceCategories,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    common_actions: bindActionCreators(commonActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
    resource_actions: bindActionCreators(resourceActions, dispatch),
    resource_section_actions: bindActionCreators(
      resourceSectionActions,
      dispatch
    ),
    resource_category_actions: bindActionCreators(
      resourceCategoryActions,
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouting(ResourceDetailPage))
