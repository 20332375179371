import { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { ReactComponent as PackageIcon } from '../../assets/images/common/icons/package-icon.svg'
import * as commonActions from '../../actions/commonActions'
import './UnavailableClassesPage.scss'
import SubmitButton from '../common/buttons/SubmitButton'
import ButtonLink from '../common/buttons/ButtonLink'
import { useNavigate } from 'react-router-dom'

const UnavailableClassesPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const current_user = useSelector(state => state.user.current_user)

  const navigateToCommunitySettings = useCallback(
    () => {
      navigate('/community-settings')
    }, [navigate]
  )

  useEffect(() => {
    dispatch(commonActions.setCurrentTab('Classes'))
    dispatch(commonActions.showSideBar(true))
    dispatch(commonActions.showNavBar(true))
  }, [])

  return (
    <div id='UnavailableClassesPage' className='d-flex flex-column justify-content-center align-items-center'>
      <PackageIcon id='packageIcon' />
      <p className='stripe-required-title'>To use programs, please connect a Stripe account and create packages.</p>
      <p className='stripe-required-instructions'>
        Only the lead coach can connect a Stripe account and create packages in the Community Settings. If you are not the lead coach,
        please ask them to make these adjustments.
      </p>
      {current_user.lead_coach && (
        <ButtonLink
          navigateTo='/community-settings'
          className='communitySettingsLink'
        >
          Go to Community Settings
        </ButtonLink>
      )}
    </div>
  )
}

export default UnavailableClassesPage
