import React from 'react';
import PropTypes from 'prop-types';
import SkyLight from 'react-skylight';

export default class AlertDialog extends React.Component {

constructor(props) {
        super(props);
        this.state = {};
    }

    show() {
      this.refs.alertDialog.show()
    }

    componentWillMount() {
        this._initState(this.props);
    }

    componentWillUpdate(nextProps) {
        if (this.props.value !== nextProps.value) {

            this._initState(nextProps);
        }
    }

    _initState(props) {

        this.setState({
            title: props.title,
            hideOnOverlayClicked: props.hideOnOverlayClicked,
            showOverlay: props.showOverlay,
        });
    }

    render() {

    const warningDialogStyle2 = {
      width: '50%',     
      minWidth: "300px", 
      position: 'fixed',
      top: '50%',
      left: '50%',
      paddingTop: '50px',
      paddingBottom: '50px',
      marginTop: '-200px',
      marginLeft: '-25%',
      backgroundColor: '#fff',
      borderRadius: '4px',
      zIndex: 100,
      padding: '15px',
      borderStyle: 'solid', 
      borderColor: '#B8464B',
      height: 'auto',      
      boxShadow: '0 0 40px rgba(0,0,0,.14),0 0px 40px rgba(0,0,0,.28)'
    };     

   const successDialogStyle2 = {
      width: '50%',     
      minWidth: "300px", 
      position: 'fixed',
      top: '50%',
      left: '50%',
      paddingTop: '50px',
      paddingBottom: '50px',
      marginTop: '-200px',
      marginLeft: '-25%',
      backgroundColor: '#fff',
      borderRadius: '4px',
      zIndex: 100,
      padding: '15px',
      borderStyle: 'solid', 
      borderColor: '#33d9b2',
      height: 'auto',      
      boxShadow: '0 0 40px rgba(0,0,0,.14),0 0px 40px rgba(0,0,0,.28)'
    }; 

    const warningTitleStyle1 = {
      marginTop: '0',
      textAlign: 'center',
      fontWeight: 'bold',
      color: '#B8464B',
      textTransform: 'uppercase',
      letterSpacing: '4px',
      fontSize: '20px',
      borderBottom: 'solid 1px rgba(217, 83, 79, .2)',
      paddingBottom: '9px'      
    };


    const successTitleStyle1 = {
      marginTop: '0',
      textAlign: 'center',
      fontWeight: 'bold',
      color: '#33d9b2',
      textTransform: 'uppercase',
      letterSpacing: '4px',
      fontSize: '20px',
      borderBottom: 'solid 1px rgba(223, 240, 216, .2)',
      paddingBottom: '9px'      
    };
     

    const warningCloseButtonStyle1 = {
      cursor: 'pointer',
      position: 'absolute',
      fontSize: '2em',
      right: '10px',
      top: '0',
      color: '#B8464B'
    };      

    const successCloseButtonStyle1 = {
      cursor: 'pointer',
      position: 'absolute',
      fontSize: '2em',
      right: '10px',
      top: '0',
      color: '#33d9b2'
    }; 
    var dStyles, tStyles, cbStyles;

    if(this.props.type === "warning"){
      dStyles = warningDialogStyle2
      tStyles = warningTitleStyle1
      cbStyles = warningCloseButtonStyle1
    }
    else if(this.props.type === "success") {
       dStyles = successDialogStyle2
       tStyles = successTitleStyle1
       cbStyles = successCloseButtonStyle1
    }
    else{
      dStyles = warningDialogStyle2
      tStyles = warningTitleStyle1
      cbStyles = warningCloseButtonStyle1
    }


        return (
          <SkyLight
            {...this.props} 
            dialogStyles={dStyles}
            titleStyle={tStyles}
            closeButtonStyle={cbStyles}
            ref="alertDialog"
            hideOnOverlayClicked={this.props.hideOnOverlayClicked}
            showOverlay={this.props.showOverlay}
            title={this.props.title}>
              <h3 className={"text-center " + (this.props.type ? ("text-" + this.props.type) : "text-danger") } > {this.props.children} </h3>
          </SkyLight>
        )
    }

}

AlertDialog.propTypes={
  title: PropTypes.string,
  hideOnOverlayClicked : PropTypes.bool,
  showOverlay: PropTypes.bool

}




