import React, { Component } from "react";
import { Collapse } from "reactstrap";
import moment from "moment";

// CHILDREN
import CoachStats from "./CoachStats";

// ASSETS
import "./CoachRow.scss";
import Avatar from "../../../assets/images/mock/StockProfilePic.png";
import certPreview from '../../../assets/images/pins/certified_preview_badge.svg'

class CoachRow extends Component {
  state = {
    collapse: false,
  };

  toggleRow = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  getColor = (color) => {
    switch (color) {
      case "red":
        return "#ed213a";
      case "yellow":
        return "#fddb10";
      case "green":
        return "#33d9b2";
      default:
        return "";
    }
  };

  displayPermission = (val) => {
    let titleTable = {
      0: { title: "Education", color: "#6677DE" },
      1: { title: "Dev League", color: "#34ACE0" },
      2: { title: "Academy", color: "#33D9B2" },
    };
    return (
      <p style={{ color: titleTable[val].color, fontSize: 13 }}>
        {titleTable[val].title}
      </p>
    );
  };

  render() {
    const { coach, makeLeadCoach } = this.props;
    const { collapse } = this.state;

    return (
      <div
        id='CoachRow'
        className={`w-100 card ${!collapse && `animation-shrink`}`}
      >
        <button className='row ' type='button' onClick={this.toggleRow}>
          <div className='coach-info-container'>
            <div
              className={`medium-round-image-wrapper ${
                coach.coach.is_lead_coach ? "orange-highlight" : ""
              }`}
            >
              <img
                src={coach.coach.avatar_url ? coach.coach.avatar_url : Avatar}
                alt={`${coach.coach.first_name} ${coach.coach.last_name}`}
                className='round-image'
              />
            </div>
            <div className='coach-info-wrapper'>
              <p className='coach-name'>
                {coach.coach.first_name} {coach.coach.last_name}{" "}
                {coach.coach.is_certified && (
                  <img
                    src={certPreview}
                    alt='certified'
                    className='cert-preview-icon'
                  />
                )}
              </p>
              {this.displayPermission(coach.coach.permission)}
              <p className='coach-login-date'>
                Last Login:{" "}
                {coach.last_login_date && coach.last_login_date.value
                  ? moment(coach.last_login_date.value).fromNow()
                  : "No data available"}
              </p>
            </div>
          </div>

          <div className='coach-score-container'>
            <p
              style={{
                color: this.getColor(coach.engagement_score.score.color),
              }}
            >
              {coach.engagement_score.value}%
            </p>
            <div
              className='coach-score-light'
              style={{
                backgroundColor: this.getColor(
                  coach.engagement_score.score.color
                ),
                boxShadow: `.5px .5px 8px ${this.getColor(
                  coach.engagement_score.score.color
                )}`,
              }}
            />
          </div>
        </button>
        <Collapse isOpen={collapse}>
          <CoachStats coach={coach} makeLeadCoach={makeLeadCoach} />
        </Collapse>
      </div>
    );
  }
}

export default CoachRow;
