import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./FamilyCard.scss";

const FamilyCard = ({ family }) => {
  const navigate = useNavigate()

  const navigateTo = useCallback(
    () => {
      const { id } = family

      navigate(
        `/familydetail/${id}`,
        {
          state: {
            family: id
          }
        }
      )
    },
    [ navigate, family ]
  )
  
  return (
    <div
      id='FamilyCardContainer'
      className='col-xl-4 col-lg-6 col-md-6 animated fadeIn'
      onClick={navigateTo}
    >
      <div className='card'>
        <div className='family-card-wrapper'>
          <p className='family-name'>{family.name}</p>
          <div className='family-card-divider' />
          <p className='family-points'>{family.points} pts</p>
          <p className='family-students'>{family.users.length} students</p>
        </div>
      </div>
    </div>
  )
}

export default FamilyCard;
