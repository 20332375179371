import React from "react";

import BlockBuilder from "./BlockBuilder";
import Loader from "../../../components/common/Op36Loader-web";
import note_icon from "../../../assets/images/common/icons/note.png";
import "./SessionBuilder.scss";

class SessionBuilder extends React.Component {
  state = {
    sessionNotesModal: false
  };

  componentDidMount() {
    this.comp.addEventListener("scroll", this._checkSticky);
  }

  componentWillUnmount() {
    this.comp.removeEventListener("scroll", this._checkSticky);
  }

  render() {
    const {
      session,
      addBlock,
      handleSessionInput,
      grabTask,
      handleBlockInput,
      handleDeleteBlock,
      handleMoveBlock,
      sessionLoader
    } = this.props;
    const { sessionNotesModal } = this.state;
    if (sessionLoader) {
      return (
        <div id='sessionBuilderLoader'>
          <Loader message={"Grabbing session"} />
        </div>
      );
    }
    return (
      <div id='SessionBuilder' ref={el => (this.comp = el)}>
        <div className='title-bar'>
          <input
            type='text'
            value={session.name}
            onChange={handleSessionInput}
            className='session-title'
            name='name'
            placeholder={"add name"}
          />
          <div
            className={`note-icon ${session.notes ? "note-present" : ""}`}
            style={{ backgroundImage: `url(${note_icon})` }}
            onClick={() => this.setState({ sessionNotesModal: true })}
          />
        </div>
        <div className='set-duration-container'>
          <p className='set-duration-input-label'>Duration</p>
          <input
            type='number'
            name='duration'
            value={session.duration}
            onChange={handleSessionInput}
            placeholder={"minutes"}
            onWheel={e => e.target.blur()}
            onBlur={
              session.duration === ""
                ? () =>
                    handleSessionInput({
                      target: { name: "duration", value: 0 }
                    })
                : () => false
            }
          />
        </div>
        <div ref={el => (this.sticky = el)}>
          <div className='duration-info-container'>
            <p className='duration-ratio'>{this._grabDurationRatio()}</p>
            <p
              className={`duration-remaining ${
                this._checkOverTime() ? "over-time" : ""
              }`}
            >
              {this._grabTimeRemaining()}
            </p>
          </div>
          <div className='duration-bar-container'>
            <div className='duration-bar-outline' />
            <div className='duration-bar-fill' style={this._calculateStyle()} />
          </div>{" "}
        </div>
        <div ref={el => (this.blockContainer = el)}>
          {session.blocks.length > 0 ? (
            session.blocks.map((block, index) => {
              return (
                <BlockBuilder
                  block={block}
                  key={index}
                  index={index}
                  grabTask={grabTask}
                  handleBlockInput={handleBlockInput}
                  deleteBlock={handleDeleteBlock}
                  moveBlock={handleMoveBlock}
                />
              );
            })
          ) : (
            <div className='zero-block-placeholder'>
              <p className='zero-block-placeholder-text'>
                You're straight out of blocks! Add at least one.
              </p>
            </div>
          )}
        </div>
        <div className='add-block-container' onClick={addBlock}>
          <p className='add-block-button'>+ Add Block</p>
        </div>
        {sessionNotesModal && (
          <div className='grey-out1' onClick={this._closeSessionNotesModal} />
        )}
        {sessionNotesModal && (
          <div className='session-notes-modal'>
            <div className='session-notes-icon-container'>
              <i
                className='fa fa-times'
                aria-hidden='true'
                onClick={this._closeSessionNotesModal}
              />
            </div>
            <p className='session-notes-title'>Session Notes</p>
            <p className='session-notes-subtitle'>{session.name}</p>
            <p>Notes</p>
            <textarea
              value={session.notes}
              name='notes'
              onChange={handleSessionInput}
              className='session-notes-textarea'
            ></textarea>
            <div className='session-notes-ok-button-container'>
              <button
                className='session-notes-ok-button'
                onClick={this._closeSessionNotesModal}
              >
                OK
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }

  _checkSticky = e => {
    if (this.comp.scrollTop >= 149) {
      this.sticky.classList.add("sticky");
      this.blockContainer.classList.add("pad");
    } else {
      this.sticky.classList.remove("sticky");
      this.blockContainer.classList.remove("pad");
    }
  };

  _grabDurationRatio = () => {
    let sessionDuration = this.props.session.duration;
    let blocksDuration = 0;
    this.props.session.blocks.map(block => {
      let duration = parseInt(block.duration, 10);
      if (duration > 0) {
        blocksDuration += duration;
      }
    });
    let formattedSessionDuration = this._splitTime(sessionDuration);
    let formattedBlocksDuration = this._splitTime(blocksDuration);
    return `${formattedBlocksDuration.hours} hr ${formattedBlocksDuration.mins} min / ${formattedSessionDuration.hours} hr ${formattedSessionDuration.mins} min`;
  };

  _checkOverTime = () => {
    let sessionDuration = this.props.session.duration;
    let blocksDuration = 0;
    this.props.session.blocks.map(block => {
      let duration = parseInt(block.duration, 10);
      if (duration > 0) {
        blocksDuration += duration;
      }
    });
    return blocksDuration > sessionDuration;
  };

  _grabTimeRemaining = () => {
    let sessionDuration = this.props.session.duration;
    let blocksDuration = 0;
    this.props.session.blocks.map(block => {
      let duration = parseInt(block.duration, 10);
      if (duration > 0) {
        blocksDuration += duration;
      }
    });
    let timeLeft;

    if (sessionDuration > blocksDuration) {
      timeLeft = this._splitTime(sessionDuration - blocksDuration);
    } else if (sessionDuration === blocksDuration) {
      timeLeft = this._splitTime(0);
    } else {
      timeLeft = this._splitTime(blocksDuration - sessionDuration);
      return `${timeLeft.hours} hr ${timeLeft.mins} min over`;
    }

    return `${timeLeft.hours} hr ${timeLeft.mins} min left`;
  };

  _calculateStyle = () => {
    let sessionDuration = this.props.session.duration;
    let blocksDuration = 0;
    this.props.session.blocks.map(block => {
      let duration = parseInt(block.duration, 10);
      if (duration > 0) {
        blocksDuration += duration;
      }
    });
    let percentage =
      sessionDuration > 0 && blocksDuration > 0
        ? (blocksDuration / sessionDuration) * 100
        : 0;

    let displayPercentage = percentage > 100 ? "100%" : `${percentage}%`;
    return displayPercentage === "100%"
      ? {
          width: "100%",
          borderTopRightRadius: "500px",
          borderBottomRightRadius: "500px"
        }
      : { width: `${displayPercentage}` };
  };

  _closeSessionNotesModal = () => {
    this.setState({ sessionNotesModal: false });
  };

  _splitTime = minutes => {
    let hours = parseInt(minutes / 60, 10).toString();
    let mins = parseInt(minutes % 60, 10).toString();

    if (hours.length < 2) {
      hours = "0" + hours;
    }
    if (mins.length < 2) {
      mins = "0" + mins;
    }
    return { hours, mins };
  };
}

export default SessionBuilder;
