import React from "react";
import Axios from 'axios'
import { baseUrl } from './baseUrl'
import { isZipCode } from '../../utility/helperFunctions'
import CommunityCard from "./CommunityCard";
import CommunityPlaceholder from "./CommunityPlaceholder";
import NoNearbyCommunities from "./NoNearbyCommunities";
import LocationTypeModal from './LocationTypeModal'

// ASSETS
import './MapSidebar.scss'
import certBadge from '../../assets/images/pins/certified_preview_badge.svg'
import top50Badge from '../../assets/images/pins/top50_preview_badge.svg'
import academyBadge from "../../assets/images/pins/academy_preview_badge.png";

class MapSidebar extends React.Component {
  state = {
    searchFilter: "",
    communityLoader: false,
    renderedCommunities: [],
    zeroResults: false,
    response_formattedAddress: "",
    notALocation: false,
    timeout: null,
    filtersOpen: true,
    locationTypeModal: false,
  };

  render() {
    const { handleNoNearbyCommunity } = this.props;
    const {
      searchFilter,
      zeroResults,
      notALocation,
      renderedCommunities,
      response_formattedAddress,
      filtersOpen,
      locationTypeModal,
    } = this.state;

    return (
      <div id='MapSidebar'>
        <div className='top-search-area'>
          <p className='search-title'>Find a Program Training Facility</p>
          <div className='search-container'>
            <i className='fa fa-search mr-2' />
            <input
              type='text'
              value={searchFilter}
              name='searchFilter'
              className='search-input'
              placeholder='search a location...'
              onChange={this._handleInputChange}
              autoComplete='off'
              // maxLength={12}
              // disabled={disabled}
            />
          </div>
          <div className='filter-title-bar'>
            <p className='mb-1 filter-title' onClick={this._toggleFilterView}>
              <i
                className={`fa fa-chevron-down ${filtersOpen ? "" : "flip"}`}
                aria-hidden='true'
              />{" "}
              Filters
            </p>
            <button
              onClick={this._toggleLocationTypeModal}
              className='btn-rectangular-main-negative location-types-modal-link'
            >
              What are the location types?
            </button>
            {locationTypeModal && (
              <LocationTypeModal closeModal={this._toggleLocationTypeModal} />
            )}
          </div>
          {filtersOpen && (
            <div>
              <div className='filter-box-container'>
                <div
                  className='filter-check-box'
                  onClick={() => this.props.handleFilterClick("top50")}
                >
                  {this.props.filters.indexOf("top50") > -1 && (
                    <i className='fa fa-check' aria-hidden='true' />
                  )}
                </div>
                <div className='filter-description-container'>
                  <img src={top50Badge} alt='cert' className='filter-badge' />
                  <div className='filter-description-box'>
                    <p className='filter-description-title'>
                      Top 50 Coach Locations
                    </p>
                    <p className='filter-description'>
                      A Top 50 Coach delivers the best Operation 36 experience
                      possible at their facility.
                    </p>
                  </div>
                </div>
              </div>
              <div className='filter-box-container'>
                <div
                  className='filter-check-box'
                  onClick={() => this.props.handleFilterClick("certified")}
                >
                  {this.props.filters.indexOf("certified") > -1 && (
                    <i className='fa fa-check' aria-hidden='true' />
                  )}
                </div>
                <div className='filter-description-container'>
                  <img src={certBadge} alt='cert' className='filter-badge' />
                  <div className='filter-description-box'>
                    <p className='filter-description-title'>
                      Certified Coach Locations
                    </p>
                    <p className='filter-description'>
                      A certified coach has attended training to know how to
                      effectively deliver the Operation 36 development model.
                    </p>
                  </div>
                </div>
              </div>
              <div className='filter-box-container'>
                <div
                  className='filter-check-box'
                  onClick={() => this.props.handleFilterClick("academy")}
                >
                  {this.props.filters.indexOf("academy") > -1 && (
                    <i className='fa fa-check' aria-hidden='true' />
                  )}
                </div>
                <div className='filter-description-container'>
                  <img src={academyBadge} alt='cert' className='filter-badge' />
                  <div className='filter-description-box'>
                    <p className='filter-description-title'>
                      Academy Locations
                    </p>
                    <p className='filter-description'>
                      Academy locations leverage the Operation 36 Curriculum,
                      weekly classes, and 9-Hole Events using the Op 36
                      Development Model.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className='community-content-area'>
          {searchFilter.length ? (
            this.state.communityLoader ? (
              <div className='text-center'>
                <i className='fa fa-cog fa-spin load-cog' aria-hidden='true' />
              </div>
            ) : notALocation ? (
              <div className='text-center invalid-location'>
                <i className='fa fa-meh-o' aria-hidden='true' />
                <p>invalid location</p>
              </div>
            ) : zeroResults ? (
              <NoNearbyCommunities
                result={response_formattedAddress}
                handleSubmit={handleNoNearbyCommunity}
              />
            ) : (
              this._renderCards(renderedCommunities)
            )
          ) : (
            <CommunityPlaceholder />
          )}
          {/* ==========================================================
            ==============================================================
            steps for above text: 1) check if anything is being searched. If not, display "Placeholder". 2) then checks if it's loading. if so, say it's loading. 3) checks if the location cannot be found. 4) checks if there are any nearby locations 5) if it passes all these, then will render the list of communities
            ==============================================================
            ============================================================== */}
        </div>
      </div>
    );
  }

  _toggleLocationTypeModal = () => {
    this.setState({ locationTypeModal: !this.state.locationTypeModal })
  }

  _toggleFilterView = () => {
    this.setState((prevState) => ({
      filtersOpen: !prevState.filtersOpen,
    }));
  };

  _renderCards = (renderedCommunities) => {
    const { filters, handleCommunityClick } = this.props;
    if (filters.length === 0) {
      return renderedCommunities
        .sort((a, b) => a.distance.value - b.distance.value)
        .map((c) => (
          <CommunityCard
            key={c.id}
            community={c}
            onClick={handleCommunityClick}
          />
        ));
    } else {
      let filteredCommunities = [];
      if (filters.indexOf("certified") > -1) {
        renderedCommunities.forEach((c) => {
          if (c.is_certified) {
            filteredCommunities.push(c);
          }
        });
      }
      if (filters.indexOf("top50") > -1) {
        renderedCommunities.forEach((c) => {
          if (c.is_top50) {
            filteredCommunities.push(c);
          }
        });
      }
      if (filters.indexOf("academy") > -1) {
        renderedCommunities.forEach((c) => {
          if (c.level === "Academy") {
            filteredCommunities.push(c);
          }
        });
      }
      return filteredCommunities
        .sort((a, b) => a.distance.value - b.distance.value)
        .map((c) => (
          <CommunityCard
            key={c.id}
            community={c}
            onClick={handleCommunityClick}
          />
        ));
    }
  };

  _getNearbyCommunities = () => {
    const { searchFilter } = this.state;
    const { reCenterMap } = this.props;
    let returnedDistances = 0; // tracks number of distances that have been returned so we know when they have all come back
    this.setState(
      { communityLoader: true, zeroResults: false, notALocation: false },
      () => {
        let lat = "";
        let lng = "";
        const geocoder = new window.google.maps.Geocoder()
        geocoder.geocode(
          { address: isZipCode(searchFilter) ? searchFilter : `${searchFilter} city` },
          (response, status) => {
            if (status === window.google.maps.GeocoderStatus.OK) {
              let renderedCommunities = [];

              const service = new window.google.maps.DistanceMatrixService()
              lat = response[0].geometry.location.lat();
              lng = response[0].geometry.location.lng();

              reCenterMap(lat, lng);

              Axios.get(
                `${baseUrl}communities/map_search_view?lat=${lat}&lng=${lng}`
              ).then((res) => {
                let filteredCommunities = res.data.searched_communities;

                if (filteredCommunities.length > 0) {
                  filteredCommunities.forEach((c, index) => {
                    service.getDistanceMatrix(
                      {
                        origins: [new window.google.maps.LatLng(lat, lng)],
                        destinations: [
                          new window.google.maps.LatLng(
                            c.address.lat,
                            c.address.lng
                          ),
                        ],
                        travelMode: "DRIVING",
                        avoidHighways: false,
                        avoidTolls: false,
                        unitSystem: window.google.maps.UnitSystem.IMPERIAL,
                      },
                      (res, status) => {
                        if (status !== "OK") {
                          console.log(status);
                        } else {
                          if (
                            res.rows[0].elements[0].distance &&
                            res.rows[0].elements[0].duration
                          ) {
                            c.distance = res.rows[0].elements[0].distance;
                            c.duration = res.rows[0].elements[0].duration;
                            if (c.distance.value <= 80467) {
                              // distance returns in meters , this is 50 miles
                              renderedCommunities.push(c);
                            }
                          } else {
                            c.distance = null;
                            c.duration = null;
                          }
                        }
                        returnedDistances++;
                        if (filteredCommunities.length === returnedDistances) {
                          this.setState(
                            { renderedCommunities: renderedCommunities },
                            () => {
                              if (renderedCommunities.length < 1) {
                                // accounts for places being within 2 lng/lat degrees, but beyond 50 miles
                                this.setState({
                                  response_formattedAddress:
                                    response[0].formatted_address,
                                  communityLoader: false,
                                  zeroResults: true,
                                });
                              } else {
                                this.setState({ communityLoader: false });
                              }
                            }
                          );
                        }
                      }
                    );
                  });
                } else {
                  this.setState({
                    response_formattedAddress: response[0].formatted_address,
                    communityLoader: false,
                    zeroResults: true,
                  });
                }
              });
            } else if (status === "ZERO_RESULTS") {
              this.setState({ notALocation: true, communityLoader: false });
            } else {
              console.log("failed to load coordinates => " + status);
              return "none";
            }
          }
        );
      }
    );
  };

  _handleInputChange = (e) => {
    // uses setTimeout so it doesn't rerender until you're done typing.
    const prevState = this.state.searchFilter;
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (this.state.searchFilter.length > prevState.length) {
        clearTimeout(this.state.timeout);
        this.setState({
          communityLoader: true,
          timeout: setTimeout(this._getNearbyCommunities, 1000),
        });
      }
    });
  };
}

export default MapSidebar
