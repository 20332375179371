import * as types from "../actions/actionTypes";

// state = [] : Start out with no accounts
export default function goal(state = [], action) {
  switch (action.type) {
    case types.GET_GOAL:
      return action.goal;
    case types.GET_GOAL_STUDENTS:
      return Object.assign({}, state, {
        goal_students: action.goal_students
      });
    case types.GET_GOAL_INACTIVE_STUDENTS:
      return Object.assign({}, state, {
        inactive_goal_students: action.inactive_goal_students
      });
    default:
      return state;
  }
}
