import * as types from '../actions/actionTypes';

// state = [] : Start out with no accounts
export default function comments(state = [], action){
  switch(action.type){

  	case types.POST_ACTIVITY_COMMENT:
  		return [...state,
        Object.assign({}, action.comment)
      ];

  	case types.ADD_ACTIVITY_COMMENTS: 
  		return action.comments;

    // case types.ADD_MANY_COMMENTS:
    //   return action.comments;

    default:
      return state;
  }
}

// APPENDS ACTION.ACTIVITY ONTO THE STATE
// [...state, Object.assign({}, action.activity) ];
