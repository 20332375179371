import * as types from "../actions/actionTypes";

export default function consents(state = [], action) {
  switch (action.type) {
    case types.GET_USER_CONSENTS:
    case types.POST_USER_CONSENTS:
      return action.consents;
    default:
      return state;
  }
}
