import PropTypes from 'prop-types'
import { ReactComponent as BackArrowIcon } from '../../../assets/images/common/icons/back-arrow-icon.svg'
import styles from './FlowFooter.module.scss'

const FlowFooter = ({ isPublished, handleSaveClick, loader, dataLoaded, step, setStep, steps }) => (
  <section className={`fixed-bottom ${styles['footer-container']}`}>
    <div className={`row ${styles.content}`}>
      {!dataLoaded && (
        <>
          <div className={`${styles['step-button']} col-12 col-sm-6 d-flex justify-content-center align-items-center`}>
            {step > 1 && (
              <button
                className={`btn btn-primary ml-sm-auto ${styles['back-button']}`}
                onClick={() => setStep(step - 1)}
              >
                <div className='d-flex align-items-center'>
                  <BackArrowIcon aria-hidden='true' className={styles['back-icon']} />
                  <span className='mr-5'>Back</span>
                </div>
              </button>
            )}
          </div>

          <div className={`${styles['step-button']} col-12 col-sm-6 d-flex justify-content-center align-items-center`}>
            {step < steps.length ? (
              <button
                className={`btn btn-primary mr-sm-auto ${styles['next-button']}`}
                onClick={() => setStep(step + 1)}
              >
                <div className='d-flex align-items-center'>
                  <span className='ml-5'>Next</span>
                  <BackArrowIcon aria-hidden='true' className={styles['next-icon']} />
                </div>
              </button>
            ) : (
              <button
                className={`btn btn-primary mr-sm-auto ${styles['save-button']}`}
                onClick={() => handleSaveClick()}
                disabled={loader}
              >
                {isPublished ? 'Save' : 'Save or Publish'}
              </button>
            )}
          </div>
        </>
      )}
    </div>
  </section>
)

FlowFooter.propTypes = {
  isPublished: PropTypes.bool,
  handleSaveClick: PropTypes.func.isRequired,
  loader: PropTypes.bool.isRequired,
  dataLoaded: PropTypes.bool.isRequired,
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
  steps: PropTypes.array,
}

export default FlowFooter
