import Axios from '../axios'

export function connectToStripe() {
  return requestConnectToStripe()
}

export function refreshConnectToStripe() {
  return requestRefreshConnectToStripe()
}

export function removeStripeConnectedAccount(connectedAccountId) {
  return requestRemoveStripeConnectedAccount(connectedAccountId)
}

// REQUESTS

function requestConnectToStripe() {
  let requestUrl = `/api/v1/stripe_connected_accounts`

  return Axios.post(requestUrl)
}

function requestRefreshConnectToStripe() {
  let requestUrl = `/api/v1/stripe_connected_accounts/refresh`

  return Axios.get(requestUrl)
}

function requestRemoveStripeConnectedAccount(connectedAccountId) {
  let requestUrl = `/api/v1/stripe_connected_accounts/${connectedAccountId}`

  return Axios.delete(requestUrl)
}