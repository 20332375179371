import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// REDUX STUFF
import * as commonActions from "../../actions/commonActions";
import * as userActions from "../../actions/userActions";
import * as challengeActions from "../../actions/challengeActions";

// CHILDREN
import PageHeader from '../common/PageHeader/PageHeader'
import ChallengeIndex from "./views/ChallengeIndex";
import ChallengeNew from "./views/ChallengeNew";
import ChallengeShow from "./views/ChallengeShow";
import ChallengeEdit from "./views/ChallengeEdit";
import ChallengeDestroy from "./views/ChallengeDestroy";

import "./ChallengesPage.scss";

class ChallengesPage extends React.Component {
  state = {
    loader: true,
    view: "index",
    selectedChallenge: {}
  };

  componentWillMount() {
    const { common_actions, challenge_actions } = this.props;
    common_actions.setCurrentTab("Challenges");
    common_actions.showSideBar(true);
    common_actions.showNavBar(true);

    challenge_actions
      .getChallenges()
      .then(() => this.setState({ loader: false }));
  }

  render() {
    const { loader, view } = this.state;
    return (
      <div id='ChallengesPage'>
        <PageHeader title='Social Challenges' />
        {loader ? <p>LOADING</p> : this._renderPage(view)}
      </div>
    );
  }

  _renderPage = view => {
    const { challenges } = this.props;
    switch (view) {
      case "index":
        return (
          <ChallengeIndex
            challenges={challenges}
            goToNew={this._goToNew}
            handleMakeSelected={this._handleMakeSelected}
            leapfrogToDestroy={this._leapfrogToDestroy}
          />
        );
      case "new":
        return (
          <ChallengeNew
            returnToIndex={this._returnToIndex}
            handleCreateChallenge={this._handleCreateChallenge}
          />
        );
      case "show":
        return (
          <ChallengeShow
            returnToIndex={this._returnToIndex}
            // handleUpdateChallenge={this._handleUpdateChallenge}
            selectedChallenge={this.state.selectedChallenge}
            goToEdit={this._goToEdit}
            goToDestroy={this._goToDestroy}
          />
        );
      case "edit":
        return (
          <ChallengeEdit
            returnToIndex={this._returnToIndex}
            selectedChallenge={this.state.selectedChallenge}
            handleUpdateChallenge={this._handleUpdateChallenge}
          />
        );
      case "destroy":
        return (
          <ChallengeDestroy
            returnToIndex={this._returnToIndex}
            selectedChallenge={this.state.selectedChallenge}
            handleDestroyChallenge={this._handleDestroyChallenge}
            goToShow={this._goToShow}
          />
        );
      default:
        return <p>something went wrong - go see Derek</p>;
    }
  };

  _handleDestroyChallenge = id => {
    this.props.challenge_actions.deleteChallenge(id).then(() => {
      this.setState({ view: "index", selectedChallenge: {} });
    });
  };

  _handleUpdateChallenge = (id, data) => {
    this.props.challenge_actions.updateChallenge(id, data).then(() => {
      this.setState({ view: "index", selectedChallenge: {} });
    });
  };

  _handleCreateChallenge = data => {
    this.props.challenge_actions.createChallenge(data).then(() => {
      this.setState({ view: "index", selectedChallenge: {} });
    });
  };

  _handleMakeSelected = id => {
    let challenge = this.props.challenges.find(c => id === c.id);
    this.setState({ selectedChallenge: challenge }, () => {
      this.setState({ view: "show" });
    });
  };

  _leapfrogToDestroy = (e, id) => {
    e.stopPropagation();
    let challenge = this.props.challenges.find(c => id === c.id);
    this.setState({ selectedChallenge: challenge }, () => {
      this.setState({ view: "destroy" });
    });
  };

  _goToDestroy = () => {
    this.setState({ view: "destroy" });
  };

  _goToEdit = () => {
    this.setState({ view: "edit" });
  };

  _goToShow = () => {
    this.setState({ view: "show" });
  };

  _goToNew = () => {
    this.setState({ view: "new" });
  };

  _returnToIndex = () => {
    this.setState({ selectedChallenge: {}, view: "index" });
  };
}

function mapStateToProps(state, ownProps) {
  return {
    ui: state.ui,
    user: state.user.current_user,
    challenges: state.challenges
  };
}

function mapDispatchToProps(dispatch) {
  return {
    common_actions: bindActionCreators(commonActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
    challenge_actions: bindActionCreators(challengeActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengesPage);
