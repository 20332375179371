import SideBarItemExpanded from './SideBarItemExpanded'
import SideBarItem from './SideBarItem'
import { NAVIGATION, sideBarItemsCoach, sideBarItemsAdmin, sideBarItemsStudents, sideBarItemsStudentAdmin, sideBarItemsLeadCoach } from '../../../misc/Constants'
import styles from '../../../assets/styles/components/common/ExpandableSideBar.module.scss'
import { useSelector } from 'react-redux'
import SideBarLegalLinks from './SideBarLegalLinks'

const SideBarNavigationItems = () => {
  const user = useSelector( state => state.user.current_user )
  const navigation = useSelector (state => state.ui.sideBarState)
  const collapsed = navigation === NAVIGATION.COLLAPSED

  const userPermissions = {
    Admin: sideBarItemsAdmin,
    Coach: user.lead_coach ? [...sideBarItemsCoach(user?.communities[0]?.id), ...sideBarItemsLeadCoach] : sideBarItemsCoach(user?.communities[0]?.id),
    Student: user.type === 'Student' && user.family_admin ? sideBarItemsStudentAdmin(user.communities[0].id) : sideBarItemsStudents,
  }

  return (
    <div className={collapsed ? styles.navigationItems : styles.navigationItemsExpanded}>
      {userPermissions[user.type].map((item, index) => collapsed ?
        <SideBarItem key={item.title}
          icon={item.icon}
          title={item.title}
          index={index}
          links={item.links}/> :
        <SideBarItemExpanded key={item.title} icon={item.icon} title={item.title} index={index} links={item.links}/>)}
      {!collapsed && <SideBarLegalLinks />}
    </div>
  )
}

export default SideBarNavigationItems
