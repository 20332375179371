import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types'
import AvatarPlaceholder from "../../assets/images/mock/StockProfilePic.png";
import SmallMilestoneIcon from "../common/SmallMilestoneIcon";
import "./StudentCard.scss";

const StudentCard = ({ student }) => {
  const navigate = useNavigate()

  const navigateTo = useCallback(
    () => {
      const { id } = student

      navigate(
        `/profile/${id}`,
        { state: { userId: id } }
      )
    },
    [ navigate, student ]
  )

  return (
    <div
      id='LargeStudentCardContainer'
      className={`col-xl-3 col-lg-4 col-md-6 student-card-container animated fadeIn apply-min`}
      onClick={navigateTo}
    >
      <div className={`card ${student.family_admin && "student-admin"}`}>
        <div className='student-card'>
          {
            //   student.family_admin ? (
            //   <div>
            //     <div className='student-admin-pill'>
            //       <p className='student-admin-text'>Family Admin</p>
            //     </div>
            //     {student.is_golfer && !division && (
            //       <div className='student-admin-icon-wrapper'>
            //         <i className='fa fa-user-circle' aria-hidden='true' />
            //       </div>
            //     )}
            //   </div>
            // ) : (
            // student.is_golfer && !division && (
            //   <div className='student-icon-wrapper'>
            //     <i className='fa fa-user-circle' aria-hidden='true' />
            //   </div>
            // )
            // )
          }
          <div className='d-flex'>
            <div className='round-image-wrapper'>
              <img
                className='round-image'
                src={
                  student.avatar ? student.avatar.file_url : AvatarPlaceholder
                }
                alt={`${student.first_name} ${student.last_name}`}
              />
            </div>
            <div className='student-info-wrapper'>
              {student.family_admin && (
                <div className='student-admin-pill'>
                  <p className='student-admin-text'>Family Admin</p>
                </div>
              )}
              <p className='m-0 student-name text-truncate'>
                {student.first_name} {student.last_name}
              </p>
              <p className='m-0 student-username'>
                {student.username ? student.username : student.email}
              </p>
            </div>
          </div>
          {student.is_golfer && (
            <div className='student-division-indicator ml-2'>
              <SmallMilestoneIcon division={student.current_formal_division} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

StudentCard.propTypes = {
  student: PropTypes.shape({
    current_formal_division: PropTypes.number,
  }),
}

export default StudentCard;
