import PropTypes from 'prop-types'
import Modal from '../common/Modal'
import CommunityContactForm from './CommunityContactForm'

import classes from './CommunityContactForm.module.scss'

const CommunityContactModal = ({ communityId, communityName, ...props }) => (
  <Modal
    className={classes.modal}
    isDarkModal
    {...props}
  >
    <CommunityContactForm
      id={communityId}
      name={communityName}
    />
  </Modal>
)

CommunityContactModal.propTypes = {
  communityId: PropTypes.number,
  communityName: PropTypes.string,
}

export default CommunityContactModal
