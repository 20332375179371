import PropTypes from 'prop-types'

import { ReactComponent as WarningIcon } from '../../../../assets/images/common/icons/warning-icon.svg'
import Modal from '../../../common/Modal'
import SubmitButton from '../../../common/buttons/SubmitButton'
import styles from './ExitModal.module.scss'

const ExitModal = ({ showModal, closeModal, onExit }) =>
  <Modal
    isOpen={showModal}
    onClose={closeModal}
    className={styles['exit-program-builder-modal-content']}
  >
    <section className='animated fadeIn'>
      <div>
        <div className='d-flex flex-column justify-content-center align-items-center'>
          <WarningIcon className='mt-2' />
          <p className={styles.title}>Attention!</p>
          <p className={styles.message}>
            Are you sure you want to stop building your program? If you exit all of your progress will be deleted.
          </p>
        </div>
        <div className='row py-3'>
          <div className='col-12'>
            <SubmitButton
              onClick={closeModal}
              buttonMessage='Back to Program'
              buttonColor='orange-button'
            />
          </div>
          <div className='col-12'>
            <SubmitButton
              onClick={onExit}
              buttonMessage='Exit'
              buttonColor='navy-button'
            />
          </div>
        </div>
      </div>
    </section>
  </Modal>

ExitModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onExit: PropTypes.func.isRequired,
}

export default ExitModal
