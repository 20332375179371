import React from 'react'

// CHILDREN
import Avatar from '../../assets/images/mock/StockProfilePic.png'

// ASSETS
import './UserRow.scss'
import { PropTypes } from 'prop-types'

const UserRow = ({ user, toggleModal }) => (
  <button id='UserRow' className='row w-100' onClick={() => toggleModal(user)}>
    <div className='col-1'>
      <p>{user.id}</p>
    </div>
    <div className='col-3'>
      <div className='smaller-round-image-wrapper'>
        <img
          src={user.avatar ? user.avatar.file_url : Avatar}
          className='round-image'
          alt={`${user.first_name} ${user.last_name}`}
        />
      </div>
      <p>
        {user.first_name} {user.last_name}
      </p>
    </div>
    <div className='col-1'>
      <p>{user.type}</p>
    </div>
    <div className='col-3'>
      <p>{user.email ? user.email : user.username}</p>
    </div>
    <div className='col-3'>
      <p>
        {user.communities && user.communities.length > 0
          ? user.communities[0].name
          : 'N/A'}
      </p>
    </div>
  </button>
)

UserRow.propTypes = {
  user: PropTypes.object,
  toggleModal: PropTypes.func,
}

export default UserRow
