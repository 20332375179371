import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as goalActions from "../../../actions/goalActions";
import ImageUploader from "../../common/form/ImageUploader";
import NewGoalTask from "../../profile/Goals/NewGoalTask";
import moment from "moment";
import Loader from "react-loaders";

class GoalForm extends Component {
  state = {
    creatorId: this.props.user && this.props.user.id,
    userId: this.props.user && this.props.user.id,
    goalName: "",
    goalVideo: "",
    goalDescription: "",
    goalSubjectId: 0,
    goalTaskArray: [0],
    goalTasks: [],
    goalLoader: false,
    submitClicked: false,
    goalCompletion: 0,
    goalImage: "",
    uploadPhotoFileSrc: "",
    uploadPhotoName: "",
    uploadPhotoFile: "",
    didUploadPhoto: false,
    uploadingPhoto: false,
    uploadPhotoFileSources: [],
    pictures: [],
    is_template: false,
    activityDate: moment().format()
  };

  submitGoal = () => {
    let goalTasks = [...this.state.goalTasks];
    const { closeModal, goal_actions, fetchGoals } = this.props;
    this.setState(
      {
        goalLoader: true,
        is_template: true
      },
      () => {
        goal_actions.createGoal(this.state, goalTasks).then(() => {
          this.setState({ goalLoader: false });
          fetchGoals();
          closeModal();
        });
      }
    );
  };
  createGoalTask = () => {
    let taskArray = [...this.state.goalTaskArray];
    let lastNum = taskArray[taskArray.length - 1];

    let newNum = lastNum + 1;

    taskArray.push(newNum);

    this.setState({
      goalTaskArray: taskArray
    });
  };

  deleteGoalTask = () => {
    let taskArray = [...this.state.goalTaskArray];
    let goalArray = [...this.state.goalTasks];
    taskArray.splice(-1, 1);
    goalArray.splice(-1, 1);
    this.setState({
      goalTaskArray: taskArray,
      goalTasks: goalArray
    });
  };

  onObjectChange = (index, desc, progress, id) => {
    let goalTasks = [...this.state.goalTasks];

    goalTasks[index] = [progress, desc, id];
    this.setState(
      {
        goalTasks: goalTasks
      },
      () => {
        let percentages = [0];
        let total = 0;

        this.state.goalTasks.map(task => {
          percentages.push(task[0]);
        });

        total = percentages.reduce(function(acc, val) {
          return acc + val;
        });
        this.setState({
          goalCompletion: total
        });
      }
    );
  };

  handleInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  selectInputFile() {
    document.getElementById("input-photo-upload").click();
  }

  handlePictureChange = event => {
    if (event.target.files.length > 0) {
      let selectedFiles = event.target.files;
      let { pictures } = this.state;

      for (let i = 0; i < selectedFiles.length; i++) {
        pictures.push(selectedFiles.item(i));
      }
      this.setState({
        pictures: pictures
      });
    }
  };

  resetPictures = () => {
    this.setState({
      pictures: []
    });
  };

  displayImageSelect() {
    if (this.state.uploadingPhoto) {
      return (
        <div id="GoalImage" className="text-center uploading-images-container">
          <i
            className="fa fa-circle-o-notch fa-spin fa-lg fa-3x uploading-images-icon"
            aria-hidden="true"
          />
          <p className="mt-2 uploading-images-text">uploading image(s)</p>
        </div>
      );
    } else {
      return (
        <ImageUploader
          label="Max Photos: 5 - Max file size: 5mb - supported file types: jpg | png"
          reset={this.resetPictures}
          id="input-photo-upload"
          onChange={this.handlePictureChange}
          accept={"image/*"}
          selectImages={this.selectInputFile}
          images={this.state.pictures}
          imageSources={this.state.uploadPhotoFileSources}
        />
      );
    }
  }

  renderPercentageText = () => {
    if (this.state.goalCompletion < 100) {
      return "set objective importance - each objective must add up to 100%";
    } else if (this.state.goalCompletion === 100) {
      return "You have successfully added objectives";
    } else if (this.state.goalCompletion > 100) {
      return "Delete objectives or decrease progress";
    }
  };

  renderGoalTasks = () => {
    let lastTaskIndex = this.state.goalTaskArray.lastIndexOf(
      this.state.goalTaskArray[this.state.goalTaskArray.length - 1]
    );
    return this.state.goalTaskArray.map((task, index) => {
      return (
        <NewGoalTask
          sendData={getData => {
            this.getData = getData;
          }}
          key={index}
          index={index}
          onObjectChange={this.onObjectChange}
          lastIndex={lastTaskIndex}
        />
      );
    });
  };

  render() {
    const {
      goalName,
      goalVideo,
      goalDescription,
      goalCompletion,
      goalTaskArray,
      goalLoader
    } = this.state;
    const { closeModal } = this.props;
    return (
      <div>
        {goalLoader ? (
          <Loader message="Saving goal" />
        ) : (
          <div className="row d-flex align-items-center flex-column h-100 mt-5">
            <div className="col-md-8 mx-auto">
              <div className="row">
                <div className="col-md-6">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="program-activity-title">Name</label>
                      <input
                        type="text"
                        name="goalName"
                        className="form-control"
                        id="goal-name"
                        placeholder="Enter a name for the goal"
                        onChange={this.handleInputChange}
                        value={goalName}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="program-activity-title">
                        Video Url (optional)
                      </label>
                      <input
                        type="text"
                        name="goalVideo"
                        className="form-control"
                        id="goal-video"
                        placeholder="Enter a Youtube or Vimeo URL"
                        onChange={this.handleInputChange}
                        value={goalVideo}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="goalDescription">Description</label>
                      <textarea
                        type="text"
                        rows={3}
                        name="goalDescription"
                        className="form-control "
                        placeholder="Insert why this goal is important to you..."
                        value={goalDescription}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="my-2 pl-2">
                    <label className="mt-3 my-0" htmlFor="goal-image">
                      Goal Image Upload (optional)
                    </label>
                  </div>
                  {this.displayImageSelect()}
                </div>
                <div className="col-md-12 goal-objective-percentage-wrapper">
                  <label htmlFor="goalObjectives">Goal Objectives</label>
                  <div className="goal-percentage-wrapper hidden-sm-down">
                    <p className="goal-percentage-desc">
                      {this.renderPercentageText()}
                    </p>
                    <div
                      className="goal-percentage-tracker"
                      style={{
                        backgroundColor: goalCompletion === 100 ? "#33d9b2" : ""
                      }}
                    >
                      {`Goal Completion: ${goalCompletion}%`}
                    </div>
                  </div>
                </div>
                {this.renderGoalTasks()}
                <div className="col-md-12 goal-objective-border" />
                <div className="col-md-12 goal-objective-icon-wrapper">
                  {goalTaskArray.length > 1 ? (
                    <i
                      className="fa fa-minus goal-objective-minus-icon"
                      onClick={this.deleteGoalTask}
                    />
                  ) : null}
                  <i
                    className="fa fa-plus goal-objective-add-icon"
                    onClick={this.createGoalTask}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="row bg-white pb-3">
          <div className="col-6 mx-auto">
            <div className="d-flex justify-content-center align-content-center">
              <button
                className="btn btn-dark log-actions-button m-2"
                onClick={closeModal}
                disabled={goalLoader}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary text-center log-actions-button m-2"
                onClick={this.submitGoal}
                disabled={goalLoader}
              >
                Add
                {goalLoader && "ing"} Goal
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    ui: state.ui,
    user: state.user.current_user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    goal_actions: bindActionCreators(goalActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GoalForm);
