import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as proShopActions from "../../../../actions/proShopActions";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// import SinglePhotoUploader from "../singlePhotoUploader/SinglePhotoUploader";
import Error from "../../../common/ErrorMessage";

import "../configPageItem/ConfigPageItem.scss";
import "./ConfigPageItemCustom.scss";

// This is like a configPageItem, but you can select photos to
// upload and display
class configPageItemCustom extends React.Component {
  state = {
    images: []
  };
  componentDidMount() {
    let imgArr = [];
    const {
      community: { pinned_ads, coach_ads }
    } = this.props;
    pinned_ads.forEach(a => {
      let image = coach_ads.find(c => c.id === a);
      imgArr.push(image);
    });
    this.setState({ images: imgArr });
  }

  componentWillReceiveProps(nextProps) {
    let imgArr = [];

    const {
      community: { pinned_ads, coach_ads }
    } = nextProps;
    pinned_ads.forEach(a => {
      let image = coach_ads.find(c => c.id === a);
      imgArr.push(image);
    });
    this.setState({ images: imgArr });
  }

  render() {
    const {
      screenName,
      screenDesc,
      screenTitle,
      checked,
      handleCheck,
      thumbnail,
      needFileUpload,
      openImageBank,
      imgSelection
      // dragReorderHandler
    } = this.props;

    const { images } = this.state;

    const checkedClass = checked ? " config-item-checkbox-checked" : "";
    const message =
      imgSelection.length > 0 ? "Edit selection" : "Choose images";

    return (
        <div className='config-item'>
          <div
            className={"config-item-checkbox" + checkedClass}
            onClick={() => handleCheck(screenTitle)}
          >
            {checked ? <i className='fa fa-check' aria-hidden='true' /> : null}
          </div>
          <img
            src={thumbnail}
            alt={screenTitle + " thumbnail"}
            className='config-item-preview-img'
          />
          <div>
            <h3 className='config-item-label'>{screenName}</h3>
            <p className='config-item-desc'>{screenDesc}</p>
            <div className='config-item-image-array'>
              {images.length > 0 && (
                <DragDropContext onDragEnd={this._handleDragEnd}>
                  {/* <DragDropContext onDragEnd={dragReorderHandler}> */}
                  <Droppable droppableId={`photo-area`} direction='horizontal'>
                    {(provided, snapshot) => (
                      <div
                        className='config-item-selected-image-container'
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {images.map((i, idx) => (
                          <Draggable
                            key={idx}
                            draggableId={`photo_${idx}`}
                            index={idx}
                          >
                            {(provided, snapshot) => (
                              <div
                                className='config-item-selected-image-wrapper'
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <img
                                  className='config-item-selected-image-thumbnail'
                                  src={i.file_url}
                                  alt={"Selected image " + idx}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )}
              <button onClick={openImageBank} className='no-bg-text-btn'>
                {message}
              </button>
            </div>
            {checked && needFileUpload ? (
              <div className='config-item-error-msg'>
                <Error error='Please choose at least 1 image to display' />
              </div>
            ) : null}
          </div>
        </div>
    );
  }

  _handleDragEnd = e => {
    const { images } = this.state;
    const imagesCopy = [...images];

    if (e.destination && e.destination !== e.source) {
      const oldLoc = e.source.index;
      const newLoc = e.destination.index;

      let draggedElem = imagesCopy.splice(oldLoc, 1)[0];

      imagesCopy.splice(newLoc, 0, draggedElem);

      this.setState({ images: imagesCopy }, () => {
        this.props.pro_shop_actions.pinAds(
          this.props.user.communities[0].id,
          this.state.images.map(i => i.id)
        );
      });
    }
  };
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user.current_user,
    community: state.proShop.community_ads
  };
}

function mapDispatchToProps(dispatch) {
  return {
    pro_shop_actions: bindActionCreators(proShopActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(configPageItemCustom);
