import CommunityLeaderboardStudentRow from './CommunityLeaderboardStudentRow'
import styles from './CoachDashboardOp36.module.scss'
import CommunityLeaderboardStatsItem from './CommunityLeaderboardStatsItem'
import PropTypes from 'prop-types'
import InfiniteScroll from '../../infinite-scroll/InfiniteScroll'
import classNames from 'classnames'
import { ReactComponent as GolferIcon } from '../../../assets/images/common/communityLeaderboard/golfer-half-icon.svg'

const CommunityLeaderboardRanking = ({ statsItems, students, fetchRanking, hasMore, tabId, loader, clearFilters, filtersApplied }) => (
  <>
    {statsItems &&
      <div className='d-flex justify-content-center align-items-center'>
        {statsItems.map((item, index) =>
          <CommunityLeaderboardStatsItem key={`${index}-${tabId}`} icon={item.icon} text={item.text} data={item.data}/>
        )}
      </div>
    }
    <div
      className={classNames(styles.communityLeaderboardRanking, { [styles.communityLeaderboardRankingHeight]: !statsItems })}
      id='leaderboardRanking'
    >
      {students.length === 0 && !loader &&
        <div className={classNames('d-flex flex-column justify-content-center align-items-center text-center', styles.noActivities)}>
          <GolferIcon/>
          <p className={styles.noActivitiesTitle}>No Activities</p>
          <p className={styles.noActivitiesText}>
            There is no activity in the selected range. Please adjust your filters or check back later after your programs start.
          </p>
          {filtersApplied &&
            <button
              className={classNames('ml-3 w-30 mt-3 animation-shrink d-flex align-items-center justify-content-center', styles.resetFiltersButton)}
              onClick={clearFilters}
            >
              Clear Filters<i className='ml-2 fa fa-close' />
            </button>
          }
        </div>
      }
      <InfiniteScroll
        dataLength={students.length}
        next={fetchRanking}
        hasMore={hasMore}
        endOfList={students.length >= 7 && !hasMore}
        scrollableTarget='leaderboardRanking'
      >
        {students.map((student, index) =>
          <CommunityLeaderboardStudentRow
            key={`${student.userId}-${tabId}`}
            rank={index + 1}
            profile={student.avatar?.fileUrl}
            name={student.name}
            points={student.points}
          />
        )}
      </InfiniteScroll>
    </div>
  </>
)

CommunityLeaderboardRanking.propTypes = {
  students: PropTypes.array,
  statsItems: PropTypes.array,
  fetchRanking: PropTypes.func,
  hasMore: PropTypes.bool,
  tabId: PropTypes.number,
  loader: PropTypes.bool,
  clearFilters: PropTypes.func,
  filtersApplied: PropTypes.bool,
}

export default CommunityLeaderboardRanking
