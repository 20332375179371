import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import classNames from 'classnames'

import classes from './BookingForm.module.scss'
import Input from '../../common/form/Input'
import { useState } from 'react'
import { flatServerFormErrors } from '../../../utility/helperFunctions'
import { useContext } from 'react'
import { AccountContext } from '../AccountContext'

const BookingForm = () => {
  const dispatch = useDispatch()
  const { user, submitDisabled, updateAction } = useContext(AccountContext) // Note: user could be golfer OR current_user

  const [isUpdating, setIsUpdating] = useState(false)
  const [hasChanges, setHasChanges] = useState(false)
  const [errors, setErrors] = useState({})

  const validateForm = formData => {
    setErrors({})
    const formErrors = {}

    const bookingUrl = formData.get('bookingUrl')

    if (bookingUrl && !URL.canParse(bookingUrl)) {
      formErrors['booking_url'] = 'Invalid URL'
    }

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors)
      return false
    }

    return true
  }

  const handleSubmitForm = async e => {
    e.preventDefault()
    const formData = new FormData(e.target)

    if (validateForm(formData)) {
      const partialUser = {
        id: user.id,
        email: user?.email,
        username: user?.username,
        booking_url: formData.get('bookingUrl'),
      }

      try {
        setIsUpdating(true)
        const newState = await dispatch(updateAction(partialUser))

        if (newState.type === 'UI_ERROR') {
          setErrors(flatServerFormErrors(newState?.error))
        } else {
          toast.success('User has been successfully updated!')
        }
      } catch {
        toast.error('User could not be updated. Please check again later.')
      } finally {
        setIsUpdating(false)
        setHasChanges(false)
      }
    }
  }

  const handleDetectChanges = (e) => {
    const formData = new FormData(e.currentTarget)
    const bookingUrl = formData.get('bookingUrl')

    const hasChanges_ =
      (user?.booking_url || '') !== (bookingUrl || '')

    if (hasChanges_ !== hasChanges) {
      setHasChanges(hasChanges_)
    }
  }

  return (
    <form onSubmit={handleSubmitForm} onInput={handleDetectChanges} className={classNames(classes.container, 'animated fadeIn')}>
      <div className={classNames(classes.extraMargin, 'row')}>
        <h4 className={classes.title}>Booking Details</h4>
      </div>

      <div className={classNames(classes.extraMargin, 'row')}>
        <label htmlFor='bookingUrl'>Booking Link</label>
        <Input
          type='text'
          id='bookingUrl'
          name='bookingUrl'
          defaultValue={user?.booking_url}
          placeholder='Enter Booking Link'
          classNames={{ inputContainer: classes.accountInputContainer }}
          error={errors?.booking_url}
        />
      </div>

      <div className='row'>
        <button
          type='submit'
          className='btn btn-primary'
          disabled={isUpdating || submitDisabled || !hasChanges}
        >
          Sav{isUpdating ? 'ing' : 'e'} Changes
        </button>
      </div>
    </form>
  )
}

export default BookingForm
