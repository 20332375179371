import { useMemo } from 'react'
import { components } from 'react-select'
import PropTypes from 'prop-types'

import AvatarPlaceholder from '../../../../assets/images/mock/StockProfilePic.png'
import { ReactComponent as DeleteIcon } from '../../../../assets/images/common/icons/modal-X-icon.svg'
import Select from '../../../common/form/Select'

import styles from './CoachSelect.module.scss'

const CoachSelect = ({ communityCoaches, coaches, errors, onAddCoach, onDeleteCoach }) => {
  const options = useMemo(() => (
    [...communityCoaches.length > 0 ? communityCoaches.filter(coach => !coaches.map(c => c.id).includes(coach.id)) : []]
  ), [communityCoaches, coaches])

  /* eslint-disable react/prop-types */
  const Option = ({ data, ...props }) => (
    <components.Option {...props}>
      <div className='d-flex align-items-center' key={data.id}>
        <div className={styles['coach-avatar']}>
          <img className='round-image' src={data.avatar ? data.avatar.fileUrl : AvatarPlaceholder} alt='coach' />
        </div>
        <p className={styles['coach-name']}>{data.firstName} {data.lastName}</p>
      </div>
    </components.Option>
  )

  return (
    <div id='CoachSelect'>
      {coaches.length > 0 && (
        <div className='row'>
          {coaches.map(coach => (
            <div className='col-12 col-sm-6 col-lg-4' key={coach.id}>
              <div className={`d-flex justify-content-center align-items-center ${styles['selected-coach-card']}`} key={coach.id}>
                <div className={styles['coach-avatar']}>
                  <img className='round-image' src={coach.avatar ? coach.avatar.fileUrl : AvatarPlaceholder} alt='coach' />
                </div>
                <p className={styles['coach-name']}>{coach.firstName} {coach.lastName}</p>

                <DeleteIcon className={`${styles['delete-icon']} ml-auto`} aria-hidden='true' onClick={() => onDeleteCoach(coach)} />
              </div>
            </div>
          ))}
        </div>
      )}

      <Select
        name='coach-selector'
        value={-1}
        options={options}
        components={{
          Option,
        }}
        onChange={onAddCoach}
        placeholder='Select the coaches that would teach this program'
        isSearchable={false}
        menuPlacement='auto'
        minMenuHeight={300}
        menuPosition='fixed'
        error={errors.coachesIds}
      />
    </div>
  )
}

CoachSelect.propTypes = {
  communityCoaches: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    price: PropTypes.number,
    description: PropTypes.string,
  })),
  coaches: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    price: PropTypes.number,
    description: PropTypes.string,
  })),
  errors: PropTypes.shape({
    coachesIds: PropTypes.arrayOf(PropTypes.string),
  }),
  onAddCoach: PropTypes.func,
  onDeleteCoach: PropTypes.func,
  noCreateOption: PropTypes.bool,
}

export default CoachSelect
