import React from 'react';
import PropTypes from 'prop-types'

// ASSETS
import Op36Icon from '../../../assets/images/logos/OP36_clean_icon.png';

import '../../classes/CoachClassCard/CoachClassCard.scss';
import './Goals.scss'

class GoalCard extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {};
  }

  render() {
    return (
      <div className="coachClassCard col-md-12">
        <div className="card">

          <div className="card-bg">
            <div className="overlay-5 h-100">
              <div className="row m-0 p-0">
                <div className="col-4 m-0 p-0">
                </div>
                <div className="col-4 m-0 p-0">
                  <div className="op36-curriculum-image-container">
                    <img src={Op36Icon} alt="Operation 36 Curriculum" className="op36-curriculum-image" />
                  </div>
                </div>
                <div className="col-4 m-0 p-0">
                </div>
              </div>
              <div className="row m-0 p-0">
                <div className="col-1 m-0 p-0">
                </div>
                <div className="col-10 m-0 p-0">
                  <h1 style={{ fontSize: '30px' }}>Operation 36 Curriculum</h1>
                </div>
                <div className="col-1 m-0 p-0">
                </div>
              </div>
              <h2 style={{ fontSize: '25px' }}>{this.props.currentRank.name}</h2>
              <div className="progress goal-progressbar">
                <div className="progress-bar" role="progressbar" style={{ width: `${this.props.percentage}%` }} aria-valuenow={this.props.percentage} aria-valuemin="0" aria-valuemax="100">{this.props.percentage}%</div>
              </div>
              <div className="row">
                <div className="col-md-3">
                </div>
                <div className="col-md-6 goal-card-btn-wrapper">
                  <button className="btn btn-primary text-white mb-3" onClick={this.props.incrementPage}>
                    VIEW GOALS
                  </button>
                </div>
                <div className="col-md-3">
                </div>
              </div>
            </div>
          </div>

          {/* <div className="card-content text-center">
            <div className="row mt-5">
              <div className="col-md-6">
                <p>Current</p>
                <h1>{this.props.incompleteObjectives.length}</h1>
              </div>
              <div className="col-md-6">
                <p>Completed</p>
                <h1>{this.props.completedCount}</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
              </div>
              <div className="col-md-6">
                <button className="btn btn-primary text-white mb-3" onClick={this.props.incrementPage}>
                  VIEW GOALS
                </button>
              </div>
              <div className="col-md-3">
              </div>
            </div>
          </div> */}
        </div>
      </div>
    )
  }
}

GoalCard.propTypes = {
  currentRank: PropTypes.shape({
    name: PropTypes.string,
  }),
}

//this.props.deleteClass(this.props.course)
export default GoalCard;
