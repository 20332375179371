import * as types from "../actions/actionTypes";

export default function representativeActionTypes(state = [], action) {
  switch (action.type) {
    case types.GET_REPRESENTATIVE_ACTION_TYPES:
      return action.representative_action_types;

    default:
      return state;
  }
}
