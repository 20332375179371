import React from 'react'

import PropTypes from 'prop-types'
import './WorkflowProgressBar.scss'

/* eslint-disable complexity */
const WorkflowProgressBar = props => {
  const titles = props.titles

  return (
    <div id='WorkflowProgressBar' className='hidden-md-down'>
      <div
        className={'bar page1 clickable'}
        aria-hidden='true'
        onClick={
          props.page === 1
            ? null
            : props.pageProgress >= 1
              ? () => props.handleClick(1)
              : null
        }
        style={
          props.pageProgress >= 1
            ? {
              background: '#ff6633',
              color: '#fff',
              border: '2px solid #fff',
              borderRight: 'none',
            }
            : {}
        }
      >
        1. {titles[0]}
      </div>
      <div
        className='triangle clickable'
        aria-hidden='true'
        onClick={
          props.page === 1
            ? null
            : props.pageProgress >= 1
              ? () => props.handleClick(1)
              : null
        }
      />
      <div
        className={`bar page2 ${props.pageProgress >= 2 && 'clickable'}`}
        aria-hidden='true'
        onClick={
          props.page === 2
            ? null
            : props.pageProgress >= 2
              ? () => props.handleClick(2)
              : null
        }
        style={
          props.pageProgress >= 2
            ? { background: '#ff6633', color: '#fff', border: '2px solid #fff' }
            : {}
        }
      >
        2. {titles[1]}
      </div>
      <div
        className={`triangle triangle2 ${props.pageProgress >= 2 && 'clickable'}`}
        aria-hidden='true'
        onClick={
          props.page === 2
            ? null
            : props.pageProgress >= 2
              ? () => props.handleClick(2)
              : null
        }
        style={
          props.pageProgress < 2
            ? {
              background: '#fff',
              borderTop: '2px solid #ff6633',
              borderRight: '2px solid #ff6633',
            }
            : {}
        }
      />
      <div
        className={`bar page3 ${props.pageProgress >= 3 && 'clickable'}`}
        aria-hidden='true'
        onClick={
          props.page === 3
            ? null
            : props.pageProgress >= 3
              ? () => props.handleClick(3)
              : null
        }
        style={
          props.pageProgress >= 3
            ? { background: '#ff6633', color: '#fff', border: '2px solid #fff' }
            : {}
        }
      >
        3. {titles[2]}
      </div>
      <div
        className={`triangle triangle3 ${props.pageProgress >= 3 && 'clickable'}`}
        aria-hidden='true'
        onClick={
          props.page === 3
            ? null
            : props.pageProgress >= 3
              ? () => props.handleClick(3)
              : null
        }
        style={
          props.pageProgress < 3
            ? {
              background: '#fff',
              borderTop: '2px solid #ff6633',
              borderRight: '2px solid #ff6633',
            }
            : {}
        }
      />
    </div>
  )
}

WorkflowProgressBar.propTypes = {
  titles: PropTypes.arrayOf(PropTypes.string),
  page: PropTypes.number,
  pageProgress: PropTypes.number,
  handleClick: PropTypes.func,
}

export default WorkflowProgressBar
