import React from "react";
import TextInput from "../../../common/form/TextInput";

import "./LessonBasicDetails.scss";

class LessonBasicDetails extends React.Component {
  render() {
    const {
      lessonPlanName,
      defaultDuration,
      tagInput, // state of tag input
      textTags, // array of tags
      notes,
      video_url,
      onChange, // basic input handler
      textTagChange, // handler to check if user hits enter on tag input. if so, makes a tag
      removeTag, // function to act as click handler to remove tags
      photo, // data/info on photo => is an object with properties described below
      onChangePhoto, // handler to add photo
      clearPhoto, // function to remove the photo from state
      failedValidation, // array of what failed
      pageProgress, // page progress - the file input breaks if you set the value programmatically, so coming BACK to this page after hitting next once doesn't set this value.
      is_admin // whether or not the current user is an admin - controls if they can add a video
    } = this.props;
    return (
      <div id='LessonBasicDetails'>
        <div className='outer-container'>
          <div className='picture-area'>
            <div className='picture-box'>
              <TextInput
                name={""}
                id={"input-photo-upload"}
                type={"file"}
                accept={"image/*"}
                className={"form-control-file photoInput hide"}
                onChange={onChangePhoto}
                placeholder={"Add a Different Photo"}
                value={pageProgress > 1 ? "" : photo.uploadPhotoName}
                error={""}
              />
              {photo && photo.uploadPhotoFileSrc ? (
                <div className='photo-preview-container'>
                  <p className='remove-photo-button' onClick={clearPhoto}>
                    Remove Photo{" "}
                    <i className='fa fa-times' aria-hidden='true' />
                  </p>
                  <div
                    className='center-cropped'
                    style={{
                      backgroundImage: "url(" + photo.uploadPhotoFileSrc + ")"
                    }}
                  />
                </div>
              ) : (
                <div
                  className='text-center lp-photo-uploader'
                  onClick={this._selectInputFile}
                >
                  <i className='fa fa-picture-o' />
                  <p>Click to add photo</p>
                </div>
              )}
            </div>
            {is_admin && (
              <div className='lp-video-container'>
                <label htmlFor='lp-video-input'>Template Video URL</label>
                <input
                  type='text'
                  id='lp-video-input'
                  name='video_url'
                  value={video_url}
                  onChange={onChange}
                  placeholder='url here'
                />
              </div>
            )}
          </div>
          <div className='form-area'>
            <div className='form-box'>
              <div className='lp-name-container'>
                <label htmlFor='lp-name-input'>
                  Lesson Plan Name <span className='orange-text'>*</span>
                </label>
                <input
                  type='text'
                  id='lp-name-input'
                  name='lessonPlanName'
                  value={lessonPlanName}
                  onChange={onChange}
                  placeholder='Lesson Plan Name'
                  maxLength={100}
                />
                <div className='failed-validation-box'>
                  {failedValidation.includes("name") && (
                    <p>Please enter your Lesson Plan Name here</p>
                  )}
                </div>
              </div>
              <div className='d-flex'>
                <div className='lp-default-duration-container'>
                  <label htmlFor='lp-default-duration-input'>
                    Default session duration
                  </label>
                  <input
                    onChange={onChange}
                    type='number'
                    id='lp-default-duration-input'
                    value={defaultDuration}
                    name='defaultDuration'
                    placeholder={"minutes"}
                    onWheel={e => e.target.blur()}
                    onBlur={
                      defaultDuration === ""
                        ? () =>
                            onChange({
                              target: { name: "defaultDuration", value: 0 }
                            })
                        : () => false
                    }
                  />
                </div>
              </div>
              <div className='lp-text-tag-container'>
                <label htmlFor='lp-text-tag-input'>Text tags</label>
                <input
                  type='text'
                  id='lp-text-tag-input'
                  name='tagInput'
                  value={tagInput}
                  onChange={onChange}
                  onKeyUp={textTagChange}
                  placeholder='Press Enter after each tag to add'
                  maxLength={25}
                />
              </div>
              <div className='tag-display-box'>
                {textTags.map((tag, index) => {
                  return (
                    <p key={index} className='tag-bubble'>
                      {tag}
                      {"  "}
                      <span onClick={() => removeTag(index)}>
                        <i className='fa fa-times' aria-hidden='true' />
                      </span>
                    </p>
                  );
                })}
              </div>
              <div className='lp-notes-container'>
                <label htmlFor='lp-notes-input'>Notes</label>
                <textarea
                  id='lp-notes-input'
                  name='notes'
                  value={notes}
                  onChange={onChange}
                  placeholder='Add notes here...'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _selectInputFile = () => {
    document.getElementById("input-photo-upload").click();
  };
}

export default LessonBasicDetails;
