import * as types from "../actions/actionTypes";
// import update from 'immutability-helper';
// state = [] : Start out with no accounts
export default function upsellProducts(state = [], action) {
  switch (action.type) {
    case types.GET_UPSELL_PRODUCTS:
      return action.upsell_products;

    case types.CREATE_UPSELL_PRODUCT:
      let productsCopy = [...state];
      productsCopy.push(action.upsell_product);
      return productsCopy;

    case types.UPDATE_UPSELL_PRODUCT:
      return state.map(product => {
        if (product.id === action.upsell_product.id) {
          return Object.assign({}, product, {
            name: action.upsell_product.name
          });
        }
        return product;
      });

    case types.DESTROY_UPSELL_PRODUCT:
      return state.filter(product => product.id !== action.upsell_product);

    default:
      return state;
  }
}
