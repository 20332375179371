import React from "react";
import ReactPlayer from "react-player";

const EducationOverview = ({ education }) => (
  <div className='animated fadeIn'>
    <h6>About this course</h6>
    <p>{education.description}</p>
    <h6>Preview Video</h6>
    {education.video_url && (
      <div className='player-wrapper'>
        <ReactPlayer
          className='react-player'
          url={education.video_url}
          width='100%'
          height='100%'
          controls
        />
      </div>
    )}
  </div>
);

export default EducationOverview;
