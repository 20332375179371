import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

import * as activityActions from "../../actions/activityActions";
import * as commonActions from "../../actions/commonActions";
import * as Constants from "../../misc/Constants.js";

import "../../assets/styles/components/activity/ActivityFeedPage.scss";
import Header from "../common/Header";
import Activity from "./Activity";
import ReactList from "react-list";
import Measure from "react-measure";

import TabBar from "../common/TabBar";
import Loader from "../common/Op36Loader";
import Placeholder from "../common/Placeholder";

// import LoadingComponent from '../common/LoadingComponent';

const PER_PAGE = 6;
const ROWS_FROM_BOTTOM_LOAD = 2;

const styles = {
  cardStyle: {
    padding: "1em",
    marginTop: "8em",
    marginRight: 12,
    marginLeft: 12,
    textAlign: "center"
  },
  containerStyle: {
    width: "100%"
  },
  messageStyle: {
    textTransform: "uppercase"
  },
  buttonStyle: {
    marginTop: "30px",
    color: "white",
    borderRadius: "5px"
  }
};

class ActivityFeed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: Constants.START_PAGE_DEFAULT,
      totalRows: 0,
      sinceTS: moment().unix(),
      containerHeight: 0,
      rowHeight: 0,
      loading: true
    };
  }

  componentWillMount() {
    if (this.state.page === Constants.START_PAGE_DEFAULT) {
      this.loadPageActivities();
    }
    // SET CURRENT TAB FOR THE TAB BAR
    if (!this.props.web) {
      this.props.common_actions.setCurrentTab("Activity");
    }
  }

  handleRefresh(resolve, reject) {
    // do some async code here
    this.setState({
      page: Constants.START_PAGE_DEFAULT
    });
    this.loadPageActivities(resolve);
  }

  displayActivities() {
    return this.props.activities.map(activity => (
      <Measure
        onMeasure={dimensions => {
          this.setState({ rowHeight: dimensions.height });
        }}
      >
        <Activity
          key={activity.id}
          displayComments={false}
          activity={activity}
        />
      </Measure>
    ));
  }

  renderActivitiesWeb() {
    if (!this.props.activities.length || this.props.activities.length === 0) {
      return (
        <div className="col-6 mx-auto">
          <Placeholder
            title={"No Activities to Show"}
            icon={"fa-list"}
            subtext1={
              "Add students and start getting them involved to show activites"
            }
          />
        </div>
      );
    } else {
      return (
        <div id="activityWrapper" className=" componentBody col-md-8 mx-auto">
          <ReactList
            itemRenderer={this.renderItem.bind(this)}
            length={this.props.activities.length}
            type="simple"
          />
        </div>
      );
    }
  }

  renderActivities() {
    if (this.state.loading) {
      return (
        <div className="container-fluid mt-5">
          <Loader message="loading activities" />
        </div>
      );
    }

    if (this.props.activities.length === 0) {
      return (
        <div className="container-fluid mx-auto" style={styles.containerStyle}>
          <div className="card" style={styles.cardStyle}>
            <h2 style={styles.messageStyle}>
              No Activity Yet. Log your first action to kick off this program!
            </h2>
            <a
              id="sign-out-btn"
              className="btn btn-primary"
              onClick={this.signOut}
              style={styles.buttonStyle}
            >
              Sign Out
            </a>
          </div>
        </div>
      );
    } else if (this.props.activities.length > 0) {
      return (
        <ReactList
          itemRenderer={this.renderItem.bind(this)}
          length={this.props.activities.length}
          type="simple"
        />
      );
    }
  }

  renderActivitiesMobile() {
    return (
      <div id="activityFeedPage" className="hasTabBar">
        <Header wrapperClass={"activityFeedHeader"} title={"Activity"} />
        <div
          id="activityWrapper"
          className="componentBody iOSscroll scrollContainer container-fluid"
        >
          {this.renderActivities()}
        </div>
        <TabBar />
      </div>
    );
  }

  // React-List Implementation
  render() {
    return (
      <div>
        {this.props.web
          ? this.renderActivitiesWeb()
          : this.renderActivitiesMobile()}
      </div>
    );
  }

  loadPageActivities(resolve) {
    if (this.state.page === Constants.START_PAGE_DEFAULT) {
      this.setState({
        sinceTS: moment().unix()
      });
    }
    var courseId;
    if (this.props.course) {
      courseId = this.props.course.id;
    } else {
      courseId = this.props.user.current_course_id;
    }

    this.props.actions
      .getCourseActivities(
        courseId,
        this.state.page,
        PER_PAGE,
        this.state.sinceTS
      )
      .then(response => {
        // Store response data in list...
        if (resolve) resolve();
        this.setState({
          loading: false
        });
      });
    this.setState({
      page: this.state.page + 1
    });
  }

  renderItem(index, key) {
    //console.log("Render item at index: " + index);
    if (
      index > PER_PAGE * (this.state.page - 1) - ROWS_FROM_BOTTOM_LOAD &&
      index > this.props.activities.length - ROWS_FROM_BOTTOM_LOAD
    ) {
      // If current index is at point to load more
      this.loadPageActivities();
    }
    if (this.props.activities) {
      var rowActivity = this.props.activities[index];
      return (
        <div className="mt-2" key={rowActivity.id}>
          <Activity displayComments={false} activity={rowActivity} />
        </div>
      );
    }
  }
}

function mapStateToProps(state, ownProps) {
  return {
    ui: state.ui,
    user: state.user.current_user,
    activities: state.activities
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(activityActions, dispatch),
    common_actions: bindActionCreators(commonActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityFeed);
