import React, { Component } from "react";
import {
  EditorState,
  convertFromHTML,
  ContentState,
  convertToRaw
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";

const linkOptions = {
  defaultTargetOption: "_blank"
};

const inlineOptions = {
  options: ["bold", "italic", "underline", "strikethrough"]
};

const options = {
  entityStyleFn: entity => {
    if (entity.get("type").toLowerCase() === "link") {
      const data = entity.getData();
      return {
        element: "a",
        attributes: {
          href: data.url,
          target: data.targetOption
        }
      };
    }
  }
};

class DraftEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty()
    };
  }

  componentDidMount = () => {
    const { content } = this.props;

    if (content) {
      this.setState({
        editorState: this.getInitialHTML(content)
      });
    } else {
      this.setState({ editorState: EditorState.createEmpty() });
    }
  };

  getInitialHTML = content => {
    let contentState = stateFromHTML(content);
    let editorState = EditorState.createWithContent(contentState);
    return editorState;
  };

  handleEditorChange = editorState => {
    const { sendEditorData } = this.props;

    this.setState(
      {
        editorState
      },
      () => {
        sendEditorData(
          stateToHTML(this.state.editorState.getCurrentContent(), options)
        );
      }
    );
  };

  render() {
    const { editorState } = this.state;
    const { placeholder } = this.props;
    return (
      <div>
        <Editor
          placeholder={placeholder}
          editorState={editorState}
          toolbarClassName='description-toolbar'
          wrapperClassName='description-wrapper'
          editorClassName='description-editor'
          onEditorStateChange={this.handleEditorChange}
          toolbar={{
            options: [
              "inline",
              "blockType",
              "fontSize",
              "list",
              "textAlign",
              "link",
              "embedded",
              "emoji",
              "image",
              "history"
            ],
            inline: inlineOptions,
            link: linkOptions
          }}
        />
      </div>
    );
  }
}

export default DraftEditor;
