import React from "react";
import _ from "lodash";
import Modal from "react-modal";
import { Wizard, Steps, Step } from "react-albus";

// CHILDREN
import AdminEducationForm from "../Forms/AdminEducationForm";
import AdminEducationSectionsForm from "../Forms/AdminEducationSectionsForm";

const AdminEducationModal = ({
  showModal,
  closeModal,
  formState,
  loader,
  grabPhotoData,
  handleInputChange,
  addSection,
  sendSectionData,
  deleteSection,
  deleteLesson,
  createEducation,
  isEditModal,
  updateEducation,
  handleDateChange
}) => (
  <Modal
    id='OrganizationModal'
    isOpen={showModal}
    onRequestClose={closeModal}
    contentLabel='Create Education Modal'
  >
    <div className='modal-header row m-0 p-0'>
      <div className='col-11 m-0 p-0'>
        <h5 className='modal-title display-4'>
          {isEditModal ? "Update" : "Create"} Education
        </h5>
      </div>
      <div className='col-1 m-0 p-0'>
        <button
          type='button'
          className='close pull-right font-200'
          data-dismiss='modal'
          aria-label='Close'
          onClick={loader ? e => e.preventDefault() : closeModal}
        >
          <span aria-hidden='true'>✕</span>
        </button>
      </div>
    </div>
    <div className='modal-content'>
      <div className='modal-body' id='input-border'>
        <div className='row mb-2 p-2'>
          <Wizard>
            <Steps>
              <Step
                id='step1'
                render={({ next }) => (
                  <div className='col-md-6 mx-auto animated fadeIn'>
                    <AdminEducationForm
                      formState={formState}
                      closeModal={closeModal}
                      next={next}
                      loader={loader}
                      grabPhotoData={grabPhotoData}
                      handleInputChange={handleInputChange}
                      isEditModal={isEditModal}
                      handleDateChange={handleDateChange}
                    />
                  </div>
                )}
              />
              <Step
                id='step2'
                render={({ next, previous }) => (
                  <div className='w-100'>
                    <button className='previous-step-button' onClick={previous}>
                      <i className='fa fa-long-arrow-left' />
                    </button>
                    <div className='col-md-6 mx-auto animated fadeIn'>
                      <AdminEducationSectionsForm
                        formState={formState}
                        closeModal={closeModal}
                        previous={previous}
                        next={next}
                        loader={loader}
                        addSection={addSection}
                        sendSectionData={sendSectionData}
                        deleteSection={deleteSection}
                        deleteLesson={deleteLesson}
                        createEducation={createEducation}
                        updateEducation={updateEducation}
                        isEditModal={isEditModal}
                      />
                    </div>
                  </div>
                )}
              />
            </Steps>
          </Wizard>
        </div>
      </div>
    </div>
  </Modal>
);

export default AdminEducationModal;
