import React from "react";

import Logo from "../../../common/logoSVGs/HorizontalLogo";
import MCElement from "../../../common/MCElement";

import "./StartingStep.scss";

/**
  First step of the coach registration process, where they
  agree that they are over 13, to the privacy policy, and to the
  terms of use.
*/
const startingStep = (props) => {
  const disabled = props.hasConsented && props.acceptedPrivacy ? false : true;

  return (
    <div id='StartingStep'>
      <Logo colorScheme='dark' />
      <h1 className='starting-step-heading'>
        Create your free Operation 36 Coach Account.
      </h1>
      <p className='starting-step-subtitle'>
        Take the first step to implementing developmental programming today!
      </p>
      <img src={props.img} alt='Op 36 dashboard' />
      <h2 className='starting-step-heading' id='starting-step-heading-2'>
        Learn. Plan. Execute. Report.
      </h2>

      <div className='starting-step-bullets'>
        <ul className='starting-step-feature-list'>
          <li className='starting-step-feature-list-item'>
            Gain access to Coach Academy video training
          </li>
          <li className='starting-step-feature-list-item'>
            Explore the Op 36 Program coaching tools
          </li>
          <li className='starting-step-feature-list-item'>
            Learn more about starting an Op 36 Program
          </li>
        </ul>
        <div className='starting-step-checkboxes'>
          <div className='starting-step-check-label'>
            <MCElement
              type='checkbox'
              clicked={(event) => props.toggleHandle(event, "consent")}
              isSelected={props.hasConsented}
            />
            <p>I agree I am 13 years of age or older</p>
          </div>
          <div className='starting-step-check-label'>
            <MCElement
              type='checkbox'
              clicked={(event) => props.toggleHandle(event, "privacy")}
              isSelected={props.acceptedPrivacy}
            />
            <p>
              I accept the{" "}
              <a href='https://operation36.golf/legal/' target='_blank' rel='noreferrer'>
                &nbsp;Privacy Policy
              </a>
              &nbsp;and&nbsp;
              <a href='https://operation36.golf/leagl/' target='_blank' rel='noreferrer'>
                Terms of Use
              </a>
            </p>
          </div>
        </div>
      </div>

      <button
        className='btn-rectangular btn-rectangular-main-positive starting-step-button'
        disabled={disabled}
        onClick={() => props.startButtonHandle()}
      >
        Get started
      </button>
      <p className='starting-step-sign-in'>
        Already have a coach account? <a href='/dashboard'>Sign in</a>
      </p>
    </div>
  );
};

export default startingStep;
