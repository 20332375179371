import React, { Component } from "react";
import _ from "lodash";
import Modal from "react-modal";

// CHILDREN
import ResourceForm from "../Forms/ResourceForm";
import ResourceSectionForm from "../Forms/ResourceSectionForm";
import ResourceCategoryForm from "../Forms/ResourceCategoryForm";

class CreateResourceModal extends Component {
  renderForm = () => {
    const {
      formState,
      handleInputChange,
      handleSectionChange,
      handleStatefulEditorChange,
      grabPhotoData,
      grabSectionPhotoData,
      createResource,
      updateResource,
      createResourceSection,
      createResourceCategory,
      resourceSectionLoader,
      resourceSections,
      resourceCategories,
      resourceCategoryLoader,
      resourceLoader,
      toggleForm,
      sendEditorData,
      isEditModal,
      closeModal
    } = this.props;

    if (formState.formRendered === "resource") {
      return (
        <ResourceForm
          formState={formState}
          handleInputChange={handleInputChange}
          handleSectionChange={handleSectionChange}
          handleStatefulEditorChange={handleStatefulEditorChange}
          grabPhotoData={grabPhotoData}
          toggleForm={toggleForm}
          closeModal={closeModal}
          createResource={createResource}
          updateResource={updateResource}
          resourceSections={resourceSections}
          resourceSectionLoader={resourceSectionLoader}
          resourceCategories={resourceCategories}
          resourceCategoryLoader={resourceCategoryLoader}
          loader={resourceLoader}
          sendEditorData={sendEditorData}
          isEditModal={isEditModal}
        />
      );
    } else if (formState.formRendered === "section") {
      return (
        <ResourceSectionForm
          formState={formState}
          handleInputChange={handleInputChange}
          grabSectionPhotoData={grabSectionPhotoData}
          toggleForm={toggleForm}
          createResourceSection={createResourceSection}
          loader={resourceSectionLoader}
        />
      );
    } else if (formState.formRendered === "category") {
      return (
        <ResourceCategoryForm
          formState={formState}
          handleInputChange={handleInputChange}
          grabPhotoData={grabPhotoData}
          toggleForm={toggleForm}
          createResourceCategory={createResourceCategory}
          loader={resourceCategoryLoader}
        />
      );
    }
    return null;
  };

  render() {
    const { showModal, closeModal, formState, isEditModal } = this.props;
    return (
      <Modal
        id="ResourceModal"
        isOpen={showModal}
        onRequestClose={closeModal}
        contentLabel={`Create Resource Modal`}
      >
        <div className="modal-header row m-0 p-0">
          <div className="col-11 m-0 p-0">
            <h5 className="modal-title display-4">
              {isEditModal
                ? `Edit ${formState.title}`
                : `Create New ${formState.formRendered.charAt(0).toUpperCase() +
                    formState.formRendered.slice(1)}`}
            </h5>
          </div>
          <div className="col-1 m-0 p-0">
            <button
              type="button"
              className="close pull-right font-200"
              data-dismiss="modal"
              aria-label="Close"
              onClick={closeModal}
            >
              <span aria-hidden="true">✕</span>
            </button>
          </div>
        </div>
        <div className="modal-content">
          <div className="modal-body" id="input-border">
            <div className="row mb-2 p-2">
              <div className="col-md-6 mx-auto">{this.renderForm()}</div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default CreateResourceModal;
