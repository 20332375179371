import { useDispatch, useSelector } from 'react-redux'
import MaterialCard from '../../../materials/MaterialCard'
import MCElement from '../../../common/MCElement'
import { addAcceptedMaterial, addRefusedMaterial } from '../../../../reducers/programRegistrationReducer'

import './ProgramMaterialsStep.scss'
import colors from '../../../../assets/styles/globals.scss'

const ProgramMaterialsStep = () => {
  const dispatch = useDispatch()
  const { courseMaterials } = useSelector(state => state.programRegistration.enrollmentData)
  const { acceptedMaterials, refusedMaterials } = useSelector(state => state.programRegistration)

  const handleAcceptMaterial = (m) => {
    if (!isMaterialAccepted(m)) {
      dispatch(addAcceptedMaterial(m))
    }
  }
  const handleRefuseMaterial = (m) => {
    if (!isMaterialRefused(m)) {
      dispatch(addRefusedMaterial(m))
    }
  }

  const isMaterialAccepted = (material) => !!acceptedMaterials[material.id]
  const isMaterialRefused = (material) => !!refusedMaterials[material.id]

  return (
    <div id='ProgramMaterialsStep' className='d-flex flex-column'>
      <div id='MaterialsSelection'>
        <h3 className='step-label'>Program Materials</h3>
        <h5 className='step-label'>
          This program requires the following materials. Please select the option that best applies to you.&nbsp;
          We will send you more details after registration.
        </h5>
        <div className='mt-5'>
          {courseMaterials.map(m => (
            <div key={m.id} className='material-card-container'>
              <MaterialCard material={m} />
              <div className='mt-4'>
                <h5 className='step-label'>Do you need {m.name}?</h5>
                <div>
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                  <div
                    className='d-flex align-items-center mb-2 action'
                    onClick={() => handleAcceptMaterial(m)}
                  >
                    <MCElement
                      type='radio'
                      onKeyDown={(e) => {
                        e.key === 'Enter' && handleAcceptMaterial(m)
                      }}
                      isSelected={isMaterialAccepted(m)}
                      primaryColor={isMaterialAccepted(m) ? colors.secondaryColor : colors.lightNavy}
                    />
                    <span className='ml-3'>Yes, I do.</span>
                  </div>
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                  <div
                    className='d-flex align-items-center action'
                    onClick={() => handleRefuseMaterial(m)}
                  >
                    <MCElement
                      type='radio'
                      onKeyDown={(e) => {
                        e.key === 'Enter' && handleRefuseMaterial(m)
                      }}
                      isSelected={isMaterialRefused(m)}
                      primaryColor={isMaterialRefused(m) ? colors.secondaryColor : colors.lightNavy}
                    />
                    <span className='ml-3'>No, I don’t. I already have one.</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ProgramMaterialsStep
