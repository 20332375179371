import { Collapse } from 'reactstrap'
import { useState } from 'react'
import cls from 'classnames'
import PropTypes from 'prop-types'

import classes from './CollapsibleBar.module.scss'
import { ReactComponent as DropdownIcon } from '../../../assets/images/common/icons/select-drop-down-icon-white.svg'

const CollapsibleBar = ({ components, classNames }) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <li className={cls(classes.collapsibleBar, classNames?.collapsibleBar, { [classNames?.collapsibleBarExpanded]: isOpen })}>
      <button
        className={cls('d-flex justify-content-between align-items-center', classes.bar, classNames?.bar)}
        onClick={() => setOpen(value => !value)}
      >
        {components?.bar}
        <DropdownIcon className={cls('ml-0 flex-shrink-0', { [classes.expanded]: isOpen })} />
      </button>
      <Collapse isOpen={isOpen} className={classes.collapse}>
        {components?.collapse}
      </Collapse>
    </li>
  )
}

CollapsibleBar.propTypes = {
  components: PropTypes.shape({
    bar: PropTypes.node,
    collapse: PropTypes.node,
  }),
  classNames: PropTypes.shape({
    bar: PropTypes.string,
    collapsibleBar: PropTypes.string,
    collapsibleBarExpanded: PropTypes.string,
  }),
}

export default CollapsibleBar
