import PropTypes from 'prop-types'
import classnames from 'classnames'
import ButtonLink, { buttonLinkType } from './buttons/ButtonLink'

import styles from './LockError.module.scss'
import { ReactComponent as LockIcon } from '../../assets/images/common/icons/lock-icon.svg'

const LockError = ({ titleText, children, linkAttributes }) => {
  const { linkMessage, ...filteredAttributes } = linkAttributes

  return (
    <div className={classnames('d-flex flex-column justify-content-center align-items-center py-3', styles['error-page'])}>
      <div
        className={styles.error}
      >
        <LockIcon className={classnames('fa fa-lock', styles['lock-image'])} />
        <h4 className={styles.title}>{titleText}</h4>
        <div className={styles.message}>
          {children}
        </div>
        <ButtonLink className='w-fit-content' {...filteredAttributes}>
          {linkMessage}
        </ButtonLink>
      </div>
    </div>
  )
}

LockError.propTypes = {
  titleText: PropTypes.string.isRequired,
  children: PropTypes.node,
  linkAttributes: PropTypes.shape({
    linkMessage: PropTypes.string.isRequired,
    ...buttonLinkType,
  }).isRequired,
}

export default LockError
