import React, { Component } from "react";

// ASSETS
import "./EducationLesson.scss";

class EducationLesson extends Component {
  renderIcon = () => {
    const { isCompleted } = this.props;

    if (!isCompleted) {
      return <i className="fa fa-circle-thin" />;
    }
    return <i className="fa fa-check-circle" />;
  };

  render() {
    const { lesson, index, toggleVideoModal, isCoach } = this.props;

    return (
      <div id="EducationLesson">
        <button onClick={() => toggleVideoModal(lesson)}>
          <p>
            {index + 1}. {lesson.name}
          </p>
          {isCoach && this.renderIcon()}
        </button>
      </div>
    );
  }
}

export default EducationLesson;
