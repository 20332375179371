/* eslint-disable no-invalid-this */
import React from "react";
import PropTypes from "prop-types";
import RichTextEditor from "react-rte";

export default class MyStatefulEditor extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    content: PropTypes.string
  };
  constructor(props) {
    let value = RichTextEditor.createEmptyValue();
    super(props);
    if (this.props.content) {
      value = RichTextEditor.createValueFromString(this.props.content, "html");
    }
    this.state = {
      value,
      placeholder: ""
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.content !== nextProps.content && nextProps.isTemplate) {
      let value = RichTextEditor.createValueFromString(
        nextProps.content,
        "html"
      );

      this.onChange(value);
    }
  }

  onChange = value => {
    this.setState({ value });
    if (this.props.onChange) {
      this.props.onChange(value.toString("html"));
    }
  };

  render() {
    const toolbarConfig = {
      // Optionally specify the groups to display (displayed in the order listed).
      display: [
        "INLINE_STYLE_BUTTONS",
        "BLOCK_TYPE_BUTTONS",
        "LINK_BUTTONS",
        "BLOCK_TYPE_DROPDOWN",
        "HISTORY_BUTTONS"
      ],
      INLINE_STYLE_BUTTONS: [
        { label: "Bold", style: "BOLD", className: "custom-css-class" },
        { label: "Italic", style: "ITALIC" },
        { label: "Underline", style: "UNDERLINE" }
      ],
      BLOCK_TYPE_DROPDOWN: [
        { label: "Normal", style: "unstyled" },
        { label: "Heading Large", style: "header-one" },
        { label: "Heading Medium", style: "header-two" },
        { label: "Heading Small", style: "header-three" }
      ],
      BLOCK_TYPE_BUTTONS: [
        { label: "UL", style: "unordered-list-item" },
        { label: "OL", style: "ordered-list-item" }
      ]
    };
    return (
      <RichTextEditor
        toolbarConfig={toolbarConfig}
        value={this.state.value}
        placeholder={this.state.placeholder}
        onChange={this.onChange}
      />
    );
  }
}

MyStatefulEditor.displayName = "MyStatefulEditor";
