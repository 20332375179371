import React, { Component } from "react";
import _ from "lodash";

// CHILDREN
import SimplePhotoUploader from "../../common/SimplePhotoUploader";
import DraftEditor from "../../common/form/DraftEditor";

// ASSETS
import "./ResourceForm.scss";

class ResourceForm extends Component {
  validateResource = () => {
    const { formState } = this.props;

    if (
      formState.title !== "" &&
      formState.resource_section_id !== -1 &&
      formState.resource_category_id !== -1
    ) {
      return false;
    }
    return true;
  };

  renderResourceSectionOptions = () => {
    const { resourceSections } = this.props;

    return resourceSections.map(section => (
      <option value={section.id} key={section.id}>
        {section.name}
      </option>
    ));
  };

  renderResourceCategoryOptions = () => {
    const { resourceCategories } = this.props;

    if (resourceCategories && resourceCategories.length > 0) {
      return resourceCategories.map(category => (
        <option value={category.id} key={category.id}>
          {category.name}
        </option>
      ));
    }
    return (
      <option value={0} disabled>
        No available categories
      </option>
    );
  };

  render() {
    const {
      formState,
      closeModal,
      loader,
      handleInputChange,
      handleSectionChange,
      grabPhotoData,
      isEditModal,
      toggleForm,
      createResource,
      updateResource,
      resourceSectionLoader,
      resourceCategoryLoader,
      sendEditorData
    } = this.props;
    return (
      <div id="ResourceForm" className="row animated fadeIn">
        <div className="col-md-6">
          <label htmlFor="featured-image">Resource Icon</label>
          <SimplePhotoUploader
            sendPhotoData={grabPhotoData}
            selectedPhoto={formState.uploadPhotoSrc}
          />
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="title">Resource Name</label>
            <input
              type="text"
              name="title"
              className="form-control"
              id="title"
              placeholder="Resource Name"
              onChange={handleInputChange}
              value={formState.title}
            />
          </div>
          <div className="form-group">
            <label htmlFor="video_url">Video URL</label>
            <input
              type="text"
              name="video_url"
              className="form-control"
              id="video_url"
              placeholder="Video URL"
              onChange={handleInputChange}
              value={formState.video_url}
            />
          </div>
          <div className="form-group">
            <label htmlFor="button_url">Resource Download Link</label>
            <input
              type="text"
              name="button_url"
              className="form-control"
              id="button_url"
              placeholder="Download Link"
              onChange={handleInputChange}
              value={formState.button_url}
            />
          </div>
          <div className="form-group">
            <label htmlFor="section_id">Section</label>
            <select
              name="resource_section_id"
              className="form-control"
              value={formState.resource_section_id}
              onChange={handleSectionChange}
            >
              <option value={-1} disabled>
                {resourceSectionLoader
                  ? "Loading Sections"
                  : "Choose a Section"}
              </option>
              {!resourceSectionLoader && this.renderResourceSectionOptions()}
            </select>
            <div className="w-100 d-flex justify-content-end flex-row">
              <button
                className="btn btn-link"
                onClick={() => toggleForm("section")}
              >
                <i className="fa fa-plus" /> Add New Section
              </button>
            </div>
          </div>
          {formState.resource_section_id > -1 && (
            <div className="form-group animated fadeIn">
              <label htmlFor="resource_category_id">Category</label>
              <select
                name="resource_category_id"
                className="form-control"
                value={formState.resource_category_id}
                onChange={handleInputChange}
              >
                <option value={-1} disabled>
                  {resourceCategoryLoader
                    ? "Loading Categories"
                    : "Choose a Category"}
                </option>
                {!resourceCategoryLoader &&
                  this.renderResourceCategoryOptions()}
              </select>
              <div className="w-100 d-flex justify-content-end flex-row">
                <button
                  className="btn btn-link"
                  onClick={() => toggleForm("category")}
                >
                  <i className="fa fa-plus" /> Add New Category
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="col-md-12 mt-3">
          <div className="form-group">
            <label htmlFor="description">Description</label>
            <DraftEditor
              placeholder="Enter content..."
              content={formState.description}
              sendEditorData={sendEditorData}
            />
          </div>
        </div>

        <div className="col-md-12 d-flex justify-content-center align-items-center mt-4">
          <button
            className="btn btn-dark m-2"
            onClick={closeModal}
            disabled={loader}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary m-2"
            onClick={isEditModal ? updateResource : createResource}
            disabled={loader || this.validateResource()}
          >
            {loader && (
              <i className="fa fa-spinner fa-spin light-loading-spinner" />
            )}
            {isEditModal
              ? `Updat${loader ? "ing" : "e"}`
              : `Add${loader ? "ing" : ""}`}{" "}
            Resource
          </button>
        </div>
      </div>
    );
  }
}
export default ResourceForm;
