import top50_badge from '../assets/images/pins/top50_badge.png'
import certified_badge from '../assets/images/pins/certified_badge.png'
import ambassador_badge from '../assets/images/pins/ambassador_badge.png'
import top50_2020 from '../assets/images/pins/top_50_2020.png'
import top50_2021 from '../assets/images/pins/top_50_2021.png'
import top50_2022 from '../assets/images/pins/top_50_2022.png'
import top50_2023 from '../assets/images/pins/top_50_2023.webp'
import master_top50 from '../assets/images/pins/master_top_50.png'

export const stockAccolades = {
  Certified: {
    badge: certified_badge,
    is_active: true,
  },
  'Top 50': {
    badge: top50_badge,
    is_active: true,
  },
  '2020 Top 50': {
    badge: top50_2020,
    is_active: true,
  },
  '2021 Top 50': {
    badge: top50_2021,
    is_active: true,
  },
  '2022 Top 50': {
    badge: top50_2022,
    is_active: true,
  },
  '2023 Top 50': {
    badge: top50_2023,
    is_active: true,
  },
  'Master Top 50': {
    badge: master_top50,
    is_active: true,
  },
  Ambassador: {
    badge: ambassador_badge,
    is_active: false,
  },
}
