import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useState, useCallback } from 'react'
import _ from 'lodash'

import { getCoachPrograms } from '../../../actions/programActions'
import { perPage } from '../../../reducers/groupProgramsReducer'
import CoachProgramsList from './CoachProgramsList'
import SearchInput from '../../common/SearchInput'
import NoPrograms from './EmptyProgramsList'
import Loader from '../../common/Op36Loader-web'

import { ReactComponent as OutsideLinkIcon } from '../../../assets/images/common/icons/outside-link-icon.svg'
import styles from './CoachProgramsFilterBar.module.scss'

const CoachProgramsDraftTab = () => {
  const dispatch = useDispatch()
  const draft = useSelector(state => state.groupPrograms.draft)
  const [searchInput, setSearchInput] = useState(draft.search || '')
  const { timezone, id } = useSelector(state => state.user.current_user.communities[0])

  const fetchDraftPrograms = () => {
    dispatch(getCoachPrograms({
      filters: { draft: 'true', search: draft.search },
      page: draft.page + 1,
      perPage: perPage,
    }))
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchPrograms = useCallback(
    _.debounce((value) => {
      dispatch(getCoachPrograms({
        filters: { draft: 'true', search: value },
        page: 1,
        perPage: perPage,
      }))
    }, 300),
    []
  )

  const handleSearchUpdated = (value) => {
    setSearchInput(value)
    debouncedFetchPrograms(value)
  }

  return (
    <>
      <div className={styles.coachProgramsFilterBar}>
        <div className={styles.programsSearchBar}>
          <SearchInput
            placeholder='Search Programs'
            onChange={(e) => handleSearchUpdated(e.target.value)}
            value={searchInput}
          />
        </div>
        <div className='d-flex flex-grow-1 align-items-center'/>
        <Link to={`/landing/${id}`} target='_blank' className='d-flex mr-5 align-items-center view-community-link'>
          <OutsideLinkIcon id='outsideLinkIcon'/>
          <span className='view-community-label'>View Landing Page</span>
        </Link>
      </div>

      {
        draft.programs.length > 0 ?
          <CoachProgramsList
            programs={draft.programs}
            timezone={timezone}
            hasMore={draft.hasMore}
            fetchPrograms={fetchDraftPrograms}
            isDraft
          />
          :
          draft.hasMore === false ?
            (draft.search) ? (
              <NoPrograms
                title='No Drafts Found'
                subtitle='There are no drafts that match the search you made, clear all filters and try again.'
              />
            ) : (
              <NoPrograms
                title='No Drafts Available'
                subtitle='Create a Program and make it live'
              />
            ) : (
              <Loader message='loading programs' />
            )
      }
    </>
  )
}

export default CoachProgramsDraftTab
