import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { useContext, useState } from 'react'
import { toast } from 'react-toastify'

import { AccountContext } from '../AccountContext'
import LoadingSpinner from '../../common/LoadingSpinner'
import NotificationToggle from './NotificationToggle'

import classes from './NotificationsForm.module.scss'
import { ReactComponent as InfoCircleIcon } from '../../../assets/images/common/icons/info-circle-icon-dark.svg'
import { flatServerFormErrors } from '../../../utility/helperFunctions'

const NotificationsForm = () => {
  const dispatch = useDispatch()
  const currentUser = useSelector(state => state.user.current_user)
  const { user, updateAction } = useContext(AccountContext) // Note: user could be golfer OR current_user
  const [, setSearchParams] = useSearchParams()

  const [isUpdating, setIsUpdating] = useState(false)
  const [, setErrors] = useState({})

  const isCurrentUser = currentUser.id === user.id
  const isCoach = currentUser.type === 'Coach'
  const hasAccess = isCurrentUser && isCoach

  const disabledEmails = user.user_settings.disabled_emails

  const getDefaultValue = (fieldName) => !disabledEmails.find(email => email === fieldName)

  const handleToggleNotification = async (e) => {
    const updatedDisabledEmails =
      e.target.checked
        ? disabledEmails.filter(email => email !== e.target.name)
        : [...disabledEmails, e.target.name]

    const partialUser = {
      id: user.id,
      email: user?.email,
      username: user?.username,
      disabled_emails: updatedDisabledEmails,
    }

    try {
      setIsUpdating(true)
      const newState = await dispatch(updateAction(partialUser))

      if (newState.type === 'UI_ERROR') {
        setErrors(flatServerFormErrors(newState?.error))
      } else {
        toast.success('Email notifications have been successfully updated!')
      }
    } catch {
      toast.error('User could not be updated. Please check again later.')
    } finally {
      setIsUpdating(false)
    }
  }

  if (!hasAccess) {
    setSearchParams({ editTab: 'personal' })
    return null
  }

  return (
    <form onInput={handleToggleNotification} className={classNames(classes.container, 'animated fadeIn')}>
      <div className={classNames(classes.header, 'row d-flex align-items-center mb-4')}>
        <h4 className={classes.title}>Notification Settings</h4>
        <InfoCircleIcon role='button' width={20} height={20} />
        {isUpdating && (
          <LoadingSpinner classNames={{ wrapper: classNames(classes.loading, 'mt-0'), spinner: 'fa-lg' }} />
        )}
      </div>

      <div className={classNames(classes.subHeader, 'd-flex flex-column row')}>
        <h5 className='font-weight-bold mb-1'>Email Notifications</h5>
        <p>Control what emails are sent to you by toggling the options below.</p>
      </div>

      <NotificationToggle
        id='enrollmentRequests'
        name='enrollment_request'
        defaultChecked={getDefaultValue('enrollment_request')}
        title='Enrollment Requests'
        description='Get notified when someone registers by paying offline.'
        disabled={isUpdating}
      />
      <NotificationToggle
        id='onlineRegistrations'
        name='online_registration'
        defaultChecked={getDefaultValue('online_registration')}
        title='Online Registrations'
        description='Get notified when someone registers by paying online.'
        disabled={isUpdating}
      />
      <NotificationToggle
        id='programFull'
        name='program_full'
        defaultChecked={getDefaultValue('program_full')}
        title='Program Full'
        description='Get notified when your program has been filled.'
        disabled={isUpdating}
      />
      <NotificationToggle
        id='familyAccountCreated'
        name='family_account_created'
        defaultChecked={getDefaultValue('family_account_created')}
        title='Family Account Created'
        description='Get notified when a family account is created within your community.'
        disabled={isUpdating}
      />
      <NotificationToggle
        id='golferAccountCreated'
        name='golfer_account_created'
        defaultChecked={getDefaultValue('golfer_account_created')}
        title='Golfer Account Created'
        description='Get notified when a golfer account is created within your community.'
        disabled={isUpdating}
      />
      <NotificationToggle
        id='curriculum'
        name='curriculum'
        defaultChecked={getDefaultValue('curriculum')}
        title='Curriculum'
        description='Get notified every time your students Level Up on the Golf Course.'
        disabled={isUpdating}
      />
    </form>
  )
}

export default NotificationsForm
