import Modal from 'react-modal'
import PropTypes from 'prop-types'

import SubmitButton from '../../../../common/buttons/SubmitButton'
import { ReactComponent as StripeIcon } from '../../../../../assets/images/common/icons/stripe-icon.svg'
import { ReactComponent as ModalXIcon } from '../../../../../assets/images/common/icons/modal-X-icon.svg'
import './StripeModal.scss'
import React from 'react'

const modalStyles = {
  content: {
    height: 'min-content',
    margin: 'auto',
    maxWidth: '550px',
    marginTop: '12%',
    borderRadius: '8px',
    overflow: 'hidden',
    paddingLeft: '40px',
    paddingRight: '40px'
  },
}

const StripeModal = ({ showModal, closeModal, transactionDetails }) => {
  return (
    <Modal
      isOpen={showModal}
      onRequestClose={closeModal}
      contentLabel='Stripe Modal'
      style={modalStyles}
      autoFocus
    >
      <div id='StripeModal'>
        <div className='animated fadeIn'>
          <div>
            <div className='d-flex justify-content-end text-align-right'>
              <ModalXIcon id='modalXIcon' onClick={closeModal} />
            </div>
            <div className='d-flex flex-column justify-content-center align-items-center'>
              <StripeIcon id='stripeIcon'/>
              <p className='stripe-title'>This golfer's payment and packages are being handled by Stripe.</p>
            </div>
            <p className='transaction-details'> Transaction Details: </p>
            <div className='divider' />
            {Object.entries(transactionDetails).map(([key, value]) =>
              <div className='d-flex justify-content-between align-items-center' key={key}>
                  <p className='key'>{key}</p>
                  <p className='value'>{value}</p>
              </div>
            )}
            <div className='row py-3'>
              <div className='col-12'>
                <SubmitButton
                  onClick={closeModal}
                  buttonMessage='Close'
                  buttonColor='navy-button'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

StripeModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  transactionDetails: PropTypes.object.isRequired
}

export default StripeModal
