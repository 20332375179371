import React, { Component } from "react";
import TextInput from "../common/form/TextInput";
import { isValidEmail } from '../../utility/helperFunctions'

// ASSETS
import "./ForgotUsernameEmailForm.scss";

export default class ForgotUsernameEmailForm extends Component {
  state = { email: "" };

  render() {
    const { email } = this.state;
    const { closeModal, emailSent } = this.props;

    return (
      <div id='ForgotUsernameEmailForm'>
        {!emailSent ? (
          <form id='reset-password-form' className='form-container'>
            <h4>Please enter your admin account e-mail</h4>
            <p>
              This will send you an e-mail with a list of your family users and
              their usernames
            </p>
            <div className='col-md-6 mx-auto mt-5 mb-5'>
              <label>Email</label>
              <TextInput
                name='email'
                type='text'
                label=''
                onChange={this._onChangeEmail}
                placeholder='john.doe@op36.com'
                value={email}
              />
            </div>
            <div className='d-flex justify-content-center align-items-center'>
              <button
                id='submit-btn'
                onClick={this._submitForm}
                type='submit'
                className='btn btn-primary'
                disabled={email === '' || !isValidEmail(email)}
              >
                {email !== '' && isValidEmail(email)
                  ? "Send email with usernames"
                  : "Enter Your Email"}
              </button>
            </div>
          </form>
        ) : (
          <form id='reset-password-form' className='form-container'>
            <div className='col-md-6 mx-auto mt-5 mb-5'>
              <label className='w-100 text-center'>
                E-mail has been sent! Please check your inbox for the list of
                members and their usernames for your family
              </label>
            </div>

            <div className='d-flex justify-content-center align-items-center'>
              <button
                id='submit-btn'
                onClick={closeModal}
                type='submit'
                className='btn btn-primary'
              >
                Back To Sign In
              </button>
            </div>
          </form>
        )}
      </div>
    );
  }

  _onChangeEmail = event => {
    this.setState({ email: event.target.value });
  };

  _submitForm = event => {
    const { handleSubmit } = this.props;
    event.preventDefault();
    handleSubmit(this.state.email);
  };
}
