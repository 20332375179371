import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

// ASSETS
import "./ResourceSectionCard.scss";

const ResourceSectionCard = ({ section }) => {
  const navigate = useNavigate()

  const goToResourceSection = useCallback(
    () => {
      const { id } = section

      navigate(
        `/resource-sections/${id}`,
        { state: { resourceSectionId: id } }
      )
    },
    [ navigate, section ]
  )

  return (
    <div
      id='ResourceSectionCard'
      className='col-xl-4 col-lg-6 col-md-6 mb-3'
      onClick={goToResourceSection}
    >
      <div className='card animation-shrink'>
        <div className='resource-section-image-wrapper'>
          <div className='image-gradient' />
          {section.photo && (
            <img src={section.photo.file_url} alt={section.name} />
          )}
        </div>
        <div className='resource-section-info-wrapper'>
          <h4>{section.name}</h4>
          <p>{section.description}</p>
        </div>
      </div>
    </div>
  );
};

export default ResourceSectionCard;
