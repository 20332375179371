import React from "react";
import moment from "moment";

import cg3 from "../../../assets/images/op36/cg3.png";
import "./ChallengeCard.scss";

class ChallengeCard extends React.Component {
  state = {
    isHovering: false
  };
  render() {
    const { challenge, onClick, onDelete } = this.props;
    return (
      <div
        id='ChallengeCard'
        onClick={() => onClick(challenge.id)}
        onMouseEnter={() => this._toggleHoverState("in")}
        onMouseLeave={() => this._toggleHoverState("out")}
      >
        <div
          className='challenge-image-container'
          style={{
            backgroundImage: `url(${
              challenge.photo ? challenge.photo.file_url : cg3
            })`
          }}
        >
          {this.state.isHovering && (
            <div className='challenge-delete-container'>
              <i
                className='fa fa-trash-o'
                aria-hidden='true'
                onClick={e => onDelete(e, challenge.id)}
              />
            </div>
          )}
        </div>
        <div className='challenge-card-footer'>
          <div
            className='challenge-card-icon'
            style={
              challenge.icon
                ? {
                    backgroundImage: `url(${challenge.icon.file_url})`
                  }
                : {}
            }
          />
          <div className='challenge-name-container'>
            <p className='challenge-name'>{challenge.name}</p>
            {challenge.start_date && challenge.end_date && (
              <p className='challenge-dates'>{`${moment(
                challenge.start_date
              ).format("M/DD/YY")} - ${moment(challenge.end_date).format(
                "M/DD/YY"
              )}`}</p>
            )}
          </div>
          {challenge.video_url ? (
            <div className='challenge-points-container'>
              <p className='challenge-points-video'>{`+${challenge.points}pts`}</p>
              <i className='fa fa-video-camera' aria-hidden='true' />
            </div>
          ) : (
            <p className='challenge-points'>{`+${challenge.points}pts`}</p>
          )}
        </div>
      </div>
    );
  }

  _toggleHoverState = type => {
    if (type === "in") {
      this.setState({ isHovering: true });
    } else if (type === "out") {
      this.setState({ isHovering: false });
    }
  };
}

export default ChallengeCard;
