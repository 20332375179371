import React from 'react';

const VimeoVideo = (props) => {
  // let regExp = /^.*(vimeo.com\/)([^#\&\?]*).*/;
  let regExp = /(https ?:\/\/)?(www\.)?(player\.)?vimeo\.com\/([a-z]*\/)*([‌​0-9]{6,11})[?]?.*/; // eslint-disable-line no-useless-escape
  let match = props.url.match(regExp)
  let vidSrc;
  let videoId;
  if (match && match[5].length) {
    videoId = match[5];
    vidSrc = `https://player.vimeo.com/video/${videoId}?title=0&byline=0&portrait=0`
  }

  let ifr = React.createElement(
    'iframe',
    { 
      src: vidSrc,
      frameBorder: "0",
      allowFullScreen: true
    }
  )

  return (
    <div className="container">
      <div className="VimeoVideo">
        {ifr}
        {/* <iframe src={src} height="200" frameBorder="0"></iframe> */}
      </div>
    </div>
  )
}

export default VimeoVideo;