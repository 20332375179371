import React from "react";
import { Droppable } from "react-beautiful-dnd";

import "./BlockBuilder.scss";

class BlockBuilder extends React.Component {
  state = {
    descriptionClicked: false,
    hovering: false,
    collapsed: false
  };
  render() {
    const { descriptionClicked, hovering, collapsed } = this.state;
    const {
      block,
      index,
      grabTask,
      handleBlockInput,
      deleteBlock,
      moveBlock
    } = this.props;
    return (
      <div
        id='BlockBuilder'
        onMouseEnter={() => this.setState({ hovering: true })}
        onMouseOver={() =>
          hovering ? false : this.setState({ hovering: true })
        }
        onMouseLeave={() => this.setState({ hovering: false })}
      >
        <div className='block-move-container'>
          {hovering && (
            <div className='block-move-box'>
              <i
                className='fa fa-sort-asc'
                aria-hidden='true'
                onClick={() => moveBlock(-1, index)}
              />
              <i
                className='fa fa-sort-desc'
                aria-hidden='true'
                onClick={() => moveBlock(1, index)}
              />
            </div>
          )}
        </div>

        <div className={`block-builder-content`}>
          <div className='block-builder-title-bar'>
            <input
              type='text'
              name='name'
              value={block.name}
              className='block-title'
              onChange={e =>
                handleBlockInput(e.target.name, e.target.value, index)
              }
              placeholder={"---"}
            />
            <i
              className={`fa fa-chevron-up ${collapsed && "rotated"}`}
              aria-hidden='true'
              onClick={this._toggleCollapse}
            />
          </div>
          {!collapsed && (
            <div className='block-duration-box'>
              <p>Duration</p>
              <input
                type='number'
                name='duration'
                value={block.duration}
                onChange={e =>
                  handleBlockInput(e.target.name, e.target.value, index)
                }
                className='block-duration-input'
                placeholder={"minutes"}
                onWheel={e => e.target.blur()}
                onBlur={
                  block.duration === ""
                    ? () => handleBlockInput("duration", 0, index)
                    : () => false
                }
              />
            </div>
          )}
          {!collapsed && (
            <div className='block-description-container'>
              {block.description || descriptionClicked ? (
                <textarea
                  name='description'
                  value={block.description}
                  onChange={e =>
                    handleBlockInput(e.target.name, e.target.value, index)
                  }
                  onBlur={this._closeEmptyTextArea}
                ></textarea>
              ) : (
                <p
                  className='add-block-description-button'
                  onClick={this._openDescription}
                >
                  + Add Description
                </p>
              )}
            </div>
          )}
          {!collapsed && (
            <div className='active-tasks-container'>
              {block.tasks.map((task, i) => {
                return grabTask(task.type, task.id, index, i);
              })}
            </div>
          )}
          {!collapsed && (
            <Droppable droppableId={`block-task-drop-zone-${index}`}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  className={`task-drop-box ${snapshot.isDraggingOver ? 'drop-hover' : ''}`}
                  {...provided.droppableProps}
                >
                  {snapshot.isDraggingOver ? (
                    <p>Add task to block</p>
                  ) : (
                    <p className='drop-box-placeholder'>
                      Drag and drop and Activity, Drill, or Curriculum item here
                    </p>
                  )}
                </div>
              )}
            </Droppable>
          )}
          {collapsed && (
            <div className='closed-block-info'>
              <p className='duration-pill'>{`${block.duration} min`}</p>
              <p className='subtext'>Open block to edit</p>
            </div>
          )}
        </div>
        <div className='delete-block-container'>
          {hovering && (
            <i
              className='fa fa-trash-o delete-block'
              aria-hidden='true'
              onClick={() => deleteBlock(index)}
            />
          )}
        </div>
      </div>
    );
  }

  _toggleCollapse = () => {
    const { collapsed } = this.state;
    this.setState({ collapsed: !collapsed });
  };

  _closeEmptyTextArea = () => {
    this.setState({ descriptionClicked: false });
  };

  _openDescription = () => {
    this.setState({ descriptionClicked: true });
  };
}

export default BlockBuilder;
