import ReactTooltip from 'react-tooltip'
import PropTypes from 'prop-types'
import SideBarIconLink from './SideBarIconLink'
import SideBarLink from './SideBarLink'
import { useSelector } from 'react-redux'
import styles from '../../../assets/styles/components/common/SideBarItem.module.scss'

const SideBarItem = ({ icon, title, links = [], index }) => {
  const singleLink = links.length === 1
  const currentTab = useSelector(state => state.ui.currentTab)
  const selected = links.filter((link) => link.tab === currentTab)[0]

  return (
    <>
      <SideBarIconLink icon={icon} links={links} index={index} singleLink={singleLink} selected={selected}/>

      <ReactTooltip id={`sidebar-tooltip-${index}`}
        effect='solid'
        delayUpdate={5}
        delayHide={5}
        place={'right'}
        className={styles.navTooltip}
      >
        {singleLink ? (
          <div className={styles.singleLink}>
            <SideBarLink link={links[0]} singleLink={singleLink} title={title}/>
          </div>
        ) : (
          <div className={styles.section}>
            <div className={styles.sectionTitle}>
              {title}
            </div>
            {links.map((link, index) =>
              <SideBarLink key={index} link={link} singleLink={singleLink} title={title} collapsed={true} selected={selected?.title === link.title}/>)
            }
          </div>
        )}
      </ReactTooltip>

    </>
  )
}

SideBarItem.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  links: PropTypes.array,
  index: PropTypes.number,
}

export default SideBarItem
