import { ReactComponent as GolferIcon } from '../../../assets/images/common/icons/golfer-half-icon.svg'
import styles from './CoachDashboardScheduleSessionCard.module.scss'
import StockProfilePic from '../../../assets/images/mock/default-profile-icon.png'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { UncontrolledPopover } from 'reactstrap'
import NotificationsPopover from '../../common/NotificationsPopover'
import { ReactComponent as GeneralNotificationIcon } from '../../../assets/images/common/icons/bell.svg'
import { useState } from 'react'
import { clearNotifications, getUnreadNotifications } from '../../../actions/notificationActions'

const CoachDashboardScheduleSessionCard = ({ session }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const notifications = useSelector(state => state.notifications)
  const currentUser = useSelector(state => state.user.current_user)
  const [notificationsLoader, setNotificationsLoader] = useState(false)
  const enrollmentNotifications = notifications.filter(
    (n) => n.type === 'NotificationApply' && n.enrollment.course_id === session.programId && n.read === false
  )

  const navigateFromNotification = (type, id) => {
    navigate(
      `/programs/${id}`,
      {
        state: { id: id },
      }
    )
  }

  const handleClearNotifications = () => {
    setNotificationsLoader(true)
    dispatch(clearNotifications(currentUser.id)).then(() => {
      setNotificationsLoader(false)
    })
  }

  const refreshNotifications = () => {
    setNotificationsLoader(true)
    dispatch(getUnreadNotifications(currentUser.id)).then(() => {
      setNotificationsLoader(false)
    })
  }

  return (
    <div
      className={styles.scheduleSessionCard}
      onClick={() => navigate(`/programs/${session.programId}`)}
      role='button'
      tabIndex={0}
      style={{ borderLeftColor: session.categoryColor || '#ff6633' }}
      onKeyDown={(e) => { e.key === 'Enter' && navigate(`/programs/${session.programId}`) }}
    >
      <div className={styles.header}>
        <p className={styles.title}>{session.programTitle}</p>
        {enrollmentNotifications.length > 0 &&
          <>
            <button
              className={styles.notificationButton}
              id={`ProgramNotificationsButton-${session.sessionId}`}
              onClick={(e) => e.stopPropagation()}
            >
              <GeneralNotificationIcon className={styles.notificationIcon} />
              <div className={styles.badge} />
            </button>
            <UncontrolledPopover
              placement='bottom'
              target={`ProgramNotificationsButton-${session.sessionId}`}
              trigger='legacy'
            >
              <NotificationsPopover
                notifications={enrollmentNotifications}
                refreshNotifications={refreshNotifications}
                clearNotifications={handleClearNotifications}
                loader={notificationsLoader}
                navigateFromNotification={navigateFromNotification} />
            </UncontrolledPopover>
          </>
        }
      </div>
      <p className={styles.subtitle}>{session.sessionTitle}</p>

      <div>
        <i className={`fa fa-clock-o ${styles.faIcon}`}/>
        <span className={styles.text}>
          {`${session.sessionStartDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }).toLowerCase()} -
          ${session.sessionEndDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }).toLowerCase()}`}
        </span>
      </div>

      {
        session.location &&
        <div>
          <i className={`fa fa-map-marker ${styles.faIcon}`}/><span className={styles.text}>{session.location}</span>
        </div>
      }

      <div className={styles.enrollments}>
        <GolferIcon className={styles.icon}/>
        <span className={styles.textStudents}>{session.enrollments} Students</span>
      </div>

      <div className={styles.studentsProfiles}>
        {session.enrollmentsProfiles.slice(0, 4).map((url, index) =>
          <div
            className={styles.profileImg}
            key={index}
            style={{
              backgroundImage: url
                ? `url(${url})`
                : `url(${StockProfilePic})`,
            }}
          />
        )}
        {session.enrollmentsProfiles.length > 4 && <div className={styles.moreProfiles}>+ {session.enrollmentsProfiles.length - 4} more</div>}
      </div>

    </div>
  )
}

CoachDashboardScheduleSessionCard.propTypes = {
  session: PropTypes.shape({
    programTitle: PropTypes.string,
    sessionTitle: PropTypes.string,
    enrollmentsProfiles: PropTypes.array,
    enrollments: PropTypes.number,
    location: PropTypes.string,
    sessionStartDate: PropTypes.object,
    sessionEndDate: PropTypes.object,
    programId: PropTypes.number,
    sessionId: PropTypes.number,
    categoryColor: PropTypes.string,
  }),
}

export default CoachDashboardScheduleSessionCard
