import React from 'react'

import PlayIcon from '../assets/images/op36/op36_play.png'
import TrainIcon from '../assets/images/op36/op36_train.png'
import SocialIcon from '../assets/images/op36/op36_social.png'
import GoalIcon from '../assets/images/op36/op36_goals.png'
import EventIcon from '../assets/images/op36/op36_event.png'
import AnnouncementIcon from '../assets/images/op36/op36_announcement.png'

const activityTypes = [
  { accessor: 'activity_play', displayName: 'Plays' },
  { accessor: 'activity_practice', displayName: 'Practices' },
  { accessor: 'activity_social', displayName: 'Social' },
  { accessor: 'activity_goal', displayName: 'Goals' },
  { accessor: 'activity_announcement', displayName: 'Announcements' },
]

export default activityTypes

const play = { color: '#33D9B2', icon: PlayIcon }

const train = { color: '#34ACE0', icon: TrainIcon }

const social = { color: '#6677DE', icon: SocialIcon }

const goal = { color: '#FF6633', icon: GoalIcon }

const event = { color: '#12a482', icon: EventIcon }

const announcement = { color: '#ff6633', icon: AnnouncementIcon }

// HELPER FUNCTIONS FOR THESE CONSTANTS //

export const renderActivityIcon = (activity) => {
  let icon = null

  switch (activity.type) {
    case 'ActivityPlay':
      icon = play.icon
      break
    case 'ActivityPractice':
      icon = train.icon
      break
    case 'ActivitySocial':
      icon = social.icon
      break
    case 'ActivityChallenge':
      icon = social.icon
      break
    case 'ActivityGoal':
      icon = goal.icon
      break
    case 'ActivityObjective':
      icon = goal.icon
      break
    case 'ActivityEvent':
      icon = event.icon
      break
    case 'ActivityAnnouncement':
      icon = announcement.icon
      break
    default:
      icon = null
  }

  if (icon) {
    return (
      <img
        src={icon}
        alt={activity.caption}
      />
    )
  }
  return null
}

export const renderActivityColor = (activity) => {
  let color = null

  switch (activity.type) {
    case 'ActivityPlay':
      color = play.color
      break
    case 'ActivityPractice':
      color = train.color
      break
    case 'ActivitySocial':
      color = social.color
      break
    case 'ActivityChallenge':
      color = social.color
      break
    case 'ActivityGoal':
      color = goal.color
      break
    case 'ActivityObjective':
      color = goal.color
      break
    case 'ActivityEvent':
      color = event.color
      break
    case 'ActivityAnnouncement':
      color = announcement.color
      break
    default:
      color = null
  }

  if (color) {
    return color
  }
  return 'transparent'
}
