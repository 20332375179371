import React from "react";
import moment from "moment";

import "./AnnouncementsPage.scss";

function renderTypeIcon(props) {
  if (props.announcement.subject === "") {
    return (
      <i
        className='fa fa-bullhorn announcement-row-email-icon'
        aria-hidden='true'
      />
    );
  } else {
    return (
      <i
        className='fa fa-envelope-o announcement-row-push-icon'
        aria-hidden='true'
      />
    );
  }
}

let AnnouncementRow = props => {
  var html = props.announcement.message;
  var tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  return (
    <tr className='border-bottom announcement-row'>
      {/* <td className="hidden-md-down announcement-row-type">
        {renderTypeIcon(props)}
      </td> */}
      <td className='hidden-md-down'>
        <p className='announcement-row-message'>{tempDiv.innerText}</p>
      </td>
      <td className='hidden-md-down announcement-row-date'>
        {moment(props.announcement.created_at).format("M/D/Y")}
      </td>
      <td className='hidden-md-down announcement-row-preview'>
        <i
          className='fa fa-search'
          aria-hidden='true'
          onClick={() => props.toggleModal(props.announcement)}
        />
      </td>
      <div className='hidden-md-up'>
        <div className='row mb-2 p-1 announcement-single'>
          <div className='col'>
            <div className='card p-2'>
              <div className='row m-0 p-0'>
                <div className='col m-0 p-0'>
                  <p className='my-0 pull-left'>
                    <strong>{props.announcement.classes}</strong>
                  </p>
                </div>
                <div className='col m-0 p-0'>
                  <p className='my-0 pull-right'>
                    {moment(props.announcement.created_at).format("M/D/Y")}
                  </p>
                </div>
              </div>

              <p className='my-0 announcement-row-type'>
                {renderTypeIcon(props)}
              </p>
              <p className='my-0 text-fade message-mobile '>
                {tempDiv.innerText}
              </p>
            </div>
          </div>
        </div>
      </div>
    </tr>
  );
};

export default AnnouncementRow;
