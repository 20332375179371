import { ReactComponent as Op36Icon } from '../../../assets/images/common/icons/sidebarMenu/Op36.svg'
import { ReactComponent as HomeIcon } from '../../../assets/images/common/icons/sidebarMenu/Home.svg'
import { ReactComponent as FamilyIcon } from '../../../assets/images/common/icons/sidebarMenu/Family.svg'
import { ReactComponent as ProgramsIcon } from '../../../assets/images/common/icons/sidebarMenu/Programs.svg'
import { ReactComponent as MarketingIcon } from '../../../assets/images/common/icons/sidebarMenu/Marketing.svg'
import { ReactComponent as SalesIcon } from '../../../assets/images/common/icons/sidebarMenu/Sales.svg'
import { ReactComponent as GlobeIcon } from '../../../assets/images/common/icons/sidebarMenu/Globe.svg'
import { ReactComponent as CartIcon } from '../../../assets/images/common/icons/sidebarMenu/Cart.svg'
import { ReactComponent as SettingsIcon } from '../../../assets/images/common/icons/sidebarMenu/Settings.svg'
import { ReactComponent as AccountIcon } from '../../../assets/images/common/icons/sidebarMenu/Account.svg'
import { ReactComponent as UserIcon } from '../../../assets/images/common/icons/sidebarMenu/User.svg'
import { ReactComponent as LessonIcon } from '../../../assets/images/common/icons/sidebarMenu/Lesson.svg'
import { ReactComponent as TrophyIcon } from '../../../assets/images/common/icons/sidebarMenu/Trophy.svg'
import { ReactComponent as LevelUpIcon } from '../../../assets/images/common/icons/sidebarMenu/LevelUp.svg'
import { ReactComponent as FilterIcon } from '../../../assets/images/common/icons/sidebarMenu/Filter.svg'
import { ReactComponent as FileIcon } from '../../../assets/images/common/icons/sidebarMenu/File.svg'
import { ReactComponent as ClipboardIcon } from '../../../assets/images/common/icons/sidebarMenu/Clipboard.svg'
import { ReactComponent as AcademyIcon } from '../../../assets/images/common/icons/sidebarMenu/Academy.svg'
import { ReactComponent as ResourcesOpenIcon } from '../../../assets/images/common/icons/sidebarMenu/ResourcesOpen.svg'
import { ReactComponent as ReportsIcon } from '../../../assets/images/common/icons/sidebarMenu/Reports.svg'


const SideBarIcon = ({ icon, className }) => {
  const icons = {
    'home': <HomeIcon className={className}/>,
    'op36': <Op36Icon className={className}/>,
    'family': <FamilyIcon className={className}/>,
    'programs': <ProgramsIcon className={className}/>,
    'marketing': <MarketingIcon className={className}/>,
    'sales': <SalesIcon className={className}/>,
    'reports': <ReportsIcon className={className}/>,
    'globe': <GlobeIcon className={className}/>,
    'cart': <CartIcon className={className}/>,
    'settings': <SettingsIcon className={className}/>,
    'account': <AccountIcon className={className}/>,
    'users': <UserIcon className={className}/>,
    'lesson': <LessonIcon className={className}/>,
    'trophy': <TrophyIcon className={className}/>,
    'levelup': <LevelUpIcon className={className}/>,
    'filter': <FilterIcon className={className}/>,
    'file': <FileIcon className={className}/>,
    'clipboard': <ClipboardIcon className={className}/>,
    'academy': <AcademyIcon className={className}/>,
    'resources': <ResourcesOpenIcon className={className}/>,
  }
  return icons[icon]
}

export default SideBarIcon
