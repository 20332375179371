import { useDispatch, useSelector } from 'react-redux'
import { displayWithCurrency } from '../../../../utility/currency'
import { setSelectedPackage } from '../../../../reducers/programRegistrationReducer'
import MCElement from '../../../common/MCElement'

import './PackageSelection.scss'
import colors from '../../../../assets/styles/globals.scss'
import PackageCard from '../../ProgramLanding/ProgramPresentationPage/PackageCard'

const PackageSelection = () => {
  const dispatch = useDispatch()
  const selectedPackage = useSelector(state => state.programRegistration.selectedPackage)
  const { coursePackages } = useSelector(state => state.programRegistration.enrollmentData)
  const { community } = useSelector(state => state.programPresentation.course)

  const handleSelectPackage = (pkg) => {
    if (selectedPackage.id !== pkg.id) {
      dispatch(setSelectedPackage(pkg))
    }
  }

  return (
    <div id='PackageSelection'>
      <h5 className='step-label'>Select a package:</h5>
      {coursePackages.map((pkg) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
          key={pkg.id}
          onClick={() => handleSelectPackage(pkg)}
          className='d-flex package-card-option'
        >
          <MCElement
            type='radio'
            onKeyDown={(e) => {
              e.key === 'Enter' && handleSelectPackage(pkg)
            }}
            primaryColor={selectedPackage.id === pkg.id ? colors.secondaryColor : colors.lightNavy}
            isSelected={selectedPackage.id === pkg.id}
          />
          <PackageCard pkg={pkg} currency={community.currency} noBorder />
        </div>
      ))}
    </div>
  )
}

export default PackageSelection
