import { createAsyncThunk } from '@reduxjs/toolkit'
import { deserialize } from '../utility/serialization'

import Axios from '../axios'
import { handleAPIError } from '../utility/actionHelper'

// THUNKS

export const getSearchTags = createAsyncThunk(
  'lessonPlan/getSearchTags',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await requestGetSearchTags()
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

// API CALLS

export const requestGetSearchTags = () => {
  const requestUrl = 'api/v1/search_tags'

  return Axios.get(requestUrl)
}
