import styles from './CoachDashboardStats.module.scss'
import StatsItemPopover from './StatsItemPopover'
import PropTypes from 'prop-types'

const StatsItem = ({ icon, yards, golfers, division }) => (
  <>
    <div className={styles.statsItem} id={`Popover-${division}`}>
      {icon}
      <div>
        <div className={styles.level}>Level {division}</div>
        <div className={styles.yards}>{yards} Yds</div>
      </div>
      <div className={styles.statsCount}>
        {golfers?.length}
      </div>
    </div>
    {golfers?.length > 0 &&
      <StatsItemPopover divisionId={division} golfers={golfers}/>
    }
  </>
)

StatsItem.propTypes = {
  icon: PropTypes.element,
  yards: PropTypes.string,
  golfers: PropTypes.array,
  division: PropTypes.number,
}


export default StatsItem
