import * as types from "../actions/actionTypes";
// import update from 'immutability-helper';
// state = [] : Start out with no accounts
export default function salesLeads(state = [], action) {
  switch (action.type) {
    case types.GET_SALES_LEADS:
      return Object.assign({}, state, {
        sales_leads: action.sales_leads,
      });
    case types.UPDATE_SALES_LEAD:
      return Object.assign({}, state, {
        sales_leads: state.sales_leads.map((lead) =>
          lead.id === action.sales_lead.id ? action.sales_lead : lead
        ),
      });
    default:
      return state;
  }
}
