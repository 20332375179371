import { useDispatch, useSelector } from 'react-redux'

import { ReactComponent as LessonPlanIcon } from '../../../../assets/images/common/icons/programBuilder/lesson-plan-icon.svg'
import { components } from 'react-select'
import Select from '../../../common/form/Select'

import { setField } from '../../../../reducers/programBuilderReducer'

import './LessonPlanSelect.scss'

/* eslint-disable react/prop-types */
const Option = ({ data, ...props }) => (
  <components.Option {...props}>
    <div className='d-flex align-items-center lesson-plan-option p-2'>
      <LessonPlanIcon aria-hidden='true' id='lessonPlanIcon' style={{ flexShrink: '0' }} />

      <div className='d-flex flex-column justify-content-center ml-3'>
        <p className='name'>{data.name}</p>
        <p className='sessions mr-1'>{data.sessions.length} Sessions </p>
      </div>
    </div>
  </components.Option>
)

/* eslint-disable react/prop-types */
const SingleValue = ({ data, ...props }) => (
  <components.SingleValue {...props}>
    <div className='d-flex align-items-center lesson-plan-value pl-2'>
      <LessonPlanIcon aria-hidden='true' id='lessonPlanIcon' style={{ flexShrink: '0' }} />

      <p className='name ml-3'>{data.name}</p>
    </div>
  </components.SingleValue>
)

const LessonPlanSelect = () => {
  const dispatch = useDispatch()

  const { errors, communityLessonPlans, lessonPlan } = useSelector(state => state.programBuilder)

  const handleLessonPlanChange = (lessonPlan) => {
    dispatch(setField({ field: 'lessonPlan', value: lessonPlan }))
  }

  return (
    <div id='LessonPlanSelect'>
      <Select
        name='lesson-plan-select'
        placeholder='Attach a Lesson Plan to your program. This will show up for you and your staff in the mobile app'
        value={lessonPlan}
        options={communityLessonPlans}
        components={{
          SingleValue,
          Option,
        }}
        isSearchable={false}
        menuPlacement='auto'
        minMenuHeight={300}
        menuPosition='fixed'
        onChange={handleLessonPlanChange}
        error={errors.lessonPlan}
        isClearable
      />
    </div>
  )
}

export default LessonPlanSelect
