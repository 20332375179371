import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'

import { defaultPerPage, resetSelectedCategory } from '../../reducers/communityLandingPageReducer'
import { getCategoryPrograms } from '../../actions/programActions'
import ProgramCard from './ProgramCard'
import InfiniteScroll from '../infinite-scroll/InfiniteScroll'

import classes from './ProgramList.module.scss'

const CategoryDetails = () => {
  const dispatch = useDispatch()
  const { community, selectedCategory: category } = useSelector(state => state.communityLandingPage)

  return (
    <>
      <h2 className={classnames('my-3', classes.categoryName)}>
        <button
          onClick={() => dispatch(resetSelectedCategory())}
          className={classes.categoryNameButton}
        >
          <i className={classnames('fa fa-angle-left mr-3', classes.angle)} />
          {category.name}
        </button>
      </h2>
      <div className='d-flex flex-column flex-lg-row'>
        {!!category.headerImage && (
          <img
            src={category.headerImage.fileUrl}
            alt='category header'
            className={classnames('flex-shrink-0', classes.headerImage)}
          />
        )}
        <div className={classnames('mt-3 mt-lg-0 ml-lg-3', classes.description)}>{category.description}</div>
      </div>
      <InfiniteScroll
        next={() => {
          dispatch(getCategoryPrograms({ categoryId: category.id, communityId: community.id, page: category.page + 1, perPage: defaultPerPage }))
        }}
        hasMore={category.hasMore}
        dataLength={category.courses.length}
        scrollableTarget='programsScreen'
      >
        {category.courses.map(course => (
          <ProgramCard
            key={course.id}
            program={course}
            community={community}
            color={category.color}
            hasFutureRegistration={course.registrationClosedReason === 'registration_closed'}
          />
        ))}
      </InfiniteScroll>
    </>
  )
}

export default CategoryDetails
