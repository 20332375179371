import * as constants from "./actionTypes";
import * as common from "./commonActions";

import Axios from '../axios'

export function successRequestingResourceSections(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_RESOURCE_SECTIONS,
      resource_sections: response.data.resource_sections
    };
  } else {
    console.log("ERROR ON GETTING RESOURCE SECTIONS");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successRequestingResourceSection(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_RESOURCE_SECTION,
      resource_section: response.data.resource_section
    };
  } else {
    console.log("ERROR ON GETTING RESOURCE SECTION");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successRequestingCreateResourceSection(response) {
  if (response.status === 201) {
    return {
      type: constants.CREATE_RESOURCE_SECTION,
      resource_section: response.data.resource_section
    };
  } else {
    console.log("ERROR ON CREATING RESOURCE SECTION");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successRequestingUpdateResourceSection(response) {
  if (response.status === 201) {
    return {
      type: constants.UPDATE_RESOURCE_SECTION,
      resource_section: response.data.resource_section
    };
  } else {
    console.log("ERROR ON UPDATING RESOURCE SECTION");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successReorderingResourceSections(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_RESOURCE_SECTIONS,
      resource_sections: response.data.resource_sections,
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

// THUNKS
export function getResourceSections() {
  return function (dispatch) {
    dispatch(common.loadingChanged(true));
    return requestResourceSections()
      .then(response => dispatch(successRequestingResourceSections(response)))
      .then(repsonse => dispatch(common.loadingChanged(false)));
  };
}

export function getResourceSection(resource_section_id) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true));
    return requestResourceSection(resource_section_id)
      .then(response => dispatch(successRequestingResourceSection(response)))
      .then(repsonse => dispatch(common.loadingChanged(false)));
  };
}

export function createResourceSection(resource_section) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true));
    return requestCreateResourceSection(resource_section)
      .then(response =>
        dispatch(successRequestingCreateResourceSection(response))
      )
      .then(response => dispatch(common.loadingChanged(false)));
  };
}

export function updateResourceSection(resource_section, categories) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true));
    return requestUpdateResourceSection(resource_section, categories)
      .then(response =>
        dispatch(successRequestingUpdateResourceSection(response))
      )
      .then(response => dispatch(common.loadingChanged(false)));
  };
}

export function reorderResourceSections(resource_sections) {
  return function (dispatch) {
    return requestReorderResourceSections(resource_sections).then((response) =>
      dispatch(successReorderingResourceSections(response))
    );
  };
}

// API CALLS

function requestResourceSections() {
  let requestUrl = "/api/v1/resource_sections";
  return Axios.get(requestUrl);
}

function requestResourceSection(resource_section_id) {
  let requestUrl = "/api/v1/resource_sections/" + resource_section_id;
  return Axios.get(requestUrl);
}

function requestCreateResourceSection(resource_section) {
  let requestUrl = "/api/v1/resource_sections/";

  var data = new FormData();

  data.append("name", resource_section.name);
  data.append("description", resource_section.description);

  if (resource_section.resource_section_photo) {
    data.append(
      "resource_section_photo",
      resource_section.resource_section_photo
    );
  }

  return Axios.post(requestUrl, data);
}

function requestUpdateResourceSection(resource_section, categories) {
  let requestUrl = "/api/v1/resource_sections/" + resource_section.id;

  var data = new FormData();

  if (resource_section.name) data.append("name", resource_section.name);
  if (resource_section.description)
    data.append("description", resource_section.description);

  if (categories && categories.length > 0) {
    data.append("resource_categories", JSON.stringify(categories));
  }

  if (resource_section.resource_section_photo) {
    data.append(
      "resource_section_photo",
      resource_section.resource_section_photo
    );
  }

  return Axios.put(requestUrl, data);
}

function requestReorderResourceSections(resource_sections) {
  let requestUrl = "/api/v1/resource_sections/reorder";
  let data = new FormData();
  resource_sections.forEach((section) => {
    data.append(`section_orders[${section.id}]`, section.order);
  });

  return Axios.post(requestUrl, data);
}
