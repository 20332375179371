import PropTypes from 'prop-types'
import { useMemo } from 'react'
import ReactSelect, { components } from 'react-select'
import clsNames from 'classnames'
import FieldError from '../../common/FieldError'

import styles from './Select.module.scss'
import { ReactComponent as DropdownIcon } from '../../../assets/images/common/icons/select-drop-down-icon-light-navy.svg'

const DropdownIndicator = ({ selectProps, ...props }) => (
  <components.DropdownIndicator {...props}>
    <div style={selectProps.menuIsOpen ? { transform: 'scaleY(-1)' } : null}>
      <DropdownIcon />
    </div>
  </components.DropdownIndicator>
)

DropdownIndicator.propTypes = {
  selectProps: PropTypes.shape({
    menuIsOpen: PropTypes.bool,
  }),
}

const Select = ({ onChange, value, options, error, classNames, components, innerRef, ...props }) => {
  const defaultClassNames = useMemo(() => ({
    control: () => clsNames(styles.control, { [classNames?.control?.()]: classNames?.control?.(), [styles['control-error']]: !!error }),
    valueContainer: () => clsNames(styles['value-container'], { [classNames?.valueContainer?.()]: classNames?.valueContainer?.() }),
    singleValue: () => clsNames(styles['single-value'], { [classNames?.singleValue?.()]: !!classNames?.singleValue?.() }),
    placeholder: () => clsNames(styles.placeholder, { [classNames?.placeholder?.()]: classNames?.placeholder?.() }),
    input: () => clsNames(styles['input-wrapper'], { [classNames?.input?.()]: classNames?.input?.() }),
    dropdownIndicator: () => clsNames(styles['indicator-container'], { [classNames?.dropdownIndicator?.()]: classNames?.dropdownIndicator?.() }),
    menu: () => clsNames(styles.menu, { [classNames?.menu?.()]: classNames?.menu?.() }),
    menuList: () => clsNames({ [classNames?.menuList?.()]: classNames?.menuList?.() }),
    noOptionsMessage: () => clsNames(styles['no-options-message'], { [classNames?.noOptionsMessage?.()]: classNames?.noOptionsMessage?.() }),
    option: (state) => clsNames(styles.option, { [classNames?.option?.()]: classNames?.option?.(), [styles['option-disabled']]: !!state.isDisabled }),
  }), [classNames, error])

  const defaultComponents = useMemo(() => ({
    IndicatorSeparator: null,
    DropdownIndicator: DropdownIndicator,
  }), [])

  return (
    <div className={styles['select-wrapper']}>
      <ReactSelect
        options={options}
        onChange={onChange}
        value={value}
        classNames={{ ...defaultClassNames, ...classNames }}
        components={{ ...defaultComponents, ...components }}
        ref={innerRef}
        {...props}
      />
      {error && <FieldError message={error} />}
    </div>
  )
}

// This component should behave exactly like the React Select, so any other prop found at
// https://react-select.com/props should be passed to the aggregated ReactSelect component

const OptionPropType = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.any,
  isDisabled: PropTypes.bool,
})

Select.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    OptionPropType,
    PropTypes.arrayOf(OptionPropType),
  ]),
  options: PropTypes.arrayOf(OptionPropType),
  error: PropTypes.arrayOf(PropTypes.string),
  classNames: PropTypes.object,
  components: PropTypes.object,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.object }),
  ]),
}

export default Select
