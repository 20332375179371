import { useDispatch, useSelector } from 'react-redux'

import { ReactComponent as InfoCircleIcon } from '../../../../assets/images/common/icons/info-circle-icon-dark.svg'
import { setField } from '../../../../reducers/programBuilderReducer'
import colors from '../../../../assets/styles/globals.scss'
import Tooltip from '../../../common/Tooltip'
import FieldError from '../../../common/FieldError'
import MCElement from '../../../common/MCElement'
import classNames from 'classnames'

import './PaymentMethodSelect.scss'

const PaymentMethodSelect = () => {
  const dispatch = useDispatch()

  const errors = useSelector(state => state.programBuilder.errors)
  const community = useSelector(state => state.user.current_user.communities[0])
  const paymentMethod = useSelector(state => state.programBuilder.paymentMethod)
  const stripeAccountConnected = !!community.selected_stripe_connected_account
  const { cash, card } = paymentMethod

  const handleSetPaymentMethod = (newPaymentMethod) => {
    dispatch(setField({ field: 'paymentMethod', value: newPaymentMethod }))
  }

  return (
    <div id='PaymentMethodSelect'>
      <Tooltip id='stripeRequiredInfo' className='payment-method-select-tooltip' />
      <div className='d-flex align-items-center checkbox-select-wrapper'>
        <div
          className={classNames('d-flex align-items-center', { 'disabled': !stripeAccountConnected })}
          style={{ cursor: 'pointer' }}
          aria-hidden='true'
          onClick={() => handleSetPaymentMethod({ card: !card, cash })}
        >
          <MCElement type='checkbox'
            isSelected={card}
            disabled={!stripeAccountConnected}
            primaryColor={!stripeAccountConnected && colors.lightNavy}
          />
          <div className='checkbox-label ml-3'>Online (Card)</div>
        </div>
        {!stripeAccountConnected &&
          <InfoCircleIcon
            data-tip='A Stripe account must be connected in order to use this payment method.'
            data-for='stripeRequiredInfo'
            aria-hidden='true'
            id='infoCircleIcon'
          />
        }
      </div>
      <div
        className='d-flex align-items-center checkbox-select-wrapper'
        style={{ cursor: 'pointer' }}
        aria-hidden='true'
        onClick={() => handleSetPaymentMethod({ card, cash: !cash })}
      >
        <MCElement type='checkbox' isSelected={cash} />
        <div className='checkbox-label ml-3'>Offline (Cash, Checks, Member Charge)</div>
      </div>

      {errors.paymentMethod && <FieldError message={errors.paymentMethod}/>}
    </div>
  )
}

export default PaymentMethodSelect
