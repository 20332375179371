import * as scheduleSessionActions from '../../../actions/scheduleSessionActions'
import ScheduleSessionForm from './ScheduleSessionForm'
import moment from 'moment-timezone'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { deserialize } from '../../../utility/serialization'
import { getMomentInCommunityTimezone } from '../../../utility/datesHelper'

const ScheduleSessionFormContainer = ({
  selectedScheduleSession,
  scheduleAction,
  addScheduleSession,
  editScheduleSession,
  removeScheduleSession,
  handleCloseForm
}) => {
  const dispatch = useDispatch()
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const action = selectedScheduleSession ? 'update' : 'create'
  const timezone = useSelector(state => state.user.current_user.communities[0].timezone)

  const handleCreateScheduleSession = scheduleSession => {
    return dispatch(scheduleSessionActions.createScheduleSession(scheduleSession))
  }

  const handleUpdateScheduleSession = scheduleSession => {
    return dispatch(scheduleSessionActions.updateScheduleSession(scheduleSession))
  }

  const handleDeleteScheduleSession = scheduleSessionId => {
    return dispatch(scheduleSessionActions.deleteScheduleSession(scheduleSessionId))
  }

  const submitScheduleSession = (e, scheduleSession) => {
    e.preventDefault()
    setLoading(true)
    action === 'update'
      ? handleUpdateScheduleSession(scheduleSession).then( (response) => {
        setShowConfirmationModal(false)
        setErrors({})
        setLoading(false)
        handleCloseForm()
      })
      .catch((err) => {
        setLoading(false)
        if (err.response && err.response.status === 422) {
          setErrors(deserialize(err.response.data.errors))
        } else {
          setErrors({ general: ['Something went wrong, please try again'] })
        }
      })
      : handleCreateScheduleSession(scheduleSession).then( (response) => {
        setShowConfirmationModal(false)
        setErrors({})
        setLoading(false)
        handleCloseForm()
      })
      .catch((err) => {
        setLoading(false)
        if (err.response && err.response.status === 422) {
          setErrors(deserialize(err.response.data.errors))
        } else {
          setErrors({ general: ['Something went wrong, please try again'] })
        }
      })
  }

  const closeScheduleSessionModal = (scheduleSession) => {
    if (
      (scheduleSession?.title || '') !== (selectedScheduleSession?.title || '') ||
      (scheduleSession?.location || '') !== (selectedScheduleSession?.location || '') ||
      (scheduleSession?.description || '') !== (selectedScheduleSession?.description || '') ||
      (scheduleSession?.startDate?.toString() || '') !== (selectedScheduleSession?.startDate?.toString() || '') ||
      (scheduleSession?.endDate?.toString() || '') !== (selectedScheduleSession?.endDate?.toString() || '')
    ) {
      setShowConfirmationModal(true)
    } else {
      handleCloseForm()
    }
  }

  const updateScheduleState = (e, scheduleSession) => {
    e.preventDefault()
    action === 'update'
    ? editScheduleSession(scheduleSession)
    : addScheduleSession(scheduleSession)
    setShowConfirmationModal(false)
    handleCloseForm()
  }

  const handleSubmit = (e, scheduleSession) => {
    const errors = checkValidation(scheduleSession)

    if(Object.keys(errors).length !== 0) {
      setErrors(errors)
      return
    }
    const startDateTimezone = getMomentInCommunityTimezone(scheduleSession.startDate, timezone, 'YYYY-MM-DD HH:mm:ss').toISOString()
    const endDateTimezone = getMomentInCommunityTimezone(scheduleSession.endDate, timezone, 'YYYY-MM-DD HH:mm:ss').toISOString()
    const scheduleSessionTimezone = { ...scheduleSession, startDate: moment(startDateTimezone), endDate: moment(endDateTimezone) }

    if(scheduleAction === 'update') {
      submitScheduleSession(e, scheduleSessionTimezone)
    }
    else if(scheduleAction === 'create') {
      updateScheduleState(e, scheduleSessionTimezone)
    }
  }

  const handleDelete = (scheduleSession) => {
    if(scheduleAction === 'update') {
      handleDeleteScheduleSession(scheduleSession.id)
    }
    else if(scheduleAction === 'create') {
      removeScheduleSession(scheduleSession.index)
      handleCloseForm()
    }
    setShowDeleteConfirmation(false)
  }

  const checkValidation = (scheduleSession) => {
    let err = {}
    if(!scheduleSession.startDate) {
      err.startDate = ['Select a date']
    }

    if(!scheduleSession.startDate || !scheduleSession.endDate) {
      err.endDate = ['Select start and end time']
    }
    else if(scheduleSession.startDate > scheduleSession.endDate) {
      err.endDate = ['Start time must be before or the same as the end time']
    }
    return err
  }

  return (
    <ScheduleSessionForm
      action={action}
      scheduleAction={scheduleAction}
      selectedScheduleSession={selectedScheduleSession}
      showConfirmationModal={showConfirmationModal}
      setShowConfirmationModal={setShowConfirmationModal}
      showDeleteConfirmation={showDeleteConfirmation}
      setShowDeleteConfirmation={setShowDeleteConfirmation}
      closeScheduleSessionModal={closeScheduleSessionModal}
      handleCloseForm={handleCloseForm}
      handleSubmit={handleSubmit}
      handleDelete={handleDelete}
      errors={errors}
      loading={loading}
    />
  )
}

export default ScheduleSessionFormContainer
