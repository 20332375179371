import Modal from 'react-modal'
import PropTypes from 'prop-types'
import EventDetail from '../../events/Detail/EventDetail'

const ActivityEventModal = ({ showModal, closeModal, event }) => (
  <Modal
    id='ActivityEventModal'
    isOpen={showModal}
    onRequestClose={closeModal}
    contentLabel='Activity Event Modal'
  >
    <div className='modal-header row m-0 p-0'>
      <div className='col-12 m-0 p-0'>
        <button
          type='button'
          className='close pull-right font-200'
          data-dismiss='modal'
          aria-label='Close'
          onClick={closeModal}
        >
          <span aria-hidden='true'>✕</span>
        </button>
      </div>
    </div>
    <div>
      <EventDetail eventId={event.id} />
    </div>
  </Modal>
)

ActivityEventModal.propTypes = {
  showModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
}

export default ActivityEventModal
