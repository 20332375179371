import { useSelector } from 'react-redux'
import classnames from 'classnames'
import MaterialCard from '../../../../materials/MaterialCard'
import PackageCard from '../PackageCard'

import styles from './ProgramPricing.module.scss'

const ProgramPricing = () => {
  const course = useSelector(state => state.programPresentation.course)

  return (
    <div className={classnames('p-3 pl-lg-5', styles['program-pricing'])}>
      {course.packages.map(p => (
        <PackageCard key={p.id} pkg={p} currency={course.community.currency} className='mb-3'/>
      ))}
      {course.materials.map(m => (
        <MaterialCard key={m.id} className='mb-3' material={course.materials[0]} withPrice />
      ))}
    </div>
  )
}

export default ProgramPricing
