import * as types from '../actions/actionTypes';
export default function announcements(state = [], action){
  switch(action.type){

    case types.ADD_ANNOUNCEMENTS:    
      return action.announcements;

    case types.POST_ANNOUNCEMENT:    
      return [...state,
        Object.assign({}, action.announcement)
      ];

    default:
      return state;
  }
}