import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

export const honeybadger = Honeybadger.configure({
  apiKey: process.env.REACT_APP_HONEYBADGER_API_KEY,
  environment: process.env.NODE_ENV,
})

const HoneybadgerProvider = ({ children }) => {
  const user = useSelector(state => state.user.current_user)

  useEffect(() => {
    if (user) {
      const community = user.communities?.length > 0
        ? { id: user.communities[0]?.id, name: user.communities[0]?.name }
        : undefined

      honeybadger.setContext({
        user_id: user.id,
        user_type: user.type,
        user_email: user.email,
        username: user.username,
        community,
      })
    } else {
      honeybadger.clear()
    }
  }, [user])

  return (
    <HoneybadgerErrorBoundary honeybadger={honeybadger}>
      {children}
    </HoneybadgerErrorBoundary>
  )
}

HoneybadgerProvider.propTypes = {
  children: PropTypes.object,
}

export default HoneybadgerProvider
