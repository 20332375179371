import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { deleteEvent } from '../../../../reducers/seriesReducer'

import { ReactComponent as CircleCalendar } from '../../../../assets/images/common/icons/circle-calendar.svg'
import { ReactComponent as RoundedPlusIcon } from '../../../../assets/images/common/icons/rounded-plus-icon.svg'


import FieldError from '../../../common/FieldError'
import EventModal from '../EventModal'
import EventRow from '../EventRow'


import styles from './EventSchedule.module.scss'
import classNames from 'classnames'

const EventSchedule = () => {
  const dispatch = useDispatch()

  const [showEventModal, setShowEventModal] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState(null)

  const errors = useSelector(state => state.programBuilder.errors)
  const events = useSelector(state => state.series.events)
  const timezone = useSelector(state => state.user.current_user.communities[0].timezone)
  const filteredEvents = events.filter(s => !s.deleted)

  const handleEditEvent = (event) => {
    setSelectedEvent(event)
    setShowEventModal(true)
  }

  const handleDeleteEvent = (event) => {
    dispatch(deleteEvent(event))
  }

  return (
    <section className={styles['event-schedule']}>
      <div className='row'>
        <div className='col-8 mx-auto mt-5'>
          <p className={styles['title']}>Create Events</p>
          <p className='mb-5'>
            Add multiple events to live inside your event series.
          </p>

          {errors.events && <FieldError message={errors.events} />}

          {filteredEvents.length > 0 && (
            <>
              <div className={classNames(styles['events'], 'mt-4')}>
                {filteredEvents.map((event, index) => (
                  <EventRow
                    key={event.index}
                    event={{ ...event }}
                    index={index}
                    handleEditEvent={handleEditEvent} handleDeleteEvent={handleDeleteEvent}
                    timezone={timezone}
                  />
                ))}
              </div>
              <div className='d-flex justify-content-center w-100 mt-3'>
                <button className='d-flex align-items-center btn btn-primary add-event-button' onClick={() => setShowEventModal(true)}>
                  <RoundedPlusIcon id='eventRoundedPlusIcon' className='mr-2' /> Add Event Date
                </button>
              </div>
            </>
          )}

          {filteredEvents.length === 0 && (
            <div className='row mt-4'>
              <div className='col-6 mb-3 mx-auto'>
                <div className='d-flex flex-column align-items-center'>
                  <CircleCalendar width={'101px'} height={'101px'} className='mb-3'/>
                  <p className={styles['title']}>No Events Added yet.</p>
                  <button className='d-flex align-items-center btn btn-primary add-event-button' onClick={() => setShowEventModal(true)}>
                    <RoundedPlusIcon id='eventRoundedPlusIcon' className='mr-2' /> Add Event Date
                  </button>
                </div>
              </div>
            </div>
          )}

          <EventModal
            showModal={showEventModal}
            closeModal={() => { setSelectedEvent(null); setShowEventModal(false) }}
            selectedEvent={selectedEvent}
          />
        </div>
      </div>
    </section>
  )
}

export default EventSchedule
