import React from 'react';
import Modal from "react-modal";

import devLeague_pin from '../../assets/images/pins/devLeague_pin.png'
import affiliate_pin from '../../assets/images/pins/affiliate_pin.png'
import certified_pin from '../../assets/images/pins/certified_pin.svg'
import top50_pin from '../../assets/images/pins/top50_pin.svg'

import './LocationTypeModal.scss';

const locationTypeModal = ({ closeModal }) => (
  <Modal
    isOpen
    contentLabel='Location Type Modal'
    shouldCloseOnOverlayClick
    portalClassName='ReactModalPortal__LocationTypeModal'
    className='customModal'>
    <div id='LocationTypeModal'>
      <div className='location-type-modal-top-bar'>
        <button
          className='x-modal-close-btn'
          onClick={() => closeModal('close')}>
          <i className='fa fa-times' aria-hidden='true' />
        </button>
      </div>
      <h3>Dev. League vs. Academy Locations</h3>

      <div
        className='location-type-modal-section'
        id='location-type-modal-section-1'>
        <div className='location-type-modal-section-header'>
          <span className='location-type-tag location-type-tag-league'>Dev. League</span>
          <span className='location-type-modal-section-pins'>
            <img src={devLeague_pin} alt='Dev League location pin' />
          </span>
        </div>
        <p>
          Perfect for beginners, Dev. League locations offer playing opportunities through <strong>9-Hole Events</strong> and progress tracking using the <strong>Op 36 Development Model</strong>. Dev. League locations are marked by flag pins on the map.
        </p>
      </div>

      <div
        className='location-type-modal-section'
        id='location-type-modal-section-2'>
        <div className='location-type-modal-section-header'>
          <span className='location-type-tag location-type-tag-coaching'>Academy</span>
          <span className='location-type-modal-section-pins'>
            <img src={affiliate_pin} alt='Academy location pin' />
            <img src={certified_pin} alt='Certified Pro location pin' />
            <img src={top50_pin} alt='Top 50 Coach location pin' />
          </span>
        </div>
        <p>
          Academy locations leverage the <strong>Operation 36 Curriculum</strong>, <strong>weekly classes</strong>, and <strong>9-Hole Events</strong> using the <strong>Op 36 Development Model</strong>. Reach out to these locations to learn more about the programs they offer and the skill levels they teach. Academy locations are marked by Academy, Certified Pro, and Top 50 Coach pins on the map.
        </p>
      </div>
    </div>
  </Modal>
);

export default locationTypeModal;
