import { createSlice } from '@reduxjs/toolkit'
import { getSignUpPageProgram } from '../actions/programActions'

const initialState = {
  course: {},
  isLoading: false,
  errors: {},
  detailsTabs: [],
  currentDetailTab: 'Description',
}

const programPresentationSlice = createSlice({
  name: 'programPresentation',
  initialState,
  reducers: {
    resetErrors: state => {
      state.errors = {}
    },
    resetCurrentDetailTab: state => {
      state.currentDetailTab = 'Description'
    },
    setCurrentDetailTab: (state, action) => {
      state.currentDetailTab = action.payload
    },
    setProgram: (state, action) => {
      state.course = action.payload.program
      state.detailsTabs = action.payload.detailsTabs
    },
  },
  extraReducers: {
    // getPresentationProgram
    [getSignUpPageProgram.pending]: state => {
      state.isLoading = true
    },
    [getSignUpPageProgram.fulfilled]: (state, action) => {
      const course = action.payload.course

      state.course = course
      state.detailsTabs = ['Description']
      if (course.schedule) {
        state.detailsTabs.push('Schedule')
      }
      if (course.series && course.series.length > 0) {
        state.detailsTabs.push('9 Hole Events')
      }
      if (course.materials.length + course.packages.length > 0) {
        state.detailsTabs.push('Pricing')
      }
      state.isLoading = false
    },
    [getSignUpPageProgram.rejected]: (state, action) => {
      state.errors = action.payload
      state.isLoading = false
    },
  },
})

export const {
  resetErrors,
  resetCurrentDetailTab,
  setCurrentDetailTab,
  setProgram,
} = programPresentationSlice.actions

export default programPresentationSlice.reducer
