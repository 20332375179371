import React, { Component } from "react";

// Constants
import CONSENT from "../../constants/consentDocuments.json";

const DISABLED = { opacity: 0.5, cursor: "default" };
class COPPAConsentNotice extends Component {
  state = { hasViewedCOPPANotice: true };

  handleLinkClick = () => this.setState({ hasViewedCOPPANotice: true });

  render() {
    const { getConsent } = this.props;
    return (
      <div className="border-top p-5 mt-2">
        <h3>Operation 36 Children's Online Privacy Protection</h3>
        <p>
          Please take a minute to review our&nbsp;
          <a
            href={CONSENT.privacy_policy.url}
            target="_blank"
            rel="noopener noreferrer"
            onClick={this.handleLinkClick}
          >
            Privacy policy
          </a>
        </p>
        <div
          className="form-group"
          style={this.state.hasViewedCOPPANotice ? {} : DISABLED}
        >
          <label htmlFor="consent_given">
            <input
              type="checkbox"
              name="consent_given"
              id=""
              onClick={getConsent}
              disabled={!this.state.hasViewedCOPPANotice}
              style={{ marginRight: "5px" }}
            />
            By checking here and clicking "Add Family Member" above, you
            indicate both that you are this minor's legal guardian and that you
            consent to the limited internal use of this minor's data as
            described in the Operation 36 COPPA policy.
          </label>
        </div>
      </div>
    );
  }
}

export default COPPAConsentNotice;
