import * as types from '../actions/actionTypes'

const INITIAL_STATE = {
  materials: []
}

const materials = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_MATERIALS:
      return { ...state, materials: action.materials }
    default:
      return state
  }
}

export default materials
