import React, { Component } from "react";

//REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as commonActions from "../../../actions/commonActions";
import * as communityStagesActions from "../../../actions/communityStagesActions";

// CHILDREN
import TextInput from "../../common/form/TextInput";
import CloseButton from "../../common/buttons/CloseButton";
import Placeholder from "../../common/Placeholder";
import Loader from "../../common/Op36Loader-web";
import CommunityStage from "./CommunityStage";

// ASSETS
import "./CommunityStageForm.scss";

class CommunityStageForm extends Component {
  state = {
    name: "",
    description: "",
    loader: false,
    submitLoader: false
  };

  componentDidMount() {
    const { community_stages } = this.props;

    if (
      !community_stages ||
      (community_stages && community_stages.length === 0)
    )
      this._fetchCommunityStages();
  }

  render() {
    const { toggleStageForm } = this.props;
    const { name, description } = this.state;

    return (
      <div id='CommunityStageForm'>
        <CloseButton onClick={() => toggleStageForm()} />
        <h5>Add A New Community Stage</h5>
        <label>Stage Name</label>
        <TextInput
          name={"name"}
          type={"text"}
          label={""}
          onChange={this._handleInputChange}
          placeholder={"Community Stage Name"}
          value={name}
        />
        <label>Stage Description (optional)</label>
        <textarea
          name='description'
          type='text'
          className='form-control'
          rows='3'
          label=''
          onChange={this._handleInputChange}
          placeholder='Enter a description about the community stage'
          value={description}
        />

        <div className='d-flex justify-content-center w-100 mt-3 mb-4'>
          <button
            className='btn btn-primary'
            onClick={this._onSubmit}
            disabled={name === ""}
          >
            Create Stage
          </button>
        </div>
        {/* <h5>Manage Community Stages</h5>
        {this._renderCommunityStages()} */}
      </div>
    );
  }

  _renderCommunityStages = () => {
    const { community_stages } = this.props;
    const { loader, submitLoader } = this.state;

    if (loader || submitLoader) {
      return <Loader message='loading stages' />;
    }

    if (!community_stages || community_stages.length === 0) {
      return (
        <Placeholder
          title={"No Community Stages Have Been Created"}
          icon={"fa-list-ul"}
          subtext1={"You currenly have not created any stages for communities"}
          subtext2={"Create the first stage with the form above"}
        />
      );
    }
    return community_stages.map(stage => (
      <CommunityStage
        stage={stage}
        deleteStage={this._deleteCommunityStage}
        key={stage.id}
      />
    ));
  };

  _handleInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  _resetForm = () => {
    this.setState({
      name: "",
      description: ""
    });
  };

  _onSubmit = () => {
    const { community_stages_actions } = this.props;

    this.setState({ submitLoader: true }, () => {
      community_stages_actions.createCommunityStage(this.state).then(() => {
        this._fetchCommunityStages();
        this._resetForm();
      });
    });
  };

  _deleteCommunityStage = stage => {
    const { community_stages_actions } = this.props;

    this.setState({ submitLoader: true }, () => {
      community_stages_actions.deleteCommunityStage(stage).then(() => {
        this._fetchCommunityStages();
      });
    });
  };

  _fetchCommunityStages = () => {
    const { community_stages_actions } = this.props;
    this.setState(
      {
        loader: true
      },
      () => {
        community_stages_actions.getCommunityStages().then(() => {
          this.setState({ loader: false, submitLoader: false }, () => {
            // console.log(this.props);
          });
        });
      }
    );
  };
}

function mapStateToProps(state, ownProps) {
  return {
    ui: state.ui,
    community_stages: state.communityStages
  };
}

function mapDispatchToProps(dispatch) {
  return {
    common_actions: bindActionCreators(commonActions, dispatch),
    community_stages_actions: bindActionCreators(
      communityStagesActions,
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunityStageForm);
