import React from "react";
import PropTypes from 'prop-types'
// import {connect} from 'react-redux';
// import {bindActionCreators} from 'redux';
// import * as userActions from '../../../actions/userActions';
// import * as activityActions from '../../../actions/activityActions';

import Placeholder from "../../common/Placeholder";
import ValidationModal from "../../common/ValidationModal";
// import Loader from "../../common/Op36Loader-web";
import "../../../assets/styles/components/profile/ProfileStats.scss";
import ConfirmationModal from "../../common/ConfirmationModal";
import DivisionDataCard from "./DivisionDataCard";

import { Line } from "react-chartjs-2";
import "chartjs-plugin-annotation";
import { Circle } from "rc-progress";
import CountUp from "react-countup";
// import { Tabs, Tab, TabPanel, TabList } from "react-web-tabs";
import Modal from "react-modal";
import moment from "moment";
import Datetime from "react-datetime";
// import _ from "lodash";

class PlayStats extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      confirmationMessage: "",
      showConfirmationModal: false,
      divisions: [225, 450, 900, 1350, 1800],
      division: "",
      data: [],
      labels: [],
      stokesLost: 0,
      showActivityModal: false,
      activity: {},
      activityId: "",
      date: "",
      format: "",
      holes: 0,
      yardage: "",
      fullTeeYardage: 0,
      location: "",
      score: "",
      greens: "",
      putts: "",
      previousPageCount: 0,
      nextPageCount: 10,
      tabChanged: false,
      activityUpdated: false,
      activityDeleted: false,
      activityDate: moment().hour(12).minute(0).seconds(0).milliseconds(0),
    };

    this.getDivisionNum = this.getDivisionNum.bind(this);
    this.drawDivisions = this.drawDivisions.bind(this);
  }

  toggleConfirmationModal = () => {
    this.setState({
      showConfirmationModal: !this.state.showConfirmationModal,
      confirmationMessage: "Are you sure you want to delete this activity?",
    });
  };

  deleteActivity = () => {
    this.setState({
      showActivityModal: false,
      showConfirmationModal: false,
      activityDeleted: true,
    })
    this.props.activityActions.deleteActivity(this.state.activity).then(res => {
      if (res) {
        this.props.reloadStats('play')
      }
      this.setState({ activityDeleted: false })
    })
    this.resetForms()
  };

  abbrNum(number, decPlaces) {
    decPlaces = Math.pow(10, decPlaces);

    let abbrev = ["k", "m", "b", "t"];

    for (let i = abbrev.length - 1; i >= 0; i--) {
      let size = Math.pow(10, (i + 1) * 3);
      if (size <= number) {
        number = Math.round((number * decPlaces) / size) / decPlaces;
        if (number === 1000 && i < abbrev.length - 1) {
          number = 1;
          i++;
        }

        number += abbrev[i];
        break;
      }
    }
    return number;
  }

  /***********
Charts
***********/
  updateCharts = () => {
    this.setState(
      {
        tabChanged: true,
      },
      () => {
        this.setState({
          tabChanged: false,
        });
      }
    );
  };
  renderAverage(div, type) {
    let numbers = [];
    this.props.playActivities.forEach((activity) => {
      let yardage = activity.yardage;
      let greens = activity.greens;
      let putts = activity.putts;

      if (div === yardage) {
        if (type === "greens" && greens) {
          numbers.push(greens);
        } else if (type === "putts" && putts) {
          numbers.push(putts);
        }
      }
    });

    let sum = 0;

    for (let i = 0; i < numbers.length; i++) {
      sum += numbers[i];
    }

    let avg = sum / numbers.length;

    return avg;
  }

  renderDivisionChart(div, bgColor, pointColor) {
    let data = [];
    let labels = [];

    this.props.playActivities.map((activity, index) => {
      let date = activity.activity_date;
      let score = activity.score;
      let yardage = activity.yardage;

      if (div === "fullTee") {
        if (activity.holes === 9 && activity.is_full_tee) {
          labels.push(moment(date).format("MM-DD-YYYY"));
          data.push(score);
        } else {
          return null;
        }
      } else if (div === yardage) {
        if (activity.holes === 9) {
          labels.push(moment(date).format("MM-DD-YYYY"));
          data.push(score);
        } else {
          return null;
        }
      }
    });

    let orderData = data.reverse();
    let orderLabels = labels.reverse();
    // Card Chart
    let cardChartData = {
      labels: orderLabels,
      datasets: [
        {
          label: "Score",
          backgroundColor: bgColor,
          borderColor: "rgba(255,255,255,.55)",
          pointBackgroundColor: pointColor,
          pointBorderColor: "transparent",
          data: orderData,
        },
      ],
    };

    let cardChartOpts = {
      maintainAspectRatio: false,
      legend: {
        display: true,
      },
      scales: {
        xAxes: [
          {
            display: true,
            gridLines: {
              color: "white",
              zeroLineColor: "white",
            },
            ticks: {
              display: false,
              /*fontSize: 2,*/
              // fontColor: "#2b3842"
            },
          },
        ],
        yAxes: [
          {
            display: true,
            ticks: {
              display: true,
              min: 20,
              max:
                Math.ceil(
                  Math.max.apply(Math, cardChartData.datasets[0].data) / 10
                ) * 10,
              stepSize: 10,
            },
          },
        ],
      },
      annotation: {
        annotations: [
          {
            type: "line",
            drawTime: "afterDatasetsDraw",
            mode: "horizontal",
            scaleID: "y-axis-0",
            value: 36,
            borderColor: "rgba(43, 56, 66, .65)",
            borderWidth: 2,
            // Line dash
            // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/setLineDash
            borderDash: [5, 5],

            // Line Dash Offset
            // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/lineDashOffset
            borderDashOffset: 5,
            label: {
              backgroundColor: "rgba(43, 56, 66, .65)",
              fontSize: 12,
              fontColor: "#fff",
              xPadding: 8,
              yPadding: 6,
              cornerRadius: 5,
              position: "center",
              xAdjust: 0,
              yAdjust: 0,
              enabled: true,
              content: "Target Score - 36",
            },
          },
        ],
      },
      elements: {
        line: {
          borderWidth: 1,
        },
        point: {
          radius: 4,
          hitRadius: 10,
          hoverRadius: 4,
        },
      },
    };
    return (
      <Line
        data={cardChartData}
        options={cardChartOpts}
        height={300}
        redraw={!this.props.scrolled || this.state.tabChanged === true}
      />
    );
  }

  activityDateChanged = (event) => {
    let startDate = moment(event, "MM-DD-YYYY").format("MM-DD-YYYY");
    let setActivityDate = moment(startDate, "MM-DD-YYYY").format();

    this.setState({
      activityDate: setActivityDate,
    });
  };

  getTotalTimePracticed() {
    var totalMins = 0;

    for (var key in this.props.stats.practice_log) {
      if (this.props.stats.practice_log.hasOwnProperty(key)) {
        var tmpMins = parseInt(this.props.stats.practice_log[key], 10);
        if (!isNaN(tmpMins)) {
          totalMins += tmpMins;
        }
      }
    }

    return totalMins / 60;
  }

  getDivisionNum() {
    var divisionNum = 0;
    for (var i = 0; i < this.state.divisions.length; i++) {
      if (
        this.props.stats.division_records[this.state.divisions[i]] !== undefined
      ) {
        divisionNum = i + 1;
      }
    }

    return divisionNum;
  }

  drawDivisions() {
    return this.state.divisions.map((division, index) => (
      <div className='row no-gutters' key={index}>
        <div className='col-3'>
          <p className='pull-right stat-score'>
            {this.props.stats.division_records[division] || ""}
          </p>
        </div>
        <div className='col-3'>
          <p
            className={
              "text-center stat-division-score text-white " +
              (this.props.stats.division_records[division] !== undefined
                ? "secondary-background"
                : "mid-font-background")
            }
          >
            <small>{index + 1}</small>
          </p>
        </div>
        <div className='col-6'>
          <h6 className='stat-yard'>
            <small>{division} Yards</small>
          </h6>
        </div>
      </div>
    ));
  }

  findFirstScore(div) {
    let activities = this.props.playActivities;
    let divActivities = [];

    activities.forEach((activity, index) => {
      if (div === activity.yardage) {
        divActivities.push(activity.score);
      }
    });

    let val = divActivities[divActivities.length - 1];
    return val;
  }

  // renderDivisionTabs() {
  //   return (
  //     <div className='tab-container'>
  //       <Tabs onChange={tabId => this.updateCharts()} className='tabs'>
  //         <TabList className='tab-list'>
  //           <Tab tabFor='one' className='tab'>
  //             <p>
  //               Div
  //               <span className='hidden-md-down'>ision</span> 1
  //             </p>
  //             {/*<p className="hidden-md-down div-desc">225 yards (25 yard hole)</p>*/}
  //           </Tab>
  //           <Tab tabFor='two' className='tab'>
  //             <p>
  //               Div
  //               <span className='hidden-md-down'>ision</span> 2
  //             </p>
  //             {/*<p className="hidden-md-down div-desc">450 yards (50 yard hole)</p>*/}
  //           </Tab>
  //           <Tab tabFor='three' className='tab'>
  //             <p>
  //               Div
  //               <span className='hidden-md-down'>ision</span> 3
  //             </p>
  //             {/*<p className="hidden-md-down div-desc">900 yards (100 yard hole)</p>*/}
  //           </Tab>
  //           <Tab tabFor='four' className='tab'>
  //             <p>
  //               Div
  //               <span className='hidden-md-down'>ision</span> 4
  //             </p>
  //             {/*<p className="hidden-md-down div-desc">1350 yards (150 yard hole)</p>*/}
  //           </Tab>
  //           <Tab tabFor='five' className='tab'>
  //             <p>
  //               Div
  //               <span className='hidden-md-down'>ision</span> 5
  //             </p>
  //             {/*<p className="hidden-md-down div-desc">1800 yards (200 yard hole)</p>*/}
  //           </Tab>
  //           <Tab tabFor='six' className='tab'>
  //             <p>Full Tee Box</p>
  //             {/*<p className="hidden-md-down div-desc">1800 yards (200 yard hole)</p>*/}
  //           </Tab>
  //         </TabList>
  //         <TabPanel tabId='one'>
  //           {this.props.stats.division_scores["225"] &&
  //           this.props.stats.division_scores["225"].length > 0
  //             ? this.renderDivisionContent(
  //                 "225 yards (25 yard hole)",
  //                 this.props.stats.division_records["225"],
  //                 this.props.stats.division_scores["225"].length,
  //                 this.props.playActivities ? this.findFirstScore("225") : null,
  //                 "225",
  //                 "rgba(246, 138, 34, 0.45)",
  //                 "rgba(246, 138, 34, 1.0)"
  //               )
  //             : this.renderFillerContent()}
  //         </TabPanel>
  //         <TabPanel tabId='two'>
  //           {this.props.stats.division_scores["450"] &&
  //           this.props.stats.division_scores["450"].length > 0
  //             ? this.renderDivisionContent(
  //                 "450 yards (50 yard hole)",
  //                 this.props.stats.division_records["450"],
  //                 this.props.stats.division_scores["450"].length,
  //                 this.props.playActivities ? this.findFirstScore("450") : null,
  //                 "450",
  //                 "rgba(253, 219, 16, 0.45)",
  //                 "rgba(253, 219, 16, 1.0)"
  //               )
  //             : this.renderFillerContent()}
  //         </TabPanel>
  //         <TabPanel tabId='three'>
  //           {this.props.stats.division_scores["900"] &&
  //           this.props.stats.division_scores["900"].length > 0
  //             ? this.renderDivisionContent(
  //                 "900 yards (100 yard hole)",
  //                 this.props.stats.division_records["900"],
  //                 this.props.stats.division_scores["900"].length,
  //                 this.props.playActivities ? this.findFirstScore("900") : null,
  //                 "900",
  //                 "rgba(213, 222, 39, 0.45)",
  //                 "rgba(213, 222, 39, 1.0)"
  //               )
  //             : this.renderFillerContent()}
  //         </TabPanel>
  //         <TabPanel tabId='four'>
  //           {this.props.stats.division_scores["1350"] &&
  //           this.props.stats.division_scores["1350"].length > 0
  //             ? this.renderDivisionContent(
  //                 "1350 yards (150 yard hole)",
  //                 this.props.stats.division_records["1350"],
  //                 this.props.stats.division_scores["1350"].length,
  //                 this.props.playActivities
  //                   ? this.findFirstScore("1350")
  //                   : null,
  //                 "1350",
  //                 "rgba(44, 170, 225, 0.45)",
  //                 "rgba(44, 170, 225, 1.0)"
  //               )
  //             : this.renderFillerContent()}
  //         </TabPanel>
  //         <TabPanel tabId='five'>
  //           {this.props.stats.division_scores["1800"] &&
  //           this.props.stats.division_scores["1800"].length > 0
  //             ? this.renderDivisionContent(
  //                 "1800 yards (200 yard hole)",
  //                 this.props.stats.division_records["1800"],
  //                 this.props.stats.division_scores["1800"].length,
  //                 this.props.playActivities
  //                   ? this.findFirstScore("1800")
  //                   : null,
  //                 "1800",
  //                 "rgba(181, 31, 140, 0.45)",
  //                 "rgba(181, 31, 140, 1.0)"
  //               )
  //             : this.renderFillerContent()}
  //         </TabPanel>
  //         <TabPanel tabId='six'>
  //           {this.isFullTeeRounds()
  //             ? this.renderFullTeeDivisionContent()
  //             : this.renderFillerContent()}
  //         </TabPanel>
  //       </Tabs>
  //     </div>
  //   );
  // }

  isFullTeeRounds = () => {
    let isFullTee = false;
    this.props.playActivities.forEach((activity) => {
      if (activity.is_full_tee) {
        isFullTee = true;
      }
    });

    return isFullTee;
  };

  findFullTeeFirstScore = () => {
    // let fullTeePlays = [];
    // this.props.playActivities.map((activity) => {
    //   if (activity.is_full_tee) {
    //     fullTeePlays.push(activity);
    //   }
    // });
    const fullTeePlays = this.props.playActivities.filter(activity => activity.is_full_tee)
    let sorted = fullTeePlays.sort(function (a, b) {
      return a.activity_date - b.activity_date;
    });

    return sorted[sorted.length - 1].score;
  };

  findRecordFullTeeScore = () => {
    let fullTeeScores = [];

    this.props.playActivities.forEach((activity) => {
      if (activity.is_full_tee) {
        fullTeeScores.push(activity.score);
      }
    });

    return Math.min(...fullTeeScores);
  };

  calculateFullTeeDivisionStrokes = () => {
    return 27;
  };

  findFullTeeAttempts = () => {
    // let fullTeeAttempts = [];

    // this.props.playActivities.map((activity) => {
    //   if (activity.is_full_tee) {
    //     fullTeeAttempts.push(activity);
    //   }
    // });

    const fullTeeAttempts = this.props.playActivities.filter(activity => activity.is_full_tee)

    return fullTeeAttempts.length;
  };

  calculateFullTeeAttemptPercentage = () => {
    let totalAttempts = this.props.stats.division_attempts;
    let fullTeeAttempts = this.findFullTeeAttempts();

    return (fullTeeAttempts / totalAttempts) * 100;
  };

  calculateAverageFullTeeGreens = () => {
    let fullTeeGreens = [];

    this.props.playActivities.forEach((activity) => {
      if (activity.is_full_tee && activity.greens) {
        fullTeeGreens.push(activity.greens);
      }
    });

    let avg = fullTeeGreens.reduce((a, b) => a + b, 0) / fullTeeGreens.length;

    return avg;
  };

  calculateAverageFullTeePutts = () => {
    let fullTeePutts = [];

    this.props.playActivities.forEach((activity) => {
      if (activity.is_full_tee && activity.putts) {
        fullTeePutts.push(activity.putts);
      }
    });

    let avg = fullTeePutts.reduce((a, b) => a + b, 0) / fullTeePutts.length;

    return avg;
  };

  calculateFullTeeStrokesLost = () => {
    let bestScore = this.findRecordFullTeeScore();

    let firstScore = this.findFullTeeFirstScore();

    let strokesLost = firstScore - bestScore;

    return strokesLost;
  };

  renderFullTeeDivisionContent = () => { // eslint-disable-line arrow-body-style
    return (
      <div>
        <h4 className='text-center pt-5 pb-3 div-desc'>Full Tee Box</h4>
        <div className='stat-group'>
          <div className='row'>
            <div className='col-2 stats-container text-center'>
              <p className='mt-3 text-center stat-title'>
                Baseline <span className='hidden-md-down'>Score</span>
              </p>
              <div className=''>
                <div className='count-container-no-icon'>
                  {/*<img className="stats-icon" alt="objective-icon" src={plusIcon} />*/}
                  <CountUp
                    className='md-percentage-count'
                    start={0}
                    end={this.findFullTeeFirstScore()}
                    duration={2.75}
                    useEasing={true}
                    useGrouping={true}
                  />
                </div>
                <div className='empty-circle' />
              </div>
            </div>
            <div className='col-2 stats-container text-center'>
              <p className='mt-3 text-center stat-title'>
                Record <span className='hidden-md-down'>Score</span>
              </p>
              <div className=''>
                <div className='count-container-no-icon'>
                  {/*<img className="stats-icon" alt="objective-icon" src={plusIcon} />*/}
                  <CountUp
                    className='md-percentage-count'
                    start={0}
                    end={this.findRecordFullTeeScore()}
                    duration={2.75}
                    useEasing={true}
                    useGrouping={true}
                  />
                </div>
                <div className='empty-circle' />
              </div>
            </div>

            <div className='col-2 stats-container text-center'>
              <p className='mt-3 text-center stat-title'>
                <span className='hidden-md-down'>Strokes</span> Lost
              </p>
              <div className=''>
                <div className='count-container-no-icon'>
                  {/*<img className="stats-icon" alt="objective-icon" src={plusIcon} />*/}
                  <CountUp
                    className='md-percentage-count'
                    start={0}
                    end={this.abbrNum(this.calculateFullTeeStrokesLost(), 1)}
                    duration={2.75}
                    useEasing={true}
                    useGrouping={true}
                  />
                </div>
                <div className='empty-circle' />
              </div>
            </div>
            <div className='col-2 stats-container text-center'>
              <p className='mt-3 text-center stat-title'>
                Level <span className='hidden-md-down'>Attempts</span>
              </p>
              <div className=''>
                <div className='count-container-no-icon-circle'>
                  {/*<img className="stats-icon" alt="objective-icon" src={clockIcon} />*/}
                  <CountUp
                    className='percentage-count'
                    start={0}
                    end={this.findFullTeeAttempts()}
                    duration={2.75}
                    useEasing={true}
                    useGrouping={true}
                  />
                </div>
                <Circle
                  className='enrolled-circle-off'
                  percent={this.calculateFullTeeAttemptPercentage()}
                  strokeWidth='4'
                  strokeColor='#ff6633'
                />
              </div>
            </div>
            <div className='col-2 stats-container text-center'>
              <p className='mt-3 text-center stat-title'>
                <span className='hidden-md-down'>Average</span> Greens
              </p>
              <div className=''>
                <div className='count-container-no-icon-circle'>
                  {/*<img className="stats-icon" alt="objective-icon" src={clockIcon} />*/}
                  <CountUp
                    className='md-percentage-count'
                    start={0}
                    end={
                      this.props.playActivities
                        ? this.calculateAverageFullTeeGreens()
                        : 0
                    }
                    duration={2.75}
                    useEasing={true}
                    useGrouping={true}
                  />
                </div>
                <div className='empty-circle' />
              </div>
            </div>
            <div className='col-2 stats-container text-center'>
              <p className='mt-3 text-center stat-title'>
                <span className='hidden-md-down'>Average</span> Putts
              </p>
              <div className=''>
                <div className='count-container-no-icon-circle'>
                  {/*<img className="stats-icon" alt="objective-icon" src={clockIcon} />*/}
                  <CountUp
                    className='md-percentage-count'
                    start={0}
                    end={
                      this.props.playActivities
                        ? this.calculateAverageFullTeePutts()
                        : 0
                    }
                    duration={2.75}
                    useEasing={true}
                    useGrouping={true}
                  />
                </div>
                <div className='empty-circle' />
              </div>
            </div>
          </div>
          <div className='row chart-row'>
            <div className='col-11'>
              <div className='chart-container' id='canvas'>
                {this.props.playActivities
                  ? this.renderDivisionChart(
                      "fullTee",
                      "rgba(0, 0, 0, 0.45)",
                      "rgba(0, 0, 0, 1.0)"
                    )
                  : null}
              </div>
            </div>
          </div>
          <div className='card mt-15px'>
            <h5 className='p-2 ml-3 mt-2 card-header-text'>Rounds List</h5>
            <div className='card-block p-2'>
              <div>
                <main className='padding-base'>
                  {this.renderRoundsTable("fullTee")}
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // renderDivisionContent(
  //   desc,
  //   record,
  //   attempts,
  //   baseline,
  //   div,
  //   bgColor,
  //   pointColor
  // ) {
  //   if (
  //     this.props.stats.division_scores[div] &&
  //     this.props.stats.division_scores[div].length > 0
  //   ) {
  //     return (
  //       <div>
  //         <h4 className='text-center pt-5 pb-3 div-desc'>{desc}</h4>
  //         <div className='stat-group'>
  //           <div className='row'>
  //             <div className='col-2 stats-container text-center'>
  //               <p className='mt-3 text-center stat-title'>
  //                 Baseline <span className='hidden-md-down'>Score</span>
  //               </p>
  //               <div className=''>
  //                 <div className='count-container-no-icon'>
  //                   {/*<img className="stats-icon" alt="objective-icon" src={plusIcon} />*/}
  //                   <CountUp
  //                     className='md-percentage-count'
  //                     start={0}
  //                     end={baseline}
  //                     duration={2.75}
  //                     useEasing={true}
  //                     useGrouping={true}
  //                   />
  //                 </div>
  //                 <div className='empty-circle' />
  //               </div>
  //             </div>
  //             <div className='col-2 stats-container text-center'>
  //               <p className='mt-3 text-center stat-title'>
  //                 Record <span className='hidden-md-down'>Score</span>
  //               </p>
  //               <div className=''>
  //                 <div className='count-container-no-icon'>
  //                   {/*<img className="stats-icon" alt="objective-icon" src={plusIcon} />*/}
  //                   <CountUp
  //                     className='md-percentage-count'
  //                     start={0}
  //                     end={record}
  //                     duration={2.75}
  //                     useEasing={true}
  //                     useGrouping={true}
  //                   />
  //                 </div>
  //                 <div className='empty-circle' />
  //               </div>
  //             </div>

  //             <div className='col-2 stats-container text-center'>
  //               <p className='mt-3 text-center stat-title'>
  //                 <span className='hidden-md-down'>Strokes</span> Lost
  //               </p>
  //               <div className=''>
  //                 <div className='count-container-no-icon'>
  //                   {/*<img className="stats-icon" alt="objective-icon" src={plusIcon} />*/}
  //                   <CountUp
  //                     className='md-percentage-count'
  //                     start={0}
  //                     end={this.abbrNum(this.calculateDivisionStrokes(div), 1)}
  //                     duration={2.75}
  //                     useEasing={true}
  //                     useGrouping={true}
  //                   />
  //                 </div>
  //                 <div className='empty-circle' />
  //               </div>
  //             </div>
  //             <div className='col-2 stats-container text-center'>
  //               <p className='mt-3 text-center stat-title'>
  //                 Level <span className='hidden-md-down'>Attempts</span>
  //               </p>
  //               <div className=''>
  //                 <div className='count-container-no-icon-circle'>
  //                   {/*<img className="stats-icon" alt="objective-icon" src={clockIcon} />*/}
  //                   <CountUp
  //                     className='percentage-count'
  //                     start={0}
  //                     end={attempts}
  //                     duration={2.75}
  //                     useEasing={true}
  //                     useGrouping={true}
  //                   />
  //                 </div>
  //                 <Circle
  //                   className='enrolled-circle-off'
  //                   percent={this.calculateAttemptPercentage(div)}
  //                   strokeWidth='4'
  //                   strokeColor='#ff6633'
  //                 />
  //               </div>
  //             </div>
  //             <div className='col-2 stats-container text-center'>
  //               <p className='mt-3 text-center stat-title'>
  //                 <span className='hidden-md-down'>Average</span> Greens
  //               </p>
  //               <div className=''>
  //                 <div className='count-container-no-icon-circle'>
  //                   {/*<img className="stats-icon" alt="objective-icon" src={clockIcon} />*/}
  //                   <CountUp
  //                     className='md-percentage-count'
  //                     start={0}
  //                     end={
  //                       this.props.playActivities
  //                         ? this.renderAverage(div, "greens")
  //                         : 0
  //                     }
  //                     duration={2.75}
  //                     useEasing={true}
  //                     useGrouping={true}
  //                   />
  //                 </div>
  //                 <div className='empty-circle' />
  //               </div>
  //             </div>
  //             <div className='col-2 stats-container text-center'>
  //               <p className='mt-3 text-center stat-title'>
  //                 <span className='hidden-md-down'>Average</span> Putts
  //               </p>
  //               <div className=''>
  //                 <div className='count-container-no-icon-circle'>
  //                   {/*<img className="stats-icon" alt="objective-icon" src={clockIcon} />*/}
  //                   <CountUp
  //                     className='md-percentage-count'
  //                     start={0}
  //                     end={
  //                       this.props.playActivities
  //                         ? this.renderAverage(div, "putts")
  //                         : 0
  //                     }
  //                     duration={2.75}
  //                     useEasing={true}
  //                     useGrouping={true}
  //                   />
  //                 </div>
  //                 <div className='empty-circle' />
  //               </div>
  //             </div>
  //           </div>
  //           <div className='row chart-row'>
  //             <div className='col-11'>
  //               <div className='chart-container' id='canvas'>
  //                 {this.props.playActivities
  //                   ? this.renderDivisionChart(div, bgColor, pointColor)
  //                   : null}
  //               </div>
  //             </div>
  //           </div>
  //           <div className='card mt-15px'>
  //             <h5 className='p-2 ml-3 mt-2 card-header-text'>Rounds List</h5>
  //             <div className='card-block p-2'>
  //               <div>
  //                 <main className='padding-base'>
  //                   {this.renderRoundsTable(div)}
  //                 </main>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     );
  //   } else if (!this.props.stats.division_scores[div]) {
  //     return (
  //       <div className='row'>
  //         <div className='col-2' />
  //         <div className='mt-5 col-8'>
  //           <h5 className='empty-data-text text-center'>
  //             No rounds logged in this division yet. Go play and give it a shot
  //             to get your baseline score!
  //           </h5>
  //         </div>
  //         <div className='col-2' />
  //       </div>
  //     );
  //   } else {
  //     return null;
  //   }
  // }

  // renderFillerContent() {
  //   return (
  //     <div className='row'>
  //       <div className='col-2' />
  //       <div className='mt-5 col-8'>
  //         <h5 className='empty-data-text text-center'>
  //           No rounds logged in this division yet. Go play and give it a shot to
  //           get your baseline score!
  //         </h5>
  //       </div>
  //       <div className='col-2' />
  //     </div>
  //   );
  // }

  /**********
  CALCULATIONS
  **********/

  calculateRounds(rounds) {
    let golfRounds = 0;

    this.props.playActivities.forEach((activity, index) => {
      if (activity.holes === rounds) {
        golfRounds += 1;
      } else {
        golfRounds += 0;
      }
    });
    return golfRounds;
  }

  calculateRoundsPercentage(rounds) {
    let golfRounds = 0;

    this.props.playActivities.forEach((activity, index) => {
      if (activity.holes === rounds) {
        golfRounds += 1;
      }
    });

    let percent = (golfRounds / this.props.playActivities.length) * 100;

    return percent;
  }

  // calculateAttemptPercentage(div) {
  //   let totalAttempts = this.props.stats.division_attempts;

  //   let attempts = this.props.stats.division_scores[div].length;

  //   let percent = (attempts / totalAttempts) * 100;

  //   return percent;
  // }

  // calculateDivisionStrokes(div) {
  //   let bestScore = this.props.stats.division_records[div];

  //   let firstScore = this.props.playActivities
  //     ? this.findFirstScore(div)
  //     : null;

  //   let strokesLost = firstScore - bestScore;

  //   return strokesLost;
  // }

  /******
  Rounds View/Edit Modal & Logic
  ******/

  // handleSortBy = key => {
  //   this.setState(prevState => ({
  //     sortBy: key,
  //     sortByReverse: !prevState.sortByReverse
  //   }));
  // };

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  resetForms = () => {
    this.setState({
      userId: "",
      activityId: "",
      date: "",
      format: "",
      holes: 0,
      yardage: "",
      location: "",
      score: "",
      greens: "",
      putts: "",
      activityDate: moment().format(),
    });
  };

  openValidationModal = (success, message) => {
    this.setState({
      showValidationModal: true,
      validationMessage: message,
      validationSuccess: success,
    });
  };

  closeValidationModal = () => {
    this.setState({
      showValidationModal: false,
    });
  };

  handleUpdateClick = () => {
    if (
      this.state.score !== "" &&
      this.state.description !== "" &&
      this.state.holes !== 0
    ) {
      if (
        (this.state.yardage === "fullTee" &&
          parseInt(this.state.fullTeeYardage, 10) < 1801 &&
          parseInt(this.state.holes, 10) === 9) ||
        (this.state.yardage === "fullTee" &&
          parseInt(this.state.fullTeeYardage, 10) < 3601 &&
          parseInt(this.state.holes, 10) === 18)
      ) {
        this.openValidationModal(
          false,
          "Please enter a valid custom full tee box division yardage"
        );
      } else if (
        this.state.yardage === "fullTee" &&
        this.state.fullTeeYardage !== 0
      ) {
        this.updateActivity();
      } else if (
        this.state.yardage !== 0 &&
        this.state.yardage !== "fullTee"
      ) {
        this.updateActivity();
      }
    } else {
      this.openValidationModal(
        false,
        'Please enter a score, level yardage, and holes played to log a play action'
      );
    }

  }

  updateActivity = () => {
    this.setState({ showActivityModal: false, activityUpdated: true })
    this.props.activityActions.updateActivity(this.state).then((response) => {
      if (response) {
        this.props.reloadStats('play')
      }
      this.setState({ activityUpdated: false })
    })
    this.resetForms()
  };

  toggleActivityModal = (activity) => {
    this.resetForms();
    this.setState({
      showActivityModal: !this.state.showActivityModal,
      userId: this.props.user.id,
      activity: activity,
      activityId: activity.id,
      date: activity.activity_date,
      format: activity.play_type.id,
      holes: activity.holes,
      yardage: activity.is_full_tee ? "fullTee" : activity.yardage,
      fullTeeYardage: activity.is_full_tee ? activity.yardage : null,
      location: activity.location,
      score: activity.score,
      greens: activity.greens,
      putts: activity.putts,
      activityDate: activity.activity_date,
    });
  };

  closeActivityModal = () => {
    this.setState({
      showActivityModal: false,
    });
  };

  renderDivsions = () => this.state.divisions.map((div, index) => (
    <option value={div} key={index}>{`Level ${
      index + 1
    } ${div} yards`}</option>
  ))

  renderPlayTypes() {
    return this.props.playTypes.map((play) => {
      return (
        <option value={play.id} key={play.id}>
          {play.name}
        </option>
      );
    });
  }

  renderPlayTypeName(id) {
    return this.props.playTypes.map((play) => {
      if (id === play.id) {
        return play.name;
      }
    });
  }

  displayActivityModal() { // eslint-disable-line max-lines-per-function
    return (
      <>
      <Modal
        isOpen={this.state.showActivityModal}
        onRequestClose={this.closeActivityModal}
        contentLabel='Notes'
      >
        <div className='modal-header row'>
          <div className='col-11'>
            <h5 className='modal-title display-4 uppercase detail-title'>
              {this.renderPlayTypeName(this.state.format)} |{" "}
              {moment(this.state.date).format("MM-DD-YYYY")}
            </h5>
          </div>
          <div className='col-1'>
            <button
              type='button'
              className='close pull-right font-200'
              data-dismiss='modal'
              aria-label='Close'
              onClick={this.closeActivityModal}
            >
              <span aria-hidden='true'>✕</span>
            </button>
          </div>
        </div>
        <div className='row d-flex align-items-center flex-column h-100 mt-5'>
          <div className='col-md-8 mx-auto'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='form-group'>
                  <label htmlFor='program-activity-title'>Format</label>
                  <select
                    onChange={this.handleInputChange}
                    value={this.state.format}
                    name='format'
                    className='form-control'
                  >
                    <option value={0} disabled>
                      Select Game Type
                    </option>
                    {this.renderPlayTypes()}
                  </select>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label htmlFor='program-activity-title'>Activity Date</label>
                  <Datetime
                    name='firstStartDate'
                    value={moment(this.state.activityDate).format("MM-DD-YYYY")}
                    onChange={this.activityDateChanged}
                    dateFormat='MM-DD-YYYY'
                    timeFormat={false}
                    inputProps={{ readOnly: true }}
                    className='date-time'
                    {...this.state}
                  />
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label htmlFor='program-activity-title'>Location</label>
                  <input
                    type='text'
                    name='location'
                    className='form-control'
                    id='play-activity-location'
                    placeholder='Location'
                    onChange={this.handleInputChange}
                    value={this.state.location}
                  />
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label htmlFor='program-activity-title'>Yardage</label>
                  <select
                    onChange={this.handleInputChange}
                    value={this.state.yardage}
                    name='yardage'
                    className='form-control'
                  >
                    <option value={0} disabled>
                      Select Level
                    </option>
                    {this.renderDivsions()}
                    <option value='fullTee' id='full-tee-box-option'>
                      Level 6 - Full Tee Box
                    </option>
                  </select>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label htmlFor='program-activity-title'>
                    Full Tee Yardage
                  </label>
                  <input
                    type='number'
                    name='fullTeeYardage'
                    className='form-control'
                    id='play-activity-full-tee'
                    placeholder={
                      this.state.yardage === "fullTee"
                        ? "Enter Full Tee Yardage"
                        : "Choose Full Tee Option to Enter Custom Yardage"
                    }
                    disabled={this.state.yardage !== "fullTee"}
                    onChange={this.handleInputChange}
                    value={
                      this.state.yardage !== "fullTee"
                        ? ''
                        : this.state.fullTeeYardage
                    }
                  />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label htmlFor='program-activity-title'>Score</label>
                  <input
                    type='number'
                    name='score'
                    className='form-control'
                    id='play-activity-score'
                    placeholder='Score'
                    onChange={this.handleInputChange}
                    value={this.state.score}
                  />
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label htmlFor='program-activity-title'>Holes</label>
                  <select
                    onChange={this.handleInputChange}
                    value={this.state.holes}
                    name='holes'
                    className='form-control'
                  >
                    <option value={0} disabled>
                      Select Holes
                    </option>
                    <option value={9}>9 holes</option>
                    <option value={18}>18 holes</option>
                  </select>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label htmlFor='program-activity-title'>Greens</label>
                  <input
                    type='number'
                    name='greens'
                    className='form-control'
                    id='play-activity-greens'
                    placeholder='Enter Green Count'
                    onChange={this.handleInputChange}
                    value={this.state.greens}
                  />
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label htmlFor='program-activity-title'>Putts</label>
                  <input
                    type='number'
                    name='putts'
                    className='form-control'
                    id='play-activity-putts'
                    placeholder='Enter Putt Count'
                    onChange={this.handleInputChange}
                    value={this.state.putts}
                  />
                </div>
              </div>
            </div>
            <div className='row mt-4'>
              <div className='col-md-12 d-flex justify-content-center align-items-center flex-row'>
                <button
                  className='btn btn-dark m-2'
                  onClick={this.closeActivityModal}
                >
                  Cancel
                </button>
                <button
                  className='btn btn-primary m-2'
                  onClick={this.handleUpdateClick}
                >
                  Update
                </button>
              </div>
            </div>
            <div className='d-flex justify-content-end mt-4'>
              <button
                className='btn btn-warning mt-4 mx-auto'
                onClick={this.toggleConfirmationModal}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <ValidationModal
      validationSuccess={this.state.validationSuccess}
      showValidationModal={this.state.showValidationModal}
      closeValidationModal={this.closeValidationModal}
      validationMessage={this.state.validationMessage}
    />
    </>
    );
  }

  /******
  Rounds Table
  ******/

  // renderPillColor(number, type) {
  //   if (type === "greens") {
  //     if (number >= 0 && number <= 2) {
  //       return "#d9534f";
  //     } else if (number >= 3 && number <= 5) {
  //       return "#FFFF00";
  //     } else if (number >= 6 && number <= 9) {
  //       return "#33d9b2";
  //     } else {
  //       return "#2b3843";
  //     }
  //   } else if (type === "putts") {
  //     if (number >= 23) {
  //       return "#d9534f";
  //     } else if (number >= 16 && number <= 22) {
  //       return "#FFFF00";
  //     } else if (number >= 1 && number <= 15) {
  //       return "#33d9b2";
  //     } else {
  //       return "#2b3843";
  //     }
  //   }
  // }

  // renderRows(div) {
  //   if (!this.props.playActivities) {
  //     return null;
  //   }
  //   let activities = this.props.playActivities
  //     .filter(
  //       activity =>
  //         activity.yardage == div || (div == "fullTee" && activity.is_full_tee)
  //     )
  //     .slice(this.state.previousPageCount, this.state.nextPageCount);

  //   let sortedActivities = _.sortBy(
  //     activities,
  //     activity => activity[this.state.sortBy]
  //   );
  //   if (this.state.sortByReverse) sortedActivities.reverse();
  //   console.log(sortedActivities);
  //   return sortedActivities
  //     .filter(activity => activity.holes !== 18)
  //     .map(activity => {
  //       return (
  //         <tr className='border-bottom' key={activity.id}>
  //           <td className='hidden-sm-down'>
  //             {moment(activity.activity_date).format("MM-DD-YYYY")}
  //           </td>
  //           <td className='hidden-sm-down'>{activity.play_type.name}</td>
  //           <td className='hidden-sm-down'>{activity.holes}</td>
  //           <td className='hidden-sm-down'>{activity.yardage}</td>
  //           <td className='hidden-sm-down'>{activity.location}</td>
  //           <td className='hidden-sm-down'>{activity.score}</td>
  //           <td className='hidden-sm-down'>
  //             <p
  //               className='round-pill'
  //               style={{
  //                 backgroundColor: activity.greens
  //                   ? this.renderPillColor(activity.greens, "greens")
  //                   : "white",
  //                 textShadow: "1px 1px 1px #545454",
  //                 boxShadow: activity.greens
  //                   ? `.5px .5px 4px ${this.renderPillColor(
  //                       activity.greens,
  //                       "greens"
  //                     )}`
  //                   : "none"
  //               }}
  //             >
  //               {activity.greens}
  //             </p>
  //           </td>
  //           <td className='hidden-sm-down'>
  //             <p>
  //               <p
  //                 className='round-pill'
  //                 style={{
  //                   backgroundColor: activity.putts
  //                     ? this.renderPillColor(activity.putts, "putts")
  //                     : "white",
  //                   textShadow: "1px 1px 1px #545454",
  //                   boxShadow: activity.putts
  //                     ? `.5px .5px 4px ${this.renderPillColor(
  //                         activity.putts,
  //                         "putts"
  //                       )}`
  //                     : "none"
  //                 }}
  //               >
  //                 {activity.putts}
  //               </p>
  //             </p>
  //           </td>
  //           {this.props.current_user === "Coach" ? (
  //             <td className='hidden-sm-down'>
  //               <button
  //                 className='btn btn-block btn-primary m-0'
  //                 onClick={() => this.toggleActivityModal(activity)}
  //               >
  //                 View/Edit
  //               </button>
  //             </td>
  //           ) : (
  //             <td />
  //           )}

  //           {/* <div className="row hidden-md-up">
  //           <div className="col">
  //             <div className="card mb-1 p-3">
  //               <h5 className="mt-0 mb-1">Todd Kidler</h5>
  //               <p className="mt-0 mb-1"><span className="badge primary-background">username</span></p>
  //               <p className="mt-0 mb-1">Family</p>
  //               <p className="mt-0 mb-1">date</p>
  //                 <button className="btn btn-primary btn-small text-white">
  //                   Edit
  //                 </button>
  //             </div>
  //           </div>
  //         </div> */}
  //         </tr>
  //       );
  //     });
  // }

  // handlePaginateClick(button) {
  //   let next = this.state.nextPageCount;
  //   let previous = this.state.previousPageCount;
  //   if (button === "next") {
  //     this.setState({
  //       nextPageCount: next + 10,
  //       previousPageCount: previous + 10
  //     });
  //   } else if (button === "previous") {
  //     this.setState({
  //       nextPageCount: next - 10,
  //       previousPageCount: previous - 10
  //     });
  //   }
  // }

  // renderPagination(div) {
  //   if (!this.props.playActivities) {
  //     return null;
  //   }
  //   let activities = this.props.playActivities.filter(
  //     activity => activity.yardage == div
  //   );

  //   let count = activities.length;
  //   if (count > 10) {
  //     return (
  //       <div className='row d-flex justify-content-flex-end'>
  //         <div className='col-md-6 m-0'>
  //           <button
  //             className='btn btn-dark m-0 mr-2'
  //             onClick={() => this.handlePaginateClick("previous")}
  //             disabled={this.state.previousPageCount === 0 ? true : false}
  //           >
  //             Previous
  //           </button>
  //           <button
  //             className='btn btn-primary m-0'
  //             onClick={() => this.handlePaginateClick("next")}
  //             disabled={this.state.nextPageCount > count ? true : false}
  //           >
  //             Next
  //           </button>
  //         </div>
  //       </div>
  //     );
  //   } else {
  //     return <div />;
  //   }
  // }

  // renderRoundsTable(div) {
  //   return (
  //     <div className='animated fadeIn'>
  //       {/* <div className="row hidden-md-up">
  //         <div className="col-6">
  //           <label className="font-200">
  //             Last Name
  //           </label>
  //         </div>
  //         <div className="col-6">
  //           <label className="font-200">
  //             Join Date
  //           </label>
  //         </div>
  //       </div> */}
  //       <table className='table w-100 '>
  //         <thead className='primary-background hidden-sm-down'>
  //           <tr className='text-white font-200'>
  //             <th
  //               className='font-200'
  //               onClick={() => this.handleSortBy(this.DATE_JOINED_KEY)}
  //             >
  //               Date {this.getSortIcon(this.DATE_JOINED_KEY, "numeric")}
  //             </th>
  //             <th
  //               className='font-200'
  //               onClick={() => this.handleSortBy(this.FORMAT_KEY)}
  //             >
  //               Format {this.getSortIcon(this.FORMAT_KEY, "alpha")}
  //             </th>
  //             <th
  //               className='font-200'
  //               onClick={() => this.handleSortBy(this.HOLES_KEY)}
  //             >
  //               Holes {this.getSortIcon(this.HOLES_KEY, "numeric")}
  //             </th>
  //             <th
  //               className='font-200'
  //               onClick={() => this.handleSortBy(this.YARDAGE_KEY)}
  //             >
  //               Yardage {this.getSortIcon(this.YARDAGE_KEY, "numeric")}
  //             </th>
  //             <th
  //               className='font-200'
  //               onClick={() => this.handleSortBy(this.LOCATION_KEY)}
  //             >
  //               Location {this.getSortIcon(this.LOCATION_KEY, "alpha")}
  //             </th>
  //             <th
  //               className='font-200'
  //               onClick={() => this.handleSortBy(this.SCORE_KEY)}
  //             >
  //               Score {this.getSortIcon(this.SCORE_KEY, "numeric")}
  //             </th>
  //             <th
  //               className='font-200'
  //               onClick={() => this.handleSortBy(this.GREENS_KEY)}
  //             >
  //               Greens {this.getSortIcon(this.GREENS_KEY, "numeric")}
  //             </th>
  //             <th
  //               className='font-200'
  //               onClick={() => this.handleSortBy(this.PUTTS_KEY)}
  //             >
  //               Putts {this.getSortIcon(this.PUTTS_KEY, "numeric")}
  //             </th>
  //             <th>&nbsp;</th>
  //           </tr>
  //         </thead>
  //         <tbody>{this.renderRows(div)}</tbody>
  //       </table>
  //       {this.renderPagination(div)}
  //     </div>
  //   );
  // }

  // getSortIcon = (sortBy, sortByType) => {
  //   const sortDirection = this.state.sortByReverse ? "desc" : "asc";
  //   const iconClass = `fa fa-sort-${sortDirection}`;
  //   return sortBy === this.state.sortBy ? (
  //     <i className={iconClass} aria-hidden='true' />
  //   ) : (
  //     <i className='fa fa-sort' aria-hidden='true' />
  //   );
  // };

  displayStats(div) {
    if (this.props.stats && this.props.stats.points) {
      return (
        <div>
          <div className='card mt-15px pb-3'>
            <h5 className='p-2 ml-3 mt-2 card-header-text'>Play Stats</h5>
            <div className='p-1'>
              <div className='statGroup'>
                <div className='row m-0'>
                  <div className='col-4 stats-container text-center'>
                    <p className='mt-3 text-center stat-title'>
                      <span className='hidden-md-down'>Total</span> Holes
                    </p>
                    <div className=''>
                      <div className='count-container-no-icon'>
                        {/*<img className="stats-icon" alt="objective-icon" src={plusIcon} />*/}
                        {typeof this.abbrNum(
                          this.props.stats.holes_played,
                          1
                        ) === "number" ? (
                          <CountUp
                            className='md-percentage-count'
                            start={0}
                            end={this.abbrNum(this.props.stats.holes_played, 1)}
                            duration={2.75}
                            useEasing={true}
                            useGrouping={true}
                          />
                        ) : (
                          <p className='md-percentage-count'>
                            {this.abbrNum(this.props.stats.holes_played, 1)}
                          </p>
                        )}
                      </div>
                      <div className='empty-circle' />
                    </div>
                  </div>
                  <div className='col-4 stats-container text-center'>
                    <p className='mt-3 text-center stat-title'>
                      <span className='hidden-md-down'>Total</span> Rounds
                    </p>
                    <div className=''>
                      <div className='count-container-no-icon'>
                        {/*<img className="stats-icon" alt="objective-icon" src={plusIcon} />*/}
                        <CountUp
                          className='md-percentage-count'
                          start={0}
                          end={this.abbrNum(this.props.stats.rounds, 1)}
                          duration={2.75}
                          useEasing={true}
                          useGrouping={true}
                        />
                      </div>
                      <div className='empty-circle' />
                    </div>
                  </div>
                  <div className='col-4 stats-container text-center'>
                    <p className='mt-3 text-center stat-title'>
                      9 Hole <span className='hidden-md-down'>Rounds</span>
                    </p>
                    <div className=''>
                      <div className='count-container-no-icon-circle'>
                        {/*<img className="stats-icon" alt="objective-icon" src={clockIcon} />*/}
                        <CountUp
                          className='percentage-count'
                          start={0}
                          end={
                            this.props.playActivities
                              ? this.abbrNum(this.calculateRounds(9), 1)
                              : 0
                          }
                          duration={2.75}
                          useEasing={true}
                          useGrouping={true}
                        />
                      </div>
                      <Circle
                        className='enrolled-circle'
                        percent={
                          this.props.playActivities
                            ? this.calculateRoundsPercentage(9)
                            : 0
                        }
                        strokeWidth='4'
                        strokeColor='#ff6633'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='card mt-15px player-stat-card-container'>
            <h5 className='p-2 ml-3 mt-2 card-header-text'>
              Operation 36 Stats
            </h5>
            <div className='card-block p-2'>
              <div>
                <main className='padding-base'>
                  {/* {this.renderDivisionTabs()} */}
                  <DivisionDataCard
                    user={this.props.user}
                    current_user={this.props.current_user}
                    toggleActivityModal={this.toggleActivityModal}
                    activityAdded={this.props.activityAdded}
                    activityUpdated={this.state.activityUpdated}
                    activityDeleted={this.state.activityDeleted}
                  />
                </main>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (this.props.user.points === 0) {
      return (
        <div className='card p-0'>
          <h5 className='p-2 ml-3 mt-2 card-header-text'>Play Stats</h5>
          <div className='card-block p-2'>
            <Placeholder
              title={"No Play Stats Yet..."}
              icon={"fa-bar-chart"}
              subtext1={
                "Download the Op36 Golf App to begin logging your first actions"
              }
              subtext2={"Not sure how to get started on your journey?"}
              subtext3={"Reach out to your coach to get started"}
            />
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <div id='profileStats' className='hasTabBar p-0'>
        {this.displayStats()}
        {this.displayActivityModal()}
        <ConfirmationModal
          showConfirmationModal={this.state.showConfirmationModal}
          closeConfirmationModal={this.toggleConfirmationModal}
          confirmationMessage={this.state.confirmationMessage}
          confirmationDenied={this.toggleConfirmationModal}
          confirmationProceed={this.deleteActivity}
          loader={this.state.deleteLoader}
        />
      </div>
    );
  }
}
//
// function mapStateToProps(state, ownProps){
//   return {
//     ui: state.ui,
//     stats: state.user.user_stats,
//     ranks: state.ranks,
//     practice_types: state.practiceTypes,
//     actvities: state.user.user_activities,
//     play_activities: state.user.user_play_activities,
//   };
// }
//
// function mapDispatchToProps(dispatch){
//   return{
//     actions: bindActionCreators(userActions, dispatch),
//     activity_actions: bindActionCreators(activityActions, dispatch)
//   };
// }

// export default connect(mapStateToProps, mapDispatchToProps)(PlayStats);

PlayStats.propTypes = {
  activityAdded: PropTypes.bool,
  user: PropTypes.shape({
    id: PropTypes.number,
  }),
  activityActions: PropTypes.shape({
    deleteActivity: PropTypes.func,
  }),
  userActions: PropTypes.shape({
    getUserStats: PropTypes.func,
    getUserActivities: PropTypes.func,
    getUserPlayActivities: PropTypes.func,
  }),
  reloadStats: PropTypes.func,
}

export default PlayStats;
