import * as constants from "./actionTypes";
import * as common from "./commonActions";
import Axios from '../axios'

export function successRequestingRanks(response) {
  if (response.status === 200) {
    return { type: constants.ADD_RANKS, ranks: response.data.ranks };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successRequestingCourseRanks(response) {
  if (response.status === 200) {
    return { type: constants.ADD_RANKS, ranks: response.data.ranks };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successRequestingCurriculumRanks(response) {
  if (response.status === 200) {
    return { type: constants.ADD_RANKS, ranks: response.data.ranks };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

// THUNKS HERE
export function getAllRanks() {
  return function (dispatch) {
    // BODY OF THUNK

    // TURN SPINNER ON...
    // dispatch(common.loadingChanged(true));
    // FETCH API CALL FROM SERVER THEN DISPATCH UI ACTION
    // THEN TURN SPINNER OFF
    dispatch(common.loadingChanged(true));
    return requestRanks()
      .then((response) => dispatch(successRequestingRanks(response)))
      .then((response) => dispatch(common.loadingChanged(false)));
  };
}
export function getCourseRanks(userId) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true));
    return requestCourseRanks(userId)
      .then((response) => dispatch(successRequestingCourseRanks(response)))
      .then((response) => dispatch(common.loadingChanged(false)));
  };
}

export function getCurriculumRanks(userId) {
  return function (dispatch) {
    return requestCurriculumRanks(userId).then((response) =>
      dispatch(successRequestingCurriculumRanks(response))
    );
  };
}

// API CALLS
function requestRanks() {
  let requestUrl = "/api/v1/ranks";
  return Axios.get(requestUrl);
}

function requestCourseRanks(userId) {
  var requestUrl = "/api/v1/ranks/curriculum";
  if (userId) {
    requestUrl += "?user_id=" + userId;
  }
  return Axios.get(requestUrl);
}

function requestCurriculumRanks(userId) {
  var requestUrl = "/api/v1/ranks/curriculum";
  if (userId) {
    requestUrl += "?user_id=" + userId;
  }
  return Axios.get(requestUrl);
}
