import { useState } from 'react'
import classNames from 'classnames'

import { ReactComponent as OpenEyeIcon } from '../../../assets/images/common/icons/programBuilder/open-eye-icon.svg'
import { ReactComponent as ClosedEyeIcon } from '../../../assets/images/common/icons/programBuilder/closed-eye-icon.svg'

import Input from './Input'

import classes from './PasswordInputWithIcon.module.scss'

const PasswordInputWithIcon = (props) => {
  const [showPassword, setShowPassword] = useState(false)

  const renderShowPasswordIcon = () => (
    <button
      type='button'
      tabIndex='-1'
      className={classNames(classes['btn-password'], 'btn btn-sm')}
      onClick={() => setShowPassword(showPassword => !showPassword)}
    >
      {showPassword ? <OpenEyeIcon width={24} height={24} /> : <ClosedEyeIcon width={24} height={24} />}
    </button>
  )

  return (
    <Input {...props} type={showPassword ? 'text' : 'password'} endAdornment={renderShowPasswordIcon()} />
  )
}

export default PasswordInputWithIcon
