import React, { Component } from "react";
import { Wizard, Steps, Step } from "react-albus";
import moment from "moment";

// REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as commonActions from "../../actions/commonActions";
import * as communitiesActions from "../../actions/communitiesActions";
import * as reportsActions from "../../actions/reportsActions";
import * as userActions from "../../actions/userActions";

//Assets
import "../../assets/styles/components/reports/reportsPage.scss";

//Children Imports
import CurriculumReportTable from "./CurriculumReportTable";
import StudentEngagementTable from "./StudentEngagementTable";
import StudentStatsTable from "./StudentStatsTable";
import CommunityStatsTable from "./CommunityStatsTable";
import CommunityRoundsTable from "./CommunityRoundsTable";
import CommunityPracticeTable from "./CommunityPracticeTable";
import StudentRoundsTable from "./StudentRoundsTable";
import ChooseReports from "./ChooseReports";
import FacilityImpactReport from "./FacilityImpactReport/FacilityImpactReport";
import PlayerJourneyReport from "./PlayerJourneyReport/PlayerJourneyReport";

// ADMIN TABLES
import AdminCommunityStatsTable from "./admin/AdminCommunityStatsTable";
import AdminCoachEngagementTable from "./admin/AdminCoachEngagementTable";
import AdminSupportActivityTable from "./admin/AdminSupportActivityTable";
import AdminCommunityPriorityTable from "./admin/AdminCommunityPriorityTable";

//PRINTABLE MODALS
import StudentRoundsModal from "./StudentRoundsModal";
import CurriculumModal from "./CurriculumModal";

import * as fadeAnimation from "../common/fadeAnimation";
import PageHeader from '../common/PageHeader/PageHeader'
import PlayerProgress from "./PlayerProgress";
import { withRouting } from "../common/hooks";

class ReportsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reportTitle: "",
      reportLoader: false,
      programLoader: false,
      studentLoader: false,
      reportType: "",
      programSelected: -1,
      studentSelected: -1,
      data: [],
      showReportModal: false,
      showCurriculumModal: false,
      start_date: moment().subtract(1, "weeks").startOf("isoWeek"),
      end_date: moment().add(1, "days"),
      playTypeSelected: "all",
    };
  }

  componentDidMount() {
    const { common_actions, current_user } = this.props;
    common_actions.setCurrentTab("Reports");
    common_actions.showSideBar(true);
    common_actions.showNavBar(true);
    this.toggleEnterState();

    if (current_user.type !== "Admin") {
      this.loadPrograms();
    }
  }

  fetchRounds = () => {
    this.setState({
      reportLoader: true,
    });
    this.props.report_actions
      .getCommunityRoundsReport(
        this.state,
        this.props.current_user.communities[0]
      )
      .then(() => {
        this.setState({
          reportLoader: false,
        });
      });
  };

  fetchPractices = () => {
    this.setState({
      reportLoader: true,
    });
    this.props.report_actions
      .getCommunityPracticeReport(
        this.state,
        this.props.current_user.communities[0]
      )
      .then(() => {
        this.setState({
          reportLoader: false,
        });
      });
  };

  fetchSupportActivities = () => {
    this.setState({
      reportLoader: true,
    });
    this.props.report_actions
      .getSupportActivityReport(this.state)
      .then((res) => {
        this.setState({
          reportLoader: false,
        });
      });
  };

  handleStartDateChanged = (date) => {
    this.setState({ start_date: date }, () => {
      const { reportType } = this.state;
      if (reportType === "community_rounds") {
        this.fetchRounds();
      } else if (reportType === "community_practice") {
        this.fetchPractices();
      } else if (reportType === "support_activity") {
        this.fetchSupportActivities();
      }
    });
  };

  handleEndDateChanged = (date) => {
    this.setState({ end_date: date }, () => {
      const { reportType } = this.state;
      if (reportType === "community_rounds") {
        this.fetchRounds();
      } else if (reportType === "community_practice") {
        this.fetchPractices();
      } else if (reportType === "support_activity") {
        this.fetchSupportActivities();
      }
    });
  };

  showCurriculumModal = () => {
    this.setState({ showCurriculumModal: true });
  };

  closeCurriculumModal = () => {
    this.setState({ showCurriculumModal: false });
  };

  showReportModal = () => {
    this.setState({
      showReportModal: true,
    });
  };

  closeReportModal = () => {
    this.setState({
      showReportModal: false,
    });
  };

  toProfile = (userId) => {
    this.props.navigate(
      `/profile/${userId}`,
      { state: { userId: userId } }
    )
  };

  loadPrograms = () => {
    this.setState({ programLoader: true });
    this.props.user_actions
      .getUserCourses(this.props.current_user.communities[0].id)
      .then(() => {
        this.setState({
          programLoader: false,
        });
      });
  };

  createRoundsData = () => {
    let stats = this.props.stats;
    let divisions = [225, 450, 900, 1350, 1800];
    let data = [];

    divisions.map((div, index) => {
      data.push({
        division: div,
        division_record: stats.division_records[div],
        division_scores: stats.division_scores[div],
      });
    });

    this.setState(
      {
        data: data,
      },
      () => {
        this.setState({
          reportLoader: false,
        });
      }
    );
  };

  grabStudents = () => {
    this.setState(
      {
        studentLoader: true,
      },
      () => {
        this.props.report_actions
          .getAllStudents(this.props.current_user.communities[0].id)
          .then(() => {
            this.setState({
              studentLoader: false,
            });
          });
      }
    );
  };

  grabReport = (state, instance, reportType) => {
    let type = reportType ? reportType : this.state.reportType;

    switch (type) {
      case "curriculum":
        this.props.report_actions
          .getCurriculumReport(
            this.state,
            this.props.current_user.communities[0]
          )
          .then(() => {
            this.setState({
              reportLoader: false,
            });
          });

        break;

      case "engagement":
        this.props.report_actions
          .getEngagementReport(
            this.state,
            this.props.current_user.communities[0]
          )
          .then(() => {
            this.setState({
              reportLoader: false,
            });
          });
        break;

      case "stats":
        this.props.report_actions
          .getStatsReport(this.state, this.props.current_user.communities[0])
          .then(() => {
            this.setState({
              reportLoader: false,
            });
          });
        break;
      case "rounds":
        this.props.user_actions.getUser(this.state.studentSelected).then(() => {
          this.props.user_actions
            .getUserDivision(this.state.studentSelected, "all")
            .then(() => {
              this.setState({ reportLoader: false });
            });
        });

        break;
      case "community_rounds":
        this.props.report_actions
          .getCommunityRoundsReport(
            this.state,
            this.props.current_user.communities[0]
          )
          .then(() => {
            this.setState({
              reportLoader: false,
            });
          });
        break;
      case "community_practice":
        this.props.report_actions
          .getCommunityPracticeReport(
            this.state,
            this.props.current_user.communities[0]
          )
          .then(() => {
            this.setState({
              reportLoader: false,
            });
          });
        break;
      case "community":
        this.props.report_actions
          .getCommunityReport(
            this.state,
            this.props.current_user.communities[0]
          )
          .then(() => {
            this.setState({
              reportLoader: false,
            });
          });
        break;

      case "admin-community":
        this.props.report_actions
          .getCommunityReport(
            this.state,
            this.props.current_user.communities[0]
          )
          .then(() => {
            this.setState({
              reportLoader: false,
            });
          });
        break;
      case "coach_engagement":
        if (reportType) {
          this.setState({ reportLoader: true });
        }
        this.props.report_actions
          .getCoachEngagementReport(this.state, state, instance)
          .then((res) => {
            this.setState({
              reportLoader: false,
            });
          });
        break;

      case "support_activity":
        if (reportType) {
          this.setState({ reportLoader: true });
        }
        this.props.report_actions
          .getSupportActivityReport(this.state)
          .then((res) => {
            this.setState({
              reportLoader: false,
            });
          });
        break;
      case "community_priority":
        if (reportType) {
          this.setState({ reportLoader: true });
        }
        this.props.report_actions
          .getCommunityPriorityReport(this.state, state, instance)
          .then((res) => {
            this.setState({
              reportLoader: false,
            });
          });
        break;
      case "progress":
        this.props.user_actions.getUser(this.state.studentSelected).then(() => {
          this.props.user_actions
            .getUserDivision(this.state.studentSelected, "all")
            .then(() => {
              this.setState({ reportLoader: false });
            });
        });
        break;
      default:
        return null;
    }
  };

  toggleEnterState = () => {
    setTimeout(
      function () {
        this.setState({ in: true });
      }.bind(this),
      fadeAnimation.FADE_DURATION
    );
  };

  handleProgramSelected = (event) => {
    let program = event.target.value;
    this.setState(
      {
        programSelected: program,
        reportLoader: true,
      },
      () => {
        this.grabReport();
      }
    );
  };

  handleStudentSelected = (event) => {
    let student = event.target.value;
    this.setState(
      {
        studentSelected: student,
        playTypeSelected: "all",
        reportLoader: true,
      },
      () => {
        this.grabReport();
      }
    );
  };

  viewReport = (type) => {
    switch (type) {
      case "curriculum":
        this.setState(
          {
            reportTitle: "Curriculum Summary",
            reportLoader: true,
            reportType: "curriculum",
          },
          () => {
            this.grabReport();
          }
        );
        break;
      case "engagement":
        this.setState(
          {
            reportTitle: "Engagement Summary",
            reportLoader: true,
            reportType: "engagement",
          },
          () => {
            this.grabReport();
          }
        );
        break;
      case "stats":
        this.setState(
          {
            reportTitle: "Activity Summary",
            reportLoader: true,
            reportType: "stats",
          },
          () => {
            this.grabReport();
          }
        );
        break;
      case "rounds":
        this.setState(
          {
            reportTitle: "On-course Journey",
            reportType: "rounds",
          },
          () => {
            this.grabStudents();
          }
        );
        break;
      case "community":
        this.setState(
          {
            reportTitle: "Community Stats",
            reportLoader: true,
            reportType: "community",
          },
          () => {
            this.grabReport();
          }
        );
        break;
      case "community_rounds":
        this.setState(
          {
            reportTitle: "Community Rounds",
            reportLoader: true,
            reportType: "community_rounds",
          },
          () => {
            this.grabReport();
          }
        );
        break;
      case "community_practice":
        this.setState(
          {
            reportTitle: "Community Training",
            reportLoader: true,
            reportType: "community_practice",
          },
          () => {
            this.grabReport();
          }
        );
        break;
      case "admin-community":
        this.setState(
          {
            reportTitle: "Admin Community Stats",
            reportLoader: true,
            reportType: "admin-community",
          },
          () => {
            this.grabReport();
          }
        );
        break;
      case "coach_engagement":
        this.setState(
          {
            reportTitle: "Coach Engagement",
            reportLoader: true,
            reportType: "coach_engagement",
          },
          () => {
            this.grabReport();
          }
        );
        break;
      case "support_activity":
        this.setState(
          {
            reportTitle: "Support Activities",
            reportLoader: true,
            reportType: "support_activity",
          },
          () => {
            this.grabReport();
          }
        );
        break;
      case "community_priority":
        this.setState(
          {
            reportTitle: "Community Priority",
            reportLoader: true,
            reportType: "community_priority",
          },
          () => {
            this.grabReport();
          }
        );
        break;
      case "progress":
        this.setState(
          {
            reportTitle: "Junior Development Model (Beta)",
            reportLoader: true,
            reportType: "progress",
          },
          () => {
            this.grabStudents();
          }
        );
        break;
      case "impact":
        this.setState(
          {
            reportTitle: "Facility Impact Report",
            reportLoader: true,
            reportType: "impact",
          },
          () => {
            this.grabReport();
          }
        );
        break;
      case "player_journey":
        this.setState(
          {
            reportTitle: "Player Journey Report",
            reportLoader: true,
            reportType: "player_journey",
          },
          () => this.grabReport()
        );
        break;
      default:
        return null;
    }
  };

  resetReports = (previous) => {
    this.setState(
      {
        reportTitle: "",
        reportType: "",
        programSelected: -1,
        studentSelected: -1,
      },
      () => {
        previous();
      }
    );
  };

  resetProgramSearch = () => {
    this.setState(
      {
        reportLoader: true,
        programSelected: -1,
      },
      () => {
        this.grabReport();
      }
    );
  };

  renderPrograms = () => {
    if (this.props.courses) {
      if (this.props.courses.length > 0) {
        return this.props.courses.map((course) => {
          if (!course.archived && course.enrollments.length > 0) {
            return (
              <option value={course.id} key={course.id}>
                {`${course.name} (${course.enrollments.length} students)`}
              </option>
            );
          }
        });
      }
    }
  };

  renderStudents = () => {
    if (this.props.students) {
      if (this.props.students.length > 0) {
        const sortedStudents = [...this.props.students].sort((a, b) =>
          a.last_name < b.last_name ? -1 : 1
        );
        return sortedStudents.map((student) => {
          return (
            <option value={student.id} key={student.id}>
              {`${student.first_name} ${student.last_name} (${student.username ? student.username : student.email
                })`}
            </option>
          );
        });
      }
    }
  };

  renderReport = (previous) => {
    let type = this.state.reportType;
    const {
      current_user: { communities },
    } = this.props;

    switch (type) {
      case "curriculum":
        return (
          <CurriculumReportTable
            title={this.state.reportTitle}
            goBack={() => this.resetReports(previous)}
            loader={this.state.reportLoader}
            data={this.props.reports}
            handleProgramSelected={this.handleProgramSelected}
            programSelected={this.state.programSelected}
            renderPrograms={this.renderPrograms}
            programLoader={this.state.programLoader}
            resetProgramSearch={this.resetProgramSearch}
            toProfile={this.toProfile}
            showCurriculumModal={this.showCurriculumModal}
          />
        );
      case "engagement":
        return (
          <StudentEngagementTable
            title={this.state.reportTitle}
            goBack={() => this.resetReports(previous)}
            loader={this.state.reportLoader}
            data={this.props.reports}
            handleProgramSelected={this.handleProgramSelected}
            programSelected={this.state.programSelected}
            renderPrograms={this.renderPrograms}
            programLoader={this.state.programLoader}
            resetProgramSearch={this.resetProgramSearch}
            toProfile={this.toProfile}
          />
        );
      case "stats":
        return (
          <StudentStatsTable
            title={this.state.reportTitle}
            goBack={() => this.resetReports(previous)}
            loader={this.state.reportLoader}
            data={this.props.reports}
            handleProgramSelected={this.handleProgramSelected}
            programSelected={this.state.programSelected}
            renderPrograms={this.renderPrograms}
            programLoader={this.state.programLoader}
            resetProgramSearch={this.resetProgramSearch}
            toProfile={this.toProfile}
          />
        );
      case "rounds":
        return (
          <StudentRoundsTable
            title={this.state.reportTitle}
            goBack={() => this.resetReports(previous)}
            loader={this.state.reportLoader}
            // data={this.state.data}
            // stats={this.props.stats}
            handleStudentSelected={this.handleStudentSelected}
            studentSelected={this.state.studentSelected}
            renderStudents={this.renderStudents}
            studentLoader={this.state.studentLoader}
            resetProgramSearch={this.resetProgramSearch}
            toProfile={this.toProfile}
            showReportModal={this.showReportModal}
            division={this.props.user_division.user_division}
          />
        );
      case "community":
        return (
          <CommunityStatsTable
            title={this.state.reportTitle}
            goBack={() => this.resetReports(previous)}
            loader={this.state.reportLoader}
            data={this.props.reports}
            handleProgramSelected={this.handleProgramSelected}
            programSelected={this.state.programSelected}
            renderPrograms={this.renderPrograms}
            programLoader={this.state.programLoader}
            resetProgramSearch={this.resetProgramSearch}
            toProfile={this.toProfile}
          />
        );
      case "community_rounds":
        return (
          <CommunityRoundsTable
            title={this.state.reportTitle}
            goBack={() => this.resetReports(previous)}
            loader={this.state.reportLoader}
            data={this.props.reports}
            handleStartDateChanged={this.handleStartDateChanged}
            handleEndDateChanged={this.handleEndDateChanged}
            toProfile={this.toProfile}
            startDate={this.state.start_date}
            endDate={this.state.end_date}
          />
        );
      case "community_practice":
        return (
          <CommunityPracticeTable
            title={this.state.reportTitle}
            goBack={() => this.resetReports(previous)}
            loader={this.state.reportLoader}
            data={this.props.reports}
            handleStartDateChanged={this.handleStartDateChanged}
            handleEndDateChanged={this.handleEndDateChanged}
            toProfile={this.toProfile}
            startDate={this.state.start_date}
            endDate={this.state.end_date}
          />
        );

      case "admin-community":
        return (
          <AdminCommunityStatsTable
            title={this.state.reportTitle}
            goBack={() => this.resetReports(previous)}
            loader={this.state.reportLoader}
            data={this.props.reports}
            handleProgramSelected={this.handleProgramSelected}
            programSelected={this.state.programSelected}
            renderPrograms={this.renderPrograms}
            programLoader={this.state.programLoader}
            resetProgramSearch={this.resetProgramSearch}
            toProfile={this.toProfile}
          />
        );
      case "coach_engagement":
        return (
          <AdminCoachEngagementTable
            title={this.state.reportTitle}
            goBack={() => this.resetReports(previous)}
            loader={this.state.reportLoader}
            data={this.props.reports}
            handleProgramSelected={this.handleProgramSelected}
            programSelected={this.state.programSelected}
            renderPrograms={this.renderPrograms}
            programLoader={this.state.programLoader}
            resetProgramSearch={this.resetProgramSearch}
            toProfile={this.toProfile}
            fetchData={this.grabReport}
            pages={this.props.pages}
          />
        );
      case "support_activity":
        return (
          <AdminSupportActivityTable
            title={this.state.reportTitle}
            goBack={() => this.resetReports(previous)}
            loader={this.state.reportLoader}
            data={this.props.reports}
            handleStartDateChanged={this.handleStartDateChanged}
            handleEndDateChanged={this.handleEndDateChanged}
            toProfile={this.toProfile}
            startDate={this.state.start_date}
            endDate={this.state.end_date}
          />
        );
      case "community_priority":
        return (
          <AdminCommunityPriorityTable
            title={this.state.reportTitle}
            goBack={() => this.resetReports(previous)}
            loader={this.state.reportLoader}
            data={this.props.reports}
            fetchData={this.grabReport}
            pages={this.props.pages}
          />
        );
      case "progress":
        const { user } = this.props;
        return (
          <PlayerProgress
            user={user}
            fromProfile={false}
            // stats={this.props.stats}
            loader={this.state.reportLoader}
            goBack={() => this.resetReports(previous)}
            title={this.state.reportTitle}
            handleStudentSelected={this.handleStudentSelected}
            studentSelected={this.state.studentSelected}
            renderStudents={this.renderStudents}
            studentLoader={this.state.studentLoader}
            resetProgramSearch={this.resetProgramSearch}
            division={this.props.user_division.user_division}
            handlePlayTypeChange={this.handlePlayTypeChange}
            playType={this.state.playTypeSelected}
            handleGoalChange={this._handleGoalChange}
          />
        );
      case "impact":
        return (
          <FacilityImpactReport
            goBack={() => this.resetReports(previous)}
            community={communities[0]}
            coachName={`${this.props.current_user.first_name} ${this.props.current_user.last_name}`}
          />
        );
      case "player_journey":
        return (
          <PlayerJourneyReport
            goBack={() => this.resetReports(previous)}
            community={communities[0]}
          />
        );
      default:
        return null;
    }
  };

  _handleGoalChange = (id, track) => {
    this.props.user_actions.updateGoalTrack(id, track);
  };

  handlePlayTypeChange = (type) => {
    this.setState({ reportLoader: true, playTypeSelected: type }, () => {
      this.props.user_actions
        .getUserDivision(this.state.studentSelected, type)
        .then(() => {
          this.setState({ reportLoader: false });
        });
    });
  };

  render() {
    return (
      <div id='ReportsPage'>
        <fadeAnimation.Fade in={this.state.in}>
          {this.state.in ? (
            <div>
              {this.state.reportType !== "impact" &&
                this.state.reportType !== "player_journey" &&
                this.state.reportType && (
                <PageHeader title='Reports' />
              )}
              <Wizard>
                <Steps>
                  <Step
                    id='Reports'
                    render={({ next }) => (
                      <div>
                        <ChooseReports
                          viewReport={this.viewReport}
                          next={next}
                        />
                      </div>
                    )}
                  />
                  <Step
                    id='ReportsTable'
                    render={({ next, previous }) => this.renderReport(previous)}
                  />
                </Steps>
              </Wizard>
              <StudentRoundsModal
                showReportModal={this.state.showReportModal}
                closeReportModal={this.closeReportModal}
                // data={this.state.data}
                // stats={this.props.stats}
                user={this.props.user}
                division={this.props.user_division.user_division}
              />
              <CurriculumModal
                title={this.state.reportTitle}
                showCurriculumModal={this.state.showCurriculumModal}
                closeCurriculumModal={this.closeCurriculumModal}
                data={this.props.reports}
                user={this.props.current_user}
              />
            </div>
          ) : (
            <div />
          )}
        </fadeAnimation.Fade>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    ui: state.ui,
    user: state.user.user,
    reports: state.reports,
    stats: state.user.user_stats,
    current_user: state.user.current_user,
    courses: state.courses.courses,
    students: state.students,
    play_activities: state.user.user_play_activities,
    train_activities: state.user.user_train_activities,
    pages: state.pages,
    user_division: state.userDivision,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    common_actions: bindActionCreators(commonActions, dispatch),
    communities_actions: bindActionCreators(communitiesActions, dispatch),
    report_actions: bindActionCreators(reportsActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouting(ReportsPage))
