/* eslint-disable react/prop-types */
import { useMemo } from 'react'
import groupBy from 'lodash/groupBy'
import moment from 'moment-timezone'
import _ from 'lodash'
import ScheduleSessionCard from './ScheduleSessionCard'
import ScheduleSessionFormContainer from './ScheduleSessionFormContainer'
import { ReactComponent as CalendarIcon } from '../../../assets/images/common/icons/circle-calendar.svg'
import PropTypes from 'prop-types'

import './SessionsList.scss'
import { useSelector } from 'react-redux'

const SessionsList = ({
  schedule,
  handleScheduleSessionClick,
  handleScheduleEditClick,
  editable,
  showPastSessions,
  scheduleAction,
  addScheduleSession,
  editScheduleSession,
  removeScheduleSession,
  showNewSessionCard,
  setShowNewSessionCard,
  className,
  communityTimezone,
}) => {
  const currentUserCommunityTimezone = useSelector(state => state.user.current_user?.communities[0]?.timezone)
  const timezone = communityTimezone ?? currentUserCommunityTimezone
  const groupedSessions = useMemo(() => {
    const sessions = showPastSessions
      ? schedule?.scheduleSessions
      : schedule?.scheduleSessions.filter(
        (s) => moment(s.startDate) >= moment(new Date())
      )

    return groupBy(sessions, (session) =>
      moment.tz(session.startDate, timezone).format('YYYY-MM-DD')
    )
  }, [showPastSessions, schedule?.scheduleSessions, timezone])

  return (
    <div id='ClassScheduleView' className={className}>
      {_.isEmpty(groupedSessions) ? (
        schedule?.scheduleSessions.length > 0 ? (
          <div className='no-sessions-placeholder'>
            <CalendarIcon className='mb-2' />
            <p className='title'>No upcoming sessions.</p>
          </div>
        ) : (
          <div className='no-sessions-placeholder'>
            <CalendarIcon className='mb-2' />
            <p className='title'>No sessions added yet.</p>
            <p>
              Let prospective sign-ups and active enrollments know your schedule
              for the class.
              <br />
              It will be visible on your class sign-up page and in your
              students’ mobile app.
            </p>
          </div>
        )
      ) : (
        Object.entries(groupedSessions).map(([date, sessions]) => (
          <ScheduleSessionCard
            key={date}
            sessions={sessions}
            handleScheduleSessionClick={handleScheduleSessionClick}
            handleScheduleEditClick={handleScheduleEditClick}
            editable={editable}
            scheduleAction={scheduleAction}
            addScheduleSession={addScheduleSession}
            editScheduleSession={editScheduleSession}
            removeScheduleSession={removeScheduleSession}
            communityTimezone={timezone}
          />
        ))
      )}
      {showNewSessionCard && (
        <div className='my-3'>
          <div className='px-5'>
            <ScheduleSessionFormContainer
              scheduleAction={'create'}
              addScheduleSession={addScheduleSession}
              handleCloseForm={() => setShowNewSessionCard(false)}
            />
          </div>
          <div className='hl' />
        </div>
      )}
    </div>
  )
}

SessionsList.propTypes = {
  setShowNewSessionCard: PropTypes.bool,
  className: PropTypes.string,
  communityTimezone: PropTypes.string,
}

export default SessionsList
