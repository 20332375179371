import PropTypes from 'prop-types'
import { ReactComponent as PackageIcon } from '../../assets/images/common/icons/package-icon.svg'
import colors from '../../assets/styles/globals.scss'

const EnrollmentSource = ({ student }) => {
  if (!student.addedFrom) {
    return <p/>
  }
  const addedFromLevel1Key = Object.keys(student.addedFrom)[0]
  const addedFromLevel1Value = student.addedFrom[addedFromLevel1Key]
  const addedFromLevel2Key = Object.keys(addedFromLevel1Value)[0]
  const addedFromLevel2Value = addedFromLevel1Value[addedFromLevel2Key]

  if (student.addedFrom.students) {
    return (<p>Manually added</p>)
  } else if (typeof addedFromLevel1Value === 'string') {
    return (<p>{addedFromLevel1Value }</p>)
  } else {
    return (
      <>
        <p>
          {addedFromLevel2Value.courseName }
        </p>
        <p className='programPackage'>
          <PackageIcon width='12px' id='packageIcon' fill={colors.placeholderGray} className='mr-2'/>
          {addedFromLevel2Value.packageName }
        </p>
      </>
    )
  }
}

EnrollmentSource.propTypes = {
  student: PropTypes.shape({
    addedFrom: PropTypes.object,
  }),
}

export default EnrollmentSource
