import * as types from "../actions/actionTypes";

// state = [] : Start out with no accounts
export default function recentResources(state = [], action) {
  switch (action.type) {
    case types.GET_RECENT_RESOURCES:
      return action.recent_resources;

    default:
      return state;
  }
}
