import React from "react";
import { abbrNum } from "../../../utility/helperFunctions";

// ASSETS
import "./CommunityStatCircle.scss";

const CommunityStatCircle = ({ number, name, color }) => (
  <div id='CommunityStatCircle'>
    <div className='stat-circle' style={{ backgroundColor: color }}>
      <p className='stat-number'>{abbrNum(number, 1)}</p>
    </div>
    <p className='stat-name'>{name}</p>
  </div>
);

export default CommunityStatCircle;
