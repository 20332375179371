import { createAsyncThunk } from '@reduxjs/toolkit'
import { deserialize, serialize } from '../utility/serialization'

import Axios from '../axios'
import { handleAPIError } from '../utility/actionHelper'

// THUNKS

export const getEnrollmentData = createAsyncThunk(
  'programRegistration/getEnrollmentData',
  async ({ courseId }, { rejectWithValue }) => {
    try {
      const { data } = await requestEnrollmentData(courseId)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const requestEnrollment = createAsyncThunk(
  'programRegistration/requestsEnrollment',
  async ({ courseId, userId, packageId, packagePrice, materialsIds }, { rejectWithValue }) => {
    try {
      const { data } = await requestRequestEnrollment(courseId, userId, packageId, packagePrice, materialsIds)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const initializeSession = createAsyncThunk(
  'programRegistration/initializeSession',
  async ({ courseId, userId, packageId, packagePrice, materialsIds }, { rejectWithValue }) => {
    try {
      const { data } = await requestInitializeSession(courseId, userId, packageId, packagePrice, materialsIds)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const getRegistration = createAsyncThunk(
  'programRegistration/getRegistration',
  async ({ registrationId }, { rejectWithValue }) => {
    try {
      const { data } = await requestGetRegistration(registrationId)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)


// API CALLS

function requestEnrollmentData(courseId) {
  const requestUrl = '/api/v1/enrollments/request_enrollment_data'
  return Axios.get(requestUrl, { params: serialize({ courseId }) })
}

function requestRequestEnrollment(courseId, userId, packageId, packagePrice, materialsIds) {
  const requestUrl = '/api/v1/enrollments/request_enrollment'
  const embed = localStorage?.getItem('embed')
  const requestEnrollmentData = { userId, packageId, packagePrice, embed, materialsIds }

  return Axios.post(requestUrl, serialize(requestEnrollmentData), { params: serialize({ courseId }) })
}

function requestInitializeSession(courseId, userId, packageId, packagePrice, materialsIds) {
  const requestUrl = '/api/v1/enrollments/initialize_session'
  const embed = localStorage?.getItem('embed')
  const initializeSessionData = { userId, packageId, packagePrice, embed, materialsIds }

  return Axios.post(requestUrl, serialize(initializeSessionData), { params: serialize({ courseId }) })
}

function requestGetRegistration(registrationId) {
  const requestUrl = `/api/v1/registrations/${registrationId}`

  return Axios.get(requestUrl)
}
