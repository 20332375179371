import * as types from './actionTypes'
import * as common from './commonActions'
import moment from 'moment'

import Axios from '../axios'

const mapVisibilityToAPIEnum = (val) => {
  switch (val) {
    case 0:
      return 'all_coaches'
    case 1:
      return 'paid_coaches'
    case 2:
      return 'academy_coaches'
    default:
      return null
  }
}

export function successRequestingEducation(response) {
  if (response.status === 200) {
    return {
      type: types.GET_EDUCATION,
      education: response.data.education,
    }
  } else {
    return { type: types.UI_ERROR, error: response.data }
  }
}

export function successRequestingUserEducation(response) {
  if (response.status === 200) {
    return {
      type: types.GET_USER_EDUCATION,
      user_education: response.data.user_education,
    }
  } else {
    return { type: types.UI_ERROR, error: response.data }
  }
}

export function successRequestingAllEducations(response) {
  if (response.status === 200) {
    return {
      type: types.GET_EDUCATIONS,
      educations: response.data.educations,
    }
  } else {
    return { type: types.UI_ERROR, error: response.data }
  }
}

export function successRequestingUserEducations(response) {
  if (response.status === 200) {
    return {
      type: types.GET_EDUCATIONS,
      educations: response.data.educations,
    }
  } else {
    return { type: types.UI_ERROR, error: response.data }
  }
}

export function successRequestingAddEducation(response) {
  if (response.status === 201) {
    return { type: types.CREATE_EDUCATION, education: response.data.education }
  } else {
    return { type: types.UI_ERROR, error: response.data }
  }
}

export function successRequestingUpdateEducation(response) {
  if (response.status === 201) {
    return { type: types.UPDATE_EDUCATION, education: response.data.education }
  } else {
    return { type: types.UI_ERROR, error: response.data }
  }
}

export function successRequestingCompleteLesson(response) {
  if (response.status === 201) {
    return {
      type: types.COMPLETE_LESSON,
      user_education: response.data.user_education,
    }
  } else {
    return { type: types.UI_ERROR, error: response.data }
  }
}

export function successRequestingIncompleteLesson(response) {
  if (response.status === 201) {
    return {
      type: types.INCOMPLETE_LESSON,
      user_education: response.data.user_education,
    }
  } else {
    return { type: types.UI_ERROR, error: response.data }
  }
}

// API CALLS

export function getEducation(education_id) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestEducation(education_id)
      .then((response) => dispatch(successRequestingEducation(response)))
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function getUserEducation(user_education_id) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestUserEducation(user_education_id)
      .then((response) => dispatch(successRequestingUserEducation(response)))
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function getAllEducations(user_id) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestAllEducations(user_id)
      .then((response) => dispatch(successRequestingAllEducations(response)))
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function getUserEducations(user_id) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestUserEducations(user_id)
      .then((response) => dispatch(successRequestingUserEducations(response)))
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function addEducation(education) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestAddEducation(education)
      .then((response) => dispatch(successRequestingAddEducation(response)))
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function updateEducation(education) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestUpdateEducation(education)
      .then((response) => dispatch(successRequestingUpdateEducation(response)))
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function completeLesson(lesson, education, current_user) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestCompleteLesson(lesson, education, current_user)
      .then((response) => dispatch(successRequestingCompleteLesson(response)))
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function incompleteLesson(lesson, education) {
  return function (dispatch) {
    dispatch(common.loadingChanged(true))
    return requestIncompleteLesson(lesson, education)
      .then((response) => dispatch(successRequestingIncompleteLesson(response)))
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

// REQUESTS

function requestEducation(education_id) {
  const requestUrl = '/api/v1/educations/' + education_id
  return Axios.get(requestUrl)
}

function requestUserEducation(user_education_id) {
  const requestUrl = '/api/v1/user_educations/' + user_education_id
  return Axios.get(requestUrl)
}

function requestAllEducations(user_id) {
  let requestUrl = '/api/v1/educations'
  if (user_id) {
    requestUrl += '?user_id=' + user_id
  }
  return Axios.get(requestUrl)
}

function requestUserEducations(user_id) {
  const requestUrl = '/api/v1/user_educations?user_id=' + user_id
  return Axios.get(requestUrl)
}

function requestAddEducation(education) {
  const requestUrl = '/api/v1/educations'

  const data = new FormData()

  data.append('name', education.name)
  data.append('description', education.description)
  data.append('price', (education.price / 100).toFixed(2))
  data.append(
    'visibility',
    mapVisibilityToAPIEnum(parseInt(education.visibility, 10))
  )

  if (education.start_date) { data.append('start_date', moment(education.start_date)) }

  if (education.end_date) { data.append('end_date', moment(education.end_date)) }

  if (education.video_url) { data.append('video_url', education.video_url) }

  if (education.uploadPhotoFile) { data.append('education_photo', education.uploadPhotoFile) }

  data.append(
    'education_sections',
    JSON.stringify(education.education_sections)
  )

  return Axios.post(requestUrl, data)
}

function requestUpdateEducation(education) {
  const requestUrl = '/api/v1/educations/' + education.id

  const data = new FormData()

  data.append('name', education.name)
  data.append('description', education.description)
  data.append('price', (education.price / 100).toFixed(2))
  data.append(
    'visibility',
    mapVisibilityToAPIEnum(parseInt(education.visibility, 10))
  )
  data.append(
    'start_date',
    education.start_date ? moment(education.start_date) : null
  )
  data.append(
    'end_date',
    education.end_date ? moment(education.end_date) : null
  )

  if (education.video_url) { data.append('video_url', education.video_url) }

  if (education.uploadPhotoFile) { data.append('education_photo', education.uploadPhotoFile) }

  data.append(
    'education_sections',
    JSON.stringify(education.education_sections)
  )

  data.append('deleted_sections', JSON.stringify(education.deleted_sections))

  data.append('deleted_lessons', JSON.stringify(education.deleted_lessons))

  return Axios.put(requestUrl, data)
}

function requestCompleteLesson(lesson, education, current_user) {
  const requestUrl = '/api/v1/complete_lesson'

  const data = new FormData()

  data.append('lesson_id', lesson.id)
  data.append('education_id', education.id)
  data.append('user_id', current_user.id)

  return Axios.put(requestUrl, data)
}

function requestIncompleteLesson(lesson, education, current_user) {
  const requestUrl = '/api/v1/incomplete_lesson'

  const data = new FormData()

  data.append('lesson_id', lesson.id)
  data.append('education_id', education.id)
  data.append('user_id', current_user.id)

  return Axios.put(requestUrl, data)
}
