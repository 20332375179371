import React, { Component } from "react";
import ReactTable from "react-table";
import createTable from "react-table-hoc-fixed-columns";
const ReactTableFixedColumns = createTable(ReactTable);
// import "react-table/react-table.css";

// import profile_avatar from "../../../assets/images/mock/StockProfilePic.png";

class AdminCommunityStatsTable extends Component {
  renderUserAvatarCell = props => {
    return (
      <div
        className='number'
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer"
        }}
        key={props.value.id}
      >
        <div className='small-round-image-wrapper'>
          <img
            className='round-image'
            src={props.value.photo.file_url}
            alt={props.value.name}
          />
        </div>
      </div>
    );
  };
  renderTableCell = props => {
    return (
      <div
        className='number'
        style={{
          backgroundColor: "",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div style={{ color: "#2b3843" }}>{props.value}</div>
      </div>
    );
  };
  renderMainTableCell = props => {
    return (
      <div
        className='number'
        style={{
          backgroundColor: "#2b3843",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div style={{ color: "white" }}>{props.value}</div>
      </div>
    );
  };
  renderTable = () => {
    const columns = [
      {
        Header: "",
        accessor: "community",
        Cell: props => this.renderUserAvatarCell(props),
        sortable: false,
        fixed: true
      },
      {
        Header: " Community",
        accessor: "community.name",
        Cell: props => this.renderTableCell(props),
        fixed: true
      },
      {
        Header: " Coach",
        accessor: "community.coach",
        Cell: props => this.renderTableCell(props),
        fixed: true
      },
      {
        Header: "Total Families",
        accessor: "report.stats.family_count",
        Cell: props => this.renderMainTableCell(props),
        style: { padding: 0 }
      },
      {
        Header: "Total Students",
        accessor: "report.stats.student_count",
        Cell: props => this.renderMainTableCell(props),
        style: { padding: 0 }
      }
    ];

    return (
      <div className='container my-5 animated fadeIn'>
        <div className='row mb-5 d-flex align-items-center'>
          <div className='col-md-8'>
            <h2>{this.props.title}</h2>
          </div>
          <div className='col-md-4'>
            <button
              className='btn btn-sm btn-block'
              onClick={() => this.props.goBack()}
            >
              <i
                className='fa fa-arrow-left text-white mr-1'
                aria-hidden='true'
              />{" "}
              View Another Report
            </button>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <ReactTableFixedColumns
              data={this.props.data}
              columns={columns}
              defaultPageSize={3}
              loading={this.props.loader}
              loadingText='Loading Report'
              noDataText={this.props.loader ? "" : "No data found"}
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    return this.renderTable();
  }
}

export default AdminCommunityStatsTable;
