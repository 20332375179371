import React, { Component } from "react";
import moment from "moment";

import PlaceholderAvatar from "../../../assets/images/mock/StockProfilePic.png";

class GoalReview extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  renderReviewTitle = () => {
    if (this.props.goal.is_completed && this.props.isLastIndex) {
      return "Goal Completed";
    } else if (this.props.review.goal_tasks.length > 0) {
      return "Objective Completed";
    } else {
      return "Coach Review";
    }
  };

  renderReviewIcon = () => {
    if (this.props.goal.is_completed && this.props.isLastIndex) {
      return <i className='fa fa-check-circle review-complete-icon' />;
    } else if (this.props.review.goal_tasks.length > 0) {
      return <i className='fa fa-check-circle review-objective-icon' />;
    } else {
      return (
        <img
          src={
            this.props.review.creator && this.props.review.creator.avatar
              ? this.props.review.creator.avatar.file_url
              : PlaceholderAvatar
          }
          alt='Goal Reviewer'
        />
      );
    }
  };

  render() {
    return (
      <div className='review-wrapper'>
        {this.props.current_user.type === "Coach" ? (
          this.props.review.photos.length > 0 ? (
            <i
              className='fa fa-file-image-o review-image-icon'
              onClick={() => this.props.toggleModal(this.props.review)}
            />
          ) : null
        ) : null}
        {this.props.current_user.type === "Coach" ? (
          <i
            className='fa fa-eye review-view-icon'
            onClick={() => this.props.toggleModal(this.props.review)}
          />
        ) : (
          <i
            className='fa fa-file-image-o review-image-icon'
            onClick={() => this.props.toggleModal(this.props.review)}
          />
        )}
        {this.props.current_user.type === "Coach" ? (
          <i
            className='fa fa-trash-o review-trash-icon'
            onClick={() =>
              this.props.toggleConfirmationModal(
                `Are you sure you want to delete this review that happened on ${moment(
                  this.props.review.created_at
                ).format("MM-DD-YYYY")}?`,
                this.props.review,
                false
              )
            }
          />
        ) : (
          <i
            className='fa fa-eye review-view-icon'
            onClick={() => this.props.toggleModal(this.props.review)}
          />
        )}
        <div className='review-desc-container'>
          <div className='review-image-wrapper'>{this.renderReviewIcon()}</div>
          <div className='review-desc-wrapper'>
            <h5 className='review-title'>{this.renderReviewTitle()}</h5>
            <p className='review-date'>
              {moment(this.props.review.created_at).format("MM-DD-YYYY")}
            </p>
          </div>
        </div>
        {this.props.review.goal_tasks.length > 0 ? (
          <div className='review-description-wrapper'>
            <i className='fa fa-check-square-o review-description-complete-icon' />
            <p className='review-objective'>
              {this.props.review.goal_tasks[0].description}
            </p>
          </div>
        ) : null}
        {this.props.review.note ? (
          <div className='review-note-wrapper'>
            <p className='review-note'>{this.props.review.note}</p>
          </div>
        ) : null}
      </div>
    );
  }
}

export default GoalReview;
