import classNames from 'classnames'
import PropTypes from 'prop-types'
import './FieldError.scss'

const FieldError = ({ innerRef, message, general, largerMargins }) => (
  <div id='FieldError' ref={innerRef}>
    <div
      className={classNames('error-container d-flex align-items-start w-100',
        `justify-content-${general ? 'center' : 'left'}`,
        { 'general-error': general },
        { 'larger-margins': largerMargins })}
    >
      <p>{message}</p>
    </div>
  </div>
)

FieldError.propTypes = {
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.instanceOf(Element),
    }),
  ]),
  message: PropTypes.string,
  general: PropTypes.bool,
  largerMargins: PropTypes.bool,
}

export default FieldError
