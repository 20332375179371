import styles from './DashboardHeaderNotifications.module.scss'
import { ReactComponent as GeneralNotificationIcon } from '../../../assets/images/common/icons/bell.svg'
import { ReactComponent as EnrollmentsNotificationIcon } from '../../../assets/images/common/icons/sidebarMenu/Programs.svg'
import { useDispatch, useSelector } from 'react-redux'
import { UncontrolledPopover } from 'reactstrap'
import NotificationsPopover from '../../common/NotificationsPopover'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getUnreadNotifications, clearNotifications } from '../../../actions/notificationActions'

const DashboardHeaderNotifications = () => {
  const [loader, setLoader] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const notifications = useSelector(state => state.notifications)
  const currentUser = useSelector(state => state.user.current_user)
  const generalNotifications = notifications.filter(
    (n) => n.type !== 'NotificationApply'
  )
  const enrollmentNotifications = notifications.filter(
    (n) => n.type === 'NotificationApply'
  )

  const navigateFromNotification = (type, id) => {
    switch (type) {
      case 'NotificationApply':
        navigate(
          `/programs/${id}`,
          {
            state: { id: id },
          }
        )
        break
      case 'NotificationFamily':
        navigate(
          `/familydetail/${id}`,
          {
            state: { familyId: id },
          }
        )
        break
      case 'NotificationLead':
        navigate('/leads')
        break
      case 'NotificationComment':
        handleNotifActivityClick(id)
        break
      case 'NotificationLike':
        handleNotifActivityClick(id)
        break
      default:
        return null
    }
  }

  const handleNotifActivityClick = (activityId) => {
    navigate(`/profile/${currentUser.id}`, { state: { userId: currentUser.id, activityId } })
  }

  const handleClearNotifications = () => {
    setLoader(true)
    dispatch(clearNotifications(currentUser.id)).then(() => {
      setLoader(false)
    })
  }

  const refreshNotifications = () => {
    setLoader(true)
    dispatch(getUnreadNotifications(currentUser.id)).then(() => {
      setLoader(false)
    })
  }

  return (
    <div className={styles.dashboardHeaderNotifications}>
      <button className={styles.notificationButton} id='NotificationsButton'>
        <GeneralNotificationIcon className={styles.icon}/>
        {generalNotifications.length > 0 && <div className={styles.badge}/>}
        <p className={styles.label}>New Notifications</p>
      </button>
      <UncontrolledPopover
        placement='bottom'
        target='NotificationsButton'
        trigger='legacy'
      >
        <NotificationsPopover
          notifications={generalNotifications}
          refreshNotifications={refreshNotifications}
          clearNotifications={handleClearNotifications}
          loader={loader}
          navigateFromNotification={navigateFromNotification}
        />
      </UncontrolledPopover>
      <button className={styles.notificationButton} id='EnrollmentNotificationsButton'>
        <EnrollmentsNotificationIcon className={styles.icon}/>
        {enrollmentNotifications.length > 0 && <div className={styles.badge}/>}
        <p className={styles.label}>New Enrollments</p>
      </button>
      <UncontrolledPopover
        placement='bottom'
        target='EnrollmentNotificationsButton'
        trigger='legacy'
      >
        <NotificationsPopover
          notifications={enrollmentNotifications}
          refreshNotifications={refreshNotifications}
          clearNotifications={handleClearNotifications}
          loader={loader}
          navigateFromNotification={navigateFromNotification}
        />
      </UncontrolledPopover>
    </div>
  )
}

export default DashboardHeaderNotifications
