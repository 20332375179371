import { createPackage, deletePackage, getCommunitySettingsPackages, updatePackage } from '../../actions/packagesActions'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  packages: [],
  totalPages: null,
  page: 1,
  archived: false,
  selectedPackage: null,
  isLoadingPackages: false,
}

const pricingSettingsReducer = createSlice({
  name: 'communitySettings/pricing',
  initialState: initialState,
  reducers: {
    setArchived: (state, action) => {
      state.archived = action.payload
    },
    setSelectedPackage: (state, action) => {
      state.selectedPackage = action.payload
    },
    setPage: (state, action) => {
      state.page = action.payload
    },
    reset: state => {
      state.page = 1
      state.archived = false
      state.selectedPackage = null
    },
  },
  extraReducers: builder => {
    builder
      // Get all packages
      .addCase(getCommunitySettingsPackages.pending, state => {
        state.isLoadingPackages = true
      })
      .addCase(getCommunitySettingsPackages.fulfilled, (state, action) => {
        state.isLoadingPackages = false
        state.packages = action.payload.packages
        state.totalPages = action.payload.meta.pagination?.totalPages
      })
      // Add package
      .addCase(createPackage.fulfilled, (state, action) => {
        state.packages.push({ ...action.payload.package, courseCount: 0, enrollmentCount: 0 })
      })
      // Update package
      .addCase(updatePackage.fulfilled, (state, action) => {
        const _package = action.payload.package
        const index = state.packages.findIndex(p => p.id === _package.id)
        if (index !== -1) {
          if (_package.archived !== state.archived) {
            state.packages.splice(index, 1)
          } else {
            state.packages[index] = { ...state.packages[index], ..._package }
          }
        }
      })
      // Delete package
      .addCase(deletePackage.fulfilled, (state, action) => {
        const _package = action.payload.package
        const index = state.packages.findIndex(p => p.id === _package.id)
        if (index !== -1) {
          state.packages.splice(index, 1)
        }
      })
  },
})

export default pricingSettingsReducer.reducer

export const {
  setPage,
  setArchived,
  setSelectedPackage,
  reset,
} = pricingSettingsReducer.actions
