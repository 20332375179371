import { forwardRef } from 'react'
import clsNames from 'classnames'
import PropTypes from 'prop-types'
import ReactDatePicker from 'react-datepicker'
import Input from './Input'

import './DatePicker.scss'

const DatePicker = ({ className, error, symbol, disabled, inputClassNames, ...props }) => {
  /* eslint-disable react/prop-types, react/display-name */
  const CustomInput = forwardRef(({ value, onClick }, ref) =>
    <Input
      type='text'
      innerRef={ref}
      onClick={onClick}
      value={value}
      error={error}
      symbol={symbol}
      disabled={disabled}
      classNames={inputClassNames}
    />
  )

  return (
    <div className={clsNames('date-picker-wrapper', className)}>
      <ReactDatePicker
        customInput={<CustomInput />}
        {...props}
      />
    </div>
  )
}

DatePicker.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  error: PropTypes.arrayOf(PropTypes.string),
  symbol: PropTypes.node,
  disabled: PropTypes.bool,
  inputClassNames: PropTypes.shape({
    inputContainerWrapper: PropTypes.string,
    inputContainer: PropTypes.string,
    symbol: PropTypes.string,
    input: PropTypes.string,
  }),
}

export default DatePicker
