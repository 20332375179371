import * as types from '../actions/actionTypes';

// state = [] : Start out with no accounts
export default function families(state = [], action){
  switch(action.type){

    case types.ADD_FAMILIES:
      return action.families;
      
    default:
      return state;
  }
}
