import React, { Component } from "react";

// ASSETS
import "./EducationSidebar.scss";

// CHILDREN
import SimpleEducationSection from "./SimpleEducationSection";
import EducationProgressBar from "../../EducationProgressBar";

class EducationSidebar extends Component {
  calculateEducationSectionCount = () => {
    const { education } = this.props;
    let sectionCount = education.education_sections.length;

    return `${sectionCount} sections`;
  };

  calculateEducationLessonCount = () => {
    const { education } = this.props;
    let lessonCount = 0;

    education.education_sections.map(
      section => (lessonCount += section.education_lessons.length)
    );

    return `${lessonCount} lessons`;
  };

  renderSimpleEducationSections = () => {
    const {
      education,
      selectLesson,
      selectedLesson,
      isCoach,
      userEducation
    } = this.props;

    if (
      education.education_sections &&
      education.education_sections.length > 0
    ) {
      return education.education_sections.map((section, index) => (
        <SimpleEducationSection
          section={section}
          index={index}
          key={section.id}
          selectLesson={selectLesson}
          selectedLesson={selectedLesson}
          userEducation={userEducation}
          isCoach={isCoach}
        />
      ));
    }
  };
  render() {
    const { education, isCoach, userEducation } = this.props;
    return (
      <div id="EducationSidebar">
        <div className="education-sidebar-header">
          <h3>{education.name}</h3>
          {isCoach && (
            <EducationProgressBar
              education={education}
              userEducation={userEducation}
            />
          )}
        </div>
        {this.renderSimpleEducationSections()}
      </div>
    );
  }
}

export default EducationSidebar;
