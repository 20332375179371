import React from "react";
import PropTypes from 'prop-types'
import "./PlayerJourneyReportDivisionProgress.scss";
import DIVISION_BADGES_GRAY from "../../../constants/divisionBadgesGray";
import DIVISION_BADGES from "../../../constants/divisionBadges";
import DIVISION_RIBBONS from "../../../constants/ribbons";
import DIVISIONS from "../../../constants/divisions.json";

const PlayerJourneyReportDivisionProgress = (props) => { //eslint-disable-line complexity
  let badgeGrayList = [...DIVISION_BADGES_GRAY];
  let badgeList = [...DIVISION_BADGES];
  let ribbonList = [...DIVISION_RIBBONS];
  let divisions = [...DIVISIONS];

  const { report } = props;
  const divisionColor = divisions.filter(division => division.order === report.current_division)[0].color

  //ribbons: Milestone circles - for any circle number <= formal_record_score,
  //         make number gray and color of circle gray
  const formalRecordScore = report.my_division_progress.formal_record_score;
  const ribbon01ColorIndex = (50 <= formalRecordScore) || !formalRecordScore ? 0 : report.current_division;
  const ribbon02ColorIndex = (45 <= formalRecordScore) || !formalRecordScore ? 0 : report.current_division;
  const ribbon03ColorIndex = (39 <= formalRecordScore) || !formalRecordScore ? 0 : report.current_division;


  const recordScore = report.my_division_progress.record_score;

  //circle: Milestone circles - for any circle number <= record_score,
  //         make number gray and color of circle gray
  const ribbon36 = report.current_division;

  let progressBadge = (36 >= recordScore) && recordScore ? badgeList[ribbon36 - 1] : badgeGrayList[ribbon36 - 1];
  let completed = 0;
  completed = (50 > recordScore && recordScore > 45) && recordScore ? 20 : completed;
  completed = (recordScore === 45) && recordScore ? 25 : completed;
  completed = (45 > recordScore && recordScore > 39) && recordScore ? 44 : completed;
  completed = (recordScore === 39) && recordScore ? 50 : completed;
  completed = (39 > recordScore && recordScore > 36) && recordScore ? 64 : completed;
  completed = (36 >= recordScore) && recordScore ? 70 : completed;

  let circle01Color = (50 < recordScore) || !recordScore ? '#afb7bd' : divisionColor;
  let circle02Color = (45 < recordScore) || !recordScore ? '#afb7bd' : divisionColor;
  let circle03Color = (39 < recordScore) || !recordScore ? '#afb7bd' : divisionColor;

  let circle01TextColor = (50 < recordScore) || !recordScore ? '#afb7bd' : "#1d2933";
  let circle02TextColor = (45 < recordScore) || !recordScore ? '#afb7bd' : "#1d2933";
  let circle03TextColor = (39 < recordScore) || !recordScore ? '#afb7bd' : "#1d2933";

  const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    backgroundColor: divisionColor,
    borderRadius: 'inherit',
    textAlign: 'right'
  }

  const circle01Styles = {
    borderColor: circle01Color
  }

  const circle02Styles = {
    borderColor: circle02Color
  }

  const circle03Styles = {
    borderColor: circle03Color
  }

  return (
    <div className="progress-ribbons-container">
      <div className="progress-bar-ribbon">
        <div style={fillerStyles} className="progress-bar-fill"></div>
      </div>

      <img className="ribbon-01" src={ribbonList[
        ribbon01ColorIndex]} alt="ribbon"></img>

      <div style={circle01Styles} className="circle-01">
        <span style={{ color: circle01TextColor }} className="text-circle">50</span>
      </div>

      <img className="ribbon-02" src={ribbonList[
        ribbon02ColorIndex]} alt="ribbon"></img>
      <div style={circle02Styles} className="circle-02">
        <span style={{ color: circle02TextColor }} className="text-circle">45</span>
      </div>

      <img className="ribbon-03" src={ribbonList[
        ribbon03ColorIndex]} alt="ribbon"></img>
      <div style={circle03Styles} className="circle-03">
        <span style={{ color: circle03TextColor }} className="text-circle">39</span>
      </div>

      {/* <img className="division-level" src={progressBadge} alt="division"></img> */}
      <div
        className='division-level'
        style={{
          backgroundImage: `url(${progressBadge})`
        }}
      />
    </div>
  );
};

PlayerJourneyReportDivisionProgress.propTypes = {
  report: PropTypes.shape({
    current_division: PropTypes.number,
  }),
}

export default PlayerJourneyReportDivisionProgress
