import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { components } from 'react-select'
import { getCommunityCategories } from '../../../../actions/programCategoryActions.js'
import { setCourseCategory, resetCourseCategoryErrors } from '../../../../reducers/community-settings/landingPageSettingsReducer'
import { setField } from '../../../../reducers/programBuilderReducer'
import Select from '../../../common/form/Select'
import InfoModal from '../../../common/InfoModal'
import CourseCategoryEditModal from '../../../community-settings/CommunityLandingPageTab/CourseCategoryEditModal'

import colors from '../../../../assets/styles/globals.scss'
import classes from './CategorySelect.module.scss'
import classNames from 'classnames'

const NEW_CATEGORY = {
  name: '',
  color: '',
  order: null,
  description: '',
  headerImageUrl: '',
  headerImageData: null,
  headerImageName: '',
}

/* eslint-disable react/prop-types */
const Option = ({ data, ...props }) => {
  if (data.id === -1) {
    return (
      <components.Option {...props}>
        <div className={classes.createCategory}>
          <p>{data.name}</p>
        </div>
      </components.Option>
    )
  } else {
    return (
      <components.Option {...props}>
        <div className={classNames('d-flex align-items-center', classes.categoryOption)}>
          <div className='d-flex flex-column justify-content-center ml-3'>
            <div className='d-flex align-items-center'>
              <span className={classNames('mr-2', classes.color)} style={{ backgroundColor: data.color }}></span>

              <p className={classes.name}>{data.name}</p>
            </div>

            <p className={classNames('ml-4', classes.description)}>{data.description} </p>
          </div>
        </div>
      </components.Option>
    )
  }
}

/* eslint-disable react/prop-types */
const SingleValue = ({ data, ...props }) => (
  <components.SingleValue {...props}>
    <div className={classNames('d-flex align-items-center', classes.categoryValue)}>
      <span className={classNames('mr-2', classes.color)} style={{ backgroundColor: data.color }}></span>

      <p className={classes.name}>{data.name}</p>
    </div>
  </components.SingleValue>
)

const CategorySelect = () => {
  const dispatch = useDispatch()

  const current_user = useSelector(state => state.user.current_user)
  const communityId = current_user.communities[0].id
  const { errors, communityCategories, category } = useSelector(state => state.programBuilder)

  const [showCategoryFormModal, setShowCategoryFormModal] = useState(false)
  const [showInfoModal, setShowInfoModal] = useState(false)

  const handleCategoryChange = (category) => {
    if (category?.id !== -1) {
      dispatch(setField({ field: 'category', value: category }))
    } else {
      if (current_user.lead_coach) {
        dispatch(setCourseCategory(NEW_CATEGORY))
        setShowCategoryFormModal(true)
      } else {
        setShowInfoModal(true)
      }
    }
  }

  return (
    <div id='CategorySelect'>
      <Select
        name='category-select'
        placeholder='Choose community category'
        value={category}
        options={[...communityCategories, { id: -1, name: current_user.lead_coach ? '+ Create a new category' : 'Need more categories?' }]}
        components={{
          SingleValue,
          Option,
        }}
        isSearchable={false}
        menuPlacement='auto'
        minMenuHeight={300}
        menuPosition='fixed'
        onChange={handleCategoryChange}
        error={errors.courseCategoryId}
        isClearable
      />

      <CourseCategoryEditModal
        isOpen={showCategoryFormModal}
        onClose={() => { dispatch(resetCourseCategoryErrors()); setShowCategoryFormModal(false) }}
        onSubmitCallback={(category) => { handleCategoryChange(category); dispatch(getCommunityCategories()) }}
        communityId={communityId}
        courseCategoriesNumber={communityCategories.length}
      />

      <InfoModal
        showModal={showInfoModal}
        closeModal={() => setShowInfoModal(false)}
        title='Only lead coaches can create or manage categories'
      >
        <div style={{ color: colors.lightNavy }}>
          <p style={{ marginBottom: '0' }}>
            If you need to create another category, please ask your community&apos;s lead coach to create it for you.
          </p>
        </div>
      </InfoModal>
    </div>
  )
}

export default CategorySelect
