import { useState } from 'react'
import { useSelector } from 'react-redux'

import { ReactComponent as RoundedPlusIcon } from '../../../../assets/images/common/icons/rounded-plus-icon.svg'
import Placeholder from '../../../common/Placeholder'
import Modal from '../../../common/Modal'
import Loader from '../../../common/Op36Loader-web'
import RosterSummary from './modals/RosterSummary'
import GolfersTable from './GolfersTable'
import { checkLicensePermission } from '../../../../utility/communityLicense'

import './GolfersTabContainer.scss'

const GolfersTabContainer = ({
  course,
  courseLoader,
  toggleAddStudentModal
}) => {
  const licenseType = useSelector(state => state.user.current_user.communities[0].license_type)
  const [showRosterSummary, setShowRosterSummary] = useState(false)

  return (
    <div id='GolfersTabContainer'>
      { courseLoader
        ? <Loader message={'loading golfers list'} />
        : <>
            <div className='d-flex justify-content-center align-items-center pt-1 golfers-header'>
              <div className='d-flex justify-content-start align-items-center golfers-count'>
                <p>
                  {`${course.enrollments.length} ${course.golfer_limit ? `/ ${course.golfer_limit}` : ''} ${course.golfer_limit === 1 || (!course.golfer_limit && course.enrollments.length === 1) ? 'Golfer' : 'Golfers'}`}
                </p>
              </div>
              <div className="d-flex align-items-center view-roster" onClick={() => setShowRosterSummary(true)}>
                <i className='fa fa-users'></i>
                <span className='view-roster-label'>Roster Summary</span>
              </div>
              {checkLicensePermission(licenseType, ['standard_legacy']) &&
                <div className='d-flex align-items-center'>
                  <button
                    type='button'
                    className='d-flex justify-content-center align-items-center add-students-button'
                    onClick={toggleAddStudentModal}
                    disabled={course.is_draft || course.is_completed}
                  >
                    <RoundedPlusIcon id='roundedPlusIcon' /> Add Students
                  </button>
                </div>
              }
            </div>
            {course.enrollments.length > 0
              ? <GolfersTable course={course} />
              : <Placeholder
                  title={"No Students Enrolled"}
                  icon={"fa-user"}
                  subtext1={
                    `Share the sign-up page with your students ${checkLicensePermission(licenseType, ['standard_legacy']) ?
                    ' or click the \"Add Students\" button to manually add students' : ''}`
                  }
                />
            }
          <Modal isOpen={showRosterSummary} onClose={() => setShowRosterSummary(false)} fixedTop>
            <RosterSummary course={course} />
          </Modal>
          </>
      }
    </div>
  )
}

export default GolfersTabContainer
