import { createAsyncThunk } from '@reduxjs/toolkit'
import { handleAPIError } from '../utility/actionHelper'
import { serialize, deserialize } from '../utility/serialization'

import Axios from '../axios'

export const getSeries = createAsyncThunk(
  'series/getSeries',
  async (seriesId, { rejectWithValue }) => {
    try {
      const { data } = await requestGetSeries(seriesId)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const getAllSeries = createAsyncThunk(
  'series/getAllSeries',
  async ({ filters, page, perPage }, { rejectWithValue }) => {
    try {
      const { data } = await requestGetAllSeries(filters, page, perPage)
      return deserialize({ ...data, filters })
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const createSeries = createAsyncThunk(
  'series/createSeries',
  async (seriesData, { rejectWithValue }) => {
    try {
      const { data } = await requestCreateSeries(seriesData)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const updateSeries = createAsyncThunk(
  'series/updateSeries',
  async (seriesData, { rejectWithValue }) => {
    try {
      const { data } = await requestUpdateSeries(seriesData)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const deleteSeries = createAsyncThunk(
  'series/deleteSeries',
  async (seriesData, { rejectWithValue }) => {
    try {
      const { data } = await requestDeleteSeries(seriesData.seriesId)
      return { series: deserialize(data.series), typeCompleted: seriesData.typeCompleted }
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

// API CALLS

function requestGetSeries(seriesId) {
  const requestUrl = '/api/v1/series/' + seriesId

  return Axios.get(requestUrl)
}

function requestGetAllSeries(filters, page, perPage) {
  const requestUrl = '/api/v1/series'
  const params = serialize({ ...filters, page, perPage })

  return Axios.get(requestUrl, { params })
}

function requestCreateSeries(series) {
  const requestUrl = '/api/v1/series'
  const data = serialize(series)

  return Axios.post(requestUrl, data)
}

function requestUpdateSeries(series) {
  const requestUrl = `/api/v1/series/${series.id}`
  const data = serialize(series)

  return Axios.put(requestUrl, data)
}

function requestDeleteSeries(seriesId) {
  const requestUrl = `/api/v1/series/${seriesId}`

  return Axios.delete(requestUrl)
}
