import React from "react";
import Loader from "../common/Op36Loader-web";

import "./MilestonePage.scss";
// import SmallMilestoneIcon from "../common/SmallMilestoneIcon";
import MilestoneProgressBar from "./MilestoneProgressBar";
import LargeMilestoneIcon from "../common/LargeMilestoneIcon";
import MilestoneModal from "./MilestoneModal";

const divs = [
  {
    id: 1,
    bgColor: "#fff",
    color: '#FF6633', // orange
  },
  {
    id: 2,
    bgColor: "#fff",
    color: "#FDDB10" // yellow
  },
  {
    id: 3,
    bgColor: "#fff",
    color: "#D5DE27" // green
  },
  {
    id: 4,
    bgColor: "#fff",
    color: "#2BAAE1" // blue
  },
  {
    id: 5,
    bgColor: "#fff",
    color: "#B51E8C" // purple
  },
  {
    id: 6,
    bgColor: "#1d2933",
    color: '#FF6633', // orange
  },
  {
    id: 7,
    bgColor: "#1d2933",
    color: "#FDDB10" // yellow
  },
  {
    id: 8,
    bgColor: "#1d2933",
    color: "#D5DE27" // green
  },
  {
    id: 9,
    bgColor: "#1d2933",
    color: "#2BAAE1" // blue
  },
  {
    id: 10,
    bgColor: "#1d2933",
    color: "#B51E8C" // purple
  }
];

class MilestonePage extends React.Component {
  state = {
    milestoneModal: false,
    selectedDivision: 0
  };
  render() {
    const { division, divisionLoader } = this.props;
    const { selectedDivision } = this.state;
    return (
      <div className='card' id='MilestonePage'>
        {divisionLoader ? (
          <div className='col-6 mx-auto'>
            <Loader message='loading milestones' />
          </div>
        ) : (
          <div>
            {this.state.milestoneModal ? (
              <div className='grey-out' onClick={this._closeModal} />
            ) : null}
            <div className='division-milestone-header'>
              <p className='mb-1'>{`Level Milestones (${this._countMilestones(
                division.divisions
              )}/10)`}</p>
            </div>
            <div className='milestone-container mt-4'>
              {this._renderMilestones(division.divisions, 5)}
            </div>
            {this.state.milestoneModal ? (
              <MilestoneModal
                division={selectedDivision}
                data={division.divisions}
                closeModal={this._closeModal}
              />
            ) : null}
          </div>
        )}
      </div>
    );
  }

  _renderMilestones = (arr, num) => {
    let first = [];
    let second = [];
    let box;

    arr.forEach((div, index) => {
      if (!div.nine_hole.record_score || div.nine_hole.record_score > 36) {
        box = (
          <div className='milestone-box' key={div.division_id}>
            <div
              className='active-milestones'
              onClick={() => this._openModal(div.division_id)}
            >
              <LargeMilestoneIcon
                division={div.division_id}
                bgColor='#fff'
                color='#f4f4f5'
                isLocked={true}
              />
            </div>
            <div className='progress-box'>
              <MilestoneProgressBar
                divId={div.division_id}
                color={divs[index].color}
                data={div}
              />
            </div>
          </div>
        );
      } else {
        box = (
          <div className='milestone-box' key={div.division_id}>
            <div
              className='active-milestones'
              onClick={() => this._openModal(div.division_id)}
            >
              <LargeMilestoneIcon division={div.division_id} />
            </div>
            <div className='progress-box'>
              <MilestoneProgressBar
                divId={div.division_id}
                color={divs[index].color}
                data={div}
              />
            </div>
          </div>
        );
      }
      if (index < num) {
        first.push(box);
      } else {
        second.push(box);
      }
    });

    return (
      <div className='milestone-row'>
        <div className='first-milestone-row'>{first}</div>
        <div className='second-milestone-row'>{second}</div>
      </div>
    );
  };

  _openModal = div => {
    this.setState({ selectedDivision: div }, () => {
      this.setState({ milestoneModal: true });
    });
  };

  _closeModal = () => {
    this.setState({ milestoneModal: false });
  };

  _countMilestones = data => {
    let count = 0;
    data.forEach(div => {
      if (div.nine_hole.record_score && div.nine_hole.record_score <= 36) {
        count++;
      }
      // if (
      //   div.eighteen_hole.record_score &&
      //   div.eighteen_hole.record_score < 72
      // ) {
      //   count++;
      // }
    });
    return count;
  };
}

export default MilestonePage;
