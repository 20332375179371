import ScheduleSessionFormContainer from './ScheduleSessionFormContainer'
import BaseModal from '../../common/BaseModal'
import ScheduleView from './ScheduleView'
import './ScheduleSessionModal.scss'

const ScheduleSessionModal = ({
  editable,
  selectedScheduleSession,
  showScheduleSessionModal,
  closeModal,
  scheduleAction,
  setScheduleAction,
  addScheduleSession,
  editScheduleSession,
  removeScheduleSession
}) => {
  return (
    <div>
      {showScheduleSessionModal &&
        <BaseModal width='600px' close={closeModal}>
          <div id='ScheduleSessionModal'>
            {scheduleAction === 'view' ? (
              <ScheduleView
                editable={editable}
                session={selectedScheduleSession}
                setScheduleAction={setScheduleAction}
                closeModal={closeModal}
              />
            ) : (
              <ScheduleSessionFormContainer
                selectedScheduleSession={selectedScheduleSession}
                handleCloseForm={closeModal}
                scheduleAction={scheduleAction}
                addScheduleSession={addScheduleSession}
                editScheduleSession={editScheduleSession}
                removeScheduleSession={removeScheduleSession}
              />
            )}
          </div>
        </BaseModal>
      }
    </div>
  )
}

export default ScheduleSessionModal
