import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styles from '../../../assets/styles/components/common/SideBarItem.module.scss'
import classNames from 'classnames'


const SideBarSectionLink = ({ link, collapsed, collapseSideBar, selected }) => (
  link.external ?
    <a
      href={link.external}
      target={link.target}
      className={classNames({ [styles.sectionLink]: collapsed }, { [styles.selectedSectionLink]: selected })}
      onClick={() => collapseSideBar()}
    >
      {link.title}
    </a>
    :
    <Link
      to={link.to}
      className={classNames({ [styles.sectionLink]: collapsed }, { [styles.selectedSectionLink]: selected })}
      target={link.target}
      onClick={() => collapseSideBar()}
    >
      {link.title}
    </Link>
)

SideBarSectionLink.propTypes = {
  link: PropTypes.object,
  collapsed: PropTypes.bool,
  collapseSideBar: PropTypes.func,
  selected: PropTypes.bool,
}

export default SideBarSectionLink
