import React, { Component } from "react";
import Modal from "react-modal";

import defaultProfilePic from "../../assets/images/default_profile.png";

class StudentModal extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  displayStudents = () => {
    return this.props.students.map(student => {
      return (
        <div className='border-bottom p-2 my-1' key={student.id}>
          <div className='row'>
            <div className='col-2 align-items-center'>
              <div className='round-image-wrapper'>
                <img
                  className='round-image'
                  src={
                    student.avatar ? student.avatar.file_url : defaultProfilePic
                  }
                />
              </div>
            </div>
            <div className='col-7'>
              <h5>{`${student.first_name} ${student.last_name}`}</h5>
              <small>
                {student.username ? `${student.username}` : `${student.email}`}
              </small>
            </div>
          </div>
        </div>
      );
    });
  };

  render() {
    return (
      <Modal
        isOpen={this.props.showModal}
        onRequestClose={() => this.props.closeModal()}
        contentLabel='Announcement Modal'
      >
        <div className='modal-header row m-0 p-0'>
          <div className='col-11 m-0 p-0'>
            <h5 className='modal-title display-4'>
              {this.props.isFamily ? "Family" : "Class"} Students
            </h5>
          </div>
          <div className='col-1 m-0 p-0'>
            <button
              type='button'
              className='close pull-right font-200'
              data-dismiss='modal'
              aria-label='Close'
              onClick={() => this.props.closeModal()}
            >
              <span aria-hidden='true'>✕</span>
            </button>
          </div>
        </div>

        <div className='row mt-4'>
          <div className='col-md-6 mx-auto'>
            <h3>{this.props.students.length} Student(s)</h3>
            {this.displayStudents()}
          </div>
        </div>
      </Modal>
    );
  }
}

export default StudentModal;
