import * as constants from "./actionTypes";

import Axios from '../axios'

function successRequestingChallenges(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_ALL_CHALLENGES,
      challenges: response.data.challenges
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successUpdatingChallenge(response) {
  if (response.status === 200) {
    return {
      type: constants.UPDATE_CHALLENGE,
      challenge: response.data.challenge
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successCreatingChallenge(response) {
  if (response.status === 201) {
    return {
      type: constants.CREATE_CHALLENGE,
      challenge: response.data.challenge
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successDestroyingChallenge(response) {
  if (response.status === 200) {
    return {
      type: constants.DESTROY_CHALLENGE,
      challenge: response.data.challenge
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

//=========================================================================================
//=========================================================================================
//=========================================================================================

export function getChallenges() {
  return function(dispatch) {
    return requestChallenges().then(response =>
      dispatch(successRequestingChallenges(response))
    );
  };
}

export function updateChallenge(challengeId, data) {
  return function(dispatch) {
    return requestUpdateChallenge(challengeId, data).then(response =>
      dispatch(successUpdatingChallenge(response))
    );
  };
}

export function createChallenge(data) {
  return function(dispatch) {
    return requestCreateChallenge(data).then(response =>
      dispatch(successCreatingChallenge(response))
    );
  };
}

export function deleteChallenge(id) {
  return function(dispatch) {
    return destroyChallenge(id).then(response =>
      dispatch(successDestroyingChallenge(response))
    );
  };
}

//=========================================================================================
//=========================================================================================
//=========================================================================================

function requestChallenges() {
  let requestUrl = "/api/v1/challenges";
  return Axios.get(requestUrl);
}

function requestUpdateChallenge(challengeId, data) {
  let requestUrl = "/api/v1/challenges/" + challengeId;

  let challengeData = new FormData();

  if (data.name) {
    challengeData.append("name", data.name);
  }
  if (data.description) {
    challengeData.append("description", data.description);
  }
  if (data.points) {
    challengeData.append("points", data.points);
  }
  if (data.photo) {
    challengeData.append("photo", data.photo);
  }
  if (data.icon) {
    challengeData.append("icon", data.icon);
  }
  challengeData.append("links", data.links);
  challengeData.append("video_url", data.video_url || "");
  challengeData.append("start_date", data.start_date);
  challengeData.append("end_date", data.end_date);

  return Axios.put(requestUrl, challengeData);
}

function requestCreateChallenge(data) {
  let requestUrl = "/api/v1/challenges";

  let challengeData = new FormData();

  challengeData.append("name", data.name);
  challengeData.append("description", data.description);
  challengeData.append("points", data.points);
  challengeData.append("photo", data.photo);
  challengeData.append("icon", data.icon);
  challengeData.append("links", data.links);
  if (data.video_url) {
    challengeData.append("video_url", data.video_url);
  }
  if (data.end_date && data.start_date) {
    challengeData.append("start_date", data.start_date);
    challengeData.append("end_date", data.end_date);
  }

  return Axios.post(requestUrl, challengeData);
}

function destroyChallenge(id) {
  let requestUrl = "/api/v1/challenges/" + id;
  return Axios.delete(requestUrl);
}
