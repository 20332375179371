import { ReactComponent as TableSelectDropDownIcon } from '../../../../assets/images/common/icons/table-select-drop-down-icon.svg'
import { components } from 'react-select'
import colors from '../../../../assets/styles/globals.scss'

const DropdownIndicator = (props) => (
  components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      <TableSelectDropDownIcon stroke={colors.lightNavy} style={{ marginRight: '-7px', marginLeft: '3px' }}/>
    </components.DropdownIndicator>
  )
)

export default DropdownIndicator
