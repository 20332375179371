import React, { Component } from "react";

// CHILDREN
import AdminEducationSection from "../AdminEducationSection.js";

// ASSETS
import "./AdminEducationSectionsForm.scss";

class AdminEducationSectionsForm extends Component {
  renderEducationSections = () => {
    const {
      formState: { education_sections },
      sendSectionData,
      deleteSection,
      deleteLesson,
      isEditModal
    } = this.props;

    if (education_sections && education_sections.length > 0) {
      return education_sections.map((section, index) => (
        <div className="admin-section" key={section.order}>
          <h1>Section {index + 1}</h1>
          <AdminEducationSection
            section={section}
            index={index}
            sendSectionData={sendSectionData}
            deleteSection={deleteSection}
            deleteLesson={deleteLesson}
            isEditModal={isEditModal}
          />
          <div className="section-divider" />
        </div>
      ));
    }
    return null;
  };

  render() {
    const {
      formState,
      addSection,
      previous,
      loader,
      createEducation,
      updateEducation,
      isEditModal
    } = this.props;
    return (
      <div id="AdminEducationSectionsForm">
        <h4>
          Start {isEditModal ? "updating" : "creating"} the sections for{" "}
          {formState.name}
        </h4>
        <p>
          Create each section and add their corresponding lessons with either
          video and/or description
        </p>
        {this.renderEducationSections()}
        <div className="add-section-button-wrapper">
          <button className="animation-shrink" onClick={addSection}>
            <i className="fa fa-plus" aria-hidden="true" />
          </button>
        </div>
        <div className="col-md-12 d-flex justify-content-center align-items-center mt-4">
          <button
            className="btn btn-dark m-2"
            onClick={previous}
            disabled={loader}
          >
            Edit Education
          </button>
          <button
            className="btn btn-primary m-2"
            onClick={isEditModal ? updateEducation : createEducation}
            disabled={loader}
          >
            {isEditModal ? "Update" : "Create"} Education
          </button>
        </div>
      </div>
    );
  }
}

export default AdminEducationSectionsForm;
