import { useState } from 'react'

import 'react-image-crop/dist/ReactCrop.css'
import PropTypes from 'prop-types'
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop'

import styles from './ImageCropper.module.scss'

const ImageCropper = ({ onCompletedCrop, selectedImage, imageRef }) => {
  const [crop, setCrop] = useState(null)

  const onImageLoad = event => {
    const { width, height } = event.currentTarget

    setCrop(centerCrop(makeAspectCrop({ unit: '%', width: 100 }, 32 / 9, width, height), width, height))
  }

  return (
    <ReactCrop
      crop={crop}
      onChange={(_, percentCrop) => setCrop(percentCrop)}
      onComplete={(crop) => onCompletedCrop(crop)}
      locked
    >
      <img src={selectedImage} ref={imageRef} onLoad={onImageLoad} className={styles['header-image-crop']} alt='Selected' />
    </ReactCrop>
  )
}

ImageCropper.propTypes = {
  onCompletedCrop: PropTypes.func.isRequired,
  selectedImage: PropTypes.func.isRequired,
  imageRef: PropTypes.func.isRequired,
}

export default ImageCropper
