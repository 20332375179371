import React from "react";
import PropTypes from "prop-types";
import "../../../assets/styles/components/common/form/TextInput.scss";

const TextInput = ({
  name,
  type,
  label,
  className,
  accept,
  id,
  rows,
  onChange,
  onBlur,
  placeholder,
  defaultValue,
  value,
  error,
  disabled,
  autoComplete
}) => {
  let wrapperClass = "text-input";
  if (error && error.length > 0) {
    wrapperClass += " has-error";
  }

  let errorString = error;
  if (Array.isArray(error)) errorString = error.join(".\n ");

  return (
    <div className={wrapperClass}>
      <div className='field'>
        <input
          type={type}
          name={name}
          id={id}
          rows={rows}
          className={`form-control ${className} ${
            errorString ? " is-invalid" : ""
          }`}
          placeholder={placeholder}
          accept={accept}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          autoComplete={autoComplete}
        />
        {errorString && <div className='alert alert-danger'>{errorString}</div>}
      </div>
    </div>
  );
};

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  defaultValue: PropTypes.string,
  autoComplete: PropTypes.string,
  // error: PropTypes.string
};

export default TextInput;
