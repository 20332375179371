import React from "react";
import { Progress } from "reactstrap";

const calculatePercentage = data => {
  let record = data.nine_hole.record_score;
  if (!record) return 0;
  if (record <= 36) {
    return 100;
  } else if (record <= 39) {
    return 75;
  } else if (record <= 45) {
    return 50;
  } else if (record <= 50) {
    return 25;
  } else {
    return 0;
  }
};

const MilestoneProgressBar = props => {
  let percentage = calculatePercentage(props.data);
  return (
    <div>
      <Progress
        value={percentage}
        className='progress-container'
        barClassName={`progress-bar-${props.divId}`}
      />
      <p className='text-center' style={{ fontSize: '0.7rem' }}>{`Level ${
        props.divId
      }`}</p>
    </div>
  );
};

export default MilestoneProgressBar;
