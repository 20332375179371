import React from "react";
import { withRouting } from "../common/hooks";

const CurriculumCard = ({ name, description, image, route, navigate }) => (
  <div className="card p-4 mt-3 curriculum-card-container animated fadeIn">
    <div className="curriculum-card-image-wrapper">
      <img src={image} alt="Operation 36 Logo" />
    </div>
    <h2>{name}</h2>
    <p>{description}</p>
    <button
      className="btn btn-primary w-50 mx-auto"
      onClick={() => navigate(route)}
    >
      View
    </button>
  </div>
);

export default withRouting(CurriculumCard)
