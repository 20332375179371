import React, { Component } from "react";
import Modal from "react-modal";
import TextInput from "../common/form/TextInput";

class ResourceModal extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  selectInputFile() {
    document.getElementById("input-photo-upload").click();
  }

  displayImageSelect() {
    if (this.props.uploadPhotoFileSrc) {
      return (
        <div id="ResourceImage" className="text-center drills-image-container">
          <button
            className="btn-link secondary-text"
            onClick={this.selectInputFile}
          >
            <div
              className="center-cropped drills-image"
              style={{
                backgroundImage: `url(${this.props.uploadPhotoFileSrc})`
              }}
            />
          </button>
        </div>
      );
    } else {
      return (
        <div id="ResourceImage" className="text-center">
          <button
            className="btn-link text-white my-5"
            onClick={this.selectInputFile}
          >
            <i className="fa fa-camera fa-lg fa-3x" aria-hidden="true" />
          </button>
        </div>
      );
    }
  }

  render() {
    return (
      <Modal
        isOpen={this.props.showModal}
        onRequestClose={this.props.closeModal}
        contentLabel="Add Resource"
      >
        <div className="modal-header row">
          <div className="col-11">
            <h5 className="modal-title display-4">Add Resource</h5>
          </div>
          <div className="col-1">
            <button
              type="button"
              className="close pull-right font-200"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.props.closeModal}
            >
              <span aria-hidden="true">✕</span>
            </button>
          </div>
        </div>

        <div className="modal-content">
          <div className="modal-body" id="input-border">
            <div className="row">
              <div className="col-md-6 mx-auto">
                <div className="w-100 primary-background mb-3">
                  <TextInput
                    name={""}
                    id={"input-photo-upload"}
                    type={"file"}
                    accept={"image/*"}
                    className={"form-control-file photoInput hide"}
                    onChange={this.props.onChangePhoto}
                    placeholder={"Add a Different Photo"}
                    value={this.props.uploadPhotoName}
                    error={""}
                  />
                  {this.displayImageSelect()}
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <p className="recommended-size-text">
                      Recommended Image Size: 180px x 180px
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="resource-title">Title</label>
                      <input
                        type="text"
                        name="title"
                        className="form-control"
                        id="resource-title"
                        placeholder="Resource Title"
                        onChange={this.props.handleInputChange}
                        value={this.props.title}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="resource-desc">Description</label>
                      <textarea
                        type="text"
                        rows={3}
                        id="resource-desc"
                        name="description"
                        className="form-control "
                        placeholder="Resource Description"
                        value={this.props.description}
                        onChange={this.props.handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="type">Type</label>
                      <select
                        name="type"
                        className="form-control"
                        selected={this.props.type}
                        value={this.props.type}
                        onChange={this.props.handleResourceTypeChange}
                      >
                        <option value={""}>Select Type</option>
                        <option value={"Training"}>Training</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="resource-link">
                        Resource Button Link
                      </label>
                      <input
                        type="text"
                        name="button_url"
                        className="form-control"
                        id="resource-link"
                        placeholder="http://www.link.com"
                        onChange={this.props.handleInputChange}
                        value={this.props.button_url}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal-footer row">
          <div className="col-md-6 mx-auto">
            {this.props.isUpdate ? (
              <button
                type="button"
                className="btn btn-primary btn-block btn-op36"
                disabled={false}
                onClick={() => this.props.submitResource("update")}
              >
                Update Resource
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-primary btn-block btn-op36"
                disabled={false}
                onClick={() => this.props.submitResource("create")}
              >
                Add Resource
              </button>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

export default ResourceModal;
