import React from "react";
import "./PlayerJourneyReportLevelsProgress.scss";
import DIVISION_BADGES_GRAY from "../../../constants/divisionBadgesGray";
import DIVISION_BADGES from "../../../constants/divisionBadges";
// import DIVISION_RIBBONS from "../../../constants/ribbons";
// import DIVISIONS from "../../../constants/divisions.json";
import levelIndicator from "../../../assets/images/common/icons/indicator-icon.png";
import divisionJourneyArrow from "../../../assets/images/common/icons/division-journey-arrow.png";
import moment from "moment";

const PlayerJourneyReportLevelsProgress = (props) => {
  let badgeGrayList = [...DIVISION_BADGES_GRAY];
  let badgeList = [...DIVISION_BADGES];
  // let divisions = [...DIVISIONS];

  const { report } = props;
  // const divisionColor = divisions.filter(
  //   (division) => division.order === report.current_division
  // )[0].color;
  // const recordScore =
  //   report.my_division_journey[report.current_division - 1].record_score;

  let currentDivisionIndex = report.current_division - 1;

  let completed = 20 * (report.current_division - 1);
  completed = completed > 95 ? 95 : completed;

  const fillerStyles = {
    width: `${completed}%`,
  };

  const highestFullTeeIndex =
    currentDivisionIndex < 5 ? 5 : currentDivisionIndex;

  const divisionsBar = [
    {
      title: 'Level 1',
      yardage: '225',
      index: 0,
    },
    {
      title: 'Level 2',
      yardage: '450',
      index: 1,
    },
    {
      title: 'Level 3',
      yardage: '900',
      index: 2,
    },
    {
      title: 'Level 4',
      yardage: '1350',
      index: 3,
    },
    {
      title: 'Level 5',
      yardage: '1800',
      index: 4,
    },
    {
      title: `Full Tee (L${highestFullTeeIndex + 1})`,
      yardage: '1801+',
      index: highestFullTeeIndex,
    },
  ]

  const renderLevels = (divisionData) => {
    return divisionsBar.map(d => (
        <div className='progress-level' key={d.title}>
          {d.index === currentDivisionIndex &&
            <img
              className='progress-levels-indicator'
              src={levelIndicator}
              alt='division indicator'/>
          }
          <span className='progress-level-number'>{d.title}</span>
          <span className='progress-level-yds'>{`${d.yardage} Yds`}</span>
          <img
            className='progress-level-division-badge'
            src={
              divisionData[d.index].record_score <= 36 &&
              divisionData[d.index].record_score
                ? badgeList[d.index]
                : badgeGrayList[d.index]
            }
            alt='division'
          ></img>
          <span className='progress-level-score'>
            {(divisionData[d.index] &&
              divisionData[d.index].record_score) ||
              "--"}
          </span>
          <span className='progress-level-date'>
            {divisionData[d.index].record_date
              ? moment(divisionData[d.index].record_date).format(
                  "MM/DD/YYYY"
                )
              : "--"}
          </span>
      </div>
      )
    );
  }

  return (
    <div className='division-journey-progress-bar'>
      <div className='journey-progress-bar'>
        <div style={fillerStyles} className='journey-progress-bar-fill'></div>
      </div>
      <img
        className='progress-levels-arrow'
        src={divisionJourneyArrow}
        alt='from division to hole'/>
      <div className='progress-levels'>
        {renderLevels(report.my_division_journey)}
        {/*
          <div className='progress-level'>
            <span className='progress-level-number'>Level 1</span>
            <span className='progress-level-yds'>225 Yds</span>
            <img
              className='progress-level-division-badge'
              src={
                report.my_division_journey[0].record_score <= 36 &&
                report.my_division_journey[0].record_score
                  ? badgeList[0]
                  : badgeGrayList[0]
              }
              alt='division'
            ></img>
            <span className='progress-level-score'>
              {(report.my_division_journey[0] &&
                report.my_division_journey[0].record_score) ||
                "--"}
            </span>
            <span className='progress-level-date'>
              {report.my_division_journey[0].record_date
                ? moment(report.my_division_journey[0].record_date).format(
                    "MM/DD/YYYY"
                  )
                : "--"}
            </span>
          </div>
          <div className='progress-level'>
            <span className='progress-level-number'>Level 2</span>
            <span className='progress-level-yds'>450 Yds</span>
            <img
              className='progress-level-division-badge'
              src={
                report.my_division_journey[1].record_score <= 36 &&
                report.my_division_journey[1].record_score
                  ? badgeList[1]
                  : badgeGrayList[1]
              }
              alt='division'
            ></img>
            <span className='progress-level-score'>
              {(report.my_division_journey[1] &&
                report.my_division_journey[1].record_score) ||
                "--"}
            </span>
            <span className='progress-level-date'>
              {report.my_division_journey[1].record_date
                ? moment(report.my_division_journey[1].record_date).format(
                    "MM/DD/YYYY"
                  )
                : "--"}
            </span>
          </div>
          <div className='progress-level'>
            <span className='progress-level-number'>Level 3</span>
            <span className='progress-level-yds'>900 Yds</span>
            <img
              className='progress-level-division-badge'
              src={
                report.my_division_journey[2].record_score <= 36 &&
                report.my_division_journey[2].record_score
                  ? badgeList[2]
                  : badgeGrayList[2]
              }
              alt='division'
            ></img>
            <span className='progress-level-score'>
              {(report.my_division_journey[2] &&
                report.my_division_journey[2].record_score) ||
                "--"}
            </span>
            <span className='progress-level-date'>
              {report.my_division_journey[2].record_date
                ? moment(report.my_division_journey[2].record_date).format(
                    "MM/DD/YYYY"
                  )
                : "--"}
            </span>
          </div>
          <div className='progress-level'>
            <span className='progress-level-number'>Level 4</span>
            <span className='progress-level-yds'>1350 Yds</span>
            <img
              className='progress-level-division-badge'
              src={
                report.my_division_journey[3].record_score <= 36 &&
                report.my_division_journey[3].record_score
                  ? badgeList[3]
                  : badgeGrayList[3]
              }
              alt='division'
            ></img>
            <span className='progress-level-score'>
              {(report.my_division_journey[3] &&
                report.my_division_journey[3].record_score) ||
                "--"}
            </span>
            <span className='progress-level-date'>
              {report.my_division_journey[3].record_date
                ? moment(report.my_division_journey[3].record_date).format(
                    "MM/DD/YYYY"
                  )
                : "--"}
            </span>
          </div>
          <div className='progress-level'>
            <span className='progress-level-number'>Level 5</span>
            <span className='progress-level-yds'>1800 Yds</span>
            <img
              className='progress-level-division-badge'
              src={
                report.my_division_journey[4].record_score <= 36 &&
                report.my_division_journey[4].record_score
                  ? badgeList[4]
                  : badgeGrayList[4]
              }
              alt='division'
            ></img>
            <span className='progress-level-score'>
              {(report.my_division_journey[4] &&
                report.my_division_journey[4].record_score) ||
                "--"}
            </span>
            <span className='progress-level-date'>
              {report.my_division_journey[4].record_date
                ? moment(report.my_division_journey[4].record_date).format(
                    "MM/DD/YYYY"
                  )
                : "--"}
            </span>
          </div>
          <div className='progress-level'>
            <span className='progress-level-number'>{`Full Tee (D${
              highestFullTeeIndex + 1
            })`}</span>
            <span className='progress-level-yds'>1801+ Yds</span>
            <img
              className='progress-level-division-badge'
              src={
                report.my_division_journey[highestFullTeeIndex].record_score <=
                  36 && report.my_division_journey[highestFullTeeIndex].record_score
                  ? badgeList[highestFullTeeIndex]
                  : badgeGrayList[highestFullTeeIndex]
              }
              alt='division'
            ></img>
            <span className='progress-level-score'>
              {(report.my_division_journey[highestFullTeeIndex] &&
                report.my_division_journey[highestFullTeeIndex].record_score) ||
                "--"}
            </span>
            <span className='progress-level-date'>
              {report.my_division_journey[highestFullTeeIndex].record_date
                ? moment(
                    report.my_division_journey[highestFullTeeIndex].record_date
                  ).format("MM/DD/YYYY")
                : "--"}
            </span>
          </div>
        */}
      </div>
    </div>
  );
};

export default PlayerJourneyReportLevelsProgress;
