// Create an array of division badge images to easily import into other files

import ribbon00 from "../assets/images/ribbons/ribbon-gray.png";
import ribbon01 from "../assets/images/ribbons/ribbon-orange.png";
import ribbon02 from "../assets/images/ribbons/ribbon-yellow.png";
import ribbon03 from "../assets/images/ribbons/ribbon-green.png";
import ribbon04 from "../assets/images/ribbons/ribbon-blue.png";
import ribbon05 from "../assets/images/ribbons/ribbon-purple.png";
import ribbon06 from "../assets/images/ribbons/ribbon-orange.png";
import ribbon07 from "../assets/images/ribbons/ribbon-yellow.png";
import ribbon08 from "../assets/images/ribbons/ribbon-green.png";
import ribbon09 from "../assets/images/ribbons/ribbon-blue.png";
import ribbon10 from "../assets/images/ribbons/ribbon-purple.png";

export default [
  ribbon00,
  ribbon01,
  ribbon02,
  ribbon03,
  ribbon04,
  ribbon05,
  ribbon06,
  ribbon07,
  ribbon08,
  ribbon09,
  ribbon10
];
