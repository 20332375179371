import ReactSlider from 'rc-slider'
import 'rc-slider/assets/index.css'

const Slider = (props) => {

  return (
    <ReactSlider
      {...props}
    />
  )
}

export default Slider