import React from "react";
import { useNavigate } from "react-router-dom";
import StockProfilePic from "../../assets/images/mock/StockProfilePic.png";

import "./EnrollmentCard.scss";

const navigateTo = (id, navigate) => {
  navigate(
    `/profile/${id}`,
    { state: { userId: id } }
  );
};

const checkUserAvatar = user => {
  if (user.avatar) {
    return (
      <div className="round-image-wrapper">
        <img src={user.avatar.file_url} alt="Name" className="round-image" />
      </div>
    );
  } else {
    return (
      <div className="round-image-wrapper">
        <img src={StockProfilePic} alt="Name" className="round-image" />
      </div>
    );
  }
};

const renderPillStyle = status => {
  switch (status) {
    case "active":
      return "badge-active";
    case "applied":
      return "badge-warning";
    case "inactive":
      return "badge-danger";
    default:
      return "badge-success";
  }
};

const EnrollmentCard = ({ enrollment, isColumn, isUserCard }) => {
  const navigate = useNavigate()

  return (
    <div className={`${isColumn && "col-md-4"}`}>
      <div
        id="EnrollmentCard"
        key={"enrollmentrow" + enrollment.id}
        className={"p-2 card animation-shrink"}
        onClick={() => navigateTo(enrollment.user.id, navigate)}
      >
        {checkUserAvatar(enrollment.user)}
        <div className="enrollment-info-wrapper">
          <h4 className="enrollment-name">
            {enrollment.user.first_name + " " + enrollment.user.last_name}
          </h4>
          <strong>
            <h6 className="enrollment-course">
              {isUserCard ? enrollment.user.username : enrollment.course.name}
            </h6>
          </strong>
          {!isUserCard && (
            <h6 className="enrollment-meeting-times">
              {enrollment.course.meeting_times}
            </h6>
          )}

          <span
            className={
              "badge " +
              renderPillStyle(enrollment.status) +
              " badge-pill enrollment-pill"
            }
          >
            {enrollment.status}
          </span>
        </div>
      </div>
    </div>
  )
}

export default EnrollmentCard
