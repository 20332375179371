import moment from 'moment'

export const startIntercom = (user) => {
  window.Intercom('boot', {
    api_base: 'https://api-iam.intercom.io',
    app_id: 'pc5kj7w8',
    action_color: '#ff6633',
    user_id: user.id?.toString(),
    email: user.email,
    name: `${user.first_name} ${user.last_name}`,
    created_at: moment(user.created_at).unix(),
    user_hash: user.intercom_hash,
    user_type: 'Coach',
    lead_coach: user.lead_coach,
    program_advisor: user.communities[0].representative_name,
    total_students_in_portal: user.communities[0].student_count,
    channel_form_submitted: 'Web App',
    license_plan_type: user.communities[0].license_type,
    community_build_date: moment(user.communities[0].created_at).unix(),
  })
}

export const stopIntercom = () => {
  window.Intercom('shutdown')
}
