import React, { Component } from "react";

// CHILDREN
import Placeholder from "../../../common/Placeholder";
import NoteRow from "./NoteRow";

class CommunityNotes extends Component {
  renderNotes = () => {
    const { notes, toggleFormSection, deleteNote, toggleEditNote } = this.props;

    if (notes && notes.length === 0) {
      return (
        <div className='col-md-8 mx-auto'>
          <Placeholder
            title={"No notes have been made yet"}
            icon={"fa-clipboard"}
            subtext1={"There have been no notes created for this community."}
            subtext2={"Click the button below to create the first note."}
            buttonText={"New Note"}
            buttonIcon={"fa-plus"}
            handleClick={() => toggleFormSection("notes")}
          />
        </div>
      );
    } else {
      return notes.map(note => (
        <NoteRow
          note={note}
          key={note.id}
          deleteNote={deleteNote}
          toggleEditNote={toggleEditNote}
        />
      ));
    }
  };

  render() {
    return <div id='CommunityNotes'>{this.renderNotes()}</div>;
  }
}

export default CommunityNotes;
