import React, { Component } from "react";
import Modal from "react-modal";

import ReactToPrint from "react-to-print";
import CurriculumReportPrint from "./CurriculumReportPrint";

class CurriculumModal extends Component {
  render() {
    return (
      <Modal
        isOpen={this.props.showCurriculumModal}
        onRequestClose={this.props.closeCurriculumModal}
        contentLabel='Validation Modal'
      >
        <div className='modal-content animated fadeIn'>
          <div>
            <button
              onClick={this.props.closeCurriculumModal}
              className='confirmation-modal-close-btn btn-link'
            >
              <i className='fa fa-times confirmation-modal-close-icon' />
            </button>
            <div className='row my-3'>
              <div className='col-12'>
                <ReactToPrint
                  trigger={() => (
                    <button
                      className='btn btn-primary mt-2 ml-3'
                      onClick={e => e.preventDefault()}
                    >
                      <i
                        className='fa fa-print text-white mr-1'
                        aria-hidden='true'
                      />{" "}
                      Print
                    </button>
                  )}
                  content={() => this.componentRef}
                />
              </div>
            </div>

            <CurriculumReportPrint
              title={this.props.title}
              data={this.props.data}
              user={this.props.user}
              ref={el => (this.componentRef = el)}
              pageStyle={"print-window"}
              bodyClass={"print-body"}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

export default CurriculumModal;
