import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { components } from 'react-select'
import classNames from 'classnames'

import { setField } from '../../../../reducers/programBuilderReducer'
import Select from '../../../common/form/Select'

import styles from './ProgramSearchTags.module.scss'

/* eslint-disable react/prop-types */
const MultiValueLabel = ({ data, ...props }) => (
  <components.MultiValueLabel className={styles['multi-value-label']} {...props}>
    {data.name}
  </components.MultiValueLabel>
)

const ProgramSearchTags = () => {
  const dispatch = useDispatch()
  const { errors, searchTags, availableSearchTags } = useSelector(state => state.programBuilder)

  const selectClassNames = useMemo(() => ({
    control: () => styles.control,
    menuList: () => styles['menu-list'],
    group: () => styles.group,
    valueContainer: () => styles['value-container'],
    multiValue: () => styles['multi-value'],
    multiValueLabel: () => styles['multi-value-label'],
    multiValueRemove: () => styles['multi-value-remove'],
    indicatorsContainer: () => styles['indicator-container'],
  }), [])

  /* eslint-disable react/prop-types */
  const Option = ({ data, ...props }) => (
    <components.Option {...props} className={classNames(styles.option, { [styles['selected-option']]: searchTags.some(t => t.key === data.key) })}>
      {data.name}
    </components.Option>
  )

  const options = useMemo(() => (
    [
      { label: 'Age Groups', options: availableSearchTags.filter((tag) => tag.category === 'age_groups') },
      { label: 'Skill Level', options: availableSearchTags.filter((tag) => tag.category === 'skill_level') },
      { label: 'Gender', options: availableSearchTags.filter((tag) => tag.category === 'gender') },
      { label: 'Program Type', options: availableSearchTags.filter((tag) => tag.category === 'program_type') },
    ]
  ), [availableSearchTags])

  return (
    <section>
      <Select
        name='program-search-tags-select'
        classNames={selectClassNames}
        value={searchTags}
        options={options}
        components={{ Option, MultiValueLabel }}
        onChange={(tags) => dispatch(setField({ field: 'searchTags', value: tags }))}
        isSearchable={false}
        menuPlacement='auto'
        minMenuHeight={400}
        menuPosition='fixed'
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        error={errors.searchTagsKeys}
        isMulti
      />
    </section>
  )
}

export default ProgramSearchTags
