import * as types from "../actions/actionTypes";

// state = [] : Start out with no accounts
export default function education(state = [], action) {
  switch (action.type) {
    case types.GET_EDUCATION:
      return action.education;

    case types.UPDATE_EDUCATION:
      return Object.assign({}, state, {
        education: action.education
      });

    default:
      return state;
  }
}
