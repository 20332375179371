import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import _ from "lodash";

// CHILDREN
import SimplePhotoUploader from "../../common/SimplePhotoUploader";
import DraggableResourceCategory from "./DraggableResourceCategory";

// ASSETS
import "./ResourceSectionForm.scss";

class ReorganizeSectionsForm extends Component {
  state = {
    categories:
      this.props.resourceSection &&
      this.props.resourceSection.resource_categories
        ? this.props.resourceSection.resource_categories
        : [],
    sections: this.props.sections || [],
  };
  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const sections = this.reorder(
      this.state.sections,
      result.source.index,
      result.destination.index
    );

    let sectionsCopy = JSON.parse(JSON.stringify(sections));

    sectionsCopy.map((category, index) => {
      category.order = index;
    });

    this.setState(
      {
        sections: sectionsCopy,
      },
      () => {
        this.props.updateSections(sectionsCopy);
      }
    );
  };

  grabSectionData = (section) => {
    let sections = JSON.parse(JSON.stringify(this.state.sections));
    let sectionIndex = sections.map((s) => s.id).indexOf(section.id);

    sections[sectionIndex].name = section.name;

    this.setState({ sections });
  };

  renderSections = () => {
    const { sections } = this.state;
    const { updateSections } = this.props;

    if (sections && sections.length > 0) {
      return sections.map((section, index) => (
        <Draggable key={section.id} draggableId={`section_${section.id}`} index={index}>
          {(provided, snapshot) => (
            <div>
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <DraggableResourceCategory
                  category={section}
                  categories={sections}
                  updateCategories={updateSections}
                  sendCategoryData={this.grabSectionData}
                />
              </div>
              {/* {provided.placeholder} */}
            </div>
          )}
        </Draggable>
      ));
    }
    return null;
  };

  render() {
    const {
      formState,
      loader,
      handleInputChange,
      toggleForm,
      closeModal,
      grabSectionPhotoData,
      isEditModal,
      createResourceSection,
      updateResourceSection,
    } = this.props;
    const { sections } = this.state;
    return (
      <div id='ResourceSectionForm' className='row animated fadeIn'>
        <div className='col-md-12'>
          <p>
            So..... don't bother trying to rename the sections here. It won't
            save. Do that by clicking into the sections, then using the 'Edit
            Section' button.
          </p>
        </div>

        {sections && sections.length > 0 && (
          <div className='resource-draggable-container mt-0'>
            <h3>Arrange Sections</h3>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId='droppable'>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {sections ? this.renderSections(provided, snapshot) : null}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        )}

        {/* <div className="col-md-12 d-flex justify-content-center align-items-center mt-4">
          <button
            className="btn btn-dark m-2"
            onClick={
              isEditModal ? () => closeModal() : () => toggleForm("resource")
            }
            disabled={loader}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary m-2"
            onClick={
              isEditModal ? updateResourceSection : createResourceSection
            }
            disabled={loader}
          >
            {loader && (
              <i className="fa fa-spinner fa-spin light-loading-spinner" />
            )}
            {isEditModal
              ? `Updat${loader ? "ing" : "e"}`
              : `Add${loader ? "ing" : ""}`}{" "}
            Section
          </button>
        </div> */}
      </div>
    );
  }
}
export default ReorganizeSectionsForm;
