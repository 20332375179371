import React from "react";
import PropTypes from 'prop-types'
import CountUp from "react-countup";
// import { Circle } from "rc-progress";
import moment from "moment";
import { Line } from "react-chartjs-2";
import annotationplugin from 'chartjs-plugin-annotation'
import _ from "lodash";

import {
  Chart as ChartJS, LineElement,
} from 'chart.js';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Progress,
} from "reactstrap";

// Redux stuff
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../../../actions/userActions";

import Loader from "../../common/Op36Loader-web";
import StateSwitcher from "../../common/StateSwitcher";
import LargeMilestoneIcon from "../../common/LargeMilestoneIcon";
import Placeholder from "../../common/Placeholder";

ChartJS.register(
  annotationplugin,
  LineElement
)

class DivisionDataCard extends React.Component {
  state = {
    divisionLoader: true,
    divisionSelected: 1,
    holeCountSelected: "nine",
    nextPageCount: 10,
    playTypeSelected: "all",
    previousPageCount: 0,
    divisionDropdownOpen: false,
    sortByReverse: true,
  };

  componentDidMount() {
    this.props.actions
      .getDivisionData(this.props.user.id, 1, "all", 9)
      .then((res) => {
        if (res) this.setState({ divisionLoader: false });
      });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.activityAdded === true && this.props.activityAdded === false
      || prevProps.activityUpdated === true && this.props.activityUpdated === false
      || prevProps.activityDeleted === true && this.props.activityDeleted === false
    ) {
      this.props.actions
        .getDivisionData(
          this.props.user.id,
          this.state.divisionSelected,
          this.state.playTypeSelected,
          this.state.holeCountSelected === "nine" ? 9 : 18
        )
        .then((res) => {
          if (res)
            this.setState(
              {
                previousPageCount: 0,
                nextPageCount: 10,
              },
              () => {
                this.setState({ divisionLoader: false });
              }
            );
        });
    }
  }

  render() {
    const data = this.props.division_data;
    const { divisionSelected } = this.state;
    return (
      <div>
        <div className='player-profile-stats-topbar'>
          <div className='player-profile-stat-topbar-section-1'>
            <div className='text-center division-select-container mr-3'>
              <p>Select Level</p>
              <Dropdown
                isOpen={this.state.divisionDropdownOpen}
                toggle={this._toggleDivisionDropdown}
              >
                <DropdownToggle
                  caret
                  tag='a'
                  className='btn btn-secondary division-dropdown-button'
                >
                  {`Level ${this.state.divisionSelected}`}
                </DropdownToggle>
                <DropdownMenu
                  className={
                    this.state.divisionDropdownOpen
                      ? "division-dropdown-menu-active"
                      : ""
                  }
                >
                  <DropdownItem header>Divisions</DropdownItem>
                  <DropdownItem onClick={() => this._handleDivisionChange(1)}>
                    Level 1{' '}
                    <span className='small-yardage'>{this._getYardage(1)}</span>
                  </DropdownItem>
                  <DropdownItem onClick={() => this._handleDivisionChange(2)}>
                    Level 2{' '}
                    <span className='small-yardage'>{this._getYardage(2)}</span>
                  </DropdownItem>
                  <DropdownItem onClick={() => this._handleDivisionChange(3)}>
                    Level 3{' '}
                    <span className='small-yardage'>{this._getYardage(3)}</span>
                  </DropdownItem>
                  <DropdownItem onClick={() => this._handleDivisionChange(4)}>
                    Level 4{' '}
                    <span className='small-yardage'>{this._getYardage(4)}</span>
                  </DropdownItem>
                  <DropdownItem onClick={() => this._handleDivisionChange(5)}>
                    Level 5{' '}
                    <span className='small-yardage'>{this._getYardage(5)}</span>
                  </DropdownItem>
                  <DropdownItem onClick={() => this._handleDivisionChange(6)}>
                    Level 6{' '}
                    <span className='small-yardage'>{this._getYardage(6)}</span>
                  </DropdownItem>
                  <DropdownItem onClick={() => this._handleDivisionChange(7)}>
                    Level 7{' '}
                    <span className='small-yardage'>{this._getYardage(7)}</span>
                  </DropdownItem>
                  <DropdownItem onClick={() => this._handleDivisionChange(8)}>
                    Level 8{' '}
                    <span className='small-yardage'>{this._getYardage(8)}</span>
                  </DropdownItem>
                  <DropdownItem onClick={() => this._handleDivisionChange(9)}>
                    Level 9{' '}
                    <span className='small-yardage'>{this._getYardage(9)}</span>
                  </DropdownItem>
                  <DropdownItem onClick={() => this._handleDivisionChange(10)}>
                    Level 10{' '}
                    <span className='small-yardage'>
                      {this._getYardage(10)}
                    </span>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            <div className='text-center mr-3'>
              <p>Select Hole Count</p>

              <StateSwitcher
                options={[
                  {
                    type: "nine",
                    text: "9 Holes",
                  },
                  {
                    type: "eighteen",
                    text: "18 Holes",
                  },
                ]}
                initialTypeSelected={"nine"}
                selectedType={this.state.holeCountSelected}
                stateSwitcher={this._handleHoleCountChange}
                position={"center"}
              />
            </div>
            <div className='text-center mr-3'>
              <p>Select Play Type</p>
              <StateSwitcher
                options={[
                  {
                    type: "all",
                    text: "All",
                  },
                  {
                    type: "casual",
                    text: "Casual",
                  },
                  {
                    type: "formal",
                    text: "Formal",
                  },
                ]}
                initialTypeSelected={"all"}
                selectedType={this.state.playTypeSelected}
                stateSwitcher={this._handlePlayTypeChange}
                position={"center"}
              />
            </div>
            <div className='text-center pt-4'>
              <button
                className='btn btn-primary ml-3'
                onClick={this._refreshData}
              >
                <i className='fa fa-refresh' /> Refresh Data
              </button>
            </div>
          </div>
          {this.state.divisionLoader ? null : this.props.division_data.hole_count !== 9 ? (
            <div className='no-milestone-title-container'>
              <h4>{`Level ${this.state.divisionSelected}`}</h4>
              <h5>
                {this._renderTitle(
                  data.division.order,
                  data.division.yardage_start,
                  data.division.yardage_end
                )}
              </h5>
            </div>
          ) : (
            <div className='player-profile-stat-topbar-section-2'>
              {!this.props.division_data.record_score || this.props.division_data.record_score > 36 ? (
                <div className='milestone-badge-container'>
                  <LargeMilestoneIcon
                    division={this.props.division_data.division.order}
                    bgColor='#fff'
                    color='#f4f4f5'
                    isLocked={true}
                  />
                </div>
              ) : (
                <div className='milestone-badge-container'>
                  <LargeMilestoneIcon
                    division={this.props.division_data.division.order}
                  />
                </div>
              )}
              {this._renderProgress(this.props.division_data.record_score)}
            </div>
          )}
        </div>
        {this.state.divisionLoader ? (
          <div className='col-6 mx-auto'>
            <Loader message='loading division data' />{" "}
          </div>
        ) : (
          <div className='col-12'>{this._renderData()}</div>
        )}
      </div>
    );
  }

  _calculatePercentage = (record) => {
    if (!record) return 0;
    if (record <= 36) return 100;
    if (record <= 39) return 66;
    if (record <= 45) return 33;
    return 0;
  };

  _getColor = (div, record, target) => {
    if (record && record <= target) {
      switch (div) {
        case 1:
        case 6:
          return '#ff6633'
        case 2:
        case 7:
          return "#FDDB10";
        case 3:
        case 8:
          return "#D5DE27";
        case 4:
        case 9:
          return "#2BAAE1";
        case 5:
        case 10:
          return "#B51E8C";
        default:
          return "#414143";
      }
    } else {
      return "#f4f4f5";
    }
  };

  _renderProgress = (record) => {
    const data = this.props.division_data;
    return (
      <div className='player-profile-milestone-progress-container'>
        <h4 className='division-name-title'>
          {`Level ${this.state.divisionSelected}`}
        </h4>
        <Progress
          value={this._calculatePercentage(record)}
          className='progress-container'
          barClassName={`progress-bar-${this.state.divisionSelected}`}
        />
        <div className='circle-container'>
          <div
            className='fifty'
            style={{
              border: `2px solid ${this._getColor(
                this.state.divisionSelected,
                record,
                50
              )}`,
              color: record && record <= 50 ? "" : "#eee",
            }}
          >
            50
          </div>
          <div
            className='fortyfive'
            style={{
              border: `2px solid ${this._getColor(
                this.state.divisionSelected,
                record,
                45
              )}`,
              color: record && record <= 45 ? "" : "#eee",
            }}
          >
            45
          </div>
          <div
            className='thirtynine'
            style={{
              border: `2px solid ${this._getColor(
                this.state.divisionSelected,
                record,
                39
              )}`,
              color: record && record <= 39 ? "" : "#eee",
            }}
          >
            39
          </div>
          <div
            className='thirtysix'
            style={{
              border: `2px solid ${this._getColor(
                this.state.divisionSelected,
                record,
                36
              )}`,
              color: record && record <= 36 ? "" : "#eee",
            }}
          >
            36
          </div>
        </div>
        <h6 className='division-yardage-title'>
          {this._renderTitle(
            data.division.order,
            data.division.yardage_start,
            data.division.yardage_end
          )}
        </h6>
      </div>
    );
  };

  _getYardage = (div) => {
    const { holeCountSelected } = this.state;
    switch (div) {
      case 1:
        return holeCountSelected === "nine" ? "225 yards" : "450 yards";
      case 2:
        return holeCountSelected === "nine" ? "450 yards" : "900 yards";
      case 3:
        return holeCountSelected === "nine" ? "900 yards" : "1800 yards";
      case 4:
        return holeCountSelected === "nine" ? "1350 yards" : "2700 yards";
      case 5:
        return holeCountSelected === "nine" ? "1800 yards" : "3600 yards";
      case 6:
        return holeCountSelected === "nine"
          ? "1801 - 2300 yards"
          : "3601 - 4600 yards";
      case 7:
        return holeCountSelected === "nine"
          ? "2301 - 2600 yards"
          : "4601 - 5200 yards";
      case 8:
        return holeCountSelected === "nine"
          ? "2601 - 2900 yards"
          : "5201 - 5800 yards";
      case 9:
        return holeCountSelected === "nine"
          ? "2901 - 3200 yards"
          : "5801 - 6400 yards";
      case 10:
        return holeCountSelected === "nine" ? "3201 + yards" : "6401 + yards";
      default:
        return "";
    }
  };

  _toggleDivisionDropdown = () => {
    this.setState((prevState) => ({
      divisionDropdownOpen: !prevState.divisionDropdownOpen,
    }));
  };

  _renderRoundsTable = (data) => {
    return (
      <div className='animated fadeIn'>
        <table className='table w-100 '>
          <thead className='primary-background hidden-sm-down'>
            <tr className='text-white font-200'>
              <th
                className='font-200'
                onClick={() => this._handleSortBy(this.DATE_JOINED_KEY)}
              >
                Date {this._getSortIcon(this.DATE_JOINED_KEY, "numeric")}
              </th>
              <th
                className='font-200'
                onClick={() => this._handleSortBy(this.FORMAT_KEY)}
              >
                Format {this._getSortIcon(this.FORMAT_KEY, "alpha")}
              </th>
              <th
                className='font-200'
                onClick={() => this._handleSortBy(this.HOLES_KEY)}
              >
                Holes {this._getSortIcon(this.HOLES_KEY, "numeric")}
              </th>
              <th
                className='font-200'
                onClick={() => this._handleSortBy(this.YARDAGE_KEY)}
              >
                Yardage {this._getSortIcon(this.YARDAGE_KEY, "numeric")}
              </th>
              <th
                className='font-200'
                onClick={() => this._handleSortBy(this.LOCATION_KEY)}
              >
                Location {this._getSortIcon(this.LOCATION_KEY, "alpha")}
              </th>
              <th
                className='font-200'
                onClick={() => this._handleSortBy(this.SCORE_KEY)}
              >
                Score {this._getSortIcon(this.SCORE_KEY, "numeric")}
              </th>
              <th
                className='font-200'
                onClick={() => this._handleSortBy(this.GREENS_KEY)}
              >
                Greens {this._getSortIcon(this.GREENS_KEY, "numeric")}
              </th>
              <th
                className='font-200'
                onClick={() => this._handleSortBy(this.PUTTS_KEY)}
              >
                Putts {this._getSortIcon(this.PUTTS_KEY, "numeric")}
              </th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>{this._renderRows(data)}</tbody>
        </table>
        {this._renderPagination(data)}
      </div>
    );
  };

  _refreshData = () => {
    this.setState(
      {
        divisionLoader: true,
      },
      () => {
        this.props.actions
          .getDivisionData(
            this.props.user.id,
            this.state.divisionSelected,
            this.state.playTypeSelected,
            this.state.holeCountSelected === "nine" ? 9 : 18
          )
          .then((res) => {
            if (res) this.setState({ divisionLoader: false });
          });
      }
    );
  };

  _renderPagination(data) {
    let activities = data.rounds;
    let count = activities.length;
    if (count > 10) {
      return (
        <div className='row d-flex justify-content-flex-end'>
          <div className='col-md-6 m-0'>
            <button
              className='btn btn-dark m-0 mr-2'
              onClick={() => this._handlePaginateClick("previous")}
              disabled={this.state.previousPageCount === 0 ? true : false}
            >
              Previous
            </button>
            <button
              className='btn btn-primary m-0'
              onClick={() => this._handlePaginateClick("next")}
              disabled={this.state.nextPageCount > count ? true : false}
            >
              Next
            </button>
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }

  _handlePaginateClick(button) {
    let next = this.state.nextPageCount;
    let previous = this.state.previousPageCount;
    if (button === "next") {
      this.setState({
        nextPageCount: next + 10,
        previousPageCount: previous + 10,
      });
    } else if (button === "previous") {
      this.setState({
        nextPageCount: next - 10,
        previousPageCount: previous - 10,
      });
    }
  }

  _renderRows(data) {
    let allActivities = [...data.rounds];

    if (this.state.sortByReverse) allActivities.reverse();
    let activities = allActivities.slice(
      this.state.previousPageCount,
      this.state.nextPageCount
    );
    return activities.map((activity) => {
      return (
        <tr className='border-bottom' key={activity.id}>
          <td className='hidden-sm-down'>
            {moment(activity.activity_date).format("MM-DD-YYYY")}
          </td>
          <td className='hidden-sm-down'>{activity.play_type.name}</td>
          <td className='hidden-sm-down'>{activity.holes}</td>
          <td className='hidden-sm-down'>{activity.yardage}</td>
          <td className='hidden-sm-down'>{activity.location}</td>
          <td className='hidden-sm-down'>{activity.score}</td>
          <td className='hidden-sm-down'>
            <p
              className='round-pill'
              style={{
                color: activity.greens === 0 ? "#3b3e42" : "#fff",
                backgroundColor: activity.greens
                  ? this._renderPillColor(
                      activity.greens,
                      "greens",
                      activity.holes
                    )
                  : "white",
                textShadow: activity.greens !== 0 ? "1px 1px 1px #545454" : "",
                boxShadow: activity.greens
                  ? `.5px .5px 4px ${this._renderPillColor(
                      activity.greens,
                      "greens",
                      activity.holes
                    )}`
                  : "none",
              }}
            >
              {activity.greens}
            </p>
          </td>
          <td className='hidden-sm-down'>
            <p
              className='round-pill'
              style={{
                color: activity.putts === 0 ? "#3b3e42" : "#fff",
                backgroundColor: activity.putts
                  ? this._renderPillColor(
                      activity.putts,
                      "putts",
                      activity.holes
                    )
                  : "white",
                textShadow: activity.putts !== 0 ? "1px 1px 1px #545454" : "",
                boxShadow: activity.putts
                  ? `.5px .5px 4px ${this._renderPillColor(
                      activity.putts,
                      "putts",
                      activity.holes
                    )}`
                  : "none",
              }}
            >
              {activity.putts}
            </p>
          </td>
          {this.props.current_user === "Coach" ? (
            <td className='hidden-sm-down'>
              <button
                className='btn btn-block btn-primary m-0'
                onClick={() => this.props.toggleActivityModal(activity)}
              >
                View/Edit
              </button>
            </td>
          ) : (
            <td />
          )}
        </tr>
      );
    });
  }

  _renderPillColor(number, type, holeCount) {
    if (type === "greens") {
      if (holeCount === 9) {
        if (number >= 0 && number <= 2) {
          return "#d9534f";
        } else if (number >= 3 && number <= 5) {
          return "#FFFF00";
        } else if (number >= 6 && number <= 9) {
          return "#33d9b2";
        } else {
          return "#2b3843";
        }
      } else {
        if (number >= 0 && number <= 5) {
          return "#d9534f";
        } else if (number >= 6 && number <= 11) {
          return "#FFFF00";
        } else if (number >= 12 && number <= 18) {
          return "#33d9b2";
        } else {
          return "#2b3843";
        }
      }
    } else if (type === "putts") {
      if (holeCount === 9) {
        if (number >= 23) {
          return "#d9534f";
        } else if (number >= 16 && number <= 22) {
          return "#FFFF00";
        } else if (number >= 1 && number <= 15) {
          return "#33d9b2";
        } else {
          return "#2b3843";
        }
      } else {
        if (number >= 45) {
          return "#d9534f";
        } else if (number >= 31 && number <= 44) {
          return "#FFFF00";
        } else if (number >= 1 && number <= 30) {
          return "#33d9b2";
        } else {
          return "#2b3843";
        }
      }
    }
  }

  _getSortIcon = (sortBy, sortByType) => {
    const sortDirection = this.state.sortByReverse ? "desc" : "asc";
    const iconClass = `fa fa-sort-${sortDirection}`;
    return sortBy === this.state.sortBy ? (
      <i className={iconClass} aria-hidden='true' />
    ) : (
      <i className='fa fa-sort' aria-hidden='true' />
    );
  };

  // sort doesn't work - just reverses the chart
  _handleSortBy = (key) => {
    this.setState((prevState) => ({
      sortBy: key,
      sortByReverse: !prevState.sortByReverse,
    }));
  };

  _renderData = () => {
    const data = this.props.division_data;
    return data.rounds.length > 0 ? (
      <div>
        {/* <h4 className='text-center pt-5 pb-2 mb-0 div-desc'>
          {this._renderTitle(
            data.division.order,
            data.division.yardage_start,
            data.division.yardage_end
          )}
        </h4> */}
        <div className={data.hole_count === 9 ? "stat-group" : ""}>
          {/*the stat-group class makes room for the milestone if we're showing it*/}
          <div className='row'>
            <div className='col-2 stats-container text-center'>
              <p className='mt-3 text-center stat-title'>
                Baseline <span className='hidden-md-down'>Score</span>
              </p>
              <div className=''>
                <div className='count-container-no-icon'>
                  {/*<img className="stats-icon" alt="objective-icon" src={plusIcon} />*/}
                  <CountUp
                    className='md-percentage-count'
                    start={0}
                    end={data.baseline_score}
                    duration={2.75}
                    useEasing={true}
                    useGrouping={true}
                  />
                </div>
                <div className='empty-circle' />
              </div>
            </div>
            <div className='col-2 stats-container text-center'>
              <p className='mt-3 text-center stat-title'>
                Record <span className='hidden-md-down'>Score</span>
              </p>
              <div className=''>
                <div className='count-container-no-icon'>
                  {/*<img className="stats-icon" alt="objective-icon" src={plusIcon} />*/}
                  <CountUp
                    className='md-percentage-count'
                    start={0}
                    end={data.record_score}
                    duration={2.75}
                    useEasing={true}
                    useGrouping={true}
                  />
                </div>
                <div className='empty-circle' />
              </div>
            </div>

            <div className='col-2 stats-container text-center'>
              <p className='mt-3 text-center stat-title'>
                <span className='hidden-md-down'>Strokes</span> Lost
              </p>
              <div className=''>
                <div className='count-container-no-icon'>
                  {/*<img className="stats-icon" alt="objective-icon" src={plusIcon} />*/}
                  <CountUp
                    className='md-percentage-count'
                    start={0}
                    end={data.strokes_lost}
                    duration={2.75}
                    useEasing={true}
                    useGrouping={true}
                  />
                </div>
                <div className='empty-circle' />
              </div>
            </div>
            <div className='col-2 stats-container text-center'>
              <p className='mt-3 text-center stat-title'>
                Level <span className='hidden-md-down'>Attempts</span>
              </p>
              <div className=''>
                <div className='count-container-no-icon-circle'>
                  {/*<img className="stats-icon" alt="objective-icon" src={clockIcon} />*/}
                  <CountUp
                    className='md-percentage-count'
                    start={0}
                    end={data.rounds.length}
                    duration={2.75}
                    useEasing={true}
                    useGrouping={true}
                  />
                </div>
                <div className='empty-circle' />
                {/* <Circle =====>>>> don't have total attempts in this view without loading all the data
                    className='enrolled-circle-off'
                    percent={this.calculateAttemptPercentage(div)}
                    strokeWidth='4'
                    strokeColor='#ff6633'
                  /> */}
              </div>
            </div>
            <div className='col-2 stats-container text-center'>
              <p className='mt-3 text-center stat-title'>
                <span className='hidden-md-down'>Average</span> Greens
              </p>
              <div className=''>
                <div className='count-container-no-icon-circle'>
                  {/*<img className="stats-icon" alt="objective-icon" src={clockIcon} />*/}
                  <CountUp
                    className='md-percentage-count'
                    start={0}
                    end={data.average_greens}
                    duration={2.75}
                    useEasing={true}
                    useGrouping={true}
                  />
                </div>
                <div className='empty-circle' />
              </div>
            </div>
            <div className='col-2 stats-container text-center'>
              <p className='mt-3 text-center stat-title'>
                <span className='hidden-md-down'>Average</span> Putts
              </p>
              <div className=''>
                <div className='count-container-no-icon-circle'>
                  {/*<img className="stats-icon" alt="objective-icon" src={clockIcon} />*/}
                  <CountUp
                    className='md-percentage-count'
                    start={0}
                    end={data.average_putts}
                    duration={2.75}
                    useEasing={true}
                    useGrouping={true}
                  />
                </div>
                <div className='empty-circle' />
              </div>
            </div>
          </div>
          <div className='row chart-row'>
            <div className='col-11'>
              <div className='chart-container' id='canvas'>
                {this._renderGraph(data)}
              </div>
            </div>
          </div>
          <div className='card mt-15px'>
            <h5 className='p-2 ml-3 mt-2 card-header-text'>Rounds List</h5>
            <div className='card-block p-2'>
              <div>
                <main className='padding-base'>
                  {this._renderRoundsTable(data)}
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className='row'>
        <div className='col-2' />
        <div className='mt-5 col-8'>
          <Placeholder
            title='No rounds logged in this division yet'
            subtext1='Go play and give it a shot to get your baseline score!'
            icon='fa-flag'
          />
        </div>
        <div className='col-2' />
      </div>
    );
  };

  _renderTitle = (div, start, end) => {
    if (this.state.holeCountSelected === "nine") {
      if (div < 6) {
        return `(${start} Yards | ${start / 9} Yard Holes)`;
      } else if (div < 10) {
        return `(${start} - ${end} Yards)`;
      } else {
        return `(${start} + Yards)`;
      }
    } else {
      if (div < 6) {
        return `(${start * 2} Yards | ${start / 9} Yard Holes)`;
      } else if (div < 10) {
        return `(${start * 2 - 1} - ${end * 2} Yards)`;
      } else {
        return `(${start * 2 - 1} + Yards)`;
      }
    }
  };

  _renderGraph = (data) => {
    let graphData = [];
    let labels = [];

    data.rounds.map((round) => {
      labels.push(moment(round.activity_date).format("MM-DD-YYYY"));
      graphData.push(round.score);
    });
    // let orderData = graphData.reverse();
    // let orderLabels = labels.reverse();

    let cardChartData = {
      labels: labels,
      datasets: [
        {
          label: "Score",
          backgroundColor: this._renderBGColor(data.division.order),
          borderColor: "rgba(255,255,255,.55)",
          pointBackgroundColor: this._renderPointerColor(data.division.order),
          pointBorderColor: "transparent",
          data: graphData,
        },
      ],
    };

    let cardChartOpts = {
      maintainAspectRatio: false,
      scales: {
        x: {
          display: true,
          grid: {
            color: "white",
            zeroLineColor: "white",
          },
          ticks: {
            display: false,
            /*fontSize: 2,*/
            // fontColor: "#2b3842"
          },
        },
        y: {
          display: true,
          min: 20,
          max:
            Math.ceil(
              Math.max.apply(Math, cardChartData.datasets[0].data) / 10
            ) * 10,
          ticks: {
            display: true,
            stepSize: 10,
          },
        },
      },
      plugins: {
        legend: {
          display: true,
        },
        annotation: {
          annotations: {
            line1: {
              type: "line",
              drawTime: "afterDatasetsDraw",
              mode: "horizontal",
              scaleID: "y",
              value: this.state.holeCountSelected === "nine" ? 36 : 72,
              borderColor: "rgba(43, 56, 66, .65)",
              borderWidth: 2,
              // Line dash
              // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/setLineDash
              borderDash: [5, 5],

              // Line Dash Offset
              // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/lineDashOffset
              borderDashOffset: 5,
              label: {
                backgroundColor: "rgba(43, 56, 66, .65)",
                fontSize: 12,
                fontColor: "#fff",
                padding: {
                  x: 8,
                  y: 6
                },
                cornerRadius: 5,
                position: "center",
                xAdjust: 0,
                yAdjust: 0,
                enabled: true,
                content:
                  this.state.holeCountSelected === "nine"
                    ? "Target Score - 36"
                    : "Target Score - 72",
              },
            },
          },
        },
      },
      elements: {
        line: {
          fill: true,
          borderWidth: 1,
          tension: 0.4,
        },
        point: {
          radius: 4,
          hitRadius: 10,
          hoverRadius: 4,
        },
      },
    };

    return (
      <Line
        data={cardChartData}
        options={cardChartOpts}
        height={300}
        redraw={!this.props.divisionLoader}
      />
    );
  };

  _renderPointerColor = (div) => {
    switch (div) {
      case 1:
      case 6:
        return "rgba(246, 138, 34, 1.0)";
      case 2:
      case 7:
        return "rgba(253, 219, 16, 1.0)";
      case 3:
      case 8:
        return "rgba(213, 222, 39, 1.0)";
      case 4:
      case 9:
        return "rgba(44, 170, 225, 1.0)";
      case 5:
      case 10:
        return "rgba(181, 31, 140, 1.0)";
      default:
        return "rgba(255, 255, 255, 1.0)";
    }
  };

  _renderBGColor = (div) => {
    switch (div) {
      case 1:
        return "rgba(246, 138, 34, 0.45)";
      case 2:
        return "rgba(253, 219, 16, 0.45)";
      case 3:
        return "rgba(213, 222, 39, 0.45)";
      case 4:
        return "rgba(44, 170, 225, 0.45)";
      case 5:
        return "rgba(181, 31, 140, 0.45)";
      default:
        return "rgba(0, 0, 0, 0.45)";
    }
  };

  _handleHoleCountChange = (event) => {
    let count = event;
    this.setState({ holeCountSelected: count, divisionLoader: true }, () => {
      // if (_.isEmpty(this.state[count + "_d" + this.state.divisionSelected])) {
      this.props.actions
        .getDivisionData(
          this.props.user.id,
          this.state.divisionSelected,
          this.state.playTypeSelected,
          count === "nine" ? 9 : 18
        )
        .then((res) => {
          this.setState({ previousPageCount: 0, nextPageCount: 10 }, () => {
            this.setState({ divisionLoader: false });
          });
        });
    });
  };

  _handlePlayTypeChange = (type) => {
    this.setState({ playTypeSelected: type, divisionLoader: true }, () => {
      this.props.actions
        .getDivisionData(
          this.props.user.id,
          this.state.divisionSelected,
          type,
          this.state.holeCountSelected === "nine" ? 9 : 18
        )
        .then((res) => {
          this.setState({ previousPageCount: 0, nextPageCount: 10 }, () => {
            this.setState({ divisionLoader: false });
          });
        });
    });
  };

  _handleDivisionChange = (div) => {
    // updates the division, checks if there is already a saved state for that division, loads data if not
    this.setState({ divisionSelected: div, divisionLoader: true }, () => {
      this.props.actions
        .getDivisionData(
          this.props.user.id,
          div,
          this.state.playTypeSelected,
          this.state.holeCountSelected === "nine" ? 9 : 18
        )
        .then((res) => {
          if (res)
            this.setState({ previousPageCount: 0, nextPageCount: 10 }, () => {
              this.setState({ divisionLoader: false });
            });
        });
    });
  };
}

DivisionDataCard.propTypes = {
  division_data: PropTypes.object,
}

function mapStateToProps(state, ownProps) {
  return {
    division_data: state.userDivision.division_data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(userActions, dispatch),
  };
}

DivisionDataCard.propTypes = {
  activityAdded: PropTypes.bool,
  activityUpdated: PropTypes.bool,
  activityDeleted: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(DivisionDataCard);
