import React from 'react'
import PropTypes from 'prop-types'

// CHILDREN
import ResourceRow from '../ResourceRow'
import Placeholder from '../../common/Placeholder'

// ASSETS
import './ResourceCategoryCard.scss'

const renderResources = (category) => {
  if (category.resources && category.resources.length > 0) {
    return category.resources.map((resource) => (
      <ResourceRow resource={resource} key={resource.id} />
    ))
  }

  return (
    <div className='col-md-8 mx-auto'>
      <Placeholder
        title={'No resources listed for this category'}
        icon={'fa-paperclip'}
        subtext1={'There has been no content created for this category'}
      />
    </div>
  )
}

const ResourceCategoryCard = ({ category, triggerReorderModal }) => (
  <div id='ResourceCategoryCard' className='col-md-4'>
    <h4>
      {category.name}
      {triggerReorderModal ? (
        <span style={{ float: 'right' }}>
          <i
            style={{ cursor: 'pointer', color: '#ff6633' }}
            className='fa fa-cutlery'
            aria-hidden='true'
            onClick={() => triggerReorderModal(category.id)}
          />
        </span>
      ) : null}
    </h4>
    {renderResources(category)}
  </div>
)

ResourceCategoryCard.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  triggerReorderModal: PropTypes.func,
}

export default ResourceCategoryCard
