import { useNavigate } from 'react-router-dom';

import logo from '../../assets/images/logos/full-brand-logo.svg'
import './CommunityHeader.scss'

const CommunityHeader = ({ loginDisabled, homeDisabled }) => {
  const navigate = useNavigate()

  return (
    <div className='community-top-header'>
      <a
        href='https://operation36.golf'
        target='_blank'
      >
        <img
          src={logo}
          alt='logo'
          className='logo-container'
        />
      </a>
      <div className='nav-button-container'>
        {!homeDisabled && (
          <a href='https://operation36.golf/' className='home-link'>
            Back to Home
          </a>
        )}
        {!loginDisabled && (
          <button className='nav-login-button' onClick={() => navigate('/')}>
            Log In
          </button>
        )}
      </div>
    </div>
  )
}

export default CommunityHeader
