import React from 'react';

import './BlockShow.scss';

class BlockShow extends React.Component {
  state = { collapsed: false };
  render() {
    const { block } = this.props;
    const { collapsed } = this.state;
    return (
      <div id='BlockShow' className={collapsed ? 'collapsed' : ''}>
        <div className='blockshow-topbar'>
          <p className='duration-pill'>{`${
            block.duration > 0 ? block.duration : 0
          } min`}</p>
          <p className='blockshow-block-name'>{block.name}</p>
          <i
            className={`fa fa-chevron-up ${collapsed && 'rotated'}`}
            aria-hidden='true'
            onClick={this._toggleCollapse}
          />
        </div>
        {block.description && !collapsed && (
          <p className='blockshow-description'>{block.description}</p>
        )}
        {!collapsed && this._renderTasks()}
      </div>
    );
  }

  _toggleCollapse = () => {
    const { collapsed } = this.state;
    this.setState({ collapsed: !collapsed });
  };

  _renderTasks = () => {
    if (this.props.isBuilder) {
      return this.props.grabTasks(this.props.block.tasks);
    }
    return (
      <div className='tasks-container'>
        <p>tasks</p>
        <p>tasks</p>
        <p>tasks</p>
      </div>
    );
  };
}

export default BlockShow;
