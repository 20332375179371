import * as types from '../actions/actionTypes'

export default function views(state = [], action) {
  switch (action.type) {
    case types.GET_VIEWS:
      return Object.assign({}, state, { views: action.views })
    default:
      return state
  }
}
