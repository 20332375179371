import React from 'react';
import PropTypes from 'prop-types';
import '../../assets/styles/common/header.scss';
import { useNavigate } from 'react-router-dom';

const hasSubmit = (submit_text, onSubmitClick) => {
  if (!submit_text) {
    return ""
  }

  return (
    <div className="submitTextWrapper">
      <div className="submitText">
        <a href="#" onClick={onSubmitClick}>{submit_text}</a>
      </div>
    </div>
  )
}

const BackLink = ({ text } ) => {
  const navigate = useNavigate()
  const goBack = () => navigate(-1)

  return text ? (
    <span className="backLinkHeader" onClick={goBack}>{text}</span>
  ) : (
    <i className="fa fa-angle-left backlink-arrow" aria-hidden="true" onClick={goBack}></i>
  )
}

const Header = ({ wrapperClass, title, has_back, back_link_text, submit_text, onSubmitClick }) => {
  let leftColLength = 2
  let middleColLength = 8
  let rightColLength = 2
  
  if (back_link_text || submit_text) {
    leftColLength = 3
    middleColLength = 6
    rightColLength = 3
  }

  if (has_back) {
    return (
      <div className={wrapperClass}>
        <header className="globalHeader bar bar-nav">
          <div className="row header-container">
            <div className={"col-" + leftColLength}>
              <div className="backLink">
                <p className="text-center"><small><BackLink text={back_link_text} /></small></p>
              </div>
            </div>
            <div className={"col-" + middleColLength}>
              <h1 className="title h-overflow text-center">{title}</h1>
            </div>
            <div className={"col-" + rightColLength}>
              <div className="secondary-text">{hasSubmit(submit_text, onSubmitClick)}</div>
            </div>
          </div>
        </header>
      </div>
    )
  } else {
    return (
      <div className={wrapperClass}>
        <header className="globalHeader bar bar-nav header-container">
          <h1 className="title">{title}</h1>
        </header>
      </div>
    )
  }
};

Header.propTypes = {
  wrapperClass: PropTypes.string
};

export default Header
