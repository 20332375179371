import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as RoundedPlusIcon } from '../../../../assets/images/common/icons/rounded-plus-icon.svg'
import { ReactComponent as ResetIcon } from '../../../../assets/images/common/icons/reset-icon.svg'
import { ReactComponent as PackageIcon } from '../../../../assets/images/common/icons/package-icon.svg'
import { ReactComponent as WarningIcon } from '../../../../assets/images/common/icons/warning-icon.svg'
import { deleteAllSessions, deleteSession } from '../../../../reducers/programBuilderReducer'
import ConfirmationModal2 from '../../../common/ConfirmationModal2'
import RecurringSessionsModal from './RecurringSessionsModal'
import SingleSessionModal from './SingleSessionModal'
import CopyScheduleModal from './CopyScheduleModal'
import FieldError from '../../../common/FieldError'
import SessionCard from './SessionCard'

import './Schedule.scss'

const Schedule = () => {
  const dispatch = useDispatch()

  const errors = useSelector(state => state.programBuilder.errors)
  const sessions = useSelector(state => state.programBuilder.schedule.scheduleSessions)
  const filteredSessions = sessions.filter(s => !s.deleted)

  const [selectedSession, setSelectedSession] = useState(null)
  const [showSingleSessionModal, setShowSingleSessionModal] = useState(false)
  const [showRecurringSessionsModal, setShowRecurringSessionsModal] = useState(false)
  const [showCopyScheduleModal, setShowCopyScheduleModal] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const handleEditSession = (session) => {
    setSelectedSession(session)
    setShowSingleSessionModal(true)
  }

  const handleDeleteSession = (session) => {
    dispatch(deleteSession(session))
  }

  const handleDeleteAllSessions = () => {
    dispatch(deleteAllSessions())
    setShowConfirmationModal(false)
  }

  return (
    <section id='Schedule'>
      <p className='title'>Schedule Builder</p>
      <p> Create a schedule by adding individual sessions, recurring sessions, or copying sessions from a previous program. </p>

      {errors.scheduleSessions && <FieldError message={errors.scheduleSessions} />}

      {filteredSessions.length > 0 && (
        <>
          <div className='sessions mt-4'>
            {filteredSessions.map((session) => (
              <SessionCard key={session.index} session={session} handleEditSession={handleEditSession} handleDeleteSession={handleDeleteSession} />
            ))}
          </div>
          <div className='d-flex justify-content-between w-100 mt-3'>
            <button className='d-flex align-items-center btn btn-primary add-session-button' onClick={() => setShowSingleSessionModal(true)}>
              <RoundedPlusIcon id='sessionRoundedPlusIcon' className='mr-2' /> Add Session
            </button>
            <button className='btn btn-primary delete-all-sessions-button' onClick={() => setShowConfirmationModal(true)}>
              Delete All
            </button>
          </div>
        </>
      )}

      {filteredSessions.length === 0 && (
        <div className='row mt-4'>
          <div className='col-12 col-lg-4 mb-3 p-2'>
            <div className='session-builder-card' aria-hidden='true' onClick={() => setShowSingleSessionModal(true)}>
              <RoundedPlusIcon aria-hidden='true' id='roundedPlusIcon' />
              <p className='session-card-title'>Create New Session</p>
              <p className='session-card-description'>
                Add new sessions one by one, customize your schedule as you go!
              </p>
            </div>
          </div>
          <div className='col-12 col-lg-4 mb-3 p-2'>
            <div className='session-builder-card' aria-hidden='true' onClick={() => setShowRecurringSessionsModal(true)}>
              <ResetIcon aria-hidden='true' id='resetIcon' />
              <p className='session-card-title'>Add Recurring Sessions</p>
              <p className='session-card-description'>
                Create multiple sessions at once. It&apos;s easy - we&apos;ll automatically create them for you! Create Now, Customize Later
              </p>
            </div>
          </div>

          <div className='col-12 col-lg-4 mb-3 p-2'>
            <div className='session-builder-card' aria-hidden='true' onClick={() => setShowCopyScheduleModal(true)}>
              <PackageIcon aria-hidden='true' id='packageIcon' />
              <p className='session-card-title'>Copy from another Program</p>
              <p className='session-card-description'>
                Already made a schedule you would like to work off from? Copy a schedule from another program and customize as you see fit.
              </p>
            </div>
          </div>
        </div>
      )}

      <SingleSessionModal
        showModal={showSingleSessionModal}
        closeModal={() => { setSelectedSession(null); setShowSingleSessionModal(false) }}
        selectedSession={selectedSession}
      />

      <RecurringSessionsModal
        showModal={showRecurringSessionsModal}
        closeModal={() => { setShowRecurringSessionsModal(false) }}
      />

      {showCopyScheduleModal && (
        <CopyScheduleModal
          showModal={showCopyScheduleModal}
          closeModal={() => { setShowCopyScheduleModal(false) }}
        />
      )}

      <ConfirmationModal2
        showModal={showConfirmationModal}
        closeModal={() => setShowConfirmationModal(false)}
        onDeny={() => setShowConfirmationModal(false)}
        denyMessage='Cancel'
        onProceed={handleDeleteAllSessions}
        proceedMessage='Confirm'
        icon={<WarningIcon aria-hidden='true' id='warningIcon' />}
        title='Delete All Sessions?'
        message='This action will delete all schedule sessions associated to this program. Are you certain you want to proceed?'
      />
    </section>
  )
}

export default Schedule
