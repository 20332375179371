import React from "react";

// import ProShopHeader from "../proShopHeader/ProShopHeader";
import DivisionRecordsCard from "./divisionRecordsCard/DivisionRecordsCard";
import DIVISIONS from "../../../constants/divisions.json"; // Division yardage, name, and color
import DIVISION_BADGES from "../../../constants/divisionBadges.js"; // Division badge images

import "./DivisionRecordsBoard.scss";

const divisionRecordsBoard = (props) => {
  const TITLES = ['Standard Levels', 'Full Tee Box Levels']

  // Grab the constants for the set of 5 divisions you're looking for
  let divisionsList = props.showStandardDivs
    ? DIVISIONS.slice(0, 5)
    : DIVISIONS.slice(5);
  let badgeList = props.showStandardDivs
    ? DIVISION_BADGES.slice(0, 5)
    : DIVISION_BADGES.slice(5);

  return (
    <div className='division-records-display-area'>
      <h1 className='division-records-title'>
        Operation 36 Program - Level Records
      </h1>
      <h2 className='division-records-subtitle'>
        {props.showStandardDivs ? TITLES[0] : TITLES[1]}
      </h2>
      {divisionsList.map((division, indx) => {
        return (
          <DivisionRecordsCard
            key={division.name}
            cardNum={indx + 1}
            yardage={division.yardage}
            divName={division.name}
            color={division.color}
            badge={badgeList[indx]}
            recordScore={props.divRecords[division.id].score}
            date={
              props.divRecords[division.id].date
                ? props.divRecords[division.id].date[0].record_date
                : null
            }
            student={
              props.divRecords[division.id].user
                ? props.divRecords[division.id].user[0]
                : null
            }
          />
        );
      })}
    </div>
  );

  /*
  return (
    <div>
      <ProShopHeader pageName={props.pageName} comm={props.comm} />
      <div className="division-records-display-area">
        <h3 className="division-records-title">
          {props.showStandardDivs ? TITLES[0] : TITLES[1]}
        </h3>
        {cards}
      </div>
    </div>
  );
  */
};

export default divisionRecordsBoard;
