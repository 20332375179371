import * as types from "../actions/actionTypes";

export default function drills(state = {}, action) {
  switch (action.type) {
    case types.GET_DRILLS:
      return Object.assign({}, state, {
        public_drills: action.drills,
        possible_tasks: []
      });

    case types.GET_MY_DRILLS:
      let possible1 = state.possible_tasks ? [...state.possible_tasks] : [];

      possible1 = [...possible1, ...action.drills];

      let unique1 = Array.from(new Set(possible1.map(d => d.id))).map(id => {
        return possible1.find(d => d.id === id);
      });

      return Object.assign({}, state, {
        my_drills: action.drills,
        possible_tasks: unique1
      });

    case types.GET_MY_ACTIVITIES:
      let possible = state.possible_tasks ? [...state.possible_tasks] : [];

      possible = [...possible, ...action.activities];

      let unique = Array.from(new Set(possible.map(d => d.id))).map(id => {
        return possible.find(d => d.id === id);
      });
      return Object.assign({}, state, {
        my_activities: action.activities,
        possible_tasks: unique
      });

    case types.GET_PUBLIC_DRILLS:
      let possible2 = state.possible_tasks ? [...state.possible_tasks] : [];

      possible2 = [...possible2, ...action.drills];

      let unique2 = Array.from(new Set(possible2.map(d => d.id))).map(id => {
        return possible2.find(d => d.id === id);
      });

      return Object.assign({}, state, {
        public_drills: action.drills,
        possible_tasks: unique2
      });

    case types.GET_PUBLIC_ACTIVITIES:
      let possible3 = state.possible_tasks ? [...state.possible_tasks] : [];

      possible3 = [...possible3, ...action.activities];

      let unique3 = Array.from(new Set(possible3.map(d => d.id))).map(id => {
        return possible3.find(d => d.id === id);
      });
      return Object.assign({}, state, {
        public_activities: action.activities,
        possible_tasks: unique3
      });

    case types.GET_MY_PINNED_DRILLS:
      return Object.assign({}, state, {
        pinned_drills: action.drills.filter(d => !d.is_coach_activity),
        pinned_activities: action.drills.filter(d => d.is_coach_activity)
      });

    case types.GET_MY_FAVORITE_DRILLS:
      return Object.assign({}, state, {
        favorites: action.favorites.map(d =>
          Object.assign({}, d, { is_favorited: true })
        )
      });

    case types.GRAB_ONE_DRILL:
      return Object.assign({}, state, {
        selected_drill: action.drill
      });

    case types.PIN_DRILL:
      if (Object.keys(state).length === 0) {
        return false;
      }
      const newPin = action.drill.is_pinned;
      const is_activity = action.drill.is_coach_activity;
      let is_selected = state.selected_drill
        ? state.selected_drill.id === action.drill.id
        : false;
      let newObj = {
        [is_activity ? "pinned_activities" : "pinned_drills"]: newPin
          ? [
              action.drill,
              ...state[is_activity ? "pinned_activities" : "pinned_drills"]
            ]
          : state[is_activity ? "pinned_activities" : "pinned_drills"].filter(
              d => d.id !== action.drill.id
            ),
        [is_activity ? "my_activities" : "my_drills"]: state[
          is_activity ? "my_activities" : "my_drills"
        ].map(d => {
          if (d.id === action.drill.id) {
            return action.drill;
          } else {
            return d;
          }
        })
      };

      if (is_selected) {
        newObj["selected_drill"] = action.drill;
      }

      return Object.assign({}, state, newObj);

    case types.ARCHIVE_DRILL:
      let stateCopy = Object.assign({}, state);
      for (let key in stateCopy) {
        if (state.hasOwnProperty(key) && key !== "selected_drill") {
          stateCopy[key] = stateCopy[key].filter(d => d.id !== action.drill.id);
        }
      }
      return stateCopy;

    case types.FAVORITE_DRILL:
      let current_favs = state.favorites;
      let newObject;

      let selected = state.selected_drill
        ? state.selected_drill.id === action.drill.id
        : false;

      let current_public = state.public_drills;

      let isShowing = current_public.find(d => d.id === action.drill.id);

      if (action.status === "add") {
        newObject = {
          favorites: [
            Object.assign({}, action.drill, { is_favorited: true }),
            ...current_favs
          ]
        };
        if (selected) {
          newObject["selected_drill"] = Object.assign({}, action.drill, {
            is_favorited: true
          });
        }
        if (isShowing) {
          newObject["public_drills"] = current_public.map(d =>
            d.id === action.drill.id
              ? Object.assign({}, action.drill, { is_favorited: true })
              : d
          );
        }
      } else if (action.status === "remove") {
        newObject = {
          favorites: current_favs.filter(d => d.id !== action.drill.id)
        };
        if (selected) {
          newObject["selected_drill"] = Object.assign({}, action.drill, {
            is_favorited: false
          });
        }
        if (isShowing) {
          newObject["public_drills"] = current_public.map(d =>
            d.id === action.drill.id
              ? Object.assign({}, action.drill, { is_favorited: false })
              : d
          );
        }
      } else {
        newObject = { favorites: [...current_favs] }; // this shouldn't ever happen - api should always return either 'add' or 'remove'
      }

      return Object.assign({}, state, newObject);

    case types.TOP_DRILL_MAKERS:
      return Object.assign({}, state, {
        top_contributors: action.users
      });

    case types.GET_LESSON_PLAN_DRILLS:
      return action.drills;
    
    case types.CLEAR_SELECTED_DRILL:
      return Object.assign({}, state, {
        selected_drill: undefined
      })

    case types.GRAB_ONE_LESSON_PLAN:
      if (action.lesson_plan.tasks) {
        let possible4 = state.possible_tasks ? [...state.possible_tasks] : [];

        possible4 = [
          ...possible4,
          ...action.lesson_plan.tasks.filter(t => !!t.title)
        ];
        let unique4 = Array.from(new Set(possible4.map(d => d.id))).map(id => {
          return possible4.find(d => d.id === id);
        });
        return Object.assign({}, state, {
          possible_tasks: unique4
        });
      } else {
        return state;
      }
    default:
      return state;
  }
}
