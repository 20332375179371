import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useDispatch } from 'react-redux'
import classnames from 'classnames'

import { setCategoriesOrdered } from '../../../reducers/community-settings/landingPageSettingsReducer'

import classes from './CategoriesReorderingDnd.module.scss'
import { ReactComponent as GripDotsVertical } from '../../../assets/images/common/icons/grip-dots-vertical.svg'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const CategoryDnd = React.memo(({ category, index, isDragDisabled, onEditButtonClick }) => (
  <Draggable
    draggableId={category.id.toString()}
    index={index}
    isDragDisabled={isDragDisabled}
  >
    {(provided) => (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        className={classes['category']}
      >
        <div className={classes['left-corner']}>
          {!isDragDisabled && (
            <GripDotsVertical className={classes['grip-dots-vertical']} />
          )}
          <div style={{ backgroundColor: category.color }} className={classes['color']} />
        </div>
        <div className={classes['details-wrapper']}>
          <h5 className={classes['title']} title={category.name}>
            <span className={classes['number']}>{!isDragDisabled && `#${index + 1} `}</span>
            {category.name}
          </h5>
          <p className={classes['description']} title={category.description}>{category.description}</p>
        </div>
        {isDragDisabled && (
          <div className={classes['edit-button-wrapper']}>
            <button type='button' className={classes['edit-button']} onClick={() => onEditButtonClick(category)}>
              <i className='fa fa-edit font-weight-bold' />
            </button>
          </div>
        )}
      </div>
    )}
  </Draggable>
))

CategoryDnd.propTypes = {
  category: PropTypes.object,
  index: PropTypes.number,
  isDragDisabled: PropTypes.bool,
  onEditButtonClick: PropTypes.func,
}

CategoryDnd.displayName = 'CategoryDnd'

const CategoriesReorderingDnd = ({ isReorderingActive, categories, handleEditButtonClick }) => {
  const dispatch = useDispatch()

  const onDragEnd = useCallback((result) => {
    if (!result.destination || result.destination.index === result.source.index) {
      return
    }

    const newCategories = reorder(
      categories,
      result.source.index,
      result.destination.index
    )

    dispatch(setCategoriesOrdered(newCategories))
  }, [categories, dispatch])

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='list'>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={classnames(classes['droppable'], { [classes['drag-disabled']]: !isReorderingActive })}
          >
            {categories.map((category, index) => (
              <CategoryDnd
                category={category}
                index={index}
                key={category.id}
                isDragDisabled={!isReorderingActive}
                onEditButtonClick={handleEditButtonClick}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

CategoriesReorderingDnd.propTypes = {
  isReorderingActive: PropTypes.bool,
  categories: PropTypes.array,
  handleEditButtonClick: PropTypes.func,
}

export default CategoriesReorderingDnd
