import { createAsyncThunk } from '@reduxjs/toolkit'
import { handleAPIError } from '../utility/actionHelper'
import { serialize, deserialize } from '../utility/serialization'

import Axios from '../axios'

export const updateSeriesEnrollment = createAsyncThunk(
  'nineHoleEvent/updateSeriesEnrollment',
  async (enrollment, { rejectWithValue }) => {
    try {
      const { data } = await requestUpdateSeriesEnrollment(enrollment)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

function requestUpdateSeriesEnrollment(enrollment) {
  const requestUrl = `/api/v1/series_enrollments/${enrollment.id}`
  const data = serialize(enrollment)

  return Axios.put(requestUrl, data)
}
