import React from "react";
import Modal from "react-modal";
import ObjectiveForm from "./ObjectiveForm";

export default class ObjectiveModal extends React.Component {
  render() {
    return (
      <Modal
        id="objective-page-modal"
        isOpen={this.props.show}
        onRequestClose={this.props.onClose}
        contentLabel="Objective Modal"
      >
        <div className="modal-header row">
          <div className="col-11">
            <h5 className="modal-title display-4">Edit Objective</h5>
          </div>
          <div className="col-1">
            <button
              type="button"
              className="close pull-right font-200"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.props.close}
            >
              <span aria-hidden="true">✕</span>
            </button>
          </div>
        </div>

        <div className="modal-content">
          <div className="modal-body" id="input-border">
            <div className="row mb-2 border-bottom p-2">
              <div className="col">
                <div className="form-group">
                  <ObjectiveForm
                    objective={this.props.objective}
                    addLink={this.props.addLink}
                    removeLink={this.props.removeLink}
                    onChangeLink={this.props.onChangeLink}
                    onChangeName={this.props.onChangeName}
                    onChangeDescription={this.props.onChangeDescription}
                    onChangePoints={this.props.onChangePoints}
                    onChangeVideo={this.props.onChangeVideo}
                    onChangePhoto={this.props.onChangePhoto}
                    handleSubmit={this.props.handleSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
