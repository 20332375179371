import React from "react";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import SessionBuilder from "../../subcomponents/SessionBuilder";
import BlockContentPicker from "../../subcomponents/BlockContentPicker";
import PlaceholderPreviewCard from "../../subcomponents/PlaceholderPreviewCard";

import DrillPreviewCard from "../../subcomponents/DrillPreviewCard";
import CurriculumPreviewCard from "../../subcomponents/CurriculumPreviewCard";

import SessionPopover from "../../subcomponents/SessionPopover";
import TwoButtonModal from "../../modals/TwoButtonModal";

import "./LessonCreateSchedule.scss";

class LessonCreateSchedule extends React.Component {
  state = {
    selectedSession: 0, // index in session of array
    db: "personal", // database switcher. this isn't included in first build
    hoveringSession: -1,
    openPopover: null, // will hold index of session whose popover is open
    deleteSessionModal: -1, // same as popover - uses index to track which one you selected
    deleteBlockModal: -1, // same as popover - uses index to track which one you selected
    sessionLoader: false, // used to flash loader and reset all blocks to NOT being collapsed
  };

  componentDidMount() {
    const { sessions, addSession } = this.props;
    if (sessions.length < 1) {
      addSession();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.sessions.length < nextProps.sessions.length) {
      this.setState({ selectedSession: nextProps.sessions.length - 1 });
    }
  }

  render() {
    const {
      selectedSession,
      hoveringSession,
      openPopover,
      deleteSessionModal,
      deleteBlockModal,
      sessionLoader,
    } = this.state;
    const {
      sessions,
      addSession,
      drills,
      subjects,
      initialMyDrillsLoader,
      initialMyActivitiesLoader,
      initialPublicDrillsLoader,
      initialPublicActivitiesLoader,
      subjectLoader,
      duplicateSession,
      deleteSession,
    } = this.props;
    return (
      // <DragDropContext onDragEnd={this._handleAddTask}>
      <div id='LessonCreateSchedule'>
        <div className='builder-session-sidebar'>
          <h4>Sessions</h4>
          <DragDropContext onDragEnd={this._rearrangeSessions}>
            <Droppable droppableId={`session-area`}>
              {(provided, snapshot) => (
                <div
                  className={`session-bar-content`}
                  style={{ minHeight: `${sessions.length * 36}px` }}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {sessions.map((session, index) => {
                    return (
                      <Draggable
                        key={index}
                        draggableId={`session_${index}`}
                        index={index}
                        style={{ opacity: 1 }}
                        // onClick={() => this._selectSession(index)}
                      >
                        {(provided, snapshot) => (
                          <div>
                            <div
                              // key={index}
                              onClick={() => this._selectSession(index)}
                              className={`session-bar ${
                                index === selectedSession && "active"
                              }`}
                              onMouseEnter={() => this._hoverSession(index)}
                              onMouseLeave={() => this._hoverSession(-1)}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              {hoveringSession === index ? (
                                <i className='fa fa-bars' aria-hidden='true' />
                              ) : (
                                ""
                              )}
                              <p onClick={() => this._selectSession(index)}>
                                {session.name ? session.name : "-add name-"}
                              </p>
                              {hoveringSession === index ? (
                                <i
                                  className='fa fa-ellipsis-v'
                                  aria-hidden='true'
                                  onClick={(e) => this._openPopover(e, index)}
                                />
                              ) : (
                                ""
                              )}
                              {openPopover === index ? (
                                <div className='session-popover-container'>
                                  <SessionPopover
                                    delete={this._triggerDeleteSessionModal}
                                    duplicate={this._duplicateSession}
                                    index={index}
                                    close={this._closePopover}
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            {provided.placeholder}
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <p className='add-session-button' onClick={addSession}>
            + Add Session
          </p>
        </div>
        <DragDropContext onDragEnd={this._handleAddTask}>
          <div className='builder-content'>
            <div className='builder-block-view'>
              {selectedSession > -1 && sessions.length > 0 ? (
                <SessionBuilder
                  session={sessions[selectedSession]}
                  addBlock={this._addSessionBlock}
                  handleSessionInput={this._handleSessionInput}
                  handleBlockInput={this._handleBlockInput}
                  handleDeleteBlock={this._triggerDeleteBlock}
                  handleMoveBlock={this._handleMoveBlock}
                  grabTask={this._grabTask}
                  sessionLoader={sessionLoader}
                />
              ) : (
                <div className='no-session-placeholder'>
                  <i className='fa fa-frown-o' aria-hidden='true' />
                  <p className='session-placeholder-text'>
                    Looks like you deleted all your sessions! Add some sessions
                    to finish building your lesson plan.
                  </p>
                </div>
              )}
            </div>
            <div className='block-content-picker-section'>
              <BlockContentPicker
                // drills={drills}
                subjects={subjects}
                initialMyDrillsLoader={initialMyDrillsLoader}
                initialMyActivitiesLoader={initialMyActivitiesLoader}
                initialPublicDrillsLoader={initialPublicDrillsLoader}
                initialPublicActivitiesLoader={initialPublicActivitiesLoader}
                subjectLoader={subjectLoader}
                triggerCurriculumDetailModal={
                  this.props.triggerCurriculumDetailModal
                }
                triggerDrillDetailModal={this.props.triggerDrillDetailModal}
                allTags={this.props.allTags}
                tagLoader={this.props.tagLoader}
              />
            </div>
          </div>
        </DragDropContext>
        {deleteSessionModal > -1 && (
          <TwoButtonModal
            title={"Delete session?"}
            subtitle={`Delete "${sessions[deleteSessionModal].name}"? This cannot be undone.`}
            close={this._closeDeleteSessionModal}
            confirmText={"Delete session"}
            confirmAction={this._deleteSession}
          />
        )}
        {deleteBlockModal > -1 && (
          <TwoButtonModal
            title={"Delete block?"}
            subtitle={`Delete "${sessions[selectedSession].blocks[deleteBlockModal].name}"? This cannot be undone.`}
            close={this._closeDeleteBlockModal}
            confirmText={"Delete block"}
            confirmAction={this._handleDeleteBlock}
          />
        )}
      </div>
      // </DragDropContext>
    );
  }

  _rearrangeSessions = (result) => {
    const { rearrangeSessions } = this.props;
    if (!result.destination || result.destination === result.source) {
      return false;
    }
    const { selectedSession } = this.state;
    let old = result.source.index;
    let fresh = result.destination.index;

    if (old === selectedSession) {
      // set selected to fresh and rearrange
      this.setState({ selectedSession: fresh });
      rearrangeSessions(old, fresh);
    }
    if (old < selectedSession && fresh < selectedSession) {
      // just rearrange - one added and removed before selection
      rearrangeSessions(old, fresh);
    }
    if (old > selectedSession && fresh > selectedSession) {
      // just rearrange - one added and removed after selection
      rearrangeSessions(old, fresh);
    }
    if (old < selectedSession && fresh >= selectedSession) {
      // move selected -1 and rearrange = added before and place after
      this.setState({ selectedSession: selectedSession - 1 });
      rearrangeSessions(old, fresh);
    }
    if (old > selectedSession && fresh <= selectedSession) {
      // move selected +1 and rearrange =
      this.setState({ selectedSession: selectedSession + 1 });
      rearrangeSessions(old, fresh);
    }
  };

  _deleteSession = () => {
    const { selectedSession, deleteSessionModal } = this.state;
    const { sessions } = this.props;
    let numSessions = sessions.length;
    if (selectedSession === numSessions - 1) {
      this.setState({ selectedSession: selectedSession - 1 }, () => {
        this.props.deleteSession(deleteSessionModal);
        this.setState({ deleteSessionModal: -1 });
        this._closePopover();
      });
    } else {
      this.props.deleteSession(deleteSessionModal);
      this.setState({ deleteSessionModal: -1 });
      this._closePopover();
    }
  };

  _closeDeleteSessionModal = () => {
    this.setState({ deleteSessionModal: -1 });
  };

  _closeDeleteBlockModal = () => {
    this.setState({ deleteBlockModal: -1 });
  };

  _duplicateSession = (sessionIndex) => {
    this._closePopover();
    this.props.duplicateSession(sessionIndex);
  };
  _triggerDeleteSessionModal = (sessionIndex) => {
    this.setState({ deleteSessionModal: sessionIndex });
  };

  _triggerDeleteBlock = (blockIndex) => {
    this.setState({ deleteBlockModal: blockIndex });
  };

  _hoverSession = (index) => {
    this.setState({ hoveringSession: index });
  };

  _grabTask = (type, id, blockIndex, i) => {
    if (type === "drill") {
      let drill = this.props.drills.possible_tasks.find(
        (d) => d.id === parseInt(id, 10)
      );
      return drill ? (
        <DrillPreviewCard
          drill={drill}
          key={`${i}-${drill.id}`}
          active={true}
          removeActive={this._removeActiveTask}
          blockIndex={blockIndex}
          taskIndex={i}
          triggerDetailModal={this.props.triggerDrillDetailModal}
          moveTask={this._handleMoveTask}
        />
      ) : (
        <PlaceholderPreviewCard
          key={i}
          message={"Something happened when loading this activity"}
          active={true}
          removeActive={this._removeActiveTask}
          blockIndex={blockIndex}
          taskIndex={i}
        />
      );
    } else if (type === "subject") {
      let subject = this.props.subjects.find((s) => s.id === parseInt(id, 10));
      return subject ? (
        <CurriculumPreviewCard
          subject={subject}
          key={`${i}-${subject.id}`}
          active={true}
          removeActive={this._removeActiveTask}
          blockIndex={blockIndex}
          taskIndex={i}
          triggerDetailModal={this.props.triggerCurriculumDetailModal}
          moveTask={this._handleMoveTask}
        />
      ) : (
        <PlaceholderPreviewCard
          key={i}
          message={"Something happened when loading this objective"}
          active={true}
          removeActive={this._removeActiveTask}
          blockIndex={blockIndex}
          taskIndex={i}
        />
      );
    }
  };

  _openPopover = (e, index) => {
    e.stopPropagation();
    this.setState({ openPopover: index });
  };

  _closePopover = () => {
    this.setState({ openPopover: null });
  };

  _removeActiveTask = (event, block, taskIndex) => {
    event.stopPropagation();
    this.props.removeActiveTask(block, taskIndex, this.state.selectedSession);
  };

  _handleAddTask = (result) => {
    this.props.handleAddTask(result, this.state.selectedSession);
  };

  _handleMoveTask = (e, dir, taskIndex, blockIndex) => {
    e.stopPropagation();
    this.props.handleMoveTask(
      dir,
      taskIndex,
      blockIndex,
      this.state.selectedSession
    );
  };

  _handleSessionInput = (e) => {
    let name = e.target.name;
    let val = e.target.value;
    this.props.handleSessionInput(
      name,
      name === "duration" && val ? parseInt(val, 10) : val,
      this.state.selectedSession
    );
  };

  _handleDeleteBlock = () => {
    const { deleteBlockModal } = this.state;
    this.props.handleDeleteBlock(this.state.selectedSession, deleteBlockModal);
    this._closeDeleteBlockModal();
  };

  _handleMoveBlock = (dir, blockIndex) => {
    this.props.handleMoveBlock(dir, blockIndex, this.state.selectedSession);
  };

  _handleBlockInput = (name, val, blockIndex) => {
    this.props.handleBlockInput(
      name,
      val && name === "duration" ? parseInt(val, 10) : val,
      this.state.selectedSession,
      blockIndex
    );
  };

  _addSessionBlock = () => {
    // adds selected session index to the method and passes up to parent
    const { addBlock } = this.props;
    addBlock(this.state.selectedSession);
  };

  _selectSession = (index) => {
    this.setState({ sessionLoader: true }, () => {
      this.setState({ selectedSession: index }, () => {
        this.setState({ sessionLoader: false });
      });
    });
  };
}

export default LessonCreateSchedule;
