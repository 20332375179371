import * as constants from "./actionTypes";

import Axios from '../axios'

function successCreatingAccolade(response) {
  if (response.status === 200) {
    return {
      type: constants.CREATE_ACCOLADE,
      accolade: response.data.accolade,
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successRequestingAllAccolades(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_ALL_ACCOLADES,
      accolades: response.data.accolades,
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successRequestingCoachAccolade(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_COACH_ACCOLADES,
      accolades: response.data.accolades,
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successDestroyingAccolade(response) {
  if (response.status === 200) {
    return {
      type: constants.DESTROY_ACCOLADE,
      accolade: response.data.id,
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function createAccolade(user_id, name, cat, issued_date) {
  return function (dispatch) {
    return requestCreateAccolade(
      user_id,
      name,
      cat,
      issued_date
    ).then((response) => dispatch(successCreatingAccolade(response)));
  };
}

export function getAllAccolades() {
  return function (dispatch) {
    return requestAllAccolades().then((response) =>
      dispatch(successRequestingAllAccolades(response))
    );
  };
}

export function getCoachAccolades(user_id) {
  return function (dispatch) {
    return requestCoachAccolade(user_id).then((response) =>
      dispatch(successRequestingCoachAccolade(response))
    );
  };
}

export function destroyAccolade(accolade_id, coach_id) {
  return function (dispatch) {
    return requestDestroyAccolade(accolade_id, coach_id).then((response) =>
      dispatch(successDestroyingAccolade(response))
    );
  };
}

function requestCreateAccolade(user_id, accolade_id, issued_date) {
  let requestUrl = "/api/v1/user_accolades";
  return Axios.post(requestUrl, { user_id, accolade_id, issued_date });
}

function requestAllAccolades() {
  let requestUrl = "/api/v1/accolades";
  return Axios.get(requestUrl);
}

function requestCoachAccolade(user_id) {
  let requestUrl = "/api/v1/accolades?user_id=" + user_id;
  return Axios.get(requestUrl);
}

function requestDestroyAccolade(accolade_id, coach_id) {
  let requestUrl = `/api/v1/user_accolades/remove?user_id=${coach_id}&accolade_id=${accolade_id}`;
  return Axios.delete(requestUrl);
}
