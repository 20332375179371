import React from "react";
import _ from "lodash";

import AnnouncementRow from "./AnnouncementRow";

export default class AnnouncementTable extends React.Component {
  DATE_KEY = "created_at";

  constructor(props, context) {
    super(props, context);
    this.state = {
      sortBy: this.DATE_KEY,
      sortByReverse: true
    };
  }

  handleSortBy = key => {
    this.setState(prevState => ({
      sortBy: key,
      sortByReverse: !prevState.sortByReverse
    }));
  };

  renderRows = (announcements, sortBy, sortByReverse) => {
    if (!_.isEmpty(announcements)) {
      let announcementsWithClasses = announcements.map(a => {
        a.classes = _.map(a.courses, "name").join(", ");
        return a;
      });
      let sortedAnnouncements = _.sortBy(
        announcementsWithClasses,
        announcement => announcement[sortBy]
      );
      if (sortByReverse) sortedAnnouncements.reverse();
      return sortedAnnouncements.map((announcement, index) => (
        <AnnouncementRow
          key={announcement.id}
          announcement={announcement}
          toggleModal={this.props.toggleModal}
        />
      ));
    }
  };

  getSortIcon = (sortBy, sortByType) => {
    const sortDirection = this.state.sortByReverse ? "desc" : "asc";
    const iconClass = `fa fa-sort-${sortDirection}`;
    return sortBy === this.state.sortBy ? (
      <i className={iconClass} aria-hidden='true' />
    ) : (
      <i className='fa fa-sort' aria-hidden='true' />
    );
  };

  render() {
    const announcements = JSON.parse(JSON.stringify(this.props.announcements));
    return (
      <div className='animated fadeIn'>
        <div className='hidden-md-up '>
          <button
            onClick={() => this.handleSortBy(this.DATE_KEY)}
            className='btn float-right btn-link m-0 '
          >
            Date {this.getSortIcon(this.DATE_KEY, "numeric")}
          </button>
        </div>
        <table className='table w-100'>
          <thead className='primary-background hidden-md-down'>
            <tr className='text-white font-200'>
              {/* <th className="font-200">Type</th> */}
              <th className='font-200'>Message</th>
              <th
                className='font-200'
                onClick={() => this.handleSortBy(this.DATE_KEY)}
              >
                Date {this.getSortIcon(this.DATE_KEY, "numeric")}
              </th>
              <th className='font-200' />
            </tr>
          </thead>
          <tbody>
            {this.renderRows(
              announcements,
              this.state.sortBy,
              this.state.sortByReverse
            )}
          </tbody>
        </table>
      </div>
    );
  }
}
