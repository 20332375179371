import * as types from "../actions/actionTypes";

// state = [] : Start out with no accounts
export default function users(state = [], action) {
  switch (action.type) {
    case types.ADD_FAMILY_ENROLLMENTS:
      return action.enrollments;

    //case types.GET_COURSE: return Object.assign({}, courses, { course: action.course });
    // case types.ADD_FAMILY_ENROLLMENT:
    // 	return [...state,
    //     Object.assign({}, action.enrollment)
    //   ];

    default:
      return state;
  }
}
