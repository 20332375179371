// Create an array of division badge images to easily import into other files
import badge01 from "../assets/images/division-badges/D01-Badge.png";
import badge02 from "../assets/images/division-badges/D02-Badge.png";
import badge03 from "../assets/images/division-badges/D03-Badge.png";
import badge04 from "../assets/images/division-badges/D04-Badge.png";
import badge05 from "../assets/images/division-badges/D05-Badge.png";
import badge06 from "../assets/images/division-badges/D06-Badge.png";
import badge07 from "../assets/images/division-badges/D07-Badge.png";
import badge08 from "../assets/images/division-badges/D08-Badge.png";
import badge09 from "../assets/images/division-badges/D09-Badge.png";
import badge10 from "../assets/images/division-badges/D10-Badge.png";

const divisionBadges = [
  badge01,
  badge02,
  badge03,
  badge04,
  badge05,
  badge06,
  badge07,
  badge08,
  badge09,
  badge10
];

export default divisionBadges
