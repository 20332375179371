import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import { formatDates } from '../../../../utility/datesHelper'
import { Link } from 'react-router-dom'

import styles from './ProgramPresentationHeader.module.scss'
import globals from '../../../../assets/styles/globals.scss'
import { ReactComponent as BackArrowIcon } from './../../../../assets/images/common/icons/back-arrow-icon.svg'

const ProgramPresentationHeader = ({ className }) => {
  const course = useSelector(state => state.programPresentation.course)

  return (
    <div
      style={{
        background: course.headerImage
          ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${course?.headerImage?.originalFileUrl}) center/cover no-repeat`
          : globals.primaryNavy,
      }}
      className={classnames('p-4 flex-column', styles['program-presentation-header'], { [className]: !!className })}
    >
      {!course.previewMode &&
        <Link to={`/landing/${course.community.id}`} className={classnames('d-none d-lg-inline mb-4 animation-shrink', styles['back-arrow-button'])}>
          <BackArrowIcon />
        </Link>
      }
      <div className={classnames('pl-lg-5', styles['program-header-details-wrapper'])}>
        <h1 className={styles['program-title']} title={course.name}>{course.name}</h1>
        <h3 className={styles['program-subtitle']}>{formatDates(course.startDate, course.endDate, course.community.timezone)}</h3>
        <h3 className={classnames(styles['program-subtitle'], styles['program-meeting-times'])} title={course.meetingTimes}>{course.meetingTimes}</h3>
        <div className='mt-2 mt-lg-3 d-flex flex-wrap'>
          {course.searchTags.map(searchTag => (
            <span key={searchTag.key} className={styles['program-search-tag']}>{searchTag.name}</span>
          ))}
        </div>
      </div>
    </div>
  )
}

ProgramPresentationHeader.propTypes = {
  className: PropTypes.string,
}

export default ProgramPresentationHeader
