import React from "react";

import "./NoNearbyCommunities.scss";
import icon from "../../assets/images/common/icons/no-comm-nearby-icon.png";

// have this trigger a modal popping up from the main page rather than submitting the information here.
// use the grey-out functionality so that more than one modal cannot be up at the same time. also, should probably move the request for more information 2nd panel to a overlaying modal with the grey out functionality.
class NoNearbyCommunities extends React.Component {
  render() {
    const { handleSubmit, result } = this.props;
    return (
      <div id='NoNearbyCommunities'>
        <img src={icon} alt='oops' />
        <p>{`Whoops, no communities were found near ${result}. Maybe try another search, or click the button below to request a facility near you.`}</p>
        <button onClick={() => handleSubmit(result)}>
          Request a facility near me
        </button>
      </div>
    );
  }
}
export default NoNearbyCommunities;
