import PropTypes from 'prop-types'
import styles from './Placeholder.module.scss'
import colors from '../../../assets/styles/globals.scss'
import SubmitButton from '../../common/buttons/SubmitButton'
import { pluralize } from '../../../utility/helperFunctions'
import { ReactComponent as LeaderboardIcon } from '../../../assets/images/common/icons/leaderboard-icon.svg'

const Placeholder = ({ eventInProgress, logScores, savedScoresCount }) => (
  <div className={styles.placeholderWrapper}>
    <div className={styles.placeholderContainer}>
      <LeaderboardIcon fill={eventInProgress ? colors.primaryOrange : colors.placeholderGray} className='mb-3'/>
      <p className={styles.title}>
        {eventInProgress ? 'Scoring "In-Progress"' : 'No Leaderboard'}
      </p>

      <p className={styles.description}>
        {eventInProgress ?
          `You have ${savedScoresCount} ${pluralize('score', savedScoresCount)} logged so far.
          Continue logging your scores and complete the event to see the leaderboard.`
          :
          'Click below to begin logging scores for this event.  You can always start logging and save your progress as you go.'
        }
      </p>

      <div className={styles.logScoresButton}>
        <SubmitButton
          onClick={logScores}
          buttonMessage={eventInProgress ? 'Continue Logging' : 'Log Scores'}
          buttonColor='orange-button'
        />
      </div>
    </div>
  </div>
)

Placeholder.propTypes = {
  eventInProgress: PropTypes.bool,
  logScores: PropTypes.func.isRequired,
  savedScoresCount: PropTypes.number,
}

export default Placeholder
