import { useDispatch, useSelector } from 'react-redux'

import Input from '../../../common/form/Input'
import moment from 'moment-timezone'
import { scheduleDates, setField, addCoach, deleteCoach } from '../../../../reducers/programBuilderReducer'
import { getMomentInCommunityTimezone, getMomentInCommunityTimezoneReversed } from '../../../../utility/datesHelper'

import DatePicker from '../../../common/form/DatePicker'
import FieldError from '../../../common/FieldError'
import Toggle from '../../../common/form/Toggle'
import VisibilitySelect from './VisibilitySelect'
import LessonPlanSelect from './LessonPlanSelect'
import CoachSelect from './CoachSelect'

import './Details.scss'

/* eslint-disable max-lines-per-function*/
const Details = () => {
  const dispatch = useDispatch()

  const {
    errors,
    name,
    subtitle,
    enableProgramCapacity,
    spotsAvailable,
    startDate,
    endDate,
    enableRegistrationDates,
    registrationStartsAt,
    registrationEndsAt,
    communityCoaches,
    coaches,
  } = useSelector(state => state.programBuilder)
  const { scheduleStartDate, scheduleEndDate } = useSelector(scheduleDates)
  const timezone = useSelector(state => state.user.current_user.communities[0].timezone)

  const startDateInTimezone = startDate ? getMomentInCommunityTimezoneReversed(moment(startDate), timezone, 'YYYY-MM-DD')?.toDate() : ''
  const endDateInTimezone = endDate ? getMomentInCommunityTimezoneReversed(moment(endDate), timezone, 'YYYY-MM-DD')?.toDate() : ''
  const scheduleStartDateInTimezone = scheduleStartDate ?
    getMomentInCommunityTimezoneReversed(moment(scheduleStartDate), timezone, 'YYYY-MM-DD')?.toDate() :
    ''
  const scheduleEndDateInTimezone = scheduleEndDate ?
    getMomentInCommunityTimezoneReversed(moment(scheduleEndDate), timezone, 'YYYY-MM-DD')?.toDate() :
    ''

  const registrationStartsAtInTimezone = registrationStartsAt ?
    getMomentInCommunityTimezoneReversed(moment(registrationStartsAt), timezone, 'YYYY-MM-DD HH:mm:ss')?.toDate() :
    ''
  const registrationEndsAtInTimezone = registrationEndsAt ?
    getMomentInCommunityTimezoneReversed(moment(registrationEndsAt), timezone, 'YYYY-MM-DD HH:mm:ss')?.toDate() :
    ''

  const handleInputChange = e => {
    const field = e.target.name
    const value = e.target.value

    if ((field === 'name' || field === 'subtitle') && value.length > 100) {
      return
    }

    if (field === 'spotsAvailable' & value.length > 5) {
      return
    }

    dispatch(setField({ field, value }))
  }

  const onDatesChange = (field, value, format) => {
    dispatch(setField({ field, value: getMomentInCommunityTimezone(moment(value), timezone, format).toDate() }))
  }

  const handleAddCoach = (coach) => {
    if (coach.id > 0) {
      dispatch(addCoach(coach))
    }
  }

  const handleDeleteCoach = (coach) => {
    dispatch(deleteCoach(coach))
  }

  return (
    <section id='Details'>
      <p className='title'>General Information</p>
      <p>Please fill out the required Information below.</p>
      <div className='row'>
        <div className='col-12 col-md-6 mb-3'>
          <div className='d-flex justify-content-between align-items-start'>
            <label htmlFor='name'>
              Name <span className='required-text'>*</span>
            </label>
            <p className='character-counter m-0'>
              {`${name.length}/100 characters`}
            </p>
          </div>

          <Input
            name='name'
            type='text'
            onChange={handleInputChange}
            placeholder='Enter Program Name'
            value={name}
            error={errors.name}
          />
        </div>
        <div className='col-12 col-md-6 mb-3'>
          <div className='d-flex justify-content-between align-items-start'>
            <label htmlFor='subtitle'>
              Subtitle <span className='required-text'>*</span>
            </label>
            <p className='character-counter m-0'>
              {`${subtitle.length}/100 characters`}
            </p>
          </div>

          <Input
            name='subtitle'
            type='text'
            onChange={handleInputChange}
            placeholder='Example: Wednesday, 2:00 - 3:00 PM'
            value={subtitle}
            error={errors.subtitle}
          />
        </div>

        <div className='col-12 col-md-6'>
          <div className='row'>
            <div className='col-12 col-md-6 mb-3'>
              <label htmlFor='dates'>
                Start Date <span className='required-text'>*</span>
              </label>

              <DatePicker
                selected={scheduleStartDateInTimezone || startDateInTimezone}
                onChange={(date) => onDatesChange('startDate', date, 'YYYY-MM-DD')}
                maxDate={endDateInTimezone}
                disabled={scheduleStartDateInTimezone !== ''}
                error={errors.startDate}
              />
            </div>
            <div className='col-12 col-md-6 mb-3'>
              <label htmlFor='dates'>
                End Date <span className='required-text'>*</span>
              </label>

              <DatePicker
                selected={scheduleEndDateInTimezone || endDateInTimezone}
                onChange={(date) => onDatesChange('endDate', date, 'YYYY-MM-DD')}
                minDate={startDateInTimezone}
                disabled={scheduleEndDateInTimezone !== ''}
                error={errors.endDate}
              />
            </div>
          </div>
        </div>

        <div className='col-12 col-md-6 mb-3'>
          <label htmlFor='privacy'>
            Visibility <span className='required-text'>*</span>
          </label>

          <VisibilitySelect />
        </div>

        <div className='col-12 mb-4'>
          <label htmlFor='lessonPlan'>Lesson Plan</label>

          <LessonPlanSelect />
        </div>

        <div className='col-12 mb-3'>
          <label htmlFor='coaches' className='mb-3'>Select Coaches</label>

          <CoachSelect
            communityCoaches={communityCoaches}
            coaches={coaches}
            errors={errors}
            onAddCoach={handleAddCoach}
            onDeleteCoach={handleDeleteCoach}
          />
        </div>

        <div className='col-12 mb-3'>
          <p className='labelTitle mb-3'>Optional Settings</p>

          <div className='row'>
            <div className='col-12 col-md-6'>
              <div className='d-flex align-items-center mb-3'>
                <Toggle
                  name='enableProgramCapacity'
                  checked={enableProgramCapacity}
                  onChange={() => dispatch(setField({ field: 'enableProgramCapacity', value: !enableProgramCapacity }))}
                />
                <label htmlFor='enableProgramCapacity' className='ml-3 mb-0'>Enable Program Capacity</label>
              </div>

              {enableProgramCapacity && (
                <div className='row mb-3'>
                  <label htmlFor='spotsAvailable' className='col-12'>
                    Capacity <span className='required-text'>*</span>
                  </label>

                  <div className='d-flex align-items-center'>
                    <div className='col-6 col-sm-4'>
                      <Input
                        name='spotsAvailable'
                        type='number'
                        onChange={handleInputChange}
                        value={spotsAvailable}
                        styles={errors.spotsAvailable && { inputContainer: { borderColor: '#cc0000' } }}
                      />
                    </div>
                    <div className='col-6 col-sm-8 pl-0'>
                      Spots Available
                    </div>
                  </div>


                  {errors.spotsAvailable && (
                    <div className='col-12'>
                      <FieldError message={errors.spotsAvailable} />
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className='col-12 col-md-6'>
              <div className='d-flex align-items-center mb-3'>
                <Toggle
                  name='enableRegistrationDates'
                  checked={enableRegistrationDates}
                  onChange={() => dispatch(setField({ field: 'enableRegistrationDates', value: !enableRegistrationDates }))}
                />
                <label htmlFor='enableRegistrationDates' className='ml-3 mb-0'>Enable Registration Dates</label>
              </div>

              {enableRegistrationDates && (
                <div className='row'>
                  <div className='col-12 col-md-6 mb-3'>
                    <label htmlFor='registration_starts_at'>
                      Open Date <span className='required-text'>*</span>
                    </label>

                    <DatePicker
                      selected={registrationStartsAtInTimezone}
                      onChange={(date) => onDatesChange('registrationStartsAt', date, 'YYYY-MM-DD HH:mm:ss')}
                      maxDate={registrationEndsAtInTimezone}
                      error={errors.registrationStartsAt}
                      dateFormat='MM/dd/yyyy h:mm aa'
                      timeIntervals={15}
                      showTimeSelect
                    />
                  </div>
                  <div className='col-12 col-md-6 mb-3'>
                    <label htmlFor='registration_ends_at'>
                      Close Date <span className='required-text'>*</span>
                    </label>

                    <DatePicker
                      selected={registrationEndsAtInTimezone}
                      onChange={(date) => onDatesChange('registrationEndsAt', date, 'YYYY-MM-DD HH:mm:ss')}
                      minDate={registrationStartsAtInTimezone}
                      error={errors.registrationEndsAt}
                      dateFormat='MM/dd/yyyy h:mm aa'
                      timeIntervals={15}
                      showTimeSelect
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Details
