import classnames from 'classnames'
import { useEffect } from 'react'

import * as stripeConnectActions from '../../../actions/stripeConnectActions'
import LoadingSpinner from '../../common/LoadingSpinner'

import classes from './ConnectStripeRefreshPage.module.scss'

const ConnectStripeRefreshPage = () => {
  useEffect(() => {
    refreshStripe()
  }, [])

  const refreshStripe = () => {
    stripeConnectActions.refreshConnectToStripe()
      .then((res) => window.location.replace(res.data.account_link_url))
      .catch((err) => console.log('Something went wrong: ', err))
  }

  return (
    <LoadingSpinner
      text='Redirecting to Stripe...'
      classNames={{
        wrapper: 'h-100',
        spinner: classnames('fa-4x', classes.spinner),
      }}
    />
  )
}

export default ConnectStripeRefreshPage
