import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'

import { getCommunityLandingPageSettings } from '../../../actions/communitiesActions'
import { selectIsInitialStateModified } from '../../../reducers/community-settings/landingPageSettingsReducer'
import SettingsForm from './SettingsForm'
import Loader from '../../common/Op36Loader-web'
import SubmitButton from '../../common/buttons/SubmitButton'

import classes from './CommunityLandingPageTab.module.scss'

const Footer = () => {
  const isInitialStateModified = useSelector(selectIsInitialStateModified)
  const isLoading = useSelector(state => state.communitySettings.landingPage.isLoadingUpdate)

  return (
    <div className={classnames('d-flex justify-content-center align-items-center', classes['footer'])}>
      <SubmitButton
        disabled={!isInitialStateModified || isLoading}
        loading={isLoading}
        noTopMargin
        className={classnames(classes['save-button'])}
        buttonMessage='Save Changes'
        form='landingPageSettings'
      />
    </div>
  )
}

const CommunityLandingPageTab = () => {
  const dispatch = useDispatch()

  const currentUser = useSelector(state => state.user.current_user)
  const { isLoadingPage, community } = useSelector(state => state.communitySettings.landingPage)

  useEffect(() => {
    dispatch(getCommunityLandingPageSettings(currentUser.communities[0].id))
  }, [currentUser.communities, dispatch])

  if (!community || isLoadingPage) {
    return <Loader message='Loading Community Landing Page Data...' />
  }

  return (
    <>
      <div className={classnames('py-4', classes['landing-page-tab'])}>
        <SettingsForm />
      </div>
      <Footer />
    </>
  )
}

export default CommunityLandingPageTab
