import { useSelector } from 'react-redux'
import SignIn from '../auth/SignIn'
import UpgradePrompt from '../common/UpgradePrompt'
import ProShopDisplay from './ProShopDisplay'

const ProShopPage = () => {
  const user = useSelector(state => state.user.current_user)
  const isCoach = user.type === 'Coach'

  if (isCoach && !user.user_settings) {
    return <SignIn />
  } else if (isCoach && user.user_settings.coach_permissions < 1) {
    return <UpgradePrompt />
  } else if (isCoach) {
    return <ProShopDisplay />
  } else {
    return <UpgradePrompt />
  }
}

export default ProShopPage
