import React from "react";

// REDUX BITS
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as familyActions from "../../actions/familyActions";
import * as authActions from "../../actions/authActions";
import * as commonActions from "../../actions/commonActions";
import PropTypes from 'prop-types'

// DEPENDENCIES
import _ from "lodash";
import "../../assets/styles/components/classes/classDetailPage.scss";
// import profile_avatar from '../../assets/images/mock/StockProfilePic.png';
import "./FamilyDetailPage.scss";

// CHILDREN
import StudentCard from "../common/StudentCard";
import Loader from "../common/Op36Loader-web";
import Placeholder from "../common/Placeholder";
import FamilyEditModal from "./family_modals/FamilyEditModal.js";
import PageHeader from '../common/PageHeader/PageHeader'
import ValidationModal from "../common/ValidationModal";

// import FamilyIcon from '../common/page-header/icons/families.png';

import "./Families.scss";
import { withRouting } from "../common/hooks";

class FamilyDetailPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      confirm_password: "",
      showEditModal: false,
      showAddModal: false,
      alertType: "success",
      alertTitle: "",
      alertMessage: "",
      editFamily: true,
      loading: false,
      familyId: this.props.location.state?.familyId || this.props.params.familyId,
      familyLoader: true,
      passwordLoader: false,
      showValidationModal: false,
      validationSuccess: true,
      validationMessage: "",
      showCoppaPopover: false
    };

    this.toggleEditModal = this.toggleEditModal.bind(this);
    this.toggleValidationModal = this.toggleValidationModal.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
  }

  componentDidMount() {
    const { common_actions } = this.props;
    common_actions.setCurrentTab("FamilyDetail");
    common_actions.showSideBar(true);
    common_actions.showNavBar(true);
    let familyId = this.state.familyId;
    this.props.family_actions.getFamily(familyId).then(() => {
      this.setState({ familyLoader: false });
    });
  }

  handleInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  toggleCoppaPopover = () => {
    this.setState({
      showCoppaPopover: !this.state.showCoppaPopover
    });
  };

  toggleEditModal() {
    this.setState(prevState => {
      return { showEditModal: !prevState.showEditModal };
    });
  }

  toggleValidationModal(success, message) {
    this.setState({
      showValidationModal: true,
      validationMessage: message,
      validationSuccess: success
    });
  }

  closeValidationModal = () => {
    this.setState({
      showValidationModal: false
    });
  };

  handleResetPassword() {
    let family = this.props.family;
    let familyAdmin;

    // Get family points
    if (family.users) {
      let members = family.users;
      members.forEach(user => {
        if (user.family_admin) {
          familyAdmin = user;
        }
      });
    }

    let email = familyAdmin.email;
    this.setState({
      passwordLoader: true
    });
    this.props.auth_actions
      .triggerResetPassword(email)
      .then(response => {
        this.setState({
          resetEmailSent: true,
          passwordLoader: false,
          showValidationModal: true,
          validationSuccess: true,
          validationMessage: "The family password has been reset"
        });
        this.toggleEditModal();
      })
      .catch(err => {
        this.setState({
          resetEmailSent: true,
          passwordLoader: false,
          showValidationModal: true,
          validationSuccess: false,
          validationMessage:
            "There was a problem sending password-reset email, please try again"
        });
        this.toggleEditModal();
      });
  }

  renderStudents = () => {
    const { family } = this.props;
    const familyCopy = JSON.parse(JSON.stringify(family));
    const { familyLoader } = this.state;
    if (familyLoader) return <Loader message={"loading family"} />;
    if (family && family.users.length > 0) {
      let sortedFamily = familyCopy.users.sort((a, b) => a.id - b.id);
      if (sortedFamily.length > 0) {
        return sortedFamily.map(student => {
          return (
            <StudentCard student={student} key={student.id} />
          );
        });
      } else {
        return (
          <div className='mx-auto'>
            <Placeholder
              title={"No Golfers Listed Yet..."}
              icon={"fa-user"}
              subtext1={"There are currently no golfers listed in this family"}
            />
          </div>
        );
      }
    }
    return (
      <div className='mx-auto'>
        <Placeholder
          title={"No Golfers Listed Yet..."}
          icon={"fa-user"}
          subtext1={"There are currently no golfers listed in this family"}
        />
      </div>
    );
  };

  render() {
    let family = this.props.family;
    let familyAdmin;
    let familyPoints = this.props.family.points;

    // Get family admin
    if (family.users) {
      let members = family.users;

      members.forEach(user => {
        if (user.family_admin) {
          familyAdmin = user;
        }
        // familyPoints += user.points;
      });
    }
    return (
      <div id='FamiliesPage'>
        <PageHeader title={`${family.name}`} headerIcon='fa fa-group' />

        <div className='card mb-2 family-admin-container mt-15px'>
          <div className='row p-2 ml-2'>
            <div className='col-md-3'>
              <small className='family-admin-header'>ADMIN NAME</small>
              <h2 className='family-admin-detail'>
                {familyAdmin && familyAdmin.first_name
                  ? `${familyAdmin.first_name} ${familyAdmin.last_name}`
                  : "not listed"}
              </h2>
            </div>
            <div className='col-md-3'>
              <small className='family-admin-header'>ADMIN EMAIL</small>
              <h2 className='family-admin-detail'>
                {familyAdmin && familyAdmin.email
                  ? familyAdmin.email
                  : "not listed"}
              </h2>
            </div>
            <div className='col-md-3'>
              <small className='family-admin-header'>Phone Number</small>
              <h2 className='family-admin-detail'>
                {familyAdmin && familyAdmin.phone
                  ? familyAdmin.phone
                  : "not listed"}
              </h2>
            </div>
            <div className='col-md-3 hidden-sm-down'>
              <small className='family-admin-header'>TOTAL POINTS</small>
              <h2 className='secondary-text family-admin-points'>
                {familyPoints}
                <sup>pt</sup>
              </h2>
            </div>
          </div>
        </div>

        <div className='row content-row'>{this.renderStudents()}</div>

        <FamilyEditModal
          toggleEditModal={this.toggleEditModal}
          showEditModal={this.state.showEditModal}
          family={family}
          toggleValidationModal={this.toggleValidationModal}
          handleInputChange={this.handleInputChange}
          handleResetPassword={this.handleResetPassword}
          showSuccess={this.showSuccess}
        />

        <ValidationModal
          validationSuccess={this.state.validationSuccess}
          showValidationModal={this.state.showValidationModal}
          closeValidationModal={this.closeValidationModal}
          validationMessage={this.state.validationMessage}
        />
        {/* <CoppaPopover // Commented because its target is not rendered anywhere
          id={1}
          showPopover={this.state.showCoppaPopover}
          togglePopover={this.toggleCoppaPopover}
        /> */}
      </div>
    );
  }
}

FamilyDetailPage.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      familyId: PropTypes.number,
    }),
  }),
  params: PropTypes.shape({
    familyId: PropTypes.string,
  }),
  family: PropTypes.shape({
    name: PropTypes.string,
  }),
}

function mapStateToProps(state, ownProps) {
  return {
    ui: state.ui,
    user: state.user.current_user,
    family: state.family
  };
}

function mapDispatchToProps(dispatch) {
  return {
    common_actions: bindActionCreators(commonActions, dispatch),
    family_actions: bindActionCreators(familyActions, dispatch),
    auth_actions: bindActionCreators(authActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouting(FamilyDetailPage))
