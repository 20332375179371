import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal'
import reportWebVitals from './reportWebVitals';

import MainApp from './App'
import MaintenancePage from './components/maintenance/MaintenancePage'
import './assets/styles/index.scss'
import './index.scss';

const App = process.env.REACT_APP_MAINTENANCE ? MaintenancePage : MainApp

Modal.setAppElement('#root')

ReactDOM.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>,
  <App />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
