import * as types from "../actions/actionTypes";
import update from "immutability-helper";
import representativeActionTypes from "./representativeActionTypesReducer";
const initState = {
  communities: [],
  stats: {}
}

export default function communities(state = initState, action) {
  switch (action.type) {
    case types.ADD_COMMUNITIES:
      return {
        ...state,
        communities: action.communities
    }

    case types.POST_COMMUNITY:
      return {
        ...state,
        communities: [...state.communities, action.community]
      }

    case types.UPDATE_COMMUNITY:
      const currentCommunityIndex = state.communities.map(c => c.id).indexOf(action.community.id)

      return update(state, {
        communities:{[currentCommunityIndex]: { $set: action.community }},
      });

    case types.GET_COMMUNITY_STATS:
      return Object.assign({}, state, {
        stats: action.stats,
      });

    case types.GET_COMMUNITY_PIPELINE_STATS:
      return Object.assign({}, state, {
        pipeline_stats: action.pipeline_stats,
      });

    case types.GET_COMMUNITY_STUDENTS_DATES:
      return Object.assign({}, state, { student_dates: action.student_dates });
    case types.GET_COMMUNITIES_PAGINATED:
      return { ...state, communities: action.communities }
    default:
      return state;
  }
}
