import React, { Component } from "react";
import moment from "moment";

// CHILDREN
import Toast from "../../../common/Toast";

// ASSETS
import "./FlagRow.scss";

class FlagRow extends Component {
  render() {
    const { flag, deleteFlag, toggleEditFlag } = this.props;
    return (
      <div id='FlagRow'>
        <div className='note-header'>
          <div className='note-info-wrapper'>
            <h4>
              <span>Note:</span> {flag.note}
            </h4>
          </div>
          <div className='note-date-container'>
            <p className='created-at'>{moment(flag.created_at).fromNow()}</p>
            <div className='note-action-container'>
              <button
                className='note-edit'
                onClick={() => toggleEditFlag(flag)}
              >
                <i className='fa fa-pencil-square-o' />
              </button>
              <Toast
                type={"warn"}
                position={"top_center"}
                message={`Are you sure? This will delete the flag created by ${
                  flag.representative.first_name
                } ${flag.representative.last_name}`}
                autoClose={false}
                action={() => deleteFlag(flag.id)}
                actionText={"Yes, Delete Flag"}
              >
                <button className='note-delete'>
                  <i className='fa fa-trash-o' />
                </button>
              </Toast>
            </div>
          </div>
        </div>

        <div className='note-container'>
          <p>
            Flag created by:{" "}
            <span>
              {flag.representative.first_name} {flag.representative.last_name}
            </span>
          </p>
          <p
            className='resolved-pill'
            style={{ backgroundColor: this._renderResolvedColor() }}
          >
            {this._renderIsResolved()}
          </p>
          <p className='flag-type-pill'>{flag.flag_type}</p>
        </div>
      </div>
    );
  }

  _renderIsResolved = () => {
    const {
      flag: { is_resolved }
    } = this.props;

    if (is_resolved) {
      return "Resolved";
    }
    return "Unresolved";
  };

  _renderResolvedColor = () => {
    const {
      flag: { is_resolved }
    } = this.props;

    if (is_resolved) {
      return "#33d9b2";
    }
    return "#ed213a";
  };
}

export default FlagRow;
