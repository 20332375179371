import React, { Component } from "react";
import ReactPlayer from "react-player";

import "./Goals.scss";

const styles = {
  video: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%"
  }
};

class CurriculumGoalView extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  renderButtonLinks = () => {
    if (this.props.objective.links.length > 0) {
      return this.props.objective.links.map(link => {
        return (
          <a
            className="btn btn-primary objective-btn-link"
            href={link.attachment_url}
            alt={link.name}
          >
            {link.name}
          </a>
        );
      });
    } else {
      return null;
    }
  };

  render() {
    return (
      <div className="container curriculum-container">
        <div className="custom-goal-header">
          <button
            className="mb-3 back-curriculms-btn"
            onClick={this.props.decrementPage}
          >
            <i
              className="fa fa-arrow-left back-curriculms-icon"
              aria-hidden="true"
            />
          </button>
          <div className="custom-goal-header-info-wrapper curriculum-wrapper">
            <div className="custom-goal-header-wrapper curriculum-header-wrapper">
              <div className="custom-goal-header-title-wrapper curriculum-title-wrapper">
                <h4 className="custom-goal-header-title curriculum-header-title">
                  {this.props.objective.name}
                </h4>
                <i
                  className="fa fa-trophy objective-view-points-icon"
                  aria-hidden="true"
                />
                <h3 className="objective-view-points">
                  {this.props.objective.points} pts
                </h3>
              </div>
              <p className="custom-goal-description">
                {this.props.objective.description}
              </p>
            </div>
          </div>
        </div>

        <div className="curriculum-video-detail-conatiner">
          <div className="detail-video-wrapper">
            <ReactPlayer
              url={this.props.objective.video.attachment_url}
              style={styles.video}
              width="100%"
              height="100%"
              controls
            />
          </div>
          <div className="objective-view-desc-wrapper">
            {this.renderButtonLinks()}
          </div>
        </div>
      </div>
    );
  }
}

export default CurriculumGoalView;
