import classNames from 'classnames'
import PropTypes from 'prop-types'
import Modal from 'react-modal'

import SubmitButton from '../../../../common/buttons/SubmitButton'
import { ReactComponent as CompletedIcon } from '../../../../../assets/images/common/icons/check-circle-white.svg'
import { ReactComponent as ModalXIcon } from '../../../../../assets/images/common/icons/modal-X-icon.svg'
import './ApproveEnrollmentModal.scss'
import Input from '../../../../common/form/Input'

const ApproveEnrollmentModal = ({ showModal, closeModal, coachMessage, handleCoachMessageChange, handleApproveEnrollment }) => (
  <Modal
    isOpen={showModal}
    onRequestClose={closeModal}
    contentLabel='Approve Enrollment Modal'
    className={classNames('approve-enrollment-modal-content')}
    autoFocus
  >
    <div id='ApproveEnrollmentModal'>
      <div className='animated fadeIn'>
        <div className='d-flex justify-content-end text-align-right'>
          <ModalXIcon id='modalXIcon' onClick={closeModal} />
        </div>
        <div className='d-flex flex-column justify-content-center align-items-center'>
          <CompletedIcon id='completedIcon' />
          <p className='title'>Approve Enrollment</p>
          <p className='sub-title mt-2'>Approve this enrollment and send a custom message to the family!</p>
          <Input
            name='description'
            type='textarea'
            onChange={handleCoachMessageChange}
            placeholder='Write a custom message for the family! (optional)'
            value={coachMessage}
            classNames={{ inputContainer: 'coachMessageInputContainer', inputContainerWrapper: 'mt-3 coachMessageWrapper' }}
          />
        </div>
        <div>
          <div className='row py-3'>
            <div className='col-12'>
              <SubmitButton
                onClick={handleApproveEnrollment}
                buttonMessage='Approve Enrollment'
                buttonColor='orange-button'
              />
            </div>
            <div className='col-12'>
              <SubmitButton
                onClick={closeModal}
                buttonMessage='Cancel'
                buttonColor='navy-button'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
)

ApproveEnrollmentModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  coachMessage: PropTypes.string,
  handleCoachMessageChange: PropTypes.func.isRequired,
  handleApproveEnrollment: PropTypes.func.isRequired,
}

export default ApproveEnrollmentModal
