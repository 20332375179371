import React from 'react'
import Axios from 'axios'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { baseUrl } from '../community-map/baseUrl'
import { isValidEmail } from '../../utility/helperFunctions'
import Modal from '../common/Modal'

import classes from './CommunityContactForm.module.scss'

class CommunityContactForm extends React.Component {
  state = {
    name: "",
    email: "",
    phone: "",
    fam_count: 1,
    note: "",
    formSubmitted: false,
    submitClicked: false,
    failedValidation: false,
    interests: [],
    skillLevel: [],
    invalidEmail: false,
    emailOptIn: true,
  };
  render() { //eslint-disable-line max-lines-per-function, complexity
    return (
      <div className={classes.communityContactForm}>
        <p className={classes.reqFormTitle}>Request Program Information</p>
        <label
          htmlFor='req-form-name'
          className={classnames({ [classes.redText]: this.state.failedValidation })}
        >
          Name
        </label>
        <input
          name='name'
          type='text'
          id='req-form-name'
          placeholder='Enter name'
          value={this.state.name}
          onChange={this._handleInputChange}
          autoComplete='name'
          className={classnames({ [classes.redLine]: this.state.failedValidation })}
          maxLength='35'
        />
        <label
          htmlFor='req-form-email'
          className={classnames({ [classes.redText]: this.state.failedValidation })}
        >
          Email
        </label>
        <input
          name='email'
          type='email'
          id='req-form-email'
          placeholder='Enter email'
          value={this.state.email}
          onChange={this._handleInputChange}
          autoComplete='email'
          className={classnames({ [classes.redLine]: this.state.failedValidation })}
          maxLength='60'
        />
        <label
          htmlFor='req-form-phone'
          className={classnames({ [classes.redText]: this.state.failedValidation })}
        >
          Phone number (required)
        </label>
        <input
          name='phone'
          type='tel'
          id='req-form-phone'
          placeholder='Enter phone number'
          value={this.state.phone}
          onChange={this._handleInputChange}
          autoComplete='tel'
          className={classnames({ [classes.redLine]: this.state.failedValidation })}
          maxLength='15'
        />
        <p className={classes.reqFormFamilyCount}>
          Number of interested family members:
        </p>
        <div className={classes.familyCountBox}>
          {[1, 2, 3, 4, 5].map((nr) => (
            <button
              className={classes.radioContainer}
              key={nr}
              onClick={() => this._changeFamilyCount(nr)}
            >
              <div className={classes.radioOuter}>
                {this.state.fam_count === nr && (
                  <div className={classes.radioInner} />
                )}
              </div>
              <p className={classes.radioLabel}>{nr}{nr === 5 ? '+' : ''}</p>
            </button>
          ))}
        </div>
        <div className={classes.checkboxArea}>
          <div className={classes.checkboxContainer}>
            <p className={classnames(classes.checkBoxTitle, { [classes.redText]: this.state.failedValidation })}>
              Interests:
            </p>
            <div className={classes.checkRow}>
              <div
                className={classes.checkBox}
                onClick={() => this._handleCheckBox('interests', 'private')}
                onKeyDown={() => this._handleCheckBox('interests', 'private')}
                role='checkbox'
                tabIndex={0}
                aria-checked='false'
              >
                {this.state.interests.indexOf('private') > -1 ? (
                  <i className='fa fa-check' aria-hidden='true' />
                ) : null}
              </div>
              <p className={classes.checkLabel}>Private Lesson</p>
            </div>
            <div className={classes.checkRow}>
              <div
                className={classes.checkBox}
                onClick={() => this._handleCheckBox('interests', 'adult')}
                onKeyDown={() => this._handleCheckBox('interests', 'private')}
                role='checkbox'
                tabIndex={0}
                aria-checked='false'
              >
                {this.state.interests.indexOf('adult') > -1 ? (
                  <i className='fa fa-check' aria-hidden='true' />
                ) : null}
              </div>
              <p className={classes.checkLabel}>Adult programs</p>
            </div>
            <div className={classes.checkRow}>
              <div
                className={classes.checkBox}
                onClick={() => this._handleCheckBox('interests', 'junior')}
                onKeyDown={() => this._handleCheckBox('interests', 'private')}
                role='checkbox'
                tabIndex={0}
                aria-checked='false'
              >
                {this.state.interests.indexOf('junior') > -1 ? (
                  <i className='fa fa-check' aria-hidden='true' />
                ) : null}
              </div>
              <p className={classes.checkLabel}>Junior programs</p>
            </div>
            <div className={classes.checkRow}>
              <div
                className={classes.checkBox}
                onClick={() => this._handleCheckBox('interests', 'other')}
                onKeyDown={() => this._handleCheckBox('interests', 'private')}
                role='checkbox'
                tabIndex={0}
                aria-checked='false'
              >
                {this.state.interests.indexOf('other') > -1 ? (
                  <i className='fa fa-check' aria-hidden='true' />
                ) : null}
              </div>
              <p className={classes.checkLabel}>Other</p>
            </div>
          </div>
          <div className={classes.checkboxContainer}>
            <p className={classnames(classes.checkBoxTitle, { [classes.redText]: this.state.failedValidation })}>
              Skill level of golfer(s):
            </p>
            <div className={classes.checkRow}>
              <button
                className={classes.checkBox}
                onClick={() => this._handleCheckBox("skillLevel", "none")}
              >
                {this.state.skillLevel.indexOf("none") > -1 ? (
                  <i className='fa fa-check' aria-hidden='true' />
                ) : null}
              </button>
              <p className={classes.checkLabel}>No experience</p>
            </div>
            <div className={classes.checkRow}>
              <button
                className={classes.checkBox}
                onClick={() => this._handleCheckBox("skillLevel", "beginner")}
              >
                {this.state.skillLevel.indexOf("beginner") > -1 ? (
                  <i className='fa fa-check' aria-hidden='true' />
                ) : null}
              </button>
              <p className={classes.checkLabel}>Beginner</p>
            </div>
            <div className={classes.checkRow}>
              <button
                className={classes.checkBox}
                onClick={() =>
                  this._handleCheckBox("skillLevel", "intermediate")
                }
              >
                {this.state.skillLevel.indexOf("intermediate") > -1 ? (
                  <i className='fa fa-check' aria-hidden='true' />
                ) : null}
              </button>
              <p className={classes.checkLabel}>Intermediate</p>
            </div>
            <div className={classes.checkRow}>
              <button
                className={classes.checkBox}
                onClick={() => this._handleCheckBox("skillLevel", "advanced")}
              >
                {this.state.skillLevel.indexOf("advanced") > -1 ? (
                  <i className='fa fa-check' aria-hidden='true' />
                ) : null}
              </button>
              <p className={classes.checkLabel}>Advanced</p>
            </div>
          </div>
        </div>

        <label htmlFor='req-form-note'>
          Additional details (optional):
          <p>( {this.state.note.length} / 150 characters max)</p>
        </label>
        <textarea
          name='note'
          id='req-form-note'
          value={this.state.note}
          onChange={this._handleInputChange}
          autoComplete='new-note'
          maxLength='150'
        />
        <div className={classes.checkboxArea}>
          <div className={classnames('w-100', classes.checkboxContainer)}>
            <div className={classes.checkRow}>
              <button
                className={classes.checkBox}
                onClick={() => this._handleEmailSetting()}
              >
                {this.state.emailOptIn && (
                  <i className='fa fa-check' aria-hidden='true' />
                )}
              </button>
              <p className={classes.checkLabel}>
                Please add me to Operation 36's email list
              </p>
            </div>
          </div>
        </div>
        {this.state.invalidEmail ? (
          <p className={classes.invalidEmailNote}>
            The email you provided doesn't appear to be valid. Please try again
          </p>
        ) : null}
        <button
          className={classnames('btn-rectangular btn-rectangular-main-positive', classes.reqFormSubmit)}
          disabled={this.state.submitClicked}
          onClick={this._submitForm}
        >
          {this.state.submitClicked ? (
            <div>
              <i className='fa fa-spin fa-spinner' aria-hidden='true' />{" "}
              Notifying Community
            </div>
          ) : (
            'Request Information'
          )}
        </button>
        <Modal
          isOpen={this.state.formSubmitted}
          onClose={this._closeSuccessModal}
          fixedTop
        >
          <div className='d-flex flex-column align-items-center'>
            <i className={classnames('fa fa-check-circle', classes.checkIcon)} aria-hidden='true' />
            <p className={classes.successTitle}>Success!</p>
            <p className='text-center'>
              Thanks for letting us know about your interest! A coach from{' '}
              {this.props.name} will reach out to you soon.
            </p>
          </div>
        </Modal>
      </div>
    );
  }

  _closeSuccessModal = () => {
    this.setState({
      formSubmitted: false,
      name: '',
      email: '',
      fam_count: 1,
      interests: [],
      skillLevel: [],
      note: '',
      phone: '',
      emailOptIn: true,
    })
  }

  _handleEmailSetting = () => {
    this.setState({ emailOptIn: !this.state.emailOptIn })
  }

  _handleCheckBox = (list, val) => {
    const arr = [...this.state[list]]
    if (arr.indexOf(val) === -1) {
      arr.push(val);
      this.setState({ [list]: arr });
    } else {
      let filteredArr = this.state[list].filter((el) => el !== val);
      this.setState({ [list]: filteredArr });
    }
  };

  _submitForm = () => {
    this.setState({ submitClicked: true }, () => {
      if (this._checkValidation()) {
        if (isValidEmail(this.state.email)) {
          let data = {
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            note: this.state.note,
            community_id: this.props.id,
            num_golfers: this.state.fam_count,
            skill_levels: this.state.skillLevel,
            interests: this.state.interests,
            opt_in: this.state.emailOptIn,
          };
          Axios.post(baseUrl + "leads", data)
            .then((res) => {
              if (res.status === 201) {
                this.setState({
                  formSubmitted: true,
                  failedValidation: false,
                  invalidEmail: false,
                  submitClicked: false,
                });
              } else {
                console.log("something happened");
              }
            })
            .catch((e) => console.log(e));
        } else {
          this.setState({ invalidEmail: true, submitClicked: false });
        }
      } else {
        this.setState({ failedValidation: true, submitClicked: false });
      }
    });
  };

  _checkValidation = () => {
    const { name, email, interests, skillLevel, phone } = this.state;
    if (
      !name ||
      !email ||
      !phone ||
      interests.length === 0 ||
      skillLevel.length === 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  _changeFamilyCount = (num) => {
    const { fam_count } = this.state;
    if (num !== fam_count) {
      this.setState({ fam_count: num });
    }
  };

  _handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
}

CommunityContactForm.propTypes = {
  name: PropTypes.string,
}

export default CommunityContactForm;
