import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { ReactComponent as StripeIcon } from '../../../assets/images/common/icons/stripe-icon.svg'
import { ReactComponent as StripePendingIcon } from '../../../assets/images/common/icons/check-circle-white.svg'
import { ReactComponent as StripeCompletedIcon } from '../../../assets/images/common/icons/check-circle-white.svg'
import { ReactComponent as LatoQuestionIcon } from '../../../assets/images/common/icons/lato-question-icon.svg'
import { checkLicensePermission } from '../../../utility/communityLicense'
import * as userActions from '../../../actions/userActions'
import * as stripeConnectActions from '../../../actions/stripeConnectActions'

import './StripeConnection.scss'
import ConfirmationModal2 from '../../common/ConfirmationModal2'

const StripeConnection = () => {
  const dispatch = useDispatch()

  const user = useSelector(state => state.user.current_user)
  const community = user.communities[0]

  const [loadingConnect, setLoadingConnect] = useState(false)
  const [loadingDisconnect, setLoadingDisconnect] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const connectStripe = () => {
    setLoadingConnect(true)
    stripeConnectActions.connectToStripe()
      .then((res) => window.location.replace(res.data.account_link_url))
      .catch((err) => {
        console.log('Something went wrong: ', err);
        dispatch(userActions.refreshCurrentUser(user.id));
        setLoadingConnect(false)
      });
  }

  const disconnectStripeAccount = () => {
    setLoadingDisconnect(true)
    stripeConnectActions.removeStripeConnectedAccount(community.stripe_connected_accounts[0]?.id)
      .catch((err) => console.log('Something went wrong: ', err))
      .finally(() => { dispatch(userActions.refreshCurrentUser(user.id)); setShowConfirmationModal(false); setLoadingDisconnect(false); })
  }

  return (
    <div id='StripeConnection' className='d-flex align-items-center'>
      {(community.selected_stripe_connected_account && (
        <>
          <div className='d-flex align-items-center'>
            <StripeCompletedIcon id='stripeCompletedIcon' className='stripe-icons' />
            <div className='d-flex flex-column justify-content-center'>
              <p className='primary-stripe-text'>
                You are verified and connected to Stripe!
              </p>
              <p className='secondary-stripe-text'>
                Online payments enabled. You are connected as <span style={{ textDecoration: 'underline' }}>
                  {community.selected_stripe_connected_account.email} </span>.
              </p>
            </div>
          </div>
          {community.selected_stripe_connected_account.can_be_deleted &&
            <button onClick={() => setShowConfirmationModal(true)} className='ml-auto stripe-disconnect-button' disabled={loadingConnect || loadingDisconnect}>
              {loadingDisconnect
                ? <span> <i className='fa fa-spinner fa-spin' style={{ marginRight: '5px' }} aria-hidden='true' /> Disconnecting from Stripe... </span>
                : 'Disconnect from Stripe'
              }
            </button>
          }
          <ConfirmationModal2
            showModal={showConfirmationModal}
            closeModal={() => setShowConfirmationModal(false)}
            onDeny={() => setShowConfirmationModal(false)}
            denyMessage='Cancel'
            onProceed={disconnectStripeAccount}
            proceedMessage='Disconnect'
            icon={<i className='fa fa-question-circle' style={{ fontSize: '72px' }} />}
            title='Disconnect Stripe Account?'
            message={
              checkLicensePermission(community.license_type, ['standard', 'standard_legacy'])
              ? 'Disconnecting your Stripe account will change all classes using the online payment method to offline and also set them to private.'
              : 'Disconnecting your Stripe account will temporary block the access to classes and set them all to private until another Stripe account is added.'
            }
          />
        </>
      )) || (community.stripe_connected_accounts[0]?.status === 'attached' && (
        <>
          <div className='d-flex align-items-center'>
            <StripePendingIcon id='stripePendingIcon' className='stripe-icons' />
            <div className='d-flex flex-column justify-content-center'>
              <p className='primary-stripe-text'>Your Stripe integration is not yet ready.</p>
              <p className='secondary-stripe-text'>
                You are connected as <span style={{ textDecoration: 'underline' }}>
                  {community.stripe_connected_accounts[0].email} </span>.
                Please complete the Stripe onboarding process or wait for Stripe to verify your account.
                <span>
                  <LatoQuestionIcon className='lato-question-icon' />
                </span>
              </p>
            </div>
          </div>
          <div className='d-flex ml-auto stripe-buttons'>
            <button onClick={connectStripe} className='stripe-connect-button' disabled={loadingConnect || loadingDisconnect}>
              {loadingConnect
                ? <span> <i className='fa fa-spinner fa-spin' style={{ marginRight: '5px' }} aria-hidden='true' /> Connecting to Stripe... </span>
                : 'Check on Stripe Application'
              }
            </button>
            {community.stripe_connected_accounts[0].can_be_deleted &&
              <button onClick={disconnectStripeAccount} className='stripe-disconnect-button' disabled={loadingConnect || loadingDisconnect}>
                {loadingDisconnect
                  ? <span> <i className='fa fa-spinner fa-spin' style={{ marginRight: '5px' }} aria-hidden='true' /> Disconnecting from Stripe... </span>
                  : 'Disconnect from Stripe'
                }
              </button>
            }
          </div>
        </>
      )) || (
        <>
          <div className='d-flex align-items-center'>
            <StripeIcon id='stripeIcon' className='stripe-icons' />
            <div className='d-flex flex-column justify-content-center'>
              <p className='primary-stripe-text'>
                Want to accept online payments during registration in Op 36?
                <span>
                  <LatoQuestionIcon className='lato-question-icon' />
                </span>
              </p>
              <p className='secondary-stripe-text'>Connect to Stripe for seamless payment collection!</p>
            </div>
          </div>
          <button onClick={connectStripe} className='ml-auto stripe-connect-button' disabled={loadingConnect || loadingDisconnect}>
            {loadingConnect
              ? <span> <i className='fa fa-spinner fa-spin' style={{ marginRight: '5px' }} aria-hidden='true' /> Connecting to Stripe... </span>
              : 'Connect to Stripe'
            }
            </button>
        </>
      )}
    </div>
  )
}

export default StripeConnection
