import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { checkExportStatus } from '../../../actions/dashboardActions'

const useReportPolling = () => {
  const dispatch = useDispatch()
  const [intervalId, setIntervalId] = useState(null)
  const exportId = useSelector(state => state.dashboard.exportId)

  useEffect(() => {
    if (exportId && !intervalId) {
      const interval = setInterval(() => dispatch(checkExportStatus(exportId)), 1000)
      setIntervalId(interval)
    }
    if (!exportId && intervalId) {
      clearInterval(intervalId)
      setIntervalId(null)
    }
  }, [dispatch, exportId, intervalId])
}

export default useReportPolling
