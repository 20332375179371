import React from 'react';
import moment from 'moment';
import { Line } from 'react-chartjs-2';

import './LeadStatsGraph.scss';

class LeadStatsGraph extends React.Component {
  state = {
    todayLeads: 0,
    aggregateArray: [],
    totalLeads: 0,
    day: 0
  };

  componentDidMount() {
    this.setState({ day: parseInt(moment().format('D'), 10) }, () => {
      this.setState({ aggregateArray: this._getAggregateArray() }, () => {
        let today =
          this.state.aggregateArray.length === 1
            ? this.state.aggregateArray[0]
            : this.state.aggregateArray[this.state.aggregateArray.length - 1] -
              this.state.aggregateArray[this.state.aggregateArray.length - 2];
        this.setState({
          todayLeads: today,
          totalLeads: this.state.aggregateArray[
            this.state.aggregateArray.length - 1
          ]
        });
      });
    });
  }
  render() {
    const data = {
      labels: this.state.aggregateArray.map(v => ''),
      datasets: [
        {
          label: 'lead',
          fill: true,
          lineTension: 0.2,
          backgroundColor: 'rgba(102,119,222,0.2)',
          borderColor: 'rgba(102,119,222,0.7)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(102,119,222,0.7)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 1,
          pointHoverBackgroundColor: 'rgba(102,119,222,0.7)',
          pointHoverBorderColor: 'rgba(220,220,220,0.7)',
          pointHoverBorderWidth: 1,
          pointRadius: 1,
          pointHitRadius: 10,
          data: this.state.aggregateArray
        }
      ]
    };
    return (
      <div id='LeadStatsGraph'>
        <div className='today-lead-container'>
          <p className='lead-count'>
            {this.state.todayLeads === 0 ? '0' : `+${this.state.todayLeads}`}
          </p>
          <p className='lead-label'>Today</p>
        </div>
        <div className='total-lead-container'>
          <p className='lead-count'>{this.state.totalLeads}</p>
          <p className='lead-label'>Total Leads</p>
        </div>
        <Line
          data={data}
          options={{
            maintainAspectRatio: false,
            scales: {
              x: {
                ticks: { display: false },
                grid: {
                  display: false,
                  drawBorder: true,
                  borderWidth: 3,
                  borderColor: '#2b3843'
                }
              },
              y: {
                beginAtZero: true,
                ticks: {
                  // max: 100,
                  display: false
                },
                grid: {
                  display: false,
                  drawBorder: true,
                  borderWidth: 3,
                  borderColor: '#2b3843'
                }
              }
            },
            plugins: {
              legend: { display: false },
              tooltip: {
                displayColors: false,
                callbacks: {
                  label: (context) => {
                    const month = moment().format('M')
                    const day = context.dataIndex + 1
                    const nr = context.raw

                    return `${month}/${day}: ${nr}`
                  }
                }
              }
            },
            layout: {
              padding: {
                left: 0,
                right: 10,
                top: 3,
                bottom: 0
              }
            }
          }}
        />
      </div>
    );
  }

  _getAggregateArray = arr => {
    const { day } = this.state;
    const { leads } = this.props;
    let counts = [];
    let totalCount = 0;
    let aggregateArr = [];
    for (let i = 1; i <= day; i++) {
      // counts leads for each day of the month
      let leadsByDay = leads.filter(
        lead =>
          moment()
            .endOf('day')
            .diff(moment(lead.created_at), 'days') ===
          day - i
      );
      counts.push(leadsByDay.length);
    }
    for (let i = 0; i < counts.length; i++) {
      // makes new array with running total rather than just specific days
      totalCount += counts[i];
      aggregateArr.push(totalCount);
    }
    return aggregateArr;
  };
}

export default LeadStatsGraph;
