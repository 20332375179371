import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as LeftArrow } from '../../../assets/images/common/icons/left-arrow.svg'
import './PageHeader.scss'

const PageHeader = ({
  title,
  middleText,
  backButton,
  onClickBack,
  questionButton,
  actionComponent,
  titleActionComponent,
  titleUrl,
  iconUrl,
}) => {
  let QuestionButton

  if (questionButton) {
    QuestionButton = (
      <button
        className='btn btn-link mr-2 question-button'
      >
        <i className='fa fa-question' aria-hidden='true' />
      </button>
    )
  }

  if (backButton) {
    backButton = (
      <div
        className='back-button'
        role='button'
        tabIndex={0}
        onClick={onClickBack}
        onKeyDown={null}
      >
        <LeftArrow/>
      </div>
    )
  }

  return (
    <div className='page-header-v2 sticky-top'>
      <div className='row mx-0 h-100 d-flex align-items-center'>
        <div className={`pl-4 ${middleText ? 'col-4' : 'col-6'} d-flex align-items-center`}>
          {iconUrl && <div
            className='headerIcon'
            style={{
              backgroundImage: `url(${iconUrl})`,
            }}
          />}
          <div className='mr-3'>{backButton && backButton}</div>
          <h1 className='header-title'>
            {titleUrl ?
              <a href={titleUrl} target='_blank'>{title}</a>
              :
              title
            }
          </h1>
          {titleActionComponent}
        </div>
        {middleText &&
          <div className='col-4 d-flex justify-content-center'>
            <span className='header-title'>{middleText}</span>
          </div>
        }
        <div className={`px-5 m-0 ${middleText ? 'col-4' : 'col-6'} header-button-container`}>
          {actionComponent}
          {QuestionButton && QuestionButton}
        </div>
      </div>
    </div>
  )
}

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  middleText: PropTypes.string,
  backButton: PropTypes.bool,
  onClickBack: PropTypes.func,
  questionButton: PropTypes.bool,
  actionComponent: PropTypes.object,
  titleActionComponent: PropTypes.object,
  iconUrl: PropTypes.string,
  titleUrl: PropTypes.string,
}

export default PageHeader
