import React, { Component } from "react";
import _ from "lodash";
import moment from "moment";
import StudentCard from "../common/StudentCard";
import EnrollmentCard from "./EnrollmentCard";
import FamilyProgramCard from "./FamilyProgramCard";
import ActivityLoader from "react-loaders";
import Activity from "../activity/Activity";
import Placeholder from "../common/Placeholder";
import Loader from "../common/Op36Loader-web";
import ReactList from "react-list";
import ReactPlayer from "react-player";

import { abbrNum } from "../../utility/helperFunctions";

//REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as familyActions from "../../actions/familyActions";

import StockProfilePic from "../../assets/images/mock/StockProfilePic.png";
// import AppleIcon from "./icons/apple-app-store.png";
// import GoogleIcon from "./icons/google-play.png";

import "./FamilyDashboard.scss";

class FamilyDashboard extends Component {
  renderUserActivities = () => {
    return this.props.user_activities.map(activity => {
      return (
        <Activity
          key={activity.id}
          displayComments={false}
          activity={activity}
        />
      );
    });
  };

  renderItem = (index, key) => {
    const {
      activities,
      page,
      reloadStats,
      PER_PAGE,
      ROWS_FROM_BOTTOM_LOAD,
      loadPageActivities
    } = this.props;

    if (activities && activities.length > 0) {
      if (
        index > PER_PAGE * (page - 1) - ROWS_FROM_BOTTOM_LOAD &&
        index > activities.length - ROWS_FROM_BOTTOM_LOAD
      ) {
        // If current index is at point to load more
        loadPageActivities();
      }

      if (activities) {
        var rowActivity = activities[index];
        if (rowActivity) {
          return (
            <Activity
              key={rowActivity.id}
              displayComments={false}
              activity={rowActivity}
              triggerReload={reloadStats}
              isStudentJourney
            />
          );
        }
      }
    }
  };

  displayActivities = () => {
    const { userActivityLoader, activities, user } = this.props;
    if (userActivityLoader) {
      return (
        <div className='card mb-2 loading-card my-3'>
          <h5 className='p-2 ml-3 my-2 card-header-text'>Activity Feed</h5>
          <div className='card-block p-2 activity-feed-section'>
            <div className='row'>
              <div className='col-12'>
                <div className='loading-content stats-loading-content '>
                  <Loader message='loading golfer activities' />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (activities && activities.length > 0) {
      return (
        <div className='container-fluid'>
          <div className='row my-3 '>
            <div className='col-md-12 p-0'>
              <div className='dashboard-third-step dashboard-welcome-container card hidden-sm-down animated fadeIn'>
                <div className='d-flex flex-row align-items-center justify-content-center dashboard-welcome-info-wrapper border-0 pt-3'>
                  <div className='round-image-wrapper mr-3 dashboard-welcome-avatar'>
                    <img
                      className='round-image'
                      src={user.avatar ? user.avatar.file_url : StockProfilePic}
                      alt={`${user.first_name} ${user.last_name}`}
                    />
                  </div>
                  <div>
                    <h5 className='dashboard-time-name'>{`Welcome ${user.first_name}`}</h5>

                    <div className='dashboard-time-wrapper'>
                      <h5 className='dashboard-time-text'>Today is</h5>
                      <h5 className='dashboard-time animated fadeIn'>
                        {moment().format("MM/DD/YYYY hh:mm A")}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className='activity-feed-divider hidden-sm-down' />
              <div className='activity-feed-section dashboard-fourth-step'>
                <ReactList
                  itemRenderer={this.renderItem}
                  length={activities.length}
                  type='simple'
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else if (activities.length === 0) {
      return (
        <div>
          <div className='dashboard-third-step dashboard-welcome-container card hidden-sm-down animated fadeIn'>
            <div className='d-flex flex-row align-items-center justify-content-center dashboard-welcome-info-wrapper border-0 pt-3'>
              <div className='round-image-wrapper mr-3 dashboard-welcome-avatar'>
                <img
                  className='round-image'
                  src={user.avatar ? user.avatar.file_url : StockProfilePic}
                  alt={`${user.first_name} ${user.last_name}`}
                />
              </div>
              <div>
                <h5 className='dashboard-time-name'>{`Welcome ${user.first_name}`}</h5>

                <div className='dashboard-time-wrapper'>
                  <h5 className='dashboard-time-text'>Today is</h5>
                  <h5 className='dashboard-time animated fadeIn'>
                    {moment().format("MM/DD/YYYY hh:mm A")}
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className='card my-3 family-activity-card'>
            <h5 className='p-2 ml-3 my-2 card-header-text'>Activity Feed </h5>
            <div className='card-block p-2'>
              {/* <div className='row py-2'>
              <div className='col-12'>
                <h2 className='text-center secondary-text activity-text'>
                  Download App And Log Your 1st Action
                </h2>
              </div>
            </div>
            <div className='row py-2 justify-content-center d-flex'>
              <div className='col-8 text-center'>
                <a
                  href='https://itunes.apple.com/us/app/op-36-golf/id1186959877?mt=8'
                  target='_blank'
                >
                  <img
                    src={AppleIcon}
                    alt='Operation36 on iOS'
                    className='app-icon'
                  />
                </a>
                <a
                  href='https://play.google.com/store/apps/details?id=com.operation36.app&hl=en'
                  target='_blank'
                >
                  <img
                    src={GoogleIcon}
                    alt='Operation36 on Google Play'
                    className='app-icon'
                  />
                </a>
              </div>
            </div> */}
              <div className='row py-2'>
                <div className='col-12'>
                  <h2 className='text-center secondary-text activity-text'>
                    No Activity Yet
                  </h2>
                </div>
              </div>
              <div className='player-wrapper animated fadeIn'>
                <ReactPlayer
                  className='react-player'
                  url='https://vimeo.com/310150761/904bc3a28a'
                  width='100%'
                  height='100%'
                  controls
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className='card my-3 loading-card'>
          <h5 className='p-2 ml-3 my-2 card-header-text'>Activity Feed </h5>
          <div className='card-block p-2'>
            <div className='row'>
              <div className='col-12'>
                <div className='loading-content stats-loading-content '>
                  <Loader message='loading golfer activities' />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  displayEnrollments = () => {
    const { enrollments } = this.props;
    if (enrollments && enrollments.length > 0) {
      return enrollments.map((enrollment, index) => {
        if (enrollment.course) {
          return (
            <EnrollmentCard
              enrollment={enrollment}
              key={index}
            />
          );
        }
        return null;
      });
    } else {
      return (
        <Placeholder
          title={"No Current Enrollments"}
          icon={"fa-group"}
          size={"100"}
          subtext1={"Currently no golfers are enrolled."}
          subtext2={"Take a look at active programs and sign up."}
        />
      );
    }
  };
  renderFamily = () => {
    const { family } = this.props;
    const familyCopy = JSON.parse(JSON.stringify(family));

    if (_.isEmpty(familyCopy)) {
      return (
        <div className='row d-flex justify-content-center align-items-center p-5 animated fadeIn'>
          <ActivityLoader type='ball-pulse' color={"lightgrey"} />
        </div>
      );
    }

    const sortedFamily = [...familyCopy.users].sort((u1, u2) => {
      if (u1.family_admin && !u2.family_admin) return -1
      if (!u1.family_admin && u2.family_admin) return 1
      
      if (u1.last_name < u2.last_name) return -1
      if (u1.last_name > u2.last_name) return 1
  
      if (u1.first_name < u2.first_name) return -1
      if (u1.first_name > u2.first_name) return 1
  
      return 0
    })

    return sortedFamily.map(user => (
      <StudentCard student={user} key={user.id} />
    ));
  };

  displayPrograms = () => {
    const { courses, family } = this.props;
    if (courses && courses.length > 0) {
      return courses.map(course => (
        <FamilyProgramCard
          key={course.id}
          course={course}
          family={family}
        />
      ));
    }
    return (
      <Placeholder
        title={"No programs listed"}
        icon={"fa-clipboard"}
        size={"100"}
        subtext1={"Contact the coach of the community"}
        subtext2={"to see when a program will be listed."}
      />
    );
  };
  render() {
    const {
      family,
      stats,
      programsLoader,
      enrollmentsLoader
    } = this.props;

    return (
      <div className='animated fadeIn'>
        <div id='FamilyDashboardHeader'>
          <div className='family-container'>{this.renderFamily()}</div>
        </div>
        <div className='row mt-4'>
          <div className='col-md-4'>{this.displayActivities()}</div>
          <div className='col-md-8 family-stats-container'>
            <div className='row my-3'>
              <div className='col-md-3'>
                <div className='card'>
                  <h5 className='p-2 ml-3 my-2 card-header-text'>
                    Family Points{" "}
                  </h5>
                  <p className='family-points'>{abbrNum(family.points, 1)}</p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='card'>
                  <h5 className='p-2 ml-3 my-2 card-header-text'>
                    Total Golfers{" "}
                  </h5>
                  <p className='family-golfers'>{family.users.length}</p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='card'>
                  <h5 className='p-2 ml-3 my-2 card-header-text'>
                    Total Activities{" "}
                  </h5>
                  <p className='family-activities'>
                    {abbrNum(stats.total_activities, 1)}
                  </p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='card'>
                  <h5 className='p-2 ml-3 my-2 card-header-text'>
                    Activities Today{" "}
                  </h5>
                  <p className='family-activities-today'>
                    {stats.activities_today}
                  </p>
                </div>
              </div>
            </div>
            <div className='row my-3'>
              <div className='col-md-6'>
                <div className='card'>
                  <h5 className='p-2 ml-3 my-2 card-header-text'>
                    Active Community Classes{" "}
                  </h5>
                  <div className='p-3 family-dashboard-card'>
                    {programsLoader ? (
                      <div className='row d-flex justify-content-center align-items-center p-5 animated fadeIn'>
                        <ActivityLoader type='ball-pulse' color={"lightgrey"} />
                      </div>
                    ) : (
                      this.displayPrograms()
                    )}
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='card'>
                  <h5 className='p-2 ml-3 my-2 card-header-text'>
                    Active Enrollments{" "}
                  </h5>
                  <div className='p-3 family-dashboard-card'>
                    {enrollmentsLoader ? (
                      <div className='row d-flex justify-content-center align-items-center p-5 animated fadeIn'>
                        <ActivityLoader type='ball-pulse' color={"lightgrey"} />
                      </div>
                    ) : (
                      this.displayEnrollments()
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    family_actions: bindActionCreators(familyActions, dispatch)
  };
}

export default connect(null, mapDispatchToProps)(FamilyDashboard);
