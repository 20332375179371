import React, { Component } from 'react'
import Modal from 'react-modal'
import moment from 'moment'
import PropTypes from 'prop-types'

import '../../assets/styles/components/annoucements/CampaignPage.scss'

class PreviewModal extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  createMarkup = () => ({ __html: this.props.message })

  displayRecipients = () => {
    if (this.props.isEmail) {
      return this.props.recipients.map((user, index) => (
        <div className='border-bottom p-1 w-100 my-1' key={`preview-${index}`}>
          <div className='row'>
            <div className='col-auto d-flex justify-content-center align-items-center'>
              <i className='fas fa text-success p-1 fa-check-circle'/>
            </div>
            <div className='col-10 pl-0'>
              <h6 className='my-0'>{`${user.first_name || user.firstName} ${
                user.last_name || user.lastName
              }`}</h6>
              <small className='my-0'>{`${user.emails ? user.emails.join(', ') : user.email}`}</small>
            </div>
            <div className='col-1 d-flex align-items-center'/>
          </div>
        </div>
      ))
    } else {
      return this.props.courses.map((course) => {
        if (course.enrollments.length > 0) {
          return (
            <div
              className='card p-2 w-100 my-3 d-flex justify-content-between flex-row animated fadeIn'
              key={course.id}
            >
              <div>
                <h5>{course.name}</h5>
                <small>{`${moment(course.start_date).format(
                  'MM-DD-YYYY'
                )} - ${moment(course.end_date).format('MM-DD-YYYY')}`}</small>
                <small>
                  <button
                    className='btn-link no-bg-text-btn text-primary ml-2'
                    disabled={true}
                  >
                    {`${course.enrollments.length} Students`}{' '}
                  </button>
                </small>
              </div>
            </div>
          )
        } else {
          return null
        }
      })
    }
  }

  render() {
    return (
      <Modal
        isOpen={this.props.showModal}
        onRequestClose={() => this.props.closeModal()}
        contentLabel='Announcement Modal'
      >
        <div className='modal-header row m-0 p-0'>
          <div className='col-11 m-0 p-0'>
            <h5 className='modal-title display-4'>
              Review {this.props.isEmail ? 'Email' : 'Announcement'}
            </h5>
          </div>
          <div className='col-1 m-0 p-0'>
            <button
              type='button'
              className='close pull-right font-200'
              data-dismiss='modal'
              aria-label='Close'
              onClick={() => this.props.closeModal()}
            >
              <span aria-hidden='true'>✕</span>
            </button>
          </div>
        </div>
        <div className='container'>
          <div className='row mt-4'>
            <div className='col-md-6'>
              {this.props.isEmail ? (
                <div>
                  <h3>Subject</h3>
                  <p className='border p-3'>{this.props.subject}</p>
                  <h3>Message</h3>
                  <div
                    className='border p-3'
                    dangerouslySetInnerHTML={this.createMarkup()}
                  />
                </div>
              ) : (
                <div>
                  <h3>Message</h3>
                  <p className='border p-3'>{this.props.pushMessage}</p>
                </div>
              )}
            </div>
            <div className='col-md-6'>
              <h3>Recipient(s)</h3>
              <div className='recipient-list-wrapper border p-3'>
                {this.displayRecipients()}
              </div>
            </div>
          </div>
        </div>
        <div className='row mt-5'>
          <div className='col-md-6 mx-auto'>
            <div className='row'>
              <div className='col-6'>
                <button
                  className='mx-auto btn-block btn btn-dark'
                  onClick={this.props.closeModal}
                >
                  Cancel
                </button>
              </div>
              <div className='col-6'>
                <button
                  className='mx-auto btn-block btn btn-primary'
                  onClick={this.props.sendMessage}
                  disabled={this.props.loader}
                >
                  <i className='fas fa fa-paper-plane' />{' '}
                  {this.props.loader ? 'Sending...' : 'Send'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

PreviewModal.propTypes = {
  isEmail: PropTypes.bool,
  recipients: PropTypes.array,
  closeModal: PropTypes.func,
  message: PropTypes.string,
  loader: PropTypes.bool,
  sendMessage: PropTypes.func,
  subject: PropTypes.string,
  pushMessage: PropTypes.string,
  showModal: PropTypes.bool,
  courses: PropTypes.array,
}

export default PreviewModal
