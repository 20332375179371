import styles from './CommunityLeaderboardExport.module.scss'
import { ReactComponent as GolferIcon } from '../../../assets/images/common/communityLeaderboard/golfer-half-icon.svg'
import { ReactComponent as WarningIcon } from '../../../assets/images/common/icons/warning-icon.svg'
import classNames from 'classnames'
import Loader from '../../common/Op36Loader'
import { useDispatch, useSelector } from 'react-redux'
import { clearExport } from '../../../reducers/dashboardReducer'
import { exportCommunityLeaderboardRanking } from '../../../actions/dashboardActions'
import PropTypes from 'prop-types'

const CommunityLeaderboardExport = ({ communityId }) => {
  const {
    exportUrl,
    exportId,
    exportError,
    leaderboardFilters,
  } = useSelector(state => state.dashboard)
  const dispatch = useDispatch()

  return (
    <div className={classNames('d-flex flex-column justify-content-center align-items-center text-center', styles.communityLeaderboardExport)}>
      {exportUrl &&
        <>
          <GolferIcon/>
          <p className={styles.exportTitle}>Leaderboard Exported</p>
          <p className={styles.exportText}>
          The results have been exported successfully, download the file to view.
          </p>
          <a
            className={classNames('ml-3 w-30 mt-3 animation-shrink d-flex align-items-center justify-content-center', styles.downloadButton)}
            href={exportUrl}
            onClick={() => dispatch(clearExport())}
          >
            Download Leaderboard<i className='ml-2 fa fa-download' />
          </a>
        </>
      }
      {exportId &&
        <div className={styles.loaderContainer}>
          <Loader message='exporting leaderboard' />
        </div>
      }
      {exportError &&
        <>
          <WarningIcon className={styles.warningIcon}/>
          <p className={styles.exportTitle}>Oops, something went wrong...</p>
          <div className={styles.errorButtons}>
            <button
              className={classNames('ml-3 w-100 mt-3 animation-shrink', styles.tryAgain)}
              onClick={() => {
                dispatch(exportCommunityLeaderboardRanking({ communityId, filters: leaderboardFilters }))
              }}
            >
              Try Again
            </button>
            <button
              className={classNames('ml-3 w-100 mt-3 animation-shrink', styles.refresh)}
              onClick={() => dispatch(clearExport())}
            >
              Refresh
            </button>
          </div>
        </>
      }
    </div>
  )
}

CommunityLeaderboardExport.propTypes = {
  communityId: PropTypes.number,
}

export default CommunityLeaderboardExport
