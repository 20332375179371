import { deserialize } from '../utility/serialization'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { handleAPIError } from '../utility/actionHelper'

import Axios from '../axios'

// THUNKS HERE

export const getCommunityCoaches = createAsyncThunk(
  'coaches/getCommunityCoaches',
  async ({ communityId }, { rejectWithValue }) => {
    try {
      const { data } = await requestGetCoaches(communityId)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

// API CALLS

function requestGetCoaches(communityId) {
  const requestUrl = `/api/v1/communities/${communityId}/coaches`

  return Axios.get(requestUrl)
}
