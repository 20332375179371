import React, { Component } from "react";
// import Toast from "../../common/Toast";

// ASSETS
import "./CommunityStage.scss";

class CommunityStage extends Component {
  state = {
    hoverActive: false
  };

  render() {
    const { stage/*, deleteStage */ } = this.props;
    // const { hoverActive } = this.state;
    return (
      <div
        id='CommunityStage'
        onMouseEnter={this._onMouseEnter}
        onMouseLeave={this._onMouseLeave}
      >
        {/* {hoverActive && (
          <Toast
            type={"warn"}
            position={"top_center"}
            message={`Are you sure? This will delete the '${stage.name}' stage`}
            autoClose={false}
            action={() => deleteStage(stage)}
            actionText={"Yes, Delete Stage"}
          >
            <button
              className={`animated ${hoverActive ? "fadeIn" : "fadeOut"}`}
            >
              <i className='fa fa-close' />
            </button>
          </Toast>
        )} */}

        <p>{stage.name}</p>
      </div>
    );
  }

  _onMouseEnter = () => {
    this.setState({
      hoverActive: true
    });
  };

  _onMouseLeave = () => {
    this.setState({
      hoverActive: false
    });
  };
}
export default CommunityStage;
