import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Loader from 'react-loaders'

import profile_avatar from '../../assets/images/mock/StockProfilePic.png'
import ProgramsModal from './Programs/ProgramsModal'
import SmallMilestoneIcon from '../common/SmallMilestoneIcon'
import { withRouting } from '../common/hooks'
import { NavLink } from 'react-router-dom'
import { hasUserWriteAccess } from '../../utility/userHelper'

class ProfileStickyHeader extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showModal: false,
      showLogActionsModal: false,
      alertType: 'success',
      alertTitle: '',
      alertMessage: '',
      lastAction: 0,
      loading: false,
      showProgramsModal: false,
      reportsDropdown: false,
    }
  }

  getImageURLForUser(user) {
    if (user.avatar) {
      return user.avatar.small_file_url
    } else {
      return profile_avatar
    }
  }

  toggleProgramsModal = () => {
    const { userActions, user } = this.props
    this.setState({ showProgramsModal: !this.state.showProgramsModal }, () => {
      if (this.state.showProgramsModal) {
        userActions.getUserCourses(user.id, 'student').then(() => {
          this.setState({
            programsLoader: false,
          })
        })
      }
    })
  }

  render() {
    const { current_user, user } = this.props
    const fullName = `${this.props.user.first_name} ${this.props.user.last_name}`

    return (
      <div>
        <div className='col-12 profile-sticky-container'>
          <div className='sticky-image-container'>
            {this.props.userLoader ? (
              <img
                src={profile_avatar}
                alt='User Loading'
                className='img-responsive mx-auto d-block user-profile-img sticky-user-profile-img-web'
              />
            ) : (
              <NavLink to={{ search: '?editTab=personal' }}>
                <img
                  src={this.getImageURLForUser(this.props.user)}
                  alt={fullName}
                  className='img-responsive mx-auto d-block user-profile-img sticky-user-profile-img-web animated fadeIn'
                />
              </NavLink>
            )}
          </div>
          <div className='sticky-profile-data'>
            <div className='data-section-2'>
              {this.props.userLoader ? null : (
                <h1 className='text-white sticky-profile-name animated fadeIn'>
                  {fullName}
                </h1>
              )}
            </div>

            <div className='sticky-division-icon-container'>
              {this.props.divisionLoader ? (
                <Loader type='line-scale' active color='#ff6633' />
              ) : (
                <SmallMilestoneIcon
                  division={this.props.division.current_division}
                  fromSticky={true}
                />
              )}
            </div>
          </div>
        </div>
        {this.props.userLoader ? null : (
          <div className='row container-fluid sticky-btn-row animated fadeIn'>
            {/* {isCoach ? (
              <div className='pull-right hidden-sm-down'>
                <Button
                  type='button'
                  // disabled={this.props.divisionLoader}
                  id='reports-popover'
                  onClick={this.toggleDropdown}
                  className='btn btn-primary edit-btn'
                >
                  <i className='fa fa-book' />
                </Button>
                <Popover
                  placement='bottom'
                  isOpen={this.state.reportsDropdown}
                  target='reports-popover'
                  toggle={this.toggleDropdown}
                >
                  <ReportsPopover
                    togglePlayerProgressModal={
                      this.props.togglePlayerProgressModal
                    }
                    toggleDropdown={this.toggleDropdown}
                    fromSticky={true}
                  />
                </Popover>
              </div>
            ) : null} */}
            {hasUserWriteAccess(current_user, user) ? (
              <>
                <div className='pull-right hidden-sm-down'>
                  <button
                    className='btn btn-primary edit-btn'
                    onClick={this.props.toggleLogActionsModal}
                  >
                    <i className='fa fa-plus' />
                  </button>
                </div>
                <div className='pull-right hidden-sm-down'>
                  <NavLink
                    to={{ search: '?editTab=personal' }}
                    className='btn btn-primary edit-btn'
                  >
                    <i className='fa fa-pencil-square-o' />
                  </NavLink>
                </div>
                <div className='pull-right hidden-sm-down'>
                  <a href={`/familydetail/${this.props.user.family.id}`}>
                    <button className='btn btn-primary family-btn'>
                      <i className='fa fa-group' />
                    </button>
                  </a>
                </div>
                <div className='pull-right hidden-sm-down'>
                  <button
                    className='btn btn-primary programs-btn'
                    onClick={this.toggleProgramsModal}
                  >
                    <i className='fa fa-flag' />
                  </button>
                </div>
              </>
            ) : null}
          </div>
        )}
        {this.state.showProgramsModal && (
          <ProgramsModal
            toggleModal={this.toggleProgramsModal}
            showModal={this.state.showProgramsModal}
            golfer={this.props.user}
            loader={this.state.programsLoader}
            courses={this.props.courses}
          />
        )}
      </div>
    )
  }
}

ProfileStickyHeader.propTypes = {
  objectives: PropTypes.object,
  user: PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    type: PropTypes.string,
    family: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  current_user: PropTypes.shape({
    user_settings: PropTypes.shape({
      coach_permission: PropTypes.number,
    }),
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    type: PropTypes.string,
    family: PropTypes.shape({
      id: PropTypes.string,
    }),
    family_admin: PropTypes.bool,
  }),
  userActions: PropTypes.shape({
    getUserCourses: PropTypes.func,
  }),
  userLoader: PropTypes.bool,
  divisionLoader: PropTypes.bool,
  courses: PropTypes.array,
  division: PropTypes.shape({
    current_division: PropTypes.any,
  }),
  toggleLogActionsModal: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
}

export default withRouting(ProfileStickyHeader)
