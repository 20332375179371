import React, { Component } from "react";

// CHILDREN
import Loader from "../../../common/Op36Loader-web";
import Placeholder from "../../../common/Placeholder";
import FlagRow from "./FlagRow";

class CommunityFlags extends Component {
  render() {
    return <div id='CommunityFlags'>{this._renderFlags()}</div>;
  }

  _renderFlags = () => {
    const {
      flags,
      loader,
      toggleFormSection,
      toggleEditFlag,
      deleteFlag
    } = this.props;

    if (loader) {
      return <Loader message='loading flags' />;
    }

    if (flags && flags.length > 0) {
      return flags.map(flag => (
        <FlagRow
          flag={flag}
          key={flag.id}
          toggleEditFlag={toggleEditFlag}
          deleteFlag={deleteFlag}
        />
      ));
    }
    return (
      <Placeholder
        title={"No flags have been created"}
        icon={"fa-flag"}
        subtext1={"You currenly have not created any flags for this community."}
        subtext2={"Click the button below to add a new flag if needed."}
        buttonText={"New Flag"}
        buttonIcon={"fa-plus"}
        handleClick={() => toggleFormSection("flags")}
      />
    );
  };
}

export default CommunityFlags;
