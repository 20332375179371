import React, { Component } from "react";

// ASSETS
import "./DraggableResourceCategory.scss";

class DraggableResourceCategory extends Component {
  state = {
    id: this.props.category.id,
    name: this.props.category.name || this.props.category.title,
  };

  handleInputChange = (event) => {
    const { sendCategoryData } = this.props;
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        sendCategoryData(this.state);
      }
    );
  };

  render() {
    const { updateCategories, categories } = this.props;
    const { name } = this.state;

    return (
      <div id='DraggableResourceCategory' className='card'>
        <i className='fa fa-bars' />
        <input
          type='text'
          name='name'
          className='form-control'
          id='name'
          placeholder='Category Name'
          onChange={this.handleInputChange}
          value={name}
          onBlur={() => updateCategories(categories)}
        />
      </div>
    );
  }
}

export default DraggableResourceCategory;
