import * as types from "../actions/actionTypes";
// import update from 'immutability-helper';
// state = [] : Start out with no accounts
export default function leads(state = [], action) {
  switch (action.type) {
    case types.GET_LEADS:
      return Object.assign({}, state, {
        leads: action.leads
      });

    case types.UPDATE_LEAD:
      let index;
      for (let i = 0; i < state.leads.length; i++) {
        if (state.leads[i].id === action.lead.id) {
          index = i;
          break;
        }
      }
      let leadsCopy = [...state.leads];
      leadsCopy[index] = action.lead;
      return Object.assign({}, state, { leads: leadsCopy });

    case types.DELETE_LEAD:
      let leads = state.leads.filter(lead => lead.id !== action.lead);
      return Object.assign({}, state, { leads: leads });

    case types.GET_ADMIN_COUNTS:
      return Object.assign({}, state, { admin_counts: action.admin_counts });

    default:
      return state;
  }
}
