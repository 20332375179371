import React, { Component } from "react";
import _ from "lodash";

import "../../../assets/styles/components/classes/classDetailPage.scss";

import Loader from "../../common/Op36Loader";

class IssueBadges extends Component {
  constructor(props, context) {
    // eslint-disable-line no-useless-constructor
    super(props, context);
    // this.pickCourse = this.pickCourse.bind(this);
    this.renderObjectives = this.renderObjectives.bind(this);
  }

  displayStudents() {
    if (this.props.studentsLoader) {
      return (
        <div className="container-fluid  student-loader-container">
          <Loader message="loading students" />
        </div>
      );
    }

    if (this.props.courseStudents.length > 0) {
      return (
        // eslint-disable-next-line
        this.props.courseStudents.map((user, index) => {
          // DISPLAY FAMILY MEMBER IF ENROLLED OR NOT
          if (this.isStudentPassed(user)) {
            return this.studentWithBadge(user, index);
          } else {
            return this.studentWithoutBadge(user, index);
          }
        })
      );
    }
  }

  renderStudents() {
    if (this.props.objectiveId > 0) {
      return (
        <div>
          <label htmlFor="students" className="mt-4 h4">
            Select Students
          </label>
          {this.displayStudents()}
        </div>
      );
    }
  }

  /*View Students*****************************************/
  isStudentPassed(user) {
    if (user.objective_activities && user.objective_activities.length > 0) {
      for (let i = 0; i < user.objective_activities.length; i++) {
        if (
          user.objective_activities[i].objective.id ===
          parseInt(this.props.objectiveId, 10)
        ) {
          return true;
        }
      }
      return false;
    }
  }

  studentWithBadge(user, index) {
    return (
      <div key={index} className="row">
        <div className="col">
          <div className="checkbox enrolledFamilyMember">
            {user.first_name} {user.last_name}
          </div>
        </div>
      </div>
    );
  }

  studentWithoutBadge(user, index) {
    return (
      <div key={index} className="row">
        <div className="col">
          <div className="checkbox nonEnrolledFamilyMember">
            <label>
              <input
                name=""
                type="checkbox"
                checked={this.isMemberChecked(
                  this.props.activeMembers,
                  user.id
                )}
                onChange={this.handleMemberChange.bind(this, user.id)}
              />{" "}
              {user.first_name} {user.last_name}
            </label>
          </div>
        </div>
      </div>
    );
  }

  isMemberChecked(members, user_id) {
    if (_.indexOf(members, user_id) > -1) {
      return true;
    } else {
      return false;
    }
  }

  handleMemberChange(user_id) {
    // IF THE MEMBER IS CHECKED UNCHECKED ELSE CHECK IT
    if (this.isMemberChecked(this.props.activeMembers, user_id)) {
      var newArrayIsChecked = _.pull(this.props.activeMembers, user_id);
      this.props.updateActiveMembers(newArrayIsChecked);
    } else {
      var newArrayNotChecked = this.props.activeMembers.slice();
      newArrayNotChecked.push(user_id);
      this.props.updateActiveMembers(newArrayNotChecked);
    }
  }

  displayRankObjectives() {
    if (this.props.incompleteObjectives.length > 0) {
      return this.props.incompleteObjectives.map((objective, index) => {
        return this.displayRankObjective(objective);
      });
    }
  }

  displayRank(rank) {
    return (
      <option value={rank.id} key={rank.id}>
        {rank.name.toUpperCase()}
      </option>
    );
  }

  displayRanks() {
    return this.props.ranks.map(rank => {
      return this.displayRank(rank);
    });
  }

  displayObjective(objective) {
    return (
      <option value={objective.id} key={objective.id}>
        {`${objective.subject.name} : ${objective.name.toUpperCase()}`}
      </option>
    );
  }

  displayObjectives() {
    return this.props.objectives.map(objective => {
      return this.displayObjective(objective);
    });
  }

  renderObjectives() {
    if (this.props.rankId > 0) {
      return (
        <div>
          <label htmlFor="badges" className="mt-4 h4">
            Goal
          </label>
          <select
            className="form-control"
            id="badges"
            onChange={this.props.handleObjectiveSelectChange}
            value={this.props.objectiveId}
          >
            <option value={-1}>Choose Goal</option>
            {this.displayObjectives()}
          </select>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="modal-content">
        <div className="modal-body" id="input-border">
          <div className="row">
            <div className="col">
              <div className="form-group container">
                <div>
                  <label htmlFor="ranks" className="h4">
                    Level
                  </label>
                  <select
                    className="form-control"
                    id="ranks"
                    onChange={this.props.handleRankSelectChange}
                    value={this.props.rankId}
                  >
                    <option value={-1}>Choose Level</option>
                    {this.displayRanks()}
                  </select>
                  {this.renderObjectives()}
                  <div className="mt-2">{this.renderStudents()}</div>
                </div>
                <div className="modal-footer row">
                  <button
                    type="button"
                    className="btn btn-primary w-50 mx-auto mt-5"
                    disabled={this.props.activeMembers.length === 0}
                    onClick={this.props.onSubmitBadges}
                  >
                    Mark Goal(s) Complete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default IssueBadges;
