import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'

import Input from '../../common/form/Input'
import SimplePhotoUploader from '../../common/SimplePhotoUploader'
import SubmitButton from '../../common/buttons/SubmitButton'
import {
  resetCourseCategoryErrors,
  selectHasCategories,
  setCategoriesOrdered,
  setCourseCategory,
  setField,
  setHeaderImage,
} from '../../../reducers/community-settings/landingPageSettingsReducer'
import { updateCommunityLandingPageSettings } from '../../../actions/communitiesActions'

import classes from './CommunityLandingPageTab.module.scss'
import ConfirmationModal from './ConfirmationModal'
import CourseCategoryEditModal from './CourseCategoryEditModal'
import CategoriesReorderingDnd from './CategoriesReorderingDnd'
import CancelButton from '../../common/buttons/CancelButton'

const NEW_COURSE_CATEGORY = {
  name: '',
  color: '',
  order: null,
  description: '',
  headerImageUrl: '',
  headerImageData: null,
  headerImageName: '',
}

const SettingsForm = () => {
  const dispatch = useDispatch()

  const { community, settingsFields, attributeErrors, categoriesOrdered } = useSelector(state => state.communitySettings.landingPage)
  const hasCategories = useSelector(selectHasCategories)

  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [isCourseCategoryModalOpen, setCourseCategoryModalOpen] = useState(false)
  const [isReorderingActive, setReorderingActive] = useState(false)

  const landingPageUrl = `${process.env.REACT_APP_BASE_URL}/landing/${community.id}`

  const handleInputChange = (e) => {
    const field = e.target.name
    const value = e.target.value

    dispatch(setField({ field, value }))
  }

  const handlePhotoChange = (photoData) => {
    const { uploadPhotoFile, uploadPhotoSrc } = photoData
    dispatch(setHeaderImage({
      headerImageUrl: uploadPhotoSrc,
      headerImageData: uploadPhotoFile,
      headerImageName: uploadPhotoFile?.name,
    }))
  }

  const handleOpenEditCategoryModal = (category = NEW_COURSE_CATEGORY) => {
    dispatch(setCourseCategory(category))
    setCourseCategoryModalOpen(true)
  }

  const handleCloseCourseCategoryModal = () => {
    dispatch(resetCourseCategoryErrors())
    setCourseCategoryModalOpen(false)
  }

  const handleReorderCategories = () => {
    dispatch(setCategoriesOrdered(settingsFields.categories))
    setReorderingActive(true)
  }

  const handleConfirmNewOrder = () => {
    setReorderingActive(false)
    dispatch(setField({ field: 'categories', value: categoriesOrdered }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    dispatch(updateCommunityLandingPageSettings({
      id: community.id,
      communityData: settingsFields,
    }))
      .unwrap()
      .then(() => setConfirmationModalOpen(true))
  }

  return (
    <>
      <form id='landingPageSettings' className={classes['settings-form']} onSubmit={handleSubmit}>
        <div className='px-3 pb-4 d-flex flex-column'>
          <label htmlFor='landingPageUrl'>Link to Community Landing Page:</label>
          <a id='landingPageUrl' className={classes['link']} href={landingPageUrl} target='_blank'>{landingPageUrl}</a>
        </div>
        <div className='px-3 pb-4'>
          <label htmlFor='description' className='d-flex justify-content-between align-items-center'>
            Community Description
          </label>
          <p className={classnames('mb-2', classes['label-description'])}>
            If you leave this empty, it will show “Accepting new students” by default on your landing page.
          </p>
          <Input
            id='description'
            name='description'
            type='textarea'
            placeholder='Write a description'
            value={settingsFields.description}
            onChange={handleInputChange}
            styles={{ inputContainer: { height: '160px' } }}
          />
        </div>
        <div className='px-3 pb-4'>
          <label htmlFor='input-photo-upload'>Landing Page Header Image</label>
          <SimplePhotoUploader
            className='form-control photo-select'
            sendPhotoData={handlePhotoChange}
            selectedPhoto={settingsFields.headerImageUrl}
            placeholder='Upload a photo'
            error={attributeErrors?.headerImagePhoto[0]}
          />
        </div>
        <div className='px-3 pb-4'>
          <label htmlFor='bookingUrl'>Facility Private Lesson Booking Link</label>
          <p className={classnames('mb-2', classes['label-description'])}>
            Add a link here to promote your private lesson booking on your Community Landing Page. This will display a promotional link that will
            open your booking calendar of choice.
          </p>
          <Input
            id='bookingUrl'
            name='bookingUrl'
            type='text'
            placeholder='Provide a booking link'
            value={settingsFields.bookingUrl}
            onChange={handleInputChange}
          />
        </div>
        <div className='px-3 pb-3 overflow-hidden'>
          <label htmlFor='bookingUrl'>Program Categories</label>
          <p className={classnames('mb-4', classes['label-description'])}>
            Organize your group programs by creating custom categories. This will allow you to have control of how your programs are displayed on
            your landing page. You will also be able to run student and financial reporting based on these program types. Ex: Junior Op 36, Summer
            Camps, Adult Op 36. 1 Day Adult Camps.
          </p>
          {hasCategories ? (
            <CategoriesReorderingDnd
              isReorderingActive={isReorderingActive}
              categories={isReorderingActive ? categoriesOrdered : settingsFields.categories}
              handleEditButtonClick={handleOpenEditCategoryModal}
            />
          ) : (
            <div className={classnames('mb-3 d-flex justify-content-center align-items-center', classes['placeholder'])}>
              No available Categories
            </div>
          )}
        </div>
        <div className='px-3 d-flex justify-content-between'>
          {isReorderingActive ? (
            <>
              <CancelButton
                type='button'
                buttonMessage='Cancel'
                className={classes['left-button']}
                onClick={() => setReorderingActive(false)}
              />
              <SubmitButton
                noTopMargin
                type='button'
                buttonMessage='Save New Order'
                className={classes['right-button']}
                onClick={handleConfirmNewOrder}
              />
            </>
          ) : (
            <>
              <SubmitButton
                noTopMargin
                type='button'
                buttonMessage='Create New Category'
                iconType='addButton'
                className={classes['left-button']}
                onClick={() => {
                  handleOpenEditCategoryModal()
                }}
              />
              {hasCategories && (
                <SubmitButton
                  noTopMargin
                  type='button'
                  buttonMessage='Reorder Categories'
                  className={classes['right-button']}
                  onClick={handleReorderCategories}
                />
              )}
            </>
          )}
        </div>
      </form>
      <ConfirmationModal isOpen={isConfirmationModalOpen} onClose={() => setConfirmationModalOpen(false)} />
      <CourseCategoryEditModal
        isOpen={isCourseCategoryModalOpen}
        onClose={handleCloseCourseCategoryModal}
        communityId={community.id}
        courseCategoriesNumber={community.courseCategories.length}
      />
    </>
  )
}

export default SettingsForm
