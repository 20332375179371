// Based off of CommunityContactForm.js
import React, { Component } from "react";

import BaseModal from "../../common/BaseModal";

import "./FilterModal.scss";

const TYPE_FILTERS = ["Any", "Drill", "Coaching Activity"];
const VIS_FILTERS = ["Any", "Public", "Private"];

class FilterModal extends Component {
  constructor(props) {
    super(props);

    this.state = { ...this.props.filters };
  }

  render() {
    const { type, visibility, skills, tags } = this.state;

    const {
      close,
      applyHandler,
      drillSkills,
      showType,
      showVisibility,
      showSkills
    } = this.props;
    const allTags = this.props.tags;

    return (
      <BaseModal close={close} width={"70vw"}>
        <div id='DrillActivityFilterModal'>
          <p className='reset-filter' onClick={this._resetFilters}>
            Reset Filters
          </p>
          <h1>Filters</h1>
          <div className='drill-activity-filters'>
            {showType && (
              <div className='drill-activity-type-filters'>
                <h2>Type</h2>
                <div className='d-flex flex-wrap'>
                  {this._createRadioButtons(TYPE_FILTERS, "type")}
                </div>
              </div>
            )}
            {showVisibility && (
              <div className='drill-activity-visibility-filters'>
                <h2>Visibility</h2>
                <div className='d-flex flex-wrap'>
                  {this._createRadioButtons(VIS_FILTERS, "visibility")}
                </div>
              </div>
            )}
            {showSkills && (
              <div className='drill-activity-skill-filters'>
                <h2>Skills</h2>
                <div className='d-flex'>
                  {this._createCheckboxes(drillSkills, "skills")}
                </div>
              </div>
            )}
            <div className='drill-activity-tag-filters'>
              <h2>Tags</h2>
              <div className='d-flex flex-wrap'>
                {this._createTags(allTags, "tags")}
              </div>
            </div>
          </div>
          <div className='filter-modal-footer'>
            <button
              className='btn-rectangular btn-rectangular-main-negative'
              onClick={close}
            >
              Cancel
            </button>
            <button
              className='btn-rectangular btn-rectangular-main-positive'
              onClick={() => applyHandler({ ...this.state })}
            >
              Apply
            </button>
          </div>
        </div>
      </BaseModal>
    );
  }

  // Creates radio buttons for Type and Visibility
  _createRadioButtons = (labels, field) => {
    const current = this.state[field];
    return labels.map(item => (
      <div className='filter-radio-container' key={item}>
        <div
          className={
            "filter-radio-outer" + (current === item ? " radio-selected" : "")
          }
          onClick={() =>
            this._handleRadioButton(this._handleRadioButton(item, field))
          }
        >
          {current === item ? <div className='filter-radio-inner' /> : null}
        </div>
        <p className='filter-radio-label'>{item}</p>
      </div>
    ));
  };

  // Creates checkboxes for the Skills filter
  _createCheckboxes = (labels, arrName) => {
    return labels.map(item => {
      const checked = this.state[arrName].indexOf(item.id) > -1;
      return (
        // <div className='filter-checkbox-container' key={item.id}>
        //   <div
        //     className={"filter-checkbox" + (checked ? " checkbox-checked" : "")}
        //     onClick={() => this._handleCheckbox(item.id, arrName)}
        //   >
        //     {checked ? <i className='fa fa-check' aria-hidden='true' /> : null}
        //   </div>
        //   <p className='filter-checkbox-label'>{item.name}</p>
        // </div>
        <div key={item.id} className='skill-container'>
          <img
            src={item.photo && item.photo.file_url}
            alt='item.name'
            className={checked ? "" : "dim"}
            onClick={() => this._handleCheckbox(item.id, arrName)}
          />
          <p>{item.name}</p>
        </div>
      );
    });
  };

  // Creates selectable tags for the Keyword tags filter
  _createTags = (labels, arrName) => {
    return labels.map((item, idx) => {
      const checked = this.state[arrName].indexOf(item.id) > -1;
      return (
        <p
          key={idx}
          className={"filter-clickable-tag" + (checked ? " tag-checked" : "")}
          onClick={() => this._handleCheckbox(item.id, arrName)}
        >
          {item.name}
        </p>
      );
    });
  };

  _handleRadioButton = (val, field) => {
    const newVal = this.state[field];
    if (val !== newVal) {
      this.setState({ [field]: val });
    }
  };

  _handleCheckbox = (val, list) => {
    let arr = [...this.state[list]];
    if (arr.indexOf(val) === -1) {
      arr.push(val);
      this.setState({ [list]: arr });
    } else {
      let filteredArr = this.state[list].filter(el => el !== val);
      this.setState({ [list]: filteredArr });
    }
  };

  _resetFilters = () => {
    this.setState({ type: "Any", visibility: "Any", skills: [], tags: [] });
  };
}

export default FilterModal;
