import React from "react";
// import Op36Logo from "../../assets/images/logos/OP36_LogoLight.png";
import ActivityLoader from "react-loaders";

const styles = {
  container: {
    margin: "0 auto",
    marginTop: "",
    width: "90%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  image: {
    maxWidth: "250px"
  }
};

const Loader = ({ message, fullScreen }) => (
  <div style={{ ...styles.container, height: fullScreen && "100%" }}>
    <div className="row d-flex justify-content-center align-items-center p-2 animated fadeIn">
      <ActivityLoader type="ball-pulse" color={"lightgrey"} />
    </div>
    <h6
      className="text-center center-block mx-auto mt-2"
      style={{
        color: "#a3a3a3",
        textTransform: "uppercase",
        letterSpacing: "5px"
      }}
    >
      {message}
    </h6>
  </div>
);

export default Loader;
