import React, { Component } from "react";

// REDUX BITS
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as familyActions from "../../../actions/familyActions";

// CHILDREN
import Modal from "react-modal";
import StudentForm from "./StudentForm";
import AlertDialog from "../../common/AlertDialog";
import { withRouting } from "../../common/hooks";

class FamilyMemberModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      family_id: null,
      errorTitle: "Error",
      errorMessage: "Something went wrong, please try again later."
    };
  }

  // handleSubmit(newStudent) {
  //   // if (newStudent.first_name && newStudent.last_name && newStudent.password && newStudent.confirm_password && newStudent.user_name && newStudent.gender !== "0" && newStudent.birthYear !== "0" && newStudent.birthDay !== "0" && newStudent.birthMonth !== "0") {
  //   newStudent.family_id = this.props.family.id;
  //   // if (newStudent.password === newStudent.confirm_password) {

  //   this.props.family_actions.addFamilyMember(newStudent)
  // .then(() => this.props.family_actions.getFamily(this.props.user.family.id)
  //   .then(() => this.props.toggleModal()/*this.props.navigate('/classes'))*/))
  //   this.props.toggleModal();
  //   this.props.family_actions.getFamily(this.props.user.family.id)
  //     .then(res => {
  //       this.props.navigate('/classes')
  //     })
  // })
  // .catch(err => {
  // console.log(err)
  // if (err.response.status === 422) {
  //   // managing this stuff!
  //   var errors = err.response.data.errors
  //   console.log('err.response: ', err.response)
  //   console.log('errors', errors);
  //   if (errors.hasOwnProperty("email")) {
  //     this.setState({ errorMessage: errors.email[0] });
  //   }
  //   else if (errors.hasOwnProperty("username")) {
  //     this.setState({ errorMessage: "Username " + errors.username[0] });
  //   }
  //   else if (errors.hasOwnProperty("password")) {
  //     this.setState({ errorMessage: "Password " + errors.password[0] });
  //   }
  //   else {
  //     debugger
  //     this.setState({ errorMessage: "Something went wrong: " + errors + ". Please check your data and try again." });
  //   }
  //   this.setState({ errorTitle: "Error Adding Student" });
  //   this.refs.alertDialog.show();
  // }

  // else {
  //   this.setState({ errorTitle: "Error Adding Student" });
  //   this.refs.alertDialog.show();
  // }
  // this.props.toggleModal()
  // });
  // }
  // else {
  //   this.props.toggleModal()
  //   this.setState({ errorMessage: "Passwords do not match" })
  //   this.setState({ errorTitle: "Invalid Credentials" })
  //   this.refs.alertDialog.show();
  // }
  // }

  // else {
  //   // this.props.toggleModal()
  //   this.setState({ errorMessage: "Please fill out all of the fields before creating a student" })
  //   this.setState({ errorTitle: "Invalid Credentials" })
  //   this.refs.alertDialog.show();
  // }
  // }

  render() {
    let { showModal, toggleModal } = this.props;
    return (
      <div>
        <AlertDialog
          hideOnOverlayClicked={true}
          ref="alertDialog"
          showOverlay={true}
          title={this.state.errorTitle}
        >
          {this.state.errorMessage}
        </AlertDialog>
        <Modal
          isOpen={showModal}
          onRequestClose={toggleModal}
          contentLabel="FamilyMemberModal"
        >
          <div className="modal-header row">
            <div className="col-11">
              <h5 className="modal-title display-4">Add Family Member</h5>
            </div>
            <div className="col-1">
              <button
                type="button"
                className="close pull-right font-200"
                data-dismiss="modal"
                aria-label="Close"
                onClick={toggleModal}
              >
                <span aria-hidden="true">✕</span>
              </button>
            </div>
          </div>
          <div className="modal-content">
            <div className="modal-body" id="input-border">
              <div className="row mb-2 p-2">
                <div className="col">
                  <div className="form-group">
                    {<StudentForm getMinorityStatus closeModal={toggleModal} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user.current_user,
    family: state.family
    // errors: state.ui.error
  };
};

const mapDispatchToProps = dispatch => {
  return {
    family_actions: bindActionCreators(familyActions, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouting(FamilyMemberModal))
