import * as types from "../actions/actionTypes";
// import update from 'immutability-helper';
// state = [] : Start out with no accounts
export default function challenges(state = [], action) {
  switch (action.type) {
    case types.GET_ALL_CHALLENGES:
      return action.challenges; // should be an array of 'challenge' objects

    case types.CREATE_CHALLENGE:
      let stateCopy = [...state];
      stateCopy.push(action.challenge);
      return stateCopy;

    case types.UPDATE_CHALLENGE:
      let newState = state.map(c => {
        if (c.id === action.challenge.id) {
          return action.challenge;
        } else {
          return c;
        }
      });
      return newState;
    case types.DESTROY_CHALLENGE:
      return state.filter(c => c.id !== action.challenge.id);
    default:
      return state;
  }
}
