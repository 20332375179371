import * as types from "../actions/actionTypes";

// state = [] : Start out with no accounts
export default function resourceSection(state = [], action) {
  switch (action.type) {
    case types.GET_RESOURCE_SECTION:
      return action.resource_section;

    case types.UPDATE_RESOURCE_SECTION:
      return Object.assign({}, state, {
        resourceSection: action.resource_section
      });

    default:
      return state;
  }
}
