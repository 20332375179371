import React from "react";
import Placeholder from "../../../common/Placeholder";
import Loader from "../../../common/Op36Loader-web";
import StudentPreviewCard from "../../../common/StudentPreviewCard";

const renderTemplateStudents = (loading, students) => {
  if (loading) return <Loader message={"Loading Students"} />;
  if (students && students.length > 0)
    return students.map(student => (
      <StudentPreviewCard
        preview
        student={student}
        key={student.id}
      />
    ));

  return (
    <div className="w-100">
      <Placeholder
        title={"No Students Assigned"}
        icon={"fa-users"}
        subtext1={"There are currently no students assigned here"}
        subtext2={"Click 'Add Students' to assign students"}
      />
    </div>
  );
};

const TemplateStudentsCard = ({ toggleModal, loading, students }) => {
  return (
    <div className="card mt-15px p-0 col-md-12">
      <h5 className="p-2 card-header-text">Students</h5>
      <div className="p-4">
        <div className="row w-100 mx-auto">
          {renderTemplateStudents(loading, students)}
        </div>
      </div>
      <div className="custom-goal-button-wrapper px-5">
        <button
          className="btn btn-primary btn-block custom-goal-btn"
          onClick={toggleModal}
        >
          Add Students
        </button>
      </div>
    </div>
  );
};

export default TemplateStudentsCard;
