import styles from './CoachDashboardHome.module.scss'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const QuickActionItem = ({ title, icon, link, externalLink }) => (
  externalLink ?
    <a href={externalLink} target='_blank' className={styles.quickAction}>
      <div className={styles.iconWrapper}>
        {icon}
      </div>
      <div className={styles.titleWrapper}>
        <p className={styles.quickActionTitle}>{title}</p>
      </div>
    </a>
    :
    <Link to={link} className={styles.quickAction}>
      <div className={styles.iconWrapper}>
        {icon}
      </div>
      <div className={styles.titleWrapper}>
        <p className={styles.quickActionTitle}>{title}</p>
      </div>
    </Link>
)

QuickActionItem.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.object,
  link: PropTypes.string,
  externalLink: PropTypes.string,
}

export default QuickActionItem
