module.exports = [
  "'The game isn’t going to grow itself.' - Op 36 Network",
  "'Go to your home ball!  Are you too good for your home!' - Happy Gilmore",
  "'Just tap it in … tap tap taparoo.' - Happy Gilmore",
  "'Your gonna die clown!' - Happy Gilmore",
  "'Ooooooh Somebody’s closer...' - Happy Gilmore",
  "'The price is wrong Bob!' - Happy Gilmore",
  "'Ah Shoota!' - Happy Gilmore",
  "'You’re in my world now Grandma.' - Happy Gilmore",
  "'Be the ball, Danny, be the ball.' - Caddyshack",
  "'Thank you very little.' - Caddyshack",
  "'We didn’t come here to layup.' - Caddyshack",
  "'Na Na na na na na na na na' - Caddyshack",
  "'He's got about 195 yards left, and he's got a, looks like he's got about an 8 iron.' - Caddyshack",
  "'Make more birdies.' - Tin Cup",
  "'Just Grip it and Rip it.' - Tin Cup",
  "'I didn’t come here to lay-up.' - Tin Cup",
  "'Of all the Hazards, fear is the worst.' - Sam Snead",
  "'Hit the shot you know you can hit, not the one you think you should.' - Bob Rotella",
  "'Success in this game depends less on strength of body than strength of mind and character.' - Arnold Palmer",
  "'A good golfer has the determination to win and the patience to wait for the breaks.' - Gary Player",
  "'As you walk down the fairway of life you must smell the roses, for you only get to play one round.' - Ben Hogan",
  "'I could break the club record, theoretically!' - Caddyshack",
];
