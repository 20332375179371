import * as types from '../actions/actionTypes';

// state = [] : Start out with no accounts
export default function leaderboard(state = [], action){
  switch(action.type){
    case types.ADD_WEEKLY_LEADERBOARD:
      return Object.assign({}, state, {
        weekly_leaderboard: action.weekly_leaderboard
      });
    case types.ADD_MONTHLY_LEADERBOARD:
      return Object.assign({}, state, {
        monthly_leaderboard: action.monthly_leaderboard
      });
    case types.ADD_ALL_TIME_LEADERBOARD:
      return Object.assign({}, state, {
        all_time_leaderboard: action.all_time_leaderboard
      });
    default:
      return state;
  }
}