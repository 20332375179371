import React from 'react';

import './FooterZone.scss';

const footerZone = (props) => (
  <div className="footer-zone">
    <p className="footer-zone-text">{props.callToAction}</p>
  </div>
);

export default footerZone;
