import React from "react";
import "./UserAvatarForm.scss";
import profile_avatar from "../../../assets/images/mock/StockProfilePic.png";

// CONSTANTS
const ACCETPABLE_MIME_TYPES = "image/*"; // Allow all image types-- some mobile devices will trigger hardware camera when enabled.

const divStyles = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center"
};

const UserAvatarForm = ({
  user,
  preview,
  handleAvatarChange,
  avatarEditState,
  toggleAvatarEditState,
  isProfile
}) => (
  <div id="UserAvatarForm" style={divStyles}>
    <div className="large-round-image-wrapper">
      {user.avatar && user.avatar.file_url ? (
        <img
          className={"round-image"}
          src={preview || (user.avatar && user.avatar.file_url)}
          alt={`${user.first_name} ${user.last_name}`}
        />
      ) : (
        <img
          className={"round-image"}
          src={preview || profile_avatar}
          alt={`${user.first_name} ${user.last_name}`}
        />
      )}
    </div>
    {avatarEditState ? (
      <input
        type="file"
        className="my-2 photo-input"
        accept={ACCETPABLE_MIME_TYPES}
        onChange={handleAvatarChange}
      />
    ) : (
      !isProfile && (
        <button
          className="my-2 btn btn-primary text-white"
          onClick={toggleAvatarEditState}
        >
          Update
        </button>
      )
    )}
  </div>
);

export default UserAvatarForm;
