import React, { Component } from "react";
import _ from "lodash";

class ResourceCategoryForm extends Component {
  render() {
    const {
      formState,
      loader,
      handleInputChange,
      toggleForm,
      isEditModal,
      createResourceCategory
    } = this.props;
    return (
      <div id="ResourceCategoryForm" className="row animated fadeIn">
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="resource_category_name">Category Name</label>
            <input
              type="text"
              name="resource_category_name"
              className="form-control"
              id="resource_category_name"
              placeholder="Category Name"
              onChange={handleInputChange}
              value={formState.resource_category_name}
            />
          </div>
          <div className="form-group">
            <label htmlFor="resource_category_description">Description</label>
            <textarea
              type="text"
              name="resource_category_description"
              className="form-control"
              id="resource_category_description"
              placeholder="Description"
              onChange={handleInputChange}
              value={formState.resource_category_description}
            />
          </div>
        </div>

        <div className="col-md-12 d-flex justify-content-center align-items-center mt-4">
          <button
            className="btn btn-dark m-2"
            onClick={() => toggleForm("resource")}
            disabled={loader}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary m-2"
            onClick={createResourceCategory}
            disabled={loader}
          >
            {loader && (
              <i className="fa fa-spinner fa-spin light-loading-spinner" />
            )}
            {isEditModal ? "Edit" : `Add${loader ? "ing" : ""}`} Category
          </button>
        </div>
      </div>
    );
  }
}
export default ResourceCategoryForm;
