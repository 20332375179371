import * as types from "../actions/actionTypes";
// import update from 'immutability-helper';
// state = [] : Start out with no accounts
export default function leadNotes(state = [], action) {
  switch (action.type) {
    case types.GET_LEAD_NOTES:
      return action.notes;

    case types.CREATE_LEAD_NOTE:
      return [...state, Object.assign({}, action.note)];

    default:
      return state;
  }
}
