import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import AnnouncementModal from "./AnnouncementModal";
import SimplePreviewModal from "./SimplePreviewModal";
import AnnouncementTable from "./AnnouncementTable";
import PageHeader from '../common/PageHeader/PageHeader'
import ValidationModal from "../common/ValidationModal";

import Pagination from "../common/Pagination";

import "./AnnouncementsPage.scss";

import * as fadeAnimation from "../common/fadeAnimation";
import Loader from "../common/Op36Loader";

import * as commonActions from "../../actions/commonActions";
import * as userActions from "../../actions/userActions";
import * as announcementsActions from "../../actions/announcementsActions";
import * as activityActions from "../../actions/activityActions";
import Placeholder from "../common/Placeholder";
import { withRouting } from "../common/hooks";

class AnnouncementsPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showAnnouncementsModal: false,
      emailSent: false,
      courseOptions: [],
      in: false,
      announcementLoader: true,
      createAnnouncementLoader: false,
      showValidationModal: false,
      showSimplePreviewModal: false,
      validationSuccess: true,
      validationMessage: "",
      announcement: {},
      per_page: 20,
    };

    // this.openAnnouncementsModal = this.openAnnouncementsModal.bind(this);
    this.closeAnnouncementsModal = this.closeAnnouncementsModal.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.showErrors = this.showErrors.bind(this);
  }

  componentDidMount() {
    const { common_actions } = this.props;
    common_actions.setCurrentTab("Announcements");
    common_actions.showSideBar(true);
    common_actions.showNavBar(true);
    this.props.announcements_actions
      .getAnnouncements(1, this.state.per_page)
      .then((res) => {
        this.setState({
          announcementLoader: false,
        });
      });
    this.toggleEnterState();
  }

  fetchAnnouncements = (page) => {
    const { per_page } = this.state;
    this.setState({ announcementLoader: true }, () => {
      this.props.announcements_actions
        .getAnnouncements(page, per_page)
        .then((res) => {
          this.setState({ announcementLoader: false });
        });
    });
  };

  toggleEnterState = () => {
    setTimeout(
      function () {
        this.setState({ in: true });
      }.bind(this),
      fadeAnimation.FADE_DURATION
    );
  };

  toggleSimplePreviewModal = (announcement) => {
    this.setState({
      announcement: announcement,
      showSimplePreviewModal: true,
    });
  };

  closeSimplePreviewModal = () => {
    this.setState({
      showSimplePreviewModal: false,
    });
  };

  openNewCampaign = () => {
    this.props.navigate('/announcements/new-campaign')
  };

  closeAnnouncementsModal() {
    this.setState({ showAnnouncementsModal: false });
  }

  toggleValidationModal = (success, message) => {
    this.setState({
      showValidationModal: true,
      validationMessage: message,
      validationSuccess: success,
    });
  };

  closeValidationModal = () => {
    this.setState({
      showValidationModal: false,
    });
  };

  showErrors() {
    if (this.state.errors) {
      return (
        <div id='formErrors'>
          <p>
            <span className='icon icon-info' />
            {this.state.errors}
          </p>
        </div>
      );
    } else {
      return "";
    }
  }

  handleSubmit(newAnnouncement) {
    if (
      newAnnouncement.courses.length > 0 &&
      newAnnouncement.subject &&
      newAnnouncement.message
    ) {
      this.setState({
        createAnnouncementLoader: true,
      });
      this.props.announcements_actions
        .postAnnouncement(newAnnouncement)
        .then((announcement) => {
          // this.props.activity_actions.postActivityAnnouncement(announcement).then(res => {
          // alert("Email has been sent.");
          this.toggleValidationModal(
            true,
            "Your email has successfully been sent"
          );
          this.closeAnnouncementsModal();
          this.setState({ emailSent: true, createAnnouncementLoader: false });
          // this.closeAnnouncementsModal();
          // });
        })
        .catch((err) => {
          this.toggleValidationModal(
            false,
            "Something went wrong, please try again"
          );
          this.setState({
            createAnnouncementLoader: false,
          });
        });
    } else {
      this.toggleValidationModal(false, "Please fill out all of the fields");
    }
  }

  renderAnnouncements = () => {
    const { announcements } = this.props;
    if (announcements && announcements.length > 0) {
      return (
        <AnnouncementTable
          announcements={announcements}
          toggleModal={this.toggleSimplePreviewModal}
        />
      );
    }
    return (
      <div className='col-6 mx-auto mt-5'>
        <Placeholder
          title={"No messages have been created"}
          icon={"fa-envelope"}
          subtext1={"You currenly have not created any messages."}
          subtext2={"Click the button below to add a new message."}
          buttonText={"New Message"}
          buttonIcon={"fa-plus"}
          handleClick={() => this.openNewCampaign()}
        />
      </div>
    );
  };

  headerActionButton = () => (
    <button
      className='btn btn-primary text-white pull-right mr-2'
      onClick={this.openNewCampaign}
    >
      New Message
    </button>
  )

  render() {
    return (
      <div id='AnnouncementsPage'>
        <fadeAnimation.Fade in={this.state.in}>
          {this.state.in ? (
            <div>
              <PageHeader
                title='Send an Email'
                buttonLabel='New Message'
                actionComponent={this.headerActionButton()}
              />

              {this.state.announcementLoader ? (
                <div
                  className=''
                  style={{
                    marginTop: "50px",
                    width: "100%",
                  }}
                >
                  <Loader message='loading messages' />
                </div>
              ) : (
                this.renderAnnouncements()
              )}
              <div className='row'>
                {this.props.pages > 1 && (
                  <Pagination
                    pageCount={this.props.pages}
                    fetchData={this.fetchAnnouncements}
                  />
                )}
              </div>
              <AnnouncementModal
                show={this.state.showAnnouncementsModal}
                web={true}
                emailSent={this.state.emailSent}
                onClose={this.closeAnnouncementsModal}
                close={this.closeAnnouncementsModal}
                courseOptions={this.state.courseOptions}
                handleSubmit={this.handleSubmit}
                loader={this.state.createAnnouncementLoader}
              />
              <ValidationModal
                validationSuccess={this.state.validationSuccess}
                showValidationModal={this.state.showValidationModal}
                closeValidationModal={this.closeValidationModal}
                validationMessage={this.state.validationMessage}
              />
              <SimplePreviewModal
                closeModal={this.closeSimplePreviewModal}
                toggleModal={this.toggleSimplePreviewModal}
                showModal={this.state.showSimplePreviewModal}
                announcement={this.state.announcement}
              />
            </div>
          ) : (
            <div />
          )}
        </fadeAnimation.Fade>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    ui: state.ui,
    current_user: state.user.current_user,
    courses: state.courses.courses,
    announcements: state.announcements,
    pages: state.pages,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    common_actions: bindActionCreators(commonActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
    announcements_actions: bindActionCreators(announcementsActions, dispatch),
    activity_actions: bindActionCreators(activityActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouting(AnnouncementsPage))
