import styles from './CoachDashboardOp36.module.scss'
import CoachDashboardStats from './CoachDashboardStats'
import CoachDashboardAllTimeTotals from './CoachDashboardAllTimeTotals'
import CoachDashboardCommunityLeaderboard from './CoachDashboardCommunityLeaderboard'

const CoachDashboardOp36 = () => (
  <div className={styles.coachDashboardOp36}>
    <CoachDashboardStats />
    <div className={styles.dashboardContent}>
      <CoachDashboardCommunityLeaderboard />
      <CoachDashboardAllTimeTotals />
    </div>
  </div>
)

export default CoachDashboardOp36
