import React from "react";
import NumericScoringCard from "./NumericScoringCard";

import "./ScoreNamePopover.scss";
import StarScoringCard from "./StarScoringCard";

class ScoreNamePopover extends React.Component {
  componentWillMount() {
    document.addEventListener("click", this._handleClick, false);
  }
  componentWillUnmount() {
    document.removeEventListener("click", this._handleClick, false);
  }

  render() {
    const { selectedScore } = this.props;
    return (
      <div
        id='ScoreNamePopover'
        ref={(node) => (this.node = node)}
        onClick={this._anotherClick}
      >
        <div
          className='d-flex align-items-center selection-line'
          onClick={this._setNumeric}
        >
          <div className='check-container'>
            {selectedScore === "numeric" && (
              <i className='fa fa-check' aria-hidden='true' />
            )}
          </div>
          <NumericScoringCard />
        </div>
        <div
          className='d-flex align-items-center selection-line'
          onClick={this._setStar}
        >
          <div className='check-container'>
            {selectedScore === "star" && (
              <i className='fa fa-check' aria-hidden='true' />
            )}
          </div>
          <StarScoringCard />
        </div>
      </div>
    );
  }

  _setNumeric = (e) => {
    e.stopPropagation();
    this.props.setScoreInfo("score_name", "numeric");
    this.props.close();
  };

  _setStar = (e) => {
    e.stopPropagation();
    this.props.setScoreInfo("score_name", "star");
    this.props.close();
  };

  _handleClick = (e) => {
    // just tracks if the user clicks outside the component to close the popover
    if (!this.node.contains(e.target)) {
      this.props.close();
    }
  };

  _anotherClick = (e) => {
    // stops from click event on whitespace of component selecting this session to display on the page
    e.stopPropagation();
  };
}

export default ScoreNamePopover;
