import * as types from '../actions/actionTypes';

// state = [] : Start out with no accounts
export default function ui(state = [], action) {
  switch (action.type) {

    case types.SET_CURRENT_TAB:
      return Object.assign({}, state, {
        currentTab: action.currentTab
      });

    case types.UI_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        isLoading: false
      });

    case types.IS_SHOW_ACTIVITY_MENU:
      return Object.assign({}, state, {
        showActivityMenu: action.showActivityMenu
      });

    case types.IS_SHOW_NAV_BAR:
      return Object.assign({}, state, {
        showNavBar: action.showNavBar
      });

    case types.IS_SHOW_SIDE_BAR:
      return Object.assign({}, state, {
        showSideBar: action.showSideBar,
      })

    case types.SET_SIDE_BAR_STATE:
      return { ...state, sideBarState: action.sideBarState }

    default:
      return state
  }
}
