import React, { Component } from "react";
import moment from "moment";
import { PropTypes } from 'prop-types'

import ProfileAvatar from "../../assets/images/mock/StockProfilePic.png";
import Op36Logo from "../../assets/images/logos/OP36_clean_icon_dark.png";

import "./RoundsReportPrint.scss";

class RoundsReportPrint extends Component {
  render() {
    let user = this.props.user;
    return (
      <div className='print-container m-5'>
        <div className='print-header-row'>
          <div>
            <div className='row py-4 d-flex align-items-center print-top-header'>
              <div className='d-flex flex-row align-items-center'>
                <div className='round-image-wrapper'>
                  <img
                    className='round-image'
                    src={user.avatar ? user.avatar.file_url : ProfileAvatar}
                    alt={`${user.first_name} ${user.last_name}`}
                  />
                </div>
                <div className='print-user-info-wrapper'>
                  <h3 className='print-name-text'>
                    {user.first_name} {user.last_name}
                  </h3>
                  {user.birthdate ? <div className='text-divider' /> : null}
                  <h5 className={`print-birthdate-text`}>
                    {user.birthdate ? this._getAge(user) : null}
                  </h5>
                </div>
              </div>
              <div>
                <p className='print-date-text'>
                  {moment().format("MM/DD/YYYY")}
                </p>
              </div>
            </div>
            <div className='row p-1  border-bottom'>
              <div className='col-2 d-flex flex-column justify-content-end'>
                <h6 className='text-center print-report-header'>Level</h6>
              </div>
              <div className='col-2 d-flex flex-column justify-content-end'>
                <h6 className='text-center print-report-header'>Status</h6>
              </div>
              <div className='col-2 d-flex flex-column justify-content-end'>
                <h6 className='text-center print-report-header'>Attempts</h6>
              </div>
              <div className='col-2 d-flex flex-column justify-content-end'>
                <h6 className='text-center print-report-header'>1st Score</h6>
              </div>
              <div className='col-2 d-flex flex-column justify-content-end'>
                <h6 className='text-center print-report-header'>Best Score</h6>
              </div>
              <div className='col-2 d-flex flex-column justify-content-end'>
                <h6 className='text-center print-report-header'>
                  Strokes Lost
                </h6>
              </div>
            </div>
            {this._renderDivisions()}
          </div>
        </div>
        {/* <img
          src={DivisionCourse}
          alt='Level Course'
          className='print-division-course'
        /> */}
        <div className='row py-2 mt-5 d-flex align-items-center flex-row border-top border-bottom print-bottom-footer'>
          <div className='d-flex align-items-center flex-row'>
            <div className='small-round-image-wrapper'>
              <img
                className='round-image'
                src={
                  user.communities
                    ? user.communities[0].photo.file_url
                    : ProfileAvatar
                }
                alt={"Community Logo"}
              />
            </div>
            <p className='ml-2 mb-0 print-community-name'>
              {user.communities[0].name}
            </p>
          </div>
          <div className='d-flex align-items-center flex-row print-op36-footer-wrapper'>
            <div className='small-round-image-wrapper'>
              <img
                className='round-image p-1'
                src={Op36Logo}
                alt={"Operation 36 Logo"}
              />
            </div>
            <div className='print-footer-text-wrapper'>
              <p className='mb-0 print-op36-text'>Powered By Operation 36</p>
              <p className='mb-0 print-op36-copyright'>
                Copyright © {this._renderYear()} - Operation 36 Golf LLC
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderYear = () => {
    let today = new Date();
    return today.getFullYear();
  };

  _renderDivisions = () => this.props.division.divisions.map((info) => (
    <div className='row p-0 border-bottom' key={info.division_id}>
      <div
        className={
          'col-2 p-0 d-flex flex-column justify-content-center text-white objective-rank-' +
          info.division_id +
          ' border-division-' +
          info.division_id
        }
      >
        <h6 className='text-center print-division-row-text'>
          Level {info.division_id}
        </h6>
        <p className='text-center mb-0 print-division-yardage'>
          {this._grabYardage(info.division_id)}
        </p>
      </div>
      <div className='col-2 py-3 d-flex justify-content-center align-items-center'>
        <div
          className='text-center mb-0'
          style={{
            color: this._renderStatusColor(
              info.nine_hole.division_attempts
                ? info.nine_hole.division_attempts
                : null,
              info.nine_hole.record_score
            ),
          }}
        >
          {info.nine_hole.record_score && info.nine_hole.record_score <= 36
            ? 'PASSED ' + String.fromCharCode(0x2713)
            : this._checkPace(info.nine_hole.division_attempts)}
        </div>
      </div>
      <div className='col-2 py-3 d-flex justify-content-center align-items-center'>
        <h1 className='text-center'>
          {info.nine_hole.division_attempts
            ? info.nine_hole.division_attempts
            : 0}
        </h1>
      </div>
      <div className='col-2 py-3 d-flex justify-content-center align-items-center flex-column'>
        <h5 className='text-center mb-0'>
          {info.nine_hole.first_score ? info.nine_hole.first_score : '---'}
        </h5>
      </div>
      <div className='col-2 py-3 d-flex justify-content-center align-items-center flex-column'>
        <h5 className='text-center mb-0'>
          {info.nine_hole.record_score
            ? info.nine_hole.record_score
            : '---'}
        </h5>
      </div>
      <div className='col-2 py-3 d-flex justify-content-center align-items-center'>
        <h1 className='text-center'>
          {info.nine_hole.record_score && info.nine_hole.first_score
            ? info.nine_hole.first_score - info.nine_hole.record_score
            : '---'}
        </h1>
      </div>
    </div>
  ))

  _getAge = (user) => {
    let years = moment().diff(user.birthdate, "years");

    return "Age " + years;
  };

  _checkPace = (attempts) => {
    if (!attempts) {
      return "---";
    } else if (attempts <= 20) {
      return "Ahead of Pace";
    } else {
      return "On Pace";
    }
  };

  _renderStatusColor = (attempts, bestScore) => {
    if (bestScore && bestScore <= 36) {
      return '#ff6633'
    }
    if (!attempts) {
      return "black";
    }
    if (attempts >= 1 && attempts <= 20) {
      return "#33d9b2";
    } else if (attempts > 20) {
      return "#FFFF00";
    } else {
      return "black";
    }
  };

  _grabYardage = (div) => {
    switch (div) {
      case 1:
        return "225 yards";
      case 2:
        return "450 yards";
      case 3:
        return "900 yards";
      case 4:
        return "1350 yards";
      case 5:
        return "1800 yards";
      case 6:
        return "1801 - 2300 yards";
      case 7:
        return "2301 - 2600 yards";
      case 8:
        return "2601 - 2900 yards";
      case 9:
        return "2901 - 3200 yards";
      case 10:
        return "3201 + yards";
      default:
        return "";
    }
  };
}

RoundsReportPrint.propTypes = {
  division: PropTypes.shape({
    divisions: PropTypes.array,
  }),
}

export default RoundsReportPrint;
