import React from "react";

import playIcon from "../../../assets/images/activity-icons/play-open-opaque.png";
import trainIcon from "../../../assets/images/activity-icons/train-open-opaque.png";
import socialIcon from "../../../assets/images/activity-icons/social-open-opaque.png";

import "./PhotoWall.scss";

const MAX_ROWS_COLUMNS = 6;
const ACTIVITIES = {
  activityPlay: {
    // Play
    color: "#33d9b2",
    icon: playIcon
  },
  activityPractice: {
    // Train
    color: "#34ace0",
    icon: trainIcon
  },
  activitySocial: {
    // Social
    color: "#6677de",
    icon: socialIcon
  },
  activityChallenge: {
    // Social Challenge
    color: "#6677de",
    icon: socialIcon
  }
};

const photoWall = props => {
  const photoData = props.photoWall;
  let photos = [];

  // If no photos (photoWall.length === 0), display message asking members to upload
  // Else, grab appropriate number of photos and add to photos array
  if (photoData.length > 0) {
    // Figure out how many rows/columns to get (numRows = numCols)
    let grid = 1; // # of rows/columns
    for (let i = 1; i <= MAX_ROWS_COLUMNS && i * i <= photoData.length; i++) {
      if (i * i <= photoData.length) {
        grid = i;
      }
    }

    // Get i * i photos from the array and map them to photo divs
    photos = photoData.slice(0, grid * grid).map((pic, id) => {
      // Get icon and color based on activity type
      // ("ActivityPlay", "ActivityPractice", or "ActivitySocial")
      const actType = pic.type[0].toLowerCase() + pic.type.substr(1);
      const actColor = ACTIVITIES[actType].color;
      const actIcon = ACTIVITIES[actType].icon;

      return (
        <div
          style={{
            backgroundImage: "url(" + pic.photo.large_file_url + ")",
            border: "4px solid " + actColor,
            width: "calc(100% / " + grid + ")",
            height: "calc(100% / " + grid + ")"
          }}
          key={id}
          className='photo-wall-photo'
        >
          <img
            src={actIcon}
            alt='activity icon'
            className='photo-wall-tag-icon'
          />
        </div>
      );
    });
  } else {
    photos = (
      <div className='photo-wall-no-photo-placeholder'>
        <h1>No recent photos to show.</h1>
        <h2>
          Log a Play, Train, or Social action with a photo to see it up here!
        </h2>
        <img
          src={playIcon}
          alt='play icon'
          className='photo-wall-placeholder-icon'
        />
        <img
          src={trainIcon}
          alt='train icon'
          className='photo-wall-placeholder-icon'
        />
        <img
          src={socialIcon}
          alt='social icon'
          className='photo-wall-placeholder-icon'
        />
      </div>
    );
  }

  return <div className='photo-wall-display-area'>{photos}</div>;
};

export default photoWall;
