import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import * as constants from '../../misc/Constants'
import EventRow from './EventRow'
import Placeholder from '../common/Placeholder'
import Loader from '../common/Op36Loader-web'
import Pagination from '../common/Pagination'

import styles from './Events.module.scss'
import colors from '../../assets/styles/globals.scss'
import { ReactComponent as GolferIcon } from '../../assets/images/common/icons/golfer-half-icon.svg'

const EventsList = ({ events, loader, searchTerm, activePage, setActivePage }) => {
  const pageCount = useSelector(state => state.events.pagination?.total_pages)
  const timezone = useSelector(state => state.user.current_user.communities[0].timezone)

  if (loader) {
    return (
      <div className='col-md-6 mx-auto'>
        <Loader message='loading events' />
      </div>
    )
  }

  if (events?.length === 0) {
    if (searchTerm) {
      return (
        <div className='mt-5 w-100'>
          <Placeholder
            title={'No Event Results Found'}
            icon={'fa-search'}
            subtext1={'Nothing matched the search criteria'}
            subtext2={'Please try again'}
          />
        </div>
      )
    } else {
      return (
        <div className={styles['no-events-placeholder']}>
          <GolferIcon id='golferIcon' fill={colors.lightNavy} width={'64px'} height={'64px'}/>
          <p className={styles['placeholder-title']}>No 9 Hole Events Logged</p>
          <div className={styles['placeholder-description']}>{constants.EVENTS_PLACEHOLDER}</div>
        </div>
      )
    }
  }

  return (
    <>
      {pageCount > 1 && (
        <Pagination
          pageCount={pageCount}
          activePage={activePage}
          fetchData={(newPage) => setActivePage(newPage)}
        />
      )}
      {events?.map((event) => (
        <div className='row' key={event.id}>
          <EventRow event={event} timezone={timezone}/>
        </div>
      ))}
    </>
  )
}

EventsList.propTypes = {
  events: PropTypes.array,
  loader: PropTypes.bool,
  searchTerm: PropTypes.string,
  activePage: PropTypes.number,
  setActivePage: PropTypes.func,
}

export default EventsList
