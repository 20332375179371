import React from "react";

import "./CoachAd.scss";

const coachAd = (props) => (
  <div className="coach-ad-wrapper">
    <img className="coach-ad-img"
      src={props.adSrc}
      alt="Coach Advertisement" />
  </div>
);

export default coachAd;
