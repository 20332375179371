import React from "react";
import { baseUrl } from "./baseUrl";
import { isValidEmail } from '../../utility/helperFunctions'

import Axios from "axios";

import "./NoCommunityModal.scss";

class NoCommunityModal extends React.Component {
  state = {
    name: "",
    email: "",
    phone: "",
    location: "",
    fam_count: 1,
    note: "",
    failedValidation: false,
    submitClicked: false,
    interests: [],
    skillLevel: [],
    success: false, // sucessfully submitted info
    invalidEmail: false,
    emailOptIn: true,
  };

  componentDidMount() {
    this.setState({ location: this.props.location });
  }
  render() {
    return (
      <div id='NoCommunityModal'>
        <i
          className='fa fa-times exit'
          aria-hidden='true'
          onClick={this.props.close}
        />
        {this.state.success ? (
          <div className='success-container'>
            <i className='fa fa-check-circle check' aria-hidden='true' />
            <p className='success-title'>Success!</p>
            <p className='success-note'>
              Thanks for letting us know about your interest! Someone from
              Operation 36 support team will reach out to you soon.
            </p>
          </div>
        ) : (
          <div>
            <p className='req-form-title'>
              Get in touch with Operation 36 Golf
            </p>
            <p className='req-form-intro'>
              No facility nearby? Don't worry! Please tell us a bit about
              yourself and what you're looking for, and we'll try to set up a
              location near you.
            </p>
            <label
              htmlFor='req-form-name'
              className={this.state.failedValidation ? "red-text" : ""}
            >
              Name
            </label>
            <input
              name='name'
              type='text'
              id='req-form-name'
              placeholder='Name'
              value={this.state.name}
              onChange={this._handleInputChange}
              autoComplete='new-name'
              className={this.state.failedValidation ? "red-line" : ""}
              maxLength='35'
            />
            <label
              htmlFor='req-form-email'
              className={this.state.failedValidation ? "red-text" : ""}
            >
              Email
            </label>
            <input
              name='email'
              type='email'
              id='req-form-email'
              placeholder='Email'
              value={this.state.email}
              onChange={this._handleInputChange}
              autoComplete='new-email'
              className={this.state.failedValidation ? "red-line" : ""}
              maxLength='35'
            />
            <div className='split-box'>
              <div className='phone-area'>
                <label htmlFor='req-form-phone'>
                  Phone number <span className='extra-text'>(optional)</span>
                </label>
                <input
                  name='phone'
                  type='tel'
                  id='req-form-phone'
                  placeholder='Phone number'
                  value={this.state.phone}
                  onChange={this._handleInputChange}
                  autoComplete='new phone'
                  maxLength='15'
                />
              </div>
              <div className='location-area'>
                <label htmlFor='req-form-location'>Location</label>
                <input
                  name='location'
                  type='text'
                  id='req-form-location'
                  placeholder='City, State'
                  value={this.state.location}
                  onChange={this._handleInputChange}
                  className={this.state.failedValidation ? "red-line" : ""}
                  autoComplete='new location'
                  maxLength='25'
                />
              </div>
            </div>
            <p className='req-form-family-count'>
              Number of interested family members:
            </p>
            <div className='family-count-box'>
              <div className='radio-container'>
                <div
                  className='radio-outer'
                  onClick={() => this._changeFamilyCount(1)}
                >
                  {this.state.fam_count === 1 ? (
                    <div className='radio-inner' />
                  ) : null}
                </div>
                <p className='radio-label'>1</p>
              </div>
              <div className='radio-container'>
                <div
                  className='radio-outer'
                  onClick={() => this._changeFamilyCount(2)}
                >
                  {this.state.fam_count === 2 ? (
                    <div className='radio-inner' />
                  ) : null}
                </div>
                <p className='radio-label'>2</p>
              </div>
              <div className='radio-container'>
                <div
                  className='radio-outer'
                  onClick={() => this._changeFamilyCount(3)}
                >
                  {this.state.fam_count === 3 ? (
                    <div className='radio-inner' />
                  ) : null}
                </div>
                <p className='radio-label'>3</p>
              </div>
              <div className='radio-container'>
                <div
                  className='radio-outer'
                  onClick={() => this._changeFamilyCount(4)}
                >
                  {this.state.fam_count === 4 ? (
                    <div className='radio-inner' />
                  ) : null}
                </div>
                <p className='radio-label'>4</p>
              </div>
              <div className='radio-container'>
                <div
                  className='radio-outer'
                  onClick={() => this._changeFamilyCount(5)}
                >
                  {this.state.fam_count === 5 ? (
                    <div className='radio-inner' />
                  ) : null}
                </div>
                <p className='radio-label'>5+</p>
              </div>
            </div>
            <div className='checkbox-area'>
              <div className='checkbox-container'>
                <p
                  className={`checkbox-title ${
                    this.state.failedValidation ? "red-text" : ""
                  }`}
                >
                  Interests:
                </p>
                <div className='check-row'>
                  <div
                    className='check-box'
                    onClick={() => this._handleCheckBox("interests", "adult")}
                  >
                    {this.state.interests.indexOf("adult") > -1 ? (
                      <i className='fa fa-check' aria-hidden='true' />
                    ) : null}
                  </div>
                  <p className='check-label'>Adult programs</p>
                </div>
                <div className='check-row'>
                  <div
                    className='check-box'
                    onClick={() => this._handleCheckBox("interests", "junior")}
                  >
                    {this.state.interests.indexOf("junior") > -1 ? (
                      <i className='fa fa-check' aria-hidden='true' />
                    ) : null}
                  </div>
                  <p className='check-label'>Junior programs</p>
                </div>
                <div className='check-row'>
                  <div
                    className='check-box'
                    onClick={() => this._handleCheckBox("interests", "other")}
                  >
                    {this.state.interests.indexOf("other") > -1 ? (
                      <i className='fa fa-check' aria-hidden='true' />
                    ) : null}
                  </div>
                  <p className='check-label'>Other</p>
                </div>
              </div>
              <div className='checkbox-container'>
                <p
                  className={`checkbox-title ${
                    this.state.failedValidation ? "red-text" : ""
                  }`}
                >
                  Skill level of golfer(s):
                </p>
                <div className='check-row'>
                  <div
                    className='check-box'
                    onClick={() => this._handleCheckBox("skillLevel", "none")}
                  >
                    {this.state.skillLevel.indexOf("none") > -1 ? (
                      <i className='fa fa-check' aria-hidden='true' />
                    ) : null}
                  </div>
                  <p className='check-label'>No experience</p>
                </div>
                <div className='check-row'>
                  <div
                    className='check-box'
                    onClick={() =>
                      this._handleCheckBox("skillLevel", "beginner")
                    }
                  >
                    {this.state.skillLevel.indexOf("beginner") > -1 ? (
                      <i className='fa fa-check' aria-hidden='true' />
                    ) : null}
                  </div>
                  <p className='check-label'>Beginner</p>
                </div>
                <div className='check-row'>
                  <div
                    className='check-box'
                    onClick={() =>
                      this._handleCheckBox("skillLevel", "intermediate")
                    }
                  >
                    {this.state.skillLevel.indexOf("intermediate") > -1 ? (
                      <i className='fa fa-check' aria-hidden='true' />
                    ) : null}
                  </div>
                  <p className='check-label'>Intermediate</p>
                </div>
                <div className='check-row'>
                  <div
                    className='check-box'
                    onClick={() =>
                      this._handleCheckBox("skillLevel", "advanced")
                    }
                  >
                    {this.state.skillLevel.indexOf("advanced") > -1 ? (
                      <i className='fa fa-check' aria-hidden='true' />
                    ) : null}
                  </div>
                  <p className='check-label'>Advanced</p>
                </div>
              </div>
            </div>
            <label htmlFor='req-form-note'>
              Tell us anything else you would like us to know:{" "}
              <p>( {this.state.note.length} / 150 characters)</p>
            </label>
            <textarea
              name='note'
              id='req-form-note'
              value={this.state.note}
              onChange={this._handleInputChange}
              autoComplete='new-note'
              maxLength='150'
            />
            <div className='checkbox-area'>
              <div className='checkbox-container w-100'>
                <div className='check-row'>
                  <div
                    className='check-box'
                    onClick={() => this._handleEmailSetting()}
                  >
                    {this.state.emailOptIn && (
                      <i className='fa fa-check' aria-hidden='true' />
                    )}
                  </div>
                  <p className='check-label'>
                    Please add me to Operation 36's email list
                  </p>
                </div>
              </div>
            </div>
            {this.state.invalidEmail ? (
              <p className='invalid-email-note'>
                The email you provided doesn't appear to be valid. Please try
                again
              </p>
            ) : null}
            <button className='form-submit-button' onClick={this._handleSubmit}>
              {this.state.submitClicked ? (
                <i className='fa fa-spinner fa-spin' aria-hidden='true' />
              ) : (
                "Submit to Operation 36"
              )}
            </button>
          </div>
        )}
      </div>
    );
  }

  _changeFamilyCount = (num) => {
    const { fam_count } = this.state;
    if (num !== fam_count) {
      this.setState({ fam_count: num });
    }
  };

  _handleEmailSetting = () => {
    this.setState({ emailOptIn: !this.state.emailOptIn });
  };

  _handleCheckBox = (list, val) => {
    let arr = [...this.state[list]];
    if (arr.indexOf(val) === -1) {
      arr.push(val);
      this.setState({ [list]: arr });
    } else {
      let filteredArr = this.state[list].filter((el) => el !== val);
      this.setState({ [list]: filteredArr });
    }
  };

  _handleSubmit = () => {
    this.setState({ submitClicked: true }, () => {
      if (this._validateForm()) {
        if (isValidEmail(this.state.email)) {
          let data = {
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            location: this.state.location,
            num_golfers: this.state.fam_count,
            note: this.state.note,
            interests: this.state.interests,
            skill_levels: this.state.skillLevel,
            opt_in: this.state.emailOptIn,
          };
          Axios.post(baseUrl + "leads", data)
            .then((res) => {
              if (res.status === 201) {
                this.setState({ success: true });
              }
            })
            .catch((e) => console.log(e));
        } else {
          this.setState({ invalidEmail: true, submitClicked: false });
        }
      } else {
        this.setState({ failedValidation: true, submitClicked: false });
      }
    });
  };

  _validateForm = () => {
    if (
      this.state.name.length < 1 ||
      this.state.email.length < 1 ||
      this.state.location.length < 1 ||
      this.state.interests.length < 1 ||
      this.state.skillLevel.length < 1
    ) {
      return false;
    } else {
      return true;
    }
  };

  _handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  _iterateFamilyCount = (dir) => {
    const { fam_count } = this.state;
    if (dir === "up") {
      if (fam_count < 99) {
        this.setState({ fam_count: fam_count + 1 });
      }
    } else if (dir === "down") {
      if (fam_count > 1) {
        this.setState({ fam_count: fam_count - 1 });
      }
    }
  };
}

export default NoCommunityModal;
