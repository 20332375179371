import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { toast } from 'react-toastify'
import classNames from 'classnames'

import classes from './PersonalForm.module.scss'
import Input from '../../common/form/Input'
import Select from '../../common/form/Select'
import moment from 'moment'
import { flatServerFormErrors } from '../../../utility/helperFunctions'
import { GENDER_SELECT_OPTIONS, EMAIL_REGEX } from '../../../misc/Constants'
import { AccountContext } from '../AccountContext'
import { useContext } from 'react'


// eslint-disable-next-line max-lines-per-function
const PersonalForm = () => {
  const dispatch = useDispatch()
  const { user, submitDisabled, updateAction } = useContext(AccountContext) // Note: user could be golfer OR current_user
  const currentUser = useSelector(state => state.user.current_user)

  const [isUpdating, setIsUpdating] = useState(false)
  const [hasChanges, setHasChanges] = useState(false)
  const [errors, setErrors] = useState({})

  // Note: DatePicker does not work as uncontrolled input; state only used to update the UI
  const [controlledBirthdate, setControlledBirthdate] = useState(user?.birthdate ? moment(user?.birthdate) : null)

  const isCurrentUser = user.id === currentUser.id
  const isCoach = currentUser.type === 'Coach'
  const isFamilyAdmin = currentUser.type === 'Student' && currentUser.family_admin

  const disallowCoachToEdit = !isCurrentUser && (isCoach || !isFamilyAdmin)

  const validateForm = formData => {
    setErrors({})
    const formErrors = {}

    const email = formData.get('email')
    const birthdate = moment(formData.get('birthdate'))

    if (!birthdate.isValid()) {
      formErrors['birthdate'] = 'Date invalid'
    }

    if (!email?.match(EMAIL_REGEX)) {
      formErrors['email'] = 'Invalid email'
    }

    const age = moment().diff(birthdate, 'years')
    if (user.family_admin && age < 13) {
      formErrors['birthdate'] = 'Family Admin age cannot be under 13 years old'
    }

    if (Object.keys(formErrors)?.length) {
      setErrors(formErrors)
      return false
    }

    return true
  }

  const handleSubmitForm = async e => {
    e.preventDefault()
    const formData = new FormData(e.target)

    if (validateForm(formData)) {
      const partialUser = {
        id: user.id,
        first_name: formData.get('firstName'),
        last_name: formData.get('lastName'),
        username: formData.get('username'),
        email: formData.get('email'),
        phone: formData.get('phone'),
        birthdate: moment(formData.get('birthdate')).toDate(),
        gender: formData.get('gender'),
      }

      try {
        setIsUpdating(true)
        const newState = await dispatch(updateAction(partialUser))

        if (newState.type === 'UI_ERROR') {
          setErrors(flatServerFormErrors(newState?.error))
        } else {
          toast.success('User has been successfully updated!')
        }
      } catch {
        toast.error('Couldn\'t update user. Please check again later')
      } finally {
        setIsUpdating(false)
        setHasChanges(false)
      }
    }
  }

  const handleDetectChanges = (e) => {
    const formData = new FormData(e.currentTarget)
    const firstName = formData.get('firstName')
    const lastName = formData.get('lastName')
    const username = formData.get('username')
    const email = formData.get('email')
    const phone = formData.get('phone')
    const birthdate = moment(formData.get('birthdate'))
    const gender = Number(formData.get('gender'))

    const hasChanges_ =
      user?.first_name !== firstName ||
      user?.last_name !== lastName ||
      user?.username !== username ||
      user?.email !== email ||
      user?.phone !== phone ||
      user?.birthdate !== birthdate.toISOString() ||
      (user?.gender || '') !== (gender || '')

    if (hasChanges_ !== hasChanges) {
      setHasChanges(hasChanges_)
    }
  }

  const handleBirthdateSelect = (e) => {
    setControlledBirthdate(e)
    handleDetectChanges(e)
  }


  return (
    <form onSubmit={handleSubmitForm} onInput={handleDetectChanges} className={classNames(classes.container, 'animated fadeIn')}>
      <div className='row'>
        <h4 className={classes.title}>Personal Details</h4>
      </div>

      <div className='row d-flex'>
        <div className='col-md-12 col-xl-6 p-0 pr-xl-4 mb-3'>
          <label htmlFor='firstName'>First Name</label>
          <Input
            type='text'
            id='firstName'
            name='firstName'
            defaultValue={user?.first_name}
            placeholder='First Name'
            classNames={{ inputContainer: classes.accountInputContainer }}
            disabled={disallowCoachToEdit}
            error={errors?.first_name}
          />
        </div>
        <div className='col-md-12 col-xl-6 p-0 pl-xl-4 mb-3'>
          <label htmlFor='lastName'>Last Name</label>
          <Input
            type='text'
            id='lastName'
            name='lastName'
            defaultValue={user?.last_name}
            placeholder='Last Name'
            classNames={{ inputContainer: classes.accountInputContainer }}
            disabled={disallowCoachToEdit}
            error={errors?.last_name}
          />
        </div>
      </div>

      <div className='row mb-3'>
        {user.username ? (
          <div className='col p-0'>
            <label htmlFor='username'>Username</label>
            <Input
              type='text'
              id='username'
              name='username'
              defaultValue={user?.username}
              placeholder='Username'
              classNames={{ inputContainer: classes.accountInputContainer }}
              disabled={disallowCoachToEdit}
              error={errors?.username}
            />
          </div>
        ) : (
          <div className='col p-0'>
            <label htmlFor='email'>Email</label>
            <Input
              type='text'
              id='email'
              name='email'
              defaultValue={user?.email}
              placeholder='Email'
              classNames={{ inputContainer: classes.accountInputContainer }}
              disabled={disallowCoachToEdit}
              error={errors?.email}
            />
          </div>
        )}
      </div>

      <div className='row mb-3'>
        <label htmlFor='phone'>Phone Number</label>
        <Input
          type='telephone'
          id='phone'
          name='phone'
          defaultValue={user?.phone}
          placeholder='Phone Number'
          classNames={{ inputContainer: classes.accountInputContainerPhone }}
          disabled={disallowCoachToEdit}
          error={errors?.phone}
        />
      </div>

      <div className='row mb-5'>
        <div className='col-md-12 col-xl-6 p-0 pr-xl-4 mb-3'>
          <label htmlFor='birthdate'>Birthdate</label>
          <Input
            type='date'
            id='birthdate'
            name='birthdate'
            placeholder='Birthdate'
            selected={controlledBirthdate}
            onChange={handleBirthdateSelect}
            classNames={{
              inputContainer: classes.accountInputContainer,
              symbol: classes.inputIcon,
            }}
            symbol={<i className='fa fa-calendar'/>}
            error={errors?.birthdate}
            disabled={disallowCoachToEdit}
          />
        </div>
        <div className='col-md-12 col-xl-6 p-0 pl-xl-4'>
          <label htmlFor='gender'>Gender</label>
          <Select
            id='gender'
            name='gender'
            placeholder='Select a gender'
            defaultValue={user?.gender !== undefined ? GENDER_SELECT_OPTIONS.find(gender => gender.value === user?.gender) : undefined}
            options={GENDER_SELECT_OPTIONS}
            onChange={handleDetectChanges}
            classNames={{ singleValue: () => classes.genderInputWrapper }}
            isDisabled={disallowCoachToEdit}
            error={errors?.gender}
            isSearchable={false}
          />
        </div>
      </div>

      <div className='row'>
        <button
          type='submit'
          className='btn btn-primary'
          disabled={isUpdating || disallowCoachToEdit || submitDisabled || !hasChanges}
        >
          Sav{isUpdating ? 'ing' : 'e'} Changes
        </button>
      </div>
    </form>
  )
}

export default PersonalForm
