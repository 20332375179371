import React, { Component } from "react";

// CHILDREN
import AnimatedCompletedEducationBadge from "./AnimatedCompletedEducationBadge";

// ASSETS
import "./CompletedEducation.scss";

class CompletedEducation extends Component {
  render() {
    const { education, closeCompleteModal } = this.props;
    return (
      <div id='CompletedEducation'>
        <h2>Congratulations! You Completed {education.name}</h2>
        <AnimatedCompletedEducationBadge education={education} />
        <p>
          Great Job! You have completed this training! Please feel free to come
          back and review the content whenever you want.
        </p>
        <button
          className='btn btn-primary'
          onClick={() => closeCompleteModal(education)}
        >
          <i className='fa fa-arrow-left' /> Go back to the education overview
        </button>
      </div>
    );
  }
}

export default CompletedEducation;
