import React from 'react';

import './ResultStep.scss';

/**
  Displays a result of the coach registration, e.g. screen for non-golf pros
  or error screen.
*/
const resultStep = (props) => {
  const {
    img,
    imgAlt,
    title,
    desc
  } = props;

  return (
    <div id='ResultStep'>
      {img &&
        <img className='result-step-image' src={img} alt={imgAlt} />
      }
      <h3 className='result-step-title'>{title}</h3>
      <p className='result-step-desc'>{desc}</p>
      <div className='result-step-button-group'>
        {props.children}
      </div>
    </div>
  );
};

export default resultStep;
