import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as rankActions from "../../actions/rankActions";
import * as courseActions from "../../actions/courseActions";
import * as userActions from "../../actions/userActions";
import * as commonActions from "../../actions/commonActions";

import "../../assets/styles/components/ranks/RanksPage.scss";

import Rank from "./Rank";
import PageHeader from '../common/PageHeader/PageHeader'
import Loader from "../common/Op36Loader";

class RanksPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      course: {},
      ranksLoader: true,
      openedRankId: 0,
    };
  }

  componentWillMount() {
    const { common_actions } = this.props;
    common_actions.setCurrentTab("Curriculums");
    common_actions.showSideBar(true);
    common_actions.showNavBar(true);

    // if (window.FirebasePlugin && !this.props.user.device_id) {
    //   window.FirebasePlugin.getToken(
    //     function (token) {
    //       this.props.user_actions.updateCurrentUser({
    //         id: this.props.user.id,
    //         device_id: token,
    //       });
    //     }.bind(this),
    //     function (error) {
    //       console.error(error);
    //     }
    //   );
    // }
    // this.props.user_actions
    //   .refreshCurrentUser(this.props.user.id)
    //   .then(response => {
    //     this.props.course_actions.getCourse(this.props.user.current_course_id);
    //   });
    this.props.actions.getCourseRanks().then((res) => {
      this.setState({
        ranksLoader: false,
      });
    });

    //this.props.course_actions.getCourse(this.props.user.current_course_id);
  }

  // componentWillReceiveProps(nextProps) {
  //   if (window.FirebasePlugin && !this.props.user.device_id) {
  //     window.FirebasePlugin.getToken(
  //       function (token) {
  //         this.props.user_actions.updateCurrentUser({
  //           id: this.props.user.id,
  //           device_id: token,
  //         });
  //       }.bind(this),
  //       function (error) {
  //         console.error(error);
  //       }
  //     );
  //   }
  // }

  isRankUnlocked(rank, current_rank, isCoach) {
    if (
      isCoach ||
      rank.progress_percentage === 100 ||
      rank.id === current_rank.id
    ) {
      return true;
    } else {
      return false;
    }
  }

  displayRanks() {
    return this.props.ranks.map((rank) => (
      <Rank
        key={rank.id}
        rank={rank}
        is_unlocked={true}
        percent_complete={100}
        isOpen={this.state.openedRankId === rank.id}
        handleOpen={this._handleOpen}
      />
    ));
  }

  render() {
    return (
      <div id='' className=''>
        <PageHeader title='Curriculum'/>
        <div id='objectiveWrapper' className='componentBody container'>
          <div id='op36ObjectivesTab'>
            {this.state.ranksLoader ? (
              <div
                className=''
                style={{
                  // marginTop: "-40%",
                  marginBottom: "50px",
                  width: "100%",
                }}
              >
                <Loader message='loading ranks' />
              </div>
            ) : (
              this.displayRanks()
            )}
          </div>
          <div id='customObjectivesTab' />
        </div>
      </div>
    );
  }

  _handleOpen = (id) => {
    this.setState({ openedRankId: id });
  };
}

function mapStateToProps(state, ownProps) {
  return {
    ui: state.ui,
    ranks: state.ranks,
    user: state.user.current_user,
    course: state.courses.course,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(rankActions, dispatch),
    common_actions: bindActionCreators(commonActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
    course_actions: bindActionCreators(courseActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RanksPage);
