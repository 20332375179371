import AccountAvatar from './AccountAvatar'
import classNames from 'classnames'

import classes from './AccountSidebar.module.scss'
import AccountNavigation from './AccountNavigation'
import { useContext } from 'react'
import { AccountContext } from '../AccountContext'

const AccountSidebar = () => {
  const { user: editingUser, updateAction, isProfile } = useContext(AccountContext)

  return (
    <div className={classNames(classes.container, 'd-flex flex-column sidebar-container')}>
      <AccountAvatar
        userId={editingUser.id}
        email={editingUser.email}
        first_name={editingUser.first_name}
        last_name={editingUser.last_name}
        avatar={editingUser.avatar}
        isProfile={isProfile}
        updateAction={updateAction}
      />
      <AccountNavigation />
    </div>
  )
}

export default AccountSidebar
