import * as types from "../actions/actionTypes";
// import update from 'immutability-helper';
// state = [] : Start out with no accounts
export default function userDivision(state = [], action) {
  switch (action.type) {
    case types.ADD_USER_DIVISION:
      return Object.assign({}, state, {
        user_division: action.user_division
      });
    case types.ADD_DIVISION_DATA:
      return Object.assign({}, state, {
        division_data: action.division_data
      });
    case types.UPDATE_TRACK_GOAL:
      let newState = JSON.parse(JSON.stringify(state));
      newState["user_division"]["goal_track"] =
        action.user_track.development_track_goal;
      return newState;
    default:
      return state;
  }
}
