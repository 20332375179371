import * as constants from "./actionTypes";
import * as common from "./commonActions";
import Axios from '../axios'

export const TYPE_WEEKLY = "weekly";
export const TYPE_MONTHLY = "monthly";
export const TYPE_ALL_TIME = "all_time";

export function successRequestingCourseLeaderboard(response, leaderboardType) {
  if (response.status === 200) {
    switch (leaderboardType) {
      case TYPE_WEEKLY:
        return {
          type: constants.ADD_WEEKLY_LEADERBOARD,
          weekly_leaderboard: response.data.leaderboard
        };
      case TYPE_MONTHLY:
        return {
          type: constants.ADD_MONTHLY_LEADERBOARD,
          monthly_leaderboard: response.data.leaderboard
        };
      case TYPE_ALL_TIME:
        return {
          type: constants.ADD_ALL_TIME_LEADERBOARD,
          all_time_leaderboard: response.data.leaderboard
        };
      default:
        break;
    }
    //console.log("ADDING COURSE LEADERBOARD TO STORE: " + response.data)
    //return { type: constants.ADD_WEEKLY_LEADERBOARD, weekly_leaderboard: response.data.leaderboard};
  } else {
    console.log("ERROR ON GETTING COURSE LEADERBOARD");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

// THUNKS HERE
export function getCourseLeaderboard(
  courseId,
  leaderboardType,
  startTS,
  endTS
) {
  return function(dispatch) {
    // BODY OF THUNK

    // TURN SPINNER ON...
    dispatch(common.loadingChanged(true));
    // FETCH API CALL FROM SERVER THEN DISPATCH UI ACTION
    // THEN TURN SPINNER OFF
    return requestCourseLeaderboard(courseId, startTS, endTS, leaderboardType)
      .then(response =>
        dispatch(successRequestingCourseLeaderboard(response, leaderboardType))
      )
      .then(response => dispatch(common.loadingChanged(false)));
    // .then(
    // turnOffSpinner => dispatch(common.loadingChanged(false))
    // )
  };
}

// API CALLS
function requestCourseLeaderboard(courseId, startTS, endTS, leaderboardType) {
  let requestUrl = "/api/v1/courses/" + courseId + "/leaderboard";
  if (startTS && endTS) {
    return Axios.get(requestUrl, {
      params: {
        start_ts: startTS,
        end_ts: endTS,
        type: leaderboardType
      }
    });
  } else {
    return Axios.get(requestUrl, {
      params: {
        type: leaderboardType
      }
    });
  }
}
