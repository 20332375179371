import React from "react";
import autosize from "autosize";
import ReactPlayer from "react-player";

import "./DrillBasicDetail.scss";

import MyStatefulEditor from "../../../common/MyStatefulEditor";
import ScoreNamePopover from "../../subcomponents/ScoreNamePopover";

import check_icon from "../../../../assets/images/common/icons/drills/check.png";
import trash_icon from "../../../../assets/images/common/icons/drills/delete-icon.png";
import NumericScoringCard from "../../subcomponents/NumericScoringCard";
import StarScoringCard from "../../subcomponents/StarScoringCard";

let example_equipment = [
  "Dome cones",
  "Alignment sticks",
  "Tees",
  "eGolf Ring",
  "LowZone straps",
  "Orange Whip",
];

class DrillBasicDetail extends React.Component {
  state = {
    scoreNameSelectorOpen: false,
  };

  componentDidMount() {
    for (let i = 0; i < this.props.steps.length; i++) {
      autosize(this[`steps-${i}`]);
    }
  }

  componentDidUpdate(prevProps) {
    for (let i = 0; i < this.props.steps.length; i++) {
      autosize(this[`steps-${i}`]);
    }
  }

  // eslint-disable-next-line max-lines-per-function
  render() {
    const {
      is_drill,
      title,
      description,
      equipment,
      steps,
      content,
      handleInputChange,
      handleArrayInput,
      addListItem,
      removeListItem,
      handleHTML,
      videoPreview,
      videoLoader,
      onVideoAdded,
      removeVideo,
      videoTooLarge,
      videoError,
      video,
      dismissVideoError,
      dismissVideoTooLarge,
      onPhotoAdded,
      photos,
      removePhoto,
      blowUpPhoto,
      originalVideoUrl,
      removeOriginalVideo,
      awsBackupUrl,
      isAdmin,
      // adminVideoOverride,
      // adminVideoUrlOverride,
      // handleAdminVideoOverrideToggle,
      // handleAdminVideoUrlOverrideChange,
    } = this.props;
    return (
      <div id='DrillBasicDetail'>
        <div className='page-content-container'>
          <div className='page-column'>
            <p className='input-label'>Video (file size should be under 50MB)</p>
            <div className='video-box'>
              <input
                id='dropzone-upload'
                className='FileInput'
                ref={(ref) => (this.fileInput = ref)}
                type='file'
                accept='video/mp4,video/x-m4v,video/*'
                onChange={onVideoAdded}
                multiple={false}
                // disabled={videoPreview}
                onClick={() => this._nullOutValue("fileInput")}
              />
              <input
                id={`photo-loader`}
                type={"file"}
                accept={"image/*"}
                className={"FileInput"}
                onChange={onPhotoAdded}
                ref={(ref) => (this.photoInput = ref)}
                onClick={() => this._nullOutValue("photoInput")}
              />
              {this._renderVideoBox(
                videoLoader,
                videoTooLarge,
                videoError,
                videoPreview,
                video,
                removeVideo,
                dismissVideoError,
                dismissVideoTooLarge,
                originalVideoUrl,
                removeOriginalVideo,
                awsBackupUrl
              )}
            </div>
            <p className='input-label mt-4'>{`Images ${
              photos.length > 0 ? `(${photos.length} / 5)` : "(up to 5)"
            }`}</p>
            <div className='image-box'>
              {this._renderPhotoBox(photos, removePhoto, blowUpPhoto)}
            </div>
            <input
              type='text'
              name='title'
              className='title-input mt-3'
              value={title}
              placeholder={`Enter ${is_drill ? "drill" : "activity"} name`}
              onChange={handleInputChange}
            />
            <p className='input-label mt-4'>
              Brief Description <span className='orange-text'>*</span>
            </p>
            <input
              type='text'
              name='description'
              className='description-input mb-3'
              value={description}
              placeholder={
                is_drill
                  ? "E.g. Self-directed putting drill"
                  : "E.g. Warm up putting activity"
              }
              onChange={handleInputChange}
            />
          </div>
          <div className='page-column'>
            {isAdmin && this._renderAdminScoringStuff()}
            <p className='input-label'>
              Equipment Needed<span className='orange-text'>*</span>
            </p>
            {equipment.length > 0 ? (
              equipment.map((e, i) => {
                return (
                  <div key={i} className='equipment-row'>
                    <div
                      className='check-icon'
                      style={{ backgroundImage: `url(${check_icon})` }}
                    />
                    <div className='equipment-input-container'>
                      <input
                        id={`equipment-input-uniq-${i}`}
                        type='text'
                        value={e}
                        className='equipment-input'
                        placeholder={`E.g. ${
                          example_equipment[i % example_equipment.length]
                        }`}
                        name='equipment'
                        onChange={(e) =>
                          handleArrayInput(e.target.name, i, e.target.value)
                        }
                        onKeyDown={(e) => this._checkKeyDown(e, i, "equipment")}
                      />
                    </div>
                    <div
                      className='trash-icon'
                      style={{ backgroundImage: `url(${trash_icon})` }}
                      onClick={() => removeListItem("equipment", i)}
                    />
                  </div>
                );
              })
            ) : (
              <div className='placeholder-bar'>
                <p>You need to list at least one piece of equipment</p>
                <p className='subtext'>
                  If this {is_drill ? "drill" : "activity"} really doesn't
                  require any equipment, enter 'none' as an equipment item so
                  you can move on
                  {/* search for common {is_drill ? "drills" : "activities"}{" "}
                  later */}
                </p>
              </div>
            )}
            <p
              className='orange-text'
              onClick={() => addListItem("equipment")}
              style={{ cursor: "pointer", display: "inline-block" }}
            >
              + Add equipment item
            </p>
            <p className='input-label mt-3'>
              Steps to Setup <span className='orange-text'>*</span>
            </p>
            {steps.length > 0 ? (
              steps.map((s, i) => {
                return (
                  <div key={i} className='equipment-row full-width'>
                    <p>{`${i + 1}.`}</p>
                    <div className='equipment-input-container'>
                      <textarea
                        // type='text'
                        id={`steps-input-uniq-${i}`}
                        value={s}
                        className='equipment-input wrap-input'
                        placeholder={`Step ${i + 1}`}
                        name='steps'
                        onChange={(e) =>
                          handleArrayInput(e.target.name, i, e.target.value)
                        }
                        rows='1'
                        style={{ height: 36 }}
                        ref={(el) => (this[`steps-${i}`] = el)}
                        onKeyDown={(e) => this._checkKeyDown(e, i, "steps")}
                      />
                    </div>
                    <div
                      className='trash-icon'
                      style={{ backgroundImage: `url(${trash_icon})` }}
                      onClick={() => removeListItem("steps", i)}
                    />
                  </div>
                );
              })
            ) : (
              <div className='placeholder-bar'>
                <p>You need to list at least one step to setup</p>
                <p className='subtext'>
                  If this {is_drill ? "drill" : "activity"} really doesn't
                  require any steps to set up, enter 'none' as a step so you can
                  move on
                  {/* search for common {is_drill ? "drills" : "activities"} later */}
                </p>
              </div>
            )}
            <p
              className='orange-text'
              onClick={() => addListItem("steps")}
              style={{ cursor: "pointer", display: "inline-block" }}
            >
              + Add step
            </p>
            <p className='input-label mt-3'>
              About this {is_drill ? "drill" : "activity"}
              <span className='orange-text'>*</span>
            </p>
            <MyStatefulEditor
              limit={true}
              content={content}
              onChange={handleHTML}
            />
            <div style={{ height: 9 }} />
            {/* above div for styling purposes (temporary) */}
          </div>
        </div>
      </div>
    );
  }

  _checkKeyDown = (e, i, val) => {
    const { addListItem } = this.props;
    if (e.key === "Enter") {
      e.preventDefault();
      addListItem(val);
      setTimeout(() => this._focusNext(i, val), 0);
    }
  };

  _focusNext = (i, val) => {
    let nextEl = document.getElementById(`${val}-input-uniq-${i + 1}`);
    nextEl.focus();
  };

  _renderAdminScoringStuff = () => {
    const {
      setScoreInfo,
      score_name,
      is_drill,
      scoringEnabled,
      handleScoringToggle,
      is_edit,
    } = this.props;
    if (!is_drill) return null;
    const { scoreNameSelectorOpen } = this.state;
    return (
      <div>
        <div className='d-flex justify-content-between'>
          <p className='input-label'>Enable Scoring/Feedback</p>
          <div
            className='custom-control custom-switch mb-3'
            onClick={handleScoringToggle}
          >
            <input
              type='checkbox'
              className='custom-control-input'
              id='scoring-enabler'
              checked={scoringEnabled}
            />
            <label className='custom-control-label input-label'></label>
          </div>
        </div>
        {scoringEnabled && (
          <div>
            <p style={{ fontSize: "0.9rem", color: "#5F6F7C" }}>
              NOTE: Once you create this drill, you cannot edit the score type.
            </p>
            <div
              className='scoring-container'
              style={{
                backgroundColor: "#f4f4f5",
                padding: 10,
                borderRadius: 8,
                marginBottom: 18,
              }}
            >
              <p>Type</p>
              {is_edit && (
                <div className='warning-box'>
                  <p>
                    2nd NOTE: As stated above, you're free to edit the score type, but it won't be saved.
                  </p>
                </div>
              )}
              <div
                className='score-name-selector'
                style={{
                  borderRadius: 9,
                  border: "2px solid #34ACE0",
                  background: "#fff",
                  padding: 10,
                  position: "relative",
                  cursor: "pointer",
                  marginBottom: 6,
                }}
                onClick={this._toggleScoreNameSelector}
              >
                {this._renderCurrentScoreSelection(score_name)}
                {scoreNameSelectorOpen && (
                  <ScoreNamePopover
                    close={this._toggleScoreNameSelector}
                    setScoreInfo={setScoreInfo}
                    selectedScore={score_name}
                  />
                )}
              </div>
              {this._renderScoringSubQuestions(score_name)}
              {this._renderScoringPreview(score_name)}
            </div>
            {this._renderSkillLevelSelect()}
          </div>
        )}
      </div>
    );
  };

  _renderSkillLevelSelect = () => {
    const { allSkillLevels, skillLevelLoader, handleSkillLevelClick } =
      this.props;
    if (skillLevelLoader) {
      return (
        <div className='d-flex flex-column align-items-center'>
          <i className='fa fa-spinner fa-spin' aria-hidden='true' />
          <p className='text-center'>Loading Skill Levels...</p>
        </div>
      );
    }
    return (
      <div>
        <p>Select Skill Levels:</p>
        {allSkillLevels.map((sl) => {
          return (
            <div key={sl.id}>
              <input
                type='checkbox'
                checked={this._checkSkillLevel(sl.id)}
                onChange={(e) => handleSkillLevelClick(e, sl.id)}
                style={{ maxWidth: 30 }}
              />
              <label>{sl.name}</label>
            </div>
          );
        })}
      </div>
    );
  };

  _checkSkillLevel = (id) => {
    const { selectedSkillLevels } = this.props;
    let resp = selectedSkillLevels.find(
      (sl) => sl.skill_level_id === id && !sl._destroy
    );
    return !!resp;
  };

  _renderCurrentScoreSelection = (score_name) => {
    return (
      <div className='d-flex align-items-center flex-nowrap'>
        <div style={{ flex: "1 1" }}>
          {this._pickScoreComponent(score_name)}
        </div>
        <div
          className='d-flex align-items-center justify-content-center'
          style={{ fontSize: 24 }}
        >
          <i className='fa fa-angle-down' aria-hidden='true' />
        </div>
      </div>
    );
  };

  _pickScoreComponent = (score_name) => {
    if (score_name === "numeric") {
      return <NumericScoringCard />;
    }
    return <StarScoringCard />;
  };

  _toggleScoreNameSelector = () => {
    this.setState((prevState) => {
      return { scoreNameSelectorOpen: !prevState.scoreNameSelectorOpen };
    });
  };

  _renderScoringSubQuestions = (name) => {
    const {
      score_type,
      score_detail,
      score_label,
      star_label_one,
      star_label_five,
    } = this.props;
    if (name === "numeric") {
      return (
        <div className='scoring-section-subquestions'>
          <div className='d-flex'>
            <div className='d-flex align-items-center justify-content-center'>
              <p
                style={{
                  marginRight: 9,
                  whiteSpace: "nowrap",
                  marginBottom: 0,
                }}
              >
                What are you measuring?
              </p>
            </div>
            <input
              onChange={this._handleScoreLabelChange}
              value={score_label}
              placeholder='E.g. Putts in a row'
            />
          </div>
          <div className='d-flex align-items-center justify-content-between'>
            <p>Is the record score the high or low score?</p>
            <div id='high-low-state-switcher'>
              <div onClick={(e) => this._handleScoreTypeChange("high")}>
                <p
                  style={
                    score_type === "high"
                      ? { background: "#34ACE0", color: "#fff" }
                      : {}
                  }
                >
                  High
                </p>
              </div>
              <div onClick={(e) => this._handleScoreTypeChange("low")}>
                <p
                  style={
                    score_type === "low"
                      ? { background: "#34ACE0", color: "#fff" }
                      : {}
                  }
                >
                  Low
                </p>
              </div>
            </div>
          </div>
          <div>
            <span>Scoring details (optional)</span>
            <div>
              <textarea
                onChange={this._handleScoreDetailChange}
                value={score_detail}
                placeholder='If needed, describe how to calculate the score...'
              ></textarea>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className='scoring-section-subquestions'>
        <div className='d-flex align-items-center'>
          <div className='d-flex align-items-center'>
            <p
              className='mb-0'
              style={{ whiteSpace: "nowrap", marginRight: 9 }}
            >
              Question to ask:
            </p>
          </div>
          <input
            onChange={this._handleScoreLabelChange}
            value={score_label}
            placeholder='E.g. How do you feel after this drill?'
          />
        </div>
        <div className='d-flex align-items-center'>
          <div className='d-flex align-items-center'>
            <p
              className='mb-0'
              style={{ whiteSpace: "nowrap", marginRight: 9 }}
            >
              Lowest value label (1-star):
            </p>
          </div>
          <input
            onChange={this._handleLowestStarChange}
            value={star_label_one}
            placeholder='E.g. Terrible'
          />
        </div>
        <div className='d-flex align-items-center'>
          <div className='d-flex align-items-center'>
            <p
              className='mb-0'
              style={{ whiteSpace: "nowrap", marginRight: 9 }}
            >
              Highest value label (5-star):
            </p>
          </div>
          <input
            onChange={this._handleHighestStarChange}
            value={star_label_five}
            placeholder='E.g. Great'
          />
        </div>
      </div>
    );
  };

  _handleLowestStarChange = (e) => {
    const { setScoreInfo } = this.props;
    let val = e.target.value;
    setScoreInfo("star_label_one", val);
  };

  _handleHighestStarChange = (e) => {
    const { setScoreInfo } = this.props;
    let val = e.target.value;
    setScoreInfo("star_label_five", val);
  };

  _handleScoreLabelChange = (e) => {
    const { setScoreInfo } = this.props;
    let val = e.target.value;
    setScoreInfo("score_label", val);
  };

  _handleScoreTypeChange = (val) => {
    const { setScoreInfo } = this.props;
    setScoreInfo("score_type", val);
  };

  _handleScoreDetailChange = (e) => {
    const { setScoreInfo } = this.props;
    let val = e.target.value;
    setScoreInfo("score_detail", val);
  };

  _renderScoringPreview = (name) => {
    if (name === "numeric") {
      return (
        <div
          style={{
            borderTop: "2px solid #AFB7BD",
            marginTop: 8,
            paddingTop: 8,
          }}
        >
          <p className='text-center mb-2'>Score/Feedback Input Preview:</p>
          <p
            style={{ color: "#AFB7BD", fontSize: "0.9rem" }}
            className='text-center mb-2'
          >
            My Score
          </p>
          <div className='d-flex justify-content-around flex-nowrap'>
            {[0, 1, 2, 3, 4, 5, 6].map((num) => {
              let threeProperties = {
                color: "#fff",
                backgroundColor: "#34ACE0",
                borderRadius: 4,
              };
              return (
                <div
                  style={{
                    opacity: this._grabNumberOpacity(num),
                    flex: "1 1",
                    ...(num === 3 && threeProperties),
                  }}
                  className='d-flex align-items-center justify-content-center'
                  key={num}
                >
                  <p
                    style={{
                      margin: 0,
                      padding: 0,
                      fontSize: 24,
                      fontWeight: 700,
                      color: "#34ACE0",
                      ...(num === 3 && { color: "#fff" }),
                    }}
                  >
                    {num}
                  </p>
                </div>
              );
            })}
          </div>
          <p
            style={{ color: "#AFB7BD", fontSize: "0.9rem" }}
            className='text-center mt-3 mb-1'
          >
            {this.props.score_label || "Putts in a row"}
          </p>
        </div>
      );
    }
    return (
      <div
        style={{
          borderTop: "2px solid #AFB7BD",
          marginTop: 8,
          paddingTop: 8,
        }}
      >
        <p className='text-center'>Score/Feedback Input Preview:</p>
        <p
          style={{ color: "#AFB7BD", fontSize: "0.9rem" }}
          className='text-center'
        >
          {this.props.score_label || "How do you feel after this drill?"}
        </p>
        <div className='d-flex justify-content-around flex-nowrap'>
          {[0, 1, 2, 3, 4].map((num) => {
            return (
              <div
                style={{ flex: "1 1", color: "#34ACE0" }}
                className='d-flex flex-column justify-content-center align-items-center'
                key={num}
              >
                <i
                  className={`fa fa-star${num > 1 ? "-o" : ""}`}
                  aria-hidden='true'
                  style={{ fontSize: 28 }}
                />
                <p
                  style={{
                    color: "#AFB7BD",
                    fontSize: "0.9rem",
                    marginBottom: 4,
                    ...([1, 2, 3].includes(num) && { color: "#f4f4f5" }),
                  }}
                  className='text-center'
                >
                  {num === 0
                    ? this.props.star_label_one || "Terrible"
                    : num === 4
                    ? this.props.star_label_five || "Great"
                    : "."}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  _grabNumberOpacity = (num) => {
    switch (num) {
      case 0:
      case 6:
        return 0.33;
      case 1:
      case 5:
        return 0.66;
      default:
        return 1;
    }
  };

  _renderVideoBox = (
    loader,
    tooLarge,
    uploadError,
    preview,
    video,
    remove,
    dismissError,
    dismissSize,
    originalUrl,
    removeOriginal,
    backupUrl
  ) => {
    if (originalUrl || backupUrl) {
      return (
        <div className='w-100 d-flex flex-column align-items-center'>
          <p className='remove-original' onClick={removeOriginal}>
            <i className='fa fa-times' aria-hidden='true' /> Remove video
          </p>
          <ReactPlayer
            className='drill-react-player'
            url={backupUrl || originalUrl}
            width='100%'
            height='240px'
            controls
          />
        </div>
      );
    }
    if (tooLarge) {
      return (
        <div className='inner-upload-preview'>
          {/* <div className='remove-button' onClick={removeVideo}>
            <i className='fa fa-times' aria-hidden='true' />
          </div> */}
          <div className='preview-placeholder'>
            <i className='fa fa-ban' aria-hidden='true' />
          </div>
          <p className='video-name'>
            There was an issue. Your video file is too large. Please compress your video file to be smaller than 50MB and then try your upload again.
          </p>
          <button className='clear-button' onClick={dismissSize}>
            Ok
          </button>
        </div>
      );
    }
    if (uploadError) {
      return (
        <div className='inner-upload-preview'>
          {/* <div className='remove-button' onClick={removeVideo}>
            <i className='fa fa-times' aria-hidden='true' />
          </div> */}
          <div className='preview-placeholder'>
            <i className='fa fa-exclamation-triangle ' aria-hidden='true' />
          </div>
          <p className='video-name'>{uploadError}</p>
          <button className='clear-button' onClick={dismissError}>
            Ok
          </button>
        </div>
      );
    }
    if (loader && preview) {
      return (
        <div className='inner-upload-preview'>
          <div className='preview-placeholder'>
            <i className='fa fa-spinner fa-spin' aria-hidden='true' />
          </div>
          <p>Hold tight</p>
          <p className='video-name'>{preview.name}</p>
          <p>is uploading...</p>
        </div>
      );
    }
    if (video && !loader) {
      return (
        <div className='inner-upload-preview'>
          <div className='remove-button' onClick={remove}>
            <i className='fa fa-times' aria-hidden='true' />
          </div>
          <div className='preview-placeholder'>
            <i className='fa fa-file-video-o ' aria-hidden='true' />
          </div>
          <p className='video-name'>{preview.name}</p>
        </div>
      );
    }
    return (
      <div className='inner-dashed-box'>
        <div
          className='play-icon-box'
          onClick={() => this._openDialog("fileInput")}
        >
          <i className='fa fa-play-circle' aria-hidden='true' />
        </div>
        <p>Click to add video</p>
      </div>
    );
  };

  _renderPhotoBox = (photos, removePhoto, blowUpPhoto) => {
    let count = photos.length;
    if (count === 0) {
      return (
        <div
          className='inner-dashed-box'
          onClick={() => this._openDialog("photoInput")}
        >
          <i className='fa fa-picture-o' aria-hidden='true' />
          <p>Click to add images</p>
          <i className='fa fa-picture-o' aria-hidden='true' />
        </div>
      );
    }
    return (
      <div className='inner-photo-preview'>
        {photos.map((p, i) => {
          return (
            <div
              key={i}
              className='photo-preview photo-preview-image'
              style={{ backgroundImage: `url(${p.src})` }}
              onClick={() => blowUpPhoto(i)}
            >
              <div
                className='photo-remove-btn'
                onClick={(e) => removePhoto(e, i, p.isLocal, p.id)}
              >
                <i className='fa fa-times' aria-hidden='true' />
              </div>
            </div>
          );
        })}
        {count < 5 && (
          <div
            className='photo-preview loader'
            onClick={() => this._openDialog("photoInput")}
          >
            <i className='fa fa-plus' aria-hidden='true' />
          </div>
        )}
      </div>
    );
  };

  _openDialog = (el) => {
    this[el].click();
  };

  _nullOutValue = (el) => {
    // this method clears out the value of the input so the user can upload the same video/photo after they remove it
    this[el].value = null;
  };
}

export default DrillBasicDetail;
