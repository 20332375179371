import React from "react";
import moment from "moment";
import PropTypes from 'prop-types'
// import defaultProfilePic from "../../../assets/images/default_profile.png";
import roundsIcon from "../../../assets/images/common/icons/play-icon-light.png";
import trainIcon from "../../../assets/images/common/icons/train-icon-light.png";
import socialIcon from "../../../assets/images/common/icons/social-icon-light.png";
import statsIcon from "../../../assets/images/common/icons/stats-icon-orange.png";
import trophyIcon from "../../../assets/images/common/icons/trophy.png";
import clipboardIcon from "../../../assets/images/common/icons/clipboard.png";
import learningGrayIcon from "../../../assets/images/common/icons/stats/learning-skills-icon-gray.png";
import trophyGrayIcon from "../../../assets/images/common/icons/stats/trophy-gray.png";
import notesIcon from "../../../assets/images/common/icons/notes-icon.png";
import checkIcon from "../../../assets/images/common/icons/checkmark-icon.png";
import golfCourseWhole from "../../../assets/images/common/golf-course-whole.png";
import DIVISION_BADGES from "../../../constants/divisionBadges.js"; // Division badge images
import HorizontalLogo from '../../common/logoSVGs/HorizontalLogo';
// import logo from "../../../assets/images/logos/logo-hori-white.png";
import backArrowIcon from "../../../assets/images/common/icons/back-arrow-icon.png";
import starGrayIcon from "../../../assets/images/common/icons/star-gray.png";
import PlayerJourneyReportDivisionProgress from "./PlayerJourneyReportDivisionProgress";
import PlayerJourneyReportProgressBar from "./PlayerJourneyReportProgressBar";
import PlayerJourneyReportLevelsProgress from "./PlayerJourneyReportLevelsProgress";
import DIVISIONS from "../../../constants/divisions.json";

let badgeList = [...DIVISION_BADGES];
let divisions = [...DIVISIONS];

const PlayerJourneyReportView = ({ report, student }) => { // eslint-disable-line
  const divisionColor = divisions.filter(
    (division) => division.order === report.current_division
  )[0].color;
  const rankColor =
    "objective-rank-" + report.my_curriculum_progress.current_rank + "-text";

  let holes_played_label = "DONE!";
  let isHolesPlayedDone = true;
  if (
    report.my_curriculum_progress.work_ethic.holes_played <
    report.my_curriculum_progress.work_ethic.holes_played_goal
  ) {
    holes_played_label =
      report.my_curriculum_progress.work_ethic.holes_played_goal -
      report.my_curriculum_progress.work_ethic.holes_played +
      " more!";
    isHolesPlayedDone = false;
  }

  let training_sessions_label = "DONE!";
  let isTrainSessionsDone = true;
  if (
    report.my_curriculum_progress.work_ethic.training_sessions <
    report.my_curriculum_progress.work_ethic.training_sessions_goal
  ) {
    training_sessions_label =
      report.my_curriculum_progress.work_ethic.training_sessions_goal -
      report.my_curriculum_progress.work_ethic.training_sessions +
      " more!";
    isTrainSessionsDone = false;
  }

  let minutes_trained_label = "DONE!";
  let isMinutesTrainedDone = true;
  if (
    report.my_curriculum_progress.work_ethic.minutes_trained <
    report.my_curriculum_progress.work_ethic.minutes_trained_goal
  ) {
    minutes_trained_label =
      report.my_curriculum_progress.work_ethic.minutes_trained_goal -
      report.my_curriculum_progress.work_ethic.minutes_trained +
      " more!";
    isMinutesTrainedDone = false;
  }

  return (
    <div className='PlayerJourneyReportView'>
      <div className='profile'>
        {/*
          <div className='avatar-container'>
            <img
              className='avatar'
              src={report.avatar ? report.avatar.file_url : defaultProfilePic}
              alt={report.altName}
            />
          </div>
        */}
        <div className='golfer-info'>
          <p className='name'>{report.name} </p>
          <p className='division'>Level {report.current_division} Golfer</p>
          <p className='age-member-since'>
            Age: {report.age ? report.age : "N/A"} | Member since{" "}
            {moment(report.member_since).format("MM/DD/YY")}
          </p>
        </div>
        <div className='division-line'></div>
          <div className='all-time-records'>
            <div className='column'>
              <div className='icon-and-stat-number'>
                <img className='ir-icon' src={roundsIcon} alt='rounds of golf logged'/>
                <p className='all-time-stat-qty'> {report.total_rounds}</p>
              </div>
              <p className='round-of-golf'>Rounds of Golf</p>

              <div className='all-time-stat-substat'>
                <span className='round-of-formal-round'>
                  - <span className='substat-qty'>{report.formal_rounds}</span> Formal
                </span>
                <span className='round-of-casual-round'>
                  - <span className='substat-qty'>{report.casual_rounds}</span> Casual
                </span>
              </div>
            </div>
            <div className='column'>
              <div className='icon-and-stat-number'>
                <img className='ir-icon' src={trainIcon} alt='training sessions logged'/>
                <p className='all-time-stat-qty'>
                  {" "}
                  {report.total_training_sessions}
                </p>
              </div>
              <p className='training-sessions'>Training Sessions</p>

              <div className='all-time-stat-substat'>
                <span className='round-of-total-training-duration'>
                  - <span className='substat-qty'>
                  {Math.floor(report.total_training_duration / 60)}</span> Hours
                </span>
              </div>
            </div>
            <div className='column'>
              <div className='icon-and-stat-number'>
                <img className='ir-icon' id='ir-icon-social' src={socialIcon} alt='social actions logged'/>
                <p className='all-time-stat-qty'>
                  {!report.total_social_actions ? 0 : report.total_social_actions}
                </p>
              </div>
              <p className='social-actions'> Social Actions</p>
            </div>
          </div>

          <div className='all-time-records'>
            <div className='column'>
              <div className='icon-and-stat-number'>
                <img className='ir-icon' src={trophyIcon} alt='op 36 milestones reached'/>
                <p className='all-time-stat-qty'>
                  {" "}
                  {report.milestones}
                </p>
              </div>
              <p className='milestones-reached-label'>
                Operation 36 Milestones
              </p>
            </div>

            <div className='column'>
              <div className='icon-and-stat-number'>
                <img className='ir-icon' src={clipboardIcon} alt='op 36 objectives completed'/>
                <p className='all-time-stat-qty'>
                  {" "}
                  {report.total_objectives_completed}
                </p>
              </div>
              <p className='objectives-completed-label'>
                Objectives Completed
              </p>
            </div>

            <div className='column'>
              <div className='icon-and-stat-number'>
                <img className='ir-icon' src={statsIcon} alt='total strokes lost'/>
                <p className='all-time-stat-qty'>
                  {" "}
                  {report.total_strokes_lost}
                </p>
              </div>
              <p className='strokes-lost-label'>
                Strokes Lost (All Levels)
              </p>
            </div>
          </div>

          <img
            className='profile-footer-badge'
            src={badgeList[report.current_division - 1]}
            alt='division'
          />
          <HorizontalLogo colorScheme='light' classes='profile-footer-logo'/>
          <p className='profile-footer-report-name'>Player Journey Report</p>
      </div>

      <div className='golf-course-whole-panel'>
        <div className='golf-course-container'>
          <div className='section-title' id='section-title-division-journey'>
            <img
              className='title-icon'
              src={starGrayIcon}
              alt='division journey'
            ></img>
            <p className='title'>
              My Level Journey: From 25 Yards to Full Tee
            </p>
          </div>

          <PlayerJourneyReportLevelsProgress report={report} />

          <div className='note-from-coach-container'>
            <div className='section-title' id='section-title-notes-from-coach'>
              <img
                className='title-icon'
                src={notesIcon}
                alt='note from coach'
              ></img>
              <p className='title'> Notes From Coach</p>
            </div>
            <p className='note-from-coach-body'>{student.note}</p>
          </div>
        </div>

        <div
          className='golf-course-whole-panel-backgroundImage'
          style={{
            backgroundImage: `url(${golfCourseWhole})`,
          }}
        />
      </div>

      <div className='progress-container-panel'>
        <div className='progress-panel'>
          <div className='progress-subpanel'>
            <div className='section-title'>
              <img className='title-icon' src={trophyGrayIcon} alt='my division progress'/>
              <div className='title-text'>
                <p className='title'>Playing Golf</p>
                <p className='subtitle'>
                  My Level {report.current_division} Progress
                </p>
              </div>
            </div>

            <div className='current-division-progress'>
              <PlayerJourneyReportDivisionProgress report={report} />
            </div>

            <p className='round-milestones-reached'>
              <span
                className='round-milestones-reached-qty'
                style={{ color: divisionColor }}>
                {report.my_division_progress.division_milestones}
              </span>
              {" "}
              {`milestone${
                report.my_division_progress.division_milestones === 1 ? "" : "s"
              } reached!`}
            </p>

            <p
              style={{ backgroundColor: divisionColor }}
              className='round-logged-body-title'
            >
              {report.my_division_progress.division_rounds_logged}{" "}
              {`Round${
                report.my_division_progress.division_rounds_logged === 1
                  ? ""
                  : "s"
              }
              Logged`}
            </p>

            <div className='current-division-progress-stats'>
              <div className='stat-wrapper'>
                <p className='stat-qty' style={{ color: divisionColor }}>
                  {!!report.my_division_progress.baseline_score
                    ? report.my_division_progress.baseline_score
                    : "--"}
                </p>
                <p className='stat-label'>Baseline Score</p>
              </div>

              <div className='stat-wrapper'>
                <p className='stat-qty' style={{ color: divisionColor }}>
                  {!!report.my_division_progress.record_score
                    ? report.my_division_progress.record_score
                    : "--"}
                </p>
                <p className='stat-label'>Record Score</p>
              </div>

              <img className='arrow-icon' src={backArrowIcon} alt='arrow'/>

              <div className='stat-wrapper'>
                <p className='stat-qty' style={{ color: divisionColor }}>
                  {!!report.my_division_progress.division_strokes_lost
                    ? report.my_division_progress.division_strokes_lost
                    : "0"}
                </p>
                <p className='stat-label'>Strokes Lost</p>
              </div>

              <div className='stat-wrapper'>
                <p className='stat-qty' style={{ color: divisionColor }}>
                  {!!report.my_division_progress.strokes_to_go
                    ? report.my_division_progress.strokes_to_go
                    : "--"}
                </p>
                <p className='stat-label'>Strokes to Go!</p>
              </div>
            </div>
          </div>

          <div className='division-line-vertical'></div>

          <div className='progress-subpanel'>
            <div className='section-title'>
              <img className='title-icon' src={learningGrayIcon} alt='my curriculum progress'/>
              <div className='title-text'>
                <p className='title'>Learning Skills</p>
                <p className='subtitle'>
                  My Curriculum Progress
                </p>
              </div>
            </div>

            <div className='progress-bar'>
              <p className={`progress-bar-level-title ${rankColor}`}>
                Level {report.my_curriculum_progress.current_rank}
              </p>
              <p className={`progress-bar-level-subtitle ${rankColor}`}>
                {report.my_curriculum_progress.current_rank_name} Objectives
              </p>
              <PlayerJourneyReportProgressBar
                report={report}>
              </PlayerJourneyReportProgressBar>
            </div>

            <div className='ethic-objective'>
              <p className='ethic-objective-title'>
                {report.my_curriculum_progress.current_rank_name} Work Ethic
                Objective
              </p>

              <div className='ethic-objective-stats'>
                <div className='tri-flex rounds-container'>
                  <img className='ethic-objective-icon' src={roundsIcon} alt='work ethic rounds requirement'/>
                  <p className='ethic-objective-stats-title play-color'>
                    {report.my_curriculum_progress.work_ethic.holes_played}/
                    {report.my_curriculum_progress.work_ethic.holes_played_goal}
                  </p>
                  <p className='ethic-objective-stats-subtitle'>holes played</p>
                  <div className={`ethic-objective-stats-box ${
                    isHolesPlayedDone
                      ? " border-play-done"
                      : " border-play"
                    }`}>
                    <p className='ethic-objective-stats-box-text'>
                      {holes_played_label}
                    </p>
                    {isHolesPlayedDone &&
                      <img className='check-icon' src={checkIcon} alt='' />
                    }
                  </div>
                </div>

                <div className='tri-flex rounds-container'>
                  <img className='ethic-objective-icon' src={trainIcon} alt='work ethic training sessions requirement'/>
                  <p className='ethic-objective-stats-title train-color'>
                    {report.my_curriculum_progress.work_ethic.training_sessions}
                    /
                    {
                      report.my_curriculum_progress.work_ethic
                        .training_sessions_goal
                    }
                  </p>
                  <p className='ethic-objective-stats-subtitle'>
                    train. sessions
                  </p>
                  <div className={`ethic-objective-stats-box ${
                    isTrainSessionsDone
                      ? " border-train-done"
                      : " border-train"
                    }`}>
                    <p className='ethic-objective-stats-box-text'>
                      {training_sessions_label}
                    </p>
                    {isTrainSessionsDone &&
                      <img className='check-icon' src={checkIcon} alt='' />
                    }
                  </div>
                </div>

                <div className='tri-flex rounds-container'>
                  <img className='ethic-objective-icon' src={trainIcon} alt='work ethic training minutes requirement'/>
                  <p className='ethic-objective-stats-title train-color'>
                    {report.my_curriculum_progress.work_ethic.minutes_trained}/
                    {
                      report.my_curriculum_progress.work_ethic
                        .minutes_trained_goal
                    }
                  </p>
                  <p className='ethic-objective-stats-subtitle'>min. logged</p>
                  <div className={`ethic-objective-stats-box ${
                    isMinutesTrainedDone
                      ? " border-train-done"
                      : " border-train"
                    }`}>
                    <p className='ethic-objective-stats-box-text'>
                      {minutes_trained_label}
                    </p>
                    {isMinutesTrainedDone &&
                      <img className='check-icon' src={checkIcon} alt='' />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PlayerJourneyReportView.propTypes = {
  report: PropTypes.shape({
    current_division: PropTypes.number,
  }),
}

export default PlayerJourneyReportView;
