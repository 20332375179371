import React, { Component } from "react";
import _ from "lodash";

// ASSETS
import "./SimpleEducationLesson.scss";

class SimpleEducationLesson extends Component {
  state = { completed: this.props.isCompleted };

  toggleCompleted = () => {
    this.setState({
      completed: !this.state.completed
    });
  };

  renderIcon = () => {
    const { isCompleted } = this.props;

    if (!isCompleted) {
      return <i className="fa fa-circle-thin animated fadeIn" />;
    }
    return <i className="fa fa-check-circle animated bounceIn" />;
  };

  render() {
    const { lesson, index, selectLesson, selectedLesson, isCoach } = this.props;
    return (
      <div id="SimpleEducationLesson">
        <button
          className={`row lesson-button  ${selectedLesson.id === lesson.id &&
            "selected-lesson"}`}
          onClick={() => selectLesson(lesson)}
        >
          <p>
            {index + 1}. {lesson.name}
          </p>
          {isCoach && this.renderIcon()}
        </button>
      </div>
    );
  }
}

export default SimpleEducationLesson;
