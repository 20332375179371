import { useEffect, useState } from 'react'
import StudentsPage from '../students/StudentsPage'
import FamiliesPage from '../families/FamiliesPage'
import PageHeader from '../common/PageHeader/PageHeader'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentTab } from '../../actions/commonActions'
import styles from '../../assets/styles/components/families/FamiliesStudentsPage.module.scss'
import { checkLicensePermission } from '../../utility/communityLicense'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'

const FamiliesStudentsPage = () => {
  const dispatch = useDispatch()
  const [showFamilyModal, setShowFamilyModal] = useState(false)
  const [tabIndex, setTabIndex] = useState(0)
  const licenseType = useSelector(state => state.user.current_user.communities[0]?.license_type)

  useEffect(() => {
    dispatch(setCurrentTab('Students-Families'))
  }, [dispatch])

  const FamilyHeaderActionButton = (
    <button
      className='btn btn-primary text-white pull-right mr-2'
      onClick={() => setShowFamilyModal(true)}
    >
      Invite Family
    </button>
  )

  return (
    <div id={styles.FamiliesStudents}>
      <PageHeader
        title='Students & Families'
        actionComponent={tabIndex === 1 && checkLicensePermission(licenseType, ['standard_legacy']) ? FamilyHeaderActionButton : null}
      />
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList className={styles.tabList}>
          <Tab className={`${styles.tab} react-tabs__tab`} selectedClassName={styles.activeTab}>Students</Tab>
          <Tab className={`${styles.tab} react-tabs__tab`} selectedClassName={styles.activeTab}>Families</Tab>
        </TabList>
        <TabPanel className={styles.tabPanel}>
          <StudentsPage />
        </TabPanel>
        <TabPanel className={styles.tabPanel}>
          <FamiliesPage showFamilyModal={showFamilyModal} setShowFamilyModal={setShowFamilyModal}/>
        </TabPanel>
      </Tabs>
    </div>
  )
}


export default FamiliesStudentsPage
