import * as types from "../actions/actionTypes";

export default function community(state = {}, action) {
  switch (action.type) {
    case types.GET_COMMUNITY:
      return action.community;
    case types.TOGGLE_COMMUNITY_FEATURE:
      return { ...state, features: action.features }
    default:
      return state;
  }
}
