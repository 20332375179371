module.exports = [
  'TIP: Don’t forget that the further you can progress golfers in the Op 36 Levels, ' +
  'the better chance you have to ensure they are a lifelong golfer.',
  'TIP: Like and Comment on your player’s activities every night at 8:00 PM.  ' +
  'It will send them a push notification and show parents and golfers you care.',
  'TIP:  Print or send a player report to your players on a consistent basis to help them know where they stand on their journey.',
  'TIP: Check your player engagement report each week and write down 5 golfers who you need to nudge to get them engaged again.',
  'TIP: Each month, offer a lesson or reach out to the golfers who have most attempts from each level to help get them to their next level.  ' +
  'Find this easily by clicking levels at top of your dashboard.',
  'TIP: Showcase more value to your golfers by taking a little time to set a private goal on their profile. ' +
  'It sends parents and golfer an email and push notification, and shows you care about their development.',
  'TIP: Run your engagement report and take a screenshot of the golfers who have not accomplished a goal in the last 60 days ' +
  'and see how many you can get back to green light status!',
  'TIP: Play 9 holes once a week where you invite 3 of your students to play with you.  It will give you better insight into their game, ' +
  'and gives an opportunity to deepen your relationship with them.',
  'TIP: A program is only as successful as the leader who is carrying it out',
  'TIP: Stay organized by reviewing your video lesson plans for your programs at the beginning of the week with your staff ' +
  'so everyone is on the same page.  Update the plans to fit your facility if needed.',
  'TIP: When using the Op 36 Curriculum did you know you can mark goals complete quickly right from the program tab?  ' +
  'We recommend marking the goal complete as soon as the golfer accomplishes it!',
];
