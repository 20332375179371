import React, { Component } from 'react';
import Toggle from 'react-toggle';
import ReactTelephoneInput from 'react-telephone-input';
import 'react-telephone-input/css/default.css';
import flags from '../../assets/images/flags.png';
import colors from '../../assets/styles/globals.scss';
import TextInput from '../common/form/TextInput';
import TextAreaInput from '../common/form/TextAreaInput'
import PlacesAutocomplete from 'react-places-autocomplete';
import Select from 'react-select'
import timezones from 'timezones-list'
import { getTimezoneId } from '../../actions/googleActions'
import MCElement from '../common/MCElement'
import FieldError from '../common/FieldError'
import CurrencySelect from './CurrencySelect'
import { checkLicensePermission, licenseLabel } from '../../utility/communityLicense'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Error from '../common/ErrorMessage'
import {
  validateApplicationFeeFixed,
  validateApplicationFeePercentage,
  isValidEmail,
} from '../../utility/helperFunctions'

// ASSETS
import './CommunityForm.scss'


class CommunityForm extends Component {
  state = {
    community: {
      id: null,
      name: '',
      description: '',
      email: '',
      community_type: '',
      phone_number: '',
      representative_id: -1,
      sales_rep_id: -1,
      booking_url: '',
      is_public: true,
      address: '',
      location: {
        lat: 0,
        lng: 0,
        country: '',
        city: '',
        street: '',
        zip_code: '',
        state: '',
      },
      timezone: '',
      license_type: 'standard',
      currency_code: 'usd',
      application_fee_type: 'fixed',
      application_fee: 0,
      photo: null,
      hubspot_onboarding_id: '',
      hubspot_company_id: '',
    },
    photoUrl: '',
    currency: { name: 'USA Dollar', symbol: '$', code: 'usd' },
    formError: {},
    generalError: false,
  }

  componentDidMount() {
    const community = this.props.editCommunity
    if (community) {
      const updateState = this.loadEditableData(community)
      this.setState(updateState)
    }
  }

  // eslint-disable-next-line complexity
  loadEditableData = (community) => {
    const updateState = {
      community: {
        id: community.id,
        name: community.name,
        email: community.email || '',
        booking_url: community.booking_url || '',
        community_type: community.community_type,
        phone_number: community.phone_number || '',
        is_public: community.is_public === null ? true : community.is_public,
        description: community.description,
        representative_id: community.representative_id || '',
        sales_rep_id: community.sales_rep_id || '',
        license_type: community.license_type,
        currency_code: community.currency.code,
        application_fee_type: community.application_fee_type,
        application_fee: community.application_fee,
        timezone: community.timezone || '',
        hubspot_onboarding_id: community.hubspot_onboarding_id || '',
        hubspot_company_id: community.hubspot_company_id || '',
      },
      currency: community.currency,
    }

    if (community.address) {
      updateState.community.address = community.address.name || ''
      updateState.community.location = {
        lat: community.address.lat || 0,
        lng: community.address.lng || 0,
        country: community.address.country || '',
        city: community.address.city || '',
        street: community.address.street || '',
        zip_code: community.address.zip_code || '',
        state: community.address.state || '',
      }
    }

    if (community.photo) {
      updateState.photoUrl = community.photo.file_url
    }

    return updateState
  }

  isValidForm = () => {
    for (const key in this.state.formError) {
      if (this.state.formError[key]) {
        return false
      }
    }
    return true
  }

  onChangePhoto = (event) => {
    if (event.target.value) {
      this.checkInput(event, 'photoUrl')
      this.setState({
        community: { ...this.state.community, photo: event.target.files[0] },
      })
      var reader = new window.FileReader();
      var page = this;
      reader.onload = function (e) {
        page.setState({
          photoUrl: e.target.result,
        })
      };
      reader.readAsDataURL(event.target.files[0])
    }
  }

  handleInput = (event) => {
    const inputName = event.target.name
    const inputValue = event.target.value
    const positive_integers = ['hubspot_onboarding_id', 'hubspot_company_id']

    if (positive_integers.find(el => el === inputName) && inputValue < 0) {
      return
    }

    this.setState({
      community: { ...this.state.community, [inputName]: inputValue },
    })
  }

  handleAddressComponentsInputChange = (event) => {
    const inputName = event.target.name
    const inputValue = event.target.value

    if (this.state.community.location[inputName] !== inputValue) {
      this.setState({
        community: {
          ...this.state.community,
          location: {
            ...this.state.community.location,
            [inputName]: inputValue,
          },
        },
      })
    }
  }

  handleLicenseTypeChange = (event) => {
    this.setState({
      community: {
        ...this.state.community,
        license_type: event.target.value,
        application_fee_type: 'fixed',
        application_fee: 0,
      },
    })
  }

  handleInputChange = (telNumber) => {
    this.setState({
      community: { ...this.state.community, phone_number: telNumber },
    })
  }

  handleApplicationFeeType = (appFeeType) => {
    this.setState({
      community: { ...this.state.community, application_fee_type: appFeeType, application_fee: 0 },
    })
  }

  handleCurrency = (newCurrency) => {
    this.setState({
      community: { ...this.state.community, currency_code: newCurrency.code },
      currency: newCurrency,
    })
  }

  handlePublicChange = (e) => {
    this.setState({
      community: { ...this.state.community, is_public: e.target.checked },
    })
  }

  handleAddressChange = (address) => {
    this.setState({
      community: { ...this.state.community, address },
    })
  }

  handleSelect = (address) => {
    this.setState(
      {
        community: { ...this.state.community, address },
      },
      () => {
        geocodeByAddress(address)
          .then((results) => {
            const fields = results[0].address_components
            const street = (fields.find(c => c.types.includes('street_number'))?.long_name || '') + ' ' +
                           (fields.find(c => c.types.includes('route'))?.long_name || '')
            const zip_code = fields.find(c => c.types.includes('postal_code'))?.long_name || ''
            const city = fields.find(c => c.types.includes('locality'))?.long_name || ''
            const state = fields.find(c => c.types.includes('administrative_area_level_1'))?.short_name || ''
            const country = fields.find(c => c.types.includes('country'))?.short_name || ''

            getLatLng(results[0]).then(async (latLng) => {
              const timeZoneId = await getTimezoneId(latLng)
              this.setState({
                community: {
                  ...this.state.community,
                  location: {
                    ...latLng,
                    street: street,
                    zip_code: zip_code,
                    city: city,
                    state: state,
                    country: country,
                  },
                  timezone: timeZoneId,
                },
              })
            })
          })
          .catch((error) => console.error("Error", error))
      }
    )
  }

  handleSelectTimezone = (timezone) => {
    this.setState({
      community: { ...this.state.community, timezone: timezone.value },
    })
  }


  displayRepresentatives = () => {
    const { representatives } = this.props;

    if (representatives && representatives.length > 0) {
      return representatives
        .filter(rep => rep.is_account_manager)
        .map(rep => (
          <option value={rep.id} key={rep.id}>
            {rep.first_name} {rep.last_name}
          </option>
        ));
    }
    return null;
  };

  displaySalesReps = () => {
    const { representatives } = this.props;

    if (representatives && representatives.length > 0) {
      return representatives
        .filter(rep => !rep.is_account_manager)
        .map(rep => (
          <option value={rep.id} key={rep.id}>
            {rep.first_name} {rep.last_name}
          </option>
        ));
    }
    return null;
  };

  customErrorMessage = (type) => {
    switch (type) {
      case 'photoUrl':
        return 'Please attach a profile photo'
      case 'name':
        return 'Please enter a name'
      case 'community_type':
        return 'Please select a type'
      case 'email':
        return 'Invalid email format'
      case 'representative_id':
        return 'Please select an account manager'
      case 'sales_rep_id':
        return 'Please select a sales representative'
      case 'address':
        return 'Please enter an address'
      case 'timezone':
        return 'Please enter a timezone'
      case 'country':
        return 'Please enter a country'
      case 'zip_code':
        return 'Please enter a zip code'
      case 'street':
        return 'Please enter a street'
      case 'city':
        return 'Please enter a city'
      case 'application_fee':
        return 'Invalid application fee value'
      default:
        return ''
    }
  }

  checkInput = (event, type) => {
    if (
      !event.target.value.trim() ||
      event.target.value === '-1' ||
      event.target.value === 'unselected'
    ) {
      this.setState(prevState => ({
        formError: { ...prevState.formError, [type]: [this.customErrorMessage(type)] },
      }))
    } else {
      this.setState(prevState => ({
        formError: { ...prevState.formError, [type]: null },
      }))
    }

    if (type === 'address') {
      this.checkAddressInputs()
    }

    if (type === 'application_fee') {
      if (this.state.community.application_fee_type === 'fixed'
        ? !validateApplicationFeeFixed(this.state.community.application_fee)
        : !validateApplicationFeePercentage(this.state.community.application_fee)) {
        this.setState(prevState => ({
          formError: { ...prevState.formError, 'application_fee': [this.customErrorMessage('application_fee')] },
        }))
      } else {
        this.setState(prevState => ({
          formError: { ...prevState.formError, 'application_fee': null },
        }))
      }
    }
  }

  checkAddressInputs = () => {
    ['country', 'zip_code', 'street', 'city'].forEach((type) => {
      if (!this.state.community.location[type].trim()) {
        this.setState(prevState => ({
          formError: { ...prevState.formError, [type]: [this.customErrorMessage(type)] },
        }))
      } else {
        this.setState(prevState => ({
          formError: { ...prevState.formError, [type]: null },
        }))
      }
    })
  }

  checkEmailInput = (event) => {
    if (event.target.value && !isValidEmail(event.target.value) ) {
      this.setState(prevState => ({
        formError: { ...prevState.formError, email: [this.customErrorMessage('email')] },
      }))
    } else {
      this.setState(prevState => ({
        formError: { ...prevState.formError, email: null },
      }))
    }
  }

  checkAllInputs = (validationCallback) => {
    const errors = {}

    if (this.state.photoUrl === '') {
      errors.photoUrl = [this.customErrorMessage('photoUrl')]
    }

    ['name', 'community_type', 'representative_id', 'sales_rep_id', 'address', 'timezone'].forEach((type) => {
      if (
        this.state.community[type] === -1
        || (this.state.community[type] === '' && !this.state.community[type].trim())
        || !this.state.community[type]
      ) {
        errors[type] = [this.customErrorMessage(type)]
      }
    });

    ['country', 'zip_code', 'street', 'city'].forEach((type) => {
      if (!this.state.community.location[type].trim()) {
        errors[type] = [this.customErrorMessage(type)]
      }
    })

    if (this.state.community.email && !isValidEmail(this.state.community.email)) {
      errors.email = [this.customErrorMessage('email')]
    }

    if (checkLicensePermission(this.state.community.license_type, ['revenue_share'])) {
      if (this.state.community.application_fee_type === 'fixed'
        ? !validateApplicationFeeFixed(this.state.community.application_fee)
        : !validateApplicationFeePercentage(this.state.community.application_fee)) {
        errors.application_fee = [this.customErrorMessage('application_fee')]
      }
    }

    this.setState({ formError: errors }, () => {
      validationCallback()
    })
  }

  handleSubmitCommunityForm = (e, id) => {
    e.preventDefault()

    this.checkAllInputs(() => {
      if (!this.isValidForm()) {
        this.setState({ generalError: true })
      } else {
        const { handleAddCommunity, handleSubmitCommunity } = this.props

        id ? handleSubmitCommunity(e, this.state.community) : handleAddCommunity(e, this.state.community)
      }
    })
  }

  render() { // eslint-disable-line max-lines-per-function, complexity
    const {
      id,
      name,
      description,
      address,
      email,
      community_type,
      phone_number,
      representative_id,
      sales_rep_id,
      booking_url,
      is_public,
      license_type,
      application_fee_type,
      application_fee,
      hubspot_onboarding_id,
      hubspot_company_id,
      location,
    } = this.state.community
    const {
      photoUrl,
      formError,
      currency,
      generalError,
    } = this.state
    const {
      loader,
      errors,
      editCommunity,
    } = this.props;

    const timezoneOptions = timezones.map(t => ({ value: t.tzCode, label: t.name }))
    const currentTimezone = timezoneOptions.find(t => t.value === this.state.community.timezone)

    return (
      <form className='form-container animated fadeIn' id='CommunityForm'>
        <h4 className='mb-4'>
          {id === null
            ? 'Create A New Community'
            : `Edit ${name}`}
        </h4>

        <div className='row'>
          <div className='col-12 mb-2'>
            <label>
              { editCommunity ? 'Edit' : 'Add'} photo <span className='required-text'>*</span>
            </label>
            <label
              id='photoInputArea'
              htmlFor='communityUploadImageThumb'
              className={classNames('d-flex flex-column align-items-center justify-content-center', {
                'error-input': !!formError.photoUrl,
              })}
            >
              <div
                className='header-photo'
                style={photoUrl ? {
                  backgroundImage: 'url(' + photoUrl + ')',
                  border: '1px solid #1D2933',
                } : null}
              >
                {photoUrl ? null : (
                  <i className='fa fa-camera h-100 w-100 d-flex align-items-center justify-content-center' aria-hidden='true'/>
                )}
              </div>
              <span className='mt-2'>{photoUrl ? 'Edit' : 'Add'} photo</span>
            </label>
            <input
              id='communityUploadImageThumb'
              onChange={this.onChangePhoto}
              style={{ display: 'none' }}
              type='file'
              accept='image/*'
            />
            {formError.photoUrl && <FieldError message={formError.photoUrl} />}
          </div>
          <div className='col-12 mb-2'>
            <label>
              Name <span className='required-text'>*</span>
            </label>
            <TextInput
              name={'name'}
              type={'text'}
              className={(formError.name || errors.name) && 'error-input'}
              label={''}
              onChange={this.handleInput}
              onBlur={e => this.checkInput(e, 'name')}
              placeholder={'Community Name'}
              value={name}
            />
            {(formError.name || errors.name) && <FieldError message={formError.name || errors.name} />}
          </div>
          <div className='col-12 mb-2'>
            <label>
              Community Type <span className='required-text'>*</span>
            </label>
            <select
              className={classNames('form-control', { 'error-input': !!formError.community_type })}
              id='community_type'
              name='community_type'
              onChange={this.handleInput}
              onBlur={e => this.checkInput(e, 'community_type')}
              value={community_type || ''}
            >
              <option value={-1}>Choose Community Type</option>
              <option value={'Private'}>Private</option>
              <option value={'Semi-Private'}>Semi-Private</option>
              <option value={'Public'}>Public</option>
              <option value={'Municipal'}>Municipal</option>
              <option value={'Resort'}>Resort</option>
              <option value={'Driving Range'}>Driving Range</option>
              <option value={'Indoor Facility'}>Indoor Facility</option>
              <option value={'Other'}>Other</option>
            </select>
            {formError.community_type && <FieldError message={formError.community_type} />}
          </div>
          <div className='col-12 mb-2'>
            <label>
              Account Manager <span className='required-text'>*</span>
            </label>
            <select
              className={classNames('form-control', { 'error-input': !!(formError.representative_id || errors.representative_id) })}
              id='representative_id'
              name='representative_id'
              onChange={this.handleInput}
              onBlur={e => this.checkInput(e, 'representative_id')}
              value={representative_id}
            >
              <option value={-1}>Choose Community Account Manager</option>
              {this.displayRepresentatives()}
            </select>
            {(formError.representative_id || errors.representative_id) && (
              <FieldError message={formError.representative_id || errors.representative_id} />
            )}
          </div>
          <div className='col-12 mb-2'>
            <label>
              Sales Rep <span className='required-text'>*</span>
            </label>
            <select
              className={classNames('form-control', { 'error-input': !!(formError.sales_rep_id || errors.sales_rep_id) })}
              id='sales_rep_id'
              name='sales_rep_id'
              onChange={this.handleInput}
              onBlur={e => this.checkInput(e, 'sales_rep_id')}
              value={sales_rep_id || ''}
            >
              <option value={-1}>Who made this sale?</option>
              {this.displaySalesReps()}
            </select>
            {(formError.sales_rep_id || errors.sales_rep_id) && <FieldError message={formError.sales_rep_id || errors.sales_rep_id} />}
          </div>

          <div className='col-12 mb-2'>
            <label>
              Description
            </label>
            <TextAreaInput
              name={'description'}
              type={'text'}
              label={''}
              onChange={this.handleInput}
              placeholder={'Accepting new students'}
              value={description}
              rows='7'
              className='textarea'
            />
            {formError.description && <FieldError message={formError.description} />}
          </div>
          <div className='col-12 mb-2'>
            <label>Email</label>
            <TextInput
              name={'email'}
              type={'text'}
              className={formError.email && 'error-input'}
              label={''}
              onChange={this.handleInput}
              onBlur={e => this.checkEmailInput(e)}
              placeholder={'Email'}
              value={email}
            />
            {formError.email && <FieldError message={formError.email} />}
          </div>
          <div className='col-12 mb-2'>
            <label htmlFor='addressInput'>
              Address <span className='required-text'>*</span>
            </label>
            <PlacesAutocomplete
              value={address}
              onChange={this.handleAddressChange}
              onSelect={this.handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <input
                    {...getInputProps({
                      id: 'addressInput',
                      placeholder: 'Address',
                      className: classNames('location-search-input form-control', { 'error-input': !!formError.address }),
                    })}
                    onBlur={e => this.checkInput(e, 'address')}
                  />
                  <div
                    className={`${suggestions &&
                      suggestions.length > 0 &&
                      'autocomplete-dropdown-container'}`}
                  >
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion, index) => {
                      const className = suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item';
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                      return (
                        <div
                          key={index}
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
            {formError.address && <FieldError message={formError.address} />}
          </div>
          <div className='col-12 mb-2'>
            <label htmlFor='timezone-select'>Timezone <span className='required-text'>*</span></label>
            <Select
              id='timezone-select'
              options={timezoneOptions}
              placeholder='Timezone'
              className='timezone-select-input'
              onChange={this.handleSelectTimezone}
              onBlur={e => this.checkInput(e, 'timezone')}
              styles={{ valueContainer: (provided) => ({ ...provided, input: { border: 'none !important' } }) }}
              value={currentTimezone}/>
            {formError.timezone && <FieldError message={formError.timezone} />}
          </div>
          <div className='col-6 mb-2'>
            <label htmlFor='addressCountryInput'>
              Country (Alpha-2 code) <span className='required-text'>*</span>
            </label>
            <TextInput
              id='addressCountryInput'
              name={'country'}
              type={'text'}
              className={formError.country && 'error-input'}
              onChange={this.handleAddressComponentsInputChange}
              onBlur={e => this.checkInput(e, 'country')}
              placeholder={'Country'}
              value={location.country}
            />
            {formError.country && <FieldError message={formError.country} />}
          </div>
          <div className='col-6 mb-2'>
            <label htmlFor='addressZipcodeInput'>
              Zip Code <span className='required-text'>*</span>
            </label>
            <TextInput
              id='addressZipcodeInput'
              name={'zip_code'}
              type={'text'}
              className={formError.zip_code && 'error-input'}
              onChange={this.handleAddressComponentsInputChange}
              onBlur={e => this.checkInput(e, 'zip_code')}
              placeholder={'Zip Code'}
              value={location.zip_code}
            />
            {formError.zip_code && <FieldError message={formError.zip_code} />}
          </div>
          <div className='col-4 mb-2'>
            <label htmlFor='addressStreetInput'>
              Street <span className='required-text'>*</span>
            </label>
            <TextInput
              id='addressStreetInput'
              name={'street'}
              type={'text'}
              className={formError.street && 'error-input'}
              onChange={this.handleAddressComponentsInputChange}
              onBlur={e => this.checkInput(e, 'street')}
              placeholder={'Street'}
              value={location.street}
            />
            {formError.street && <FieldError message={formError.street} />}
          </div>
          <div className='col-4 mb-2'>
            <label htmlFor='addressCityInput'>
              City <span className='required-text'>*</span>
            </label>
            <TextInput
              id='addressCityInput'
              name={'city'}
              type={'text'}
              className={formError.city && 'error-input'}
              onChange={this.handleAddressComponentsInputChange}
              onBlur={e => this.checkInput(e, 'city')}
              placeholder={'City'}
              value={location.city}
            />
            {formError.city && <FieldError message={formError.city} />}
          </div>
          <div className='col-4 mb-2'>
            <label htmlFor='addressStateInput'>
              State (Alpha-2 code)
            </label>
            <TextInput
              id='addressStateInput'
              name={'state'}
              type={'text'}
              onChange={this.handleAddressComponentsInputChange}
              placeholder={'State'}
              value={location.state}
            />
          </div>
          <div className='col-12 mb-2'>
            <label htmlFor='phone'>Phone (choose country)</label>
            <ReactTelephoneInput
              defaultCountry='us'
              flagsImagePath={flags}
              onChange={this.handleInputChange}
              value={phone_number}
            />
          </div>
          <div className='col-12 mb-2'>
            <label>Booking URL</label>
            <TextInput
              name={'booking_url'}
              type={'text'}
              label={''}
              onChange={this.handleInput}
              placeholder={'Booking URL'}
              value={booking_url}
            />
          </div>
          <div className='col-12 mb-2'>
            <label>
              Currency <span className='required-text'>*</span>
            </label>
            <CurrencySelect
              currencies={this.props.currencies}
              currency={currency}
              onCurrencyChange={this.handleCurrency}
              classNames={errors.currency_code && 'error-input'}
            />
            {errors.currency_code && <FieldError message={errors.currency_code} />}
          </div>
          <>
            <div className='col-12 mb-2'>
              <label htmlFor='license_type'>
                License Type <span className='required-text'>*</span>
              </label>
              <select
                className={classNames('form-control', { 'error-input': !!errors.license_type })}
                id='license_type'
                name='license_type'
                onChange={this.handleLicenseTypeChange}
                value={license_type}
              >
                <option value={'standard'}>{licenseLabel('standard')}</option>
                <option value={'revenue_share'}>{licenseLabel('revenue_share')}</option>
                <option value={'standard_legacy'}>{licenseLabel('standard_legacy')}</option>
              </select>
              {errors.license_type && <FieldError message={errors.license_type} />}
            </div>
            {checkLicensePermission(license_type, ['revenue_share']) &&
              <>
                <div className='col-12 mb-2'>
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label>
                    Application Fee Type <span className='required-text'>*</span>
                  </label>

                  <div className='d-flex flex-column justify-content-center align-items-start application-fee-type-field'>
                    <div
                      className='d-flex justify-content-center align-items-center'
                      onClick={() => this.handleApplicationFeeType('fixed')}
                      onKeyDown={(e) => { e.key === 'Enter' && this.handleApplicationFeeType('fixed') }}
                      role='radio'
                      aria-checked={application_fee_type === 'fixed'}
                      tabIndex={0}
                    >
                      <MCElement
                        id='fixedFeeRadioButton'
                        type='radio'
                        primaryColor={application_fee_type === 'fixed' ? colors.secondaryColor : colors.lightNavy}
                        isSelected={application_fee_type === 'fixed'}
                      />
                      <label htmlFor='fixedFeeRadioButton'>Fixed Fee</label>
                    </div>

                    <div
                      className='d-flex justify-content-center align-items-center mt-2'
                      onClick={() => this.handleApplicationFeeType('percentage')}
                      onKeyDown={(e) => { e.key === 'Enter' && this.handleApplicationFeeType('percentage') }}
                      role='radio'
                      aria-checked={application_fee_type === 'percentage'}
                      tabIndex={0}
                    >
                      <MCElement
                        id='percentageFeeRadioButton'
                        type='radio'
                        primaryColor={application_fee_type === 'percentage' ? colors.secondaryColor : colors.lightNavy}
                        isSelected={application_fee_type === 'percentage'}
                      />
                      <label htmlFor='percentageFeeRadioButton'>Percentage Fee</label>
                    </div>
                  </div>
                </div>
                <div className='col-12 mb-2'>
                  <label htmlFor='applicationFeeValueInput' className='w-100'>
                    Application Fee <span className='required-text'>*</span>
                  </label>
                  <span
                    className={classNames(
                      `application-fee-field-${application_fee_type === 'fixed' ? 'currency' : 'percent'}`,
                      { 'error-input': (formError.application_fee || errors.application_fee) }
                    )}
                  >
                    {application_fee_type === 'fixed' && currency.symbol}
                    <input
                      id='applicationFeeValueInput'
                      name='application_fee'
                      type='number'
                      onChange={this.handleInput}
                      onBlur={e => this.checkInput(e, 'application_fee')}
                      value={application_fee}
                    />
                    {application_fee_type === 'percentage' && '%'}
                  </span>
                  {(formError.application_fee || errors.application_fee) && (
                    <FieldError message={(formError.application_fee || errors.application_fee)} />
                  )}
                </div>
              </>
            }
          </>
          <div className='col-12 mb-2'>
            <label>Is this Community Public?</label>
            <div className='pl-2'>
              <Toggle
                checked={is_public}
                onChange={this.handlePublicChange}
              />
            </div>
          </div>
          <div className='col-6 mb-2'>
            <label htmlFor='hubspot_onboarding_id'>HubSpot Onboarding ID</label>
            <div className='pl-2'>
              <TextInput
                name='hubspot_onboarding_id'
                id='hubspot_onboarding_id'
                type='number'
                value={hubspot_onboarding_id}
                onChange={this.handleInput}
                onWheel={(e) => e.target.blur()}
                placeholder='Ex: 13565933651'
              />
            </div>
          </div>
          <div className='col-6 mb-2'>
            <label htmlFor='hubspot_company_id'>HubSpot Company ID</label>
            <div className='pl-2'>
              <TextInput
                name='hubspot_company_id'
                id='hubspot_company_id'
                type='number'
                value={hubspot_company_id}
                onChange={this.handleInput}
                onWheel={(e) => e.target.blur()}
                placeholder='Ex: 21225332887'
              />
            </div>
          </div>
        </div>

        <div className='row mt-3'>
          <div className='d-flex justify-content-end flex-row w-100 px-3'>
            <p className='required-text '>* Required Fields</p>
          </div>
        </div>

        {(generalError || Object.keys(errors).length > 0) && (
          <div className='row'>
            <div className='col-12'>
              <Error error={`Please fill out all of the fields correctly before ${id ? 'editing' : 'creating'} this community`} />
            </div>
          </div>
        )}

        <div className='d-flex justify-content-center align-items-center flex-column'>
          <button
            type='submit'
            className='btn btn-primary mt-5'
            onClick={e => this.handleSubmitCommunityForm(e, id)}
            disabled={loader}
          >
            {loader && (
              <i className='fa fa-spinner fa-spin confirmation-spinner' />
            )}
            {id === null
              ? `Add${loader ? 'ing' : ''}`
              : `Sav${loader ? 'ing' : 'e'}`}{' '}
            Community
          </button>
        </div>
      </form>
    );
  }
}

CommunityForm.propTypes = {
  editCommunity: PropTypes.object,
  handleAddCommunity: PropTypes.func,
  handleSubmitCommunity: PropTypes.func,
  loader: PropTypes.bool,
  currencies: PropTypes.array,
  errors: PropTypes.shape({
    name: PropTypes.arrayOf(PropTypes.string),
    representative_id: PropTypes.arrayOf(PropTypes.string),
    sales_rep_id: PropTypes.arrayOf(PropTypes.string),
    phone_number: PropTypes.arrayOf(PropTypes.string),
    currency_code: PropTypes.arrayOf(PropTypes.string),
    license_type: PropTypes.arrayOf(PropTypes.string),
    application_fee: PropTypes.arrayOf(PropTypes.string),
  }),
}

export default CommunityForm
