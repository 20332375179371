import * as constants from "./actionTypes";
import Axios from '../axios'

export function successRequestingGetRepresentativeNotes(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_REPRESENTATIVE_NOTES,
      representative_notes: response.data.representative_notes
    };
  } else {
    console.log("ERROR GETTING REPRESENTATIVES");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successRequestingCreateRepresentativeNote(response) {
  if (response.status === 201) {
    // console.log("CREATED COURSE")
    return {
      type: constants.CREATE_REPRESENTATIVE_NOTE,
      representative_note: response.data.representative_note
    };
  } else {
    console.log("ERROR ON CREATING REPRESENTATIVE NOTE");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successRequestingUpdateRepresentativeNote(response) {
  if (response.status === 201) {
    // console.log("CREATED COURSE")
    return {
      type: constants.UPDATE_REPRESENTATIVE_NOTE,
      representative_note: response.data.representative_note
    };
  } else {
    console.log("ERROR ON UPDATING REPRESENTATIVE NOTE");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successDeleteingRepresentativeNote(response) {
  if (response.status === 201) {
    return {
      type: constants.DELETE_REPRESENTATIVE_NOTE,
      representative_note: response.data.representative_note
    };
  } else {
    console.log("ERROR ON DELETING REPRESENTATIVE NOTE");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

// THUNKS HERE
export function getRepresentativeNotes(community_id) {
  return function(dispatch) {
    return requestGetRepresentativeNotes(community_id).then(response =>
      dispatch(successRequestingGetRepresentativeNotes(response))
    );
  };
}

export function createRepresentativeNote(note) {
  return function(dispatch) {
    return requestCreateRepresentativeNote(note).then(response =>
      dispatch(successRequestingCreateRepresentativeNote(response))
    );
  };
}

export function updateRepresentativeNote(note) {
  return function(dispatch) {
    return requestUpdateRepresentativeNote(note).then(response =>
      dispatch(successRequestingUpdateRepresentativeNote(response))
    );
  };
}

export function deleteRepresentativeNote(note_id) {
  return function(dispatch) {
    return requestDeleteRepresentativeNote(note_id).then(response =>
      dispatch(successDeleteingRepresentativeNote(response))
    );
  };
}

// API CALLS
function requestGetRepresentativeNotes(community_id) {
  let requestUrl = "/api/v1/representative_notes/?community_id=" + community_id;

  return Axios.get(requestUrl);
}

function requestCreateRepresentativeNote(note) {
  let requestUrl = "/api/v1/representative_notes/";

  return Axios.post(requestUrl, {
    note: note.note,
    community_id: note.community_id,
    representative_id: note.representative_id,
    representative_action_type_id: note.representative_action_type_id,
    contact_made: note.contact_made,
    note_type: note.note_type
  });
}

function requestUpdateRepresentativeNote(note) {
  let requestUrl = "/api/v1/representative_notes/" + note.id;

  return Axios.put(requestUrl, {
    note: note.note,
    community_id: note.community_id,
    representative_id: note.representative_id,
    representative_action_type_id: note.representative_action_type_id,
    contact_made: note.contact_made,
    note_type: note.note_type
  });
}

function requestDeleteRepresentativeNote(note_id) {
  let requestUrl = "/api/v1/representative_notes/" + note_id;
  return Axios.delete(requestUrl);
}
