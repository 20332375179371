import ReactToggle from 'react-toggle'
import PropTypes from 'prop-types'

import './Toggle.scss'

const SIZES = ['small', 'medium', 'large']

const Toggle = ({ size, ...restProps }) => {
  const size_ = SIZES.includes(size) ? size : 'medium'

  return (
    <ReactToggle
      className={`op36-toggle ${size_}`}
      icons={false}
      {...restProps}
    />
  )
}

Toggle.propTypes = {
  size: PropTypes.string,
}

export default Toggle
