/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import _ from 'lodash'

import * as leadsActions from '../../actions/leadsActions'
import * as commonActions from '../../actions/commonActions'
import * as communitiesActions from '../../actions/communitiesActions'
import * as familyActions from '../../actions/familyActions'
import * as viewActions from '../../actions/viewActions'

import { isValidEmail } from '../../utility/helperFunctions'

import ActionLeadModal from './ActionLeadModal'
import DeleteConfirmationModal from './DeleteConfirmationModal'
import Loader from '../common/Op36Loader-web'
import StateSwitcher from '../common/StateSwitcher'
import LeadStatsGraph from './LeadStatsGraph'
import MemberStatsGraph from './MemberStatsGraph'
import PageViewsGraph from './PageViewsGraph'
import PageHeader from '../../components/common/PageHeader/PageHeader'

import './LeadsPage.scss'
import { withRouting } from '../common/hooks'
import Tooltip from '../common/Tooltip'

class LeadsPage extends React.Component {
  state = {
    loader: true,
    selectedLead: {},
    actionModal: false,
    viewIsArchived: false,
    // for the modal
    newName: '',
    newEmail: '',
    newPhone: '',
    editSubmitted: false,
    leadNote: '',
    leadNotePostClicked: false,
    newStatus: '',
    notesLoader: false,
    copySuccess: false,
    deleteConfirmationModal: false,
    idToDelete: null,
    deleteClicked: false,
    successDelete: false,
    studentDatesLoader: true,
    pageViewLoader: true,
    emailModalOpen: false,
    inviteSuccess: false,
    sendEmailClicked: false,
    failedEmailValidation: false,
    adminCountsLoader: true,
  }

  componentDidMount() {
    this.props.common_actions.setCurrentTab('Leads')
    if (this.props.user.type === 'Student') {
      this.props.navigate('/dashboard')
    }
    this._getLeads()
    if (this.props.user.type !== 'Admin') {
      this.props.community_actions
        .getCommunityStudentsDates(this.props.user.communities[0].id)
        .then(() => this.setState({ studentDatesLoader: false }))

      this.props.view_actions
        .getViews(this.props.user.communities[0].id)
        .then(() => this.setState({ pageViewLoader: false }))
    } else {
      this.props.view_actions
        .getViews()
        .then(() => this.setState({ pageViewLoader: false }))

      this.props.leads_actions
        .adminLeadCounts()
        .then(() => this.setState({ adminCountsLoader: false }))
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.actionModal) {
      const id = this.state.selectedLead.id
      const nextSelected = nextProps.leads.leads.find((lead) => lead.id === id)
      if (!_.isEqual(nextSelected, this.state.selectedLead)) {
        this.setState({
          selectedLead: nextSelected,
        })
      }
    }
  }
  render() {
    const {
      loader,
      actionModal,
      viewIsArchived,
      selectedLead,
      newName,
      newGolfers,
      newEmail,
      newPhone,
      newStatus,
      notesLoader,
      leadNote,
      leadNotePostClicked,
      deleteConfirmationModal,
      idToDelete,
      deleteClicked,
      successDelete,
      emailModalOpen,
      inviteSuccess,
      sendEmailClicked,
      failedEmailValidation,
    } = this.state

    const isAdmin = this.props.user.type === 'Admin'

    return (
      <div id='LeadsPage'>
        <PageHeader title='Community Leads' />
        <Tooltip />
        <div
          className={deleteConfirmationModal || actionModal ? 'grey-out' : ''}
          onClick={this._closeModals}
        />
        {loader ? (
          <div className='d-flex align-items-center justify-content-center'>
            <Loader message='loading leads' />
          </div>
        ) : (
          <div>
            <div className='d-flex justify-content-end'>
              <button
                className='btn btn-link mr-2 question-button'
                onClick={() => false}
              >
                <i className='fa fa-question' aria-hidden='true' />
              </button>
            </div>
            {isAdmin ? null : (
              <div className='card first-card'>
                <p className='card-title'>My Community Landing Page</p>
                <div className='d-flex'>
                  <input
                    type='text'
                    className='fake-input'
                    ref={(el) => (this.url = el)}
                    value={this._getUrl()}
                    readOnly
                    style={{ width: this._getUrl().length * 10 + 'px' }}
                  />

                  {document.queryCommandSupported('copy') && (
                    <button
                      className='copy-to-clipboard'
                      onClick={this._copyToClipboard}
                    >
                      <i className='fa fa-clone' aria-hidden='true' />
                      {this.state.copySuccess ? ' Copied!' : ' Copy link'}
                    </button>
                  )}
                  <button
                    className='go-to-landing-page-button'
                    onClick={this._goToLandingPage}
                  >
                    View landing page
                  </button>
                </div>
              </div>
            )}
            {isAdmin ? null : (
              <div className='chart-area'>
                <div className='card chart-card landing-page-stats-container'>
                  <div className='card-title-container'>
                    <p className='card-title'>Landing Page Stats</p>
                    <p className='card-subtitle'>this month</p>
                  </div>
                  {this.state.pageViewLoader ? (
                    <div className='d-flex align-items-center justify-content-center'>
                      <Loader message='loading page views' />
                    </div>
                  ) : (
                    <PageViewsGraph views={this.props.page_views} />
                  )}
                </div>
                <div className='card chart-card landing-page-stats-container'>
                  <div className='card-title-container'>
                    <p className='card-title'>Lead Stats</p>
                    <p className='card-subtitle'>this month</p>
                  </div>
                  <LeadStatsGraph
                    dateRange={'monthly'}
                    leads={this.props.leads.leads}
                  />
                </div>
                <div className='card chart-card landing-page-stats-container'>
                  <div className='card-title-container'>
                    <p className='card-title'>Member Stats</p>
                    <p className='card-subtitle'>this month</p>
                  </div>
                  {this.state.studentDatesLoader ? (
                    <div className='d-flex align-items-center justify-content-center'>
                      <Loader message='loading members' />
                    </div>
                  ) : (
                    <MemberStatsGraph dates={this.props.student_dates} />
                  )}
                </div>
              </div>
            )}
            {isAdmin && (
              <div className='chart-area'>
                <div className='card admin-chart-card'>
                  <p className='card-title'>Views</p>
                  <p className='card-desc'>
                    landing page views for all communities
                  </p>
                  <p className='stat-title'>
                    This month:{' '}
                    <span className='stat1 view-stat'>
                      {this.state.pageViewLoader ? (
                        <i className='fa fa-grav fa-spin' aria-hidden='true' />
                      ) : (
                        this.props.page_views.this_month
                      )}
                    </span>
                  </p>
                  <p className='stat-title'>
                    All time:{' '}
                    <span className='stat1 view-stat'>
                      {this.state.pageViewLoader ? (
                        <i className='fa fa-grav fa-spin' aria-hidden='true' />
                      ) : (
                        this.props.page_views.all_time
                      )}
                    </span>
                  </p>
                </div>
                <div className='card admin-chart-card'>
                  <p className='card-title'>Community Leads</p>
                  <p className='card-desc'>new leads for all communities</p>
                  <p className='stat-title'>
                    This month:{' '}
                    <span className='stat1 comm-lead-stat'>
                      {this.state.adminCountsLoader ? (
                        <i className='fa fa-grav fa-spin' aria-hidden='true' />
                      ) : (
                        this.props.lead_admin_stat.comm_this_month
                      )}
                    </span>
                  </p>
                  <p className='stat-title'>
                    All time:{' '}
                    <span className='stat1 comm-lead-stat'>
                      {this.state.adminCountsLoader ? (
                        <i className='fa fa-grav fa-spin' aria-hidden='true' />
                      ) : (
                        this.props.lead_admin_stat.comm_total
                      )}
                    </span>
                  </p>
                </div>
                <div className='card admin-chart-card'>
                  <p className='card-title'>Admin Leads</p>
                  <p className='card-desc'>&apos;no location near me&apos; leads</p>
                  <p className='stat-title'>
                    This month:{' '}
                    <span className='stat1 admin-lead-stat'>
                      {this.state.adminCountsLoader ? (
                        <i className='fa fa-grav fa-spin' aria-hidden='true' />
                      ) : (
                        this.props.lead_admin_stat.admin_this_month
                      )}
                    </span>
                  </p>
                  <p className='stat-title'>
                    All time:{' '}
                    <span className='stat1 admin-lead-stat'>
                      {this.state.adminCountsLoader ? (
                        <i className='fa fa-grav fa-spin' aria-hidden='true' />
                      ) : (
                        this.props.lead_admin_stat.admin_total
                      )}
                    </span>
                  </p>
                </div>
                <div className='card admin-chart-card'>
                  <p className='card-title'>Students</p>
                  <p className='card-desc'>total student counts</p>
                  <p className='stat-title'>
                    This month:{' '}
                    <span className='stat1 total-student-stat'>
                      {this.state.adminCountsLoader ? (
                        <i className='fa fa-grav fa-spin' aria-hidden='true' />
                      ) : (
                        this.props.lead_admin_stat.new_members
                      )}
                    </span>
                  </p>
                  <p className='stat-title'>
                    All time:{' '}
                    <span className='stat1 total-student-stat'>
                      {this.state.adminCountsLoader ? (
                        <i className='fa fa-grav fa-spin' aria-hidden='true' />
                      ) : (
                        this.props.lead_admin_stat.total_members
                      )}
                    </span>
                  </p>
                </div>
              </div>
            )}
            <div className='card'>
              <div className='d-flex justify-content-between'>
                <p className='card-title'>Community Leads</p>
                <StateSwitcher
                  options={[
                    { type: false, text: 'Active' },
                    { type: true, text: 'Archived' },
                  ]}
                  initialTypeSelected={this.state.viewIsArchived}
                  selectedType={this.state.viewIsArchived}
                  stateSwitcher={this._handleViewArchive}
                  position='right'
                />
              </div>
              {this._renderLeads(isAdmin)}
              {this.props.leads.leads && // placeholder if they don't have any leads in the viewed status
              this.props.leads.leads.length > 0 &&
              this.props.leads.leads.some(
                (lead) => lead.is_archived === viewIsArchived
              ) ? null : (
                  <div className='w-100 mt-3 text-center'>
                    <i className='fa fa-info-circle' aria-hidden='true' />
                    <p className='no-lead-placeholder-text'>
                    Looks like you don&apos;t have any leads in{' '}
                      {viewIsArchived ? 'archived' : 'active'} status
                    </p>
                  </div>
                )}
            </div>
          </div>
        )}

        {actionModal ? (
          <ActionLeadModal
            lead={selectedLead}
            close={this._closeModals}
            newStatus={newStatus}
            handleInputChange={this._handleInputChange}
            newName={newName}
            newGolfers={newGolfers}
            newEmail={newEmail}
            newPhone={newPhone}
            saveChanges={this._handleSubmitClick}
            handleArchive={this._toggleArchive}
            notesLoader={notesLoader}
            notes={this.props.lead_notes}
            getPillColor={this._getPillColor}
            leadNote={leadNote}
            handleLeadNoteSubmit={this._handleLeadNoteSubmit}
            postClicked={leadNotePostClicked}
            userAvatar={this.props.user.avatar}
            userFullName={`${this.props.user.first_name} ${this.props.user.last_name}`}
            emailModalOpen={emailModalOpen}
            handleInviteClick={this._triggerEmailModal}
            closeEmailModal={this._closeEmailModal}
            inviteSuccess={inviteSuccess}
            sendEmailClicked={sendEmailClicked}
            handleSendEmail={this._handleSendEmail}
            isAdmin={isAdmin}
            handleSendReminder={this._handleSendReminder}
            failedEmailValidation={failedEmailValidation}
          />
        ) : null}

        {deleteConfirmationModal ? (
          <DeleteConfirmationModal
            id={idToDelete}
            close={this._closeModals}
            kill={this._handleDelete}
            success={successDelete}
            clicked={deleteClicked}
          />
        ) : null}
      </div>
    )
  }

  _handleSendEmail = (name, email, communityId, leadId) => {
    if (isValidEmail(email)) {
      this.setState({ sendEmailClicked: true, editSubmitted: true }, () => {
        const url = window.location.origin
        this.props.family_actions
          .inviteFamily(
            // send the invite to the api
            { email: email, first_name: name },
            communityId,
            this.props.user.id,
            url,
            leadId
          )
          .then((_) =>
            this.setState(
              { inviteSuccess: true, sendEmailClicked: false },
              () => {
                this.props.leads_actions
                  .updateCommunityLead(this.state.selectedLead.id, {
                    // switch is_invited to true on the lead
                    is_invited: true,
                    status: 'invited',
                  })
                  .then(() => {
                    this.props.leads_actions
                      .createLeadNote({
                        // logs a note to the lead that the invite was sent
                        lead_id: this.state.selectedLead.id,
                        user_id: this.props.user.id,
                        content: `<action-invite>Invitation email sent to ${this.state.newEmail}.
                        Switched status from "${this.state.newStatus}" to "invited"`,
                      })
                      .then(() => this.setState({ newStatus: 'invited' }))
                  })
              }
            )
          )
          .catch((e) => console.log(e)) // eslint-disable-line no-console
      })
    } else {
      this.setState({ failedEmailValidation: true })
    }
  }

  _handleSendReminder = (name, email, communityId, leadId, invitation_id) => {
    if (isValidEmail(email)) {
      this.setState({ sendEmailClicked: true }, () => {
        const url = window.location.origin
        this.props.family_actions
          .sendFamilyReminder(
            { email: email, id: invitation_id },
            communityId,
            this.props.user.id,
            url
          )
          .then((_) =>
            this.setState({ inviteSuccess: true, sendEmailClicked: false })
          )
        this.props.leads_actions.createLeadNote({
          lead_id: this.state.selectedLead.id,
          user_id: this.props.user.id,
          content: `<action-invite>Reminder email sent to ${this.state.newEmail}`,
        })
      })
    } else {
      this.setState({ failedEmailValidation: true })
    }
  }

  _triggerEmailModal = () => {
    this.setState({ emailModalOpen: true })
  }

  _closeEmailModal = () => {
    /// just closes this modal and keeps the user on the actionleadmodal
    this.setState({ emailModalOpen: false, inviteSuccess: false })
  }

  _handleLeadNoteSubmit = (event, leadId) => {
    event.preventDefault()
    this.setState({ leadNotePostClicked: true }, () => {
      const data = {
        lead_id: leadId,
        user_id: this.props.user.id,
        content: this.state.leadNote,
      }
      this.props.leads_actions.createLeadNote(data).then(() =>
        this.setState({
          leadNotePostClicked: false,
          leadNote: '',
          editSubmitted: true,
        })
      )
    })
  }

  _copyToClipboard = () => {
    if ('clipboard' in navigator) {
      const text = this.url.value
      navigator.clipboard.writeText(text).then(() => {
        this.setState({ copySuccess: true })
        setTimeout(() => {
          this.setState({ copySuccess: false })
        }, 2500)
      })
    } else {
      this.url.select()
      document.execCommand('copy') // deprecated: https://developer.mozilla.org/en-US/docs/Web/API/Document/execCommand
      this.setState({ copySuccess: true })
    }
  }

  _goToLandingPage = () => {
    if (this.props.user.type === 'Admin') {
      alert("You're an Admin, this button doesn't lead anywhere... sorry") // eslint-disable-line no-alert
    } else {
      this.props.navigate(
        '/landing/' + this.props.user.communities[0].id
      )
    }
  }

  _getUrl = () => {
    if (this.props.user.type === 'Admin') {
      return 'https://operation36.golf'
    }
    return `${window.location.origin}/landing/${this.props.user.communities[0].id}`
  }

  _handleViewArchive = (type) => {
    this.setState({ viewIsArchived: type })
  }

  _getLeads = () => {
    const { leads_actions, user } = this.props
    const { loader } = this.state
    const isAdmin = user.type === 'Admin'
    if (!loader) {
      this.setState({ loader: true }, () => {
        leads_actions
          .getCommunityLeads(isAdmin ? -1 : user.communities[0].id)
          .then(() => {
            this.setState({ loader: false })
          })
      })
    } else {
      // skips the loader step it's already true
      leads_actions
        .getCommunityLeads(isAdmin ? -1 : user.communities[0].id)
        .then(() => {
          this.setState({ loader: false })
        })
    }
  }

  _handleSubmitClick = () => {
    /// not actually a click, but triggered when an input loses focus
    const { selectedLead, newName, newEmail, newPhone, newStatus } = this.state
    const id = selectedLead.id
    const data = {}
    if (newName && newName !== selectedLead.name) {
      data.name = newName
    }
    if (newEmail && newEmail !== selectedLead.email) {
      data.email = newEmail
    }
    if (newPhone && newPhone !== selectedLead.phone) {
      data.phone = newPhone
    }
    if (newStatus && newStatus !== selectedLead.status) {
      data.status = newStatus
      if (newStatus === 'not interested' || newStatus === 'inaccurate info') {
        data.is_archived = true
      }
    }
    if (Object.keys(data).length > 0) {
      this.props.leads_actions.updateCommunityLead(id, data).then((_) => {
        this.setState({ editSubmitted: true })
      })
    }
    // editSubmitted tells the page that the data has changed and triggers a new api hit to get updated info
  }

  _toggleArchive = () => {
    const isArchived = this.state.selectedLead.is_archived
    this.props.leads_actions
      .updateCommunityLead(this.state.selectedLead.id, {
        is_archived: !isArchived,
      })
      .then(() => {
        this.setState({ editSubmitted: true }, () => {
          // this state is set so that the _closeModals function will call the _getLeads function to rerender the table

          this._closeModals()
        })
      })
  }

  _handleInputChange = (e) => {
    const targetName = e.target.name
    this.setState({ [targetName]: e.target.value }, () => {
      if (targetName === 'newStatus') {
        // can capture this change here since it's a select - the other inputs are handled separately since they're triggered onblur
        this.props.leads_actions.createLeadNote({
          lead_id: this.state.selectedLead.id,
          user_id: this.props.user.id,
          content: `<action>switched status from "${this.state.selectedLead.status}" to "${this.state.newStatus}"`,
        })
        this._handleSubmitClick()
      }
      if (targetName === 'newEmail') {
        this.setState({ failedEmailValidation: false })
      }
    })
  }

  _renderLeads = (isAdmin) => {
    const { leads } = this.props
    const leadsCopy = leads.leads.filter(
      (lead) => lead.is_archived === this.state.viewIsArchived
    )
    return (
      <table>
        <thead>
          <tr>
            <th>Added</th>
            <th>Updated</th>
            <th>Name</th>
            <th>{isAdmin ? 'Location' : 'Golfers'}</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Interests</th>
            <th>Skill levels</th>
            <th>Status</th>
            <th />{/*message icon will show under here if one exists*/}
            {/* <th>Action</th> */}
            {isAdmin ? <th /> : null}
          </tr>
        </thead>
        <tbody>
          {leadsCopy.map((lead) => (
            <tr
              key={lead.id}
              className='data-rows'
            >
              <td aria-hidden='true' onClick={() => this._openActionModal(lead.id)}>
                {this._calcLeadAge(lead.created_at)}
              </td>
              <td aria-hidden='true'onClick={() => this._openActionModal(lead.id)}>
                {this._calcLeadAge(lead.last_worked)}
              </td>
              <td aria-hidden='true'onClick={() => this._openActionModal(lead.id)}>
                {lead.name}
              </td>
              <td aria-hidden='true'onClick={() => this._openActionModal(lead.id)}>
                {isAdmin ? lead.location : lead.num_golfers}
              </td>
              <td aria-hidden='true'onClick={() => this._openActionModal(lead.id)}>
                {lead.email}
              </td>
              <td aria-hidden='true'onClick={() => this._openActionModal(lead.id)}>
                {lead.phone || '-not provided-'}
              </td>
              <td aria-hidden='true'onClick={() => this._openActionModal(lead.id)}>
                {lead.interests.join(', ')}
              </td>
              <td aria-hidden='true'onClick={() => this._openActionModal(lead.id)}>
                {lead.skill_levels.join(', ')}
              </td>
              <td aria-hidden='true'onClick={() => this._openActionModal(lead.id)}>
                <p
                  className='status-pill'
                  style={{ background: this._getPillColor(lead.status) }}
                >
                  {lead.status}
                </p>
              </td>
              <td
                onClick={() => this._openActionModal(lead.id)}
                aria-hidden='true'
                className='note-cell'
              >
                {lead.note ? (
                  <i
                    className='fa fa-file-o'
                    aria-hidden='true'
                    data-tip={lead.note}
                  />
                ) : null}
              </td>
              {isAdmin ? (
                <td className='td-delete'>
                  <i
                    className='fa fa-times'
                    aria-hidden='true'
                    onClick={() => this._handleDeleteClick(lead.id)}
                  />
                </td>
              ) : null}
            </tr>
          ))}
        </tbody>
      </table>
    )
  }

  _handleDeleteClick = (id) => {
    this.setState({ idToDelete: id, deleteConfirmationModal: true })
  }

  _handleDelete = (id) => {
    this.setState({ deleteClicked: true }, () => {
      this.props.leads_actions.deleteLead(id).then(() => {
        this.setState({ deleteClicked: false, successDelete: true })
      })
    })
  }

  _calcLeadAge = (date) => {
    if (!date) {
      return 'Never'
    }
    const now = moment()
    let time = now.diff(moment(date), 'days')
    let unit = `day${time !== 1 ? 's' : ''}`
    if (time < 1) {
      time = now.diff(moment(date), 'hours')
      unit = `hour${time !== 1 ? 's' : ''}`
      if (time < 1) {
        time = now.diff(moment(date), 'minutes')
        unit = `minute${time !== 1 ? 's' : ''}`
        if (time < 1) {
          return 'just now'
        }
      }
    }
    return `${time} ${unit} ago`
  }

  _closeModals = () => {
    if (this.state.editSubmitted) {
      this._getLeads()
    }
    this.setState({
      selectedLead: {},
      actionModal: false,
      newName: '',
      newEmail: '',
      newPhone: '',
      editSubmitted: false,
      newStatus: '',
      deleteConfirmationModal: false,
      idToDelete: null,
      successDelete: false,
      emailModalOpen: false,
      inviteSuccess: false,
      failedEmailValidation: false,
    })
  }

  _openActionModal = (id) => {
    const { leads, leads_actions } = this.props
    this.setState(
      {
        selectedLead: leads.leads.find((lead) => lead.id === id),
        notesLoader: true,
      },
      () => {
        leads_actions
          .getLeadNotes(id)
          .then(() => this.setState({ notesLoader: false }))

        this.setState(
          {
            newStatus: this.state.selectedLead.status,
            newName: this.state.selectedLead.name,
            newEmail: this.state.selectedLead.email,
            newPhone: this.state.selectedLead.phone,
          },
          () => this.setState({ actionModal: true })
        )
      }
    )
  }

  _getPillColor = (status) => {
    if (status.indexOf('attempt') > -1) {
      status = 'attempt'
    }
    switch (status) {
      case 'new':
        return '#ff6633' // orange
      case 'attempt':
        return '#fdda12' // gold
      case 'contacted':
        return '#33d9b2' // green
      case 'invited':
        return '#6677de' // purple..... ish
      case 'not interested':
        return '#ec8391' // light red
      case 'inaccurate info':
        return '#dc3446' // daker red
      case 'added':
        return '#2b3843' // dark slate grey
      default:
        return '#fff'
    }
  }
}

LeadsPage.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    communities: PropTypes.arrayOf({
      id: PropTypes.number,
    }),
    avatar: PropTypes.shape({
      file_url: PropTypes.string,
    }),
  }),
  leads: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({
      leads: PropTypes.array,
    }),
  ]),
  lead_admin_stat: PropTypes.shape({
    comm_this_month: PropTypes.number,
    comm_total: PropTypes.number,
    admin_this_month: PropTypes.number,
    admin_total: PropTypes.number,
    new_members: PropTypes.number,
    total_members: PropTypes.number,
  }),
  page_views: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({
      this_month: PropTypes.number,
      all_time: PropTypes.number,
    }),
  ]),
  student_dates: PropTypes.array,
  navigate: PropTypes.func,
  lead_notes: PropTypes.array,
  common_actions: PropTypes.shape({
    setCurrentTab: PropTypes.func,
  }),
  view_actions: PropTypes.shape({
    getViews: PropTypes.func,
  }),
  community_actions: PropTypes.shape({
    getCommunityStudentsDates: PropTypes.func,
  }),
  leads_actions: PropTypes.shape({
    adminLeadCounts: PropTypes.func,
    updateCommunityLead: PropTypes.func,
    getLeadNotes: PropTypes.func,
    createLeadNote: PropTypes.func,
    deleteLead: PropTypes.func,
    getCommunityLeads: PropTypes.func,
  }),
  family_actions: PropTypes.shape({
    inviteFamily: PropTypes.func,
    sendFamilyReminder: PropTypes.func,
  }),
}

function mapStateToProps(state, _) {
  return {
    ui: state.ui,
    user: state.user.current_user,
    leads: state.leads,
    lead_notes: state.leadNotes,
    student_dates: state.communities.student_dates,
    page_views: state.views.views,
    lead_admin_stat: state.leads.admin_counts,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    leads_actions: bindActionCreators(leadsActions, dispatch),
    common_actions: bindActionCreators(commonActions, dispatch),
    community_actions: bindActionCreators(communitiesActions, dispatch),
    family_actions: bindActionCreators(familyActions, dispatch),
    view_actions: bindActionCreators(viewActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouting(LeadsPage))
