import React, { Component } from "react";

// ASSETS
import "./RepresentativeNotesForm.scss";

class RepresentativeNotesForm extends Component {
  checkValidation = () => {
    const { formState } = this.props;

    if (
      parseInt(formState.representative_id, 10) !== -1 &&
      formState.contact_made !== "unselected" &&
      parseInt(formState.status_id, 10) !== -1 &&
      formState.note !== "" &&
      formState.note_type !== "unselected"
    ) {
      return false;
    }
    return true;
  };

  displayRepresentatives = () => {
    const { representatives } = this.props;

    if (representatives && representatives.length > 0) {
      return representatives.map(rep => (
        <option value={rep.id} key={rep.id}>
          {rep.first_name} {rep.last_name}
        </option>
      ));
    }
    return null;
  };

  displayRepresentativeActionTypes = () => {
    const { representativeActionTypes } = this.props;

    if (representativeActionTypes && representativeActionTypes.length > 0) {
      return representativeActionTypes.map(rep => (
        <option value={rep.id} key={rep.id}>
          {rep.name}
        </option>
      ));
    }
    return null;
  };

  render() {
    const {
      formState,
      handleInputChange,
      toggleFormSection,
      createNote,
      updateNote,
      loader,
      isEdit
    } = this.props;
    return (
      <div id='RepresentativeNotesForm' className='animated fadeIn'>
        <div className='rep-form-header'>
          <h4>
            {isEdit
              ? "Update Representative Note"
              : "Create A New Representative Note"}
          </h4>
          <div>
            <button
              className='btn btn-primary'
              onClick={isEdit ? () => updateNote() : () => createNote()}
              disabled={loader || this.checkValidation()}
            >
              {loader && (
                <i className='fa fa-spinner fa-spin light-loading-spinner' />
              )}
              {isEdit
                ? `Updat${loader ? "ing" : "e"}`
                : `Add${loader ? "ing" : ""}`}{" "}
              Note
            </button>
            <button
              className='btn-dark-link'
              onClick={() => toggleFormSection("notes")}
              disabled={loader}
            >
              Cancel
            </button>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-6'>
            <div className='row'>
              <div className='col-6'>
                <label>
                  Current Representative{" "}
                  <span className='required-text'>*</span>
                </label>
                <select
                  className='form-control'
                  id='representative_id'
                  name='representative_id'
                  onChange={handleInputChange}
                  value={formState.representative_id}
                  disabled={loader}
                >
                  <option value={-1} disabled>
                    Choose Current Representative
                  </option>
                  {this.displayRepresentatives()}
                </select>
              </div>
              <div className='col-6'>
                <label>
                  Was Contact Made? <span className='required-text'>*</span>
                </label>
                <select
                  className='form-control'
                  id='contact_made'
                  name='contact_made'
                  onChange={handleInputChange}
                  value={formState.contact_made}
                  disabled={loader}
                >
                  <option value={"unselected"} disabled>
                    Choose Answer
                  </option>
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </select>
              </div>
            </div>

            <div className='row mt-3'>
              <div className='col-6'>
                <label>
                  Note Type <span className='required-text'>*</span>
                </label>
                <select
                  className='form-control'
                  id='note_type'
                  name='note_type'
                  onChange={handleInputChange}
                  value={formState.note_type}
                  disabled={loader}
                >
                  <option value={"unselected"} disabled>
                    Choose Note Type
                  </option>
                  <option value={"Check In"}>Check In</option>
                  <option value={"Recap"}>Recap</option>
                  <option value={"Support"}>Support</option>
                </select>
              </div>
              <div className='col-6'>
                <label>
                  Action Type <span className='required-text'>*</span>
                </label>
                <select
                  className='form-control'
                  id='status_id'
                  name='status_id'
                  onChange={handleInputChange}
                  value={formState.status_id}
                  disabled={loader}
                >
                  <option value={"-1"} disabled>
                    Choose A Status
                  </option>
                  {this.displayRepresentativeActionTypes()}
                </select>
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <label>Representative Notes</label>
            <textarea
              name='note'
              type='text'
              className='form-control'
              rows='5'
              label=''
              onChange={handleInputChange}
              placeholder='Enter some notes...'
              value={formState.note}
              disabled={loader}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default RepresentativeNotesForm;
