import styles from '../../../assets/styles/components/common/ExpandableSideBar.module.scss'

const SideBarLegalLinks = () => (
  <div className={styles.legalLinksContainer}>
    <div className={styles.policyWrapper}>
      <a
        href='https://operation36.golf/legal/'
        className={styles.linkText}
        target='_blank'
      >
        Privacy Policy
      </a>
      <span className={styles.textDivider}>-</span>
      <a
        href='https://operation36.golf/legal/'
        className={styles.linkText}
        target='_blank'
      >
        Terms of Use
      </a>
    </div>
    <p className={styles.rights}>All Rights Reserved | Operation 36</p>
    <p className={styles.version}>v3.0.0</p>
  </div>
)

export default SideBarLegalLinks
