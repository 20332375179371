import React from 'react';
import TextInput from '../common/form/TextInput';
import {Link} from 'react-router-dom';

export default class SetNewPasswordForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      password: '',
      confirmPassword: ''
    };

    this.onChangePassword = this.onChangePassword.bind(this)
    this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this)
    this.submitForm = this.submitForm.bind(this);
  }

  onChangePassword(event){
    this.setState({password: event.target.value});
  }
  onChangeConfirmPassword(event){
    this.setState({confirmPassword: event.target.value});
  }

  submitForm(event) {
    event.preventDefault();
    this.props.handleSubmit(this.state);
  }

  renderConfirmation() {
    return (
      <div>
        <h1>Password reset successfully!</h1>
        <p>Return to your app and try again with your new password.</p>
      </div>
    );
  }

  renderForm() {
    return (
      <form className="form-container" onSubmit={this.submitForm}>
        <div className="row my-3">
          <h2>Reset Your Password</h2>
        </div>
        <div className="row my-3">
          <div className="col">
            <label htmlFor='new-password'>New Password</label>
            <TextInput		
              name='new-password'
              type='password'
              label=''
              value={this.state.password}
              onChange={this.onChangePassword}
              className='my-2 input-border'
              autoComplete='new-password'
            />
          </div>
        </div>
        <div className="row my-3">
          <div className="col">
            <label htmlFor='new-password-confirmation'>New Password Again</label>
            <TextInput
              name='new-password-confirmation'
              type='password'
              label=''
              value={this.state.confirmPassword}
              onChange={this.onChangeConfirmPassword}
              className='my-2 input-border'
              placeholder=''
              autoComplete='new-password'
            />
          </div>
        </div>
        <button type="submit" className="btn btn-block">Change Password</button>
        <Link to='/signin'>
          Remember your password? Login
        </Link>
      </form>
    );
  }

  render() {
    return this.props.passwordWasReset ? this.renderConfirmation() : this.renderForm();
  }
};