import React from "react";

// ASSETS
import "./Goals.scss";

const GoalCard = ({ name, description, goal, nextPage }) => (
  <div
    id='GoalCardContainer'
    className='card mt-3 animated fadeIn animation-shrink'
    onClick={() => nextPage(goal)}
  >
    <div className='goal-card-icon-wrapper'>
      <i className='fa fa-bullseye' />
    </div>
    <div className='goal-card-info-wrapper'>
      <h4>{name}</h4>
      <p>{description}</p>
    </div>
  </div>
);

export default GoalCard;
