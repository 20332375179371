import { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import './TooltipText.scss'

const TooltipText = ({ text, className, twoLinesEllipsis, noEllipsis }) => {
  const [ellipsisActive, setEllipsisActive] = useState(false)
  const textRef = useRef()

  useEffect(() => {
    setEllipsisActive((textRef.current.scrollWidth > textRef.current.offsetWidth) || (textRef.current.scrollHeight > textRef.current.offsetHeight))
  }, [text])

  return (
    <p
      id='tooltip-text'
      ref={textRef}
      className={classNames('tooltip-text one-line-ellipsis', className, {'two-lines-ellipsis': twoLinesEllipsis} )}
      data-tip={text}
      data-tip-disable={noEllipsis || !ellipsisActive}
    >
      {text}
    </p>
  )
}

export default TooltipText
