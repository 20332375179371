import classNames from 'classnames'
import PropTypes from 'prop-types'

import { ReactComponent as ErrorIcon } from '../../../assets/images/common/icons/error-icon.svg'

import styles from './FlowNavigation.module.scss'

const FlowNavigation = ({ currentStep, setStep, errors, steps }) => (
  <section className={styles['navigation-container']}>
    <div className='d-flex flex-wrap justify-content-center align-items-center'>
      {steps.map((step, index) => (
        <div
          key={index}
          aria-hidden='true'
          className={`d-flex flex-column justify-content-between ${styles.step}`}
          onClick={() => setStep(step.index)}
        >
          <div className={`${styles.content} d-flex justify-content-center align-items-center px-md-3 pt-md-3 pb-md-0`}>
            {errors?.[`step${step.index}`]
              ? <ErrorIcon aria-hidden='true' className={`${styles['error-icon']} flex-shrink-0 mr-md-2`} />
              : <step.icon aria-hidden='true' className={classNames('mr-md-2', styles['icon'], { [styles['active']]: currentStep === step.index })} />
            }
            <p className={`d-none d-md-block ${styles.name}`}>{step.title}</p>
          </div>
          <div className={classNames('d-none d-md-block',
            styles['bar'],
            { [styles['active-bar']]: currentStep === step.index },
            { [styles['error-bar']]: currentStep === step.index && errors?.[`step${step.index}`] }
          )}></div>
        </div>
      ))}
    </div>
  </section>
)

FlowNavigation.propTypes = {
  currentStep: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
  errors: PropTypes.object,
  steps: PropTypes.array.isRequired,
}

export default FlowNavigation
