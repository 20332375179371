import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import classNames from 'classnames'

import classes from './ProfileForm.module.scss'
import Input from '../../common/form/Input'
import { useState } from 'react'
import { flatServerFormErrors } from '../../../utility/helperFunctions'
import Toggle from '../../common/form/Toggle'
import { AccountContext } from '../AccountContext'
import { useContext } from 'react'

const ProfileForm = () => {
  const dispatch = useDispatch()
  const { user, updateAction, submitDisabled } = useContext(AccountContext) // Note: user could be golfer OR current_user
  const currentUser = useSelector(state => state.user.current_user)

  const [isUpdating, setIsUpdating] = useState(false)
  const [hasChanges, setHasChanges] = useState(false)
  const [errors, setErrors] = useState({})

  const isCurrentUser = user.id === currentUser.id
  const isCoach = currentUser.type === 'Coach'
  const isEditedUserCoach = user.type === 'Coach'
  const isFamilyAdmin = currentUser.type === 'Student' && currentUser.family_admin

  // Input enabled for: currentUser or familyAdmin
  const disallowCoachToEdit = !isCurrentUser && (isCoach || !isFamilyAdmin)
  const showSocialMediaInputs = isCurrentUser && isCoach

  const validateForm = formData => {
    setErrors({})
    const formErrors = {}

    // const headline = formData.get('headline')
    // const bio = formData.get('bio')
    const facebook = formData.get('facebook')
    const twitter = formData.get('twitter')
    const insta = formData.get('insta')
    // const isGolfer = Boolean(formData.get('isGolfer'))

    if (facebook && !URL.canParse(facebook)) {
      formErrors['social_media'] = { ...formErrors['social_media'], facebook: 'Invalid URL' }
    }

    if (twitter && !URL.canParse(twitter)) {
      formErrors['social_media'] = { ...formErrors['social_media'], twitter: 'Invalid URL' }
    }

    if (insta && !URL.canParse(insta)) {
      formErrors['social_media'] = { ...formErrors['social_media'], insta: 'Invalid URL' }
    }

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors)
      return false
    }

    return true
  }

  const handleSubmitForm = async e => {
    e.preventDefault()
    const formData = new FormData(e.target)

    if (validateForm(formData)) {
      const partialUser = {
        id: user.id,
        email: user?.email,
        username: user?.username,
        headline: formData.get('headline') ?? '',
        bio: formData.get('bio') ?? '',
        facebook: formData.get('facebook') ?? '',
        twitter: formData.get('twitter') ?? '',
        insta: formData.get('insta') ?? '',
        is_golfer: Boolean(formData.get('isGolfer')),
      }

      try {
        setIsUpdating(true)
        const newState = await dispatch(updateAction(partialUser))

        if (newState.type === 'UI_ERROR') {
          setErrors(flatServerFormErrors(newState?.error))
        } else {
          toast.success('User updated!', { toastId: 'profileFormSuccess' }) // TODO: Revise message
        }
      } catch {
        toast.error('Couldn\'t update user. Please check again later')
      } finally {
        setIsUpdating(false)
        setHasChanges(false)
      }
    }
  }

  const handleDetectChanges = (e) => {
    const formData = new FormData(e.currentTarget)
    const headline = formData.get('headline')
    const bio = formData.get('bio')
    const facebook = formData.get('facebook')
    const twitter = formData.get('twitter')
    const insta = formData.get('insta')
    const isGolfer = Boolean(formData.get('isGolfer'))

    const hasChanges_ =
      user?.headline !== (headline || '') ||
      user?.bio !== (bio || '') ||
      (user?.social_media?.facebook || '') !== (facebook || '') ||
      (user?.social_media?.twitter || '') !== (twitter || '') ||
      (user?.social_media?.insta || '') !== (insta || '') ||
      user?.is_golfer !== isGolfer

    if (hasChanges_ !== hasChanges) {
      setHasChanges(hasChanges_)
    }
  }

  return (
    <form onSubmit={handleSubmitForm} onInput={handleDetectChanges} className={classNames(classes.container, 'animated fadeIn')}>
      <div className='row mb-3'>
        <h4 className={classes.title}>Profile Details</h4>
      </div>

      <div className='row mb-3'>
        <label htmlFor='headline'>Headline</label>
        <Input
          type='text'
          id='headline'
          name='headline'
          defaultValue={user?.headline}
          placeholder="What's your headline?"
          classNames={{ inputContainer: classes.accountInputContainer }}
          disabled={disallowCoachToEdit}
          error={errors?.headline}
        />
      </div>

      <div className='row mb-3'>
        <div className='col p-0'>
          <label htmlFor='bio'>About You</label>
          <Input
            type='textarea'
            id='bio'
            name='bio'
            defaultValue={user?.bio}
            placeholder='About You'
            classNames={{ inputContainer: classes.accountInputContainer }}
            disabled={disallowCoachToEdit}
            error={errors?.bio}
          />
        </div>
      </div>


      {showSocialMediaInputs && (
        <div className='mb-3'>
          <div className='row mb-3'>
            <label htmlFor='facebook'>Facebook URL</label>
            <Input
              type='text'
              id='facebook'
              name='facebook'
              defaultValue={user?.social_media?.facebook}
              placeholder='Facebook URL'
              classNames={{ inputContainer: classes.accountInputContainer }}
              disabled={disallowCoachToEdit}
              error={errors?.social_media?.facebook}
            />
          </div>
          <div className='row mb-3'>
            <label htmlFor='twitter'>Twitter URL</label>
            <Input
              type='text'
              id='twitter'
              name='twitter'
              defaultValue={user?.social_media?.twitter}
              placeholder='Twitter'
              classNames={{ inputContainer: classes.accountInputContainer }}
              disabled={disallowCoachToEdit}
              error={errors?.social_media?.twitter}
            />
          </div>
          <div className='row'>
            <label htmlFor='insta'>Instagram URL</label>
            <Input
              type='text'
              id='insta'
              name='insta'
              defaultValue={user?.social_media?.insta}
              placeholder='Instagram URL'
              classNames={{ inputContainer: classes.accountInputContainer }}
              disabled={disallowCoachToEdit}
              error={errors?.social_media?.insta}
            />
          </div>
        </div>
      )}

      {!isEditedUserCoach && (
        <div className='d-flex align-items-center row mb-3'>
          <Toggle id='isGolfer' name='isGolfer' defaultChecked={user?.is_golfer} />
          <label htmlFor='isGolfer' className='my-0 mt-3 mt-xl-0 ml-xl-3'>
          Are you an active golfer?
          </label>
        </div>
      )}

      <div className='row'>
        <button
          type='submit'
          className='btn btn-primary'
          disabled={isUpdating || submitDisabled || !hasChanges}
        >
          Sav{isUpdating ? 'ing' : 'e'} Changes
        </button>
      </div>
    </form>
  )
}

export default ProfileForm
