import React from "react";
import profile_avatar from "../../assets/images/mock/StockProfilePic.png";
import moment from "moment";

import "./ParentClassCard.scss";

class ParentClassCard extends React.Component {
  //   constructor(props, context) {
  //     super(props, context);
  //     var newStudents = 0;
  //     var activeStudents = 0;

  //     for (var i = 0; i < this.props.course.enrollments.length; i++) {
  //       var enrollment = this.props.course.enrollments[i];
  //       switch (enrollment["status"]) {
  //         case "active":
  //           activeStudents++;
  //           break;
  //         case "completed":
  //           activeStudents++;
  //           break;
  //         case "applied":
  //           newStudents++;
  //           break;
  //         default:
  //           break;
  //       }
  //     }

  //     this.state = {
  //       newStudents,
  //       activeStudents,
  //       showConfirmModal: false
  //     };
  //   }



  displayEnrollments = activeEnrollments => {
    return activeEnrollments.slice(0, 8).map(enrollment => {
      return (
        <div className='enrollment-avatar-wrapper' key={enrollment.id}>
          <img
            src={
              enrollment.user.avatar
                ? enrollment.user.avatar.file_url
                : profile_avatar
            }
            alt={`${enrollment.first_name} ${enrollment.last_name}`}
          />
        </div>
      );
    });
  };

  renderEnrollments = () => {
    const {
      course: { enrollments }
    } = this.props;

    let extraStudents = 0;

    if (enrollments && enrollments.length > 0) {
      const activeEnrollments = enrollments.filter(
        enrollment => enrollment.status === "active"
      );
      if (activeEnrollments.length > 8) {
        extraStudents = activeEnrollments.length - 8;
      }
      return (
        <div className='class-card-enrollments-container'>
          {this.displayEnrollments(activeEnrollments)}
          <p>{extraStudents > 0 ? `+${extraStudents} more` : ""}</p>
        </div>
      );
    }
    return (
      <div className='class-card-enrollments-container'>
        <p>No enrollments listed</p>
      </div>
    );
  };

  render() {
    const { course, isStudentProfile, current_user, openModal } = this.props;
    // const { newStudents } = this.state;
    const datesValid = course.start_date && course.end_date
    return (
      <div
        key={course.id}
        id='ParentClassCard'
        className={`${
          isStudentProfile && current_user.type === "Student"
            ? ""
            : "animation-shrink"
        } animated fadeIn`}
      >
        {/* {newStudents > 0 && !isStudentProfile && (
          <div className="class-card-notification">
            <p>{newStudents}</p>
          </div>
        )} */}

        <div
          className={`${this.props.index + 1 > 3 ? "card mt-15px" : "card"} 
            disabled-card`}
        >
          <div className='class-card-header' />
          <div className='class-card-info-wrapper'>
            {course && course.photo_url ? (
              <div className='class-card-photo-wrapper'>
                <img src={course.photo_url} alt={course.name} />
              </div>
            ) : (
              <div className='class-card-photo-wrapper class-card-no-photo'>
                <i className='fa fa-flag' />
              </div>
            )}
            <div>
              <h6>{course.name}</h6>
              <p>
                {course.meeting_times && course.meeting_times != "null"
                  ? course.meeting_times
                  : "No meeting times listed"}
              </p>
              {
                datesValid && (
                  <p>
                    {`${moment(course.start_date).format(
                      "MM-DD-YYYY"
                    )} - ${moment(course.end_date).format("MM-DD-YYYY")}`}
                  </p>
                )
              }
            </div>
          </div>
          {!isStudentProfile && this.renderEnrollments()}
          <button className='btn btn-primary' onClick={() => openModal(course)}>
            Sign Up
          </button>
        </div>
      </div>
    );
  }
}

export default ParentClassCard;
