import React, { Component } from "react";
import moment from "moment";

// CHILDREN
import Toast from "../../../common/Toast";

// ASSETS
import "./NoteRow.scss";

class NoteRow extends Component {
  renderContactMade = () => {
    const {
      note: { contact_made }
    } = this.props;

    if (contact_made) {
      return "Contact Made";
    }
    return "No Contact Made";
  };

  renderContactColor = () => {
    const {
      note: { contact_made }
    } = this.props;

    if (contact_made) {
      return "#33d9b2";
    }
    return "#ed213a";
  };

  render() {
    const { note, deleteNote, toggleEditNote } = this.props;
    return (
      <div id='NoteRow'>
        <div className='note-header'>
          <div className='note-info-wrapper'>
            <h4>
              {note.representative.first_name} {note.representative.last_name}
            </h4>
            <p className='representative-action-type'>
              {note.representative_action_type.name}
            </p>
            <p
              className='contact-made'
              style={{ backgroundColor: this.renderContactColor() }}
            >
              {this.renderContactMade()}
            </p>
          </div>
          <div className='note-date-container'>
            <p className='created-at'>{moment(note.created_at).fromNow()}</p>
            <div className='note-action-container'>
              <button
                className='note-edit'
                onClick={() => toggleEditNote(note)}
              >
                <i className='fa fa-pencil-square-o' />
              </button>
              <Toast
                type={"warn"}
                position={"top_center"}
                message={`Are you sure? This will delete the note by ${
                  note.representative.first_name
                } ${note.representative.last_name}`}
                autoClose={false}
                action={() => deleteNote(note.id)}
                actionText={"Yes, Delete Note"}
              >
                <button className='note-delete'>
                  <i className='fa fa-trash-o' />
                </button>
              </Toast>
            </div>
          </div>
        </div>

        <div className='note-container'>
          <p>
            <span>Note:</span> {note.note}
          </p>
        </div>
      </div>
    );
  }
}

export default NoteRow;
