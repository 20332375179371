import * as constants from "./actionTypes";
import Axios from '../axios'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { deserialize } from '../utility/serialization'
import { handleAPIError } from '../utility/actionHelper'

function successGrabbingLessonPlans(response) {
  if (response.status === 200) {
    return {
      type: constants.GRAB_LESSON_PLANS,
      lesson_plans: response.data.lesson_plans,
      community_lesson_plans: response.data.community_lesson_plans
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successGrabbingOneLessonPlan(response) {
  if (response.status === 200) {
    return {
      type: constants.GRAB_ONE_LESSON_PLAN,
      lesson_plan: response.data.lesson_plan
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successGrabbingOp36Templates(response) {
  if (response.status === 200) {
    return {
      type: constants.GRAB_LP_TEMPLATES,
      templates: response.data.templates
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successGrabbingPreviews(response) {
  if (response.status === 200) {
    return {
      type: constants.GRAB_LP_PREVIEWS,
      previews: response.data.lesson_plans
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successSavingNewDraft(response) {
  if (response.status === 201) {
    return {
      type: constants.CREATE_LESSON_PLAN_DRAFT,
      draft: response.data.draft
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successUpdatingDraft(response) {
  if (response.status === 204) {
    return {
      type: constants.UPDATE_LESSON_PLAN_DRAFT,
      draft: response.data.draft
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successHandlingPinClick(response) {
  if (response.status === 200) {
    return {
      type: constants.LESSON_PLAN_PIN,
      lesson_plan: response.data.lesson_plan
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successDeletingLessonPlan(response) {
  if (response.status === 200) {
    return {
      type: constants.DELETE_LESSON_PLAN,
      lesson_plan_id: response.data.id
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export const getLessonPlans = createAsyncThunk(
  'lessonPlan/getLessonPlans',
  async (userId, { rejectWithValue }) => {
    try {
      const { data } = await requestLessonPlans(userId)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export function grabLessonPlans(user_id) {
  return function(dispatch) {
    return requestLessonPlans(user_id).then(response =>
      dispatch(successGrabbingLessonPlans(response))
    );
  };
}

export function grabOneLessonPlan(id, tasks_needed) {
  return function(dispatch) {
    return requestOneLessonPlan(id, tasks_needed).then(response =>
      dispatch(successGrabbingOneLessonPlan(response))
    );
  };
}

export function grabOp36Templates() {
  return function(dispatch) {
    return requestOp36Templates().then(response =>
      dispatch(successGrabbingOp36Templates(response))
    );
  };
}

export function grabLessonPlanPreview(userType, userId) {
  return function(dispatch) {
    return requestLessonPlanPreviews(userType, userId).then(response =>
      dispatch(successGrabbingPreviews(response))
    );
  };
}

export function saveNewLessonPlanDraft(data) {
  return function(dispatch) {
    return requestSaveNewLessonPlanDraft(data).then(response =>
      dispatch(successSavingNewDraft(response))
    );
  };
}

export function updateDraftToDraft(id, data) {
  return function(dispatch) {
    return requestUpdateDraft(id, data).then(response =>
      dispatch(successUpdatingDraft(response))
    );
  };
}

export function handlePinClick(id) {
  return function(dispatch) {
    return requestHandlePinClick(id).then(response =>
      dispatch(successHandlingPinClick(response))
    );
  };
}

export function deleteLessonPlan(id) {
  return function(dispatch) {
    return requestDeleteLessonPlan(id).then(response =>
      dispatch(successDeletingLessonPlan(response))
    );
  };
}

function requestLessonPlans(user_id) {
  let requestUrl = "/api/v1/lesson_plans?user_id=" + user_id;
  return Axios.get(requestUrl);
}

function requestOneLessonPlan(id, tasks_needed) {
  let requestUrl = "/api/v1/lesson_plans/" + id;
  if (tasks_needed) {
    requestUrl += "?tasks=true";
  }
  return Axios.get(requestUrl);
}

function requestOp36Templates() {
  let requestUrl = "/api/v1/lesson_plans/op36_templates";
  return Axios.get(requestUrl);
}

function requestLessonPlanPreviews(userType, userId) {
  let requestUrl =
    "/api/v1/lesson_plans/previews?user_id=" + userId + "&type=" + userType;
  return Axios.get(requestUrl);
}

function requestSaveNewLessonPlanDraft(data) {
  let requestUrl = "/api/v1/lesson_plans/save_new_draft";
  const formData = new FormData();
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      formData.append(key, data[key]);
    }
  }
  return Axios.post(requestUrl, formData);
}

function requestUpdateDraft(id, data) {
  let requestUrl = "/api/v1/lesson_plans/" + id;
  const formData = new FormData();
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      formData.append(key, data[key]);
    }
  }
  return Axios.put(requestUrl, formData);
}

function requestHandlePinClick(id) {
  let requestUrl = "/api/v1/lesson_plans/" + id + "/pin";
  return Axios.put(requestUrl);
}

function requestDeleteLessonPlan(id) {
  let requestUrl = "/api/v1/lesson_plans/" + id;
  return Axios.delete(requestUrl);
}
