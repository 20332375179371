// ===================================================================
// these will be displayed on the admin community views checkboxes
// as they are checked, the 'value' string is saved to a community in the "map_filters" array
// NOTES!!!!!:
// 1) make sure all categories are grouped together on this list - otherwise the checkboxes will look weird
// 2) the id is only used for rendering (key={filter.id}), nothing else
// 3) if these change a ton, may want to clean up the "map_filters" array on a community
// ===================================================================
export const allFilters = [
  {
    id: 1,
    display: "Runs matches?",
    value: "matches",
    category: "Op 36"
  },
  {
    id: 2,
    display: "Uses curriculum?",
    value: "curriculum",
    category: "Op 36"
  },
  {
    id: 3,
    display: "Children (3-6 years old) programs?",
    value: "children",
    category: "Programs"
  },
  {
    id: 4,
    display: "Junior (7-18 years old) programs?",
    value: "junior",
    category: "Programs"
  },
  {
    id: 5,
    display: "Adult programs?",
    value: "adult",
    category: "Programs"
  },
  {
    id: 6,
    display: "Birdie Basics",
    value: "birdie_basics",
    category: "Partner"
  },
  {
    id: 7,
    display: "PGA Summer Camps",
    value: "pga_summer_camps",
    category: "Partner"
  },
  {
    id: 8,
    display: "Girls Golf",
    value: "girls_golf",
    category: "Partner"
  },
  {
    id: 9,
    display: "First Tee",
    value: "first_tee",
    category: "Partner"
  }
];
