import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setField } from '../../../../reducers/seriesReducer'

import Input from '../../../common/form/Input'
import Select from '../../../common/form/Select'
import { NOTIFICATION_OPTIONS_FIVE_DAYS, NOTIFICATION_OPTIONS_TEN_DAYS } from '../NotificationOptions.js'

import styles from './SeriesDetails.module.scss'

const DESCRIPTION_MAX_LENGTH = 400

const SeriesDetails = () => {
  const dispatch = useDispatch()

  const {
    errors,
    title,
    subtitle,
    reminderDescription,
    earlyReminderDays,
    lateReminderDays,
  } = useSelector(state => state.series)

  const [notificationOne, setNotificationOne] = useState(NOTIFICATION_OPTIONS_TEN_DAYS.find(p => p.value === earlyReminderDays))
  const [notificationTwo, setNotificationTwo] = useState(NOTIFICATION_OPTIONS_FIVE_DAYS.find(p => p.value === lateReminderDays))

  const handleInputChange = e => {
    const field = e.target.name
    const value = e.target.value

    if ((field === 'title' || field === 'subtitle') && value.length > 50) {
      return
    }

    dispatch(setField({ field, value }))
  }

  const handleSelectChange = (name, e) => {
    if (name === 'earlyReminderDays') {
      setNotificationOne(e)
    } else if (name === 'lateReminderDays') {
      setNotificationTwo(e)
    }

    dispatch(setField({ field: name, value: e.value }))
  }

  return (
    <section className={styles.seriesDetails}>
      <div className='row'>
        <div className='col-8 mx-auto mt-5'>
          <p className={styles.title}>9 Hole Event Series</p>
          <p>Please fill out the information below</p>
          <div className='row mb-3'>
            <div className='col-12 col-md-6 pr-5'>
              <div className='d-flex justify-content-between align-items-start'>
                <label htmlFor='title' className={styles.label}>
                  Series Title <span className='required-text'>*</span>
                </label>
              </div>

              <Input
                name='title'
                type='text'
                onChange={handleInputChange}
                placeholder='Example: Fall Junior Series'
                value={title}
                error={errors.title}
              />
              <p className={styles.characterCounter}>
                {`${title.length}/50 characters`}
              </p>
            </div>
            <div className='col-12 col-md-6 pr-5'>
              <div className='d-flex justify-content-between align-items-start'>
                <label htmlFor='subtitle' className={styles.label}>
                  Subtitle
                </label>
              </div>

              <Input
                name='subtitle'
                type='text'
                onChange={handleInputChange}
                placeholder='Example: Spring 9 Hole Event Series'
                value={subtitle}
                error={errors.subtitle}
              />
              <p className={styles.characterCounter}>
                {`${subtitle.length}/50 characters`}
              </p>
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-12'>
              <p className={styles.label}>Communication Settings</p>
              <p>
                For each event, students inside the roster will be sent an email/notification to confirm
                their attendance in the Operation 36 Mobile App.
                There will be maximum two notifications sent.  Please designate the times in which you want these to be sent for each event.
              </p>
            </div>
          </div>
          <div className='row mb-4'>
            <div className='col-12 col-md-6'>
              <label htmlFor='subtitle' className={styles.label}>
                Notification 1
              </label>
              <Select
                name='notification-select'
                value={notificationOne}
                options={NOTIFICATION_OPTIONS_TEN_DAYS}
                isSearchable={false}
                onChange={(e) => handleSelectChange('earlyReminderDays', e)}
                error={errors.notificationOne}
              />
            </div>
            <div className='col-12 col-md-6'>
              <label htmlFor='subtitle' className={styles.label}>
                Notification 2
              </label>
              <Select
                name='notification-select'
                value={notificationTwo}
                options={NOTIFICATION_OPTIONS_FIVE_DAYS}
                isSearchable={false}
                onChange={(e) => handleSelectChange('lateReminderDays', e)}
                error={errors.notificationTwo}
              />
            </div>
          </div>
          <div className='row mb-4'>
            <div className='col-12'>
              <label htmlFor='description' className={styles.label}>
                Notification Description
              </label>

              <Input
                name='reminderDescription'
                type='textarea'
                onChange={handleInputChange}
                placeholder='Write a description'
                value={reminderDescription}
                error={errors.reminderDescription}
                maxLength={DESCRIPTION_MAX_LENGTH}
                styles={{ inputContainer: { height: '220px' } }}
              />
              <p className={styles.characterCounter}>
                {`${reminderDescription?.length}/${DESCRIPTION_MAX_LENGTH} characters`}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SeriesDetails
