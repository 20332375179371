import React, { Component } from "react";
import DatePicker from '../common/DatePicker'
import moment from "moment";
import ReactTable from "react-table";
import createTable from "react-table-hoc-fixed-columns";
// import "react-table/react-table.css";

import CSVExportModal from "./CSVExportModal";
import profile_avatar from "../../assets/images/mock/StockProfilePic.png";

const ReactTableFixedColumns = createTable(ReactTable);

const HEADERS = [
  { label: "First Name", key: "firstname" },
  { label: "Last Name", key: "lastname" },
  { label: "Date", key: "date" },
  { label: "Duration", key: "duration" },
  { label: "Putting", key: "putting" },
  { label: "Chipping", key: "chipping" },
  { label: "Pitch Shots (10-30 yards)", key: "pitchshots" },
  { label: "Wedges (30+ yards)", key: "wedges" },
  { label: "Full Swing Irons", key: "irons" },
  { label: "Full Swing Woods", key: "woods" },
  { label: "On-Course Play Simulation", key: "oncourse" },
  { label: "Bunkers", key: "bunkers" }
];

class CommunityPracticeTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      dataLoader: false,
      data: []
    };
  }
  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    })
  };
  createData = () => {
    const { data } = this.props;
    let practices = data[0].practices;
    let tempData = [];
    this.setState({ dataLoader: true });
    if (practices.length > 0) {
      practices.map(practice => {
        let firstname = practice.user.first_name;
        let lastname = practice.user.last_name;
        let date = practice.activity_date;
        let sessions = this.combineSessions(practice);

        tempData.push({
          firstname,
          lastname,
          date: moment(date).format("MM-DD-YYYY"),
          duration: this.calculateDuration(sessions),
          putting: this.calculateSessionDuration(sessions, 1),
          chipping: this.calculateSessionDuration(sessions, 2),
          pitchshots: this.calculateSessionDuration(sessions, 3),
          wedges: this.calculateSessionDuration(sessions, 4),
          irons: this.calculateSessionDuration(sessions, 5),
          woods: this.calculateSessionDuration(sessions, 6),
          oncourse: this.calculateSessionDuration(sessions, 7),
          bunkers: this.calculateSessionDuration(sessions, 8)
        });
      });
    }

    this.setState({ data: tempData }, () => this.toggleModal())
  };
  renderUserAvatarCell = props => {
    return (
      <div
        className='number'
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer"
        }}
        key={props.value ? props.value.id : null}
        onClick={() =>
          this.props.toProfile(props.value ? props.value.id : null)
        }
      >
        <div className='small-round-image-wrapper'>
          {props.value ? (
            <img
              className='round-image'
              src={
                props.value.avatar
                  ? props.value.avatar.file_url
                  : profile_avatar
              }
              alt={
                props.value
                  ? `${props.value.first_name} ${props.value.last_name}`
                  : ""
              }
            />
          ) : null}
        </div>
      </div>
    );
  };
  renderDataSessions = practice_sessions =>
    practice_sessions.reduce(
      (acc, curr) => `${acc} ${curr.practice_type.name},`,
      ""
    );
  renderSessions = ({ value: { practice_sessions } }) =>
    practice_sessions.reduce(
      (acc, curr) => `${acc} ${curr.practice_type.name},`,
      ""
    );
  renderPracticeSessionCell = props => {
    return (
      <div
        className='number'
        style={{
          backgroundColor: "",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div style={{ color: "#2b3843" }}>{this.renderSessions(props)}</div>
      </div>
    );
  };

  calculateDuration = props => {
    let duration = 0;

    props.practice_sessions.map(session => {
      duration += session.duration;
    });

    return duration;
  };

  calculateSessionDuration = (props, id) => {
    let duration = 0;

    props.practice_sessions.map(session => {
      if (id === session.practice_type.id) duration += session.duration;
    });

    return duration;
  };

  combineSessions = (activity) => {
    let sessions = activity.practice_sessions ? activity.practice_sessions : []
    activity.logged_drills.forEach(ld => {
      sessions = [...sessions, ...ld.practice_sessions]
    })
    return { ...activity, practice_sessions: sessions }
  }

  renderTotalDurationCell = props => {
    return (
      <div
        className='number'
        style={{
          backgroundColor: "#2b3843",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div style={{ color: "white" }}>
          {props.value}
        </div>
      </div>
    );
  };

  renderPracticeTypeDurationCell = props => {
    return (
      <div
        className='number'
        style={{
          backgroundColor: "",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div style={{ color: "#2b3843" }}>
          {props.value}
        </div>
      </div>
    );
  };

  renderActivityDateCell = props => {
    return (
      <div
        className='number'
        style={{
          backgroundColor: "",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div style={{ color: "#2b3843" }}>
          {moment(props.value).format("MM-DD-YYYY")}
        </div>
      </div>
    );
  };

  renderTable = () => {
    const {
      title,
      startDate,
      endDate,
      handleStartDateChanged,
      handleEndDateChanged
    } = this.props;
    const columns = [
      {
        Header: "",
        accessor: "user",
        Cell: props => this.renderUserAvatarCell(props),
        sortable: false,
        fixed: true
      },
      {
        Header: "First Name",
        accessor: "user.first_name",
        fixed: true
      },
      {
        Header: "Last Name",
        accessor: "user.last_name",
        fixed: true
      },
      {
        Header: "Date",
        accessor: "activity_date",
        Cell: props => this.renderActivityDateCell(props)
      },
      {
        Header: "Total Duration",
        id: 'total_duration',
        accessor: this.calculateDuration,
        Cell: this.renderTotalDurationCell,
        style: { padding: 0 }
      },
      {
        Header: "Putting",
        id: 'putting',
        accessor: props => this.calculateSessionDuration(props, 1),
        Cell: this.renderPracticeTypeDurationCell
      },
      {
        Header: "Chipping",
        id: 'chipping',
        accessor: props => this.calculateSessionDuration(props, 2),
        Cell: this.renderPracticeTypeDurationCell
      },
      {
        Header: "Pitch Shots (10-30 yards)",
        id: 'pitch_shots',
        accessor: props => this.calculateSessionDuration(props, 3),
        Cell: this.renderPracticeTypeDurationCell
      },
      {
        Header: "Wedges (30+ yards)",
        id: 'wedges',
        accessor: props => this.calculateSessionDuration(props, 4),
        Cell: this.renderPracticeTypeDurationCell
      },
      {
        Header: "Full Swing Irons",
        id: 'full_swing_irons',
        accessor: props => this.calculateSessionDuration(props, 5),
        Cell: this.renderPracticeTypeDurationCell
      },
      {
        Header: "Full Swing Woods",
        id: 'full_swing_woods',
        accessor: props => this.calculateSessionDuration(props, 6),
        Cell: this.renderPracticeTypeDurationCell
      },
      {
        Header: "On-Course Play Simulation",
        id: 'play_simulation',
        accessor: props => this.calculateSessionDuration(props, 7),
        Cell: this.renderPracticeTypeDurationCell
      },
      {
        Header: "Bunkers",
        id: 'bunkers',
        accessor: props => this.calculateSessionDuration(props, 8),
        Cell: this.renderPracticeTypeDurationCell
      }
    ];

    return (
      <div className='container my-5 animated fadeIn'>
        <div className='row mb-5 d-flex align-items-center'>
          <div className='col-md-4'>
            <h2>{this.props.title}</h2>
          </div>
          <div className='col-md-4 d-flex align-items-center'>
            <div>
              <p className='m-0'>Start Date</p>
              <DatePicker
                name={"start-date"}
                id={"start-date"}
                startDate={startDate}
                endDate={endDate}
                type={"date"}
                className={"col-12 form-control"}
                onChange={handleStartDateChanged}
                placeholder={"Start Date"}
                selected={startDate}
                error={""}
              />
            </div>

            <div className='ml-2'>
              <p className='m-0'>End Date</p>
              <DatePicker
                name={"end-date"}
                id={"end-date"}
                startDate={startDate}
                endDate={endDate}
                type={"date"}
                className={"col-12 form-control"}
                onChange={handleEndDateChanged}
                placeholder={"End Date"}
                selected={endDate}
                error={""}
              />
            </div>
          </div>
          <div className='col-md-4'>
            <button
              className='btn btn-sm btn-block'
              onClick={() => this.props.goBack()}
            >
              <i
                className='fa fa-arrow-left text-white mr-1'
                aria-hidden='true'
              />{" "}
              View Another Report
            </button>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <ReactTableFixedColumns
              data={
                this.props.data[0] && !this.props.loader
                  ? this.props.data[0].practices
                  : []
              }
              columns={columns}
              defaultPageSize={10}
              loading={this.props.loader}
              loadingText='Loading Report'
              noDataText={
                this.props.data[0]
                  ? "Choose a start date and end date with activities"
                  : "No data found"
              }
            />
          </div>
        </div>
        <button onClick={this.createData} className='mt-3 btn btn-success'>
          Export as CSV
        </button>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderTable()}
        <CSVExportModal
          headers={HEADERS}
          data={this.state.data}
          showModal={this.state.showModal}
          toggleModal={this.toggleModal}
          reportType={"Community Training Report"}
          filename={"community-training-report.csv"}
        />
      </div>
    );
  }
}

export default CommunityPracticeTable;
