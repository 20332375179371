import React, { Component } from "react";
import _ from "lodash";
import moment from "moment";
import DatePicker from '../../common/DatePicker'

//REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as commonActions from "../../../actions/commonActions";
import * as reportsActions from "../../../actions/reportsActions";
import * as communitiesActions from "../../../actions/communitiesActions";
import * as communityStagesActions from "../../../actions/communityStagesActions";

// CHILDREN
import CommunityStageForm from "../Stages/CommunityStageForm";
import TextInput from "../../common/form/TextInput";

// ASSETS
import "./CommunityDetailCard.scss";

class CommunityDetailCard extends Component {
  state = {
    loader: false,
    stageLoader: false,
    showCreateStageForm: false,
    community_stage_id:
      this.props.community && this.props.community.community_stage_id
        ? this.props.community.community_stage_id
        : "",
    goals_note:
      this.props.community && this.props.community.goals_note
        ? this.props.community.goals_note
        : "",
    additional_note:
      this.props.community && this.props.community.additional_note
        ? this.props.community.additional_note
        : "",
    additionalTextActive: false,
    goalsTextActive: false,
    completed_onboarding:
      this.props.community &&
      this.props.community.completed_onboarding &&
      this.props.community.completed_onboarding.length > 0
        ? this.props.community.completed_onboarding
        : [],
    season_start_date:
      this.props.community && this.props.community.season_start_date
        ? moment(this.props.community.season_start_date)
        : moment().subtract(1, "weeks").startOf("isoWeek"),
    season_end_date:
      this.props.community && this.props.community.season_end_date
        ? moment(this.props.community.season_end_date)
        : moment().add(1, "days"),
    onboardingLoader: false,
    mailing_address:
      this.props.community && this.props.community.mailing_address
        ? this.props.community.mailing_address
        : "",
    onboarding_checklist_url:
      this.props.community && this.props.community.onboarding_checklist_url
        ? this.props.community.onboarding_checklist_url
        : "",
    resource_folder_url:
      this.props.community && this.props.community.resource_folder_url
        ? this.props.community.resource_folder_url
        : "",
    show_onboarding_banner:
      this.props.community && this.props.community.show_onboarding_banner,
  };

  componentDidMount() {
    const { community_stages } = this.props;
    if (
      !community_stages ||
      (community_stages && community_stages.length === 0)
    )
      this._fetchCommunityStages();
  }

  render() {
    const { community } = this.props;
    const {
      showCreateStageForm,
      community_stage_id,
      goals_note,
      additional_note,
      loader,
      goalsTextActive,
      additionalTextActive,
      onboardingLoader,
      season_start_date,
      season_end_date,
      mailing_address,
      onboarding_checklist_url,
      resource_folder_url,
      show_onboarding_banner,
    } = this.state;
    return (
      <div id='CommunityDetailCard'>
        {showCreateStageForm ? (
          <CommunityStageForm
            community={community}
            toggleStageForm={this._toggleStageForm}
          />
        ) : (
          <div>
            <label>
              Onboarding{" "}
              {onboardingLoader && (
                <i className='fa fa-spinner fa-spin animated fadeIn' />
              )}
            </label>
            <br />
            <div className='progress'>
              <div
                className='progress-bar'
                role='progressbar'
                style={{ width: `${this._calculateProgressPercentage()}%` }}
                aria-valuenow='25'
                aria-valuemin='0'
                aria-valuemax='100'
              />
            </div>
            {/* <hr /> */}
            {this._renderOnboardingSteps()}
            <label>Stage</label>
            <select
              className='form-control'
              id='community_stage_id'
              name='community_stage_id'
              onChange={this._handleInputChange}
              value={community_stage_id}
              disabled={loader}
            >
              <option value={""} disabled>
                Choose A Community Stage
              </option>
              {this._renderStages()}
            </select>
            {/* <div className='create-stage-wrapper'>
              <button className='btn btn-link' onClick={this._toggleStageForm}>
                <i className='fa fa-cog' /> Manage Community Stages
              </button>
            </div> */}
            <label>Onboarding Checklist URL</label>
            <TextInput
              name={"onboarding_checklist_url"}
              type={"text"}
              label={""}
              onChange={this._handleInputChange}
              placeholder={"Add URL here..."}
              value={onboarding_checklist_url}
            />
            <label>Resource Folder URL</label>
            <TextInput
              name={"resource_folder_url"}
              type={"text"}
              label={""}
              onChange={this._handleInputChange}
              placeholder={"Add URL here..."}
              value={resource_folder_url}
            />
            <div className='mt-3'>
              <input
                type='checkbox'
                name={"show_onboarding_banner"}
                checked={show_onboarding_banner}
                onChange={this._handleBannerChange}
              />{" "}
              Show links to Onboarding Checklist and Resource Folder on coach
              dash
            </div>
            <hr />
            <label>Mailing Address</label>
            <TextInput
              name={"mailing_address"}
              type={"text"}
              label={""}
              onChange={this._handleInputChange}
              placeholder={"Mailing Address"}
              value={mailing_address}
            />

            <label>Season</label>
            <div className='row season-date-wrapper'>
              <div className='col-6'>
                <p className='m-0'>Start Date</p>
                <DatePicker
                  name={"season_start-date"}
                  id={"season_start-date"}
                  startDate={season_start_date}
                  endDate={season_end_date}
                  type={"date"}
                  className={"col-12 form-control date-picker"}
                  onChange={this._handleStartDateChanged}
                  placeholder={"Start Date"}
                  selected={season_start_date}
                  error={""}
                />
              </div>

              <div className='col-6'>
                <p className='m-0'>End Date</p>
                <DatePicker
                  name={"season_end-date"}
                  id={"season_end-date"}
                  startDate={season_start_date}
                  endDate={season_end_date}
                  type={"date"}
                  className={"col-12 form-control date-picker"}
                  onChange={this._handleEndDateChanged}
                  placeholder={"End Date"}
                  selected={season_end_date}
                  error={""}
                />
              </div>
            </div>

            {/* <label>Flags</label>
            <div className='flag-overview-wrapper'>
              {this._renderFlagOverview()}
            </div> */}

            <div className='d-flex flex-row justify-content-between'>
              <label>
                Black Swan (main pain we are trying to solve for them)
              </label>
              {/* Goals Note */}
              <button
                className='btn btn-link'
                onClick={() => this._toggleEdit("goalsTextActive")}
              >
                Edit
              </button>
            </div>
            <textarea
              name='goals_note'
              type='text'
              className='form-control'
              rows='3'
              label=''
              onChange={this._handleInputChange}
              placeholder='Enter some of the goals the community has using our platform'
              value={goals_note}
              disabled={loader || !goalsTextActive}
            />
            <div className='d-flex flex-row justify-content-between'>
              <label>Goals and Additional Notes</label>
              {/* Additional Note */}
              <button
                className='btn btn-link'
                onClick={() => this._toggleEdit("additionalTextActive")}
              >
                Edit
              </button>
            </div>
            <textarea
              name='additional_note'
              type='text'
              className='form-control'
              rows='3'
              label=''
              onChange={this._handleInputChange}
              placeholder='Enter some additonal notes if applicable'
              value={additional_note}
              disabled={loader || !additionalTextActive}
            />
            <div className='community-save-btn-wrapper'>
              <button
                className='btn btn-primary btn-small'
                onClick={this._updateCommunity}
              >
                {loader && (
                  <i className='fa fa-spinner fa-spin light-loading-spinner' />
                )}
                Sav{loader ? "ing" : "e"} Changes
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }

  _handleBannerChange = () => {
    this.setState((prevState) => {
      return { show_onboarding_banner: !prevState.show_onboarding_banner };
    });
  };

  _calculateProgressPercentage = () => {
    const {
      selected_community: { completed_onboarding, onboarding_steps },
    } = this.props;

    if (
      completed_onboarding &&
      onboarding_steps &&
      onboarding_steps.length
    ) {
      return (completed_onboarding.length / onboarding_steps.length) * 100;
    }
    return null;
  };

  _updateCommunity = () => {
    const { reports_actions, communities_actions, community } = this.props;
    const {
      goals_note,
      additional_note,
      community_stage_id,
      season_start_date,
      season_end_date,
      mailing_address,
      onboarding_checklist_url,
      resource_folder_url,
      show_onboarding_banner,
    } = this.state;

    let newCommunity = {
      id: community.id,
      goals_note,
      additional_note,
      community_stage_id,
      season_start_date,
      season_end_date,
      mailing_address,
      onboarding_checklist_url,
      resource_folder_url,
      show_onboarding_banner,
    };

    this.setState(
      {
        loader: true,
      },
      () => {
        communities_actions.updateCommunity(newCommunity).then(() => {
          reports_actions
            .getCommunityEngagementReport(community.id)
            .then(() => {
              this.setState({
                loader: false,
                goalsTextActive: false,
                additionalTextActive: false,
              });
            });
        });
      }
    );
  };

  // _renderFlagOverview = () => {
  //   const { flags } = this.props;
  //   const resolvedCount = flags.filter(flag => flag.is_resolved).length;
  //   const unresolvedCount = flags.filter(flag => !flag.is_resolved).length;

  //   if (flags && flags.length > 0) {
  //     return (
  //       <div className='flag-text-wrapper'>
  //         <p className='resolved-flags'>{resolvedCount} resolved flags</p>
  //         <p className='unresolved-flags'>{unresolvedCount} unresolved flags</p>
  //       </div>
  //     );
  //   }
  //   return <p className='no-flags'>0 flags created</p>;
  // };

  _renderOnboardingSteps = () => {
    const { stageLoader } = this.state;
    if (stageLoader) {
      return (
        <div className='d-flex align-items-center justify-content-center my-5'>
          <i className='fa fa-spinner fa-spin' aria-hidden='true' />
        </div>
      );
    }
    const { community, community_stages } = this.props;
    const { completed_onboarding, onboardingLoader } = this.state;
    let stepOrders = [];

    completed_onboarding.forEach((step) => {
      let key = _.keys(step);
      if (key) stepOrders.push(parseInt(key[0], 10));
    });

    if (community.onboarding_steps && community.onboarding_steps.length > 0) {
      return community_stages
        .filter((stage) => stage.name.includes("Onboarding"))
        .map((stage) => {
          return (
            <div key={stage.id}>
              <hr />
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "1.2rem",
                  color: "black",
                }}
              >
                {stage.name}
              </p>
              {community.onboarding_steps
                .filter((step) => step.community_stage_id === stage.id)
                .map((step) => {
                  let index = stepOrders.indexOf(step.id);

                  return (
                    <div key={step.id}>
                      <input
                        type='checkbox'
                        name={step.name}
                        value={step.id}
                        onChange={this._handleOnboardingChange}
                        defaultChecked={index > -1}
                        disabled={onboardingLoader}
                      />{" "}
                      {step.name}
                    </div>
                  );
                })}
            </div>
          );
        });
    }
  };

  _renderStages = () => {
    const { community_stages } = this.props;
    const { stageLoader } = this.state;

    if (stageLoader) {
      return (
        <option value={"loading"} disabled>
          Loading
        </option>
      );
    }

    if (community_stages && community_stages.length > 0) {
      return community_stages.map((stage) => (
        <option value={stage.id} key={stage.id}>
          {stage.name}
        </option>
      ));
    } else {
      return (
        <option value={"null"} disabled>
          No Stages Listed - Create One First
        </option>
      );
    }
  };

  _handleStartDateChanged = (date) => {
    this.setState({ season_start_date: date });
  };

  _handleEndDateChanged = (date) => {
    this.setState({ season_end_date: date });
  };

  _handleOnboardingChange = (event) => {
    const { communities_actions, community } = this.props;
    const isChecked = event.target.checked;
    const completed_onboarding = [...this.state.completed_onboarding];
    const parsedValue = parseInt(event.target.value, 10);
    const index = completed_onboarding
      .map((step) => {
        let key = _.keys(step);
        return parseInt(key[0], 10);
      })
      .indexOf(parsedValue);

    if (isChecked) {
      this.setState(
        {
          onboardingLoader: true,
        },
        () => {
          completed_onboarding.push(parsedValue);
          communities_actions
            .completeOnboardingStep(community.id, parsedValue)
            .then(() => {
              this.setState({
                onboardingLoader: false
              })
            });
        }
      );
    } else if (!isChecked) {
      this.setState(
        {
          onboardingLoader: true,
        },
        () => {
          completed_onboarding.splice(index, 1);
          communities_actions
            .incompleteOnboardingStep(community.id, parsedValue)
            .then(() => {
              this.setState({
                onboardingLoader: false
              })
            });
        }
      );
    }

    this.setState({
      completed_onboarding,
    });
  };

  _handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  _toggleEdit = (type) => {
    this.setState({
      [type]: !this.state[type],
    });
  };

  _toggleStageForm = () => {
    this.setState({
      showCreateStageForm: !this.state.showCreateStageForm,
    });
  };

  _fetchCommunityStages = () => {
    const { community_stages_actions } = this.props;
    this.setState(
      {
        stageLoader: true,
      },
      () => {
        community_stages_actions.getCommunityStages().then(() => {
          this.setState({ stageLoader: false });
        });
      }
    );
  };

  _fetchCommunityReport = () => {
    const { community, reports_actions } = this.props;

    reports_actions.getCommunityEngagementReport(community.id).then(() => {
      this.setState({
        onboardingLoader: false,
      });
    });
  };
}

function mapStateToProps(state, ownProps) {
  return {
    ui: state.ui,
    community_stages: state.communityStages,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    common_actions: bindActionCreators(commonActions, dispatch),
    reports_actions: bindActionCreators(reportsActions, dispatch),
    communities_actions: bindActionCreators(communitiesActions, dispatch),
    community_stages_actions: bindActionCreators(
      communityStagesActions,
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunityDetailCard);
