import React from "react";
import moment from "moment";
import PropTypes from 'prop-types'

// REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as commonActions from "../../actions/commonActions";
import * as coachActions from "../../actions/coachActions";
import * as userActions from "../../actions/userActions";
import * as communitiesActions from "../../actions/communitiesActions";
import * as accoladeActions from "../../actions/accoladeActions";
import * as adminTagActions from "../../actions/adminTagActions";

// CHILDREN
import Select from '../common/Select'
import TextInput from "../common/form/TextInput";
import Loader from "../common/Op36Loader-web";
import FieldError from '../common/FieldError'

// ASSETS
import "./NewCoachForm.scss";

// Accolades
import { stockAccolades } from "../../accolades/accolades";
import BaseModal from "../common/BaseModal";
import AdminTagIndex from "../common/AdminTagIndex";
import DatePicker from "../common/DatePicker";

class NewCoachForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    let communityId = -1;
    if (this.props.coach) {
      communityId = this.props.coach.communities[0].id;
    }

    this.state = {
      first_name: this.props.coach ? this.props.coach.first_name : "",
      last_name: this.props.coach ? this.props.coach.last_name : "",
      email: this.props.coach ? this.props.coach.email : "",
      gender: this.props.coach ? this.props.coach.gender : -1,
      password: "",
      confirm_password: "",
      community_id: communityId,
      type: "Coach",
      communities: [],
      loader: false,
      accoladesShow: false,
      accoladeLoader: true,
      removeAccoladeConfirmationModal: false,
      accoladeConfirmationModal: false,
      selectedAccolade: {},
      removeButtonClicked: false,
      addButtonClicked: false,
      backdate: moment(),
      permission: this._grabCoachPermission(this.props.coach), // the permission which you want to set your coach
      adminTagLoader: true,
      tagManagerModal: false,
      birthdate: this.props.coach ? this.props.coach.birthdate : null,
    };
  }

  componentDidMount() {
    if (this.props.coach) {
      Promise.all([
        this.props.accolade_actions.getCoachAccolades(this.props.coach.id),
        this.props.accolade_actions.getAllAccolades(),
      ]).then(() => this.setState({ accoladeLoader: false }));
      Promise.all([
        this.props.admin_tag_actions.getAllTags(),
        this.props.admin_tag_actions.getUserAdminTags(this.props.coach.id),
      ]).then(() => {
        this.setState({ adminTagLoader: false });
      });
    }
  }

  _grabCoachPermission = (coach) => {
    if (!coach) {
      return -1;
    }
    if (coach.permission) return coach.permission;
    if (coach.user_settings) return coach.user_settings.coach_permission;
    return -1;
  };

  fetchCommunities = (text) => {
    const { communities_actions, communities } = this.props;

    if (text && text.length >= 4) {
      this.setState({ loader: true }, () => {
        communities_actions.searchCommunities(text).then(() => {
          let refactorCommunities = communities.map((community) => {
            return { value: community.id, label: community.name };
          });

          this.setState({
            communities: refactorCommunities,
            loader: false,
          });
        });
      });
    }
  };

  resetForm = () => {
    this.setState({
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      confirm_password: "",
      community_id: -1,
      permission: -1,
      gender: -1,
    });
  };

  onSelectOption = (communitySelected) => {
    this.setState({
      community_id: communitySelected.value,
    });
  };

  handlePermissionChange = (e) => {
    this.setState({ permission: e.value });
  };

  handleGenderChange = (e) => {
    this.setState({ gender: e.value });
  };

  handleSubmitCoach = (event) => {
    event.preventDefault();
    if (
      this.state.first_name &&
      this.state.last_name &&
      this.state.email &&
      // this.state.password &&
      // this.state.confirm_password &&
      this.state.community_id !== "-1" &&
      this.state.gender > 0 &&
      !!this.state.birthdate
    ) {
      if (this.state.password === this.state.confirm_password) {
        if (this.props.coach) {
          let coach = { ...this.state };
          coach.id = this.props.coach.id;
          if (this.state.password) {
            coach.current_password = this.props.coach.password;
          } else {
            delete coach.password;
          }
          this.props.handleEditCoach(coach).then((success) => {
            if (success) {
              this.resetForm()
            }
          })
        } else {
          if (this.state.password) {
            this.props.handleSubmitCoach(this.state).then((success) => {
              if (success) {
                this.resetForm()
              }
            })
          }
        }
      }
    }
  };

  validateCoachForm = () => {
    const {
      first_name,
      last_name,
      email,
      password,
      confirm_password,
      community_id,
      permission,
      gender,
      birthdate,
    } = this.state;
    if (
      first_name !== "" &&
      last_name !== "" &&
      email !== "" &&
      password === confirm_password &&
      parseInt(community_id, 10) > -1 &&
      permission > -1 &&
      gender > 0 &&
      birthdate
    ) {
      return false;
    }
    return true;
  };

  displayCommunities = () => {
    const { communities, communityLoader, coach } = this.props;
    if (communities && communities.length > 0 && !communityLoader) {
      return communities.map((community, index) => (
        <option
          disabled={coach}
          value={community.id}
          key={"community-option-" + community.id}
        >
          {community.name}
        </option>
      ));
    } else if (communityLoader) {
      return (
        <option disabled={true} value={-1}>
          Loading
        </option>
      );
    }
  };

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() { // eslint-disable-line max-lines-per-function, complexity
    const { closeNewCoachModal, coach } = this.props;
    const {
      loader,
      community_id,
      communities,
      accoladesShow,
      accoladeConfirmationModal,
      removeAccoladeConfirmationModal,
      selectedAccolade,
      removeButtonClicked,
      addButtonClicked,
      tagManagerModal,
      gender,
    } = this.state;

    let { birthdate } = this.state;
    if (birthdate) {
      birthdate = moment(birthdate);
    }

    return (
      <form
        id='NewCoachForm'
        className='form-container'
        onSubmit={this.handleSubmitCoach}
      >
        {(accoladeConfirmationModal || removeAccoladeConfirmationModal) && (
          <div className='grey-out' onClick={this._closeConfirmationModals} />
        )}
        {removeAccoladeConfirmationModal && (
          <div className='accolade-confirmation-modal'>
            <p>{`Are sure you want to remove this accolade (${selectedAccolade.name}) from ${this.props.coach.first_name}?`}</p>
            <div className='button-box'>
              <button
                className='nevermind'
                onClick={this._closeConfirmationModals}
              >
                Nevermind
              </button>
              <button
                className='remove-button'
                disabled={removeButtonClicked}
                onClick={this._handleRemoveAccolade}
              >
                {removeButtonClicked ? (
                  <i className='fa fa-spinner fa-spin' aria-hidden='true' />
                ) : (
                  "Remove"
                )}
              </button>
            </div>
          </div>
        )}
        {accoladeConfirmationModal && (
          <div className='accolade-confirmation-modal'>
            <p>{`Are you sure you wish to bequeath this honor (${selectedAccolade.name}) unto ${this.props.coach.first_name}?`}</p>
            <div className='datepicker-container'>
              <p className='add-date-prompt'>
                change this date if you want to backdate this accolade
              </p>
              <DatePicker
                selected={this.state.backdate}
                onChange={this._handleBackdateChange}
              />
            </div>
            <div className='button-box'>
              <button
                className='nevermind'
                onClick={this._closeConfirmationModals}
              >
                Nevermind
              </button>
              <button
                className='add-accolade-button'
                onClick={this._handleAddAccolade}
                disabled={addButtonClicked}
              >
                {addButtonClicked ? (
                  <i className='fa fa-spinner fa-spin' aria-hidden='true' />
                ) : (
                  "Add"
                )}
              </button>
            </div>
          </div>
        )}
        <h4 className='mb-4'>
          {coach
            ? `Edit Coach ${coach.first_name} ${coach.last_name}`
            : "Create A New Coach"}
        </h4>
        {this.props.errors?.general && <FieldError message={this.props.errors.general} general largerMargins />}
        <div className='row'>
          <div className='col-md-6'>
            <div className='form-group'>
              <label htmlFor='first_name'>
                First Name <span className='required-text'>*</span>
              </label>
              <TextInput
                name={"first_name"}
                type={"text"}
                label={""}
                onChange={this.handleInputChange}
                placeholder={"First Name"}
                value={this.state.first_name}
              />
              {this.props.errors?.firstName && <FieldError message={this.props.errors.firstName}/>}
            </div>
          </div>
          <div className='col-md-6'>
            <div className='form-group'>
              <label htmlFor='last_name'>
                Last Name <span className='required-text'>*</span>
              </label>
              <TextInput
                name={"last_name"}
                type={"text"}
                label={""}
                onChange={this.handleInputChange}
                placeholder={"Last Name"}
                value={this.state.last_name}
              />
              {this.props.errors?.lastName && <FieldError message={this.props.errors.lastName}/>}
            </div>
          </div>
        </div>

        <div className='form-group'>
          <label htmlFor='email'>
            Email <span className='required-text'>*</span>
          </label>
          <TextInput
            name={"email"}
            type={"email"}
            label={""}
            onChange={this.handleInputChange}
            placeholder={"Email"}
            value={this.state.email}
            autoComplete='email'
          />
          {this.props.errors?.email && <FieldError message={this.props.errors.email}/>}
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <div className='form-group'>
              <label htmlFor='coach-gender'>
                Gender <span className='required-text'>*</span>
              </label>
              <Select
                multi={false}
                name='form-field-gender'
                value={gender}
                options={[
                  { value: 1, label: "Male" },
                  { value: 2, label: "Female" },
                ]}
                placeholder='Gender'
                onChange={this.handleGenderChange}
                className='w-100 community-select'
                styles={{
                  input: (base) => ({
                    ...base,
                    border: 'none !important'
                  })
                }}
                // onInputChange={event => this.fetchCommunities(event)}
              />
              {this.props.errors?.gender && <FieldError message={this.props.errors.gender}/>}
            </div>
          </div>
          <div className='col-md-6'>
            <label>
              Birthdate <span className='required-text'>*</span>
            </label>
            <DatePicker
              selected={birthdate}
              onChange={this._handleBirthdateChange}
            />
            {this.props.errors?.birthdate && <FieldError message={this.props.errors.birthdate}/>}
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <div className='form-group'>
              <label htmlFor='password'>
                Password <span className='required-text'>*</span>
              </label>
              <TextInput
                name={"password"}
                type={"password"}
                label={""}
                onChange={this.handleInputChange}
                placeholder={"Password"}
                value={this.state.password}
                autoComplete={coach ? 'current-password' : 'new-password'}
              />
              {this.props.errors?.password && <FieldError message={this.props.errors.password}/>}
            </div>
          </div>
          <div className='col-md-6'>
            <div className='form-group'>
              <label htmlFor='confirm_password'>
                Confirm Password <span className='required-text'>*</span>
              </label>
              <TextInput
                name={"confirm_password"}
                type={"password"}
                label={""}
                onChange={this.handleInputChange}
                placeholder={"Confirm Password"}
                value={this.state.confirm_password}
                autoComplete={coach ? 'current-password' : 'new-password'}
              />
              {this.props.errors?.confirmPassword && <FieldError message={this.props.errors.confirmPassword}/>}
            </div>
          </div>
        </div>
        <div className='form-group'>
          <label htmlFor='coach-permission'>
            What type of coach is this? <span className='required-text'>*</span>
          </label>
          <Select
            multi={false}
            name='form-field-permission'
            value={this.state.permission}
            options={[
              { value: 0, label: "Education Only", isDisabled: true },
              { value: 1, label: "Dev Camp" },
              { value: 2, label: "Academy" },
              // { value: 3, label: "Business?" },
            ]}
            placeholder='Set Coach Permission'
            onChange={this.handlePermissionChange}
            className='w-100 community-select'
            // onInputChange={event => this.fetchCommunities(event)}
          />
          {this.props.errors?.permission && <FieldError message={this.props.errors.permission}/>}
        </div>

        {!coach && (
          <div className='form-group'>
            <label htmlFor='community_id'>
              Start typing to search and select Community (minimum of 5
              characters needed) <span className='required-text'>*</span>
            </label>
            <Select
              multi={false}
              name='form-field-name'
              value={community_id}
              options={communities}
              placeholder='Search Communities'
              onChange={this.onSelectOption}
              className='w-100 community-select'
              onInputChange={(event) => this.fetchCommunities(event)}
            />
            {this.props.errors?.community && <FieldError message={this.props.errors.community}/>}
          </div>
        )}
        <div className='row'>
          <div className='d-flex justify-content-end flex-row w-100 px-3'>
            <p className='required-text '>* Required Fields</p>
          </div>
        </div>
        {!this.state.adminTagLoader &&
          this.props.coach &&
          this._renderAdminTagSelect(this.props.adminTags)}
        <div className='row d-flex flex-column align-items-center mt-3'>
          {this.props.coach && (
            <button
              className='accolade-button'
              onClick={(e) => this._handleAccoladeButton(e)}
            >
              <i className='fa fa-trophy' aria-hidden='true' />
              {` ${accoladesShow ? "Hide" : "Add/Remove"} Accolades `}
              <i className='fa fa-trophy' aria-hidden='true' />
            </button>
          )}
          {accoladesShow ? (
            this.state.accoladeLoader ? (
              <div className='accolade-loader'>
                <Loader message='loading accolades' />
              </div>
            ) : (
              <div className='accolade-tables-container'>
                <table className='accolade-table'>
                  <thead>
                    <tr>
                      <th colSpan='2'>Click to issue accolade to this coach</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.accolades.all_accolades
                      .filter(
                        (a) =>
                          !this.props.accolades.accolades.some(
                            (acc) => acc.name === a.name
                          )
                      )
                      .map((a) => {
                        return (
                          <tr
                            key={a.name}
                            className={
                              stockAccolades[a.name].is_active
                                ? "active"
                                : "not-active"
                            }
                            onClick={() => this._triggerAddModal(a)}
                          >
                            <td>{a.name}</td>
                            <td>
                              <div
                                className='accolade-badge'
                                style={{
                                  backgroundImage: `url(${
                                    stockAccolades[a.name].badge
                                  })`,
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                {this.props.accolades.accolades.length > 0 && (
                  <table className='accolade-table'>
                    <thead>
                      <tr>
                        <th colSpan='2'>Current Accolades: Click to remove</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.accolades.all_accolades
                        .filter((a) =>
                          this.props.accolades.accolades.some(
                            (acc) => acc.name === a.name
                          )
                        )
                        .map((a) => {
                          return (
                            <tr
                              key={a.name}
                              className={
                                stockAccolades[a.name].is_active
                                  ? "active"
                                  : "not-active"
                              }
                              onClick={() =>
                                this._triggerRemoveModal(
                                  this.props.accolades.accolades.find(
                                    (acc) => acc.name === a.name
                                  )
                                )
                              }
                            >
                              <td>{a.name}</td>
                              <td>
                                <div
                                  className='accolade-badge'
                                  style={{
                                    backgroundImage: `url(${
                                      stockAccolades[a.name].badge
                                    })`,
                                  }}
                                />
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                )}
              </div>
            )
          ) : null}
        </div>
        <div className='row d-flex justify-content-center align-items-center mt-4'>
          <button className='btn btn-dark m-2' onClick={closeNewCoachModal}>
            Cancel
          </button>
          <button
            type='submit'
            className='btn btn-primary m-2'
            disabled={this.validateCoachForm()}
          >
            {this.props.coach ? "Update Coach" : "Create Coach"}
          </button>
        </div>
        {tagManagerModal && (
          <BaseModal
            close={this._closeConfirmationModals}
            back={false}
            width={"50vw"}
          >
            <AdminTagIndex />
          </BaseModal>
        )}
      </form>
    );
  }

  _handleBirthdateChange = (date) => {
    this.setState({ birthdate: date });
  };

  _handleAdminTagChange = (e) => {
    let newTagId = e.target.value;
    if (this.props.userAdminTags.find((t) => t.id === parseInt(newTagId))) {
      return false;
    }
    this.props.admin_tag_actions.addAdminTag(this.props.coach.id, newTagId);
  };

  _renderAdminTagSelect = (adminTags) => {
    let categories = adminTags.reduce((acc, cur) => {
      if (!acc.includes(cur.category)) {
        acc.push(cur.category);
      }
      return acc;
    }, []);
    return (
      <div className='row d-flex flex-column mt-2'>
        <p
          style={{ marginLeft: "auto", color: "lightblue", cursor: "pointer" }}
          onClick={() => this.setState({ tagManagerModal: true })}
        >
          -Manage Tags-
        </p>
        <div style={{ border: "1px solid grey", borderRadius: 4 }}>
          <select
            style={{ width: "100%" }}
            onChange={this._handleAdminTagChange}
            value='-1'
          >
            <option disabled value='-1'>
              Add Admin Tag
            </option>
            {categories.map((c, i) => {
              return (
                <optgroup key={i} label={c}>
                  {adminTags
                    .filter((t) => t.category === c)
                    .map((t) => {
                      return (
                        <option key={`inner-${t.id}`} value={t.id}>
                          {t.name}
                        </option>
                      );
                    })}
                </optgroup>
              );
            })}
          </select>
        </div>
        <div className='d-flex mt-2'>
          {this.props.userAdminTags.map((t) => {
            return (
              <div
                key={t.id}
                className='p-2 d-flex justify-content-between align-items-center'
                style={{
                  border: "1px solid orange",
                  borderRadius: 8,
                  marginRight: 6,
                }}
              >
                <div className='d-flex flex-column'>
                  <p style={{ margin: 0, fontSize: "0.85rem" }}>{t.name}</p>
                  <p style={{ color: "grey", fontSize: "0.75rem", margin: 0 }}>
                    {t.category}
                  </p>
                </div>
                <i
                  className='fa fa-times'
                  aria-hidden='true'
                  style={{ cursor: "pointer", marginLeft: 6 }}
                  onClick={() =>
                    this._handleRemoveAdminTag(this.props.coach.id, t.id)
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  _handleRemoveAdminTag = (user_id, admin_tag_id) => {
    this.props.admin_tag_actions.removeAdminTag(user_id, admin_tag_id);
  };

  _handleBackdateChange = (date) => {
    this.setState({ backdate: date });
  };

  _handleAddAccolade = () => {
    const { selectedAccolade } = this.state;
    this.setState({ addButtonClicked: true }, () => {
      this.props.accolade_actions
        .createAccolade(
          this.props.coach.id,
          selectedAccolade.id,
          this.state.backdate
        )
        .then(() => this._closeConfirmationModals());
    });
  };

  _handleRemoveAccolade = () => {
    const { selectedAccolade } = this.state;
    this.setState({ removeButtonClicked: true }, () => {
      this.props.accolade_actions
        .destroyAccolade(selectedAccolade.id, this.props.coach.id)
        .then(() => this._closeConfirmationModals());
    });
  };

  _triggerAddModal = (accolade) => {
    this.setState({
      selectedAccolade: accolade,
      accoladeConfirmationModal: true,
    });
  };

  _triggerRemoveModal = (accolade) => {
    // this accolade is coming from the api
    this.setState({
      removeAccoladeConfirmationModal: true,
      selectedAccolade: accolade,
    });
  };

  _closeConfirmationModals = () => {
    this.setState({
      removeAccoladeConfirmationModal: false,
      accoladeConfirmationModal: false,
      tagManagerModal: false,
      selectedAccolade: {},
      addButtonClicked: false,
      removeButtonClicked: false,
      backdate: moment(),
    });
  };

  _handleAccoladeButton = (e) => {
    e.preventDefault();
    const { accoladesShow } = this.state;
    this.setState({ accoladesShow: !accoladesShow });
  };
}

NewCoachForm.propTypes = {
  handleEditCoach: PropTypes.func.isRequired,
  handleSubmitCoach: PropTypes.func.isRequired,
  email: PropTypes.string,
  errors: PropTypes.shape({
    general: PropTypes.arrayOf(PropTypes.string),
    firstName: PropTypes.arrayOf(PropTypes.string),
    lastName: PropTypes.arrayOf(PropTypes.string),
    email: PropTypes.arrayOf(PropTypes.string),
    gender: PropTypes.arrayOf(PropTypes.string),
    birthdate: PropTypes.arrayOf(PropTypes.string),
    password: PropTypes.arrayOf(PropTypes.string),
    confirmPassword: PropTypes.arrayOf(PropTypes.string),
    permission: PropTypes.arrayOf(PropTypes.string),
    community: PropTypes.arrayOf(PropTypes.string),
  }),
}

//this.props.deleteClass(this.props.course)
function mapStateToProps(state, ownProps) {
  return {
    ui: state.ui,
    user: state.user.current_user,
    coaches: state.coaches,
    communities: state.communities.communities,
    accolades: state.accolades,
    adminTags: state.adminTags.admin_tags,
    userAdminTags: state.adminTags.user_admin_tags,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    common_actions: bindActionCreators(commonActions, dispatch),
    coach_actions: bindActionCreators(coachActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
    communities_actions: bindActionCreators(communitiesActions, dispatch),
    accolade_actions: bindActionCreators(accoladeActions, dispatch),
    admin_tag_actions: bindActionCreators(adminTagActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCoachForm);
