import * as types from '../actions/actionTypes';
// import update from 'immutability-helper';
// state = [] : Start out with no accounts
export default function communityStudents(state = [], action){
  switch(action.type){

    case types.GET_COMMUNITY_STUDENTS:
      return action.students;

    default:
      return state;
  }
}
