import { useSelector } from 'react-redux'
import SessionsList from '../../../ScheduleBuilder/SessionsList'

const ProgramSchedule = () => {
  const course = useSelector(state => state.programPresentation.course)

  return (
    <SessionsList
      className='pb-3 pl-lg-5'
      schedule={course.schedule}
      showPastSessions
      communityTimezone={course.community.timezone}
    />
  )
}

export default ProgramSchedule
