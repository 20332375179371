import * as types from '../actions/actionTypes';

// state = [] : Start out with no accounts
export default function socialObjectives(state = [], action){
  switch(action.type){

    case types.ADD_SOCIAL_OBJECTIVES:
        return action.social_objectives;

    case types.UPDATE_OBJECTIVE_SOCIAL:
      const newItem = action.social_objectives;
      return socialObjectives.map(oldItem => {
        if (oldItem.id !== newItem.id) {
          return oldItem;
        }
        return { ...oldItem, ...newItem };
      });

    default:
      return state;
  }
}

// APPENDS ACTION.ACTIVITY ONTO THE STATE
// [...state, Object.assign({}, action.activity) ];