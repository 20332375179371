import React, { Component } from "react";
import AvatarPlaceholder from "../../assets/images/mock/StockProfilePic.png";
import { withRouting } from "./hooks";

import "./StudentPreviewCard.scss";

class StudentPreviewCard extends Component { // TODO: Check if anyone sends "router" through props
  state = {
    selected: false
  };
  componentDidMount = () => {
    const { preview } = this.props;
    if (!preview) this.renderActiveStudent();
  };
  toggleStudentCard = id => {
    const { filterStudents, student } = this.props;

    this.setState({ selected: !this.state.selected }, () =>
      filterStudents(id, student)
    );
  };
  renderActiveStudent = () => {
    const { student, selectedStudents } = this.props;
    let index = selectedStudents.indexOf(student.id);

    if (index > -1) this.setState({ selected: true });
  };

  navigateTo = id => {
    const { smartToggle } = this.props;
    this.props.navigate(
      `/profile/${id}`,
      {
        state: {
          userId: id,
          smartToggle
        }
      }
    )
  };

  render() {
    const { student, preview, stayInactive } = this.props;
    const { selected } = this.state;
    return (
      <div
        id='StudentCardContainer'
        className={`card mb-2 p-2 ${
          selected && !stayInactive ? "selected" : ""
        } animated fadeIn`}
        onClick={
          preview
            ? () => this.navigateTo(student.id)
            : () => this.toggleStudentCard(student.id)
        }
      >
        <div className='student-card-container'>
          <div className='smaller-round-image-wrapper'>
            <img
              className='round-image'
              src={student.avatar ? student.avatar.file_url : AvatarPlaceholder}
              alt={`${student.first_name} ${student.last_name}`}
            />
          </div>
          <p className='m-0'>
            {student.first_name} {student.last_name}
          </p>
        </div>
      </div>
    );
  }
}

export default withRouting(StudentPreviewCard)
