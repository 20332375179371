import React from 'react';
import Modal from 'react-modal';
import AnnouncementForm from './AnnouncementForm';

export default class AnnouncementModal extends React.Component {

  render() {
    return (
      <Modal
        id="announcements-page-modal"
        isOpen={this.props.show}
        onRequestClose={this.props.onClose}
        contentLabel="Announcement Modal">
        <div className="modal-header row m-0 p-0">
          <div className="col-11 m-0 p-0">
            <h5 className="modal-title display-4">Send Email</h5>
          </div>
          <div className="col-1 m-0 p-0">
            <button type="button" className="close pull-right font-200" data-dismiss="modal" aria-label="Close" onClick={this.props.close}>
              <span aria-hidden="true">✕</span>
            </button>
          </div>
        </div>
        <div className="modal-content">
          <div className="modal-body" id="input-border">
            <div className="row mb-2 border-bottom p-2">
              <div className="col">
                <div className="form-group">
                  <AnnouncementForm
                    sendEmail={true}
                    web={this.props.web}
                    courseOptions={this.props.courseOptions}
                    handleSubmit={this.props.handleSubmit}
                    loader={this.props.loader} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
};
