import * as types from "../actions/actionTypes";

// state = [] : Start out with no accounts
export default function activities(state = [], action) {
  switch (action.type) {
    case types.ADD_PRACTICE_TYPES:
      return action.practice_types;

    default:
      return state;
  }
}

// APPENDS ACTION.ACTIVITY ONTO THE STATE
// [...state, Object.assign({}, action.activity) ];
