import React from 'react';

// MUST PASS IN AN ACTIVITY OBJECT, HAS OPTION OF SHOWING COMMENTS OR NOT
class ReportsCard extends React.Component{

  render(){
  	var reportType = this.props.reportType
    return(
      <div className="row my-4 d-flex align-items-center">
        <div className="col-9">
          <h4>{this.props.reportName}</h4>
          <p>
            {this.props.summary}
          </p>
        </div>
        <div className="col-3">
          <button
            className="btn btn-sm btn-block"
            onClick={() => this.props.viewReport(reportType)}
          >
            <i className="fa fa-eye text-white mr-1" aria-hidden="true" />{" "}
            View Report
          </button>
        </div>
      </div>
    );
  }
}

export default ReportsCard;
