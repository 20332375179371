import React from "react";

import "./ReportsThumbCard.scss";

class ReportsThumbCard extends React.Component {
  render() {
    const { reportType, reportName, summary, viewReport, image } = this.props;
    return (
      <div id='ReportsThumbCard'>
        <div className='thumb' style={{ backgroundImage: `url(${image})` }} />
        <div className='d-flex flex-column justify-content-between'>
          <p className='title'>{reportName}</p>
          <p className='summary'>{summary}</p>
          <button
            className='btn-rectangular btn-rectangular-main'
            onClick={() => viewReport(reportType)}
          >
            View Report
          </button>
        </div>
      </div>
    );
  }
}

export default ReportsThumbCard;
