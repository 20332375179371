import React, { Component } from "react";

// CHILDREN
import Placeholder from "../common/Placeholder";
import AdminEducationLesson from "./AdminEducationLesson";

// ASSETS
import "./AdminEducationSection.scss";

class AdminEducationSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.section.id ? props.section.id : -1,
      order: props.section.order,
      name: props.section.name ? props.section.name : "",
      education_lessons: props.section.education_lessons
        ? props.section.education_lessons
        : [],
      lessonIndex: -1,
      sectionHoverActive: false
    };
  }

  handleInputChange = event => {
    const { sendSectionData } = this.props;
    this.setState(
      {
        [event.target.name]: event.target.value
      },
      () => {
        sendSectionData(this.state);
      }
    );
  };

  onMouseEnter = () => {
    this.setState({ sectionHoverActive: true });
  };

  onMouseLeave = () => {
    this.setState({ sectionHoverActive: false });
  };

  addLesson = () => {
    const { sendSectionData } = this.props;
    let lessonIndex = this.state.lessonIndex;
    let education_lessons = [...this.state.education_lessons];
    if (education_lessons.length > 0) {
      let lastLessonIndex =
        education_lessons[education_lessons.length - 1].order;
      lastLessonIndex++;
      lessonIndex = lastLessonIndex;
    } else {
      lessonIndex++;
    }

    education_lessons.push({ order: lessonIndex });
    this.setState(
      {
        lessonIndex,
        education_lessons
      },
      () => {
        sendSectionData(this.state);
      }
    );
  };

  deleteLesson = lesson => {
    const { sendSectionData, deleteLesson } = this.props;
    let education_lessons = [...this.state.education_lessons];
    let indexOfLesson = education_lessons
      .map(lesson => lesson.order)
      .indexOf(lesson.order);

    let deletedLesson = education_lessons.splice(indexOfLesson, 1);
    education_lessons.splice(indexOfLesson, 1);

    this.setState({ education_lessons }, () => sendSectionData(this.state));

    if (deletedLesson[0].id && deletedLesson[0].id > -1)
      deleteLesson(deletedLesson[0]);
  };

  grabLessonData = data => {
    const { sendSectionData } = this.props;
    let education_lessons = [...this.state.education_lessons];
    let indexOfLesson = education_lessons
      .map(lesson => lesson.order)
      .indexOf(data.order);

    if (indexOfLesson > -1) {
      education_lessons.splice(indexOfLesson, 1, data);
    } else {
      education_lessons.push(data);
    }

    this.setState({ education_lessons }, () => sendSectionData(this.state));
  };

  renderEducationLessons = () => {
    const { education_lessons } = this.state;
    const { isEditModal } = this.props;

    if (education_lessons && education_lessons.length > 0) {
      return education_lessons.map((lesson, index) => (
        <div className="education-lesson-wrapper" key={lesson.order}>
          <h1 className="col-md-1">{index + 1}</h1>
          <AdminEducationLesson
            lesson={lesson}
            index={index}
            sendLessonData={this.grabLessonData}
            deleteLesson={this.deleteLesson}
            isEditModal={isEditModal}
          />
        </div>
      ));
    }
    return (
      <Placeholder
        title={"No Lessons Created Yet"}
        icon={"fa-graduation-cap"}
        subtext1={
          "Click the 'Add Lesson' button below to get started creating lessons for this section"
        }
      />
    );
  };

  render() {
    const { name, sectionHoverActive } = this.state;
    const { deleteSection, section, isEditModal } = this.props;

    return (
      <div
        id="AdminEducationSection"
        className="card animation fadeIn"
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        {sectionHoverActive && !isEditModal && (
          <button
            className="delete-btn animation-shrink"
            onClick={() => deleteSection(section)}
          >
            <i className="fa fa-times" />
          </button>
        )}
        <div className="form-group">
          <label htmlFor="name">Section Name</label>
          <input
            type="text"
            name="name"
            className="form-control"
            id="name"
            placeholder="Section name"
            onChange={this.handleInputChange}
            value={name}
          />
        </div>
        <div className="section-lession-header">
          <p>Section Lessons</p>
        </div>
        <div className="education-lesson-container">
          {this.renderEducationLessons()}
        </div>
        <div className="add-lesson-btn-wrapper">
          <button
            className="add-lesson-btn animation-shrink"
            onClick={this.addLesson}
          >
            <i className="fa fa-plus" aria-hidden="true" /> Add Lesson
          </button>
        </div>
      </div>
    );
  }
}

export default AdminEducationSection;
