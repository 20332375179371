import { useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { pluralize } from '../../../utility/helperFunctions'
import moment from 'moment'
import styles from './RosterLabels.module.scss'

const RosterLabels = ({ event }) => {
  const students = event.students

  const noResponseCount = useMemo(() => (
    students.filter(s => s.status === 'created').length
  ), [students])

  const attendingCount = useMemo(() => (
    students.filter(s => s.status === 'confirmed').length
  ), [students])


  const notAttendingCount = useMemo(() => (
    students.filter(s => s.status === 'declined').length
  ), [students])

  return (
    <div className={classNames(styles.rosterLabels, 'd-flex flex-wrap')}>
      <div className={classNames(styles.labelWrapper, styles.golfersLabel)}>
        <span className='mx-auto'>{students.length} {pluralize('Golfer', students.length)}</span>
      </div>

      <div className={classNames(styles.labelWrapper, styles.attendingLabel)}>
        <div className={styles.hl}/>
        <div className={styles.iconWrapper}>
          <i className='fa fa-check' aria-hidden='true'/>
        </div>
        <div className={styles.textWrapper}>
          <span>{attendingCount}</span>
          <span>Attending</span>
        </div>
      </div>

      <div className={classNames(styles.labelWrapper, styles.notAttendingLabel)}>
        <div className={styles.hl}/>
        <div className={styles.iconWrapper}>
          <i className='fa fa-times' aria-hidden='true'/>
        </div>
        <div className={styles.textWrapper}>
          <span>{notAttendingCount}</span>
          <span>Not Attending</span>
        </div>
      </div>

      <div className={classNames(styles.labelWrapper, styles.noResponseLabel)}>
        <div className={styles.hl}/>
        <div className={styles.iconWrapper}>
          <i className='fa fa-question' aria-hidden='true'/>
        </div>
        <div className={styles.textWrapper}>
          <span>{noResponseCount}</span>
          <span>No Response</span>
        </div>
      </div>

      {event.nextRsvp ?
        <div className={classNames(styles.labelWrapper, styles.rsvpLabel, 'px-3')}>
          <div className={styles.iconWrapper}>
            <i className='fa fa-envelope' aria-hidden='true'/>
          </div>
          <div className={styles.textWrapper}>
            <span>
              RSVP Reminder - Sending in {event.nextRsvp} Days, {moment(new Date(), 'DD-MM-YYYY').add(event.nextRsvp, 'days').format('Do MMMM')}
            </span>
          </div>
        </div>
        :
        event.daysUntilEvent > 0 ?
          <div className={classNames(styles.labelWrapper, 'px-3')}>
            <div className={styles.textWrapper}>
              <span>Event in {event.daysUntilEvent} {pluralize('Day', event.daysUntilEvent)}</span>
            </div>
          </div>

          :
          event.daysUntilEvent === 0 ?
            <div className={classNames(styles.labelWrapper, 'px-3')}>
              <div className={styles.textWrapper}>
                <span className={styles.eventToday}>Event day: Today!</span>
              </div>
            </div>
            :
            null
      }
    </div>
  )
}

RosterLabels.propTypes = {
  event: PropTypes.shape({
    students: PropTypes.array.isRequired,
    nextRsvp: PropTypes.number,
    daysUntilEvent: PropTypes.number,
  }),
}

export default RosterLabels
