import * as constants from "./actionTypes";

import Axios from '../axios'

function successRequestingDivisionLeaderboard(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_PROSHOP_DIVISION_LEADERBOARD,
      division_leaderboard: response.data.division_leaderboard
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successRequestingCoachDivisionLeaderboard(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_PROSHOP_COACH_DIVISION_LEADERBOARD,
      coach_division_leaderboard: response.data.coach_division_leaderboard
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successRequestingCommunityLeaderboard(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_PROSHOP_COMMUNITY_LEADERBOARD,
      community_leaderboard: response.data.community_leaderboard
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successRequestingPhotoWall(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_PROSHOP_PHOTO_WALL,
      photo_wall: response.data.photo_wall
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successRequestingCommunityStats(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_PROSHOP_COMMUNITY_STATS,
      community_stats: response.data.community_stats
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successRequestingProShop(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_ALL_PRO_SHOP,
      stats: response.data.stats
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successPostingNewPhoto(response) {
  if (response.status === 201) {
    return {
      type: constants.ADD_COACH_AD,
      community_ads: response.data.community_ads
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successPostingPinnedAds(response) {
  if (response.status === 200) {
    return {
      type: constants.POST_PINNED_ADS,
      community_ads: response.data.community_ads
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successDeletingPhoto(response) {
  if (response.status === 200) {
    return {
      type: constants.DELETE_COACH_AD,
      community_ads: response.data.community_ads
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

//=========================================================================================
//=========================================================================================
//=========================================================================================

export function getDivisionLeaderboard(community_id) {
  return function(dispatch) {
    return requestDivisionLeaderboard(community_id).then(response =>
      dispatch(successRequestingDivisionLeaderboard(response))
    );
  };
}

export function getCoachDivisionLeaderboard(community_id) {
  return function(dispatch) {
    return requestCoachDivisionLeaderboard(community_id).then(response =>
      dispatch(successRequestingCoachDivisionLeaderboard(response))
    );
  };
}

export function getCommunityLeaderboard(community_id) {
  return function(dispatch) {
    return requestCommunityLeaderboard(community_id).then(response =>
      dispatch(successRequestingCommunityLeaderboard(response))
    );
  };
}

export function getPhotoWall(community_id) {
  return function(dispatch) {
    return requestPhotoWall(community_id).then(response =>
      dispatch(successRequestingPhotoWall(response))
    );
  };
}

export function getCommunityStats(community_id) {
  return function(dispatch) {
    return requestCommunityStats(community_id).then(response =>
      dispatch(successRequestingCommunityStats(response))
    );
  };
}

export function getProShop(community_id) {
  return function(dispatch) {
    return requestProShop(community_id).then(response =>
      dispatch(successRequestingProShop(response))
    );
  };
}

export function addNewPhoto(community_id, data) {
  return function(dispatch) {
    return postNewPhoto(community_id, data).then(response =>
      dispatch(successPostingNewPhoto(response))
    );
  };
}

export function pinAds(community_id, data) {
  return function(dispatch) {
    return postPinAds(community_id, data).then(response =>
      dispatch(successPostingPinnedAds(response))
    );
  };
}

export function deletePhoto(attachment_id) {
  return function(dispatch) {
    return destroyPhoto(attachment_id).then(response =>
      dispatch(successDeletingPhoto(response))
    );
  };
}

//=========================================================================================
//=========================================================================================
//=========================================================================================

function requestDivisionLeaderboard(community_id) {
  let requestUrl =
    "/api/v1/pro_shop_display/division_leaderboard?community_id=" +
    community_id;
  return Axios.get(requestUrl);
}

function requestCoachDivisionLeaderboard(community_id) {
  let requestUrl =
    "/api/v1/pro_shop_display/coach_division_leaderboard?community_id=" +
    community_id;
  return Axios.get(requestUrl);
}

function requestCommunityLeaderboard(community_id) {
  let requestUrl =
    "/api/v1/pro_shop_display/community_leaderboard?community_id=" +
    community_id;
  return Axios.get(requestUrl);
}

function requestPhotoWall(community_id) {
  let requestUrl =
    "/api/v1/pro_shop_display/photo_wall?community_id=" + community_id;
  return Axios.get(requestUrl);
}

function requestCommunityStats(community_id) {
  let requestUrl =
    "/api/v1/pro_shop_display/community_stats?community_id=" + community_id;
  return Axios.get(requestUrl);
}

function requestProShop(community_id) {
  let requestUrl =
    "/api/v1/pro_shop_display/all_info?community_id=" + community_id;
  return Axios.get(requestUrl);
}

function postNewPhoto(community_id, data) {
  let requestUrl = "/api/v1/pro_shop_display/add_photo";
  const formData = new FormData();
  formData.append("community_id", community_id);
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      formData.append(key, data[key]);
    }
  }
  return Axios.post(requestUrl, formData);
}

function postPinAds(community_id, data) {
  let requestUrl = "/api/v1/pro_shop_display/pin_ads";
  return Axios.post(requestUrl, {
    community_id: community_id,
    pins: data
  });
}

function destroyPhoto(attachment_id) {
  let requestUrl = "api/v1/pro_shop_display/delete_photo/" + attachment_id;
  return Axios.delete(requestUrl);
}
