import React, { Component } from "react";
import ReactTable from "react-table";
import createTable from "react-table-hoc-fixed-columns";
// import "react-table/react-table.css";
import moment from "moment";

import CSVExportModal from "../CSVExportModal";
// import profile_avatar from "../../../assets/images/mock/StockProfilePic.png";

const ReactTableFixedColumns = createTable(ReactTable);

class AdminCoachEngagementTable extends Component {
  state = {
    showModal: false,
    dataLoader: false,
    data: []
  };

  headers = [
    { label: "Score", key: "score" },
    { label: "First Name", key: "firstname" },
    { label: "Last Name", key: "lastname" },
    { label: "Community", key: "community" },
    { label: "Email", key: "email" },
    { label: "Phone", key: "phone" },
    { label: "Last Login", key: "lastlogin" },
    { label: "Community Activity (Last 30 Days)", key: "communityactivity" },
    { label: "Active Golfers", key: "activegolfers" },
    { label: "Golfers", key: "golfers" },
    { label: "Plays", key: "plays" },
    { label: "Practices", key: "practices" },
    { label: "Social", key: "social" },
    { label: "Goals", key: "goals" },
    { label: "Private Goals Issued", key: "privategoals" },
    { label: "Completed Express Training", key: "expresstraining" },
    { label: "Programs", key: "programs" },
    { label: "Events", key: "events" },
    { label: "Announcements", key: "announcements" },
    { label: "Likes", key: "likes" },
    { label: "Comments", key: "comments" }
  ];

  toggleModal = data => {
    this.setState({
      showModal: !this.state.showModal,
      data: data ? data : []
    });
  };

  createData = () => {
    const { data } = this.props;

    let tempData = [];
    this.setState({ dataLoader: true });
    if (data.length > 0) {
      data.map(d => {
        let score = d.engagement_score.value;
        let firstname = d.coach.first_name;
        let lastname = d.coach.last_name;
        let community = d.community.name;
        let email = d.coach.email;
        let phone = d.coach.phone;
        let lastlogin = d.last_login_date.value;
        let communityactivity = d.month_activity_count.value;
        let activegolfers = d.active_golfer_count.value;
        let golfers = d.golfer_count.value;
        let plays = d.activity_play_count.value;
        let practices = d.activity_train_count.value;
        let social = d.activity_social_count.value;
        let goals = d.activity_goal_count.value;
        let privategoals = d.private_goal_count.value;
        let expresstraining = d.completed_express_training.value;
        let programs = d.programs_count.value;
        let events = d.events_count.value;
        let announcements = d.announcements_count.value;
        let likes = d.likes_count.value;
        let comments = d.comments_count.value;

        tempData.push({
          score,
          firstname,
          lastname,
          community,
          email,
          phone,
          lastlogin: lastlogin ? moment(lastlogin).fromNow() : "---",
          communityactivity,
          activegolfers,
          golfers,
          plays,
          practices,
          social,
          goals,
          privategoals,
          expresstraining,
          programs,
          events,
          announcements,
          likes,
          comments
        });
      });
    }

    this.toggleModal(tempData);
  };

  getColor = color => {
    switch (color) {
      case "red":
        return "#ed213a";
      case "yellow":
        return "#fddb10";
      case "green":
        return "#33d9b2";
      default:
        return "";
    }
  };

  renderEngagementTableCell = props => {
    return (
      <div
        className='number'
        style={{
          backgroundColor: `${this.getColor(props.value.score.color)}`,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div style={{ color: "#2b3843" }}>
          {props.value ? `${props.value.value}%` : "---"}
        </div>
      </div>
    );
  };

  renderTableCell = props => {
    return (
      <div
        className='number'
        style={{
          backgroundColor: `${this.getColor(props.value.score.color)}`,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div style={{ color: "#2b3843" }}>
          {props.value ? props.value.value : "---"}
        </div>
      </div>
    );
  };

  renderCoachTableCell = props => {
    return (
      <div
        className='number'
        style={{
          backgroundColor: "",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div style={{ color: "#2b3843" }}>
          {props.value.first_name} {props.value.last_name}
        </div>
      </div>
    );
  };

  renderLastLoginTableCell = props => {
    return (
      <div
        className='number'
        style={{
          backgroundColor: `${this.getColor(props.value.score.color)}`,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div style={{ color: "#2b3843" }}>
          {props.value && props.value.value
            ? moment(props.value.value).fromNow()
            : "---"}
        </div>
      </div>
    );
  };

  renderBasicTableCell = props => {
    return (
      <div
        className='number'
        style={{
          backgroundColor: "",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div style={{ color: "#2b3843" }}>{props.value}</div>
      </div>
    );
  };

  renderTable = () => {
    const { loader, data, fetchData, pages } = this.props;
    const columns = [
      {
        Header: "Score",
        accessor: "engagement_score",
        sortable: false,
        style: { padding: 0 },
        Cell: props => this.renderEngagementTableCell(props),
        fixed: true
      },
      {
        Header: "First Name",
        accessor: "coach.first_name",
        Cell: props => this.renderBasicTableCell(props),
        fixed: true
      },
      {
        Header: "Last Name",
        accessor: "coach.last_name",
        Cell: props => this.renderBasicTableCell(props),
        fixed: true
      },
      {
        Header: "Community",
        accessor: "community.name",
        sortable: false,
        Cell: props => this.renderBasicTableCell(props)
      },
      {
        Header: "Email",
        accessor: "coach.email",
        Cell: props => this.renderBasicTableCell(props)
      },
      {
        Header: "Phone",
        accessor: "coach.phone",
        sortable: false,
        Cell: props => this.renderBasicTableCell(props)
      },
      {
        Header: "Last Login",
        accessor: "last_login_date",
        style: { padding: 0 },
        Cell: props => this.renderLastLoginTableCell(props)
      },
      {
        Header: "Community Activity (Last 30 days)",
        accessor: "month_activity_count",
        sortable: false,
        style: { padding: 0 },
        Cell: props => this.renderTableCell(props)
      },
      {
        Header: "Active Golfers",
        accessor: "active_golfer_count",
        sortable: false,
        style: { padding: 0 },
        Cell: props => this.renderTableCell(props)
      },
      {
        Header: "Golfers",
        accessor: "golfer_count",
        sortable: false,
        style: { padding: 0 },
        Cell: props => this.renderTableCell(props)
      },
      {
        Header: "Plays",
        accessor: "activity_play_count",
        sortable: false,
        style: { padding: 0 },
        Cell: props => this.renderTableCell(props)
      },
      {
        Header: "Practices",
        accessor: "activity_train_count",
        sortable: false,
        style: { padding: 0 },
        Cell: props => this.renderTableCell(props)
      },
      {
        Header: "Social",
        accessor: "activity_social_count",
        sortable: false,
        style: { padding: 0 },
        Cell: props => this.renderTableCell(props)
      },
      {
        Header: "Goals",
        accessor: "activity_goal_count",
        sortable: false,
        style: { padding: 0 },
        Cell: props => this.renderTableCell(props)
      },
      {
        Header: "Private Goals Issued",
        accessor: "private_goal_count",
        sortable: false,
        style: { padding: 0 },
        Cell: props => this.renderTableCell(props)
      },
      {
        Header: "Completed Express Training",
        accessor: "completed_express_training",
        sortable: false,
        style: { padding: 0 },
        Cell: props => this.renderTableCell(props)
      },
      {
        Header: "Programs",
        accessor: "programs_count",
        sortable: false,
        style: { padding: 0 },
        Cell: props => this.renderTableCell(props)
      },
      {
        Header: "Events",
        accessor: "events_count",
        sortable: false,
        style: { padding: 0 },
        Cell: props => this.renderTableCell(props)
      },
      {
        Header: "Announcements",
        accessor: "announcements_count",
        sortable: false,
        style: { padding: 0 },
        Cell: props => this.renderTableCell(props)
      },
      {
        Header: "Likes",
        accessor: "likes_count",
        sortable: false,
        style: { padding: 0 },
        Cell: props => this.renderTableCell(props)
      },
      {
        Header: "Comments",
        accessor: "comments_count",
        sortable: false,
        style: { padding: 0 },
        Cell: props => this.renderTableCell(props)
      }
    ];

    return (
      <div className='container my-5 animated fadeIn'>
        <div className='row mb-5 d-flex align-items-center'>
          <div className='col-md-8'>
            <h2>{this.props.title}</h2>
          </div>
          <div className='col-md-4'>
            <button
              className='btn btn-sm btn-block'
              onClick={() => this.props.goBack()}
            >
              <i
                className='fa fa-arrow-left text-white mr-1'
                aria-hidden='true'
              />{" "}
              View Another Report
            </button>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <ReactTableFixedColumns
              data={!loader ? data : []}
              columns={columns}
              pages={pages}
              pageSizeOptions={[5, 10, 20, 25, 50]}
              defaultPageSize={10}
              loading={loader}
              loadingText='Loading Report'
              noDataText={loader ? "" : "No data found"}
              manual
              onFetchData={(state, instance) =>
                fetchData(state, instance, "coach_engagement")
              }
            />
          </div>
        </div>
        <button onClick={this.createData} className='mt-3 btn btn-success'>
          Export as CSV
        </button>
      </div>
    );
  };

  render() {
    const { showModal } = this.state;
    return (
      <div>
        {this.renderTable()}
        {showModal && (
          <CSVExportModal
            headers={this.headers}
            data={this.state.data}
            showModal={this.state.showModal}
            toggleModal={this.toggleModal}
            reportType={"Coach Engagement Report"}
            filename={"coach-engagement-report.csv"}
          />
        )}
      </div>
    );
  }
}

export default AdminCoachEngagementTable;
