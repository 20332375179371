import * as types from "../actions/actionTypes";

export default function event(state = [], action) {
  switch (action.type) {
    case types.GET_EVENT:
      return action.event;

    case types.UPDATE_EVENT:
      return Object.assign({}, state, {
        event: action.event
      });

    default:
      return state;
  }
}
