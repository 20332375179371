import * as types from "../actions/actionTypes";
// import update from 'immutability-helper';

// state = [] : Start out with no accounts
export default function notifications(state = [], action) {
  switch (action.type) {
    case types.ADD_NOTIFICATIONS:
      return action.notifications;
    case types.ADD_NEW_NOTIFICATIONS:
      return action.notifications;
    case types.CLEAR_NOTIFICATIONS:
      return action.notifications;
    default:
      return state;
  }
}
