import { useSelector } from 'react-redux'
import classnames from 'classnames'
import ProgramActionsCard from '../ProgramActionsCard'
import ProgramPresentationHeader from '../ProgramPresentationHeader'
// import RichTextViewer from '../../../../common/form/RichTextEditor/RichTextViewer'

import styles from './ProgramDescription.module.scss'

const ProgramDescription = () => {
  const course = useSelector(state => state.programPresentation.course)

  return (
    <div className='pl-lg-5'>
      <ProgramPresentationHeader className='d-flex d-lg-none' />
      <ProgramActionsCard className='d-flex d-lg-none' />
      <div className={classnames('px-4 py-3', styles['program-description'])}>
        {course.description}
        {/* <RichTextViewer value={course.description} /> */}
      </div>
    </div>
  )
}

export default ProgramDescription
