import React from 'react'
import moment from 'moment'
import Axios from '../../../axios'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as salesLeadActions from '../../../actions/salesLeadActions'

import BaseModal from '../../common/BaseModal'
import Loader from '../../common/Op36Loader-web'

import './SalesLeadDetailModal.scss'

import defaultProfile from '../../../assets/images/default_profile.png'
import communityPlaceholder from '../../../assets/images/logos/OP36_clean_icon_orange.png'

import registrationQuestions from '../../auth/Registration/CoachRegistrationFlow/registrationQuestions'
import onboardingQuestions from '../../auth/Registration/CoachRegistrationFlow/onboardingQuestions'

class SalesLeadDetailModal extends React.Component {
  state = {
    loader: true, // initial loader for sales lead data
    coach: null,
    sales_rep: null,
    login_info: null,
    coach_academy_courses: [],
    // form questions
    communityName: '',
    location: '',
    communityType: -1,
    seasonLength: -1,
    hours: -1,
    title: -1,
    notes: '',
    signupReasons: [],
    affiliations: [],
    interests: [],
    programs: [],
    painPoints: [],
    amenities: [],
    status: '',
    upgrade: false, // triggers upgrade modal
    communitySearch: '', // user input into search bar within upgrade
    communityLoader: false, // loader for searching communities within upgrade flow
    communityResults: [], // api response for communities held here = no reason to keep them in REDUX
    selectedCommunity: null, // triggers confirmation modal
    newPermission: -1, // modified by select within confirmation modal
    upgrading: false, // loader for while you're upgrading a coach
    upgradeSuccess: false, // shows for a brief time after upgrading complete
    repChange: false, // controls view of changing sales reps
    selectedNewSalesRep: null, // holds selected sales rep to change
  }

  componentDidMount() {
    const { lead } = this.props
    Axios.get('/api/v1/sales_leads/' + lead.id).then((res) => {
      const data = res.data.sales_lead
      // console.log(data);
      this.setState(
        {
          coach: data.coach,
          sales_rep: data.sales_rep,
          login_info: data.login_info,
          coach_academy_courses: data.coach_academy_courses,
          communityName: data.community_name,
          location: data.location || '',
          communityType: data.community_type || -1,
          seasonLength: data.season_length || -1,
          hours: data.hours || -1,
          title: data.job_title || -1,
          notes: data.notes || '',
          signupReasons: data.sign_up_reasons || [],
          affiliations: data.affiliations || [],
          interests: data.interests || [],
          programs: data.programs || [],
          painPoints: data.pain_points || [],
          amenities: data.amenities || [],
          status: data.status,
        },
        () => {
          this.setState({ loader: false })
        }
      )
    })
  }

  // eslint-disable-next-line max-lines-per-function
  render() {
    const {
      loader,
      coach,
      sales_rep,
      login_info,
      communityName,
      location,
      coach_academy_courses,
      affiliations,
      upgrade,
      communitySearch,
      communityResults,
    } = this.state
    return (
      <BaseModal
        close={
          upgrade ? () => this.setState({ upgrade: false }) : this.props.close
        }
        width='96%'
      >
        {loader ? (
          <Loader message='grabbing sales lead' />
        ) : upgrade ? (
          <div id='SalesLeadUpgradeModal'>
            <h2>{`Upgrade ${coach.first_name} ${coach.last_name}`}</h2>
            <div className='info-box'>
              <p className='bold'>Before upgrading:</p>
              <ul>
                <li>
                  Create a new community for the coach if not joining a
                  pre-existing one.
                </li>
                <li>After creating the community, search for it below</li>
                <li>{`Indicate if Coach ${coach.last_name} will be a Dev League or Full Academy coach`}</li>
                <li>
                  *** Watch out *** You will <span className='bold'>NOT</span>{' '}
                  be able to revert this change.
                </li>
              </ul>
            </div>
            <form>
              <input
                type='text'
                name='communitySearch'
                value={communitySearch}
                onChange={(e) =>
                  this.setState({ communitySearch: e.target.value })
                }
                placeholder='search a community'
              />
              <input
                type='submit'
                value='search'
                className='sub-btn'
                onClick={this._handleCommunitySearch}
              />
            </form>
            {communityResults.length > 0 && (
              <table>
                <thead>
                  <tr>
                    <th>Img</th>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Is Active</th>
                    <th>Type</th>
                  </tr>
                </thead>
                <tbody>
                  {communityResults.map((c, _) => this._renderCommunityRow(c))}
                </tbody>
              </table>
            )}
            {communityResults === 'error' && (
              <p>something happened when searching for communities</p>
            )}
            {this.state.selectedCommunity && (
              <BaseModal
                close={() => {
                  if (this.state.upgrading) {
                    return false
                  }
                  this.setState({ selectedCommunity: null, newPermission: -1 })
                }}
                width='40%'
              >
                {this.state.upgrading ? (
                  <div className='d-flex align-items-center justify-content-center mt-4 upgrading'>
                    <i className='fa fa-spinner fa-spin' aria-hidden='true' />
                  </div>
                ) : this.state.upgradeSuccess ? (
                  <div className='d-flex flex-column align-items-center upgrading'>
                    <i className='fa fa-thumbs-up' aria-hidden='true' />
                    <p>Upgrade Successful!</p>
                  </div>
                ) : (
                  <div className='upgrade-conf-modal'>
                    <p>
                      What type of coach will Coach {coach.last_name} be in{' '}
                      {this.state.selectedCommunity.name}?
                    </p>
                    <div className='confirmation-button-box'>
                      <select
                        value={this.state.newPermission}
                        onChange={(e) =>
                          this.setState({ newPermission: e.target.value })
                        }
                      >
                        <option value={-1} disabled>
                          Select Permission
                        </option>
                        <option value={1}>Dev League</option>
                        <option value={2}>Academy</option>
                      </select>
                      <button
                        onClick={this._handleUpgradeClick}
                        disabled={this.state.newPermission === -1}
                      >
                        Confirm
                      </button>
                    </div>
                    <button
                      className='cancel-button'
                      onClick={() => {
                        if (this.state.upgrading) {
                          return false
                        }
                        this.setState({
                          selectedCommunity: null,
                          newPermission: -1,
                        })
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </BaseModal>
            )}
          </div>
        ) : (
          <div id='SalesLeadDetailModal'>
            <div className='modal-main-content pr-3'>
              <div className='d-flex'>
                <div className='avatar-container'>
                  <div
                    className='avatar'
                    style={{
                      backgroundImage: `url(${
                        coach.avatar ? coach.avatar.file_url : defaultProfile
                      })`,
                    }}
                  />
                </div>
                <div className='personal-info'>
                  <p className='sales-lead-name'>{`${coach.first_name} ${coach.last_name}`}</p>
                  <div className='d-flex'>
                    <div className='col-4'>
                      <p>{`Email: ${coach.email}`}</p>
                      <p>{`Phone: ${coach.phone}`}</p>
                    </div>
                    <div className='col-4'>
                      <div className='d-flex align-items-center'>
                        <p className='mb-0'>{'Title:'}</p>
                        {this._renderTitle()}
                      </div>
                      <div className='d-flex'>
                        <p
                          style={{ width: 'min-content' }}
                          className='mb-0'
                        >{'Hours coaching/wk:'}</p>
                        {this._renderCoachingHours()}
                      </div>
                    </div>
                    <div className='col-4'>
                      <div className='d-flex align-items-center'>
                        <p
                          style={{ width: 'min-content' }}
                          className='mb-0'
                        >{'Status:'}</p>
                        {this._renderStatus()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='d-flex align-items-center flex-wrap my-2'>
                <p className='mb-0'>Affiliations:</p>
                {affiliations.map((a, i) => (
                  <div key={i} className='navy-pill d-flex'>
                    <p className='mb-0 mr-2' style={{ whiteSpace: 'nowrap' }}>
                      {a}
                    </p>
                    <i
                      className='fa fa-times'
                      aria-hidden='true'
                      onClick={() => this._removeFromList('affiliations', a)}
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                ))}
                <select
                  value={-1}
                  onChange={(e) => this._addToList('affiliations', e)}
                  className='button-select aff-select'
                >
                  <option value={-1} disabled>
                    + Add affiliation
                  </option>
                  {this._renderAffiliationChoices()}
                </select>
              </div>
              <p>Reason(s) for signup:</p>
              <div className='d-flex'>{this._renderReasonsForSignup()}</div>
              <div
                style={{
                  height: 2,
                  width: '100%',
                  background: '#5F6F7C',
                  margin: '18px 0',
                }}
              />
              <p className='section-title'>Facility Information</p>
              <div className='d-flex' style={{ color: '#ff6633' }}>
                <div className='col-4'>
                  <div className='d-flex'>
                    <i
                      className='fa fa-map-marker mr-1 mt-2'
                      aria-hidden='true'
                    />
                    <div className='col-fill'>
                      <input
                        placeholder='Facility Name'
                        type='text'
                        value={communityName}
                        name='communityName'
                        onChange={this._handleInputChange}
                        onBlur={this._sendAPIChange}
                        className='hover-border w-100 name-input keep-focus'
                      />
                      <input
                        placeholder='Facility Location'
                        type='text'
                        value={location}
                        name='location'
                        onChange={this._handleInputChange}
                        onBlur={this._sendAPIChange}
                        className='hover-border w-100 location-input keep-focus'
                      />
                    </div>
                  </div>
                </div>
                <div className='col-4'>
                  <div className='d-flex'>
                    <i className='fa fa-users mr-1 mt-2' aria-hidden='true' />
                    <div className='col-fill'>
                      {this._renderCommunityType()}
                      {this._renderOtherCommunityType()}
                    </div>
                  </div>
                </div>
                <div className='col-4'>
                  <div className='d-flex'>
                    <i
                      className='fa fa-calendar mr-1 mt-2'
                      aria-hidden='true'
                    />
                    <div className='col-fill'>{this._renderSeasonLength()}</div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  height: 2,
                  width: '100%',
                  background: '#5F6F7C',
                  margin: '18px 0',
                }}
              />
              <p className='section-title'>Pain Points</p>
              <div className='d-flex flex-wrap'>
                {this._renderPainPointsCheckboxes()}
                {this._renderOtherValsInArray(
                  'Pain Points',
                  'painPoints',
                  'pain_points'
                )}
              </div>
              <div
                style={{
                  height: 2,
                  width: '100%',
                  background: '#5F6F7C',
                  margin: '18px 0',
                }}
              />
              <p className='section-title'>Programs Offered</p>
              <div className='d-flex flex-wrap'>
                {this._renderProgramsCheckboxes()}
                {this._renderOtherValsInArray(
                  'Programs',
                  'programs',
                  'programs'
                )}
              </div>
              <div
                style={{
                  height: 2,
                  width: '100%',
                  background: '#5F6F7C',
                  margin: '18px 0',
                }}
              />
              <p className='section-title'>Facility Amenities</p>
              <div className='d-flex flex-wrap'>
                {this._renderAmenitiesCheckboxes()}
              </div>
            </div>
            <div className='modal-side-content'>
              <div
                className='upgrade-container'
                aria-hidden='true'
                onClick={
                  this.state.status === 'upgraded'
                    ? () => false
                    : () => this.setState({ upgrade: true })
                }
              >
                <i className='fa fa-line-chart' aria-hidden='true' />
                <p className='upgrade-btn'>Upgrade!</p>
              </div>
              {this.state.repChange ? (
                <div className='rep-change-box'>
                  <i
                    className='fa fa-times'
                    aria-hidden='true'
                    onClick={() =>
                      this.setState({
                        repChange: false,
                        selectedNewSalesRep: null,
                      })
                    }
                  />
                  <p className='box-title'>Change Sales Rep</p>
                  {this.props.represetativeLoader ? (
                    <div className='f-flex flex-column align-items-center'>
                      <i className='fa fa-spinner fa-spin' aria-hidden='true' />
                      <p className='loader-text'>loading sales reps</p>
                    </div>
                  ) : (
                    <div>
                      {this.props.representatives.map((r, i) => (
                        <p
                          key={i}
                          className={`${
                            r.id === sales_rep.id ? 'inactive' : 'selectable'
                          } ${
                            this.state.selectedNewSalesRep &&
                            this.state.selectedNewSalesRep.id === r.id &&
                            'selected'
                          }`}
                          aria-hidden='true'
                          onClick={
                            r.id === sales_rep.id
                              ? () => false
                              : () => this.setState({ selectedNewSalesRep: r })
                          }
                        >
                          {`${r.first_name} ${r.last_name}`}
                        </p>
                      ))}
                      {this.state.selectedNewSalesRep && (
                        <div className='button-box'>
                          <button
                            className='cancel-button'
                            onClick={() =>
                              this.setState({
                                repChange: false,
                                selectedNewSalesRep: null,
                              })
                            }
                          >
                            Cancel
                          </button>
                          <button
                            className='confirm-button'
                            onClick={this._changeSalesRep}
                          >
                            Confirm
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <p>
                    Sales Rep:{' '}
                    {`${sales_rep.first_name} ${sales_rep.last_name}`}
                  </p>
                  <div className='d-flex justify-content-center'>
                    <p
                      className='change-btn'
                      aria-hidden='true'
                      onClick={() => this.setState({ repChange: true })}
                    >
                      -change-
                    </p>
                  </div>
                </div>
              )}
              <div className='meta-box d-flex my-3'>
                <div className='meta-row'>
                  <div className='d-flex align-items-center mb-2'>
                    <div
                      className='status-dot'
                      style={{
                        background: this._grabDotColor(
                          'created',
                          coach.created_at
                        ),
                      }}
                    />
                    <p>Created: {this._calcLeadAge(coach.created_at)}</p>
                  </div>
                  <div className='d-flex align-items-center mb-2'>
                    <div
                      className='status-dot'
                      style={{
                        background: this._grabDotColor(
                          'login',
                          coach.last_login
                        ),
                      }}
                    />
                    <p>
                      Last Login: {this._calcLeadAge(login_info.last_login)}
                    </p>
                  </div>
                  <div className='d-flex align-items-center'>
                    <div
                      className='status-dot'
                      style={{
                        background: this._grabDotColor(
                          'courses',
                          coach_academy_courses.length
                        ),
                      }}
                    />
                    <p>Courses: {coach_academy_courses.length}</p>
                  </div>
                </div>
              </div>
              <p className='mb-0'>Coach Academy Courses</p>
              <hr className='mt-0 w-100' />
              {coach_academy_courses.map((c, i) => (
                <div className='class-row mb-1' key={i}>
                  <div
                    className='class-pic'
                    style={{
                      backgroundImage: `url(${
                        c.education.photo && c.education.photo.file_url
                      })`,
                    }}
                  />
                  <div className='class-detail-box'>
                    <p>{c.education.name}</p>
                    <div className='d-flex justify-content-between align-items-center'>
                      <div className='class-progress-outer'>
                        <div
                          className='class-progress-inner'
                          style={{ width: `${this._calcPercentage(c)}%` }}
                        />
                      </div>
                      <p className='percent-text'>{`${this._calcPercentage(
                        c
                      )}% complete`}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </BaseModal>
    )
  }

  _changeSalesRep = () => {
    const { selectedNewSalesRep } = this.state
    const data = {
      target: { name: 'sales_rep_id', value: selectedNewSalesRep.id },
    }
    this.setState({ sales_rep: selectedNewSalesRep }, () => {
      this.setState({ repChange: false, selectedNewSalesRep: null })
      this._sendAPIChange(data)
    })
  }

  _handleUpgradeClick = () => {
    this.setState({ upgrading: true }, () => {
      const { coach, newPermission, selectedCommunity } = this.state
      const { lead, sales_lead_actions } = this.props
      const data = {
        permission: parseInt(newPermission, 10),
        community_id: selectedCommunity.id,
        sales_lead_id: lead.id,
      }
      Axios.post(`/api/v1/users/${coach.id}/upgrade_coach`, data).then(
        (res) => {
          if (res.status === 200) {
            this.setState({ upgradeSuccess: true, upgrading: false }, () => {
              sales_lead_actions.shortUpdateSalesLead(res)
              setTimeout(this._closeEverything, 2000)
            })
          } else {
            this.setState({ upgrading: false })
          }
        }
      )
    })
  }

  _closeEverything = () => {
    this.props.close()
  }

  _handleCommunitySearch = (e) => {
    e.preventDefault()
    const { communitySearch } = this.state
    if (!communitySearch) {
      return false
    }
    this.setState({ communityLoader: true }, () => {
      Axios.get(
        '/api/v1/communities?family_switcher=1&search=' + communitySearch
      )
        .then((res) => {
          this.setState({ communityResults: res.data.communities })
        })
        .catch((_) => this.setState({ communityResults: 'error' }))
        .finally(() => this.setState({ communityLoader: false }))
    })
  }

  _renderCommunityRow = (c) => (
    <tr key={c.id} onClick={() => this.setState({ selectedCommunity: c })}>
      <td>
        <div
          style={{
            backgroundImage: `url(${
              c.photo ? c.photo.file_url : communityPlaceholder
            }), linear-gradient(to right, red,orange,yellow,green,blue,indigo,violet)`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: 70,
            width: 80,
            borderRadius: 4,
          }}
        />
      </td>
      <td>{c.id}</td>
      <td>{c.name}</td>
      <td>{c.description}</td>
      <td>
        {c.is_active ? (
          <i
            className='fa fa-check-circle'
            aria-hidden='true'
            style={{ color: 'green' }}
          />
        ) : (
          <i
            className='fa fa-times-circle'
            aria-hidden='true'
            style={{ color: 'red' }}
          />
        )}
      </td>
      <td>{`${c.community_type}`}</td>
    </tr>
  )

  _grabDotColor = (category, val) => {
    let diff = 0
    const now = moment()
    switch (category) {
      case 'created':
        diff = now.diff(moment(val), 'days')
        if (diff > 120) {
          return '#CC0000' //red
        }
        if (diff > 30) {
          return '#FCD935' // yellow
        }
        return '#12A482' // green
      case 'login':
        diff = now.diff(moment(val), 'days')
        if (diff > 30) {
          return '#CC0000' // red
        }
        if (diff > 15) {
          return '#FCD935' // yellow
        }
        return '#12A482' // green
      case 'courses':
        if (val > 1) {
          return '#12A482' //green
        }
        if (val > 0) {
          return '#FCD935' // yellow
        }
        return '#CC0000' // red
      default:
        return 'black'
    }
  }

  _addToList = (listType, event) => {
    const val = event.target.value
    this.setState(
      (prevState) => ({ [listType]: [...prevState[listType], val] }),
      () => {
        this._sendAPIChange({
          target: { name: listType, value: this.state[listType] },
        })
      }
    )
  }

  _removeFromList = (listType, val) => {
    const list = this.state[listType].filter((item) => item !== val)
    return this.setState({ [listType]: list }, () => {
      this._sendAPIChange({
        target: { name: listType, value: this.state[listType] },
      })
    })
  }

  _handleInputChange = (e) => {
    const name = e.target.name
    const val = e.target.value
    this.setState({ [name]: val })
  }

  _sendAPIChange = (e) => {
    const name = e.target.name
    const val = e.target.value
    const data = this._formatData(name, val)
    this.props.sales_lead_actions.updateSalesLead(this.props.lead.id, data)
  }

  _saveNotes = (_) => {
    const note = this.state.notes
    const data = { notes: note }
    this.props.sales_lead_actions.updateSalesLead(this.props.lead.id, data)
  }

  _formatData = (name, val) => {
    let key = name
    switch (name) {
      case 'communityName':
        key = 'community_name'
        break
      case 'communityType':
        key = 'community_type'
        break
      case 'seasonLength':
        key = 'season_length'
        break
      case 'title':
        key = 'job_title'
        break
      case 'signupReasons':
        key = 'sign_up_reasons'
        break
      case 'painPoints':
        key = 'pain_points'
        break
      default:
        break
    }
    if (key) {
      return { [key]: val }
    }
  }

  _renderOtherValsInArray = (title, stateKey, questionId) => {
    const arr = this.state[stateKey]
    const outerQuestion = onboardingQuestions.find((q) => q.id === questionId)
    const innerQuestion = outerQuestion.questions.find(
      (q) => q.id === questionId
    )
    const answers = innerQuestion.choices
    const other = arr.filter((e) => !answers.includes(e))
    if (other.length < 1) { return false }
    return (
      <div className='w-100'>
        <p className='other-title'>{`Other ${title}`}</p>
        {other.map((o, i) => (
          <p key={i} className='ml-3'>
            {o}
          </p>
        ))}
      </div>
    )
  }

  _renderProgramsCheckboxes = () => {
    const { programs } = this.state
    const outerQuestion = onboardingQuestions.find((q) => q.id === 'programs')
    const innerQuestion = outerQuestion.questions.find(
      (q) => q.id === 'programs'
    )
    const answers = innerQuestion.choices.map((a, i) => (
      <div className='checkbox-container' key={i}>
        <input
          type='checkbox'
          name={a}
          id={a}
          checked={programs.includes(a)}
          onChange={(e) => this._handleCheckbox('programs', e)}
        />
        <label htmlFor={a}>{a}</label>
      </div>
    ))
    return answers
  }

  _renderPainPointsCheckboxes = () => {
    const { painPoints } = this.state
    const outerQuestion = onboardingQuestions.find((q) => q.id === 'pain_points')
    const innerQuestion = outerQuestion.questions.find(
      (q) => q.id === 'pain_points'
    )
    const answers = innerQuestion.choices.map((a, i) => (
      <div className='checkbox-container' key={i}>
        <input
          type='checkbox'
          name={a}
          id={a}
          checked={painPoints.includes(a)}
          onChange={(e) => this._handleCheckbox('painPoints', e)}
        />
        <label htmlFor={a}>{a}</label>
      </div>
    ))
    return answers
  }

  _renderReasonsForSignup = () => {
    const { signupReasons } = this.state
    const question = registrationQuestions.find(
      (q) => q.id === 'sign_up_reasons'
    )
    const answers = question.ans_choices.slice(0, -1).map((a, i) => (
      <div className='checkbox-container' key={i}>
        <input
          type='checkbox'
          name={a}
          id={a}
          checked={signupReasons.includes(a)}
          onChange={(e) => this._handleCheckbox('signupReasons', e)}
        />
        <label htmlFor={a}>{a}</label>
      </div>
    ))
    return answers
  }

  _renderAmenitiesCheckboxes = () => {
    const { amenities } = this.state
    const answers = [
      '9 Hole Facility',
      '18 Hole Facility',
      '27+ Hole Facility',
      'Executive (Par 3)',
      'Driving Range',
      'Practice Putting Green',
      'Practice Chipping/Pitching Area',
      'Practice Bunker Area',
      'Indoor Simulator',
      'Indoor Hitting Bay',
      'Indoor Putting Green',
      'Launch Monitor',
    ]
    return answers.map((a, i) => (
      <div className='checkbox-container' key={i}>
        <input
          type='checkbox'
          name={a}
          id={a}
          checked={amenities.includes(a)}
          onChange={(e) => this._handleCheckbox('amenities', e)}
        />
        <label htmlFor={a}>{a}</label>
      </div>
    ))
  }

  _handleCheckbox = (type, event) => {
    const val = event.target.name
    const isPresent = this.state[type].includes(val)
    if (isPresent) {
      return this.setState(
        (prevState) => ({ [type]: prevState[type].filter((item) => item !== val) }),
        () =>
          this._sendAPIChange({
            target: { name: type, value: this.state[type] },
          })
      )
    }
    return this.setState(
      (prevState) => ({ [type]: [...prevState[type], val] }),
      () =>
        this._sendAPIChange({ target: { name: type, value: this.state[type] } })
    )
  }

  _handleDropdownChange = (type, event) => {
    const val = event.target.value
    this.setState({ [type]: val }, () => {
      this._sendAPIChange({ target: { name: type, value: val } })
    })
  }

  _renderAffiliationChoices = () => {
    const { affiliations } = this.state
    const outerQuestion = onboardingQuestions.find(
      (q) => q.id === 'affiliations'
    )
    const innerQuestion = outerQuestion.questions.find(
      (q) => q.id === 'affiliations'
    )
    const answers = innerQuestion.choices
      .filter((a) => !affiliations.includes(a))
      .map((a, i) => (
        <option value={a} key={i}>
          {a}
        </option>
      ))
    return answers
  }

  _renderInterestChoices = () => {
    const { interests } = this.state
    return ['Juniors', 'Seniors', 'Ladies', 'Men', 'Elite']
      .filter((a) => !interests.includes(a))
      .map((a, i) => (
        <option value={a} key={i}>
          {a}
        </option>
      ))
  }

  _renderSeasonLength = () => {
    const outerQuestion = onboardingQuestions.find(
      (q) => q.id === 'facility_about'
    )
    const innerQuestion = outerQuestion.questions.find(
      (q) => q.id === 'season_length'
    )
    const answers = innerQuestion.choices.map((a, i) => (
      <option value={a} key={i}>
        {a}
      </option>
    ))
    const def = (
      <option key={-1} value={-1} disabled>
        Season Length
      </option>
    )
    return (
      <select
        value={this.state.seasonLength}
        onChange={(e) => this._handleDropdownChange('seasonLength', e)}
        className='hover-border'
      >
        {[def, ...answers]}
      </select>
    )
  }

  _renderCommunityType = () => {
    const outerQuestion = onboardingQuestions.find(
      (q) => q.id === 'facility_about'
    )
    const innerQuestion = outerQuestion.questions.find(
      (q) => q.id === 'community_type'
    )
    const answers = innerQuestion.choices
    const answerTags = innerQuestion.choices.map((a, i) => (
      <option value={a} key={i}>
        {a}
      </option>
    ))
    const def = (
      <option key={-1} value={-1} disabled>
        Community Type
      </option>
    )
    return (
      <select
        value={this._checkForOther(this.state.communityType, answers)}
        onChange={(e) => this._handleDropdownChange('communityType', e)}
        className='hover-border'
      >
        {[def, ...answerTags]}
      </select>
    )
  }

  _renderOtherCommunityType = () => {
    const { communityType } = this.state
    const outerQuestion = onboardingQuestions.find(
      (q) => q.id === 'facility_about'
    )
    const innerQuestion = outerQuestion.questions.find(
      (q) => q.id === 'community_type'
    )
    const answers = innerQuestion.choices
    if (
      this._checkForOther(communityType, answers) === -1 &&
      communityType !== -1
    ) {
      return (
        <div className='w-100 other-community-type'>
          <p className='other-title'>Other:</p>
          <p>{communityType}</p>
        </div>
      )
    }
  }

  _checkForOther = (val, choices) => choices.includes(val) ? val : -1

  _renderStatus = () => {
    const { status } = this.state
    return (
      <select
        className='hover-border inline-select hours-select'
        value={status}
        onChange={(e) => this._handleDropdownChange('status', e)}
        disabled={status === 'upgraded'}
      >
        <option value={'new'} disabled>
          new
        </option>
        <option value={'attempted contact'}>attempted contact</option>
        <option value={'contacted/working'}>contacted/working</option>
        <option value={'demo booked'}>demo booked</option>
        <option value={'demo performed'}>demo performed</option>
        <option value={'not interested'}>not interested</option>
        <option value={'upgraded'} disabled>
          upgraded
        </option>
      </select>
    )
  }

  _renderCoachingHours = () => {
    const outerQuestion = onboardingQuestions.find((q) => q.id === 'hours')
    const innerQuestion = outerQuestion.questions.find((q) => q.id === 'hours')
    const answerOptions = innerQuestion.choices.map((a, i) => (
      <option value={a} key={i}>
        {a}
      </option>
    ))
    const def = (
      <option value={-1} key={-1} disabled>
        - Hours
      </option>
    )
    return (
      <select
        value={this.state.hours}
        onChange={(e) => this._handleDropdownChange('hours', e)}
        className='hover-border inline-select hours-select'
      >
        {[def, ...answerOptions]}
      </select>
    )
  }

  _renderTitle = () => {
    const titleQuestion = registrationQuestions.find((q) => q.id === 'job_title')
    const titleOptions = titleQuestion.ans_choices.map((a, i) => (
      <option value={a} key={i}>
        {a}
      </option>
    ))
    const def = (
      <option value={-1} key={-1} disabled>
        Coach Title
      </option>
    )
    return (
      <select
        value={this.state.title}
        onChange={(e) => this._handleDropdownChange('title', e)}
        className='hover-border title-select inline-select'
      >
        {[def, ...titleOptions]}
      </select>
    )
  }

  _calcPercentage = (course) => {
    const completed = course.completed_lessons.length
    let total = 0
    course.education.education_sections.forEach((section) => {
      total += section.education_lessons.length
    })
    const percent = (completed / total) * 100
    return Math.round(percent)
  }

  _calcLeadAge = (date) => {
    if (!date) {
      return '----'
    }
    const now = moment()
    let time = now.diff(moment(date), 'days')
    let unit = `day${time !== 1 ? 's' : ''}`
    if (time < 1) {
      time = now.diff(moment(date), 'hours')
      unit = `hour${time !== 1 ? 's' : ''}`
      if (time < 1) {
        time = now.diff(moment(date), 'minutes')
        unit = `minute${time !== 1 ? 's' : ''}`
        if (time < 1) {
          return 'just now'
        }
      }
    }
    return `${time} ${unit} ago`
  }
}

SalesLeadDetailModal.propTypes = {
  lead: PropTypes.shape({
    id: PropTypes.number,
  }),
  sales_lead_actions: PropTypes.shape({
    shortUpdateSalesLead: PropTypes.func,
    updateSalesLead: PropTypes.func,
  }),
  represetativeLoader: PropTypes.bool,
  representatives: PropTypes.array,
  close: PropTypes.func,
}

function mapStateToProps(state, _) {
  return {
    ui: state.ui,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sales_lead_actions: bindActionCreators(salesLeadActions, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SalesLeadDetailModal)
