import * as constants from "./actionTypes";
import * as common from "./commonActions";

import Axios from '../axios'

export function successRequestingResourceCategories(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_RESOURCE_CATEGORIES,
      resource_categories: response.data.resource_categories
    };
  } else {
    console.log("ERROR ON GETTING RESOURCE CATEGORIES");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successRequestingCreateResourceCategory(response) {
  if (response.status === 201) {
    return {
      type: constants.CREATE_RESOURCE_CATEGORY,
      resource_category: response.data.resource_category
    };
  } else {
    console.log("ERROR ON CREATING RESOURCE SECTION");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successReorderResourcesInCategory(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_RESOURCE_SECTION,
      resource_section: response.data.resource_section,
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

// THUNKS
export function getResourceCategories(section_id) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true));
    return requestResourceCategories(section_id)
      .then(response => dispatch(successRequestingResourceCategories(response)))
      .then(repsonse => dispatch(common.loadingChanged(false)));
  };
}

export function createResourceCategory(resource_category) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true));
    return requestCreateResourceCategory(resource_category)
      .then(response =>
        dispatch(successRequestingCreateResourceCategory(response))
      )
      .then(response => dispatch(common.loadingChanged(false)));
  };
}

export function reorderResourcesInCategory(category_id, resources) {
  return function (dispatch) {
    return requestReorderResourcesInCategory(category_id, resources).then(
      (response) => dispatch(successReorderResourcesInCategory(response))
    );
  };
}

// API CALLS

function requestResourceCategories(section_id) {
  let requestUrl =
    "/api/v1/resource_sections/" + section_id + "/resource_categories";
  return Axios.get(requestUrl);
}

function requestCreateResourceCategory(resource_category) {
  let requestUrl = "/api/v1/resource_categories/";

  var data = new FormData();

  data.append("name", resource_category.name);
  data.append("description", resource_category.description);
  data.append("resource_section_id", resource_category.resource_section_id);

  return Axios.post(requestUrl, data);
}

function requestReorderResourcesInCategory(category_id, resources) {
  let requestUrl = `api/v1/resource_categories/${category_id}/reorder_resources`;
  let data = new FormData();

  resources.forEach((r) => {
    data.append(`resource_orders[${r.id}]`, r.order);
  });
  return Axios.post(requestUrl, data);
}
