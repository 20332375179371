import _ from 'lodash'

export const serialize = (obj) => transform(obj, _.snakeCase)
export const deserialize = (obj) => transform(obj, _.camelCase)

const isAlphaNumeric = (code) => !(!(code > 47 && code < 58) && // numeric (0-9)
    !(code > 64 && code < 91) && // upper alpha (A-Z)
    !(code > 96 && code < 123))

const transform = (obj, transform_fn) => {
  if (!obj) {
    return obj
  }

  if (Array.isArray(obj)) {
    return obj.map((obj) => transform(obj, transform_fn))
  }

  if (typeof obj === 'object') {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      let transformedKey = transform_fn(key)

      // The lodash serialization methods remove everything that is not alphanumeric from the resulting string
      // For now, there are cases when only the first char is needed after serialization
      if (!isAlphaNumeric(key.charCodeAt(0))) {
        transformedKey = key.charAt(0) + transformedKey
      }

      acc[transformedKey] = (value instanceof Date || value instanceof File)
        ? value
        : transform(value, transform_fn)
      return acc
    }, {})
  }

  return obj
}
