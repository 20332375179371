import './ErrorPage.scss'
import SubmitButton from './buttons/SubmitButton'
import PropTypes from 'prop-types'
import logo from '../../assets/images/logos/full-brand-logo.svg'

const ErrorPage = ({ removeError }) => {

  const handleClick = () => {
    removeError()
    window.location.reload()
  }

  return (
    <div id='ErrorPage'>
      <div className='header'>
        <img src={logo} alt='Op36 Logo' className='op36-logo'/>
      </div>
      <div className='main-content'>
        <i className='fa fa-exclamation-triangle error-icon'/>
        <p className='error-title'>Something went wrong</p>
        <p className='error-text'>We apologize for the inconvenience, it seems like an error has occured.</p>
        <div className='error-message-box'>
          <p className='error-primary-text'>Are you reaching this error and need some help?</p>
          <p className='error-secondary-text'>Email: support@op36golf.com</p>
        </div>
        <div className='main-button'>
          <SubmitButton
            onClick={handleClick}
            buttonMessage={'Refresh'}
          />
        </div>
      </div>
    </div>
  )
}

ErrorPage.propTypes = {
  removeError: PropTypes.func,
}

export default ErrorPage
