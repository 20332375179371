import React from "react";
import "./InvitationCard.scss";

const InvitationCard = ({ invitation, toggleConfirmation }) => (
  <div id="InvitationCard" className="card mb-3 p-3">
    <button
      className="btn btn-link invitation-delete-button"
      onClick={() =>
        toggleConfirmation(
          `Are you sure you want to delete the ${invitation.first_name} ${
            invitation.last_name
          } family invitation?`,
          invitation,
          "delete"
        )
      }
    >
      <i className="fa fa-trash-o" />
    </button>
    <h6 className="invitation-name">
      {invitation.first_name} {invitation.last_name}
    </h6>
    <p className="invitation-email">{invitation.email}</p>
    <button
      className="btn btn-link invitation-button"
      onClick={() =>
        toggleConfirmation(
          `Are you sure you want to send a reminder to ${
            invitation.first_name
          } ${invitation.last_name}?`,
          invitation,
          "reminder"
        )
      }
    >
      Send Reminder
    </button>
  </div>
);

export default InvitationCard;
