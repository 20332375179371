import Modal from 'react-modal'
import PropTypes from 'prop-types'

import { ReactComponent as ModalXIcon } from '../../../assets/images/common/icons/modal-X-icon.svg'
import { ReactComponent as GolferIcon } from '../../../assets/images/common/icons/golfer-half-icon-round.svg'
import SubmitButton from '../../common/buttons/SubmitButton'

import styles from './SaveModal.module.scss'
import classNames from 'classnames'

const SaveModal = ({ showModal, closeModal, publish, draft, title, message }) =>
  <Modal
    isOpen={showModal}
    onRequestClose={closeModal}
    contentLabel='Program Builder Save Modal'
    className={styles['save-modal-content']}
    autoFocus
  >
    <div id='SaveModal' className='animated fadeIn'>
      <div className='d-flex justify-content-end text-align-right'>
        <ModalXIcon className={styles['modal-x-icon']} onClick={closeModal} />
      </div>
      <div className='d-flex flex-column justify-content-center align-items-center'>
        <GolferIcon className={styles['golfer-icon']} />
        <p className={styles['title']}>{title}</p>
        <p className={classNames('text-center', styles['message'])}>
          {message}
        </p>
      </div>
      <div className='row py-3'>
        <div className='col-12'>
          <SubmitButton
            onClick={publish}
            buttonMessage={title}
            buttonColor='orange-button'
          />
        </div>
        <div className='col-12'>
          <SubmitButton
            onClick={draft}
            buttonMessage='Save as Draft'
            buttonColor='navy-button'
          />
        </div>
      </div>
    </div>
  </Modal>

SaveModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  publish: PropTypes.func.isRequired,
  draft: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
}

export default SaveModal
