import React from "react";

import { Map, Marker } from "google-maps-react";
import devLeague_pin from "../../assets/images/pins/devLeague_pin.png";
import affiliate_pin from "../../assets/images/pins/affiliate_pin.png";
import certified_pin from '../../assets/images/pins/certified_pin.svg'
import top50_pin from '../../assets/images/pins/top50_pin.svg'

import Loader from "../common/Op36Loader-web";
import { withOutletProps } from '../common/hooks';

const mapStyles = {
  width: "100%",
  height: "100%",
};

export class CommunityMap extends React.Component {
  state;
  render() {
    const {
      loader,
      geo_location,
      communities,
      openNoCommunityModal,
    } = this.props;

    return loader ? (
      <div className='location-permission-container'>
        <Loader />
      </div>
    ) : (
      <div className='map-outer-container'>
        <div className='map-container'>
          <Map
            google={window.google}
            zoom={this.props.isDefaultLocation ? 2 : 9}
            mapTypeControl={false}
            fullscreenControl={false}
            streetViewControl={false}
            style={mapStyles}
            initialCenter={geo_location}
          >
            {this._renderMarkers(communities)}
            {!this.props.isDefaultLocation && (
              <Marker position={geo_location} />
            )}
          </Map>
        </div>
        <div className='map-bottom-bar'>
          <p className='map-bottom-bar-text'>
            No location near you?{" "}
            <span className='extra-text'>
              Want to recommend a facility you think would be a good fit for the
              program?{" "}
            </span>
            <span
              className='map-bottom-bar-link'
              onClick={() => openNoCommunityModal()}
            >
              Click Here
            </span>{" "}
            to let us know.
          </p>
        </div>
      </div>
    );
  }

  _grabPin = (comm) => {
    if (comm.is_top50) {
      return {
        scaledSize: new window.google.maps.Size(75, 72),
        url: top50_pin,
      };
    }
    if (comm.is_certified) {
      return {
        scaledSize: new window.google.maps.Size(75, 72),
        url: certified_pin,
      };
    }
    if (comm.level === "Academy") {
      return {
        scaledSize: new window.google.maps.Size(75, 72),
        url: affiliate_pin,
      };
    }
    return {
      scaledSize: new window.google.maps.Size(61, 64),
      url: devLeague_pin,
    };
  };

  _renderMarkers = (communities) => {
    const { filters, handleCommunityClick } = this.props;

    if (this.props.filters.length === 0) {
      return communities.map((comm, index) => (
        <Marker
          key={index}
          name={comm.name}
          icon={this._grabPin(comm)}
          title={comm.name}
          position={comm.coordinates}
          onClick={() => handleCommunityClick(comm.id)}
        />
      ));
    } else {
      let rendered_communities = [];

      if (filters.indexOf("top50") > -1) {
        communities
          .filter((c) => c.is_top50)
          .forEach((comm, index) => {
            rendered_communities.push(
              <Marker
                key={`50-${index}`}
                name={comm.name}
                icon={this._grabPin(comm)}
                title={comm.name}
                position={comm.coordinates}
                onClick={() => handleCommunityClick(comm.id)}
              />
            );
          });
      }
      if (filters.indexOf("certified") > -1) {
        // if (filters.indexOf('top50') > -1) {
        //   communities
        //     .filter(c => c.is_certified && !c.is_top50)
        //     .map((comm, index) => {
        //       rendered_communities.push(
        //         <Marker
        //           key={index}
        //           name={comm.name}
        //           icon={this._grabPin(comm)}
        //           title={comm.name}
        //           position={comm.coordinates}
        //           onClick={() => handleCommunityClick(comm.id)}
        //         />
        //       );
        //     });
        // } else {
        communities
          .filter((c) => c.is_certified)
          .forEach((comm, index) => {
            rendered_communities.push(
              <Marker
                key={`cert-${index}`}
                name={comm.name}
                icon={this._grabPin(comm)}
                title={comm.name}
                position={comm.coordinates}
                onClick={() => handleCommunityClick(comm.id)}
              />
            );
          });
        // }
      }

      if (filters.indexOf("academy") > -1) {
        communities
          .filter((c) => c.level === "Academy")
          .forEach((comm, index) => {
            rendered_communities.push(
              <Marker
                key={`academy-${index}`}
                name={comm.name}
                icon={this._grabPin(comm)}
                title={comm.name}
                position={comm.coordinates}
                onClick={() => handleCommunityClick(comm.id)}
              />
            );
          });
      }

      return [...new Set(rendered_communities)];
    }
  };
}

export default CommunityMap
