import * as types from "../actions/actionTypes";

// state = [] : Start out with no accounts
export default function teamActivity(state = [], action) {
  switch (action.type) {
    case types.GET_TEAM_ACTIVITY:
      return action.team_activity;

    default:
      return state;
  }
}
