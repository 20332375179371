import PropTypes from 'prop-types'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import { formatDates } from '../../../../utility/datesHelper'
import { displayWithCurrency } from '../../../../utility/currency'

import SummaryDropDown from './SummaryDropDown'
import RegistrationStepsProgressBar from './RegistrationStepsProgressBar'

import './SummaryPanel.scss'
import CheckCircleWhite from '../../../../assets/images/common/icons/check-circle-white.svg'
import Logo from '../../../../assets/images/logos/full-brand-logo.svg'
import CommunityAvatarPlaceholder from '../../../../assets/images/mock/classRowBanner.png'
import AvatarPlaceholder from '../../../../assets/images/mock/StockProfilePic.png'
import colors from '../../../../assets/styles/globals.scss'

const SummaryPanel = ({ isEmbedded }) => {
  const course = useSelector(state => state.programPresentation.course)
  const {
    status,
    currentStep,
    steps,
    completedSteps,
    selectedPaymentMethod,
  } = useSelector(state => state.programRegistration)

  return (
    <Container fluid id='SummaryPanel' className='px-lg-0 py-lg-4'>
      <Row className='pl-md-5 d-lg-none summary-step-details'>
        <Col md={12} className='d-flex align-items-center justify-content-start'>
          {status === 'finished' ? (
            <h4 className='d-flex align-items-center mb-0 post-registration-title'>
              <img src={CheckCircleWhite} alt='check circle' className='post-registration-check-icon mr-2' />&nbsp;
              {selectedPaymentMethod === 'cash' ? 'Enrollment request sent!' : 'Successfully Registered!'}
            </h4>
          ) : (
            <h4 className='mb-0'>Step {currentStep + 1}: {steps[currentStep]}</h4>
          )}
        </Col>
      </Row>
      {status === 'finished' && (
        <Row className='d-lg-none'>
          <SummaryDropDown isEmbedded={isEmbedded}>
            <ProgramDetails />
            <RegistrationDetails />
          </SummaryDropDown>
        </Row>
      )}
      <div className='h-100 d-none d-lg-flex flex-column justify-content-between'>
        <div className='d-flex flex-column justify-content-between registration-details-wrapper'>
          <a href='https://operation36.golf' target='_blank' className='mb-3 px-3'>
            <img src={Logo} alt='operation 36 logo' width={200} />
          </a>
          <Link to={`/landing/${course?.community?.id}`} className='d-flex align-items-center py-2 px-3'>
            <div className='medium-round-image-wrapper white-border'>
              <img
                className='round-image'
                src={course?.community?.photo ? course.community.photo.fileUrl : CommunityAvatarPlaceholder}
                alt='community'
              />
            </div>
            <h5
              className='mb-0 ml-2 community-name'
              title={course?.community?.name}
            >
              {course?.community?.name}
            </h5>
          </Link>
          <ProgramDetails />
        </div>
        {completedSteps.includes(0) && (
          <>
            <div className='registration-summary-separator' />
            <RegistrationDetails />
          </>
        )}
        <div className='px-3'>
          <RegistrationStepsProgressBar />
        </div>
      </div>
    </Container>
  )
}

SummaryPanel.propTypes = {
  isEmbedded: PropTypes.bool,
}

const ProgramDetails = () => {
  const course = useSelector(state => state.programPresentation.course)

  return (
    <div className='registration-program-details'>
      <h2 className='program-title' title={course?.name}>{course?.name}</h2>
      <p className='program-dates'>{formatDates(course?.startDate, course?.endDate, course?.community?.timezone)}</p>
      <p className='program-meeting-times'>{course?.meetingTimes}</p>
    </div>
  )
}

const RegistrationDetails = () => {
  const course = useSelector(state => state.programPresentation.course)
  const {
    selectedFamilyMember,
    selectedPackage,
    selectedPaymentMethod,
  } = useSelector(state => state.programRegistration)

  return (
    <div className='registration-enrollment-details'>
      <div className='d-flex align-items-center selected-family-member'>
        <div className='medium-round-image-wrapper'>
          <img
            className='round-image'
            src={selectedFamilyMember?.avatar?.fileUrl ? selectedFamilyMember.avatar.fileUrl : AvatarPlaceholder}
            alt='selected family member'
          />
        </div>
        <span
          className='ml-2 selected-family-member-name'
          title={`${selectedFamilyMember?.firstName} ${selectedFamilyMember?.lastName}`}
        >
          {`${selectedFamilyMember?.firstName} ${selectedFamilyMember?.lastName}`}
        </span>
      </div>
      <p
        style={{ color: selectedPaymentMethod === 'card' ? colors.darkTeal : colors.primaryOrange }}
      >
        {selectedPaymentMethod === 'card' ? 'Paid Online' : 'Paid Offline'}
      </p>
      {!_.isEmpty(selectedPackage) && (
        <>
          <p className='selected-package-name'>{selectedPackage.name}</p>
          <p className='selected-package-price'>{displayWithCurrency(selectedPackage.price, course?.community?.currency)}</p>
        </>
      )}
    </div>
  )
}

export default SummaryPanel
