import React, { Component } from "react";
import { Popover } from "reactstrap";
import { abbrNum } from "../../utility/helperFunctions";

// ASSETS
import "./Claps.scss";
import ActiveClap from "../../assets/images/op36/clap_secondary.png";

class Claps extends Component {
  state = {
    clapHoverTriggered: false
  };

  clapHoverEnter = () => [
    this.setState({
      clapHoverTriggered: true
    })
  ];

  clapHoverLeave = () => {
    this.setState({
      clapHoverTriggered: false
    });
  };

  renderLikes = () => {
    const { likes, currentUser } = this.props;

    if (likes && likes.length > 0) {
      return likes.map(like => (
        <p className='like-username' key={like.id}>
          {currentUser.id === like.user.id
            ? "You"
            : `${like.user.first_name} ${like.user.last_name}`}
        </p>
      ));
    }
  };

  render() {
    const { activity } = this.props;
    const { clapHoverTriggered } = this.state;
    return (
      <div
        id='Claps'
        onMouseEnter={this.clapHoverEnter}
        onMouseLeave={this.clapHoverLeave}
      >
        <div className='clap-wrapper'>
          <img src={ActiveClap} alt='Activity Clap' />
        </div>

        <p>{abbrNum(activity.like_count, 1)}</p>
        {activity.like_count > 0 && (
          <Popover
            placement='bottom'
            isOpen={clapHoverTriggered}
            target={"Claps"}
            toggle={this.clapHoverEnter}
            className='clap-popover'
          >
            <div className='clap-popover-container'>
              {this.renderLikes()}
              {activity.like_count > 10 && (
                <p>+ {activity.like_count - 10} more</p>
              )}
            </div>
          </Popover>
        )}
      </div>
    );
  }
}

export default Claps;
