import React, { Component } from 'react'

import PropTypes from 'prop-types'
import DrillActivityCardPopover from './DrillActivityCardPopover'

import StockProfilePic from '../../../assets/images/mock/StockProfilePic.png'
// import logo from "../../../assets/images/logos/OP36_clean_icon_dark.png";
import fav_icon from '../../../assets/images/common/icons/drills/favorite-icon.png'
import more_icon from '../../../assets/images/common/icons/drills/more-icon-vertical.png'

import './DrillActivityCard.scss'

// Based off of DrillPreviewCard from the LPB - we will need to update
// DrillPreviewCard designs when done
class DrillActivityCard extends Component {
  state = {
    hovering: false,
    popoverOpen: false,
  }

  render() {
    const { popoverOpen } = this.state
    const { item, publicDatabaseView, handleStarClick, onMain } = this.props

    // If in public database, need to show author and modify thumbnail corners
    // If user's profile pic is null, replace with StockProfilePic
    // If in public database and new, need to show New tag
    let author = null
    let imgCorners = '8px 8px 8px 8px'
    let hoverIcons = [
      <img
        key='fav'
        src={fav_icon}
        alt='favorite button'
        aria-hidden='true'
        onClick={e => handleStarClick(e, item.id, item.is_pinned)}
      />,
      <img
        key='more'
        src={more_icon}
        alt='more button'
        aria-hidden='true'
        onClick={e => this._triggerPopover(e)}
      />,
    ]

    if (publicDatabaseView) {
      let authorPic = StockProfilePic
      if (item.author && item.author.avatar) {
        authorPic = item.author.avatar.file_url
      }

      author = (
        <div className='drill-activity-card-author'>
          <img
            src={authorPic}
            alt={
              'Avatar of ' +
              item.author.first_name +
              ' ' +
              item.author.last_name
            }
          />
          <p>{item.author.first_name + ' ' + item.author.last_name}</p>
        </div>
      )
      imgCorners = '0 0 0 8px'

      hoverIcons = (
        <img
          src={fav_icon}
          alt='favorite button'
          aria-hidden='true'
          onClick={e => handleStarClick(e, item.id)}
        />
      )
    }

    // If "drill", show "Drill" and make tag blue; else, show "Coaching Activity"
    // and make tag orange
    let type = {
      name: 'Coaching Activity',
      color: '#ff6633',
    }
    if (item.type === 'drill') {
      type = {
        name: 'Drill',
        color: '#34ace0',
      }
    }

    return (
      <div
        className={`card drill-activity-card ${
          onMain ? 'drill-activity-card-50' : 'drill-activity-card-100'
        }`}
        onMouseEnter={this._startHover}
        onMouseOver={this.state.hovering ? (() => {}) : this._startHover}
        onFocus={this.state.hovering ? (() => {}) : this._startHover}
        onMouseLeave={this._endHover}
        aria-hidden='true'
        onClick={() => this.props.onClick(item.id)}
      >
        <div className='drill-activity-card-hover-overlay'>
          {popoverOpen && (
            <DrillActivityCardPopover
              type={item.type}
              close={this._closePopover}
              edit={this.props.handleEdit}
              handleDelete={this.props.handleDelete}
              drill_id={item.id}
            />
          )}
          {hoverIcons}
        </div>
        {author}
        <div className='drill-activity-card-details'>
          <img
            src={item.photo.file_url}
            alt={'Photo of ' + item.name}
            style={
              item.photo.placeholder
                ? {
                  objectFit: 'scale-down',
                  backgroundColor: `${
                    item.type === 'drill' ? '#34ACE0' : '#ff6633'
                  }`,
                  borderRadius: imgCorners,
                  padding: '15px',
                }
                : { borderRadius: imgCorners }
            }
          />
          <div className='drill-activity-card-details-text'>
            <p className='drill-card-name'>{item.name}</p>
            <div className='drill-activity-tags-container'>
              {item.tags.map((tag, index) => {
                if (index < 1) {
                  return (
                    <p key={index} className='drill-card-tag'>
                      {tag.name}
                    </p>
                  )
                } else if (index === 1) {
                  const end = item.tags.length - 1 === 1 ? 'tag' : 'tags'
                  return (
                    <p key={index} className='drill-card-tag-more'>{`+${item
                      .tags.length - 1} ${end}`}</p>
                  )
                } else {
                  return null
                }
              })}
              {item.tags.length < 1 ? (
                <p className='drill-card-no-tag-indicator'>(No tags)</p>
              ) : null}
            </div>
            <div className='drill-activity-type-container'>
              <div
                className='drill-activity-type-dot'
                style={{ backgroundColor: type.color }}
              />
              <p style={{ color: type.color, whiteSpace: 'nowrap' }}>
                {type.name}
              </p>
            </div>
          </div>
          {item.is_favorited ? (
            <i className='fa fa-star' aria-hidden='true' />
          ) : (
            <i className='fa fa-play-circle-o' aria-hidden='true' />
          )}
        </div>
      </div>
    )
  }

  _startHover = () => {
    this.setState({ hovering: true })
  }

  _endHover = () => {
    this.setState({ hovering: false, popoverOpen: false })
  }

  _triggerPopover = e => {
    e.stopPropagation()
    this.setState({ popoverOpen: true })
  }

  _closePopover = () => {
    this.setState({ popoverOpen: false })
  }
}

DrillActivityCard.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    name: PropTypes.string,
    tags: PropTypes.array,
    is_pinned: PropTypes.bool,
    is_favorited: PropTypes.bool,
    author: PropTypes.shape({
      avatar: PropTypes.shape({
        file_url: PropTypes.string,
      }),
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }),
    photo: PropTypes.shape({
      file_url: PropTypes.string,
      placeholder: PropTypes.bool,
    }),
  }),
  publicDatabaseView: PropTypes.bool,
  onClick: PropTypes.func,
  onMain: PropTypes.bool,
  handleStarClick: PropTypes.func,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
}

export default DrillActivityCard
