import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useState } from 'react'
import { displayWithCurrency } from '../../utility/currency'
import { USD_CURRENCY } from '../../misc/Constants'

import MCElement from '../common/MCElement'
import MaterialInfoModal from './MaterialInfoModal'

import styles from './MaterialCard.module.scss'

const MaterialCard = ({
  material,
  classEditor,
  withPrice,
  isSelected,
  handleCourseMaterialsChange,
  className,
}) => {
  const [showInfoModal, setShowInfoModal] = useState(false)

  return (
    <div
      className={classnames(
        'd-flex flex-column',
        styles['material-card'],
        { [className]: !!className }
      )}
    >
      <div className={classnames('d-flex', styles['material-info'])}>
        <img className={styles.photo} src={material.photo.fileUrl} alt='material' />
        <div className={classnames('d-flex flex-column justify-content-between', styles['text-section'])}>
          <div className={classnames('d-flex flex-column align-items-start', styles.title)}>
            <h5 className={classnames('mb-0', styles.name)}>{material.name}</h5>
            {withPrice && (
              <h5 className={classnames('mt-2 mb-0', styles.price)}>{displayWithCurrency(material.price, USD_CURRENCY)}</h5>
            )}
          </div>
          <p className={classnames('mb-0', styles.description)}>{material.description}</p>
        </div>
      </div>
      {classEditor && (
        <>
          <div className={classnames('d-flex justify-content-between', styles.actions)}>
            <div
              className={classnames('d-flex align-items-center', styles['select-action'])}
              onClick={() => handleCourseMaterialsChange(material, isSelected)}
              onKeyDown={(e) => e.key === 'Enter' && handleCourseMaterialsChange(material, isSelected)}
              role='button'
              tabIndex='0'
            >
              <MCElement type='checkbox' isSelected={isSelected}/>
              <div className='ml-2'>Require Participants to order at checkout</div>
            </div>
            <button
              className={classnames('animation-shrink', styles['learn-more-action'])}
              onClick={() => setShowInfoModal(true)}
            >
              Learn more
            </button>
          </div>
          <MaterialInfoModal className={className} showModal={showInfoModal} setShowModal={setShowInfoModal} material={material}/>
        </>
      )}
    </div>
  )
}

MaterialCard.propTypes = {
  material: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    price: PropTypes.number,
    photo: PropTypes.shape({
      fileUrl: PropTypes.string.isRequired,
    }),
  }).isRequired,
  classEditor: PropTypes.bool,
  withPrice: PropTypes.bool,
  handleCourseMaterialsChange: PropTypes.func,
  isSelected: PropTypes.bool,
  className: PropTypes.string,
}

export default MaterialCard
