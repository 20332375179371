import React from "react";
import Avatar from "../../../../assets/images/mock/StockProfilePic.png";
import "./UserCard.scss";

const UserCard = ({ user, handleEdit }) => (
  <div className="col-md-6 ">
    <div id="UserCard" className="card">
      <div className="user-edit-wrapper">
        <div className={`user-type-pill ${user.family_admin && "active"}`}>
          <p className="user-type-text">
            {user.family_admin ? "Admin" : "Golfer"}
          </p>
        </div>
        <button
          className="btn btn-link user-edit-btn"
          onClick={() => handleEdit(user, true)}
        >
          Edit
        </button>
      </div>
      <div className="user-info-wrapper">
        <div className="large-round-image-wrapper">
          <img
            className="round-image"
            src={user.avatar ? "" : Avatar}
            alt={`${user.first_name} ${user.last_name}`}
          />
        </div>
        <h5 className="user-name">
          {user.first_name} {user.last_name}
        </h5>
        <p className="user-username">
          {user.family_admin ? user.email : user.username}
        </p>
      </div>
    </div>
  </div>
);

export default UserCard;
