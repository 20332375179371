import React from "react";
import moment from "moment";
import { Line } from "react-chartjs-2";

import "./PageViewsGraph.scss";

class PageViewsGraph extends React.Component {
  state = {
    day: 0,
    dataArray: [],
    newToday: 0,
    totalViews: 0
  };

  componentDidMount() {
    this.setState({ day: parseInt(moment().format("D"), 10) }, () => {
      this._setDataArray();
    });
  }

  render() {
    const data = {
      labels: this.state.dataArray.map(v => ""),
      datasets: [
        {
          label: "member",
          fill: true,
          lineTension: 0.2,
          backgroundColor: "rgba(14,124,172,0.2)",
          borderColor: "rgba(14,124,172,0.7)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(14,124,172,0.7)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 1,
          pointHoverBackgroundColor: "rgba(14,124,172,0.7)",
          pointHoverBorderColor: "rgba(220,220,220,0.7)",
          pointHoverBorderWidth: 1,
          pointRadius: 1,
          pointHitRadius: 10,
          data: this.state.dataArray
        }
      ]
    };

    return (
      <div id='PageViewsGraph'>
        <div className='today-lead-container'>
          <p className='lead-count'>
            {this.state.newToday === 0 ? "0" : `+${this.state.newToday}`}
          </p>
          <p className='lead-label'>Today</p>
        </div>
        <div className='total-lead-container'>
          <p className='lead-count'>{this.state.totalViews}</p>
          <p className='lead-label'>Total Views</p>
        </div>
        <Line
          data={data}
          options={{
            maintainAspectRatio: false,
            scales: {
              x: {
                ticks: { display: false },
                grid: {
                  display: false,
                  drawBorder: true,
                  borderWidth: 3,
                  borderColor: "#2b3843"
                }
              },
              y: {
                beginAtZero: true,
                ticks: {
                  // max: 100,
                  display: false
                },
                grid: {
                  display: false,
                  drawBorder: true,
                  borderWidth: 3,
                  borderColor: "#2b3843"
                }
              }
            },
            layout: {
              padding: {
                left: 0,
                right: 10,
                top: 3,
                bottom: 0
              }
            },
            plugins: {
              legend: { display: false },
              tooltip: {
                displayColors: false,
                callbacks: {
                  label: (context) => {
                    const month = moment().format('M')
                    const day = context.dataIndex + 1
                    const nr = context.raw

                    return `${month}/${day}: ${nr}`
                  }
                }
              }
            }
          }}
        />
      </div>
    );
  }

  _setDataArray = () => {
    const { day } = this.state;
    const { views } = this.props;

    let dailyCount = [];
    let aggregator = 0;
    let data = [];

    let today = moment.utc().endOf("day");

    for (let i = 1; i <= day; i++) {
      let byDayViews = views.find(view => {
        // return today.diff(moment(view.created_at), "days") === day - i;
        let diff = today.diff(moment.utc(view.created_at), "days");
        return diff === day - i;
      });
      if (byDayViews) {
        dailyCount.push(byDayViews.count);
      } else {
        dailyCount.push(0);
      }
    }
    for (let i = 0; i < dailyCount.length; i++) {
      aggregator += dailyCount[i];
      data.push(aggregator);
    }
    this.setState({
      dataArray: data,
      newToday:
        data.length > 1
          ? data[data.length - 1] - data[data.length - 2]
          : data[data.length - 1],
      totalViews: data[data.length - 1]
    });
  };
}

export default PageViewsGraph;
