import * as types from "../actions/actionTypes";

// state = [] : Start out with no accounts
export default function resources(state = [], action) {
  switch (action.type) {
    case types.GET_RESOURCES:
      return action.resources;

    case types.CREATE_RESOURCE:
      return [...state, Object.assign({}, action.resource)];

    default:
      return state;
  }
}
