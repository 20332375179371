import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import Loader from '../common/Op36Loader-web'
import _ from 'lodash'
import Modal from 'react-modal'
import { deserialize } from '../../utility/serialization'

import '../../assets/styles/components/families/FamilyPage.scss'

import PageHeader from '../common/PageHeader/PageHeader'
import NewCoachForm from './NewCoachForm'
import Placeholder from '../common/Placeholder'
import SearchInput from '../common/SearchInput'

import * as commonActions from '../../actions/commonActions'
import * as coachActions from '../../actions/coachActions'
import * as userActions from '../../actions/userActions'
import * as communitiesActions from '../../actions/communitiesActions'

import certPreview from '../../assets/images/pins/certified_preview_badge.svg'

class CoachesPage extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      showNewCoachModal: false,
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      confirm_password: '',
      community_id: '-1',
      type: 'Coach',
      selectedCoach: null,
      searchTerm: '',
      coachLoader: false,
      coaches: [],
      message: '',
      showSyncCompleteModal: false,
      communityLoader: false,
      errors: {},
    }

    this.openNewCoachModal = this.openNewCoachModal.bind(this)
    this.closeNewCoachModal = this.closeNewCoachModal.bind(this)

    this.onChangeFirstName = this.onChangeFirstName.bind(this)
    this.onChangeLastName = this.onChangeLastName.bind(this)
    this.onChangeEmail = this.onChangeEmail.bind(this)
    this.onChangePassword = this.onChangePassword.bind(this)
    this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this)
    this.onChangeCommunityId = this.onChangeCommunityId.bind(this)

    this.handleSubmitCoach = this.handleSubmitCoach.bind(this)
    this.handleEditCoach = this.handleEditCoach.bind(this)

    this.resetForm = this.resetForm.bind(this)

    this.showErrors = this.showErrors.bind(this)
  }

  componentDidMount() {
    const { common_actions } = this.props
    common_actions.setCurrentTab('Coaches')
    common_actions.showSideBar(true)
    common_actions.showNavBar(true)
  }

  fetchCoachData = () => {
    this.setState({ coachLoader: true }, () => {
      this.setState({ coachLoader: false })
    })
  }

  openNewCoachModal(coach) {
    this.setState({
      showNewCoachModal: true,
      selectedCoach: coach,
      errors: {},
    })
  }

  closeNewCoachModal() {
    this.setState({
      showNewCoachModal: false,
      selectedCoach: null,
    })
  }

  fetchCoaches = () => {
    const { searchTerm } = this.state

    if (searchTerm.length < 3) {
      return
    }

    this.setState({ coachLoader: true })
    this.props.coach_actions.getCoaches(searchTerm).then(() => {
      this.setState({
        coaches: [...this.props.coaches],
        coachLoader: false,
      })
    })
  }

  debouncedFetchCoaches = _.debounce(this.fetchCoaches, 500)

  searchUpdated = (e) => {
    const term = e.target.value

    if (term) {
      this.setState({ searchTerm: term }, () => {
        this.debouncedFetchCoaches()
      })
    } else {
      this.setState({ coaches: [] })
    }
  }

  displayNewCoachModal() {
    const { communityLoader, errors } = this.state
    return (
      <Modal
        isOpen={this.state.showNewCoachModal}
        onRequestClose={this.closeNewCoachModal}
        contentLabel='Example Modal'
      >
        <div className='modal-header row m-0 p-0'>
          <div className='col-11 m-0 p-0'>
            <h5 className='modal-title display-4'>New Coach</h5>
          </div>
          <div className='col-1 m-0 p-0'>
            <button
              type='button'
              className='close pull-right font-200'
              data-dismiss='modal'
              aria-label='Close'
              onClick={this.closeNewCoachModal}
            >
              <span aria-hidden='true'>✕</span>
            </button>
          </div>
        </div>
        <div className='modal-content'>
          <div className='modal-body' id='input-border'>
            <div className='row mb-2 p-2'>
              <div className='col-md-6 mx-auto'>
                <div className='form-group'>
                  <NewCoachForm
                    handleSubmitCoach={this.handleSubmitCoach}
                    handleEditCoach={this.handleEditCoach}
                    communities={this.props.communities}
                    coach={this.state.selectedCoach}
                    communityLoader={communityLoader}
                    closeNewCoachModal={this.closeNewCoachModal}
                    errors={errors}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }

  checkUserAvatar(user) {
    if (user.avatar) {
      return (
        <img
          src={user.avatar.file_url}
          alt={user.first_name}
          className='img-responsive classDetailPage-avatar center-block'
        />
      )
    } else {
      return null
    }
  }

  renderCoachRow(user) {
    let info
    const isAdmin = this.props.user.type === 'Admin'
    if (user.username) {
      info = user.username
    } else if (user.email) {
      info = user.email
    }
    return (
      <tr key={'table-row-' + user.id} className='border-bottom'>
        <td>{this.checkUserAvatar(user)}</td>
        <td>
          {user.is_certified ? (
            <img
              style={{ height: 30, borderRadius: 0 }}
              src={certPreview}
              alt='certified'
            />
          ) : (
            ''
          )}
        </td>
        <td>{user.first_name}</td>
        <td>{user.last_name}</td>
        <td>{info}</td>
        <td>
          {user.communities.length > 0
            ? user.communities[0].name
            : 'No Community'}
        </td>
        {isAdmin && (
          <td>
            <button
              onClick={() => this.openNewCoachModal(user)}
              className='btn btn-primary btn-small text-white'
            >
              Edit
            </button>
          </td>
        )}
      </tr>
    )
  }
  renderCoachesTable() {
    const { coaches } = this.state

    if (coaches && coaches.length > 0) {
      return (
        <table className='table w-100 animated fadeIn'>
          <thead className='primary-background'>
            <tr className='text-white font-200'>
              <th />
              <th />
              <th className='font-200'>First Name</th>
              <th className='font-200'>Last Name</th>
              <th className='font-200'>Email</th>
              <th className='font-200'>Community</th>
              <th className='font-200' />
            </tr>
          </thead>
          <tbody>
            {this.props.coaches.map((coach) => this.renderCoachRow(coach))}
          </tbody>
        </table>
      )
    } else {
      return (
        <div className='col-6 mx-auto'>
          <Placeholder
            title={'Search for Coach By First Name'}
            icon={'fa-user'}
            subtext1={'Start typing to search for a coach by their first name'}
          />
        </div>
      )
    }
  }

  showErrors() {
    if (this.state.errors) {
      return (
        <div id='formErrors'>
          <p>
            <span className='icon icon-info' />
            {this.state.errors}
          </p>
        </div>
      )
    } else {
      return ''
    }
  }

  resetForm() {
    this.setState({
      showNewCoachModal: false,
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      confirm_password: '',
      community_id: '-1',
    })
  }

  handleSubmitCoach(coach) {
    this.setState({ coachLoader: true, errors: {} })
    return this.props.coach_actions.createCoach(coach).then(() => {
      this.fetchCoachData()
      this.setState({
        showNewCoachModal: false,
        selectedCoach: null,
        coachLoader: false,
      })
      return true
    }).catch((err) => {
      this.setState({
        errors: deserialize(err.response.data.errors),
        coachLoader: false,
      })
      return false
    })
  }

  handleEditCoach(coach) {
    this.setState({ coachLoader: true })
    return this.props.coach_actions.adminUpdateCoach(coach).then(() => {
      this.fetchCoachData()
      this.setState({
        showNewCoachModal: false,
        selectedCoach: null,
      })
      return true
    }).catch((err) => {
      this.setState({
        errors: deserialize(err.response.data.errors),
        coachLoader: false,
      })
      return false
    })
  }
  onChangeFirstName(event) {
    this.setState({ first_name: event.target.value })
  }
  onChangeLastName(event) {
    this.setState({ last_name: event.target.value })
  }
  onChangeEmail(event) {
    this.setState({ email: event.target.value })
  }
  onChangePassword(event) {
    this.setState({ password: event.target.value })
  }
  onChangeConfirmPassword(event) {
    this.setState({ confirm_password: event.target.value })
  }
  onChangeCommunityId(event) {
    this.setState({ community_id: event.target.value })
  }

  headerActionButton = () => (
    <button
      className='btn btn-primary text-white pull-right mr-2'
      onClick={() => this.openNewCoachModal(null)}
    >
      Add Coach
    </button>
  )

  render() {
    return (
      <div id='FamilyPage'>
        <PageHeader
          title='Coaches'
          actionComponent={this.headerActionButton()}
        />

        <div className='coaches-search-bar mt-2'>
          <SearchInput placeholder='Search Coaches' onChange={this.searchUpdated}/>
        </div>

        {this.state.coachLoader ? (
          <Loader message={'Loading Coaches'} />
        ) : (
          this.renderCoachesTable()
        )}
        {this.displayNewCoachModal()}
      </div>
    )
  }
}

CoachesPage.propTypes = {
  coaches: PropTypes.array,
  communities: PropTypes.array,
  user: PropTypes.shape({
    type: PropTypes.string,
  }),
  coach_actions: PropTypes.shape({
    createCoach: PropTypes.func,
    adminUpdateCoach: PropTypes.func,
    getCoaches: PropTypes.func,
  }),
  common_actions: PropTypes.shape({
    setCurrentTab: PropTypes.func,
    showSideBar: PropTypes.func,
    showNavBar: PropTypes.func,
  }),
}

function mapStateToProps(state) {
  return {
    ui: state.ui,
    user: state.user.current_user,
    coaches: state.coaches,
    communities: state.communities.communities,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    common_actions: bindActionCreators(commonActions, dispatch),
    coach_actions: bindActionCreators(coachActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
    communities_actions: bindActionCreators(communitiesActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CoachesPage)
