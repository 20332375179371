import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as commonActions from '../../actions/commonActions';
import * as authActions from '../../actions/authActions';

import '../../assets/styles/components/auth/SignIn.scss';
import '../../assets/styles/globals.scss';

import coachLogo from '../../assets/images/logos/coach_logo.png';
import SetNewPasswordForm from './SetNewPasswordForm';
import { withRouting } from '../common/hooks';

class SetNewPassword extends React.Component{
  constructor(props, context){
    super(props, context);
    this.state = {
      passwordWasReset: false
    };
    this.onSignInSubmit = this.onSignInSubmit.bind(this)
  }

  componentWillMount(){
    this.props.common_actions.showSideBar(false);
    this.props.common_actions.showNavBar(false);
    this.props.auth_actions.signOutUser();
  }

  componentWillUnmount(){
    this.props.common_actions.showSideBar(true);
    this.props.common_actions.showNavBar(true);
  }

  // ============
  // FORM FUNCTIONS
  // ============
  onSignInSubmit(childState){
    if(childState.password.length > 0 && childState.password.length >= 6 && childState.password === childState.confirmPassword){
      this.props.auth_actions.resetPassword(childState.password, childState.confirmPassword, this.props.params.token).then(res => {
        this.setState({passwordWasReset: true});
      }).catch(
        err => alert(err.response.data.errors.general) // eslint-disable-line no-alert
      );
    } else if (childState.password.length > 0 && childState.password.length < 6) {
      alert("Your password must be more than 6 characters long.");
    } else {
      alert("Your passwords must match!");
    }
  }

  navigateTo() {
    this.props.navigate('/dashboard')
  }

  userLoggedIn() {
    // Register for push notifications
    this.navigateTo();
  }

  render(){
    return(
      <div id="SignInWeb" className="">
        <div className="signInPage"></div>
        <div className="container">
          <div className="row py-5">
            <div className="col-12 my-4">
              { false && <img alt={"Coach Logo"} src={coachLogo} className="mx-auto d-block"/> }
              { false && <h1 className="text-white text-center">Lonnie Poole</h1> }
            </div>
            <div className="col-md-8 push-md-2 form-container card p-5 mx-auto">
              <SetNewPasswordForm handleSubmit={this.onSignInSubmit} passwordWasReset={this.state.passwordWasReset} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch){
  return{
    common_actions: bindActionCreators(commonActions, dispatch),
    auth_actions: bindActionCreators(authActions, dispatch)
  };
}

export default connect(null, mapDispatchToProps)(withRouting(SetNewPassword))
