import React from "react";

import ConfigPageItem from "./configPageItem/ConfigPageItem";
import ConfigPageItemCustom from "./configPageItemCustom/ConfigPageItemCustom";

import mockup from "../../../assets/images/pro-shop-display/academy-display-mockup.png";

import "./ConfigPage.scss";

const configPage = (props) => {
  // Check the bottom of this component for details on props.

  const checked = props.pages.every((p) => p.displayed);
  const checkedClass = checked ? " config-item-checkbox-checked" : "";

  return (
    <div id='ConfigPage' className='card config-card'>
      <div id='config-card-main-content'>
        <div id='config-card-part-1'>
          <h1 className='config-card-header card-title'>Academy Display</h1>
          <p style={{ textAlign: "center", lineHeight: 1 }}>
            This display works best on a screen with a 16:9 ratio (e.g. 1920px x
            1080px).
          </p>
          <div className='academy-display-mockup'>
            <img src={mockup} alt='Mockup of academy display' />
          </div>

          <form className='display-input' id='display-call-to-action-input'>
            <label htmlFor='call-to-action'>Call-to-action footer text: </label>
            <div>
              <input
                name='call-to-action'
                type='text'
                value={props.callToAction}
                onChange={props.handleCallToActionChange}
                onBlur={props.handleCallToActionChange}
                placeholder='Add a call-to-action for viewers here'
              />
              {/*
              <textarea
                name="call-to-action"
                placeholder="Add a call-to-action for viewers here"
                maxLength={75}
                value={props.callToAction}
                onChange={props.handleCallToActionChange}
                onBlur={props.handleCallToActionChange}>
              </textarea>
              */}
              <p id='call-to-action-char-limit'>
                {props.callToAction.length} / 75 characters
              </p>
            </div>
          </form>

          <form className='display-input' id='display-duration-input'>
            <label htmlFor='duration'>Duration of each screen: </label>
            <div>
              <input
                name='duration'
                type='number'
                value={props.duration}
                onChange={props.handleDurationChange}
                onBlur={props.handleDurationChange}
                min='10'
                placeholder='duration'
              />{" "}
              sec
            </div>
          </form>
          <div className='pendo-trigger'>
            <i className='fa fa-cogs' aria-hidden='true' />
            <p>Recommended Setup</p>
          </div>
        </div>

        <div id='config-card-part-2'>
          <h2 className='config-card-header card-heading-1'>
            Select at least 2 screens to display:
          </h2>
          <div id='display-all-checkbox-item'>
            <div
              className={"config-item-checkbox" + checkedClass}
              onClick={() => props.handleAllDisplayClick(!checked)}
            >
              {checked ? (
                <i className='fa fa-check' aria-hidden='true' />
              ) : null}
            </div>
            <h3 className='config-item-label' id='display-all-label'>
              Display all screens
            </h3>
          </div>
          <hr className='section-divider' />
          <div className='config-card-items'>
            {props.pages.map((p, i) => {
              return i < props.pages.length - 1 ? (
                <ConfigPageItem
                  key={i}
                  screenName={p.name}
                  screenDesc={p.description}
                  screenTitle={p.title}
                  checked={p.displayed}
                  handleCheck={props.handleCheck}
                  thumbnail={p.thumbnail}
                />
              ) : (
                <ConfigPageItemCustom
                  key={i}
                  screenName={p.name}
                  screenDesc={p.description}
                  screenTitle={p.title}
                  checked={p.displayed}
                  handleCheck={props.handleCheck}
                  thumbnail={p.thumbnail}
                  needFileUpload={props.needCoachAd}
                  openImageBank={props.openImageBank}
                  imgSelection={props.imageSelection}
                  dragReorderHandler={props.dragReorderHandler}
                />
              );
            })}
          </div>
        </div>
      </div>

      <button
        className='btn-rectangular btn-rectangular-main-positive'
        onClick={props.start}
        // disabled={!props.pages.some(p => p.displayed)} ==== don't need this if we're forcing user to select 2 screens
      >
        Start slideshow
      </button>
    </div>
  );
};

export default configPage;

// ==============================================
// props this component accepts:
// ==============================================

// displayRunning={this.state.displayRunning} => whether or not the display is running...
// start={this._startDisplay} => function to start the display
// handleCheck={this._handleConfigCheckbox} => handles clicks on check boxes for individual pages
// handleAllDisplayClick={this._handleAllDisplayClick} => handles clicking 'Display all screens' specifically
// pages={this.state.displayed_pages} => data for each of the pages to display. should be an array of objects
// hasPhoto={!!this.state.coachAdSrc} => boolean if a photo has been loaded
