import { useState } from 'react'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'

import { ReactComponent as InfoCircleIcon } from '../../../assets/images/common/icons/info-circle-icon-dark.svg'
import { checkLicensePermission } from '../../../utility/communityLicense'
import Tooltip from '../../common/Tooltip'
import * as featureActions from '../../../actions/featureActions'
import './FeatureCard.scss'

const FeatureCard = ({ community, feature }) => {
  const dispatch = useDispatch()

  const [checked, setChecked] = useState(feature.enabled)
  const [loader, setLoader] = useState(false)

  const handleFeatureCheck = () => {
    const initialCheckValue = checked;

    setLoader(true)
    setChecked(prevChecked => !prevChecked)
    dispatch(featureActions.toggleCommunityFeature(community.id, feature))
      .then(() => { setLoader(false) })
      .catch(() => { setChecked(initialCheckValue); setLoader(false) })
  }

  return (
    <div id='FeatureCard' className='d-flex flex-wrap align-items-center'>
      <div
        role='checkbox'
        aria-checked
        tabIndex={0}
        className={classNames('check-box', { 'box-checked': checked }, { 'disabled': loader })}
        onClick={() => { !loader && handleFeatureCheck() }}
        onKeyDown={(e) => { e.key === 'Enter' && !loader && handleFeatureCheck() }}
      >
        {checked && (
          <i className='fa fa-check' aria-hidden='true' />
        )}
      </div>
      <p className='checkbox-label'>{feature.name}</p>
    </div>
  )
}

export default FeatureCard
