import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import * as Constants from '../../../misc/Constants'
import PackageForm from './PackageForm'
import Modal from '../../common/Modal'
import { setSelectedPackage } from '../../../reducers/community-settings/pricingSettingsReducer'
import { createPackage, updatePackage } from '../../../actions/packagesActions'

const EMPTY_PACKAGE = { name: '', price: '', description: '', archived: false }

const PackageFormContainer = ({
  showPackageFormModal,
  setShowPackageFormModal,
  requestCallback
}) => {
  const dispatch = useDispatch()
  const selectedPackage = useSelector(state => state.communitySettings.pricing.selectedPackage)


  const [formPackage, setFormPackage] = useState(EMPTY_PACKAGE)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const [showPackageConfirmationModal, setShowPackageConfirmationModal] = useState(false)

  useEffect(() => {
    setFormPackage(selectedPackage ? { ...selectedPackage } : EMPTY_PACKAGE)
  }, [showPackageFormModal, selectedPackage, dispatch])

  const handleAddPackage = e => {
    e.preventDefault()
    setLoading(true)

    dispatch(createPackage(formPackage)).unwrap()
      .then((response) => {
        closePackageFormModal()
        if (response && requestCallback) {
          requestCallback(response.package)
        }
      })
      .catch((err) => {
        setLoading(false)
        setErrors(err)
      })
  }

  const handleUpdatePackage = e => {
    e.preventDefault()
    setLoading(true)

    dispatch(updatePackage({ _package: formPackage })).unwrap()
      .then((response) => {
        closePackageFormModal()
        if (response && requestCallback && (
          selectedPackage.name !== formPackage.name ||
          selectedPackage.price.toString() !== formPackage.price.toString() ||
          selectedPackage.description !== formPackage.description)) {
          requestCallback(response.package)
        }
      })
      .catch((err) => {
        setLoading(false)
        setErrors(err)
      })
  }

  const handlePackageFormModal = () => {
    if (
      (selectedPackage?.name || '') !== formPackage.name ||
      (selectedPackage?.price.toString() || '') !== formPackage.price.toString() ||
      (selectedPackage?.description || '') !== formPackage.description
    ) {
      setShowPackageConfirmationModal(true)
    } else {
      closePackageFormModal()
    }
  }

  const closePackageFormModal = () => {
    setShowPackageConfirmationModal(false)
    setErrors({})
    setLoading(false)
    setShowPackageFormModal(false)
    dispatch(setSelectedPackage(null))
  }

  return (
    <div>
      <Modal isOpen={showPackageFormModal} onClose={handlePackageFormModal} fixedTop>
        <PackageForm
          action={selectedPackage ? Constants.FORM_ACTIONS.EDIT : Constants.FORM_ACTIONS.ADD}
          formPackage={formPackage}
          setFormPackage={setFormPackage}
          errors={errors}
          loading={loading}
          handleAddPackage={handleAddPackage}
          handleUpdatePackage={handleUpdatePackage}
          handleCloseModal={handlePackageFormModal}
          closeModal={closePackageFormModal}
          showPackageConfirmationModal={showPackageConfirmationModal}
          setShowPackageConfirmationModal={setShowPackageConfirmationModal}
        />
      </Modal>
    </div>
  )
}

export default PackageFormContainer
