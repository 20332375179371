import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { UncontrolledPopover } from 'reactstrap'

import { displayWithCurrency } from '../../../utility/currency'
import { setSelectedPackage } from '../../../reducers/community-settings/pricingSettingsReducer'
import Pagination from '../../common/Pagination'
import Loader from '../../common/Op36Loader-web'
import TooltipText from '../../common/TooltipText'
import Tooltip from '../../common/Tooltip'

import './PackagesTable.scss'
import { ReactComponent as EllipsisIcon } from '../../../assets/images/common/icons/ellipsis-icon.svg'

const PackagesTable = ({
  fetchPackages,
  toggleArchiveConfirmationModal,
  unarchivePackage,
  toggleDeleteConfirmationModal,
  showPopover,
}) => {
  const dispatch = useDispatch()

  const { totalPages, packages, archived, isLoadingPackages, page } = useSelector(state => state.communitySettings.pricing)
  const currency = useSelector(state => state.user.current_user.communities[0].currency)

  const onEditPackage = (pkg) => {
    dispatch(setSelectedPackage(pkg))
  }

  const onToggleArchive = useCallback(
    (pkg) => pkg.archived ? unarchivePackage(pkg) : toggleArchiveConfirmationModal(pkg),
    [unarchivePackage, toggleArchiveConfirmationModal]
  )

  return (
    <div id='PackagesTable'>
      {totalPages > 1 && (
        <Pagination
          pageCount={totalPages}
          fetchData={fetchPackages}
          activePage={page}
        />
      )}
      { isLoadingPackages
        ? <Loader message={'loading packages'} />
        : packages && packages.length > 0
          ? (
            <div>
              <Tooltip/>
              <table className='table table-responsive w-100'>
                <thead className='primary-background'>
                  <tr>
                    <th style={{ paddingLeft: '36px' }}>Name</th>
                    <th>Price</th>
                    <th>Description</th>
                    <th style={{ whiteSpace: 'nowrap' }}>Associated classes</th>
                    <th>Enrollments</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>{packages.map((pkg) =>
                  <tr
                    key={pkg.id}
                    className={'packages-table-row'}
                  >
                    <td className='table-row-name'>
                      <TooltipText text={pkg.name} />
                    </td>
                    <td className='table-row-price'>{displayWithCurrency(pkg.price, currency, true)}</td>
                    <td className='table-row-description'>
                      <TooltipText text={pkg.description} />
                    </td>
                    <td style={{ width: '10%' }}>{pkg.courseCount}</td>
                    <td style={{ width: '10%' }}>{pkg.enrollmentCount}</td>
                    <td className='table-row-actions' style={{ width: '5%' }}>
                      <EllipsisIcon id={`package_${pkg.id}`} className='table-ellipsis'/>
                      {showPopover &&
                        <UncontrolledPopover
                          placement='bottom'
                          target={`package_${pkg.id}`}
                          trigger='legacy'
                          popperClassName='popover-style'
                        >
                          <div className='packages-actions d-flex flex-column align-items-start justify-content-start'>
                            {!pkg.archived && (
                              <p className='package-action' onClick={() => onEditPackage(pkg)}>
                                Edit Package
                              </p>
                            )}
                            <p className='package-action' onClick={() => onToggleArchive(pkg)}>
                              {`${pkg.archived ? 'Unarchive' : 'Archive'} Package`}
                            </p>
                            {!pkg.enrollmentCount && (
                              <p className='package-action' onClick={() => toggleDeleteConfirmationModal(pkg)}>
                              Delete Package
                              </p>
                            )}
                          </div>
                        </UncontrolledPopover>
                      }
                    </td>
                  </tr>
                )}
                </tbody>
              </table>
            </div>
          ) : (
            <div className='no-packages d-flex justify-content-center'>
              <p className='mb-0'>{`No ${archived ? 'archived' : 'available'} packages`}</p>
            </div>
          )
      }
    </div>
  )
}

export default PackagesTable
