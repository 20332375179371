import styles from './CoachDashboardStats.module.scss'
import { UncontrolledPopover } from 'reactstrap'
import DefaultAvatar from '../../../assets/images/default_profile.png'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const POPOVER_HEADER = {
  1: 'Non-Golfers - Level 1',
  2: 'New Golfers - Level 2',
  3: 'New Golfers - Level 3',
  4: 'Intermediate - Level 4',
  5: 'Intermediate - Level 5',
  6: 'Advanced - Level 6',
  7: 'Advanced - Level 7',
  8: 'Advanced - Level 8',
  9: 'Advanced - Level 9',
  10: 'Advanced - Level 10',
}


const StatsItemPopover = ({ divisionId, golfers }) => {
  const golfersList = [...golfers]
  const sortedGolfers = golfersList.sort(
    (a, b) => b.division_attempts - a.division_attempts
  )

  return (
    <UncontrolledPopover
      placement='bottom'
      target={`Popover-${divisionId}`}
      trigger='legacy'
      className={styles.statsItemPopover}
    >
      <div className={styles.statsPopoverContainer}>
        <div className={styles.statsPopoverHeaderWrapper}>
          <p className={styles.statsPopoverHeader}>
            {POPOVER_HEADER[divisionId]}
          </p>
          <p className={styles.statsPopoverHeaderCount}>Attempts</p>
        </div>

        <div className={`p-2 ${styles.statsPopoverGolferWrapper}`}>
          {sortedGolfers?.length > 0 && sortedGolfers.map((g, index) => (
            <div
              className={`row mx-0 py-2 border-bottom ${styles.popoverGolferCard}`}
              key={index}
            >
              <div className={styles.popoverGolferWrapper}>
                <Link
                  className={styles.popoverGolfer}
                  to={`/profile/${g.golfer.id}`}
                  state={{ userId: g.golfer.id }}
                >
                  <img
                    src={
                      g.golfer.avatar ? g.golfer.avatar.file_url : DefaultAvatar
                    }
                    alt={`${g.golfer.first_name} ${g.golfer.last_name}`}
                    className='smaller-round-image-wrapper mr-1'
                  />
                </Link>
                <div>
                  <h6 className={`mb-0 ml-2 ${styles.popoverGolferName}`}>{`${g.golfer.first_name} ${g.golfer.last_name}`}</h6>
                </div>
              </div>
              <div className={styles.popoverAttemptsWrapper}>
                {g.division_attempts > 0 && (
                  <p className={styles.popoverAttempts}>{g.division_attempts}</p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </UncontrolledPopover>
  )
}

StatsItemPopover.propTypes = {
  divisionId: PropTypes.number,
  golfers: PropTypes.array,
}

export default StatsItemPopover
