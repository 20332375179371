import { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import Select, { components } from 'react-select'
import { toast } from 'react-toastify'
import classNames from 'classnames'
import moment from 'moment'
import _ from 'lodash'

import colors from '../../../../assets/styles/globals.scss'
import { displayWithCurrency } from '../../../../utility/currency'
import { ReactComponent as TableSelectDropDownIcon } from '../../../../assets/images/common/icons/table-select-drop-down-icon.svg'
import { ReactComponent as StripeIcon } from '../../../../assets/images/common/icons/stripe-icon.svg'
import * as courseActions from '../../../../actions/courseActions'
import * as enrollmentActions from '../../../../actions/enrollmentActions'
import * as Constants from '../../../../misc/Constants'
import StripeModal from './modals/StripeModal'

import './ColumnPaymentStatusSelect.scss'

const selectStyles = {
  container: (provided) => ({
    ...provided, height: '45px'
  }),
  control: (provided) => ({
    ...provided, cursor: 'pointer', border: '0', background: 'none', boxShadow: 'none', padding: '0'
  }),
  option: (provided) => ({
    ...provided, cursor: 'pointer'
  }),
  valueContainer: (provided) => ({
    ...provided, height: '45px', padding: '0', input: { position: 'absolute', height: '0', border: 'none' }
  })
}

const NO_PAYMENT_STATUS = { status: 'Select status' }

const ColumnPaymentStatusSelect = ({ enrollment }) => {
  const dispatch = useDispatch()

  const paymentStatus = enrollment.payment_status
  const paidOnline = paymentStatus === 'paid_online'

  const defaultPaymentStatus = useMemo(() =>
    paymentStatus ? { status: paidOnline ? 'Paid Online' : _.capitalize(paymentStatus) } : NO_PAYMENT_STATUS
  , [])
  const transactionDetails = useMemo(() => ({
      'Golfer': `${enrollment.user.first_name} ${enrollment.user.last_name}`,
      'Date': moment(enrollment.paid_at).format('MM/DD/YYYY'),
      'Amount': displayWithCurrency(enrollment.transaction_amount, enrollment.currency, true)
  }), [])

  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState(defaultPaymentStatus)
  const [selectLoader, setSelectLoader] = useState(false)
  const [showStripeModal, setShowStripeModal] = useState(false)

  const handlePaymentStatusChange = (newPaymentStatus) => {
    if (newPaymentStatus.status.toLowerCase() !== selectedPaymentStatus.status.toLowerCase()) {
      const oldPaymentStatus = {...selectedPaymentStatus}

      setSelectLoader(true)
      setSelectedPaymentStatus({ id: null, status: 'Loading...' })

      const updatedPaymentStatus = (newPaymentStatus.status === 'Select status' ? null : newPaymentStatus.status.toLowerCase())

      dispatch(enrollmentActions.updateEnrollment({ id: enrollment.id, paymentStatus: updatedPaymentStatus}))
        .then(() => {
          setSelectedPaymentStatus(newPaymentStatus);
          setSelectLoader(false);
        })
        .catch(() => {
          dispatch(courseActions.getCourse(enrollment.course_id))
          setSelectedPaymentStatus(oldPaymentStatus)
          toast.error('Something went wrong. The payment status has not been updated.', { position: toast.POSITION.BOTTOM_LEFT })
          setSelectLoader(false)
        })
    }
  }

  const options = [ NO_PAYMENT_STATUS, { status: 'Paid' }, { status: 'Invoiced' }, { status: 'Outstanding' }]

  const Option = (props) => {
    const { data, innerProps } = props;
    const paymentStatusColors = {
      'Select status': colors.placeholderGray,
      'Paid': colors.darkTeal,
      'Invoiced': colors.secondaryColor,
      'Outstanding': '#CC0000'
    }

    return (
      <div {...innerProps} className='d-flex align-items-center payment-status-option'>
        <p style={{
          marginBottom: '0',
          color: paymentStatusColors[data.status],
          fontWeight: data.status === 'Select status' ? 400 : 700
        }}>
          {data.status}
        </p>
      </div>
    )
  }

  const SingleValue = (props) => {
    let { data, innerProps } = props;
    const paymentStatusColors = {
      'Select status': colors.placeholderGray,
      'Loading...': colors.placeholderGray,
      'Paid': colors.darkTeal,
      'Invoiced': colors.secondaryColor,
      'Outstanding': '#CC0000',
      'Paid Online': colors.primaryPurple
    }

    return (
      <div {...innerProps} className='d-flex justify-content-between align-items-center payment-status-selected-value'>
        <p style={{
          marginBottom: '0',
          color: paymentStatusColors[data.status],
          fontWeight: (data.status === 'Select status' || data.status === 'Loading...') ? 400 : 700,
          textAlign: 'center'
        }}>
          {data.status}
        </p>
        {paidOnline &&
          <StripeIcon id='stripeIcon' />
        }
      </div>
    )
  }

  const DropdownIndicator = (props) => (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <TableSelectDropDownIcon stroke={colors.lightNavy} style={{ marginRight: '-5.5px' }}/>
      </components.DropdownIndicator>
    )
  )

  return (
    <div id='ColumnPaymentStatusSelect' className={classNames({ 'disabled': selectLoader })}>
      <div onClick={() => paidOnline && setShowStripeModal(true)}>
        <Select
          name='payment-status-select'
          onClick={() => paidOnline && setShowStripeModal(true)}
          className='payment-status-select'
          value={selectedPaymentStatus}
          options={options}
          components={{
            DropdownIndicator: paidOnline ? () => null : DropdownIndicator,
            Menu: paidOnline ? () => null : components.Menu,
            IndicatorSeparator: () => null,
            SingleValue,
            Option
          }}
          isSearchable={false}
          onChange={handlePaymentStatusChange}
          styles={selectStyles}
          isDisabled={selectLoader}
          menuPortalTarget={Constants.DOCUMENT_BODY_REFERENCE}
          menuPlacement='auto'
        />
      </div>

      {paidOnline &&
        <StripeModal
          showModal={showStripeModal}
          closeModal={() => setShowStripeModal(false) }
          transactionDetails={transactionDetails}
        />
      }
    </div>
  )
}

export default ColumnPaymentStatusSelect
