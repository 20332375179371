import PropTypes from "prop-types";
import * as Constants from '../../../misc/Constants'
import Input from '../../common/form/Input'
import ConfirmationModal2 from '../../common/ConfirmationModal2'
import SubmitButton from '../../common/buttons/SubmitButton'
import CancelButton from '../../common/buttons/CancelButton'
import FieldError from '../../common/FieldError'
import { useSelector } from "react-redux";

import './PackageForm.scss'

const PackageForm = ({
  action,
  formPackage,
  setFormPackage,
  errors,
  loading,
  handleAddPackage,
  handleUpdatePackage,
  handleCloseModal,
  closeModal,
  showPackageConfirmationModal,
  setShowPackageConfirmationModal
}) => {
  const currency = useSelector(state => state.user.current_user.communities[0].currency)

  const handleInputChange = e => {
    const inputName = e.target.name
    const inputValue = e.target.value
    if (inputName === 'name' && inputValue.length <= 100) {
      setFormPackage({ ...formPackage, name: inputValue })
    } else if (inputName === 'price' && (inputValue.match(Constants.PRICE_REGEX) || inputValue === '')) {
      setFormPackage({ ...formPackage, price: inputValue })
    } else if (inputName=== 'description' && inputValue.length <= 1000) {
      setFormPackage({ ...formPackage, description: inputValue })
    }
  }

  const formValid = () => {
    if (formPackage.name === '' || formPackage.price === '') {
      return false
    }
    return true
  }

  return (
    <div id='PackageForm'>
      <div className='container-fluid p-0'>
        <div className='row'>
          <div className='col-12 mb-2'>
            <h1 className='form-title'>{`${action === Constants.FORM_ACTIONS.EDIT ? 'Edit' : 'Create New'} Package`}</h1>
          </div>
          <div className='col-12 mb-2'>
            {errors?.general && <FieldError message={errors.general} style={{ paddingBottom: '5px' }} general />}
            <div className='d-flex justify-content-between align-items-start'>
              <label htmlFor='nameInput' className='form-label'>
                Name <span className='required-text'>*</span>
              </label>
              <p className='character-counter m-0'>
                {`${formPackage.name.length}/100 characters`}
              </p>
            </div>
            <Input
              id='nameInput'
              name='name'
              type='text'
              onChange={handleInputChange}
              placeholder='E.g. Recreation Package'
              value={formPackage.name}
              error={errors?.name}
            />
          </div>
          <div className='col-6 mb-2'>
            <label htmlFor='priceInput' className='form-label'>
              Price <span className='required-text'>*</span>
            </label>
            <Input
              id='priceInput'
              name='price'
              type='number'
              symbol={currency.symbol}
              onChange={handleInputChange}
              onWheel={(e) => e.target.blur()}
              placeholder='E.g. 250'
              value={formPackage.price}
              error={errors?.price}
            />
          </div>
          <div className='col-12 mb-2'>
            <div className='d-flex justify-content-between align-items-start'>
              <label htmlFor='descriptionInput' className='form-label'>
                Description
              </label>
              <p className='character-counter m-0'>
                {`${formPackage.description.length}/1000 characters`}
              </p>
            </div>
            <Input
              id='descriptionInput'
              name='description'
              type='textarea'
              onChange={handleInputChange}
              placeholder='E.g. This package contains...'
              value={formPackage.description}
              error={errors?.description}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-6'>
            <CancelButton
              onClick={handleCloseModal}
              buttonMessage='Cancel'
              className='mt-3'
            />
          </div>
          <div className='col-6'>
            <SubmitButton
              onClick={action === Constants.FORM_ACTIONS.EDIT ? handleUpdatePackage : handleAddPackage}
              disabled={!formValid()}
              buttonMessage={action === Constants.FORM_ACTIONS.EDIT
                ? `${loading ? 'Saving' : 'Save'} changes`
                : `${loading ? 'Creating' : 'Create'} package`}
            />
          </div>
        </div>
      </div>
      <ConfirmationModal2
        showModal={showPackageConfirmationModal}
        closeModal={() => setShowPackageConfirmationModal(false)}
        onDeny={() => setShowPackageConfirmationModal(false)}
        denyMessage='No'
        onProceed={closeModal}
        proceedMessage='Yes'
        icon={<i className='fa fa-question-circle' style={{ fontSize: '72px' }} />}
        title='Discard changes?'
        message='All changes will be lost. Are you sure you want to close this window?'
      />
    </div>
  )
}

PackageForm.propTypes = {
  action: PropTypes.oneOf([
    Constants.FORM_ACTIONS.ADD,
    Constants.FORM_ACTIONS.EDIT
  ])
}


export default PackageForm
