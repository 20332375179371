import { createSlice, createSelector } from '@reduxjs/toolkit'
import { getSeries, getAllSeries, createSeries, updateSeries, deleteSeries } from '../actions/seriesActions'

const initialState = {
  eventIndex: 0,
  id: null,
  title: '',
  subtitle: '',
  reminderDescription: '',
  earlyReminderDays: 5,
  lateReminderDays: 2,
  status: '',
  step: 1,
  allSeries: null,
  upcomingSeries: null,
  completedSeries: null,
  allSeriesPagination: {},
  upcomingSeriesPagination: {},
  completedSeriesPagination: {},
  events: [],
  students: [],
  courses: [],
  errors: {},
}

const seriesReducer = createSlice({
  name: 'series',
  initialState,
  reducers: {
    resetState: () => initialState,
    setField: (state, action) => {
      const { field, value } = action.payload
      state[field] = value
    },

    updateFields: (state, action) => {
      const fields = action.payload
      return {
        ...state,
        ...fields,
      }
    },

    addEvent: (state, action) => {
      const newEvent = action.payload
      state.events.push({ index: state.eventIndex, ...newEvent })
      state.events.sort((a, b) => a.eventDate > b.eventDate ? 1 : -1)
      state.eventIndex += 1
    },
    updateEvent: (state, action) => {
      const event = action.payload
      const index = state.events.findIndex(e => e.index === event.index)

      state.events.splice(index, 1)
      state.events.push({ index: state.eventIndex, ...event })
      state.events.sort((a, b) => a.eventDate > b.eventDate ? 1 : -1)
      state.eventIndex += 1
    },
    deleteEvent: (state, action) => {
      const event = action.payload
      const index = state.events.findIndex(e => e.index === event.index)

      if (state.events[index].id) {
        state.events[index].deleted = true
      } else {
        state.events.splice(index, 1)
      }
    },
    deleteAllEvents: (state, _) => {
      const newEvents = []
      state.events.forEach((e) => {
        if (e.id) {
          newEvents.push({ ...e, deleted: true })
        }
      })
      state.events = newEvents
    },

    addStudent: (state, action) => {
      const std = action.payload
      state.students.push(std)
    },

    updateStudent: (state, action) => {
      const student = action.payload
      const newStudents = [...state.students]
      const index = newStudents.findIndex(s => s.user_id === student.user_id)
      newStudents[index] = student
      state.students = newStudents
    },

    deleteStudent: (state, action) => {
      const stdId = action.payload
      const index = state.students.findIndex(s => s.user_id === stdId)

      state.students.splice(index, 1)
    },
    addCourse: (state, action) => {
      const course = { ...action.payload, selectedPackages: [] }
      state.courses.push(course)
    },
    updateCourse: (state, action) => {
      const course = action.payload
      const newCourses = [...state.courses]
      const index = newCourses.findIndex(c => c.id === course.id)
      newCourses[index] = course
      state.courses = newCourses
    },
    deleteCourse: (state, action) => {
      const courseId = action.payload
      const index = state.courses.findIndex(c => c.id === courseId)

      state.courses.splice(index, 1)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllSeries.fulfilled, (state, action) => {
        const series = action.payload.series
        series.sort((a, b) => new Date(a.nextEventDate) - new Date(b.nextEventDate))
        if (action.payload.filters.completed === true) {
          state.completedSeries = series
          state.completedSeriesPagination = action.payload.meta.pagination
        } else if (action.payload.filters.completed === false) {
          state.upcomingSeries = series
          state.upcomingSeriesPagination = action.payload.meta.pagination
        } else {
          state.allSeries = series
          state.allSeriesPagination = action.payload.meta.pagination
        }
      })
      .addCase(getAllSeries.rejected, (state, action) => {
        state.errors = action.payload
      })
      .addCase(getSeries.fulfilled, (state, action) => {
        const series = action.payload.series
        state.id = series.id
        state.title = series.title
        state.subtitle = series.subtitle
        state.earlyReminderDays = series.earlyReminderDays || ''
        state.lateReminderDays = series.lateReminderDays || ''
        state.reminderDescription = series.reminderDescription
        state.status = series.status
        state.eventIndex = series.events.length

        state.events = series.events?.map((e, index) => (
          {
            id: e.id,
            index: index,
            eventDate: new Date(e.eventDate),
            name: e.name,
            location: e.location,
            description: e.description,
            format: e.format,
            status: e.status,
          }
        )) ?? []

        state.students = series.students?.map((s) => {
          let addedFrom = null
          if (s.course) {
            if (s.package) {
              addedFrom = { [s.course.id]: { [s.package.id]: { packageName: s.package.name, courseName: s.course.name } } }
            } else {
              addedFrom = { [s.course.id]: s.course.name }
            }
          } else {
            addedFrom = { 'students': true }
          }

          return {
            user_id: s.id,
            first_name: s.firstName,
            last_name: s.lastName,
            level: s.currentDivision,
            current_formal_division: s.currentDivision,
            avatar: s.avatar,
            addedFrom: addedFrom,
            age: s.age,
          }
        }) ?? []

        state.courses = []
        series.courses?.forEach((course) => {
          state.courses.push(course)
        })
      })
      .addCase(getSeries.rejected, (state, action) => {
        state.errors = action.payload
      })
      .addCase(deleteSeries.fulfilled, (state, action) => {
        if (action.typeCompleted) {
          const filteredSeries = state.completedSeries.filter(series => series.id !== action.payload.series.id)
          state.completedSeries = filteredSeries
        } else {
          const filteredSeries = state.upcomingSeries.filter(series => series.id !== action.payload.series.id)
          state.upcomingSeries = filteredSeries
        }
      })
      .addCase(deleteSeries.rejected, (state, action) => {
        state.errors = action.payload
      })
      .addCase(createSeries.rejected, (state, action) => {
        const errors = action.payload
        state.errors = {}
        state.errors = errors
        if (errors.title || errors.subtitle) {
          state.errors.step1 = true
        }

        if (errors.events) {
          state.errors.step2 = true
        }

        if (errors.courses || errors.students) {
          state.errors.step3 = true
        }
      })
      .addCase(updateSeries.rejected, (state, action) => {
        const errors = action.payload
        state.errors = {}
        if (Object.keys(errors.series).length) {
          state.errors = errors.series
          state.errors.step1 = true
        }

        if (errors.events) {
          state.errors.step2 = true
        }

        if (errors.courses || errors.students) {
          state.errors.step3 = true
        }
      })
  },
})

export const {
  resetState,
  setField,
  updateFields,
  addStudent,
  updateStudent,
  deleteStudent,
  addCourse,
  updateCourse,
  deleteCourse,
  addEvent,
  updateEvent,
  deleteEvent,
  deleteAllEvents,
} = seriesReducer.actions


export const seriesFormData = createSelector(
  state => state.series,
  seriesFields => ({
    title: seriesFields.title,
    subtitle: seriesFields.subtitle,
    earlyReminderDays: seriesFields.earlyReminderDays,
    lateReminderDays: seriesFields.lateReminderDays,
    reminderDescription: seriesFields.reminderDescription,
    events: seriesFields.events,
    courses: seriesFields.courses?.map(c => ({ id: c.id, selectedPackages: c.selectedPackages })),
    students: seriesFields.students,
  })
)

export default seriesReducer.reducer
