import * as types from "../actions/actionTypes";

// state = [] : Start out with no accounts
export default function family(state = [], action) {
  switch (action.type) {
    case types.ADD_FAMILY:
      return action.family;

    case types.UPDATE_FAMILY:
      return Object.assign({}, state, {
        family: action.family
      });

    case types.ADD_FAMILY_MEMBER:
      return {
        ...state,
        users: [...state.users, action.user]
      };

    case types.GET_FAMILY_STATS:
      return Object.assign({}, state, {
        family_stats: action.family_stats
      });

    default:
      return state;
  }
}
