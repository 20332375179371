import { useCallback } from 'react'
import ReactSelect from 'react-select'
import PropTypes from 'prop-types'

const Select = ({ value, onChange, options, ...props }) => {
  const innerValue = options.find(o => o.value === value) || {}

  const onInnerChange = useCallback(
    (value) => {
      onChange && onChange(value)
    },
    [ onChange ]
  )

  return (
    <ReactSelect
      value={innerValue}
      onChange={onInnerChange}
      options={options}
      {...props}
    />
  )
}

Select.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.object // TODO: There is some bug that sends object as value
  ]),
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ])
  })),
  onChange: PropTypes.func
}

export default Select