const FIVE_DAYS_IN_ADVANCE = [
  {
    value: 5,
    label: '5 Days in Advance',
  },
  {
    value: 4,
    label: '4 Days in Advance',
  },
  {
    value: 3,
    label: '3 Days in Advance',
  },
  {
    value: 2,
    label: '2 Days in Advance',
  },
  {
    value: 1,
    label: '1 Day in Advance',
  },
]

export const NOTIFICATION_OPTIONS_FIVE_DAYS = [
  {
    value: '',
    label: 'Do Not Send',
  },
  ...FIVE_DAYS_IN_ADVANCE,
]

export const NOTIFICATION_OPTIONS_TEN_DAYS = [
  {
    value: '',
    label: 'Do Not Send',
  },
  {
    value: 10,
    label: '10 Days in Advance',
  },
  {
    value: 9,
    label: '9 Days in Advance',
  },
  {
    value: 8,
    label: '8 Days in Advance',
  },
  {
    value: 7,
    label: '7 Days in Advance',
  },
  {
    value: 6,
    label: '6 Days in Advance',
  },
  ...FIVE_DAYS_IN_ADVANCE,
]
