import * as constants from './actionTypes'
import * as common from './commonActions'

import Axios from '../axios'

export function successRequestingCreateResource(response) {
  if (response.status === 201) {
    // console.error("CREATED COURSE")
    return {
      type: constants.CREATE_RESOURCE,
      resource: response.data.resource,
    }
  } else {
    console.error('ERROR ON CREATING RESOURCE')
    return { type: constants.UI_ERROR, error: response.data }
  }
}

export function successDeletingResource(response) {
  if (response.status === 200) {
    return {
      type: constants.DELETE_RESOURCE,
      resource: response.data.resource,
    }
  } else {
    console.error('ERROR ON GETTING RESOURCE')
    return { type: constants.UI_ERROR, error: response.data }
  }
}

export function successRequestingUpdateResource(response) {
  if (response.status === 200) {
    // console.error("UPDATED COURSE")
    return {
      type: constants.UPDATE_RESOURCE,
      resource: response.data.resource,
    }
  } else {
    console.error('ERROR ON UPDATING RESOURCE')
    return { type: constants.UI_ERROR, error: response.data }
  }
}

export function successRequestingResources(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_RESOURCES,
      resources: response.data.resources,
    }
  } else {
    console.error('ERROR ON GETTING RESOURCES')
    return { type: constants.UI_ERROR, error: response.data }
  }
}

export function successRequestingRecentResources(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_RECENT_RESOURCES,
      recent_resources: response.data.recent_resources,
    }
  } else {
    console.error('ERROR ON GETTING RECENT RESOURCES')
    return { type: constants.UI_ERROR, error: response.data }
  }
}

export function successRequestingResource(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_RESOURCE,
      resource: response.data.resource,
    }
  } else {
    console.error('ERROR ON GETTING RESOURCE')
    return { type: constants.UI_ERROR, error: response.data }
  }
}
// THUNKS

export function searchResources(search) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true))
    return requestSearchResources(search)
      .then(response => dispatch(successRequestingResources(response)))
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function getResources(course_id) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true))
    return requestResources(course_id)
      .then(response => dispatch(successRequestingResources(response)))
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function getRecentResources() {
  return function(dispatch) {
    dispatch(common.loadingChanged(true))
    return requestRecentResources()
      .then(response => dispatch(successRequestingRecentResources(response)))
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function getResource(resource_id) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true))
    return requestResource(resource_id)
      .then(response => dispatch(successRequestingResource(response)))
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function createResource(resource) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true))
    return requestCreateResource(resource)
      .then(response => dispatch(successRequestingCreateResource(response)))
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function adminCreateResource(resource) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true))
    return requestAdminCreateResource(resource)
      .then(response => dispatch(successRequestingCreateResource(response)))
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function updateResource(resource) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true))
    return requestUpdateResource(resource)
      .then(response => dispatch(successRequestingUpdateResource(response)))
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

export function deleteResource(resourceId) {
  return function(dispatch) {
    dispatch(common.loadingChanged(true))
    return requestDeleteResource(resourceId)
      .then(response => dispatch(successDeletingResource(response)))
      .then(() => dispatch(common.loadingChanged(false)))
  }
}

// API CALLS

function requestSearchResources(search) {
  const requestUrl = '/api/v1/resources?search=' + search
  return Axios.get(requestUrl)
}

function requestResources(course_id) {
  const requestUrl = '/api/v1/courses/' + course_id + '/resources'
  return Axios.get(requestUrl)
}

function requestRecentResources() {
  const requestUrl = '/api/v1/recent_resources'
  return Axios.get(requestUrl)
}

function requestResource(resource_id) {
  const requestUrl = '/api/v1/resources/' + resource_id
  return Axios.get(requestUrl)
}

function requestCreateResource(resource) {
  const requestUrl = '/api/v1/courses/' + resource.course.id + '/addResource'

  const data = new FormData()
  data.append('course_id', resource.course.id)
  data.append('title', resource.title)
  data.append('description', resource.description)
  data.append('button_url', resource.button_url)
  data.append('resource_type', resource.type)

  if (resource.resource_photo) {
    data.append('resource_photo', resource.resource_photo)
  }

  return Axios.post(requestUrl, data)
}

function requestAdminCreateResource(resource) {
  const requestUrl = '/api/v1/resources/'

  const data = new FormData()
  data.append('title', resource.title)
  data.append('description', resource.description)
  data.append('button_url', resource.button_url)
  data.append('video_url', resource.video_url)
  data.append('resource_category_id', resource.resource_category_id)

  if (resource.uploadPhotoFile) {
    data.append('resource_photo', resource.uploadPhotoFile)
  }

  return Axios.post(requestUrl, data)
}

function requestUpdateResource(resource) {
  const requestUrl = '/api/v1/resources/' + resource.id

  const data = new FormData()
  data.append('title', resource.title)
  data.append('description', resource.description)
  data.append('button_url', resource.button_url)
  data.append('video_url', resource.video_url)
  data.append('resource_category_id', resource.resource_category_id)

  if (resource.type) { data.append('resource_type', resource.type) }

  if (resource.resource_photo) {
    data.append('resource_photo', resource.resource_photo)
  }

  if (resource.uploadPhotoFile) {
    data.append('resource_photo', resource.uploadPhotoFile)
  }

  return Axios.put(requestUrl, data)
}

function requestDeleteResource(resource_id) {
  const requestUrl = '/api/v1/resources/' + resource_id
  return Axios.delete(requestUrl)
}
