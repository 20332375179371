import React from "react";

const StarScoringCard = (props) => {
  return (
    <div className='d-flex align-items-center'>
      <div
        className='d-flex align-items-center'
        style={{ color: "#34ACE0", fontSize: 18 }}
      >
        {[0, 1, 2, 3, 4].map((num) => {
          return (
            <i
              className={`fa fa-star${num > 1 ? "-o" : ""}`}
              aria-hidden='true'
              style={{ marginRight: 2 }}
            />
          );
        })}
      </div>
      <div style={{ marginLeft: 4 }}>
        <p className='mb-0' style={{ lineHeight: 1.2 }}>
          Star Feedback
        </p>
        <p className='mb-0' style={{ lineHeight: 1.2 }}>
          (E.g. How do you feel after this drill?)
        </p>
      </div>
    </div>
  );
};

export default StarScoringCard;
