import React, { Component } from "react";
import Modal from "react-modal";

import "../../assets/styles/common/validationModal.scss";

const modalStyles = {
  content: {
    height: "270px",
    width: "30%",
    marginTop: "12%",
    overflow: 'hidden',
  }
};

class ValidationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    return (
      <Modal
        isOpen={this.props.showValidationModal}
        onRequestClose={this.props.closeValidationModal}
        contentLabel="Validation Modal"
        style={modalStyles}
      >
        <div className="modal-content animated fadeIn">
          <button
            onClick={this.props.closeValidationModal}
            className="validation-modal-close-btn btn-link"
          >
            <i className="fa fa-times validation-modal-close-icon" />
          </button>
          <div className="row my-3">
            <div className="col-md-12 validation-modal-container">
              <div className="validation-title-container">
                <i
                  className={`fa ${
                    this.props.validationSuccess
                      ? "fa-check-circle"
                      : "fa-exclamation-triangle"
                  } validation-modal-icon`}
                  aria-hidden="true"
                  style={{
                    color: `${
                      this.props.validationSuccess ? "#5cb85c" : "#d9534f"
                    }`
                  }}
                />
                <h2
                  className="text-center validation-modal-title"
                  style={{
                    color: `${
                      this.props.validationSuccess ? "#5cb85c" : "#d9534f"
                    }`
                  }}
                >
                  {this.props.validationSuccess ? "Success" : "Error"}
                </h2>
              </div>
              <p className="text-center validation-modal-message">
                {this.props.validationMessage}
              </p>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ValidationModal;
