import * as types from "../actions/actionTypes";

// state = [] : Start out with no accounts
export default function flaggedAccounts(state = [], action) {
  switch (action.type) {
    case types.GET_FLAGGED_ACCOUNTS:
      return action.flagged_accounts;

    default:
      return state;
  }
}
