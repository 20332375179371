import React, { Component } from "react";
import ReactTable from "react-table";
import createTable from "react-table-hoc-fixed-columns";
import "react-table/react-table.css";

import CSVExportModal from "./CSVExportModal";
import profile_avatar from "../../assets/images/mock/StockProfilePic.png";

const ReactTableFixedColumns = createTable(ReactTable);

const HEADERS = [
  { label: "Community", key: "community" },
  { label: "Total Families", key: "totalfamilies" },
  { label: "Total Students", key: "totalstudents" },
  { label: "Points Earned", key: "pointsearned" },
  { label: "Total Activities", key: "totalactivities" },
  { label: "Total Practices", key: "totalpractices" },
  { label: "Total Social Actions", key: "totalsocialactions" },
  { label: "Goals Completed", key: "goalscompleted" },
  { label: 'Levels Completed', key: 'rankscompleted' },
  { label: "Rounds Played", key: "roundsplayed" },
  { label: "Strokes Lost", key: "strokeslost" },
  { label: "Enrolled Students", key: "enrolledstudents" },
  { label: "Not Enrolled Students", key: "notenrolledstudents" }
];

class CommunityStatsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      dataLoader: false,
      data: []
    };
  }

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    })
  }

  createData = () => {
    const { data } = this.props;
    let tempData = [];
    this.setState({ dataLoader: true });
    if (data.length > 0) {
      data.map(info => {
        let community = info.community.name;
        let totalfamilies = info.report.stats.family_count;
        let totalstudents = info.report.stats.student_count;
        let pointsearned = info.report.stats.points;
        let totalactivities = info.report.stats.total_activities;
        let totalpractices = info.report.stats.total_train_activities;
        let totalsocialactions = info.report.stats.total_social_activities;
        let goalscompleted = info.report.stats.total_goal_activities;
        let rankscompleted = info.report.stats.ranks_completed;
        let roundsplayed = info.report.stats.total_play_activities;
        let strokeslost = info.report.stats.strokes_lost;
        let enrolledstudents = info.report.stats.enrolled_students_count;
        let notenrolledstudents = info.report.stats.not_enrolled_students_count;

        tempData.push({
          community,
          totalfamilies,
          totalstudents,
          pointsearned,
          totalactivities,
          totalpractices,
          totalsocialactions,
          goalscompleted,
          rankscompleted,
          roundsplayed,
          strokeslost,
          enrolledstudents,
          notenrolledstudents
        });
      });
    }

    this.setState({
      showModal: !this.state.showModal,
      data: tempData
    })
  };

  renderUserAvatarCell = props => {
    return (
      <div
        className='number'
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer"
        }}
      >
        <div className='small-round-image-wrapper'>
          <img
            className='round-image'
            src={props.value ? props.value.photo.file_url : ""}
            alt={
              props.value
                ? `${props.value.first_name} ${props.value.last_name}`
                : ""
            }
          />
        </div>
      </div>
    );
  };
  renderTableCell = props => {
    return (
      <div
        className='number'
        style={{
          backgroundColor: "",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div style={{ color: "#2b3843" }}>{props.value}</div>
      </div>
    );
  };
  renderMainTableCell = props => {
    return (
      <div
        className='number'
        style={{
          backgroundColor: "#2b3843",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div style={{ color: "white" }}>{props.value}</div>
      </div>
    );
  };
  renderTable = () => {
    const { loader, data } = this.props;
    const columns = [
      {
        Header: "",
        accessor: "community",
        Cell: props => this.renderUserAvatarCell(props),
        sortable: false,
        fixed: true
      },
      {
        Header: " Community",
        accessor: "community.name",
        Cell: props => this.renderTableCell(props),
        fixed: true
      },
      {
        Header: "Total Families",
        accessor: "report.stats.family_count",
        Cell: props => this.renderMainTableCell(props),
        style: { padding: 0 }
      },
      {
        Header: "Total Students",
        accessor: "report.stats.student_count",
        Cell: props => this.renderMainTableCell(props),
        style: { padding: 0 }
      },
      {
        Header: "Points Earned",
        accessor: "report.stats.points",
        Cell: props => this.renderMainTableCell(props),
        style: { padding: 0 }
      },
      {
        Header: "Total Activities",
        accessor: "report.stats.total_activities",
        Cell: props => this.renderMainTableCell(props),
        style: { padding: 0 }
      },
      {
        Header: "Total Practices",
        accessor: "report.stats.total_train_activities",
        Cell: props => this.renderTableCell(props),
        style: { padding: 0 }
      },
      {
        Header: "Total Social Actions",
        accessor: "report.stats.total_social_activities",
        Cell: props => this.renderTableCell(props),
        style: { padding: 0 }
      },
      {
        Header: "Goals Completed",
        accessor: "report.stats.total_goal_activities",
        Cell: props => this.renderTableCell(props),
        style: { padding: 0 }
      },
      {
        Header: 'Levels Completed',
        accessor: "report.stats.ranks_completed",
        Cell: props => this.renderTableCell(props),
        style: { padding: 0 }
      },
      {
        Header: "Rounds Played",
        accessor: "report.stats.total_play_activities",
        Cell: props => this.renderTableCell(props),
        style: { padding: 0 }
      },
      {
        Header: "Strokes Lost",
        accessor: "report.stats.strokes_lost",
        Cell: props => this.renderTableCell(props),
        style: { padding: 0 }
      },
      {
        Header: "Enrolled Students",
        accessor: "report.stats.enrolled_students_count",
        Cell: props => this.renderTableCell(props),
        style: { padding: 0 }
      },
      {
        Header: "Not Enrolled Students",
        accessor: "report.stats.not_enrolled_students_count",
        Cell: props => this.renderTableCell(props),
        style: { padding: 0 }
      }
    ];

    return (
      <div className='container my-5 animated fadeIn'>
        <div className='row mb-5 d-flex align-items-center'>
          <div className='col-md-8'>
            <h2>{this.props.title}</h2>
          </div>
          <div className='col-md-4'>
            <button
              className='btn btn-sm btn-block'
              onClick={() => this.props.goBack()}
            >
              <i
                className='fa fa-arrow-left text-white mr-1'
                aria-hidden='true'
              />{" "}
              View Another Report
            </button>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <ReactTableFixedColumns
              data={!loader ? data : []}
              columns={columns}
              defaultPageSize={3}
              loading={loader}
              loadingText='Loading Report'
              noDataText={loader ? "" : "No data found"}
            />
          </div>
        </div>
        <button onClick={this.createData} className='mt-3 btn btn-success'>
          Export as CSV
        </button>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderTable()}
        <CSVExportModal
          headers={HEADERS}
          data={this.state.data}
          showModal={this.state.showModal}
          toggleModal={this.toggleModal}
          reportType={"Community Stats Report (All Time)"}
          filename={"community-stats-report.csv"}
        />
      </div>
    );
  }
}

export default CommunityStatsTable;
