import styles from '../../../assets/styles/components/common/SideBarItemExpanded.module.scss'
import PropTypes from 'prop-types'
import SideBarLink from './SideBarLink'
import SideBarIcon from './SideBarIcon'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

const SideBarItemExpanded = ({ icon, title, links = [], index }) => {
  const singleLink = links.length === 1
  const currentTab = useSelector(state => state.ui.currentTab)
  const selected = links.filter((link) => link.tab === currentTab)[0]

  return (
    <>
      <div id={index} className={styles.sideBarItemExpanded}>
        <div className={styles.iconWrapper}>
          <SideBarIcon icon={icon} className={selected ? styles.iconSelected : styles.icon}/>
        </div>
        <div className={classNames(styles.title, { [styles.selectedTitle ]: selected })}>
          {singleLink ?
            <SideBarLink link={links[0]} singleLink={singleLink} title={title}/>
            :
            <div>{title}</div>}
        </div>
      </div>
      {!singleLink &&
        <div className={styles.section}>
          {links.map((link, index) =>
            <div key={index} className={classNames(styles.sectionLink, { [styles.selectedSectionLink ]: selected?.title === link.title })}>
              <SideBarLink link={link} singleLink={singleLink} title={title} />
            </div>)
          }
        </div>
      }
    </>
  )
}

SideBarItemExpanded.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  links: PropTypes.array,
  index: PropTypes.number,
}

export default SideBarItemExpanded
