import styles from './CoachDashboardSupport.module.scss'
import StockProfilePic from '../../../assets/images/mock//default-profile-icon.png'
import { ReactComponent as ArrowIcon } from '../../../assets/images/common/icons/arrow-right.svg'
import ReactTooltip from 'react-tooltip'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getRepresentative } from '../../../actions/dashboardActions'

const CoachDashboardSupport = () => {
  const communityRepId = useSelector(state => state.user.current_user.communities[0].representative_id)
  const rep = useSelector(state => state.dashboard.representative)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getRepresentative(communityRepId))
  }, [dispatch, communityRepId])

  return (
    <div className={styles.CoachDashboardSupport}>
      <p className={styles.title}>Are you stuck? We are here to help</p>
      <p className={styles.title}>Meet your Program Advisor.</p>
      <p>Schedule as many calls as you need to feel confident in setting up or running programs.</p>
      <div
        className={styles.profileImg}
        style={{
          backgroundImage: rep?.avatar ? `url(${rep?.avatar.file_url})` : `url(${StockProfilePic})`,
        }}
      />
      <p className={styles.repName}>{rep?.first_name} {rep?.last_name}</p>
      <p data-for='bioTooltip' data-tip className={styles.repBio}>Learn More</p>
      <ReactTooltip id='bioTooltip'
        effect='solid'
        delayUpdate={5}
        delayHide={5}
        place={'bottom'}
        className={styles.bioTooltip}
      ><p>{rep?.bio}</p></ReactTooltip>
      <a
        href={rep?.booking_url}
        target='_blank'
        className={`btn btn-primary btn-sm text-white ${styles.supportBtn}`}
      >
        Book a Meeting
      </a>
      <button
        className={`btn btn-primary btn-sm text-white ${styles.supportBtn}`}
        onClick={() => window.Intercom('show')}
      >
        Live Chat With Support
      </button>
      <p className={styles.title}>Other Support & Training Options</p>

      <a
        href='https://operation-36-network-coaches.mn.co/landing?from=https%3A%2F%2Foperation-36-network-coaches.mn.co%2Ffeed'
        className={styles.supportActionLink}
        target='_blank'
      >
        Connect in our Coaches Community
        <ArrowIcon className={styles.arrow}/>
      </a>
      <a
        href='https://operation-36-network-coaches.mn.co/landing?from=https%3A%2F%2Foperation-36-network-coaches.mn.co%2Fcollections%2F21698'
        target='_blank'
        className={styles.supportActionLink}
      >
        Take Free Coach Education Courses
        <ArrowIcon className={styles.arrow}/>
      </a>
      <a
        href='https://operation36.supporthero.io/'
        target='_blank'
        className={styles.supportActionLink}
      >
        Visit our Technology Knowledgebase
        <ArrowIcon className={styles.arrow}/>
      </a>
      <a
        href='https://operation36.golf/operation-36-coach-certification/'
        target='_blank'
        className={styles.supportActionLink}
      >
        Become a Certified Operation 36 Coach
        <ArrowIcon className={styles.arrow}/>
      </a>
    </div>
  )
}

export default CoachDashboardSupport
