import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import * as activityActions from "../../actions/activityActions";
import "../../assets/styles/components/activity/Activity.scss";
import InactiveClap from "../../assets/images/op36/clap_lightgray.png";
import ActiveClap from "../../assets/images/op36/clap_secondary.png";
import "./Activity.scss";
import { Popover } from "reactstrap";
import ConfirmationModal from "../../components/common/ConfirmationModal";
import { deserialize } from '../../utility/serialization'
import {
  renderActivityIcon,
  renderActivityColor,
} from "../../constants/activityTypes";

import CommentForm from "./CommentForm";
import Comment from "./Comment";
import Avatar from "../../components/common/avatar/avatar.js";
import moment from "moment";

import ActivityModal from "./modals/ActivityModal";
import ActivityEventModal from "./modals/ActivityEventModal";
import { withRouting } from "../common/hooks";

// MUST PASS IN AN ACTIVITY OBJECT, HAS OPTION OF SHOWING COMMENTS OR NOT
class Activity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      commentBody: "",
      showCommentModal: this.props.isFromNotifications && ['ActivityPlay', 'ActivityPractice'].includes(this.props.activity.type),
      isOpen: false,
      activity: this.props.activity ? this.props.activity : null,
      comment_count: this.props.activity
        ? this.props.activity.comment_count
        : null,
      like_count: this.props.activity ? this.props.activity.like_count : null,
      is_liked: this.props.activity ? this.props.activity.is_liked : null,
      postLoader: false,
      popoverOpen: false,
      confirmationMessage: "",
      showConfirmationModal: false,
      clapTriggered: false,
      commentLoader: false,
      event: {},
      showEventModal: this.props.isFromNotifications && this.props.activity.type === 'ActivityEvent',
      confirmAction: '',
    };
  }

  componentWillMount() {
    if (this.props.show_comments || this.state.showEventModal) {
      this.getComments();
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.isFromNotifications || this.props.isFromNotifications === prevProps.isFromNotifications) {
      return
    }

    this.setState({
      showCommentModal: this.props.isFromNotifications && ['ActivityPlay', 'ActivityPractice'].includes(this.props.activity.type),
      showEventModal: this.props.isFromNotifications && this.props.activity.type === 'ActivityEvent'
    })
  }

  deleteActivity = () => {
    this.props.actions.deleteActivity(this.props.activity).then(() => {
      if (this.props.triggerReload) {
        this.props.triggerReload();
      }
    });
  };

  validateActivity = () => {
    const activity = {
      ...deserialize(this.props.activity),
      activityId: this.props.activity.id,
      format: 2,
    }
    this.props.actions.updateActivity(activity).then(() => {
      if (this.props.triggerReload) {
        this.props.triggerReload()
      }
      this.setState({
        showConfirmationModal: false,
      })
    })
  }

  handleConfirmation = () => {
    if (this.state.confirmAction === 'delete') {
      this.deleteActivity()
    } else if (this.state.confirmAction === 'validate') {
      this.validateActivity()
    }
  }

  togglePopover = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  };

  toggleEventModal = () => {
    this.setState({ showEventModal: true });
  };

  closeEventModal = () => {
    this.setState({ showEventModal: false });
  };

  toggleConfirmationModal = (action) => {
    const { activity } = this.props
    let confirmationMessage = ''

    if (action === 'delete') {
      confirmationMessage = `Are you sure you want to delete ${activity.user.first_name} ${activity.user.last_name}'s activity?`
    } else if (action === 'validate') {
      confirmationMessage = 'You are validating that the student shot this score as a formal round. '
                          + 'If they shot 36 or better it will automatically Level them up and emails will be sent.'
    }

    this.setState({
      showConfirmationModal: !this.state.showConfirmationModal,
      confirmationMessage: confirmationMessage,
      confirmAction: action,
      popoverOpen: false,
    });
  };

  toggleComments = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  getComments = () => {
    this.setState({ commentLoader: true }, () => {
      this.props.actions
        .getActivityComments(this.props.activity.id)
        .then(() => {
          this.setState({ commentLoader: false });
        });
    });
  };

  getLikes = () => {
    const { activity } = this.props;
    this.setState({ likeLoader: true }, () => {
      this.props.actions.getActivityLikes(activity.id).then(() => {
        this.setState({ likeLoader: false });
      });
    });
  };

  // ======================
  // COMMENT FORM FUNCTIONS
  // ======================
  onCommentInput = (event) => {
    this.setState({ commentBody: event.target.value });
  };

  onCommentSubmit = (event) => {
    event.preventDefault();
    this.setState({
      postLoader: true,
      commentLoader: true,
    });
    if (this.state.comment !== undefined || this.state.comment !== "") {
      this.props.actions
        .postActivityComment(
          this.props.activity.id,
          this.state.commentBody,
          this.state.activity
        )
        .then((res) => {
          if (res) {
            this.setState({
              comment_count: this.state.comment_count + 1,
              commentBody: "",
              postLoader: false,
              commentLoader: false,
              isOpen: true,
            });
          }
        });
    }
    this.scrollContent();
  };
  showCommentForm = () => {
    if (this.props.show_comments || this.state.showEventModal) {
      return (
        <CommentForm
          onInputChange={this.onCommentInput}
          onSubmit={this.onCommentSubmit}
          loading={this.props.ui.isLoading}
          user_name={this.props.activity.user.first_name}
          value={this.state.commentBody}
        />
      );
    }
  };
  showWebCommentForm = () => {
    return (
      <CommentForm
        onInputChange={this.onCommentInput}
        onSubmit={this.onCommentSubmit}
        loading={this.state.postLoader}
        user_name={this.props.activity.user.first_name}
        value={this.state.commentBody}
        web={true}
      />
    );
  };
  showCommentSectionTrue = () => {
    return (
      <div className='scrollable-content'>
        {this.props.comments.map((comment, index) => (
          <div className='col-md-12' key={index}>
            <Comment
              key={comment.id}
              user={comment.user}
              body={comment.text}
              web={true}
              time={comment.created_at}
            />
          </div>
        ))}
      </div>
    );
  };
  showCommentSection = () => {
    if (this.props.show_comments) {
      return this.props.comments.map((comment) => (
        <Comment
          key={comment.id}
          user={comment.user}
          body={comment.text}
          web={this.props.web}
          time={comment.created_at}
        />
      ));
    } else {
      return "";
    }
  };

  // ======================
  // LIKE ACTIVITY FUNCTIONS
  // ======================
  onClickLike = () => {
    if (!this.state.is_liked) {
      this.props.actions.postActivityLike(this.state.activity.id);
      this.setState({
        like_count: this.state.like_count + 1,
        is_liked: true,
      });
    } else {
      this.props.actions.deleteActivityLike(this.state.activity.id);
      this.setState({
        like_count: this.state.like_count - 1,
        is_liked: false,
      });
    }
  };

  // ======================
  // DISPLAY ACTIVITY FUNCTION
  // ======================
  displayActivityHeaderType = () => {
    switch (this.props.activity.type) {
      case "ActivityPlay":
        return (
          <p className='activityName' style={{ color: renderActivityColor(this.props.activity) }}>
            Played {this.props.activity.holes} holes.
          </p>
        );

      case "ActivityPractice":
        let practiceTime = 0
        this.props.activity.practice_sessions?.forEach((ps) => {
          practiceTime += ps.duration
        })

        this.props.activity.logged_drills?.forEach(ld => {
          //totalTime += ld.duration
          ld.practice_sessions?.forEach(ps => {
            practiceTime += ps.duration
          })
        })
        return <p className='activityName' style={{ color: renderActivityColor(this.props.activity) }}>Trained for {practiceTime} min.</p>

      case "ActivityRank":
        return (
          <p className='activityName' style={{ color: renderActivityColor(this.props.activity) }}>
            Completed level: {this.props.activity.rank.name}
          </p>
        );

      case "ActivityObjective":
        return (
          <p className='activityName' style={{ color: renderActivityColor(this.props.activity) }}>
            Completed objective: {this.props.activity.objective.name}
          </p>
        );

      case "ActivitySocial":
        return (
          <p className='activityName' style={{ color: renderActivityColor(this.props.activity) }}>
            Logged {this.props.activity.social_objective.name}
          </p>
        );

      case "ActivityChallenge":
        return (
          <p className='activityName' style={{ color: renderActivityColor(this.props.activity) }}>
            Logged {this.props.activity.challenge.name}
          </p>
        );
      case "ActivityAnnouncement":
        return (
          <div>
            <p className='activityName' style={{ color: renderActivityColor(this.props.activity) }}>Coach Announcement</p>
          </div>
        );

      case "ActivityGoal":
        return <p className='activityName' style={{ color: renderActivityColor(this.props.activity) }}>Completed a Private Goal</p>
      case "ActivityEvent":
        return (
          <p className='activityName' style={{ color: renderActivityColor(this.props.activity) }}>
            {this.props.activity.event.play_type.name}
          </p>
        );

      default:
        return "ERROR";
    }
  };

  displayObjectivePhoto = () => {
    let bgColor = "transparent";
    let congratsText =
      "Completed " + this.props.activity.objective.subject.name + " Objective!";
    if (this.props.activity.rank) {
      bgColor = this.props.activity.rank.color_hex;
      congratsText =
        "Completed the " +
        this.props.activity.rank.name +
        " " +
        this.props.activity.objective.subject.name +
        " Objective!";
    }
    return (
      <div className='activityObjectivePost'>
        <div className='overlay-5 m-0 p-0'>
          <div className='row py-3'>
            <div className='col-12 objective-post-wrapper'>
              <p className='text-center text-white my-2 mb-3'>{congratsText}</p>
              <div
                className='rounded-circle activityObjectiveBadge mb-4'
                style={{ backgroundColor: bgColor }}
              >
                <img
                  alt='objective-icon'
                  src={this.props.activity.objective.subject.icon.file_url}
                  className='mx-auto img-responsive w-100'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  displayRankPhoto = () => (
    <div className='activityObjectivePost'>
      <div className='overlay-5 m-0 p-0'>
        <div className='row py-3'>
          <div className='col-2' />
          <div className='col-8'>
            <p className='text-center text-white my-2 activity-rank-text'>
              Completed the {this.props.activity.rank.name}!
            </p>
            <p className='text-center'>
              <span className='fa-stack fa-lg fa-5x'>
                <i
                  className='fa fa-circle fa-stack-2x fa-2x'
                  style={{ color: this.props.activity.rank.color_hex }}
                />
                <i className='fa fa-check fa-stack-1x fa-inverse' />
              </span>
            </p>
          </div>
          <div className='col-2' />
        </div>
      </div>
    </div>
  )

  displayGoalPhoto = () => (
    <div className='activityObjectivePost'>
      <div className='overlay-5 m-0 p-0'>
        <div className='row py-3'>
          <div className='col-2' />
          <div className='col-8'>
            <p className='text-center text-white my-2 activity-rank-text'>
              Completed a Private Goal!
            </p>
            <p className='text-center'>
              <span className='fa-stack fa-lg fa-5x'>
                <i
                  className='fa fa-circle fa-stack-2x fa-2x'
                  style={{ color: '#FF6633' }}
                />
                <i className='fa fa-check fa-stack-1x fa-inverse' />
              </span>
            </p>
          </div>
          <div className='col-2' />
        </div>
      </div>
    </div>
  )

  displayPhotoUrl = () => {
    const { activity } = this.props;
    if (activity.type === "ActivityObjective") {
      return (
        <div className='activity-picture-wrapper'>
          {this.displayObjectivePhoto()}
        </div>
      );
    } else if (activity.type === "ActivityRank") {
      return (
        <div className='activity-picture-wrapper'>
          {this.displayRankPhoto()}
        </div>
      );
    } else if (activity.type === "ActivityGoal") {
      return (
        <div className='activity-picture-wrapper'>
          {this.displayGoalPhoto()}
        </div>
      );
    } else if (
      activity.type === "ActivityEvent" &&
      activity.event &&
      activity.event.photo
    ) {
      return (
        <div className='activity-image-wrapper'>
          <div onClick={this.openCommentModal}>
            <img
              src={activity.event.photo.large_file_url}
              alt={`${activity.user.first_name} ${activity.user.last_name}`}
            />
          </div>
        </div>
      );
    } else if (
      activity.type === "ActivityPlay" &&
      activity.event &&
      activity.event.photo
    ) {
      return (
        <div className='activity-image-wrapper'>
          <div onClick={this.openCommentModal}>
            <img
              src={activity.event.photo.large_file_url}
              alt={`${activity.user.first_name} ${activity.user.last_name}`}
            />
          </div>
        </div>
      );
    } else {
      if (!activity.photo?.large_file_url && !(activity.type === "ActivityPlay" && activity.share_score)) {
        return null
      }
      return (
        <div className={`activity-image-wrapper ${!activity.photo?.large_file_url && ' score-overlay'}`}>
          {activity.photo && activity.photo.large_file_url && (
            <div onClick={this.openCommentModal}>
              <img
                src={activity.photo.large_file_url}
                alt={`${activity.user.first_name} ${activity.user.last_name}`}
              />
            </div>
          )}
          {activity.type === "ActivityPlay" && activity.share_score && (
            <div className='activity-score-overlay-container'>
              <div className='activity-score-overlay-wrapper'>
                <div className='row'>
                  <div className='col-4 activity-data activity-score'>
                    <h5>{activity.score}</h5>
                    <p>Score</p>
                  </div>
                  <div className='col-4 activity-data activity-greens'>
                    <h5
                      style={{
                        color: this.renderPillColor(
                          activity.greens,
                          "greens",
                          activity.holes
                        ),
                      }}
                    >
                      {activity.greens ? activity.greens : "N/A"}
                    </h5>
                    <p>Greens</p>
                  </div>
                  <div className='col-4 activity-data activity-putts'>
                    <h5
                      style={{
                        color: this.renderPillColor(
                          activity.putts,
                          "putts",
                          activity.holes
                        ),
                      }}
                    >
                      {activity.putts ? activity.putts : "N/A"}
                    </h5>
                    <p>Putts</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    }
  };

  _renderVideo = (url) => {
    if (url[0] === '"') {
      url = JSON.parse(url);
    }
    return (
      <ReactPlayer
        className='activity-react-player'
        url={url}
        width='100%'
        height='250px'
        controls
      />
    );
  };

  renderPillColor(number, type, holeCount) {
    if (type === "greens") {
      if (holeCount === 9) {
        if (number >= 0 && number <= 2) {
          return "#d9534f";
        } else if (number >= 3 && number <= 5) {
          return "#FFFF00";
        } else if (number >= 6 && number <= 9) {
          return "#33d9b2";
        } else {
          return "#2b3843";
        }
      } else {
        if (number >= 0 && number <= 5) {
          return "#d9534f";
        } else if (number >= 6 && number <= 11) {
          return "#FFFF00";
        } else if (number >= 12 && number <= 18) {
          return "#33d9b2";
        } else {
          return "#2b3843";
        }
      }
    } else if (type === "putts") {
      if (holeCount === 9) {
        if (number >= 23) {
          return "#d9534f";
        } else if (number >= 16 && number <= 22) {
          return "#FFFF00";
        } else if (number >= 1 && number <= 15) {
          return "#33d9b2";
        } else {
          return "#2b3843";
        }
      } else {
        if (number >= 45) {
          return "#d9534f";
        } else if (number >= 31 && number <= 44) {
          return "#FFFF00";
        } else if (number >= 1 && number <= 30) {
          return "#33d9b2";
        } else {
          return "#2b3843";
        }
      }
    }
  }

  openCommentModal = (id) => {
    this.getComments();
    this.getLikes();
    this.setState({
      showCommentModal: true,
    });
  };

  closeCommentModal = () => {
    this.setState({
      showCommentModal: false,
    });
  };

  scrollContent = () => {
    let someElement = document.querySelector(".scrollable-content");
    let observer = new MutationObserver(scrollToBottom);
    let config = { childList: true };
    observer.observe(someElement, config);

    function animateScroll(duration) {
      let start = someElement.scrollTop;
      let end = someElement.scrollHeight;
      let change = end - start;
      let increment = 20;
      function easeInOut(currentTime, start, change, duration) {
        currentTime /= duration / 2;
        if (currentTime < 1) {
          return (change / 2) * currentTime * currentTime + start;
        }
        currentTime -= 1;
        return (-change / 2) * (currentTime * (currentTime - 2) - 1) + start;
      }
      function animate(elapsedTime) {
        elapsedTime += increment;
        let position = easeInOut(elapsedTime, start, change, duration);
        someElement.scrollTop = position;
        if (elapsedTime < duration) {
          setTimeout(function () {
            animate(elapsedTime);
          }, increment);
        }
      }
      animate(0);
    }

    function scrollToBottom() {
      let duration = 300;
      animateScroll(duration);
    }
  };

  toProfile = () => {
    let memberID = this.props.activity.user.id;
    this.props.navigate(
      `/profile/${memberID}`,
      {
        state: { userId: memberID }
      }
    );
  };

  clapHoverTrigger = () => {
    this.setState({
      clapTriggered: !this.state.clapTriggered,
    });
  };

  // PASS IN: USER AND ACTIVITY OBJECTS
  render() { //eslint-disable-line max-lines-per-function, complexity
    const { activity, user, comments, likes } = this.props;
    const { showCommentModal, commentLoader, showEventModal } = this.state;
    const currentUser = user.id === activity.user.id;

    return (
      <div id='ActivityCard' ref={this.props.innerRef}>
        <div className={"activity row py-3 activity-card"}>
          {(user.type === 'Coach' || currentUser) && activity.type !== 'ActivityEvent' && (
            <>
              <div className='activity-dropdown-wrapper'>
                <i
                  className='fa fa-chevron-down activity-dropdown-icon'
                  id={`Popover${activity.id}`}
                  onClick={this.togglePopover}
                  onKeyDown={(e) => e.key === 'Enter' && this.togglePopover}
                  role='button'
                  tabIndex={0}
                />
              </div>
              <Popover
                placement='bottom'
                isOpen={this.state.popoverOpen}
                target={`Popover${this.props.activity.id}`}
                toggle={this.togglePopover}
              >
                <div className='activity-dropdown-container'>
                  {user.type === 'Coach' && this.props.activity.play_type_id && this.props.activity.play_type_id !== 2 &&
                    <>
                      <button
                        className='btn btn-link activity-dropdown-delete'
                        onClick={() => this.toggleConfirmationModal('validate')}
                      >
                        Validate as Formal Round
                      </button>
                      <hr />
                    </>
                  }
                  <button
                    className='btn btn-link activity-dropdown-delete'
                    onClick={() => this.toggleConfirmationModal('delete')}
                  >
                    Delete
                  </button>
                </div>
              </Popover>
            </>
          )}
          <div className='activity-card-container'>
            <div className='container'>
              <div className='row d-flex align-items-center activity-header-wrapper'>
                <div className='profilePicture' style={{cursor:'pointer'}} onClick={this.toProfile}>
                    <Avatar
                      className='img-responsive'
                      imageUrl={
                        activity.user.avatar
                          ? activity.user.avatar.file_url
                          : null
                      }
                      hasCoachLabel={activity.user.type === "Coach"}
                      hasBorder={activity.user.type === "Coach"}
                      altName={
                        activity.user.first_name + " " + activity.user.last_name
                      }
                    />
                </div>
                <div className='postName activity-info-wrapper'>
                  <div className='activity-name-wrapper'>
                    <h4 className='userName ml-2'>
                      {activity.user.first_name} {activity.user.last_name}
                    </h4>
                    {activity.user.type === "Coach" && (
                      <div className='coach-pill-wrapper'>
                        <p>Coach</p>
                      </div>
                    )}
                  </div>
                  <div
                    className='activity-overview-wrapper'
                  >
                    <div
                      className={'activity-icon-wrapper'}
                    >
                      {renderActivityIcon(activity)}
                    </div>
                    {this.displayActivityHeaderType()}
                  </div>
                </div>
              </div>
            </div>

            <div className='container'>
              <div className='row'>
                {activity.type === "ActivityAnnouncement" && (
                  <div className='col-12' style={{ fontWeight: "bold" }}>
                    {activity.announcement.subject}
                  </div>
                )}
                <div className='col-12 postDescription'>{activity.caption}</div>
              </div>
            </div>

            {activity.type === "ActivityAnnouncement" &&
              activity.announcement.video
              ? this._renderVideo(activity.announcement.video.attachment_url)
              : this.displayPhotoUrl()
            }

            <div className='col-12 postCreatedAt p-0'>
              <span style={{ color: "#aaa", paddingTop: "10px" }}>
                {moment(activity.activity_date).fromNow()}
              </span>
            </div>
            <div className='showButtons'>
              <div className='container'>
                <div className='row'>
                  <div
                    className={`${
                      activity.event ? "col-4" : "col-6"
                    } showButton activity-clap-container d-flex justify-content-center align-items-center flex-row`}
                    onClick={this.onClickLike}
                    onMouseEnter={this.clapHoverTrigger}
                    onMouseLeave={this.clapHoverTrigger}
                  >
                    <div className='activity-clap-wrapper'>
                      <img
                        src={
                          this.state.clapTriggered || this.state.is_liked
                            ? ActiveClap
                            : InactiveClap
                        }
                        alt='Activity Clap'
                      />
                    </div>
                    {this.state.like_count} Clap
                    {this.state.like_count === 1 ? "" : "s"}
                  </div>
                  <div
                    className={`${
                      activity.event ? "col-4" : "col-6"
                    } showButton d-flex justify-content-center align-items-center flex-row`}
                  >
                    {!this.props.ui.showSideBar ? (
                      <Link
                        to={"/activity/" + this.props.activity.id + "/comments"}
                        className='text-secondary w-100 d-flex justify-content-center align-items-center flex-row'
                      >
                        <i className='fa fa-comment' aria-hidden='true' />
                        {this.state.comment_count} Comment
                        {this.state.comment_count === 1 ? "" : "s"}
                      </Link>
                    ) : (
                      <div
                        onClick={this.openCommentModal}
                        className='text-secondary w-100 d-flex justify-content-center align-items-center flex-row'
                      >
                        <i className='fa fa-comment' aria-hidden='true' />
                        {this.state.comment_count} Comment
                        {this.state.comment_count === 1 ? "" : "s"}
                      </div>
                    )}
                  </div>
                  {activity.event && (
                    <div
                      className='col-4 showButton activity-clap-container d-flex justify-content-center align-items-center flex-row'
                      onClick={this.toggleEventModal}
                    >
                      <div className='activity-stat-wrapper'>
                        <i className='fa fa-line-chart' />
                      </div>
                      View Event
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div
              id='commentSection'
              className={this.props.web ? "comment-web-container" : ""}
            >
              {this.showCommentSection()}
            </div>
            <div className='container-fluid'>
              {/*{this.showCommentForm()} */}
            </div>
          </div>
          {showCommentModal && (
            <ActivityModal
              activity={activity}
              showCommentModal={showCommentModal}
              closeCommentModal={this.closeCommentModal}
              toProfile={this.toProfile}
              displayActivityHeaderType={this.displayActivityHeaderType}
              openCommentModal={this.openCommentModal}
              displayObjectivePhoto={this.displayObjectivePhoto}
              displayRankPhoto={this.displayRankPhoto}
              displayGoalPhoto={this.displayGoalPhoto}
              commentLoader={commentLoader}
              comments={comments}
              likes={likes}
              user={user}
              onInputChange={this.onCommentInput}
              onSubmit={this.onCommentSubmit}
              value={this.state.commentBody}
            />
          )}
          {showEventModal && (
            <ActivityEventModal
              event={activity.event}
              showModal={showEventModal}
              closeModal={this.closeEventModal}
            />
          )}
        </div>
        {!this.props.web && this.props.isStudentJourney && (
          <div className='activity-feed-divider' />
        )}
        <ConfirmationModal
          showConfirmationModal={this.state.showConfirmationModal}
          closeConfirmationModal={this.toggleConfirmationModal}
          confirmationMessage={this.state.confirmationMessage}
          confirmationDenied={this.toggleConfirmationModal}
          confirmationProceed={this.handleConfirmation}
        />
      </div>
    );
  }
}

Activity.propTypes = {
  activity: PropTypes.object.isRequired,
  show_comments: PropTypes.bool,
  actions: PropTypes.shape({
    updateActivity: PropTypes.func,
  }),
  triggerReload: PropTypes.func,
  user: PropTypes.shape({
    type: PropTypes.string,
  }),
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user.current_user,
    ui: state.ui,
    activity: ownProps.activity,
    show_comments: ownProps.displayComments,
    comments: state.comments,
    likes: state.likes,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(activityActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouting(Activity))
