import * as types from "../actions/actionTypes";

export default function accolades(state = [], action) {
  switch (action.type) {
    case types.CREATE_ACCOLADE:
      let accoladeCopy = [...state.accolades];
      accoladeCopy.push(action.accolade);
      return Object.assign({}, state, {
        accolades: accoladeCopy,
      });
    case types.GET_COACH_ACCOLADES:
      return Object.assign({}, state, { accolades: action.accolades });

    case types.GET_ALL_ACCOLADES:
      return Object.assign({}, state, { all_accolades: action.accolades });

    case types.DESTROY_ACCOLADE:
      let filteredAccolades = state.accolades.filter(
        (acc) => acc.id !== action.accolade
      );
      return Object.assign({}, state, { accolades: filteredAccolades });

    default:
      return state;
  }
}
