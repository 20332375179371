import PropTypes from 'prop-types'
import styles from './PageFooter.module.scss'
import classNames from 'classnames'
import * as constants from '../../../misc/Constants'

import { ReactComponent as InfoIcon } from '../../../assets/images/common/icons/info-circle-icon-dark.svg'
import ReactTooltip from 'react-tooltip'

const PageFooter = ({
  event,
  step,
  next,
  updateEnrollments,
  validateStudents,
  updateEvent,
  togglePreviewModal,
  scoresSaved,
  saveScores,
  eventScores,
  showSaveScores }) => {
  const saveButtonDisabled = scoresSaved || event.students.length === 0

  return (
    <div className={classNames(styles.pageFooterContainer, 'fixed-bottom')}>
      <div className='h-100 d-flex align-items-center'>
        <div className={classNames('col-12', styles.footerButtonsContainer)}>
          <div className='d-flex flex-row pull-right align-items-center'>
            {
              step === 1 && (
                <div>
                  <button
                    className={classNames(styles.btn, 'btn text-white pull-right ml-3')}
                    onClick={() => event.action === 'Edit Roster' ? updateEnrollments() : next()}
                    disabled={event.students.length === 0}
                  >
                    <span className='ml-2'>{event.action === 'Edit Roster' ? 'Save Roster' : 'Next' }</span>
                  </button>
                </div>
              )
            }
            {
              step === 2 && (
                <div className='d-flex align-items-center'>
                  {showSaveScores &&
                    <>
                      <InfoIcon data-for='infoTooltip' data-tip className='mr-3'/>
                      <ReactTooltip id='infoTooltip'
                        effect='solid'
                        delayUpdate={5}
                        delayHide={5}
                        place={'top'}
                        className={styles.infoTooltip}
                      >
                        <p>{constants.SAVE_SCORES_TOOLTIP}</p>
                      </ReactTooltip>
                      <p className={classNames({ [styles.savedScores]: eventScores })}>
                        {eventScores ? `Saved: ${eventScores['savedAt']}` : 'Scores not saved'}
                      </p>
                      <button
                        className={classNames({ [styles.btnDisabled]: saveButtonDisabled }, styles.btn, 'btn text-white pull-right ml-3')}
                        onClick={saveScores}
                        disabled={saveButtonDisabled}
                      >
                        <span className='ml-2'>Save Scores</span>
                      </button>
                    </>
                  }
                  <button
                    className={classNames(styles.btn, 'btn text-white pull-right ml-3')}
                    onClick={() => validateStudents()}
                    disabled={event.students.length === 0}
                  >
                    <span className='ml-2'>Next: Details</span>
                  </button>
                </div>
              )
            }
            {
              step === 3 && (
                event.status === 'draft' ?
                  <div>
                    <button
                      className={classNames(styles.btn, 'btn text-white pull-right ml-3')}
                      onClick={() => updateEvent()}
                    >
                      <span className='ml-2'>Update Event</span>
                    </button>
                  </div>
                  :
                  <div>
                    <button
                      className={classNames(styles.btn, 'btn text-white pull-right ml-3')}
                      onClick={() => togglePreviewModal()}
                    >
                      <span className='ml-2'>Review and Submit Scores</span>
                    </button>
                  </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

PageFooter.propTypes = {
  event: PropTypes.object,
  step: PropTypes.number.isRequired,
  next: PropTypes.func,
  updateEnrollments: PropTypes.func,
  validateStudents: PropTypes.func,
  updateEvent: PropTypes.func,
  togglePreviewModal: PropTypes.func,
  scoresSaved: PropTypes.bool,
  saveScores: PropTypes.func,
  eventScores: PropTypes.object,
  showSaveScores: PropTypes.bool,
}

export default PageFooter
