import * as types from "../actions/actionTypes";

export default function communityStages(state = [], action) {
  switch (action.type) {
    case types.GET_COMMUNITY_STAGES:
      return action.community_stages;

    case types.CREATE_COMMUNITY_STAGE:
      return Object.assign({}, state, {
        community_stage: action.community_stage
      });

    case types.DELETE_COMMUNITY_STAGE:
      if (!state || state.length === 0) {
        return null;
      }
      let selected_index_delete = state
        .map(function(e) {
          return e.id;
        })
        .indexOf(action.community_stage.id);
      let stateCopy = [...state];
      stateCopy.splice(selected_index_delete, 1);

      return stateCopy;

    default:
      return state;
  }
}
