import React, { Component } from 'react'
import PropTypes from 'prop-types'
import QRCode from 'qrcode.react'

import StockProfilePic from '../../../../assets/images/mock/StockProfilePic.png'
import './SidebarZone.scss'

// Using a class-based component to update time in live time
class SidebarZone extends Component {

  // TODO do I need to set default values for these in case Intl.DateTimeFormat fails?
  state = {
    weekday: '',
    date: '',
    time: ''
  }

  componentDidMount() {
    // Get today's date and time (e.g. December 10, 2019)
    let now = new Date();
    const weekDayOptions = { weekday: 'long' };
    this.setState({ weekday: new Intl.DateTimeFormat('default', weekDayOptions).format(now) });
    const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    this.setState({ date: new Intl.DateTimeFormat('default', dateOptions).format(now) });
    const timeOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit'};
    this.setState({ time: new Intl.DateTimeFormat('default', timeOptions).format(now) });

    // Refresh the time every 1000ms so it's live
    this.interval = setInterval(() => {
      now = new Date();
      this.setState({ weekday: new Intl.DateTimeFormat('default', weekDayOptions).format(now) });
      this.setState({ date: new Intl.DateTimeFormat('default', dateOptions).format(now) });
      this.setState({ time: new Intl.DateTimeFormat('default', timeOptions).format(now) });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render () {
    // If error getting weather data, need to say "No weather data available"...or just don't display anything
    // EDIT: We are not going to get weather data yet

    // Before generating QR code, check if community is public
    const baseAppUrl = process.env.REACT_APP_BASE_URL
    const url = this.props.comm.is_public ? `${baseAppUrl}/landing/${this.props.comm.id}` : baseAppUrl

    return (
      <div className="sidebar-zone">
        <img
          src={this.props.comm.photo ? this.props.comm.photo.file_url : StockProfilePic}
          alt={this.props.comm.name + " icon"}
          className="sidebar-zone-comm-icon" />
        <h1 className="sidebar-zone-comm-name">{this.props.comm.name}</h1>
        <p className="sidebar-zone-date-time">
          {this.state.weekday}
          <br />
          {this.state.date}
          <br />
          <span className="sidebar-zone-date-time-clock">{this.state.time}</span>
        </p>
        {/* We are not going to implement weather yet
          <div className="sidebar-zone-weather">
            <div className="sidebar-zone-weather-primary">
              <img
                src=""
                alt="Weather icon"
                className="" />
              <p>Temp</p>
            </div>
            <p>Weather desc</p>
            <p>High/Low</p>
          </div>
          */}
        <div className="sidebar-zone-qr-code-wrapper">
          <p>To learn more, scan this QR code with your camera:</p>
          <QRCode
            value={url}
            fgColor={"#1d2933"}
            renderAs={"svg"} />
        </div>
      </div>
    );
  }
}

SidebarZone.propTypes = {
  comm: PropTypes.shape({
    is_public: PropTypes.bool,
    id: PropTypes.number,
  }),
}

export default SidebarZone;
