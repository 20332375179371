import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css';
import './Tabs.scss'
import PropTypes from 'prop-types'


const CustomTab = ({ children, barColor, width, borderRadius, ...props }) => {
  const style = {
    background: barColor || 'black',
    width: width || '30%',
    borderRadius: borderRadius || '0px'
  }

  return (
    <Tab {...props}>
      {children}
      <div className='hover-bar' style={style} />
    </Tab>
  )
}
CustomTab.propTypes = {
  children: PropTypes.node.isRequired,
}
CustomTab.tabsRole = 'Tab'

const CustomTabList = ({ children, ...props }) => {
  return (
    <TabList {...props}>
      {children}
    </TabList>
  )
}
CustomTabList.propTypes = {
  children: PropTypes.node.isRequired,
}
CustomTabList.tabsRole = 'TabList'

const CustomTabs = ({ children, ...props }) => (
  <Tabs {...props}>
    { children }
  </Tabs>
)

CustomTabs.propTypes = {
  children: PropTypes.node.isRequired,
}

const CustomTabPanel = ({ children, ...props }) => (
  <TabPanel {...props}>
    { children }
  </TabPanel>
)
CustomTabPanel.propTypes = {
  children: PropTypes.node.isRequired,
}
CustomTabPanel.tabsRole = 'TabPanel'


export {
  CustomTab as Tab,
  CustomTabs as Tabs,
  CustomTabList as TabList,
  CustomTabPanel as TabPanel,
}
