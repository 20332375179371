import Axios from '../axios'
import * as constants from './actionTypes'
import { deserialize } from '../utility/serialization'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { handleAPIError } from '../utility/actionHelper'

export const successRequestGetMaterials = (response) => {
  if (response.status === 200) {
    return {
      type: constants.GET_MATERIALS,
      materials: deserialize(response.data.materials)
    }
  } else {
    return { type: constants.UI_ERROR, error: deserialize(response.data) };
  }
}

// THUNKS HERE
export const getMaterials = () => (dispatch) => {
  requestGetMaterials()
    .then((response) => dispatch(successRequestGetMaterials(response)))
}

export const getCommunityMaterials = createAsyncThunk(
  'materials/getCommunityMaterials',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await requestGetMaterials()
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

// API CALLS
export const requestGetMaterials = () => {
  let requestUrl = 'api/v1/materials'

  return Axios.get(requestUrl)
}
