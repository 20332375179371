import React, { Component } from "react";
import MyStatefulEditor from "../common/form/MyStatefulEditor";
import DraftEditor from "../common/form/DraftEditor";

// ASSETS
import "./AdminEducationLesson.scss";

class AdminEducationLesson extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.lesson.id ? props.lesson.id : -1,
      order: props.lesson.order,
      name: props.lesson.name ? props.lesson.name : "",
      video_url: props.lesson.video_url ? props.lesson.video_url : "",
      description: props.lesson.description ? props.lesson.description : "",
      lessonHoverActive: false
    };
  }

  onMouseEnter = () => {
    this.setState({ lessonHoverActive: true });
  };

  onMouseLeave = () => {
    this.setState({ lessonHoverActive: false });
  };

  handleInputChange = event => {
    const { sendLessonData } = this.props;
    this.setState(
      {
        [event.target.name]: event.target.value
      },
      () => {
        sendLessonData(this.state);
      }
    );
  };

  handleStatefulEditorChange = htmlString => {
    const { sendLessonData } = this.props;
    this.setState(
      {
        description: htmlString
      },
      () => {
        sendLessonData(this.state);
      }
    );
  };

  render() {
    const { name, video_url, description, lessonHoverActive } = this.state;
    const { lesson, deleteLesson, isEditModal } = this.props;
    return (
      <div
        id='AdminEducationLesson'
        className='col-md-11 animated fadeIn'
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        {lessonHoverActive && !isEditModal && (
          <button
            className='delete-btn animation-shrink'
            onClick={() => deleteLesson(lesson)}
          >
            <i className='fa fa-times' />
          </button>
        )}
        <div className='row'>
          <div className='col-md-6'>
            <div className='form-group'>
              <label htmlFor='name'>Lesson Name</label>
              <input
                type='text'
                name='name'
                className='form-control'
                id='name'
                placeholder='Lesson name'
                onChange={this.handleInputChange}
                value={name}
              />
            </div>
          </div>
          <div className='col-md-6'>
            <div className='form-group'>
              <label htmlFor='video_url'>Video URL</label>
              <input
                type='text'
                name='video_url'
                className='form-control'
                id='video_url'
                placeholder='Lesson Video URL'
                onChange={this.handleInputChange}
                value={video_url}
              />
            </div>
          </div>
          <div className='col-md-12'>
            <div className='form-group'>
              <label htmlFor='description'>Description</label>
              {/* <MyStatefulEditor
                name="description"
                content={description}
                onChange={this.handleStatefulEditorChange}
              />
               */}
              <DraftEditor
                placeholder='Enter content...'
                content={description}
                sendEditorData={this.handleStatefulEditorChange}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminEducationLesson;
