import React from "react";
import Modal from "react-modal";
import AlertDialog from "../../common/AlertDialog";
import FamilyEditForm from "./FamilyEditForm.js";

// const modalStyles = {
//   content: {
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)'
//   }
// };

class FamilyEditModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleInputChange = this.handleInputChange.bind(this);
    this.showSuccess = this.showSuccess.bind(this);
  }

  showSuccess() {
    let title = "User updated";
    let message = "Family details have been successfully updated";

    this.setState(
      {
        alertType: "success",
        alertTitle: title,
        alertMessage: message
      },
      this.refs.alertDialog.show()
    );

    this.props.toggleEditModal();
  }

  handleInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  render() {
    let family = this.props.family;

    return (
      <div>
        <Modal
          isOpen={this.props.showEditModal}
          onRequestClose={this.props.toggleEditModal}
          contentLabel="Invite Family Modal"
          // style={modalStyles}
        >
          <div className="modal-header row">
            <div className="col-11">
              <h5 className="modal-title display-4">
                {family ? family.name : "New "} Details
              </h5>
            </div>
            <div className="col-1">
              <button
                type="button"
                className="close pull-right font-200"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.props.toggleEditModal}
              >
                <span aria-hidden="true">✕</span>
              </button>
            </div>
          </div>
          <div className="modal-content">
            <h6 className="text-center font-200">
              <FamilyEditForm
                family={family}
                handleInputChange={this.handleInputChange}
                toggleEditModal={this.props.toggleEditModal}
                toggleValidationModal={this.props.toggleValidationModal}
                showSuccess={this.showSuccess}
                handleResetPassword={this.props.handleResetPassword}
              />
            </h6>
          </div>
        </Modal>
        <AlertDialog
          hideOnOverlayClicked={true}
          ref="alertDialog"
          showOverlay={true}
          title={this.state.alertMessage}
        >
          {this.state.alertMessage}
        </AlertDialog>
      </div>
    );
  }
}

export default FamilyEditModal;
