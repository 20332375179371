import React from "react";

import "./StudentPickerRow.scss";

import defaultProfilePic from "../../../assets/images/default_profile.png";

const StudentPickerRow = ({ student, onClick, clickable }) => {
  return (
    <div id='StudentPickerRow' onClick={() => onClick(student.id)}>
      <div
        className={`cover d-flex align-items-center w-100 h-100 ${
          clickable ? "hover-red" : ""
        }`}
      >
        <i
          className={`fa fa-check-circle ${clickable ? "hide-hover" : ""}`}
          aria-hidden='true'
        />
        {clickable && (
          <i
            className='fa fa-times-circle hidden show-hover'
            aria-hidden='true'
          />
        )}
        <div
          className='avatar'
          style={{
            backgroundImage: `url(${
              student.avatar ? student.avatar.file_url : defaultProfilePic
            })`,
          }}
        />
        <div className='name-area'>
          <p className='name'>{student.name}</p>
          <p className='login'>{student.username}</p>
        </div>
        {clickable && <div className={`remove-inciator`}>Remove</div>}
      </div>
    </div>
  );
};

export default StudentPickerRow;
