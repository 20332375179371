import React from "react";
import "./RegistrationStepper.scss";

const STEPS = [
  {
    id: 1,
    name: "Admin Setup"
  },
  {
    id: 2,
    name: "Create Password"
  },
  {
    id: 3,
    name: "Create Golfers"
  }
  // {
  //   id: 3,
  //   name: "Done"
  // }
];

const renderSmartCheck = (id, activeStep, completedStep) => {
  if (activeStep === completedStep)
    return (
      <i className="fa fa-check-circle step active-step" aria-hidden="true" />
    );
  if (id === activeStep) {
    return (
      <div className="registration-step-wrapper">
        <p className="registration-step-number active-step">{id}</p>
        <i className="fa fa-circle-o step active-step" aria-hidden="true" />
      </div>
    );
  } else if (id <= completedStep) {
    return (
      <i
        className="fa fa-check-circle step active-step animated zoomIn"
        aria-hidden="true"
      />
    );
  }
  return (
    <div className="registration-step-wrapper">
      <p className="registration-step-number inactive-step">{id}</p>
      <i className="fa fa-circle-o step inactive-step" aria-hidden="true" />
    </div>
  );
};

const renderSteps = (activeStep, completedStep) => {
  return STEPS.map(step => (
    <div className="col-md-4 registration-step-container" key={step.id}>
      <p
        className={`registration-step-name ${step.id <= activeStep &&
          "active-step-name"}`}
      >
        {step.name}
      </p>
      {renderSmartCheck(step.id, activeStep, completedStep)}
    </div>
  ));
};

const RegistrationStepper = ({ activeStep, completedStep }) => (
  <div id="RegistrationStepperContainer">
    <div className="row w-50">{renderSteps(activeStep, completedStep)}</div>
  </div>
);

export default RegistrationStepper;
