import * as constants from "./actionTypes";
import * as common from "./commonActions";
import Axios from "../axios";

export function successRequestingNotifications(response) {
  if (response.status === 200) {
    // console.log("ADDING NOTIFICATIONS TO STORE: " + response.data.notifications)
    return {
      type: constants.ADD_NOTIFICATIONS,
      notifications: response.data.notifications
    };
  } else {
    console.log("ERROR ON GETTING NOTIFICATIONS");
    return { type: constants.UI_ERROR, error: response.data };
  }
}
//Not using this yet
export function successRequestingUnreadNotifications(response) {
  if (response.status === 200) {
    // console.log("ADDING NOTIFICATIONS TO STORE: " + response.data.notifications)
    return {
      type: constants.ADD_NEW_NOTIFICATIONS,
      notifications: response.data.notifications
    };
  } else {
    console.log("ERROR ON GETTING NOTIFICATIONS");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function sucessClearingNotifications(response) {
  if (response.status === 200) {
    // console.log("ADDING NOTIFICATIONS TO STORE: " + response.data.notifications)
    return {
      type: constants.CLEAR_NOTIFICATIONS,
      notifications: response.data.notifications
    };
  } else {
    console.log("ERROR ON GETTING NOTIFICATIONS");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

// THUNKS HERE
export function getNotifications() {
  return function(dispatch) {
    // BODY OF THUNK

    // TURN SPINNER ON...
    dispatch(common.loadingChanged(true));
    // FETCH API CALL FROM SERVER THEN DISPATCH UI ACTION
    // THEN TURN SPINNER OFF
    return requestNotifications()
      .then(response => dispatch(successRequestingNotifications(response)))
      .then(response => dispatch(common.loadingChanged(false)));
    // .then(
    // turnOffSpinner => dispatch(common.loadingChanged(false))
    // )
  };
}

export function getUnreadNotifications(id) {
  return function(dispatch) {
    return requestGetUnreadNotifications(id).then(response =>
      dispatch(successRequestingNotifications(response))
    );
  };
}

export function clearNotifications(id) {
  return function(dispatch) {
    // BODY OF THUNK

    // TURN SPINNER ON...
    dispatch(common.loadingChanged(true));
    // FETCH API CALL FROM SERVER THEN DISPATCH UI ACTION
    // THEN TURN SPINNER OFF
    return requestClearNotifications(id)
      .then(response => dispatch(sucessClearingNotifications(response)))
      .then(response => dispatch(common.loadingChanged(false)));
    // .then(
    // turnOffSpinner => dispatch(common.loadingChanged(false))
    // )
  };
}

// API CALLS
function requestNotifications() {
  let requestUrl = "/api/v1/notifications";
  return Axios.get(requestUrl);
}

function requestGetUnreadNotifications(id) {
  let requestUrl = "/api/v1/users/" + id + "/unreadNotifications/";
  return Axios.get(requestUrl);
}

function requestClearNotifications(id) {
  let requestUrl = "/api/v1/users/" + id + "/clearNotifications/";
  return Axios.get(requestUrl);
}
