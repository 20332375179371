import Modal from 'react-modal'
import classnames from 'classnames'

import { ReactComponent as ModalXIcon } from '../../assets/images/common/icons/modal-X-icon.svg'
import SubmitButton from '../common/buttons/SubmitButton'
import CancelButton from '../common/buttons/CancelButton'
import PropTypes from 'prop-types'
import './ConfirmationModal2.scss'

const modalStyles = {
  content: {
    height: 'min-content',
    margin: 'auto',
    maxWidth: '500px',
    marginTop: '12%',
    borderRadius: '8px',
    overflow: 'hidden',
  },
}

const ConfirmationModal2 = ({
  showModal,
  closeModal,
  icon,
  title,
  message,
  finalMessage,
  onDeny,
  denyMessage,
  onProceed,
  proceedMessage,
  type,
}) => (
  <Modal
    isOpen={showModal || false}
    onRequestClose={closeModal}
    contentLabel='Confirmation Modal'
    style={modalStyles}
    autoFocus
  >
    <div id='ConfirmationModal2'>
      <div className='animated fadeIn'>
        <div>
          <div className='d-flex justify-content-end text-align-right'>
            <ModalXIcon id='modalXIcon' onClick={closeModal} />
          </div>
          <div className='d-flex flex-column justify-content-center text-align-center confirmation-modal-container'>
            {icon}
            {title && (
              <div className='confirmation-modal-title'>
                <p>{title}</p>
              </div>
            )}
            <p className='text-center confirmation-modal-message'>
              {message}
            </p>
            {finalMessage &&
              <p className='text-center confirmation-modal-final-message'>
                {finalMessage}
              </p>
            }
          </div>
          <div className='row py-3'>
            <div className='col-6 pl-4'>
              <CancelButton
                onClick={onDeny}
                buttonMessage={denyMessage}
                className={classnames('mt-3', { 'btn-dark': type === 'delete' })}
              />
            </div>
            <div className='col-6 pr-4'>
              <SubmitButton
                onClick={onProceed}
                buttonMessage={proceedMessage}
                buttonColor={type === 'delete' ? 'btn-red' : 'orange-button'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
)


ConfirmationModal2.propTypes = {
  showModal: PropTypes.bool,
  closeModal: PropTypes.func,
  icon: PropTypes.element,
  title: PropTypes.string,
  message: PropTypes.string,
  finalMessage: PropTypes.string,
  onDeny: PropTypes.func,
  denyMessage: PropTypes.string,
  onProceed: PropTypes.func,
  proceedMessage: PropTypes.string,
  type: PropTypes.string,
}

export default ConfirmationModal2
