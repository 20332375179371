import ReactDateTime from 'react-datetime'
import "react-datetime/css/react-datetime.css";

const DateTimePicker = (props) => {

  return (
    <ReactDateTime
      {...props}
    />
  )
}

export default DateTimePicker
