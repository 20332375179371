import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'
import FieldError from '../../../common/FieldError'
import classNames from 'classnames'
import Embed, { defaultProviders } from 'react-tiny-oembed'

import { ReactComponent as SelectedVideoIcon } from '../../../../assets/images/common/icons/programBuilder/selected-media-icon.svg'
import { setField } from '../../../../reducers/programBuilderReducer'
import SubmitButton from '../../../common/buttons/SubmitButton'
import Modal from '../../../common/Modal'

import styles from './PersonalizedVideoModal.module.scss'
import Input from '../../../common/form/Input'

const providers = defaultProviders.filter(p => ['YouTube', 'Vimeo'].includes(p.provider_name))
const libraryVideos = [
  {
    title: 'Operation36 Introduction',
    // youtube link - 'https://www.youtube.com/watch?v=XmmCT4mQT-I&ab_channel=Operation36Golf'
    url: 'https://vimeo.com/942400018',
  },
]

const PersonalizedVideoModal = ({ showModal, closeModal }) => {
  const dispatch = useDispatch()

  const [userUrl, setUserUrl] = useState('')
  const [userVideo, setUserVideo] = useState(null)
  const [selectedVideo, setSelectedVideo] = useState(null)
  const [invalidVideo, setInvalidVideo] = useState(true)
  const [errors, setErrors] = useState({})

  const EmbeddedVideoError = () => {
    useEffect(() => {
      setInvalidVideo(true)
    }, [])

    return (
      <FieldError message={'The video could not be loaded. Make sure it is a correct YouTube or Vimeo link.'} />
    )
  }

  const onUserVideoSelect = useCallback(() => {
    setUserVideo(null)
    setInvalidVideo(false)
    setTimeout(() => {
      setUserVideo(userUrl)
      setSelectedVideo(userUrl)
    }, 100)
  }, [userUrl, setUserVideo, setInvalidVideo, setSelectedVideo])

  const saveChanges = () => {
    if (formValid()) {
      dispatch(setField({ field: 'personalizedVideoUrl', value: selectedVideo }))

      exitModal()
    }
  }

  const formValid = () => {
    const errors = {}

    if (!selectedVideo) {
      errors.selectedVideo = 'Please select a video from the library or add your own using a link.'
    }

    setErrors(errors)

    return Object.keys(errors).length === 0
  }

  const exitModal = () => {
    closeModal()
    setUserUrl('')
    setUserVideo(null)
    setInvalidVideo(true)
    setErrors({})
    setSelectedVideo(null)
  }

  return (
    <Modal
      isOpen={showModal}
      onClose={exitModal}
      className={styles['personalized-video-modal-content']}
    >
      <section className='animated fadeIn'>
        <p className={styles.title}>Add a Marketing Video</p>
        <p className='mb-3'>
          Customize your program page with a marketing video. You can choose one from your favorite video platform or from our media library.
        </p>

        <div className='d-flex flex-column align-items-center justify-content-center mb-4'>
          {userVideo && (
            <div className='d-flex justify-content-center'>
              <div className={styles['link-video-container']}>
                <div
                  className={classNames(styles['link-video-preview'], { [styles['selected-video']]: selectedVideo === userVideo && !invalidVideo })}
                  onClick={() => setSelectedVideo(userVideo)}
                  aria-hidden='true'
                >
                  <Embed
                    url={userVideo}
                    providers={providers}
                    FallbackElement={<EmbeddedVideoError />}
                    LoadingFallbackElement=' '
                  />
                </div>

                {selectedVideo === userVideo && !invalidVideo && (
                  <SelectedVideoIcon className={styles['selected-video-icon']} />
                )}
              </div>
            </div>
          )}

          <div className='mt-3 w-100'>
            <Input
              name='video-link'
              type='url'
              onChange={(event) => setUserUrl(event.target.value)}
              placeholder='Enter your video link (YouTube, Vimeo)'
              value={userUrl}
            />

            <SubmitButton
              onClick={onUserVideoSelect}
              buttonMessage='Select Video from Link'
              buttonColor='orange-button'
            />
          </div>
        </div>

        <label className={styles['sub-title']} htmlFor='library'>Choose from our Library:</label>
        <div className={`row ${styles.library}`}>
          {libraryVideos.map(({ title, url }, index) => (
            <div
              className={`col-4 ${styles['library-video-container']}`}
              aria-hidden='true'
              onClick={() => setSelectedVideo(url)}
              key={index}
            >
              <div className='d-flex flex-column align-items-center justify-content-center'>
                <p className={styles['video-title']}>{title}</p>
                <div className='position-relative'>
                  <div className={classNames(styles['library-video'], { [styles['selected-video']]: selectedVideo === url })}>
                    <Embed
                      url={url}
                      providers={providers}
                    />
                  </div>

                  {selectedVideo === url && (
                    <SelectedVideoIcon className={styles['selected-video-icon']} />
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        {errors.selectedVideo && <FieldError message={errors.selectedVideo} />}

        <SubmitButton
          onClick={saveChanges}
          buttonMessage='Save Changes'
          buttonColor='orange-button'
          className='mb-4'
        />
      </section>
    </Modal>
  )
}

PersonalizedVideoModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default PersonalizedVideoModal
