import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as leaderboardActions from "../../actions/leaderboardActions";
import "../../assets/styles/components/leaderboard/LeaderboardList.scss";
import "../../assets/styles/components/activity/CommentsPage.scss";
import LeaderboardItem from "./LeaderboardItem";
import Loader from "../common/Op36Loader";
import Placeholder from "../common/Placeholder";

const styles = {
  cardStyle: {
    padding: "1em",
    marginTop: "3em",
    textAlign: "center"
  },
  containerStyle: {
    width: "100%"
  },
  messageStyle: {
    textTransform: "uppercase"
  },
  buttonStyle: {
    marginTop: "30px",
    color: "white",
    borderRadius: "5px"
  }
};

class LeaderboardList extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  componentWillMount() {
    var courseId;
    if (this.props.course) {
      courseId = this.props.course.id;
      this.props.actions.getCourseLeaderboard(
        courseId,
        this.props.type,
        this.props.startTS,
        this.props.endTS
      );
    } else if (this.props.user) {
      courseId = this.props.user.current_course_id;
      this.props.actions.getCourseLeaderboard(
        courseId,
        this.props.type,
        this.props.startTS,
        this.props.endTS
      );
    }
  }

  // componentWillReceiveProps(nextProps){
  //   if(nextProps.course){
  //     this.props.actions.getCourseLeaderboard(nextProps.course.id, this.props.type, this.props.startTS, this.props.endTS)
  //   }
  // }

  displayLeaderboardItems() {
    if (!this.props.leaderboard) {
      return (
        <div className="mt-5">
          <Loader message="loading stats" />
        </div>
      );
    } else if (this.props.leaderboard.length > 0) {
      return this.props.leaderboard.map((leaderboardItem, index) => (
        <LeaderboardItem
          key={index}
          index={index}
          points={leaderboardItem.points}
          user={leaderboardItem.user}
        />
      ));
    } else {
      return (
        <div className="container-fluid mx-auto" style={styles.containerStyle}>
          <Placeholder
            title={"No Leaders to Show"}
            icon={"fa-list-ol"}
            subtext1={
              "Add students and start getting them involved to build points"
            }
          />
        </div>
      );
    }
  }

  // LOOP THROUGH ALL ACTIVITIES IN GLOBAL STATE WITH .map
  render() {
    return (
      <div>
        <div id="LeaderboardList" className="hidden-sm-down">
          <div className="leaderBoardItemWrapper px-md-2">
            {this.displayLeaderboardItems()}
          </div>
        </div>
        <div id="LeaderboardList" className="hidden-md-up">
          <div className="componentBody container iOSscroll leaderBoardItemWrapper">
            {this.displayLeaderboardItems()}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var leaderboard = [];
  switch (ownProps.type) {
    case leaderboardActions.TYPE_WEEKLY:
      leaderboard = state.leaderboard.weekly_leaderboard;
      break;
    case leaderboardActions.TYPE_MONTHLY:
      leaderboard = state.leaderboard.monthly_leaderboard;
      break;
    case leaderboardActions.TYPE_ALL_TIME:
      leaderboard = state.leaderboard.all_time_leaderboard;
      break;
    default:
      leaderboard = [];
      console.log("ERROR: TYPE NOT SPECIFIED FOR LEADERBOARD LIST");
  }
  return {
    ui: state.ui,
    user: state.user.current_user,
    leaderboard: leaderboard
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(leaderboardActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeaderboardList);
