import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as drillActions from "../../../actions/drillActions";

import DrillsDetail from "../DrillsDetail";
import BaseModal from "../../common/BaseModal";
import Loader from "../../common/Op36Loader-web";

class DrillDetailModal extends React.Component {
  state = {
    loader: true
  };

  componentDidMount() {
    this.props.drill_actions.grabOneDrill(this.props.drillId).then(() => {
      this.setState({ loader: false });
    });
  }
  render() {
    const drill = this.props.drills ? this.props.drills.selected_drill : null;
    const { loader } = this.state;
    const {
      close,
      currentUser,
      drillsPage,
      handleFavorite,
      handlePin,
      handleEdit,
      handleDelete,
      currentPage // which view is this being called from - goes a deeper than drillsPage to actually tell the state of the parent component's view
    } = this.props;
    return (
      <BaseModal close={close} width={"80vw"}>
        {loader ? (
          <Loader message={"grabbing drill"} />
        ) : (
          <DrillsDetail
            key={1}
            drill={drill}
            fromBuilder={false}
            fromDrillsPage={drillsPage}
            isMine={drill.user_id === currentUser.id}
            awsBackupUrl={drill.video && drill.video.attachment_url}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            handleFavorite={
              drill.user_id === currentUser.id && currentPage !== "public"
                ? handlePin
                : handleFavorite
            }
            isPublicPage={currentPage === "public"}
          />
        )}
      </BaseModal>
    );
  }
}

// export default DrillDetailModal;

function mapStateToProps(state, ownProps) {
  return {
    // ui: state.ui,
    // user: state.user.current_user,
    drills: state.drills
    // tags: state.tags,
    // organizations: state.organizations,
    // pages: state.pages,
    // practice_types: state.practiceTypes
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // common_actions: bindActionCreators(commonActions, dispatch),
    drill_actions: bindActionCreators(drillActions, dispatch)
    // tag_actions: bindActionCreators(tagActions, dispatch),
    // practice_types_actions: bindActionCreators(practiceTypesActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DrillDetailModal);
