import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import _ from "lodash";

// CHILDREN
import SimplePhotoUploader from "../../common/SimplePhotoUploader";
import DraggableResourceCategory from "./DraggableResourceCategory";

// ASSETS
import "./ResourceSectionForm.scss";

class ResourceSectionForm extends Component {
  state = {
    categories:
      this.props.resourceSection &&
      this.props.resourceSection.resource_categories
        ? this.props.resourceSection.resource_categories
        : []
  };
  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    const categories = this.reorder(
      this.state.categories,
      result.source.index,
      result.destination.index
    );

    let categoriesCopy = JSON.parse(JSON.stringify(categories));

    categoriesCopy.map((category, index) => {
      category.order = index;
    });

    this.setState(
      {
        categories: categoriesCopy
      },
      () => {
        this.props.updateCategories(categoriesCopy);
      }
    );
  };

  grabCategoryData = category => {
    let categories = JSON.parse(JSON.stringify(this.state.categories));
    let categoryIndex = categories.map(c => c.id).indexOf(category.id);

    categories[categoryIndex].name = category.name;

    this.setState({ categories });
  };

  renderCategories = () => {
    const { categories } = this.state;
    const { updateCategories } = this.props;

    if (categories && categories.length > 0) {
      return categories.map((category, index) => (
        <Draggable key={category.id} draggableId={`category_${category.id}`} index={index}>
          {(provided, snapshot) => (
            <div>
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <DraggableResourceCategory
                  category={category}
                  categories={categories}
                  updateCategories={updateCategories}
                  sendCategoryData={this.grabCategoryData}
                />
              </div>
              {/* {provided.placeholder} */}
            </div>
          )}
        </Draggable>
      ));
    }
    return null;
  };

  render() {
    const {
      formState,
      loader,
      handleInputChange,
      toggleForm,
      closeModal,
      grabSectionPhotoData,
      isEditModal,
      createResourceSection,
      updateResourceSection
    } = this.props;
    const { categories } = this.state;
    return (
      <div id="ResourceSectionForm" className="row animated fadeIn">
        <div className="col-md-6">
          <label htmlFor="featured-image">Section Icon</label>
          <SimplePhotoUploader
            sendPhotoData={grabSectionPhotoData}
            selectedPhoto={formState.resource_section_uploadPhotoSrc}
          />
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="resource_section_name">Section Name</label>
            <input
              type="text"
              name="resource_section_name"
              className="form-control"
              id="resource_section_name"
              placeholder="Enter a section name"
              onChange={handleInputChange}
              value={formState.resource_section_name}
            />
          </div>
          <div className="form-group">
            <label htmlFor="resource_section_description">Description</label>
            <textarea
              type="text"
              name="resource_section_description"
              className="form-control"
              id="resource_section_description"
              placeholder="Enter description"
              onChange={handleInputChange}
              value={formState.resource_section_description}
            />
          </div>
        </div>

        {isEditModal && categories && categories.length > 0 && (
          <div className="resource-draggable-container">
            <h3>Arrange / Edit Categories</h3>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {categories
                      ? this.renderCategories(provided, snapshot)
                      : null}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        )}

        <div className="col-md-12 d-flex justify-content-center align-items-center mt-4">
          <button
            className="btn btn-dark m-2"
            onClick={
              isEditModal ? () => closeModal() : () => toggleForm("resource")
            }
            disabled={loader}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary m-2"
            onClick={
              isEditModal ? updateResourceSection : createResourceSection
            }
            disabled={loader}
          >
            {loader && (
              <i className="fa fa-spinner fa-spin light-loading-spinner" />
            )}
            {isEditModal
              ? `Updat${loader ? "ing" : "e"}`
              : `Add${loader ? "ing" : ""}`}{" "}
            Section
          </button>
        </div>
      </div>
    );
  }
}
export default ResourceSectionForm;
