import * as types from "../actions/actionTypes";

export default function skillLevels(state = [], action) {
  switch (action.type) {
    case types.GET_SKILL_LEVELS:
      return action.skillLevels;

    default:
      return state;
  }
}
