import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { UncontrolledPopover } from 'reactstrap'
import { Link } from 'react-router-dom'
import { ReactComponent as BackArrow } from '../../../../assets/images/common/icons/back-arrow-icon.svg'
import { ReactComponent as GolferIcon } from '../../../../assets/images/common/icons/golfer-half-icon.svg'
import { ReactComponent as TableSelectDropDownIcon } from '../../../../assets/images/common/icons/table-select-drop-down-icon.svg'
import { pluralize } from '../../../../utility/helperFunctions'
import moment from 'moment'
import styles from './EventHeader.module.scss'
import classNames from 'classnames'

const EventHeader = ({ event, onClickBack, openEditModal, handleLogScoresClick, setShowDeleteModal }) => {
  const timezone = useSelector(state => state.user.current_user.communities[0].timezone)
  const navigate = useNavigate()

  const attendances = (event.students?.length || 0)
  const eventDateInTimezone = moment(event.event_date).tz(timezone)
  const completed = event.status === 'completed'


  const navigateToEmails = () => {
    navigate('/announcements/new-campaign', { state: { smartToggle: 'email', students: event.students } })
  }

  return (
    <div className={classNames(styles.eventHeader, 'sticky-top')}>
      <div className='w-100 d-flex align-items-center '>
        <div className='col-9 d-flex align-items-center'>
          <span className='mr-3 h-75' role='button' tabIndex={0} onClick={onClickBack} onKeyDown={null}>
            <BackArrow style={{ width: '25px' }}/>
          </span>
          <span className={classNames(styles.eventTitle, 'mr-5')}>{event.name || event.indexName}</span>
          <span><i className='fa fa-calendar'/>{moment(eventDateInTimezone).format('dddd, MMMM D, YYYY')}</span>
          {event.location && <span><i className='fa fa-map-marker'/>{event.location}</span>}
          <span><GolferIcon fill='#fff' className='mr-2'/>{attendances} {pluralize('Golfer', attendances)} Attended</span>
        </div>
        <div className='col-3'>
          { event.status === 'completed' ?
            <div className='d-flex justify-content-center align-items-center pull-right'>
              <button id='eventMenuButton' className='btn btn-primary pl-3 pr-3 pull-right'>
                <div className='d-flex align-items-center'>
                  <span className='mr-2'>Manage Event</span>
                  <TableSelectDropDownIcon stroke='#fff' style={{ marginLeft: '2px' }}/>
                </div>
              </button>
              <UncontrolledPopover
                placement='bottom'
                target='eventMenuButton'
                trigger='legacy'
              >
                <div className={classNames('d-flex flex-column', styles.headerButtonActions)}>
                  <p aria-hidden='true' onClick={() => setShowDeleteModal(true)}>Delete Event</p>
                  <p aria-hidden='true' onClick={() => openEditModal({ action: 'Edit Results' })}>Edit Results</p>
                </div>
              </UncontrolledPopover>
            </div>
            :
            event.status === 'draft' ?
              null
              :
              <div>
                <button id='logEventButton' className='btn btn-primary pl-3 pr-3 pull-right'>
                  <div className='d-flex align-items-center'>
                    <span className='mr-2'>Manage Event</span>
                    <TableSelectDropDownIcon stroke='#fff' style={{ marginLeft: '2px' }}/>
                  </div>
                </button>
                <UncontrolledPopover
                  placement='bottom'
                  target='logEventButton'
                  trigger='legacy'
                >
                  <div className={classNames('d-flex flex-column', styles.headerButtonActions)}>
                    <p aria-hidden='true' onClick={() => openEditModal({ action: !completed ? 'Edit Roster' : '' })}>Edit Roster</p>
                    <Link to={`/series/${event.series.id}/edit?step=2`}>Edit Event</Link>
                    <p aria-hidden='true' onClick={navigateToEmails}>Send an Email</p>
                    <p aria-hidden='true' onClick={handleLogScoresClick}>Log Scores</p>
                  </div>
                </UncontrolledPopover>
              </div>
          }
        </div>
      </div>
    </div>
  )
}

EventHeader.propTypes = {
  event: PropTypes.object.isRequired,
  onClickBack: PropTypes.func.isRequired,
  openEditModal: PropTypes.func.isRequired,
  handleLogScoresClick: PropTypes.func.isRequired,
  setShowDeleteModal: PropTypes.func.isRequired,
}

export default EventHeader
