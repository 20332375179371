import React from "react";
import { Link } from "react-router-dom";

const RankObjectivesCard = props => {
  return (
    <div
      className='d-flex align-items-center'
      style={{ border: "1px solid #ccc", padding: "10px", background: "#fff" }}
    >
      <div className='icon-container'>
        <div
          style={{
            height: "60px",
            width: "60px",
            borderRadius: "50%",
            padding: "6px",
            marginRight: "10px",
            background: props.color
          }}
        >
          <img
            src={props.icon}
            alt={props.subject}
            style={{ height: "100%", width: "100%" }}
          />
        </div>
      </div>
      <div className='content-container mr-4'>
        <h5 className='mr-4 d-inline'>{props.name}</h5>
        <span
          style={{
            background: "#2a3743",
            borderRadius: "30px",
            padding: "4px",
            color: "#fff",
            fontSize: "14px"
          }}
        >
          {props.subject}
        </span>
        <p>{props.description}</p>
      </div>
      <div
        className='button-container d-flex align-items-center mr-1 ml-auto'
        style={{ border: "1px solid #2a3743" }}
      >
        <Link to={"/ranks/" + props.rankId + "/objective/" + props.id}>
          <button className='align-items-center'>
            <i className='fa fa-chevron-right' aria-hidden='true' />
          </button>
        </Link>
      </div>
    </div>
  );
};

export default RankObjectivesCard;
