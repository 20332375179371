import React, { Component } from "react";
import ReactTable from "react-table";
import createTable from "react-table-hoc-fixed-columns";
import PropTypes from 'prop-types'
// import "react-table/react-table.css";

import CSVExportModal from "./CSVExportModal";
import profile_avatar from "../../assets/images/mock/StockProfilePic.png";

const ReactTableFixedColumns = createTable(ReactTable);

const HEADERS = [
  { label: "Last Name", key: "lastname" },
  { label: "First Name", key: "firstname" },
  { label: 'Level', key: 'rank' },
  { label: 'Level Progress', key: 'rankprogress' },
  { label: "Posture", key: "posture" },
  { label: "Power", key: "power" },
  { label: "Grip", key: "grip" },
  { label: "Ball-flight", key: "ballflight" },
  { label: "Alignment", key: "alignment" },
  { label: "Putting", key: "putting" },
  { label: "Green Reading", key: "greenreading" },
  { label: "Work Ethic", key: "workethic" },
  { label: "Mastery", key: "mastery" },
  { label: "Honor", key: "honor" },
  { label: "Fitness", key: "fitness" },
  { label: "Performance", key: "performance" },
];

class CurriculumReportTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      dataLoader: false,
      data: [],
    };
  }

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  createData = () => {
    const { data } = this.props;
    let tempData = [];
    this.setState({ dataLoader: true });
    if (data.length > 0) {
      data.map((info) => {
        let lastname = info.student.last_name;
        let firstname = info.student.first_name;
        let rank = info.current_rank.name;
        let rankprogress = info.percent_rank_complete.toFixed(1) + "%";
        let posture = info.report[8].has_completed_objective
          ? String.fromCharCode(0x2713)
          : "---";
        let power = info.report[9].has_completed_objective
          ? String.fromCharCode(0x2713)
          : "---";
        let grip = info.report[4].has_completed_objective
          ? String.fromCharCode(0x2713)
          : "---";
        let ballflight = info.report[1].has_completed_objective
          ? String.fromCharCode(0x2713)
          : "---";
        let alignment = info.report[0].has_completed_objective
          ? String.fromCharCode(0x2713)
          : "---";
        let putting = info.report[10].has_completed_objective
          ? String.fromCharCode(0x2713)
          : "---";
        let greenreading = info.report[3].has_completed_objective
          ? String.fromCharCode(0x2713)
          : "---";
        let workethic = info.report[11].has_completed_objective
          ? String.fromCharCode(0x2713)
          : "---";
        let mastery = info.report[6].has_completed_objective
          ? String.fromCharCode(0x2713)
          : "---";
        let honor = info.report[5].has_completed_objective
          ? String.fromCharCode(0x2713)
          : "---";
        let fitness = info.report[2].has_completed_objective
          ? String.fromCharCode(0x2713)
          : "---";
        let performance = info.report[7].has_completed_objective
          ? String.fromCharCode(0x2713)
          : "---";

        tempData.push({
          lastname,
          firstname,
          rank,
          rankprogress,
          posture,
          power,
          grip,
          ballflight,
          alignment,
          putting,
          greenreading,
          workethic,
          mastery,
          honor,
          fitness,
          performance,
        });
      });
    }

    this.setState({
      showModal: !this.state.showModal,
      data: tempData,
    });
  };

  renderUserAvatarCell = (props) => {
    return (
      <div
        className='number'
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        key={props.value ? props.value.id : null}
        onClick={() =>
          this.props.toProfile(props.value ? props.value.id : null)
        }
      >
        <div className='small-round-image-wrapper'>
          {props.value ? (
            <img
              className='round-image'
              src={
                props.value.avatar
                  ? props.value.avatar.file_url
                  : profile_avatar
              }
              alt={
                props.value
                  ? `${props.value.first_name} ${props.value.last_name}`
                  : ""
              }
            />
          ) : null}
        </div>
      </div>
    );
  };
  renderTableCellName = (props) => {
    return (
      <div
        className='number'
        style={{
          backgroundColor: "#2b3843",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ color: props.value ? props.value.color_hex : "white" }}>
          {props.value ? props.value.name : "---"}
        </div>
      </div>
    );
  };
  renderRankProgress = (props) => {
    return (
      <div
        className='number'
        style={{
          backgroundColor: "#2b3843",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ color: "white" }}>
          {props.value ? `${Math.round(props.value)}%` : "---"}
        </div>
      </div>
    );
  };
  // renderCurriculumProgress = props => {
  //   let currentRank = props.value.current_rank
  //     ? props.value.current_rank.id
  //     : null;
  //   let op36RankCount = 6;
  //   let op36SubjectCount = 12;

  //   if (currentRank) {
  //     let rankFraction = currentRank / op36RankCount;
  //     let rankMatchArray = [];

  //     props.value.report.map(report => {
  //       if (report.rank) {
  //         if (report.rank.id === currentRank) {
  //           rankMatchArray.push(report.rank.id);
  //         }
  //       }
  //     });

  //     let subjectFraction = rankMatchArray.length / op36SubjectCount;

  //     return (
  //       <div
  //         className="number"
  //         style={{
  //           backgroundColor: "#2b3843",
  //           width: "100%",
  //           height: "100%",
  //           display: "flex",
  //           justifyContent: "center",
  //           alignItems: "center"
  //         }}
  //       >
  //         <div style={{ color: "white" }}>
  //           {`${Math.round((rankFraction + subjectFraction) * 100) - 17}%`}
  //         </div>
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <div
  //         className="number"
  //         style={{
  //           backgroundColor: "#2b3843",
  //           width: "100%",
  //           height: "100%",
  //           display: "flex",
  //           justifyContent: "center",
  //           alignItems: "center"
  //         }}
  //       >
  //         <div style={{ color: "white" }}>---</div>
  //       </div>
  //     );
  //   }
  // };
  renderTableCell = (props) => {
    return (
      <div
        className='number'
        style={{
          backgroundColor: props.value ? props.row.current_rank.color_hex : "",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            color: props.value ? "white" : "",
          }}
        >
          {props.value ? String.fromCharCode(0x2713) : "---"}
        </div>
      </div>
    );
  };
  handleRankSort = (a, b, desc) => {
    a = a === null || a === undefined ? "" : a.id;
    b = b === null || b === undefined ? "" : b.id;

    if (a > b) {
      return 1;
    }
    if (a < b) {
      return -1;
    }
    return 0;
  };
  handleRankProgressSort = (a, b, desc) => {
    a = a === null || a === undefined ? "" : a;
    b = b === null || b === undefined ? "" : b;

    // a = typeof a === 'string' ? a.toLowerCase() : a
    // b = typeof b === 'string' ? b.toLowerCase() : b

    if (a > b) {
      return 1;
    }
    if (a < b) {
      return -1;
    }
    return 0;
  };
  renderTable = () => {
    const { loader, data } = this.props;
    const columns = [
      {
        Header: "",
        accessor: "student",
        Cell: (props) => this.renderUserAvatarCell(props),
        sortable: false,
        fixed: true,
      },
      {
        Header: "First Name",
        accessor: "student.first_name",
        fixed: true,
      },
      {
        Header: "Last Name",
        accessor: "student.last_name",
        fixed: true,
      },
      // {
      //   Header: "Curriculum Progress",
      //   accessor: "",
      //   Cell: props => this.renderCurriculumProgress(props),
      //   style: { padding: 0 },
      //   sortable: false
      // },
      {
        Header: 'Level',
        accessor: "current_rank",
        Cell: (props) => this.renderTableCellName(props),
        style: { padding: 0 },
        sortMethod: (a, b, desc) => this.handleRankSort(a, b, desc),
      },
      {
        Header: 'Level Progress',
        accessor: "percent_rank_complete",
        Cell: (props) => this.renderRankProgress(props),
        style: { padding: 0 },
        sortMethod: (a, b, desc) => this.handleRankProgressSort(a, b, desc),
      },
      {
        Header: "Posture",
        accessor: "report[8].has_completed_objective",
        Cell: (props) => this.renderTableCell(props),
        style: { padding: 0 },
        sortMethod: (a, b, desc) => this.handleRankSort(a, b, desc),
      },
      {
        Header: "Power",
        accessor: "report[9].has_completed_objective",
        Cell: (props) => this.renderTableCell(props),
        style: { padding: 0 },
        sortMethod: (a, b, desc) => this.handleRankSort(a, b, desc),
      },
      {
        Header: "Grip",
        accessor: "report[4].has_completed_objective",
        Cell: (props) => this.renderTableCell(props),
        style: { padding: 0 },
        sortMethod: (a, b, desc) => this.handleRankSort(a, b, desc),
      },
      {
        Header: "Ball-flight",
        accessor: "report[1].has_completed_objective",
        Cell: (props) => this.renderTableCell(props),
        style: { padding: 0 },
        sortMethod: (a, b, desc) => this.handleRankSort(a, b, desc),
      },
      {
        Header: "Alignment",
        accessor: "report[0].has_completed_objective",
        Cell: (props) => this.renderTableCell(props),
        style: { padding: 0 },
        sortMethod: (a, b, desc) => this.handleRankSort(a, b, desc),
      },
      {
        Header: "Putting",
        accessor: "report[10].has_completed_objective",
        Cell: (props) => this.renderTableCell(props),
        style: { padding: 0 },
        sortMethod: (a, b, desc) => this.handleRankSort(a, b, desc),
      },
      {
        Header: "Green Reading",
        accessor: "report[3].has_completed_objective",
        Cell: (props) => this.renderTableCell(props),
        style: { padding: 0 },
        sortMethod: (a, b, desc) => this.handleRankSort(a, b, desc),
      },
      {
        Header: "Work Ethic",
        accessor: "report[11].has_completed_objective",
        Cell: (props) => this.renderTableCell(props),
        style: { padding: 0 },
        sortMethod: (a, b, desc) => this.handleRankSort(a, b, desc),
      },
      {
        Header: "Mastery",
        accessor: "report[6].has_completed_objective",
        Cell: (props) => this.renderTableCell(props),
        style: { padding: 0 },
        sortMethod: (a, b, desc) => this.handleRankSort(a, b, desc),
      },
      {
        Header: "Honor",
        accessor: "report[5].has_completed_objective",
        Cell: (props) => this.renderTableCell(props),
        style: { padding: 0 },
        sortMethod: (a, b, desc) => this.handleRankSort(a, b, desc),
      },
      {
        Header: "Fitness",
        accessor: "report[2].has_completed_objective",
        Cell: (props) => this.renderTableCell(props),
        style: { padding: 0 },
        sortMethod: (a, b, desc) => this.handleRankSort(a, b, desc),
      },
      {
        Header: "Performance",
        accessor: "report[7].has_completed_objective",
        Cell: (props) => this.renderTableCell(props),
        style: { padding: 0 },
        sortMethod: (a, b, desc) => this.handleRankSort(a, b, desc),
      },
    ];

    return (
      <div className='container my-5 animated fadeIn'>
        <div className='row mb-5 d-flex align-items-center'>
          <div className='col-md-4'>
            <h2>{this.props.title}</h2>
          </div>
          <div className='col-md-4 d-flex align-items-center'>
            <button
              className='btn btn-primary mr-2 refresh-button'
              onClick={this.props.resetProgramSearch}
            >
              <i className='fa fa-undo' aria-hidden='true' />
            </button>
            <select
              onChange={this.props.handleProgramSelected}
              value={this.props.programSelected}
              name='program'
              className='form-control'
              disabled={this.props.programLoader || this.props.loader}
            >
              <option value={-1} disabled>
                {this.props.programLoader
                  ? "Loading Classes..."
                  : 'Filter by Class (optional)'}
              </option>
              {this.props.renderPrograms()}
            </select>
          </div>
          <div className='col-md-4'>
            <button
              className='btn btn-sm btn-block'
              onClick={() => this.props.goBack()}
            >
              <i
                className='fa fa-arrow-left text-white mr-1'
                aria-hidden='true'
              />{" "}
              View Another Report
            </button>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <ReactTableFixedColumns
              data={!loader ? data : []}
              columns={columns}
              defaultPageSize={10}
              loading={this.props.loader}
              loadingText='Loading Report'
              noDataText={this.props.loader ? "" : "No data found"}
            />
          </div>
        </div>
        {this.props.loader ? (
          ""
        ) : (
          <div>
            <button
              className='btn btn-primary mr-3 mt-3'
              onClick={this.props.showCurriculumModal}
            >
              <i className='fa fa-print text-white mr-1' aria-hidden='true' />{" "}
              Print Overview
            </button>
            <button onClick={this.createData} className='mt-3 btn btn-success'>
              Export as CSV
            </button>
          </div>
        )}
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderTable()}
        <CSVExportModal
          headers={HEADERS}
          data={this.state.data}
          showModal={this.state.showModal}
          toggleModal={this.toggleModal}
          reportType={"Golfer Curriculum Summary"}
          filename={"curriculum-summary.csv"}
        />
      </div>
    );
  }
}

CurriculumReportTable.propTypes = {
  programLoader: PropTypes.bool,
  loader: PropTypes.bool,
}

export default CurriculumReportTable;
