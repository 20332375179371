import * as types from "../actions/actionTypes";

// state = [] : Start out with no accounts
export default function resource(state = [], action) {
  switch (action.type) {
    case types.GET_RESOURCE:
      return action.resource;

    default:
      return state;
  }
}
