import { ReactComponent as GolferIcon } from '../../../assets/images/common/icons/golfer-half-icon.svg'
import styles from './CoachDashboardScheduleSessionCard.module.scss'
import StockProfilePic from '../../../assets/images/mock/default-profile-icon.png'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

const CoachDashboardScheduleEventCard = ({ event }) => {
  const navigate = useNavigate()

  return (
    <div
      className={styles.scheduleSessionCard}
      onClick={() => navigate(`/events/${event.id}`)}
      role='button'
      tabIndex={0}
      onKeyDown={(e) => { e.key === 'Enter' && navigate(`/events/${event.id}`) }}
    >
      <div className={styles.header}>
        <p className={styles.title}>{`${event.seriesName} - ${event.name}`}</p>
      </div>
      <p className={styles.subtitle}>{event.format}</p>

      <div>
        <i className={`fa fa-clock-o ${styles.faIcon}`}/>
        <span className={styles.text}>
          {event.date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }).toLowerCase()}
        </span>
      </div>

      {
        event.location &&
        <div>
          <i className={`fa fa-map-marker ${styles.faIcon}`}/><span className={styles.text}>{event.location}</span>
        </div>
      }

      <div className={styles.enrollments}>
        <GolferIcon className={styles.icon}/>
        <span className={styles.textStudents}>{event.students.length} Students</span>
      </div>

      <div className={styles.studentsProfiles}>
        {event.students.slice(0, 4).map((url, index) =>
          <div
            className={styles.profileImg}
            key={index}
            style={{
              backgroundImage: url
                ? `url(${url})`
                : `url(${StockProfilePic})`,
            }}
          />
        )}
        {event.students.length > 4 && <div className={styles.moreProfiles}>+ {event.students.length - 4} more</div>}
      </div>

    </div>
  )
}

CoachDashboardScheduleEventCard.propTypes = {
  event: PropTypes.shape({
    date: PropTypes.object,
    location: PropTypes.string,
    id: PropTypes.number,
    seriesName: PropTypes.string,
    name: PropTypes.string,
    format: PropTypes.string,
    students: PropTypes.array,
  }),
}

export default CoachDashboardScheduleEventCard
