import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import classnames from 'classnames'

import { initializePendo } from '../../utility/pendo'
import { setRequestInformationModalOpen } from '../../reducers/communityLandingPageReducer'
import { getCommunityLandingPageData } from '../../actions/communitiesActions'
import { createCommunityView } from '../../actions/viewActions'
import Loader from '../common/Op36Loader-web'
import LockError from '../common/LockError'
import TooltipText from '../common/TooltipText'
import CommunityDetails from './CommunityDetails'
import ProgramsScreen from './ProgramsScreen'
import CommunityContactModal from '../community-contact-modal/CommunityContactModal'
import Tooltip from '../common/Tooltip'

import classes from './CommunityLandingPage.module.scss'
import globals from '../../assets/styles/globals.scss'

const CommunityLandingPage = () => {
  const dispatch = useDispatch()
  const params = useParams()

  const user = useSelector(state => state.user.current_user)
  const { errors, community, isRequestInformationModalOpen, isLoadingViewCreate } = useSelector(state => state.communityLandingPage)
  const [areDetailsVisible, setDetailsVisible] = useState(false)

  useEffect(() => {
    if (!community) {
      const id = params.communityId
      dispatch(getCommunityLandingPageData(id)).unwrap()
        .then(() => dispatch(createCommunityView(id)))
    }
  }, [community, dispatch, params.communityId])

  useEffect(() => {
    if (window.pendo && user) {
      initializePendo(window.pendo, user)
    }
  }, [user])

  if (errors) {
    return (
      <LockError
        fullscreen
        titleText={errors.title}
        linkAttributes={{
          linkMessage: 'Browser other communities',
          navigateTo: '/community-map',
        }}
      >
        <p>{errors.message}</p>
      </LockError>
    )
  }

  if (!community || isLoadingViewCreate) {
    return <Loader className='bg-white' message='loading community' />
  }

  return (
    <div className='h-100 w-100 d-flex flex-column flex-lg-row'>
      <div className={classnames('d-flex flex-column', classes.detailsPanelWrapper, { 'flex-grow-1': areDetailsVisible })}>
        <div
          style={{
            background: community.headerImage
              ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${community?.headerImage?.fileUrl}) center/cover no-repeat`
              : globals.darkNavy,
          }}
          className={classnames('flex-shrink-0 d-flex flex-column justify-content-lg-center align-items-lg-center p-3', classes.header)}
        >
          <div className='d-none d-lg-block large-round-image-wrapper mb-4'>
            <img className='round-image' src={community.photo.fileUrl} alt='community avatar' />
          </div>
          <h1 className={classnames('px-lg-3', classes.name)}>{community.name}</h1>
          <TooltipText className={classnames('px-3', classes.address)} text={community.address.name} />
          {!!community.bookingUrl && (
            <a
              href={community.bookingUrl}
              className={classnames('d-lg-none', classes.bookingLink)}
              target='_blank'
            >
              Book a Private Lesson <i className={classnames('ml-1 fa fa-angle-right', classes.angleRight)} />
            </a>
          )}
          <div className='d-flex d-lg-none justify-content-between'>
            <button
              className={classes.switchViewModeButton}
              onClick={() => setDetailsVisible(value => !value)}
            >
              {areDetailsVisible ? 'See Programs' : 'About Club'} <i className={classnames('ml-1 fa fa-angle-right', classes.angleRight)} />
            </button>
            <button
              className={classes.haveQuestionsButton}
              onClick={() => dispatch(setRequestInformationModalOpen(true))}
            >
              Have Questions?
            </button>
          </div>
        </div>
        <CommunityDetails className={classnames('flex-grow-1 d-lg-flex', areDetailsVisible ? 'd-flex' : 'd-none')} />
      </div>
      <ProgramsScreen className={classnames('d-lg-flex flex-grow-1', areDetailsVisible ? 'd-none' : 'd-flex')} />
      <CommunityContactModal
        communityId={community.id}
        communityName={community.name}
        isOpen={isRequestInformationModalOpen}
        onClose={() => dispatch(setRequestInformationModalOpen(false))}
      />
      <Tooltip />
    </div>
  )
}

export default CommunityLandingPage
