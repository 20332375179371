import React, { Component } from 'react';
import Slider from '../../common/Slider';

import './Goals.scss';

class NewGoalTask extends Component {
  constructor(props) {
    super(props);

    if (props.sendData){
      props.sendData(this.getData.bind(this));
    }

    this.state = {
      taskId: this.props.isEdit ? this.props.task[2] : -1,
      progress: this.props.isEdit ? this.props.task[0] : 0,
      objectiveDesc: this.props.isEdit ? this.props.task[1] : ''
    };
  }

  getData = () => {
    return [this.state.progress, this.state.objectiveDesc, this.state.taskId];
  }


  handleInputChange = (index, event) => {
    this.setState({
      [event.target.name]: event.target.value,

    }, () => {
      this.props.onObjectChange(index, this.state.objectiveDesc, this.state.progress, this.state.taskId)
    })
  }

  onProgressChange = (index, value) => {
    this.setState({
      progress: value
    }, () => {
      this.props.onObjectChange(index, this.state.objectiveDesc, this.state.progress, this.state.taskId)
    })
  }

  render() {
    return (
      <div className="goal-task-container">
        <div className="col-md-6">
          <div className="form-group goal-task-input-container">
            <p className="goal-task-index">{this.props.index + 1}.</p>
            <input type="text" name='objectiveDesc' className="form-control goal-task-input" id="play-activity-location" placeholder="Short Objective Description" onChange={this.handleInputChange.bind(this, this.props.index)} value={this.state.objectiveDesc}/>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <Slider
              min={0}
              max={100}
              step={5}
              value={this.state.progress}
              onChange={this.onProgressChange.bind(this, this.props.index)}
              trackStyle={{ backgroundColor: '#ff6633', height: 4 }}
              handleStyle={{
                borderColor: '#ff6633',
                backgroundColor: '#ff6633',
              }} />
          </div>
          <p className="goal-completion-text">{`Objective Completion: ${this.state.progress}%`}</p>
        </div>
      </div>
    )
  }
}

export default NewGoalTask;
