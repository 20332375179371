import React, { Component } from "react";

// ASSETS
import "./AnimatedCompletedEducationBadge.scss";

// ACTIVE BADGES
import ActiveExpressBadge from "../../assets/images/education/expressCompleted.png";

// INACTIVE BADGES
import InactiveExpressBadge from "../../assets/images/education/expressNotCompleted.png";

class AnimatedCompletedEducationBadge extends Component {
  state = {
    inactiveBadge: null,
    activeBadge: null,
    animateBadge: false
  };

  componentDidMount() {
    this.educationSetBadge();
    this.triggerBadgeAnimation();
  }

  triggerBadgeAnimation = () => {
    setTimeout(() => {
      this.setState({
        animateBadge: true
      });
    }, 750);
  };

  toggleBadges = (activeBadge, inactiveBadge) => {
    if (activeBadge && inactiveBadge) {
      this.setState({
        activeBadge,
        inactiveBadge
      });
    } else {
      this.setState({ activeBadge: null, inactiveBadge: null });
    }
  };

  educationSetBadge = () => {
    const { education } = this.props;

    switch (education.id) {
      case 1:
        this.toggleBadges(ActiveExpressBadge, InactiveExpressBadge);
        break;
      default:
        this.toggleBadges();
        break;
    }
  };

  renderEducationBadge = () => {
    const { activeBadge, inactiveBadge, animateBadge } = this.state;
    const { education } = this.props;

    if (activeBadge && inactiveBadge) {
      return (
        <div className="education-badge-image-wrapper">
          <img
            className={animateBadge ? "animated bounceIn" : ""}
            src={animateBadge ? activeBadge : inactiveBadge}
            alt={education.name}
          />
        </div>
      );
    }
    return (
      <div
        className={`empty-education-badge-wrapper ${animateBadge &&
          "animated-badge-wrapper animated bounceIn"}`}
      >
        <i className="fa fa-thumbs-up empty-education-badge" />
      </div>
    );
  };

  render() {
    return (
      <div id="AnimatedCompletedEducationBadge">
        {this.renderEducationBadge()}
      </div>
    );
  }
}

export default AnimatedCompletedEducationBadge;
