import React, { Component } from "react";
import ReactTable from "react-table";
import createTable from "react-table-hoc-fixed-columns";
// import "react-table/react-table.css";

import CSVExportModal from "./CSVExportModal";
import profile_avatar from "../../assets/images/mock/StockProfilePic.png";

const ReactTableFixedColumns = createTable(ReactTable);

const HEADERS = [
  { label: "Last Name", key: "lastname" },
  { label: "First Name", key: "firstname" },
  { label: "Last Play", key: "lastplay" },
  { label: "Last Train", key: "lasttrain" },
  { label: "Last Goal", key: "lastgoal" }
];

class StudentEngagementTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      dataLoader: false,
      data: []
    };
  }

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  createData = () => {
    const { data } = this.props;
    let tempData = [];
    this.setState({ dataLoader: true });
    if (data.length > 0) {
      data.map(info => {
        let lastname = info.student.last_name;
        let firstname = info.student.first_name;
        let lastplay = info.report.play
          ? Math.round(info.report.play.days_since) + " day(s) ago"
          : "Not Logged";
        let lasttrain = info.report.train
          ? Math.round(info.report.train.days_since) + " day(s) ago"
          : "Not Logged";
        let lastgoal = info.report.goal
          ? Math.round(info.report.goal.days_since) + " day(s) ago"
          : "Not Logged";

        tempData.push({
          lastname,
          firstname,
          lastplay,
          lasttrain,
          lastgoal
        });
      });
    }

    this.setState({
      showModal: !this.state.showModal,
      data: tempData,
    });
  };

  renderUserAvatarCell = props => {
    return (
      <div
        className='number'
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer"
        }}
        onClick={() => this.props.toProfile(props.value && props.value.id)}
      >
        <div className='small-round-image-wrapper'>
          <img
            className='round-image'
            src={
              props.value && props.value.avatar
                ? props.value.avatar.file_url
                : profile_avatar
            }
            alt={`${props.value.first_name} ${props.value.last_name}`}
          />
        </div>
      </div>
    );
  };
  renderTableCell = props => {
    let daysSince = props.value ? props.value.days_since : -1;

    if (daysSince > -1) {
      daysSince = Math.round(daysSince);
    }

    return (
      <div
        className='number'
        style={{
          backgroundColor: props.value ? props.value.status : "#2b3843",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div>
          <p
            style={{
              color: "white",
              margin: "0px",
              backgroundColor: daysSince > -1 ? "rgba(0, 0, 0, 0.5)" : "",
              borderRadius: daysSince > -1 ? "500vh" : "",
              padding: "1px 10px",
              fontSize: "12px"
            }}
          >
            {daysSince > -1 ? `${daysSince} day(s) ago` : "Not Logged"}
          </p>
        </div>
      </div>
    );
  };
  lastActionSort = (a, b, desc) => {
    a = a === null || a === undefined ? 999999 : a.days_since;
    b = b === null || b === undefined ? 999999 : b.days_since;

    if (a > b) {
      return 1;
    }
    if (a < b) {
      return -1;
    }
    return 0;
  };
  renderTable = () => {
    const { loader, data } = this.props;
    const columns = [
      {
        Header: "",
        accessor: "student",
        Cell: props => this.renderUserAvatarCell(props),
        sortable: false,
        fixed: true
      },
      {
        Header: "First Name",
        accessor: "student.first_name",
        fixed: true
      },
      {
        Header: "Last Name",
        accessor: "student.last_name",
        fixed: true
      },
      {
        Header: "Last Play",
        accessor: "report.play",
        Cell: props => this.renderTableCell(props),
        style: { padding: 0 },
        sortMethod: (a, b, desc) => this.lastActionSort(a, b, desc)
      },
      {
        Header: "Last Train",
        accessor: "report.train",
        Cell: props => this.renderTableCell(props),
        style: { padding: 0 },
        sortMethod: (a, b, desc) => this.lastActionSort(a, b, desc)
      },
      {
        Header: "Last Goal",
        accessor: "report.goal",
        Cell: props => this.renderTableCell(props),
        style: { padding: 0 },
        sortMethod: (a, b, desc) => this.lastActionSort(a, b, desc)
      }
    ];

    return (
      <div className='container my-5 animated fadeIn'>
        <div className='row mb-5 d-flex align-items-center'>
          <div className='col-md-4'>
            <h2>{this.props.title}</h2>
          </div>
          <div className='col-md-4 d-flex align-items-center'>
            <button
              className='btn btn-primary mr-2 refresh-button'
              onClick={this.props.resetProgramSearch}
            >
              <i className='fa fa-undo' aria-hidden='true' />
            </button>
            <select
              onChange={this.props.handleProgramSelected}
              value={this.props.programSelected}
              name='program'
              className='form-control'
            >
              <option value={-1} disabled>
                {this.props.programLoader
                  ? "Loading Classes..."
                  : "Select Class (optional)"}
              </option>
              {this.props.renderPrograms()}
            </select>
          </div>
          <div className='col-md-4'>
            <button
              className='btn btn-sm btn-block'
              onClick={() => this.props.goBack()}
            >
              <i
                className='fa fa-arrow-left text-white mr-1'
                aria-hidden='true'
              />{" "}
              View Another Report
            </button>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <ReactTableFixedColumns
              data={!loader ? data : []}
              columns={columns}
              defaultPageSize={10}
              loading={this.props.loader}
              loadingText='Loading Report'
              noDataText={this.props.loader ? "" : "No data found"}
            />
          </div>
        </div>
        <button onClick={this.createData} className='mt-3 btn btn-success'>
          Export as CSV
        </button>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderTable()}
        <CSVExportModal
          headers={HEADERS}
          data={this.state.data}
          showModal={this.state.showModal}
          toggleModal={this.toggleModal}
          reportType={"Golfer Engagement Summary"}
          filename={"student-engagement.csv"}
        />
      </div>
    );
  }
}

export default StudentEngagementTable;
