import React, { Component } from "react";
import ReactTable from "react-table";
import createTable from "react-table-hoc-fixed-columns";
// import "react-table/react-table.css";

import CSVExportModal from "./CSVExportModal";
import profile_avatar from "../../assets/images/mock/StockProfilePic.png";

const ReactTableFixedColumns = createTable(ReactTable);

const HEADERS = [
  { label: "Last Name", key: "lastname" },
  { label: "First Name", key: "firstname" },
  { label: "Points Earned", key: "pointsearned" },
  { label: "Total Activities", key: "totalactivities" },
  { label: "Total Practices", key: "totalpractices" },
  { label: "Total Social Actions", key: "totalsocialactions" },
  { label: "Goals Completed", key: "goalscompleted" },
  { label: 'Op36 Levels Completed', key: 'op36rankscompleted' },
  { label: "Rounds Played", key: "roundsplayed" },
  { label: "9 Hole Rounds Played", key: "nineholeroundsplayed" },
  { label: "18 Hole Rounds Played", key: "eighteenholeroundsplayed" }
];

class StudentStatsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      dataLoader: false,
      data: []
    };
  }

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  createData = () => {
    const { data } = this.props;
    let tempData = [];
    this.setState({ dataLoader: true });
    if (data.length > 0) {
      data.map(info => {
        let lastname = info.student.last_name;
        let firstname = info.student.first_name;
        let pointsearned = info.report.stats.points;
        let totalactivities = info.report.stats.total_activities;
        let totalpractices = info.report.stats.total_train_activities;
        let totalsocialactions = info.report.stats.total_social_activities;
        let goalscompleted = info.report.stats.total_goal_activities;
        let op36rankscompleted = info.report.stats.ranks_completed;
        let roundsplayed = info.report.stats.total_play_activities;
        let nineholeroundsplayed = info.report.stats.nine_hole_rounds;
        let eighteenholeroundsplayed = info.report.stats.eighteen_hole_rounds;

        tempData.push({
          lastname,
          firstname,
          pointsearned,
          totalactivities,
          totalpractices,
          totalsocialactions,
          goalscompleted,
          op36rankscompleted,
          roundsplayed,
          nineholeroundsplayed,
          eighteenholeroundsplayed
        });
      });
    }

    this.setState({
      showModal: !this.state.showModal,
      data: tempData,
    });
  };

  renderUserAvatarCell = props => {
    return (
      <div
        className='number'
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer"
        }}
        key={props.value ? props.value.id : null}
        onClick={() =>
          this.props.toProfile(props.value ? props.value.id : null)
        }
      >
        <div className='small-round-image-wrapper'>
          {props.value ? (
            <img
              className='round-image'
              src={
                props.value.avatar
                  ? props.value.avatar.file_url
                  : profile_avatar
              }
              alt={
                props.value
                  ? `${props.value.first_name} ${props.value.last_name}`
                  : ""
              }
            />
          ) : null}
        </div>
      </div>
    );
  };
  renderTableBGColor = type => {
    switch (type) {
      case "play":
        return "#00ddb0";
      case "train":
        return "#00aee5";
      case "social":
        return "#6775e5";
      case "goal":
        return "#f48e00";
      case "rank":
        return "#f48e00";
      case "main":
        return "#2b3843";
      default:
        return "";
    }
  };
  renderTableCell = (props, type) => {
    return (
      <div
        className='number'
        style={{
          backgroundColor: this.renderTableBGColor(type),
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div style={{ color: !type ? "#2b3843" : "white" }}>{props.value}</div>
      </div>
    );
  };
  renderMainTableCell = props => {
    return (
      <div
        className='number'
        style={{
          backgroundColor: "#2b3843",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div style={{ color: "white" }}>{props.value}</div>
      </div>
    );
  };
  lastActionSort = (a, b, desc) => {
    a = a === null || a === undefined ? "" : a.days_since;
    b = b === null || b === undefined ? "" : b.days_since;

    if (a > b) {
      return 1;
    }
    if (a < b) {
      return -1;
    }
    return 0;
  };
  renderTable = () => {
    const { loader, data } = this.props;
    const columns = [
      {
        Header: "",
        accessor: "student",
        Cell: props => this.renderUserAvatarCell(props),
        sortable: false,
        fixed: true
      },
      {
        Header: "First Name",
        accessor: "student.first_name",
        fixed: true
      },
      {
        Header: "Last Name",
        accessor: "student.last_name",
        fixed: true
      },
      {
        Header: "Points Earned",
        accessor: "report.stats.points",
        Cell: props => this.renderTableCell(props, "main"),
        style: { padding: 0 }
      },
      {
        Header: "Total Activities",
        accessor: "report.stats.total_activities",
        Cell: props => this.renderTableCell(props, "main"),
        style: { padding: 0 }
      },
      {
        Header: "Total Practices",
        accessor: "report.stats.total_train_activities",
        Cell: props => this.renderTableCell(props, "train"),
        style: { padding: 0 }
      },
      {
        Header: "Total Social Actions",
        accessor: "report.stats.total_social_activities",
        Cell: props => this.renderTableCell(props, "social"),
        style: { padding: 0 }
      },
      {
        Header: "Goals Completed",
        accessor: "report.stats.total_goal_activities",
        Cell: props => this.renderTableCell(props, "goal"),
        style: { padding: 0 }
      },
      {
        Header: 'Op36 Levels Completed',
        accessor: "report.stats.ranks_completed",
        Cell: props => this.renderTableCell(props, "rank"),
        style: { padding: 0 }
      },
      {
        Header: "Rounds Played",
        accessor: "report.stats.total_play_activities",
        Cell: props => this.renderTableCell(props, "play"),
        style: { padding: 0 }
      },
      {
        Header: "9 Hole Rounds Played",
        accessor: "report.stats.nine_hole_rounds",
        Cell: props => this.renderTableCell(props),
        style: { padding: 0 }
      },
      {
        Header: "18 Hole Rounds Played",
        accessor: "report.stats.eighteen_hole_rounds",
        Cell: props => this.renderTableCell(props),
        style: { padding: 0 }
      }
    ];

    return (
      <div className='container my-5 animated fadeIn'>
        <div className='row mb-5 d-flex align-items-center'>
          <div className='col-md-4'>
            <h2>{this.props.title}</h2>
          </div>
          <div className='col-md-4 d-flex align-items-center'>
            <button
              className='btn btn-primary mr-2 refresh-button'
              onClick={this.props.resetProgramSearch}
            >
              <i className='fa fa-undo' aria-hidden='true' />
            </button>
            <select
              onChange={this.props.handleProgramSelected}
              value={this.props.programSelected}
              name='program'
              className='form-control'
            >
              <option value={-1} disabled>
                {this.props.programLoader
                  ? "Loading Classes..."
                  : "Select Class (optional)"}
              </option>
              {this.props.renderPrograms()}
            </select>
          </div>
          <div className='col-md-4'>
            <button
              className='btn btn-sm btn-block'
              onClick={() => this.props.goBack()}
            >
              <i
                className='fa fa-arrow-left text-white mr-1'
                aria-hidden='true'
              />{" "}
              View Another Report
            </button>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <ReactTableFixedColumns
              data={!loader ? data : []}
              columns={columns}
              defaultPageSize={10}
              loading={this.props.loader}
              loadingText='Loading Report'
              noDataText={this.props.loader ? "" : "No data found"}
            />
          </div>
        </div>
        <button onClick={this.createData} className='mt-3 btn btn-success'>
          Export as CSV
        </button>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderTable()}
        <CSVExportModal
          headers={HEADERS}
          data={this.state.data}
          showModal={this.state.showModal}
          toggleModal={this.toggleModal}
          reportType={"Golfer Activity Summary (All Time)"}
          filename={"golfer-activity-summary.csv"}
        />
      </div>
    );
  }
}

export default StudentStatsTable;
