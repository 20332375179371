import PropTypes from 'prop-types'
import classnames from 'classnames'

import classes from './LoadingSpinner.module.scss'

const LoadingSpinner = ({ text, classNames }) => (
  <div
    className={classnames('pt-4 pb-1 d-flex flex-column justify-content-center align-items-center', { [classNames?.wrapper]: !!classNames?.wrapper })}
  >
    <i className={classnames('fa fa-spinner fa-spin', { [classNames?.spinner]: !!classNames?.spinner })} />
    {!!text && <h1 className={classnames('mt-4 mb-0', classes.text)}>{text}</h1>}
  </div>
)


LoadingSpinner.propTypes = {
  text: PropTypes.string,
  classNames: PropTypes.shape({
    wrapper: PropTypes.string,
    spinner: PropTypes.string,
  }),
}

export default LoadingSpinner
