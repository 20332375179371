import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import CoachEmailInfo from './CoachEmailInfo'
import QRCode from 'qrcode.react'
import { Col, Container, Row } from 'reactstrap'

import './RegistrationCompletedSimple.scss'
import AppMock from '../../../../assets/images/mock/successful-registration-phone-details.webp'
import AppBackgroundMock from '../../../../assets/images/mock/successful-registration-background-details.webp'
import CheckCircleWhite from '../../../../assets/images/common/icons/check-circle-white.svg'

const RegistrationCompletedSimple = ({ isEmbedded }) => {
  const course = useSelector(state => state.programPresentation.course)
  const selectedPaymentMethod = useSelector(state => state.programRegistration.selectedPaymentMethod)
  const leadCoach = course?.community?.leadCoach

  return (
    <Container
      id='RegistrationCompletedSimple'
      className='d-flex flex-column justify-content-around justify-content-md-center'
    >
      <Row className='mt-3 mb-5 d-none d-lg-flex'>
        <Col className='d-flex flex-column align-items-center'>
          <h1 className='d-flex align-items-center post-registration-title'>
            <img src={CheckCircleWhite} alt='check circle' className='post-registration-check-icon mr-2' />&nbsp;
            {selectedPaymentMethod === 'cash' ? 'Enrollment request sent!' : 'Successfully Registered!' }
          </h1>
        </Col>
      </Row>
      <Row className={classNames('mt-3 justify-content-md-end', isEmbedded ? 'd-none' : 'd-md-none')}>
        <Col>
          <h4 className='final-step-header font-weight-bold'>
            Final Step:<br />
          </h4>
          <p className='font-600'>Download the mobile app to guide and track your golf journey!</p>
        </Col>
      </Row>
      <Row className={classNames('mb-3 mb-md-5', { 'd-none d-md-flex': isEmbedded })}>
        <Col xs={3} md={6} className='pr-0 d-flex'>
          <div className='w-100 d-flex align-items-center justify-content-end'>
            <img src={AppBackgroundMock} alt='Op36 App mock-up' className='d-none d-md-inline app-mock-background-img'/>
            <img src={AppMock} alt='Op36 App mock-up' className='app-mock-img'/>
          </div>
        </Col>
        <Col xs={9} md={6} className='d-flex flex-column justify-content-center'>
          <h4 className='d-none d-md-block mb-3 final-step-header'>
            Final Step:<br />
            <span className='d-none d-md-inline font-weight-normal'>Scan the QR Code and download the mobile app:</span>
          </h4>
          <div className='d-none d-md-flex mb-3 qr-code-wrapper'>
            <QRCode value='https://qrco.de/bbQ6EO' />
          </div>
          <ul className='app-facilities-list'>
            <li>Stay up to date with Schedules and Coach Announcements!</li>
            <li>View Curriculum and Golf Tools to guide you through your journey!</li>
            <li>Register easily for future programs!</li>
          </ul>
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col className='d-flex justify-content-center'>
          <CoachEmailInfo leadCoach={leadCoach} />
        </Col>
      </Row>
    </Container>
  )
}

RegistrationCompletedSimple.propTypes = {
  isEmbedded: PropTypes.bool,
}

export default RegistrationCompletedSimple
