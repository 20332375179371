import React, { Component } from "react";

// ASSETS
import "./CommunityFlagsForm.scss";

class CommunityFlagsForm extends Component {
  render() {
    const {
      formState,
      handleInputChange,
      toggleFormSection,
      createFlag,
      loader,
      isEdit
    } = this.props;
    return (
      <div id='CommunityFlagsForm' className='animated fadeIn'>
        <div className='rep-form-header'>
          <h4>
            {isEdit ? "Update Community Flag" : "Create A New Community Flag"}
          </h4>
          <div className='form-btn-wrapper'>
            <button
              className='btn btn-primary'
              onClick={() => createFlag(isEdit ? "update" : "create")}
              disabled={loader || this._checkValidation()}
            >
              {loader && (
                <i className='fa fa-spinner fa-spin light-loading-spinner' />
              )}
              {isEdit
                ? `Updat${loader ? "ing" : "e"}`
                : `Add${loader ? "ing" : ""}`}{" "}
              Flag
            </button>
            <button
              className='btn-dark-link'
              onClick={() => toggleFormSection("flags")}
              disabled={loader}
            >
              Cancel
            </button>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-6'>
            <div className='row'>
              <div className='col-12'>
                <label>
                  Current Representative{" "}
                  <span className='required-text'>*</span>
                </label>
                <select
                  className='form-control'
                  id='representative_id'
                  name='representative_id'
                  onChange={handleInputChange}
                  value={formState.representative_id}
                  disabled={loader}
                >
                  <option value={-1} disabled>
                    Choose Current Representative
                  </option>
                  {this._displayRepresentatives()}
                </select>
              </div>
            </div>

            <div className='row mt-3'>
              <div className='col-6'>
                <label>
                  Is this resolved? <span className='required-text'>*</span>
                </label>
                <select
                  className='form-control'
                  id='is_resolved'
                  name='is_resolved'
                  onChange={handleInputChange}
                  value={formState.is_resolved}
                  disabled={loader}
                >
                  <option value={"unselected"} disabled>
                    Choose Answer
                  </option>
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </select>
              </div>
              <div className='col-6'>
                <label>
                  Flag Type <span className='required-text'>*</span>
                </label>
                <select
                  className='form-control'
                  id='flag_type'
                  name='flag_type'
                  onChange={handleInputChange}
                  value={formState.flag_type}
                  disabled={loader}
                >
                  <option value={"unselected"} disabled>
                    Choose Flag Type
                  </option>
                  <option value={"Credit Card Decline"}>
                    Credit Card Decline
                  </option>
                  <option value={"Annual Renewal Past Due"}>
                    Annual Renewal Past Due
                  </option>
                  <option value={"45 Days Past Due"}>45 Days Past Due</option>
                  <option value={"Verbally Going to Cancel"}>
                    Verbally Going to Cancel
                  </option>
                  <option value={"Needs Attention"}>Needs Attention</option>
                </select>
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <label>Flag Notes</label>
            <textarea
              name='note'
              type='text'
              className='form-control'
              rows='5'
              label=''
              onChange={handleInputChange}
              placeholder='Enter some notes...'
              value={formState.note}
              disabled={loader}
            />
          </div>
        </div>
      </div>
    );
  }

  _checkValidation = () => {
    const { formState } = this.props;

    if (
      parseInt(formState.representative_id, 10) !== -1 &&
      formState.is_resolved !== "unselected" &&
      formState.note !== "" &&
      formState.flag_type !== "unselected"
    ) {
      return false;
    }
    return true;
  };

  _displayRepresentatives = () => {
    const { representatives } = this.props;

    if (representatives && representatives.length > 0) {
      return representatives.map(rep => (
        <option value={rep.id} key={rep.id}>
          {rep.first_name} {rep.last_name}
        </option>
      ));
    }
    return null;
  };

  _displayRepresentativeActionTypes = () => {
    const { representativeActionTypes } = this.props;

    if (representativeActionTypes && representativeActionTypes.length > 0) {
      return representativeActionTypes.map(rep => (
        <option value={rep.id} key={rep.id}>
          {rep.name}
        </option>
      ));
    }
    return null;
  };
}

export default CommunityFlagsForm;
