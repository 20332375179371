import * as constants from "./actionTypes";
import Axios from '../axios'

function successRequestingSkillLevels(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_SKILL_LEVELS,
      skillLevels: response.data.skill_levels,
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function getSkillLevels() {
  return function (dispatch) {
    return requestAllSkillLevels().then((response) =>
      dispatch(successRequestingSkillLevels(response))
    );
  };
}

function requestAllSkillLevels() {
  let requestUrl = "/api/v1/skill_levels";

  return Axios.get(requestUrl);
}
