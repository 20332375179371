import React from "react";
import Vimeo from "./Vimeo";
import YouTube from "./YouTube";

const ObjectiveVideo = props => {
  let url = props.vidURL;
  let isVimeo = url.search("vimeo") > 0;
  let video = isVimeo ? (
    <Vimeo url={url} />
  ) : (
    <div
      className='embed-responsive embed-responsive-16by9'
      id='ObjectiveVideo-Youtube'
    >
      <YouTube className='embed-responsive-item' url={url} />
    </div>
  );

  return (
    <div className='videoSection'>
      <div className='row'>
        <div className='col-3 col-md-2 col-lg-1'>
          <h4>VIDEO</h4>
        </div>
        <div className='col-9 col-md-10 col-lg-11'>
          <div className='fullWidthLine' />
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>{video}</div>
      </div>
    </div>
  );
};

export default ObjectiveVideo;
