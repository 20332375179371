import * as constants from './actionTypes';
import Axios from '../axios';

function successRequestingSubjects(response){
  if(response.status === 200){
    return { type: constants.ADD_SUBJECTS, subjects: response.data.subjects};
  }else{
    return {type: constants.UI_ERROR, error: response.data}
  }
}

// THUNKS HERE
export function getAllSubjects(){
  return function(dispatch){
    // BODY OF THUNK

    // TURN SPINNER ON...
    // dispatch(common.loadingChanged(true));
    // FETCH API CALL FROM SERVER THEN DISPATCH UI ACTION
    // THEN TURN SPINNER OFF
    return requestAllSubjects().then(
      response => dispatch(successRequestingSubjects(response))
    )
    // .then(
    // turnOffSpinner => dispatch(common.loadingChanged(false))
    // )
  }
}

// API CALLS
function requestAllSubjects(){
  var requestUrl = '/api/v1/subjects'

  return Axios.get(requestUrl)
}
