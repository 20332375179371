import React from "react";
import ReactPlayer from "react-player";
import moment from "moment";

import "./ChallengeDetailCard.scss";

const _renderPlaceholder = () => {
  return (
    <div className='placeholder-image'>
      <i className='fa fa-camera-retro' aria-hidden='true'></i>
      <p className='subtext'>No Photo!</p>
    </div>
  );
};

const ChallengeDetailCard = ({
  selectedChallenge,
  goToEdit,
  goToDestroy,
  goToShow,
  handleDestroy,
  secretPassword,
  handleInputChange,
  destroyClicked
}) => {
  return (
    <div id='ChallengeDetailCard'>
      <div
        className='challenge-show-pic'
        style={
          selectedChallenge.photo
            ? {
                backgroundImage: `url(${selectedChallenge.photo.file_url})`
              }
            : {}
        }
      >
        {selectedChallenge.photo ? null : _renderPlaceholder()}
        {goToEdit ? (
          <i
            className='icon-nav delete-view fa fa-trash-o'
            aria-hidden='true'
            onClick={goToDestroy}
          />
        ) : (
          <i
            className='icon-nav edit-view fa fa-pencil-square-o'
            aria-hidden='true'
            onClick={goToShow}
          />
        )}
      </div>
      <div className='challenge-show-content-container'>
        <div className='challenge-show-name-section'>
          <div
            className='challenge-show-icon'
            style={
              selectedChallenge.icon
                ? { backgroundImage: `url(${selectedChallenge.icon.file_url})` }
                : {}
            }
          />
          <div className='challenge-show-name-box'>
            <p className='challenge-show-name'>{selectedChallenge.name}</p>
            {selectedChallenge.end_date && selectedChallenge.start_date && (
              <p className='challenge-show-dates'>{`${moment(
                selectedChallenge.start_date
              ).format("M/DD/YY")} - ${moment(
                selectedChallenge.end_date
              ).format("M/DD/YY")}`}</p>
            )}
          </div>
          <p className='challenge-show-points'>{`+${selectedChallenge.points}pts`}</p>
        </div>
        <div className='challenge-show-desc-section'>
          <p>{selectedChallenge.description}</p>
        </div>
        {selectedChallenge.video_url && (
          <div className='challenge-video-container'>
            <ReactPlayer
              className='challenge-react-player'
              url={selectedChallenge.video_url}
              width='90%'
              height='400px'
              controls
            />
          </div>
        )}
        {goToEdit ? (
          <div className='submit-button-container'>
            <button className='challenge-submit-button' onClick={goToEdit}>
              Edit Challenge
            </button>
          </div>
        ) : (
          <div className='delete-flow-container'>
            <p>Type the secret password to delete this challenge...</p>
            <input
              className='secret-password-input'
              name='secretPassword'
              type='password'
              value={secretPassword}
              onChange={handleInputChange}
              placeholder='hmmmm....'
            />
            <button
              className='delete-trigger'
              onClick={() => handleDestroy(secretPassword)}
            >
              {destroyClicked ? (
                <i className='fa fa-spinner fa-spin' aria-hidden='true' />
              ) : null}
              {`${destroyClicked ? " deleting!" : "ok"}`}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChallengeDetailCard;
