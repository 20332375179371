import React, { Component } from "react";
import moment from "moment";
import StarRatings from "react-star-ratings";
import ReactTable from "react-table";
import createTable from "react-table-hoc-fixed-columns";
// import "react-table/react-table.css";

import CSVExportModal from "../CSVExportModal";

const ReactTableFixedColumns = createTable(ReactTable);

class AdminCommunityPriorityTable extends Component {
  state = {
    showModal: false,
    dataLoader: false,
    data: []
  };

  headers = [
    { label: "Community", key: "community" },
    { label: "Account Manager", key: "accountmanager" },
    { label: "Community Stage", key: "stage" },
    { label: "Our Rating", key: "supportrating" },
    { label: "Time In Network", key: "timeinnetwork" },
    { label: "Season Start Date", key: "seasonstartdate" },
    { label: "Season End Date", key: "seasonenddate" },
    { label: "Days Since Last Contacted", key: "lastcontacted" },
    { label: "Days Since Attepted", key: "lastattempted" }
  ];

  render() {
    const { showModal, data } = this.state;

    return (
      <div>
        {this._renderTable()}
        {showModal && (
          <CSVExportModal
            headers={this.headers}
            data={data}
            showModal={showModal}
            toggleModal={this._toggleModal}
            reportType={"Community Priority Report"}
            filename={"community-priority-report.csv"}
          />
        )}
      </div>
    );
  }

  _toggleModal = data => {
    this.setState({
      showModal: !this.state.showModal,
      data: data ? data : []
    });
  };

  _createData = () => {
    const { data } = this.props;

    let tempData = [];
    this.setState({ dataLoader: true });
    if (data.length > 0) {
      data.map(d => {
        let community = d.community;
        let accountmanager = d.account_manager;
        let stage = d.stage;
        let supportrating = d.support_rating;
        let timeinnetwork = d.time_in_network;
        let seasonstartdate = d.season_start_date;
        let seasonenddate = d.season_end_date;
        let lastcontacted = d.last_contact_made;
        let lastattempted = d.last_attempt_made;

        tempData.push({
          community,
          accountmanager: accountmanager
            ? `${accountmanager.first_name} ${accountmanager.last_name}`
            : null,
          stage: stage ? stage.name : null,
          supportrating,
          timeinnetwork: moment(timeinnetwork).fromNow(),
          seasonstartdate: seasonstartdate
            ? moment(seasonstartdate).format("MM-DD-YYYY")
            : null,
          seasonenddate: seasonenddate
            ? moment(seasonenddate).format("MM-DD-YYYY")
            : null,
          lastcontacted: lastcontacted
            ? moment(lastcontacted.created_at).fromNow()
            : null,
          lastattempted: lastattempted
            ? moment(lastattempted.created_at).fromNow()
            : null
        });
      });
    }

    this._toggleModal(tempData);
  };

  _toggleModal = data => {
    this.setState({
      showModal: !this.state.showModal,
      data: data ? data : []
    });
  };

  _renderTableCell = props => {
    return (
      <div
        className='number'
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div style={{ color: "#2b3843" }}>
          {props.value ? props.value : "---"}
        </div>
      </div>
    );
  };

  _renderDateCell = props => {
    return (
      <div
        className='number'
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div style={{ color: "#2b3843" }}>
          {props.value ? moment(props.value).format("MM-DD-YYYY") : "---"}
        </div>
      </div>
    );
  };

  _renderTimeFromCell = props => {
    return (
      <div
        className='number'
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div style={{ color: "#2b3843" }}>
          {props.value ? moment(props.value).fromNow() : "---"}
        </div>
      </div>
    );
  };

  _renderLastTimeSinceCell = props => {
    return (
      <div
        className='number'
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div style={{ color: "#2b3843" }}>
          {props.value ? moment(props.value.created_at).fromNow() : "---"}
        </div>
      </div>
    );
  };

  _renderStarColor = rating => {
    switch (rating) {
      case 0:
        return "#ed213a";
      case 1:
        return "#ed213a";
      case 2:
        return "#ed213a";
      case 3:
        return "#fddb10";
      case 4:
        return "#fddb10";
      case 5:
        return "#33d9b2";
      default:
        return '#ff6633'
    }
  };

  _renderRatingCell = props => {
    return (
      <div
        className='number'
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <StarRatings
          rating={props.value ? props.value : 0}
          starRatedColor={this._renderStarColor(props.value ? props.value : 0)}
          starHoverColor={"#64747f"}
          numberOfStars={5}
          name='support_rating'
          isSelectable={false}
          starDimension={"15px"}
        />
      </div>
    );
  };

  _renderNameCell = props => {
    return (
      <div
        className='number'
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div style={{ color: "#2b3843" }}>
          {props.value
            ? `${props.value.first_name} ${props.value.last_name}`
            : "---"}
        </div>
      </div>
    );
  };

  _renderStageCell = props => {
    return (
      <div
        className='number'
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div style={{ color: "#2b3843" }}>
          {props.value ? `${props.value.name}` : "---"}
        </div>
      </div>
    );
  };
  _renderTable = () => {
    const { loader, data, fetchData, pages } = this.props;

    const columns = [
      {
        Header: "Community Name",
        accessor: "community",
        Cell: props => this._renderTableCell(props),
        fixed: true
      },
      {
        Header: "Account Manager",
        accessor: "account_manager",
        Cell: props => this._renderNameCell(props),
        sortable: false
      },
      {
        Header: "Community Stage",
        accessor: "stage",
        Cell: props => this._renderStageCell(props),
        sortable: false
      },
      {
        Header: "Our Rating",
        accessor: "support_rating",
        Cell: props => this._renderRatingCell(props)
      },
      {
        Header: "Time In Network",
        accessor: "time_in_network",
        Cell: props => this._renderTimeFromCell(props)
      },
      {
        Header: "Season Start Date",
        accessor: "season_start_date",
        Cell: props => this._renderDateCell(props)
      },
      {
        Header: "Season End Date",
        accessor: "season_end_date",
        Cell: props => this._renderDateCell(props)
      },
      {
        Header: "Days Since Last Contact",
        accessor: "last_contact_made",
        Cell: props => this._renderLastTimeSinceCell(props),
        sortable: false
      },
      {
        Header: "Days Since Attempted",
        accessor: "last_attempt_made",
        Cell: props => this._renderLastTimeSinceCell(props),
        sortable: false
      }
    ];

    return (
      <div className='container my-5 animated fadeIn'>
        <div className='row mb-5 d-flex align-items-center'>
          <div className='col-md-8'>
            <h2>{this.props.title}</h2>
          </div>
          <div className='col-md-4'>
            <button
              className='btn btn-sm btn-block'
              onClick={() => this.props.goBack()}
            >
              <i
                className='fa fa-arrow-left text-white mr-1'
                aria-hidden='true'
              />{" "}
              View Another Report
            </button>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <ReactTableFixedColumns
              data={!loader ? data : []}
              columns={columns}
              pages={pages}
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              defaultPageSize={25}
              loading={loader}
              loadingText='Loading Report'
              noDataText={loader ? "" : "No data found"}
              manual
              onFetchData={(state, instance) =>
                fetchData(state, instance, "community_priority")
              }
            />
          </div>
        </div>
        <button onClick={this._createData} className='mt-3 btn btn-success'>
          Export as CSV
        </button>
      </div>
    );
  };
}

export default AdminCommunityPriorityTable;
