import React from 'react';

import PhotoUploader from './PhotoUploader';

import './AccountCreatedStep.scss';

/**
  This is the step after registration and before onboarding. The user can upload a
  profile pic here if they'd like.
*/
const accountCreatedStep = ({ logo, name, changeHandle, nextButtonHandle, questionId }) => (
  <div id='AccountCreatedStep'>
    <div className='account-created-header'>
      {logo}
    </div>
    <div className='account-created-body'>
      <PhotoUploader
        sendPhotoData={(userInput) => {changeHandle(questionId, userInput)}} />
      <h2>Hey there, {name || 'Coach'}!</h2>
      <h3>Welcome to the Op 36 Coaching Platform</h3>
      <h4>Your Hub for Learning, Planning, and Running Effective Developmental Golf Programs</h4>
      <button
        className='btn-rectangular btn-rectangular-main-positive'
        onClick={() => nextButtonHandle()}>
        Let’s start by customizing your portal!
      </button>
    </div>
  </div>
);

export default accountCreatedStep;
