import Modal from 'react-modal'
import PropTypes from 'prop-types'

import EventInfo from '../Detail/Leaderboard/EventInfo'
import Leaderboard from '../Detail/Leaderboard/Leaderboard'
import ModalFooter from '../../common/ModalFooter/ModalFooter'

import './PreviewEventModal.scss'

const PreviewEventModal = ({ showModal, closeModal, onSubmit, goBackToEdit }) => {

  const firstButton = {
    text: 'Submit Scores',
    onClick: () => onSubmit(),
  }

  const secondButton = {
    text: 'Back to Editing',
    onClick: () => goBackToEdit(),
  }

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={closeModal}
      contentLabel='Preview Event Modal'
      className='vh-100 mt-5'
      autoFocus
    >
      <div id='PreviewEventModal'>
        <div className='modal-content animated fadeIn'>
          <div className='event-content row'>
            <div className='col-md-4'>
              <EventInfo isModal/>
            </div>
            <div className='col-md-8'>
              <Leaderboard isModal/>
            </div>
          </div>
          <ModalFooter
            firstButton={firstButton}
            secondButton={secondButton}
          />
        </div>
      </div>
    </Modal>
  )
}

PreviewEventModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  goBackToEdit: PropTypes.func.isRequired,
}

export default PreviewEventModal
