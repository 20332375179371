import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'

import { defaultPerPage, setRequestInformationModalOpen } from '../../reducers/communityLandingPageReducer'
import SubmitButton from '../common/buttons/SubmitButton'

import classes from './NoProgramsPlaceholder.module.scss'
import { ReactComponent as GolferHalfIcon } from '../../assets/images/common/icons/golfer-half-icon.svg'
import { getCommunityLandingPagePrograms } from '../../actions/programActions'

const NoProgramsPlaceholder = () => {
  const dispatch = useDispatch()
  const { otherRegistrationPeriodCoursesCount, filters, community } = useSelector(state => state.communityLandingPage)

  const isPlural = otherRegistrationPeriodCoursesCount > 1

  const otherRegistrationPeriodFilters = {
    ...filters,
    future: !filters.future,
  }

  return (
    <div className='flex-grow-1 d-flex flex-column align-items-center justify-content-center'>
      <GolferHalfIcon className={classnames('mb-3', classes.icon)} />
      <h2 className={classnames('mb-3', classes.title)}>No {filters.future ? 'Future' : 'Active'} Programs Available</h2>
      <p className={classnames('mb-3', classes.message)}>
        {otherRegistrationPeriodCoursesCount > 0 ? (
          `There ${isPlural ? 'are' : 'is'} ${otherRegistrationPeriodCoursesCount} program${isPlural ? 's' : ''} that registration is `
          + `${filters.future ? 'already open' : 'opening soon'}.`
        ) : (
          'Please check back soon, or send a message to the coaching staff by requesting information below'
        )}
      </p>
      {otherRegistrationPeriodCoursesCount > 0 && (
        <button
          className={classnames('mb-5', classes.viewFuturePrograms)}
          onClick={() => {
            dispatch(getCommunityLandingPagePrograms({
              communityId: community.id,
              filters: otherRegistrationPeriodFilters,
              page: 1,
              perPage: defaultPerPage,
            }))
          }}
        >
          <i className='fa fa-calendar mr-2' />
          View {filters.future ? 'Active' : 'Future'} Programs
        </button>
      )}
      <p className={classes.message}>
        Have Questions?
      </p>
      <SubmitButton
        buttonMessage='Request Information'
        className='w-fit-content'
        noTopMargin
        onClick={() => dispatch(setRequestInformationModalOpen(true))}
      />
    </div>
  )
}

export default NoProgramsPlaceholder
