import { useCallback, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import 'react-image-crop/dist/ReactCrop.css'
import PropTypes from 'prop-types'
import FieldError from '../../../common/FieldError'
import Modal from '../../../common/Modal'
import classNames from 'classnames'

import juniorSmiling from '../../../../assets/images/program-builder/junior-smiling.webp'
import juniorWithCoach from '../../../../assets/images/program-builder/junior-with-coach.webp'
import maleSoloChipping from '../../../../assets/images/program-builder/male-solo-chipping.webp'
import ladySoloPutting from '../../../../assets/images/program-builder/lady-solo-putting.webp'
import ladySoloPutting2 from '../../../../assets/images/program-builder/lady-solo-putting-2.webp'
import ladiesGroup from '../../../../assets/images/program-builder/ladies-group.webp'
import { ReactComponent as SelectedImageIcon } from '../../../../assets/images/common/icons/programBuilder/selected-media-icon.svg'
import { setField } from '../../../../reducers/programBuilderReducer'
import SubmitButton from '../../../common/buttons/SubmitButton'
import styles from './HeaderImageModal.module.scss'
import ImageCropper from './ImageCropper'

const STEP = {
  SELECT: 'select',
  CROP: 'crop',
}

const libraryImages = [
  { src: juniorSmiling, alt: 'Junior Smiling', url: `${process.env.REACT_APP_BASE_URL}/program-builder/junior-smiling-cropped.webp` },
  { src: juniorWithCoach, alt: 'Junior with Coach', url: `${process.env.REACT_APP_BASE_URL}/program-builder/junior-with-coach-cropped.webp` },
  { src: maleSoloChipping, alt: 'Male Solo Chipping', url: `${process.env.REACT_APP_BASE_URL}/program-builder/male-solo-chipping-cropped.webp` },
  { src: ladySoloPutting, alt: 'Lady Solo Putting', url: `${process.env.REACT_APP_BASE_URL}/program-builder/lady-solo-putting-cropped.webp` },
  { src: ladySoloPutting2, alt: 'Lady Solo Putting 2', url: `${process.env.REACT_APP_BASE_URL}/program-builder/lady-solo-putting-2-cropped.webp` },
  { src: ladiesGroup, alt: 'Ladies Group', url: `${process.env.REACT_APP_BASE_URL}/program-builder/ladies-group-cropped.webp` },
]

const HeaderImageModal = ({ showModal, closeModal }) => {
  const dispatch = useDispatch()

  const uploadImageRef = useRef(null)
  const imageRef = useRef(null)

  const [step, setStep] = useState(STEP.SELECT)
  const [completedCrop, setCompletedCrop] = useState(null)
  const [uploadedImage, setUploadedImage] = useState(null)
  const [selectedImage, setSelectedImage] = useState(null)
  const [errors, setErrors] = useState({})

  const onUploadImage = useCallback((event) => {
    const objectUrl = URL.createObjectURL(event.target.files[0])

    setSelectedImage(objectUrl)
    setUploadedImage(objectUrl)
  }, [setSelectedImage, setUploadedImage])

  const saveChanges = () => {
    const image = imageRef.current

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height

    const offscreen = new OffscreenCanvas(completedCrop.width * scaleX, completedCrop.height * scaleY)
    offscreen.getContext('2d').drawImage(
      image,
      completedCrop.x * scaleX,
      completedCrop.y * scaleY,
      offscreen.width,
      offscreen.height,
      0,
      0,
      offscreen.width,
      offscreen.height
    )

    offscreen.convertToBlob({ type: 'image/webp' }).then((blob) => {
      dispatch(setField({ field: 'headerImage', value: (new File([blob], 'header_image')) }))
      dispatch(setField({ field: 'headerImageUrl', value: URL.createObjectURL(blob) }))
    })

    exitModal()
  }

  const addLibraryImage = () => {
    const croppedLibraryImageUrl = libraryImages.find(libraryImage => libraryImage.src === selectedImage).url

    fetch(croppedLibraryImageUrl)
      .then(response => response.blob())
      .then(blob => {
        dispatch(setField({ field: 'headerImage', value: (new File([blob], 'header_image')) }))
        dispatch(setField({ field: 'headerImageUrl', value: croppedLibraryImageUrl }))
      })

    exitModal()
  }

  const formValid = () => {
    const errors = {}

    if (!selectedImage) {
      errors.selectedImage = 'Please select an image from the library or upload your own.'
    }

    setErrors(errors)

    return Object.keys(errors).length === 0
  }

  const exitModal = () => {
    closeModal()
    setStep(STEP.SELECT)
    setCompletedCrop(null)
    setUploadedImage(null)
    setSelectedImage(null)
  }

  return (
    <Modal
      isOpen={showModal}
      onClose={exitModal}
      contentLabel='Header Image Modal'
      className={styles['header-image-modal-content']}
    >
      <section className='animated fadeIn'>
        {step === STEP.SELECT ? (
          <>
            <p className={styles.title}>Add Header Image</p>
            <p className='mb-3'>Customize your program page with an image. You can choose one from your computer or from our media library. </p>

            {uploadedImage && (
              <div className='d-flex justify-content-center'>
                <div className={styles['uploaded-header-image-container']} aria-hidden='true' onClick={() => setSelectedImage(uploadedImage)}>
                  <img
                    src={uploadedImage}
                    alt='Selected'
                    className={classNames(styles['uploaded-header-image-preview'], { [styles['selected-image']]: selectedImage === uploadedImage })}
                  />

                  {selectedImage === uploadedImage && (
                    <SelectedImageIcon className={styles['selected-image-icon']} />
                  )}
                </div>
              </div>
            )}

            <input
              ref={uploadImageRef}
              type='file'
              accept='image/*'
              className='d-none'
              onChange={onUploadImage}
            />

            <SubmitButton
              onClick={() => uploadImageRef.current.click()}
              buttonMessage={uploadedImage ? 'Upload another image' : 'Upload your own image'}
              buttonColor='orange-button'
              className='mb-4'
            />

            <label className={styles['sub-title']} htmlFor='library'>Choose from our Library:</label>
            <div className={`row ${styles.library}`}>
              {libraryImages.map(({ src, alt }, index) => (
                <div
                  className={`col-4 ${styles['library-image-container']}`}
                  aria-hidden='true'
                  onClick={() => setSelectedImage(src)}
                  key={index}
                >
                  <div className='position-relative'>
                    <img
                      className={classNames(styles['library-image'], { [styles['selected-image']]: selectedImage === src })}
                      src={src}
                      alt={alt}
                    />

                    {selectedImage === src && (
                      <SelectedImageIcon className={styles['selected-image-icon']} />
                    )}
                  </div>
                </div>
              ))}
            </div>

            {errors.selectedImage && <FieldError message={errors.selectedImage} />}

            <SubmitButton
              onClick={() => formValid() && (selectedImage === uploadedImage ? setStep(STEP.CROP) : addLibraryImage())}
              buttonMessage={selectedImage === uploadedImage ? 'Next' : 'Add Library Image'}
              buttonColor='orange-button'
              className='mb-4'
            />
          </>
        ) : (
          <>
            <p className={styles.title}>Adjust Header Image</p>

            <div className='d-flex align-items-center justify-content-center mt-2'>
              <ImageCropper
                onCompletedCrop={setCompletedCrop}
                selectedImage={selectedImage}
                imageRef={imageRef}
              />
            </div>

            <SubmitButton
              onClick={saveChanges}
              buttonMessage='Save Changes'
              buttonColor='orange-button'
              className='mb-4 mt-4'
            />

            <SubmitButton
              onClick={() => setStep(STEP.SELECT)}
              buttonMessage='Back'
              buttonColor='navy-button'
              className='mb-4'
            />
          </>
        )}
      </section>
    </Modal>
  )
}

HeaderImageModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default HeaderImageModal
