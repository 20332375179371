import * as constants from "./actionTypes";
import Axios from '../axios'

export function successRequestingGetCommunityFlags(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_COMMUNITY_FLAGS,
      community_flags: response.data.community_flags
    };
  } else {
    console.log("ERROR GETTING REPRESENTATIVES");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successRequestingCreateCommunityFlag(response) {
  if (response.status === 201) {
    return {
      type: constants.CREATE_COMMUNITY_FLAG,
      community_flag: response.data.community_flag
    };
  } else {
    console.log("ERROR ON CREATING COMMUNITYFLAG");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successRequestingUpdateCommunityFlag(response) {
  if (response.status === 201) {
    return {
      type: constants.UPDATE_COMMUNITY_FLAG,
      community_flag: response.data.community_flag
    };
  } else {
    console.log("ERROR ON UPDATING COMMUNITY FLAG");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

export function successDeleteingCommunityFlag(response) {
  if (response.status === 201) {
    return {
      type: constants.DELETE_COMMUNITY_FLAG,
      community_flag: response.data.community_flag
    };
  } else {
    console.log("ERROR ON DELETING COMMUNITY FLAG");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

// THUNKS HERE
export function getCommunityFlags(community_id) {
  return function(dispatch) {
    return requestGetCommunityFlags(community_id).then(response =>
      dispatch(successRequestingGetCommunityFlags(response))
    );
  };
}

export function createCommunityFlag(flag) {
  return function(dispatch) {
    return requestCreateCommunityFlag(flag).then(response =>
      dispatch(successRequestingCreateCommunityFlag(response))
    );
  };
}

export function updateCommunityFlag(flag) {
  return function(dispatch) {
    return requestUpdateCommunityFlag(flag).then(response =>
      dispatch(successRequestingUpdateCommunityFlag(response))
    );
  };
}

export function deleteCommunityFlag(flag_id) {
  return function(dispatch) {
    return requestDeleteCommunityFlag(flag_id).then(response =>
      dispatch(successDeleteingCommunityFlag(response))
    );
  };
}

// API CALLS
function requestGetCommunityFlags(community_id) {
  let requestUrl = "/api/v1/community_flags/?community_id=" + community_id;

  return Axios.get(requestUrl);
}

function requestCreateCommunityFlag(flag) {
  let requestUrl = "/api/v1/community_flags/";

  return Axios.post(requestUrl, {
    note: flag.note,
    community_id: flag.community_id,
    representative_id: flag.representative_id,
    flag_type: flag.flag_type,
    is_resolved: flag.is_resolved
  });
}

function requestUpdateCommunityFlag(flag) {
  let requestUrl = "/api/v1/community_flags/" + flag.id;

  return Axios.put(requestUrl, {
    note: flag.note,
    community_id: flag.community_id,
    representative_id: flag.representative_id,
    flag_type: flag.flag_type,
    is_resolved: flag.is_resolved
  });
}

function requestDeleteCommunityFlag(flag_id) {
  let requestUrl = "/api/v1/community_flags/" + flag_id;
  return Axios.delete(requestUrl);
}
