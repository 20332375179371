import React, { Component } from "react";
import _ from "lodash";
import Modal from "react-modal";

// CHILDREN
import ResourceSectionForm from "../../Forms/ResourceSectionForm";

class ResourceSectionEditModal extends Component {
  render() {
    const {
      showModal,
      closeModal,
      isEditModal,
      formState,
      handleInputChange,
      grabSectionPhotoData,
      loader,
      updateResourceSection,
      toggleForm,
      resourceSection,
      updateCategories
    } = this.props;
    return (
      <Modal
        id="ResourceModal"
        isOpen={showModal}
        onRequestClose={closeModal}
        contentLabel={`Create Resource Modal`}
      >
        <div className="modal-header row m-0 p-0">
          <div className="col-11 m-0 p-0">
            <h5 className="modal-title display-4">Edit Section</h5>
          </div>
          <div className="col-1 m-0 p-0">
            <button
              type="button"
              className="close pull-right font-200"
              data-dismiss="modal"
              aria-label="Close"
              onClick={closeModal}
            >
              <span aria-hidden="true">✕</span>
            </button>
          </div>
        </div>
        <div className="modal-content">
          <div className="modal-body" id="input-border">
            <div className="row mb-2 p-2">
              <div className="col-md-6 mx-auto">
                <ResourceSectionForm
                  resourceSection={resourceSection}
                  formState={formState}
                  handleInputChange={handleInputChange}
                  grabSectionPhotoData={grabSectionPhotoData}
                  toggleForm={toggleForm}
                  closeModal={closeModal}
                  updateResourceSection={updateResourceSection}
                  updateCategories={updateCategories}
                  loader={loader}
                  isEditModal={isEditModal}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ResourceSectionEditModal;
