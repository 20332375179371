import React from "react";
import moment from "moment";

import ChallengeDetailCard from "../subcomponents/ChallengeDetailCard";

import "./ChallengeShow.scss";

class ChallengeShow extends React.Component {
  render() {
    const {
      selectedChallenge,
      returnToIndex,
      goToEdit,
      goToDestroy
    } = this.props;
    return (
      <div id='ChallengeShow'>
        <div className='return-button-container'>
          <button className='return-challenge-button' onClick={returnToIndex}>
            Back to Challenges
          </button>
        </div>
        <ChallengeDetailCard
          selectedChallenge={selectedChallenge}
          goToEdit={goToEdit}
          goToDestroy={goToDestroy}
        />
      </div>
    );
  }
}

export default ChallengeShow;
