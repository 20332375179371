import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { addEvent, updateEvent } from '../../../reducers/seriesReducer'
import { getMomentInCommunityTimezone, getMomentInCommunityTimezoneReversed } from '../../../utility/datesHelper'
import SubmitButton from '../../common/buttons/SubmitButton'
import DatePicker from '../../common/form/DatePicker'
import Input from '../../common/form/Input'
import Modal from '../../common/Modal'

import styles from './EventModal.module.scss'
import FieldError from '../../common/FieldError'
import MCElement from '../../common/MCElement'
import classNames from 'classnames'
import moment from 'moment'

import colors from '../../../assets/styles/globals.scss'

const customErrorMessages = {
  eventDate: 'Please select the date.',
  eventTime: 'Please select the time.',
  eventDateInThePast: "Can't be in the past",
  format: 'Please select the format.',
}

const EMPTY_EVENT = { eventDate: '', format: '', name: '', location: '', description: '' }

const EventModal = ({ showModal, closeModal, selectedEvent }) => {
  const dispatch = useDispatch()
  const timezone = useSelector(state => state.user.current_user.communities[0].timezone)
  const [event, setEvent] = useState(EMPTY_EVENT)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    if (selectedEvent) {
      const dateInTimezone = getMomentInCommunityTimezoneReversed(moment(selectedEvent.eventDate), timezone, 'YYYY-MM-DD HH:mm:ss')?.toDate()
      setEvent({ ...selectedEvent, eventDate: dateInTimezone })
    }
  }, [showModal, selectedEvent, timezone])

  const handleInputChange = e => {
    const { name, value } = e.target
    setEvent({ ...event, [name]: value })
  }

  const handleSelectFormat = (format) => {
    if (event.format !== format) {
      setEvent({ ...event, format: format })
    }
  }

  const handleDateTimeChange = e => {
    const { name, value } = e

    if (name === 'date') {
      let eventDate = event.eventDate ? moment(event.eventDate) : moment(new Date().setMinutes(0))
      eventDate = moment(moment(value).format('YYYY-MM-DD') + ' ' + eventDate.format('HH:mm')).toDate()
      setEvent({ ...event, eventDate: eventDate })
    } else if (name === 'eventDate') {
      let eventDate = event.eventDate ? moment(event.eventDate) : moment(new Date())
      eventDate = moment(eventDate.format('YYYY-MM-DD') + ' ' + moment(value).format('HH:mm')).toDate()
      setEvent({ ...event, eventDate: eventDate })
    }
  }

  const invalidFields = {
    eventDate: event.eventDate === '',
    eventTime: event.eventDate === '',
    format: event.format === '',
  }

  const formValid = () => {
    const errors = {};

    ['eventDate', 'eventTime', 'format'].forEach((type) => {
      if (invalidFields[type]) {
        errors[type] = customErrorMessages[type]
      }
    })
    setErrors(errors)

    return Object.keys(errors).length === 0
  }

  const submitEvent = () => {
    if (formValid()) {
      const newEvent = {
        ...event,
        eventDate: getMomentInCommunityTimezone(moment(event.eventDate), timezone, 'YYYY-MM-DD HH:mm:ss').toDate(),
      }
      if (event.index === undefined) {
        dispatch(addEvent(newEvent))
      } else {
        dispatch(updateEvent(newEvent))
      }
      exitModal()
    }
  }

  const exitModal = () => {
    setEvent(EMPTY_EVENT)
    setErrors({})
    closeModal()
  }

  return (
    <Modal
      isOpen={showModal}
      onClose={exitModal}
      className='single-session-builder-modal-content'
    >
      <div className={styles['event-modal']}>
        <div className='animated fadeIn'>
          <div>
            <p className={styles['title']}>New Event Date</p>
            <div className='row'>

              <div className='col-12 col-sm-6 mb-2'>
                <label htmlFor='date' className={styles['label-text']}>
                  Date <span className='required-text'>*</span>
                </label>

                <DatePicker
                  selected={event.eventDate}
                  onChange={date => handleDateTimeChange({ name: 'date', value: date })}
                  error={errors.eventDate || errors.eventDateInThePast}
                  symbol={<i className='fa fa-calendar-o'/>}
                />
              </div>

              <div className='col-12 col-sm-6 mb-2'>
                <label htmlFor='time' className={styles['label-text']}>
                  Start Time <span className='required-text'>*</span>
                </label>

                <DatePicker
                  selected={event.eventDate}
                  className='eventDateInput'
                  onChange={time => handleDateTimeChange({ name: 'eventDate', value: time })}
                  inputClassNames={{
                    inputContainer: classNames('', { 'error-input': !!errors.eventDate }),
                  }}
                  symbol={<i className='fa fa-clock-o'/>}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption='time'
                  dateFormat='h:mm aa'
                  autoComplete='off'
                  error={errors.eventTime}
                />
              </div>

              <div className='col-12'>
                <label htmlFor='format' className={styles['label-text']}>
                  Format <span className='required-text'>*</span>
                </label>

                <div
                  onClick={() => handleSelectFormat('Tee Times')}
                  className='d-flex format-option-tee-times mb-2'
                  role='button'
                  onKeyDown={null}
                  tabIndex={0}
                >
                  <MCElement
                    type='radio'
                    onKeyDown={(e) => {
                      e.key === 'Enter' && handleSelectFormat('Tee Times')
                    }}
                    primaryColor={event.format === 'Tee Times' ? colors.secondaryColor : colors.lightNavy}
                    isSelected={event.format === 'Tee Times'}
                  />
                  <span className='ml-2'>Tee times</span>
                </div>
                <div
                  onClick={() => handleSelectFormat('Shotgun')}
                  className='d-flex format-option-shotgun'
                  role='button'
                  onKeyDown={null}
                  tabIndex={0}
                >
                  <MCElement
                    type='radio'
                    onKeyDown={(e) => {
                      e.key === 'Enter' && handleSelectFormat('Shotgun')
                    }}
                    primaryColor={event.format === 'Shotgun' ? colors.secondaryColor : colors.lightNavy}
                    isSelected={event.format === 'Shotgun'}
                  />
                  <span className='ml-2'>Shotgun</span>
                </div>
                {!!errors.format && <FieldError message={errors.format} />}
              </div>

              <div className={styles['hl']}/>

              <div className='col-12 mb-2'>
                <label htmlFor='subtitle' className={styles['label-text']}>Subtitle (Optional)</label>
                <Input
                  name='name'
                  type='text'
                  onChange={handleInputChange}
                  placeholder='Ex: Kickoff Event'
                  value={event.name}
                  error={errors.name}
                />
              </div>

              <div className='col-12 mb-3'>
                <label htmlFor='location' className={styles['label-text']}>Location (Optional)</label>
                <Input
                  name='location'
                  type='text'
                  onChange={handleInputChange}
                  placeholder='Add a Location'
                  value={event.location}
                  error={errors.location}
                  symbol={<i className='fa fa-map-marker'/>}
                />
              </div>

              <div className='col-12 mb-3'>
                <label htmlFor='description' className={styles['label-text']}>Description (Optional)</label>
                <Input
                  name='description'
                  type='textarea'
                  onChange={handleInputChange}
                  placeholder='Ex: Everyone please arrive 20 minutes to tee time.'
                  value={event.description}
                  error={errors.description}
                />
              </div>
            </div>
            <div className='row py-3'>
              <div className='col-6'>
                <SubmitButton
                  onClick={exitModal}
                  buttonMessage='Cancel'
                  buttonColor='navy-button'
                />
              </div>
              <div className='col-6'>
                <SubmitButton
                  onClick={submitEvent}
                  buttonMessage={event.index === undefined ? 'Create' : 'Update'}
                  buttonColor='orange-button'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

EventModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  selectedEvent: PropTypes.object,
}

export default EventModal
