// Create an array of division badge images to easily import into other files
import badgeGray01 from "../assets/images/division-badges-gray/D01-Badge-Gray.png";
import badgeGray02 from "../assets/images/division-badges-gray/D02-Badge-Gray.png";
import badgeGray03 from "../assets/images/division-badges-gray/D03-Badge-Gray.png";
import badgeGray04 from "../assets/images/division-badges-gray/D04-Badge-Gray.png";
import badgeGray05 from "../assets/images/division-badges-gray/D05-Badge-Gray.png";
import badgeGray06 from "../assets/images/division-badges-gray/D06-Badge-Gray.png";
import badgeGray07 from "../assets/images/division-badges-gray/D07-Badge-Gray.png";
import badgeGray08 from "../assets/images/division-badges-gray/D08-Badge-Gray.png";
import badgeGray09 from "../assets/images/division-badges-gray/D09-Badge-Gray.png";
import badgeGray10 from "../assets/images/division-badges-gray/D10-Badge-Gray.png";

export default [
  badgeGray01,
  badgeGray02,
  badgeGray03,
  badgeGray04,
  badgeGray05,
  badgeGray06,
  badgeGray07,
  badgeGray08,
  badgeGray09,
  badgeGray10
];
