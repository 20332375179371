import PropTypes from 'prop-types'
import { pluralize } from '../../utility/helperFunctions'
import styles from './EventStatus.module.scss'
import classNames from 'classnames'

const EventStatus = ({ event, inProgress }) => (
  <div className={styles.eventStatus}>
    {inProgress ?
      <div className={styles.ongoing}>
        <div className={styles.iconWrapper}>
          <i className='fa fa-repeat' aria-hidden='true'/>
        </div>
        <p>Logging in progress</p>
      </div> :

      event.status === 'created' && event.nextRsvp ?
        <div className={styles.created}>
          <div className={styles.iconWrapper}>
            <i className='fa fa-users' aria-hidden='true'/>
          </div>
          <div className='d-flex flex-column'>
            <p>RSVP Sending in {event.nextRsvp} {pluralize('Day', event.nextRsvp)}</p>
            <div className={classNames(styles.label, styles.rsvpLabel)}>
              <div className={styles.labelText}>
                {event.respondedEnrollmentsCount
                  ? `${event.respondedEnrollmentsCount} ${pluralize('RSVP', event.respondedEnrollmentsCount)}`
                  : null
                }
              </div>
            </div>
          </div>
        </div> :

        event.status === 'created' && !event.nextRsvp ?
          <div className={styles.ready}>
            <div className='d-flex'>
              <div className={styles.iconWrapper}>
                <i className='fa fa-flag' aria-hidden='true'/>
              </div>
              <p>Ready to run</p>
            </div>
            { event.confirmedEnrollmentsCount ? <p className={styles.attendingText}> {event.confirmedEnrollmentsCount} Attending </p> : null }
          </div> :

          event.status === 'ongoing' ?
            <div className={styles.ongoing}>
              <div className={styles.iconWrapper}>
                <i className='fa fa-bullhorn' aria-hidden='true'/>
              </div>
              <p>Log scores</p>
            </div> :

            event.status === 'completed' ?
              <div className={styles.completed}>
                <div className='d-flex mb-2'>
                  <div className={styles.iconWrapper}>
                    <i className='fa fa-check' aria-hidden='true'></i>
                  </div>
                  <p>Completed</p>
                </div>
                <div className={classNames(styles.label, styles.leaderboardLabel)}>
                  <div className={styles.labelText}> View Leaderboard </div>
                </div>
              </div> : null
    }
  </div>
)

EventStatus.propTypes = {
  event: PropTypes.shape({
    status: PropTypes.string,
    nextRsvp: PropTypes.number,
    confirmedEnrollmentsCount: PropTypes.number,
    respondedEnrollmentsCount: PropTypes.number,
  }),
  inProgress: PropTypes.bool,
}

export default EventStatus
