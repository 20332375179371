import * as types from "../actions/actionTypes";

// state = [] : Start out with no accounts
export default function representativeNotes(state = [], action) {
  switch (action.type) {
    case types.GET_REPRESENTATIVE_NOTES:
      return action.representative_notes;

    case types.CREATE_REPRESENTATIVE_NOTE:
      return [...state, Object.assign({}, action.representative_note)];

    default:
      return state;
  }
}
