import React from 'react';
import default_profile_pic from '../../assets/images/default_profile.png'
import '../../assets/styles/components/leaderboard/LeaderboardItem.scss';
import { Link } from 'react-router-dom'

// MUST PASS IN AN ACTIVITY OBJECT, HAS OPTION OF SHOWING COMMENTS OR NOT
class LeaderboardItem extends React.Component {

  getImageURLForUser(user) {
    if (user.avatar) {
      return user.avatar.file_url;
    } else {
      return {default_profile_pic}.default_profile_pic;
    }
  }

  render(){
    var fullName = this.props.user.first_name + ' ' + this.props.user.last_name
    
    return(
      <div className="row comment padding-base">
        <div className="no-padding col-1 commentIndex">
          <h5 className="list-index spacer-sm">{this.props.index + 1}</h5>
        </div>
        <div className="no-padding col-2">
          <div className="round-image-wrapper">
            <Link to={'/profile/' + this.props.user.id}><img src={this.getImageURLForUser(this.props.user)} alt="Name" className="pull-left round-image"/></Link>
          </div> 
        </div>

        <div className="col-6 commentContent">
          <h6 className="commentUserName font-300">{fullName}</h6>
        </div>
        <div className="no-padding col-1">
          <p className="commentBody h6"><span className="pt-num">{this.props.points}</span><span className="pt-label">pt</span></p>
        </div>
      </div>
    );
  }
}

export default LeaderboardItem;
