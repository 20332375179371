import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from 'classnames'

// ASSETS
import "./StateSwitcher.scss";

class StateSwitcher extends Component {
  componentDidMount = () => {
    const { stateSwitcher, initialTypeSelected } = this.props;

    stateSwitcher(initialTypeSelected);
  };

  positionSwitcher = () => {
    const { position } = this.props;

    switch (position) {
      case "center":
        return "justify-content-center";
      case "left":
        return "justify-content-start";
      case "right":
        return "justify-content-end";
      default:
        return "justify-content-start";
    }
  };

  renderOptions = () => {
    const { options, selectedType, stateSwitcher } = this.props;

    if (options && options.length > 0) {
      return options.map((option, index) => (
        <button
          className={`${option.type === selectedType &&
            "active-option animated fadeIn"}`}
          onClick={() => stateSwitcher(option.type)}
          key={index}
        >
          {option.text}
        </button>
      ));
    }
  };

  render() {
    return (
      <div
        id="StateSwitcher"
        className={classNames('d-flex flex-row align-items-center', this.positionSwitcher())}
      >
        <div className={classNames('switcher-wrapper', { disabled: this.props.disabled })}>{this.renderOptions()}</div>
      </div>
    );
  }
}

StateSwitcher.propTypes = {
  options: PropTypes.array.isRequired,
  initialTypeSelected: PropTypes.any.isRequired,
  selectedType: PropTypes.any.isRequired,
  stateSwitcher: PropTypes.func.isRequired,
  position: PropTypes.string.isRequired
};

export default StateSwitcher;
