import React from 'react'
import PropTypes from 'prop-types'

import './LessonPlanBuilderProgressBar.scss'

// eslint-disable-next-line complexity
const LessonPlanBuilderProgressBar = props => (
  <div id='LessonPlanBuilderProgressBar' className='hidden-md-down'>
    <div
      className={'bar page1 clickable'}
      aria-hidden='true'
      onClick={
        props.page === 1
          ? null
          : props.pageProgress >= 1
            ? () => props.handleClick(1)
            : null
      }
      style={
        props.pageProgress >= 1
          ? {
            background: '#ff6633',
            color: '#fff',
            border: '2px solid #fff',
            borderRight: 'none',
          }
          : {}
      }
    >
        1. Add Basic Details
    </div>
    <div
      className='triangle clickable'
      aria-hidden='true'
      onClick={
        props.page === 1
          ? null
          : props.pageProgress >= 1
            ? () => props.handleClick(1)
            : null
      }
    />
    <div
      className={`bar page2 ${props.pageProgress >= 2 && 'clickable'}`}
      aria-hidden='true'
      onClick={
        props.page === 2
          ? null
          : props.pageProgress >= 2
            ? () => props.handleClick(2)
            : null
      }
      style={
        props.pageProgress >= 2
          ? { background: '#ff6633', color: '#fff', border: '2px solid #fff' }
          : {}
      }
    >
        2. Create Schedule
    </div>
    <div
      className={`triangle triangle2 ${props.pageProgress >= 2 && 'clickable'}`}
      aria-hidden='true'
      onClick={
        props.page === 2
          ? null
          : props.pageProgress >= 2
            ? () => props.handleClick(2)
            : null
      }
      style={
        props.pageProgress < 2
          ? {
            background: '#fff',
            borderTop: '2px solid #ff6633',
            borderRight: '2px solid #ff6633',
          }
          : {}
      }
    />
    <div
      className={`bar page3 ${props.pageProgress >= 3 && 'clickable'}`}
      aria-hidden='true'
      onClick={
        props.page === 3
          ? null
          : props.pageProgress >= 3
            ? () => props.handleClick(3)
            : null
      }
      style={
        props.pageProgress >= 3
          ? { background: '#ff6633', color: '#fff', border: '2px solid #fff' }
          : {}
      }
    >
        3. Review and Publish
    </div>
    <div
      className={`triangle triangle3 ${props.pageProgress >= 3 && 'clickable'}`}
      aria-hidden='true'
      onClick={
        props.page === 3
          ? null
          : props.pageProgress >= 3
            ? () => props.handleClick(3)
            : null
      }
      style={
        props.pageProgress < 3
          ? {
            background: '#fff',
            borderTop: '2px solid #ff6633',
            borderRight: '2px solid #ff6633',
          }
          : {}
      }
    />
  </div>
)

LessonPlanBuilderProgressBar.propTypes = {
  page: PropTypes.number,
  pageProgress: PropTypes.number,
  handleClick: PropTypes.func,
}

export default LessonPlanBuilderProgressBar
