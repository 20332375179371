import * as types from "../actions/actionTypes";

// state = [] : Start out with no accounts
export default function resourceSections(state = [], action) {
  switch (action.type) {
    case types.GET_RESOURCE_SECTIONS:
      return action.resource_sections;

    case types.CREATE_RESOURCE_SECTION:
      return [...state, Object.assign({}, action.resource_section)];

    default:
      return state;
  }
}
