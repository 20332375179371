import React from "react";

import DrillsDetail from "../../DrillsDetail";

import "./DrillReviewAndPublish.scss";

class DrillReviewAndPublish extends React.Component {
  render() {
    const {
      drill,
      navigate,
      triggerSkillModal,
      allSkills,
      allTags,
      videoLoader,
      videoPreview,
      videoTooLarge,
      videoError,
      awsBackupUrl
    } = this.props;
    return (
      <div id='DrillReviewAndPublish'>
        <div className='page-content-container'>
          <DrillsDetail
            fromBuilder={true}
            drill={drill}
            navigate={navigate}
            triggerSkillModal={triggerSkillModal}
            skillList={allSkills}
            tagList={allTags}
            videoLoader={videoLoader}
            videoError={!!videoTooLarge || !!videoError}
            videoPreview={videoPreview}
            awsBackupUrl={awsBackupUrl}
          />
        </div>
      </div>
    );
  }
}

export default DrillReviewAndPublish;
