import colors from '../assets/styles/globals.scss'

export function checkLicensePermission(license, permittedLicenses) {
  return permittedLicenses.includes(license)
}

export function licenseLabel(license) {
  switch (license) {
    case 'standard':
      return 'Standard';
    case 'revenue_share':
      return 'Partner Plan'
    case 'standard_legacy':
      return 'Standard (Legacy)'
  }
}

export function licenseColor(license) {
  switch (license) {
    case 'standard':
      return colors.primaryTeal
    case 'revenue_share':
      return colors.primaryBlue
    case 'standard_legacy':
      return colors.secondaryColor
  }
}
