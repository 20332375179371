import React from "react";
import ReactPlayer from "react-player";

class ImageGallery extends React.Component {
  state = {
    selectedIndex: 0
  };

  render() {
    const { items } = this.props;
    return (
      <div id='ImageGallery' className='d-flex'>
        {items && items.length > 0 && <div className='w-100 d-flex'>{items.length > 1 && <div
          className='left-nav d-flex align-items-center pr-1'
          style={{ cursor: "pointer" }}
          onClick={() => this._next(items.length, this.state.selectedIndex)}
        >
          <i
            className='fa fa-caret-left'
            aria-hidden='true'
            style={{ fontSize: 80 }}
          />
        </div>}
        <div className='featured flex-grow-1'>
          {this._renderFeatured(this.state.selectedIndex, items)}
        </div>
        {items.length > 1 && <div
          className='right-nav d-flex align-items-center pl-1'
          style={{ cursor: "pointer" }}
          onClick={() => this._back(items.length, this.state.selectedIndex)}
        >
          <i
            className='fa fa-caret-right'
            aria-hidden='true'
            style={{ fontSize: 80 }}
          />
        </div>}</div>}
      </div>
    );
  }

  _next = (len, current) => {
    if (current === len - 1) {
      return this.setState({selectedIndex: 0})
    }
    this.setState({selectedIndex: current + 1})
  }

  _back = (len, current) => {
    if (current === 0) {
      return this.setState({selectedIndex: len - 1})
    }
    this.setState({selectedIndex: current - 1})
  }

  _renderFeatured = (idx, items) => {
    if (items[idx].embedUrl) {
      return (
        <ReactPlayer
          className='challenge-react-player'
          url={items[idx].embedUrl}
          width='100%'
          height='400px'
          controls
        />
      );
    }
    return <div style={{backgroundImage: `url(${items[idx].original})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', height: 400}} />
  };
}

export default ImageGallery;
