import React from 'react'
import ReactPlayer from 'react-player'
import TwoButtonModal from '../lessonPlans/modals/TwoButtonModal'

import './DrillsDetail.scss'

import StockProfilePic from '../../assets/images/mock/StockProfilePic.png'
import lock_icon from '../../assets/images/common/icons/drills/lock-icon.png'
import unlock_icon from '../../assets/images/common/icons/drills/unlock-icon.png'
import { CheckMark } from '../util/SvgIcons'
import PropTypes from 'prop-types'

class DrillsDetail extends React.Component {
  state = {
    equipmentOpen: true,
    setupOpen: true,
    contentOpen: true,
    selectedMediaIndex: -1,
    deleteModal: { type: 'none', id: -1 },
  }

  // eslint-disable-next-line max-lines-per-function, complexity
  render() {
    const { equipmentOpen, setupOpen, contentOpen, selectedMediaIndex } =
      this.state
    const {
      drill,
      navigate, // used to navigate to other parts of the builder
      fromBuilder, // indicates if you're in the builder or not
      skillList, // list of all skills to render the selected ones from
      tagList, // list of all tags to render the selected ones from
      triggerSkillModal, // if you're in the builder, you can edit the selected skills for the drill here
      videoLoader, // if you're in the builder this will show a loader if the video is skill on its way to aws
      videoError, // if you're in the builder will show if the video is too big or if there was an error sending it to aws
      videoPreview, // just the preview of the file before going to aws - only used to grab the file name
      awsBackupUrl, // the aws url to the video - only shown if the vimeo stream fails for some reason
      isMine, // does the drill user_id match the current user id
      fromDrillsPage, // indicates if you're on the drills page - will show from the DrillDetailModal
      handleFavorite, // if you're on the drills page, you can favorite or pin a drill. Will pin if it's yours and will favorite if it's not yours.
      // Just pass in (in this order) click event, drill_id, current pinned status. Favorite doesn't actually need the pinned status,
      // but what the hell, let's throw it in there for good measure
      handleEdit, // if it's yours you can jump to the builder - only if you're in the drills page
      isPublicPage, // will only be true if you're opening this from the modal while in the 'public' view - used to show favorite rather than pin on
      // your own drill
    } = this.props

    return (
      <div id='DrillsDetail'>
        {fromDrillsPage && !fromBuilder && (
          <div className='d-flex w-100'>
            {isMine && !isPublicPage && (
              <div
                className={`favorite-action-button ${
                  drill.is_pinned ? 'pinned' : ''
                }`}
                aria-hidden='true'
                onClick={(e) => handleFavorite(e, drill.id, drill.is_pinned)}
              >
                <i className='fa fa-star fav-icon' aria-hidden='true' />
                <p>{`${drill.is_pinned ? 'Unfavorite' : 'Favorite'} ${
                  drill.is_coach_activity ? 'activity' : 'drill'
                }`}</p>
              </div>
            )}
            {isPublicPage && (
              <div
                className={`favorite-action-button ${
                  drill.is_favorited ? 'pinned' : ''
                }`}
                aria-hidden='true'
                onClick={(e) => handleFavorite(e, drill.id)}
              >
                <i className='fa fa-star fav-icon' aria-hidden='true' />
                <p>{`${drill.is_favorited ? 'Unfavorite' : 'Favorite'} ${
                  drill.is_coach_activity ? 'activity' : 'drill'
                }`}</p>
              </div>
            )}
            {isMine && (
              <div
                className={'favorite-action-button edit-action'}
                aria-hidden='true'
                onClick={() => handleEdit(drill.id)}
              >
                <i
                  className='fa fa-pencil-square-o fav-icon'
                  aria-hidden='true'
                />
                <p>{`Edit ${
                  drill.is_coach_activity ? 'activity' : 'drill'
                }`}</p>
              </div>
            )}
            {isMine && (
              <div
                className={'favorite-action-button delete-action'}
                aria-hidden='true'
                onClick={() =>
                  this._handleDelete({
                    id: drill.id,
                    type: drill.is_coach_activity ? 'activity' : 'drill',
                  })
                }
              >
                <i className='fa fa-trash fav-icon' aria-hidden='true' />
                <p>{`Delete ${
                  drill.is_coach_activity ? 'activity' : 'drill'
                }`}</p>
              </div>
            )}
          </div>
        )}
        <div className='page-column'>
          <div className='featured-image-box'>
            {this._renderFeatured(
              selectedMediaIndex,
              drill,
              videoLoader,
              videoError,
              videoPreview,
              awsBackupUrl
            )}
          </div>
          <div className='image-preview-box'>
            {this._renderFeaturePicker(drill, videoLoader, videoError)}
          </div>
          <p className='drill-title'>
            {drill.title}{' '}
            <span
              className='type-indicator-pill'
              style={
                drill.is_coach_activity ? { backgroundColor: '#ff6633' } : {}
              }
            >
              {drill.is_coach_activity ? 'Activity' : 'Drill'}
            </span>
            {fromBuilder && (
              <span className='orange-text' aria-hidden='true' onClick={() => navigate(1)}>
                <i className='fa fa-pencil-square-o' aria-hidden='true' />
                Edit Basic Details
              </span>
            )}
          </p>
          {!fromBuilder && !isMine && drill.user && (
            <div className='author-pill'>
              <div
                className='author-avatar'
                style={{
                  backgroundImage: `url(${
                    drill.user.avatar
                      ? drill.user.avatar.file_url
                      : StockProfilePic
                  })`,
                }}
              />
              <p className='author-name'>{`${drill.user.first_name} ${drill.user.last_name}`}</p>
            </div>
          )}
          <p className='drill-description'>{drill.description}</p>
          <div className='d-flex mb-3'>
            <p className='visibility-text'>{`Visibility: ${
              drill.privacy === 'private_community' || drill.privacy === 0
                ? 'Private'
                : 'Public'
            }`}</p>
            <div
              className='visibility-icon'
              style={{
                backgroundImage: `url(${
                  drill.privacy === 'private_community' || drill.privacy === 0
                    ? lock_icon
                    : unlock_icon
                })`,
              }}
            />
          </div>
          {drill.is_coach_activity ? null : (
            <div className='skills-display'>
              <p className='subsection-label'>
                Skills trained{' '}
                {fromBuilder ? (
                  <span className='orange-text' aria-hidden='true'onClick={triggerSkillModal}>
                    <i className='fa fa-pencil-square-o' aria-hidden='true' />
                    Edit skills
                  </span>
                ) : (
                  ''
                )}
              </p>
              {fromBuilder
                ? this._renderSkills(drill.skills, skillList)
                : this._renderSkills(
                  drill.practice_types.map((p) => p.id),
                  drill.practice_types
                )}
            </div>
          )}
          <p className='subsection-label'>
            Tags{' '}
            {fromBuilder ? (
              <span className='orange-text' aria-hidden='true' onClick={() => navigate(2)}>
                <i className='fa fa-pencil-square-o' aria-hidden='true' />
                Edit Tags and Visibility
              </span>
            ) : (
              ''
            )}
          </p>
          {fromBuilder
            ? this._renderTags(drill.tags, tagList)
            : this._renderTags(
              drill.tags.map((t) => t.id),
              drill.tags
            )}
          {drill.custom_tags.length > 0 && (
            <div className='tag-category-container custom-tag-container'>
              <p className='subsection-label' style={{ fontWeight: 'normal' }}>
                Other:
              </p>
              {this._renderCustomTags(drill.custom_tags)}
            </div>
          )}
        </div>
        <div className='page-column'>
          <div
            className='drop-bar'
            aria-hidden='true'
            onClick={() => this._handleDropdown('equipmentOpen')}
          >
            <p>
              Equipment{' '}
              {equipmentOpen ? '' : <span>{drill.equipment.length}</span>}
            </p>
            <i
              className={`fa fa-chevron-up ${equipmentOpen ? '' : 'flip'}`}
              aria-hidden='true'
            />
          </div>
          {equipmentOpen && (
            <div className='collapsable equipment-content'>
              {drill.equipment.map((e, i) => (
                <div className='list-row' key={i}>
                  {/* <div
                      className='check-icon'
                      style={{ backgroundImage: `url(${check_icon})` }}
                    /> */}
                  <CheckMark />
                  <p>{e}</p>
                </div>
              ))}
            </div>
          )}
          <div
            className='drop-bar'
            aria-hidden='true'
            onClick={() => this._handleDropdown('setupOpen')}
          >
            <p>Setup {setupOpen ? '' : <span>{drill.steps.length}</span>}</p>
            <i
              className={`fa fa-chevron-up ${setupOpen ? '' : 'flip'}`}
              aria-hidden='true'
            />
          </div>
          {setupOpen && (
            <div className='collapsable setup-content'>
              {drill.steps.map((s, i) => (
                <div className='list-row setup-row' key={i}>
                  <div className='step-counter'>
                    <p>{i + 1}</p>
                  </div>
                  <p>{s}</p>
                </div>
              ))}
            </div>
          )}
          <div
            className='drop-bar'
            aria-hidden='true'
            onClick={() => this._handleDropdown('contentOpen')}
          >
            <p>About this {drill.is_coach_activity ? 'activity' : 'drill'} </p>
            <i
              className={`fa fa-chevron-up ${contentOpen ? '' : 'flip'}`}
              aria-hidden='true'
            />
          </div>
          {contentOpen && (
            <div
              className='collapsable'
              dangerouslySetInnerHTML={{ __html: drill.content }}
            ></div>
          )}
        </div>
        {this.state.deleteModal.id > 0 && (
          <TwoButtonModal
            title={`Delete this ${this.state.deleteModal.type}?`}
            subtitle={`If you delete this ${this.state.deleteModal.type}, it will no longer be viewable in Lesson Plans.`}
            close={this._closeDeleteModal}
            confirmText={'Delete'}
            confirmAction={this._handleArchive}
          />
        )}
      </div>
    )
  }

  _handleArchive = () => {
    this.props.handleDelete(this.state.deleteModal.id)
  }

  _handleDelete = (item) => {
    this.setState({ deleteModal: item })
  }

  _closeDeleteModal = () => {
    this.setState({ deleteModal: { type: 'none', id: -1 } })
  }

  // eslint-disable-next-line max-params
  _renderFeatured = (
    selected,
    drill,
    videoLoader,
    videoError,
    videoPreview,
    awsBackupUrl
  ) => {
    if (selected === -1) {
      return this._renderVideoBox(
        drill.video_url,
        drill.video,
        videoLoader,
        videoError,
        videoPreview,
        awsBackupUrl
      )
    } else {
      const photos = drill.photos
      return (
        <div
          className='featured-image'
          style={{ backgroundImage: `url(${photos[selected].file_url})` }}
        />
      )
    }
  }

  _renderFeaturePicker = (drill, videoLoader, videoError) => {
    const photos = drill.photos
    if (photos.length < 1) {
      return false
    }
    const current = this.state.selectedMediaIndex
    return [
      <div
        key='0'
        className='preview-image nav-box'
        aria-hidden='true'
        onClick={this._featureBack}
      >
        <i className='fa fa-caret-left' aria-hidden='true' />
      </div>,
      <div
        key='1'
        className={`preview-image video-preview ${
          videoError ? 'video-error' : null
        }`}
        onClick={() => this._jumpFeature(-1)}
        aria-hidden='true'
        style={current === -1 ? { border: '1px solid #ff6633' } : {}}
      >
        <i
          className={`fa fa-${
            videoLoader ? 'spinner fa-spin' : 'file-video-o'
          }`}
          aria-hidden='true'
        />
      </div>,
      ...photos.map((photo, i) => (
        <div
          key={i + 2}
          className='preview-image'
          aria-hidden='true'
          onClick={() => this._jumpFeature(i)}
          style={
            current === i
              ? {
                border: '1px solid #ff6633',
                backgroundImage: `url(${photo.file_url})`,
              }
              : { backgroundImage: `url(${photo.file_url})` }
          }
        />
      )),
      <div
        key='7'
        className='preview-image nav-box'
        aria-hidden='true'
        onClick={this._featureForward}
      >
        <i className='fa fa-caret-right' aria-hidden='true' />
      </div>,
    ]
  }

  _jumpFeature = (num) => {
    this.setState({ selectedMediaIndex: num })
  }

  _featureForward = () => {
    const current = this.state.selectedMediaIndex
    const numPhotos = this.props.drill.photos.length // this won't show if length is 0, so don't have to account for that
    if (current === numPhotos - 1) {
      return this.setState({ selectedMediaIndex: -1 })
    } else {
      return this.setState({ selectedMediaIndex: current + 1 })
    }
  }

  _featureBack = () => {
    const current = this.state.selectedMediaIndex
    const numPhotos = this.props.drill.photos.length // again, this will not ever be 0
    if (current === -1) {
      return this.setState({ selectedMediaIndex: numPhotos - 1 })
    } else {
      return this.setState({ selectedMediaIndex: current - 1 })
    }
  }

  // eslint-disable-next-line max-params
  _renderVideoBox = (
    video_url,
    video,
    videoLoader,
    videoError,
    videoPreview,
    awsBackupUrl
  ) => {
    if (video_url || awsBackupUrl) {
      return (
        <ReactPlayer
          className='drill-react-player'
          url={awsBackupUrl || video_url}
          width='100%'
          height='250px'
          controls
        />
      )
    }
    if (videoError) {
      return (
        <div className='h-100 d-flex flex-column align-items-center justify-content-center'>
          <i className='fa fa-exclamation-triangle' aria-hidden='true' />
          <p>Whoops, something happened with your video.</p>
          {this.props.fromBuilder && (
            <p className='orange-link' aria-hidden='true' onClick={() => this.props.navigate(1)}>
              Click here to check it out.
            </p>
          )}
        </div>
      )
    }
    if (videoLoader && videoPreview) {
      return (
        <div className='h-100 d-flex flex-column align-items-center justify-content-center'>
          <div className='preview-placeholder'>
            <i className='fa fa-spinner fa-spin' aria-hidden='true' />
          </div>
          <p className='video-name mt-3'>{videoPreview.name}</p>
          <p>is still loading...</p>
          <br />
          <p>You&apos;ll be able to move forward once it&apos;s done.</p>
        </div>
      )
    }
    if (video && !videoLoader) {
      return (
        <div className='h-100 d-flex flex-column align-items-center justify-content-center'>
          <div className='preview-placeholder'>
            <i className='fa fa-file-video-o ' aria-hidden='true' />
          </div>
          <p className='video-name mt-3'>{videoPreview.name}</p>
        </div>
      )
    }
    return (
      <div className='h-100 d-flex flex-column align-items-center justify-content-center'>
        <i className='fa fa-video-camera' aria-hidden='true' />
        <p>
          This {this.props.drill.is_coach_activity ? 'activity' : 'drill'}{' '} doesn&apos;t have a video.
        </p>
        {this.props.fromBuilder && (
          <p className='orange-link' aria-hidden='true' onClick={() => this.props.navigate(1)}>
            Click here to go back to add one.
          </p>
        )}
      </div>
    )
  }

  _handleDropdown = (type) => {
    this.setState({ [type]: !this.state[type] })
  }

  _renderCustomTags = (tags) => tags.map((t, i) => (
    <p key={i} className='shown-tag'>
      {t}
    </p>
  ))

  _renderTags = (idArray, tagList) => {
    if (idArray.length < 1) {
      return <p className='text-center'>-no tags-</p>
    }
    const returnArray = []
    const tags = this._sortTags(idArray, tagList)
    for (const key in tags) {
      if (tags.key) {
        // object with keys being title and tags[key] being an array of tags
        returnArray.push(
          <div key={key} className='tag-category-container'>
            <p className='subsection-label' style={{ fontWeight: 'normal' }}>
              {key}
            </p>
            <div className='tag-display-container'>
              {tags[key].map((t, _) => (
                <p key={t.id} className='shown-tag'>
                  {t.name}
                </p>
              ))}
            </div>
          </div>
        )
      }
    }
    return returnArray
  }

  _sortTags = (idArray, tagList) => {
    const strokes = []
    const location = []
    const group = []
    const obj = {}

    idArray.forEach((id) => {
      const tag = tagList.find((t) => t.id === id)
      if (tag) {
        if (tag.type === 'TagStroke') {
          strokes.push(tag)
        }
        if (tag.type === 'TagLocation') {
          location.push(tag)
        }
        if (tag.type === 'TagGroup') {
          group.push(tag)
        }
      }
    })
    if (strokes.length > 0) {
      obj['Stroke types:'] = strokes
    }
    if (location.length > 0) {
      obj['Location:'] = location
    }
    if (group.length > 0) {
      obj['Group:'] = group
    }
    return obj
  }

  _renderSkills = (idArray, skillList) => idArray.map((id, _) => {
    const skill = skillList.find((s) => s.id === id)
    if (!skill) {
      return false
    }
    return (
      <div className='icon-container' key={skill.id}>
        <div
          className='skill-icon'
          style={{
            backgroundImage: `url(${skill.photo && skill.photo.file_url})`,
          }}
        />
        <p>{skill.name}</p>
      </div>
    )
  })
}

DrillsDetail.propTypes = {
  drill: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    is_favorited: PropTypes.bool,
    is_pinned: PropTypes.bool,
    photos: PropTypes.array,
    is_coach_activity: PropTypes.bool,
    content: PropTypes.string,
    equipment: PropTypes.array,
    privacy: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    description: PropTypes.string,
    user: PropTypes.shape({
      avatar: PropTypes.shape({
        file_url: PropTypes.string,
      }),
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }),
    steps: PropTypes.array,
    tags: PropTypes.array,
    custom_tags: PropTypes.array,
    practice_types: PropTypes.array,
    skills: PropTypes.array,
  }),
  navigate: PropTypes.func,
  fromBuilder: PropTypes.bool,
  tagList: PropTypes.array,
  skillList: PropTypes.array,
  triggerSkillModal: PropTypes.func,
  videoLoader: PropTypes.bool,
  videoError: PropTypes.bool,
  videoPreview: PropTypes.object,
  awsBackupUrl: PropTypes.string,
  isMine: PropTypes.bool,
  fromDrillsPage: PropTypes.bool,
  handleFavorite: PropTypes.func,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  isPublicPage: PropTypes.bool,
}

export default DrillsDetail
