import { useState, useRef, useContext } from 'react'
import PropTypes from 'prop-types'
import profileAvatarUrl from '../../../assets/images/mock/default-profile-icon.png'
import classNames from 'classnames'
import { toast } from 'react-toastify'

import classes from './AccountAvatar.module.scss'
import { useDispatch } from 'react-redux'
import { AccountContext } from '../AccountContext'

// Allow all image types-- some mobile devices will trigger hardware camera when enabled.
const ACCEPTABLE_MIME_TYPES = 'image/*'

const AccountAvatar = () => {
  const { user, isProfile, updateAction } = useContext(AccountContext)
  const { id: userId, email, first_name: firstName, last_name: lastName, avatar } = user

  const dispatch = useDispatch()
  const inputRef = useRef(null)

  const [preview, setPreview] = useState(null)
  const [isUpdating, setIsUpdating] = useState(false)

  const fullName = `${firstName} ${lastName}`

  const handleAvatarChange = e => {
    const uploadPhotoFile = e.target.files[0]
    const uploadPhotoPreview = URL.createObjectURL(uploadPhotoFile)
    setPreview(uploadPhotoPreview)
  }

  const validateForm = formData => {
    if (!formData.get('avatar')) {
      toast.error('Invalid photo')
      return false
    }

    return true
  }

  const handleSubmitForm = async e => {
    e.preventDefault()
    const formData = new FormData(e.target)

    if (validateForm(formData)) {
      const partialUser = {
        id: userId,
        email: email,
        uploadPhotoFile: formData.get('avatar'),
      }

      try {
        setIsUpdating(true)
        await dispatch(updateAction(partialUser))
        setPreview(null)
        toast.success('Sucessfully updated profile photo!')
      } catch {
        toast.error("Couldn't update profile photo")
      } finally {
        setIsUpdating(false)
      }
    }
  }

  return (
    <div className='d-flex align-items-center'>
      <form className='position-relative py-2 pr-2 mr-3' onSubmit={handleSubmitForm}>
        {!isProfile && (
          <button
            type='button'
            onClick={() => inputRef.current?.click()}
            className={classNames(classes.editAvatar, 'position-absolute btn btn-primary user-edit-btn')}
          >
            <i className='fa fa-pencil-square-o' />
          </button>
        )}
        <div className={classes.avatarWrapper}>
          <img
            className={classes.roundImage}
            src={preview || avatar?.file_url || profileAvatarUrl}
            alt={fullName}
          />
        </div>
        <input
          ref={inputRef}
          type='file'
          name='avatar'
          className='d-none'
          accept={ACCEPTABLE_MIME_TYPES}
          onChange={handleAvatarChange}
        />
        {preview && (
          <button type='submit' disabled={isUpdating} className={classNames(classes.saveAvatarBtn, 'btn btn-primary text-white')}>
            {isUpdating ? 'Saving...' : 'Save'}
          </button>
        )}
      </form>

      <h4 className={classes.fullName}>{fullName}</h4>
    </div>
  )
}

AccountAvatar.propTypes = {
  userId: PropTypes.number,
  email: PropTypes.string,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  avatar: PropTypes.shape({
    file_url: PropTypes.string,
  }),
  isProfile: PropTypes.bool,
  updateAction: PropTypes.func,
}

export default AccountAvatar
