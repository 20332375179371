import Modal from '../common/Modal'
import OP36Logo from '../../assets/images/logos/OP36_round_icon_gray.png'
import { useMemo } from 'react'
import MaterialCard from './MaterialCard'
import SubmitButton from '../common/buttons/SubmitButton'
import PropTypes from 'prop-types'

import './MaterialInfoModal.scss'

const MaterialInfoModal = ({ showModal, setShowModal, material, className }) => {
  const title = useMemo(() => {
    if (material.name.includes('Starter Kit')) {
      return 'Starter Kit'
    } else {
      return material.name
    }
  }, [material])

  return (
    <Modal isOpen={showModal} onClose={() => setShowModal(false)} fixedTop>
      <div id='MaterialInfoModal' className='d-flex flex-column align-items-center'>
        <img className='material-modal-logo' src={OP36Logo} alt='op36 logo' />
        <div className='material-modal-title'>{title}</div>
        <div className='material-modal-info'>
          <p>
            This section allows you to include required program materials to your class.
            <br />
            After registration participants will be able to order their {title} online.
          </p>
          <p>
            This is the card that will be displayed during registration:
          </p>
        </div>
        <MaterialCard className={className} material={material} />
        <SubmitButton onClick={() => setShowModal(false)} buttonMessage='Got it!' buttonColor='navy-button' />
      </div>
    </Modal>
  )
}

MaterialInfoModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  material: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    photo: PropTypes.shape({
      fileUrl: PropTypes.string.isRequired,
    }),
  }).isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
}

export default MaterialInfoModal
