import { useSelector } from 'react-redux'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'

import PageHeader from '../common/PageHeader/PageHeader'
import Loader from '../common/Op36Loader-web'
import PricingTab from './PricingTab/PricingTab'
import CommunityLandingPageTab from './CommunityLandingPageTab/CommunityLandingPageTab'

import classes from './CommunitySettingsPage.module.scss'

const tabsData = [
  {
    name: 'Pricing & Packages',
    body: <PricingTab />,
  },
  {
    name: 'Landing Page',
    body: <CommunityLandingPageTab />,
  },
]

const CommunitySettingsPage = () => {
  const isLoadingUserRefresh = useSelector(state => state.loading.isLoading)

  if (isLoadingUserRefresh === false) {
    return (
      <div className={classes['community-settings-page']}>
        <PageHeader title='Community Settings' />
        <Tabs className={classes['tabs']} selectedTabClassName={classes['selected-tab']} selectedTabPanelClassName={classes['selected-tab-panel']}>
          <TabList className={classes['tab-list']}>
            {tabsData.map(tabData => (
              <Tab key={tabData.name} className={classes['tab']}>{tabData.name}</Tab>
            ))}
          </TabList>

          {tabsData.map(tabsData => (
            <TabPanel key={tabsData.name}>
              {tabsData.body}
            </TabPanel>
          ))}
        </Tabs>
      </div>
    )
  }

  return <Loader message='Loading Community Settings...' />
}

export default CommunitySettingsPage
