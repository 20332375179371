import React from "react";
import Notification from "./Notification";
import "./NotificationsPopover.scss";
import ActivityLoader from "react-loaders";

const displayNotifications = (notifications, navigateFromNotification) => {
  if (notifications && notifications.length > 0) {
    return notifications.map(notification => (
      <Notification
        notification={notification}
        navigateFromNotification={navigateFromNotification}
        key={notification.id}
      />
    ));
  }
  return (
    <p className='notifications-empty-text'>
      No notifications to show right now
    </p>
  );
};

const NotificationPopover = ({
  notifications,
  refreshNotifications,
  clearNotifications,
  loader,
  navigateFromNotification
}) => {
  return (
    <div id='NotificationsContainer'>
      {loader ? (
        <div className='p-4 animated fadeIn'>
          <ActivityLoader type='ball-pulse' color={"lightgrey"} />
        </div>
      ) : (
        <div className='notifications-wrapper'>
          <div className='notifications-button-container'>
            {notifications && notifications.length > 0 && (
              <button
                className='btn btn-link notifications-clear-btn'
                onClick={clearNotifications}
              >
                Clear Notifications
              </button>
            )}
            <button
              className='btn btn-link notifications-refresh-btn'
              onClick={refreshNotifications}
            >
              <i className='fa fa-refresh' />
            </button>
          </div>
          {displayNotifications(notifications, navigateFromNotification)}
        </div>
      )}
    </div>
  );
};
export default NotificationPopover;
