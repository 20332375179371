import { useNavigate } from 'react-router-dom'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { UncontrolledPopover } from 'reactstrap'
import moment from 'moment-timezone'

import { formatDates } from '../../../utility/datesHelper'
import EnrollmentsProgressBar from './EnrollmentsProgressBar'
import ConfirmationModal from '../../common/ConfirmationModal'
import { deleteProgram } from '../../../actions/programActions'
import LoadingSpinner from '../../common/LoadingSpinner'

import { ReactComponent as CalendarIcon } from '../../../assets/images/common/icons/calendar-icon.svg'
import { ReactComponent as ActionIcon } from '../../../assets/images/common/icons/more-icon-vertical.svg'
import { ReactComponent as PublicIcon } from '../../../assets/images/common/icons/programBuilder/open-eye-icon.svg'
import { ReactComponent as PrivateIcon } from '../../../assets/images/common/icons/lock-icon.svg'
import { ReactComponent as UnlistedIcon } from '../../../assets/images/common/icons/programBuilder/closed-eye-icon.svg'
import styles from './CoachProgramsCard.module.scss'
import colors from '../../../assets/styles/globals.scss'

const CoachProgramsCard = ( { program, timezone, category, isDraft } ) => {
  const navigate = useNavigate()
  const [showConfirmationModal, setShowConfirmModal] = useState(false)
  const [deleteLoader, setDeleteLoader] = useState(false)
  const dispatch = useDispatch()
  const appliedEnrollments = program.enrollments.filter(enrollment => enrollment.status === 'applied' && enrollment.userId)
  const registrationInFuture = program.registrationStartsAt && new Date(program.registrationStartsAt) > new Date()

  const handleCardClick = () => {
    navigate(`/programs/${program.id}`)
  }

  const visibilityIcon = {
    public: <PublicIcon className={styles.visibilityIcon} data-for={`visibilityTooltip-${program.id}`} data-tip/>,
    private: <PrivateIcon className={styles.visibilityIcon} data-for={`visibilityTooltip-${program.id}`} data-tip/>,
    unlisted: <UnlistedIcon className={styles.visibilityIcon} data-for={`visibilityTooltip-${program.id}`} data-tip/>,
  }

  const handleCloseConfirmDeleteModal = () => {
    setShowConfirmModal(false)
  }

  const handleDeleteProgram = () => {
    handleCloseConfirmDeleteModal()
    setDeleteLoader(true)
    dispatch(deleteProgram(program.id)).then(() => setDeleteLoader(false))
  }

  return (
    <div
      style={{ borderLeftColor: category?.color || '#ddd' }}
      className={classnames('mt-4 d-flex', styles.programCard)}
    >
      <div
        className={classnames('d-flex', styles.programInfoWrapper)}
        onClick={handleCardClick}
        onKeyDown={(e) => e.key === 'Enter' && handleCardClick}
        role='button'
        tabIndex={0}
      >
        {visibilityIcon[program.visibility]}
        <ReactTooltip id={`visibilityTooltip-${program.id}`}
          effect='solid'
          place={'top'}
          className={styles.visibilityTooltip}
        >
          Visibility - {program.visibility}
        </ReactTooltip>

        <div className={classnames('d-flex flex-column', styles.descriptionWrapper)}>
          <h3 className={styles.name}>{program.name}</h3>
          <div className='flex-grow-1 d-flex flex-column justify-content-between justify-content-lg-start flex-lg-row'>
            <span className={classnames('d-lg-flex align-items-lg-center', styles.subtitle)}>{program.meetingTimes}</span>
            { program.startDate &&
              program.endDate &&
              <div className={classnames('d-flex align-items-center', styles.subtitle)}>
                <CalendarIcon className={styles.subtitleIcon} />
                <span className='ml-2'>{formatDates(program.startDate, program.endDate, timezone)}</span>
              </div>
            }
          </div>
        </div>
        <div className={styles.description}>
          { !isDraft && registrationInFuture && `Registration Opens: ${moment.tz(program.registrationStartsAt, timezone).format('MMM, Do, hA z')}` }
          {isDraft && 'This is a Draft'}
        </div>
        {!registrationInFuture && !isDraft &&
          <EnrollmentsProgressBar
            id={program.id}
            students={program.students}
            capacity={program.golferLimit}
            appliedEnrollments={appliedEnrollments}
          />
        }
      </div>
      {appliedEnrollments.length > 0 && <div className={styles.enrollmentsBadge}> + {appliedEnrollments.length} enrollment </div>}
      <div className={classnames(styles.action, 'flex-shrink-0 d-flex justify-content-end align-items-center')}>
        {deleteLoader ?
          <LoadingSpinner/>
          :
          <button className={styles.actionsButton} id={`actionButton-${program.id}`}>
            <div className='d-flex align-items-center'>
              <ActionIcon fill={colors.primaryNavy}/>
            </div>
          </button>
        }
      </div>
      {!deleteLoader &&
        <UncontrolledPopover
          placement='bottom'
          target={`actionButton-${program.id}`}
          trigger='legacy'
        >
          <div className='d-flex flex-column build-program-actions'>
            <Link to={`/programs/${program.id}/edit`}>Edit</Link>
            <Link to={'/announcements/new-campaign'} state = {{ smartToggle: 'email', students: program.students }}>Send an Email</Link>
            <p aria-hidden='true' className={styles.delete} onClick={() => setShowConfirmModal(true)}>Delete</p>
          </div>
        </UncontrolledPopover>
      }
      <ConfirmationModal
        showConfirmationModal={showConfirmationModal}
        closeConfirmationModal={handleCloseConfirmDeleteModal}
        confirmationMessage='Are you sure you want to delete this course? It will permanently delete the enrollments.'
        confirmationDenied={handleCloseConfirmDeleteModal}
        confirmationProceed={handleDeleteProgram}
      />
    </div>
  )
}

CoachProgramsCard.propTypes = {
  program: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    students: PropTypes.array,
    enrollments: PropTypes.array,
    golferLimit: PropTypes.number,
    visibility: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    meetingTimes: PropTypes.string,
    registrationStartsAt: PropTypes.string,
  }),
  timezone: PropTypes.string,
  category: PropTypes.shape({
    color: PropTypes.string,
  }),
  isDraft: PropTypes.bool,
}

export default CoachProgramsCard
