import _ from 'lodash'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import classnames from 'classnames'

import { resetErrors } from '../../../reducers/programPresentationReducer'
import { getSignUpPageProgram } from '../../../actions/programActions'
import Loader from '../../common/Op36Loader-web'
import LockError from '../../common/LockError'

import styles from './ProgramLandingPage.module.scss'

const ProgramLandingPage = () => {
  const params = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const user = useSelector(state => state.user.current_user)
  const { course, isLoading, errors } = useSelector(state => state.programPresentation)

  useEffect(() => {
    dispatch(getSignUpPageProgram(params.courseId))
      .unwrap()
      .then(response => {
        if (response.course.community.id.toString() !== params.communityId) {
          navigate(`/landing/${response.course.community.id}/class/${params.courseId}`)
        }
      })

    return () => {
      dispatch(resetErrors())
    }
  }, [dispatch, navigate, params.communityId, params.courseId])

  return (
    <div className={classnames('h-100 d-flex flex-column', styles['program-landing-page'])}>
      {!_.isEmpty(errors) ? (
        <LockError
          titleText='Program not found'
          linkAttributes={{
            linkMessage: 'Browse other programs',
            navigateTo: user && user.communities[0]?.id ? `/landing/${user.communities[0].id}` : '/community-map',
          }}
        >
          <p>
            This class may have been made private, deleted, or completed, or it may not exist.
          </p>
        </LockError>
      ) : isLoading || _.isEmpty(course)
        ? <Loader message='Loading program' />
        : <Outlet />
      }
    </div>
  )
}

export default ProgramLandingPage
