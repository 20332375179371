import * as types from "../actions/actionTypes";

// state = [] : Start out with no accounts
export default function accountEngagement(state = [], action) {
  switch (action.type) {
    case types.GET_ACCOUNT_ENGAGEMENT:
      return action.data;

    default:
      return state;
  }
}
