import PropTypes from 'prop-types'

import './CoachEmailInfo.scss'
import { ReactComponent as MailIcon } from '../../../../assets/images/common/icons/mail-icon.svg'

const CoachEmailInfo = ({ leadCoach }) => (
  <div id='CoachEmailInfo'>
    <MailIcon className='email-icon' />
    <div className='email-text'>
      If you have any questions please reach out to your lead coach, {leadCoach.firstName} {leadCoach.lastName} via email:&ensp;
      <a className='email-address' href={`mailto:${leadCoach.email}`}>{leadCoach.email}</a>.
    </div>
  </div>
)

CoachEmailInfo.propTypes = {
  leadCoach: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
  }),
}

export default CoachEmailInfo
