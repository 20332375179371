import PropTypes from 'prop-types'
import StudentWithAvatar from '../StudentWithAvatar'

import './StudentPickerCard.scss'

const StudentPickerCard = ({ checked, student, onClick }) => (
  <div className='student-picker-wrapper'>
    <div className='row'>
      <div className='ml-3 d-flex align-items-center'>
        <div
          className={`check-box ${checked && 'checked'}`}
          role='button'
          tabIndex={0}
          onClick={() => onClick(checked, student.id)}
          onKeyDown={null}
        >
          {checked && (
            <i className='fa fa-check' aria-hidden='true' />
          )}
        </div>
      </div>
      <div className='ml-3 col-5 py-2 student-data'>
        <StudentWithAvatar student={student}/>
      </div>
      <div className='col-5 py-2 d-flex align-items-center justify-content-end student-data'>
        <p className='student-title mr-3'>Age: {student.age}</p>
      </div>
    </div>
  </div>
)

StudentPickerCard.propTypes = {
  checked: PropTypes.bool,
  student: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default StudentPickerCard
