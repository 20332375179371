import React from "react";
import "../../assets/styles/components/activity/CommentForm.scss";
import TextInput from "../common/form/TextInput";

const CommentForm = ({
  onInputChange,
  onSubmit,
  loading,
  user_name,
  value,
  web
}) => {
  return (
    <div id="commentForm" className="w-100">
      <form>
        <div className="" id={web ? "commentFormWeb" : "commentFormBox"}>
          <div className="container input-wrapper w-100">
            <div className="row">
              <div className="col-md-9">
                <TextInput
                  name={"Comment Form"}
                  onChange={onInputChange}
                  value={value}
                  className={"commentTextInput"}
                  placeholder={"Comment on activity..."}
                />
              </div>
              <div
                className={`col-md-3 d-flex justify-content-center align-items-center ${!web &&
                  "mt-3"}`}
              >
                <input
                  type="submit"
                  disabled={loading}
                  value={loading ? "Posting..." : "Post"}
                  className="btn btn-primary commentTextSubmit"
                  onClick={onSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CommentForm;
