/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import './avatar.scss'
import defaultProfileIcon from '../../../assets/images/mock/default-profile-icon.png'
import PropTypes from 'prop-types'

const Avatar = props => (
  <div
    className='medium-round-image-wrapper'
    style={{ border: props.hasBorder ? '2px solid #ff6633' : 'none' }}
  >
    <img
      className={'round-image'}
      src={props.imageUrl ? props.imageUrl : defaultProfileIcon}
      alt={props.altName}
      onClick={props.avatarClicked}
    />
  </div>
)

Avatar.propTypes = {
  imageUrl: PropTypes.string,
  altName: PropTypes.string,
  avatarClicked: PropTypes.func,
  hasBorder: PropTypes.bool,
}

export default Avatar
