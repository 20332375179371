import React from "react";
import ReactPlayer from "react-player";

import Loader from "../../../common/Op36Loader-web";
import BlockShow from "../../subcomponents/BlockShow";
import DrillPreviewCard from "../../subcomponents/DrillPreviewCard";
import CurriculumPreviewCard from "../../subcomponents/CurriculumPreviewCard";
import PlaceholderPreviewCard from "../../subcomponents/PlaceholderPreviewCard";

import defaultIcon from "../../../../assets/images/common/icons/lesson_plans/lesson-plan-icon.png";
import calendarIcon from '../../../../assets/images/common/icons/lesson_plans/lesson-calendar.svg'
import clockIcon from '../../../../assets/images/common/icons/lesson_plans/lesson-clock.svg'
import blockIcon from '../../../../assets/images/common/icons/lesson_plans/lesson-block.svg'
import note_icon from "../../../../assets/images/common/icons/note.png";

import "./LessonReviewPublish.scss";
import { withRouting } from "../../../common/hooks";

class LessonReviewPublish extends React.Component {
  state = {
    selectedSession: 0,
    infoModal: false,
    notesModal: false,
    videoModal: false
  };

  // eslint-disable-next-line max-lines-per-function
  render() {
    const { selectedSession, infoModal, notesModal, videoModal } = this.state;
    const {
      isBuilder,
      sessions,
      lessonPlanName,
      lessonPlanNotes,
      lessonPlanTags,
      lessonPlanPhoto,
      backToPage,
      video_url,
      fromProgramPage,
      navigate
    } = this.props;
    return (
      <div id='LessonReviewPublish'>
        <div className='lesson-review-sidebar-container'>
          {lessonPlanPhoto ? (
            <div
              className='lesson-review-photo'
              style={{ backgroundImage: `url(${lessonPlanPhoto})` }}
            />
          ) : (
            <div className='lesson-review-photo-placeholder'>
              <div
                className='lesson-plan-default-icon'
                style={{ backgroundImage: `url(${defaultIcon})` }}
              />
            </div>
          )}
          <div className='lesson-review-title-container'>
            <div className='lesson-review-title'>
              <p className='lesson-reivew-title-text'>{lessonPlanName}</p>
              <i
                className='fa fa-info lesson-info-button'
                aria-hidden='true'
                onClick={this._openInfoModal}
              />
            </div>
            <div className='lesson-review-session-count-container'>
              <div
                className='calendar-icon'
                style={{ backgroundImage: `url(${calendarIcon})` }}
              />
              <p className='lesson-review-session-count'>{sessions.length} </p>
              <p className='lesson-reivew-session-count-label'>{`session${
                sessions.length !== 1 ? "s" : ""
              }`}</p>
              {video_url && (
                <i
                  className='fa fa-video-camera video-indicator'
                  aria-hidden='true'
                  onClick={this._showVideo}
                />
              )}
            </div>
            {!isBuilder && (
              <button
                className='resource-button-link'
                onClick={() => {
                  navigate(
                    '/resource-sections/2',
                    { state: { resourceSectionId: 2 } }
                  )
                  // window.location.reload();
                }}
              >
                Go To Resources
              </button>
            )}
          </div>
          <div className='lesson-review-sidebar'>
            {sessions.map((session, index) => {
              return (
                <div
                  key={index}
                  className={`lesson-review-sidebar-session ${this.state
                    .selectedSession === index &&
                    "lesson-review-active-session"}`}
                  onClick={() => this._selectSession(index)}
                >
                  <p className='lesson-review-sidebar-session-name'>{`${index +
                    1} - ${session.name}`}</p>
                </div>
              );
            })}
          </div>
          {isBuilder && (
            <div className='lesson-review-sidebar-edit-container'>
              <button
                className='jump-to-edit-button'
                onClick={() => backToPage(2)}
              >
                <i className='fa fa-pencil-square-o' aria-hidden='true' /> Edit
                Sessions
              </button>
            </div>
          )}
        </div>
        <div className='lesson-review-content-container'>
          {this.state.sessionLoader ? (
            <div className='session-loader-container'>
              <Loader message={"Grabbing session"} />
            </div>
          ) : (
            <div className='lesson-review-content'>
              <div
                className={`lesson-review-block-section ${
                  fromProgramPage ? "grow" : ""
                }`}
              >
                <div className='lesson-review-session-title-detail'>
                  <p className='detail-session-title'>
                    {sessions[selectedSession].name}
                  </p>
                  <div className='session-detail-box'>
                    <div
                      className='clock-icon'
                      style={{ backgroundImage: `url(${clockIcon})` }}
                    />
                    <p className='review-session-detail-text'>
                      {this._calcTime(sessions[selectedSession].duration)}
                    </p>
                    <div
                      className='block-icon'
                      style={{ backgroundImage: `url(${blockIcon})` }}
                      // <BlockIcon class='blockIcon'/>
                    />
                    <p className='review-session-detail-text'>{`${
                      sessions[selectedSession].blocks.length
                    } block${
                      sessions[selectedSession].blocks.length !== 1 ? "s" : ""
                    }`}</p>
                    {fromProgramPage && sessions[selectedSession].notes && (
                      <i
                        className='fa fa-comments session-notes-trigger'
                        aria-hidden='true'
                        onClick={this._triggerNotesModal}
                      />
                    )}
                  </div>
                </div>
                <div className={`review-session-block-view`}>
                  {sessions[selectedSession].blocks.map((block, index) => {
                    return (
                      <BlockShow
                        key={index}
                        block={block}
                        isBuilder={true}
                        grabTasks={this._grabTasks}
                      />
                    );
                  })}
                </div>
              </div>
              <div
                className={
                  fromProgramPage ? "d-none" : "lesson-review-notes-section"
                }
              >
                <p className='session-notes-title'>Session notes</p>
                <div>{this._renderNote(sessions[selectedSession].notes)}</div>
              </div>
            </div>
          )}
        </div>
        {infoModal && (
          <div className='review-info-modal-container'>
            <div className='grey-out' onClick={this._closeInfoModal} />
            <div className='review-info-modal'>
              <div className='exit-icon-container'>
                <i
                  className='fa fa-times'
                  aria-hidden='true'
                  onClick={this._closeInfoModal}
                />
              </div>
              <p className='review-info-modal-title'>{lessonPlanName}</p>
              <div className='review-info-modal-content'>
                <p className='review-info-modal-subtitle'>Notes</p>
                {lessonPlanNotes ? (
                  <p>{lessonPlanNotes}</p>
                ) : (
                  <p className='lp-info-placeholder-text'>
                    {isBuilder
                      ? "You didn't add any notes to this lesson plan"
                      : "This lesson plan doesn't have any notes"}
                  </p>
                )}
                <p className='review-info-modal-subtitle'>Tags</p>
                <div className='reivew-info-modal-tag-container'>
                  {lessonPlanTags.length > 0 ? (
                    lessonPlanTags.map((t, i) => {
                      return (
                        <p key={i} className='tag-pill'>
                          {t}
                        </p>
                      );
                    })
                  ) : (
                    <p className='lp-info-placeholder-text'>
                      {isBuilder
                        ? "You didn't assign any tags to this lesson plan"
                        : "This lesson plan doesn't have any tags"}
                    </p>
                  )}
                </div>
              </div>
              {isBuilder && (
                <div className='edit-basic-detail-button-container'>
                  <button
                    className='edit-basic-detail-button'
                    onClick={() => backToPage(1)}
                  >
                    <i className='fa fa-pencil-square-o' aria-hidden='true' />
                    Edit Basic Details
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
        {notesModal && (
          <div className='review-info-modal-container'>
            <div className='grey-out' onClick={this._closeNotesModal} />
            <div className='review-info-modal'>
              <div className='exit-icon-container'>
                <i
                  className='fa fa-times'
                  aria-hidden='true'
                  onClick={this._closeNotesModal}
                />
              </div>
              <p className='review-info-modal-title'>Session Notes</p>
              {this._renderNote(sessions[selectedSession].notes)}
            </div>
          </div>
        )}
        {videoModal && (
          <div className='review-info-modal-container'>
            <div className='grey-out' onClick={this._closeVideoModal} />
            <div className='review-info-modal'>
              <div className='exit-icon-container'>
                <i
                  className='fa fa-times'
                  aria-hidden='true'
                  onClick={this._closeVideoModal}
                />
              </div>
              <ReactPlayer
                className='objective-modal-react-player'
                url={video_url}
                width='100%'
                height='240px'
                controls
              />
            </div>
          </div>
        )}
      </div>
    );
  }

  _showVideo = () => {
    this.setState({ videoModal: true });
  };

  _closeVideoModal = () => {
    this.setState({ videoModal: false });
  };

  _triggerNotesModal = () => {
    this.setState({ notesModal: true });
  };

  _closeNotesModal = () => {
    this.setState({ notesModal: false });
  };

  _grabTasks = tasks => {
    return tasks.map((task, i) => {
      if (task.type === "drill") {
        let drill = this.props.drills.find(d => d.id === parseInt(task.id, 10));
        return drill ? (
          <DrillPreviewCard
            drill={drill}
            show={true}
            key={`${i}-${drill.id}`}
            triggerDetailModal={this.props.triggerDrillDetailModal}
          />
        ) : (
          <PlaceholderPreviewCard
            key={i}
            message={"Something happened when loading this activity"}
            active={false}
          />
        );
      } else if (task.type === "subject") {
        let subject = this.props.subjects.find(
          s => s.id === parseInt(task.id, 10)
        );
        return subject ? (
          <CurriculumPreviewCard
            subject={subject}
            show={true}
            key={`${i}-${subject.id}`}
            triggerDetailModal={this.props.triggerCurriculumDetailModal}
          />
        ) : (
          <PlaceholderPreviewCard
            key={i}
            message={"Something happened when loading this objective"}
            active={false}
          />
        );
      }
    });
  };

  _renderNote = note => {
    if (note) {
      let paragraphArray = note.split("\n");
      return paragraphArray.map((p, i) => {
        if (p) {
          return <p key={i}>{p}</p>;
        }
      });
    } else {
      return (
        <div className='review-session-notes-placeholder'>
          <p>This session doesn't have any notes.</p>
          {this.props.isBuilder && (
            <p>
              If you're confused by this, you can add notes to a session by
              clicking the{" "}
              <span style={{ backgroundImage: `url(${note_icon})` }} /> icon
              while creating sessions.
            </p>
          )}
        </div>
      );
    }
  };

  _closeInfoModal = () => {
    this.setState({ infoModal: false });
  };

  _openInfoModal = () => {
    this.setState({ infoModal: true });
  };

  _calcTime = minutes => {
    if (minutes < 0) {
      return "00 hr 00 min";
    }
    let hours = parseInt(minutes / 60, 10).toString();
    let mins = parseInt(minutes % 60, 10).toString();

    if (hours.length < 2) {
      hours = "0" + hours;
    }
    if (mins.length < 2) {
      mins = "0" + mins;
    }
    return `${hours} hr ${mins} min`;
  };

  _selectSession = index => {
    if (index !== this.state.selectedSession) {
      this.setState({ sessionLoader: true }, () => {
        this.setState({ selectedSession: index }, () => {
          this.setState({ sessionLoader: false });
        });
      });
    }
  };
}

export default withRouting(LessonReviewPublish)
