import * as types from "./actionTypes";
import * as common from "./commonActions";

import Axios from '../axios'

export function successRequestingPracticeTypes(response) {
  if (response.status === 200) {
    return {
      type: types.ADD_PRACTICE_TYPES,
      practice_types: response.data.practice_types
    };
  } else {
    console.log("ERROR ON PRACTICE TYPES");
    return { type: types.UI_ERROR, error: response.data };
  }
}

export function getPracticeTypes() {
  return function(dispatch) {
    dispatch(common.loadingChanged(true));
    return requestPracticeTypes()
      .then(response => dispatch(successRequestingPracticeTypes(response)))
      .then(response => dispatch(common.loadingChanged(false)));
  };
}

function requestPracticeTypes() {
  let requestUrl = "/api/v1/practice_types";
  return Axios.get(requestUrl);
}
