import * as constants from './actionTypes'
import { serialize, deserialize } from '../utility/serialization'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { handleAPIError } from '../utility/actionHelper'

import Axios from '../axios'

export function successRequestingSchedule(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_SCHEDULE,
      schedule: response.data.schedule,
    }
  } else {
    return { type: constants.UI_ERROR, error: response.data }
  }
}

export function successRequestingCreateSchedule(response) {
  if (response.status === 201) {
    return {
      type: constants.CREATE_SCHEDULE,
      schedule: response.data.schedule,
    }
  } else {
    return { type: constants.UI_ERROR, error: response.data }
  }
}

export function successDeletingSchedule(response) {
  if (response.status === 200) {
    return {
      type: constants.DELETE_SCHEDULE,
      schedule: response.data.schedule,
    }
  } else {
    return { type: constants.UI_ERROR, error: response.data }
  }
}

export function successRequestingUpdateSchedule(response) {
  if (response.status === 200) {
    return {
      type: constants.UPDATE_SCHEDULE,
      schedule: response.data.schedule,
    }
  } else {
    return { type: constants.UI_ERROR, error: response.data }
  }
}

// THUNKS

export function getCourseSchedule(course_id) {
  return function(dispatch) {
    return requestCourseSchedule(course_id)
      .then(response => dispatch(successRequestingSchedule(deserialize(response))))
      .then(response => deserialize(response))
  }
}

export const getProgramSchedule = createAsyncThunk(
  'schedules/getProgramSchedule',
  async (programId, { rejectWithValue }) => {
    try {
      const { data } = await requestCourseSchedule(programId)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export function createSchedule(schedule) {
  return function(dispatch) {
    return requestCreateSchedule(schedule)
      .then(response => dispatch(successRequestingCreateSchedule(deserialize(response))))
      .then(response => deserialize(response))
  }
}

export const createProgramSchedule = createAsyncThunk(
  'schedules/createProgramSchedule',
  async (schedule, { rejectWithValue }) => {
    try {
      const { data } = await requestCreateSchedule(schedule)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export function updateSchedule(schedule) {
  return function(dispatch) {
    return requestUpdateSchedule(schedule)
      .then(response => dispatch(successRequestingUpdateSchedule(deserialize(response))))
      .then(response => deserialize(response))
  }
}

export const updateProgramSchedule = createAsyncThunk(
  'schedules/updateProgramSchedule',
  async (schedule, { rejectWithValue }) => {
    try {
      const { data } = await requestUpdateSchedule(schedule)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

// API CALLS

function requestCourseSchedule(course_id) {
  const requestUrl = '/api/v1/schedules/course/' + course_id

  return Axios.get(requestUrl)
}

function requestCreateSchedule(schedule) {
  const requestUrl = '/api/v1/schedules'

  const scheduleSessions = schedule.scheduleSessions.map(({ title, location, description, startDate, endDate, isInvalid }) => ({
    title,
    location,
    description,
    isInvalid,
    startDate: typeof(startDate) === 'string' ? startDate : startDate.toISOString(),
    endDate: typeof(endDate) === 'string' ? endDate : endDate.toISOString(),
  }))

  const data = serialize({ course_id: schedule.courseId, schedule_sessions: scheduleSessions })
  return Axios.post(requestUrl, data)
}

function requestUpdateSchedule(schedule) {
  const requestUrl = '/api/v1/schedules/' + schedule.id

  const scheduleSessions = schedule.scheduleSessions.map(({ id, title, location, description, startDate, endDate, deleted, isInvalid }) => ({
    id: id ?? null,
    title,
    location,
    description,
    isInvalid,
    deleted: deleted ?? false,
    startDate: typeof(startDate) === 'string' ? startDate : startDate.toISOString(),
    endDate: typeof(endDate) === 'string' ? endDate : endDate.toISOString(),
  }))

  const data = serialize({ ...schedule, scheduleSessions: scheduleSessions })
  return Axios.put(requestUrl, data)
}
