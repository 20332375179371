import PropTypes from 'prop-types'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import classnames from 'classnames'
import { toast, ToastContainer } from 'react-toastify'
import { Col, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import Embed from 'react-tiny-oembed'

import { RegisterButton } from './ProgramPresentationPage'
import CommunityContactModal from '../../../community-contact-modal/CommunityContactModal'

import styles from './ProgramActionsCard.module.scss'
import { ReactComponent as GolferIcon } from '../../../../assets/images/common/icons/golfer-half-icon.svg'
import { ReactComponent as CallIcon } from '../../../../assets/images/common/icons/call-icon.svg'
import { ReactComponent as MailIcon } from '../../../../assets/images/common/icons/mail-icon.svg'
import { ReactComponent as ShareIcon } from '../../../../assets/images/common/icons/share-icon.svg'
import CommunityAvatarPlaceholder from '../../../../assets/images/mock/classRowBanner.png'

const ProgramActionsCard = ({ className }) => {
  const [isModalOpen, setModalOpen] = useState(false)

  const course = useSelector(state => state.programPresentation.course)
  const community = course.community
  const isPhoneNumberPresent = !!community.phoneNumber
  const landingPageUrl = `${process.env.REACT_APP_BASE_URL}/landing/${community.id}/class/${course.id}`

  const handleClickShareButton = () => {
    navigator.clipboard.writeText(landingPageUrl)
      .then(() => {
        toast.info('Link was copied to clipboard', {
          toastId: 'LinkCopiedToast',
          position: 'bottom-right',
          autoClose: 2500,
        })
      })
  }

  return (
    <>
      <div className={classnames('flex-column pt-lg-3 pb-lg-3 justify-content-between', styles['program-actions-card'], !!className && className)}>
        {!!course.personalizedVideoUrl && (
          <div className={classnames('mt-3 mt-lg-0 mb-lg-4 mx-4', styles['video-wrapper'])}>
            <Embed url={course.personalizedVideoUrl} />
          </div>
        )}
        <Link
          to={`/landing/${community.id}`}
          className='d-none px-4 d-lg-flex justify-content-center align-items-center'
          onClick={(e) => course.previewMode && e.preventDefault()}
        >
          <div className={classnames('round-image-wrapper', styles['light-navy-border'])}>
            <img
              className='round-image'
              src={community.photo ? community.photo.fileUrl : CommunityAvatarPlaceholder}
              alt='community'
            />
          </div>
          <h2 className={classnames('ml-2 mb-0', styles['program-community-name'])} title={course?.community?.name}>{community.name}</h2>
        </Link>
        {(course.spotsLeft || course.spotsLeft === 0) && (
          <h3 className={classnames('px-4 py-3 d-flex align-items-center justify-content-center', styles['program-spots-left'])}>
            <GolferIcon className={styles.icon} />
            <span className='ml-2 text-center'><span className={styles['open-spots-number']}>{course.spotsLeft}</span> open spots</span>
          </h3>
        )}
        <div className={classnames('px-5 py-3', styles['program-contact-share-actions'])}>
          <Row>
            {isPhoneNumberPresent && (
              <Col xs={6} md={4} className='d-md-flex align-items-center'>
                <a
                  href={`tel:${community.phoneNumber}`}
                  className={classnames('w-100 d-flex animation-shrink align-items-center', styles['program-action'])}
                >
                  <CallIcon className={styles.icon} />
                  <span className='ml-2'>Call us</span>
                </a>
              </Col>
            )}
            <Col xs={6} md={isPhoneNumberPresent ? 4 : 6} className='d-md-flex align-items-center'>
              <button
                onClick={(e) => course.previewMode ? e.preventDefault() : setModalOpen(true)}
                className={classnames(
                  'w-100 d-flex animation-shrink align-items-center justify-content-md-center',
                  styles['program-action'],
                  { 'justify-content-end': isPhoneNumberPresent }
                )}
              >
                <MailIcon className={styles.icon} />
                <span className='ml-2'>Send email</span>
              </button>
            </Col>
            <Col
              xs={isPhoneNumberPresent ? 12 : 6}
              md={isPhoneNumberPresent ? 4 : 6}
              className={classnames('d-md-flex align-items-center', { 'pt-2 pt-md-0': isPhoneNumberPresent })}
            >
              <button
                className={classnames(
                  'w-100 d-flex animation-shrink align-items-center',
                  styles['program-action'],
                  isPhoneNumberPresent ? 'justify-content-center justify-content-md-end' : 'justify-content-end justify-content-lg-center'
                )}
                onClick={(e) => course.previewMode ? e.preventDefault() : handleClickShareButton()}
              >
                <ShareIcon className={styles.icon} />
                <span className='ml-2'>Copy & Share</span>
              </button>
            </Col>
          </Row>
          <Row className='mt-3 d-none d-lg-flex'>
            <RegisterButton />
          </Row>
        </div>
      </div>
      <CommunityContactModal
        communityId={community.id}
        communityName={community.name}
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
      />
      <ToastContainer />
    </>
  )
}

ProgramActionsCard.propTypes = {
  className: PropTypes.string,
}

export default ProgramActionsCard
