import { useSelector } from 'react-redux'
import { displayWithCurrency } from '../../../../../utility/currency'
import TooltipText from '../../../../common/TooltipText'
import Tooltip from '../../../../common/Tooltip'

import './RosterSummary.scss'

const RosterSummary = ({ course }) => {
  const currency = useSelector(state => state.user.current_user.communities[0].currency)

  return (
    <div id='RosterSummary' className='d-flex flex-column justify-content-center align-items-center'>
      <Tooltip offset={{ top: '-8' }} />

      <p className='roster-summary-label'>Roster summary</p>
      <table className='table table-borderless table-responsive w-100'>
        <thead>
          <tr>
            <th style={{ width: '90%' }}>Package</th>
            <th style={{ width: '10%' }}>Golfers</th>
          </tr>
        </thead>
        <tbody>{course.used_packages.map((pkg) =>
          <tr
            key={pkg.id}
            className={'used-packages-table-row'}
          >
            <td className='package-column'>
              <p className={pkg.name === 'No Package' ? 'title-no-package' : 'title'}>{pkg.name}</p>
              { pkg.name !== 'No Package' &&
                <div className='d-flex justify-content-start align-items-center'>
                  <p className='price'>{displayWithCurrency(pkg.price, currency, true)}</p>
                  { pkg.description &&
                    <>
                      <label className='separating-dot'>·</label>
                      <TooltipText text={pkg.description} className='description'/>
                    </>
                  }
                </div>
              }
              <div className='d-flex justify-content-start align-items-center'>
                { pkg.detached &&
                  <p className='mt-1 mr-1 detached-archived-package'>Detached</p>
                }
                { pkg.archived &&
                  <p className='mt-1 detached-archived-package'>Archived</p>
                }
              </div>
            </td>
            <td className='golfers-count-column'>
              <p>{pkg.enrollment_count}</p>
            </td>
          </tr>
        )}
        </tbody>
      </table>
    </div>
  )
}

export default RosterSummary
