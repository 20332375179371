import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { ReactComponent as BackArrowIcon } from '../../../assets/images/common/icons/programBuilder/back-arrow-icon.svg'
import { setField } from '../../../reducers/programBuilderReducer'

import styles from './BuilderFooter.module.scss'

const BuilderFooter = ({ onSaveProgram, setShowSaveModal, loader, dataLoaded }) => {
  const dispatch = useDispatch()

  const programFields = useSelector(state => state.programBuilder)
  const { id, isDraft, step } = programFields

  const setStep = (value) => {
    dispatch(setField({ field: 'step', value: value }))
  }

  return (
    <section className={`fixed-bottom ${styles['footer-container']}`}>
      <div className={`row ${styles.content}`}>
        {!dataLoaded && (
          <>
            <div className={`${styles['step-button']} col-12 col-sm-6 d-flex justify-content-center align-items-center`}>
              {step > 1 && (
                <button
                  className={`btn btn-primary ml-sm-auto ${styles['back-button']}`}
                  onClick={() => setStep(step - 1)}
                >
                  <div className='d-flex align-items-center'>
                    <BackArrowIcon aria-hidden='true' className={styles['back-icon']} />
                    <span className='mr-5'>Back</span>
                  </div>
                </button>
              )}
            </div>

            <div className={`${styles['step-button']} col-12 col-sm-6 d-flex justify-content-center align-items-center`}>
              {step < 4 ? (
                <button
                  className={`btn btn-primary mr-sm-auto ${styles['next-button']}`}
                  onClick={() => setStep(step + 1)}
                >
                  <div className='d-flex align-items-center'>
                    <span className='ml-5'>Next</span>
                    <BackArrowIcon aria-hidden='true' className={styles['next-icon']} />
                  </div>
                </button>
              ) : (
                <button
                  className={`btn btn-primary mr-sm-auto ${styles['save-button']}`}
                  onClick={(id && !isDraft) ? () => onSaveProgram(false) : () => setShowSaveModal(true)}
                  disabled={loader}
                >
                  {(id && !isDraft) ? 'Save' : 'Save or Publish'}
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </section>
  )
}

BuilderFooter.propTypes = {
  onSaveProgram: PropTypes.func.isRequired,
  setShowSaveModal: PropTypes.func.isRequired,
  loader: PropTypes.bool.isRequired,
  dataLoaded: PropTypes.bool.isRequired,
}

export default BuilderFooter
