import Modal from 'react-modal'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { ReactComponent as ModalXIcon } from '../../../assets/images/common/icons/modal-X-icon.svg'
import { ReactComponent as WarningIcon } from '../../../assets/images/common/icons/warning-icon.svg'
import SubmitButton from '../../common/buttons/SubmitButton'

import classes from './DeleteAccountModal.module.scss'
import Input from '../../common/form/Input'

const DeleteAccountModal = ({ showModal, closeModal, password, passwordError, handlePasswordChange, handleDeleteAccount }) =>
  <Modal
    isOpen={showModal}
    onRequestClose={closeModal}
    contentLabel='Account Form Delete Account Modal'
    className={classes.deleteAccountModalContent}
    autoFocus
  >
    <div className='animated fadeIn'>
      <div className='d-flex justify-content-end text-align-right'>
        <ModalXIcon className={classes.modalXIcon} onClick={closeModal} />
      </div>
      <div className='d-flex flex-column justify-content-center align-items-center'>
        <WarningIcon className={classes.warningIcon} />
        <p className={classes.title}>Delete Account</p>
        <p className={classNames('text-center', classes.message)}>
          The student&apos;s password is required to delete their account.
        </p>
      </div>
      <label htmlFor='password' className='my-2 font-weight-bold'>
        Password <span className='required-text'>*</span>
      </label>
      <Input
        name='password'
        type='password'
        value={password}
        onChange={handlePasswordChange}
        placeholder='Enter the student password'
        error={passwordError}
      />
      <div className='row py-3'>
        <div className='col-12'>
          <SubmitButton
            onClick={handleDeleteAccount}
            buttonMessage='Delete Account'
            buttonColor='orange-button'
          />
        </div>
        <div className='col-12'>
          <SubmitButton
            onClick={closeModal}
            buttonMessage='Cancel'
            buttonColor='navy-button'
          />
        </div>
      </div>
    </div>
  </Modal>

DeleteAccountModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  passwordError: PropTypes.string.isRequired,
  handlePasswordChange: PropTypes.func.isRequired,
  handleDeleteAccount: PropTypes.func.isRequired,
}

export default DeleteAccountModal
