import React, { Component } from "react";

// this component sends the value back through
// onValueChange callback as an integer representing
// the number of CENTS, not dollars.

// PROPS //
// className => string => classes you want to apply to the input element
// max => int => the highest number you want the user to be able to type.
// onValueChange => func => this is your callback for doing something with the returned value (again, cents)
// value => int => pass in value to the component (generally will be from state...)
// style => obj => any styles you want to apply to the input element. It doesn't come with any styles out of the box

class CurrencyInput extends Component {
  render() {
    const { className, style = {}, value } = this.props;
    return (
      <input
        className={className}
        inputMode='numeric'
        onChange={this._handleChange}
        onKeyDown={this._handleKeyDown}
        style={style}
        value={this._valueDisplay(value)}
      />
    );
  }

  _valueDisplay = val => {
    return (val / 100).toLocaleString("en-US", {
      style: "currency",
      currency: "USD"
    });
  };

  _handleChange = () => {
    // don't do anything. the change will be handled in the keydown.
    // this just keeps the React warning away about not having a onChange
  };

  _handleKeyDown = e => {
    const { key, keyCode } = e;
    const { value, max = Number.MAX_SAFE_INTEGER, onValueChange } = this.props;
    const VALID_FIRST = /^[1-9]{1}$/;
    const VALID_NEXT = /^[0-9]{1}$/;
    const DELETE_KEY_CODE = 8;

    if (
      (value === 0 && !VALID_FIRST.test(key)) ||
      (value !== 0 && !VALID_NEXT.test(key) && keyCode !== DELETE_KEY_CODE)
    ) {
      return;
    }

    const valueString = value.toString();
    let nextValue;

    if (keyCode !== DELETE_KEY_CODE) {
      const nextValueString = value === 0 ? key : `${valueString}${key}`;
      nextValue = parseInt(nextValueString, 10);
    } else {
      const nextValueString = valueString.slice(0, -1);
      nextValue = nextValueString === "" ? 0 : parseInt(nextValueString, 10);
    }
    if (nextValue > max) {
      return;
    }
    onValueChange(nextValue);
  };
}

export default CurrencyInput;
