import React, { Component } from 'react';

import './ImageUploader.scss'

class ImageUploader extends Component {
  constructor(props) {
    super(props);

    this.state = {}
  }

  render(){
    return (
      <div className='image-uploader-container'>
        <p className='image-uploader-text'>{this.props.label}</p>
        <p className='image-uploader-recommendation'>please use square images - recommended size 720px x 480px | 16:9 ratio</p>
        <input
          type='file'
          id='input-photo-upload'
          name='file'
          onChange={this.props.onChange}
          accept={this.props.accept}
          className='image-uploader-input'
          multiple />
        <label htmlFor="file" className='image-uploader-label' onClick={this.props.selectImages}><i className="fa fa-upload image-uploader-icon" aria-hidden="true"></i>{this.props.images.length > 0 ? `${this.props.images.length} images selected` : 'Choose Images'}</label>
        { this.props.images.length > 0 ? <button className='image-uploader-reset' onClick={this.props.reset}>Clear Images</button> : null }
        { this.props.images.length > 5 ? <p className='image-uploader-error'>you have too many images uploaded - please clear and try again</p> : null }
      </div>
    )
  }
}

export default ImageUploader;
