import React from "react";

import PlayerJourneyReportView from "./PlayerJourneyReportView";

import "./PlayerJourneyReportContainer.scss";

const PlayerJourneyReportContainer = ({ reports, selectedStudents }) => {
	return (
		<div id='PlayerJourneyReportContainer'>
			{reports.player_journey_report.map((rep, i) => {
				return <PlayerJourneyReportView report={rep} key={i} student={selectedStudents[i]} />;
			})}
		</div>
	);
};

export default PlayerJourneyReportContainer;
