import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import './CoachDashboardCalendar.scss'
import { ReactComponent as ArrowIcon } from '../../../assets/images/common/icons/arrow-right.svg'
import { useDispatch, useSelector } from 'react-redux'
import { eventsDates, sessionsDates, setSelectedDate, setSelectedMonthDate } from '../../../reducers/dashboardReducer'

const CoachDashboardCalendar = () => {
  const sessions = useSelector(sessionsDates)
  const events = useSelector(eventsDates)
  const selectedDate = useSelector(state => state.dashboard.selectedDate)
  const dispatch = useDispatch()

  function isSameDay(a, b) {
    return a.getDate() === b.getDate() && a.getMonth() === b.getMonth() && a.getYear() === b.getYear()
  }

  const tileContent = ({ date, view }) => {
    if (view === 'month') {
      if (sessions.find(dDate => isSameDay(dDate, date)) && events.find(dDate => isSameDay(dDate, date))) {
        return <div className='eventAndProgram'>
          <div className='programDate noMargin'/>
          <div className='eventDate noMargin'/>
        </div>
      }
      if (sessions.find(dDate => isSameDay(dDate, date))) {
        return <div className='programDate'/>
      }
      if (events.find(dDate => isSameDay(dDate, date))) {
        return <div className='eventDate'/>
      }
      return <div className='noProgramDate'/>
    }
  }

  return (
    <div className='calendarWrapper'>
      <Calendar
        calendarType='gregory'
        nextLabel={<ArrowIcon className='nextArrow'/>}
        prevLabel={<ArrowIcon className='prevArrow'/>}
        tileContent={tileContent}
        value={selectedDate}
        onChange={(value) => dispatch(setSelectedDate(value))}
        onActiveStartDateChange={({ activeStartDate }) => dispatch(setSelectedMonthDate(activeStartDate))}/>

      <div className='calendarLegend'>
        <div className='programDate'/>
        <span>Programs</span>
        <div className='eventDate'/>
        <span>9 Hole Event Series</span>
      </div>
    </div>
  )
}

export default CoachDashboardCalendar
