import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

import SearchInput from '../common/SearchInput'
import _ from 'lodash'
import Modal from 'react-modal'

import CommunityForm from './CommunityForm'
import PageHeader from '../common/PageHeader/PageHeader'
import ConfirmationModal from '../common/ConfirmationModal'

import '../../assets/styles/components/communities/CommunitiesPage.scss'

import * as commonActions from '../../actions/commonActions'
import * as authActions from '../../actions/authActions'
import * as communitiesActions from '../../actions/communitiesActions'
import * as reportsActions from '../../actions/reportsActions'
import * as representativesActions from '../../actions/representativesActions'
import * as representativeActionTypesActions from '../../actions/representativeActionTypesActions'
import * as representativeNotesActions from '../../actions/representativeNotesActions'
import * as currenciesActions from '../../actions/currenciesActions'

import { withRouting } from '../common/hooks'
import CommunitiesTable from './CommunitiesTable'

class CommunitiesPage extends React.Component {
  NAME_KEY = 'name'
  constructor(props, context) {
    super(props, context)
    this.state = {
      showCommunitiesModal: false,
      isToggleModal: false,
      searchTerm: '',
      communityLoader: false,
      repLoader: false,
      support_rating: 0,
      errors: {},
      page: 1,
    }

    this.openCommunitiesModal = this.openCommunitiesModal.bind(this)
    this.closeCommunitiesModal = this.closeCommunitiesModal.bind(this)
    this.showErrors = this.showErrors.bind(this)
    this.fetchCommunities = this.fetchCommunities.bind(this)
  }
  componentDidMount() {
    const {
      common_actions,
      representatives,
      representative_action_types,
      location,
    } = this.props
    common_actions.setCurrentTab('Communities')
    common_actions.showSideBar(true)
    common_actions.showNavBar(true)
    if (
      location.state &&
      location.state.communityId &&
      location.state.smartToggle
    ) {
      this.navigateToCommunity(location.state.communityId)
    }
    if (!representatives || representatives.length === 0) { this.fetchRepresentatives() }
    if (
      !representative_action_types ||
      representative_action_types.length === 0
    ) { this.fetchRepresentativeActionTypes() }
    this.fetchCurrencies()
  }


  fetchRepresentativeActionTypes = () => {
    const { representative_action_types_actions } = this.props

    this.setState({ repLoader: true }, () => {
      representative_action_types_actions
        .getRepresentativeActionTypes()
        .then(() => {
          this.setState({ repLoader: false })
        })
    })
  }

  fetchRepresentatives = () => {
    const { representatives_actions } = this.props

    this.setState({ repLoader: true }, () => {
      representatives_actions.getRepresentatives().then(() => {
        this.setState({ repLoader: false })
      })
    })
  }

  fetchCurrencies = () => {
    this.props.currencies_actions.getCurrencies()
  }

  navigateToCommunity = (communityId) => {
    this.props.navigate(`/communities/${communityId}`)
  }

  handleSubmitCommunity = (event, community) => {
    this.setState({ loader: true })
    this.props.communities_actions
      .updateCommunity(community)
      .then(() => this.setState({ showConfirmationModal: false, loader: false, errors: {} }))
      .catch((err) => this.setState({ loader: false, errors: err.response.data.errors }))
  }

  handleAddCommunity = (event, community) => {
    this.setState({ loader: true })
    this.props.communities_actions
      .createCommunity(community)
      .then(() => this.setState({ showCommunitiesModal: false, loader: false, errors: {} }))
      .catch((err) => this.setState({ loader: false, errors: err.response.data.errors }))
  }

  openCommunitiesModal() {
    this.setState({
      showCommunitiesModal: true,
      isToggleModal: false,
      editCommunity: null,
    })
  }

  debouncedSearch = _.debounce(this.fetchCommunities, 700)

  searchUpdated = (e) => {
    this.setState({ searchTerm: e.target.value }, () => {
      this.debouncedSearch()
    })
  }

  fetchCommunities(newPage = 1) {
    this.setState({ communityLoader: true, page: newPage })
    this.props.communities_actions.getCommunitiesPaginated(newPage, 10, this.state.searchTerm)
      .then(() => this.setState({ communityLoader: false }))
      .catch((_) => {
        this.setState({ communityLoader: false })
        toast.error('Something went wrong while retrieving the communities', { position: toast.POSITION.BOTTOM_LEFT })
      })
  }

  toggleCommunity = (community) => {
    this.setState({
      showConfirmationModal: true,
      confirmationMessage: `Are you sure you want to ${
        community.isActive ? 'deactivate' : 'activate'
      } '${community.name}'?`,
      selectedCommunity: community,
      isToggleModal: true,
      toggledCommunity: {
        id: community.id,
        is_active: !community.isActive,
      },
    })
  }

  toggleConfirmationModal = (community) => {
    this.setState({
      showConfirmationModal: true,
      confirmationMessage: `Are you sure you want to login to '${community.name}'?`,
      selectedCommunity: community,
      isToggleModal: false,
    })
  }

  closeCommunitiesModal() {
    this.setState({
      showCommunitiesModal: false,
    })
  }

  closeConfirmationModal = () => {
    this.setState({
      showConfirmationModal: false,
    })
  }

  loginToCommmunity = () => {
    const { selectedCommunity } = this.state
    this.setState({ loader: true })
    this.props.auth_actions.signInSuperUser(selectedCommunity).then(() => {
      this.props.navigate('/dashboard')
      this.setState({ loader: false })
    })
  }

  userLoggedIn() {
    // Register for push notifications
    this.props.navigate('/dashboard')
    this.setState({ loading: false })
    if (window.FirebasePlugin) {
      window.FirebasePlugin.grantPermission()
      window.FirebasePlugin.subscribe('op36user')
    }
  }

  displayCommunityModal() {
    const { representatives, currencies } = this.props
    const { errors, loader } = this.state
    return (
      <Modal
        isOpen={this.state.showCommunitiesModal}
        onRequestClose={this.closeCommunitiesModal}
        contentLabel='Example Modal'
      >
        <div className='modal-header row'>
          <div className='col-11'>
            <h5 className='modal-title display-4'>
              {this.state.editCommunity === null ? 'New' : 'Edit'} Community
            </h5>
          </div>
          <div className='col-1'>
            <button
              type='button'
              className='close pull-right font-200'
              data-dismiss='modal'
              aria-label='Close'
              onClick={this.closeCommunitiesModal}
            >
              <span aria-hidden='true'>✕</span>
            </button>
          </div>
        </div>
        <div className='modal-content'>
          <div className='modal-body' id='input-border'>
            <div className='row mb-2 p-2'>
              <div className='col-md-6 mx-auto'>
                <div className='form-group'>
                  <CommunityForm
                    editCommunity={this.state.editCommunity}
                    closeModal={this.closeCommunitiesModal}
                    handleAddCommunity={this.handleAddCommunity}
                    handleSubmitCommunity={this.handleSubmitCommunity}
                    representatives={representatives}
                    currencies={currencies}
                    errors={errors}
                    loader={loader}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }

  renderStarColor = (rating) => {
    switch (rating) {
      case 0:
        return '#ed213a'
      case 1:
        return '#ed213a'
      case 2:
        return '#ed213a'
      case 3:
        return '#fddb10'
      case 4:
        return '#fddb10'
      case 5:
        return '#33d9b2'
      default:
        return '#ff6633'
    }
  }

  showErrors() {
    if (this.state.errors) {
      return (
        <div id='formErrors'>
          <p>
            <span className='icon icon-info' />
            {this.state.errors}
          </p>
        </div>
      )
    } else {
      return ''
    }
  }

  headerActionButton = () => (
    <button
      className='btn btn-primary text-white pull-right mr-2'
      onClick={this.openCommunitiesModal}
    >
      Add Community
    </button>
  )

  render() {
    return (
      <div id='CommunitiesPage'>
        <PageHeader
          title='Communities'
          actionComponent={this.headerActionButton()}
        />

        <div className='communities-search-bar mt-2'>
          <SearchInput placeholder='Search Communities' onChange={this.searchUpdated}/>
        </div>

        <CommunitiesTable
          fetchCommunities={this.fetchCommunities}
          toggleCommunity={this.toggleCommunity}
          toggleConfirmationModal={this.toggleConfirmationModal}
          loader={this.state.communityLoader}
          page={this.state.page}
          searchTerm={this.state.searchTerm}
        />
        {this.displayCommunityModal()}
        <ConfirmationModal
          showConfirmationModal={this.state.showConfirmationModal}
          closeConfirmationModal={this.closeConfirmationModal}
          confirmationMessage={this.state.confirmationMessage}
          confirmationDenied={this.closeConfirmationModal}
          confirmationProceed={
            this.state.isToggleModal
              ? (e) => this.handleSubmitCommunity(e, this.state.toggledCommunity)
              : this.loginToCommmunity
          }
        />
      </div>
    )
  }
}

CommunitiesPage.propTypes = {
  representatives: PropTypes.array,
  currencies: PropTypes.array,
  representative_action_types: PropTypes.array,
  navigate: PropTypes.func,
  location: PropTypes.shape({
    state: PropTypes.shape({
      communityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      smartToggle: PropTypes.bool,
    }),
  }),
  auth_actions: PropTypes.shape({
    signInSuperUser: PropTypes.func,
  }),
  common_actions: PropTypes.shape({
    setCurrentTab: PropTypes.func,
    showSideBar: PropTypes.func,
    showNavBar: PropTypes.func,
  }),
  communities_actions: PropTypes.shape({
    createCommunity: PropTypes.func,
    updateCommunity: PropTypes.func,
    getCommunitiesPaginated: PropTypes.func,
  }),
  currencies_actions: PropTypes.shape({
    getCurrencies: PropTypes.func,
  }),
  representatives_actions: PropTypes.shape({
    getRepresentatives: PropTypes.func,
  }),
  representative_action_types_actions: PropTypes.shape({
    getRepresentativeActionTypes: PropTypes.func,
  }),
}

function mapStateToProps(state) {
  return {
    ui: state.ui,
    community: state.community,
    reports: state.reports,
    representatives: state.representatives,
    representative_action_types: state.representativeActionTypes,
    representative_notes: state.representativeNotes,
    currencies: state.currencies,
    pages: state.pages,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    common_actions: bindActionCreators(commonActions, dispatch),
    communities_actions: bindActionCreators(communitiesActions, dispatch),
    representatives_actions: bindActionCreators(
      representativesActions,
      dispatch
    ),
    representative_action_types_actions: bindActionCreators(
      representativeActionTypesActions,
      dispatch
    ),
    representative_notes_actions: bindActionCreators(
      representativeNotesActions,
      dispatch
    ),
    reports_actions: bindActionCreators(reportsActions, dispatch),
    currencies_actions: bindActionCreators(currenciesActions, dispatch),
    auth_actions: bindActionCreators(authActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouting(CommunitiesPage))
