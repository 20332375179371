import * as types from "../actions/actionTypes";

// state = [] : Start out with no accounts
export default function onboardingNeededAccounts(state = [], action) {
  switch (action.type) {
    case types.GET_ONBOARDING_NEEDED_ACCOUNTS:
      return action.onboarding_needed_accounts;

    default:
      return state;
  }
}
