import Modal from '../../common/Modal'
import PropTypes from 'prop-types'

import SubmitButton from '../../common/buttons/SubmitButton'

import { ReactComponent as GolferIcon } from '../../../assets/images/common/icons/golfer-half-icon.svg'

import './ExitModal.scss'

const ExitModal = ({ showModal, closeModal, onSubmit }) => (
  <Modal
    isOpen={showModal}
    onClose={closeModal}
    contentLabel='Exit Event Modal'
    className='exit-modal'
    autoFocus
  >
    <div id='ExitModal'>
      <div className='d-flex flex-column align-items-center modal-container'>
        <div className='d-flex justify-content-center align-items-center icon-wrapper'>
          <GolferIcon className='golfer-icon' fill='#fff' width={'60px'} height={'60px'}/>
        </div>
        <div className='modal-content'>
          <span className='title'>Continue Editing</span>
          <p>Are you sure you want to stop editing?</p>
          <p>All progress will be lost if you stop now. Continue Editing and Publish your Event.</p>
        </div>
        <div className='w-100 pull-bottom'>
          <SubmitButton
            onClick={closeModal}
            buttonMessage='Continue Editing'
            buttonColor='orange-button'
          />
          <SubmitButton
            onClick={onSubmit}
            buttonMessage='Exit'
            buttonColor='navy-button'
          />
        </div>
      </div>
    </div>
  </Modal>
)

ExitModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default ExitModal
