import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

import '../../assets/styles/components/common/ActivitySocialMenu.scss';

import playIcon from '../../assets/images/common/icons/activityMenu/playIcon.png';
import socialIcon from '../../assets/images/common/icons/activityMenu/socialIcon.png';
import trainIcon from '../../assets/images/common/icons/activityMenu/trainIcon.png';
import announceIcon from '../../assets/images/common/icons/activityMenu/announceIcon.png';

const playStyle = {
  student: {
    bottom: '80px',
    left: 'calc(50% - 120px)'
  },
  coach: {
    bottom: '20px',
    left: 'calc(50% - 120px)'
  }
}

const socialStyle = {
  student: {
    bottom: '200px',
    left: 'calc(50% - 40px)',
  },
  coach: {
    bottom: '140px',
    left: 'calc(50% - 80px)',
  }
}

const trainStyle = {
  student: {
    bottom: '80px',
    left: 'calc(50% + 40px)'
  },
  coach: {
    bottom: '20px',
    left: 'calc(50% + 40px)'
  }
}

const announceStyle = {
  student: {},
  coach: {
    bottom: '140px',
    left: 'calc(50% + 10px)'
  }
}

class ActivitySocialMenu extends React.Component {
  constructor(props, context){
    super(props, context);
    this.state = {};
  }

  renderAnnounceButton(isCoach) {
    if (isCoach) {
      return (
        <div id="announcementActivityContainer" onClick={this.props.selectItem} style={isCoach ? announceStyle.coach : announceStyle.student}>
          <Link to={'/announce'}>
            <div className="activityIcon">
              <img className="iconImage" src={announceIcon} role="presentation" />
            </div>
            <div className="activityText">Announce</div>
          </Link>
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    let isCoach = false;
    if (this.props.current_user && this.props.current_user.type) {
      isCoach = this.props.current_user.type === 'Coach';
    }
    return(
      <div className="activitySocialMenu" id="activitySocialMenu">
        <div className="darkTransparentOverlay"></div>
        <div id="playActivityContainer" onClick={this.props.selectItem} style={isCoach ? playStyle.coach : playStyle.student}>
          <Link to={'/play'}>
            <div className="activityIcon">
              <img className="iconImage" src={playIcon} role="presentation" />
            </div>
            <div className="activityText ">Play</div>
          </Link>
        </div>
        {this.renderAnnounceButton(isCoach)}
        <div id="socialActivityContainer" onClick={this.props.selectItem} style={isCoach ? socialStyle.coach : socialStyle.student}>
          <Link to={'/social'}>
            <div className="activityIcon">
              <img className="iconImage" src={socialIcon} role="presentation" />
            </div>
            <div className="activityText ">Social</div>
          </Link>
        </div>
        <div id="trainActivityContainer" onClick={this.props.selectItem} style={isCoach ? trainStyle.coach : trainStyle.student}>
          <Link to={'/train'}>
            <div className="activityIcon">
              <img className="iconImage" src={trainIcon} role="presentation" />
            </div>
            <div className="activityText ">Train</div>
          </Link>
        </div>
      </div>
    );
  }
};

function mapStateToProps(state, ownProps){
  return {
    current_user: state.user.current_user
  };
}


export default connect(mapStateToProps, null)(ActivitySocialMenu);
