import React from "react";
import ActivityLoader from "react-loaders";
import PropTypes from 'prop-types'

const styles = {
  container: {
    margin: "0 auto",
    padding: '10% 5% 0',
    height: '100%',
    width: '100%',
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  },
  image: {
    maxWidth: "250px"
  }
};

const Loader = ({ message, className }) => (
  <div style={styles.container} className={className}>
    <div className="row d-flex justify-content-center align-items-center p-2 animated fadeIn">
      <ActivityLoader type="ball-pulse" color={"lightgrey"} />
    </div>
    <h6
      className="text-center center-block mx-auto mt-2"
      style={{
        color: "#a3a3a3",
        textTransform: "uppercase",
        letterSpacing: "5px"
      }}
    >
      {message}
    </h6>
  </div>
)

Loader.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string,
}

export default Loader;
