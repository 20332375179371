import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import Comment from '../../../activity/Comment'
import Claps from '../../../common/Claps'
import Placeholder from '../../../common/Placeholder'
import Loader from '../../../common/Op36Loader-web'

import * as activityActions from '../../../../actions/activityActions'

import './EventInfo.scss'

const EventInfo = ({ isModal }) => {
  const dispatch = useDispatch()

  const event = useSelector(state => state.nineHoleEvent)
  const currentUser = useSelector(state => state.user.current_user)
  const comments = useSelector(state => state.comments)
  const likes = useSelector(state => state.likes)

  const [commentLoader, setCommentLoader] = useState(false)
  const [activityEvent, setActivityEvent] = useState(null)

  useEffect(() => {
    setActivityEvent(event.activityEvent)
    setCommentLoader(true)
  }, [dispatch, event])

  useEffect(() => {
    if (!activityEvent) {
      return
    }
    dispatch(activityActions.getActivityComments(activityEvent.id))
      .finally(() => {
        setCommentLoader(false)
      })

    dispatch(activityActions.getActivityLikes(activityEvent.id))
  }, [dispatch, activityEvent])

  return (
    <div className='event-detail-container'>
      <div className='event-image-description'>
        <div className='event-detail-image-wrapper'>
          {event.uploadPhotoSrc?.large_file_url || event.uploadPhotoSrc ? (
            <img src={event.uploadPhotoSrc?.large_file_url || event.uploadPhotoSrc} alt={event.name}/>
          ) : (
            <i className='fa fa-calendar' />
          )}
        </div>
        <div className='event-data'>
          <p className='event-name'>{event.name}</p>
          <p className='event-description'>{event.description}</p>
        </div>
      </div>

      {!isModal && activityEvent &&
        <div className='event-activity'>
          <div className='event-social-wrapper'>
            <Claps
              currentUser={currentUser}
              activity={activityEvent}
              likes={likes}
            />
            <div>
              <i className='fa fa-comment' aria-hidden='true' />
              <p>{activityEvent ? activityEvent.comment_count : 0}</p>
            </div>
          </div>
          <div>
            <h5>Comments</h5>
            <div className='p-2 event-detail-comment-container'>
              {commentLoader ?
                <Loader message='loading comments' /> :
                comments?.length === 0 ?
                  <Placeholder
                    title={'No comments to show'}
                    icon={'fa-comment'}
                    subtext1='No members have posted any comments about this event yet'
                  /> :
                  comments.map((comment) => (
                    <Comment
                      key={comment.id}
                      user={comment.user}
                      body={comment.text}
                      time={comment.created_at}
                    />
                  ))
              }
            </div>
          </div>
        </div>
      }
    </div>
  )
}

EventInfo.propTypes = {
  isModal: PropTypes.bool,
}

export default EventInfo
