import { useState } from 'react'
import styles from './CoachDashboardStats.module.scss'
import { ReactComponent as ArrowIcon } from '../../../assets/images/common/icons/Rectangle.svg'
import classNames from 'classnames'
import StatsItem from './StatsItem'
import { ReactComponent as Level1Icon } from '../../../assets/images/common/icons/divisionLevels/L1.svg'
import { ReactComponent as Level2Icon } from '../../../assets/images/common/icons/divisionLevels/L2.svg'
import { ReactComponent as Level3Icon } from '../../../assets/images/common/icons/divisionLevels/L3.svg'
import { ReactComponent as Level4Icon } from '../../../assets/images/common/icons/divisionLevels/L4.svg'
import { ReactComponent as Level5Icon } from '../../../assets/images/common/icons/divisionLevels/L5.svg'
import { ReactComponent as Level6Icon } from '../../../assets/images/common/icons/divisionLevels/L6.svg'
import { ReactComponent as Level7Icon } from '../../../assets/images/common/icons/divisionLevels/L7.svg'
import { ReactComponent as Level8Icon } from '../../../assets/images/common/icons/divisionLevels/L8.svg'
import { ReactComponent as Level9Icon } from '../../../assets/images/common/icons/divisionLevels/L9.svg'
import { ReactComponent as Level10Icon } from '../../../assets/images/common/icons/divisionLevels/L10.svg'
import { useDispatch, useSelector } from 'react-redux'
import { getPipelineStats } from '../../../actions/communitiesActions'

const CoachDashboardStats = () => {
  const [collapsed, setCollapsed] = useState(false)
  const [playType, setPlayType] = useState('formal')
  const dispatch = useDispatch()
  const stats = useSelector(state => state.communities.pipeline_stats)
  const communityId = useSelector(state => state.user.current_user.communities[0].id)

  const filterPlayTypeChanged = (playType) => {
    setPlayType(playType)
    dispatch(getPipelineStats(communityId, playType))
  }

  return (
    <div
      className={classNames(styles.coachDashboardStats, { [styles.statsCollapsed]: collapsed })}
      onClick={() => collapsed && setCollapsed(false)}
      role='button'
      tabIndex={0}
      onKeyDown={(e) => { e.key === 'Enter' && collapsed && setCollapsed(false) }}
    >
      <div className={classNames(styles.statsHeader, { [styles.statsHeaderCollapsed]: collapsed })}>
        {collapsed ?
          <button className={styles.iconWrapper} onClick={() => setCollapsed(false)}>
            <ArrowIcon/>
          </button>
          :
          <>
            <div className='d-flex' >
              <p className={`${styles.statsTitle} col-10`}>Level Breakdown</p>
              <div className={styles.arrowWrapper}>
                <button className={styles.arrowButton} onClick={() => setCollapsed(true)}/>
              </div>
            </div>
            <div className={`d-flex justify-content-center align-items-center ${styles.filterButtons}`}>
              <button
                className={`${styles.filterButton} ${playType === 'all' ? `${styles.filterSelected} animated fadeIn` : styles.filterUnselected}`}
                onClick={() => filterPlayTypeChanged('all')}
              >
                All
              </button>
              <button
                className={`${styles.filterButton} ${playType === 'formal' ? `${styles.filterSelected} animated fadeIn` : styles.filterUnselected}`}
                onClick={() => filterPlayTypeChanged('formal')}
              >
                Formal
              </button>
            </div>
          </>
        }
      </div>
      {!stats &&
        <div className='d-flex justify-content-center mb-5'>
          <i className='fa fa-spinner fa-spin' aria-hidden='true' />
        </div>
      }
      {!collapsed && stats &&
        <div className={styles.statsItemsContainer}>
          <StatsItem
            icon={<Level1Icon className={styles.levelIcon}/>}
            yards='225'
            golfers={stats.golfers['division_1']}
            division={1}
          />
          <StatsItem
            icon={<Level2Icon className={styles.levelIcon}/>}
            yards='450'
            golfers={stats.golfers['division_2']}
            division={2}
          />
          <StatsItem
            icon={<Level3Icon className={styles.levelIcon}/>}
            yards='900'
            golfers={stats.golfers['division_3']}
            division={3}
          />
          <StatsItem
            icon={<Level4Icon className={styles.levelIcon}/>}
            yards='1350'
            golfers={stats.golfers['division_4']}
            division={4}
          />
          <StatsItem
            icon={<Level5Icon className={styles.levelIcon}/>}
            yards='1800'
            golfers={stats.golfers['division_5']}
            division={5}
          />
          <StatsItem
            icon={<Level6Icon className={styles.levelIcon}/>}
            yards='1801 - 2300'
            golfers={stats.golfers['division_6']}
            division={6}
          />
          <StatsItem
            icon={<Level7Icon className={styles.levelIcon}/>}
            yards='2301 - 2600'
            golfers={stats.golfers['division_7']}
            division={7}
          />
          <StatsItem
            icon={<Level8Icon className={styles.levelIcon}/>}
            yards='2601 - 2900'
            golfers={stats.golfers['division_8']}
            division={8}
          />
          <StatsItem
            icon={<Level9Icon className={styles.levelIcon}/>}
            yards='2901 - 3200'
            golfers={stats.golfers['division_9']}
            division={9}
          />
          <StatsItem
            icon={<Level10Icon className={styles.levelIcon}/>}
            yards='3200+'
            golfers={stats.golfers['division_10']}
            division={10}
          />
        </div>
      }
    </div>
  )
}

export default CoachDashboardStats
