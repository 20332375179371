import React from 'react'
import PropTypes from 'prop-types'

import LessonPlanCardPopover from './LessonPlanCardPopover'
import { withRouting } from '../../common/hooks'

import defaultIcon from '../../../assets/images/common/icons/lesson_plans/lesson-plan-icon.png'
import calendarIcon from '../../../assets/images/common/icons/lesson_plans/lesson-calendar.svg'

import './LessonPlanCard.scss'

class LessonPlanCard extends React.Component {
  state = {
    hovering: false,
    popoverOpen: false,
  }
  render() {
    const {
      plan,
      isPinnable,
      handlePinClick,
      triggerDeleteModal,
      current_user_id,
      is_admin,
    } = this.props
    const { hovering, popoverOpen } = this.state
    return (
      <div
        id='LessonPlanCard'
        onMouseEnter={this._startHover}
        onMouseOver={this.state.hovering ? (() => {}) : this._startHover}
        onFocus={this.state.hovering ? (() => {}) : this._startHover}
        onMouseLeave={this._endHover}
        aria-hidden='true'
        onClick={this._handleCardClick}
      >
        {plan.is_draft && <p className='lp-draft-indicator'>-draft-</p>}
        {plan.in_use && <p className='lp-draft-indicator in-use'>-in use-</p>}
        {hovering && (
          <div className='grey-out'>
            {isPinnable && (
              <i
                className='fa fa-thumb-tack'
                aria-hidden='true'
                onClick={e => {
                  handlePinClick(e, plan.id)
                }}
              />
            )}
            <i
              className={`fa fa-ellipsis-v ${
                popoverOpen ? 'icon-clicked' : ''
              }`}
              aria-hidden='true'
              onClick={e => this._triggerPopover(e)}
            />
          </div>
        )}
        {popoverOpen && (
          <LessonPlanCardPopover
            close={this._closePopover}
            is_draft={plan.is_draft}
            edit={this._editLessonPlan}
            duplicate={this._duplicateLessonPlan}
            deleteLP={triggerDeleteModal}
            id={plan.id}
            isMine={plan.user_id === current_user_id || is_admin}
          />
        )}
        <div
          className='card-header-photo'
          style={
            plan.photo
              ? { backgroundImage: `url(${plan.photo.file_url})` }
              : plan.template_photo_url
                ? { backgroundImage: `url(${plan.template_photo_url})` }
                : { backgroundColor: '#ff6633' }
          }
        >
          {!plan.photo && !plan.template_photo_url && (
            <div
              className='default-icon'
              style={{ backgroundImage: `url(${defaultIcon})` }}
            />
          )}
        </div>
        <div className='lp-card-title-bar'>
          <p className='lp-card-title'>{plan.name}</p>
        </div>
        <div className='lp-card-notes-section'>
          <p className='lp-card-notes'>{plan.notes || '(no notes)'}</p>
        </div>
        <div className='lp-card-footer'>
          <div className='lp-card-session-box'>
            <div
              className='calendar-icon'
              style={{ backgroundImage: `url(${calendarIcon})` }}
            />
            {this._grabSessionCount()}
          </div>
          <div className='lp-card-tag-box'>
            <i className='fa fa-tag' aria-hidden='true' />
            <p className='lp-card-tag-count'>{`${plan.tags.length} tag${
              plan.tags.length !== 1 ? 's' : ''
            }`}</p>
          </div>
        </div>
      </div>
    )
  }

  _handleCardClick = () => {
    if (this.props.plan.is_draft) {
      this._editLessonPlan()
    } else {
      this.props.navigate(`/lesson-plan/view/${this.props.plan.id}`)
    }
  }

  _editLessonPlan = () => {
    this.props.navigate(`/lesson-plan/builder/${this.props.plan.id}/edit`)
  }

  _duplicateLessonPlan = () => {
    this.props.navigate(`/lesson-plan/builder/${this.props.plan.id}/new`)
  }

  _triggerPopover = e => {
    e.stopPropagation()
    this.setState({ popoverOpen: true })
  }

  _closePopover = () => {
    this.setState({ popoverOpen: false })
  }

  _startHover = () => {
    this.setState({ hovering: true })
  }

  _endHover = () => {
    this.setState({ hovering: false, popoverOpen: false })
  }

  _grabSessionCount = () => {
    const sessions = JSON.parse(this.props.plan.draft_sessions)
    if (sessions) {
      return (
        <p className='session-count-text'>
          <span>{sessions.length}</span>{' '}
          {`session${sessions.length !== 1 ? 's' : ''}`}
        </p>
      )
    } else {
      return <p className='session-count-text'>No sessions</p>
    }
  }
}

LessonPlanCard.propTypes = {
  plan: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    is_draft: PropTypes.bool,
    template_photo_url: PropTypes.string,
    draft_sessions: PropTypes.string,
    notes: PropTypes.string,
    tags: PropTypes.array,
    in_use: PropTypes.bool,
    user_id: PropTypes.number,
    photo: PropTypes.shape({
      file_url: PropTypes.string,
    }),
  }),
  isPinnable: PropTypes.bool,
  is_admin: PropTypes.bool,
  current_user_id: PropTypes.number,
  handlePinClick: PropTypes.func,
  triggerDeleteModal: PropTypes.func,
  navigate: PropTypes.func,
}

export default withRouting(LessonPlanCard)
