import PropTypes from 'prop-types'
import StudentWithAvatar from '../StudentWithAvatar'
import EnrollmentSource from '../EnrollmentSource'
import { ReactComponent as XIcon } from '../../../assets/images/common/icons/X-icon.svg'
import colors from '../../../assets/styles/globals.scss'
import './StudentCard.scss'

const StudentCard = ({ student, onXClick }) => (
  <div className='student-card-wrapper my-2'>
    <div className='p-2 d-flex align-items-center justify-content-between student-data'>
      <div className='col-4'>
        <StudentWithAvatar student={student} clickable/>
      </div>
      <div className='col-2 px-0'>
        <p>Age: {student.age} years</p>
      </div>
      <div className='col-4 px-0 d-flex flex-column added-from-wrapper'>
        <EnrollmentSource student={student}/>
      </div>
      <div className='col-1 x-button-wrapper'>
        <XIcon onClick={() => onXClick(true, student.user_id)} className='mr-3 pull-right' fill={colors.lightNavy} />
      </div>
    </div>
  </div>
)

StudentCard.propTypes = {
  student: PropTypes.object.isRequired,
  onXClick: PropTypes.func.isRequired,
}

export default StudentCard
