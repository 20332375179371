import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DatePicker from "../../common/DatePicker";
import moment from "moment";
import ReactTable from "react-table";
import createTable from "react-table-hoc-fixed-columns";
// import "react-table/react-table.css";

import * as representativeActionTypesActions from "../../../actions/representativeActionTypesActions";
import * as representativesActions from "../../../actions/representativesActions";
// import flaggedAccounts from "../../../reducers/flaggedAccountsReducer";

import CSVExportModal from "../CSVExportModal";
import "./AdminSupportActivityTable.scss";

const ReactTableFixedColumns = createTable(ReactTable);

const HEADERS = [
  { label: "First Name", key: "firstname" },
  { label: "Last Name", key: "lastname" },
  { label: "Community", key: "community" },
  { label: "Note Type", key: "notetype" },
  { label: "Action Type", key: "actiontype" },
  { label: "Made Contact", key: "madecontact" },
  { label: "Date Created", key: "datecreated" },
  { label: "notes", key: "notes" }
];

class AdminSupportActivityTable extends Component {
  state = {
    showModal: false,
    dataLoader: false,
    data: [],
    representativeLoader: true,
    actionTypesLoader: true,
    repFilterOpen: false,
    noteTypeFilterOpen: false,
    actionTypeFilterOpen: false,
    madeContactFilterOpen: false,
    repFilters: [],
    noteFilters: [],
    actionFilters: [],
    contactFilters: []
  };

  componentDidMount() {
    if (this.props.representatives.length === 0) {
      this.props.representatives_actions.getRepresentatives().then(() => {
        this.setState({ representativeLoader: false });
      });
    } else {
      this.setState({ representativeLoader: false });
    }

    if (this.props.representative_action_types.length === 0) {
      this.props.representative_action_type_actions
        .getRepresentativeActionTypes()
        .then(() => {
          this.setState({ actionTypesLoader: false });
        });
    } else {
      this.setState({ actionTypesLoader: false });
    }
  }

  render() {
    const { showModal, data } = this.state;
    return (
      <div id='AdminSupportActivityTable'>
        {this._renderTable()}
        <CSVExportModal
          headers={HEADERS}
          data={data}
          showModal={showModal}
          toggleModal={this._toggleModal}
          reportType={"Community Training Report"}
          filename={"community-training-report.csv"}
        />
      </div>
    );
  }

  _toggleModal = data => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  _createData = () => {
    const { data } = this.props;
    const filteredData = this._filterData(data);
    let tempData = [];
    this.setState({ dataLoader: true });
    if (filteredData.length > 0) {
      filteredData.map(d => {
        let firstname = d.note.representative.first_name;
        let lastname = d.note.representative.last_name;
        let communityName = d.community.name;
        let notetype = d.note.note_type;
        let actiontype = d.note.representative_action_type.name;
        let madecontact = d.note.contact_made ? "Yes" : "No";
        let datecreated = d.note.created_at;
        let notes = d.note.note;

        tempData.push({
          firstname,
          lastname,
          community: communityName,
          notetype,
          actiontype,
          madecontact,
          datecreated: moment(datecreated).format("MM-DD-YYYY"),
          notes
        });
      });
    }

    this.setState({
      showModal: !this.state.showModal,
      data: tempData
    })
  };

  _renderTable = () => {
    const {
      title,
      startDate,
      endDate,
      handleStartDateChanged,
      handleEndDateChanged,
      data,
      loader
    } = this.props;

    const { filterLoader } = this.state;

    const filteredData = this._filterData(data);

    const columns = [
      {
        Header: "First Name",
        accessor: "note.representative.first_name",
        Cell: props => this._renderTableCell(props)
      },
      {
        Header: "Last Name",
        accessor: "note.representative.last_name",
        Cell: props => this._renderTableCell(props)
      },
      {
        Header: "Community",
        accessor: "community.name",
        Cell: props => this._renderTableCell(props)
      },
      {
        Header: "Note Type",
        accessor: "note.note_type",
        Cell: props => this._renderTableCell(props)
      },
      {
        Header: "Action Type",
        accessor: "note.representative_action_type.name",
        Cell: props => this._renderTableCell(props)
      },
      {
        Header: "Made Contact",
        accessor: "note.contact_made",
        Cell: props => this._renderContactMadeCell(props)
      },
      {
        Header: "Date Created",
        accessor: "note.created_at",
        Cell: props => this._renderActivityDateCell(props)
      },
      {
        Header: "Notes",
        accessor: "note.note",
        Cell: props => this._renderTableCell(props)
      }
    ];

    return (
      <div className='container my-5 animated fadeIn'>
        <div className='row mb-1 d-flex align-items-center'>
          <div className='col-md-4'>
            <h2>{title}</h2>
          </div>
          <div className='col-md-4 d-flex align-items-center'>
            <div>
              <p className='m-0'>Start Date</p>
              <DatePicker
                name={"start-date"}
                id={"start-date"}
                startDate={startDate}
                endDate={endDate}
                type={"date"}
                className={"col-12 form-control"}
                onChange={handleStartDateChanged}
                placeholder={"Start Date"}
                selected={startDate}
                error={""}
              />
            </div>

            <div className='ml-2'>
              <p className='m-0'>End Date</p>
              <DatePicker
                name={"end-date"}
                id={"end-date"}
                startDate={startDate}
                endDate={endDate}
                type={"date"}
                className={"col-12 form-control"}
                onChange={handleEndDateChanged}
                placeholder={"End Date"}
                selected={endDate}
                error={""}
              />
            </div>
          </div>
          <div className='col-md-4'>
            <button
              className='btn btn-sm btn-block'
              onClick={() => this.props.goBack()}
            >
              <i
                className='fa fa-arrow-left text-white mr-1'
                aria-hidden='true'
              />{" "}
              View Another Report
            </button>
          </div>
        </div>
        <div className='row mb-2'>
          <div
            className='col-10 offset-1'
            style={{
              background: "#f4f4f5",
              padding: 15,
              border: "1px solid #111",
              borderRadius: "8px"
            }}
          >
            <div className='d-flex justify-content-between'>
              <h5>Filters ({this._getAllFilterCount()})</h5>
              <p
                className='text-danger clear-all-filters'
                style={{ fontSize: "0.8rem", margin: 0 }}
                onClick={this._clearAllFilters}
              >
                Clear all
              </p>
            </div>
            <div className='p-2 d-flex'>
              <div className='w-25 p-2 report-filter-section'>
                <p
                  className='filter-title'
                  style={{ margin: 0 }}
                  onClick={() => this._toggleFilter("rep")}
                >
                  <i
                    className={`fa fa-${
                      this.state.repFilterOpen ? "minus" : "plus"
                    } mr-1 toggle-icon`}
                    aria-hidden='true'
                  />
                  Rep ({this.state.repFilters.length})
                </p>
                {this.state.repFilterOpen && (
                  <div className='mr-2 filter-content-container'>
                    {this.state.representativeLoader ? (
                      <div className='text-center'>
                        <i
                          className='fa fa-spinner fa-spin'
                          aria-hidden='true'
                        />
                      </div>
                    ) : (
                      this._renderRepresentativeFilters()
                    )}
                  </div>
                )}
              </div>
              <div className='w-25 p-2 report-filter-section'>
                <p
                  className='filter-title'
                  style={{ margin: 0 }}
                  onClick={() => this._toggleFilter("noteType")}
                >
                  <i
                    className={`fa fa-${
                      this.state.noteTypeFilterOpen ? "minus" : "plus"
                    } mr-1 toggle-icon`}
                    aria-hidden='true'
                  />
                  Note Type ({this.state.noteFilters.length})
                </p>
                {this.state.noteTypeFilterOpen && (
                  <div className='mr-2 filter-content-container'>
                    <div className='filter-box-container'>
                      <div
                        className='filter-box'
                        onClick={() =>
                          this._handleFilterClick("noteFilters", "Check In")
                        }
                      >
                        {this.state.noteFilters.includes("Check In") && (
                          <i className='fa fa-check' aria-hidden='true' />
                        )}
                      </div>
                      <p className='filter-label'>Check In</p>
                    </div>
                    <div className='filter-box-container'>
                      <div
                        className='filter-box'
                        onClick={() =>
                          this._handleFilterClick("noteFilters", "Recap")
                        }
                      >
                        {this.state.noteFilters.includes("Recap") && (
                          <i className='fa fa-check' aria-hidden='true' />
                        )}
                      </div>
                      <p className='filter-label'>Recap</p>
                    </div>
                    <div className='filter-box-container'>
                      <div
                        className='filter-box'
                        onClick={() =>
                          this._handleFilterClick("noteFilters", "Support")
                        }
                      >
                        {this.state.noteFilters.includes("Support") && (
                          <i className='fa fa-check' aria-hidden='true' />
                        )}
                      </div>
                      <p className='filter-label'>Support</p>
                    </div>
                  </div>
                )}
              </div>
              <div className='w-25 p-2 report-filter-section'>
                <p
                  className='filter-title'
                  style={{ margin: 0 }}
                  onClick={() => this._toggleFilter("actionType")}
                >
                  <i
                    className={`fa fa-${
                      this.state.actionTypeFilterOpen ? "minus" : "plus"
                    } mr-1 toggle-icon`}
                    aria-hidden='true'
                  />
                  Action Type ({this.state.actionFilters.length})
                </p>
                {this.state.actionTypeFilterOpen && (
                  <div className='mr-2 filter-content-container'>
                    {this.state.actionTypesLoader ? (
                      <div className='text-center'>
                        <i
                          className='fa fa-spinner fa-spin'
                          aria-hidden='true'
                        />
                      </div>
                    ) : (
                      this._renderActionTypesFilters()
                    )}
                  </div>
                )}
              </div>
              <div className='w-25 p-2 report-filter-section'>
                <p
                  className='filter-title'
                  style={{ margin: 0 }}
                  onClick={() => this._toggleFilter("madeContact")}
                >
                  <i
                    className={`fa fa-${
                      this.state.madeContactFilterOpen ? "minus" : "plus"
                    } mr-1 toggle-icon`}
                    aria-hidden='true'
                  />
                  Made Contact ({this.state.contactFilters.length})
                </p>
                {this.state.madeContactFilterOpen && (
                  <div className='mr-2 filter-content-container'>
                    <div className='filter-box-container'>
                      <div
                        className='filter-box'
                        onClick={() =>
                          this._handleFilterClick("contactFilters", true)
                        }
                      >
                        {this.state.contactFilters.includes(true) && (
                          <i className='fa fa-check' aria-hidden='true' />
                        )}
                      </div>
                      <p className='filter-label'>Yes</p>
                    </div>
                    <div className='filter-box-container'>
                      <div
                        className='filter-box'
                        onClick={() =>
                          this._handleFilterClick("contactFilters", false)
                        }
                      >
                        {this.state.contactFilters.includes(false) && (
                          <i className='fa fa-check' aria-hidden='true' />
                        )}
                      </div>
                      <p className='filter-label'>No</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <ReactTableFixedColumns
              data={!loader && !filterLoader ? filteredData : []}
              columns={columns}
              defaultPageSize={10}
              loading={loader}
              loadingText='Loading Report'
              noDataText={
                filteredData
                  ? "Choose a start date and end date with support activities"
                  : "No data found"
              }
            />
          </div>
        </div>
        <button onClick={this._createData} className='mt-3 btn btn-success'>
          Export as CSV
        </button>
      </div>
    );
  };

  _filterData = data => {
    let first_rep_filter;
    let second_note_filter;
    let third_action_filter;
    let fourth_contact_filter;

    if (this.state.repFilters.length > 0) {
      first_rep_filter = data.filter(d =>
        this.state.repFilters.includes(d.note.representative.id)
      );
    } else {
      first_rep_filter = data;
    }

    if (this.state.noteFilters.length > 0) {
      second_note_filter = first_rep_filter.filter(d =>
        this.state.noteFilters.includes(d.note.note_type)
      );
    } else {
      second_note_filter = first_rep_filter;
    }

    if (this.state.actionFilters.length > 0) {
      third_action_filter = second_note_filter.filter(d =>
        this.state.actionFilters.includes(d.note.representative_action_type.id)
      );
    } else {
      third_action_filter = second_note_filter;
    }

    if (this.state.contactFilters.length > 0) {
      fourth_contact_filter = third_action_filter.filter(d =>
        this.state.contactFilters.includes(d.note.contact_made)
      );
    } else {
      fourth_contact_filter = third_action_filter;
    }
    return fourth_contact_filter;
  };

  _handleFilterClick = (type, value) => {
    let filters = [...this.state[type]];

    let newFilters = filters.filter(f => f !== value);

    if (filters.length === newFilters.length) {
      newFilters.push(value);
    }

    this.setState({ [type]: newFilters });
  };

  _getAllFilterCount = () => {
    let count = 0;
    count += this.state.repFilters.length;
    count += this.state.noteFilters.length;
    count += this.state.contactFilters.length;
    count += this.state.actionFilters.length;
    return count;
  };

  _clearAllFilters = () => {
    this.setState({
      repFilters: [],
      noteFilters: [],
      contactFilters: [],
      actionFilters: []
    });
  };

  _renderRepresentativeFilters = () => {
    return this.props.representatives.map(rep => {
      return (
        <div key={rep.id} className='filter-box-container'>
          <div
            className='filter-box'
            onClick={() => this._handleFilterClick("repFilters", rep.id)}
          >
            {this.state.repFilters.includes(rep.id) && (
              <i className='fa fa-check' aria-hidden='true' />
            )}
          </div>
          <p className='filter-label'>
            {rep.last_name + ", " + rep.first_name}
          </p>
        </div>
      );
    });
  };

  _renderActionTypesFilters = () => {
    return this.props.representative_action_types.map(type => {
      return (
        <div key={type.id} className='filter-box-container'>
          <div
            className='filter-box'
            onClick={() => this._handleFilterClick("actionFilters", type.id)}
          >
            {this.state.actionFilters.includes(type.id) && (
              <i className='fa fa-check' aria-hidden='true' />
            )}
          </div>
          <p className='filter-label'>{type.name}</p>
        </div>
      );
    });
  };

  _toggleFilter = name => {
    switch (name) {
      case "rep":
        this.setState({ repFilterOpen: !this.state.repFilterOpen });
        break;
      case "noteType":
        this.setState({ noteTypeFilterOpen: !this.state.noteTypeFilterOpen });
        break;
      case "actionType":
        this.setState({
          actionTypeFilterOpen: !this.state.actionTypeFilterOpen
        });
        break;
      case "madeContact":
        this.setState({
          madeContactFilterOpen: !this.state.madeContactFilterOpen
        });
        break;
      default:
        return false;
    }
  };

  _renderTableCell = props => {
    return (
      <div
        className='number'
        style={{
          backgroundColor: "",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div style={{ color: "#2b3843" }}>{props.value}</div>
      </div>
    );
  };

  _renderContactMadeCell = props => {
    return (
      <div
        className='number'
        style={{
          backgroundColor: "",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div style={{ color: "#2b3843" }}>{props.value ? "Yes" : "No"}</div>
      </div>
    );
  };

  _renderActivityDateCell = props => {
    return (
      <div
        className='number'
        style={{
          backgroundColor: "",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div style={{ color: "#2b3843" }}>
          {moment(props.value).format("MM-DD-YYYY")}
        </div>
      </div>
    );
  };
}

function mapStateToProps(state, ownProps) {
  return {
    representative_action_types: state.representativeActionTypes,
    representatives: state.representatives
  };
}

function mapDispatchToProps(dispatch) {
  return {
    representative_action_type_actions: bindActionCreators(
      representativeActionTypesActions,
      dispatch
    ),
    representatives_actions: bindActionCreators(
      representativesActions,
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminSupportActivityTable);
