import React from "react";
import PropTypes from 'prop-types'
// REDUX BITS
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createFilter } from 'react-search-input'
import HowToUseAppGuide from '../reports/HowToUseAppGuide/HowToUseAppGuide';
import * as commonActions from "../../actions/commonActions";
import * as familyActions from "../../actions/familyActions";
import * as authActions from "../../actions/authActions";
import * as communitiesActions from "../../actions/communitiesActions";
import * as invitationActions from "../../actions/invitationActions";
import * as fadeAnimation from "../common/fadeAnimation";
import ValidationModal from "../common/ValidationModal";
import ConfirmationModal from "../common/ConfirmationModal";
import { checkLicensePermission } from '../../utility/communityLicense'

// DEPENDENCIES
import _ from "lodash";
import Modal from "react-modal";
import Loader from "../common/Op36Loader";

import { isValidEmail } from '../../utility/helperFunctions'

// CHILDREN
import TextInput from "../common/form/TextInput";
import AlertDialog from "../common//AlertDialog";
import FamilyEditForm from "./family_modals/FamilyEditForm.js";
import Placeholder from "../common/Placeholder";
import FamilyCard from "../common/FamilyCard";
import InvitationCard from "../common/InvitationCard";
import { withRouting } from "../common/hooks";
import SearchInput from '../common/SearchInput'
import './Families.scss'

// const modalStyles = {
//   content: {
//     top: "50%",
//     left: "50%",
//     right: "auto",
//     bottom: "auto",
//     marginRight: "-50%",
//     transform: "translate(-50%, -50%)"
//   }
// };

const KEYS_TO_FILTERS = ["name", "email"];

class FamiliesPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      community_id: "",
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      password_confirmation: "",
      type: "Student",
      family_admin: "true",
      showAddFamilyModal: false,
      alertTitle: "Error",
      alertMessage: "Something went wrong, please try again later.",
      alertType: "danger",
      in: false,
      loading: false,
      errorTitle: "Error",
      errorMessage: "Something went wrong. Please try again later.",
      addFamily: true,
      familyLoader: true,
      addFamilyLoader: false,
      inviteFamilyLoader: false,
      showValidationModal: false,
      validationSuccess: true,
      validationMessage: "",
      searchTerm: "",
      confirmationMessage: "",
      confirmationType: "",
      showConfirmationModal: false,
      invitation: {},
      actionLoader: false,
    };

    this.openInviteFamilyModal = this.openInviteFamilyModal.bind(this);
    this.openAddFamilyModal = this.openAddFamilyModal.bind(this);
    this.closeAddFamilyModal = this.closeAddFamilyModal.bind(this);
    this.inviteFamily = this.inviteFamily.bind(this);
    this.onRegisterSubmit = this.onRegisterSubmit.bind(this);

    this.licenseType = this.props.user?.communities[0]?.license_type
  }

  componentDidMount() {
    const {
      common_actions,
      communities_actions,
      family_actions,
      user,
    } = this.props;
    common_actions.showSideBar(true);
    common_actions.showNavBar(true);
    // communities_actions.getCommunities();
    family_actions.getFamilies(user.communities[0].id).then(() => {
      communities_actions
        .getCommunityInvitations(user.communities[0].id)
        .then((res) => {
          this.setState({
            familyLoader: false,
          });
        });
    });

    if (user.communities) {
      this.setState({ community_id: user.communities[0].id });
    }

    this.applySmartToggle()
  }

  applySmartToggle(){
    const { location } = this.props
    if (!_.isEmpty(location.state) && location.state.smartToggle) {
      // reset the location state to prevent inifinte rerender of this component
      this.props.navigate(location.pathname, {});
      this.props.setShowFamilyModal(true)
    }
  }

  searchUpdated = (e) => {
    this.setState({ searchTerm: e.target.value })
  }

  //
  // MODAL ACTIONS
  //

  openConfirmationModal = (
    confirmationMessage,
    invitation,
    confirmationType
  ) => {
    this.setState({
      showConfirmationModal: true,
      invitation,
      confirmationMessage,
      confirmationType,
    });
  };

  closeConfirmationModal = () => {
    this.setState({
      showConfirmationModal: false,
      invitation: {},
      confirmationMessage: "",
      confirmationType: "",
    });
  };

  openInviteFamilyModal() {
    this.props.setShowFamilyModal(true)
    this.setState({
      first_name: "",
      last_name: "",
      email: "",
    });
  }

  openAddFamilyModal() {
    this.setState({
      showAddFamilyModal: true,
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      password_confirmation: "",
    });
  }

  closeAddFamilyModal() {
    this.setState({
      showAddFamilyModal: false,
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      password_confirmation: "",
    });
  }

  closeValidationModal = () => {
    this.setState({
      showValidationModal: false,
    });
  };

  //
  // FORM FUNCTIONS
  //

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  //
  // MODAL FUNCTIONS
  //

  validateForm(formState) {
    return (
      formState.first_name &&
      formState.last_name &&
      formState.password &&
      formState.password_confirmation &&
      formState.email
    );
  }

  deleteInvitation = () => {
    const { invitation } = this.state;
    const { invitation_actions } = this.props;
    this.setState({ actionLoader: true });
    invitation_actions.deleteInvitation(invitation).then(() => {
      this.closeConfirmationModal();
      this.setState({
        showValidationModal: true,
        validationMessage: "You have successfully deleted the invitation",
        validationSuccess: true,
        actionLoader: false,
      });
    });
  };

  sendReminder = () => {
    const { user, family_actions } = this.props;
    const invitation = this.state.invitation;

    let url = window.location.origin;

    this.setState(
      {
        actionLoader: true,
      },
      () => {
        family_actions
          .sendFamilyReminder(invitation, user.communities[0].id, user.id, url)
          .then(() => {
            this.closeConfirmationModal();
            this.setState({
              showValidationModal: true,
              validationMessage: `You have successfully reminded the ${
                invitation.first_name || ""
              } ${invitation.last_name || ""} family`,
              validationSuccess: true,
              actionLoader: false,
            });
          });
      }
    );
  };

  inviteFamily() {
    const { email, first_name, last_name } = this.state;
    const { user, communities_actions, family_actions } = this.props;
    let url = window.location.origin;
    if (
      email !== "" &&
      first_name !== "" &&
      last_name !== "" &&
      isValidEmail(this.state.email)
    ) {
      this.setState({
        inviteFamilyLoader: true,
      });
      family_actions
        .inviteFamily(
          { email, first_name, last_name },
          user.communities[0].id,
          user.id,
          url
        )
        .then(() => {
          this.props.setShowFamilyModal(false)
          this.setState({
            showValidationModal: true,
            validationSuccess: true,
            validationMessage:
              "An email invite has been sent to the family!",
            inviteFamilyLoader: false,
          });
          this.alertDialog?.show()
        });
    } else {
      this.setState({
        showValidationModal: true,
        validationMessage: "Please enter a valid email to send",
        validationSuccess: false,
      });
    }
  }

  onRegisterSubmit(formState) {
    const newFamilyMember = { ...formState };
    this.setState({ loading: true, addFamilyLoader: true });
    // WIP here
    if (this.validateForm(formState)) {
      if (formState.password === formState.password_confirmation) {
        newFamilyMember.community_id = this.props.user.communities[0].id;
        this.props.auth_actions.createFamilyUser(newFamilyMember, () => {
          this.setState({ loading: false, addFamilyLoader: false });
          this.setState({
            showValidationModal: true,
            validationSuccess: true,
            validationMessage: "Family has been added to the community!",
          });
          this.props.family_actions.getFamilies(
            this.props.user.communities[0].id
          );
          this.closeAddFamilyModal();
        });
        //   .then(response => {
        // this.setState({ loading: false, addFamilyLoader: false });
        // this.setState({
        //   showValidationModal: true,
        //   validationSuccess: true,
        //   validationMessage: "Family has been added to the community!"
        // });
        // this.props.family_actions.getFamilies(
        //   this.props.user.communities[0].id
        // );
        // this.closeAddFamilyModal();
        //   })
        // // .catch(_err => {
        //   this.setState({ loading: false, addFamilyLoader: false });
        // });
      } else {
        this.setState({
          showValidationModal: true,
          validationSuccess: false,
          validationMessage: "Passwords do not match",
        });
        this.setState({ loading: false, addFamilyLoader: false });
      }
    } else {
      this.setState({
        showValidationModal: true,
        validationSuccess: false,
        validationMessage:
          "Please fill out all of the fields before registering",
      });
      this.setState({ loading: false, addFamilyLoader: false });
    }
  }

  getWindowURl() {
    //window.location.origin
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      return "http://localhost:3001"; // LOCAL
    } else {
      //baseURL = 'https://operation36-dev.herokuapp.com'; // DEV
      return "https://operation36golf.com"; // PROD
    }
  }

  validateInviteForm = () => {
    const { email, first_name, last_name } = this.state;

    if (email && first_name && last_name && isValidEmail(email)) {
      return false;
    }
    return true;
  };

  displayInviteFamilyModal() {
    const { inviteFamilyLoader } = this.state;
    return (
      <Modal
        isOpen={this.props.showFamilyModal}
        onRequestClose={() => this.props.setShowFamilyModal(false)}
        contentLabel='Example Modal'
        // style={modalStyles}
      >
        <div className='modal-header row'>
          <div className='col-11'>
            <h5 className='modal-title display-4'>
              Invite Families to your Community:
            </h5>
          </div>
          <div className='col-1'>
            <button
              type='button'
              className='close pull-right font-200'
              data-dismiss='modal'
              aria-label='Close'
              onClick={() => this.props.setShowFamilyModal(false)}
            >
              <span aria-hidden='true'>✕</span>
            </button>
          </div>
        </div>
        <div className='modal-content'>
          <div className='row my-3'>
            <div className='col'>
              <h2 className='text-center'>
                Send an email to a family to join your community:
              </h2>
            </div>
          </div>
          <div className='row my-4'>
            <div className='col-3' />
            <div className='col-6'>
              <div className='row'>
                <div className='col-6 mx-auto'>
                  <label className='invite-form-label'>First Name</label>
                  <TextInput
                    onChange={this.handleInputChange}
                    name={"first_name"}
                    id={""}
                    className={"form-control mx-auto"}
                    type={"text"}
                    placeholder={"Insert First Name"}
                    error={""}
                  />
                </div>

                <div className='col-6 mx-auto'>
                  <label className='invite-form-label'>Last Name</label>
                  <TextInput
                    onChange={this.handleInputChange}
                    name={"last_name"}
                    id={""}
                    className={"form-control mx-auto"}
                    type={"text"}
                    placeholder={"Insert Last Name"}
                    error={""}
                  />
                </div>
              </div>
              <div className='row mt-2'>
                <div className='col-12 mx-auto'>
                  <label className='invite-form-label'>Email</label>
                  <TextInput
                    onChange={this.handleInputChange}
                    name={"email"}
                    id={""}
                    className={"form-control mx-auto"}
                    type={"text"}
                    placeholder={"family@gmail.com"}
                    error={""}
                  />
                </div>
              </div>
              <div className='w-100 d-flex justify-content-center align-items-center mt-5'>
                <button
                  className='btn btn-primary'
                  onClick={this.inviteFamily}
                  disabled={inviteFamilyLoader || this.validateInviteForm()}
                >
                  <i className='fa fa-envelope' aria-hidden='true' />{" "}
                  {inviteFamilyLoader ? "Sending Email..." : "Send Email"}
                </button>
              </div>
            </div>
            <div className='col-3' />
          </div>
          {/*
          <div className="row my-5">
            <div className="col">
              <h2 className="text-center">Please copy this URL and share it to invite family</h2>
          </div>   */}

          <div className='row my-5'>
            <div className='col'>
              <h2 className='text-center'>Or share this guide with your families:</h2>
              <HowToUseAppGuide commId={this.props.user.communities[0].id} />
            </div>
          </div>

          {/*<div className='row my-5'>
            <div className='col'>
              <h2 className='text-center'>Or copy this URL and share it</h2>
              <h6 className='text-center font-200'>
                <Link
                  to={"/register/" + this.props.user.communities[0].id}
                  target='_blank'
                >
                  {this.getWindowURl() +
                    "/register/" +
                    this.props.user.communities[0].id}
                </Link>
              </h6>
            </div>
          </div>*/}

          <AlertDialog
            hideOnOverlayClicked={true}
            ref='alertDialog'
            showOverlay={true}
            title={this.state.alertTitle}
            type={this.state.alertType}
          >
            {this.state.alertMessage}
          </AlertDialog>
        </div>
      </Modal>
    );
  }

  displayAddFamilyModal() {
    return (
      <Modal
        isOpen={this.state.showAddFamilyModal}
        onRequestClose={this.closeAddFamilyModal}
        contentLabel='Add Family Modal'
      >
        <div className='modal-header row'>
          <div className='col-11'>
            <h5 className='modal-title display-4'>
              Add Family To Your Community
            </h5>
          </div>
          <div className='col-1'>
            <button
              type='button'
              className='close pull-right font-200'
              data-dismiss='modal'
              aria-label='Close'
              onClick={this.closeAddFamilyModal}
            >
              <span aria-hidden='true'>✕</span>
            </button>
          </div>
        </div>
        <div className='modal-content'>
          <div className='row my-3'>
            <div className='col'>
              <h2 className='text-center'>
                Add a family and send their login credentials
              </h2>
            </div>
          </div>
          <FamilyEditForm
            update={false}
            showAlert={this.showAlert}
            loader={this.state.addFamilyLoader}
            addFamily={this.onRegisterSubmit}
            handleInputChange={this.handleInputChange}
          />
        </div>
      </Modal>
    );
  }

  toggleEnterState = () => {
    setTimeout(
      function () {
        this.setState({ in: true });
      }.bind(this),
      fadeAnimation.FADE_DURATION
    );
  };

  renderFamilies = () => {
    const { families } = this.props;
    const filteredFamilies = families.filter(
      createFilter(this.state.searchTerm, KEYS_TO_FILTERS)
    );

    return filteredFamilies.map((family) => {
      if (family.users.length > 0) {
        return <FamilyCard family={family} key={family.id} />;
      }
      return null;
    });
  };

  renderInvitations = () => {
    const { invitations } = this.props;
    if (invitations && invitations.length > 0) {
      return invitations.map((invitation) => {
        return (
          <InvitationCard
            invitation={invitation}
            toggleConfirmation={this.openConfirmationModal}
            key={invitation.id}
          />
        );
      });
    }
    return (
      <Placeholder
        title={"No Invitations Listed"}
        icon={"fa-envelope"}
        subtext1={"You currently have no pending invitations."}
        subtext2={"Click the invite family button below"}
        subtext3={"to send an invite"}
        buttonText={"Invite Family"}
        buttonIcon={"fa-envelope"}
        handleClick={this.openInviteFamilyModal}
      />
    );
  };

  renderFamilyView = () => {
    if (this.props.families.length > 0) {
      return (
        <div className='animated fadeIn'>
          <div className='families-search-bar mt-2'>
            <SearchInput placeholder='Search Families' onChange={this.searchUpdated}/>
          </div>
          <div className='row'>
            <div className='col'>
              {/* <h4 className="families-title">Active Families</h4> */}
              <div className='row'>{this.renderFamilies()}</div>
            </div>
            {checkLicensePermission(this.licenseType, ['standard_legacy']) &&
              <div className='col-md-3 border-left'>
                <h4 className='families-title'>Invitations</h4>
                <div className='invitations-container'>
                  {this.renderInvitations()}
                </div>
              </div>
            }
          </div>
          {/* <FamiliesTable families={families} /> */}
        </div>
      );
    } else {
      return (
        <Placeholder
          title={"No Families Yet..."}
          icon={"fa-users"}
          subtext1={"You currently do not have families listed"}
          subtext2={"Add or Invite a Family to get started"}
        />
      );
    }
  };

  render() {
    const {
      familyLoader,
      validationMessage,
      validationSuccess,
      showValidationModal,
      showConfirmationModal,
      confirmationMessage,
      confirmationType,
      actionLoader,
    } = this.state;
    return (
      <div id='FamiliesPage' className='animated fadeIn'>
        <div>
          {familyLoader ? (
            <div className='' style={{ marginTop: "50px", width: "100%" }}>
              <Loader message='loading families' />
            </div>
          ) : (
            this.renderFamilyView()
          )}

          {this.displayInviteFamilyModal()}
          {this.displayAddFamilyModal()}
          <ValidationModal
            validationSuccess={validationSuccess}
            showValidationModal={showValidationModal}
            closeValidationModal={this.closeValidationModal}
            validationMessage={validationMessage}
          />
          <ConfirmationModal
            showConfirmationModal={showConfirmationModal}
            closeConfirmationModal={this.closeConfirmationModal}
            confirmationMessage={confirmationMessage}
            confirmationDenied={this.closeConfirmationModal}
            confirmationProceed={
              confirmationType === "delete"
                ? this.deleteInvitation
                : this.sendReminder
            }
            actionLoader={actionLoader}
          />
        </div>
      </div>
    );
  }
}

FamiliesPage.propTypes = {
  showFamilyModal: PropTypes.bool,
  setShowFamilyModal: PropTypes.func,
  closeFamilyModal: PropTypes.func,
}


function mapStateToProps(state, ownProps) {
  return {
    ui: state.ui,
    user: state.user.current_user,
    families: state.families,
    communities: state.communities.communities,
    invitations: state.invitations,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    common_actions: bindActionCreators(commonActions, dispatch),
    family_actions: bindActionCreators(familyActions, dispatch),
    auth_actions: bindActionCreators(authActions, dispatch),
    communities_actions: bindActionCreators(communitiesActions, dispatch),
    invitation_actions: bindActionCreators(invitationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouting(FamiliesPage))
