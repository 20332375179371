import React from "react";
import logo from "../../../assets/images/logos/OP36_clean_icon_dark.png";
import "./DrillPreviewCard.scss";

class DrillPreviewCard extends React.Component {
  state = {
    hovering: false
  };
  render() {
    const {
      drill,
      triggerDetailModal,
      taskIndex,
      blockIndex,
      moveTask
    } = this.props;
    return (
      <div
        id='DrillPreviewCard'
        onMouseEnter={this._startHover}
        onMouseLeave={this._endHover}
        onClick={
          drill.is_archived ? () => false : () => triggerDetailModal(drill.id)
        }
        style={
          drill.is_archived
            ? { background: "#c0c0c0", cursor: "not-allowed" }
            : {}
        }
      >
        {this.props.active && this.state.hovering ? (
          <div className='grey-out'>
            <div className='task-nav-buttons-container'>
              <i
                className='fa fa-sort-asc'
                aria-hidden='true'
                onClick={e => moveTask(e, -1, taskIndex, blockIndex)}
              />
              <i
                className='fa fa-sort-desc'
                aria-hidden='true'
                onClick={e => moveTask(e, 1, taskIndex, blockIndex)}
              />
            </div>
            <div className='task-play-button-container'>
              {drill.is_archived ? (
                <p className='archived-text'>{`This ${
                  drill.is_coach_activity ? "activity" : "drill"
                } has been deleted by its creator. You should probably go ahead and remove it from your lesson plan.`}</p>
              ) : (
                <i className='fa fa-play' aria-hidden='true' />
              )}
            </div>
            <div className='task-delete-container'>
              <i
                className='fa fa-trash-o'
                aria-hidden='true'
                onClick={e =>
                  this.props.removeActive(
                    e,
                    this.props.blockIndex,
                    this.props.taskIndex
                  )
                }
              />
            </div>
          </div>
        ) : null}
        <div
          className={`drill-card-photo`}
          style={
            drill.is_archived
              ? {
                  backgroundImage: `linear-gradient(to bottom right, rgba(255,255,255,0.8), rgba(0,0,0,0.5),rgba(255,255,255,0.8),rgba(0,0,0,0.5),rgba(255,255,255,0.8),rgba(0,0,0,0.5),rgba(255,255,255,0.8),rgba(0,0,0,0.5),rgba(255,255,255,0.8),rgba(0,0,0,0.5)), url(${
                    drill.photos.length > 0 ? drill.photos[0].file_url : logo
                  })`
                }
              : {
                  backgroundImage: `url(${
                    drill.photos.length > 0 ? drill.photos[0].file_url : logo
                  })`
                }
          }
        />
        <div className={`drill-card-info-container`}>
          <p
            className='drill-card-title'
            style={drill.is_archived ? { color: "#777" } : {}}
          >
            {drill.is_archived ? "[archived]" + drill.title : drill.title}
          </p>
          <p className='drill-card-description'>{drill.description}</p>
          <div className='drill-card-tag-container'>
            {drill.tags.map((tag, index) => {
              if (index < 2) {
                return (
                  <p
                    key={index}
                    className='drill-card-tag-bubble'
                    style={drill.is_archived ? { color: "#ddd" } : {}}
                  >
                    {tag.name}
                  </p>
                );
              } else if (index === 2) {
                return (
                  <p key={index} className='more-tags-indicator'>{`+${drill.tags
                    .length - 2} skills`}</p>
                );
              }
            })}
          </div>
        </div>
        {this.props.picker && (
          <i className='fa fa-play-circle-o' aria-hidden='true' />
        )}
      </div>
    );
  }

  _startHover = () => {
    this.setState({ hovering: true });
  };

  _endHover = () => {
    this.setState({ hovering: false });
  };
}

export default DrillPreviewCard;
