import _ from 'lodash'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { getEnrollmentData, initializeSession, requestEnrollment, getRegistration } from '../actions/programRegistrationActions'
import { createUser } from '../actions/authActions'
import { PROGRAM_REGISTRATION_STEPS } from '../misc/Constants'

const initialState = {
  status: 'inactive',
  errors: null,
  newUserErrors: {},
  enrollmentData: null,
  registrationStatus: null,
  selectedFamilyMember: null,
  selectedPackage: {},
  selectedPaymentMethod: null,
  acceptedMaterials: {},
  refusedMaterials: {},
  steps: [],
  completedSteps: [],
  currentStep: null,
  isEmbedded: false,
}

const programRegistrationSlice = createSlice({
  name: 'programRegistration',
  initialState,
  reducers: {
    resetRegistration: () => initialState,
    decrementCurrentStep: (state) => {
      state.currentStep--
    },
    incrementCurrentStep: (state) => {
      state.currentStep++
    },
    incrementCurrentStepBy: (state, action) => {
      state.currentStep += action.payload
    },
    completeStep: (state, action) => {
      state.completedSteps.push(action.payload)
    },
    setSelectedFamilyMember: (state, action) => {
      state.selectedFamilyMember = action.payload
    },
    setSelectedPackage: (state, action) => {
      state.selectedPackage = action.payload
    },
    setSelectedPaymentMethod: (state, action) => {
      state.selectedPaymentMethod = action.payload
    },
    addAcceptedMaterial: (state, action) => {
      const material = action.payload
      state.acceptedMaterials[material.id] = material
      delete state.refusedMaterials[material.id]
    },
    addRefusedMaterial: (state, action) => {
      const material = action.payload
      state.refusedMaterials[material.id] = material
      delete state.acceptedMaterials[material.id]
    },
    setPreviousSteps: (state, action) => {
      const { courseWithMaterials } = action.payload
      if (courseWithMaterials) {
        state.steps = PROGRAM_REGISTRATION_STEPS.withMaterials
        state.completedSteps = [0, 1, 2]
        state.currentStep = 3
      } else {
        state.steps = PROGRAM_REGISTRATION_STEPS.simple
        state.completedSteps = [0, 1]
        state.currentStep = 2
      }
    },
  },
  extraReducers: {
    // Get enrollment data
    [getEnrollmentData.pending]: state => {
      state.status = 'loading enrollment data'
    },
    [getEnrollmentData.fulfilled]: (state, action) => {
      state.status = 'active'
      const enrollmentData = action.payload.enrollmentData
      state.enrollmentData = enrollmentData
      if (_.isEmpty(enrollmentData.courseMaterials)) {
        state.steps = PROGRAM_REGISTRATION_STEPS.simple
      } else {
        state.steps = PROGRAM_REGISTRATION_STEPS.withMaterials
      }
      state.currentStep = 0
    },
    [getEnrollmentData.rejected]: (state, action) => {
      state.status = 'error'
      state.errors = action.payload
    },

    // Request enrollment
    [requestEnrollment.pending]: state => {
      state.status = 'processing registration'
    },
    [requestEnrollment.rejected]: (state, action) => {
      state.status = 'error'
      state.errors = action.payload
    },

    // Initialize payment session
    [initializeSession.pending]: state => {
      state.status = 'processing registration'
    },
    [initializeSession.rejected]: (state, action) => {
      state.status = 'error'
      state.errors = action.payload
    },

    // Get registration (success screen)
    [getRegistration.pending]: state => {
      state.status = 'processing registration'
    },
    [getRegistration.fulfilled]: (state, action) => {
      if (action.payload.registration.status === 'pending') {
        state.status = 'processing registration'
      } else {
        state.status = 'finished'
        state.registrationStatus = action.payload.registration.status
        state.acceptedMaterials = action.payload.registration.materials
        state.selectedPackage = action.payload.registration.package
        state.selectedFamilyMember = action.payload.registration.user
        state.selectedPaymentMethod = action.payload.registration.paymentMethod
        state.isEmbedded = action.payload.registration.createdBy === 'mobile_registration'
      }
    },
    [getRegistration.rejected]: (state, action) => {
      state.status = 'error'
      state.errors = action.payload
    },

    // Create new user
    [createUser.pending]: state => {
      state.status = 'creating user'
    },
    [createUser.fulfilled]: (state, action) => {
      const user = action.payload.user

      state.enrollmentData.familyMembers.push(user)
      state.selectedFamilyMember = user
      state.status = 'active'
    },
    [createUser.rejected]: state => {
      state.status = 'active'
    },
  },
})

export const {
  resetRegistration,
  decrementCurrentStep,
  incrementCurrentStep,
  incrementCurrentStepBy,
  completeStep,
  setSelectedFamilyMember,
  setSelectedPackage,
  setSelectedPaymentMethod,
  addAcceptedMaterial,
  addRefusedMaterial,
  setPreviousSteps,
} = programRegistrationSlice.actions

export const selectAcceptedMaterialsIds = createSelector(
  state => state.programRegistration.acceptedMaterials,
  acceptedMaterials => _.map(Object.keys(acceptedMaterials), Number)
)

export const selectRefusedMaterialsIds = createSelector(
  state => state.programRegistration.refusedMaterials,
  refusedMaterials => _.map(Object.keys(refusedMaterials), Number)
)

export const withMaterials = createSelector(
  state => state.programRegistration.acceptedMaterials,
  acceptedMaterials => !_.isEmpty(acceptedMaterials)
)

export const validProgramDetailsStep = createSelector(
  state => ({
    selectedFamilyMember: state.programRegistration.selectedFamilyMember,
    selectedPackage: state.programRegistration.selectedPackage,
    coursePackages: state.programRegistration.enrollmentData?.coursePackages,
    selectedPaymentMethod: state.programRegistration.selectedPaymentMethod,
  }),
  programDetailsData => programDetailsData.selectedFamilyMember
    && (!_.isEmpty(programDetailsData.selectedPackage) || programDetailsData.coursePackages?.length === 0)
    && programDetailsData.selectedPaymentMethod
)

export const validProgramMaterialsStep = createSelector(
  selectAcceptedMaterialsIds,
  selectRefusedMaterialsIds,
  state => state.programRegistration.enrollmentData?.courseMaterials,
  (acceptedMaterialsIds, refusedMaterialsIds, courseMaterials) =>
    acceptedMaterialsIds?.length + refusedMaterialsIds?.length === courseMaterials?.length
)

export default programRegistrationSlice.reducer
