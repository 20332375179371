import { deserialize } from './serialization'

const defaultErrorHandler = (data) => data

export const handleAPIError = (error, errorHandler = defaultErrorHandler) => {
  if (error.response && error.response.data?.errors) {
    return errorHandler(deserialize(error.response.data.errors))
  } else {
    return errorHandler(error.message)
  }
}
