import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import Error from "../../../common/ErrorMessage";
import ReactTelephoneInput from "react-telephone-input";
import { displayYearOptions } from "../../../../utility/helperFunctions";
import 'react-telephone-input/css/default.css';
import flags from "../../../../assets/images/flags.png";
import "./FamilyRegisterForm.scss";

import {
  isValidEmail,
  isValidPhone,
} from "../../../../utility/helperFunctions";

class FamilyRegisterForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      first_name: "",
      last_name: "",
      community_id: props.community.id,
      invitation_id:
        props.invitation && props.invitation.id && props.invitation.id,
      email:
        props.invitation && props.invitation.email
          ? props.invitation.email
          : "",
      phone: "",
      birth_day: "",
      birth_month: "",
      birth_year: "",
      birthdate: "",
      gender: "unselected",
      type: "Student",
      password: "",
      confirm_password: "",
      consent: false,
      privacy: false,
      family_admin: true,
      is_golfer: null,
      formError: {},
      emptyError: "",
      nextStep: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      !_.isEmpty(nextProps.error) &&
      nextProps.error.email &&
      nextProps.activeStep === 2 &&
      this.props.activeStep === 2
    ) {
      this.goBack();
    }
  }

  handleInputChange = (telNumber, selectedCountry) => {
    this.setState({
      phone: telNumber,
    });
  };

  validateAge = () => {
    const { birth_day, birth_month, birth_year } = this.state;
    let birthdate = moment()
      .date(birth_day)
      .month(birth_month - 1)
      .year(birth_year)
      .toDate();

    let age = moment().diff(birthdate, "years");

    if (age >= 13) {
      return true;
    }
    return false;
  };

  checkValidation = () => {
    const {
      first_name,
      last_name,
      email,
      phone,
      birth_day,
      birth_month,
      birth_year,
      password,
      confirm_password,
      gender,
    } = this.state;
    if (
      first_name !== "" &&
      last_name !== "" &&
      email !== "" &&
      phone.length > 2 &&
      birth_day !== "" &&
      birth_month !== "" &&
      birth_year !== "" &&
      gender !== "unselected" &&
      this.validateAge() &&
      isValidPhone(phone) &&
      password.length >= 6 &&
      password === confirm_password
    ) {
      return true;
    }
    return false;
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleCheck = (event) => {
    this.setState({
      [event.target.name]: event.target.checked,
    });
  };

  onChangeBirthday = (event) => {
    switch (event.target.name) {
      case "day":
        this.setState({ birth_day: event.target.value });
        break;
      case "month":
        this.setState({ birth_month: event.target.value });
        break;
      case "year":
        this.setState({ birth_year: event.target.value });
        break;
      default:
        return;
    }
  };

  signUpFamily = (e) => {
    e.preventDefault();
    const { signUpFamily } = this.props;
    const { birth_day, birth_month, birth_year, consent, privacy } = this.state;

    if (!this.checkValidation()) {
      this.setState({
        emptyError:
          "Please fill out all of the fields correctly before signing up",
      });
    } else if (!consent) {
      this.setState({
        emptyError:
          "Please confirm you are above 13 years of age before signing up",
      });
    } else if (!privacy) {
      this.setState({
        emptyError:
          "Please agree to privacy policy and terms of use before signing up",
      });
    } else {
      let birthdate = moment()
        .date(birth_day)
        .month(birth_month - 1)
        .year(birth_year)
        .toDate();

      this.setState(
        {
          birthdate,
          emptyError: "",
        },
        () => {
          signUpFamily(this.state);
        }
      );
    }
  };

  goBack = () => {
    this.setState({ nextStep: false, is_golfer: null }, () =>
      this.props.previousStep()
    );
  };

  chooseParticipation = (participationChoice) => {
    if (this.checkCanContinue()) {
      this.setState({
        emptyError:
          "Please fill out all of the fields correctly before continuing",
      });
    } else {
      this.setState(
        {
          is_golfer: participationChoice,
        },
        () => {
          this.setState(
            {
              nextStep: true,
              emptyError: "",
            },
            () => {
              this.props.nextStep();
            }
          );
        }
      );
    }
  };

  checkCanContinue = () => {
    const { first_name, last_name, email, birth_day, birth_month, birth_year } =
      this.state;

    if (
      first_name !== "" &&
      last_name !== "" &&
      email !== "" &&
      birth_day !== "" &&
      birth_month !== "" &&
      birth_year !== "" &&
      this.validateAge() &&
      isValidEmail(email)
    ) {
      return false;
    }
    return true;
  };

  customErrorMessage = (type) => {
    switch (type) {
      case "first_name":
        return "Please enter a first name";
      case "last_name":
        return "Please enter a last name";
      case "email":
        return "Please enter an email";
      case "birthday":
        return "Please fill out all birthday fields";
      case "gender":
        return "Please choose a gender";
      case "password":
        return "Please enter a password";
      case "confirm_password":
        return "Please confirm your password";
      default:
        return "";
    }
  };

  checkInput = (event, type) => {
    const { birth_day, birth_month, birth_year, password, confirm_password } =
      this.state;

    if (
      !event.target.value ||
      event.target.value === "-1" ||
      event.target.value === "unselected"
    ) {
      this.setState({
        formError: { [type]: this.customErrorMessage(type) },
      });
    } else {
      this.setState({
        formError: { [type]: null },
      });
    }

    if (
      type === "email" &&
      !isValidEmail(event.target.value) &&
      event.target.value
    ) {
      this.setState({
        formError: { [type]: "Please enter a valid email address" },
      });
    }

    if (
      type === "birthday" &&
      birth_day !== "" &&
      birth_month !== "" &&
      birth_year !== "" &&
      !this.validateAge()
    ) {
      this.setState({
        formError: {
          [type]:
            "You are younger than 13 years old and cannot create an account",
        },
      });
    }

    if (
      password !== "" &&
      confirm_password !== "" &&
      password !== confirm_password
    ) {
      this.setState({
        formError: {
          [type]: "Your passwords do not match",
        },
      });
    }
  };

  checkPhoneInput = (type) => {
    const { phone } = this.state;
    if (type === 'phone' && phone !== '' && !isValidPhone(phone)) {
      this.setState({
        formError: {
          [type]: "Please enter a valid phone number",
        },
      });
    } else if (type === "phone" && phone === "") {
      this.setState({
        formError: {
          [type]: "Please enter a phone number",
        },
      });
    } else {
      this.setState({
        formError: { [type]: null },
      });
    }
  };

  render() {
    const { error, loader } = this.props;
    const {
      first_name,
      last_name,
      email,
      birth_day,
      birth_month,
      birth_year,
      gender,
      phone,
      is_golfer,
      formError,
      nextStep,
      password,
      confirm_password,
      consent,
      privacy,
      emptyError,
    } = this.state;

    return (
      <div id='FamilyRegisterForm' className='col-xl-6 col-lg-12 mx-auto registration-form'>
        {!nextStep ? (
          <div className='animated fadeInLeft'>
            <div className='form-row'>
              <label htmlFor='first_name'>First Name*</label>
              <input
                type={"text"}
                label={""}
                name={"first_name"}
                value={first_name}
                onChange={this.handleChange}
                placeholder={"Enter First Name"}
                disabled={loader}
                onBlur={(e) => this.checkInput(e, "first_name")}
              />
              {formError && formError.first_name && (
                <Error error={formError.first_name} />
              )}
            </div>
            <div className='form-row'>
              <label htmlFor='last_name'>Last Name*</label>
              <input
                type={"text"}
                label={""}
                name={"last_name"}
                value={last_name}
                onChange={this.handleChange}
                placeholder={"Enter Last Name"}
                disabled={loader}
                onBlur={(e) => this.checkInput(e, "last_name")}
              />
              {formError && formError.last_name && (
                <Error error={formError.last_name} />
              )}
            </div>
            <div className='form-row'>
              <label htmlFor='email'>Email*</label>
              <input
                name={"email"}
                type={"text"}
                label={""}
                value={email}
                onChange={this.handleChange}
                placeholder={"Enter Email"}
                disabled={loader}
                onBlur={(e) => this.checkInput(e, "email")}
              />
              {error && error.email && <Error error={error.email[1]} />}
              {formError && formError.email && (
                <Error error={formError.email} />
              )}
            </div>
            <div className='form-row'>
              <label>
                Birthdate (must be 13 years or older to create an account)*
              </label>
              <div className='row'>
                <div className='col-4'>
                  <small>
                    <label>Month</label>
                  </small>
                  <select
                    onChange={this.onChangeBirthday}
                    name='month'
                    value={birth_month}
                    className={`form-control ${
                      birth_month === "" && "default-option"
                    }`}
                    disabled={loader}
                    onBlur={(e) => this.checkInput(e, "birthday")}
                  >
                    <option value='' disabled>
                      Select a month
                    </option>
                    {_.range(1, 13).map(function (x) {
                      return <option key={x}>{x}</option>;
                    })}
                  </select>
                </div>

                <div className='col-4'>
                  <small>
                    <label>Day</label>
                  </small>
                  <select
                    onChange={this.onChangeBirthday}
                    name='day'
                    value={birth_day}
                    className={`form-control ${
                      birth_day === "" && "default-option"
                    }`}
                    disabled={loader}
                    onBlur={(e) => this.checkInput(e, "birthday")}
                  >
                    <option value='' disabled>
                      Select a day
                    </option>
                    {_.range(1, 32).map((x) => {
                      return <option key={x}>{x}</option>;
                    })}
                  </select>
                </div>

                <div className='col-4'>
                  <small>
                    <label>Year</label>
                  </small>
                  <select
                    onChange={this.onChangeBirthday}
                    name='year'
                    value={birth_year}
                    className={`form-control ${
                      birth_year === "" && "default-option"
                    }`}
                    disabled={loader}
                    onBlur={(e) => this.checkInput(e, "birthday")}
                  >
                    <option value='' disabled>
                      Select a Year
                    </option>
                    {displayYearOptions()}
                  </select>
                </div>
              </div>
              {formError && formError.birthday && (
                <Error error={formError.birthday} />
              )}
              {emptyError && <Error error={emptyError} />}
            </div>
            <div className='form-row mt-3'>
              <label htmlFor='first_name'>
                Will you be participating as a golfer? (choose to continue)*
              </label>
              <div className='golfer-selection-row'>
                <button
                  className={`btn btn-dark ${
                    is_golfer === true && "btn-primary"
                  }`}
                  onClick={() => this.chooseParticipation(true)}
                >
                  Yes
                </button>
                <button
                  className={`btn btn-dark ${
                    is_golfer === false && "btn-primary"
                  }`}
                  onClick={() => this.chooseParticipation(false)}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className='animated fadeInRight'>
            <form>
              <div className='form-row'>
                <label htmlFor='phone'>Phone (choose country)*</label>
                <ReactTelephoneInput
                  defaultCountry='us'
                  flagsImagePath={flags}
                  onChange={this.handleInputChange}
                  value={phone}
                  disabled={loader}
                  onBlur={() => this.checkPhoneInput("phone")}
                />
                {formError && formError.phone && (
                  <Error error={formError.phone} />
                )}
              </div>
              <div className='form-row'>
                <label htmlFor='gender'>Gender*</label>
                <select
                  onChange={this.handleChange}
                  name='gender'
                  value={gender}
                  className={`form-control ${
                    gender === "unselected" && "default-option"
                  }`}
                  disabled={loader}
                  onBlur={(e) => this.checkInput(e, "gender")}
                >
                  <option value={"unselected"} disabled>
                    Select a gender
                  </option>
                  <option value={1}>Male</option>
                  <option value={2}>Female</option>
                  <option value={3}>Other</option>
                  {/* <option value={0}>Prefer not to say</option> */}
                </select>
                {formError && formError.gender && (
                  <Error error={formError.gender} />
                )}
              </div>

              <div className='form-row'>
                <label htmlFor='password'>
                  Password (at least 6 characters)*
                </label>
                <input
                  name={"password"}
                  type={"password"}
                  label={""}
                  value={password}
                  onChange={this.handleChange}
                  placeholder={"Create password"}
                  disabled={loader}
                  onBlur={(e) => this.checkInput(e, "password")}
                  autoComplete='new-password'
                />
                {formError && formError.password && (
                  <Error error={formError.password} />
                )}
              </div>
              <div className='form-row'>
                <label htmlFor='confirm_password'>Confirm Password*</label>
                <input
                  name={"confirm_password"}
                  type={"password"}
                  label={""}
                  value={confirm_password}
                  onChange={this.handleChange}
                  placeholder={"Confirm password"}
                  disabled={loader}
                  onBlur={(e) => this.checkInput(e, "confirm_password")}
                  autoComplete='new-password'
                />
                {formError && formError.confirm_password && (
                  <Error error={formError.confirm_password} />
                )}
              </div>
              <div className='checkbox-container'>
                <div className='checkbox-wrapper'>
                  <input
                    type='checkbox'
                    name='consent'
                    value={consent}
                    onChange={this.handleCheck}
                    checked={consent}
                    disabled={loader}
                  />
                  <p>I agree I am over the age of 13</p>
                </div>
                <div className='checkbox-wrapper'>
                  <input
                    type='checkbox'
                    name='privacy'
                    value={privacy}
                    onChange={this.handleCheck}
                    checked={privacy}
                    disabled={loader}
                  />
                  <p>
                    I agree to the{" "}
                    <a href='https://operation36.golf/legal/' target='_blank' rel='noreferrer'>
                      Privacy Policy
                    </a>{" "}
                    and{" "}
                    <a href='https://operation36.golf/legal/' target='_blank' rel='noreferrer'>
                      Terms of Use
                    </a>
                  </p>
                </div>
              </div>
              {emptyError && <Error error={emptyError} />}

              <div className='row form-btn-wrapper'>
                <button
                  className='btn btn-dark'
                  onClick={() => this.goBack()}
                  disabled={loader}
                >
                  Go Back
                </button>
                <button
                  type='submit'
                  disabled={loader}
                  className='btn btn-primary'
                  onClick={(e) => this.signUpFamily(e)}
                >
                  {loader && (
                    <i className='fa fa-spinner fa-spin confirmation-spinner' />
                  )}
                  Sign{loader && "ing"} Up
                </button>
              </div>
            </form>
          </div>
        )}

        <p className='required-text'>
          *All fields are required to create an account on the Operation 36
          platform
        </p>
      </div>
    );
  }
}

export default FamilyRegisterForm;
