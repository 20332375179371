import React from "react";
import Modal from "react-modal";
import moment from "moment";

import { delay } from "lodash";
// import autosize from "autosize";
import Axios from "../../../axios";

import StudentPickerRow from "../FacilityImpactReport/StudentPickerRow";
import BaseModal from "../../common/BaseModal";
import PlayerJourneyReportContainer from "./PlayerJourneyReportContainer";

import placeholderIcon from "../../../assets/images/class-icon.png";
import ohSnap from "../../../assets/images/registration-coach/oh-snap.png";
import defaultProfilePic from "../../../assets/images/default_profile.png";

import "./PlayerJourneyReport.scss";
import html2canvas from "html2canvas";
import jsPDF from 'jspdf'

export default class PlayerJourneyReport extends React.Component {
  state = {
    studentModal: false,
    selectionDisplay: "Students", // controls which subsection you're picking students from on the add student modal
    selectedStudents: [], // controlls actually adding students to the report
    temporarySelectedStudents: [], //conrolls adding students in the modal
    selectionFilter: "", // the searchbar on the studentModal
    reportLoader: false, // loader for when the report is being generated
    report: null, // the returned result from the report endpoint stored here
    studentLoader: true, // initial loader for bringing in students
    totalStudents: [], // from api - don't touch
    communityCourses: [], // from api - don't touch
    communityCompletedCourses: [], // from api - don't touch
    completedCourseLoader: true, // initial loader for bringing in completed courses
    courseLoader: true, // initial loader for bringing in courses
    familyLoader: true, // initial loader for bringing in families
    families: [], // from api - don't touch
    generalNoteModal: false,
    generalNote: "",
    errorModal: false, // if an error occurs requesting the report
    pdfLoader: false,
  };

  componentDidMount() {
    this._grabStudentsList(this.props.community.id).then(() => {
      this.setState({ studentLoader: false });
    });
    this._grabCourseList(this.props.community.id).then(() => {
      this.setState({ courseLoader: false });
    });
    this._grabCompletedCourseList(this.props.community.id).then(() => {
      this.setState({ completedCourseLoader: false });
    });
    this._grabFamilyList(this.props.community.id).then(() => {
      this.setState({ familyLoader: false });
    });
  }

  _grabStudentsList = async (community_id) => {
    let response = await Axios.get(
      `/api/v1/communities/${community_id}/students`
    );
    this.setState({ totalStudents: response.data.students });
  };

  _grabCourseList = async (community_id) => {
    let response = await Axios.get(
      `/api/v1/communities/${community_id}/coach_courses`
    );
    this.setState({ communityCourses: response.data.courses });
  };

  _grabCompletedCourseList = async (community_id) => {
    let response = await Axios.get(
      `/api/v1/communities/${community_id}/completed_courses`
    );
    this.setState({ communityCompletedCourses: response.data.courses });
  };

  _grabFamilyList = async (community_id) => {
    let response = await Axios.get(
      `/api/v1/communities/${community_id}/families`
    );
    this.setState({ families: response.data.families });
  };

  render() {
    const { goBack } = this.props;
    const {
      studentModal,
      selectedStudents,
      temporarySelectedStudents,
      selectionDisplay,
      selectionFilter,
      generalNoteModal,
      generalNote,
      report,
      reportModal,
      reportLoader,
      errorModal,
      pdfLoader,
    } = this.state;
    if (report && reportModal) {
      return (
        <div className='w-100 h-100 pt-3 pb-3 report-display-container'>
          <div className='redownload-bar'>
            <div className='d-flex align-items-center justify-content-center w-50 mx-auto'>
              <button
                className='btn-rectangular btn-rectangular-main'
                onClick={this._convertReportToPDF}
                disabled={pdfLoader}
              >
                {pdfLoader ? (
                  <div className='d-flex align-items-center justify-content-center'>
                    <i
                      className='fa fa-spinner fa-spin mr-2'
                      aria-hidden='true'
                    />
                    Loading Report
                    <i
                      className='fa fa-spinner fa-spin ml-2'
                      aria-hidden='true'
                    />
                  </div>
                ) : (
                  <div className='d-flex align-items-center justify-content-center'>
                    <i className='fa fa-file-pdf-o mr-2' aria-hidden='true' />
                    Download Report
                    <i className='fa fa-file-pdf-o ml-2' aria-hidden='true' />
                  </div>
                )}
              </button>
            </div>
          </div>
          <p
            className='back-to-previous'
            onClick={() => this.setState({ reportModal: false })}
          >
            <i className='fa fa-arrow-left' aria-hidden='true' />
            Go Back
          </p>
          <PlayerJourneyReportContainer
            reports={report}
            selectedStudents={selectedStudents}
          />
        </div>
      );
    }
    return (
      <div id='PlayerJourneyReport'>
        <div className='impact-top-bar'>
          <i className='fa fa-arrow-left' aria-hidden='true' onClick={goBack} />
          <div className='top-bar-title-container'>
            <h3>Player Journey Report</h3>
            <p className='title-description'>
              Tell the story of your student's progression in the Operation 36
              Program with a report to be proud of!
            </p>
          </div>
          <div className='impact-video-trigger-container pjr-trigger'>
            <i className='fa fa-play-circle-o' aria-hidden='true' />
            <p>Need help?</p>
          </div>
        </div>
        <div className='player-journey-main d-flex flex-column'>
          <h4>
            Step 1: Select students to generate reports for and add notes:
          </h4>
          {selectedStudents.length === 0 ? (
            <div className='student-placeholder-container'>
              <div
                className='placeholder-icon'
                style={{ backgroundImage: `url(${placeholderIcon})` }}
              />
              <p className='bold'>No students selected yet.</p>
              <p>Please select the students to generate reports for.</p>
              <div>
                <button
                  className='btn-rectangular btn-rectangular-main-positive'
                  onClick={this._openStudentModal}
                >
                  + Select students to include
                </button>
              </div>
            </div>
          ) : (
            <div className='student-note-container'>
              <div className='d-flex justify-content-between'>
                <p className='mb-0 bold'>
                  Selected students ({selectedStudents.length})
                </p>
                <div
                  className='d-flex orange-text flex-nowrap align-items-center clickable'
                  onClick={this._openStudentModal}
                >
                  <i
                    className='fa fa-pencil-square-o mr-1'
                    aria-hidden='true'
                  />
                  <p className='mb-0'>Edit student selection</p>
                </div>
              </div>
              <div className='student-table-head'>
                <div className='left-table'>
                  <p className='head-text-title'>Student</p>
                </div>
                <div className='right-table'>
                  <p className='head-text-title'>Note from Coach (optional)</p>
                  <p className='head-text'>
                    You can type up to 300 characters of notes for each student.
                    If you want to handwrite your notes instead, then leave
                    these text boxes empty.
                  </p>
                  <div className='d-flex orange-text mt-2'>
                    <div
                      className='d-flex clickable align-items-center mr-4'
                      onClick={this._toggleGeneralNoteModal}
                    >
                      <i className='fa fa-file-o mr-1' aria-hidden='true' />
                      <p>Enter general note for all</p>
                    </div>
                    <div
                      className='d-flex clickable align-items-center'
                      onClick={this._clearAllNotes}
                    >
                      <i className='fa fa-trash-o mr-1' aria-hidden='true' />
                      <p>Clear all notes</p>
                    </div>
                  </div>
                </div>
              </div>
              {selectedStudents.map((student) => {
                return (
                  <div key={student.id} className='student-row'>
                    <div className='left-table row-left'>
                      <div
                        className='player-icon'
                        style={{
                          backgroundImage: `url(${
                            student.avatar
                              ? student.avatar.file_url
                              : defaultProfilePic
                          })`,
                        }}
                      />
                      <div className='d-flex flex-column'>
                        <p className='row-name'>{student.name}</p>
                        <p className='row-username'>{student.username}</p>
                      </div>
                    </div>
                    <div className='right-table row-right'>
                      <textarea
                        className='student-note'
                        placeholder={`Add a note for ${
                          student.name.split(" ")[0]
                        } here...`}
                        value={student.note}
                        onChange={(e) =>
                          this._handleStudentNoteChange(e, student.id)
                        }
                      />
                      <p className='word-count'>{student.note.length}/300</p>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className='impact-report-bottom-bar'>
          {!!report ? (
            <button
              className='btn-rectangular btn-rectangular-main-positive'
              onClick={this._reopenReport}
            >
              View your report
            </button>
          ) : (
            <button
              className='btn-rectangular btn-rectangular-main-positive grab-report-button'
              disabled={this._checkValidation()}
              onClick={this._grabReportData}
            >
              Step 2: Generate your report!
            </button>
          )}
        </div>
        {errorModal && (
          <BaseModal
            close={() => false}
            backable={false}
            hideClose={true}
            width='350px'
          >
            <div id='impact-report-error-modal'>
              <div
                className='oh-snap'
                style={{ backgroundImage: `url(${ohSnap})` }}
              />
              <p className='main-text text-center'>Something went wrong.</p>
              <p className='sub-text text-center'>
                Uh oh. We weren't able to generate your report at this time.
                Please try again later.
              </p>
              <button
                className='btn-rectangular btn-rectangular-main'
                onClick={() => this.setState({ errorModal: false })}
              >
                Close
              </button>
            </div>
          </BaseModal>
        )}
        {reportLoader && (
          <BaseModal
            close={() => false}
            backable={false}
            hideClose={true}
            width='350px'
          >
            <div id='impact-report-loading-modal'>
              <i className='fa fa-spinner fa-spin' aria-hidden='true' />
              <p className='main-text text-center'>Generating report...</p>
              <p className='sub-text text-center'>
                This report may take up to 30 seconds to generate. Please be
                patient!
              </p>
            </div>
          </BaseModal>
        )}
        {generalNoteModal && (
          <BaseModal
            close={this._toggleGeneralNoteModal}
            hideClose={true}
            width={"50vw"}
          >
            <h4 className='text-center'>Enter general note for all</h4>
            <p className='text-center' style={{ color: "#5f6f7c" }}>
              Enter a general note for all of the students that you have
              selected. This will override the notes that you currently have
              entered for each student.
            </p>
            <textarea
              className='player-journey-general-note-input'
              value={generalNote}
              onChange={this._handleGeneralNoteChange}
              placeholder='Enter your general note here...'
            ></textarea>
            <p className='text-right' style={{ color: "#AFB7BD" }}>
              {generalNote.length}/300 characters
            </p>
            <div className='d-flex'>
              <div style={{ flex: "1 1" }}>
                <button
                  className='btn-rectangular btn-rectangular-main-negative'
                  onClick={this._toggleGeneralNoteModal}
                >
                  Cancel
                </button>
              </div>
              <div style={{ flex: "1 1" }}>
                <button
                  className='btn-rectangular btn-rectangular-main-positive'
                  onClick={this._applyGeneralNote}
                >
                  Apply note to all students
                </button>
              </div>
            </div>
          </BaseModal>
        )}
        <Modal
          isOpen={studentModal}
          contentLabel='Add Student Modal'
          shouldCloseOnOverlayClick
          className='customModal'
        >
          <div id='add-student-to-report-modal'>
            <div className='modal-header'>
              <h5 className='modal-title display-4'>Select students</h5>
              <div className='col-1'>
                <button
                  type='button'
                  className='close pull-right font-200'
                  data-dismiss='modal'
                  aria-label='Close'
                  onClick={this._closeUnsavedStudentModal}
                >
                  <span aria-hidden='true'>✕</span>
                </button>
              </div>
            </div>
            <div className='student-modal-content'>
              <div className='selector-section'>
                <div className='top-selector-bar'>
                  <div
                    className='selection-container'
                    onClick={() => this._switchDisplay("Students")}
                  >
                    <p
                      className={`${
                        selectionDisplay === "Students" ? "isActive" : ""
                      }`}
                    >
                      STUDENTS
                    </p>
                  </div>
                  <div
                    className='selection-container'
                    onClick={() => this._switchDisplay("Families")}
                  >
                    <p
                      className={`${
                        selectionDisplay === "Families" ? "isActive" : ""
                      }`}
                    >
                      FAMILIES
                    </p>
                  </div>
                  <div
                    className='selection-container'
                    onClick={() => this._switchDisplay("Active Classes")}
                  >
                    <p
                      className={`${
                        selectionDisplay === "Active Classes" ? "isActive" : ""
                      }`}
                    >
                      ACTIVE CLASSES
                    </p>
                  </div>
                  <div
                    className='selection-container'
                    onClick={() => this._switchDisplay("Completed Classes")}
                  >
                    <p
                      className={`${
                        selectionDisplay === "Completed Classes"
                          ? "isActive"
                          : ""
                      }`}
                    >
                      COMPLETED CLASSES
                    </p>
                  </div>
                  <div
                    className='selection-indicator'
                    style={this._grabLeftCoordinate()}
                  />
                </div>
                <div className='d-flex align-items-center justify-content-between mt-5'>
                  <h4>{selectionDisplay}</h4>
                  <div className='icon-search-bar'>
                    <i className='fa fa-search' aria-hidden='true' />
                    <input
                      name='selectionFilter'
                      onChange={this._onInputChange}
                      value={selectionFilter}
                      placeholder={`Search ${selectionDisplay}`}
                      disabled={this._checkSearchLoading(selectionDisplay)}
                    />
                  </div>
                </div>
                <div className='picker-selection-display'>
                  {this._renderPicker()}
                </div>
              </div>
              {temporarySelectedStudents.length < 1 ? (
                <div className='placeholder-container d-flex align-items-center justify-content-center flex-column'>
                  <div
                    className='placeholder-icon'
                    style={{ backgroundImage: `url(${placeholderIcon})` }}
                  />
                  <p className='bold'>No students selected yet</p>
                  <p>
                    Please select the students to generate reports for by
                    selecting classes, families, or individual students.
                  </p>
                </div>
              ) : (
                <div className='student-display-section'>
                  <div className='inner-display'>
                    <p className='bold section-title'>{`Selected students (${temporarySelectedStudents.length})`}</p>
                    <div className='student-box'>
                      {temporarySelectedStudents.map((student) => {
                        return (
                          <StudentPickerRow
                            key={student.id}
                            student={student}
                            clickable
                            onClick={this._removeStudentFromTemporary}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-8 mx-auto'>
              <button
                className='btn-rectangular btn-rectangular-main'
                onClick={this._closeSavedStudentModal}
              >
                Confirm selection
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }

  _reopenReport = () => {
    this.setState({ reportModal: true });
  };

  _grabReportData = () => {
    this.setState({ reportLoader: true }, async () => {
      const { selectedStudents } = this.state;
      try {
        let requestUrl = `/api/v1/reports/player_journey_progress`;
        selectedStudents.forEach((student, i) => {
          requestUrl += `${i === 0 ? "?" : "&"}user_ids[]=${student.id}`;
        });
        let response = await Axios.get(requestUrl);
        this.setState({ report: response.data }, () => {
          this.setState(
            { reportLoader: false, reportModal: true, pdfLoader: false },
            () => {
              //setTimeout(this._convertReportToPDF, 1000);
            }
          );
          this.setState({ reportLoader: false });
        });
      } catch (err) {
        this.setState({ reportLoader: false, errorModal: true });
      }
    });
  };

  _convertReportToPDF = () => {
    this.setState({ pdfLoader: true }, async () => {
      const ORIENTATION = "landscape";
      const UNIT = "px";

      const reportPages = document.querySelectorAll(".PlayerJourneyReportView");

      const pageWidth =
        (reportPages && reportPages[0] && reportPages[0].clientWidth) || 1000;
      const pageHeight =
        (reportPages && reportPages[0] && reportPages[0].clientHeight) || 780;

      const pdf = new jsPDF({
        orientation: ORIENTATION,
        unit: UNIT,
        format: [pageWidth, pageHeight],
        hotfixes: ["px_scaling"],
      });

      for (let i = 0; i < reportPages.length; i++) {
        const canvas = await html2canvas(reportPages[i], {
          useCORS: true,
          logging: true,
        });
        if (i > 0) pdf.addPage([pageWidth, pageHeight]);
        const imgData = canvas.toDataURL("image/png", 1.0);
        pdf.addImage(imgData, "PNG", 0, 0, pageWidth, pageHeight, "", "FAST");
      }

      await pdf.save("player_journey_reports.pdf");
      this.setState({ pdfLoader: false });
    });
  };

  _handleGeneralNoteChange = (e) => {
    let generalNote = e.target.value;
    if (generalNote.length > 300) return false;
    this.setState({ generalNote });
  };

  _toggleGeneralNoteModal = () => {
    this.setState((prevState) => {
      return { generalNoteModal: !prevState.generalNoteModal };
    });
  };

  _applyGeneralNote = () => {
    const { selectedStudents, generalNote } = this.state;
    let selectedStudentsCopy = [...selectedStudents];
    selectedStudentsCopy.map((s) => {
      s.note = generalNote;
      return s;
    });
    this.setState(
      { selectedStudents: selectedStudentsCopy, report: null },
      () => {
        this._toggleGeneralNoteModal();
      }
    );
  };

  _clearAllNotes = () => {
    const { selectedStudents } = this.state;
    let selectedStudentsCopy = [...selectedStudents];
    selectedStudentsCopy.map((s) => {
      s.note = "";
      return s;
    });
    this.setState({ selectedStudents: selectedStudentsCopy, report: null });
  };

  _handleStudentNoteChange = (e, studentId) => {
    let fullNote = e.target.value;
    if (fullNote.length > 300) {
      return false;
    }
    const { selectedStudents } = this.state;
    let selectedStudentsCopy = [...selectedStudents];
    let changingStudent = selectedStudentsCopy.find((s) => s.id === studentId);
    changingStudent.note = fullNote;
    this.setState({ selectedStudents: selectedStudentsCopy, report: null });
  };

  _openStudentModal = () => {
    this.setState({ studentModal: true });
  };

  _closeUnsavedStudentModal = () => {
    const { selectedStudents } = this.state;
    this.setState({ temporarySelectedStudents: [...selectedStudents] }, () => {
      this.setState({ studentModal: false });
    });
  };

  _switchDisplay = (val) => {
    const { selectionDisplay } = this.state;
    if (val === selectionDisplay) return false;
    this.setState({ selectionFilter: "" }, () => {
      this.setState({ selectionDisplay: val });
    });
  };

  _grabLeftCoordinate = () => {
    const { selectionDisplay } = this.state;
    switch (selectionDisplay) {
      case "Families":
        return { left: "25%" };
      case "Active Classes":
        return { left: "50%" };
      case "Completed Classes":
        return { left: "75%" };
      default:
        return { left: 0 };
    }
  };

  _checkAlreadySelected = (users) => {
    if (users.length === 0) return false;
    const { temporarySelectedStudents } = this.state;
    return users.every((user) =>
      temporarySelectedStudents.map((u) => u.id).includes(user.id)
    );
  };

  _onInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "membershipPercent" && value.length > 3) {
      return false;
    }
    this.setState({ [name]: value });
  };

  _checkSearchLoading = (current) => {
    const { studentLoader, courseLoader, completedCourseLoader, familyLoader } =
      this.state;
    switch (current) {
      case "Students":
        return studentLoader;
      case "Families":
        return familyLoader;
      case "Active Classes":
        return courseLoader;
      case "Completed Classes":
        return completedCourseLoader;
      default:
        return true;
    }
  };

  _checkValidation = () => {
    //return true to fail validation and false to pass
    const { selectedStudents } = this.state;
    if (selectedStudents.length < 1) return true;
    return false;
  };

  _renderPicker = () => {
    const { selectionDisplay, selectionFilter } = this.state;
    switch (selectionDisplay) {
      case "Active Classes":
        return this._renderClasses(selectionFilter);
      case "Completed Classes":
        return this._renderCompletedClasses(selectionFilter);
      case "Families":
        return this._renderFamilies(selectionFilter);
      case "Students":
        return this._renderStudents(selectionFilter);
      default:
        return null;
    }
  };

  _renderClasses = (filter) => {
    const { courseLoader, communityCourses } = this.state;
    if (courseLoader) {
      return (
        <div className='d-flex flex-column align-items-center justify-content-center pt-3'>
          <i className='fa fa-spinner fa-spin' aria-hidden='true' />
          <p className='mt-3'>Loading Active Classes</p>
        </div>
      );
    }
    if (communityCourses.length < 1) {
      return (
        <div className='d-flex justify-content-center mt-3'>
          <p>looks like you don't have any active classes right now</p>
        </div>
      );
    }
    return communityCourses
      .filter((c) => {
        if (!filter || filter.split("").every((char) => char === " "))
          return true;
        return c.name.toLowerCase().includes(filter.trim().toLowerCase());
      })
      .map((course) => {
        const alreadySelected = this._checkAlreadySelected(
          course.enrollments
            .filter((e) => e.status === "active")
            .map((e) => e.user)
        );
        const enrollmentCount = course.enrollments.filter(
          (e) => e.status === "active"
        ).length;
        return (
          <div
            key={course.id}
            className={`class-row ${
              alreadySelected || enrollmentCount === 0 ? "" : "clickable"
            }`}
            onClick={() =>
              this._addStudentArray(
                this._pullStudentsFromCourse(course, "active")
              )
            }
          >
            <div
              className='class-icon'
              style={{
                backgroundImage: `url(${
                  course.photo
                    ? course.photo.file_url
                    : course.lesson_plan && course.lesson_plan.photo
                    ? course.lesson_plan.photo.file_url
                    : placeholderIcon
                })`,
              }}
            />
            <div className='name-area'>
              <p className='name'>{course.name}</p>
              <p className='meeting-times'>
                {course.meeting_times}{" "}
                <span className='#ff6633'>{`${enrollmentCount} student${
                  enrollmentCount === 1 ? "" : "s"
                }`}</span>
              </p>
            </div>
            {alreadySelected ? (
              <i className='fa fa-check-circle' aria-hidden='true' />
            ) : (
              <i className='fa fa-plus' aria-hidden='true' />
            )}
          </div>
        );
      });
  };

  _renderCompletedClasses = (filter) => {
    const { completedCourseLoader, communityCompletedCourses } = this.state;
    if (completedCourseLoader) {
      return (
        <div className='d-flex flex-column align-items-center justify-content-center pt-3'>
          <i className='fa fa-spinner fa-spin' aria-hidden='true' />
          <p className='mt-3'>Loading Completed Classes</p>
        </div>
      );
    }
    if (communityCompletedCourses.length < 1) {
      return (
        <div className='d-flex justify-content-center mt-3'>
          <p>looks like you don't have any completed classes yet</p>
        </div>
      );
    }
    return communityCompletedCourses
      .filter((c) => {
        if (!filter || filter.split("").every((char) => char === " "))
          return true;
        return c.name.toLowerCase().includes(filter.trim().toLowerCase());
      })
      .map((course) => {
        const alreadySelected = this._checkAlreadySelected(
          course.enrollments
            .filter((e) => e.status === "completed")
            .map((e) => e.user)
        );
        const enrollmentCount = course.enrollments.filter(
          (e) => e.status === "completed"
        ).length;
        return (
          <div
            key={course.id}
            className={`class-row ${
              alreadySelected || enrollmentCount === 0 ? "" : "clickable"
            }`}
            onClick={() =>
              this._addStudentArray(
                this._pullStudentsFromCourse(course, "completed")
              )
            }
          >
            <div
              className='class-icon'
              style={{
                backgroundImage: `url(${
                  course.photo
                    ? course.photo.file_url
                    : course.lesson_plan && course.lesson_plan.photo
                    ? course.lesson_plan.photo.file_url
                    : placeholderIcon
                })`,
              }}
            />
            <div className='name-area'>
              <p className='name'>
                {course.name}{" "}
                <span className='completed-range'>{`(${moment(
                  course.start_date
                ).format("L")} - ${moment(course.end_date).format(
                  "L"
                )})`}</span>
              </p>
              <p className='meeting-times'>
                {course.meeting_times}{" "}
                <span className='#ff6633'>{`${enrollmentCount} student${
                  enrollmentCount === 1 ? "" : "s"
                }`}</span>
              </p>
            </div>
            {alreadySelected ? (
              <i className='fa fa-check-circle' aria-hidden='true' />
            ) : (
              <i className='fa fa-plus' aria-hidden='true' />
            )}
          </div>
        );
      });
  };

  _renderFamilies = (filter) => {
    const { familyLoader, families } = this.state;
    if (familyLoader) {
      return (
        <div className='d-flex flex-column align-items-center justify-content-center pt-3'>
          <i className='fa fa-spinner fa-spin' aria-hidden='true' />
          <p className='mt-3'>Loading Families</p>
        </div>
      );
    }
    if (families.lentgh < 1) {
      return (
        <div className='d-flex justify-content-center mt-3'>
          <p>We can't find any families in your community</p>
        </div>
      );
    }
    return families
      .filter((f) => {
        if (!filter || filter.split("").every((char) => char === " "))
          return true;
        return f.name.toLowerCase().includes(filter.trim().toLowerCase());
      })
      .map((family) => {
        const studentCount = family.users.filter((u) => u.is_golfer).length;
        const alreadySelected = this._checkAlreadySelected(
          family.users.filter((u) => u.is_golfer)
        );
        return (
          <div
            key={family.id}
            className={`family-row1 ${
              alreadySelected || studentCount === 0 ? "" : "clickable"
            }`}
            onClick={() =>
              this._addStudentArray(this._pullStudentsFromFamily(family))
            }
          >
            <i className='fa fa-users' aria-hidden='true' />
            <div className='name-area'>
              <p className='name'>{family.name}</p>
              <p className='student-count'>{`${studentCount} student${
                studentCount === 1 ? "" : "s"
              }`}</p>
            </div>
            {alreadySelected ? (
              <i className='fa fa-check-circle' aria-hidden='true' />
            ) : (
              <i className='fa fa-plus' aria-hidden='true' />
            )}
          </div>
        );
      });
  };

  _renderStudents = (filter) => {
    const { temporarySelectedStudents, totalStudents, studentLoader } =
      this.state;
    if (studentLoader) {
      return (
        <div className='d-flex flex-column align-items-center justify-content-center pt-3'>
          <i className='fa fa-spinner fa-spin' aria-hidden='true' />
          <p className='mt-3'>Loading Students</p>
        </div>
      );
    }
    if (totalStudents.length < 1) {
      return (
        <div className='d-flex justify-content-center mt-3'>
          <p>You'll need students in your community to use this report</p>
        </div>
      );
    }
    return totalStudents
      .filter((s) => {
        if (!filter || filter.split("").every((char) => char === " "))
          return true;
        return (
          s.first_name.toLowerCase().includes(filter.trim().toLowerCase()) ||
          s.last_name.toLowerCase().includes(filter.trim().toLowerCase())
        );
      })
      .map((student) => {
        const alreadySelected = temporarySelectedStudents
          .map((s) => s.id)
          .includes(student.id);
        return (
          <div
            key={student.id}
            className={`student-row ${alreadySelected ? "" : "clickable"}`}
            onClick={() => this._addStudent(this._formatStudentData(student))}
          >
            <div
              className='avatar'
              style={{
                backgroundImage: `url(${
                  student.avatar ? student.avatar.file_url : defaultProfilePic
                })`,
              }}
            />
            <div className='name-area'>
              <p className='name'>{`${student.first_name} ${student.last_name}`}</p>
              <p className='login'>{student.email || student.username}</p>
            </div>
            {alreadySelected ? (
              <i className='fa fa-check-circle' aria-hidden='true' />
            ) : (
              <i className='fa fa-plus' aria-hidden='true' />
            )}
          </div>
        );
      });
  };

  _removeStudentFromTemporary = (student_id) => {
    this.setState((prevState) => {
      return {
        temporarySelectedStudents: prevState.temporarySelectedStudents.filter(
          (s) => s.id !== student_id
        ),
      };
    });
  };

  _closeSavedStudentModal = () => {
    const { temporarySelectedStudents, selectedStudents, report } = this.state;
    let obj = { selectedStudents: [...temporarySelectedStudents] };
    if (
      report &&
      this._checkNotSame(temporarySelectedStudents, selectedStudents)
    ) {
      obj["report"] = null;
    }
    this.setState(obj, () => {
      this.setState({ studentModal: false });
    });
  };

  _checkNotSame = (one, two) => {
    // checks if arrays hold the same students
    if (one.length !== two.length) return true;
    let sameValues = one.every((val) => {
      return two.map((v) => v.id).includes(val.id);
    });
    return !sameValues;
  };

  _pullStudentsFromFamily = (family) => {
    return family.users
      .filter((user) => user.is_golfer)
      .map((user) => this._formatStudentData(user));
  };

  _pullStudentsFromCourse = (course, status) => {
    return course.enrollments
      .filter((enrollment) => enrollment.status === status)
      .map((enrollment) => this._formatStudentData(enrollment.user));
  };

  _formatStudentData = (student) => {
    return {
      id: student.id,
      name: `${student.first_name} ${student.last_name}`,
      username: student.username || student.email,
      avatar: student.avatar,
      note: "",
    };
  };

  _addStudent = (student) => {
    this.setState((prevState) => {
      if (
        prevState.temporarySelectedStudents
          .map((s) => s.id)
          .includes(student.id)
      ) {
        return prevState;
      }
      return {
        temporarySelectedStudents: [
          ...prevState.temporarySelectedStudents,
          student,
        ],
      };
    });
  };

  _addStudentArray = (students) => {
    this.setState((prevState) => {
      let studentsToAdd = students.filter(
        (s1) =>
          !prevState.temporarySelectedStudents
            .map((s2) => s2.id)
            .includes(s1.id)
      );
      return {
        temporarySelectedStudents: [
          ...prevState.temporarySelectedStudents,
          ...studentsToAdd,
        ],
      };
    });
  };

  _removeStudentFromSelected = (student_id) => {
    this.setState((prevState) => {
      let newList = prevState.selectedStudents.filter(
        (s) => s.id !== student_id
      );
      return {
        selectedStudents: [...newList],
        temporarySelectedStudents: [...newList],
      };
    });
  };

  _removeStudentFromTemporary = (student_id) => {
    this.setState((prevState) => {
      return {
        temporarySelectedStudents: prevState.temporarySelectedStudents.filter(
          (s) => s.id !== student_id
        ),
      };
    });
  };
}
