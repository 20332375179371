import * as types from "../actions/actionTypes";

export default function adminTags(state = [], action) {
  switch (action.type) {
    case types.GET_ALL_ADMIN_TAGS:
      return Object.assign({}, state, { admin_tags: action.admin_tags });

    case types.GET_USER_ADMIN_TAGS:
      return Object.assign({}, state, { user_admin_tags: action.admin_tags });

    case types.GET_COMMUNITY_ADMIN_TAGS:
      return Object.assign({}, state, {
        community_admin_tags: action.admin_tags,
      });

    case types.REMOVE_USER_ADMIN_TAG:
      let filteredTags = state.user_admin_tags.filter(
        (tag) => tag.id !== action.admin_tag_id
      );
      return Object.assign({}, state, { user_admin_tags: filteredTags });

    case types.REMOVE_COMMUNITY_ADMIN_TAG:
      let filteredCommunityTags = state.community_admin_tags.filter(
        (tag) => tag.id !== action.admin_tag_id
      );
      return Object.assign({}, state, {
        community_admin_tags: filteredCommunityTags,
      });

    case types.ADD_USER_ADMIN_TAG:
      let selectedTag = state.admin_tags.find(
        (t) => t.id === parseInt(action.admin_tag_id)
      );
      let userAdminTagCopy = [...state.user_admin_tags];
      userAdminTagCopy.push(selectedTag);
      return Object.assign({}, state, { user_admin_tags: userAdminTagCopy });

    case types.ADD_COMMUNITY_ADMIN_TAG:
      let selectedCommunityTag = state.admin_tags.find(
        (t) => t.id === parseInt(action.admin_tag_id)
      );
      let communityAdminTagCopy = [...state.community_admin_tags];
      communityAdminTagCopy.push(selectedCommunityTag);
      return Object.assign({}, state, {
        community_admin_tags: communityAdminTagCopy,
      });

    case types.CREATE_NEW_ADMIN_TAG:
      return Object.assign({}, state, {
        admin_tags: [action.admin_tag, ...state.admin_tags],
      });

    case types.UPDATE_ADMIN_TAG:
      let adminTagsCopy = state.admin_tags.map((t) => {
        if (t.id === action.admin_tag.id) {
          return action.admin_tag;
        } else {
          return t;
        }
      });
      return Object.assign({}, state, { admin_tags: adminTagsCopy });

    case types.REMOVE_ADMIN_TAG:
      let adminTagsCopy1 = state.admin_tags.filter((t) => {
        return t.id !== action.admin_tag.id;
      });
      let filteredUserTags =
        state.user_admin_tags &&
        state.user_admin_tags.filter((tag) => tag.id !== action.admin_tag.id);
      let filteredCommunityTags1 =
        state.community_admin_tags &&
        state.community_admin_tags.filter(
          (tag) => tag.id !== action.admin_tag.id
        );
      return Object.assign({}, state, {
        admin_tags: adminTagsCopy1,
        ...(filteredUserTags && { user_admin_tags: filteredUserTags }),
        ...(filteredCommunityTags1 && {
          community_admin_tags: filteredCommunityTags1,
        }),
      });

    default:
      return state;
  }
}
