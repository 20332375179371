import PropTypes from 'prop-types'
import './SearchInput.scss'

const SearchInput = ({ placeholder, onChange, value }) => (
  <div className='search-input-container'>
    <i className='fa fa-search search-icon' />
    <input
      className='search-input'
      onChange={onChange}
      value={value}
      style={{
        borderRadius: '5px',
        paddingLeft: '10px',
        paddingRight: '10px',
      }}
      placeholder={placeholder}
    />
  </div>
)

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
}

export default SearchInput
