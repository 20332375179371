import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";
import Axios from "../../../../axios";

// For multiple choice questions (radio button or checkbox)
import MCElement from "../../../common/MCElement";

// For phone input
import ReactTelephoneInput from "react-telephone-input";
import flags from "../../../../assets/images/flags.png";

// Would be nice to load these lazily
import {
  isValidEmail,
  validatePhoneForTwilio,
} from "../../../../utility/helperFunctions";

// For 'sign_up_reasons'
import flagIcon from "../../../../assets/images/activity-icons/play-open.png";
import videoIcon from "../../../../assets/images/registration-coach/video-icon.png";

import "./QuestionStep.scss";

const PROG_BAR_LENGTH = 236; // 236px long
const MAX_CODE_LENGTH = 6; // Max number of chars in verification_code
const MIN_PW_LENGTH = 6; // Min chars in password

/**
  Represents a coach registration (not onboarding) step question.
  Onboarding questions will be in a different file so this file is more readable.
*/
class QuestionStep extends Component {
  state = {
    buttonDisabled: true,

    // For input field questions only
    errors: [], // Stores the error to display under each input field
    changed: [], // To track if input has been changed from initial empty state
  };

  componentDidMount() {
    this._initState();
  }

  // Need to reset local state when question changes
  componentDidUpdate(prevProps) {
    if (this.props.question.id !== prevProps.question.id) {
      this._initState();
    }
  }

  render() {
    const {
      id,
      question,
      desc,
      type,
      ans_choices,
      multi_select,
      input_fields,
      errorMsgs,
    } = this.props.question;

    // For the percentage bar and filling in/selecting elements
    const {
      percentage,
      answers,
      backButtonHandle,
      questionTransition,
      animDuration,
    } = this.props;

    // Render interactive elements of questions
    let elem;
    switch (type) {
      case "input":
        elem = this._renderInput(input_fields, answers, id, errorMsgs);
        break;
      case "multiple_choice":
        elem = this._renderMultipleChoice(
          ans_choices,
          multi_select,
          answers,
          id
        );
        break;
      default:
        elem = null;
    }

    return (
      <div className='QuestionStep'>
        <div className='question-step-header'>
          {id !== "password" && (
            <i
              className='fa fa-arrow-left question-step-header-arrow'
              aria-hidden='true'
              onClick={() => backButtonHandle()}
            />
          )}
          <div
            className='question-step-header-bar'
            style={{ width: PROG_BAR_LENGTH }}
          >
            <div
              className='question-step-header-bar-fill'
              style={{ width: percentage * PROG_BAR_LENGTH + "px" }}
            />
          </div>
        </div>
        <CSSTransition
          key={id}
          in={questionTransition}
          timeout={animDuration}
          classNames='regStep'
        >
          <div style={{ width: "100%" }}>
            <h3 className='question-step-question'>{question}</h3>
            {desc && <p className='question-step-desc'>{desc}</p>}
            {elem}
            {this._renderButtons(id, answers, type)}
          </div>
        </CSSTransition>
      </div>
    );
  }

  /**
    Resets the arrays in local state when the question changes.
  */
  _initState = () => {
    const { id, type, input_fields, errorMsgs } = this.props.question;
    const { answers } = this.props;

    // Initialize errors and changed array if input question type
    let errors = [];
    let changed = [];
    if (type === "input") {
      for (let i = 0; i < input_fields.length; i++) {
        errors[i] = null;
        // changed[i] = false;
        changed[i] = answers[i] ? true : false;
      }
      this.setState({ errors, changed }, () => {
        this._checkInputValidity(id, answers, errorMsgs);
      });
    }
  };

  /**
    Renders text input fields.
  */
  _renderInput = (fields, values, questionId, errorMsgs) => {
    const { errors } = this.state;

    // Verification code has max length
    const maxChars = questionId !== "verification_code" ? "" : MAX_CODE_LENGTH;

    return fields.map((f, idx) => {
      const errorClass =
        errors[idx] && errors[idx] ? " question-step-input-field-error" : "";

      let inputField = (
        <input
          className={"question-step-input-field" + errorClass}
          type={f.type}
          placeholder={f.placeholder}
          value={values[idx] || ""}
          maxLength={maxChars}
          onChange={(event) =>
            this._handleInputChange(
              event.target.value,
              idx,
              values,
              questionId,
              errorMsgs
            )
          }
        />
      );
      let errorMsgMargin = "0px";

      // Check if special input needs to be used because this is 'phone' question
      if (questionId === "phone") {
        inputField = (
          <ReactTelephoneInput
            defaultCountry='us'
            flagsImagePath={flags}
            onChange={(input) =>
              this._handleInputChange(input, idx, values, questionId, errorMsgs)
            }
            value={values[idx] || ""}
            classNames={"question-step-phone-input-container"}
            inputProps={{
              className: "question-step-input-field" + errorClass,
              id: "question-step-phone-input-field",
            }}
            listItemClassName={"question-step-phone-input-dropdown-item"}
          />
        );
        errorMsgMargin = "64px";
      }

      return (
        <div key={f.placeholder.split(" ").join("-")}>
          {inputField}
          <p
            className='question-step-input-error-msg'
            style={{ marginLeft: errorMsgMargin }}
          >
            {errors[idx] && errors[idx]}
          </p>
        </div>
      );
    });
  };

  /**
    Change the appropriate array index in the values array and
    send it back to CoachRegistrationFlow to save in state.
  */
  _handleInputChange = (input, idx, values, questionId, errorMsgs) => {
    // Check if verification_code - only digits are allowed in the code,
    // so don't update userInputCopy[idx] if non-digit value is entered
    if (
      questionId === "verification_code" &&
      !/\d/.test(input) &&
      input !== ""
    ) {
      return;
    }

    // Mark the input field as changed so we can show errors if needed
    if (!this.state.changed[idx]) {
      let changedCopy = this.state.changed;
      changedCopy[idx] = true;
      this.setState({ changed: changedCopy });
    }

    let userInputCopy = [...values];
    userInputCopy[idx] = input;
    this.props.changeHandle(questionId, userInputCopy);

    // Check validity as user types
    this._checkInputValidity(questionId, userInputCopy, errorMsgs);
  };

  /**
    Checks if input in input fields is valid and shows an error message if not.
    The index of the error indicates which field in the question the error
    will appear under, while the index of the errorMsgs indicates which error
    message from registrationQuestions.js will appear.
  */
  // eslint-disable-next-line complexity
  _checkInputValidity = (questionId, answers, errorMsgs) => {
    // The index of the error indicates which field in the question the error
    // will appear under
    // The index of the errorMsgs indicates which error message from
    // console.log('checking input validity');
    const { changed } = this.state;
    let errors = [];
    let regex = /[^\s]/; // Test if it contains non-whitespace chars
    // console.log(questionId);
    switch (questionId) {
      case "first_last_name":
        // Check if first name is invalid
        if (changed[0]) {
          errors[0] = regex.test(answers[0]) ? null : errorMsgs[0];
        }
        // Check if last name is invalid
        if (changed[1]) {
          errors[1] = regex.test(answers[1]) ? null : errorMsgs[1];
        }
        break;
      case "email":
        if (changed[0]) {
          // Check if email is invalid
          // errors[0] = validateEmail(answers[0]) ? null : errorMsgs[0];

          // Not sure this if this best practice, but we don't have another
          // endpoint that checks for unique email
          if (!isValidEmail(answers[0])) {
            // Check if email is invalid
            errors[0] = errorMsgs[0];
          } else {
            // Then check if email is taken (but only after they finish typing)
            Axios.get(
              `/api/v1/users/test_credentials?login=${encodeURIComponent(
                answers[0]
              )}`
            ).then((res) => {
              if (res.data.in_use) {
                errors[0] = errorMsgs[1];
                this.setState({ errors, buttonDisabled: true });
              } else {
                errors[0] = null;
                this.setState({ errors, buttonDisabled: false });
              }
            });
          }
        }
        break;
      case "phone":
        // Check if phone is invalid
        if (changed[0]) {
          // Convert phone input to only digits and + sign before validation
          const re = /[\+0-9]/g;
          const num = answers[0].match(re).join("");
          errors[0] = validatePhoneForTwilio(num) ? null : errorMsgs[0];
        }
        break;
      case "verification_code":
        if (changed[0]) {
          // Check if code is entered and 6 digits (neither empty nor whitespace)
          regex = new RegExp("\\d{" + MAX_CODE_LENGTH + "}");
          errors[0] = regex.test(answers[0]) ? null : errorMsgs[0];
        }
        break;
      case "password":
        if (changed[0]) {
          // Password field
          if (!regex.test(answers[0])) {
            // Check if password field is empty (neither empty nor whitespace)
            errors[0] = errorMsgs[0];
          } else if (answers[0] && answers[0].length < MIN_PW_LENGTH) {
            // Check if password is at least 6 characters
            errors[0] = errorMsgs[1];
          } else {
            errors[0] = null;
          }

          if (changed[1]) {
            // Confirm password field
            // Check if passwords match
            if (answers[1] && answers[0] !== answers[1]) {
              errors[1] = errorMsgs[2];
            } else {
              errors[1] = null;
            }
          }
        }
        break;
      default:
        return;
    }

    // If all the fields are filled out and if all the errors are null
    // (there are no errors), the button is enabled
    // Make sure no undefined or empty answers
    const allAnswersEntered =
      answers.length === errors.length &&
      answers.every((ans) => {
        // console.log(regex.test(ans));
        return ans && regex.test(ans);
      });
    const noErrors = errors.every((elem) => elem === null);
    if (allAnswersEntered && noErrors) {
      this.setState({ errors, buttonDisabled: false });
    } else {
      this.setState({ errors, buttonDisabled: true });
    }

    // this.setState({ errors });
  };

  /**
    Renders multiple choice answer choices.
  */
  _renderMultipleChoice = (choices, isMultiSelect, selectedVal, questionId) => {
    const elemType = isMultiSelect ? "checkbox" : "radio";

    // Is this a question with special styling (e.g. 'sign_up_reasons')?
    let items;
    if (questionId !== "sign_up_reasons") {
      items = choices.map((c, idx) => {
        // Check selectedVal depending on if it's multiselect (array) or not (string)
        return (
          <div
            key={c + idx}
            className='question-step-multiple-choice'
            onClick={(event) =>
              this._handleMultipleChoiceClick(
                event,
                isMultiSelect,
                idx,
                selectedVal,
                questionId
              )
            }
          >
            <MCElement type={elemType} isSelected={selectedVal[idx]} />
            {c}
          </div>
        );
      });
    } else {
      const btnImg = [flagIcon, videoIcon, null];
      const imgAlt = ["Flag", "Video"];
      const itemsInner = choices.map((c, idx) => (
        <div
          key={c + idx}
          className='question-step-multiple-choice-special btn-rectangular btn-rectangular-secondary-positive'
          onClick={(event) =>
            this._handleMultipleChoiceClick(
              event,
              isMultiSelect,
              idx,
              selectedVal,
              questionId
            )
          }
        >
          {btnImg[idx] && (
            <img
              src={btnImg[idx]}
              alt={imgAlt[idx]}
              className='question-step-multiple-choice-special-img'
            />
          )}
          {c}
        </div>
      ));

      // Need extra div for flexbox (so top items are 1 column each and bottom
      // is 2 columns)
      items = (
        <div className='question-step-multiple-choice-special-container'>
          {itemsInner}
        </div>
      );
    }

    return items;
  };

  /**
    Change the values array and send it back to CoachRegistrationFlow
    to save in state.
  */
  _handleMultipleChoiceClick = (
    event,
    isMultiSelect,
    idx,
    selectedVal,
    questionId
  ) => {
    event.stopPropagation();
    let userInput = [...selectedVal];

    // Check if multiple items are allowed to be selected
    if (isMultiSelect) {
      userInput[idx] = !userInput[idx]; // De/select (toggle to opposite state)
    } else {
      // Change value to selected value
      userInput = userInput.map((c, i) => (userInput[i] = false));
      userInput[idx] = true;
    }

    this.props.changeHandle(questionId, userInput);

    // If at least one item is selected, enable 'Next' button
    // Edit: No button for job_title question
    // this.setState({ buttonDisabled: !userInput.includes(true) });

    // this.props.nextButtonHandle();
  };

  /**
    Renders buttons underneath the question inputs to progress the flow.
  */
  _renderButtons = (id, answers, type) => {
    if (type === "multiple_choice") {
      // Don't render button for this question - just clicking "yes" or "no"
      // should take them to the next screen
      return;
    }

    let btnText = "Next";
    switch (id) {
      case "phone":
        btnText = "Send code";
        break;
      case "verification_code":
        btnText = "Verify code";
        break;
      case "password":
        btnText = "Create account";
        break;
      default:
        btnText = "Next";
    }

    // Default button
    let btn = (
      <button
        className='btn-rectangular btn-rectangular-main-positive question-step-button'
        disabled={this.state.buttonDisabled}
        onClick={() => this.props.nextButtonHandle()}
      >
        {btnText}
      </button>
    );

    // If this is verification_code question, need multiple buttons
    if (id !== "verification_code") {
      return btn;
    } else {
      // Add buttons to resend code and enter different phone number
      return (
        <div className='question-step-button-group'>
          {btn}
          <button
            className='btn-rectangular btn-rectangular-main-negative'
            onClick={() => this.props.resendCodeHandle()}
          >
            Resend code
          </button>
          <button
            className='btn-rectangular btn-rectangular-main-negative'
            onClick={() => this.props.backButtonHandle()}
          >
            Enter different phone number
          </button>
        </div>
      );
    }
  };
}

export default QuestionStep;
