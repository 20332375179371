import * as constants from "./actionTypes";
import Axios from '../axios'

export function successRequestingGetRepresentatives(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_REPRESENTATIVES,
      representatives: response.data.representatives
    };
  } else {
    console.log("ERROR GETTING REPRESENTATIVES");
    return { type: constants.UI_ERROR, error: response.data };
  }
}

// THUNKS HERE
export function getRepresentatives(searchTerm) {
  return function(dispatch) {
    return requestGetRepresentatives(searchTerm).then(response =>
      dispatch(successRequestingGetRepresentatives(response))
    );
  };
}

// API CALLS
function requestGetRepresentatives(searchTerm) {
  let requestUrl = "/api/v1/representatives";

  if (searchTerm) requestUrl += `?search=${searchTerm}`;
  return Axios.get(requestUrl);
}
