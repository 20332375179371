import * as types from "../actions/actionTypes";

export default function keyMetrics(state = {}, action) {
  switch (action.type) {
    case types.GET_COMMUNITY_KEY_METRICS:
      return action.key_metrics;

    case types.CLEAR_COMMUNITY_KEY_METRICS:
      return null;

    default:
      return state;
  }
}
