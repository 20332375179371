import React, { Component } from "react";
import { Breadcrumbs, BreadcrumbItem } from "../../common/Breadcrumbs";
import { toast } from "react-toastify";
import PropTypes from 'prop-types'
import SearchInput from '../../common/SearchInput'

// CHILDREN
import PageHeader from '../../common/PageHeader/PageHeader'
import Loader from "../../common/Op36Loader-web";
import Placeholder from "../../common/Placeholder";
import ResourceCategoryCard from "../ResourceCategories/ResourceCategoryCard";
import ResourceSectionEditModal from "./Modals/ResourceSectionEditModal";
import ResourceRow from "../ResourceRow";
import ReorganizeResourceModal from "../Modals/ReorganizeResourceModal";

// ASSETS
import "./ResourceSectionDetailPage.scss";

// REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as commonActions from "../../../actions/commonActions";
import * as userActions from "../../../actions/userActions";
import * as resourceActions from "../../../actions/resourceActions";
import * as resourceSectionActions from "../../../actions/resourceSectionActions";
import * as resourceCategoryActions from "../../../actions/resourceCategoryActions";
import { withRouting } from "../../common/hooks";

class ResourceSectionDetailPage extends Component {
  state = {
    resourceSectionLoader: false,
    showEditModal: false,
    resource_section_name: "",
    resource_section_description: "",
    resource_section_uploadPhotoName: "",
    resource_section_uploadPhotoFile: "",
    resource_section_uploadPhotoSrc: "",
    resource_section_id: -1,
    searchTerm: "",
    reorderResourceModal: null,
  };

  componentDidMount() {
    this.props.common_actions.showSideBar(true);
    this.props.common_actions.showNavBar(true);
    this.fetchResourceSection();
  }

  fetchResourceSection = () => {
    const { resource_section_actions } = this.props;

    let resourceSectionId =
      this.props.location.state && this.props.location.state.resourceSectionId
        ? this.props.location.state.resourceSectionId
        : this.props.params.resourceSectionId;

    this.setState({ resourceSectionLoader: true }, () => {
      resource_section_actions
        .getResourceSection(resourceSectionId)
        .then(() => {
          this.setState({ resourceSectionLoader: false });
        });
    });
  };

  renderResources = () => {
    const { resourceLoader } = this.state;
    const { resources } = this.props;

    if (resourceLoader) {
      return <Loader message='loading resources' />;
    }

    if (resources && resources.length > 0) {
      return resources.map((resource) => (
        <ResourceRow resource={resource} key={resource.id} />
      ));
    } else {
      return (
        <Placeholder
          title={"No Resources Found"}
          icon={"fa-search"}
          subtext1={
            "We couldn't find any resources that matched the search criteria"
          }
          subtext2={"Please Try Again"}
        />
      );
    }
  };

  searchUpdated = (e) => {
    const term = e.target.value
    const { resource_actions } = this.props;
    this.setState({ searchTerm: term }, () => {
      this.setState({ resourceLoader: true }, () => {
        if (this.state.searchTerm !== "") {
          resource_actions.searchResources(this.state.searchTerm).then(() => {
            this.setState({
              resourceLoader: false,
            });
          });
        } else {
          this.setState({
            resourceLoader: false,
          });
        }
      });
    });
  };

  updateCategories = (categories) => {
    const { resource_section_actions } = this.props;
    this.setState(
      {
        resourceSectionLoader: true,
      },
      () => {
        const { resource_section_id } = this.state;
        let resourceSection = {
          id: resource_section_id,
        };

        resource_section_actions
          .updateResourceSection(resourceSection, categories)
          .then(() => {
            this.fetchResourceSection();
            toast.success("Success. Updated Category!", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          });
      }
    );
  };

  updateResourceSection = () => {
    const { resource_section_actions } = this.props;
    this.setState(
      {
        resourceSectionLoader: true,
      },
      () => {
        const {
          resource_section_id,
          resource_section_name,
          resource_section_description,
          resource_section_uploadPhotoFile,
        } = this.state;
        let resourceSection = {
          id: resource_section_id,
          name: resource_section_name,
          description: resource_section_description,
          resource_section_photo: resource_section_uploadPhotoFile,
        };

        resource_section_actions
          .updateResourceSection(resourceSection)
          .then(() => {
            this.fetchResourceSection();
            this.closeEditModal();
            toast.success("Success. Updated Resource Section!", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          });
      }
    );
  };

  resetSectionForm = () => {
    this.setState({
      name: "",
      description: "",
      resource_section_uploadPhotoName: "",
      resource_section_uploadPhotoFile: "",
      resource_section_uploadPhotoSrc: "",
      resource_section_id: -1,
    });
  };

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  grabSectionPhotoData = (photoData) => {
    const { uploadPhotoFile, uploadPhotoName, uploadPhotoSrc } = photoData;
    this.setState({
      resource_section_uploadPhotoName: uploadPhotoName,
      resource_section_uploadPhotoFile: uploadPhotoFile,
      resource_section_uploadPhotoSrc: uploadPhotoSrc,
    });
  };

  toggleEditModal = () => {
    const { resource_section } = this.props;
    this.setState({
      showEditModal: true,
      resource_section_name: resource_section.name,
      resource_section_description: resource_section.description,
      resource_section_uploadPhotoSrc: resource_section.photo
        ? resource_section.photo.file_url
        : "",
      resource_section_id: resource_section.id,
    });
  };

  closeEditModal = () => {
    this.setState(
      {
        showEditModal: false,
      },
      () => {
        this.resetSectionForm();
      }
    );
  };

  renderResourceCategories = () => {
    const { resource_section, navigate, current_user } = this.props;
    const { resourceSectionLoader } = this.state;

    if (resourceSectionLoader) {
      return <Loader message='loading resource section' />;
    }
    if (!this.state.searchTerm) {
      if (
        resource_section &&
        resource_section.resource_categories &&
        resource_section.resource_categories.length > 0
      ) {
        return resource_section.resource_categories.map((category) => {
          if (category.resources && category.resources.length > 0) {
            return (
              <ResourceCategoryCard
                category={category}
                key={category.id}
                triggerReorderModal={
                  current_user.type === "Admin"
                    ? this._triggerReorderModal
                    : null
                }
              />
            );
          }
          return null;
        });
      } else {
        return (
          <div className='col-md-6 mx-auto mt-5'>
            <Placeholder
              title={"No categories are available for this section"}
              icon={"fa-folder"}
              subtext1={"There has been no content created for this section"}
              subtext2={
                "Click the button below to return to the Resource Center"
              }
              buttonText={"Resource Center"}
              buttonIcon={"fa-arrow-left"}
              handleClick={() => navigate('/resource-sections')}
            />
          </div>
        );
      }
    } else {
      return (
        <div className='col-md-6 mx-auto resource-search-container'>
          <h3 className='mb-4'>Search Results</h3>
          {this.renderResources()}
        </div>
      );
    }
  };

  headerActionButton = () => (
    <button
      className='btn btn-primary text-white pull-right mr-2'
      onClick={this.toggleEditModal}
    >
      Edit Section
    </button>
  )

  render() {
    const { current_user, resource_section } = this.props;
    const { showEditModal, resourceSectionLoader, reorderResourceModal } =
      this.state;

    return (
      <div id='ResourceSectionDetailPage' className='animated fadeIn'>
        <PageHeader
          title='Resource Center'
          actionComponent={current_user.type === 'Admin' && this.headerActionButton()}
        />
        <div className='resource-section-container'>
          <Breadcrumbs>
            <BreadcrumbItem name='Resource Center' route='/resource-sections' />
            {resource_section && (
              <BreadcrumbItem
                name={resource_section.name}
                route={`/resource-sections/${resource_section.id}`}
                disabled
              />
            )}
          </Breadcrumbs>
          <div className='resource-search-bar mt-2'>
            <SearchInput placeholder='Search Resource Center' onChange={this.searchUpdated}/>
          </div>
          <div className='resource-section-info-wrapper'>
            <h3>{resource_section.name}</h3>
            <p>{resource_section.description}</p>
          </div>
          <div className='row pt-4'>{this.renderResourceCategories()}</div>
        </div>
        {showEditModal && (
          <ResourceSectionEditModal
            resourceSection={resource_section}
            showModal={showEditModal}
            closeModal={this.closeEditModal}
            handleInputChange={this.handleInputChange}
            grabSectionPhotoData={this.grabSectionPhotoData}
            updateResourceSection={this.updateResourceSection}
            loader={resourceSectionLoader}
            formState={this.state}
            updateCategories={this.updateCategories}
            isEditModal
          />
        )}

        {reorderResourceModal && (
          <ReorganizeResourceModal
            showModal={!!reorderResourceModal}
            closeModal={this._closeReorderModal}
            category={resource_section.resource_categories.find(
              (cat) => cat.id === reorderResourceModal
            )}
            updateResources={this._updateResources}
          />
        )}
      </div>
    );
  }

  _updateResources = (resources) => {
    const { reorderResourceModal } = this.state;
    this.props.resource_category_actions.reorderResourcesInCategory(
      reorderResourceModal,
      resources
    );
  };

  _closeReorderModal = () => {
    this._triggerReorderModal(null);
  };

  _triggerReorderModal = (cat_id) => {
    this.setState({ reorderResourceModal: cat_id });
  };
}

ResourceSectionDetailPage.propTypes = {
  current_user: PropTypes.shape({
    type: PropTypes.string,
  }),
}

function mapStateToProps(state, ownProps) {
  return {
    ui: state.ui,
    current_user: state.user.current_user,
    resources: state.resources,
    resource_section: state.resourceSection,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    common_actions: bindActionCreators(commonActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
    resource_actions: bindActionCreators(resourceActions, dispatch),
    resource_section_actions: bindActionCreators(
      resourceSectionActions,
      dispatch
    ),
    resource_category_actions: bindActionCreators(
      resourceCategoryActions,
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouting(ResourceSectionDetailPage))
