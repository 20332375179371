import React from "react";
import { Progress } from "reactstrap";
import moment from "moment";
import "./MilestoneModal.scss";
import LargeMilestoneIcon from "../common/LargeMilestoneIcon";

class MilestoneModal extends React.Component {
  state = {
    divId: this.props.division
  };

  render() {
    const { divId } = this.state;
    let index = this.state.divId - 1;
    let record = this.props.data[index].nine_hole.record_score;
    return (
      <div id='MilestoneModal'>
        <div className='milestone-modal-topbar text-right mr-3'>
          <i className='fa fa-times' onClick={this.props.closeModal} />
        </div>
        <div className='move-left'>
          <i
            className={`fa fa-caret-left ${divId === 1 ? "unclickable" : ""}`}
            onClick={() => this._flipPage(-1)}
          />
        </div>
        <div className='move-right'>
          <i
            className={`fa fa-caret-right ${divId === 10 ? "unclickable" : ""}`}
            onClick={() => this._flipPage(1)}
          />
        </div>
        <div className='milestone-modal-content text-center'>
          <h4>{`Level ${this.state.divId}`}</h4>
          <p>{record ? `Best Score: ${record}` : "No Scores Logged Yet"}</p>
          <div className='badge-container'>
            {this._renderMilestone(this.state.divId, record)}
          </div>
          <div className='small-milestones'>
            {this._renderProgress(record, this.state.divId)}
          </div>
          <p className='subtext'>{`Shoot 36 in Level ${
            this.state.divId
          }`}</p>
          <p className='subtext'>{`(9 holes, ${this._getYardage(
            this.state.divId
          )})!`}</p>
          {this.props.data[index].completed_date ? (
            <p className='milestone-time-stamp'>
              {this._renderTimeStamp(this.props.data[index].completed_date)}
            </p>
          ) : null}
          <div
            className={`small-button-area ${
              this.props.data[index].completed_date ? "is-done" : ""
            }`}
          >
            {this._renderNavButtons()}
          </div>
        </div>
      </div>
    );
  }

  _renderTimeStamp = date => {
    let now = moment();
    let unit = "day";
    let time = now.diff(moment(date), "days");
    if (time !== 1) unit = "days";
    if (time > 30) {
      time = now.diff(moment(date), "weeks");
      unit = "weeks";
      if (time > 20) {
        time = now.diff(moment(date), "months");
        unit = "months";
        if (time > 24) {
          time = now.diff(moment(date), "years");
          unit = "years";
        }
      }
    }

    return `completed ${time} ${unit} ago`;
  };

  _renderNavButtons = () => {
    let first = [1, 2, 3, 4, 5];
    let second = [6, 7, 8, 9, 10];
    return (
      <div>
        <div className='first-row-buttons'>
          {first.map(div => {
            return (
              <div
                key={div}
                className={`small-nav-button ${
                  this.state.divId === div ? "filled" : ""
                }`}
                onClick={() => this._navPage(div)}
              />
            );
          })}
        </div>
        <div className='second-row-buttons'>
          {second.map(div => {
            return (
              <div
                key={div}
                className={`small-nav-button ${
                  this.state.divId === div ? "filled" : ""
                }`}
                onClick={() => this._navPage(div)}
              />
            );
          })}
        </div>
      </div>
    );
  };

  _navPage = div => {
    this.setState({ divId: div });
  };

  _flipPage = num => {
    const { divId } = this.state;
    if (num === -1) {
      if (divId === 1) {
        return false;
      } else {
        this.setState({ divId: divId - 1 });
      }
    } else {
      if (divId === 10) {
        return false;
      } else {
        this.setState({ divId: divId + 1 });
      }
    }
  };

  _getYardage = id => {
    switch (id) {
      case 1:
        return "225 yards";
      case 2:
        return "450 yards";
      case 3:
        return "900 yards";
      case 4:
        return "1350 yards";
      case 5:
        return "1800 yards";
      case 6:
        return "1801 - 2300 yards";
      case 7:
        return "2301 - 2600 yards";
      case 8:
        return "2601 - 2900 yards";
      case 9:
        return "2901 - 3200 yards";
      case 10:
        return "3201 + yards";
      default:
        return "";
    }
  };

  _renderProgress = (record, id) => {
    return (
      <div>
        <Progress
          value={this._getPercentage(record)}
          className='progress-container'
          barClassName={`progress-bar-${id}`}
        />
        <div className='circle-container'>
          <div
            className='fifty'
            style={{
              border: `2px solid ${this._getColor(id, record, 50)}`,
              color: record && record <= 50 ? "" : "#f4f4f5"
            }}
          >
            50
          </div>
          <div
            className='fortyfive'
            style={{
              border: `2px solid ${this._getColor(id, record, 45)}`,
              color: record && record <= 45 ? "" : "#f4f4f5"
            }}
          >
            45
          </div>
          <div
            className='thirtynine'
            style={{
              border: `2px solid ${this._getColor(id, record, 39)}`,
              color: record && record <= 39 ? "" : "#f4f4f5"
            }}
          >
            39
          </div>
          <div
            className='thirtysix'
            style={{
              border: `2px solid ${this._getColor(id, record, 36)}`,
              color: record && record <= 36 ? "" : "#f4f4f5"
            }}
          >
            36
          </div>
        </div>
      </div>
    );
  };

  _getPercentage = record => {
    if (record && record <= 36) {
      return 100;
    } else if (record && record <= 39) {
      return 66;
    } else if (record && record <= 45) {
      return 33;
    } else {
      return 0;
    }
  };

  _getColor = (div, record, target) => {
    if (record && record <= target) {
      switch (div) {
        case 1:
        case 6:
          return '#FF6633'
        case 2:
        case 7:
          return "#FDDB10";
        case 3:
        case 8:
          return "#D5DE27";
        case 4:
        case 9:
          return "#2BAAE1";
        case 5:
        case 10:
          return "#B51E8C";
        default:
          return "#414143";
      }
    } else {
      return "#f4f4f5";
    }
  };

  _renderMilestone = (id, record) => {
    if (record && record <= 36) {
      return <LargeMilestoneIcon division={id} />;
    } else {
      return (
        <LargeMilestoneIcon
          division={id}
          bgColor='#fff'
          color='#f4f4f5'
          isLocked={true}
        />
      );
    }
  };
}

export default MilestoneModal;
