import React, { Component } from "react";
import Loader from "../../common/Op36Loader-web";
import _ from "lodash";

// REGISTRATION STEPS
import Stepper from "./RegistrationStepper";
import FamilyAdminStep from "./Steps/FamilyAdminStep";
// import QuestionStep from "./Steps/QuestionStep";
import CreateGolfersStep from "./Steps/CreateGolfersStep";
import FinishedStep from "./Steps/FinishedStep";

// REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as commonActions from "../../../actions/commonActions";
import * as authActions from "../../../actions/authActions";
import * as communitiesActions from "../../../actions/communitiesActions";
import * as invitationActions from "../../../actions/invitationActions";
import * as familyActions from "../../../actions/familyActions";
import * as userActions from "../../../actions/userActions";

// ASSETS
import "./Registration.scss";
import { withRouting } from "../../common/hooks";

class RegistrationFlow extends Component {
  state = {
    community_id: -1,
    invitation_id: -1,
    communityLoader: true,
    invitationLoader: false,
    completedStep: 0,
    activeStep: 1,
    loader: false,
    loaderMessage: "",
    showGolferForm: false,
    golfer: null,
    shouldUpdateGolfer: false,
  };
  componentWillMount() {
    this.props.common_actions.showSideBar(false);
    this.props.common_actions.showNavBar(false);
    // this.props.auth_actions.signOutUser();
  }

  componentDidMount() {
    const {
      communities_actions,
      params: routeParams,
      family_actions,
      invitation_actions,
      current_user,
    } = this.props;

    if (routeParams.invitationId) {
      this.setState(
        { invitation_id: routeParams.invitationId, invitationLoader: true },
        () => {
          invitation_actions
            .getInvitation(routeParams.invitationId)
            .then(() => {
              this.setState({ invitationLoader: false });
            })
            .catch(() => {
              this.setState({ invitation_id: -1, invitationLoader: false });
            });
        }
      );
    }

    communities_actions.getCommunity(routeParams.communityId).then(() => {
      this.setState({ communityLoader: false });
    });

    // Check query variable for community if already exists
    if (routeParams.communityId) {
      this.setState({ community_id: routeParams.communityId });
    }

    if (current_user) {
      family_actions.getFamily(current_user.family.id);
    }

    if (window.$zopim && window.$zopim.livechat)
      window.$zopim.livechat.button.show();
  }

  componentWillUnmount() {
    this.props.common_actions.showSideBar(true);
    this.props.common_actions.showNavBar(true);
  }

  previousStep = () => {
    const { activeStep, completedStep } = this.state;
    this.setState({
      activeStep: activeStep - 1,
      completedStep: completedStep - 1,
    });
  };

  nextStep = () => {
    const { activeStep, completedStep } = this.state;
    this.setState({
      activeStep: activeStep + 1,
      completedStep: completedStep + 1,
    });
  };

  toggleGolferForm = (golfer, shouldUpdateGolfer) => {
    this.setState({
      showGolferForm: !this.state.showGolferForm,
      activeStep: 3,
      completedStep: 2,
      golfer,
      shouldUpdateGolfer,
    });
  };

  completeSteps = () => {
    this.setState({
      activeStep: 3,
      completedStep: 3,
    });
  };

  signUpFamily = (family) => {
    const { auth_actions, family_actions } = this.props;
    this.setState({ loader: true, loaderMessage: "creating family" });
    auth_actions
      .createAdminUser(family)
      .then((res) => {
        this.setState({ loaderMessage: "signing in" });
        auth_actions.signInUser(res.user.email, family.password).then(() => {
          this.setState({ loaderMessage: "loading family" });
          family_actions.getFamily(res.user.family_id).then(() => {
            this.nextStep();
            this.setState({ loader: false, loaderMessage: "" });
          });
        });
      })
      .catch(() => {
        this.setState({
          loader: false,
        });
      });
  };

  createGolfer = (golfer) => {
    const { family_actions, current_user } = this.props;
    this.setState({ loader: true, loaderMessage: "creating golfer" });
    const {
      first_name,
      last_name,
      username,
      birthdate,
      family_admin,
      password,
      community_id,
      family_id,
      type,
      gender,
    } = golfer;
    let user = {
      first_name,
      last_name,
      username,
      birthdate,
      family_admin,
      password,
      community_id,
      family_id,
      type,
      gender,
      ...(golfer.proxyConsentGiven && {
        proxy_consent_given: JSON.stringify({
          proxy_consent_given_by: current_user.id,
        }),
      }),
    };
    family_actions
      .addFamilyMember(user)
      .then((res) => {
        if (res) {
          family_actions.getFamily(current_user.family.id).then(() => {
            this.setState({
              loader: false,
              loaderMessage: "",
              showGolferForm: false,
            });
          });
        } else {
          this.setState({
            loader: false,
          });
        }
      })
      .catch(() => {
        this.setState({ loader: false, showGolferForm: true });
      });
  };

  updateGolfer = (golfer) => {
    const { user_actions, family_actions, current_user } = this.props;
    this.setState({ loader: true, loaderMessage: "updating golfer" });
    user_actions
      .getUser(golfer.id)
      .then(() => {
        const {
          id,
          first_name,
          last_name,
          username,
          email,
          birthdate,
          family_admin,
          gender,
        } = golfer;
        let user = {};
        if (family_admin) {
          user = {
            id,
            first_name,
            last_name,
            email,
            birthdate,
            gender,
          };
        } else {
          user = {
            id,
            first_name,
            last_name,
            username,
            birthdate,
            gender,
            ...(golfer.proxyConsentGiven && {
              proxy_consent_given: JSON.stringify({
                proxy_consent_given_by: current_user.id,
              }),
            }),
          };
        }
        if (family_admin) {
          user_actions
            .updateUser(user)
            .catch(() => {
              this.setState({ loader: false, showGolferForm: true });
            })
            .then(() => {
              if (_.isEmpty(this.props.error)) {
                family_actions.updateFamily(golfer.family_id, user).then(() => {
                  family_actions.getFamily(current_user.family.id).then(() => {
                    this.setState({
                      loader: false,
                      showGolferForm: false,
                    });
                  });
                });
              } else {
                this.setState({ loader: false, showGolferForm: true });
              }
            });
        } else {
          user_actions.updateUser(user).then(() => {
            if (_.isEmpty(this.props.error)) {
              family_actions.getFamily(current_user.family.id).then(() => {
                this.setState({
                  loader: false,
                  showGolferForm: false,
                });
              });
            } else {
              this.setState({ loader: false, showGolferForm: true });
            }
          });
        }
      })
      .catch(() => {
        this.setState({ loader: false, showGolferForm: true });
      });
  };

  questionNextStep = (success) => {
    const { current_user } = this.props;
    if (!success) {
      this.nextStep();
    } else {
      this.toggleGolferForm(current_user, false);
    }
  };

  renderFlow = () => {
    const { activeStep, showGolferForm, golfer, shouldUpdateGolfer, loader } =
      this.state;
    const { community, family, current_user, invitation, error } =
      this.props;
    switch (activeStep) {
      case 1:
        return (
          <FamilyAdminStep
            nextStep={this.nextStep}
            previousStep={this.previousStep}
            community={community}
            invitation={invitation}
            signUpFamily={this.signUpFamily}
            error={error}
            loader={loader}
            activeStep={activeStep}
          />
        );
      case 2:
        return (
          <FamilyAdminStep
            nextStep={this.nextStep}
            previousStep={this.previousStep}
            community={community}
            invitation={invitation}
            signUpFamily={this.signUpFamily}
            error={error}
            loader={loader}
            activeStep={activeStep}
          />
        );
      case 3:
        return (
          <CreateGolfersStep
            nextStep={this.nextStep}
            previousStep={this.previousStep}
            showGolferForm={showGolferForm}
            golfer={golfer}
            toggleGolferForm={this.toggleGolferForm}
            shouldUpdateGolfer={shouldUpdateGolfer}
            createGolfer={this.createGolfer}
            updateGolfer={this.updateGolfer}
            family={!_.isEmpty(family) && family}
            community={community && community}
            current_user={current_user}
            error={error}
            loader={loader}
          />
        );
      case 4:
        return <FinishedStep community={community} />;
      default:
        return null;
    }
  };

  render() {
    const { community } = this.props;
    const {
      communityLoader,
      invitationLoader,
      activeStep,
      completedStep,
    } = this.state;
    return (
      <div id='RegistrationPage'>
        {communityLoader || invitationLoader ? (
          <div className='registration-loader col-xl-6 col-lg-8 col-md-8 mx-auto my-5 animated fadeIn'>
            <Loader message='loading community registration' />
          </div>
        ) : (
          <div className='card registration-card col-xl-6 col-lg-8 col-md-8 mx-auto my-5 animated fadeIn'>
            <div className='registration-header-wrapper'>
              {community.photo && (
                <div className='round-image-wrapper registration-community-image'>
                  <img
                    className='round-image'
                    src={community.photo.file_url}
                    alt={community.name}
                  />
                </div>
              )}
              <h2 className='registration-community-name'>{community.name}</h2>
              <Stepper activeStep={activeStep} completedStep={completedStep} />
            </div>
            <div className='mt-3 col-md-12 mx-auto px-0'>{this.renderFlow()}</div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    current_user: state.user.current_user,
    community: state.community,
    family: state.family,
    user: state.user.user,
    invitation: state.invitation,
    error: state.ui.error || {},
  };
}

function mapDispatchToProps(dispatch) {
  return {
    common_actions: bindActionCreators(commonActions, dispatch),
    auth_actions: bindActionCreators(authActions, dispatch),
    communities_actions: bindActionCreators(communitiesActions, dispatch),
    invitation_actions: bindActionCreators(invitationActions, dispatch),
    family_actions: bindActionCreators(familyActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouting(RegistrationFlow))
