import React, { Component } from "react";

// ASSETS
import "./AnimatedScrollView.scss";

class AnimatedScrollView extends Component {
  state = {
    views: [],
    selectedViewIndex: 1
  };

  animationTimer = null;

  componentDidMount() {
    this.updateViews();
  }

  componentWillUnmount() {
    if (this.animationTimer) {
      clearInterval(this.animationTimer);
    }
  }

  startAnimation = () => {
    const { views } = this.state;
    let selectedViewIndex = this.state.selectedViewIndex;
    this.animationTimer = setInterval(() => {
      if (views.length === selectedViewIndex) {
        selectedViewIndex = 1;
        this.setState({
          selectedViewIndex
        });
      } else {
        selectedViewIndex++;
        this.setState({
          selectedViewIndex
        });
      }
    }, 8000);
  };

  setView = selectedViewIndex => {
    this.setState({
      selectedViewIndex
    });
  };

  updateViews = () => {
    const { views } = this.props;

    this.setState(
      {
        views
      },
      () => this.startAnimation()
    );
  };

  renderScrollViewtabs = () => {
    const { views, selectedViewIndex } = this.state;

    if (views && views.length > 0) {
      return views.map((view, index) => (
        <button
          className='btn'
          onClick={() => this.setView(index + 1)}
          key={index}
        >
          {selectedViewIndex === index + 1 ? (
            <i className='fa fa-circle' />
          ) : (
            <i className='fa fa-circle-thin' />
          )}
        </button>
      ));
    }
    return null;
  };

  renderActions = (primaryAction, secondaryAction) => {
    return (
      <div className='action-wrapper'>
        {primaryAction && (
          <a
            href={primaryAction.url}
            alt={primaryAction.text}
            target='_blank'
            className='btn-outline--white'
          >
            {primaryAction.text}
          </a>
        )}

        {secondaryAction && (
          <a
            href={secondaryAction.url}
            alt={secondaryAction.text}
            target='_blank'
            className='btn-outline--white'
          >
            {secondaryAction.text}
          </a>
        )}
      </div>
    );
  };

  renderScrollView() {
    const { views, selectedViewIndex } = this.state;

    if (views && views.length > 0) {
      return views.map((view, index) => {
        if (selectedViewIndex === index + 1) {
          return (
            <div
              id='AnimatedView'
              className='animated fadeIn'
              ref={"View" + index}
              key={index}
            >
              <h5>{view.title}</h5>
              <p>{view.subtitle}</p>
              {this.renderActions(view.primaryAction, view.secondaryAction)}
            </div>
          );
        }
        return null;
      });
    }
  }

  render() {
    return (
      <div id='AnimatedScrollView'>
        <div className='animated-scrollview-wrapper'>
          {this.renderScrollView()}
        </div>
        <div className='animated-scrollview-tabs-wrapper'>
          {this.renderScrollViewtabs()}
        </div>
      </div>
    );
  }
}

export default AnimatedScrollView;
