import React, { Component } from "react";

class GoalTask extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isChecked: false
    };
  }

  handleChange = (event, task) => {
    if (task.is_completed || !this.props.current_user.type === "Coach") {
      event.preventDefault();
    } else if (this.props.current_user.type === "Student") {
      event.preventDefault();
    } else {
      this.props.toggleModal(task);
    }
  };

  render() {
    const { task, is_template } = this.props;
    return (
      <div className="goal-task-wrapper" key={task.id}>
        {is_template && <i className="fa fa-minus goal-task-check-active" />}
        {task.is_completed &&
          !is_template && (
            <i
              className="fa fa-check-square-o goal-task-check-active"
              onClick={(event) => this.handleChange(event, task)}
            />
          )}
        {!task.is_completed &&
          !is_template && (
            <i
              className="fa fa-square-o goal-task-check"
              onClick={(event) => this.handleChange(event, task)}
            />
          )}
        <p className="goal-task-text">{task.description}</p>
      </div>
    );
  }
}

export default GoalTask;
