import styles from './CoachDashboardOp36.module.scss'
import PropTypes from 'prop-types'

const AllTimeTotalsItem = ({ icon, title, info }) => (
  <div className={styles.allTimeTotalsItem}>
    {icon}
    <div className={styles.infoWrapper}>
      <div className={styles.info}>{info}</div>
      <div className={styles.itemTitle}>{title}</div>
    </div>
  </div>
)

AllTimeTotalsItem.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string,
  info: PropTypes.number,
}

export default AllTimeTotalsItem
