import React, { Component } from "react";

// REDUX BITS
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as familyActions from "../../../actions/familyActions";
import AlertDialog from "../../common/AlertDialog";
import TextInput from "../../common/form/TextInput";
import ValidationModal from "../../common/ValidationModal";

class FamilyEditForm extends Component {
  constructor(props) {
    super(props);
    let family_admin = this.props.family
      ? this.props.family.users.find(user => user.family_admin)
      : null;
    this.state = {
      first_name: family_admin ? family_admin.first_name : "",
      last_name: family_admin ? family_admin.last_name : "",
      email: family_admin ? family_admin.email : "",
      password: "",
      password_confirmation: "",
      familyLoader: false
    };

    this.resendWelcomeEmail = this.resendWelcomeEmail.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.updateFamily = this.updateFamily.bind(this);
  }

  handleInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
    // this.props.handleInputChange(event);
  };

  resendWelcomeEmail() {
    console.log(
      "Not set up in backend just yet. This method lives in 'FamilyEditForm.jsx' "
    );
  }

  updateFamily(event) {
    event.preventDefault();
    let familyID = this.props.family.id;

    let family = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email
    };
    this.setState({
      familyLoader: true
    });
    this.props.family_actions
      .updateFamily(familyID, family)
      .then(response => {
        this.setState({
          familyLoader: false
        });
        this.props.toggleValidationModal(
          true,
          `You have successfully updated the ${this.state.last_name} family`
        );
        this.props.family_actions.getFamily(familyID).then(res => {
          this.props.toggleEditModal();
        });
      })
      .catch(error => {
        this.props.toggleValidationModal(
          false,
          "Something went wrong, please try to edit the family again"
        );
      });
  }

  handleAddFamily = e => {
    e.preventDefault();
    const { familyLoader, ...formState } = this.state;
    this.props.addFamily(formState)
  }

  render() {
    return (
      <div className="container">
        <form
          className="form-container"
          onSubmit={
            this.props.family ? this.updateFamily : this.handleAddFamily
          }
        >
          <div className="row my-3">
            <div className="col">
              <label>First Name</label>
              <TextInput
                type={"text"}
                label={""}
                name={"first_name"}
                value={this.state.first_name}
                onChange={this.handleInputChange}
                className={"my-2 input-border"}
                placeholder={"First Name"}
              />
            </div>
            <div className="col">
              <label>Last Name</label>
              <TextInput
                type={"text"}
                label={""}
                name={"last_name"}
                value={this.state.last_name}
                onChange={this.handleInputChange}
                className={"my-2 input-border"}
                placeholder={"Last Name"}
              />
            </div>
          </div>

          <div className="row my-3">
            <div className="col">
              <label>Email</label>
              <TextInput
                name={"email"}
                type={"text"}
                label={""}
                value={this.state.email}
                onChange={this.handleInputChange}
                className={`my-2 input-border`}
                error={this.props.error ? this.props.error.email : ""}
                placeholder={"family@gmail.com"}
              />
            </div>
          </div>
          {!this.props.family ? (
            <div className="row my-3">
              <div className="col-6">
                <label>Password</label>
                <TextInput
                  name={"password"}
                  type={"password"}
                  label={""}
                  value={this.state.password}
                  onChange={this.handleInputChange}
                  className={"my-2 input-border"}
                  placeholder={"Password"}
                />
              </div>

              <div className="col-6">
                <label>Confirm Password</label>
                <TextInput
                  name={"password_confirmation"}
                  type={"password"}
                  label={""}
                  value={this.state.password_confirmation}
                  onChange={this.handleInputChange}
                  className={"my-2 input-border"}
                  placeholder={"Confirm Password"}
                />
              </div>
            </div>
          ) : (
              <div />
            )}
          <div className="col-md-12 d-flex justify-content-center">
            {/*this.props.loader || this.state.familyLoader*/this.props.isLoading || this.props.loader ?
              <button
                type="submit"
                disabled
                className="btn btn-primary w-50 mt-5 mx-auto"
              >
                Applying Changes
                </button>
              :
              <button
                type="submit"
                disabled={this.state.loading}
                className="btn btn-primary w-50 mt-5 mx-auto"
              >
                {this.props.family ? "Update Family" : "Add Family"}
              </button>
            }
          </div>
          <br />
        </form>
        {/*this.props.family ?
            <button className="btn btn-primary mt-0" onClick={this.resendWelcomeEmail}>
              <i className="fa fa-envelope" aria-hidden="true"></i> Resend Welcome Email
            </button> : null */}
        {this.props.family && (
          <div className="col-md-12 d-flex justify-content-center">
            <button
              onClick={this.props.handleResetPassword}
              className="btn text-white btn-dark mt-3 mx-auto"
              disabled={this.state.resetEmailSent || this.props.loader}
            >
              Reset Password
            </button>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({ error: state.ui.error, isLoading: state.ui.isLoading });

const mapDispatchToProps = dispatch => ({
  family_actions: bindActionCreators(familyActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FamilyEditForm);
