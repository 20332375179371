import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

import './ActionLeadModal.scss'
import Loader from '../common/Op36Loader-web'
import defaultAvatar from '../../assets/images/mock/StockProfilePic.png'

class ActionLeadModal extends React.Component {
  componentDidMount() {
    if (!this.props.notesLoader) {
      this._scrollToBottom()
    }
  }
  componentDidUpdate() {
    if (!this.props.notesLoader) {
      this._scrollToBottom()
    }
  }

  // eslint-disable-next-line max-lines-per-function, complexity
  render() {
    const {
      lead,
      close,
      handleInputChange,
      newStatus,
      newEmail,
      newPhone,
      newName,
      handleArchive,
      notes,
      notesLoader,
      getPillColor,
      leadNote,
      handleLeadNoteSubmit,
      postClicked,
      saveChanges,
      userAvatar,
      userFullName,
      emailModalOpen,
      closeEmailModal,
      handleInviteClick,
      inviteSuccess,
      sendEmailClicked,
      handleSendEmail,
      isAdmin,
      handleSendReminder,
      failedEmailValidation,
    } = this.props

    return (
      <div id='ActionLeadModal'>
        <i
          className='fa fa-times exit-button'
          onClick={close}
          aria-hidden='true'
        />

        <div className='lead-info-section d-flex flex-column'>
          <div className='d-flex'>
            <div className='header-left'>
              <div className='name-container'>
                <i className='fa fa-user' aria-hidden='true' />
                <p className='num-golfers'>{lead.num_golfers} </p>
                <div className='divider' />
                <input
                  type='text'
                  name='newName'
                  value={newName}
                  className='lead-name'
                  onChange={handleInputChange}
                  onBlur={saveChanges}
                  placeholder='add a name'
                  autoComplete='name'
                />
              </div>
              <div className='status-container'>
                <p className='subtitle'>
                  {moment(lead.created_at).format('L LTS')}
                </p>
                <div
                  className='select-wrapper'
                  style={{
                    background: getPillColor(newStatus),
                    border: `2px solid ${getPillColor(newStatus)}`,
                  }}
                >
                  <select
                    name='newStatus'
                    onChange={handleInputChange}
                    value={newStatus}
                    disabled={lead.status === 'added'}
                  >
                    <option disabled value='none'>
                      status...
                    </option>
                    <option disabled value='new'>
                      new
                    </option>
                    <option value='attempted'>attempted</option>
                    <option value='contacted'>contacted</option>
                    <option value='invited'>invited</option>
                    <option value='added' hidden>
                      added
                    </option>
                    <option value='not interested'>not interested</option>
                    <option value='inaccurate info'>inaccurate info</option>
                  </select>
                </div>
              </div>
              <div className='email-container'>
                <i className='fa fa-envelope-o' aria-hidden='true' />
                <input
                  type='text'
                  name='newEmail'
                  value={newEmail}
                  className='lead-email'
                  onChange={handleInputChange}
                  onBlur={saveChanges}
                  placeholder='add an email'
                  autoComplete='email'
                />
              </div>
              <div className='phone-container'>
                <i className='fa fa-phone' aria-hidden='true' />
                <input
                  type='text'
                  name='newPhone'
                  value={newPhone}
                  className='phone-number'
                  onChange={handleInputChange}
                  onBlur={saveChanges}
                  placeholder='add a phone number'
                  autoComplete='tel'
                />
              </div>
            </div>
            <div className='header-right'>
              <div className='lead-action-button-container'>
                <button
                  className={`lead-action-button archive-button ${
                    lead.is_archived ? 'inverted' : ''
                  }`}
                  onClick={handleArchive}
                >
                  <i className='fa fa-archive' aria-hidden='true' /> Archive
                  {lead.is_archived ? 'd' : ''}
                </button>
                <button
                  className={`lead-action-button send-email-button ${
                    lead.is_invited ? 'inverted' : ''
                  }`}
                  onClick={
                    isAdmin
                      // eslint-disable-next-line no-undef, no-alert
                      ? () => alert("this doesn't do anything yet")
                      : handleInviteClick
                  }
                  disabled={lead.status === 'added'}
                >
                  <i className='fa fa-envelope-o' aria-hidden='true' /> Invite
                  {lead.is_invited ? 'd' : ''} to community
                </button>
              </div>
              <div className='interest-skill-container'>
                <div className='interest-container'>
                  <p className='info-title'>
                    Interest{lead.interests.length === 1 ? '' : 's'}
                  </p>
                  {lead.interests.length > 0 ? (
                    <p className='info-list'>{lead.interests.join(', ')}</p>
                  ) : (
                    <p className='info-list'>-none-</p>
                  )}
                </div>
                <div className='skill-container'>
                  <p className='info-title'>
                    Skill level{lead.skill_levels.length === 1 ? '' : 's'}
                  </p>
                  {lead.skill_levels.length > 0 ? (
                    <p className='info-list'>{lead.skill_levels.join(', ')}</p>
                  ) : (
                    <p className='info-list'>-none-</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='header-bottom'>
            <p className='original-message-title'>Original message:</p>
            <p className='original-message'>{lead.note}</p>
          </div>
        </div>
        <div className='notes-area'>
          {notesLoader ? (
            <div className='notes-loader-container'>
              <Loader message='loading notes' />
            </div>
          ) : (
            <div className='lead-notes-container'>
              {notes.length > 0 ? (
                notes.map(n => {
                  const isAction = n.content.indexOf('<action>') > -1 // checks if this was an auto-fired message on status change === these begin
                  // with <action>.
                  const isInvite = n.content.indexOf('<action-invite>') > -1 // set by LeadPage component if someone sends invite email
                  const isAccept = n.content.indexOf('<action-accept>') > -1 // set by API if someone goes through registration with this invitation
                  return (
                    <div className='note-card' key={n.id}>
                      <div className='d-flex'>
                        <div // uses the current user if the author isn't available - redux doesn't have it available right when the lead is posted
                        // and will be available when loaded from the API => the same for the name
                          className='author-icon'
                          style={{
                            backgroundImage: `url(${
                              n.author
                                ? n.author.avatar
                                  ? n.author.avatar.file_url
                                  : defaultAvatar
                                : userAvatar
                                  ? userAvatar.file_url
                                  : defaultAvatar
                            })`,
                          }}
                        />
                        <p className='author-name'>
                          {n.author
                            ? `${n.author.first_name} ${n.author.last_name}`
                            : userFullName}
                        </p>
                      </div>
                      <p className='note-date'>
                        {moment(n.created_at).format('L LTS')}
                      </p>
                      <p
                        className={`note-content ${
                          isAction
                            ? 'green'
                            : isInvite
                              ? 'dark'
                              : isAccept
                                ? '#ff6633'
                                : ''
                        }`}
                      >
                        {isAction
                          ? n.content.substring(8)
                          : isInvite || isAccept // just so happens that these both have a 15 substring value
                            ? n.content.substring(15)
                            : n.content}
                      </p>
                    </div>
                  )
                })
              ) : (
                <div className='notes-loader-container'>
                  <p>
                    Looks like this lead doesn&apos;t have any notes. Time to smile
                    and dial!
                  </p>
                </div>
              )}
              <div ref={el => (this.messagesEnd = el)} />
              {/* empty div to use as an anchor point for the auto scroll to the bottom of the notes section */}
            </div>
          )}
        </div>
        <div className='bottom-input-area'>
          <form className='bottom-form d-flex' autoComplete='new-stuff'>
            <input type='hidden' value='something' autoComplete='none' />
            {/* hack to get the autocomplete to turn off on the actual input */}
            <input
              type='text'
              name='leadNote'
              onChange={handleInputChange}
              value={leadNote}
              className='lead-note-input'
              placeholder='Add a note here'
              autoComplete='off'
            />
            <button
              type='submit'
              disabled={postClicked}
              className='lead-note-post-button'
              onClick={leadNote ? e => handleLeadNoteSubmit(e, lead.id) : e => e.preventDefault()}
            >
              {postClicked ? (
                <i className='fa fa-spinner fa-spin' aria-hidden='true' />
              ) : (
                'Post'
              )}
            </button>
          </form>
        </div>
        {emailModalOpen ? (
          <>
            <div className='grey-grey-out' aria-hidden='true' onClick={closeEmailModal} />
            <div className='submodal'>
              <i
                className='fa fa-times exit-button submodal-exit'
                onClick={closeEmailModal}
                aria-hidden='true'
              />
              {inviteSuccess ? (
                <div className='submodal-content'>
                  <i className='fa fa-check-circle' aria-hidden='true' />
                  <p className='success-title'>Success!</p>
                  <p className='success-note'>
                    You have sent {newName} an invitation to join your
                    community. If you don&apos;t hear back within a few days, you may
                    want to reach out again.
                  </p>
                </div>
              ) : (
                <div className='submodal-content'>
                  <p className='name-confirm'>{`${
                    lead.is_invited ? 'Remind' : 'Invite'
                  } ${newName}?`}</p>
                  {lead.is_invited ? (
                    newEmail === lead.invitation_record.email ? (
                      <p className='email-confirm'>
                        Send reminder to{' '}
                        <span className='actual-email'>{newEmail}</span>?
                      </p>
                    ) : (
                      <p className='email-confirm'>
                        Looks like the email has changed since the initial
                        invitation was sent to{' '}
                        <span className='actual-email'>
                          {lead.invitation_record.email}
                        </span>
                        . Do you want to send an invite to{' '}
                        <span className='actual-email'>{newEmail}</span>?{' '}
                        <span className='caution-note'>
                          Note: this will delete the previous invitation
                        </span>
                      </p>
                    )
                  ) : (
                    <p className='email-confirm'>
                      Send invite to{' '}
                      <span className='actual-email'>{newEmail}</span>? You may
                      want to verify that this is the correct email before
                      sending the invite.
                    </p>
                  )}
                  <div className='submodal-button-container'>
                    <button
                      className='submodal-button cancel'
                      onClick={closeEmailModal}
                    >
                      Nah, not yet
                    </button>
                    <button
                      disabled={sendEmailClicked}
                      className={`submodal-button invite ${
                        failedEmailValidation ? 'fail' : ''
                      }`}
                      onClick={() => {
                        if (
                          lead.is_invited &&
                          lead.invitation_record.email === newEmail
                        ) {
                          handleSendReminder(
                            newName,
                            newEmail,
                            lead.community_id,
                            lead.id,
                            lead.invitation_record.id
                          )
                        } else {
                          handleSendEmail(
                            newName,
                            newEmail,
                            lead.community_id,
                            lead.id
                          )
                        }
                      }}
                    >
                      {sendEmailClicked ? (
                        <i
                          className='fa fa-spinner fa-spin'
                          aria-hidden='true'
                        />
                      ) : failedEmailValidation ? (
                        'Invalid Email'
                      ) : (
                        `Yeah, send ${
                          lead.is_invited &&
                          lead.invitation_record.email === newEmail
                            ? 'reminder'
                            : 'invite'
                        }!`
                      )}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </>
        ) : null}
      </div>
    )
  }

  _scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: 'smooth' })
  }
}

ActionLeadModal.propTypes = {
  lead: PropTypes.shape({
    id: PropTypes.number,
    community_id: PropTypes.number,
    note: PropTypes.string,
    num_golfers: PropTypes.number,
    created_at: PropTypes.string,
    status: PropTypes.string,
    is_archived: PropTypes.bool,
    is_invited: PropTypes.bool,
    interests: PropTypes.array,
    skill_levels: PropTypes.array,
    invitation_record: PropTypes.shape({
      id: PropTypes.number,
      email: PropTypes.string,
    }),
  }),
  notesLoader: PropTypes.bool,
  newName: PropTypes.string,
  newPhone: PropTypes.string,
  newEmail: PropTypes.string,
  handleArchive: PropTypes.func,
  handleInputChange: PropTypes.func,
  emailModalOpen: PropTypes.bool,
  userFullName: PropTypes.string,
  userAvatar: PropTypes.shape({
    file_url: PropTypes.string,
  }),
  postClicked: PropTypes.bool,
  handleLeadNoteSubmit: PropTypes.func,
  leadNote: PropTypes.string,
  getPillColor: PropTypes.func,
  failedEmailValidation: PropTypes.bool,
  isAdmin: PropTypes.bool,
  sendEmailClicked: PropTypes.bool,
  handleInviteClick: PropTypes.func,
  notes: PropTypes.array,
  newStatus: PropTypes.string,
  close: PropTypes.func,
  handleSendReminder: PropTypes.func,
  handleSendEmail: PropTypes.func,
  inviteSuccess: PropTypes.bool,
  closeEmailModal: PropTypes.func,
  saveChanges: PropTypes.func,
}

export default ActionLeadModal
