import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { addSession, updateSession } from '../../../../reducers/programBuilderReducer'
import { getMomentInCommunityTimezone, getMomentInCommunityTimezoneReversed } from '../../../../utility/datesHelper'
import SubmitButton from '../../../common/buttons/SubmitButton'
import DatePicker from '../../../common/form/DatePicker'
import Input from '../../../common/form/Input'
import Modal from '../../../common/Modal'

import './SingleSessionModal.scss'
import FieldError from '../../../common/FieldError'
import classNames from 'classnames'
import moment from 'moment'

const customErrorMessages = {
  startDate: 'Please select the date.',
  endDate: 'Please select a start time and an end time.',
}

const EMPTY_SESSION = { title: '', startDate: '', endDate: '', location: '', description: '', isInvalid: false }

const SingleSessionModal = ({ showModal, closeModal, selectedSession }) => {
  const dispatch = useDispatch()
  const timezone = useSelector(state => state.user.current_user.communities[0].timezone)
  const [formSession, setFormSession] = useState(EMPTY_SESSION)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    if (selectedSession) {
      const startDateInTimezone = getMomentInCommunityTimezoneReversed(moment(selectedSession.startDate), timezone, 'YYYY-MM-DD HH:mm:ss')?.toDate()
      const endDateInTimezone = getMomentInCommunityTimezoneReversed(moment(selectedSession.endDate), timezone, 'YYYY-MM-DD HH:mm:ss')?.toDate()
      setFormSession({ ...selectedSession, startDate: startDateInTimezone, endDate: endDateInTimezone })
    }
  }, [showModal, selectedSession, timezone])

  const handleInputChange = e => {
    const { name, value } = e.target

    setFormSession({ ...formSession, [name]: value })
  }

  const handleDateTimeChange = e => {
    const { name, value } = e

    if (name === 'date') {
      let startDate = formSession.startDate ? moment(formSession.startDate) : moment(new Date().setMinutes(0))
      startDate = moment(moment(value).format('YYYY-MM-DD') + ' ' + startDate.format('HH:mm')).toDate()
      let endDate = formSession.endDate ? moment(formSession.endDate) : moment(startDate).add(1, 'hours')
      endDate = moment(moment(value).format('YYYY-MM-DD') + ' ' + endDate.format('HH:mm')).toDate()

      if (startDate >= endDate) {
        endDate = moment(endDate).add(1, 'days').toDate()
      }

      setFormSession({ ...formSession, isInvalid: false, startDate: startDate, endDate: endDate })
    } else if (name === 'startDate') {
      let startDate = formSession.startDate ? moment(formSession.startDate) : moment(new Date())
      startDate = moment(startDate.format('YYYY-MM-DD') + ' ' + moment(value).format('HH:mm')).toDate()
      let endDate = formSession.endDate ? moment(formSession.endDate) : moment(startDate).add(1, 'hours')
      endDate = moment(moment(startDate).format('YYYY-MM-DD') + ' ' + endDate.format('HH:mm')).toDate()

      if (startDate >= endDate) {
        endDate = moment(endDate).add(1, 'days').toDate()
      }

      setFormSession({ ...formSession, isInvalid: false, startDate: startDate, endDate: endDate })
    } else {
      let endDate = formSession.startDate ? moment(formSession.startDate) : moment(new Date())
      endDate = moment(endDate.format('YYYY-MM-DD') + ' ' + moment(value).format('HH:mm')).toDate()

      let startDate = formSession.startDate
      if (!startDate) {
        startDate = moment(moment(endDate).format('YYYY-MM-DD') + ' ' + moment(endDate).subtract(1, 'hours').format('HH:mm')).toDate()
      }

      if (startDate >= endDate) {
        endDate = moment(endDate).add(1, 'days').toDate()
      }

      setFormSession({ ...formSession, isInvalid: false, startDate: startDate, endDate: endDate })
    }
  }

  const invalidFields = {
    startDate: formSession.startDate === '' || formSession.isInvalid,
    endDate: formSession.endDate === '',
  }

  const formValid = () => {
    const errors = {};

    ['startDate', 'endDate'].forEach((type) => {
      if (invalidFields[type]) {
        errors[type] = customErrorMessages[type]
      }
    })

    setErrors(errors)

    return Object.keys(errors).length === 0
  }

  const handleSession = () => {
    if (formValid()) {

      const newSession = {
        ...formSession,
        startDate: getMomentInCommunityTimezone(moment(formSession.startDate), timezone, 'YYYY-MM-DD HH:mm:ss').toDate(),
        endDate: getMomentInCommunityTimezone(moment(formSession.endDate), timezone, 'YYYY-MM-DD HH:mm:ss').toDate(),
      }
      if (formSession.index === undefined) {
        dispatch(addSession(newSession))
      } else {
        dispatch(updateSession(newSession))
      }
      exitModal()
    }
  }

  const exitModal = () => {
    setFormSession(EMPTY_SESSION)
    setErrors({})
    closeModal()
  }

  return (
    <Modal
      isOpen={showModal}
      onClose={exitModal}
      className='single-session-builder-modal-content'
    >
      <div id='SingleSessionModal'>
        <div className='animated fadeIn'>
          <div>
            <p className='title'>{formSession.index === undefined ? 'New' : 'Edit'} Session</p>
            <div className='row'>
              <div className='col-12 mb-2'>
                <label htmlFor='title'>Title</label>

                <Input
                  name='title'
                  type='text'
                  onChange={handleInputChange}
                  placeholder='Add a title'
                  value={formSession.title}
                  error={errors.title}
                />
              </div>

              <div className='col-12 col-sm-6 mb-2'>
                <label htmlFor='date'>
                  Date <span className='required-text'>*</span>
                </label>

                <DatePicker
                  selected={!formSession.isInvalid && formSession.startDate}
                  onChange={date => handleDateTimeChange({ name: 'date', value: date })}
                  error={errors.startDate}
                  symbol={<i className='fa fa-calendar-o'/>}
                />
              </div>

              <div className='col-12 col-sm-6 mb-2'>
                <label htmlFor='time'>
                  Time <span className='required-text'>*</span>
                </label>

                <div className='d-flex'>
                  <DatePicker
                    selected={formSession.startDate}
                    className='startDateInput'
                    onChange={time => handleDateTimeChange({ name: 'startDate', value: time })}
                    inputClassNames={{
                      inputContainer: classNames('br-0', { 'error-input': !!errors.startDate && !formSession.isInvalid }),
                      input: 'w-100 text-right',
                    }}
                    symbol={<i className='fa fa-clock-o'/>}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption='time'
                    dateFormat='h:mm aa'
                    autoComplete='off'
                  />
                  <DatePicker
                    selected={formSession.endDate}
                    className='endDateInput'
                    onChange={time => handleDateTimeChange({ name: 'endDate', value: time })}
                    inputClassNames={{ inputContainer: classNames('bl-0 pl-0', { 'error-input': !!errors.endDate }) }}
                    symbol={<i className='fa fa-minus'/>}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption='time'
                    dateFormat='h:mm aa'
                    autoComplete='off'
                  />
                </div>
                {(!!errors.endDate || (!!errors.startDate && !formSession.isInvalid)) && <FieldError message={errors.endDate} />}
              </div>

              <div className='col-12 mb-3'>
                <label htmlFor='location'>Location</label>

                <Input
                  name='location'
                  type='text'
                  onChange={handleInputChange}
                  placeholder='Add a Location'
                  value={formSession.location}
                  error={errors.location}
                  symbol={<i className='fa fa-map-marker'/>}
                />
              </div>

              <div className='col-12 mb-3'>
                <label htmlFor='description'>Description</label>

                <Input
                  name='description'
                  type='textarea'
                  onChange={handleInputChange}
                  placeholder='Add a Description'
                  value={formSession.description}
                  error={errors.description}
                />
              </div>
            </div>
            <div className='row py-3'>
              <div className='col-6'>
                <SubmitButton
                  onClick={exitModal}
                  buttonMessage='Cancel'
                  buttonColor='navy-button'
                />
              </div>
              <div className='col-6'>
                <SubmitButton
                  onClick={handleSession}
                  buttonMessage={formSession.index === undefined ? 'Create' : 'Update'}
                  buttonColor='orange-button'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

SingleSessionModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  selectedSession: PropTypes.object,
}

export default SingleSessionModal
