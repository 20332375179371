import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import moment from 'moment'
import { Line } from 'react-chartjs-2'
import ImageGallery from '../../common/ImageGallery'
import ImageUploader from '../../common/form/ImageUploader'
import ReactPlayer from 'react-player'

import ConfirmationModal from '../../common/ConfirmationModal'
import ValidationModal from '../../common/ValidationModal'
import TextAreaInput from '../../common/form/TextAreaInput'
import Placeholder from '../../common/Placeholder'

import TemplateStudentsCard from './Templates/TemplateStudentsCard'
import AddStudentsModal from './Templates/AddStudentsModal'
import NewGoalTask from './NewGoalTask'
import GoalTask from './GoalTask'
import GoalReview from './GoalReview'
import Loader from '../../common/Op36Loader-web'

import './Goals.scss'

const styles = {
  video: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
  },
}

class CustomGoalView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showStudentsModal: false,
      showModal: false,
      showReviewModal: false,
      showCompleteModal: false,
      showViewReviewModal: false,
      showViewPhotoModal: false,
      showEditModal: false,
      showConfirmationModal: false,
      showValidationModal: false,
      goalLoader: false,
      currentGoal: props.goal,
      userId: props.user.id,
      creatorId: props.current_user.id,
      goalComplete: false,
      goalPercentage: 0,
      reviewNote: '',
      task: {},
      taskId: -1,
      review: {},
      reviewGoalTasks: [],
      reviewImages: [],
      uploadPhotoFileSrc: '',
      uploadPhotoName: '',
      uploadPhotoFile: '',
      didUploadPhoto: false,
      uploadingPhoto: false,
      goalPhotos: [],
      pictures: props.goal.photos ? props.goal.photos : [],
      deletedPictures: [],
      goalName: props.goal ? props.goal.name : '',
      goalVideo: props.goal ? props.goal.video_url : '',
      goalDescription: props.goal ? props.goal.description : '',
      goalSubjectId: 0,
      goalTaskArray: [0],
      goalTasks: props.goal.goal_tasks ? props.goal.goal_tasks : [],
      deletedTasks: [],
      confirmationMessage: '',
      validationSuccess: true,
      validationMessage: '',
      isGoal: null,
    }
  }

  componentDidMount() {
    const { is_template, getActiveStudents, goal } = this.props
    this.resetState()
    this.renderGoalPhotos()

    if (is_template) { getActiveStudents(goal) }
  }

  resetState = () => {
    this.setState({
      goalPercentage: 0,
      reviewNote: '',
      task: {},
      taskId: -1,
      goalComplete: false,
      reviewImages: [],
      uploadPhotoFileSrc: '',
      uploadPhotoName: '',
      uploadPhotoFile: '',
      didUploadPhoto: false,
      uploadingPhoto: false,
      pictures: [],
      deletedPictures: [],
      goalName: '',
      goalVideo: '',
      goalDescription: '',
      goalSubjectId: 0,
      goalTaskArray: [0],
      goalTasks: [],
      deletedTasks: [],
      goalLoader: false,
    })
  }

  toggleStudentsModal = () => {
    this.setState(
      {
        showStudentsModal: true,
      },
      () => {
        const { is_template, getInactiveStudents, goal } = this.props
        if (is_template) { getInactiveStudents(goal) }
      }
    )
  }

  closeStudentsModal = () => {
    this.setState({
      showStudentsModal: false,
    })
  }

  toggleModal = task => {
    this.setState({
      showModal: true,
      task: task,
      goalPercentage: !this.props.goal.progress_percentage
        ? 0 + task.progress_percentage
        : this.props.goal.progress_percentage + task.progress_percentage,
      taskId: task.id,
    })
  }

  toggleEditModal = goal => {
    const goalTaskArray = []
    const goalTasks = []
    let goalCompletion = 0
    if (goal.goal_tasks.length > 0) {
      goal.goal_tasks.map((task, index) => {
        goalTaskArray.push(index)
        goalTasks.push([task.progress_percentage, task.description, task.id])

        goalCompletion += task.progress_percentage
      })
    }

    let goalVideo = goal.video_url

    if (goalVideo === null || goalVideo === 'null') {
      goalVideo = ''
    }

    this.setState({
      showEditModal: true,
      goalName: goal.name,
      goalDescription: goal.description,
      goalVideo: goalVideo,
      goalCompletion: goalCompletion,
      pictures: goal.photos ? goal.photos : [],
      goalTaskArray: goalTaskArray,
      goalTasks: goalTasks,
    })
  }

  toggleViewPhotoModal = () => {
    this.setState({
      showViewPhotoModal: true,
    })
  }

  toggleViewReviewModal = review => {
    this.renderGoalReviewPhotos(review)
    this.setState({
      showViewReviewModal: true,
      review: review,
      reviewNote: review.note,
      reviewGoalTasks: review.goal_tasks,
    })
  }

  toggleSimpleReviewModal = () => {
    this.setState({
      showReviewModal: true,
    })
  }

  toggleCompleteModal = () => {
    this.setState({
      showCompleteModal: true,
      goalPercentage: 100,
      goalComplete: true,
    })
  }

  toggleConfirmationModal = (message, item, isGoal) => {
    this.setState(
      {
        showConfirmationModal: true,
        confirmationMessage: message,
        isGoal: isGoal,
      },
      () => {
        if (!isGoal) {
          this.setState({
            review: item,
          })
        }
      }
    )
  }

  toggleValidationModal = (success, message) => {
    this.setState({
      showValidationModal: true,
      validationMessage: message,
      validationSuccess: success,
    })
  }

  closeValidationModal = () => {
    this.setState({
      showValidationModal: false,
      validationMessage: '',
      validationSuccess: true,
    })
  }

  closeConfirmationModal = () => {
    this.setState({
      showConfirmationModal: false,
      review: {},
    })
  }

  closeModal = () => {
    this.setState(
      {
        showModal: false,
      },
      () => {
        this.resetState()
      }
    )
  }

  closeEditModal = () => {
    this.setState(
      {
        showEditModal: false,
      },
      () => {
        this.resetState()
      }
    )
  }

  closeViewPhotoModal = () => {
    this.setState(
      {
        showViewPhotoModal: false,
      },
      () => {
        this.resetState()
      }
    )
  }

  closeViewReviewModal = () => {
    this.setState(
      {
        showViewReviewModal: false,
      },
      () => {
        this.resetState()
      }
    )
  }

  closeReviewModal = () => {
    this.setState(
      {
        showReviewModal: false,
        taskId: -1,
      },
      () => {
        this.resetState()
      }
    )
  }

  closeCompleteModal = () => {
    this.setState(
      {
        showCompleteModal: false,
      },
      () => {
        this.resetState()
      }
    )
  }

  handleInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  onObjectChange = (index, desc, progress, id) => {
    const goalTasks = [...this.state.goalTasks]

    goalTasks[index] = [progress, desc, id]
    this.setState(
      {
        goalTasks: goalTasks,
      },
      () => {
        const percentages = [0]
        let total = 0

        this.state.goalTasks.map(task => {
          percentages.push(task[0])
        })

        total = percentages.reduce((acc, val) => acc + val)
        this.setState({
          goalCompletion: total,
        })
      }
    )
  }

  deleteItem = () => {
    if (this.state.isGoal) {
      this.props.deleteItem(this.props.goal, true, this.renderGoalPhotos)
      this.closeConfirmationModal()
    } else {
      this.props.deleteItem(this.state.review, false, this.renderGoalPhotos)
      this.closeConfirmationModal()
      this.toggleValidationModal(
        true,
        'You have successfully deleted the review'
      )
    }
  }

  createGoalTask = () => {
    const goalTasks = [...this.state.goalTasks]

    goalTasks.push([0, '', -1])

    this.setState({
      goalTasks: goalTasks,
    })
  }

  deleteGoalTask = () => {
    let goalCompletion = 0
    const taskArray = [...this.state.goalTaskArray]
    const goalArray = [...this.state.goalTasks]
    const deletedTasks = [...this.state.deletedTasks]
    const deletedTask = goalArray.pop()

    goalArray.map(task => {
      goalCompletion += task.progress_percentage
    })

    deletedTasks.push(deletedTask)
    taskArray.splice(-1, 1)
    this.setState({
      goalTaskArray: taskArray,
      goalTasks: goalArray,
      deletedTasks: deletedTasks,
      goalCompletion: goalCompletion,
    })
  }

  handleReviewNote = note => {
    this.setState({
      reviewNote: note.target.value,
    })
  }

  handlePictureChange = event => {
    if (event.target.files.length > 0) {
      const selectedFiles = event.target.files
      const pictures = [...this.state.pictures]

      for (let i = 0; i < selectedFiles.length; i++) {
        pictures.push(selectedFiles.item(i))
      }
      this.setState({
        pictures: pictures,
      })
    }
  }

  resetPictures = () => {
    const deletedPictures = [...this.state.deletedPictures]

    if (this.props.goal.photos.length > 0) {
      const goalPhotos = this.props.goal.photos

      goalPhotos.map(photo => {
        if (photo.id) {
          deletedPictures.push(photo)
        }
      })
    }

    this.setState(
      {
        deletedPictures: deletedPictures,
      },
      () => {
        this.setState({
          pictures: [],
        })
      }
    )
  }

  selectInputFile() {
    document.getElementById('input-photo-upload').click()
  }

  displayImageSelect() {
    if (this.props.uploadingPhoto) {
      return (
        <div id='GoalImage' className='text-center uploading-images-container'>
          <i
            className='fa fa-circle-o-notch fa-spin fa-lg fa-3x uploading-images-icon'
            aria-hidden='true'
          />
          <p className='mt-2 uploading-images-text'>uploading image(s)</p>
        </div>
      )
    } else {
      return (
        <ImageUploader
          label='Max Photos: 5 - Max file size: 5mb - supported file types: jpg | png'
          reset={this.resetPictures}
          id='input-photo-upload'
          onChange={this.handlePictureChange}
          accept={'image/*'}
          selectImages={this.selectInputFile}
          images={this.state.pictures}
          imageSources={this.state.uploadPhotoFileSources}
        />
      )
    }
  }

  renderProgressChart() {
    const data = []
    const newData = []
    const labels = []
    this.props.goal.goal_tasks
      .sort((a, b) => a[a.completed_at] - b[b.completed_at])
      .map(task => {
        if (task.is_completed) {
          data.push(task.progress_percentage)
          labels.push(moment(task.completed_at).format('MM-DD-YYYY'))
        }
      })

    let i
    for (i = 0; i < data.length; i++) {
      if (data.length > 1) {
        if (i === 0) {
          newData.push(data[i])
        } else {
          newData.push(data[i] + newData[i - 1])
        }
      } else {
        newData.push(data[i])
      }
    }

    // Card Chart
    const cardChartData = {
      labels: labels,
      datasets: [
        {
          label: 'Progress',
          backgroundColor: 'rgba(246, 138, 34, 0.45)',
          borderColor: 'rgba(255,255,255,.55)',
          pointBackgroundColor: '#ff6633',
          pointBorderColor: 'transparent',
          data: newData,
        },
      ],
    }

    const cardChartOpts = {
      maintainAspectRatio: false,
      legend: {
        display: true,
      },
      scales: {
        x: {
          display: true,
          gridLines: {
            color: 'white',
            zeroLineColor: 'white',
          },
          ticks: {
            /*fontSize: 2,*/
            fontColor: '#2b3842',
          },
        },
        y: {
          display: true,
          ticks: {
            display: true,
            min: 0,
            max: 100,
            stepSize: 25,
          },
        },
      },
      elements: {
        line: {
          borderWidth: 1,
        },
        point: {
          radius: 4,
          hitRadius: 10,
          hoverRadius: 4,
        },
      },
    }
    return <Line data={cardChartData} options={cardChartOpts} height={230} />
  }

  reviewTask = type => {
    this.props.reviewTask(
      this.state,
      this.props.goal.id,
      this.renderGoalPhotos
    )

    setTimeout(() => {
      if (type === 'simpleReview') {
        this.props.getCustomGoalPage(this.props.goal)
        this.closeReviewModal()
      } else if (type === 'review') {
        this.props.getCustomGoalPage(this.props.goal)
        this.closeModal()
      } else if (type === 'complete') {
        this.props.getCustomGoalPage(this.props.goal)
        this.closeCompleteModal()
      }
    }, 1000)
  }

  resetPhotos = () => {
    this.setState({
      goalPhotos: [],
    })
  }

  updateGoal = () => {
    this.resetPhotos()
    this.props.updateGoal(
      this.state,
      this.props.goal.id,
      this.renderGoalPhotos
    )
    setTimeout(() => {
      this.closeEditModal()
    }, 1000)
  }

  renderNewGoalTasks = () => {
    const lastTaskIndex = this.state.goalTaskArray.lastIndexOf(
      this.state.goalTaskArray[this.state.goalTaskArray.length - 1]
    )
    return this.state.goalTasks.map((task, index) => (
      <NewGoalTask
        sendData={getData => {
          this.getData = getData
        }}
        task={task}
        isEdit={true}
        key={index}
        index={index}
        onObjectChange={this.onObjectChange}
        lastIndex={lastTaskIndex}
      />
    ))
  }

  renderPercentageText = () => {
    if (this.state.goalCompletion < 100) {
      return 'set objective importance - each objective must add up to 100%'
    } else if (this.state.goalCompletion === 100) {
      return 'You have successfully added objectives'
    } else if (this.state.goalCompletion > 100) {
      return 'Delete objectives or decrease progress'
    }
  }

  renderEditPrivateGoalForm = () => (
    <Modal
      isOpen={this.state.showEditModal}
      onRequestClose={this.closeEditModal}
      contentLabel='Goal Review'
    >
      <div className='modal-header row'>
        <div className='col-11'>
          <h5 className='modal-title display-4'>
              Edit {this.props.goal.name}
          </h5>
        </div>
        <div className='col-1'>
          <button
            type='button'
            className='close pull-right font-200'
            data-dismiss='modal'
            aria-label='Close'
            onClick={this.closeEditModal}
          >
            <span aria-hidden='true'>✕</span>
          </button>
        </div>
      </div>
      <div className='modal-content'>
        <div className='col-md-8 mx-auto'>
          <div className='row pb-3'>
            <div className='col-md-12 mx-auto'>
              <div className='row'>
                <div className='col-md-6'>
                  <div className='col-md-12'>
                    <div className='form-group'>
                      <label htmlFor='program-activity-title'>Name</label>
                      <input
                        type='text'
                        name='goalName'
                        className='form-control'
                        id='goal-name'
                        placeholder='Enter a name for the goal'
                        onChange={this.handleInputChange}
                        value={this.state.goalName}
                      />
                    </div>
                  </div>
                  <div className='col-md-12'>
                    <div className='form-group'>
                      <label htmlFor='program-activity-title'>
                          Video Url (optional)
                      </label>
                      <input
                        type='text'
                        name='goalVideo'
                        className='form-control'
                        id='goal-video'
                        placeholder='Enter a Youtube or Vimeo URL'
                        onChange={this.handleInputChange}
                        value={this.state.goalVideo}
                      />
                    </div>
                  </div>
                  <div className='col-md-12'>
                    <div className='form-group'>
                      <label htmlFor='goalDescription'>Description</label>
                      <textarea
                        type='text'
                        rows={3}
                        name='goalDescription'
                        className='form-control '
                        placeholder='Insert why this goal is important to you...'
                        value={this.state.goalDescription}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='my-2 pl-2'>
                    <label className='mt-3 my-0' htmlFor='goal-image'>
                        Goal Image Upload (optional)
                    </label>
                  </div>
                  {this.displayImageSelect()}
                </div>
                <div className='col-md-12 goal-objective-percentage-wrapper'>
                  <label htmlFor='goalObjectives'>Goal Objectives</label>
                  <div className='goal-percentage-wrapper hidden-sm-down'>
                    <p className='goal-percentage-desc'>
                      {this.renderPercentageText()}
                    </p>
                    <div
                      className='goal-percentage-tracker'
                      style={{
                        backgroundColor:
                            this.state.goalCompletion === 100 ? '#33d9b2' : '',
                      }}
                    >
                      {`Goal Completion: ${this.state.goalCompletion}%`}
                    </div>
                  </div>
                </div>
                {this.renderNewGoalTasks()}
                <div className='col-md-12 goal-objective-border' />
                <div className='col-md-12 goal-objective-icon-wrapper'>
                  {this.state.goalTasks.length > 1 ? (
                    <i
                      className='fa fa-minus goal-objective-minus-icon'
                      aria-hidden='true'
                      onClick={this.deleteGoalTask}
                    />
                  ) : null}
                  <i
                    className='fa fa-plus goal-objective-add-icon'
                    aria-hidden='true'
                    onClick={this.createGoalTask}
                  />
                </div>
                <div className='col-md-12 goal-delete-wrapper'>
                  <button
                    className='btn-warning'
                    onClick={() =>
                      this.toggleConfirmationModal(
                        `Are you sure you want to delete '${
                          this.props.goal.name
                        }'?`,
                        null,
                        true
                      )
                    }
                  >
                    <p className='goal-delete-text mb-0 p-2'>Delete Goal</p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row container mx-auto'>
          <div className='col-md-6 mx-auto'>
            <div className='row'>
              <div className='col-md-6'>
                <button
                  type='submit'
                  className='btn btn-dark w-100'
                  onClick={this.closeEditModal}
                >
                    Cancel
                </button>
              </div>
              <div className='col-md-6'>
                <button
                  type='submit'
                  className='btn btn-primary btn-block'
                  disabled={
                    this.state.goalName === '' ||
                      this.state.goalDescription === '' ||
                      this.state.goalCompletion !== 100
                  }
                  onClick={this.updateGoal}
                >
                  {this.props.loader ? 'Updating Goal...' : 'Update Goal'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )

  renderViewReviewModal = () => (
    <Modal
      isOpen={this.state.showViewReviewModal}
      onRequestClose={this.closeViewReviewModal}
      contentLabel='Goal Review'
    >
      <div className='modal-header row'>
        <div className='col-11'>
          <h5 className='modal-title display-4'>
              Review |{' '}
            {moment(this.state.review.created_at).format('MM-DD-YYYY')}
          </h5>
        </div>
        <div className='col-1'>
          <button
            type='button'
            className='close pull-right font-200'
            data-dismiss='modal'
            aria-label='Close'
            onClick={this.closeViewReviewModal}
          >
            <span aria-hidden='true'>✕</span>
          </button>
        </div>
      </div>
      <div className='modal-content'>
        <div className='col-md-6 mx-auto p-4'>
          <div className='row pb-3'>
            {this.state.reviewGoalTasks.length > 0 ? (
              <div className='col-md-12'>
                <h4 className='review-modal-title'>Objective Completed</h4>
                {this.state.reviewGoalTasks.length > 0 ? (
                  <div className='review-modal-objective-wrapper'>
                    <i className='fa fa-check-square-o review-modal-icon' />
                    <p className='review-modal-objective'>
                      {this.state.reviewGoalTasks[0].description}
                    </p>
                  </div>
                ) : (
                  <p className='review-modal-no-objective'>
                      No objective complete - check notes
                  </p>
                )}
              </div>
            ) : null}
          </div>
          <div className='row pb-3'>
            <div className='col-md-12'>
              <h4 className='review-modal-title'>Notes</h4>
              <p className='review-modal-note'>{this.state.reviewNote}</p>
            </div>
          </div>
          {this.state.reviewImages.length > 0 ? (
            <div className='row pb-3'>
              <div className='col-md-12'>
                <h4 className='review-modal-title'>Review Image(s)</h4>
                <ImageGallery
                  items={this.state.reviewImages}
                  showThumbnails={false}
                  showPlayButton={false}
                  useBrowserFullscreen={false}
                  showFullscreenButton={false}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </Modal>
  )

  renderSimpleReviewModal = () => (
    <Modal
      isOpen={this.state.showReviewModal}
      onRequestClose={this.closeReviewModal}
      contentLabel='Goal Review'
    >
      <div className='modal-header row'>
        <div className='col-11'>
          <h5 className='modal-title display-4'>Review Goal</h5>
        </div>
        <div className='col-1'>
          <button
            type='button'
            className='close pull-right font-200'
            data-dismiss='modal'
            aria-label='Close'
            onClick={this.closeReviewModal}
          >
            <span aria-hidden='true'>✕</span>
          </button>
        </div>
      </div>
      <div className='modal-content'>
        <div className='col-md-6 mx-auto p-4'>
          <div className='row pb-3'>
            <div className='col-md-12'>
              <div className='my-2 pl-2'>
                <label className='mt-3 my-0' htmlFor='goal-image'>
                    Goal Review Image Upload
                </label>
              </div>
              {this.displayImageSelect()}
            </div>
          </div>
          <div className='row pb-3'>
            <div className='col-md-12'>
              <div className='form-group'>
                <label htmlFor='course-name'>Notes</label>
                <TextAreaInput
                  className='w-100 border p-2'
                  rows='4'
                  name={'review-note'}
                  id={'review-note'}
                  value={this.state.reviewNote}
                  onChange={this.handleReviewNote}
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <button
                type='submit'
                className='btn btn-dark w-100'
                onClick={this.closeReviewModal}
              >
                  Cancel
              </button>
            </div>
            <div className='col-md-6'>
              <button
                type='submit'
                className='btn btn-primary btn-block'
                disabled={this.props.loader}
                onClick={() => this.reviewTask('simpleReview')}
              >
                {this.props.loader
                  ? 'Reviewing Objective ...'
                  : 'Review Objective'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )

  renderCompleteModal = () => (
    <Modal
      isOpen={this.state.showCompleteModal}
      onRequestClose={this.closeCompleteModal}
      contentLabel='Goal Review'
    >
      <div className='modal-header row'>
        <div className='col-11'>
          <h5 className='modal-title display-4'>Complete Goal</h5>
        </div>
        <div className='col-1'>
          <button
            type='button'
            className='close pull-right font-200'
            data-dismiss='modal'
            aria-label='Close'
            onClick={this.closeCompleteModal}
          >
            <span aria-hidden='true'>✕</span>
          </button>
        </div>
      </div>
      <div className='modal-content'>
        <div className='col-md-6 mx-auto p-4'>
          <div className='row pb-3'>
            <div className='col-md-12'>
              <div className='form-group'>
                <label htmlFor='course-name'>Notes</label>
                <TextAreaInput
                  className='w-100 border p-2'
                  rows='4'
                  name={'review-note'}
                  id={'review-note'}
                  value={this.state.reviewNote}
                  onChange={this.handleReviewNote}
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <button
                type='submit'
                className='btn btn-dark w-100'
                onClick={this.closeCompleteModal}
              >
                  Cancel
              </button>
            </div>
            <div className='col-md-6'>
              <button
                type='submit'
                className='btn btn-primary btn-block'
                disabled={this.props.loader}
                onClick={() => this.reviewTask('complete')}
              >
                {this.props.loader
                  ? 'Completing Objective...'
                  : 'Complete Objective'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )

  renderReviewModal = () => (
    <Modal
      isOpen={this.state.showModal}
      onRequestClose={this.closeModal}
      contentLabel='Goal Review'
    >
      <div className='modal-header row'>
        <div className='col-11'>
          <h5 className='modal-title display-4'>Complete Objective</h5>
        </div>
        <div className='col-1'>
          <button
            type='button'
            className='close pull-right font-200'
            data-dismiss='modal'
            aria-label='Close'
            onClick={this.closeModal}
          >
            <span aria-hidden='true'>✕</span>
          </button>
        </div>
      </div>
      <div className='modal-content'>
        <div className='col-md-6 mx-auto p-4'>
          <div className='row pb-3'>
            <div className='col-md-12'>
              <div className='my-2 pl-2'>
                <label className='mt-3 my-0' htmlFor='goal-image'>
                  Goal Review Image Upload (optional)
                </label>
              </div>
              {this.displayImageSelect()}
            </div>
          </div>
          <div className='row pb-3'>
            <div className='col-md-12'>
              <div className='form-group'>
                <label htmlFor='course-name'>Goal Completion</label>
                <div className='progress'>
                  <div
                    className='progress-bar'
                    role='progressbar'
                    style={{
                      width: `${this.state.goalPercentage}%`,
                      backgroundColor: '#ff6633',
                    }}
                    aria-valuenow={this.state.goalPercentage}
                    aria-valuemin='0'
                    aria-valuemax='100'
                  >{`${this.state.goalPercentage}%`}</div>
                </div>
              </div>
            </div>
          </div>
          <div className='row pb-3'>
            <div className='col-md-12'>
              <div className='form-group'>
                <label htmlFor='objective-completion'>
                  Objective Reviewed
                </label>
                <div className='goal-tasks-container'>
                  - {this.state.task ? this.state.task.description : ''}
                </div>
              </div>
            </div>
          </div>
          <div className='row pb-3'>
            <div className='col-md-12'>
              <div className='form-group'>
                <label htmlFor='course-name'>Notes</label>
                <TextAreaInput
                  className='w-100 border p-2'
                  rows='4'
                  name={'review-note'}
                  id={'review-note'}
                  value={this.state.reviewNote}
                  onChange={this.handleReviewNote}
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <button
                type='submit'
                className='btn btn-dark w-100'
                onClick={this.closeModal}
              >
                Cancel
              </button>
            </div>
            <div className='col-md-6'>
              <button
                type='submit'
                className='btn btn-primary btn-block'
                disabled={this.props.loader}
                onClick={() => this.reviewTask('review')}
              >
                {this.props.loader
                  ? 'Completing Objective ...'
                  : 'Complete Objective'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )

  viewPhoto = () => (
    <Modal
      isOpen={this.state.showViewPhotoModal}
      onRequestClose={this.closeViewPhotoModal}
      contentLabel='Goal Review'
    >
      <div className='modal-header row'>
        <div className='col-11'>
          <h5 className='modal-title display-4'>Goal Media View</h5>
          {/* <h6 className="font-200">{this.state.course.name}, {this.state.course.meeting_times}</h6> */}
        </div>
        <div className='col-1'>
          <button
            type='button'
            className='close pull-right font-200'
            data-dismiss='modal'
            aria-label='Close'
            onClick={this.closeViewPhotoModal}
          >
            <span aria-hidden='true'>✕</span>
          </button>
        </div>
      </div>
      <div className='modal-content image-gallery-modal-content'>
        <div className='col-md-12 mx-auto mt-5 mb-5'>
          {this.props.goal.photos.length > 0 || this.props.goal.video_url ? (
            <div className='modal-goal-image-gallery'>
              <ImageGallery
                items={this.state.goalPhotos}
                showThumbnails={false}
                showPlayButton={false}
                useBrowserFullscreen={false}
                showFullscreenButton={false}
              />
            </div>
          ) : null}
        </div>
      </div>
    </Modal>
  )

  renderGoalTasks = () => this.props.goal.goal_tasks.map(task => (
    <GoalTask
      key={task.id}
      task={task}
      current_user={this.props.current_user}
      taskId={this.state.taskId}
      handleChange={this.handleChange}
      toggleModal={this.toggleModal}
      is_template={this.props.is_template}
    />
  ))

  renderGoalReviews = () => {
    if (this.props.goal.goal_reviews.length > 0) {
      const lastIndex = this.props.goal.goal_reviews.length - 1
      return this.props.goal.goal_reviews.map((review, index) => (
        <GoalReview
          key={review.id}
          goal={this.props.goal}
          index={index}
          isLastIndex={index === lastIndex}
          current_user={this.props.current_user}
          review={review}
          toggleModal={this.toggleViewReviewModal}
          toggleConfirmationModal={this.toggleConfirmationModal}
        />
      ))
    } else {
      if (this.props.current_user.type === 'Coach') {
        return (
          <Placeholder
            title={'No Reviews Yet...'}
            icon={'fa-clipboard'}
            subtext1={'Check off an objective as the student completes them'}
            subtext2={
              'Send a review even if they have not completed an objective'
            }
            subtext3={'Complete the goal and leave notes to finish the goal'}
          />
        )
      } else {
        return (
          <Placeholder
            title={'No Reviews Yet...'}
            icon={'fa-clipboard'}
            subtext1={'To start the review process,'}
            subtext2={'You need to successfully complete an objective'}
            subtext3={'Do your best and have fun!'}
          />
        )
      }
    }
  }

  renderGoalPhotos = () => {
    const { goal } = this.props
    const goalPhotos = [...this.state.goalPhotos]
    let photo = {}

    if (this.props.goal.video_url) {
      photo = {
        embedUrl: this.props.goal.video_url,
        renderItem: this.renderVideo.bind(this),
      }

      goalPhotos.push(photo)
    }

    if (goal.photo_urls.length > 0) {
      const photos = goal.photo_urls
      for (let i = 0; i < photos.length; i++) {
        photo = {
          original: photos[i].large_file_url,
        }

        goalPhotos.push(photo)
      }
    }

    if (goal.photos) {
      const photos = goal.photos
      for (let i = 0; i < photos.length; i++) {
        photo = {
          original: photos[i].large_file_url,
        }

        goalPhotos.push(photo)
      }
    }

    this.setState({
      goalPhotos,
    })
  }

  renderVideo = item => (
    <div className='video-wrapper'>
      <ReactPlayer
        url={item.embedUrl}
        style={styles.video}
        width='100%'
        height='100%'
        controls
      />
    </div>
  )

  renderGoalReviewPhotos = review => {
    const reviewImages = [...this.state.reviewImages]
    let photo = {}
    if (review.photos) {
      const photos = review.photos
      for (let i = 0; i < photos.length; i++) {
        photo = {
          original: photos[i].large_file_url,
        }

        reviewImages.push(photo)
      }
      this.setState({
        reviewImages,
      })
    }
  }

  renderDays() {
    if (this.props.goal.is_completed) {
      const date1 = new Date(this.props.goal.created_at)
      const date2 = new Date(this.props.goal.completed_at)
      const timeDiff = Math.abs(date2.getTime() - date1.getTime())

      const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))

      return diffDays
    } else {
      const date1 = new Date(this.props.goal.created_at)
      const date2 = new Date()
      const timeDiff = Math.abs(date2.getTime() - date1.getTime())

      const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))

      return diffDays
    }
  }

  renderTemplateStudents = () => (
    <Placeholder
      title={'No Students Assigned'}
      icon={'fa-users'}
      subtext1={'There are currently no students assigned here'}
      subtext2={"Click 'Add Students' to assign students"}
    />
  )

  render() {
    const {
      is_template,
      activeStudentsLoader,
      goal_students,
      loader,
      goalsLoader,
      goal,
    } = this.props
    if (loader || goalsLoader) {
      return (
        <div className='row'>
          <div className='col-12'>
            <div className='loading-content goal-loading'>
              <Loader message='loading golfer goal' />
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className={`container pb-4 animated fadeIn custom-goal-container ${this.props.isMobile ? 'custom-mobile-container' : ''}`}>
        <div className='custom-goal-header'>
          <button className='text-white mb-3 back-curriculms-btn' onClick={this.props.startPage}>
            <i className='fa fa-arrow-left back-curriculms-icon' aria-hidden='true' />
          </button>
          <div className='custom-goal-header-info-wrapper'>
            {this.props.current_user.type === 'Coach' ? (
              <button className='btn btn-primary text-white mb-3 custom-goal-edit-btn' onClick={() => this.toggleEditModal(goal)}>
                <i
                  className='fa fa-pencil-square-o custom-goal-edit-icon'
                  aria-hidden='true'
                />
              </button>
            ) : null}

            <div className='custom-goal-header-wrapper'>
              <div className='custom-goal-header-title-wrapper'>
                <h4 className='custom-goal-header-title'>{goal.name}</h4>
                {goal.is_completed ? (
                  <i className='fa fa-check-circle custom-goal-header-icon' aria-hidden='true' />
                ) : null}
              </div>
              <p className='custom-goal-description'>{goal.description}</p>
            </div>
            {!is_template && (
              <div className='custom-goal-header-info hidden-sm-down'>
                <div className='progress'>
                  <div
                    className='progress-bar'
                    role='progressbar'
                    style={{
                      width: `${!goal.progress_percentage ? '0' : goal.progress_percentage }%`,
                      backgroundColor: '#ff6633',
                    }}
                    aria-valuenow={!goal.progress_percentage ? 0 : goal.progress_percentage}
                    aria-valuemin='0'
                    aria-valuemax='100'
                  >
                    {!goal.progress_percentage ? '' : `${goal.progress_percentage}%`}
                  </div>
                </div>
                <div className='custom-goal-date-container'>
                  <div className='custom-goal-text-container'>
                    <div className='custom-goal-text-wrapper pr-0'>
                      <p className='custom-goal-text'>Date Assigned:</p>{' '}
                      <span className='custom-goal-text-aside'>
                        {moment(goal.created_at).format('MM/DD/YYYY')}
                      </span>
                    </div>
                    <div className='custom-goal-text-wrapper pr-0'>
                      <p className='custom-goal-text'>Date Completed:</p>{' '}
                      <span className='custom-goal-text-aside'>
                        {goal.completed_at ? moment(goal.completed_at).format('MM/DD/YYYY') : ''}
                      </span>
                    </div>
                  </div>
                  <div className='custom-goal-day-wrapper'>
                    <p className='custom-goal-day'>{`${this.renderDays()} days`}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {!is_template && (
          <div className='card mt-15px'>
            <h5 className='p-2 card-header-text'>Progress</h5>
            <div className='p-4 custom-progress-bar-container'>
              <div>{this.renderProgressChart()}</div>
            </div>
          </div>
        )}
        <div className='row'>
          <div className='col-md-6'>
            <div className='card mt-15px p-0 col-md-12'>
              <h5 className='p-2 card-header-text'>Goal</h5>
              <div className='p-4'>
                <div className='row'>
                  <div className='col-md-12'>
                    {goal.photos.length > 0 ||
                    goal.video_url ||
                    goal.photo_urls.length > 0 ? (
                        <div className='goal-image-gallery'>
                          <ImageGallery
                            items={this.state.goalPhotos}
                            showThumbnails={false}
                            showPlayButton={false}
                            useBrowserFullscreen={false}
                            renderFullscreenButton={(onClick, isFullscreen) => {
                              if (this.props.isMobile) {
                                return <div />
                              } else {
                                return (
                                  <button
                                    type='button'
                                    className={`image-gallery-fullscreen-button${isFullscreen ? ' active' : ''}`}
                                    onClick={this.toggleViewPhotoModal}
                                  />
                                )
                              }
                            }}
                          />
                        </div>
                      ) : null}
                    <h4 className='goal-overview'>Objectives</h4>
                    <div className='p-4'>
                      <div className='goal-tasks-container'>
                        {this.renderGoalTasks()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            {is_template ? (
              <TemplateStudentsCard
                toggleModal={this.toggleStudentsModal}
                loading={activeStudentsLoader}
                students={goal_students}
              />
            ) : (
              <div className='card mt-15px p-0 col-md-12'>
                <h5 className='p-2 card-header-text'>Reviews</h5>
                <div className='p-4'>
                  <div className='custom-goal-review-container'>
                    {this.renderGoalReviews()}
                  </div>
                  {this.props.current_user.type === 'Coach' ? (
                    <div className='custom-goal-button-wrapper'>
                      <button
                        className='btn btn-primary btn-block custom-goal-btn'
                        onClick={this.toggleSimpleReviewModal}
                        disabled={this.props.goal.is_completed}
                      >
                        Review
                      </button>
                      <button
                        className='btn btn-block btn-success custom-goal-btn'
                        onClick={this.toggleCompleteModal}
                        disabled={this.props.goal.is_completed}
                      >
                        Complete
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            )}
          </div>
        </div>
        {this.renderReviewModal()}
        {this.renderSimpleReviewModal()}
        {this.renderCompleteModal()}
        {this.renderViewReviewModal()}
        {this.viewPhoto()}
        {this.renderEditPrivateGoalForm()}
        <ConfirmationModal
          showConfirmationModal={this.state.showConfirmationModal}
          closeConfirmationModal={this.closeConfirmationModal}
          confirmationMessage={this.state.confirmationMessage}
          confirmationDenied={this.closeConfirmationModal}
          confirmationProceed={this.deleteItem}
          loader={this.state.objectiveLoader}
        />
        <ValidationModal
          validationSuccess={this.state.validationSuccess}
          showValidationModal={this.state.showValidationModal}
          closeValidationModal={this.closeValidationModal}
          validationMessage={this.state.validationMessage}
        />
        <AddStudentsModal
          showModal={this.state.showStudentsModal}
          closeModal={this.closeStudentsModal}
          students={this.props.inactive_goal_students}
          loading={this.props.studentsLoader}
          goal={this.props.goal}
          getActiveStudents={this.props.getActiveStudents}
        />
      </div>
    )
  }
}

CustomGoalView.propTypes = {
  goal: PropTypes.shape({
    id: PropTypes.number,
    is_completed: PropTypes.bool,
    photo_urls: PropTypes.arrayOf({
      large_file_url: PropTypes.string,
    }),
    video_url: PropTypes.string,
    photos: PropTypes.array,
    goal_tasks: PropTypes.array,
    goal_reviews: PropTypes.array,
    description: PropTypes.string,
    name: PropTypes.string,
    progress_percentage: PropTypes.number,
    completed_at: PropTypes.string,
    created_at: PropTypes.string,
  }),
  user: PropTypes.shape({
    id: PropTypes.number,
  }),
  current_user: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
  }),
  loader: PropTypes.bool,
  getActiveStudents: PropTypes.func,
  studentsLoader: PropTypes.bool,
  is_template: PropTypes.bool,
  activeStudentsLoader: PropTypes.bool,
  goalsLoader: PropTypes.bool,
  startPage: PropTypes.number,
  isMobile: PropTypes.bool,
  inactive_goal_students: PropTypes.array,
  reviewTask: PropTypes.func,
  uploadingPhoto: PropTypes.bool,
  getInactiveStudents: PropTypes.func,
  deleteItem: PropTypes.func,
  goal_students: PropTypes.array,
  updateGoal: PropTypes.func,
  getCustomGoalPage: PropTypes.func,
}

export default CustomGoalView
