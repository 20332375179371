import Modal from '../../common/Modal'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

import SubmitButton from '../../common/buttons/SubmitButton'

import { ReactComponent as GolferIcon } from '../../../assets/images/common/icons/golfer-half-icon.svg'

import './ExitModal.scss'

const ExitModal = ({ showModal, closeModal, onSubmit, saveDraftScores, event }) => {

  const scoresSavedAt = useMemo(() => {
    const scores = JSON.parse(localStorage.getItem('draftScores'))

    return scores && scores[event.id] && scores[event.id]['savedAt']
  }, [event.id, showModal]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      isOpen={showModal}
      onClose={closeModal}
      contentLabel='Exit Event Modal'
      className='exit-modal'
      autoFocus
    >
      <div id='ExitModal'>
        <div className='d-flex flex-column align-items-center modal-container'>
          <div className='d-flex justify-content-center align-items-center icon-wrapper'>
            <GolferIcon className='golfer-icon' fill='#fff' width={'60px'} height={'60px'}/>
          </div>
          <div className='modal-content'>
            <span className='title'>You Are Leaving Scoring</span>
            {scoresSavedAt ? (
              <>
                <p className='mb-3'>Are you sure you want to stop logging scores?</p>
                <p>Scores last saved: {scoresSavedAt}</p>
              </>
            ) : (
              <>
                <p className='mb-3'>Are you sure you want to stop editing?</p>
                { event.students.length > 0 && <p>Scores Not Saved</p> }
              </>
            )}
          </div>
          <div className='w-100 pull-bottom'>
            <SubmitButton
              onClick={closeModal}
              buttonMessage='Continue Editing'
              buttonColor='orange-button'
            />
            {!scoresSavedAt && event.students.length > 0 &&
              <SubmitButton
                onClick={saveDraftScores}
                buttonMessage='Save and Publish Later'
                buttonColor='orange-button'
              />
            }
            <SubmitButton
              onClick={onSubmit}
              buttonMessage={scoresSavedAt ? 'Exit and Publish Later' : 'Discard Changes & Exit'}
              buttonColor='navy-button'
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

ExitModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  saveDraftScores: PropTypes.func.isRequired,
  event: PropTypes.object,
}

export default ExitModal
