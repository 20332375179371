import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as commonActions from "../../actions/commonActions";
import * as userActions from "../../actions/userActions";
import * as goalActions from "../../actions/goalActions";
import PageHeader from '../common/PageHeader/PageHeader'
import GoalModal from "./Goals/GoalModal";
import CurriculumCard from "./CurriculumCard";
import GoalCard from "./Goals/GoalCard";
import Loader from "../common/Op36Loader-web";
import Placeholder from "../common/Placeholder";
import CustomGoalView from "../profile/Goals/CustomGoalView";
import _ from "lodash";

// ASSETS
import Op36Icon from "../../assets/images/logos/OP36_clean_icon_dark.png";
import "./CurriculumsPage.scss";
import { withRouting } from "../common/hooks";

class CurriculumsPage extends Component {
  state = {
    showModal: false,
    page: 1,
    goal: {},
    goalsLoader: false,
    studentsLoader: false,
    activeStudentsLoader: false,
  };

  componentDidMount() {
    const { common_actions } = this.props;
    common_actions.setCurrentTab("Goals");
    common_actions.showSideBar(true);
    common_actions.showNavBar(true);
    this.fetchGoals();

    const { location } = this.props;

    if (!_.isEmpty(location.state) && location.state.smartToggle)
      this.setState({ showModal: true });
  }

  fetchGoals = (getGoal, goal, renderGoalPhotos) => {
    this.setState({ goalsLoader: true });
    this.props.user_actions
      .getUserGoals(this.props.current_user.id)
      .then(() => {
        if (getGoal) {
          this.getCustomGoalPage(goal);
          renderGoalPhotos();
        }
        this.setState({ goalsLoader: false });
      });
  };

  getActiveStudents = (goal) => {
    const { goal_actions, current_user } = this.props;
    this.setState(
      {
        activeStudentsLoader: true,
      },
      () => {
        goal_actions
          .getGoalStudents(goal.id, current_user.communities[0].id)
          .then(() => {
            this.setState({ activeStudentsLoader: false });
          });
      }
    );
  };

  getInactiveStudents = (goal) => {
    const { goal_actions, current_user } = this.props;
    this.setState(
      {
        studentsLoader: true,
      },
      () => {
        goal_actions
          .getInactiveGoalStudents(goal.id, current_user.communities[0].id)
          .then(() => {
            this.setState({ studentsLoader: false });
          });
      }
    );
  };

  renderPrivateGoals = () => {
    const { goalsLoader } = this.state;
    const { goals } = this.props;

    if (goalsLoader) return <Loader message={"Loading Goals"} />;
    return goals.map((goal) => {
      const { name, description, id } = goal;
      if (goal.is_template) {
        return (
          <GoalCard
            name={name}
            description={description}
            key={id}
            goal={goal}
            nextPage={this.nextPage}
          />
        );
      }
    });
  };

  openModal = () => {
    this.setState({
      showModal: true,
    });
  };

  closeModal = () => {
    this.setState({
      showModal: false,
    });
  };

  nextPage = (goal) => {
    this.setState({
      page: this.state.page + 1,
      goal,
    });
  };

  previousPage = () => {
    this.setState({
      page: this.state.page - 1,
      goal: {},
    });
  };

  deleteItem = () => {
    const { goal } = this.state;
    const { goal_actions } = this.props;
    this.setState({ goalsLoader: true });
    goal_actions.deleteGoal(goal).then((res) => {
      this.fetchGoals();
    });
    this.previousPage();
  };

  getCustomGoalPage = (goal) => {
    const { goals } = this.props;
    goals.map((newGoal) => {
      if (goal.id === newGoal.id) {
        this.setState({
          goal: newGoal,
        });
      }
    });
  };

  updateGoal = (state, index, renderGoalPhotos) => {
    this.setState({
      goalsLoader: true,
    });
    let goalTasks = [...state.goalTasks];
    let deletedTasks = [...state.deletedTasks];
    let deletedPictures = [...state.deletedPictures];
    if (state.pictures.length > 0) {
      this.setState({
        uploadingPhoto: true,
      });
    }
    this.setState(
      {
        goalTasks: goalTasks,
      },
      () => {
        this.props.goal_actions
          .updateGoal(state, goalTasks, deletedTasks, deletedPictures)
          .then((res) => {
            this.fetchGoals(true, state.currentGoal, renderGoalPhotos);
            this.setState({
              uploadingPhoto: false,
            });
          });
      }
    );
  };

  headerActionButton = (page) => (
    <button
      className='btn btn-primary text-white pull-right mr-2'
      onClick={page === 1 ? this.openModal : (e) => e.preventDefault()}
    >
      New Goal
    </button>
  )

  render() {
    const {
      goals,
      current_user,
      inactive_goal_students,
      goal_students,
    } = this.props;
    const { showModal, page, goalsLoader, goal } = this.state;
    return (
      <div id='CurriculumsPage' className='animated fadeIn'>
        <PageHeader
          title='Private Goals'
          actionComponent={this.headerActionButton(page)}
        />
        <div className='container-fluid'>
          {page === 1 && (
            <div className='row'>
              <div className='col-md-8 mx-auto'>
                <div className='curriculums-header'>
                  <h4>Private Goal Templates</h4>
                </div>
                {goals && goals.length > 0 ? (
                  this.renderPrivateGoals()
                ) : (
                  <Placeholder
                    title={"No Private Goal Templates Yet"}
                    icon={"fa-trophy"}
                    subtext1={
                      "There have currently been no private goal templates created."
                    }
                    subtext2={
                      "Click the Add Goal Template to start creating a goal."
                    }
                    buttonText={"Add Goal Template"}
                    buttonIcon={"fa-plus"}
                    handleClick={this.openModal}
                  />
                )}
              </div>
              {/* <div className='col-md-4'>
                <div className='curriculums-header'>
                  <h4>All Curriculums</h4>
                </div>
                <CurriculumCard
                  name={"Operation 36"}
                  description={
                    "View the ranks and objectives associated with the Operation 36 Curriculum"
                  }
                  image={Op36Icon}
                  route={"/ranks"}
                />
              </div> */}
            </div>
          )}
          {page === 2 && (
            <div className='mt-5'>
              <CustomGoalView
                user={current_user}
                current_user={current_user}
                updateGoal={this.updateGoal}
                goals={goals}
                goal={goal}
                loader={this.props.reviewLoader}
                getCustomGoalPage={this.props.getCustomGoalPage}
                startPage={this.previousPage}
                deleteItem={this.deleteItem}
                uploadingPhoto={this.props.uploadingPhoto}
                is_template
                getInactiveStudents={this.getInactiveStudents}
                getActiveStudents={this.getActiveStudents}
                goal_students={goal_students}
                inactive_goal_students={inactive_goal_students}
                studentsLoader={this.state.studentsLoader}
                goalsLoader={this.state.goalsLoader}
                activeStudentsLoader={this.state.activeStudentsLoader}
              />
            </div>
          )}
        </div>
        <GoalModal
          showModal={showModal}
          closeModal={this.closeModal}
          fetchGoals={this.fetchGoals}
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    ui: state.ui,
    current_user: state.user.current_user,
    goals: state.user.user_goals,
    goal_students: state.goal.goal_students,
    inactive_goal_students: state.goal.inactive_goal_students,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    common_actions: bindActionCreators(commonActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
    goal_actions: bindActionCreators(goalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouting(CurriculumsPage))
