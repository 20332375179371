import React from "react";

import moment from "moment";
import StockProfilePic from "../../../../assets/images/mock/StockProfilePic.png";

import "./DivisionRecordsCard.scss";

const divisionRecordsCard = (props) => {
  let studentPic = StockProfilePic;
  if (props.student && props.student.avatar) {
    studentPic = props.student.avatar.file_url;
  }

  return (
    <div className="division-records-card" id={"division-records-card-" + props.cardNum}>
      <div className="division-records-numbers">
        <img
          className="division-records-badge"
          src={props.badge}
          alt={props.divName + " Badge"} />
        <div className="divisions-records-div-name" style={{backgroundColor: props.color}}>
          <p>{props.divName}
            <br />
            {props.yardage} Yards
          </p>
        </div>
        <div className="division-records-score-info">
          {props.student ?
            [
              <p key="score" className="division-records-score" style={{color: props.color}}>{props.recordScore}</p>,
              <p key="date" className="division-records-date">{moment(props.date).format("MM/DD/YY")}</p>
            ]
          : null}
      </div>
    </div>
    <div className="division-records-student">
      {props.student ?
        [
          <img
            key="student-pic"
            className="division-records-student-pic"
            src={studentPic}
            alt={props.student.first_name + " " + props.student.last_name} />,
          <p key="student-name" className="division-records-student-name">{props.student.first_name + " " + props.student.last_name}</p>
        ]
      : <p className="division-not-attempted-msg">Not yet attempted - be the first!</p>}
    </div>
  </div>);
};

export default divisionRecordsCard;
