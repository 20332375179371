import PropTypes from 'prop-types'
import styles from './CoachDashboardOp36.module.scss'

const CommunityLeaderboardStatsItem = ({ icon, text, data }) => (
  <div className={styles.leaderboardStatsItem}>
    {icon}
    <div className={styles.info}>
      <div className={styles.data}>{data}</div>
      <div className={styles.text}>{text}</div>
    </div>
  </div>
)

CommunityLeaderboardStatsItem.propTypes = {
  icon: PropTypes.object,
  text: PropTypes.string,
  data: PropTypes.number,
}

export default CommunityLeaderboardStatsItem
