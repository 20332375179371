import React, { Component, useMemo } from "react";
import UserCard from "./UserCard";
import GolferForm from "../Forms/CreateGolferForm";
import Loader from "../../../common/Op36Loader-web";
import "./CreateGolfersStep.scss";
import { withRouting } from "../../../common/hooks";
import { useNavigate } from "react-router-dom"

const CreateGolfersStep = ({
  showGolferForm, toggleGolferForm, shouldUpdateGolfer,
  golfer, createGolfer, updateGolfer,
  family, community,
  loader, error 
}) => {
  const navigate = useNavigate()

  const course = useMemo(() => {
    const id = sessionStorage.getItem('courseId')
    const name = sessionStorage.getItem('courseName')

    if (id !== null && name !== null) {
      return { id, name }
    }
    return null
  }, [])

  const handleFinishButtonAction = () => {
    if (course) {
      sessionStorage.removeItem('courseId')
      sessionStorage.removeItem('courseName')
      navigate(`/landing/${community.id}/class/${course.id}/register`)
    }
    else {
      navigate('/')
    }
  }

  const sortedUsers = [...family.users].sort((a, b) => a.id - b.id)

  return (
    <div id="CreateGolfersStep" className="animated fadeIn">
      {showGolferForm ? (
        <h5 className="title">
          {shouldUpdateGolfer ? "Update golfer" : "Add a golfer"}
        </h5>
      ) : (
        <div>
          <h5 className="title">Welcome {family.name}</h5>
          <p className="description">
            Start creating golfers that will be using the Op 36 Platform
          </p>
        </div>
      )}
      {showGolferForm ? (
        <GolferForm
          golfer={golfer}
          createGolfer={createGolfer}
          updateGolfer={updateGolfer}
          family={family}
          community={community}
          toggleGolferForm={toggleGolferForm}
          shouldUpdateGolfer={shouldUpdateGolfer}
          loader={loader}
          error={error}
        />
      ) : (
        <div>
          {family ? (
            <div>
              <div className="user-container row">
                {sortedUsers.map(user => (
                  <UserCard user={user} handleEdit={toggleGolferForm} key={user.id} />
                ))}
              </div>
              <div className="add-btn-container">
                <div className='add-golfer-btn-wrapper'>
                  <button
                    className="btn btn-outline--orange add-golfer-btn"
                    onClick={() => toggleGolferForm(null, false)}
                  >
                    <i className="fa fa-plus add-icon" /> Add another golfer
                  </button>
                </div>
              </div>
              <div className="dashboard-btn-container">
                <p className='finished-text'>
                  Finished?
                </p>
                {course && (
                  <p className='course-name'>
                    {`Register for ${course.name}.`}
                  </p>
                )}
                <button
                  className="btn btn-primary"
                  onClick={handleFinishButtonAction}
                >
                  {course ? 'Go to class sign-up page' : 'Go to your dashboard'}
                </button>
              </div>
            </div>
          ) : (
            <Loader message="loading family" />
          )}
        </div>
      )}
    </div>
  )
}

export default CreateGolfersStep
