import React from 'react'
import ErrorPage from './common/ErrorPage'
import PropTypes from 'prop-types'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, messageError: '' }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  removeError() {
    this.setState({ hasError: false })
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage removeError={this.removeError.bind(this)}/>
    }

    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element,
}

export default ErrorBoundary
