import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import * as Constants from '../../../misc/Constants'
import { deletePackage, getCommunitySettingsPackages, updatePackage } from '../../../actions/packagesActions'
import { reset, setArchived, setPage } from '../../../reducers/community-settings/pricingSettingsReducer'
import ConfirmationModal2 from '../../common/ConfirmationModal2'
import PackageFormContainer from './PackageFormContainer'
import PackagesTable from './PackagesTable'
import StripeConnection from './StripeConnection'

import './PricingTab.scss'
import colors from '../../../assets/styles/globals.scss'
import { ReactComponent as RoundedPlusIcon } from '../../../assets/images/common/icons/rounded-plus-icon.svg'
import { ReactComponent as ArchiveIcon } from '../../../assets/images/common/icons/archive-icon.svg'
import { ReactComponent as TrashIcon } from '../../../assets/images/common/icons/trash-icon.svg'

const PricingTab = () => {
  const dispatch = useDispatch()

  const { packages, archived, selectedPackage, totalPages } = useSelector(state => state.communitySettings.pricing)

  const [showPackageFormModal, setShowPackageFormModal] = useState(false)
  const [confirmationType, setConfirmationType] = useState('')
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [confirmationPackage, setConfirmationPackage] = useState(null)
  const [confirmationMessage, setConfirmationMessage] = useState('')
  const [confirmationTitle, setConfirmationTitle] = useState('')
  const [confirmationFinalMessage, setConfirmationFinalMessage] = useState('')
  const [confirmationProceedMessage, setConfirmationProceedMessage] = useState('')

  const onConfirmationModalClose = useCallback(() => {
    setShowConfirmationModal(false)
    setConfirmationPackage(null)
  }, [])

  const fetchPackages = (newPage = 1) => {
    if (totalPages && newPage === totalPages && packages.length === 1) {
      newPage -= 1
    }
    dispatch(setPage(newPage))
    dispatch(getCommunitySettingsPackages({ archived, page: newPage, perPage: Constants.PER_PAGE_DEFAULT, mode: 'stats' }))
  }

  useEffect(() => () => {
    dispatch(setPage(1))
    dispatch(reset())
  }, [dispatch])

  useEffect(() => {
    dispatch(getCommunitySettingsPackages({ archived, page: 1, perPage: Constants.PER_PAGE_DEFAULT, mode: 'stats' }))
  }, [archived, dispatch])

  useEffect(() => {
    if (confirmationPackage) {
      setShowConfirmationModal(true)
    }
  }, [confirmationPackage])

  useEffect(() => {
    if (selectedPackage) {
      setShowPackageFormModal(true)
    }
  }, [selectedPackage])

  const toggleArchiveConfirmationModal = (pkg) => {
    setConfirmationType('archive')
    setConfirmationTitle('Archive Package?')
    setConfirmationMessage('After you archive this package, you will still be able to view its associated classes and enrollments.' +
      ' However, you won\'t be able to add it to any other classes or have your students select it while requesting enrollment.')
    setConfirmationFinalMessage('You can always unarchive this package later if needed.')
    setConfirmationProceedMessage('Archive')
    setConfirmationPackage(pkg)
  }

  const archivePackage = () => {
    dispatch(updatePackage({ _package: confirmationPackage, changeArchived: true })).unwrap()
      .then(() => {
        onConfirmationModalClose()
        toast.success(`"${confirmationPackage.name}" was archived`, { position: toast.POSITION.BOTTOM_LEFT })
      })
  }

  const unarchivePackage = (pkg) => {
    dispatch(updatePackage({ _package: pkg, changeArchived: true })).unwrap()
      .then(() => {
        onConfirmationModalClose()
        toast.success(`"${pkg.name}" was unarchived`, { position: toast.POSITION.BOTTOM_LEFT })
      })
  }

  const toggleDeleteConfirmationModal = (pkg) => {
    setConfirmationType('delete')
    setConfirmationTitle('Delete Package?')
    setConfirmationMessage('If you delete this package, you won\'t be able to add it to classes or have students select it while requesting ' +
      'enrollment for classes. This action cannot be undone.')
    setConfirmationFinalMessage(null)
    setConfirmationProceedMessage('Delete')
    setConfirmationPackage(pkg)
  }

  const handleDeletePackage = () => {
    dispatch(deletePackage(confirmationPackage)).unwrap()
      .then(() => {
        onConfirmationModalClose()
        toast.success(`"${confirmationPackage.name}" was deleted`, { position: toast.POSITION.BOTTOM_LEFT })
      })
  }

  const toggleArchivedValue = (e) => {
    const newValue = e.target.value === 'true'
    if (archived !== newValue) {
      dispatch(setArchived(newValue))
    }
  }

  return (
    <div id='PricingTab'>
      <StripeConnection />
      <div className='d-flex community-settings-main-row'>
        <div className='d-flex flex-column community-pricing-packages'>
          <p className='community-pricing-packages-title'>Community Pricing Packages</p>
          <p className='community-pricing-packages-description'>Use packages to offer different pricing options for your classes. Students can see
            and select available packages when they register for a class.</p>
        </div>

        <div className='d-flex justify-content-center align-items-center packages-filter-buttons'>
          <button
            className={`packages-filter-button ${archived ? 'packages-filter-unselected' : 'packages-filter-selected'}`}
            value='false'
            onClick={toggleArchivedValue}
          >
            Available
          </button>
          <button
            className={`packages-filter-button ${archived ? 'packages-filter-selected' : 'packages-filter-unselected'}`}
            value='true'
            onClick={toggleArchivedValue}
          >
            Archived
          </button>
        </div>

        <div className='package-add-wrapper'>
          <button
            type='button'
            className='d-flex justify-content-center align-items-center package-add-button'
            onClick={() => setShowPackageFormModal(true)}
          >
            <RoundedPlusIcon id='roundedPlusIcon' /> Create new package
          </button>
        </div>
      </div>

      <div className='table-wrapper'>
        <PackagesTable
          fetchPackages={fetchPackages}
          toggleArchiveConfirmationModal={toggleArchiveConfirmationModal}
          unarchivePackage={unarchivePackage}
          toggleDeleteConfirmationModal={toggleDeleteConfirmationModal}
          showPopover={!showConfirmationModal && !showPackageFormModal}
        />
      </div>
      <ConfirmationModal2
        showModal={showConfirmationModal}
        closeModal={onConfirmationModalClose}
        onDeny={onConfirmationModalClose}
        denyMessage='Cancel'
        onProceed={confirmationType === 'archive'
          ? archivePackage
          : handleDeletePackage
        }
        proceedMessage={confirmationProceedMessage}
        icon={confirmationType === 'archive'
          ? <ArchiveIcon id='archiveIcon' aria-hidden='true' />
          : <TrashIcon id='trashIcon' aria-hidden='true' fill={colors.lightGray} />
        }
        title={confirmationTitle}
        message={confirmationMessage}
        finalMessage={confirmationFinalMessage}
      />
      <PackageFormContainer
        showPackageFormModal={showPackageFormModal}
        setShowPackageFormModal={setShowPackageFormModal}
      />
    </div>
  )
}

export default PricingTab
