import * as constants from "./actionTypes";

import Axios from '../axios'

function successRequestingSalesLeads(response) {
  if (response.status === 200) {
    return {
      type: constants.GET_SALES_LEADS,
      sales_leads: response.data.sales_leads,
      pages: response.data.meta.pagination
        ? response.data.meta.pagination.total_pages
        : null,
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

function successUpdatingSalesLead(response) {
  if (response.status === 200) {
    return {
      type: constants.UPDATE_SALES_LEAD,
      sales_lead: response.data.sales_lead,
    };
  } else {
    return { type: constants.UI_ERROR, error: response.data };
  }
}

//========================================================================
//========================================================================
//========================================================================

export function getSalesLeads(search, sales_rep_id, status, page) {
  return function (dispatch) {
    return requestSalesLeads(
      search,
      sales_rep_id,
      status,
      page
    ).then((response) => dispatch(successRequestingSalesLeads(response)));
  };
}

export function updateSalesLead(id, data) {
  return function (dispatch) {
    return requestUpdateSalesLead(id, data).then((response) =>
      dispatch(successUpdatingSalesLead(response))
    );
  };
}

export function shortUpdateSalesLead(lead) {
  return function (dispatch) {
    dispatch(successUpdatingSalesLead(lead));
  };
}

//==============================================================
//==============================================================
//==============================================================

function requestSalesLeads(search, sales_rep_id, status, page) {
  let requestUrl = "/api/v1/sales_leads";
  let params = {};
  if (search) params["search"] = search;
  if (sales_rep_id) params["sales_rep_id"] = sales_rep_id;
  if (status) params["status"] = status;
  if (page) params["page"] = page;

  return Axios.get(requestUrl, { params });
}

function requestUpdateSalesLead(id, data) {
  let requestUrl = "/api/v1/sales_leads/" + id;
  return Axios.put(requestUrl, data);
}
