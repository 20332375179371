import * as types from "../actions/actionTypes";

export default function reports(state = {}, action) {
  switch (action.type) {
    case types.GET_CURRICULUM_REPORT:
      return action.reports;
    case types.GET_ENGAGEMENT_REPORT:
      return action.reports;
    case types.GET_STATS_REPORT:
      return action.reports;
    case types.GET_COMMUNITY_REPORT:
      return action.reports;
    case types.GET_COMMUNITY_ROUNDS_REPORT:
      return action.reports;
    case types.GET_COMMUNITY_PRACTICE_REPORT:
      return action.reports;
    case types.GET_COACH_ENGAGEMENT_REPORT:
      return action.reports;
    case types.GET_COMMUNITY_ENGAGEMENT_REPORT:
      return action.reports;
    case types.GET_SUPPORT_ACTIVITY_REPORT:
      return action.reports;
    case types.GET_COMMUNITY_PRIORITY_REPORT:
      return action.reports;
    case types.TOGGLE_COMMUNITY_FEATURE:
      return { ...state, community: { ...state.community, features: action.features }}
    default:
      return state;
  }
}
