import React from "react";
import Loader from "../../common/Op36Loader-web";
import Axios from "../../../axios";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as coachActions from "../../../actions/coachActions";
import * as userActions from "../../../actions/userActions";
import * as reportsActions from "../../../actions/reportsActions";
// ASSETS
import "./CoachStats.scss";

const getColor = (value) => {
  switch (value) {
    case 0:
      return "#ed213a";
    case 1:
      return "#fddb10";
    case 2:
      return "#33d9b2";
    default:
      return "";
  }
};

class CoachStats extends React.Component {
  state = {
    coachId: this.props.coach.coach.id,
    status: "static",
    savedPhone: this.props.coach.coach.phone
      ? this.props.coach.coach.phone
      : null,
    savedEmail: this.props.coach.coach.email
      ? this.props.coach.coach.email
      : null,
    email: this.props.coach.coach.email ? this.props.coach.coach.email : null,
    phone: this.props.coach.coach.phone ? this.props.coach.coach.phone : null,
    contactLoader: false,
  };

  render() {
    const { coach, makeLeadCoach } = this.props;
    const { contactLoader } = this.state;
    return (
      <div id='CoachStats'>
        <div className='coach-contact-container'>
          <h4>
            Contact Info
            <span onClick={this._toggleEdit} id='contact-info-edit'>
              {this.state.status === "static" ? "Edit" : "Close"}
            </span>
          </h4>

          {this._renderContactInfo(coach, contactLoader)}
        </div>
        {!coach.coach.is_lead_coach && (
          <p
            className='lead-coach-button'
            onClick={() => makeLeadCoach(coach.coach.id)}
          >
            -Make Lead Coach-
          </p>
        )}
        <div className='row'>
          <div className='col-6'>
            <h2
              style={{
                color: getColor(coach.completed_express_training.score.value),
              }}
            >
              {coach.completed_express_training.value}
            </h2>
            <p>Completed Old Express Training</p>
          </div>
          <div className='col-6'>
            <h2
              style={{
                color: getColor(
                  coach.completed_updated_express_training.score.value
                ),
              }}
            >
              {coach.completed_updated_express_training.value}
            </h2>
            <p>Completed Updated Express Training</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-6'>
            <h2
              style={{
                color: getColor(coach.events_count.score.value),
              }}
            >
              {coach.events_count.value}
            </h2>
            <p>Events Created</p>
          </div>
          <div className='col-6'>
            <h2
              style={{
                color: getColor(coach.programs_count.score.value),
              }}
            >
              {coach.programs_count.value}
            </h2>
            <p>Classes Created</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-6'>
            <h2
              style={{
                color: getColor(coach.likes_count.score.value),
              }}
            >
              {coach.likes_count.value}
            </h2>
            <p>Likes Given</p>
          </div>
          <div className='col-6'>
            <h2
              style={{
                color: getColor(coach.comments_count.score.value),
              }}
            >
              {coach.comments_count.value}
            </h2>
            <p>Comments Created</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-6'>
            <h2
              style={{
                color: getColor(coach.private_goal_count.score.value),
              }}
            >
              {coach.private_goal_count.value}
            </h2>
            <p>Private Goals Issued</p>
          </div>
          <div className='col-6'>
            <h2
              style={{
                color: getColor(coach.announcements_count.score.value),
              }}
            >
              {coach.announcements_count.value}
            </h2>
            <p>Announcements Created</p>
          </div>
        </div>
      </div>
    );
  }

  _handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value ? value : null,
    });
  };

  _toggleEdit = () => {
    if (this.state.status === "static") {
      this.setState({
        status: "edit",
      });
    } else {
      this.setState({
        status: "static",
      });
    }
  };

  _renderContactInfo = (coach, loader) => {
    if (loader) {
      return <Loader message='saving' />;
    }
    if (this.state.status === "static") {
      return (
        <div>
          <p>
            <span>Email:</span>{" "}
            {this.state.savedEmail
              ? this.state.savedEmail
              : "No E-Mail Provided"}
          </p>
          <p>
            <span>Phone:</span>{" "}
            {this.state.savedPhone
              ? this.state.savedPhone
              : "No Phone Number Provided"}
          </p>
        </div>
      );
    } else {
      return (
        <form>
          <table>
            <tbody>
              <tr>
                <td className='input-label'>Email: </td>
                <td>
                  <input
                    type='email'
                    name='email'
                    value={this.state.email ? this.state.email : ""}
                    placeholder='-none-'
                    onChange={this._handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td className='input-label'>Phone: </td>
                <td>
                  <input
                    type='text'
                    name='phone'
                    value={this.state.phone ? this.state.phone : ""}
                    placeholder='-none-'
                    onChange={this._handleInputChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <p onClick={this._handleSave} id='form-save'>
            Save
          </p>
        </form>
      );
    }
  };

  _handleSave = () => {
    if (
      this.state.email === this.state.savedEmail &&
      this.state.phone === this.state.savedPhone
    ) {
      this.setState({ status: "static" });
    } else {
      this.setState({ contactLoader: true });
      this._updateCoach(this.state.coachId);
    }
  };

  _updateCoach = async (id) => {
    let user = { id: id, phone: this.state.phone, email: this.state.email };
    this.props.user_actions.updateUser(user).then((res) =>
      this.setState({
        status: "static",
        contactLoader: false,
        savedPhone: this.state.phone,
        savedEmail: this.state.email,
      })
    );
  };
}

function mapStateToProps(state, ownProps) {
  return {
    ui: state.ui,
    user: state.user.current_user,
    coaches: state.coaches,
    communities: state.communities.communities,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    coach_actions: bindActionCreators(coachActions, dispatch),
    user_actions: bindActionCreators(userActions, dispatch),
    reports_actions: bindActionCreators(reportsActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CoachStats);
