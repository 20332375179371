const ResourcesList = ({ links }) => {
  return links.map((link, index) => {
    return (
      <a
        key={index}
        className='resource-button btn btn-block'
        href={`${link.attachment_url}`}
        target="_blank"
      >
        {link.name}
      </a>
    )
  })
}

export default ResourcesList
